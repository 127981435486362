import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useAccessControl } from "hooks/useAccessControl";
import { contentService } from "services/content";
import { HitAction } from "@alphasights/portal-api-client";

import { errorLoadingTranscript, transcriptUnavailableTryAgainContent } from "content/AlphaNow";
import { CONTENT_TYPE_PERMISSION, SPEAKER_FOREIGN_TRANSLATIONS, TOO_MANY_REQUESTS_STATUS } from "constants/AlphaNow";

import { AudioView } from "./AudioView";
import QuestionView from "./QuestionView/QuestionView";
import ComprehensiveSummaryView from "./ComprehensiveSummaryView/ComprehensiveSummaryView";
import { AlphaNowSpinner } from "pages/AlphaNowPage/components";
import ErrorMessage from "../../ErrorMessage";
import MentionsView from "./MentionsView";
import TranscriptView from "./TranscriptView";
import { handleScroll, logScrollPercentage } from "../../../utils/logHit";
import { View } from "models/Header";

const ContentView = ({
  content,
  isTranscriptDataReady,
  setIsTranscriptDataReady,
  searchQuery,
  viewInTranscript,
  setDetailsCollapsed,
  contentAudio,
  contentVtt,
  projectToken = null,
  viewMode,
  setViewMode,
  audioToggle,
  questionsData,
  questionsFetched,
  comprehensiveSummaryProps,
  setAccessSuspended = () => {},
  keywordSearchProps: {
    setTotalKeywordMatches,
    setSelectedMentionIndex,
    selectedComprehensiveSummaryIndex,
    selectedMentionIndex,
  },
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();

  const isClientOrHasPermission = useAccessControl([CONTENT_TYPE_PERMISSION[content.contentType]]);

  const ref = useRef(null);
  const scrollRef = useRef(null);
  const maxScrollPercentage = useRef(0);
  const [containerWidth, setContainerWidth] = useState();

  const [transcriptData, setTranscriptData] = useState({});
  const [conversationData, setConversationData] = useState([]);
  const [originalConversation, setOriginalConversation] = useState();
  const [mentionsData, setMentionsData] = useState({});

  const [isConversation, setIsConversation] = useState(false);
  const [isConversationFetchAttempted, setIsConversationFetchAttempted] = useState(false);
  const [isConversationLoading, setIsConversationLoading] = useState(false);
  const [isTranscriptFetchAttempted, setIsTranscriptFetchAttempted] = useState(false);

  const isMentionsView = viewMode === View.Mentions;
  const isComprehensiveSummaryView = viewMode === View.Summary;
  const contentId = content?.id;
  const hasKeywords = searchQuery.length > 0;
  const transcriptId = content?.transcriptId;
  const isPurchased = !content?.paymentRequired;

  const newKeywords = [...new Set(searchQuery.map((keyword) => keyword.value))];
  const contentType = content.contentType;

  const speakers = useMemo(() => {
    // auto-map speaker roles where roles are not mapped properly and there is only one expert
    const contentSpeakers = content?.speakers?.slice() || [];
    if (contentSpeakers.length === 2 && contentSpeakers.some((speaker) => !speaker.transcriptParticipantRole)) {
      contentSpeakers.forEach((speaker) => {
        speaker.transcriptParticipantRole = speaker.isModerator ? "Interviewer" : "Expert";
      });
    }

    if (content.contentType === "PEER_CONTRIBUTED" || content.contentType === "PRIVATE_ENTERPRISE") {
      const interviewer = {
        isModerator: true,
        transcriptParticipantRole: "Interviewer",
      };
      contentSpeakers.push(interviewer);
    }

    return contentSpeakers;
  }, [content?.speakers, content?.contentType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const action = HitAction.alphaNowTranscriptScrollPercentage;
    return () => {
      logScrollPercentage(
        logHit,
        {
          contentId: content?.id,
          scrollPercentage: maxScrollPercentage.current,
          userId: null,
        },
        action
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollWrapper = useCallback(() => {
    handleScroll(
      (newMaxScrollPercentage) => (maxScrollPercentage.current = newMaxScrollPercentage),
      maxScrollPercentage.current,
      scrollRef.current
    );
  }, [maxScrollPercentage, scrollRef]);

  useEffect(() => {
    const current = scrollRef.current;
    if (current) {
      scrollRef.current.scrollTop = 0;
      current.addEventListener("scroll", handleScrollWrapper);
    }

    return () => {
      if (current) current.removeEventListener("scroll", handleScrollWrapper);
    };
  }, [handleScrollWrapper, viewMode]);

  const { isLoading: isTranscriptLoading, refetch: refetchTranscript } = useQuery(
    ["transcript", transcriptId, searchQuery, isMentionsView, isPurchased],
    () => {
      setIsTranscriptDataReady(false);

      if (!(isClientOrHasPermission && isPurchased) || (isMentionsView && !hasKeywords)) return {};
      if (hasKeywords) {
        logHit({
          origin: "alpha-now",
          action: HitAction.alphaNowSearchInTranscript,
          details: { contentId, keywords: newKeywords },
        });
        return contentService.searchTranscript(contentId, !isMentionsView, newKeywords, transcriptId, projectToken);
      }

      return contentService.fetchTranscript(contentId, projectToken);
    },
    {
      enabled: false,
      onSuccess: (res) => {
        if (hasKeywords) {
          setTotalKeywordMatches(res.total);
        }

        if (isMentionsView) {
          setMentionsData(res);
        } else {
          setTranscriptData(res);
        }

        setIsTranscriptDataReady(true);
        setIsTranscriptFetchAttempted(true);
      },
      onError: () => {
        setIsTranscriptFetchAttempted(true);
      },
    }
  );

  const { refetch: refetchSummary, data: refetchedComprehensiveSummaryViewData } = useQuery(
    ["Summary", contentId, newKeywords],
    () => {
      if (hasKeywords) {
        return contentService.searchComprehensiveSummary(contentId, projectToken, newKeywords);
      }
    },
    {
      enabled: !!contentId,
    }
  );

  const handleConversationError = () => {
    setIsConversation(false);
    setIsConversationFetchAttempted(true);
    setConversationData([]);
    setIsConversationLoading(false);
    setIsTranscriptDataReady(false);
    setAccessSuspended(false);
  };

  const handleConversationSuccess = (conversation) => {
    setConversationData(conversation);
    setIsConversationFetchAttempted(true);
    setIsConversationLoading(false);
    setIsTranscriptDataReady(true);
  };

  const isConversationValid = (conversationFragments) => {
    if (!conversationFragments?.length > 0) return false;

    const contentSpeakers = speakers.map((s) => s.transcriptParticipantRole);
    const conversationSpeakers = conversationFragments
      .map((f) => f.participant)
      .filter((value, index, array) => array.indexOf(value) === index);

    const speakerTranslations = Object.values(SPEAKER_FOREIGN_TRANSLATIONS).reduce(
      (prev, curr) => [...prev, ...curr],
      []
    );
    // check we have speaker info for all conversation speakers
    return (
      contentSpeakers?.length &&
      conversationSpeakers?.length &&
      conversationSpeakers.every((s) => contentSpeakers.includes(s) || speakerTranslations.includes(s))
    );
  };

  const fetchConversationMutation = useMutation(
    ({ contentId }) => contentService.fetchConversation(contentId, projectToken),
    {
      onSuccess: (res) => {
        setAccessSuspended(false);
        const conversationFragments = res.conversation?.conversationFragments;
        const isSuccess = isConversationValid(conversationFragments);
        setIsConversation(isSuccess);
        if (isSuccess) {
          setOriginalConversation(conversationFragments);
          handleConversationSuccess(conversationFragments);
        } else {
          handleConversationError();
        }
      },
      onError: (res) => {
        res.status === TOO_MANY_REQUESTS_STATUS ? setAccessSuspended(true) : handleConversationError();
      },
    }
  );

  const { refetch: refetchConversation } = useQuery(
    ["conversation", isConversationFetchAttempted, isMentionsView, searchQuery, transcriptId, isPurchased],
    () => {
      setTotalKeywordMatches(null);
      setIsConversationLoading(true);
      setIsTranscriptDataReady(false);

      if (!(isClientOrHasPermission && isPurchased)) return {};

      if (!isConversationFetchAttempted) {
        return fetchConversationMutation.mutate({ contentId });
      }

      if (!isConversation) return {};

      if (!hasKeywords) {
        if (isMentionsView) return {};
        // Transcript Contents, keywords removed, return full conversation
        setSelectedMentionIndex(0);
        return originalConversation;
      }

      logHit({
        origin: "alpha-now",
        action: HitAction.alphaNowSearchInTranscript,
        details: { contentId, keywords: newKeywords },
      });

      return contentService.searchConversation(contentId, !isMentionsView, newKeywords, transcriptId, projectToken);
    },
    {
      enabled: isPurchased && !!transcriptId && speakers?.length > 0,
      onSuccess: (res) => {
        // if this is the first fetch, we run fetchConversationMutation instead and res = undefined
        if (!res) return;

        if (hasKeywords) {
          setTotalKeywordMatches(res.total);
        }

        if (isMentionsView && (!!res?.results || !hasKeywords)) {
          setMentionsData(res);
          setIsConversationLoading(false);
          setIsTranscriptDataReady(true);
          return;
        }

        if (!!res?.results) {
          // search in a conversation
          const conversationDataWithMentions = originalConversation.map((fragment) => ({ ...fragment }));
          res.results.forEach((fragment) => {
            conversationDataWithMentions[fragment.position].speechContent = fragment.speechContent;
          });
          handleConversationSuccess(conversationDataWithMentions);
          return;
        }

        const conversation = res?.length > 0 ? res : res?.conversation?.conversationFragments?.length > 0;

        if (!!conversation) {
          handleConversationSuccess(conversation);
        } else {
          refetchTranscript();
          refetchSummary();
          handleConversationError();
        }
      },
      onError: () => {
        refetchTranscript();
        if (isComprehensiveSummaryView) {
          refetchSummary();
        }
        handleConversationError();
      },
    }
  );

  useEffect(() => {
    setContainerWidth(ref.current?.clientWidth);
    const handleResize = () => setContainerWidth(ref.current?.clientWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (hasKeywords && isComprehensiveSummaryView) {
      setTotalKeywordMatches(refetchedComprehensiveSummaryViewData?.total);
    }
  });

  useEffect(() => {
    if (isPurchased && !!transcriptId && speakers.length > 0) {
      refetchConversation();
    }
    setDetailsCollapsed(isPurchased);
  }, [isPurchased, speakers, transcriptId, refetchConversation, setDetailsCollapsed]);

  let Contents;

  if (isTranscriptLoading || isConversationLoading) {
    Contents = <AlphaNowSpinner />;
  } else if (!isTranscriptDataReady) {
    if (isTranscriptFetchAttempted) {
      Contents = <ErrorMessage header={errorLoadingTranscript} body={transcriptUnavailableTryAgainContent} />;
    } else {
      Contents = <AlphaNowSpinner />;
    }
  } else if (isMentionsView) {
    Contents = (
      <MentionsView
        contentId={contentId}
        containerWidth={containerWidth}
        isConversation={isConversation}
        data={mentionsData}
        speakers={isConversation ? speakers : []}
        isSuccess={isTranscriptDataReady}
        keywords={searchQuery}
        viewInTranscript={viewInTranscript}
        contentType={contentType}
      />
    );
  } else if (viewMode === View.Audio) {
    Contents = (
      <AudioView
        contentId={contentId}
        audioUrl={contentAudio}
        vttUrl={contentVtt}
        recording={{
          visibleToClient: true,
        }}
        conversation={conversationData}
        speakers={speakers}
        setViewMode={setViewMode}
        audioToggle={audioToggle}
      />
    );
  } else if (isComprehensiveSummaryView) {
    Contents = (
      <ComprehensiveSummaryView
        contentId={contentId}
        comprehensiveSummaryFetched={comprehensiveSummaryProps.comprehensiveSummaryFetched}
        selectedIndex={selectedComprehensiveSummaryIndex}
        comprehensiveSummaryViewData={
          refetchedComprehensiveSummaryViewData?.summary || comprehensiveSummaryProps.summary
        }
        setViewMode={setViewMode}
      />
    );
  } else if (viewMode === View.Question) {
    Contents = (
      <QuestionView
        contentId={contentId}
        questionsData={questionsData}
        isSuccess={questionsFetched}
        setViewMode={setViewMode}
        viewMode={viewMode}
      />
    );
  } else {
    Contents = (
      <TranscriptView
        conversation={conversationData}
        selectedMentionIndex={selectedMentionIndex}
        speakers={speakers}
        transcript={transcriptData}
        contentType={contentType}
      />
    );
  }

  return isClientOrHasPermission ? (
    <x.div flex="1" overflowY="auto" ref={scrollRef}>
      <x.div mt={inner.base06} ref={ref}>
        {Contents}
      </x.div>
    </x.div>
  ) : (
    <x.img
      mt={inner.base04}
      h="100%"
      w="100%"
      objectFit="fill"
      maxWidth="100%"
      src={require("pages/AlphaNowPage/images/blurred-transcript.png")}
    ></x.img>
  );
};

ContentView.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    paymentRequired: PropTypes.bool,
    transcriptId: PropTypes.number,
    speakers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  isTranscriptDataReady: PropTypes.bool.isRequired,
  setIsTranscriptDataReady: PropTypes.func.isRequired,
  searchQuery: PropTypes.arrayOf(PropTypes.object),
  viewInTranscript: PropTypes.func,
  isMentionsView: PropTypes.bool,
  setDetailsCollapsed: PropTypes.func,
  setAccessSuspended: PropTypes.func,
};

export default ContentView;
