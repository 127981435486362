import React, { forwardRef, useCallback, useEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { HitAction } from "@alphasights/portal-api-client";
import { AlphaNowSRMProductType, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { AlphaNowSpinner } from "pages/AlphaNowPage/components";
import { CurrentScrollAreaDataArgs, handleScroll, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import {
  CompetitorDynamics,
  CustomerDynamics,
  OverviewSectionV2,
  StrategicOutlook,
} from "pages/AlphaNowPage/primers/CompanyPrimer/sections";
import { useCitationTrackingContext } from "components/CitationContext/CitationTrackingContext";
import useModal from "hooks/useModal";
import useForwardedRef from "pages/AlphaNowPage/hooks/useForwardedRef";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import { errorLoadingResearch, unavailableResearch } from "content/AlphaNow";
import { AlphaNowCompanyPrimerProps, DataTestIds, PrimerStatus } from "../../CompanyPrimerContentPageVars";
import { CommissionFooter, CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import SectionWrapper from "../v3/sections/styled";
import { COMMISSION_URL_PARAM } from "pages/AlphaNowPage/primers/CommissionPrimer/constants";
import useGetModalFromUrl from "pages/AlphaNowPage/primers/hooks/useGetModalFromUrl";

interface CompanyPrimerContentProps extends Pick<AlphaNowCompanyPrimerProps, "content"> {
  primerStatus: string;
  isHidden?: boolean;
  isFetchingContent?: boolean;
  content: CompanyPrimerContentV2;
  showShrinkedPaywallHeader: (value: boolean) => void;
}

const CompanyPrimerContent = forwardRef<HTMLDivElement, CompanyPrimerContentProps>(
  ({ content, primerStatus, isHidden = false, isFetchingContent = false, showShrinkedPaywallHeader }, ref) => {
    const {
      id,
      companyNameIdToCDSCompanyNamesMap,
      competitors,
      createdAt,
      fullTimeEquivalents,
      location,
      markets,
      outlooks,
      customerDynamics,
      primer: {
        companyNameId: primerCompanyNameId,
        companyName,
        currency,
        description,
        exampleUseCase,
        groupingType,
        history,
        id: primerId,
        ownershipType,
        ownershipLastChangeAtQuarter,
        ownershipLastChangeAtYear,
        ownerId,
        ownerName,
      },
      revenue,
    } = content;
    const { registerScrollContainer } = useCitationTrackingContext();
    const {
      spacing: { inner },
      color,
    } = useThemeTokens();
    const currentUser = useCurrentUser();
    // @ts-ignore
    const userId = currentUser?.id;
    const { logHit } = useTrackUserAction();
    const {
      isVisible: isCommissionModalVisible,
      onOpen: onOpenCommissionModal,
      onClose: onCloseCommissionModal,
    } = useModal();
    useGetModalFromUrl(onOpenCommissionModal, COMMISSION_URL_PARAM);

    const maxScrollPercentage = useRef(0);
    const { ref: scrollAreaRef, updateRef: updateScrollAreaRef } = useForwardedRef(ref);

    useEffect(() => {
      registerScrollContainer(scrollAreaRef);

      const action = HitAction.alphaNowCompanyPrimerScrollPercentage;
      const scrollPercentage = maxScrollPercentage.current;

      return () => {
        logScrollPercentage(
          logHit,
          {
            contentId: id,
            scrollPercentage,
            userId: String(userId),
          },
          action
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScrollWrapper = useCallback(() => {
      handleScroll(
        (newMaxScrollPercentage: number) => (maxScrollPercentage.current = newMaxScrollPercentage),
        maxScrollPercentage.current,
        scrollAreaRef.current as CurrentScrollAreaDataArgs
      );

      if (scrollAreaRef.current) {
        const { scrollTop, scrollHeight } = scrollAreaRef.current;

        // hide the paywall detailed component once 0.5% of the primer content has been scrolled
        if (scrollTop > 0.005 * scrollHeight) {
          showShrinkedPaywallHeader(true);
        } else {
          showShrinkedPaywallHeader(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxScrollPercentage, scrollAreaRef]);

    useEffect(() => {
      const current = scrollAreaRef.current;
      current?.addEventListener("scroll", handleScrollWrapper);

      return () => {
        current?.removeEventListener("scroll", handleScrollWrapper);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollAreaRef.current, handleScrollWrapper]);

    let Contents;

    if (primerStatus === PrimerStatus.loading || isFetchingContent) {
      Contents = (
        <x.div mt={inner.base06} data-testid={DataTestIds.purchasingPrimer}>
          <AlphaNowSpinner />
        </x.div>
      );
    } else if (primerStatus === PrimerStatus.error) {
      Contents = (
        <div data-testid={DataTestIds.errorToPurchasePrimer}>
          <ErrorMessage header={errorLoadingResearch} body={unavailableResearch} />
        </div>
      );
    } else {
      Contents = (
        <x.div
          pt={{ xs: 0, sm: inner.base06 }}
          pl={{ xs: inner.base06, sm: inner.base08 }}
          pr={inner.base06}
          data-testid={DataTestIds.loadedCompanyPrimer}
        >
          <OverviewSectionV2
            companyPrimerOverview={{
              id: primerId,
              currency,
              description,
              history,
              exampleUseCase,
              ownershipType,
              revenue: revenue[0],
              fullTimeEquivalent: fullTimeEquivalents[0],
              location,
              markets,
            }}
            contentCreatedAt={createdAt}
            {...{
              ownershipLastChangeAtQuarter,
              ownershipLastChangeAtYear,
            }}
            ownerName={
              ownerName?.value
                ? ownerName
                : {
                    value: ownerId.value ? companyNameIdToCDSCompanyNamesMap[ownerId.value!]?.displayName : "",
                    citedBy: ownerId.citedBy,
                  }
            }
          />
          <CustomerDynamics customerDynamics={customerDynamics} />
          <CompetitorDynamics
            {...{
              competitors,
              companyNameIdToCDSCompanyNamesMap,
              primerCompanyNameId,
              companyName,
              groupingType,
            }}
          />
          <StrategicOutlook {...{ outlooks }} />
          <SectionWrapper px={inner.base06} py="0">
            <CommissionFooter onOpen={onOpenCommissionModal} />
          </SectionWrapper>
          {isCommissionModalVisible && (
            <CommissionModal productType={AlphaNowSRMProductType.companyPrimer} onClose={onCloseCommissionModal} />
          )}
        </x.div>
      );
    }
    return (
      <x.div
        ref={updateScrollAreaRef}
        h={isHidden ? 0 : "100%"}
        overflow="scroll"
        backgroundColor={color.background.surface.recessed}
      >
        {Contents}
      </x.div>
    );
  }
);

export default CompanyPrimerContent;
