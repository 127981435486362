import styled, { th } from "@xstyled/styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: ${th.space("layout-base")};
`;

export const IconContainer = styled.div`
  width: 10rem;
`;

export const Content = styled.div`
  font-weight: 600;
  color: ${th.color("text-secondary")};
  flex-grow: 1;
`;

export const StyledButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: ${th.space("layout-base02")};
`;
