import { camelCase, clamp } from "lodash";

export const findElements = (
  root: HTMLElement,
  { dataField, divider = "" }: { dataField: string; divider?: string },
  value?: string | null
) => {
  const queryField = `data-${dataField}`;
  const queryValue = value ? `*="${divider}${value}${divider}"` : "";
  return [...root.querySelectorAll(`[${queryField}${queryValue}]`)] as HTMLElement[];
};

export const getElementValues = (
  el: HTMLElement | null,
  { dataField, divider = "" }: { dataField: string; divider?: string }
) => {
  if (!el) {
    return [];
  }
  const valuesString = el.dataset[camelCase(dataField)] ?? "";
  return (divider ? valuesString.split(divider) : [valuesString]).filter((v) => v);
};

export const focus = (el: HTMLElement | null) => el?.scrollIntoView({ behavior: "smooth", block: "center" });

export const findParentWithDataAttribute = (el: HTMLElement, dataAttribute: string) => {
  let current: HTMLElement | null = el;
  while (current && current !== document.body && !current?.dataset[dataAttribute]) {
    current = current!.parentElement;
  }
  return current?.dataset[dataAttribute] ? current : null;
};

export const traverseArray = (arr: any[], currentElement: any, offset: number, circular: boolean = false) => {
  const currentIdx = arr.indexOf(currentElement);
  if (circular) {
    const targetIdx = (currentIdx + offset) % arr.length;
    return arr[targetIdx >= 0 ? targetIdx : arr.length + (targetIdx % arr.length)];
  } else {
    return arr[clamp(currentIdx + offset, 0, arr.length - 1)];
  }
};
