import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const {
    color: { text, background },
    spacing: { inner },
  } = useThemeTokens();

  return {
    popoverStyles: {
      p: 0,
      borderRadius: inner.base02,
      zIndex: "auto",
    },
    popoverWrapperStyles: {
      gap: inner.base,
      display: "flex",
      flexDirection: "column",
    },
    popoverOptionStyles: {
      p: inner.base02,
      color: text.strong._,
      justifyContent: "space-between",
      backgroundColor: {
        hover: background.surface.page.hover,
      },
      minW: "150px",
    },
    popoverOptionSelectedStyles: {
      ml: "auto",
      backgroundColor: {
        _: background.surface.page.default,
        hover: background.surface.page.hover,
      },
    },
  };
};
