import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { TableHeader, TableRow } from "./table";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const UsageAndAwarenessTable = () => {
  const sectionName = "usageAndAwarenessTable";
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);
  const { spacing } = useThemeTokens();
  const experts = usePrimersStore(({ experts }) => experts);
  const usageAndAwareness = useCustomerPrimersStore(({ usageAndAwareness }) => usageAndAwareness);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const tableCellsConfig = "300px repeat(5, 1fr)";

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedCustomer !== null) {
      setSelectedCustomer(null);
    }
  }, [expertsMentionedSection, selectedCustomer]);

  const selectCustomerSpeaker = (speakerId: number) => {
    if (speakerId === selectedCustomer) {
      resetExpertsMentioned();
      setSelectedCustomer(null);
    } else {
      setExpertsMentionedSectionAndExpertsIds(sectionName, [speakerId]);
      setSelectedCustomer(speakerId);
    }
  };

  return (
    <x.div mt={spacing.inner.base06}>
      <TableHeader cellsWidth={tableCellsConfig} />
      {experts.map((expert, index) => {
        const customer = usageAndAwareness.customers.find((customer) => customer.speaker === expert.speakerId);

        return (
          <TableRow
            key={`${expert.company}-${index}`}
            cellsWidth={tableCellsConfig}
            expert={expert}
            customerEntry={
              customer ?? {
                speaker: expert.id,
                uses: [],
                churned: [],
                evaluated: [],
                aware: [],
                unaware: [],
              }
            }
            isGrayScaled={selectedCustomer !== null && selectedCustomer !== expert.speakerId}
            setSelectedCustomer={selectCustomerSpeaker}
          />
        );
      })}
    </x.div>
  );
};

export default UsageAndAwarenessTable;
