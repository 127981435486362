import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Divider, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AlphaNowContentType, AlphaNowProductType, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";

import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";
import { useMarketPrimersStore } from "../state/store";
import { AlphaNowPurchasableHeader, AlphaNowSpinner, WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import { ICON_BUTTON_DEVICE_PROPS } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions";
import CopyLinkButton from "components/CopyLinkButton";
import BookmarkButton from "components/BookmarkButton";
import { formatDate } from "utils/dates";
import { PrimerStatus } from "pages/AlphaNowPage/primers/CompanyPrimer/CompanyPrimerContentPageVars";
import { errorLoadingResearch, unavailableResearch } from "content/AlphaNow";
import { ErrorMessage } from "pages/AlphaNowPage/components";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import useContentApi from "components/BookmarkButton/useContentApi";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { usePrimersStore } from "../../state/store";

const PrePaywallPage = ({
  isShrinkedPaywallHeader,
  id,
  contentType,
  companyIds,
  productType,
  onReturnToMobileSearch,
  onContentErrorChanged,
  onPurchasedContentChanged,
  status,
}: {
  isShrinkedPaywallHeader: boolean;
  id: string;
  contentType: AlphaNowContentType;
  companyIds: number[];
  productType: AlphaNowSRMProductType;
  onReturnToMobileSearch: () => void;
  onContentErrorChanged: () => void;
  onPurchasedContentChanged: () => void;
  status: string;
}) => {
  const {
    color: { text },
    shape,
    spacing: { inner },
  } = useThemeTokens();
  const [primerStatus, setPrimerStatus] = useState(PrimerStatus.idle);
  const { overview } = useMarketPrimersStore();
  const { lastUpdatedAt, purchaseProperties } = usePrimersStore();

  // Transition states
  const [maxHeight, setMaxHeight] = useState(isShrinkedPaywallHeader ? "0px" : "1000px");
  const [opacity, setOpacity] = useState(isShrinkedPaywallHeader ? 0 : 1);

  const { isMobile } = useCheckScreen();
  const { isBookmarked, onToggleBookmark } = useContentApi(id, AlphaNowProductType.marketPrimer);

  const requestPrimerInfoList = {
    title: "What is a Market Primer?",
    listItems: [
      "Market Primers are expert-led market overviews that support the first week of desktop research with trusted expert perspectives",
      "Our internal research team engage the best-in-class experts, including competitors, industry authorities and customers that operate in the market",
      "Click on a sentence to see the contributing expert(s) and request a call",
    ],
  };

  const { title, listItems } = requestPrimerInfoList;
  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;

  useEffect(() => {
    if (!isShrinkedPaywallHeader) {
      setTimeout(() => {
        setMaxHeight("1000px");
        setOpacity(1);
      }, 10); // Small delay to trigger the transition
    } else {
      setMaxHeight("0px");
      setOpacity(0);
    }
  }, [isShrinkedPaywallHeader]); // eslint-disable-line react-hooks/exhaustive-deps

  if (primerStatus === PrimerStatus.loading) {
    return (
      <x.div mt={inner.base06}>
        <AlphaNowSpinner />
      </x.div>
    );
  }
  if (primerStatus === PrimerStatus.error) {
    return (
      <div>
        <ErrorMessage header={errorLoadingResearch} body={unavailableResearch} />
      </div>
    );
  }

  return (
    <x.div data-testid="paywall-page" h="100%" display="flex" flexDirection="column">
      <x.div py={inner.base06} px={inner.base08}>
        <x.div
          data-testid="market-insights-header-paywall-bar"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <x.div display="flex" alignItems="center" gap={inner.base02}>
            <Typography variant="body-em" style={{ textTransform: "none" }}>
              {overview.marketPrimerTitle.value
                ? `${overview.marketPrimerTitle.value} | Market Primer`
                : "Market Primer"}
            </Typography>
            {Boolean(lastUpdatedAt) && (
              <Typography color={text.secondary} variant="body">{`Last updated ${formatDate(
                lastUpdatedAt
              )} `}</Typography>
            )}
          </x.div>
          <x.div
            data-testid="market-insights-header-buttons-container"
            display="flex"
            alignItems="center"
            gap={inner.base02}
          >
            {!isMobile && (
              <WatchlistQuickAddButton cdsAlphaCompanyIds={companyIds} productType={productType} contentId={id} />
            )}
            <CopyLinkButton contentId={id} contentType={AlphaNowProductType.marketPrimer} {...iconButtonProps} />
            <BookmarkButton
              permissions={[MANAGE_CONTENT_PERMISSION]}
              isBookmarked={isBookmarked}
              onToggleBookmark={onToggleBookmark}
              {...iconButtonProps}
            />
          </x.div>
        </x.div>

        <x.div
          display="flex"
          flexDirection="column"
          maxH={maxHeight}
          opacity={opacity}
          overflow="hidden"
          style={{
            transition: "max-height 700ms ease-in-out, opacity 700ms ease-in-out",
          }}
        >
          {isMobile && (
            <x.div pl={inner.base06}>
              <ReturnButton onReturn={onReturnToMobileSearch} style={{ ml: `-${inner.base03}` }} />
            </x.div>
          )}
          <x.div key={title} pb={inner.base02} pt={inner.base06}>
            <Typography variant="body-em" paddingBottom={inner.base02} color={text.secondary}>
              {title}
            </Typography>
            <x.ul listStyleType="initial">
              {listItems.map((item) => {
                return (
                  <x.ul listStyleType="initial" pl={inner.base06}>
                    <li key={item}>
                      <Typography>{item}</Typography>
                    </li>
                  </x.ul>
                );
              })}
            </x.ul>
          </x.div>
          <Link href={freePrimers.marketPrimer} size="medium" target="_blank">
            <Typography variant="body">View a free Market Primer</Typography>
          </Link>
        </x.div>
      </x.div>
      <x.div w="100%" px={inner.base08} h={shape.border.radius[2]}>
        <Divider />
      </x.div>
      <AlphaNowPurchasableHeader
        content={{
          id,
          contentType,
          productType: AlphaNowProductType.marketPrimer,
          status,
        }}
        contentTitle={overview.marketPrimerTitle.value ?? ""}
        price={purchaseProperties.price}
        contentApprovalStatus={purchaseProperties.approvalStatus}
        contentPurchaseStatus={purchaseProperties.purchaseStatus}
        onError={onContentErrorChanged}
        onPurchaseSuccess={onPurchasedContentChanged}
        setPrimerStatus={setPrimerStatus}
      />
      <Divider />
    </x.div>
  );
};

export default PrePaywallPage;
