import { Typography, Icon } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";

export const ProjectTitle = styled(Typography).attrs({
  variant: "body-em",
  component: "div",
  color: "inverse",
})`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 2px;
  margin-left: ${th.space("inner-base02")};
`;

export const ProjectIcon = styled(Icon).attrs({ color: "inverse" })``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`;
