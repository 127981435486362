import React, { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Typography, Icon, Popover } from "@alphasights/alphadesign-components";
import {
  SECTION_DESCRIPTIONS,
  SECTION_HELP_MESSAGES,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components/Section";
import { HeaderContainer } from "./styled";
import { Info } from "@alphasights/alphadesign-icons";

const DataTestIds = {
  SectionHeader: "section-header",
  SectionTitle: "section-title",
  SectionDescription: "section-description",
};

type SectionProps = {
  title: string;
  companyName: string;
  noMarginBottom?: boolean;

  id?: string;
  children?: ReactElement;
};

const SectionHeader = ({ title, companyName, children, noMarginBottom = false }: SectionProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<Element | undefined>();
  const isPopoverOpen = Boolean(popoverAnchorEl);
  const { color, spacing } = useThemeTokens();

  const normalisedTitle = title.toString().toLowerCase().replace(/\s/g, "-");
  const description = SECTION_DESCRIPTIONS[title](companyName);
  const sectionHelpMessage = SECTION_HELP_MESSAGES[title];

  return (
    <HeaderContainer data-testid={`${DataTestIds.SectionHeader}-${normalisedTitle}`} mb={noMarginBottom ? "0" : ""}>
      <div>
        <x.div display="flex" alignItems="center" columnGap={spacing.inner.base}>
          <Typography variant="body-large-em" data-testid={`${DataTestIds.SectionTitle}-${normalisedTitle}`}>
            {title}
          </Typography>
          {sectionHelpMessage && sectionHelpMessage.length > 0 && (
            <>
              <x.span onMouseEnter={(event) => setPopoverAnchorEl(event.currentTarget)}>
                <Icon size="small" color={color.icon.disabled}>
                  <Info />
                </Icon>
              </x.span>
              <Popover
                closeOnMouseLeave={true}
                anchorEl={popoverAnchorEl}
                open={isPopoverOpen}
                onClose={() => setPopoverAnchorEl(undefined)}
                leaveDelay={500}
              >
                <Typography
                  variant="body-small"
                  padding={spacing.inner.base02}
                  color={color.text.secondary}
                  maxW="200px"
                >
                  {sectionHelpMessage}
                </Typography>
              </Popover>
            </>
          )}
        </x.div>
        <x.div mt={spacing.inner.base} data-testid={`${DataTestIds.SectionDescription}-${normalisedTitle}`}>
          <Typography variant="body-small" color={color.text.secondary} component="span">
            <ReactMarkdown source={description} />
          </Typography>
        </x.div>
      </div>
      {children}
    </HeaderContainer>
  );
};

export { SectionHeader as default, DataTestIds };
