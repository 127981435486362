import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMessengerSidebarStyles = ({
  isSidebarCollapsed = false,
  isOverflow = false,
  newNavigationEnabled = false,
} = {}) => {
  const {
    spacing: { inner },
    color: { background, border },
  } = useThemeTokens();

  const container = {
    w: isSidebarCollapsed ? "80px" : "470px",
    transition: "all 0.5s ease 0s",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
  };

  const sidebarExpandedContent = {
    transition: "all 0.2s ease-in 0s",
    opacity: isSidebarCollapsed ? 0 : 1,
    pointerEvents: isSidebarCollapsed ? "none" : undefined,
    background: background.surface.recessed,
    display: "flex",
    flexDirection: "column",
    minH: 0,
    flex: 1,
  };

  const messagesListViewPort = {
    overflowY: isSidebarCollapsed ? "hidden" : "auto",
    pl: inner.base06,
    pr: isOverflow ? inner.base04 : inner.base06,
    py: newNavigationEnabled ? inner.base06 : inner.base03,
    flexDirection: "column",
    display: "flex",
    flex: 1,
  };

  const topBar = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: inner.base06,
    ...(newNavigationEnabled
      ? {
          py: inner.base04,
          flexDirection: "row-reverse",
        }
      : {
          pt: inner.base08,
          pb: isOverflow ? inner.base03 : 0,
          borderBottomWidth: isOverflow ? 1 : 0,
          borderColor: border.divider,
          minH: isOverflow ? "85px" : undefined,
        }),
  };

  const messagesList = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base03,
    flexShrink: 0,
    flex: 1,
  };

  const sidebarCollapsedContent = {
    transition: "all 0.2s ease-in 0s",
    opacity: isSidebarCollapsed ? 1 : 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    margin: "28px",
    position: "absolute",
  };

  const noMessagesPanel = {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
  };

  const filterToggleButtons = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
    flexWrap: "wrap",
  };

  return {
    container,
    messagesList,
    messagesListViewPort,
    sidebarExpandedContent,
    sidebarCollapsedContent,
    topBar,
    noMessagesPanel,
    filterToggleButtons,
  };
};
