import pluralize from "pluralize";

import { AlphaNowProductType, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import { TRIGGER_TYPE, WatchlistDataProps, WatchlistTriggerProps } from "hooks/useWatchlist";
import { difference } from "lodash";
import { RequestType, ToastText } from "pages/AlphaNowPage/components/WatchlistQuickAddButton/consts";

const hasSelectedWatchlists = (availableWatchlists: WatchlistDataProps[], companyIds: number[]) =>
  availableWatchlists.some((watchlist) =>
    watchlist.triggers.some((trigger) => trigger.cdsAlphaCompanyId && companyIds.includes(trigger.cdsAlphaCompanyId))
  );

const hasCompanyTriggers = (triggers: WatchlistTriggerProps[], cdsAlphaCompanyIds: number[]) => {
  const triggerCdsAlphaCompanyIds = triggers.map((trigger) => trigger.cdsAlphaCompanyId).filter(Boolean);
  if (!cdsAlphaCompanyIds?.length || !triggerCdsAlphaCompanyIds?.length) {
    return false;
  }
  return difference(cdsAlphaCompanyIds, triggerCdsAlphaCompanyIds).length === 0;
};

const createCompanyTriggers = (cdsAlphaCompanyIds: number[]) =>
  cdsAlphaCompanyIds.map((cdsAlphaCompanyId) => ({
    type: TRIGGER_TYPE.company,
    cdsAlphaCompanyId,
  }));

const getOperationText = (requestType: RequestType) =>
  requestType === RequestType.add ? ToastText.addedTo : ToastText.removedFrom;

const getToastMessage = (
  watchlistName = "",
  triggers: WatchlistTriggerProps[],
  requestType = RequestType.add,
  isSuccess = true,
  productType?: AlphaNowProductType | AlphaNowSRMProductType,
  companyIds: number[] = []
) => {
  let message;
  const operationText = getOperationText(requestType);
  const watchlistNameText = watchlistName ?? ToastText.newWatchlist;

  if (isSuccess) {
    const hasMultipleCompanies = triggers.length > 1;
    if (hasMultipleCompanies) {
      if (requestType === RequestType.add) {
        let addedObjects;
        switch (productType) {
          case AlphaNowProductType.marketPrimer:
            addedObjects = ToastText.leadingCompetitors;
            break;
          case AlphaNowProductType.customerPrimer:
            addedObjects = ToastText.featuredVendors;
            break;
          default:
            addedObjects = ToastText.relevantCompanies;
        }
        message = `${triggers.length} ${addedObjects} ${operationText} ${watchlistNameText}.`;
      } else {
        message = `${triggers.length} ${ToastText.companies} ${operationText} ${watchlistNameText}.`;
      }
    } else {
      message = `${triggers[0].companyInfo!.name} ${operationText} ${watchlistNameText}.`;
    }
  } else {
    message = `${pluralize(ToastText.company, companyIds.length)} ${
      ToastText.errorMessage
    } ${operationText} ${watchlistNameText}.`;
  }

  return message;
};

export { hasCompanyTriggers, createCompanyTriggers, getToastMessage, hasSelectedWatchlists };
