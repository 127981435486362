import { useIdleTimer } from "react-idle-timer";

export const useAudioPlayerIdleTimer = (setPlayerVisible) => {
  return useIdleTimer({
    onIdle: () => setPlayerVisible(false),
    onActive: () => setPlayerVisible(true),
    timeout: 3000,
    throttle: 500,
  });
};
