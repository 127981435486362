import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useTextAreaStyles = () => {
  const {
    color: { border, text },
    spacing: { inner },
  } = useThemeTokens();

  const textArea = {
    w: "100%",
    px: inner.base04,
    py: inner.base03,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: {
      _: border.divider,
      focusVisible: border.selected,
    },
    borderRadius: "200",
    outline: "none",
    color: { _: text.strong._, placeholder: text.assistive },
    overflowY: "auto",
    resize: "none",
  };

  const textAreaLabel = {
    color: text.secondary,
  };

  const textAreaContainer = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    flexGrow: 1,
  };

  return {
    textArea,
    textAreaLabel,
    textAreaContainer,
  };
};
