import { CONTENT_TYPE_DISPLAY_NAME, CONTENT_TYPE } from "@alphasights/client-portal-shared";

const CONTENT_TYPE_TABS = [
  {
    type: CONTENT_TYPE.alphaview,
    name: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.alphaview],
  },
  {
    type: CONTENT_TYPE.pcc,
    name: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.pcc],
  },
  {
    type: CONTENT_TYPE.privateEnterprise,
    name: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.privateEnterprise],
  },
];

const THEME_NAV_WIDTH = "200px";

const OVERVIEW = "Overview";
const SEE_MORE = "...See more";
const SHOW_LESS = "Show less";
const RELEVANT_COLLEAGUES = "RELEVANT COLLEAGUES";

const expertCalls = (numCallsPerformed: number) => `**${numCallsPerformed}** expert calls`;

enum PromiseStatus {
  Fulfilled = "fulfilled",
  Rejected = "rejected",
}

export {
  CONTENT_TYPE_TABS,
  THEME_NAV_WIDTH,
  OVERVIEW,
  SEE_MORE,
  SHOW_LESS,
  RELEVANT_COLLEAGUES,
  expertCalls,
  PromiseStatus,
};
