import React, { useRef, useState } from "react";
import { Popover } from "../../components/Popover";
import useOnClickOutside from "../../hooks/useOnClickHooks";
import { useNotifications } from "@alphasights/client-portal-shared";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

export const CalendarViewPreferences = ({ promoteCalendar, promoteTimezone }) => {
  const { color, spacing } = useThemeTokens();
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  const { showSuccessBanner } = useNotifications();

  useOnClickOutside(ref, () => {
    setIsActive(false);
  });

  const onCalendarDefault = () => {
    promoteCalendar();
    showSuccessBanner("Preferences saved");
    setIsActive(false);
  };

  const onTimezoneDefault = () => {
    promoteTimezone();
    showSuccessBanner("Preferences saved");
    setIsActive(false);
  };

  return (
    <Popover
      body={
        <div ref={ref}>
          <Typography
            variant="body-em"
            className="aui-px-6 aui-py-1 hover:aui-text-primary-2 aui-cursor-pointer"
            onClick={onCalendarDefault}
            data-testid="calendar-default"
          >
            Save default calendar layout
          </Typography>
          <Typography
            variant="body-em"
            className="aui-px-6 aui-py-1 hover:aui-text-primary-2 aui-cursor-pointer"
            onClick={onTimezoneDefault}
            data-testid="timezone-default"
          >
            Save default timezone
          </Typography>
        </div>
      }
      isActive={isActive}
      placement="bottom-end"
      disableHover
      disableArrow
      borderless
      className="aui-border aui-border-solid aui-border-grey-2 aui-rounded-lg"
    >
      <x.div mr={spacing.layout.base}>
        <Icon size={"medium"} color={color.icon.secondary} onClick={() => setIsActive(!isActive)}>
          <MoreVert />
        </Icon>
      </x.div>
    </Popover>
  );
};
