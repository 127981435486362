import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, Icon } from "@alphasights/alphadesign-components";
import { Copy, PlayCircle } from "@alphasights/alphadesign-icons";
import { useComplianceResourcesStyles } from "./ComplianceResources.styles";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const ResourceCard = (props) => {
  switch (props.type) {
    case "video":
      return <VideoCard {...props} />;
    case "file":
      return <FileCard {...props} />;
    default:
      return <DocumentCard {...props} />;
  }
};

export const ComplianceResources = ({ openComplianceModal, showComplianceVideoCallback }) => {
  const { wrapper } = useComplianceResourcesStyles();
  const { complianceResources: resources } = useCurrentProjectContext();

  return resources && resources.length > 0 ? (
    <x.div {...wrapper}>
      {resources.map((resource) => (
        <x.div key={resource.title}>
          <ResourceCard
            key={resource.title}
            {...resource}
            openComplianceModal={openComplianceModal}
            showComplianceVideoCallback={showComplianceVideoCallback}
          />
        </x.div>
      ))}
    </x.div>
  ) : null;
};

const ResourceInfo = ({ title, description }) => {
  const { info, infoText } = useComplianceResourcesStyles();

  return (
    <x.div {...info}>
      <Typography variant="body-em">{title}</Typography>
      <Typography {...infoText}>{description}</Typography>
    </x.div>
  );
};

const Card = (props) => {
  const { title, thumbUrl } = props;

  const { cardImageWrapper, cardImage, cardImageIcon } = useComplianceResourcesStyles();

  return (
    <>
      <x.div {...cardImageWrapper}>
        <x.img alt={title} src={thumbUrl} {...cardImage} />
        <x.div {...cardImageIcon}>{props.icon}</x.div>
      </x.div>
      <ResourceInfo {...props} />
    </>
  );
};

const FileCard = (props) => {
  const { file } = props;

  const { card } = useComplianceResourcesStyles();

  return (
    <x.a href={file} {...card} textDecoration="none">
      <Card {...props} icon={<Copy />} />
    </x.a>
  );
};

const DocumentCard = (props) => {
  const { openComplianceModal } = props;

  const handleDocumentClick = () => {
    openComplianceModal({ title: props.title, description: props.description });
  };

  const { card } = useComplianceResourcesStyles();

  return (
    <x.div {...card} onClick={handleDocumentClick}>
      <Card
        {...props}
        icon={
          <Icon size="large">
            <Copy />
          </Icon>
        }
      />
    </x.div>
  );
};

const VideoCard = (props) => {
  const { videoId, showComplianceVideoCallback } = props;

  const { card } = useComplianceResourcesStyles();

  return (
    <x.div onClick={() => showComplianceVideoCallback({ videoId })}>
      <x.div {...card}>
        <Card
          {...props}
          icon={
            <Icon size="large">
              <PlayCircle />
            </Icon>
          }
        />
      </x.div>
    </x.div>
  );
};
