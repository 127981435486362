import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@alphasights/alphadesign-components";
import SpeakerTitles from "components/SpeakerTitles";
import { CONTENT_TYPE_DISPLAY_NAME, CONTENT_TYPE } from "@alphasights/client-portal-shared";

const CardTitle = ({
  speakers = [],
  typographyVariant = "body",
  isAlphaView = false,
  isCompanyPrimer = false,
  isMarketPrimer = false,
  isCustomerPrimer = false,
  title,
  isRequestPrimer = false,
  ...props
}) => {
  const manySpeakers = speakers.length > 1;

  const TitleContent = ({ id }) => {
    let displayTitle;

    if ((isAlphaView && manySpeakers) || isRequestPrimer) {
      displayTitle = title;
    } else if (isCompanyPrimer) {
      displayTitle = CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer];
    } else if (isMarketPrimer) {
      displayTitle = `${CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer]}: ${title}`;
    } else if (isCustomerPrimer) {
      displayTitle = `${CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer]}: ${title}`;
    }

    if (displayTitle) {
      return (
        <Typography component="span" variant={`${typographyVariant}-em`} id={id}>
          {displayTitle}
        </Typography>
      );
    }

    return <SpeakerTitles speakers={speakers} typographyVariant={typographyVariant} id={id} />;
  };

  return <TitleContent {...props?.style} id="content-card-title" />;
};

CardTitle.propTypes = {
  isRequestPrimer: PropTypes.bool,
  isAlphaView: PropTypes.bool,
  isCompanyPrimer: PropTypes.bool,
  isMarketPrimer: PropTypes.bool,
  isCustomerPrimer: PropTypes.bool,
  title: PropTypes.string,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      isModerator: PropTypes.bool,
    })
  ),
  typographyVariant: PropTypes.string,
};

export { CardTitle as default };
