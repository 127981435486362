export const fromString = (string) => {
  return string
    .split("|")
    .map((pair) => pair.split("="))
    .filter(([key, value]) => !!value)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value.split(",").map(decodeURIComponent),
      }),
      {}
    );
};

export const toString = (filters) => {
  return Object.entries(filters)
    .filter(([key, value]) => (Array.isArray(value) ? value.length > 0 : !!value))
    .map(([key, value]) =>
      Array.isArray(value) ? `${key}=${value.map(encodeURIComponent).join(",")}` : `${key}=${encodeURIComponent(value)}`
    )
    .join("|");
};

export const getFiltersFromURL = (url) => {
  const filterString = new URLSearchParams(url.search).get("f");
  const filterHash = fromString(filterString || "");
  return Object.keys(filterHash).length === 0 ? null : filterHash;
};

export const setFiltersIntoURL = (filters, url) => {
  const searchParams = new URLSearchParams(url.search);
  searchParams.set("f", toString(filters));
  return `${url.pathname}?${searchParams}`;
};

export const getInteractionIdFromURL = (url) => {
  return [
    /\w+\/experts\/(?<route>list-view|table-view|calendar-view|messages-view|deliverables-view|survey-view|customer-view|comparison-view)\/(?<id>\w+)/,
    /\w+\/(?<route>calendar-view)\/(?<id>\w+)/,
    /\w+\/workstream\/\w+\/experts\/\w+\/(?<route>list-view|table-view|calendar-view|messages-view|deliverables-view|survey-view|customer-view|comparison-view)\/(?<id>\w+)/,
  ]
    .map((regex) => regex.exec(url.pathname)?.groups?.id)
    .find(Boolean);
};
