import { useTheme } from "@alphasights/alphadesign-components";

export const useRecordingPlayerStyles = (variant) => {
  const {
    tokens: { color, spacing },
  } = useTheme();

  const headerStyles = {
    display: "flex",
    ...(variant === "new-flyout" && {
      alignItems: "center",
      justifyContent: "space-between",
    }),
    ...(variant === "calendar-popup" && {
      flexDirection: "column",
    }),
  };

  const titleStyles = {
    display: "flex",
    alignItems: "flex-start",
    ...(variant === "new-flyout" && {
      gap: spacing.inner.base02,
    }),
  };

  const iconStyles = {
    width: spacing.inner.base05,
    height: spacing.inner.base05,
    ...(variant === "new-flyout" && {
      color: color.icon.secondary,
    }),
  };

  const controlStyles = {
    display: "flex",
    alignItems: "center",
    gap: spacing.inner.base06,
    ...(variant === "new-flyout" && {
      gap: spacing.inner.base02,
    }),
    ...(variant === "calendar-popup" && {
      ml: spacing.inner.base05,
      pl: spacing.inner.base04,
      mt: spacing.inner.base02,
      justifyContent: "center",
    }),
  };

  const progressBarStyles = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.inner.base04,
    ...(variant === "calendar-popup" && {
      ml: spacing.inner.base05,
      pl: spacing.inner.base04,
    }),
    ...(variant === "new-flyout" && {
      ml: spacing.inner.base08,
    }),
  };

  const durationsStyles = {
    display: "flex",
    justifyContent: "space-between",
  };

  const wrapperStyles = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.inner.base04,
    ...(variant === "calendar-popup" && {
      mt: spacing.inner.base04,
    }),
  };

  const lightGreyTextStyles = {
    color: color.text.secondary,
  };

  const darkGreyTextStyles = {
    color: color.text.strong._,
  };

  const controlButtonStyles = {
    borderColor: {
      _: color.border.neutral.default,
      hover: color.border.selected,
    },
    color: {
      _: color.icon.secondary,
      hover: color.icon.secondary,
    },

    borderRadius: spacing.inner.base,
    variant: "outline",
  };

  const playSpeedButtonStyles = {
    ...controlButtonStyles,
    ...(variant === "new-flyout" && {
      borderColor: {
        _: color.border.neutral.default,
        hover: color.border.selected,
        focus: color.border.selected,
      },
    }),
  };

  const descriptionStyles = {
    ...(variant === "calendar-popup" && {
      pl: spacing.inner.base04,
    }),
  };

  const popoverStyles = {
    p: 0,
  };

  const playbackSpeedOptionsStyles = {
    gap: spacing.inner.base,
    display: "flex",
    flexDirection: "column",
    p: spacing.inner.base02,
  };

  const playbackSpeedStyles = {
    px: spacing.inner.base04,
    justifyContent: "space-between",
    color: color.text.strong._,
    backgroundColor: {
      hover: color.background.surface.page.hover,
    },
    minW: "110px",
  };

  const selectedPlaybackSpeedStyles = {
    backgroundColor: {
      _: color.background.surface.page.hover,
      hover: color.background.surface.page.hover,
    },
  };

  return {
    headerStyles,
    titleStyles,
    iconStyles,
    controlStyles,
    progressBarStyles,
    durationsStyles,
    lightGreyTextStyles,
    darkGreyTextStyles,
    controlButtonStyles,
    playSpeedButtonStyles,
    wrapperStyles,
    descriptionStyles,
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
  };
};
