import React, { ForwardedRef, forwardRef } from "react";
import { x } from "@xstyled/styled-components";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { Mail, Request } from "@alphasights/alphadesign-icons";
import { useMobileExpertsFlyoutStyles } from "./MobileExpertsFlyout.styles";
import { InstantScheduleButton, RequestButton, ScheduleButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { interactionAboutToStart } from "pages/UpcomingCallsPage/utils";
import { JoinCall } from "pages/UpcomingCallsPage/JoinCall";
import { useCallAvailability } from "providers/TwilioProvider";
import { useMessengerContext } from "providers/MessengerProvider";
import { MessageType } from "types";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";

const WebConnectCTAs = ({ interaction }: { interaction: Interaction }) => {
  const { callAvailability } = useCallAvailability({
    interactionId: interaction.id,
    projectToken: interaction.projectToken,
    scheduledCallTime: interaction.scheduledCallTime,
  });

  return (
    <JoinCall
      // @ts-ignore
      interaction={interaction}
      thick
      callAvailability={callAvailability}
      origin={HitOrigin.flyout}
    />
  );
};

export const FlyoutMobileCTAs = forwardRef(
  (
    {
      interaction,
      onRequest,
      onRequestFollowUp,
      onSchedule,
      hasClientPortalMessages,
      isActiveProject,
      pendingRequest,
      setNewMessage,
      backgroundSyncRunning,
    }: {
      interaction: Interaction;
      onRequest: (id: string, origin: string) => void;
      onSchedule: (interaction: Interaction) => void;
      onRequestFollowUp: ({ id, origin }: { id: string; origin: string }) => void;
      hasClientPortalMessages: boolean;
      isActiveProject: boolean;
      pendingRequest: any;
      setNewMessage: (newMessage: boolean) => void;
      backgroundSyncRunning: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const callAboutToBegin = interactionAboutToStart({ scheduledCallTime: interaction.scheduledCallTime });
    const { ctasBar, ctasBarButton } = useMobileExpertsFlyoutStyles({});
    const { onSendNewMessage } = useMessengerContext();
    const user = useCurrentUser();
    const { openLoginModal } = useClientPortalOperations();

    const onClickMessage = () => {
      if (user) {
        setNewMessage(true);
        onSendNewMessage(interaction.noWrittenWork ? MessageType.CallGuide : MessageType.WorkRequest);
      } else {
        openLoginModal({ allowAnonymousContinue: true });
      }
    };

    const possibleCtas = [
      {
        isActive: hasClientPortalMessages && isActiveProject,
        element: (
          <Button variant="outline" startIcon={<Mail />} onClick={onClickMessage} {...ctasBarButton}>
            Message
          </Button>
        ),
      },
      {
        isActive: interaction.showRequest,
        element: (
          <RequestButton
            disabled={backgroundSyncRunning}
            onClick={() => onRequest(interaction.id, "mobile-flyout")}
            interaction={interaction}
            loading={interaction.runningAction === "request"}
            {...ctasBarButton}
          />
        ),
      },
      {
        isActive: interaction.showInstantSchedule,
        element: (
          <InstantScheduleButton
            disabled={backgroundSyncRunning}
            onClick={() => onRequest(interaction.id, "mobile-flyout")}
            interaction={interaction}
            loading={interaction.runningAction === "request"}
            {...ctasBarButton}
          />
        ),
      },
      {
        isActive: interaction.showSchedule,
        element: (
          <ScheduleButton
            disabled={backgroundSyncRunning}
            onClick={() => onSchedule(interaction)}
            interaction={interaction}
            loading={interaction.runningAction === "schedule"}
            {...ctasBarButton}
          />
        ),
      },
      {
        isActive: !interaction.followUpId && !pendingRequest && !["proposed", "requested"].includes(interaction.state),
        element: (
          <Button
            variant={interaction.state === "completed" ? "primary" : "secondary"}
            startIcon={<Request />}
            onClick={() => onRequestFollowUp({ id: interaction.id, origin: "mobile-flyout" })}
            data-testid="request-follow-up-button"
            loading={interaction.runningAction === "followUp"}
            {...ctasBarButton}
          >
            <Typography variant="body-em" whiteSpace="nowrap">
              Follow-up
            </Typography>
          </Button>
        ),
      },
    ];

    if (possibleCtas.every((cta) => !cta.isActive) && !callAboutToBegin) {
      return null;
    }

    return (
      <x.div {...ctasBar} data-testid="mobile-ctas-bar" ref={ref}>
        {callAboutToBegin ? (
          <WebConnectCTAs interaction={interaction} />
        ) : (
          possibleCtas.map((cta, index) => cta.isActive && cta.element)
        )}
      </x.div>
    );
  }
);
