const SORT = "Sort";

const SORT_BY = {
  relevance: "RELEVANCE",
  callDate: "SCHEDULED_TIME",
};

const SORT_DIRECTION = {
  asc: "asc",
  desc: "desc",
};

const SORT_KEYS = {
  callDateAsc: "callDateAsc",
  callDateDesc: "callDateDesc",
  relevance: "relevance",
};

const SORT_LABELS = {
  [SORT_KEYS.relevance]: "Relevance",
  [SORT_KEYS.callDateDesc]: "Date (newest first)",
  [SORT_KEYS.callDateAsc]: "Date (oldest first)",
};

const SORT_OPTIONS = {
  [SORT_KEYS.callDateAsc]: {
    sortBy: SORT_BY.callDate,
    direction: SORT_DIRECTION.asc,
  },
  [SORT_KEYS.callDateDesc]: {
    sortBy: SORT_BY.callDate,
    direction: SORT_DIRECTION.desc,
  },
  [SORT_KEYS.relevance]: {
    sortBy: SORT_BY.relevance,
    direction: SORT_DIRECTION.desc,
  },
};

export { SORT, SORT_BY, SORT_DIRECTION, SORT_KEYS, SORT_LABELS, SORT_OPTIONS };
