import { useEffect, useMemo, useState } from "react";
import { fetch } from "./useApi";

export const useClientDialInNumbers = ({ interaction }) => {
  const [dialInNumbers, setDialInNumbers] = useState([]);

  useEffect(() => {
    if (interaction.dialInNumbers?.length > 0) {
      setDialInNumbers(interaction.dialInNumbers);
    } else {
      fetch({
        url: `/api/internal/projects/${interaction.projectToken}/calls/${interaction.id}/dial-numbers`,
        method: "GET",
        skipAlert: true,
      })
        .then((response) => response.json())
        .then(setDialInNumbers);
    }
  }, [interaction]);

  return useMemo(() => {
    return {
      dialInNumbers,
    };
  }, [dialInNumbers]);
};
