import { Alert, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const StyledAlert = styled(Alert)`
  width: 100%;
`;

export const useMessengerReplyFieldStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
  };

  const buttonsContainer = {
    display: "flex",
    gap: inner.base02,
    marginLeft: "auto",
  };

  return {
    container,
    buttonsContainer,
  };
};
