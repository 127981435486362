import React from "react";
import { Modal } from "../Modal";
import { Textile } from "../Textile";

export const ComplianceResourceModal = ({ resource, ...props }) => (
  <Modal
    {...props}
    isOpen={!!resource}
    className="aui-w-auto md:aui-w-6/12"
    title={`${resource ? resource.title : null}`}
  >
    <Textile className="aui-p-6">{`${resource ? resource.description : null}`}</Textile>
  </Modal>
);
