import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

import { useAppSearchContext } from "providers/AppSearchProvider";
import ConversationFragment from "./ConversationFragment/ConversationFragment";
import { AlphaNowSpinner } from "pages/AlphaNowPage/components";
import { SPEAKER, SPEAKER_ENGLISH_TRANSLATIONS, desktopContentContainerWidth } from "constants/AlphaNow";

const Conversation = ({ addRefToMention, conversation, speakers, contentType }) => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();
  // do not change speakerMap default value to {} as it will introduce a bug
  // where speaker company & title will briefly be displayed as undefined
  const [speakerMap, setSpeakerMap] = useState();
  const fragmentRefs = useRef({});
  const {
    query: { fragmentIds },
  } = useAppSearchContext();
  const [isFragmentActive, setFragmentActive] = useState(false);

  useEffect(() => {
    if (isFragmentActive && fragmentIds.length > 0) {
      const timer = setTimeout(() => {
        setFragmentActive(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isFragmentActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fragmentIds.length > 0 && setFragmentActive(true);
  }, [fragmentIds]);

  useEffect(() => {
    const speakersInfo = speakers.reduce((speakerMap, speaker) => {
      const { company, jobTitle, transcriptParticipantRole, isModerator } = speaker;
      speakerMap[speaker.transcriptParticipantRole] = {
        company,
        jobTitle,
        transcriptParticipantRole,
        isModerator,
      };
      return speakerMap;
    }, {});
    setSpeakerMap(speakersInfo);
  }, [conversation, speakers]);

  useEffect(() => {
    if (fragmentIds.length > 0) {
      // We are only navigating to the first fragment on the list for now
      const refId = Number(fragmentIds[0]);
      const componentRef = fragmentRefs.current[refId];
      if (componentRef) {
        componentRef.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  if (!(conversation && speakers) || typeof speakerMap === "undefined") {
    return <AlphaNowSpinner />;
  }
  return (
    <x.div spaceY={inner.base04} pb={layout.base03} pl={{ xs: layout.base03, sm: 0 }} pr={{ xs: layout.base02, sm: 0 }}>
      {conversation.map((fragment) => {
        const { position, participant, speechContent, speakerDummyName, isSensitive } = fragment;
        const speakerInfo = {
          ...(speakerMap[participant] || speakerMap[SPEAKER_ENGLISH_TRANSLATIONS[participant]]),
          speakerDummyName,
        };
        if (speakerInfo.transcriptParticipantRole === SPEAKER.Interviewer) {
          speakerInfo.transcriptParticipantRole = participant;
        }

        const isFragmentRelatedToQuestion = fragmentIds[0] === position;
        const isFragmentHighlighted = isFragmentActive && isFragmentRelatedToQuestion;

        return (
          <x.div
            m="0 auto"
            maxW={{ xs: "none", sm: desktopContentContainerWidth }}
            key={position}
            data-testid={`conv-fragment-${position}`}
            className={isSensitive ? "obfuscated-sensitive-data" : ""}
            ref={(ref) => (fragmentRefs.current[position] = ref)}
          >
            <ConversationFragment
              addRefToMention={addRefToMention}
              isFragmentHighlighted={isFragmentHighlighted}
              speakerInfo={speakerInfo}
              text={speechContent}
              contentType={contentType}
              isSensitive={isSensitive}
            />
          </x.div>
        );
      })}
    </x.div>
  );
};

Conversation.defaultProps = {
  conversation: [],
  speakers: [],
};

Conversation.propTypes = {
  addRefToMention: PropTypes.func.isRequired,
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      participant: PropTypes.string,
      speechContent: PropTypes.string,
      speakerDummyName: PropTypes.string,
    })
  ).isRequired,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      jobTitle: PropTypes.string,
      transcriptParticipantRole: PropTypes.string,
      isModerator: PropTypes.bool,
    })
  ).isRequired,
};

export default Conversation;
