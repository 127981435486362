import { areIntervalsOverlapping } from "date-fns";
import sortBy from "lodash/sortBy";
import min from "lodash/min";
import round from "lodash/round";
import groupBy from "lodash/groupBy";

/**
 * As greater the value, the event will be rendered more at right / top of others
 */
const renderPriority = (event) => {
  return event.original.renderPriority || Number.MIN_SAFE_INTEGER;
};

const putEventOnColumn = (columns, event) => {
  for (let lix = 0; lix < columns.length; lix++) {
    const column = columns[lix];

    const noOverlapping = column.every((columnEvent) => !areIntervalsOverlapping(columnEvent, event));

    if (noOverlapping) {
      column.push(event);
      return;
    }
  }

  columns.push([event]);
};

const renderDay = (events) => {
  const eventsByPrio = groupBy(events, renderPriority);
  const columns = Object.keys(eventsByPrio)
    .sort()
    .map((k) => eventsByPrio[k])
    .flatMap(columnsForPrio);

  return columns
    .map((column, lix) => {
      return column.map((event) => {
        const offset = min([20 * lix, (100 / columns.length) * lix]);

        const width = columns.length > 1 ? min([round(40 + 100 / columns.length, 5), 100 - offset]) : 100;

        return {
          event: event.original,
          style: {
            top: round(event.top, 5) + "%",
            height: round(event.height, 5) + "%",
            width: width + "%",
            xOffset: offset + "%",
          },
        };
      });
    })
    .flat();
};

const columnsForPrio = (events) => {
  const sorted = sortBy(events, (e) => e.start.getTime());
  const columns = [];

  sorted.forEach((event) => putEventOnColumn(columns, event));
  return columns;
};

const groupAllOverlappings = (events) => {
  const overlappingGroups = [];

  events.forEach((e) => {
    const ix = overlappingGroups.findIndex((group) => group.some((other) => areIntervalsOverlapping(other, e)));

    if (ix >= 0) {
      overlappingGroups[ix].push(e);
    } else {
      overlappingGroups.push([e]);
    }
  });

  return overlappingGroups;
};

export default function dayAlgorithm({ events, slotMetrics, accessors }) {
  const sorted = sortBy(
    events.map((e) => {
      const { startDate, endDate, top, height } = slotMetrics.getRange(accessors.start(e), accessors.end(e));

      return {
        start: startDate,
        end: endDate,
        top,
        height,
        original: e,
      };
    }),
    [(e) => e.start.getTime(), (e) => -(e.end.getTime() - e.start.getTime())]
  );

  const overlappingGroups = groupAllOverlappings(sorted);

  return overlappingGroups.map((group) => renderDay(group)).flat();
}
