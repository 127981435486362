import React, { useEffect, useState } from "react";
import { CidStatus } from "components/CidBadge/cid-status";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { RadioButton, RadioGroup, Typography } from "@alphasights/alphadesign-components";
import { MoreInfo } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useCidStatusRadioButtonsStyles } from "./CidStatusRadioButtons.styles";

export const CidStatusRadioInputGroup = ({
  job,
  userCidEnabled,
  setError,
  cidStatus,
  isJobsCidStatusesLoading,
  persistCidStatusChange,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const jobCidStatusToDisplayStatus = new Map([
    [CidStatus.CLEARED, CidStatus.CLEARED],
    [CidStatus.RESTRICTED, CidStatus.BLOCKED],
    [CidStatus.APPROVED, CidStatus.BLOCKED],
    [CidStatus.PENDING, ""],
  ]);

  const [persistCidStatusLoading, setPersistCidStatusLoading] = useState(false);
  const [companyCidStatus, setCompanyCidStatus] = useState(jobCidStatusToDisplayStatus.get(cidStatus));
  const { radioGroup, radioGroupDiv, radioButton, approvedLabel, cidControls } = useCidStatusRadioButtonsStyles();

  useEffect(() => {
    cidStatus && setCompanyCidStatus(jobCidStatusToDisplayStatus.get(cidStatus));
  }, [cidStatus, jobCidStatusToDisplayStatus]);

  const onCidStatusChange = async (cidStatus) => {
    setCompanyCidStatus(cidStatus);
    await updateCidStatus(cidStatus);
  };

  const updateCidStatus = async (cidStatus) => {
    try {
      setPersistCidStatusLoading(true);
      await persistCidStatusChange(cidStatus, job.id);
      setError("");
    } catch (error) {
      setCompanyCidStatus("");
      setError("Error updating CID status. Please try again and contact your project lead if the problem persists.");
    } finally {
      setPersistCidStatusLoading(false);
    }
  };

  const isIndependent = () => {
    const companyName = (job && job.company) || "";
    return companyName.toUpperCase() === "INDEPENDENT";
  };

  const CidRadioButtons = withAccessControl(({ disabled, ...props }) => {
    const cidRadioButtonsDisabled = disabled || !userCidEnabled || isIndependent(job);

    return (
      <x.div {...radioGroupDiv}>
        <RadioGroup
          value={companyCidStatus}
          onChange={(event) => onCidStatusChange(event.target.value)}
          {...radioGroup}
          {...props}
        >
          <RadioButton
            value="CLEARED"
            variant="boxed"
            disabled={cidRadioButtonsDisabled}
            {...radioButton}
            labelDataAttributes={{ flex: 1 }}
            size="small"
          >
            Clear
          </RadioButton>
          <RadioButton
            value="BLOCKED"
            variant="boxed"
            disabled={cidRadioButtonsDisabled}
            labelDataAttributes={{ flex: 1 }}
            size="small"
          >
            Block
          </RadioButton>
        </RadioGroup>
        {cidStatus === CidStatus.APPROVED && (
          <Typography component="span" {...approvedLabel}>
            Approved <MoreInfo data-tip="This company was one-off approved for the expert." />
          </Typography>
        )}
      </x.div>
    );
  });

  const isLoading = isJobsCidStatusesLoading || persistCidStatusLoading;

  return (
    <>
      {job.current && (
        <x.div {...cidControls} data-testid="company-cid-controls">
          {userCidEnabled ? (
            <CidRadioButtons accessControl={{ allowedPermissions: ["cid"] }} disabled={isLoading} />
          ) : (
            <CidRadioButtons disabled={true} data-tip="You must be signed in to action the CID Status." />
          )}
        </x.div>
      )}
    </>
  );
};
