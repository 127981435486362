import { useEffect, useState } from "react";

export const useExpiration = (delay, onExpire) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
      onExpire();
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, onExpire]);

  return visible;
};
