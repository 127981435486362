import React, { FC, useCallback, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Alert, Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { Summary, SummaryStatus, SummaryType } from "./AiSummaries.types";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import useQueryParams from "hooks/useQueryParams";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { DefaultView } from "components/DefaultView";
import { LANDING_PAGE } from "../text";
interface GenerateSummaryProps {
  interactionId: string;
  recordingId: string;
  transcriptId: string;
  onGenerateClicked: (summariesStatus: Summary[]) => void;
  onSummaryGenerated: () => void;
  summaryType: SummaryType;
  isTranscriptLoaded: boolean;
  initialSummaryStatus?: SummaryStatus;
  mobile?: boolean;
}

const FETCH_STATUS_INTERVAL = 5000;
const PARAM_AUTO_GENERATE_AI_SUMMARY = "autoGenerateAiSummary";

export const GenerateSummary: FC<GenerateSummaryProps> = ({
  interactionId,
  recordingId,
  transcriptId,
  onGenerateClicked,
  onSummaryGenerated,
  summaryType = SummaryType.Comprehensive,
  isTranscriptLoaded,
  initialSummaryStatus = SummaryStatus.NotRequested,
  mobile = false,
}) => {
  const queryParams = useQueryParams();
  const [summariesStatus, setSummariesStatus] = useState<Summary[]>();
  const [isLoading, setIsLoading] = useState(initialSummaryStatus === SummaryStatus.Generating);
  const [showFailedBanner, setShowFailedBanner] = useState(initialSummaryStatus === SummaryStatus.FailedToGenerate);
  const [errorLoadingStatus, setErrorLoadingStatus] = useState(false);
  const [autoGenerateAiSummary, setAutoGenerateAiSummary] = useState<boolean>(
    queryParams.get(PARAM_AUTO_GENERATE_AI_SUMMARY) === "true"
  );
  const styles = useGenerateSummaryStyles(showFailedBanner, mobile);
  const { getAiSummariesStatus, generateAiSummary } = useDeliverableContext();
  const { logHit } = useTrackUserAction();
  const { project } = useDeliverableContext();
  const {
    color: { text },
  } = useThemeTokens();

  const { onSummariesUpdated } = useInteractionDeliverablesContext() ?? {};

  const fetchStatus = useCallback(
    () =>
      getAiSummariesStatus(interactionId, recordingId)
        .then((response: Summary[]) => {
          setSummariesStatus(response);
        })
        .catch((_error: any) => {
          setErrorLoadingStatus(true);
        }),
    [getAiSummariesStatus, interactionId, recordingId]
  );

  useEffect(() => {
    if (autoGenerateAiSummary) {
      queryParams.delete("autoGenerateAiSummary");
    }
  }, [autoGenerateAiSummary, queryParams]);

  useEffect(() => {
    if (!summariesStatus) {
      fetchStatus();
    } else if (autoGenerateAiSummary) {
      const selectedSummaryStatus = summariesStatus?.find((e) => e.type === summaryType)?.status;
      if (
        selectedSummaryStatus === SummaryStatus.NotRequested ||
        selectedSummaryStatus === SummaryStatus.FailedToGenerate
      ) {
        onClickGenerate(true);
      }
      setAutoGenerateAiSummary(false);
    } else if (
      summariesStatus.some((e) => e.status === SummaryStatus.Generating) &&
      summariesStatus.find((e) => e.type === SummaryType.Comprehensive)?.status !== SummaryStatus.FailedToGenerate
    ) {
      const pollingInterval = setInterval(fetchStatus, FETCH_STATUS_INTERVAL);
      return () => {
        clearInterval(pollingInterval);
      };
    }
  }, [summariesStatus, fetchStatus, autoGenerateAiSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const comprehensiveFailed =
      summariesStatus?.find((e) => e.type === SummaryType.Comprehensive)?.status === SummaryStatus.FailedToGenerate;
    const selectedSummaryStatus = summariesStatus?.find((e) => e.type === summaryType)?.status ?? initialSummaryStatus;
    setIsLoading(selectedSummaryStatus === SummaryStatus.Generating && !comprehensiveFailed);
    setShowFailedBanner(selectedSummaryStatus === SummaryStatus.FailedToGenerate || comprehensiveFailed);
    selectedSummaryStatus === SummaryStatus.Generated && onSummaryGenerated();
  }, [summariesStatus, summaryType, onSummaryGenerated, initialSummaryStatus]);

  const onClickGenerate = (isAutomaticGeneration: boolean) => {
    setIsLoading(true);
    setShowFailedBanner(false);
    logHit({
      origin: HitOrigin.deliverablesView,
      action: isAutomaticGeneration ? HitAction.aiSummaryAutoGenerate : HitAction.aiSummaryGenerate,
      projectToken: project.token,
      advisorshipId: interactionId,
      references: { transcriptId: transcriptId },
    });
    return generateAiSummary(interactionId, recordingId).then((response: Summary[]) => {
      setSummariesStatus(response);
      onGenerateClicked(response);
      onSummariesUpdated?.(response);
    });
  };

  if (errorLoadingStatus || !isTranscriptLoaded) {
    return (
      <DefaultView
        data-testid="deliverables-main-content-message"
        title={LANDING_PAGE.aiSummaryError.title}
        subtitle={LANDING_PAGE.aiSummaryError.subtitle}
      />
    );
  }

  return (
    <x.div {...styles.wrapper}>
      {showFailedBanner && (
        <x.div {...styles.errorBanner}>
          <Alert variant="danger" flexGrow="1">
            <Typography variant="body-small-em" component="span">
              Failed:{" "}
            </Typography>
            <Typography variant="body-small" component="span">
              Generation was unsuccessful. Please try again.
            </Typography>
          </Alert>
        </x.div>
      )}

      <Typography color={text.secondary} {...styles.summaryInstruction}>
        {isLoading
          ? `Generating ${summaryType.toLowerCase().replace("_", " ")} summary. This may take a minute!`
          : `Click ‘generate’ below to create a free ${summaryType
              .toLowerCase()
              .replace("_", " ")} summary of your transcript.`}
      </Typography>

      <Button
        variant="outline"
        size="small"
        loading={isLoading}
        disabled={isLoading}
        onClick={() => onClickGenerate(false)}
        data-testid="generate-summary-btn"
      >
        Generate
      </Button>
    </x.div>
  );
};

const useGenerateSummaryStyles = (showFailedBanner: boolean, mobile: boolean) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      alignItems: mobile ? "stretch" : "center",
      flexDirection: "column",
      textAlign: "center",
    },
    summaryInstruction: {
      marginBottom: inner.base03,
      marginTop: mobile ? undefined : showFailedBanner ? inner.base03 : inner.base08,
    },
    errorBanner: {
      marginTop: inner.base03,
      display: "flex",
      w: "100%",
      flexDirection: "row",
    },
  };
};
