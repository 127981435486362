import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { flatExpertsWithRequests } from "../../components/InteractionsPage/helpers/Interaction";
import { useBuildColumnDefinitions, filterColumnDefinitions } from "./TableColumnDefinitions";
import { usePreference } from "../../hooks/usePreference";
import { ExpertFilters } from "components/InteractionsPage/ExpertFilters";
import { Button } from "@alphasights/alphadesign-components";
import { ENABLE_PORTAL_COMMENTS, ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import { x } from "@xstyled/styled-components";
import _, { isEqual } from "lodash";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { selectExperts } from "components/InteractionsPage/reducer";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import { ActionBar } from "./ActionBar/ActionBar";
import {
  buildColumnsOptions,
  columnIds,
  columnPreferenceExists,
  hasOrderChanged,
  hasPinningChange,
  hasVisibilityChanged,
} from "../../helpers/portalTablePreferencesHelper";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useLabelsContext } from "providers/LabelsProvider";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { AdvisorsPageHeader } from "views/AdvisorsPage/AdvisorsPageHeader";
import pluralize from "pluralize";
import { useMemoizedValue } from "hooks/useMemoizedValue";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { PreferenceType } from "providers/types";
import * as S from "./AdvisorsTableView.styled";
import { AdvisorsTableSkeleton, AdvisorsTableViewSkeleton } from "./AdvisorsTableViewSkeleton";

export const AdvisorsTableView = React.memo(({ loading, isFilterOptionsLoading, ...props }) => {
  const newNavigationEnabled = useNewNavigation();

  if (!props.headerHeight && !newNavigationEnabled) {
    return <AdvisorsTableViewSkeleton />;
  }
  return <TableViewWithPreferences {...props} loading={loading || isFilterOptionsLoading} />;
});

const TableViewWithPreferences = React.memo(({ token, ...props }) => {
  const {
    preference: columnPreference,
    updatePreference: updateColumnPreference,
    promote: applyColumnLayoutToAllProjects,
    isLoading,
  } = usePreference(PreferenceType.TABLE_VIEW_COLUMNS, { token });

  return (
    <>
      {isLoading ? (
        <AdvisorsTableViewSkeleton />
      ) : (
        <TableView
          columnPreference={columnPreference}
          updateColumnPreference={updateColumnPreference}
          applyColumnLayoutToAllProjects={applyColumnLayoutToAllProjects}
          token={token}
          {...props}
        />
      )}
    </>
  );
});

const TableView = React.memo((props) => {
  const {
    clientRequests,
    columnPreference,
    applyColumnLayoutToAllProjects,
    updateColumnPreference,
    experts = [],
    isMobile,
    isProjectCidEnabled,
    onDownloadCompaniesCid,
    onSelectCard,
    pastProjectsEnabled,
    preferencesEnabled,
    scrollToCardId,
    selectedCardId,
    token,
    userCidEnabled,
    project,
    onSearchQueryChange,
    searchQuery,
    onSearch,
    onSubmitFilters,
    onToggleFilter,
    loading,
    showing,
    currentView,
    isPCCEnabledClient,
    onResetAllFilters,
    hasClientPortalMessages,
    selectedExpertsIds,
    newMessageOrigin,
    interactions,
    isActiveProject,
    appliedFilters,
  } = props;
  const [showHiddenExperts, setShowHiddenExperts] = useState(false);
  const { isExpertHidden } = useExpertRemarksContext();
  const newNavigationEnabled = useNewNavigation();

  const isExpertHiddenRef = useRef(isExpertHidden);
  useEffect(() => {
    isExpertHiddenRef.current = isExpertHidden;
  }, [isExpertHidden]);

  useEffect(
    function changeHiddenExpertsVisibilityOnFilterChanged() {
      setShowHiddenExperts(appliedFilters.profile_activity && appliedFilters.profile_activity.includes("Hidden"));
    },
    [appliedFilters]
  );

  const rowData = useMemoizedValue(
    _.sortBy(flatExpertsWithRequests(experts, clientRequests), [(o) => isExpertHiddenRef.current(o)]).flat()
  );

  const hiddenExperts = useMemo(() => rowData.filter((expert) => isExpertHiddenRef.current(expert)), [rowData]);

  return (
    <x.div display="flex" flexDirection="column" flexGrow="1" mr="0">
      {!newNavigationEnabled && (
        <x.div display="flex" flexDirection="column">
          <div>
            <AdvisorsPageHeader
              onSearch={onSearch}
              onSearchQueryChange={onSearchQueryChange}
              pastProjectsEnabled={pastProjectsEnabled}
              preferencesEnabled={preferencesEnabled}
              project={project}
              searchQuery={searchQuery}
              showing={showing}
              token={token}
              isActiveProject={project.active}
              applyColumnLayoutToAllProjects={applyColumnLayoutToAllProjects}
              filterComponent={(isCompact) => (
                <ExpertFilters
                  onSubmitFilters={onSubmitFilters}
                  onToggleFilter={onToggleFilter}
                  onResetAllFilters={onResetAllFilters}
                  searchQuery={searchQuery}
                  isCompact={isCompact}
                />
              )}
              currentView={currentView}
              {...props}
            />
          </div>
        </x.div>
      )}
      <AdvisorsTableSkeleton style={{ display: loading ? "" : "none" }} />
      <x.div className="AdvisorsTableView" position="relative" flexGrow="1" display={loading ? "none" : undefined}>
        <Table
          columnPreference={columnPreference}
          updateColumnPreference={updateColumnPreference}
          isMobile={isMobile}
          isProjectCidEnabled={isProjectCidEnabled}
          onSelectCard={onSelectCard}
          scrollToCardId={scrollToCardId}
          selectedCardId={selectedCardId}
          project={project}
          selectedExpertsIds={useMemoizedValue(selectedExpertsIds ?? [])}
          newMessageOrigin={newMessageOrigin}
          preferencesEnabled={preferencesEnabled}
          hasClientPortalMessages={hasClientPortalMessages}
          userCidEnabled={userCidEnabled}
          showHiddenExperts={showHiddenExperts}
          interactions={useMemoizedValue(interactions ?? [])}
          isActiveProject={isActiveProject}
          onDownloadCompaniesCid={onDownloadCompaniesCid}
          isPCCEnabledClient={isPCCEnabledClient}
          rowData={rowData}
        />
        <ShowHideExpertsButton
          onClick={() => setShowHiddenExperts((prev) => !prev)}
          showHiddenExperts={showHiddenExperts}
          hiddenExperts={hiddenExperts}
        />
      </x.div>
    </x.div>
  );
});

const ShowHideExpertsButton = ({ onClick, showHiddenExperts, hiddenExperts }) => {
  const hasHiddenExperts = hiddenExperts.length > 0;

  if (!hasHiddenExperts) return null;

  return (
    <S.ShowHideExpertsButtonWrapper>
      <Button variant="outline" size="small" onClick={onClick}>
        {showHiddenExperts ? "Remove" : "Show"} Hidden {pluralize("Expert", hiddenExperts.length)}
      </Button>
    </S.ShowHideExpertsButtonWrapper>
  );
};

const Table = (props) => {
  const {
    columnPreference,
    updateColumnPreference,
    isMobile,
    isProjectCidEnabled,
    onSelectCard,
    scrollToCardId,
    selectedCardId,
    project,
    selectedExpertsIds,
    newMessageOrigin,
    interactions,
    preferencesEnabled,
    hasClientPortalMessages,
    userCidEnabled,
    onDownloadCompaniesCid,
    showHiddenExperts,
    isPCCEnabledClient,
    rowData,
  } = props;

  const [hideHeader, setHideHeader] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { isExpertHidden } = useExpertRemarksContext();
  const { enableLabels } = useLabelsContext();
  const poralMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);
  const hasActionButtons = poralMessagesEnabled || isProjectCidEnabled || enableLabels;
  const { threadHeaders } = useMessageThreadContext();

  const isExpertHiddenRef = useRef(isExpertHidden);
  useEffect(() => {
    isExpertHiddenRef.current = isExpertHidden;
  }, [isExpertHidden]);

  const showComments =
    (!currentUser || allProjects.find(({ token }) => token === project.token)) &&
    hasProjectBadge(ENABLE_PORTAL_COMMENTS) &&
    project.active;

  useEffect(() => {
    const element = document.getElementById(`advisor-row-${scrollToCardId}`);
    if (element) {
      element.scrollIntoView();
    }
  }, [scrollToCardId]);

  const initialColumns = useBuildColumnDefinitions({
    onSelectCard,
    project,
    interactions,
    preferencesEnabled,
    userCidEnabled,
  });

  const saveColumnPreferences = () => {
    const attributes = buildColumnPreferences(columnIds(columns));
    updateColumnPreference(attributes);
  };

  useEffect(() => {
    if (!columnPreferenceExists(columnPreference)) {
      saveColumnPreferences();
    }
  }, [columnPreference?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = filterColumnDefinitions({
    columns: initialColumns,
    project,
    showComments,
    isProjectCidEnabled,
    isMobile,
    threadHeaders,
    interactions: rowData,
  });

  const dispatch = useContext(DispatchContext);

  const onRowSelectionChange = (selection) => {
    const selectedRows = rowData.filter((item, index) => index in selection && selection[index]);
    setSelectedRows(selectedRows);
    setHideHeader(hasActionButtons && Object.keys(selectedRows).length > 0);
    const expertsIds = selectedRows.map((interaction) => interaction.advisorId);
    if (newMessageOrigin === NewMessageOrigin.TableView && !isEqual(expertsIds, selectedExpertsIds) && !firstLoad) {
      dispatch(selectExperts(expertsIds));
    }
  };

  const onRowClicked = useCallback(
    (row) => {
      const { original: interaction } = row;

      if (!isExpertHidden(interaction)) {
        onSelectCard({
          ...interaction,
          showChainList: false,
          scrollToCardId: interaction.id,
        });
      }
    },
    [onSelectCard, isExpertHidden]
  );

  const rowClassRules = [
    {
      className: "row-selected",
      ruleFunction: (row) => row.original.id === selectedCardId,
    },
    {
      className: "row-hidden",
      ruleFunction: (row) => isExpertHiddenRef.current(row.original),
    },
  ];

  const options = buildColumnsOptions({
    columnPreference,
    columns,
    hideSelectColumn: !currentUser || !hasActionButtons,
    onRowClicked,
    rowClassRules,
    hideHeader: hideHeader,
    enableRowSelection: ({ original: interaction }) => !isExpertHidden(interaction),
    onRowSelectionChange,
    onColumnOrderChange: (updatedColumnOrders) => {
      if (hasOrderChanged(updatedColumnOrders, columnPreference)) {
        saveColumnPreferences();
      }
    },
    onColumnVisibilityChange: (updatedColumnVisibilities) => {
      if (hasVisibilityChanged(updatedColumnVisibilities, columnPreference, columns)) {
        saveColumnPreferences();
      }
    },
    onColumnPinningChange: (updatedColumnPinning) => {
      if (hasPinningChange(updatedColumnPinning, columnPreference)) {
        saveColumnPreferences();
      }
    },
  });

  const filteredData = useMemo(
    () => rowData.filter((expert) => showHiddenExperts || !isExpertHiddenRef.current(expert)),
    [rowData, showHiddenExperts, isExpertHiddenRef]
  );
  const table = useAlphaTable(filteredData, columns, options);

  useEffect(
    function updateColumnOrderOnNewColumnsAdded() {
      table.setColumnOrder(options.state.columnOrder);
    },
    [columns.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (selectedExpertsIds && newMessageOrigin === NewMessageOrigin.TableView) {
      const selectedRows = Object.assign(
        {},
        rowData.map((row) => selectedExpertsIds.includes(row.advisorId))
      );
      table.setRowSelection(selectedRows);
    }
  }, [selectedExpertsIds, table, rowData, newMessageOrigin]);

  const downloadCidCompaniesCsv = () => {
    onDownloadCompaniesCid(selectedRows);
    table.resetRowSelection(true);
  };

  const buildColumnPreferences = useCallback(
    (columns) => {
      const columnIds = table.getState().columnOrder.length > 0 ? table.getState().columnOrder : columns;
      return columnIds.reduce((obj, column, index) => {
        const isColumnVisible = _.get(table.getState(), `columnVisibility.${column}`, true);
        const isColumnHiddenInPreferences = _.get(columnPreference, `attributes.${column}.hide`, false);
        return {
          ...obj,
          [column]: {
            position: index,
            hide: columns.includes(column) ? !isColumnVisible : isColumnHiddenInPreferences,
            pinned: table.getState().columnPinning.left.includes(column),
          },
        };
      }, {});
    },
    [table, columnPreference]
  );

  const onSelectAll = () => {
    table.toggleAllRowsSelected(true);
  };

  const onUnselectAll = () => {
    table.resetRowSelection(true);
  };

  useEffect(() => setFirstLoad(false), []);

  return (
    <S.AlphaTableWrapper>
      {hideHeader && (
        <ActionBar
          interactions={rowData}
          selectedInteractions={selectedRows}
          onDownloadCid={downloadCidCompaniesCsv}
          pcc={isPCCEnabledClient}
          isActiveProject={project.active}
          hasClientPortalMessages={hasClientPortalMessages}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          isProjectCidEnabled={isProjectCidEnabled}
        />
      )}
      <AlphaTable table={table} />
    </S.AlphaTableWrapper>
  );
};
