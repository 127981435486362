import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const FooterContentWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "center",
    gap: spacing.inner.base02,
    paddingTop: spacing.inner.base,
    paddingBottom: spacing.inner.base,
  };
});

export { FooterContentWrapper };
