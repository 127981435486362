import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useBannerStyles = () => {
  const {
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const bannerContainer = {
    display: "flex",
    justifyContent: "flex-start",
    px: inner.base04,
    py: inner.base02,
    animation: "fade-in 1s both",
    borderRadius: border.radius.medium,
    gap: inner.base02,
  };

  const bannerBody = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    alignSelf: "center",
  };

  return {
    bannerContainer,
    bannerBody,
  };
};
