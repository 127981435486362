import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useMarketPrimersStore } from "../../state/store";
import { LabeledIcon, PrimerFieldTitle, SectionTitle } from "pages/AlphaNowPage/primers/components";
import SizeAndGrowthTabs from "./SizeAndGrowthTabs";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import { formatCurrency } from "components/CitationContext/formatCurrency";
import { SizeAndGrowthExpert } from "./SizeAndGrowthTabContent";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

type SizeAndGrowthProps = {
  isSidebarExpanded: boolean;
};

const SizeAndGrowth = ({ isSidebarExpanded }: SizeAndGrowthProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [chartKey, setChartKey] = useState<number>(0);

  //TODO check chartSegment necessity
  const [, setSelectedChartSegment] = useState<number | null>(null);
  const { overview, sizeAndGrowth } = useMarketPrimersStore();
  const { isAccessible } = usePrimersStore();
  const { experts } = usePrimersStore();
  const reportYear = sizeAndGrowth.currentMarketSizeYear;
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  let counter = 0;
  const chartSeriesMapping = sizeAndGrowth.data.map(({ currentMarketSize }) =>
    currentMarketSize.value ? counter++ : null
  );

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const calculateGrowth = (
    type: number,
    yearCount: number,
    currentMarketSize: number,
    growthPercentage: number
  ): number => {
    if (yearCount === 3) return currentMarketSize;

    const growthValue = 1 + growthPercentage / 100;

    return calculateGrowth(
      type,
      yearCount + 1,
      type === 0 ? currentMarketSize / growthValue : currentMarketSize * growthValue,
      growthPercentage
    );
  };
  const currencySymbol = CURRENCY_SYMBOL[overview.currency];

  const expertsMarketsData: SizeAndGrowthExpert[] = useMemo(() => {
    let i = 1;

    return sizeAndGrowth.data.map(
      ({
        expertId,
        commentary,
        currentMarketSize,
        currentMarketSizeMin,
        currentMarketSizeMax,
        predictedGrowth,
        previousGrowth,
      }) => {
        const expert = experts.find(({ speakerId }) => speakerId === parseInt(expertId));

        return expert
          ? {
              id: `${expert.id}`,
              name: `Expert ${i++}`,
              relevantEmployment: {
                company: expert.company ?? "",
                position: expert.jobTitle ?? "",
                dates: expert.jobDuration ?? "",
              },
              currentMarketSize,
              currentMarketSizeMin,
              currentMarketSizeMax,
              previousGrowth,
              predictedGrowth,
              commentary,
            }
          : {
              id: "",
              name: `Expert ${i++}`,
              relevantEmployment: {
                company: "",
                position: "",
                dates: "",
              },
              currentMarketSize,
              currentMarketSizeMin,
              currentMarketSizeMax,
              previousGrowth,
              predictedGrowth,
              commentary,
            };
      }
    );
  }, [experts, sizeAndGrowth]);

  const { getChartColors } = useGetChartColors({
    numberOfBars: expertsMarketsData.length,
  });

  const series = useMemo(() => {
    return expertsMarketsData.map(({ name, previousGrowth, currentMarketSize, predictedGrowth }) => ({
      name,
      data: [
        currentMarketSize.value && previousGrowth.value
          ? calculateGrowth(0, 0, currentMarketSize.value, previousGrowth.value)
          : null,
        !!currentMarketSize.value ? currentMarketSize.value : null,
        currentMarketSize.value && predictedGrowth.value
          ? calculateGrowth(1, 0, currentMarketSize.value, predictedGrowth.value)
          : null,
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertsMarketsData]);

  const minMax = useMemo(() => {
    return [
      Math.min(
        ...series.filter((serie) => !!serie.data[0]).map((serie) => serie.data[0] ?? 999999),
        ...series.filter((serie) => !!serie.data[2]).map((serie) => serie.data[2] ?? 999999)
      ),
      Math.max(
        ...series.filter((serie) => !!serie.data[0]).map((serie) => serie.data[0] ?? 0),
        ...series.filter((serie) => !!serie.data[2]).map((serie) => serie.data[2] ?? 0)
      ),
    ];
  }, [series]);

  const options = {
    chart: {
      id: "SizeAndGrowth",
      toolbar: {
        show: false,
      },
      width: "100%",
      events: {
        // FIXME: this should be markerClick
        click: function (event: any, chartContext: any, config: any) {
          setSelectedChartSegment(config.seriesIndex);
          setSelectedTab(config.seriesIndex + 1);
        },
      },
      fontFamily: "Proxima Nova",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 2,
    },
    forecastDataPoints: {
      count: 1,
    },
    xaxis: {
      categories: [reportYear - 3, reportYear, reportYear + 3],
      axisBorder: {
        show: false,
      },
      title: {
        text: "Year",
        style: {
          color: `${color.text.strong._}`,
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          color: `${color.text.strong._}`,
          fontSize: "12px",
          fontWeight: "400",
        },
      },
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      min: minMax[0] - 3,
      max: minMax[1] + 3,
      forceNiceScale: true,
      decimalsInFloat: 0,
      labels: {
        formatter: (val: number) => {
          return `${formatCurrency(val)}`;
        },
        style: {
          color: `${color.text.strong._}`,
          fontSize: "12px",
          fontWeight: "400",
        },
        offsetX: 10,
      },
      axisBorder: {
        show: false,
      },
      title: {
        text: `Market size (${currencySymbol})`,
        style: {
          color: `${color.text.strong._}`,
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    colors: getChartColors(selectedTab === 0 ? null : selectedTab - 1),
    markers: {
      size: 7,
      hover: {
        sizeOffset: 0,
      },
    },
    grid: {
      show: true,
      padding: {
        left: 100,
        right: 100,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function (option: any) {
        return "";
      },
    },
  };

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);

    if (chartSeriesMapping[index])
      // this avoids selecting a series that is not on the chart
      ApexCharts.exec("SizeAndGrowth", "toggleDataPointSelection", chartSeriesMapping[index]);
  };

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  return (
    <SectionWrapper ref={ref} px={inner.base06}>
      <x.div display="flex" flexDirection="column" gap={inner.base04}>
        <SectionTitle dataTestId="SizeAndGrowthSectionTitle" text="Size and Growth" />
        <PrimerFieldTitle text="Expert Estimations" />
        <LabeledIcon labelText="Click a data point to view an expert’s estimate" iconItem={<Info />} />
        <SensitiveDataContainer isSensitive={!isAccessible}>
          <Chart key={chartKey} options={options} series={series} type="line" height={400} />
        </SensitiveDataContainer>
        <SensitiveDataContainer isSensitive={!isAccessible}>
          <SizeAndGrowthTabs
            selectedTab={selectedTab}
            tabsData={expertsMarketsData}
            chartColors={getChartColors(null)}
            handleTabChange={handleTabChange}
            onHighlightChartSegment={onHighlightChartSegment}
          />
        </SensitiveDataContainer>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(SizeAndGrowth));
