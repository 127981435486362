import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Icon } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const useRequestEventStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    lineHeight: "tight",
    position: "relative",
  };

  const closeIcon = {
    position: "absolute",
    right: inner.base,
  };

  return {
    wrapper,
    closeIcon,
  };
};

export const IconWrapper = styled(Icon).attrs({ size: "large" })(() => {
  return {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  };
});
