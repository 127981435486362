import React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, IconButton, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components"; // or any other UI library you are using
import { InsertLink } from "@alphasights/alphadesign-icons";

const CopyUrlButton = () => {
  const {
    color: { icon },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const [anchorEl, setAnchorEl] = React.useState(undefined);

  const open = Boolean(anchorEl);
  const handleCopyUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
  };

  const ref = React.useRef(null);
  const handleClick = (event: any) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          handleCopyUrl();
          handleClick(e);
        }}
        size="medium"
        variant="basic"
        color={icon.secondary}
      >
        <InsertLink />
      </IconButton>
      <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClose} leaveDelay={3000} closeOnClickOutside>
        <Typography component="span" color={icon.strong._} variant="body-em">
          <x.div display="flex" alignItems="center" px={inner.base02} py={inner.base} whiteSpace="nowrap">
            <x.div position="relative" top={-1} display="flex" alignItems="center" mr="10px">
              <Icon size="medium">
                <InsertLink />
              </Icon>
            </x.div>
            <x.div pb={border.width.large}>Link copied</x.div>
          </x.div>
        </Typography>
      </Popover>
    </>
  );
};

export default CopyUrlButton;
