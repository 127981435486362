import { fetch } from "../hooks/useApi";

export const labelsService = {
  async fetchLabels(projectToken: string): Promise<Label[]> {
    return fetch({
      url: `/api/projects/${projectToken}/labels`,
      method: "GET",
    }).then((response) => response.json());
  },

  async createLabel(projectToken: string, labelText: string): Promise<Label> {
    return fetch({
      url: `/api/projects/${projectToken}/labels`,
      method: "POST",
      body: JSON.stringify({ text: labelText }),
    }).then((response) => response.json());
  },

  async editLabel(projectToken: string, labelId: string, labelText: string): Promise<Label> {
    return fetch({
      url: `/api/projects/${projectToken}/labels/${labelId}`,
      method: "PATCH",
      body: JSON.stringify({ text: labelText }),
    }).then((response) => response.json());
  },

  async deleteLabel(projectToken: string, labelId: string): Promise<void> {
    return fetch({
      url: `/api/projects/${projectToken}/labels/${labelId}`,
      method: "DELETE",
    }).then(() => Promise.resolve());
  },

  async fetchLabelledExperts(projectToken: string): Promise<LabelledExpert[]> {
    return fetch({
      url: `/api/projects/${projectToken}/labelled-experts`,
      method: "GET",
    }).then((response) => response.json());
  },

  async addLabelledExpert(
    projectToken: string,
    labelId: string,
    experts: { expertId: string; angleId: string }[]
  ): Promise<LabelledExpert[]> {
    return fetch({
      url: `/api/projects/${projectToken}/labelled-experts`,
      method: "PUT",
      body: JSON.stringify({
        labelId,
        experts,
      }),
    }).then((response) => response.json());
  },

  async removeLabelledExpert(projectToken: string, labelledExpertId: string): Promise<void> {
    return fetch({
      url: `/api/projects/${projectToken}/labelled-experts/${labelledExpertId}`,
      method: "DELETE",
    }).then(() => Promise.resolve());
  },
};
