import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const notApplicable = "N/A";

type RelevantDurationDetailsProps = {
  jobDuration?: string;
};

const RelevantDurationDetails: FC<RelevantDurationDetailsProps> = ({ jobDuration }) => {
  const { isMobile } = useCheckScreen();

  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div mb={inner.base}>
      <Typography component="p" flex="1 1 auto" variant={isMobile ? "body" : "body-small"}>
        {jobDuration?.replace(" - ", "–") || notApplicable}
      </Typography>
    </x.div>
  );
};

export default RelevantDurationDetails;
