import React, { useEffect, useMemo } from "react";
import { x } from "@xstyled/styled-components";
import {
  Typography,
  Icon as IconWrapper,
  Button,
  Icon,
  Tooltip,
  useThemeTokens,
  TooltipContent,
  ContentCard,
} from "@alphasights/alphadesign-components";
import { BookmarkSelected, Company, Purchased, Lock, Search } from "@alphasights/alphadesign-icons";
import Tag from "components/Tag";
import { FormattedDateTime } from "providers/TimezoneProvider";
import useStyles from "./styles";
import { useEmptyResultsStyle } from "./Cards.style";
import { default as Content } from "react-content-loader";
import { cardDescriptionLengths, trySingularAngleName } from "./helpers";
import { industryPerspective } from "content/AlphaNow";
import { usePlusMore } from "hooks/usePlusMore";
import {
  CONTENT_TYPE_DISPLAY_NAME,
  AlphaNowProductType,
  useNewNavigation,
  useTrackUserAction,
  PageFooter,
} from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { ProjectSummaryCard } from "./ProjectSummaries/ProjectSummaryCard";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { SearchAndFilterSkeleton } from "components/SearchAndFilterSkeleton";
import { RecommendedContentCard } from "./RecommendedContentCard/RecommendedContentCard";
import { noop } from "lodash";
import PitchedDetails from "components/PitchedDetails";
import { CardsSidebarHeader } from "./CardsSidebarHeader";
import { TranscriptCard, MentionsFlag } from "./TranscriptCard";
import { ENABLE_NEW_DELIVERABLES_UX, useProjectBadgeContext } from "providers/BadgeProvider";

export const RECOMMENDED_CONTENT_ID = "recommended-content";
export const PROJECT_SUMMARY_ID = "project-summary";

export const SuggestionCard = ({ suggestion, onSelect, selected }) => {
  const styles = useStyles();

  const {
    id: contentId,
    isAccessible,
    isPrimer,
    anchorCompanies,
    primerCompanyLogoSrc,
    title,
    externalTitle,
    scheduledTimeUTC,
    bookmarked,
    description,
    contentType,
    productType,
    privateMode,
    pitchedAt,
    keywordHits,
  } = suggestion;

  const isMarketOrCustomerPrimer = [AlphaNowProductType.marketPrimer, AlphaNowProductType.customerPrimer].includes(
    productType
  );

  const Logo = () => {
    if (productType === AlphaNowProductType.companyPrimer && primerCompanyLogoSrc) {
      return <x.img src={primerCompanyLogoSrc} alt={`${title ?? "company"} logo`} {...styles.primerImg} />;
    } else if (isMarketOrCustomerPrimer && suggestion.companyLogos && suggestion.companyLogos.length > 0) {
      return (
        <x.div
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="2px"
          padding="2px"
          w="100%"
          h="100%"
          gridAutoRows="1fr"
          textAlign="center"
          alignItems="center"
        >
          {suggestion.companyLogos.map((companyLogo) => (
            <x.div
              w="100%"
              h="100%"
              backgroundImage={`url(${companyLogo})`}
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              title={`${title ?? "company"} logo`}
            />
          ))}
        </x.div>
      );
    }

    return (
      <div data-testid="placeholder-icon">
        <Icon size="large" {...styles.primerNoLogo}>
          <Company />
        </Icon>
      </div>
    );
  };

  const defaultProps = {
    anchorCompanies,
    scheduledTimeUTC,
    contentType,
    productType,
    description,
    externalTitle,
    isAccessible,
    bookmarked,
    pitchedAt,
    title: isMarketOrCustomerPrimer ? externalTitle : title,
    hitCount: keywordHits,
  };

  return (
    <ContentCard {...styles.card} selected={selected} onClick={onSelect} data-testid={`suggested-card-${contentId}`}>
      {isPrimer ? (
        <x.div display="flex">
          <x.div
            data-testid="primer-logo"
            {...(primerCompanyLogoSrc
              ? { backgroundColor: "transparent", ...styles.primerWrapper }
              : styles.primerWrapper)}
          >
            <Logo />
          </x.div>
          <x.div {...styles.primerContent}>
            <SuggestionCardMainContent {...defaultProps} privateMode={privateMode} />
          </x.div>
        </x.div>
      ) : (
        <SuggestionCardMainContent {...defaultProps} />
      )}
    </ContentCard>
  );
};

const SuggestionCardMainContent = ({
  anchorCompanies,
  scheduledTimeUTC,
  title,
  productType,
  isAccessible,
  bookmarked,
  privateMode,
  pitchedAt,
  hitCount,
}) => {
  const labels = useMemo(
    () => (anchorCompanies.length > 0 ? anchorCompanies.map((company) => company.companyName) : [industryPerspective]),
    [anchorCompanies]
  );

  const { setContainerEl, regularPills, morePill, steady } = usePlusMore({
    labels,
  });

  const { spacing } = useThemeTokens();
  const styles = useStyles();
  const { project } = useCurrentProjectContext();

  const displayProductType = CONTENT_TYPE_DISPLAY_NAME[productType];
  const productTypeDescription = privateMode ? `Private ${displayProductType}` : displayProductType;

  return (
    <>
      <x.div display="flex" justifyContent="space-between" gap="4px" alignItems="center">
        <x.div ref={setContainerEl} {...styles.cardRow} overflow="hidden" zIndex={steady ? null : -1}>
          {regularPills.map(({ original, text }) => (
            <Tooltip key={original} disabled={original === text} dark>
              <TooltipContent>
                <Typography variant="body-small">{original}</Typography>
              </TooltipContent>
              <Tag name={text} whiteSpace="nowrap" icon={<Company />} />
            </Tooltip>
          ))}
          {morePill.length > 0 && (
            <Tooltip dark>
              <TooltipContent>
                <Typography variant="body-small">{morePill.map(({ original }) => original).join(", ")}</Typography>
              </TooltipContent>
              <Tag name={"+" + morePill.length + " more"} />
            </Tooltip>
          )}
        </x.div>
        <x.div {...styles.cardRowDate}>
          <Typography variant="body-small" color="secondary">
            <FormattedDateTime date={scheduledTimeUTC} format="d LLL yyyy" />
          </Typography>
        </x.div>
      </x.div>
      <x.div {...styles.suggestionCardRow}>
        <Typography variant="body-em">{title}</Typography>
      </x.div>
      <x.div {...styles.cardRow} justifyContent="space-between">
        <Typography variant="body-small" color="secondary">
          {productTypeDescription}
        </Typography>
        <x.div {...styles.cardRow}>
          {privateMode && (
            <Tooltip title="Private research created exclusively for your research">
              <IconWrapper color="secondary" size="medium" data-testid="private-primer">
                <Lock />
              </IconWrapper>
            </Tooltip>
          )}
          {isAccessible && (
            <IconWrapper color="success" size="medium" data-testid="suggestion-purchased">
              <Purchased />
            </IconWrapper>
          )}
          {bookmarked && (
            <IconWrapper color="secondary" size="medium" data-testid="bookmarked-flag">
              <BookmarkSelected />
            </IconWrapper>
          )}
          {pitchedAt && (
            <PitchedDetails
              pitchedAt={pitchedAt}
              pitchedBy={project.lead}
              stylingProps={{ marginLeft: spacing.inner.base }}
              hasDescription
            />
          )}
          {hitCount > 0 ? <MentionsFlag hitCount={hitCount} /> : null}
        </x.div>
      </x.div>
    </>
  );
};

const DeliverablesCardsList = ({ interactions, currentSelection, onSelect }) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const ActualCard = hasProjectBadge(ENABLE_NEW_DELIVERABLES_UX) ? TranscriptCard : Card;
  return interactions.map((i) => (
    <ActualCard
      key={`deliverable-card-${i.id}`}
      interaction={i}
      onSelect={() => onSelect(i)}
      selected={currentSelection?.id === i.id}
    />
  ));
};

const Card = ({ interaction, onSelect, selected }) => {
  const { isMobile } = useCheckScreen();
  const styles = useStyles();
  const recordings = interaction.recordings || [];
  const transcriptTypes = recordings
    .flatMap((r) => r.transcriptRequests || [])
    .filter((r) => r.completed)
    .map((tr) => tr.transcriptType);

  const mainUpgrade = findMainDeliverable(transcriptTypes);

  const otherUpgrades = transcriptTypes
    .concat(recordings.find((r) => r.visibleToClient && !r.purgedAt) ? ["recording"] : [])
    .filter((u) => u !== mainUpgrade);

  const { score: hitCount, bookmarked } = interaction;

  const maxLength = isMobile ? 42 : 50;
  const lengths = cardDescriptionLengths(interaction.companyName, interaction.role, maxLength);

  return (
    <ContentCard {...styles.card} selected={selected} onClick={onSelect} data-testid={`card-${interaction.id}`}>
      <x.div display="flex" justifyContent="space-between">
        <x.div {...styles.cardRow} pb={"4px"} overflow="auto">
          <AllUpgrades upgrades={[mainUpgrade, ...otherUpgrades]} />
        </x.div>
        <x.div {...styles.cardRow}>
          <Typography variant="body-small" color="secondary" whiteSpace="nowrap">
            <FormattedDateTime date={interaction.scheduledCallTime} format="d LLL yyyy" />
          </Typography>
        </x.div>
      </x.div>
      <x.div {...styles.cardRow}>
        <CardContent
          description={interaction.companyName}
          maxLength={lengths.companyMaxLength}
          variant="body-em"
          data-testid="select-card"
        />
        <CardContent prefix={"- "} maxLength={lengths.roleMaxLength} description={interaction.role} />
      </x.div>
      <x.div {...styles.cardRow} justifyContent="space-between">
        <Typography variant="body-small" color="secondary">
          {trySingularAngleName(interaction)} | {interaction.advisorName}
        </Typography>
        <x.div display={"flex"} alignItems={"center"}>
          {bookmarked && (
            <IconWrapper color="secondary" size="medium" data-testid="deliverables-card-bookmark">
              <BookmarkSelected />
            </IconWrapper>
          )}
          <MentionsFlag hitCount={hitCount} />
        </x.div>
      </x.div>
    </ContentCard>
  );
};

const CardContent = ({ description, prefix = "", maxLength = 25, ...props }) => {
  return description?.length > maxLength ? (
    <Tooltip title={description}>
      <Typography {...props}>
        {prefix} {description.substring(0, maxLength)}...
      </Typography>
    </Tooltip>
  ) : (
    <Typography {...props}>
      {prefix} {description}
    </Typography>
  );
};

const AllUpgrades = ({ upgrades }) => {
  const { shape, color } = useThemeTokens();

  const mainUpgrades = upgrades.slice(0, 2);
  const otherUpgrades = upgrades.slice(2);

  const mainTags = mainUpgrades.map((upgrade) => (
    <Tag
      name={deliverablesNames[upgrade].name}
      style={{
        spaceX: shape.border.width.large,
        bg: color.background.info,
        maxWidth: "110px",
      }}
      typographyStyle={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    />
  ));

  const tooltipCopy = otherUpgrades.map((upgrade) => deliverablesNames[upgrade]?.name ?? upgrade).join(", ");
  const otherTag = otherUpgrades.length ? (
    <PortalTooltip title={tooltipCopy} position="bottom" dark leaveDelay={200}>
      <Tag
        data-testid="more-deliverables"
        name={`+${otherUpgrades.length} more`}
        style={{
          spaceX: shape.border.width.large,
          bg: color.background.neutral.default,
        }}
      />
    </PortalTooltip>
  ) : null;

  return mainTags.length ? [...mainTags, otherTag] : null;
};

const findMainDeliverable = (transcriptTypes) => {
  const priorityList = Object.keys(deliverablesNames);
  return priorityList.find((p) => transcriptTypes.includes(p)) || "recording";
};

export const deliverablesNames = {
  regular: {
    name: "Human Transcript",
  },
  summary: {
    name: "Synthesized Transcript",
  },
  translated: {
    name: "Translated Transcript",
  },
  ai: {
    name: "AI Transcript",
  },
  recording: {
    name: "Recording",
  },
};

const EmptyResult = ({ variant = "deliverables", onClearFilters, hasAppliedFilterOrKeyword }) => {
  const styles = useEmptyResultsStyle();

  const texts = {
    deliverables: {
      title: "No project deliverables have been uploaded",
      subtitle: "Your deliverable will be uploaded as soon as it's ready.",
    },
    research: {
      title: "No research content found for your project",
      subtitle: "Check again later.",
    },
  };

  const textsOnSearch = {
    deliverables: {
      title: "No Results Found",
      subtitle: "Try removing the applied filters or rephrasing your search",
      cta: "Remove All Filters",
    },
    research: {
      title: "No Results Found",
      subtitle: "Try removing the applied filters or rephrasing your search",
      cta: "Remove All Filters",
    },
  };

  const { title, subtitle, cta } = (hasAppliedFilterOrKeyword ? textsOnSearch : texts)[variant];

  return (
    <x.div {...styles.container} data-testid="deliverables-empty-results">
      <Typography variant="body-large-em" color="secondary">
        {title}
      </Typography>
      <Typography variant="body-small" color="secondary" {...styles.subtitle}>
        {subtitle}
      </Typography>
      {cta && (
        <Button variant="outline" size="small" onClick={onClearFilters} {...styles.button}>
          {cta}
        </Button>
      )}
    </x.div>
  );
};

export const CardSkeleton = ({ ...props }) => (
  <Content
    data-testid="card-loading"
    viewBox="0 0 400 100"
    className={"aui-border-solid aui-border-grey-2 aui-border aui-flex aui-box-border aui-bg-white aui-rounded-lg"}
    {...props}
  >
    <rect x="17" y="17" width="100" height="18" />
    <rect x="127" y="17" width="50" height="18" />
    <rect x="310" y="17" width="77" height="18" />

    <rect x="17" y="42" width="370" height="18" />

    <rect x="17" y="68" width="55" height="15" />
    <rect x="77" y="68" width="160" height="15" />
  </Content>
);

export const CardsSidebar = (props) => {
  const {
    appliedFilters,
    appliedKeywords,
    filterOptions,
    interactions,
    alphaNowContent,
    currentSelection,
    sortCriteria,
    onSelect,
    onFilter,
    onExpandSidebar,
    onSort,
    onTextSearch,
    onClearFilters,
    loading,
    isCardsSidebarCollapsed,
  } = props;

  const { project } = useCurrentProjectContext();

  const newNavigationEnabled = useNewNavigation();

  const styles = useStyles({
    isCardsSidebarCollapsed,
    currentSelection,
    newNavigationEnabled,
  });

  const { logHit } = useTrackUserAction();

  useEffect(() => {
    if (Object.keys(alphaNowContent).length === 0) return;
    if (loading) return;

    const suggestedContent = alphaNowContent?.suggestedContent || [];

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.suggestionsPopulated,
      projectToken: project.token,
      details: {
        suggestedContent: suggestedContent.map((e) => e.id),
        numSuggestions: suggestedContent.length,
      },
    });
  }, [alphaNowContent, loading, project.token, logHit]);

  const { suggestedContent = [], privateContent = [] } = alphaNowContent;

  const numInteractions = useMemo(() => {
    const accessible = suggestedContent.filter((it) => it.isAccessible);
    return interactions.length + privateContent.length + accessible.length;
  }, [interactions, privateContent, suggestedContent]);

  return (
    <>
      {!newNavigationEnabled && (
        <x.div data-testid="sidebar-collapsed-content" {...styles.sidebarCollapsedContent}>
          <Button size="large" variant="icon" onClick={onExpandSidebar} {...styles.iconButtonStyles}>
            <Search />
          </Button>
        </x.div>
      )}

      <x.div data-testid="sidebar-expanded-content" {...styles.sidebarExpandedContent}>
        {loading ? (
          <>
            <x.div {...styles.searchSkeleton} data-testid="deliverables-search-loading">
              <SearchAndFilterSkeleton />
            </x.div>

            <x.div {...styles.cardsList}>
              <CardSkeleton width="100%" height="105px" />
              <CardSkeleton width="100%" height="105px" />
              <CardSkeleton width="100%" height="105px" />
            </x.div>
          </>
        ) : (
          <>
            <x.div {...styles.sideSearchWrapper}>
              <CardsSidebarHeader
                appliedFilters={appliedFilters}
                appliedKeywords={appliedKeywords}
                filterOptions={filterOptions}
                interactions={interactions}
                alphaNowContent={alphaNowContent}
                sortCriteria={sortCriteria}
                onFilter={onFilter}
                onSort={onSort}
                onTextSearch={onTextSearch}
                loading={loading}
                numInteractions={numInteractions}
                onClearFilters={onClearFilters}
              />
              <x.div data-testid="deliverables-cards" {...styles.cardsList}>
                <DeliverablesCards
                  order={[
                    "recommendedTable",
                    "projectSummary",
                    "privateContent",
                    "accessibleContent",
                    "deliverables",
                    "empty",
                  ]}
                  alphaNowContent={alphaNowContent}
                  interactions={interactions}
                  onSelect={onSelect}
                  currentSelection={currentSelection}
                  appliedFilters={appliedFilters}
                  appliedKeywords={appliedKeywords}
                  loading={loading}
                  numInteractions={numInteractions}
                  onClearFilters={onClearFilters}
                />

                <PageFooter classNames="aui-mt-auto" />
              </x.div>
            </x.div>
          </>
        )}
      </x.div>
    </>
  );
};

export const DeliverablesCards = ({
  order = [],
  alphaNowContent = {},
  interactions = [],
  onSelect,
  currentSelection = null,
  appliedFilters = {},
  appliedKeywords = [],
  loading = false,
  numInteractions = 0,
  onClearFilters = noop,
}) => {
  const { project } = useCurrentProjectContext();
  const { suggestedContent = [], privateContent = [], pitchedContent = [] } = alphaNowContent;

  const notAccessibleSuggestedContentCount = useMemo(
    () => (suggestedContent ?? []).filter((suggestion) => !suggestion.isAccessible).length,
    [suggestedContent]
  );

  const pitchedContentCount = useMemo(() => (pitchedContent ?? []).length, [pitchedContent]);

  const accessibleContent = useMemo(() => {
    return suggestedContent.filter((suggestion) => suggestion.isAccessible);
  }, [suggestedContent]);

  const hasAppliedFilterOrKeyword =
    appliedKeywords.length > 0 || Object.values(appliedFilters).some((filter) => filter?.length > 0);

  const hasOnlyAngleFilterApplied =
    Object.keys(appliedFilters || {})
      .filter((key) => key !== "groups")
      .every((key) => appliedFilters[key]?.length === 0) && (appliedFilters?.groups?.length || 0) > 0;

  const maybeAngleFilterApplied = !hasAppliedFilterOrKeyword || hasOnlyAngleFilterApplied;

  const suggestionRender = (source) => {
    return source.map((suggestion) => (
      <SuggestionCard
        key={suggestion.id}
        suggestion={suggestion}
        onSelect={() => onSelect(suggestion)}
        selected={currentSelection?.id === suggestion.id}
      />
    ));
  };

  const showRecommendedContentCard = pitchedContentCount > 0 || notAccessibleSuggestedContentCount > 0;
  const recommendedContentCardCount = pitchedContentCount + notAccessibleSuggestedContentCount;

  const recommendedTableRender = showRecommendedContentCard ? (
    <RecommendedContentCard
      count={recommendedContentCardCount}
      onClick={() => onSelect({ id: RECOMMENDED_CONTENT_ID })}
      selected={currentSelection?.id === RECOMMENDED_CONTENT_ID}
    />
  ) : null;

  const projectSummaryRender = maybeAngleFilterApplied && project.canGenerateProjectSummary && (
    <ProjectSummaryCard onSelect={onSelect} currentSelection={currentSelection} />
  );

  const privateContentRender = suggestionRender(privateContent);
  const pitchedContentRender = suggestionRender(pitchedContent);
  const accessibleContentRender = suggestionRender(accessibleContent);
  const suggestionsRender = suggestionRender(suggestedContent);

  const deliverablesRender = (
    <DeliverablesCardsList interactions={interactions} currentSelection={currentSelection} onSelect={onSelect} />
  );

  const emptyRenderer = !loading && numInteractions === 0 && (
    <EmptyResult
      variant="deliverables"
      onClearFilters={onClearFilters}
      hasAppliedFilterOrKeyword={hasAppliedFilterOrKeyword}
    />
  );

  const renderers = {
    recommendedTable: recommendedTableRender,
    projectSummary: projectSummaryRender,
    privateContent: privateContentRender,
    accessibleContent: accessibleContentRender,
    deliverables: deliverablesRender,
    pitchedContent: pitchedContentRender,
    suggestions: suggestionsRender,
    empty: emptyRenderer,
  };

  return (
    <>
      {order.map((section) => {
        return renderers[section];
      })}
    </>
  );
};

export { Card, DeliverablesCardsList, EmptyResult };
