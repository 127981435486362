import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, Divider, Filter, ListOption, useThemeTokens } from "@alphasights/alphadesign-components";
import { Filter as FilterIcon } from "@alphasights/alphadesign-icons";
import { filterLabel, TRIGGER_TYPE_NAMES, WATCHLIST_SORT_DISPLAY_NAME } from "components/WatchlistModal/consts";
import { x } from "@xstyled/styled-components";
import { INITIAL_FILTERS, TRIGGER_TYPE, WATCHLIST_SORT_BY } from "hooks/useWatchlist/consts";
import { CheckboxGroupWrapper } from "./WatchlistFilter.styled";
import { FilterWatchlistTriggerProps, WatchlistDataProps } from "hooks/useWatchlist";
import { numTriggersOfType } from "components/WatchlistModal/utils";

type WatchlistFilterProps = {
  triggerFilters: FilterWatchlistTriggerProps;
  updateTriggerFilter: (triggerFilters: any) => void;
  availableWatchlists: WatchlistDataProps[];
  isDisabled: boolean;

  watchlistId?: string;
};

const DataTestIds = {
  WatchlistTriggerFilter: "watchlist-trigger-filter",
};

const orderedSortByOptions = [
  WATCHLIST_SORT_BY.dateDesc,
  WATCHLIST_SORT_BY.dateAsc,
  WATCHLIST_SORT_BY.nameAsc,
  WATCHLIST_SORT_BY.nameDesc,
];

const orderedFilterOptions = [TRIGGER_TYPE.company, TRIGGER_TYPE.keyword];

const WatchlistFilter: FC<WatchlistFilterProps> = ({
  triggerFilters,
  updateTriggerFilter,
  availableWatchlists,
  watchlistId,
  isDisabled,
}) => {
  const [initialTriggers, setInitialTriggers] = useState(availableWatchlists[0]?.triggers || []);
  const {
    spacing: { inner },
  } = useThemeTokens();

  const selectedWatchlist = availableWatchlists.find((watchlist) => watchlist?.id === watchlistId);

  useEffect(() => {
    updateTriggerFilter(INITIAL_FILTERS);
    selectedWatchlist && setInitialTriggers(selectedWatchlist.triggers);
  }, [selectedWatchlist, updateTriggerFilter]);

  const handleOnChange = useCallback(
    (updatedFilter: any) => {
      updateTriggerFilter((prev: FilterWatchlistTriggerProps) => ({
        ...prev,
        ...updatedFilter,
      }));
    },
    [updateTriggerFilter]
  );

  const sortByOptions = useMemo(
    () =>
      orderedSortByOptions.map((sortBy) => (
        <ListOption
          key={sortBy}
          type="text"
          label={WATCHLIST_SORT_DISPLAY_NAME[sortBy]}
          size="small"
          selected={sortBy === triggerFilters.sort}
          onChange={() => handleOnChange({ sort: sortBy })}
        />
      )),
    [triggerFilters.sort, handleOnChange]
  );

  const filterOptions = useMemo(
    () =>
      orderedFilterOptions.map((trigger) => {
        const numberOfTriggers = numTriggersOfType(initialTriggers, trigger);

        return (
          <Checkbox key={trigger} value={trigger}>
            {`${TRIGGER_TYPE_NAMES[trigger]}${numberOfTriggers > 0 ? ` (${numberOfTriggers})` : ""}`}
          </Checkbox>
        );
      }),
    [initialTriggers]
  );

  return (
    <Filter
      disabled={isDisabled}
      dataAttributes={{ "data-testid": DataTestIds.WatchlistTriggerFilter }}
      filterPopoverProps={{ shouldShowFooter: false }}
      icon={<FilterIcon />}
      title={filterLabel}
      alwaysDisplayTitle
    >
      {sortByOptions}
      <Divider />
      <x.div mb={inner.base02}>
        <CheckboxGroupWrapper
          size="small"
          value={triggerFilters.filter}
          onChange={(value: any) => handleOnChange({ filter: value })}
        >
          {filterOptions}
        </CheckboxGroupWrapper>
      </x.div>
    </Filter>
  );
};

export { WatchlistFilter as default, DataTestIds };
