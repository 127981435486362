import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useActionBarStyles = () => {
  const {
    spacing: { inner, layout },
    shape: { border },
    color: { border: borderColor, background },
  } = useThemeTokens();

  const container = {
    h: layout.base06,
    backgroundColor: background.neutral.default,
    display: "flex",
    alignItems: "center",
    borderBottom: `${border.width.small} solid ${borderColor.divider}`,
  };

  const selectCheckbox = {
    w: layout.base08,
    paddingLeft: "26px",
    paddingRight: "1px",
  };

  const separador = {
    h: inner.base05,
    w: border.width.small,
    background: borderColor.divider,
    marginLeft: inner.base06,
  };

  const uploadCidButton = {
    marginLeft: inner.base06,
  };

  const downloadCidButton = {
    marginLeft: inner.base06,
  };

  const mr = {
    mr: inner.base06,
  };

  return {
    container,
    selectCheckbox,
    separador,
    uploadCidButton,
    downloadCidButton,
    mr,
  };
};
