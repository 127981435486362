import React, { FC, useEffect } from "react";
import { useQuery } from "react-query";
import { singular } from "pluralize";
import { SEARCH_SUGGESTION_TYPES, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { AngleCompanySummaryProps } from "./types";
import { CardCarousel } from "pages/AlphaNowPage/components";
import { contentService } from "services/content";
import { SPEAKER_ANGLE_TYPE } from "constants/AlphaNow";
import {
  SectionHeader,
  SectionWrapper,
  SECTION_TITLES,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { buildSearchQuery } from "content/AlphaNow/utils";

const DataTestIds = {
  AlphaNowCompanyAngleSection: "angle-section",
};

enum Variant {
  Competitors = "Competitors",
  Customers = "Customers",
}
interface AlphaNowCompanyAngleSectionProps {
  companyName: string;
  variant: Variant.Competitors | Variant.Customers;
  companyId: number;
  onDataFetch?: (data: any) => void;
  onDataBubbling?: (data: CompanyInfo[]) => void;
}

const getCardData = (
  summary: AngleCompanySummaryProps[],
  cardHeader: string,
  handleOnClick: (companyId: number, companyName: string) => void
) =>
  summary.map(({ company, numberOfContents }) => ({
    id: company.cdsAlphaCompanyId,
    header: cardHeader,
    title: company.name,
    logo: company.logo,
    subtitle: company.headquarters,
    numberRelatedContent: numberOfContents,
    handleOnClick: () => handleOnClick(company.cdsAlphaCompanyId, company.name),
  }));

const AlphaNowCompanyAngleSection: FC<AlphaNowCompanyAngleSectionProps> = ({
  companyName,
  companyId,
  variant,
  onDataFetch,
  onDataBubbling,
}) => {
  const { logHit } = useTrackUserAction();
  const { updateQuery } = useAppSearchContext();

  const isCustomerSection = variant === Variant.Customers;
  const queryParam = isCustomerSection ? SPEAKER_ANGLE_TYPE.EndCustomers : variant;
  const cardHeader = singular(variant);

  const { data, isSuccess, isError } = useQuery(
    ["companyAngleType", companyId, queryParam],
    () => contentService.fetchAlphaNowAggregatedCompaniesContent(companyId, queryParam),
    {
      enabled: !!companyId,
    }
  );

  const isSettled = isSuccess || isError;

  const summary = data?.summary ?? [];
  const hasSummary = isSuccess && summary?.length > 0;
  const numberCompetitorCardsDisplayed = 2;

  useEffect(() => {
    if (isSettled) {
      if (onDataFetch) {
        const companyIds = summary?.map(({ company }: AngleCompanySummaryProps) => company.cdsAlphaCompanyId) ?? null;
        onDataFetch({ companyIds });
      }

      if (onDataBubbling) {
        onDataBubbling(summary?.map(({ company }: AngleCompanySummaryProps) => company));
      }
    }
  }, [isSettled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectCard = (targetCompanyId: number, targetCompanyName: string) => {
    const searchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Company,
      value: targetCompanyName,
      id: targetCompanyId,
    });

    updateQuery({ searchQuery });
    logHit({
      origin: HitOrigin.companyPage,
      action: HitAction.alphaNowViewCompanyPage,
      details: { sourceCompanyId: companyId, targetCompanyId },
    });
  };

  if (!hasSummary) return null;

  const sectionTitle = isCustomerSection ? SECTION_TITLES.Customers : SECTION_TITLES.Competitors;

  return (
    <SectionWrapper
      data-testid={`${DataTestIds.AlphaNowCompanyAngleSection}-${sectionTitle.toLowerCase()}`}
      hasDivider={!isCustomerSection}
    >
      <SectionHeader title={sectionTitle} companyName={companyName} />
      <CardCarousel
        cardData={getCardData(summary, cardHeader, handleSelectCard)}
        numberCardsDisplayed={numberCompetitorCardsDisplayed}
      />
    </SectionWrapper>
  );
};

export { AlphaNowCompanyAngleSection as default, DataTestIds, Variant };
