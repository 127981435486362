import { noop } from "lodash";
import React, { useContext } from "react";

type CardsSidebarControlContextState = {
  isCollapsed: boolean;
  onExpand: () => void;
};

type CardsSidebarControlContextProps = {
  children?: JSX.Element;
} & CardsSidebarControlContextState;

const CardsSidebarControlContext = React.createContext<undefined | CardsSidebarControlContextState>(undefined);

export const CardsSidebarControlProvider = ({ isCollapsed, onExpand, children }: CardsSidebarControlContextProps) => {
  const context = {
    isCollapsed,
    onExpand,
  };
  return <CardsSidebarControlContext.Provider value={context} children={children} />;
};

export const useCardsSidebarControlContext = () =>
  useContext(CardsSidebarControlContext) ?? { isCollapsed: false, onExpand: noop };
