import { useReducer } from "react";
import { fetch } from "../../hooks/useApi";
import { withThunk } from "../../withThunk";

export const useCalendarReducer = (initialState) => withThunk(useReducer(reducer, initialState, init));

export function setSelectedAttendees(attendees) {
  return (dispatch) => dispatch({ type: "onSetSelectedAttendees", attendees });
}

export function popupOpen({ interactionId, angleId, source, token, selectedStart, duration }) {
  return (dispatch, state) => {
    if (interactionId !== state.popup.interactionId) {
      dispatch({
        type: "popupOpen",
        data: {
          interactionId,
          selectedStart,
          duration,
          attendees: { isLoading: true },
        },
      });

      Promise.all([
        fetch({
          url: `/api/projects/${token}/members?type=CLIENT`,
        }),
        fetch({
          url: `/api/projects/${token}/interactions/${interactionId}/calendar-invitations/user-attendees`,
        }),
      ])
        .then(([options, initial]) => Promise.all([options.json(), initial.json()]))
        .then(([options, initial]) => {
          const currentUserEmail = state.currentUser
            ? [
                {
                  displayName: state.currentUser.name,
                  emailAddress: state.currentUser.email,
                  optional: false,
                },
              ]
            : [];
          const useDefaults = source !== "interaction";
          const selected = useDefaults ? [...currentUserEmail, ...initial] : initial;

          const uniqueUsers = selected.reduce((acc, current) => {
            const alreadyAdded = acc.find((a) => a.emailAddress === current.emailAddress);
            return alreadyAdded ? acc : [...acc, current];
          }, []);

          dispatch({
            type: "attendees",
            data: { options, initial, selected: uniqueUsers, isLoading: false },
          });
        });
    } else {
      dispatch(
        popupStateUpdate({
          selectedStart,
          duration,
        })
      );
    }
  };
}

export function updateInitialAttendees(initial) {
  return { type: "updateInitialAttendees", data: { initial } };
}

export function popupStateUpdate(data) {
  return { type: "popupUpdateState", data };
}

export function popupStateCleanUp() {
  return { type: "popupClean" };
}

const reducer = (state, action) => {
  switch (action.type) {
    case "attendees": {
      return {
        ...state,
        popup: {
          ...state.popup,
          attendees: action.data,
        },
      };
    }
    case "onSetSelectedAttendees": {
      return {
        ...state,
        popup: {
          ...state.popup,
          attendees: {
            ...state.popup.attendees,
            selected: action.attendees,
          },
        },
      };
    }
    case "updateInitialAttendees": {
      return {
        ...state,
        popup: {
          ...state.popup,
          attendees: {
            ...state.popup.attendees,
            initial: action.data.initial,
          },
        },
      };
    }
    case "popupOpen": {
      return {
        ...state,
        popup: action.data,
      };
    }
    case "popupUpdateState": {
      return {
        ...state,
        popup: {
          ...state.popup,
          ...action.data,
        },
      };
    }
    case "popupClean": {
      return {
        ...state,
        popup: {},
      };
    }
    default:
      throw new Error(`${action.type} not found for CalendarReducer`);
  }
};

export const init = (props) => ({
  popup: {},
  ...props,
});
