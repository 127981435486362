import React, { FC } from "react";
import pluralize from "pluralize";
import { x } from "@xstyled/styled-components";
import { ComponentSize, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { format } from "date-fns";
// @ts-ignore

import { AlphaNowPurchaseModal } from "pages/AlphaNowPage/components";
import {
  availableSoonText,
  credit,
  perPrimer,
  perTranscript,
  purchaseCompanyPrimer,
  purchaseTranscript,
  upcomingHeaderText,
  forAccess,
  purchaseMarketPrimer,
  perMarketPrimer,
  perCustomerPrimer,
  purchaseCustomerPrimer,
} from "content/AlphaNow";
import { ContentAccessText } from "constants/AlphaShield";
import {
  AlphaNowContentType,
  AlphaNowProductType,
  ContentPurchaseStatus,
  ContentStatus,
} from "@alphasights/client-portal-shared";
import { ContentApprovalStatus } from "models/AlphaShield";
import { browsersLocalTime } from "content/AlphaNow/utils";
import { passedPayment, isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { HitOrigin } from "@alphasights/portal-api-client";
import { noop } from "lodash";

export const DataTestIds = {
  pricingMessage: "purchasable-pricing-message",
  purchasableHeader: "purchasable-header-container",
  purchaseMessage: "purchase-message",
  subtitleMessage: "subtitle-message",
};

const DATE_FORMAT = "d MMM yyyy, h:mma (z)";

interface Content {
  id: string;
  contentType: string;
  productType?: string;
  upcomingContentAccessRequest?: string;
  status?: string;
  canDialIn?: boolean;
}

interface AlphaNowPurchasableHeaderProps {
  content: Content;
  contentTitle: string;
  onError: (error: string) => void;
  setPrimerStatus?: (value: string) => void;
  onPurchaseSuccess: (params: OnPurchasedContentChangedParam) => void;
  price?: number;
  scheduledTimeUTC?: string;
  isContentPreScheduledTime?: boolean;
  isUpcomingTransitionActive?: boolean;
  contentApprovalStatus: ContentApprovalStatus;
  contentPurchaseStatus: ContentPurchaseStatus;
  size?: ComponentSize;
  handleButtonSize?: ComponentSize;
  className?: string;
  productType?: string;
  projectToken?: string;
  origin?: HitOrigin;
  onRequestAccessSuccess?: () => void;
}

const AlphaNowPurchasableHeader: FC<AlphaNowPurchasableHeaderProps> = ({
  content,
  contentTitle,
  onError,
  onPurchaseSuccess,
  price,
  scheduledTimeUTC,
  isContentPreScheduledTime = false,
  isUpcomingTransitionActive = false,
  contentApprovalStatus,
  contentPurchaseStatus,
  setPrimerStatus = () => {},
  size = "medium",
  className,
  projectToken,
  origin = HitOrigin.alphaNow,
  handleButtonSize = "medium",
  onRequestAccessSuccess = noop,
}) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  const { contentType, status, productType } = content;
  const isAccessible = isContentAccessible(contentPurchaseStatus, contentApprovalStatus);

  const generateUpcomingText = () => {
    const dialInEnabled = content.canDialIn === true;
    if (dialInEnabled && isContentPreScheduledTime && scheduledTimeUTC && !isUpcomingTransitionActive) {
      const formattedDate = format(browsersLocalTime(scheduledTimeUTC), DATE_FORMAT);
      return `${upcomingHeaderText} ${formattedDate}`;
    }

    return availableSoonText;
  };

  const getHeaderTitle = (): string => {
    switch (contentApprovalStatus) {
      case ContentApprovalStatus.ApprovalRequired:
        return ContentAccessText.ApprovalRequiredTitle;
      case ContentApprovalStatus.ApprovalRequested:
        return ContentAccessText.ApprovalRequestedTitle;
      case ContentApprovalStatus.Declined:
        return ContentAccessText.ApprovalDeclinedTitle;
      default:
        break;
    }

    if (status === ContentStatus.upcoming) {
      return generateUpcomingText();
    }

    if (contentType === AlphaNowContentType.srm) {
      if (productType === AlphaNowProductType.marketPrimer) {
        return purchaseMarketPrimer;
      }
      if (productType === AlphaNowProductType.customerPrimer) {
        return purchaseCustomerPrimer;
      }

      return purchaseCompanyPrimer;
    }

    return purchaseTranscript;
  };

  const getHeaderSubtitle = () => {
    if (
      status === ContentStatus.upcoming &&
      contentApprovalStatus &&
      ![ContentApprovalStatus.ApprovalNotNeeded, ContentApprovalStatus.Approved].includes(contentApprovalStatus)
    ) {
      return generateUpcomingText();
    }
  };

  const getHeaderDetails = (): string => {
    const cost = pluralize(credit, price, true);

    let contentTypeText = perTranscript;

    if (contentType === AlphaNowContentType.srm) {
      switch (productType) {
        case AlphaNowProductType.companyPrimer:
          contentTypeText = perPrimer;
          break;
        case AlphaNowProductType.marketPrimer:
          contentTypeText = perMarketPrimer;
          break;
        case AlphaNowProductType.customerPrimer:
          contentTypeText = perCustomerPrimer;
          break;
        default:
          break;
      }
    } else if (status !== ContentStatus.published) {
      contentTypeText = forAccess;
    }

    switch (contentApprovalStatus) {
      case ContentApprovalStatus.ApprovalRequired:
        return `${ContentAccessText.ApprovalRequiredDetails} ${cost}`;
      case ContentApprovalStatus.ApprovalRequested:
        return ContentAccessText.ApprovalRequestedDetails;
      case ContentApprovalStatus.Declined:
        return ContentAccessText.ApprovalDeclinedDetails;
      default:
        break;
    }

    return `${cost} ${contentTypeText}`;
  };

  const isShowHeaderDetails: boolean = !(
    price === undefined ||
    isAccessible ||
    (contentApprovalStatus === ContentApprovalStatus.ApprovalRequired && passedPayment(contentPurchaseStatus))
  );

  const headerTitle = getHeaderTitle();
  const headerSubtitle = getHeaderSubtitle();
  const headerDetails = getHeaderDetails();

  return (
    <>
      <x.div
        alignItems={{ xs: "baseline", sm: "center" }}
        data-testid={DataTestIds.purchasableHeader}
        w={{ xs: "100%", sm: "auto" }}
        position={{ xs: "fixed", sm: "relative" }}
        bottom={{ xs: 0 }}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        zIndex="1"
        flex={1}
        backgroundColor={color.background.surface.page.default}
        background={color.background.surface.page.default}
        px={inner.base08}
        py={inner.base06}
        className={className}
      >
        <div>
          <Typography
            color={color.text.strong._}
            component="p"
            data-testid={DataTestIds.purchaseMessage}
            variant={size === "small" ? "body-small-em" : "body-large-em"}
          >
            {headerTitle}
          </Typography>
          {headerSubtitle && (
            <Typography
              component="p"
              color="secondary"
              variant="body-em"
              data-testid={DataTestIds.subtitleMessage}
              paddingTop={inner.base}
            >
              {headerSubtitle}
            </Typography>
          )}
          {isShowHeaderDetails && (
            <Typography
              component="p"
              color="secondary"
              data-testid={DataTestIds.pricingMessage}
              paddingTop={inner.base}
              variant={size === "small" ? "body-small" : "body"}
            >
              {headerDetails}
            </Typography>
          )}
        </div>
        <AlphaNowPurchaseModal
          content={content}
          contentTitle={contentTitle}
          price={price}
          onError={onError}
          onPurchaseSuccess={onPurchaseSuccess}
          isContentPreScheduledTime={isContentPreScheduledTime}
          isUpcomingTransitionActive={isUpcomingTransitionActive}
          contentApprovalStatus={contentApprovalStatus}
          contentPurchaseStatus={contentPurchaseStatus}
          setPrimerStatus={setPrimerStatus}
          projectToken={projectToken}
          handleButtonSize={handleButtonSize}
          origin={origin}
          onRequestAccessSuccess={onRequestAccessSuccess}
        />
      </x.div>
    </>
  );
};

export default AlphaNowPurchasableHeader;
