import { useLocation, useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { useMemo } from "react";

const useQueryParams = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();

  const value = useMemo(
    () => ({
      get: (key) => query.get(key),
      set: (key, value) => {
        query.set(key, value);
        history.push({
          pathname,
          search: `?${query}`,
        });
      },
      delete: (key) => {
        query.delete(key);
        history.push({
          pathname,
          search: `?${query}`,
        });
      },
    }),
    [pathname, history, query]
  );

  return value;
};

export default useQueryParams;
