import React, { ForwardedRef, useState } from "react";
import { CSSProperties, x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Button, Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import {
  AngleFilter,
  ContentTypeFilter,
  DateFilter,
  PurchasedFilter,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters";
import { FilterFormState } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/types";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { FILTERS, SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import {
  SHOW_RESULTS,
  CANCEL,
  RESET,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/FilterForm/constants";
import CompanyDataFilters from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/CompanyDataFilters";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";

import {
  FilterFormContainer,
  FilterFormButtonsWrapper,
  FilterFormWrapper,
  TitleWrapper,
  FilterViewWrapper,
  StyledConfirmButton,
} from "./FilterForm.styled";

const DataTestIds = {
  filtersContainer: "filters-container",
  showResultsButton: "show-results-button",
};

type FilterFormProps = {
  value: FilterFormState;
  onChange: (value: Partial<FilterFormState>) => void;
  onConfirm: () => void;
  isPreviewLoading: boolean;

  onClose?: () => void;
  onClear?: () => void;
  isCancelDisabled?: boolean;
  isConfirmDisabled?: boolean;
  numResults?: string;
  maxHeight?: CSSProperties["maxHeight"];
};

const FilterForm = React.forwardRef(
  (
    {
      value,
      onChange,
      onConfirm,
      isPreviewLoading,
      numResults,
      maxHeight,
      onClose = () => {},
      onClear = () => {},
      isConfirmDisabled = false,
      isCancelDisabled = false,
    }: FilterFormProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isCompanyDataFilterView, setIsCompanyDataFilterView] = useState(false);

    const { spacing } = useThemeTokens();
    const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();
    const { isMobile } = useCheckScreen();
    const { hasUserBadge } = useUserBadgeContext();

    // TODO [RD1-134]: Remove Company Metadata Badge
    const hasCompanyMetadataBadge = hasUserBadge(Badge.companyMetadata);
    const showCompanyDataFilters = !isMobile && hasCompanyMetadataBadge;

    const { contentType, callDate, angle, purchasedFilter, headcount, revenue, ownershipType, hqLocation } = value;
    const companyDataFilters = {
      headcount,
      revenue,
      ownershipType,
      hqLocation,
    };

    const showLoadingSpinner = isPreviewLoading && !isConfirmDisabled;
    const hasNumResults = !showLoadingSpinner && !!numResults && !isConfirmDisabled;

    const handleClearFilter = (key: SearchFilters, defaultValue: any) => {
      onChange({ [key]: defaultValue });
    };

    const handleClickConfirm = () => {
      onConfirm();
      onClose();
    };

    const handleClickCancel = isMobile ? onClose : onClear;

    const buttonSize = isMobile ? ("medium" as "medium") : ("small" as "small");

    return (
      <FilterFormWrapper data-testid={DataTestIds.filtersContainer}>
        <FilterFormContainer ref={ref} maxHeight={maxHeight}>
          {isMobile && (
            <TitleWrapper>
              <Typography variant="h2">{FILTERS}</Typography>
            </TitleWrapper>
          )}
          {showCompanyDataFilters && (
            <CompanyDataFilters
              isOpen={isCompanyDataFilterView}
              setIsOpen={setIsCompanyDataFilterView}
              onFilterChange={onChange}
              value={companyDataFilters}
            />
          )}
          <FilterViewWrapper isVisible={!isCompanyDataFilterView}>
            {!isCompanyDataFilterView && (
              <>
                {isMobile && <Divider />}
                <DateFilter value={callDate} onChange={onChange} onClear={handleClearFilter} />
                <Divider />
                {!hasOnlyPremiumContent && (
                  <PurchasedFilter value={purchasedFilter} onChange={onChange} onClear={handleClearFilter} />
                )}
                <Divider />
                <ContentTypeFilter value={contentType} onChange={onChange} onClear={handleClearFilter} />
                <Divider />
                <AngleFilter value={angle} onChange={onChange} onClear={handleClearFilter} />
              </>
            )}
          </FilterViewWrapper>
        </FilterFormContainer>
        <FilterFormButtonsWrapper>
          <Button variant="ghost" onClick={handleClickCancel} size={buttonSize} disabled={isCancelDisabled}>
            {isMobile ? CANCEL : RESET}
          </Button>
          <StyledConfirmButton
            dataAttributes={{ "data-testid": DataTestIds.showResultsButton }}
            variant="secondary"
            size={buttonSize}
            onClick={handleClickConfirm}
            disabled={isConfirmDisabled}
            loading={showLoadingSpinner}
          >
            <x.div display="flex" gap={spacing.inner.base}>
              {SHOW_RESULTS}
              {hasNumResults && (
                <Typography variant={isMobile ? "body" : "body-small"} component="span">
                  ({numResults})
                </Typography>
              )}
            </x.div>
          </StyledConfirmButton>
        </FilterFormButtonsWrapper>
      </FilterFormWrapper>
    );
  }
);

export { FilterForm as default, DataTestIds };
