import React, { PropsWithChildren, useRef } from "react";
import { IconButton, Popover, PopoverProps, Tooltip } from "@alphasights/alphadesign-components";
import { offset, hide, detectOverflow } from "@floating-ui/react-dom";
import styled, { x } from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

// visibility property has transitions that make the popover still appear momentarily
// display: none does not work and that's why we are using zIndex
// to hide the element here
const HIDE = {
  zIndex: -1,
};

type FloatingActionsProps = {
  actions: FloatingAction[];
  highlightOnHover?: boolean;
} & PropsWithChildren;

export interface FloatingAction {
  icon: JSX.Element;
  testid?: string;
  tooltip: string;
  onClick: () => void;
}

export const FloatingActions = ({
  actions,
  children,
  highlightOnHover = true,
  ...props
}: PopoverProps & FloatingActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <FloatingActionsWrapper highlightOnHover={highlightOnHover}>
      <x.div
        onMouseEnter={(event: React.MouseEvent) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {children}
      </x.div>
      {actions.length > 0 && (
        <Popover
          className="floating-actions"
          portal={false}
          ref={ref}
          closeOnMouseLeave
          size="small"
          anchorEl={anchorEl}
          strategy="fixed"
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(undefined)}
          placement="right-start"
          middleware={[
            offset(() => ({
              mainAxis: -(ref.current?.getBoundingClientRect()?.width ?? 0),
            })),
            hide({
              strategy: "escaped",
            }),
            {
              name: "overflow",
              fn: async (state: any) => {
                const overflow = await detectOverflow(state, {
                  boundary: document.querySelector("[data-summary-scrollable-area]") || undefined,
                });
                return {
                  data: { clipping: overflow.top > 0 },
                };
              },
            },
          ]}
          onFloatingData={(data) => {
            if (data.middlewareData.hide?.escaped) return HIDE;
            if (data.middlewareData.overflow?.clipping) return HIDE;

            return {};
          }}
          {...props}
        >
          <x.div display="flex" gap="4px">
            {actions.map((action, index) => (
              <Tooltip key={index} title={action.tooltip} position="top">
                <IconButton
                  variant="ghost"
                  size="small"
                  onClick={action.onClick}
                  // @ts-ignore
                  buttonAttributes={{ "data-testid": `action-${action.testid}` }}
                  xStyledProps={{ h: "24px", w: "24px" }}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            ))}
          </x.div>
        </Popover>
      )}
    </FloatingActionsWrapper>
  );
};

const FloatingActionsWrapper = styled.div(({ highlightOnHover }: { highlightOnHover?: boolean }) => {
  return `
    display: block;
    width: 100%;
    & > div:has(.floating-actions) ~ .floating-actions {
      display: none;
    }
    &:has(.floating-actions) {
      background-color: ${highlightOnHover ? tokens.color.background.highlightBase : "transparent"};
    }
    &:has(div .floating-actions) {
      background-color: transparent !important;
    }
    transition-delay: 150ms;
    transition-property: background;
  `;
});
