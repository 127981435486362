import React, { useCallback, useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { flatExpertsWithRequests } from "components/InteractionsPage/helpers/Interaction";
import { MobileExpertsList } from "./MobileExpertsList";
import { ShowHiddenExpertsButton } from "./ShowHiddenExpertsButton";
import { IconButton, ToggleButton } from "@alphasights/alphadesign-components";
import { Close, PageInfo, Search } from "@alphasights/alphadesign-icons";
import { MobileInteractionsFilter } from "./MobileInteractionsFilter";
import { isFuture } from "date-fns";
import { parseISO } from "../../providers/TimezoneProvider";
import { sortBy } from "lodash";

export const MobileInteractionsPage = ({
  project,
  workstream,
  experts,
  clientRequests,
  isProjectCidEnabled,
  userCidEnabled,
  transcriptEnabled,
  appliedFilters,
  filterOptions,
  searchQuery,
  onSearch,
  onSearchQueryChange,
  onSelectCard,
  onToggleMobileNav,
  onResetAllFilters,
  onSubmitFilters,
}) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [showHiddenExperts, setShowHiddenExperts] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [hasAvailability, setHasAvailability] = useState(false);

  const interactions = useMemo(() => flatExpertsWithRequests(experts, clientRequests), [experts, clientRequests]);

  const filteredInteractions = useMemo(() => {
    return !hasAvailability
      ? interactions
      : interactions.filter((it) =>
          (it?.advisorAvailability || []).find(({ startsAt, endsAt }) => isFuture(parseISO(startsAt)))
        );
  }, [interactions, hasAvailability]);

  const hasNewlyAddedExperts = useMemo(() => filteredInteractions.filter((i) => i.newlyAdded).length > 0, [
    filteredInteractions,
  ]);

  const effectiveSelectedTab = useMemo(() => (searchQuery ? "all" : selectedTab), [searchQuery, selectedTab]);

  const selectedInteractions = useMemo(() => {
    const tabInteractions =
      effectiveSelectedTab === "newlyAdded" ? filteredInteractions.filter((i) => i.newlyAdded) : filteredInteractions;

    return sortBy(
      tabInteractions.filter(({ hidden }) => (showHiddenExperts ? true : !hidden)),
      "hidden"
    );
  }, [effectiveSelectedTab, filteredInteractions, showHiddenExperts]);

  const handleHiddenExperts = () => setShowHiddenExperts((prev) => !prev);

  const handleShowFilter = (show) => {
    setShowFilter(show);
    onToggleMobileNav(!show);
  };

  return (
    <x.div data-testid="mobile-interactions-page">
      <MobileInteractionsPageTopBar
        projectTitle={project.title}
        selectedTab={effectiveSelectedTab}
        setSelectedTab={setSelectedTab}
        showFilter={showFilter}
        searchQuery={searchQuery}
        onSearchQueryChange={onSearchQueryChange}
        onSearch={onSearch}
        onShowFilter={handleShowFilter}
        hasNewlyAddedExperts={hasNewlyAddedExperts}
      />
      {!showFilter ? (
        <>
          <MobileExpertsList
            interactions={selectedInteractions}
            isProjectCidEnabled={isProjectCidEnabled}
            userCidEnabled={userCidEnabled}
            onSelectInteraction={onSelectCard}
          />
          {effectiveSelectedTab === "all" && (
            <ShowHiddenExpertsButton
              interactions={filteredInteractions}
              isShowing={showHiddenExperts}
              onClick={handleHiddenExperts}
            />
          )}
        </>
      ) : (
        <MobileInteractionsFilter
          project={project}
          workstream={workstream}
          appliedFilters={appliedFilters}
          options={filterOptions}
          transcriptEnabled={transcriptEnabled}
          hasAvailability={hasAvailability}
          onFilterApply={() => handleShowFilter(false)}
          onResetAllFilters={onResetAllFilters}
          onSubmitFilters={onSubmitFilters}
          onToggleAvailabilityFilter={(hasAvailability) => setHasAvailability(hasAvailability)}
        />
      )}
    </x.div>
  );
};

const MobileInteractionsPageTopBar = ({
  projectTitle,
  selectedTab,
  setSelectedTab,
  showFilter,
  searchQuery,
  onSearchQueryChange,
  onSearch,
  onShowFilter,
  hasNewlyAddedExperts,
}) => {
  const [topBarVariant, setTopBarVariant] = useState(searchQuery ? "search" : "regular");
  const [keywords, setKeywords] = useState(searchQuery ? [searchQuery] : []);

  const onSearchClose = useCallback(() => {
    setTopBarVariant("regular");
    setKeywords([]);
    onSearchQueryChange("");
    onSearch("");
  }, [onSearch, onSearchQueryChange]);

  const onKeywordsMatch = useCallback(
    (keywords) => {
      onSearchQueryChange(keywords.join(" "));
      onSearch(keywords.join(" "));
      setKeywords(keywords);
    },
    [onSearch, onSearchQueryChange]
  );

  const onFilterToggle = () => {
    setTopBarVariant("regular");
    onShowFilter(true);
  };

  useEffect(() => {
    if (!searchQuery) {
      setTopBarVariant("regular");
    }
  }, [searchQuery]);

  useEffect(() => {
    if (hasNewlyAddedExperts) {
      setSelectedTab("newlyAdded");
    } else {
      setSelectedTab("all");
    }
  }, [hasNewlyAddedExperts, setSelectedTab]);

  const filterButton = (
    <IconButton size="large" variant="ghost" onClick={onFilterToggle} testId="mobile-top-bar-filter-button">
      <PageInfo />
    </IconButton>
  );

  const topBarButtons = (
    <>
      {showFilter ? (
        <IconButton
          size="large"
          variant="ghost"
          onClick={() => onShowFilter(false)}
          testId="mobile-top-bar-filter-open-btn"
        >
          <Close />
        </IconButton>
      ) : (
        <>
          <IconButton
            size="large"
            variant="ghost"
            onClick={() => setTopBarVariant("search")}
            testId="mobile-top-bar-search-open-btn"
          >
            <Search />
          </IconButton>
          {filterButton}
        </>
      )}
    </>
  );

  return (
    <PortalMobileTopBar
      title={projectTitle}
      defaultNavValue={selectedTab}
      regularButtons={topBarButtons}
      searchButton={filterButton}
      showFilter={showFilter}
      variant={topBarVariant}
      onSearchClose={onSearchClose}
      onKeywordsMatch={onKeywordsMatch}
      keywords={keywords}
      autoFocusSearch
      zIndex={1}
    >
      {!showFilter && hasNewlyAddedExperts && !searchQuery && (
        <>
          <ToggleButton value="newlyAdded" sizeVariant="small" onClick={() => setSelectedTab("newlyAdded")}>
            Newly Added
          </ToggleButton>
          <ToggleButton value="all" sizeVariant="small" onClick={() => setSelectedTab("all")}>
            All Experts
          </ToggleButton>
        </>
      )}
    </PortalMobileTopBar>
  );
};
