import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const useCompanyPrimerCompetitorCardStyles = () => {
  const { isMobile } = useCheckScreen();
  const { spacing, color, shape } = useThemeTokens();

  const companyPrimerCompetitorCard = isMobile
    ? {
        border: 0,
        borderBottom: {
          _: `${shape.border.width.sm} solid ${color.border.divider}`,
          last: 0,
        },
        borderRadius: 0,
        p: 0,
        pb: { _: spacing.inner.base05, last: 0 },
      }
    : {
        pl: spacing.inner.base12,
      };

  return { companyPrimerCompetitorCard };
};

export default useCompanyPrimerCompetitorCardStyles;
