import { INITIAL_QUERY_STATE } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";

const INITIAL_CONTEXT_STATE = Object.freeze({
  query: { ...INITIAL_QUERY_STATE },
  updateQuery: () => {},
  navigate: () => {},
  goBack: () => {},
  goForward: () => {},
  isBackDisabled: true,
  isForwardDisabled: true,
  searchErrorMessage: "",
  setSearchErrorMessage: () => {},
  isNavigating: false,
});

export { INITIAL_CONTEXT_STATE };
