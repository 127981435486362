import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTExpertRecommendationStyles = () => {
  const {
    spacing: { inner, layout },
    shape: { border },
    color: { border: borderColor, text, background, icon },
  } = useThemeTokens();

  const contentWrapper = {
    display: "flex",
    gap: inner.base06,
    flexDirection: "column",
  };

  const expertRecomendation = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
  };

  const viewAllExpertsButton = {
    w: "fit-content",
  };

  const requestPrimerButton = {
    w: "fit-content",
  };

  const expertCard = {
    mt: 0,
    p: layout.base02,
  };

  const expertCardHeader = {
    display: "flex",
    justifyContent: "space-between",
  };

  const expertCardInfo = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base,
  };

  const expertCardSeparator = {
    h: border.width.sm,
    background: borderColor.neutral.default,
    mt: layout.base,
    mb: layout.base,
  };

  const requestedExpertStatus = {
    textColor: text.success,
    color: background.success,
    iconColor: icon.success,
  };

  const requestedExpertButton = {
    minWidth: "fit-content",
  };

  return {
    contentWrapper,
    expertRecomendation,
    expertCard,
    viewAllExpertsButton,
    requestPrimerButton,
    expertCardHeader,
    expertCardInfo,
    expertCardSeparator,
    requestedExpertStatus,
    requestedExpertButton,
  };
};
