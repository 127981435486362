import React, { useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Avatar, Button, Icon, IconButton, Modal, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { useAlphaGPTMessageStyles } from "./AlphaGPTMessage.styles";
import { Debug, ExternalLink, GenerativeAi, Transcript } from "@alphasights/alphadesign-icons";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import _ from "lodash";
import { useAccessControl } from "hooks/useAccessControl";
import { MessageType } from "models/AlphaGpt";
import {
  AlphaGPTContentRecommendation,
  AlphaGPTReplyIcon,
} from "../AlphaGPTContentRecommendation/AlphaGPTContentRecommendation";
import { AlphaGPTMessageContent } from "../AlphaGPTMessageContent/AlphaGPTMessageContent";
import { useAlphaGPTMessageContext } from "providers/AlphaGPTMessageProvider";
import { AlphaGPTExpertRecommendation } from "../AlphaGPTExpertRecommendation/AlphaGPTExpertRecommendation";

export interface AlphaGPTMessageProps {
  showSeparator?: boolean;
  collapseCitations?: boolean;
}

const AlphaGPTMessage = ({ showSeparator = true, collapseCitations = false }: AlphaGPTMessageProps) => {
  const { sender } = useAlphaGPTMessageContext();
  const { messageWrapper } = useAlphaGPTMessageStyles();

  return (
    <>
      <x.div {...messageWrapper}>
        {sender === "GPT" ? <AlphaGPTAvatar /> : <UserAvatar />}
        <MessageBody collapseCitations={collapseCitations} />
      </x.div>
      {showSeparator && <MessageSeparator />}
    </>
  );
};

export const AlphaGPTMessageSkeleton = ({ showSeparator }: { showSeparator: boolean }) => {
  const { messageWrapper } = useAlphaGPTMessageStyles();
  return (
    <>
      <x.div {...messageWrapper} gap="0">
        <x.div w="50px">
          <Skeleton circle height="50px" variant="noMargin" />
        </x.div>
        <Skeleton count={3} />
      </x.div>
      {showSeparator && <MessageSeparator />}
    </>
  );
};

const MessageBody = ({ collapseCitations }: { collapseCitations: boolean }) => {
  const {
    id,
    sender,
    text,
    suggestedQuestions,
    followUpQuestions,
    type = MessageType.Respond,
  } = useAlphaGPTMessageContext();
  const { hasValidAlphaGPTPurchase } = useAlphaGPTContext();
  const { messageBody } = useAlphaGPTMessageStyles(!hasValidAlphaGPTPurchase);
  const ref = useRef(null);
  const showFooter = sender === "GPT" && id.length > 0;
  const showSuggestedQuestions = (suggestedQuestions?.length ?? 0) > 0;
  const showFollowUpQuestions = (followUpQuestions?.length ?? 0) > 0 && !showSuggestedQuestions;

  return (
    <x.div {...messageBody}>
      {text && <AlphaGPTMessageContent collapseCitations={collapseCitations} ref={ref} />}
      {showSuggestedQuestions && <SuggestedQuestions />}
      {type === MessageType.GetContentRecommendations && (
        <AlphaGPTContentRecommendation ref={ref} collapse={collapseCitations} />
      )}
      {type === MessageType.GetExpertRecommendations && (
        <AlphaGPTExpertRecommendation ref={ref} collapse={collapseCitations} />
      )}
      {showFollowUpQuestions && <FollowUpQuestionsSection />}
      {showFooter && <MessageFooter messageRef={ref} />}
    </x.div>
  );
};

const FollowUpQuestionsSection = () => {
  const { followUpQuestions } = useAlphaGPTMessageContext();
  const { followUpQuestionsSection } = useAlphaGPTMessageStyles();

  return (
    <>
      {followUpQuestions && followUpQuestions?.length > 0 && (
        <x.div {...followUpQuestionsSection}>
          {followUpQuestions?.map((followUpQuestion) => (
            <FollowUpQuestion question={followUpQuestion.question} />
          ))}
        </x.div>
      )}
    </>
  );
};

const FollowUpQuestion = ({ question }: { question: string }) => {
  const { followUpQuestionButton } = useAlphaGPTMessageStyles();
  const { submitQuery } = useAlphaGPTContext();

  return (
    <Button
      variant="outline"
      size="small"
      startIcon={<AlphaGPTReplyIcon />}
      onClick={() => submitQuery(question)}
      {...followUpQuestionButton}
    >
      {question}
    </Button>
  );
};

const SuggestedQuestions = () => {
  const { suggestedQuestions = [] } = useAlphaGPTMessageContext();
  const { suggestedQuestionWrapper, suggestedQuestionsWrapper, suggestedQuestionIcon } = useAlphaGPTMessageStyles();
  const { setQuery } = useAlphaGPTContext();
  return (
    <x.ul {...suggestedQuestionsWrapper}>
      {suggestedQuestions.map((question, idx) => (
        <x.li key={question}>
          <x.div
            onClick={() => setQuery(question)}
            data-testid={`suggested-question-${idx}`}
            {...suggestedQuestionWrapper}
          >
            <x.div {...suggestedQuestionIcon}>
              <Icon color="secondary">
                <AlphaGPTReplyIcon />
              </Icon>
            </x.div>
            <Typography variant="body-small-em" color="strong">
              {question}
            </Typography>
          </x.div>
        </x.li>
      ))}
    </x.ul>
  );
};

const MessageFooter = ({ messageRef }: { messageRef: React.MutableRefObject<HTMLElement | null> }) => {
  const { messageFooter } = useAlphaGPTMessageStyles();
  const { projectToken } = useAlphaGPTContext();
  const { citations } = useAlphaGPTMessageContext();
  const showDebug = useAccessControl(["read_alphagpt_metadata"], false);

  const footerItems = _.compact([!projectToken && citations && <ViewAllButton />, showDebug && <DebugButton />]);

  if (!footerItems.length) {
    return null;
  }

  return (
    <x.div data-testid="gpt-message-footer" {...messageFooter}>
      {footerItems.map((item) => item)}
    </x.div>
  );
};

const ViewAllButton = () => {
  const { citations } = useAlphaGPTMessageContext();

  if (!citations?.length) {
    return null;
  }

  const handleClick = () => {
    citations.forEach((r) => window.open(r.link, "_blank"));
  };

  return (
    <Button
      variant="outline"
      size="small"
      startIcon={<Transcript />}
      endIcon={<ExternalLink />}
      onClick={handleClick}
      data-testid="view-all-button"
    >
      View All
    </Button>
  );
};

const DebugButton = () => {
  const { metadata } = useAlphaGPTMessageContext();
  const { debugText } = useAlphaGPTMessageStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <IconButton variant="outline" size="small" onClick={() => setOpen(true)} testId="debug-button">
        <Debug />
      </IconButton>
      <Modal
        title="Debug data"
        open={open}
        onClose={() => setOpen(false)}
        variant="complex"
        primaryButton={
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
        }
        shouldShowFooter
        slotWidth="624px"
      >
        <Typography color="strong" variant="body-small" component="div">
          <x.pre {...debugText}>{JSON.stringify(metadata, null, 2)}</x.pre>
        </Typography>
      </Modal>
    </>
  );
};

export const MessageSeparator = () => {
  const { messageSeparator } = useAlphaGPTMessageStyles();

  return <x.div {...messageSeparator} />;
};

const AlphaGPTAvatar = () => (
  <Avatar flex="0 0 auto" size="large" color="base04">
    <GenerativeAi />
  </Avatar>
);

const UserAvatar = () => {
  const currentUser = useCurrentUser();

  return <Avatar flex="0 0 auto" size="large" color="base02" text={currentUser?.name} />;
};

export default AlphaGPTMessage;
