import React, { useState } from "react";
import { fetch } from "hooks/useApi";

import { Variant, withAccessControl } from "components/AccessControl/AccessControl";
import { ResponsiveModal } from "components/ResponsiveModal";
import { Alert, Button, TextArea, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { noop } from "lodash";

const ButtonWithAccess = withAccessControl(Button);

export enum CidStatus {
  Blocked = "BLOCKED",
  Cleared = "CLEARED",
}

export type CidCompaniesModalProps = {
  projectToken: string;
  isModalOpen?: boolean;
  onClose: () => void;
  onCidCompaniesModalUpload: () => void;
};

export const CidCompaniesModal = ({
  projectToken,
  isModalOpen,
  onClose,
  onCidCompaniesModalUpload,
}: CidCompaniesModalProps) => {
  const [companies, setCompanies] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [processingCidClear, setProcessingCidClear] = useState(false);
  const [processingCidBlock, setProcessingCidBlock] = useState(false);

  const onSubmitCompanies = (cidStatus: CidStatus) => {
    setError(null);
    cidStatus === CidStatus.Cleared ? setProcessingCidClear(true) : setProcessingCidBlock(true);
    const companiesArray = companies.split("\n");
    submitCompaniesCidPromise(companiesArray, cidStatus)
      .then(() => {
        setCompanies("");
        setError(null);
        onClose();
        onCidCompaniesModalUpload();
      })
      .catch(() => {
        setError("Error when uploading the CID Companies. Please, contact your project lead for support.");
      })
      .finally(() => (cidStatus === CidStatus.Cleared ? setProcessingCidClear(false) : setProcessingCidBlock(false)));
  };

  const submitCompaniesCidPromise = async (companies: string[], cidState: string) => {
    return await fetch({
      url: `/api/projects/${projectToken}/conflict-identification`,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        companies: companies,
        cidState: cidState,
      }),
    });
  };

  const { modalContainer, list } = useCidCompaniesModalStyles();

  return (
    <ResponsiveModal
      open={isModalOpen}
      onClose={() => {
        setError(null);
        onClose();
      }}
      title="Bulk upload CID companies"
      testId="cid-modal"
      showCloseIcon={true}
      slotWidth={"540px"}
      primaryButton={
        <ButtonWithAccess
          accessControl={{
            allowedPermissions: ["cid"],
            variant: Variant.NotAllowed,
          }}
          variant="secondary"
          onClick={() => onSubmitCompanies(CidStatus.Blocked)}
          data-testid="upload-cid-blocked"
          loading={processingCidBlock}
          disabled={processingCidClear}
        >
          Upload As CID Block
        </ButtonWithAccess>
      }
      secondaryButton={
        <ButtonWithAccess
          accessControl={{
            allowedPermissions: ["cid"],
            variant: Variant.NotAllowed,
          }}
          variant="secondary"
          onClick={() => onSubmitCompanies(CidStatus.Cleared)}
          data-testid="upload-cid-cleared"
          loading={processingCidClear}
          disabled={processingCidBlock}
        >
          Upload As CID Clear
        </ButtonWithAccess>
      }
    >
      <Typography {...modalContainer} component="span">
        {error && <Alert variant="danger">{error}</Alert>}
        <x.ul {...list}>
          <li>
            <Typography component="span">One company per line</Typography>
          </li>
          <li>
            <Typography component="span">
              Paste list of companies and then select to either upload as Cleared or Blocked companies
            </Typography>
          </li>
          <li>
            <Typography component="span">Please remove any bullet points or numbers, e.g. ‘1. Company X’</Typography>
          </li>
        </x.ul>
        <TextArea
          placeholder="Paste companies here..."
          width="auto"
          onChange={(event) => setCompanies(event.target.value)}
          htmlAttributes={{ onKeyDown: noop }} // This is to override ADS behavior and allow user to break lines with Enter instead of Shift + Enter
        />
      </Typography>
    </ResponsiveModal>
  );
};

const useCidCompaniesModalStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const modalContainer = {
    h: "100%",
    display: "flex",
    flexDirection: "column",
    gap: inner.base06,
    flexGrow: 1,
  };

  const list = {
    listStyleType: "inherit",
    listStylePosition: "outside",
    ml: inner.base06,
    fontSize: 8,
  };

  return {
    modalContainer,
    list,
  };
};
