import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useAdvisorFlyOutStyles } from "pages/InteractionPage/AdvisorFlyOut.styles";

export const useSidebarStyles = () => {
  const {
    color: { border: borderColor },
    shape: { border },
  } = useThemeTokens();

  const { sidebar } = useAdvisorFlyOutStyles();

  const contentSidebar = {
    ...sidebar,
    borderLeftWidth: border.width.sm,
    borderStyle: "solid",
    borderColor: borderColor.divider,
    display: "flex",
  };

  return {
    sidebar: contentSidebar,
  };
};
