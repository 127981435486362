import { Avatar, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const AvatarWrapper = styled(Avatar)(() => {
  const {
    spacing: { inner },
    color: { border },
    shape: {
      border: { width },
    },
  } = useThemeTokens();
  return {
    outlineColor: border.divider,
    borderWidth: inner.base,
    borderColor: border.dividerInverse,
    outlineWidth: width.sm,
  };
});

export const LogoWrapper = styled.div(({ backgroundColor }: { backgroundColor: string }) => {
  const {
    shape: {
      border: { radius },
    },
  } = useThemeTokens();

  return {
    height: "100px",
    backgroundColor,
    borderRadius: radius.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});
