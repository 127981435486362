import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useDownloadAttachmentsDrawerStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    drawerContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base02,
    },
    attachmentItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    attachmentInfo: {
      display: "flex",
      alignItems: "center",
      padding: inner.base02,
      gap: inner.base02,
    },
  };
};
