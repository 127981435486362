import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const CardWrapper = styled.div(({ disabled }: { disabled: boolean }) => {
  const {
    color: { border },
    spacing: { inner },
    shape: {
      border: { width },
    },
  } = useThemeTokens();

  return {
    "&:hover": {
      borderColor: border.selected,
      cursor: "pointer",
    },
    width: "360px",
    height: "auto",
    padding: inner.base02,
    border: `${width.sm} solid ${border.disabled}`,
    borderRadius: inner.base04,
  };
});

export const CardHeaderWrapper = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: inner.base02,
    alignContent: "center",
  };
});
