import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";
import { Badge } from "components/Badge";
import { AlphaCircle } from "@alphasights/alphadesign-icons";
import { useExpertChargesListStyles } from "./ExpertChagesList.styles";

interface ExpertChargesListProps {
  experts: any[];
}

export const ExpertChargesList: React.FC<ExpertChargesListProps> = ({ experts = [] }) => {
  const { container, expertName, expertDiv } = useExpertChargesListStyles();

  if (experts == null || experts.length === 0) return null;

  return (
    <x.div {...container} data-testid="expert-charges-list">
      {experts.map((expert: any, index: number) => (
        <x.div {...expertDiv} key={`expert-charge-${index}`}>
          <Typography variant="body" {...expertName}>
            {`${expert.credits}  •  ${expert.name}`}
          </Typography>
          {expert.alphaCircleMultiplier > 1 && (
            <Badge
              tooltipText={`Available at a rate of ${expert.alphaCircleMultiplier} credits per hour`}
              value={expert.alphaCircleMultiplier}
              icon={<AlphaCircle />}
              data-testid="alphacircle-badge"
            />
          )}
        </x.div>
      ))}
    </x.div>
  );
};
