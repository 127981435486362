import React, { useEffect, useState } from "react";
import { SelectMenu, SelectOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { typography } from "@alphasights/alphadesign-tokens/dist/js/base/tokens-docs";
import { x } from "@xstyled/styled-components";
import CommissionModalSearch from "./CommissionModalSearch";
import { PrimerTypes } from "models/primers/constants";
import { createRequestFormEntry, getCreditsValue } from "../mappers";
import { AddModalRowButton } from "../CommissionModalContent/CommissionModalContent";
import {
  CommissionPrimerProperties,
  COMMISSION_MODAL_FIT,
  PRIMER_WORKING_DAYS,
  companyErrorMessage,
  formatErrorMessage,
  PRIMER_WORKING_DAYS_RUSH,
} from "../../constants";
import PrimerTypeButtonGroup from "../CommissionModalContent/PrimerTypeButtonGroup";
import { CONTENT_TYPE, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";
import { ModalPrimerRow } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";
import CommissionModalRush from "../CommissionModalContent/CommissionModalRush";

export const testIds = {
  selectPrimerFormat: "select-primer-format",
  searchRow: "commission-modal-search-row",
};

const SearchRow = ({
  addRow,
  isEditing,
  setIsEditing,
}: {
  addRow: (searchRequestFormRow: ModalPrimerRow) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const initialFormState = createRequestFormEntry({
    companyId: "",
    companyName: "",
    type: PrimerTypes.ALPHA_NOW,
    format: "",
    credits: 0,
    logoLinks: [""],
    rush: false,
    isRecommended: false,
  });

  const [searchRequestFormRow, setSearchRequestFormRow] = useState<ModalPrimerRow>(initialFormState);

  const [searchQuery, setSearchQuery] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<{ companyName: string; format: string }>({
    companyName: "",
    format: "",
  });
  const [primerType, setPrimerType] = useState(PrimerTypes.ALPHA_NOW);

  const formatOptions = () => {
    const optionsKey = searchRequestFormRow.rush ? PRIMER_WORKING_DAYS_RUSH : PRIMER_WORKING_DAYS;

    return [
      {
        format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
        workingDays: optionsKey.companyPrimer,
      },
      {
        format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
        workingDays: optionsKey.marketPrimer,
      },
      {
        format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
        workingDays: optionsKey.customerPrimer,
      },
    ];
  };

  const onChangeProperties = (value: any, property: string) => {
    setSearchRequestFormRow((prevForm) => {
      const updatedForm = { ...prevForm };
      const companyFormat = updatedForm.requestedCompanyFormat;

      switch (property) {
        case CommissionPrimerProperties.REQUESTED_COMPANY_ID:
          updatedForm.requestedCompanyId = value ?? "";
          break;
        case CommissionPrimerProperties.REQUESTED_COMPANY_NAME:
          updatedForm.requestedCompanyName = value ?? "";
          break;
        case CommissionPrimerProperties.REQUESTED_COMPANY_FORMAT:
          if (!value) {
            updatedForm.rush = false;
          }

          updatedForm.credits = getCreditsValue(value, updatedForm.requestedCompanyType, updatedForm.rush);
          updatedForm.requestedCompanyFormat = value ?? "";
          break;
        case CommissionPrimerProperties.REQUESTED_COMPANY_TYPE:
          updatedForm.requestedCompanyType = value;
          updatedForm.credits = getCreditsValue(companyFormat, value, updatedForm.rush);
          break;
        case CommissionPrimerProperties.REQUESTED_COMPANY_RUSH:
          updatedForm.rush = !!value;
          updatedForm.credits = !!companyFormat
            ? getCreditsValue(companyFormat, updatedForm.requestedCompanyType, updatedForm.rush)
            : 0;

          break;
        case CommissionPrimerProperties.REQUESTED_COMPANY_LOGO:
          updatedForm.logoLinks = [value];
          break;
        default:
          break;
      }

      return updatedForm;
    });
  };

  const handleAddRowClick = () => {
    if (onAddValidation()) {
      addRow(searchRequestFormRow);

      setSearchRequestFormRow(initialFormState);
      setErrorMessage({ companyName: "", format: "" });
      setSearchQuery([]);
      setPrimerType(PrimerTypes.ALPHA_NOW);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0) setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    const { requestedCompanyName, requestedCompanyFormat } = searchRequestFormRow;

    setIsEditing(requestedCompanyName.length > 0 || requestedCompanyFormat.length > 0);

    if (requestedCompanyName.length > 0) {
      setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, companyName: "" }));
    }
    if (requestedCompanyFormat.length > 0) {
      setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, format: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequestFormRow]);

  const onAddValidation = () => {
    const { requestedCompanyName, requestedCompanyFormat } = searchRequestFormRow;

    const isCompanyNameValid = !!requestedCompanyName;
    const isCompanyFormatValid = !!requestedCompanyFormat;

    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      companyName: isCompanyNameValid ? "" : companyErrorMessage,
      format: isCompanyFormatValid ? "" : formatErrorMessage,
    }));

    return isCompanyNameValid && isCompanyFormatValid;
  };

  const onHandlePrimerTypeChange = (type: any) => {
    setIsEditing(true);
    onChangeProperties(type, CommissionPrimerProperties.REQUESTED_COMPANY_TYPE);
    setPrimerType(type);
  };

  return (
    <x.div
      alignItems="start"
      display="grid"
      columnGap="10px"
      gridTemplateColumns={COMMISSION_MODAL_FIT}
      py={inner.base03}
      ml={inner.base06}
      data-testid={testIds.searchRow}
    >
      {/* COMPANY NAME & LOGO */}
      <x.div display="flex" gap="2" alignItems="center">
        <CommissionModalSearch
          setIsEditing={setIsEditing}
          onChangeProperties={onChangeProperties}
          errorMessage={errorMessage.companyName}
          query={searchQuery}
          setQuery={setSearchQuery}
        />
      </x.div>
      {/* CONTENT FORMAT */}
      <x.div display="flex" flexDirection="column" gap="2">
        <SelectMenu
          error={errorMessage.format}
          dataAttributes={{ "data-testid": testIds.selectPrimerFormat }}
          size="small"
          value={searchRequestFormRow.requestedCompanyFormat}
          isClearButtonEnabled
          customSelectedItemRendererInInput={(item) => (
            <Typography variant="body-small">{item[0].value.toString()}</Typography>
          )}
          onChange={(format: any) => {
            onChangeProperties(format, CommissionPrimerProperties.REQUESTED_COMPANY_FORMAT);
          }}
          placeholder="Select format"
        >
          {formatOptions().map((option) => {
            const { format, workingDays } = option;

            return (
              <SelectOption key={format} value={format}>
                <div>
                  <Typography variant="body-small">{format}</Typography>
                  <Typography fontSize={typography.body.small["font-size"].value} color={text.secondary}>
                    {workingDays}
                  </Typography>
                </div>
              </SelectOption>
            );
          })}
        </SelectMenu>
      </x.div>
      {/* COMPANY TYPE AN / PRIVATE */}

      <PrimerTypeButtonGroup value={primerType} onChange={onHandlePrimerTypeChange} />
      <x.div pt={inner.base02}>
        <CommissionModalRush
          rush={searchRequestFormRow.rush}
          disabled={!Boolean(searchRequestFormRow.requestedCompanyFormat)}
          onChange={(value: any) => {
            onChangeProperties(value, CommissionPrimerProperties.REQUESTED_COMPANY_RUSH);
          }}
        />
      </x.div>

      {/* CREDITS*/}
      <Typography lineHeight={inner.base08} color={text.disabled} variant="body-small" textAlign="center">
        {searchRequestFormRow.credits ? searchRequestFormRow.credits : "—"}
      </Typography>
      <AddModalRowButton variant={isEditing ? "secondary" : "outline"} onClick={handleAddRowClick} />
    </x.div>
  );
};

export default SearchRow;
