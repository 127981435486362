import { toString, fromString } from "components/InteractionsFilter/serializer";
import useQueryParams from "hooks/useQueryParams";
import _ from "lodash";
import { Experience } from "models/Interaction";
import { useCallback, useMemo } from "react";
import { CustomerKnowledgeFilters, filterInitialValues } from "./CustomerKnowledgeFilters/ckFilterService";

const FILTER_MEMORY_PARAM = "fm";

export default function useCustomerKnowledgeURL() {
  const queryParams = useQueryParams();

  const apply = useCallback(
    (filters: CustomerKnowledgeFilters) => {
      const serialized = Object.values(Experience).reduce((acc: any, exp) => {
        return {
          ...acc,
          ["experience-" + exp]: Object.entries(filters.vendors || {})
            .filter(([, val]) => val.includes(exp))
            .map(([key]) => key),
        };
      }, _.omit(filters, "vendors"));

      queryParams.set(FILTER_MEMORY_PARAM, toString(serialized));
    },
    [queryParams]
  );

  const restore = useCallback(
    (filterOptions: any) => {
      const url = fromString(queryParams.get(FILTER_MEMORY_PARAM) || "");

      const parsed = {
        ...url,
        vendors: Object.entries(url)
          .filter(([exp]) => exp.startsWith("experience-"))
          .map(([exp, vendorIds]) => [exp.replace("experience-", ""), vendorIds])
          .reduce((acc, [exp, vendorIds]) => {
            for (const vendorId of vendorIds as string[]) {
              if (!acc[vendorId]) acc[vendorId] = [];

              acc[vendorId].push(exp);
            }

            return acc;
          }, {} as any),
      };

      const valid = Object.entries(parsed).reduce((acc, [filter, values]) => {
        return {
          ...acc,
          [filter]: filterInitialValues[filter] ? filterInitialValues[filter](values, filterOptions[filter]) : values,
        };
      }, {});

      const cleared = _.omit(
        valid,
        Object.values(Experience).map((exp) => "experience-" + exp)
      );

      apply(cleared);

      return cleared;
    },
    [queryParams, apply]
  );

  return useMemo(
    () => ({
      restore,
      apply,
    }),
    [restore, apply]
  );
}
