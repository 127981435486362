import React from "react";
import { GlobalNav } from "../../components/GlobalNav";
import { useLoginStyles } from "./index.styles";
import styled, { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Logo, useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";

export const BackgroundPage = ({ children }) => (
  <div className="aui-h-screen aui-flex aui-flex-col">
    <GlobalNav hideLimitationsOfUse hideAdvisorsLink url={"../"} />
    {children}
  </div>
);

export const ModalForm = ({ children, className = "", style = { minWidth: "300px" }, step, ...props }) => {
  const { loginModal } = useLoginStyles(step);
  const { isMobile } = useCheckScreen();

  return (
    <BackgroundPage>
      <UnpaddedModal
        open
        slotHeight={loginModal.h}
        slotWidth={loginModal.w}
        showCloseButton={false}
        zIndex={30}
        {...props}
      >
        {isMobile && <LoginLogoHeader />}
        {children}
      </UnpaddedModal>
    </BackgroundPage>
  );
};

export const UnpaddedModal = styled(ResponsiveModal)`
  & > div {
    display: flex;
    div {
      padding: 0;
      @media (max-width: md) {
        border: none;
      }
    }
  }
`;

export const LoginLogoHeader = () => {
  const {
    color: { border },
    spacing: { inner },
  } = useThemeTokens();
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={`${inner.base08} !important`}
      borderBottom={`1px solid ${border.divider} !important`}
    >
      <Logo variation="ColorName" size="xxs" />
    </x.div>
  );
};
