import { useEffect } from "react";

export const useScript = (url, enabled, onRemoveCallback) => {
  useEffect(() => {
    if (enabled) {
      const script = document.createElement("script");

      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        if (onRemoveCallback && typeof onRemoveCallback === "function") {
          onRemoveCallback();
        }
      };
    }
  }, [url, enabled, onRemoveCallback]);
};

export default useScript;
