export const mapOverview = (res: OverviewAPI): Overview => {
  return {
    ...res,
    currency: res.currency.value || "",
  };
};

export const mapSizeAndGrowth = (res: SizeAndGrowthAPI): SizeAndGrowth => {
  return {
    currentMarketSizeYear: res.currentMarketSizeYear.value || 0,
    data: res.data.map((entry) => ({
      id: entry.id,
      expertId: entry.expertId.value ? entry.expertId.value.toString() : "",
      currentMarketSize: {
        citedBy: entry.currentMarketSizeMin.citedBy,
        value:
          entry.currentMarketSizeMin?.value && entry.currentMarketSizeMax?.value
            ? (Number(entry.currentMarketSizeMin.value) + Number(entry.currentMarketSizeMax.value)) / 2
            : undefined,
        isSensitive: entry.currentMarketSizeMin.isSensitive,
      },
      currentMarketSizeMin: entry.currentMarketSizeMin,
      currentMarketSizeMax: entry.currentMarketSizeMax,
      previousGrowth: entry.previousGrowth,
      predictedGrowth: entry.predictedGrowth,
      commentary: entry.commentary,
    })),
  };
};

export const mapCompetitorDynamics = (res: CompetitorDynamicsEntryAPI): CompetitorDynamicsEntry[] => {
  const toReturn = res.leaders.map((dynamicsEntry) => {
    return {
      ...dynamicsEntry,
      id: dynamicsEntry.id,
      cdsAlphaCompanyId: dynamicsEntry.cdsAlphaCompanyId ? dynamicsEntry.cdsAlphaCompanyId.toString() : "",
      marketShare: {
        citedBy: dynamicsEntry.marketShare ? dynamicsEntry.marketShare.citedBy : [],
        value: parseInt(dynamicsEntry.marketShare?.value ?? ""),
        isSensitive: dynamicsEntry.marketShare?.isSensitive,
      },
      otherNotableCompetitor: false,
      logoLink: dynamicsEntry.logoLink.value || "",
      companyId: dynamicsEntry.companyNameId?.value?.toString() || "",
      companyName: dynamicsEntry.companyName?.value || "",
      revenue:
        dynamicsEntry.revenueMin && dynamicsEntry.revenueMax && dynamicsEntry.revenueYear
          ? {
              min: dynamicsEntry.revenueMin,
              max: dynamicsEntry.revenueMax,
              year: dynamicsEntry.revenueYear,
              quarter: dynamicsEntry.revenueQuarter,
            }
          : null,
      ownershipType: dynamicsEntry.ownershipType ? dynamicsEntry.ownershipType.value || "" : null,
      ownershipCompanyId: dynamicsEntry.ownershipCompanyId
        ? dynamicsEntry.ownershipCompanyId.value?.toString() || ""
        : null,
      ownershipCompanyName: dynamicsEntry.ownershipCompanyName ? dynamicsEntry.ownershipCompanyName?.value || "" : null,
      ownershipYear: dynamicsEntry.ownershipYear ? dynamicsEntry.ownershipYear.value || "" : null,
      ownershipQuarter: dynamicsEntry.ownershipQuarter ? dynamicsEntry.ownershipQuarter.value || "" : null,
    };
  });

  // sort by market share descending
  const sorted = [...toReturn.sort((a, b) => a.marketShare.value - b.marketShare.value).reverse()];

  return [
    ...sorted,
    ...res.otherNotableCompetitors.map((dynamicsEntry) => {
      return {
        ...dynamicsEntry,
        cdsAlphaCompanyId: dynamicsEntry.cdsAlphaCompanyId ? dynamicsEntry.cdsAlphaCompanyId.toString() : "",
        otherNotableCompetitor: true,
        logoLink: dynamicsEntry.logoLink.value || "",
        companyId:
          dynamicsEntry.companyNameId && dynamicsEntry.companyNameId?.value
            ? dynamicsEntry.companyNameId?.value?.toString() || ""
            : "",
        companyName: dynamicsEntry.companyName?.value || "",
        marketShare: null,
        trend: null,
        revenue: null,
        ownershipType: null,
        ownershipCompanyId: null,
        ownershipCompanyName: null,
        ownershipYear: null,
        ownershipQuarter: null,
        overview: null,
        competitorComparison: null,
        shareDynamics: null,
      };
    }),
  ];
};
export const mapSegmentation = (res: SegmentationAPI): Segmentation => {
  const copySegmentation = (segmentation: SegmentAPI) => ({
    ...segmentation,
    segment: segmentation.name,
    revenue: {
      max: segmentation.revenueMax,
      min: segmentation.revenueMin,
      year: segmentation.revenueYear,
    },
    predictedGrowth: {
      max: segmentation.predictedGrowthMax,
      min: segmentation.predictedGrowthMin,
    },
  });

  const customisedSegmentations = (res.customised ?? []).map((customisedSegmentation) => {
    const newCustomisedSegments = customisedSegmentation.segments.map((segment) => copySegmentation(segment));

    return {
      ...customisedSegmentation,
      segments: [...newCustomisedSegments],
    };
  });

  return {
    customers: res.customers.map((customer) => copySegmentation(customer)),
    regions: res.regions.map((region) => copySegmentation(region)),
    products: res.products.map((product) => copySegmentation(product)),
    customised: customisedSegmentations,
  };
};
