import { ContentApprovalStatus } from "./AlphaShield";
import { AlphaNowContentType, ContentPurchaseStatus } from "@alphasights/client-portal-shared";

export interface Conversation {
  id: string;
  title: string;
  clientProfile: ClientProfile;
  libraries?: AlphaGptLibrary[];
}

export interface FindMessagesResponse {
  messages: Message[];
  error?: string;
}

export interface FollowUpQuestionResponse {
  question: string;
}

export interface Message {
  id: string;
  text?: string;
  sender: string;
  createdAt?: string;
  metadata?: Map<string, any>;
  citations?: Citation[];
  followUpQuestions?: FollowUpQuestionResponse[];
  suggestedQuestions?: string[];
  query?: string;
  type?: MessageType;
}

export interface ClientProfile {
  id: number;
  name: string;
}

export type CitationContentType = AlphaNowContentType | "PRIVATE_TRANSCRIPT";

export interface Citation {
  id: string;
  title?: string;
  position?: number;
  link?: string;
  contentType: CitationContentType;
  publishedAt?: string;
  fragmentIds?: string[];
  showLink?: boolean;
  quote?: string;
  content?: ContentData;
  description?: string;
  companies: string[];
}

export interface ContentData {
  purchaseStatus?: ContentPurchaseStatus;
  approvalStatus?: ContentApprovalStatus;
  logoLink?: string;
}

export interface ProjectAlphaGptPurchase {
  projectToken: string;
  createdAt: string;
  expiresAt: string;
}

export enum AlphaGptLibrary {
  AlphaSights = "alphasights",
  Private = "private",
  Project = "project",
}

export interface ProjectAlphaGptServicePlan {
  charge: number;
}

export enum MessageType {
  Respond = "respond",
  GetContentRecommendations = "get_content_recommendations",
  GetQuotes = "get_quotes",
  GetExpertRecommendations = "get_expert_recommendations",
}
