import React, { useState, useEffect, FC } from "react";

import { Typography, Button, useThemeTokens, Switch } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { x } from "@xstyled/styled-components";
import { title, confirm, cancel, share, optIn, optOut } from "./text";

export const testIds = {
  modalTestId: "private-library-modal",
  libraryToggle: "library-toggle",
};

export const LIBRARY_STATUS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
};

type PrivateSettingsModalProps = {
  libraryStatus: string;
  onClose: () => void;
  onSubmit: (isToggleOn: boolean) => void;
};

const PrivateLibrarySettingsModal: FC<PrivateSettingsModalProps> = ({ libraryStatus, onClose, onSubmit }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const isPrivateLibraryContributor = libraryStatus === LIBRARY_STATUS.active;

  const [isToggleOn, setIsToggleOn] = useState<boolean>(isPrivateLibraryContributor);

  useEffect(() => {
    setIsToggleOn(isPrivateLibraryContributor);
  }, [isPrivateLibraryContributor]);

  const switchText = isToggleOn ? optIn : optOut;

  return (
    <ResponsiveModal
      open
      title={title}
      onClose={onClose}
      slotWidth={isMobile ? "100vw" : "35vw"}
      primaryButton={
        <Button variant="secondary" onClick={() => onSubmit(isToggleOn)} ml={inner.base02}>
          <Typography variant="body-em" component="span">
            {confirm}
          </Typography>
        </Button>
      }
      secondaryButton={
        <Button variant="ghost" onClick={onClose} px={inner.base06} py={inner.base03}>
          <Typography variant="body-em" component="span" color={text.strong._}>
            {cancel}
          </Typography>
        </Button>
      }
      testId={testIds.modalTestId}
    >
      <x.div pt={inner.base05}>
        <x.div display="flex" flexDirection="row" alignItems="center" mb={inner.base06}>
          <Typography component="span" color={text.strong._}>
            {share}
          </Typography>
          <x.div marginLeft="auto" display="flex" alignItems="center">
            <Switch
              isSelected={isToggleOn}
              dataAttributes={{ "data-testId": testIds.libraryToggle }}
              leftText={switchText}
              size="medium"
              onClick={() => setIsToggleOn(!isToggleOn)}
            ></Switch>
          </x.div>
        </x.div>
      </x.div>
    </ResponsiveModal>
  );
};

export default PrivateLibrarySettingsModal;
