import React, { FC, ReactElement } from "react";
import { Button, TypographyVariation, Skeleton, ComponentSize } from "@alphasights/alphadesign-components";
import "./index.css";
import { RequestExpertButtonType } from "../AlphaNowContentActions/constants";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { DISABLED_BUTTON_STYLES, MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const ButtonWithAccess = withAccessControl(Button);

const BUTTON_SIZE = {
  small: {
    minWidth: "106px",
    minHeight: "32px",
    whiteSpace: "nowrap",
  },
  medium: {
    minWidth: "127px",
    minHeight: "44px",
  },
};

interface RequestExpertButtonProps {
  onClick: () => void;
  buttonActionVariant: string;
  text?: string;
  variant?: TypographyVariation;
  loading?: boolean;
  startIcon?: ReactElement;
  disabled?: boolean;
  size?: ComponentSize;
}

const RequestExpertButton: FC<RequestExpertButtonProps> = ({
  onClick,
  buttonActionVariant,
  text,
  loading,
  startIcon,
  disabled = false,
  size = "medium",
}) => {
  const { isMobile } = useCheckScreen();

  const buttonSizeProps = isMobile || size === "small" ? BUTTON_SIZE.small : BUTTON_SIZE.medium;

  return loading && buttonActionVariant === RequestExpertButtonType.openModal ? (
    <Skeleton height={buttonSizeProps.minHeight} width={buttonSizeProps.minWidth} variant="noMargin" />
  ) : (
    <ButtonWithAccess
      accessControl={{ allowedPermissions: [MANAGE_CONTENT_PERMISSION] }}
      className="request-expert-button"
      data-testid={buttonActionVariant}
      size={size}
      onClick={onClick}
      variant="secondary"
      whiteSpace="nowrap"
      startIcon={startIcon}
      disabled={disabled}
      {...(disabled ? DISABLED_BUTTON_STYLES : {})}
      {...buttonSizeProps}
    >
      {text}
    </ButtonWithAccess>
  );
};

export default RequestExpertButton;
