import React from "react";
import { Typography, Button } from "@alphasights/alphadesign-components";

export const DeliverableNotFoundMessage = ({ token }) => {
  return (
    <>
      <Typography mr="24px" data-testid="deliverable-not-found-alert">
        This deliverable is no longer available. Please reach out to your AlphaSights contact.
      </Typography>
      <Button href={`/${token}/experts`} variant="link" h="initial">
        <Typography variant="body-em" component="span">
          Back to Project
        </Typography>
      </Button>
    </>
  );
};

export const DeliverableNotReady = ({ token }) => {
  return (
    <>
      <Typography mr="24px" data-testid="deliverable-not-found-alert">
        This deliverable is not ready yet. Please try again later.
      </Typography>
      <Button href={`/${token}/experts`} variant="link" h="initial">
        <Typography variant="body-em" component="span">
          Back to Project
        </Typography>
      </Button>
    </>
  );
};

export const ContentNotFoundMessage = () => {
  return (
    <Typography mr="24px" data-testid="deliverable-not-found-alert">
      Invalid link. Content not available.
    </Typography>
  );
};
