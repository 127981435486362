export const arrayToString = (array, separator, lastSeparator) => {
  if (lastSeparator && array.length > 1) {
    return `${array.slice(0, -1).join(separator)}${lastSeparator}${array.slice(-1)}`;
  } else {
    return array.join(separator);
  }
};

export const eventOnElement = (event, elementRef) => {
  if (elementRef && event) {
    const portalTargets = elementRef.querySelectorAll("[data-portal-to]");
    const portalIds = Array.from(portalTargets).map((element) => element.dataset.portalTo);
    const portals = portalIds.map((id) => Array.from(document.querySelectorAll(`[data-portal-from='${id}']`))).flat();

    return elementRef.contains(event.target) || portals.some((portal) => portal.contains(event.target));
  }

  return false;
};
