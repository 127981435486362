import styled, { th } from "@xstyled/styled-components";

export const StyledMain = styled.main`
  padding-left: ${th.space("inner-base04")};
  padding-right: ${th.space("inner-base04")};
  padding-top: ${th.space("inner-base06")};
  padding-bottom: ${th.space("inner-base08")};
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  overflow-x: auto;

  @media (min-width: 1024px) {
    box-sizing: border-box;
  }
`;

export const StyledHeading = styled.h1`
  text-align: center;
  font-weight: bold;
  margin-bottom: ${th.space("inner-base08")};
  margin-top: ${th.space("inner-base")};
  font-size: ${th.fontSize("09")};
  line-height: ${th.space("inner-base14")};
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${th.space("inner-base10")};
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;

export const StyledFormWrapper = styled.div`
  box-shadow: ${th.shadow("600")};
  display: flex;
  flex-direction: column;
  padding-left: ${th.space("inner-base12")};
  padding-right: ${th.space("inner-base12")};
  padding-top: ${th.space("inner-base10")};
  padding-bottom: ${th.space("inner-base10")};
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${th.space("inner-base08")};

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: ${th.fontSize("03")};
  margin-top: ${th.space("inner-base")};
`;
