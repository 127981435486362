import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMyProfilePageStyles = () => {
  const {
    color: { text, background },
    spacing,
  } = useThemeTokens();

  const formLabel = {
    color: text.secondary,
  };

  const formLabelDisabled = {
    color: text.disabled,
  };

  const formInputDisabled = {
    backgroundColor: background.disabled,
    color: text.disabled,
  };

  const footerBackground = {
    backgroundColor: background.surface.page.default,
  };

  const formContent = {
    gap: spacing.inner.base04,
    display: "flex",
    flexDirection: "column",
  };

  return {
    formLabel,
    formLabelDisabled,
    formInputDisabled,
    footerBackground,
    formContent,
  };
};
