export const ContentAccessText = {
  ApprovalRequiredTitle: "Compliance approval needed for full access",
  ApprovalRequestedTitle: "Access Requested",
  ApprovalDeclinedTitle: "Access Restricted",
  ApprovalRequiredDetails: "If approved, you will be charged",
  ApprovalRequestedDetails: "You will be notified if access has been granted.",
  ApprovalDeclinedDetails:
    "Access to this content has been restricted by your compliance officer. Please contact your compliance team for further information.",
  ApprovalRequiredModalDetailsPayg: "Request the transcript to gain full access.",
  ApprovalRequiredModalDetailsPaygPrimer: "Request the Company Primer to gain full access.",
  ApprovalRequiredModalDetailsPaygMarketPrimer: "Request the Market Primer to gain full access.",
  ApprovalRequiredModalDetailsPaygCustomerPrimer: "Request the Customer Primer to gain full access.",
  ApprovalRequiredModalDetailsPremium:
    "To read this transcript please request access and you will be notified if the access has been granted.",
  ApprovalRequiredModalDetailsPremiumPrimer:
    "To read this Company Primer please request access and you will be notified if the access has been granted.",
  ApprovalRequiredModalDetailsPremiumMarketPrimer:
    "To read this Market Primer please request access and you will be notified if the access has been granted.",
  ApprovalRequiredModalDetailsPremiumCustomerPrimer:
    "To read this Customer Primer please request access and you will be notified if the access has been granted.",
} as const;
