import styled from "styled-components";
import { useThemeTokens, Typography } from "@alphasights/alphadesign-components";

const TriggerContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    padding: spacing.inner.base03,
    justifyContent: "space-between",
    cursor: "pointer",
  };
});

const TriggerTitleContainer = styled.div(({ hasUnreadTrigger }: { hasUnreadTrigger: boolean }) => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base02,
    width: hasUnreadTrigger ? "70%" : "100%",
  };
});

const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: fit-content;
`;

const NotificationContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base02,
  };
});

export { TriggerContainer, TriggerTitleContainer, StyledTypography, NotificationContainer };
