interface SurveyTableOptionsProp {
  isLoading: boolean;
  isPrefLoading: boolean;
  onRowClicked: (row: any) => void;
  showSelectColumn: boolean;
  rowsSelected: any;
  setRowsSelected: any;
  onColumnPinningChange: any;
  onColumnOrderChange: any;
  onColumnVisibilityChange: any;
  rowClassRules: any;
  rowHoverHighlight?: boolean;
  hideHeader?: boolean;
}

const tableOptions = ({
  isLoading,
  onRowClicked,
  showSelectColumn,
  rowsSelected,
  rowClassRules,
  setRowsSelected,
  isPrefLoading,
  onColumnPinningChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  rowHoverHighlight = false,
  hideHeader = false,
}: SurveyTableOptionsProp) => {
  const defaultColumnPinning = {
    left: ["_selection", "CallToActionColumn"],
    right: ["_visibility"],
  };
  const defaultColumnOrder = [
    "CallToActionColumn",
    "CommentColumn",
    "TransIdColumn",
    "SurveyStatusColumn",
    "ExpertColumn",
    "RelevantCompanyColumn",
    "RelevantPositionColumn",
    "TenureColumn",
    "AngleColumn",
    "FollowUpCallStatusColumn",
    "HideExpertColumn",
  ];

  return {
    state: {
      columnPinning: defaultColumnPinning,
      columnOrder: defaultColumnOrder,
      sorting: [],
      rowSelection: rowsSelected,
    },
    meta: {
      isLoading: isLoading || isPrefLoading,
      onRowClicked,
      rowClassRules,
      hideSelectColumn: !showSelectColumn,
      fullWidth: true,
      rowHoverHighlight,
      hideHeader,
    },
    enableRowSelection: showSelectColumn,
    enableMultiRowSelection: showSelectColumn,
    onRowSelectionChange: setRowsSelected,
    onColumnPinningChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
  };
};

export { tableOptions };
