import React, { useCallback, useMemo, useState } from "react";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { Loading, StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { EXPERT_REMARKS_BADGE, useProjectBadgeContext } from "../../providers/BadgeProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useStarExpertContext } from "providers/StarExpertProvider";

export const StarColumn = ({ row: { original: data } }) => {
  const { font } = useThemeTokens();
  const { locked } = useLockedExpert(data);
  const [isLoading, setIsLoading] = useState(false);
  const { onToggleStar } = useStarExpertContext();
  const { getExpertRemark, isExpertHidden } = useExpertRemarksContext();
  const { hasProjectBadge } = useProjectBadgeContext();

  const angleId = useMemo(() => data.angles[0].id, [data.angles]);
  const expertId = useMemo(() => data.advisorId, [data.advisorId]);

  const starred = useMemo(() => {
    if (hasProjectBadge(EXPERT_REMARKS_BADGE)) {
      return getExpertRemark({ angleId, expertId }).starred;
    } else {
      return data.starred;
    }
  }, [data.starred, angleId, expertId, getExpertRemark, hasProjectBadge]);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      setIsLoading(true);
      return onToggleStar(data, HitOrigin.tableView).finally(() => {
        setIsLoading(false);
      });
    },
    [onToggleStar, data]
  );

  const loading = useMemo(() => {
    return hasProjectBadge(EXPERT_REMARKS_BADGE) ? isLoading : data.runningAction === "toggleStar";
  }, [data.runningAction, hasProjectBadge, isLoading]);

  return (
    <div className="aui-space-x-4 aui-inline-flex aui-items-center">
      {!isExpertHidden(data) && (
        <StarButton
          starred={starred}
          onClick={handleClick}
          loading={loading}
          fontSize={font.size["06"]}
          disabled={locked}
        />
      )}
    </div>
  );
};

const StarButton = ({ starred, loading, disabled, onClick, ...props }) => {
  const icon = useMemo(() => (starred ? <StarSelected /> : <StarUnselected />), [starred]);
  const tooltip = useMemo(() => (starred ? "Unstar expert" : "Star expert"), [starred]);

  return loading ? (
    <x.div {...props}>
      <Loading style={{ animation: "x-spin 1s linear infinite" }} />
    </x.div>
  ) : (
    <x.div cursor={disabled ? "not-allowed" : "auto"} {...props}>
      <PortalTooltip title={tooltip} disabled={disabled} dark>
        <Icon color="secondary" data-testid="table-star-button" onClick={disabled ? undefined : onClick}>
          {icon}
        </Icon>
      </PortalTooltip>
    </x.div>
  );
};
