import { useQuery } from "react-query";
import { contentService } from "services/content";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { isEmpty } from "lodash";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const useCompanyOverview = (companyId: number) => {
  // TODO [CON-3152]: Remove Company Page Badge
  const { hasUserBadge } = useUserBadgeContext();
  const { isMobile } = useCheckScreen();

  const hasCompanyPageBadge = hasUserBadge(Badge.companyPage);
  const hasCompanyPageEnabled = !!companyId && hasCompanyPageBadge && !isMobile;

  const { data: companyOverview, isSuccess: isOverviewSuccess } = useQuery({
    queryKey: ["alphaNowCompanyOverview", companyId],
    queryFn: () => contentService.fetchAlphaNowCompanyOverview(companyId),
    enabled: hasCompanyPageEnabled,
  });

  return {
    hasCompanyOverview: !!isOverviewSuccess && !isEmpty(companyOverview!),
    companyOverviewData: companyOverview,
  };
};

export default useCompanyOverview;
