import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const useAvailabilitySelectorStyles = () => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const availabilitiesStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base06,
  };

  const selectLabelStyles = {
    color: color.text.secondary,
    mb: inner.base02,
  };

  const selectStyles = {
    maxW: !isMobile ? "260px" : undefined,
  };

  return {
    availabilitiesStyles,
    selectLabelStyles,
    selectStyles,
  };
};
