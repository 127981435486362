import React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { Citation } from "components/CitationContext/Citation";
import DirectionalArrow from "components/DirectionalArrow";
import styles from "./styles/npsTopics.module.css";
import { Remove } from "@alphasights/alphadesign-icons";
import { netPromoterScore } from "../../../utils/constants";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

type PropTypes = {
  npsExpertsResponses: NpsExpertsRemark[];
};

const NpsTopics = ({ npsExpertsResponses }: PropTypes) => {
  const { color, spacing, typography } = useThemeTokens();
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);

  const sentimentSymbol = (sentiment: string | undefined) => {
    let isVerticalArrow = true;
    let isUp = true;
    let symbolColor;

    switch (sentiment) {
      case netPromoterScore.topics.promoter:
        symbolColor = color.text.success;
        break;
      case netPromoterScore.topics.detractor:
        isUp = false;
        symbolColor = color.text.danger;
        break;
      case netPromoterScore.topics.passive:
        isVerticalArrow = false;
        symbolColor = color.chart.categorical.base09;
        break;
      default:
        return null;
    }

    return (
      <x.span display="inline-flex" alignItems="center" color={symbolColor} backgroundColor="inherit">
        {isVerticalArrow ? (
          <DirectionalArrow isUp={isUp} />
        ) : (
          <Icon>
            <Remove />
          </Icon>
        )}
        <Typography {...typography.body.em} color={color.text.strong._} component="span" ml={spacing.inner.base}>
          {sentiment}
        </Typography>
      </x.span>
    );
  };

  return (
    <x.div mt={spacing.inner.base06} className={styles.topicsContainer} rowGap={spacing.inner.base06}>
      {npsExpertsResponses?.map((category, categoryIndex) => {
        const { npsSentiment, expertsResponses } = category;

        if (expertsResponses.length === 0) return null;

        return (
          <SensitiveDataContainer key={categoryIndex.toString()} isSensitive={!isAccessible}>
            <x.div className={styles.topicGroup} mt={categoryIndex === 0 ? 0 : spacing.inner.base06}>
              <x.div mb={spacing.inner.base02}>{sentimentSymbol(npsSentiment)}</x.div>
              <x.ul listStyleType="disc" paddingLeft={spacing.layout.base03}>
                {expertsResponses.map(({ response }, responseIndex) => {
                  return (
                    <x.li
                      key={responseIndex.toString()}
                      {...typography.body.regular}
                      color={color.text.strong._}
                      fontStyle="italic"
                    >
                      <Citation
                        value={{
                          ...response,
                          value: `‘${response.value}’`,
                        }}
                      />
                    </x.li>
                  );
                })}
              </x.ul>
            </x.div>
          </SensitiveDataContainer>
        );
      })}
    </x.div>
  );
};

export default NpsTopics;
