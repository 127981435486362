import React, { useState } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { LabeledIcon, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import ChartLegend from "pages/AlphaNowPage/primers/MarketPrimer/components/ChartLegend/ChartLegend";
import { Info } from "@alphasights/alphadesign-icons";
import SegmentChart from "./SegmentChart";
import SegmentationTabs from "./SegmentationTabs";
import { Section } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";

const SegmentSection = ({ segmentSection, sectionIndex }: { segmentSection: Section; sectionIndex: number }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { sectionTitle, segments, type, year, quarter } = segmentSection;
  const { isAccessible } = usePrimersStore(({ isAccessible }) => ({ isAccessible }));
  const [selectedChartSegment, setSelectedChartSegment] = useState(0);
  const { getChartColors } = useGetChartColors({
    numberOfBars: segments.length,
  });

  const getSecondColumnLabel = () => {
    if (quarter && year) {
      return `Split (Q${quarter}, ${year})`;
    }

    if (quarter) {
      return `Split (Q${quarter})`;
    }

    if (year) {
      return `Split (${year})`;
    }

    return "Split";
  };

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);
    ApexCharts.exec(`segmentationChart${sectionIndex}`, "toggleDataPointSelection", index);
  };

  return (
    <x.div px={inner.base10}>
      <x.div display="flex" flexDirection="column" gap={inner.base03} pb={inner.base05}>
        <PrimerFieldTitle text={sectionTitle && sectionTitle.length > 0 ? sectionTitle : type} />
        <LabeledIcon labelText={`Click a chart section to view the ${sectionTitle} segment`} iconItem={<Info />} />
      </x.div>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <x.div display="flex" gap={inner.base12}>
          <SegmentChart
            sectionIndex={sectionIndex}
            segments={segments}
            selectedSegment={selectedChartSegment}
            setSelectedSegment={setSelectedChartSegment}
          />
          <x.div display="flex" flex="1 1 auto" justifyContent="space-between" alignItems="center">
            <ChartLegend
              //TODO discuss if this should be on payload for custom segments
              firstColumnLabel={type === "Regions" ? "Regions" : "Segments"}
              secondColumnLabel={getSecondColumnLabel()}
              segmentInfo={[
                ...segments.map((entry, index) => ({
                  leader: entry.other ? { ...entry.title, value: "Other" } : entry.title,
                  share: entry.split ? `${entry.split.min.value}-${entry.split.max.value}` : undefined,
                  color: getChartColors(null)[index],
                })),
              ]}
              onClick={onHighlightChartSegment}
            />
          </x.div>
        </x.div>
      </SensitiveDataContainer>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <x.div>
          <SegmentationTabs
            segments={segments}
            onClick={onHighlightChartSegment}
            selectedChartSegment={selectedChartSegment}
          />
        </x.div>
      </SensitiveDataContainer>
    </x.div>
  );
};

export default SegmentSection;
