import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { getPublicationDate } from "../helpers";

const PublicationDateCell = ({ row: { original: content } }: AlphaTableTypes.CellContext<ContentResults, any>) => (
  <FormattedDateTime date={getPublicationDate(content)} format="d LLL yyyy" />
);

export default PublicationDateCell;
