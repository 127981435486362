import React, { useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import Chart from "react-apexcharts";
import { useMarketPrimersStore } from "../../state/store";
import CompetitorDynamicsTabs from "./Tabs/CompetitorDynamicsTabs";
import { PrimerFieldTitle, SectionTitle } from "pages/AlphaNowPage/primers/components";
import { LabeledIcon } from "pages/AlphaNowPage/primers/components";
import ChartLegend from "../../components/ChartLegend/ChartLegend";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

const CompetitorDynamics = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { competitorDynamics } = useMarketPrimersStore();
  const { isAccessible } = usePrimersStore();
  const leaders = competitorDynamics.filter((competitor) => !competitor.otherNotableCompetitor);
  const { getChartColors } = useGetChartColors({
    // + 1 to include the other notable competitors
    numberOfBars: leaders.length + 1,
  });

  const [selectedChartSegment, setSelectedChartSegment] = useState<number | null>(0);

  const chartOptions = {
    chart: {
      id: "competitorDynamicsChart",
      toolbar: {
        show: false,
      },
      parentHeightOffset: 0,
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          setSelectedChartSegment(config.dataPointIndex);
        },
      },
    },
    colors: getChartColors(selectedChartSegment),
    labels: [...leaders.map((entry) => entry.companyName), "Other notable competitors"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const leadersMarketShare = leaders
    .filter((entry) => !entry.otherNotableCompetitor && entry.marketShare !== null)
    .map((entry) => (entry.marketShare && entry.marketShare.value ? entry.marketShare.value : 0))
    .reduce((partialSum, a) => partialSum + a, 0);

  const series = [
    ...leaders.map((entry) => (entry.marketShare && entry.marketShare.value ? entry.marketShare.value : 0)),
    100 - leadersMarketShare,
  ];

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);
    ApexCharts.exec("competitorDynamicsChart", "toggleDataPointSelection", index);
  };

  return (
    <SectionWrapper ref={ref} px={inner.base06}>
      <x.div w="100%">
        <SectionTitle dataTestId="CompetitorDynamicsSectionTitle" text="Competitor Dynamics" />
        <x.div display="flex" flexDirection="column" gap={inner.base03} py={inner.base04}>
          <PrimerFieldTitle text="Market Share" />
          <LabeledIcon labelText="Click a chart section to view the profile of a company" iconItem={<Info />} />
        </x.div>
        <x.div display="flex" gap={inner.base12}>
          <SensitiveDataContainer isSensitive={!isAccessible}>
            <Chart options={chartOptions} series={series} type="donut" height={300} width={300} />
          </SensitiveDataContainer>
          <x.div display="flex" flex="1 1 auto" justifyContent="space-between" alignItems="center">
            <SensitiveDataContainer isSensitive={!isAccessible}>
              <ChartLegend
                segmentInfo={[
                  ...leaders.map((entry, index) => {
                    const [companyName, tooltip] = getTruncatedTextAndTooltip(entry.companyName, 30);

                    return {
                      leader: companyName as string,
                      tooltip: tooltip as string,
                      share: entry.marketShare ? entry.marketShare.value : undefined,
                      color: getChartColors(null)[index],
                    };
                  }),
                  {
                    leader: "Other notable competitors",
                    tooltip: null,
                    share: `${100 - leadersMarketShare}`,
                    color: getChartColors(null)[leaders.length],
                  },
                ]}
                onClick={onHighlightChartSegment}
              />
            </SensitiveDataContainer>
          </x.div>
        </x.div>
        <x.div>
          <CompetitorDynamicsTabs
            chartColors={getChartColors(null)}
            selectedChartSegment={selectedChartSegment}
            onClick={onHighlightChartSegment}
          />
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(CompetitorDynamics));
