import React, { useContext, useEffect, useState } from "react";
import {
  parseISO as fnsParseISO,
  getDay,
  set,
  differenceInMinutes,
  subMilliseconds,
  isToday,
  isYesterday,
} from "date-fns";
import { format as fnsFormat, utcToZonedTime, zonedTimeToUtc as fnsZonedTimeToUtc } from "date-fns-tz";
import { DAY_FORMAT } from "helpers/interactionHelpers";
import moment from "moment-timezone";

type TimezoneContextState = {
  setCurrentTimezone: React.Dispatch<React.SetStateAction<string>>;
  currentTimezone: string;
  format: (date: string | Date, fmt: string) => string;
  parseDateZoned: (isoStringOrDate: string | Date) => Date;
  timespansByDay: (
    timespans: TimeSpan[],
    dayFormat?: string
  ) => {
    [x: string]: TimeSpan[];
  };
  breakPeriodByDay: (
    {
      start,
      end,
    }: {
      start: Date;
      end: Date;
    },
    intersectDates?: boolean
  ) => {
    start: Date;
    end: Date;
  }[];
  zonedTimeToUtc: (isoStringOrDate: string | Date) => Date;
  zonedMidnightToUtc: (date: string | Date) => Date;
};

type TimeSpan = {
  startsAt: string;
  endsAt: string;
};

export const TimezoneContext = React.createContext<undefined | TimezoneContextState>(undefined);

export const TimezoneProvider = ({ parentTimezone, ...props }: { parentTimezone?: string; children: JSX.Element }) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [currentTimezone, setCurrentTimezone] = useState(parentTimezone || browserTimezone);

  useEffect(() => {
    if (parentTimezone) setCurrentTimezone(parentTimezone);
  }, [parentTimezone]);

  const parseDateZoned = (isoStringOrDate: string | Date) => utcToZonedTime(parseISO(isoStringOrDate), currentTimezone);

  const zonedTimeToUtc = (isoStringOrDate: string | Date) =>
    fnsZonedTimeToUtc(parseISO(isoStringOrDate), currentTimezone);

  const format = (date: string | Date, fmt: string) =>
    fnsFormat(parseDateZoned(date), fmt, {
      timeZone: currentTimezone,
    });

  const timespansByDay = (timespans: TimeSpan[], dayFormat = DAY_FORMAT) =>
    timespans.reduce((acc: { [key: string]: TimeSpan[] }, timespan) => {
      const day = format(parseDateZoned(timespan.startsAt), dayFormat);
      return {
        ...acc,
        [day]: [...(acc[day] || []), timespan],
      };
    }, {});

  const breakPeriodByDay = ({ start, end }: { start: Date; end: Date }, intersectDates = false) => {
    const startOnTz = parseDateZoned(start);
    const endOnTz = parseDateZoned(end);
    if (getDay(startOnTz) !== getDay(endOnTz)) {
      const startOfDay2 = zonedTimeToUtc(
        set(endOnTz, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      );

      const day1 = {
        start,
        end: subMilliseconds(startOfDay2, intersectDates ? 0 : 1),
      };
      const day2 = { start: startOfDay2, end };
      const hasDay2 = differenceInMinutes(day2.end, day2.start) >= 15;
      return hasDay2 ? [day1, day2] : [{ start, end }];
    }
    return [{ start, end }];
  };

  const zonedMidnightToUtc = (date: string | Date) => {
    return zonedTimeToUtc(format(date, "yyyy-MM-dd") + " 00:00:00.000");
  };

  const context = {
    setCurrentTimezone,
    currentTimezone,
    format,
    parseDateZoned,
    timespansByDay,
    breakPeriodByDay,
    zonedTimeToUtc,
    zonedMidnightToUtc,
  };

  return <TimezoneContext.Provider value={context} {...props} />;
};

export const useTimezone = () => {
  const context = useContext(TimezoneContext);

  if (!context) throw new Error("TimezoneContext should only be used within TimezoneProvider");

  return context;
};

export const parseISO = (isoStringOrDate: string | Date) =>
  isoStringOrDate instanceof Date ? isoStringOrDate : fnsParseISO(isoStringOrDate);

export const localTimezone = <P extends object>(Component: React.ComponentType<P>) => (props: P) => {
  const tz = useTimezone();
  return (
    <TimezoneProvider parentTimezone={tz.currentTimezone}>
      <Component {...props} />
    </TimezoneProvider>
  );
};

const padStart2 = (number: number) => String(number.toFixed(0)).padStart(2, "0");

export const FormattedDuration = ({ duration }: { duration: number }) => {
  return `${padStart2(duration / 60)}:${padStart2(duration % 60)}`;
};

export const FormattedTimeRange = ({ start, end }: { start: string | Date; end: string | Date }) => {
  const hourFormat = (showPeriod: boolean) => {
    const period = showPeriod ? "aaa" : "";
    return `h:mm${period}`;
  };

  const timezone = useTimezone();
  const isSamePeriod = timezone.format(start, "a") === timezone.format(end, "a");

  return (
    <>
      <FormattedDateTime date={start} format={hourFormat(!isSamePeriod)} /> -{" "}
      <FormattedDateTime date={end} format={hourFormat(true)} />
    </>
  );
};

export const FormattedDateTime = ({
  prefix = undefined,
  date,
  format,
}: {
  prefix?: string;
  date?: string | Date;
  format: string;
}) => {
  const timezone = useTimezone();
  if (!date) return <></>;
  const formatted = timezone.format(date, format);

  return (
    <>
      {prefix}
      {formatted}
    </>
  );
};

const formatDay = (tz: TimezoneContextState, date: Date) => {
  if (isToday(date)) return "Today";

  if (isYesterday(date)) return "Yesterday";

  return tz.format(date, "iiii d MMM");
};

export const FormattedDateRelative = ({ date }: { date: string | Date }) => {
  const tz = useTimezone();

  const parsed = tz.parseDateZoned(date);

  const day = formatDay(tz, parsed);
  const hour = tz.format(parsed, "h:mmaaa");

  return (
    <>
      {hour} {day}
    </>
  );
};

export const CurrentTimezone = ({
  prefix,
  formatOnly,
  format = "O",
}: {
  prefix?: string;
  formatOnly?: boolean;
  format?: string;
}) => {
  const timezone = useTimezone();

  const offsetDescription = timezone.format(new Date(), format);

  if (formatOnly) return offsetDescription;

  const timezoneDescription = timezone.currentTimezone.replace("_", " ");
  const description = `${timezoneDescription} (${offsetDescription})`;

  if (prefix) return `${prefix}${description}`;

  return description;
};

const formatTimezoneOffset = (timezone: string) => {
  const offset = moment.tz(timezone).utcOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset >= 0 ? "+" : "-";
  return `GMT${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

export const getGmtTimezones = () => {
  const timezones = moment.tz.names();
  const formattedTimezones = timezones.map((timezone) => {
    const formattedOffset = formatTimezoneOffset(timezone);
    return {
      formattedTimezone: `(${formattedOffset}) ${timezone.replace(/_/g, " ")}`,
      timezone,
      offset: moment.tz(timezone).utcOffset(),
    };
  });

  formattedTimezones.sort((a, b) => a.offset - b.offset);

  return formattedTimezones;
};

export const hasTimeZoneAbbreviation = (timeZone: string) => {
  const zonedDate = utcToZonedTime(new Date(), timeZone);
  const timeZoneAbbr = fnsFormat(zonedDate, "zzz", { timeZone });

  return !timeZoneAbbr.includes("GMT") && timeZoneAbbr.length > 0;
};
