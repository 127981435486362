import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, color } = useThemeTokens();

  const topRow = {
    display: "flex",
    gap: spacing.layout.base03,
    w: "100%",
    h: "32px",
  };

  const bottomRow = {
    display: "flex",
    marginTop: spacing.inner.base03,
    w: "100%",
    h: "32px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
  const centerIcon = {
    marginTop: "auto",
    marginBottom: "auto",
  };

  return {
    headerWrapper: {
      paddingTop: "22px",
      paddingRight: "32px",
      paddingBottom: "22px",
      paddingLeft: "24px",
      gap: spacing.layout.base03,
      borderBottom: "solid 1px " + color.border.divider,
    },
    topRow,
    searchWrapper: {
      display: "flex",
      gap: spacing.inner.base04,
      flex: 1,
    },
    bottomRow,
    centerIcon,
  };
};
