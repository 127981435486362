import React, { useEffect, useState, useMemo } from "react";
import { CalendarFull } from "../../views/ProjectCalendarView/CalendarFull";
import { eventHandler as eventHandlerImported } from "./eventHandler";
import {
  mapAdvisorAvailability,
  mapScheduledCalls,
  mapPendingSchedule,
  mapClientAvailability,
  mapMissingEventFromPreselect,
  selectedInteractionAngleIds,
  mapUpcomingCalls,
} from "./eventSupport";
import { isPast, max, addMinutes } from "date-fns";
import { useTimezone } from "../../providers/TimezoneProvider";

// to ease transition between calendars
const enrichWithProxy = (ev) => {
  const handler = {
    get(target, prop) {
      if (prop in target) return target[prop];
      return (args) => ev.emit(prop, args);
    },
  };
  const deps = new Proxy({}, handler);
  return { ...ev, deps };
};

const wrapHandler = (handler, { clearEdit, showClientAvailability, keepPendingChanges }) => {
  return (type, args) => {
    type === "selectEvent" && !args && clearEdit();
    type === "saveClientAvailability" && showClientAvailability();
    if (type === "pendingChangesUpdated") return keepPendingChanges(args);
    return handler(type, args);
  };
};

export const ProjectsCalendar = ({
  projects,
  secondaryProjects = [],
  eventHandler: handlerInput,
  preselect: preselectInput,
  isFlyout = false,
  show = {
    clientAvailability: true,
    expertAvailability: true,
    interaction: true,
    pending: true,
    ghost: true,
    upcomingCall: true,
  },
  showClientAvailability,
  setShowClientAvailability,
  reschedulingId,
  newFlyout,
  upcomingCalls,
  ...props
}) => {
  const [events, setEvents] = useState([]);
  const [editing, setEditing] = useState(null);
  const [preselect, setPreselect] = useState(preselectInput);

  const tz = useTimezone();
  useEffect(() => {
    setPreselect(preselectInput);
    if (!preselectInput) setEditing(null);
  }, [preselectInput]);

  const eventHandler = useMemo(
    () =>
      wrapHandler(handlerInput, {
        clearEdit: () => {
          setPreselect(null);
          setEditing(null);
        },
        showClientAvailability: () => setShowClientAvailability(true),
        keepPendingChanges: (vals) => setEditing((v) => ({ ...(v || {}), ...vals })),
      }),
    [handlerInput, setShowClientAvailability]
  );

  const eventsFromInput = useMemo(() => {
    return [
      ...mapScheduledCalls({
        projects,
        preselect,
        eventHandler,
        reschedulingId,
      }),
      ...mapPendingSchedule({ projects, preselect, eventHandler }),
      ...mapAdvisorAvailability({
        projects,
        preselect,
        eventHandler,
        reschedulingId,
      }),
      ...mapClientAvailability({ projects, preselect, eventHandler }),
      ...mapScheduledCalls({
        projects: secondaryProjects,
        preselect,
        eventHandler,
        secondary: true,
        reschedulingId,
      }),
      ...mapUpcomingCalls({ upcomingCalls, eventHandler }),
    ]
      .map(enrichWithProxy)
      .flatMap((ev) =>
        tz.breakPeriodByDay(ev).map((a) => ({
          ...ev,
          start: a.start,
          end: a.end,
        }))
      );
  }, [projects, preselect, eventHandler, reschedulingId, secondaryProjects, tz, upcomingCalls]);

  useEffect(() => {
    let events = eventsFromInput;
    const project = projects[0];
    if (preselect) {
      const preselectedEvent = eventsFromInput.find((ev) => ev.preselected);
      const selectedEvent =
        preselectedEvent ||
        mapMissingEventFromPreselect({
          preselect,
          editing,
          eventHandler,
          project,
        });
      const defaultValues =
        !preselectedEvent &&
        (project.selectedInteractionIds?.length > 0
          ? {
              advisorshipIds: project.selectedInteractionIds,
              angleIds: selectedInteractionAngleIds(project),
            }
          : { angleIds: project.anglesTaggedToClient });

      const extraData = editing || defaultValues || {};
      const extraEvent = { ...selectedEvent, ...extraData };
      events = [...events.filter((e) => e !== preselectedEvent), extraEvent];
    }

    setEvents(events.map((e) => ({ ...e, isFlyout })));
  }, [preselect, eventHandler, editing, eventsFromInput, projects, isFlyout]);

  const minSlotMinutes = 15;

  const onResizeOrDrop = ({ event, start, end }) => {
    if (isPast(start)) return false;
    setEditing((v) => ({
      ...(v || {}),
      event,
      start: tz.zonedTimeToUtc(start),
      end: tz.zonedTimeToUtc(max([end, addMinutes(start, minSlotMinutes)])),
    }));
    setPreselect({ id: event.id });
  };
  return (
    <CalendarFull
      events={events.filter((e) => show[e?.source])}
      selectable={"ignoreEvents"}
      onSelectEvent={(event) => {
        event.emit("clickEvent", event);
      }}
      onSelectSlot={({ start, end, action }) => {
        if (newFlyout) return;
        if (reschedulingId) return;
        const effectiveEnd = action === "click" ? addMinutes(start, 60) : end;
        return eventHandler("selectTimeslots", {
          start: tz.zonedTimeToUtc(start),
          end: tz.zonedTimeToUtc(max([effectiveEnd, addMinutes(start, minSlotMinutes)])),
        });
      }}
      onSelecting={({ start }) => !reschedulingId && !newFlyout && !isPast(start)}
      onEventDrop={onResizeOrDrop}
      onEventResize={onResizeOrDrop}
      draggableAccessor={({ source }) => source === "clientAvailability"}
      showClientAvailability={showClientAvailability}
      setShowClientAvailability={setShowClientAvailability}
      onDrillDown={() => null}
      newFlyout={newFlyout}
      {...props}
    />
  );
};

export const eventHandler = eventHandlerImported;
