import React from "react";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { getInitialWatchlistData } from "hooks/useWatchlist/utils";
import useWatchlist, {
  DEFAULT_WATCHLIST_NAME,
  PlaceholderWatchlistDataProps,
  WatchlistDataProps,
} from "hooks/useWatchlist";
import { Add } from "@alphasights/alphadesign-icons";

const DataTestIds = {
  createNewWatchlistOption: "create-new-watchlist-option",
};

const CreateWatchlistButton = ({
  hasWatchlists,
  watchlistData,
}: {
  hasWatchlists: boolean;
  watchlistData: WatchlistDataProps[] | PlaceholderWatchlistDataProps[];
}) => {
  const { font } = useThemeTokens();
  const { createWatchlist } = useWatchlist();
  const { logHit } = useTrackUserAction();

  const logCreateWatchlist = (watchlistId: string) =>
    logHit({
      origin: HitOrigin.watchlist,
      action: HitAction.createWatchlist,
      details: {
        watchlistId: watchlistId,
      },
    });

  const handleCreateWatchlist = async () => {
    const newWatchlistData = getInitialWatchlistData(watchlistData);
    let res;
    try {
      // on first creation, adds "New Watchlist" and "New Watchlist 1"
      res = await createWatchlist();
      logCreateWatchlist(res?.id);

      if (!hasWatchlists) {
        res = await createWatchlist(newWatchlistData);
        logCreateWatchlist(res?.id);
      }
    } catch (e) {
      console.error(`Failed to create a Watchlist: ${e}`);
    }
  };

  return (
    <Button
      data-testid={DataTestIds.createNewWatchlistOption}
      variant="ghost"
      size="small"
      startIcon={<Add />}
      fontWeight={font.weight.regular}
      onClick={handleCreateWatchlist}
    >
      {DEFAULT_WATCHLIST_NAME}
    </Button>
  );
};

export { CreateWatchlistButton as default, DataTestIds };
