import React, { useRef } from "react";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { isEllipsisActive } from "../helpers";

type WrapperCellProps = {
  text: string;
  testId: string;
  textColor?: string;
};

const TruncatedTextCell = ({ text, testId, textColor }: WrapperCellProps) => {
  const { color } = useThemeTokens();
  const ref = useRef(null);
  const disableTooltip = ref.current ? !isEllipsisActive((ref.current as unknown) as HTMLElement) : false;

  return (
    <Tooltip title={text} position="bottom" variant="dark" disabled={disableTooltip} size="small">
      <Typography
        data-testid={testId}
        variant="body-small"
        cursor="pointer"
        ref={ref}
        style={{
          color: textColor || color.text.strong._,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTextCell;
