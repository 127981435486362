export interface ProjectSummaryStatusResponse {
  id: string;
  projectId: string;
  angleId?: string;
  status: ProjectSummaryStatus;
  lastUpdatedAt?: string;
  isUpToDate: boolean;
  interactionIds: string[];
}

export enum ProjectSummaryStatus {
  Pending = "PENDING",
  Processing = "PROCESSING",
  Complete = "COMPLETED",
  Error = "ERROR",
}

export interface SelectedInsight {
  insight: ProjectSummaryPart;
  interaction: Interaction;
}

export interface InsightSource {
  transcriptId: string;
  text: string;
  index: number;
}

export interface ProjectSummaryContent {
  id: string;
  projectId: string;
  angleId?: string;
  lastUpdatedAt?: string;
  status: ProjectSummaryStatus;
  interactionIds: string[];
  aiSummaryIds: string[];
  overview: ProjectSummaryOverview;
  themes: ProjectSummaryTheme[];
  isUpToDate: boolean;
}

export enum ThemeType {
  Gpt = "GPT",
  Custom = "CUSTOM",
}

export interface ProjectSummaryTheme {
  title: string;
  description: string;
  overview: ProjectSummaryOverview;
  expertInsights: ExpertInsights[];
  type: ThemeType;
}

export interface ProjectSummaryOverview {
  status: ProjectSummaryStatus;
  parts: ProjectSummaryPart[];
}

export interface ExpertInsights {
  interactionIds: string[];
  parts: ProjectSummaryPart[];
}

export interface ProjectSummaryPart {
  type: string;
  text: string;
  interactionIds: string[];
  sources: InsightSource[];
}

export interface ProjectSummaryAnglePill {
  label: string;
  id: string;
  children: ProjectSummaryAnglePill[];
  transcriptCount: number;
  summariesCount: number;
}
