import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { ExpertCompareQuestion, ExpertCompareResponse, QuestionType } from "models/ExpertCompare";
import { compact, groupBy, minBy, partition, sortBy, take, uniq, values } from "lodash";
import { Typography } from "@alphasights/alphadesign-components";
import { ExpertColumn } from "../ExpertColumn/ExpertColumn";
import styled from "@xstyled/styled-components";
import { mainInteractionFor } from "components/InteractionsPage/helpers/Interaction";
import { QuestionColumn } from "../QuestionColumn/QuestionColumn";

export type ComparisonTableData = {
  interaction: Interaction;
  responses: {
    questionId: number;
    text: string;
  }[];
};

const buildOptions = ({
  rowClassRules,
  onRowClicked,
}: {
  rowClassRules: {
    className: string;
    ruleFunction: (row: AlphaTableTypes.Row<ComparisonTableData>) => any;
  }[];
  onRowClicked: (row: AlphaTableTypes.Row<ComparisonTableData>) => void;
}) => {
  return {
    meta: {
      hideSelectColumn: true,
      fullWidth: true,
      rowClassRules,
      onRowClicked,
    },
  };
};

const buildData = (
  selectedAngle: FilterOption,
  questions: ExpertCompareQuestion[],
  interactions: Interaction[],
  maxExperts: number
) => {
  const responses = questions
    .flatMap((question) =>
      question.responses.map((response) => ({
        questionId: question.id,
        interactionId: (mainInteractionFor(
          interactions.filter((interaction) => interaction.advisorId === response.expertId)
        ) as Interaction | undefined)?.id,
        text: response.text,
      }))
    )
    .filter((response) => !!response.interactionId);

  const advisorIdsToShow = getAdvisorIdsToShow(
    selectedAngle,
    questions.flatMap((q) => q.responses),
    interactions,
    maxExperts
  );

  const mainInteractions = values(groupBy(interactions, "advisorId")).map(
    (chain) => mainInteractionFor(chain) as Interaction
  );

  const data = mainInteractions
    .filter((interaction) => advisorIdsToShow.includes(interaction.advisorId))
    .map(
      (interaction) =>
        ({
          interaction,
          responses: responses
            .filter((r) => r.interactionId === interaction.id)
            .map((response) => ({
              questionId: response.questionId,
              text: response.text,
            })),
        } as ComparisonTableData)
    );

  return data;
};

const getAdvisorIdsToShow = (
  selectedAngle: FilterOption,
  responses: ExpertCompareResponse[],
  interactions: Interaction[],
  maxExperts: number
) => {
  const interactionsFromSelectedAngle = interactions.filter((interaction) =>
    interaction.angles?.some((angle) => [angle?.id, angle?.parent?.id].includes(selectedAngle.value))
  );

  const [interactionsWithResponses, interactionsWithoutResponses] = partition(
    interactionsFromSelectedAngle,
    (interaction) => responses.some((response) => response.expertId === interaction.advisorId)
  );

  const expertIdsWithResponses = uniq(interactionsWithResponses.map((interaction) => interaction.advisorId));

  const expertIdsWithoutResponses = compact(
    sortBy(
      values(groupBy(interactionsWithoutResponses, "advisorId")).map((chain) => minBy(chain, "proposedAt")),
      "proposedAt"
    )
  ).map((interaction) => interaction.advisorId);

  // We're showing all interactions with responses and the oldest proposed interactions without responses up to maxExperts
  return expertIdsWithResponses.concat(take(expertIdsWithoutResponses, maxExperts - expertIdsWithResponses.length));
};

const buildColumns = (questions: ExpertCompareQuestion[], angleIds: string[]) => {
  const meta = {
    enableSorting: false,
    enableColumnActions: false,
    enableDragDrop: true,
  };

  const selectedQuestions = questions.filter((question) => angleIds.includes(question.angle.id));
  const enrichedQuestions = questions.map((question) => ({
    ...question,
    isLoading: question.responses.length === 0 && question.type === QuestionType.Client,
  }));

  const columns: AlphaTableTypes.ColumnDef<ComparisonTableData>[] = [
    {
      id: "expert",
      cell: ({ row: { original: data } }) => <ExpertColumn interaction={data.interaction} />,
      header: () => <ColumnHeader>Expert</ColumnHeader>,
      minSize: 260,
      size: 260,
      meta,
    },
    ...selectedQuestions.map(({ id: questionId }) => ({
      id: `question-${questionId}`,
      cell: ({ row: { original: data } }: { row: AlphaTableTypes.Row<ComparisonTableData> }) => {
        const text = data.responses.find((response) => response.questionId === questionId)?.text ?? "";
        const isLoading = enrichedQuestions.find((question) => question.id === questionId)?.isLoading;
        return <QuestionColumn text={text} isLoading={isLoading} />;
      },
      header: () => <ColumnHeader>{questions.find((q) => q.id === questionId)!.text}</ColumnHeader>,
      size: 295,
      meta,
    })),
  ];

  return columns;
};

const ColumnHeader = styled(Typography).attrs({ variant: "body-small-em", color: "strong" })`
  text-transform: none;
`;

export { buildData, buildColumns, buildOptions };
