import { useTheme } from "@alphasights/alphadesign-components";

export const useFormStyles = (marginShowButton = true) => {
  const {
    tokens: { color, shape, spacing },
  } = useTheme();

  const showPasswordButton = {
    color: color.icon.secondary,
    ...(marginShowButton ? { mr: spacing.inner.base04 } : {}),
  };

  const formFooterDiv = {
    display: "flex",
    flexDirection: "column",
    marginTop: { _: "0px", md: "auto" },
  };

  const separator = {
    borderBottom: `${shape.border.width.small} solid ${color.border.divider}`,
  };

  const formHeader = {
    textAlign: "center",
    marginTop: { _: spacing.layout.base05, md: spacing.layout.base03 },
    marginLeft: spacing.layout.base03,
    marginRight: spacing.layout.base03,
  };

  const formBodyDiv = {
    marginTop: spacing.layout.base,
    marginLeft: spacing.layout.base03,
    marginRight: spacing.layout.base03,
    display: "inline-flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const formContainerDiv = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    h: { _: "100dvh", md: "100%" },
  };

  const formContentDiv = {
    display: "inline-flex",
    flexDirection: "column",
    w: "100%",
  };

  const passwordRuleIcon = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  const passwordRule = {
    color: color.text.secondary,
  };

  const passwordRuleSuccess = {
    color: color.text.success,
  };

  return {
    showPasswordButton,
    formFooterDiv,
    separator,
    formHeader,
    formBodyDiv,
    formContainerDiv,
    formContentDiv,
    passwordRuleIcon,
    passwordRuleSuccess,
    passwordRule,
  };
};
