import React, { useEffect, useMemo } from "react";
import useAvatarColors from "hooks/useAvatarColors";
import { TranscriptSpeakerCue } from "./TranscriptSpeakerCue";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { appendCues } from "./support";

export const SpeechTranscript = ({
  sentences,
  currentTime,
  onCueClick,
  canPlayAudio,
  setActiveCues,
  highlightAiMatches,
  fragmentIds,
  isMobile,
  showPlayer,
  ...props
}) => {
  const transcript = useMemo(() => {
    return appendCues(sentences);
  }, [sentences]);

  useEffect(() => {
    if (transcript?.length && fragmentIds?.length) {
      const cues = transcript
        .flatMap((transcriptFragment) => transcriptFragment.cues)
        .filter((cue) => cue.id === Number(fragmentIds[0]));
      if (onCueClick) {
        onCueClick(cues[0]);
      } else {
        setActiveCues(cues);
      }
    }
  }, [transcript, fragmentIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const { cuesStyles, containerStyles } = useSpeechTranscriptStyles({});

  const orderedUniqueSpeakers = useMemo(
    () =>
      transcript?.reduce((acc, { speaker }) => {
        if (acc.find((existing) => existing.speaker === speaker)) return acc;

        return [...acc, { speaker: speaker }];
      }, []) || [],
    [transcript]
  );

  const avatarColors = useAvatarColors(orderedUniqueSpeakers);

  return (
    <x.div {...containerStyles} {...props}>
      <x.div {...cuesStyles}>
        {transcript?.map(({ id, speaker, timestamp, cues }) => (
          <TranscriptSpeakerCue
            id={id}
            key={speaker + id}
            speakerName={speaker}
            seconds={canPlayAudio && timestamp}
            cues={cues}
            avatarColors={avatarColors}
            currentTime={currentTime}
            onCueClick={onCueClick}
            setActiveCues={setActiveCues}
            highlightAiMatches={highlightAiMatches}
            isMobile={isMobile}
            showPlayer={showPlayer}
          />
        ))}
      </x.div>
    </x.div>
  );
};

const useSpeechTranscriptStyles = () => {
  const { spacing } = useThemeTokens();

  return {
    cuesStyles: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base04,
    },
    containerStyles: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
  };
};
