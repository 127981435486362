import { SliderOption } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";

const TITLE = "Revenue";

const REVENUE_MARKS: SliderOption[] = [
  {
    label: "< $100k",
    value: 0,
  },
  {
    label: "$100k",
    value: 1,
  },
  {
    label: "$1m",
    value: 2,
  },
  {
    label: "$50m",
    value: 3,
  },
  {
    label: "$100m",

    value: 4,
  },
  {
    label: "$500m",
    value: 5,
  },
  {
    label: "$1bn",
    value: 6,
  },
  {
    label: "> $1bn",
    value: 7,
  },
];

const REVENUE_VALUES = [null, 100000, 1000000, 50000000, 200000000, 500000000, 1000000000, null];

export { TITLE, REVENUE_MARKS, REVENUE_VALUES };
