import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const ListOptionWrapper = styled.div(({ selected = false }: { selected?: boolean }) => {
  const {
    color: { border, background },
    spacing: { inner },
    shape: {
      border: { width },
    },
  } = useThemeTokens();

  return {
    "&:hover": !selected
      ? {
          borderColor: border.selected,
          cursor: "pointer",
        }
      : "",
    width: "180px",
    height: "auto",
    padding: inner.base02,
    border: `${width.sm} solid ${border.disabled}`,
    borderRadius: inner.base04,
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
    justifyContent: "space-between",
    backgroundColor: selected ? background.selected.subtle : "transparent",
  };
});
export const CardCompetitorWrapper = styled.div(() => {
  const {
    color: { border },
    spacing: { inner },
    shape: {
      border: { width },
    },
  } = useThemeTokens();

  return {
    height: "auto",
    padding: inner.base06,
    border: `${width.sm} solid ${border.disabled}`,
    borderRadius: inner.base04,
    flex: "1 1 auto",
  };
});
