import { useThemeTokens } from "@alphasights/alphadesign-components";

const useTableStyles = () => {
  const {
    color: { background, border },
    spacing: { inner },
  } = useThemeTokens();

  return {
    surveysMainContent: {
      backgroundColor: background.surface.page.default,
      selectedBackgroundColor: background.selected.subtle,
      selectedBorderColor: border.selected.default,
    },
    surveysSelect: {
      mb: inner.base14,
      maxWidth: "25%",
    },
    wrapper: {
      display: "flex",
      overflow: "auto",
    },
  };
};

export default useTableStyles;
