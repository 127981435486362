import { RefObject, useEffect, useState } from "react";
// These are all temporary solutions for wacky mobile UX issues.
// Not thoroughly tested and hopefully replaced with better approaches soon

// This is a workaround for when the keyboard is opened, it will resize the
// component to the visible viewport size. Useful for raising elements that
// float at the bottom of the screen
export const useResizeWithVisualViewport = ({
  refToResize,
  idToResize,
  elemToResize,
}: {
  refToResize?: RefObject<HTMLDivElement>;
  idToResize?: string;
  elemToResize?: HTMLElement;
}) => {
  const resizeRef = (ev: Event) => {
    const toResize = elemToResize ?? refToResize?.current ?? document.getElementById(idToResize!);

    if (toResize) {
      const targetViewport = ev.target! as HTMLObjectElement;
      toResize.style.minHeight = `${targetViewport.height}px`;
      toResize.style.maxHeight = `${targetViewport.height}px`;
      toResize.style.overflowY = "auto";
    }
  };
  useEffect(() => {
    if (window.visualViewport) {
      const viewport = window.visualViewport;
      viewport.addEventListener("resize", resizeRef);
      return () => viewport.removeEventListener("resize", resizeRef);
    }
  }, [window.visualViewport]); // eslint-disable-line react-hooks/exhaustive-deps
};

// This is useful when you open an absolute div on top of a scrolling div.
// It will keep the div below from scrolling when the absolute runs out and
// will keep the position from when the absolute is removed
export const useFixedWhileInBackground = ({
  refToFix,
  isInBackground,
}: {
  refToFix: RefObject<HTMLDivElement>;
  isInBackground: boolean;
}) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    if (refToFix.current) {
      const y = window.scrollY;
      if (isInBackground) {
        setScrollY(y);
        refToFix.current.style.position = "fixed";
        refToFix.current.style.top = `-${y}px`;
      } else {
        refToFix.current.style.position = "";
        refToFix.current.style.top = "";
        window.scrollTo(0, scrollY);
      }
    }
  }, [isInBackground]); // eslint-disable-line react-hooks/exhaustive-deps
};

// This disable dragging in a component so it is harder to scroll out of bounds.
export const useNoDrag = ({ ref: noDragRef }: { ref: RefObject<HTMLDivElement> }) => {
  const ignoreFn = (e: Event) => e.preventDefault();
  useEffect(() => {
    const cur = noDragRef.current;
    if (cur) {
      cur.addEventListener("touchmove", ignoreFn);
    }
    return () => cur?.removeEventListener("touchmove", ignoreFn);
  }, [noDragRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
};
