import React from "react";
import { RecordingPlayer } from "../RecordingPlayer";
import { useAudioPlayer } from "hooks/useAudioPlayer";
import { Close } from "@alphasights/alphadesign-icons";
import { IconButton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const AudioPlayer = ({ recording, onClose, interactionId, projectToken, origin, language }) => {
  const { controlsProps, progressBarProps, playbackProps, renderElements } = useAudioPlayer({
    audioUrl: recording.url || "",
    disabled: recording.obfuscated,
  });

  return (
    <x.div flexGrow={1} data-testid="audio-player">
      {recording.url ? (
        <>
          {renderElements}
          <RecordingPlayer
            controlsProps={controlsProps}
            progressBarProps={progressBarProps}
            playbackProps={playbackProps}
            disabled={recording.obfuscated}
            interactionId={interactionId}
            projectToken={projectToken}
            recordingId={recording.id}
            origin={origin}
            onClose={onClose}
            language={language}
          />
        </>
      ) : (
        <x.div display="flex" alignItems="center" justifyContent="space-between">
          <x.span ml="16px">Invalid Audio</x.span>
          <IconButton variant="ghost" onClick={onClose}>
            <Close />
          </IconButton>
        </x.div>
      )}
    </x.div>
  );
};
