import { useThemeTokens } from "@alphasights/alphadesign-components";

const useCustomerExpertViewStyles = ({ newNavigationEnabled } = {}) => {
  const {
    shape,
    spacing: { inner },
    color: { background, border },
  } = useThemeTokens();

  return {
    headerWrapper: {
      display: "flex",
      mt: inner.base05,
      mb: inner.base04,
      px: inner.base08,
      justifyContent: "space-between",
      alignItems: "center",
    },
    contentWrapper: {
      minH: 0,
      display: "flex",
      border: newNavigationEnabled ? undefined : `${shape.border.width.sm} solid ${border.divider}`,
      flexGrow: newNavigationEnabled ? 1 : undefined,
    },
    barChartWrapper: {
      borderRight: `${shape.border.width.sm} solid ${border.divider}`,
      backgroundColor: newNavigationEnabled ? undefined : background.surface.page.default,
      flexShrink: 0,
      overflow: "auto",
    },
    tableWrapper: {
      display: "flex",
      ...(newNavigationEnabled && {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        background: background.surface.page.default,
      }),
    },
  };
};

export default useCustomerExpertViewStyles;
