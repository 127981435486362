import React from "react";
import { TextField, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CASE_CODE, COMMISSION_MP_INFO } from "../../constants";
import { RequestForm } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";

const CaseCodeInfoContent = ({
  onChange,
  requestForm,
}: {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  requestForm: RequestForm;
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  const Item = ({ value }: { value: string }) => (
    <x.ul listStyleType="initial" pl={inner.base06}>
      <li>
        <Typography color={text.assistive} variant="body-small">
          {value}
        </Typography>
      </li>
    </x.ul>
  );

  return (
    <>
      <x.div
        display="flex"
        flexDirection="column"
        gap={inner.base02}
        data-testid="section-case-code-input"
        mt={inner.base06}
        mx={inner.base06}
      >
        <Typography variant="body-small" color={text.secondary}>
          {CASE_CODE}
        </Typography>
        <TextField
          size="small"
          onChange={(e) => onChange(e)}
          style={{ fontSize: size["02"] }}
          placeholder="Input case code"
          value={requestForm?.caseCode}
        />
      </x.div>
      <x.div data-testid="section-information" py={inner.base06} className="list" mx={inner.base06}>
        <Typography color={text.assistive} paddingBottom={inner.base02} variant="body-small-em">
          {COMMISSION_MP_INFO.title}
        </Typography>
        {COMMISSION_MP_INFO.listItems.map(({ value, property }) => {
          return property === "root" ? (
            <Item key={`root-${value}`} value={value} />
          ) : (
            <x.ul key={`secondary-${value}`} pl={inner.base06}>
              <li>
                <Item value={value} />
              </li>
            </x.ul>
          );
        })}
      </x.div>
    </>
  );
};

export default CaseCodeInfoContent;
