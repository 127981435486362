import { Button, TextArea, useAlphaToast } from "@alphasights/alphadesign-components";
import React, { useCallback, useState } from "react";
import { useMessengerReplyFieldStyles, StyledAlert } from "./MessengerReplyField.styled";
import { x } from "@xstyled/styled-components";
import { withAccessControl } from "@alphasights/client-portal-shared";
import { useImpersonatingMessenger } from "hooks/messenger/useImpersonatingMessenger";

export type MessengerReplyFieldProps = {
  closeReply: () => void;
  threadId: string;
  onSubmitReply: (threadId: string, message: { content?: string }) => Promise<any>;
  onReplyCreated: (threadId: string, message: any) => void;
};

const REPLY_SIZE_LIMIT = 300;

const ButtonWithAccess = withAccessControl(Button);

export const MessengerReplyField = React.forwardRef<HTMLTextAreaElement, MessengerReplyFieldProps>(
  ({ closeReply, threadId, onSubmitReply, onReplyCreated }, ref) => {
    const { toast } = useAlphaToast();
    const { canSendImpersonatingMessage } = useImpersonatingMessenger();

    const { container, buttonsContainer } = useMessengerReplyFieldStyles();

    const [value, setValue] = useState<string | undefined>("");
    const [isLoading, setIsLoading] = useState(false);

    const onClickSend = useCallback(() => {
      setIsLoading(true);
      onSubmitReply(threadId, {
        content: value,
      })
        .then((message) => {
          toast.success({ message: "Reply sent!" });
          onReplyCreated(threadId, message);
          closeReply();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [onSubmitReply, onReplyCreated, toast, closeReply, threadId, value]);

    const isReplyValid = value != null && value.trim().length > 0 && value.trim().length <= REPLY_SIZE_LIMIT;

    return (
      <x.div data-testid="advisor-thread-reply-form" {...container}>
        <TextArea
          isDynamic={true}
          ref={ref}
          onChange={(evt) => setValue(evt.target.value)}
          width="100%"
          height="60px"
          dataAttributes={{ "data-testid": "reply-textarea" }}
          required
          placeholder="Begin typing your message here..."
        />

        {value && value.length > REPLY_SIZE_LIMIT && (
          <StyledAlert variant="warning" size="small">
            You have exceeded the character limit for this response.
          </StyledAlert>
        )}

        {canSendImpersonatingMessage && (
          <StyledAlert variant="warning" size="small">
            You must receive written approval from the client that you are impersonating before sending a reply to an
            expert.
          </StyledAlert>
        )}

        <x.div {...buttonsContainer}>
          <Button variant="ghost" size="small" onClick={closeReply} disabled={isLoading}>
            Cancel
          </Button>

          <ButtonWithAccess
            data-testid="send-reply-button"
            accessControl={canSendImpersonatingMessage ? undefined : { allowedPermissions: ["follow_up_messages"] }}
            onClick={onClickSend}
            disabled={!isReplyValid || isLoading}
            variant="secondary"
            size="small"
            loading={isLoading}
          >
            Send
          </ButtonWithAccess>
        </x.div>
      </x.div>
    );
  }
);
