import React from "react";
import { x } from "@xstyled/styled-components";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { useMarketPrimersStore } from "../../state/store";
import { SectionTitle } from "pages/AlphaNowPage/primers/components";
import SegmentationSegment from "./SegmentationSegment";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Segmentation = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { spacing } = useThemeTokens();
  const { segmentation } = useMarketPrimersStore();

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <x.div w="100%">
        <SectionTitle
          text="Segmentation"
          dataTestId="SegmentationSectionTitle"
          xtraProps={{ marginBottom: spacing.inner.base04 }}
        />
        <x.div display="flex" flexDirection="column" gap={spacing.layout.base04}>
          {segmentation.customers.length > 0 && (
            <>
              <SegmentationSegment title="Customers" segments={segmentation.customers} />
              <Divider />
            </>
          )}
          {segmentation.products.length > 0 && (
            <>
              <SegmentationSegment title="Products" segments={segmentation.products} />
              <Divider />
            </>
          )}
          {segmentation.regions.length > 0 && (
            <>
              <SegmentationSegment title="Regions" segments={segmentation.regions} />
              <Divider />
            </>
          )}

          {segmentation.customised.length > 0 &&
            segmentation.customised.map((customisedSegmentation) => {
              if (customisedSegmentation.segments.length === 0) return null;

              return (
                <>
                  <SegmentationSegment
                    title={customisedSegmentation.type.value ?? ""}
                    segments={customisedSegmentation.segments}
                  />
                  <Divider />
                </>
              );
            })}
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Segmentation));
