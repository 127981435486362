import React, { useCallback, useMemo } from "react";
import { HideAlt, Loading, ShowAlt } from "@alphasights/alphadesign-icons";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { useProjectBadgeContext, ENABLE_PORTAL_COMMENTS } from "../../providers/BadgeProvider";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { x } from "@xstyled/styled-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";

export const HideColumn = ({ row: { original: data } }) => {
  const { font } = useThemeTokens();
  const { locked } = useLockedExpert(data);

  const { isExpertHidden } = useExpertRemarksContext();

  return (
    <div className="aui-space-x-4 aui-inline-flex aui-items-center">
      <HideExpertButton hidden={isExpertHidden(data)} data={data} fontSize={font.size["06"]} disabled={locked} />
    </div>
  );
};

const HideExpertButton = ({ hidden, data, disabled, ...props }) => {
  const icon = useMemo(() => (hidden ? <ShowAlt /> : <HideAlt />), [hidden]);
  const currentUser = useCurrentUser();
  const { hasProjectBadge } = useProjectBadgeContext();

  let tooltip = useMemo(
    () =>
      hidden
        ? "Show expert"
        : currentUser && hasProjectBadge(ENABLE_PORTAL_COMMENTS)
        ? "Hide expert with reason"
        : "Hide expert",
    [currentUser, hasProjectBadge, hidden]
  );

  const { setHideRef, onToggleHiddenWithMessage, isLoading: isLoadingInteraction } = useHideExpertContext();
  const isLoading = useMemo(() => isLoadingInteraction(data.id), [data.id, isLoadingInteraction]);

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      onToggleHiddenWithMessage(data, HitOrigin.tableView, "table-view");
    },
    [onToggleHiddenWithMessage, data]
  );

  return isLoading ? (
    <x.div {...props}>
      <Loading style={{ animation: "x-spin 1s linear infinite" }} />
    </x.div>
  ) : (
    <x.div
      ref={(ref) => setHideRef(data.id, ref, "table-view")}
      style={{ display: "flex", alignItems: "center" }}
      cursor={disabled ? "not-allowed" : "auto"}
      {...props}
    >
      <PortalTooltip title={tooltip} disabled={disabled} dark>
        <Icon
          color="secondary"
          onClick={disabled ? undefined : onClick}
          data-testid={`hide-column-hide-expert-${data.id}`}
        >
          {icon}
        </Icon>
      </PortalTooltip>
    </x.div>
  );
};
