import React, { forwardRef } from "react";
import { x } from "@xstyled/styled-components";
import {
  Icon as IconWrapper,
  Typography,
  TypographyProps,
  useThemeTokens,
  XStyledAndHTMLProps,
} from "@alphasights/alphadesign-components";

type TagProps = {
  name: string;
  icon?: React.ReactNode;
  style?: XStyledAndHTMLProps<HTMLDivElement>;
  typographyStyle?: Partial<TypographyProps>;
} & XStyledAndHTMLProps<HTMLDivElement>;

const Tag = forwardRef(
  ({ name, style = {}, typographyStyle = {}, icon = null, ...props }: TagProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      color: { text, background },
      spacing: { inner },
      shape: { border },
    } = useThemeTokens();

    return (
      <x.div
        ref={ref}
        flex
        row
        flexShrink="0"
        flexGrow="0"
        spaceX={inner.base}
        color={text.strong._}
        bg={background.neutral.default}
        py={inner.base}
        px={inner.base02}
        borderRadius={border.radius.medium}
        alignItems="center"
        {...props}
        {...style}
      >
        {icon && <IconWrapper size="small">{icon}</IconWrapper>}
        <Typography variant="body-small" {...typographyStyle}>
          {name}
        </Typography>
      </x.div>
    );
  }
);

export default Tag;
