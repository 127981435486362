import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useDeliverablesViewStyles = () => {
  const {
    color: { background, border, icon },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  const recommendedContentWrapper = {
    display: "flex",
    overflow: "auto",
    flexGrow: 1,
  };

  const recommendedContentDiv = {
    display: "flex",
    flexDirection: "column",
    bg: background.surface.page.default,
    overflow: "auto",
    w: "full",
    pt: inner.base08,
    px: inner.base08,
    position: "relative",
  };

  const recommendedContentTableDiv = {
    mt: inner.base05,
  };

  const insightsReferences = {
    wrapper: {
      p: inner.base06,
      pb: 0,
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base02,
      maxWidth: "360px",
      h: "100%",
      borderLeft: "1px solid",
      borderLeftColor: border.divider,
    },
    close: {
      display: "flex",
      alignItems: "center",
      columnGap: inner.base04,
      marginBottom: inner.base03,
    },
    cardsWrap: {
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base02,
      overflow: "scroll",
    },
    cardWrap: {
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base03,
      p: inner.base04,
      border: "1px solid",
      borderColor: border.divider,
      borderRadius: shape.border.radius.small,
      background: "white",
    },
    cardTitle: {
      display: "flex",
      alignItems: "center",
      columnGap: inner.base02,
    },
    expertIcon: {
      backgroundColor: background.decorative.base05,
      color: icon.decorativeBase05,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: shape.border.radius.large,
      h: inner.base08,
      w: inner.base08,
      flexShrink: 0,
    },
  };

  const recommendedContentTitle = {
    display: "flex",
    alignItems: "center",
    gap: inner.base03,
    minH: inner.base08,
  };

  return {
    recommendedContentWrapper,
    recommendedContentDiv,
    recommendedContentTableDiv,
    insightsReferences,
    recommendedContentTitle,
  };
};
