import React, { useMemo } from "react";
import { Icon, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Companies, Lock, Purchased } from "@alphasights/alphadesign-icons";
import { formatDate } from "utils/dates";
import { x } from "@xstyled/styled-components";
import { ContentCTAs } from "./ContentCTAs";
import useStyles from "./styles";
import { lastUpdatedAtHeader } from "content/AlphaNow";
import { AlphaNowSRMProductType } from "@alphasights/client-portal-shared";

export const PrimerDetails = ({ suggestion, updateDeliverableSuggestion, freeLink }) => {
  const styles = useStyles();
  const { anchorCompanies } = suggestion;
  const companyName = anchorCompanies.map(({ companyName }) => companyName).find((it) => it);

  return (
    <x.div {...styles.primerTopBar}>
      <x.div {...styles.suggestionHeaderDisplay}>
        <CompanyLogo suggestion={suggestion} />
        <ContentCTAs
          suggestion={suggestion}
          updateDeliverableSuggestion={updateDeliverableSuggestion}
          downloadFileName={companyName}
        />
      </x.div>
      <DateProduced suggestion={suggestion} freeLink={freeLink} />
    </x.div>
  );
};

const CompanyLogo = ({ suggestion }) => {
  const styles = useStyles();
  const { anchorCompanies, externalTitle, primerCompanyLogoSrc, productType } = suggestion;

  const title = useMemo(() => {
    switch (productType) {
      case AlphaNowSRMProductType.companyPrimer:
        return `Company Primer: ${anchorCompanies.map(({ companyName }) => companyName).find((it) => it)}`;
      case AlphaNowSRMProductType.marketPrimer:
      case AlphaNowSRMProductType.customerPrimer:
        return externalTitle;
      default:
        return "";
    }
  }, [anchorCompanies, externalTitle, productType]);

  const Logo = () => {
    if (productType === AlphaNowSRMProductType.companyPrimer && primerCompanyLogoSrc) {
      return <x.img alt={`${title || "company"} logo`} src={primerCompanyLogoSrc} {...styles.suggestionHeaderLogo} />;
    }

    return (
      <Icon size="x-large">
        <Companies />
      </Icon>
    );
  };

  return (
    <x.div {...styles.suggestionHeaderTitle}>
      {productType !== AlphaNowSRMProductType.marketPrimer && (
        <x.div display="flex" alignItems="center">
          <Logo suggestion={suggestion} />
        </x.div>
      )}
      <x.div display="flex" overflow="hidden" textOverflow="ellipsis">
        <Typography component="span" textTransform="none" variant="h3" fontWeight="600">
          {title}
        </Typography>
      </x.div>
    </x.div>
  );
};

const IconWrapper = ({ children, text, testId, iconColor }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return (
    <x.div display="inherit" alignItems="inherit" data-testid={testId}>
      <x.div display="inherit" alignItems="inherit" spaceX={inner.base} color={iconColor}>
        <Icon>{children}</Icon>
        <Typography component="span">{text}</Typography>
      </x.div>
    </x.div>
  );
};

const DateProduced = ({ freeLink, suggestion }) => {
  const { updatedAt, createdAt, lastUpdatedAt } = suggestion;
  const formattedDate = formatDate(lastUpdatedAt || updatedAt || createdAt, "d MMM yyyy");
  const styles = useStyles();
  const {
    color: { icon, border },
  } = useThemeTokens();

  return (
    <x.div {...styles.primerDateProduced}>
      {!suggestion.isAccessible && !suggestion.privateMode && <WhatIsAPrimer />}
      <x.div display={"flex"} alignItems={"center"}>
        <Typography color="secondary">
          {lastUpdatedAtHeader}
          {formattedDate}
        </Typography>
        {!suggestion.isAccessible && freeLink && (
          <x.div>
            <Typography component="span" px="300">
              |
            </Typography>
            <Link href={freeLink} target="_blank" data-testid="free-sample-link">
              <Typography variant="body-em">View sample Primer</Typography>
            </Link>
          </x.div>
        )}
        {suggestion.isAccessible && (
          <>
            <Typography component="span" px="300" variant="body-large" color={border.neutral.default}>
              |
            </Typography>
            <IconWrapper
              testId="suggestion-header-purchased-status"
              iconColor={icon.success}
              text={suggestion.purchaseStatus}
            >
              <Purchased />
            </IconWrapper>
          </>
        )}
        {suggestion.privateMode && (
          <>
            <Typography component="span" px="300" variant="body-large" color={border.neutral.default}>
              |
            </Typography>
            <IconWrapper testId="suggestion-header-private-mode" iconColor={icon.secondary} text="Private">
              <Lock />
            </IconWrapper>
          </>
        )}
      </x.div>
    </x.div>
  );
};

export const WhatIsAPrimer = ({ mobile = false }) => {
  const styles = useStyles();
  return (
    <x.div {...styles.primerWhatIs}>
      <Typography variant={mobile ? "body-em-small" : "body-em"} component="div" color={styles.textSecondary.color}>
        What is a Primer?
      </Typography>
      <PrimerDescription mobile={mobile} />
    </x.div>
  );
};

export const PrimerDescription = ({ mobile = false }) => {
  const variant = mobile ? "body-small" : "body";
  return (
    <x.ul listStyleType={"disc"} marginLeft={"20px"}>
      <x.li>
        <Typography variant={variant}>
          Primers are expert-led company overviews that replace ‘day 1’ desktop research
        </Typography>
      </x.li>
      <x.li>
        <Typography variant={variant}>
          We synthesize experts insights on a company’s background, competitive dynamics, and outlook
        </Typography>
      </x.li>
      <x.li>
        <Typography variant={variant}>
          Click on a sentence to see the contributing expert(s) and request a call
        </Typography>
      </x.li>
    </x.ul>
  );
};
