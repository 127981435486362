import React, { useState } from "react";
import { x } from "@xstyled/styled-components";
import { withAccessControl } from "@alphasights/client-portal-shared";
import { Alert, Button, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { MaximumChargeLink } from "pages/InteractionPage/sections/Topbar/WorkRequestMaximumCharge";
import { ExpertChargesList } from "components/MessengerPage/ExpertChargesList";
import { formatTimeInHoursAndMinutes } from "helpers/interactionHelpers";
import { calculateTotalCredit, convertCreditsToString } from "components/MessengerPage/helper";
import { ENABLE_MESSAGES_COMPLIANCE_REVIEW, useProjectBadgeContext } from "providers/BadgeProvider";
import { useIsInternalUser } from "@alphasights/portal-auth-react";
import { useImpersonatingMessenger } from "hooks/messenger/useImpersonatingMessenger";
import { Expert } from "types/common-types";

const ButtonWithAccess = withAccessControl(Button);

const SendMessageModal = ({
  children,
  isOpen,
  onClose,
  title,
  onSend,
  sendButtonText,
  onCancel,
  isLoading,
  blinded,
  needComplianceReview,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onSend: () => void;
  sendButtonText: string;
  onCancel: () => void;
  isLoading: boolean;
  blinded: boolean;
  needComplianceReview: boolean;
}) => {
  const isInternalUser = useIsInternalUser();

  const { isMobile } = useCheckScreen();
  const { canSendImpersonatingMessage } = useImpersonatingMessenger();
  const [showExpandedDisclaimer, setShowExpandedDisclaimer] = useState(false);

  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const modalStyles = {
    h: isMobile ? "100%" : undefined,
    display: "flex",
    flexDirection: "column",
  };

  const skipPermissionsCheck = needComplianceReview || canSendImpersonatingMessage;

  return (
    <ResponsiveModal
      variant="fullscreen"
      title={<x.div color={text.strong._}>{title}</x.div>}
      open={isOpen}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      primaryButton={
        <ButtonWithAccess
          data-testid="messages-flyout-v2-modal-send-button"
          variant="secondary"
          accessControl={skipPermissionsCheck ? undefined : { allowedPermissions: ["follow_up_messages"] }}
          onClick={() => {
            if (!isLoading) {
              onSend();
            }
          }}
          loading={isLoading}
          cursor={isLoading ? "not-allowed" : "pointer"}
        >
          {sendButtonText}
        </ButtonWithAccess>
      }
      secondaryButton={
        <Button
          variant="ghost"
          mr={spacing.inner.base04}
          onClick={() => {
            if (!isLoading) {
              onCancel();
            }
          }}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
      }
      testId="send-message-modal"
      {...modalStyles}
    >
      <Body isMobile={isMobile}>
        {children}

        {(needComplianceReview && isInternalUser) || canSendImpersonatingMessage ? (
          <Alert my={spacing.layout.base03} variant="warning">
            You must receive written approval from the client that you are impersonating before sending a Work Request
            to an expert.
          </Alert>
        ) : (
          <Alert my={spacing.layout.base03}>
            You must not use AlphaSights Messenger to solicit confidential, proprietary or material non-public
            information. Your AlphaSights project lead has no visibility of expert responses.
          </Alert>
        )}
        {(!isMobile || showExpandedDisclaimer) && (
          <Typography component="div" overflow="auto" maxHeight="175px" minHeight="175px">
            <DisclaimerText
              setShowExpandedDisclaimer={setShowExpandedDisclaimer}
              showExpandedDisclaimer={showExpandedDisclaimer}
              isMobile={isMobile}
              blinded={blinded}
              needComplianceReview={needComplianceReview}
            />
          </Typography>
        )}
      </Body>
    </ResponsiveModal>
  );
};

const Body = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }) => {
  return (
    <x.div maxWidth="640px" minWidth={!isMobile ? "640px" : undefined}>
      {children}
    </x.div>
  );
};

const StorageText = ({
  showExpandedDisclaimer,
  setShowExpandedDisclaimer,
  isMobile,
  needComplianceReview,
}: {
  showExpandedDisclaimer: boolean;
  setShowExpandedDisclaimer: (value: boolean) => void;
  isMobile: boolean;
  needComplianceReview: boolean;
}) => {
  const { spacing } = useThemeTokens();

  return (
    <Typography mt={spacing.inner.base02} variant="body-small" color="secondary">
      <Typography component="span" variant="body-small-em">
        Storage:
      </Typography>{" "}
      {needComplianceReview &&
        "AlphaSights remotely archives all written correspondence and attachments exchanged via AlphaSights Messenger following project closure. AlphaSights has no access to Expert responses. Expert responses are permanently deleted from our systems 48 business hours after project closure. All projects are automatically closed following 90 days of inactivity."}
      {!needComplianceReview &&
        "AlphaSights remotely archives all written correspondence and attachments exchanged via AlphaSights Messenger for 48 business hours after project closure, after which they will be permanently deleted. All projects are automatically closed following 90 days of inactivity. Except for your initial request, AlphaSights has no access to any written correspondence or attachments."}
      {isMobile && !showExpandedDisclaimer && (
        <ShowDisclamerLink onClick={() => setShowExpandedDisclaimer(!showExpandedDisclaimer)}>
          Show More
        </ShowDisclamerLink>
      )}
    </Typography>
  );
};

const DisclaimerText = ({
  showExpandedDisclaimer,
  setShowExpandedDisclaimer,
  isMobile,
  blinded,
  needComplianceReview,
}: {
  showExpandedDisclaimer: boolean;
  setShowExpandedDisclaimer: (value: boolean) => void;
  blinded: boolean;
  isMobile: boolean;
  needComplianceReview: boolean;
}) => {
  const { spacing } = useThemeTokens();

  if (isMobile && !showExpandedDisclaimer) return <></>;

  return (
    <Typography mt={spacing.inner.base} variant="body-small" color="secondary">
      <Typography component="span" variant="body-small-em">
        Disclaimer:
      </Typography>{" "}
      In using AlphaSights Messenger, you acknowledge and agree that each use of this service shall constitute a
      “Transaction” as defined under the Access Terms or other written agreement between your employer or its Affiliate
      and AlphaSights (each, an “Agreement”) and shall be governed by the terms of that Agreement. Accordingly,
      AlphaSights takes no responsibility or liability whatsoever in respect of any information exchanged between you
      and an Expert using AlphaSights Messenger, including in respect of the information’s: (i) accuracy or
      completeness; (ii) compliance with applicable Law or Intellectual Property Rights; or (iii) exclusion of any
      Inside Information or confidential information belonging to another party. Any information exchanged is shared
      directly between you and the Expert and AlphaSights will not review, monitor or modify any written correspondence
      or attachments. Any response you receive from an Expert shall constitute an Expert Engagement Record. Any Expert
      Engagement Record submitted by an Expert solely constitutes the opinion of such Expert and not the opinion of
      AlphaSights.
      {showExpandedDisclaimer && (
        <Typography component="span" variant="body-small" color="secondary">
          <Typography mt={spacing.inner.base02} variant="body-small">
            <Typography component="span" variant="body-small-em">
              Terms of Use:
            </Typography>{" "}
            {needComplianceReview &&
              "AlphaSights Messenger facilitates direct communications between clients and experts for specific, documented purposes. In particular, you are prohibited from using AlphaSights Messenger to: (i) ask specific questions about an Expert’s current employer; (ii) solicit any confidential or Insider Information; (iii) exchange direct contact details with an Expert; or (iv) exchange legal documents (i.e. non-disclosure agreements), unless this is in preparation for a Direct Engagement"}
            {!needComplianceReview &&
              "AlphaSights Messenger facilitates direct communications between clients and experts for specific documented purposes. In particular, you are prohibited from using AlphaSights Messenger to respond to specific questions that request: (i) any information about your current employer; (ii) any Confidential Information or Material Non-Public Information (as defined in the Terms of Engagement); (iii) that you exchange direct contact details with a client; or (iv) that you exchange legal documents (i.e., non-disclosure agreements), unless this is in preparation for a Direct Engagement. Following submission of your response, in accordance with the Terms of Engagement, you must delete any locally saved copies of the request and/or its attachments."}
            {blinded && (
              <>
                <x.div mt={spacing.inner.base02} />
                <Typography component="span" variant="body-small-em">
                  Blinded Transaction:
                </Typography>{" "}
                This is a blinded Transaction so the recipient of your message should not know your or your company's
                identity. You should introduce yourself with your first name only and should not share any information
                that could reveal your or your company's identity in your message(s) or attached files. While we have
                implemented stringent protocols to reduce the risk of your identity being revealed, please be aware that
                we cannot guarantee anonymity. By sending this message, you acknowledge and accept that anonymity is not
                guaranteed and release AlphaSights of any liability in the event that a party's identity is disclosed
                during this Transaction and any related correspondence.{" "}
              </>
            )}
            <StorageText
              setShowExpandedDisclaimer={setShowExpandedDisclaimer}
              showExpandedDisclaimer={showExpandedDisclaimer}
              isMobile={isMobile}
              needComplianceReview={needComplianceReview}
            />
            <ShowDisclamerLink onClick={() => setShowExpandedDisclaimer(!showExpandedDisclaimer)}>
              Show Less
            </ShowDisclamerLink>
          </Typography>
        </Typography>
      )}{" "}
      {!isMobile && !showExpandedDisclaimer && (
        <ShowDisclamerLink onClick={() => setShowExpandedDisclaimer(!showExpandedDisclaimer)}>
          Show More
        </ShowDisclamerLink>
      )}
    </Typography>
  );
};

const ShowDisclamerLink = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <Link component="button" onClick={onClick}>
      <Typography component="span" variant="body-small-em">
        {children}
      </Typography>
    </Link>
  );
};

export const SendClarificationModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  message,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: number;
  message: string;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  return (
    <SendMessageModal
      title={"Send Clarification"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Clarification"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      needComplianceReview={needComplianceReview}
    >
      <x.div>
        <Typography overflow="auto" color={text.strong._}>
          Message: {message}
        </Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Experts: {experts}
        </Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Maximum charge: Free
        </Typography>
      </x.div>
    </SendMessageModal>
  );
};

export const SendCallGuideModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: number;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  return (
    <SendMessageModal
      title={"Send Call Guide"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Call Guide"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      needComplianceReview={needComplianceReview}
    >
      <x.div>
        <Typography color={text.strong._}>Experts: {experts}</Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Maximum charge: Free
        </Typography>
      </x.div>
    </SendMessageModal>
  );
};

export const SendWorkRequestModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  attachments,
  maximumTaskDuration,
  deadline,
  charge,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: Expert[];
  attachments: number;
  maximumTaskDuration: number;
  charge: string;
  deadline: string;
  message: boolean;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  const expertCharges = experts.map((selectedExpert) => ({
    credits: convertCreditsToString(calculateTotalCredit([selectedExpert], maximumTaskDuration)),
    name: selectedExpert.name,
    alphaCircleMultiplier: selectedExpert.alphaCircleMultiplier,
  }));

  const [isExpertsListOpen, setIsExpertsListOpen] = useState(false);

  return (
    <SendMessageModal
      title={"Send Work Request"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Work Request"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      needComplianceReview={needComplianceReview}
    >
      <x.div display="flex">
        <x.div flex="100%" display="inline-grid" gap={spacing.layout.base02}>
          <x.div display="flex">
            <Typography flex="50%" color={text.strong._}>
              Experts: {experts.length}
            </Typography>
            <Typography flex="50%" color={text.strong._}>
              Maximum task duration: {formatTimeInHoursAndMinutes(maximumTaskDuration)}
            </Typography>
          </x.div>
          <x.div display="flex">
            <Typography flex="50%" color={text.strong._}>
              Attachments: {attachments}
            </Typography>
            <Typography flex="50%" color={text.strong._}>
              Deadline: {deadline}
            </Typography>
          </x.div>
          <x.div>
            <Typography color={text.strong._}>
              Maximum charge:{" "}
              <MaximumChargeLink
                expertCount={expertCharges.length}
                totalCharge={charge}
                opened={isExpertsListOpen}
                setOpened={setIsExpertsListOpen}
                fontColor={text.strong._}
              />
            </Typography>
            {isExpertsListOpen && (
              <x.div pt={spacing.inner.base02}>
                <ExpertChargesList experts={expertCharges} />
              </x.div>
            )}
          </x.div>
        </x.div>
      </x.div>
    </SendMessageModal>
  );
};
