import React, { useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Typography } from "@alphasights/alphadesign-components";
import { useAlphaGPTCitationStyles } from "./AlphaGPTCitation.styles";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { Citation, MessageType } from "models/AlphaGpt";
import { CONTENT_TYPE_DISPLAY_NAME, FLAGS, Companies } from "@alphasights/client-portal-shared";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { Company } from "@alphasights/alphadesign-icons";
import { compact } from "lodash";
import { useAlphaGPTMessageContext } from "providers/AlphaGPTMessageProvider";
import { AlphaGptCollapsibleList } from "../AlphaGPTCollapsibleList/AlphaGPTCollapsibleList";
import { Flags } from "pages/AlphaNowPage/components";

export const AlphaGPTCitations = ({
  collapseCitations = false,
  citations,
}: {
  collapseCitations?: boolean;
  citations: Citation[];
}) => {
  const { selectedContent } = useAlphaGPTContext();
  const [citationsState, setCitationsState] = React.useState(citations);

  useEffect(
    function updateCitationsFieldsOnContentPurchased() {
      if (selectedContent) {
        setCitationsState((prev) =>
          prev.map((citation) =>
            citation.id === selectedContent.id
              ? {
                  ...citation,
                  ...(citation.content
                    ? {
                        content: {
                          ...citation.content,
                          purchaseStatus: selectedContent.purchaseStatus ?? citation.content.purchaseStatus,
                        },
                      }
                    : {}),
                }
              : citation
          )
        );
      }
    },
    [selectedContent]
  );

  return (
    <AlphaGptCollapsibleList
      collapse={collapseCitations}
      items={citationsState.map((citation) => (
        <AlphaGPTCitation citation={citation} key={citation.id} />
      ))}
    />
  );
};

const AlphaGPTCitation = React.forwardRef(
  ({ citation }: { citation: Citation }, ref?: React.ForwardedRef<HTMLLIElement>) => {
    const { wrapper } = useAlphaGPTCitationStyles();
    const { onCitationClick } = useAlphaGPTContext();

    const onClick = onCitationClick ?? (() => citation.link && window.open(citation.link, "_blank")?.focus());

    return (
      <x.li
        onClick={() => onClick(citation, citation.contentType)}
        data-testid={`citation-${citation.id}`}
        ref={ref}
        {...wrapper}
      >
        {citation.contentType === "COMPANY_PRIMER" ? (
          <PrimerCitation citation={citation} />
        ) : (
          <ContentCitation citation={citation} />
        )}
      </x.li>
    );
  }
);

const PrimerCitation = ({ citation }: { citation: Citation }) => {
  const { primerWrapper, primerLogo, primerImg, primerNoLogo, primerDescription, footer } = useAlphaGPTCitationStyles();
  const { type } = useAlphaGPTMessageContext();

  const showPosition = !!citation.position && type !== MessageType.GetQuotes;
  const primerCompanyLogoSrc = citation.content?.logoLink;
  const title = citation.title;
  const isAccessible = isCitationAccessible(citation);
  const flagItems = isAccessible ? [FLAGS.Purchased] : [];

  return (
    <x.div {...primerWrapper}>
      <x.div data-testid="primer-logo" {...primerLogo}>
        {primerCompanyLogoSrc ? (
          <x.img src={primerCompanyLogoSrc} alt={`${title ?? "company"} logo`} {...primerImg} />
        ) : (
          <div data-testid="placeholder-icon">
            <Icon size="large" {...primerNoLogo}>
              <Company />
            </Icon>
          </div>
        )}
      </x.div>
      <x.div flexGrow={1}>
        <x.div>
          {showPosition && (
            <Typography variant="body-em" color="link" component="span">
              [{citation.position}]{" "}
            </Typography>
          )}
          <Typography color="strong" component="span">
            {citation.title}
          </Typography>
        </x.div>
        <x.div {...footer}>
          <Typography variant="body-small" color="secondary" {...primerDescription}>
            {citation?.description ?? ""}
          </Typography>
          <Flags items={flagItems} />
        </x.div>
      </x.div>
    </x.div>
  );
};

const ContentCitation = ({ citation }: { citation: Citation }) => {
  const { footer, info, title: titleStyles } = useAlphaGPTCitationStyles();
  const { type } = useAlphaGPTMessageContext();

  const showPosition = !!citation.position && type !== MessageType.GetQuotes;
  const citationInfo = compact([citation.title?.split("-")[0].trim(), citation.title?.split("-")[1]?.trim()]);
  const publishedAt = citation.publishedAt;
  const contentTypeDisplayName =
    citation.contentType === "PRIVATE_TRANSCRIPT"
      ? "Project Transcripts"
      : CONTENT_TYPE_DISPLAY_NAME[citation.contentType];
  const isAccessible = isCitationAccessible(citation);
  const footerInfo = compact([citation.description, contentTypeDisplayName]);
  const flagItems = isAccessible ? [FLAGS.Purchased] : [];

  return (
    <>
      <x.div {...info}>
        <Companies companies={citation.companies} searchCompanies={[]} />
        <Typography variant="body-small" color="secondary" flexShrink={0}>
          <FormattedDateTime date={publishedAt} format="dd LLL yyyy" />
        </Typography>
      </x.div>
      <x.div>
        {showPosition && (
          <Typography variant="body-em" color="link" component="span">
            [{citation.position}]{" "}
          </Typography>
        )}
        {!isAccessible ? (
          <Typography variant="body-small" color="secondary" component="span">
            Purchase to view
          </Typography>
        ) : (
          <>
            {(citation.quote?.length ?? 0) > 0 ? (
              <Typography color="strong" component="span">
                "{citation.quote}"
              </Typography>
            ) : (
              <Typography variant="body-small" color="secondary" component="span">
                View transcript for full context on this citation.
              </Typography>
            )}
          </>
        )}
      </x.div>
      <x.div {...titleStyles}>
        {citationInfo.map((info, index) => (
          <Typography variant={index === 0 ? "body-em" : "body"} color="strong" component="span" key={index}>
            {index === 1 ? " - " : ""}
            {info}
          </Typography>
        ))}
      </x.div>
      <x.div {...footer}>
        <Typography variant="body-small" color="secondary">
          {footerInfo.join(" | ")}
        </Typography>
        <Flags items={flagItems} />
      </x.div>
    </>
  );
};

const isCitationAccessible = (citation: Citation) => {
  return (
    citation.contentType === "PRIVATE_TRANSCRIPT" ||
    (citation.content &&
      citation.content.purchaseStatus &&
      citation.content.approvalStatus &&
      isContentAccessible(citation.content.purchaseStatus, citation.content.approvalStatus))
  );
};
