import { Icon as AdsIcon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Recommended, Translate, Verified } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { TotalHighlights } from "components/TotalHighlights";
import React from "react";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { CustomReason } from "../../components/HighlyRecommendedPopover";
import { getLanguageFromCode } from "../../helpers/displayHelpers";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";

const Badge = React.forwardRef(({ IconComponent, text, ...props }, ref) => {
  return (
    <x.div
      ref={ref}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="base"
      lineHeight="4"
      {...props}
    >
      {IconComponent && (
        <AdsIcon size="small">
          <IconComponent />
        </AdsIcon>
      )}
      {text}
    </x.div>
  );
});

const HighlyRecommendedBadge = ({ data: { highlyRecommendedReason, primaryContact }, ...props }) => {
  const hasCustomReason = highlyRecommendedReason && primaryContact;

  return (
    <PortalTooltip
      position="bottom"
      title={
        hasCustomReason ? (
          <CustomReason reason={highlyRecommendedReason} primaryContact={primaryContact} />
        ) : (
          "This expert is highly recommended."
        )
      }
    >
      <Badge IconComponent={Recommended} {...props} />
    </PortalTooltip>
  );
};

const NeedsInterpreterBadge = ({ needsInterpreter, ...props }) => (
  <PortalTooltip title={`Needs Interpreter: ${getLanguageFromCode(needsInterpreter)}`} position="bottom">
    <Badge IconComponent={Translate} {...props} />
  </PortalTooltip>
);

const VerifiedBadge = ({ ...props }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <PortalTooltip
      title="This advisor has completed multiple successful interactions with your colleagues over the past 12 months"
      position="bottom"
    >
      <Badge
        style={{ marginLeft: spacing.layout.base, color: color.icon.strong._ }}
        IconComponent={Verified}
        {...props}
      />
    </PortalTooltip>
  );
};

export const AdvisorColumn = ({ row: { original: data } }) => {
  const { color, spacing } = useThemeTokens();
  const { isExpertHidden } = useExpertRemarksContext();

  const textClass = isExpertHidden(data) ? color.text.secondary : color.text.info;
  const totalHighlights = data.highlights?.flatMap(({ terms }) => terms).length;
  const isNameNotWithheld = data.advisorName !== "[advisor name withheld]";

  return (
    <x.div
      data-testid={`advisor-row-name-${data.id}`}
      display="flex"
      hidden={isExpertHidden(data)}
      value={data.advisorName}
    >
      {isNameNotWithheld ? (
        <HighlightTextTypography
          variant="body-small"
          component="span"
          highlights={data.highlights}
          fieldNames={[
            "advisorships.advisor_name",
            "advisorships.advisor_name.concat",
            "advisor.name",
            "advisor.name.concat",
          ]}
          text={data.advisorName}
          color={textClass}
        />
      ) : (
        <Typography
          variant="body-small"
          component="span"
          color={textClass}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {data.advisorName}
        </Typography>
      )}

      <x.div display="flex" gap={spacing.layout.base} flexShrink={0}>
        {data.isActiveProject && data.verified && <VerifiedBadge data-testid={`advisor-row-verified-${data.id}`} />}
        {data.needsInterpreter && (
          <NeedsInterpreterBadge
            needsInterpreter={data.needsInterpreter}
            data-testid={`advisor-row-interpreter-${data.id}`}
          />
        )}
        {data.highlyRecommended && (
          <HighlyRecommendedBadge data={data} data-testid={`advisor-row-recommended-${data.id}`} />
        )}
        {totalHighlights > 0 && (
          <TotalHighlights totalHighlights={totalHighlights} data-testid={`advisor-row-total-highlights-${data.id}`} />
        )}
      </x.div>
    </x.div>
  );
};
