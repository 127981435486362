import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Button, Typography } from "@alphasights/alphadesign-components";
import { RequestCancel } from "@alphasights/alphadesign-icons";
import { cancelContent, cancelRequestContent } from "content/AlphaNow";

const DataTestIds = {
  cancelExistingRequest: "cancel-existing-expert-request-button",
  cancelRequest: "cancel-request-expert-button",
};

const CancelButton = ({ isExistingExpertRequest, handleCancelRequest }: any) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return (
    <Button
      data-testid={isExistingExpertRequest ? DataTestIds.cancelExistingRequest : DataTestIds.cancelRequest}
      size="medium"
      variant="ghost"
      onClick={handleCancelRequest}
      startIcon={isExistingExpertRequest ? <RequestCancel /> : <></>}
      mr={inner.base04}
    >
      <x.div display="flex" spaceX={inner.base}>
        <Typography variant="body-em"> {isExistingExpertRequest ? cancelRequestContent : cancelContent}</Typography>
      </x.div>
    </Button>
  );
};

export { CancelButton as default, DataTestIds };
