import React, { FC } from "react";
import { Icon, TextField, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Search } from "@alphasights/alphadesign-icons";
import { useAdvisorsPageStyles } from "views/AdvisorsPage/AdvisorsPage.styles";
import { PastProjectsBanner } from "components/PastProjectsBanner";
import { AdvisorsHeaderNewUx, AdvisorsHeaderNewUxProps } from "components/AdvisorsHeaderNewUx";
import { HitOrigin } from "@alphasights/portal-api-client";
import { x } from "@xstyled/styled-components";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";

type AdvisorsPageHeaderProps = {
  filterComponent: (isCompact: boolean) => React.ReactNode;
  loading: boolean;
  onSearch: (searchQuery?: string) => void;
  onSearchQueryChange: (value: string) => void;
  pastProjectsEnabled: boolean;
  project: Project;
  searchQuery: string;
  showing: number;
  currentView: string;
} & Omit<AdvisorsHeaderNewUxProps, "searchComponent" | "filterComponent" | "counter">;

export const AdvisorsPageHeader: FC<AdvisorsPageHeaderProps> = ({
  filterComponent,
  loading,
  onSearch,
  onSearchQueryChange,
  pastProjectsEnabled = false,
  project,
  searchQuery,
  showing,
  currentView,
  ...props
}) => {
  const styles = useAdvisorsPageStyles();
  const {
    color: { text },
  } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(new FormData(event.target as HTMLFormElement).get("query") as string);
  };
  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onSearchQueryChange("");
    onSearch("");
  };
  const origin = currentView === HitOrigin.listView ? HitOrigin.listView : HitOrigin.tableView;

  const searchForm = newNavigationEnabled ? (
    <CollapsibleSearch
      onSubmit={onSubmit}
      onValueChange={onSearchQueryChange}
      onClear={onClear}
      value={searchQuery}
      id="advisor-page-search-input"
      name="query"
      placeholder="Search in experts"
    />
  ) : (
    <x.div w="100%">
      <x.form data-testid="search-form" onSubmit={onSubmit} flexGrow={1}>
        <TextField
          id="advisor-page-search-input"
          className="aui-w-full"
          name="query"
          onChange={(e) => onSearchQueryChange(e.target.value)}
          placeholder="Search"
          value={searchQuery}
          autoComplete="off"
          h="44px !important"
          startAdornment={
            <Icon size="small">
              <Search />
            </Icon>
          }
        />
      </x.form>
    </x.div>
  );

  const counter = (
    <Typography color={text.secondary} whiteSpace={"nowrap"} ml="8px" data-testid="summary-bar">
      {showing} {showing === 1 ? "result" : "results"}
    </Typography>
  );

  const Container = newNavigationEnabled ? HeaderPortal : React.Fragment;

  return (
    <Container>
      <>
        {pastProjectsEnabled && project?.inactive && (
          <x.div {...styles.header}>
            <PastProjectsBanner
              className={`aui-mt-${newNavigationEnabled ? "4" : "5"} aui-mx-4 aui-flex-grow`}
              project={project}
              origin={origin}
            />
          </x.div>
        )}
        <AdvisorsHeaderNewUx
          {...props}
          searchComponent={searchForm}
          filterComponent={filterComponent}
          counter={counter}
          currentView={currentView}
        />
      </>
    </Container>
  );
};
