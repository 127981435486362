import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useRecommendedContentTableStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const contentTypeColumnDescription = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    p: inner.base02,
  };

  return {
    contentTypeColumnDescription,
  };
};
