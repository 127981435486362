import { Icon, Tooltip } from "@alphasights/alphadesign-components";
import { Show } from "@alphasights/alphadesign-icons";
import React from "react";
import { IconWrapper } from "./CustomBadge.styled";

export const CustomBadge = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <Tooltip title={tooltipText} position="right" size="small">
      <IconWrapper>
        <Icon size="medium" color="inverse">
          <Show />
        </Icon>
      </IconWrapper>
    </Tooltip>
  );
};
