import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCompanyPrimerV3Store } from "../../../store/store";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import { PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { NotableEvent as NotableEventType } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/overview";

import { x } from "@xstyled/styled-components";
import styles from "./styles/notableEvents.module.css";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const NotableEvents = () => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const { notableEvents } = useCompanyPrimerV3Store(({ overview }) => overview);

  const getTimeRange = (event: NotableEventType) => {
    const isYearRange = !!event.maxYear;

    if (isYearRange) {
      return `${event.minYear} – ${event.maxYear}`;
    }

    if (event.quarter) {
      return `${event.minYear}, Q${event.quarter}`;
    }

    return `${event.minYear}`;
  };

  return (
    <x.div className={styles.timeline} px={inner.base10}>
      <PrimerFieldTitle
        text="Notable events"
        variant="body-large-em"
        xtraProps={{
          pb: inner.base04,
        }}
      />
      <div className={styles.timelineWrapper}>
        <div className={styles.timelineBar}></div>
        {notableEvents.map((event, index) => {
          return (
            <div className={styles.timelineEvent} key={index}>
              <x.div textAlign="right">
                <SensitiveDataContainer isSensitive={event.title.isSensitive}>
                  <Typography className={styles.timelineYear} variant="body-small-em" color={text.secondary}>
                    {getTimeRange(event)}
                  </Typography>
                </SensitiveDataContainer>
              </x.div>
              <x.div position="relative">
                <div className={styles.timelineDot}></div>
                <div className={styles.timelineArrowBody}></div>
                <div className={styles.timelineArrow}></div>
              </x.div>
              <div className={styles.timelineContent}>
                <SensitiveDataContainer isSensitive={event.description.isSensitive}>
                  <Typography variant="body-small-em" color={text.strong._}>
                    <CitationTextWrapper value={event.title} />
                  </Typography>
                </SensitiveDataContainer>
                <CitationTextWrapper value={event.description} />
              </div>
            </div>
          );
        })}
      </div>
    </x.div>
  );
};

export default NotableEvents;
