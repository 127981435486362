import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { contentService } from "services/content";
import {
  LandingPageContainer,
  StyledLoadingSpinner,
} from "pages/AlphaNowPage/components/LandingPage/LandingPage.styled";
import LandingPageContent from "pages/AlphaNowPage/components/LandingPage/LandingPageContent";
import LandingPageSidebar from "pages/AlphaNowPage/components/LandingPage/LandPageSidebar";
import { logTimeSpent } from "pages/AlphaNowPage/utils/logHit";
import {
  SORT_KEYS,
  SORT_OPTIONS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/SortByFilter";

const DATA_TEST_IDS = {
  landingPage: "landing-page",
  landingPageLoadingSpinner: "landing-page-loading-spinner",
};

const LandingPage = () => {
  const { logHit } = useTrackUserAction();
  const currentUser = useCurrentUser();
  const userId = currentUser?.id;
  const sortOption = SORT_OPTIONS[SORT_KEYS.callDateDesc];

  const { data: purchasedContentData, isLoading: isPurchasedContentLoading } = useQuery(["PurchasedContent"], () =>
    contentService.fetchContent({
      filters: {
        purchased_by_me: true,
        purchased_by_colleagues: true,
        sort_by: sortOption.sortBy,
        sort_by_direction: sortOption.direction,
      },
    })
  );

  const { data: bookmarkedData, isLoading: isBookmarkedContentLoading } = useQuery(["BookmarkedContent"], () =>
    contentService.fetchContent({
      filters: {
        search_in_bookmarks: true,
        sort_by: sortOption.sortBy,
        sort_by_direction: sortOption.direction,
      },
    })
  );

  const { data: contentRecommendationsData, isLoading: isContentRecommendationsLoading } = useQuery(
    ["fetchContentRecommendations"],
    () => contentService.fetchContentRecommendations()
  );
  const { data: inTheNewsContentData, isLoading: isInTheNewsContentLoading } = useQuery(
    ["fetchRecommendedNewsContent"],
    () => contentService.fetchRecommendedNewsContent()
  );

  const { data: investorLedContentData, isLoading: isInvestorLedContentLoading } = useQuery(
    ["fetchRecommendedInvestorLedContent"],
    () => contentService.fetchRecommendedInvestorLedContent()
  );

  const landingPageContent = {
    purchasedContent: purchasedContentData?.results ?? [],
    bookmarkedContent: bookmarkedData?.results ?? [],
    inTheNewsContent: inTheNewsContentData?.recommendations ?? [],
    recommendedInvestorLedContent: investorLedContentData?.recommendations ?? [],
    contentRecommendations: contentRecommendationsData?.recommendations ?? [],
  };

  const isLoading =
    isPurchasedContentLoading ||
    isBookmarkedContentLoading ||
    isInTheNewsContentLoading ||
    isInvestorLedContentLoading ||
    isContentRecommendationsLoading;

  useEffect(() => {
    const startTime = Date.now();
    return () => {
      logTimeSpent(
        startTime,
        { userId: String(userId) },
        logHit,
        HitAction.landingPageTimeSpent,
        HitOrigin.landingPage
      );
    };
  }, [userId, logHit]);

  return (
    <LandingPageContainer data-testid={DATA_TEST_IDS.landingPage}>
      {isLoading ? (
        <StyledLoadingSpinner size="sm" />
      ) : (
        <>
          <LandingPageContent data={landingPageContent} />
          <LandingPageSidebar />
        </>
      )}
    </LandingPageContainer>
  );
};

export { LandingPage as default, DATA_TEST_IDS };
