import React, { useState, useRef, useEffect, useCallback } from "react";
import { RecordingPlayer } from "../RecordingPlayer";
import { toMinutesAndSeconds } from "../../helpers/displayHelpers";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { debounce } from "lodash";
import { useAudioPlayer, useAutoScroll } from "hooks/useAudioPlayer";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

const TextCue = React.forwardRef(({ cue, highlighted, onClick, obfuscated, ...props }, ref) =>
  highlighted ? (
    <Typography
      ref={ref}
      component="mark"
      className={`${obfuscated ? "" : "hover:aui-bg-highlight-1"}`}
      onClick={onClick}
      {...props}
    >
      {cue.text}
    </Typography>
  ) : (
    <Typography
      component="span"
      className={`${obfuscated ? "" : "hover:aui-bg-highlight-1"}`}
      onClick={onClick}
      {...props}
    >
      {cue.text}
    </Typography>
  )
);

export const TranscriptReadAlong = ({
  audioUrl,
  vttUrl,
  compactPlayer,
  audioError,
  obfuscated = false,
  interactionId,
  projectToken,
  recording,
  origin,
  language,
}) => {
  const {
    color: { text },
  } = useThemeTokens();
  const [showPlayerScrolled, setShowPlayerScrolled] = useState(false);

  const debounceHidePlayer = useCallback(
    debounce(() => {
      setShowPlayerScrolled(false);
    }, 3000),
    []
  );

  const showPlayerMomentanelly = () => {
    if (!showPlayerScrolled) {
      setShowPlayerScrolled(true);
    }
    debounceHidePlayer();
  };

  const audioPlayer = useAudioPlayer({
    audioUrl,
    vttUrl,
    disabled: obfuscated,
  });

  const {
    isError,
    isLoading,
    transcriptProps,
    controlsProps,
    progressBarProps,
    playbackProps,
    renderElements,
  } = useAutoScroll({
    audioPlayer,
    onNonAutomaticScrollEnded: showPlayerMomentanelly,
  });

  const playerRef = useRef();
  const playerWrapperRef = useRef();
  const cuesRef = useRef({});

  const { sectionsWrapperStyles, transformedPlayer } = useTranscriptReadAlongStyles(showPlayerScrolled);

  useEffect(() => {
    const target = playerWrapperRef.current;
    const handleMouseEnter = () => {
      debounceHidePlayer.cancel();
    };
    const handleMouseLeave = () => {
      debounceHidePlayer();
    };

    if (target) {
      target.addEventListener("mouseenter", handleMouseEnter);
      playerWrapperRef.current.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (target) {
        target.removeEventListener("mouseenter", handleMouseEnter);
        target.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [playerWrapperRef, debounceHidePlayer]);

  const { logHit } = useTrackUserAction();
  const cueClicked = (cue) => {
    logHit({
      origin: "deliverables-view",
      action: "TRANSCRIPT_READALONG_CLICK",
      advisorshipId: interactionId,
      projectToken: projectToken,
    });
    transcriptProps.movePlayerTo(cue.startTime);
    if (controlsProps.playing) {
      showPlayerMomentanelly();
    }
  };

  return (
    <>
      <x.div ref={playerWrapperRef} {...transformedPlayer}>
        {renderElements}
        {recording.visibleToClient && (
          <RecordingPlayer
            controlsProps={controlsProps}
            progressBarProps={progressBarProps}
            playbackProps={playbackProps}
            isError={isError}
            isLoading={isLoading}
            ref={playerRef}
            src={audioUrl}
            vttUrl={vttUrl}
            compact={compactPlayer}
            audioError={audioError}
            disabled={obfuscated}
            interactionId={interactionId}
            projectToken={projectToken}
            recordingId={recording.id}
            origin={origin}
            language={language}
          />
        )}
      </x.div>
      <x.div className={"aui-text-grey-5 aui-h-2/3 aui-flex aui-flex-col aui-space-y-3"} {...sectionsWrapperStyles}>
        {transcriptProps.transcriptSections && (
          <>
            {transcriptProps.transcriptSections.map((section, index) => {
              return (
                <div
                  style={{
                    filter: obfuscated ? "blur(2.5px)" : "none",
                    userSelect: obfuscated ? "none" : "auto",
                  }}
                  className="aui-block md:aui-flex"
                  id={`transcript-section-${index}`}
                >
                  {section.speakerChange ? (
                    <div className="aui-w-full md:aui-w-48 aui-flex aui-text-grey-3">
                      <Typography
                        variant="body-em"
                        component="span"
                        color={text.secondary}
                        data-testid="transcript-readalong-speaker"
                        className="aui-w-1/2"
                      >
                        {section.speakerChange?.speaker}
                      </Typography>
                      <Typography
                        component="span"
                        color={text.secondary}
                        data-testid="transcript-readalong-timestamp"
                        className="aui-w-1/2"
                        textAlign="center"
                      >
                        {toMinutesAndSeconds(section.speakerChange?.timestamp)}
                      </Typography>
                    </div>
                  ) : (
                    <Typography
                      variant="body-em"
                      component="span"
                      color={text.secondary}
                      data-testid="transcript-readalong-speaker"
                      className="aui-w-1/2"
                    >
                      Empty transcript file
                    </Typography>
                  )}

                  <span data-testid="transcript-readalong-text" className="md:aui-w-9/12">
                    {section.cues.map((cue) => (
                      <TextCue
                        ref={(element) => (cuesRef.current[cue.id] = element)}
                        key={cue.id}
                        cue={cue}
                        obfuscated={obfuscated}
                        onClick={() => {
                          cueClicked(cue);
                        }}
                        highlighted={
                          transcriptProps.activeCues && transcriptProps.activeCues.some((c) => c.id === cue.id)
                        }
                        color={text.strong._}
                        data-transcript-id={cue.id}
                      />
                    ))}
                  </span>
                </div>
              );
            })}
          </>
        )}
      </x.div>
    </>
  );
};

export const useTranscriptReadAlongStyles = (showPlayer) => {
  const {
    color: { background, border },
    spacing: { inner },
  } = useThemeTokens();

  const sectionsWrapperStyles = {
    paddingTop: inner.base06,
    marginTop: inner.base06,
    borderTopWidth: 1,
    borderTopColor: border.divider,
  };

  const transformedPlayer = {
    transition: "top 0.3s",
    background: background.surface.page.default,
    position: "sticky",
    top: "-132px",
    ...(showPlayer && {
      top: "-24px",
      p: inner.base06,
      m: `-${inner.base06}`,
    }),
  };

  return {
    sectionsWrapperStyles,
    transformedPlayer,
  };
};
