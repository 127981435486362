import React from "react";
import { Revenue } from "../../../types/overview";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import { x } from "@xstyled/styled-components";
import { Citation } from "components/CitationContext/Citation";
import {
  currencyRendererWithSymbol,
  rangeOf,
  renderRange,
  PercentRenderer,
} from "components/CitationContext/PropertyRenderers";
import DirectionalArrow from "components/DirectionalArrow";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const RevenueDetails = ({ revenue, currency }: { revenue: Revenue; currency: string }) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();

  const { min, max, expertRevenueYear, expertRevenueQuarter, expertRevenueGrowthYear, growthMin, growthMax } = revenue;

  const hasGrowth = Boolean(growthMin?.value && growthMax?.value && expertRevenueGrowthYear?.value);

  const growthSign = (() => {
    const growthDifference = (growthMax?.value ?? 0) - (growthMin?.value ?? 0);

    const growthMidpoint = growthDifference / 2 + (growthMin?.value ?? 0);

    return Math.sign(growthMidpoint);
  })();

  const currencySymbol = currency ? CURRENCY_SYMBOL[currency] : "";

  const CurrencyRenderer = currencyRendererWithSymbol(currencySymbol);

  const growthIcon = growthSign !== 0 && (
    <x.span h={inner.base05} mr={inner.base} w={inner.base05}>
      <DirectionalArrow display="inline" isUp={growthSign === 1} />
    </x.span>
  );

  const desc = expertRevenueQuarter?.value
    ? `Q${expertRevenueQuarter.value}, ${expertRevenueYear.value}`
    : expertRevenueYear.value;
  return (
    <SensitiveDataContainer isSensitive={expertRevenueYear.isSensitive}>
      <Typography component="div" variant="body" color={color.text.strong._}>
        <x.div display={{ xs: "flex", sm: "inline-flex" }} flexDirection={{ xs: "column", sm: "row" }} flexWrap="wrap">
          <span>
            <Citation
              value={rangeOf(min, max)}
              renderer={renderRange({
                renderer: CurrencyRenderer,
                desc,
              })}
            />
          </span>
          {hasGrowth && (
            <x.span
              display="flex"
              flexDirection="column"
              color={
                {
                  [-1]: color.text.danger,
                  0: color.text.secondary,
                  1: color.text.success,
                }[growthSign]
              }
              marginLeft={inner.base02}
            >
              <span>
                <Citation
                  value={rangeOf(growthMin, growthMax)}
                  renderer={renderRange({
                    before: growthIcon,
                    renderer: PercentRenderer,
                    after: " p.a.",
                    shouldRenderFullRangeWhenNoChange: false,
                    desc: expertRevenueGrowthYear.value ?? undefined,
                  })}
                />
              </span>
            </x.span>
          )}
        </x.div>
      </Typography>
    </SensitiveDataContainer>
  );
};

export default RevenueDetails;
