import React, { ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import { Pill, PillVariants, useThemeTokens } from "@alphasights/alphadesign-components";
import GrowthSymbol, {
  CompetitorGrowth,
  GrowthSymbolIconPosition,
} from "../../CompanyPrimer/sections/competitor-dynamics/Growth";
import ConditionalTooltip from "../conditional-tooltip/ConditionalTooltip";

const TrendPill = ({
  trend,
  label,
  fillBackgroundColor = false,
  hasBorder,
  children,
  tooltip,
  maxChars,
}: {
  trend: CompetitorGrowth;
  label?: string;
  fillBackgroundColor?: boolean;
  hasBorder?: boolean;
  children?: ReactNode;
  tooltip?: string | null;
  maxChars?: number;
}) => {
  const {
    color: { text, base },
    spacing: { inner },
  } = useThemeTokens();

  const getTrendPillVariant = () => {
    let color = text.strong._;
    let variant = "light";

    if (!fillBackgroundColor) {
      return {
        color,
        variant,
      };
    }

    switch (trend) {
      case CompetitorGrowth.Increasing:
        color = text.success;
        variant = "green";
        break;
      case CompetitorGrowth.Decreasing:
        color = text.danger;
        variant = "red";
        break;
      case CompetitorGrowth.Constant:
        color = text.strong._;
        variant = "light";
        break;
      default:
        break;
    }

    return {
      color,
      variant,
    };
  };

  const { color: trendColor, variant: pillVariant } = getTrendPillVariant();

  return (
    <x.div display="flex" gap={inner.base03} alignItems="center">
      <Pill
        border={hasBorder && `solid 1px ${base.grey["300"]}`}
        whiteSpace="nowrap"
        size="small"
        variant={pillVariant as PillVariants}
        isInteractive={false}
      >
        <x.div alignItems="center" display="flex">
          <ConditionalTooltip tooltip={tooltip ?? ""}>{children ? children : null}</ConditionalTooltip>

          <GrowthSymbol
            iconPosition={GrowthSymbolIconPosition.right}
            {...{ growth: trend, color: trendColor, size: "small", maxChars: maxChars, customLabel: label }}
          />
        </x.div>
      </Pill>
    </x.div>
  );
};

export default TrendPill;
