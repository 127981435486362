import { isThisWeek, isToday, isYesterday, parseISO } from "date-fns";

export interface CustomerKnowledgeFilters {
  role?: string[];
  status?: string[];
  groups?: string[];
  date_added?: string[];
  keywords?: string[];
  vendors?: { [key: string]: string[] };
}

const interactionHasStatus = (interaction: Interaction, selected: string[]) => {
  return selected.includes(interaction.state);
};

const interactionHasRole = (interaction: Interaction, selected: string[]) => {
  return interaction.customerKnowledges
    ?.flatMap((ck: CustomerKnowledge) => ck.role)
    .some((role) => selected.includes(role as string));
};

const interactionHasGroup = (interaction: Interaction, selected: string[]) =>
  recursiveGroupSearch(interaction.group, selected);

const recursiveGroupSearch = (group: Group, selected: string[]): boolean => {
  if (!group) return false;
  if (selected.includes(group.id)) return true;
  return recursiveGroupSearch(group.parent, selected);
};

const interactionHasDateAdded = (interaction: Interaction, selected: string[]) => {
  const proposedAt = interaction.proposedAt ? parseISO(interaction.proposedAt) : undefined;

  const newlyAdded = selected.includes("New") ? interaction.newlyAdded : false;
  const today = selected.includes("Today") && proposedAt ? isToday(proposedAt) : false;
  const yesterday = selected.includes("Yesterday") && proposedAt ? isYesterday(proposedAt) : false;
  const thisWeek = selected.includes("This Week") && proposedAt ? isThisWeek(proposedAt) : false;

  return newlyAdded || today || yesterday || thisWeek;
};

const interactionHasVendorExperience = (interaction: Interaction, selected: { [key: string]: string[] }) => {
  if (Object.values(selected).every((i) => i.length === 0)) return true;

  for (const [vendorId, experiences] of Object.entries(selected)) {
    const some = interaction.customerKnowledges.some((ck) => {
      return ck.vendor.id === vendorId && experiences.includes(ck.experience);
    });

    if (some) return true;
  }

  return false;
};

interface KeywordSearchableField {
  fieldName: string;
  esAlias: string[];
}
const keywordSearchFields: KeywordSearchableField[] = [
  { fieldName: "advisorName", esAlias: ["advisor.name", "advisor.name.concat"] },
  { fieldName: "geographies", esAlias: ["geographies.name", "geographies.name.concat"] },
  { fieldName: "relevanceStatement", esAlias: ["relevanceStatement", "relevanceStatement.concat"] },
  { fieldName: "invoiceNumber", esAlias: ["invoiceNumber"] },
  { fieldName: "surveyResponse.token", esAlias: [] },
  { fieldName: "role", esAlias: ["relevantPrimaryJob.jobTitle", "relevantPrimaryJob.jobTitle.concat"] },
  { fieldName: "advisorCompany", esAlias: ["relevantPrimaryJob.companyName", "relevantPrimaryJob.companyName.concat"] },
  { fieldName: "secondaryTitle", esAlias: ["relevantSecondaryJob.jobTitle", "relevantSecondaryJob.jobTitle.concat"] },
  {
    fieldName: "secondaryCompany",
    esAlias: ["relevantSecondaryJob.companyName", "relevantSecondaryJob.companyName.concat"],
  },
];

const getString = (obj: any, fieldName: string): string => {
  const fieldValue = obj[fieldName];
  return (
    (fieldName.includes(".") && fieldValue ? getString(fieldValue, fieldName.split(".")[1]) : fieldValue?.toString()) ||
    ""
  );
};

const interactionHasKeyword = (interaction: Interaction, keywords: string[]) => {
  const highlights = keywordSearchFields.reduce(
    (agg: SearchHighlight[], field: KeywordSearchableField): SearchHighlight[] => {
      const hasMatch = keywords.find((word: string) =>
        getString(interaction, field.fieldName)?.toLowerCase()?.includes(word.toLowerCase())
      );
      if (!hasMatch) return agg;

      const highlighted = keywords.reduce((reduced: string, word: string) => {
        const wordRegex = new RegExp(`(${word})`, "ig");
        return `${getString(interaction, field.fieldName).replace(wordRegex, '<em class="highlighted-text">$1</em>')}`;
      }, "");
      const matches = field.esAlias.map(
        (fieldName: string): SearchHighlight => ({
          fieldName,
          terms: [highlighted],
        })
      );
      return [...agg, ...matches];
    },
    []
  );

  interaction.highlights = highlights;
  return highlights.length > 0;
};

export const filterFunctions: {
  [k in keyof CustomerKnowledgeFilters]: (interaction: Interaction, value: any) => boolean;
} = {
  role: interactionHasRole,
  status: interactionHasStatus,
  groups: interactionHasGroup,
  date_added: interactionHasDateAdded,
  vendors: interactionHasVendorExperience,
  keywords: interactionHasKeyword,
};

export const filterInitialValues: any = {
  groups: (initialValue: string[], possibleValues: { id: string; children: { id: string }[] }[]) => {
    const flattenIds = possibleValues.reduce((acc: string[], group) => {
      return [...acc, group.id, ...group.children.map((g) => g.id)];
    }, []);

    return initialValue.filter((v) => flattenIds.includes(v));
  },
};
