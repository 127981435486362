import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMobileQuestionContextStyles = () => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return {
    advisorCardWrapper: {
      borderColor: color.border.neutral.default,
      borderBottomWidth: "1px",
    },
    messageWrapper: {
      display: "flex",
      flexDirection: "column",
      bg: color.background.surface.page.default,
      padding: inner.base04,
      gap: inner.base03,
    },
    companyAndRole: {
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      lineClamp: "1" as any,
      WebkitLineClamp: 1,
      boxOrient: "vertical" as any,
      WebkitBoxOrient: "vertical" as any,
    },
    questionWrapper: {
      p: inner.base04,
      display: "flex",
      flexDirection: "column",
      gap: inner.base03,
      borderBottomWidth: "1px",
      borderColor: color.border.neutral.default,
    },
    headerOnlyMessageWrapper: {
      p: inner.base04,
    },
    replyWrapper: {
      borderColor: color.border.neutral.default,
      borderTopWidth: "1px",
    },
  };
};
