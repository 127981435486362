import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PrimersContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    display: "flex",
    gap: inner.base04,
  };
});
