import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaCircle } from "@alphasights/alphadesign-icons";
import { Badge } from "components/Badge";

export const ExpertChargesList = ({ experts }) => {
  const { spacing, color } = useThemeTokens();

  if (experts == null || experts.length === 0) return null;

  return (
    <x.div gap={spacing.inner.base02}>
      {experts.map((expert) => (
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          pt={spacing.inner.base}
          gap={spacing.inner.base02}
        >
          <Typography variant="body" color={color.text.strong._}>
            {`${expert.credits}  •  ${expert.name}`}
          </Typography>
          {expert.alphaCircleMultiplier > 1 && (
            <Badge
              tooltipText={`Available at a rate of ${expert.alphaCircleMultiplier} credits per hour`}
              value={expert.alphaCircleMultiplier}
              icon={<AlphaCircle />}
              data-testid="alphacircle-badge"
            />
          )}
        </x.div>
      ))}
    </x.div>
  );
};
