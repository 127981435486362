import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PROPERTIES, PROPERTY_ORDER, LABEL_TYPES } from "./constants";
import { Citation } from "components/CitationContext/Citation";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import CitedMarkdown from "components/CitedMarkdown";

export const DataTestIds = {
  revenue: "company-primers-revenue",
  upArrow: "company-primers-up-arrow",
  downArrow: "company-primers-down-arrow",
};

const OverviewLabel: FC<{ label: string }> = ({ label }) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <Typography
      component="div"
      color={color.text.secondary}
      flex={{ sm: "0 0 90px", md: "0 0 150px" }}
      mr={inner.base06}
      variant={isMobile ? "body-large" : "body"}
    >
      {LABEL_TYPES[label]}
    </Typography>
  );
};

interface OverviewRowProps extends Pick<React.HTMLAttributes<HTMLDivElement>, "children"> {
  index: number;
  overviewProperty: string;
  isSensitive: boolean;
}

const OverviewRow: FC<OverviewRowProps> = ({ index, overviewProperty, children, isSensitive }) => {
  const { spacing, color, shape } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      w={{
        md: "100%",
      }}
      pt={{ xs: spacing.inner.base06, sm: 0 }}
      pb={{ xs: spacing.inner.base05, sm: spacing.inner.base04 }}
      borderTop={{
        xs: index === 0 ? 0 : `${shape.border.width.sm} solid ${color.border.divider}`,
        sm: 0,
      }}
      lineHeight={spacing.inner.base06}
    >
      <OverviewLabel label={overviewProperty} />
      <SensitiveDataContainer isSensitive={isSensitive}>
        <Typography component="div" color={color.text.strong._} variant={isMobile ? "body-large" : "body"}>
          {children}
        </Typography>
      </SensitiveDataContainer>
    </x.div>
  );
};

export type CompanyPrimerOverview = {
  description: CitableValue<string>;
  monetization: CitableValue<string>;
  goToMarket: CitableValue<string>;
};

type ValueOf<T> = T[keyof T];

interface OverviewSectionProps {
  companyPrimerOverview: CompanyPrimerOverview;
}

const CustomerDynamicsMarkdownSection: FC<OverviewSectionProps> = ({ companyPrimerOverview: overview }) => {
  const generateDetailByKey = (key: keyof CompanyPrimerOverview) => {
    const contentFactoriesByProperty = {
      [PROPERTIES.description]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [PROPERTIES.monetization]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [PROPERTIES.goToMarket]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
    };

    const contentFactory = contentFactoriesByProperty[key as any];

    if (contentFactory) return contentFactory();

    return <Citation value={overview[key] as CitableValue<string>} />;
  };

  const order = PROPERTY_ORDER as (keyof CompanyPrimerOverview)[];

  return (
    <div>
      {overview &&
        order.map((key: keyof CompanyPrimerOverview, index: number) => {
          const content = generateDetailByKey(key);
          const isSensitive = hasSensitiveData(overview[key]);

          if (!content) return null;

          return (
            <OverviewRow {...{ key, index }} overviewProperty={key} isSensitive={isSensitive}>
              {content}
            </OverviewRow>
          );
        })}
    </div>
  );
};

export default CustomerDynamicsMarkdownSection;
