import React, { FC } from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens, Accordion, AccordionItem } from "@alphasights/alphadesign-components";
import ReactHtmlParser from "react-html-parser";
import { OverviewHeader } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import { OverviewDataType } from "./types";

const DataTestIds = {
  alphaNowCompanyOverview: "company-overview",
};

const punctuationRegex = /(?<=[.!?])\s*/;

interface AlphaNowCompanyOverviewProps {
  data: OverviewDataType;
}

const AlphaNowCompanyOverview: FC<AlphaNowCompanyOverviewProps> = ({ data }) => {
  const {
    spacing: { layout, inner },
  } = useThemeTokens();

  const { description, fte, markets, ownership } = data;

  const firstSentenceCompanyDescription = description && ReactHtmlParser(description.split(punctuationRegex)[0]);

  const accessibleCompanyOverviewData = [
    { displayName: "Markets", value: markets },
    { displayName: "FTEs", value: fte },
    { displayName: "Ownership", value: ownership },
  ].filter(({ value }) => value != null);

  const isOverviewDisabled = accessibleCompanyOverviewData.length === 0 && !description;

  return (
    <>
      <Accordion mx={layout.base04} data-testid={DataTestIds.alphaNowCompanyOverview}>
        <AccordionItem
          titleContent={<OverviewHeader data={data} isDisabled={isOverviewDisabled} />}
          open
          disabled={isOverviewDisabled}
          borderTop={0}
        >
          <div>
            <>{firstSentenceCompanyDescription}</>
            <x.div display="flex" flexDirection="row" pt={inner.base06} gap={layout.base05}>
              {accessibleCompanyOverviewData.map(({ displayName, value }, index) => (
                <x.div display="flex" key={index}>
                  <Typography component="span" variant="body-em" pr={inner.base03}>
                    {displayName}:
                  </Typography>
                  <Typography component="span">{value}</Typography>
                </x.div>
              ))}
            </x.div>
          </div>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export { AlphaNowCompanyOverview as default, DataTestIds, punctuationRegex };
