import React from "react";
import { x } from "@xstyled/styled-components";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { useAlphaGPTLandingPageStyles } from "./AlphaGPTLandingPage.styles";
import { AlphaGPTLibrarySelector } from "../AlphaGPTLibrarySelector/AlphaGPTLibrarySelector";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { AlphaGptLibrary } from "models/AlphaGpt";

export const AlphaGPTLandingPage = () => {
  const { searchesContainer, librarySelectorContainer, wrapper } = useAlphaGPTLandingPageStyles();
  const { selectedLibraries, libraryOptions, hideSearchSuggestions } = useAlphaGPTContext();

  const searchSuggestions = [
    {
      query: "Can you give me a detailed overview of [Company / Industry]?",
      libraries: [AlphaGptLibrary.AlphaSights, AlphaGptLibrary.Private],
    },
    {
      query: "Who are [Company’s] main competitors and how are they differentiated?",
      libraries: [AlphaGptLibrary.AlphaSights, AlphaGptLibrary.Private],
    },
    {
      query: "What are the key risks and challenges in [Industry]?",
      libraries: [AlphaGptLibrary.AlphaSights, AlphaGptLibrary.Private],
    },

    {
      query: "Show all relevant content you have on [Company / Industry].",
      libraries: [AlphaGptLibrary.AlphaSights],
    },
    {
      query: "List experts that can speak to [Company / Industry].",
      libraries: [AlphaGptLibrary.AlphaSights],
    },

    {
      query: "Source quotes on [a specific topic].",
      libraries: [AlphaGptLibrary.AlphaSights, AlphaGptLibrary.Private],
    },
  ];

  const label = libraryOptions.find((library) => selectedLibraries === library.libraries)?.secondaryText;

  return (
    <>
      <x.div {...librarySelectorContainer}>
        <AlphaGPTLibrarySelector />
      </x.div>
      <x.div {...wrapper}>
        <x.div {...searchesContainer}>
          <Typography variant="body-large" color="strong">
            {label}
          </Typography>
          {!hideSearchSuggestions && (
            <SearchSuggestions searchSuggestions={searchSuggestions} selectedLibraries={selectedLibraries} />
          )}
        </x.div>
      </x.div>
    </>
  );
};

const SearchSuggestions = ({
  searchSuggestions,
  selectedLibraries,
}: {
  searchSuggestions: SearchSuggestion[];
  selectedLibraries?: AlphaGptLibrary[];
}) => {
  const { suggestedSearches } = useAlphaGPTLandingPageStyles();

  return (
    <x.div {...suggestedSearches} data-testid="alphagpt-landing-page">
      {searchSuggestions
        .filter((searchSuggestion) =>
          selectedLibraries?.some((library) => searchSuggestion.libraries.includes(library))
        )
        .map((searchSuggestion: SearchSuggestion, index: number) => (
          <SearchSuggestionButton key={`search-suggestion-${index}`} searchSuggestion={searchSuggestion} />
        ))}
    </x.div>
  );
};

const SearchSuggestionButton = ({ searchSuggestion }: { searchSuggestion: SearchSuggestion }) => {
  const { searchSuggestionButton, searchSuggestionTextPlaceholder } = useAlphaGPTLandingPageStyles();
  const { setQuery } = useAlphaGPTContext();

  const [query, placeholder, queryEnd] = searchSuggestion.query.split(/\[|\]/);

  return (
    <Button onClick={() => setQuery(searchSuggestion.query)} variant="outline" size="small" {...searchSuggestionButton}>
      {query}
      <Typography variant="body-small" component="span" {...searchSuggestionTextPlaceholder}>
        {placeholder}
      </Typography>
      {queryEnd}
    </Button>
  );
};

interface SearchSuggestion {
  query: string;
  libraries: AlphaGptLibrary[];
}
