import React from "react";
import { x } from "@xstyled/styled-components";
import { Tile, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Citation } from "components/CitationContext/Citation";
import GrowthSymbol from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";
import { useMarketPrimersStore } from "pages/AlphaNowPage/primers/MarketPrimer/state/store";
import { revenueCitation } from "pages/AlphaNowPage/primers/MarketPrimer/utils/utils";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

interface CompetitorDynamicsTabContentProps {
  currency: string;
  segment: CompetitorDynamicsEntry;
}

const CompetitorDynamicsTabContent = ({ currency, segment }: CompetitorDynamicsTabContentProps) => {
  const {
    spacing: { inner },
    typography,
    color,
    shape: { border },
  } = useThemeTokens();
  const { companies, companiesV2 } = useMarketPrimersStore();
  const { isAccessible } = usePrimersStore();
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);
  const {
    competitorComparison,
    overview,
    ownershipType,
    ownershipCompanyId,
    ownershipCompanyName,
    ownershipYear,
    ownershipQuarter,
    revenue,
    shareDynamics,
    trend,
    companyName,
  } = segment;

  const getOwnershipType = () => {
    if (
      ownershipType &&
      (ownershipCompanyId || ownershipCompanyName) &&
      ["VC backed", "PE backed"].includes(ownershipType)
    ) {
      return `${ownershipType} (${ownershipCompanyName}, Q${ownershipQuarter} ${ownershipYear})`;
    }

    return ownershipType;
  };

  if (!revenue || !trend) return null;

  return (
    <>
      <x.div py={inner.base04} display="flex" gap={inner.base06}>
        <SensitiveDataContainer isSensitive={!isAccessible}>
          <x.div
            w="90px"
            h="90px"
            padding="6px"
            border={`1px solid ${color.border.divider}`}
            borderRadius={border.radius.small}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
          >
            <Tile variant="image" size="x-large" image={getCompanyProperties(companyName).logoLink} customSize={80} />
          </x.div>
        </SensitiveDataContainer>
        <x.div display="flex" gap="12px" flexDirection="column">
          <x.div pb={inner.base}>
            <CitationWrapper label="revenue" value={revenue} labelWidth="100px">
              {revenue.max.value && revenue.min.value ? revenueCitation({ currency, value: revenue }) : "—"}
            </CitationWrapper>
          </x.div>
          <x.div display="flex" gap={inner.base03}>
            <PrimerFieldTitle text="ownership" w="100px" />
            <SensitiveDataContainer isSensitive={!isAccessible}>
              <Typography {...typography.body.regular}>{getOwnershipType()}</Typography>
            </SensitiveDataContainer>
          </x.div>
          <x.div display="flex" gap="12px" alignItems="center">
            <PrimerFieldTitle text="trend" w="95px" />
            <Citation
              value={trend}
              renderer={({ value: trend }) => <GrowthSymbol {...{ growth: trend, variant: "body", size: "small" }} />}
            />
          </x.div>
        </x.div>
      </x.div>
      <CitationWrapper value={overview} label="Overview" verticalLabel />
      <CitationWrapper value={competitorComparison} label="Competitor Comparison" verticalLabel />
      <CitationWrapper value={shareDynamics} label="Share Dynamics" verticalLabel />
    </>
  );
};

export default CompetitorDynamicsTabContent;
