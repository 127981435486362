import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const HeaderContainer = styled.div(({ mb }: { mb?: string }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    display: "flex",
    marginBottom: mb && mb.length > 0 ? mb : inner.base04,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  };
});
