import { x } from "@xstyled/styled-components";
import React, { FC } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Accordion, AccordionItem, Typography } from "@alphasights/alphadesign-components";

interface CompanyPrimerSectionCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  titleId?: string;
  info?: React.ReactElement;
}

const dataTestId = "primer-overview";

const CompanyPrimerSectionCard: FC<CompanyPrimerSectionCardProps> = React.forwardRef<
  HTMLDivElement,
  CompanyPrimerSectionCardProps
>(({ className, title, children, titleId, info, ...props }, ref) => {
  const {
    spacing: { inner },
    color: { border: borderColor, text },
    shape: { border },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isMobile) {
    return (
      <Accordion>
        <AccordionItem
          open
          titleContent={
            <x.div row mx={-2} alignItems="center" my={inner.base02} id={titleId}>
              <x.div display="inline-flex" col={8 / 10} px={2} alignItems="center" spaceX={inner.base02}>
                <Typography component="span" variant="h3" color={text.strong._}>
                  {title}
                </Typography>
                {info}
              </x.div>
            </x.div>
          }
          borderTop="0"
        >
          <CardContentWrapper>{children}</CardContentWrapper>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <x.div
      ref={ref}
      display="flex"
      flexDirection="column"
      padding={inner.base08}
      backgroundColor="light"
      borderStyle="solid"
      borderWidth={border.width.small}
      borderColor={borderColor.neutral.default}
      marginBottom={inner.base08}
      overflow="visible"
      data-testid={dataTestId}
      borderRadius={border.radius.medium}
      {...props}
    >
      <CompanyPrimerSectionHeader heading={title} />
      <CardContentWrapper>{children}</CardContentWrapper>
    </x.div>
  );
});

interface CompanyPrimerSectionHeaderProps {
  heading: string;
}

const CompanyPrimerSectionHeader: FC<CompanyPrimerSectionHeaderProps> = ({ heading }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return (
    <Typography color="strong" variant="body-large-em" mb={inner.base06}>
      {heading}
    </Typography>
  );
};

const CardContentWrapper = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  const { color } = useThemeTokens();
  return (
    <x.div
      display="flex"
      flexDirection="column"
      w={{ md: "100%" }}
      borderTop={{
        xs: { first: `1px solid ${color.border.divider}` },
        sm: { first: 0 },
      }}
    >
      {children}
    </x.div>
  );
};

export { CompanyPrimerSectionCard as default, dataTestId };
