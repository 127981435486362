import { cloneDeep } from "lodash";

const replaceMatchingElements = <T extends { id: CitableValue<string> }>(fullList: T[], replacingItems: T[]): T[] => {
  let replaceableList = [...fullList];

  replacingItems.forEach((replacingItem) => {
    replaceableList.splice(
      replaceableList.findIndex((replaceableItem) => replaceableItem.id.value === replacingItem.id.value),
      1,
      replacingItem
    );
  });

  return replaceableList;
};

export const mergeMatchingSingleSummary = (sum1: PrimerSummary, sum2: PrimerSummary) => {
  if (sum1.id.value === sum2.id.value) {
    return sum2;
  }
  return sum1;
};

export const mergeMatchingSummary = (
  currentSummary: PrimerSummary,
  kpcRank2: (RankedKPCApi | companyMetrics | NetPromoterScoreAPI)[]
): PrimerSummary => {
  const element = kpcRank2.find((element) => element.summary.id.value === currentSummary.id.value);

  return element ? element.summary : currentSummary;
};

export const mergeMatchingExpertResponses = (currentResponses: any[], kpcRanked2: any) => {
  const currentResponseIds = currentResponses.map((response) => response.id.value);

  const matchingElements = kpcRanked2.map((element: { expertsResponses: any }) =>
    element.expertsResponses.filter((response: { id: { value: string } }) =>
      currentResponseIds.includes(response.id.value)
    )
  );

  return replaceMatchingElements(currentResponses, matchingElements.flat());
};

const stripHtmlTags = (str: string) => {
  return str.replace(/<[^>]*>/g, "");
};

const mergeMatchingKpcName = (currentName: string, kpcRanked2: any[]) => {
  const strippedCurrentName = stripHtmlTags(currentName);

  const element = kpcRanked2.find((element) => {
    if (!element.name) return null;
    return stripHtmlTags(element.name) === strippedCurrentName;
  });

  return element ? element.name : currentName;
};

export const mergeRankedKpcs = (ranked1: RankedKPCApi[], ranked2: RankedKPCApi[]): RankedKPCApi[] => {
  return ranked1.map((kpc: any, index) => ({
    ...kpc,
    name: mergeMatchingKpcName(kpc.name, ranked2),
    summary: mergeMatchingSummary(kpc.summary, ranked2),
    expertsResponses: mergeMatchingExpertResponses(kpc.expertsResponses, ranked2),
  }));
};

export const mergeCompanyMetrics = (companyMetrics1: companyMetrics[], companyMetrics2: companyMetrics[]) => {
  return companyMetrics1.map((companyMetric, index) => ({
    ...companyMetric,
    summary: mergeMatchingSummary(companyMetric.summary, companyMetrics2),
    kpcs: companyMetric.kpcs.map((kpc: any, i: number) => ({
      ...kpc,
      name: mergeMatchingKpcName(kpc.name, companyMetrics2[i].kpcs),
      expertsResponses: mergeMatchingMetricsKpcResponses(kpc.expertsResponses, companyMetrics2),
    })),
  }));
};

const mergeMatchingMetricsKpcResponses = (currentResponses: any[], metrics: any[]) => {
  const currentResponseIds = currentResponses.map((response) => response.id.value);

  let matchingElements: any[] = [];

  metrics.forEach((metric: any) =>
    metric.kpcs.forEach(({ expertsResponses }: { expertsResponses: any[] }) => {
      matchingElements = [
        ...matchingElements,
        ...expertsResponses.filter((response: { id: { value: string } }) =>
          currentResponseIds.includes(response.id.value)
        ),
      ];
    })
  );

  return replaceMatchingElements(currentResponses, matchingElements);
};

const mergeKpc = (
  currentPrimer: KeyPurchasingCriteriaAPI,
  mentionsPrimer: KeyPurchasingCriteriaAPI
): KeyPurchasingCriteriaAPI => {
  return {
    rankedKpcs:
      mentionsPrimer.rankedKpcs.length > 0
        ? mergeRankedKpcs(currentPrimer.rankedKpcs, mentionsPrimer.rankedKpcs)
        : currentPrimer.rankedKpcs,
    companyMetrics:
      mentionsPrimer.companyMetrics.length > 0
        ? mergeCompanyMetrics(currentPrimer.companyMetrics, mentionsPrimer.companyMetrics)
        : currentPrimer.companyMetrics,
  };
};
const mergeNps = (
  currentPrimerNps: NetPromoterScoreAPI[],
  mentionsPrimerNps: NetPromoterScoreAPI[]
): NetPromoterScoreAPI[] =>
  currentPrimerNps.map((npsEntry: NetPromoterScoreAPI) => ({
    ...npsEntry,
    summary: mergeMatchingSummary(npsEntry.summary, mentionsPrimerNps),
    expertsResponses: mergeMatchingExpertResponses(npsEntry.expertsResponses, mentionsPrimerNps),
  }));

const mergeVendorSwitching = (
  currentPrimerVendorSwitching: VendorSwitchingAPI,
  mentionsPrimerVendorSwitching: VendorSwitchingAPI
): VendorSwitchingAPI => {
  const { summary: btsSummary, categories: btsCategories } = currentPrimerVendorSwitching.barriersToSwitch;
  const { summary: dtsSummary, categories: dtsCategories } = currentPrimerVendorSwitching.driveToSwitch;

  const matchingBtsSummary = mergeMatchingSingleSummary(
    btsSummary,
    mentionsPrimerVendorSwitching.barriersToSwitch.summary
  );

  let matchingDtsSummary = mergeMatchingSingleSummary(dtsSummary, mentionsPrimerVendorSwitching.driveToSwitch.summary);

  return {
    ...currentPrimerVendorSwitching,
    barriersToSwitch: {
      summary: matchingBtsSummary,
      categories: btsCategories.map((category: CustomerPrimerCategory) => ({
        ...category,
        title: mergeMatchingCategoryTitle(category, mentionsPrimerVendorSwitching.barriersToSwitch.categories),
        expertsResponses: mergeMatchingExpertResponses(
          category.expertsResponses,
          mentionsPrimerVendorSwitching.barriersToSwitch.categories
        ),
      })),
    },
    driveToSwitch: {
      summary: matchingDtsSummary,
      categories: dtsCategories.map((category: CustomerPrimerCategory) => ({
        ...category,
        title: mergeMatchingCategoryTitle(category, mentionsPrimerVendorSwitching.driveToSwitch.categories),
        expertsResponses: mergeMatchingExpertResponses(
          category.expertsResponses,
          mentionsPrimerVendorSwitching.driveToSwitch.categories
        ),
      })),
    },
  };
};

const mergeMatchingCategoryTitle = (
  currentCategory: CustomerPrimerCategory,
  categories: CustomerPrimerCategory[],
  text?: string
) => {
  const matchingCategory = categories.find((category) => category.id.value === currentCategory.id.value);

  return matchingCategory ? matchingCategory.title : currentCategory.title;
};

const mergeOutlook = (
  currentPrimerOutlook: CustomerPrimerOutlookAPI,
  mentionsPrimerOutlook: CustomerPrimerOutlookAPI
): CustomerPrimerOutlook => {
  return {
    ...currentPrimerOutlook,
    change: {
      ...currentPrimerOutlook.change,
      summary: mergeMatchingSingleSummary(currentPrimerOutlook.change.summary, mentionsPrimerOutlook.change.summary),
      categories: currentPrimerOutlook.change.categories.map((category: CustomerPrimerCategory, index: number) => ({
        ...category,
        title: mergeMatchingCategoryTitle(category, mentionsPrimerOutlook.change.categories),
        expertsResponses: mergeMatchingExpertResponses(
          category.expertsResponses,
          mentionsPrimerOutlook.change.categories
        ),
      })),
    },
    allocation: [...currentPrimerOutlook.allocation],
    excited: {
      ...currentPrimerOutlook.excited,
      summary: mergeMatchingSingleSummary(currentPrimerOutlook.excited.summary, mentionsPrimerOutlook.excited.summary),
      categories: currentPrimerOutlook.excited.categories.map((category: CustomerPrimerCategory, index: number) => ({
        ...category,
        title: mergeMatchingCategoryTitle(category, mentionsPrimerOutlook.excited.categories),
        expertsResponses: mergeMatchingExpertResponses(
          category.expertsResponses,
          mentionsPrimerOutlook.excited.categories
        ),
      })),
    },
    concerned: {
      ...currentPrimerOutlook.concerned,
      summary: mergeMatchingSingleSummary(
        currentPrimerOutlook.concerned.summary,
        mentionsPrimerOutlook.concerned.summary
      ),
      categories: currentPrimerOutlook.concerned.categories.map((category: CustomerPrimerCategory, index: number) => ({
        ...category,
        title: mergeMatchingCategoryTitle(category, mentionsPrimerOutlook.concerned.categories),
        expertsResponses: mergeMatchingExpertResponses(
          category.expertsResponses,
          mentionsPrimerOutlook.concerned.categories
        ),
      })),
    },
    emerging: currentPrimerOutlook.emerging.map((emerging: any) => ({
      ...emerging,
      expertsResponses: mergeMatchingExpertResponses(emerging.expertsResponses, mentionsPrimerOutlook.emerging),
    })),
  };
};

const mergeOverview = (currentOverview: CustomerPrimerOverviewAPI, mentionsOverview: CustomerPrimerOverviewAPI) => {
  return {
    ...currentOverview,
    title: mentionsOverview.title ? mentionsOverview.title : currentOverview.title,
    marketContext:
      currentOverview.marketContext &&
      mergeMatchingSingleSummary(currentOverview.marketContext, mentionsOverview.marketContext),
  };
};

// this is needed because the mentions endpoint only returns the objects that matches the search keywords
export const mergeMentionsPrimerWithActualPrimer = (
  currentPrimer: CustomerPrimer,
  mentionsPrimer: CustomerPrimerKeywordSearchContent
) => {
  let newPrimer = cloneDeep(currentPrimer);

  if (mentionsPrimer.overview) {
    newPrimer.overview = mergeOverview(newPrimer.overview, mentionsPrimer.overview);
  }

  if (mentionsPrimer.keyPurchasingCriteria)
    newPrimer.keyPurchasingCriteria = mergeKpc(newPrimer.keyPurchasingCriteria, mentionsPrimer.keyPurchasingCriteria);

  if (mentionsPrimer.netPromoterScore)
    newPrimer.netPromoterScore = mergeNps(newPrimer.netPromoterScore, mentionsPrimer.netPromoterScore);

  if (mentionsPrimer.vendorSwitching)
    newPrimer.vendorSwitching = mergeVendorSwitching(newPrimer.vendorSwitching, mentionsPrimer.vendorSwitching);

  if (mentionsPrimer.outlook) newPrimer.outlook = mergeOutlook(newPrimer.outlook, mentionsPrimer.outlook);

  return newPrimer;
};
