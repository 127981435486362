import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCkFlyoutStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    roleSubtitle: {
      mb: inner.base02,
      mt: inner.base03,
    },
    knowledgesList: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base,
    },
    knowledgeLine: {
      display: "flex",
      gap: inner.base02,
      alignItems: "center",
    },
    icon: {
      padding: inner.base,
      borderRadius: inner.base02,
    },
    knowledgeText: {
      display: "block",
      whiteSpace: { _: "", md: "nowrap" },
      overflow: { _: "", md: "hidden" },
      textOverflow: { _: "", md: "ellipsis" },
    },
  };
};
