import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useClientAvailabilityStyles = () => {
  const {
    spacing: { inner },
    color: { text, border },
    shape,
    font,
  } = useThemeTokens();

  const title = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    p: inner.base04,
    borderBottomWidth: shape.border.width.sm,
    borderColor: border.divider,
  };

  const closeIcon = {
    h: inner.base03,
    w: inner.base03,
    fontSize: font.size["06"],
  };

  const deleteIcon = {
    h: inner.base03,
    w: inner.base03,
    fontSize: font.size["06"],
  };

  const textStrong = {
    color: text.strong._,
  };

  const textSecondary = {
    color: text.secondary,
  };

  const formWrapper = {
    p: inner.base04,
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    pr: inner.base08,
    borderBottomWidth: shape.border.width.sm,
    borderColor: border.divider,
  };

  const fields = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const save = {
    display: "flex",
    justifyContent: "flex-end",
    p: inner.base04,
  };

  const lastUpdated = {
    color: text.secondary,
    pl: inner.base06,
    mt: `-${inner.base02}`,
  };

  const selectLabel = {
    color: text.secondary,
    mb: inner.base02,
  };

  const chip = {
    m: inner.base,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxW: "126px",
  };

  return {
    title,
    closeIcon,
    deleteIcon,
    textStrong,
    textSecondary,
    fields,
    formWrapper,
    save,
    lastUpdated,
    selectLabel,
    chip,
  };
};
