import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTConversationListStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base03,
    pt: inner.base,
  };

  return {
    wrapper,
  };
};
