import React, { useRef, useState, useCallback, useEffect } from "react";
import { EllipsisTextProps } from "./EllipsisText.types";
import { Tooltip } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const EllipsisText = ({ text, showTooltip = true, toggleExternalTooltip }: EllipsisTextProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      const currentOverflow = textRef.current.scrollWidth > textRef.current.clientWidth;
      if (isOverflowing !== currentOverflow) {
        setIsOverflowing(currentOverflow);
        toggleExternalTooltip?.(currentOverflow);
      }
    }
  }, [isOverflowing, toggleExternalTooltip]);

  useEffect(() => {
    // Initial check
    checkOverflow();

    // Re-check on window resize
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [checkOverflow]);

  return (
    <Tooltip title={text} variant="dark" disabled={!isOverflowing || !showTooltip || !!toggleExternalTooltip}>
      <x.div ref={textRef} data-testid="ellipsis-text" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        {text}
      </x.div>
    </Tooltip>
  );
};

export default EllipsisText;
