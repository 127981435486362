import React, { useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton } from "@alphasights/alphadesign-components";
import { useMessengerContext } from "providers/MessengerProvider";
import { MobileQuestionContext } from "../MobileQuestionContext/MobileQuestionContext.component";
import { RequestType } from "../../../types";
import { ReplyThreadView } from "./ReplyThreadView.component";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { PortalMobileTopBar } from "../../../components/PortalMobileTopBar";
import { useMessengerContentStyles } from "./MobileMessengerContent.styles";

const requestTypeMap = new Map<RequestType, string>([
  [RequestType.CallGuide, "Call Guide"],
  [RequestType.Clarification, "Clarification"],
  [RequestType.WorkRequest, "Work Request"],
]);

export const MobileMessengerContent = ({ onClose }: { onClose: () => void }) => {
  const { selectedInbox: inbox, projectToken, inboxes } = useMessengerContext();
  const { wrapper, content } = useMessengerContentStyles();

  const [selectedThreadId, setSelectedThreadId] = useState<string>();

  const validInbox = inbox ? inbox : inboxes[0];
  const title = validInbox && (requestTypeMap.get(validInbox.requestType) || "");
  const selectedThread = useMemo(() => validInbox.threads.find((thread) => thread.id === selectedThreadId), [
    validInbox,
    selectedThreadId,
  ]);

  return (
    <x.div data-testid="messenger-inbox-content" {...wrapper}>
      <>
        {validInbox && (
          <>
            <PortalMobileTopBar
              title={title}
              mainButton={
                <IconButton size="large" variant="ghost" onClick={() => onClose()} testId="close-message-tab-button">
                  <ArrowLeft />
                </IconButton>
              }
            />
            <x.div {...content}>
              {selectedThread ? (
                <ReplyThreadView selectedThread={selectedThread} onClose={() => setSelectedThreadId(undefined)} />
              ) : (
                <MobileQuestionContext
                  inbox={validInbox}
                  projectToken={projectToken}
                  setSelectedThreadId={setSelectedThreadId}
                />
              )}
            </x.div>
          </>
        )}
      </>
    </x.div>
  );
};
