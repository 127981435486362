import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const useContentWrapper = () => {
  const {
    color: { background },
    shape,
    spacing,
  } = useThemeTokens();

  const outerContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: background.surface.recessed,
  };

  const innerContainer = {
    display: "flex",
    flexDirection: "column",
    padding: spacing.layout.base04,
    boxShadow: shape.border.radius.default,
    borderRadius: shape.border.radius.medium,
    background: background.surface.raised,
  };

  const contentContainer = {
    backgroundColor: background.white,
    height: "auto",
    padding: spacing.inner.base04,
    paddingBottom: spacing.inner.base08,
  };

  return {
    outerContainer,
    innerContainer,
    contentContainer,
  };
};

export const StyledOuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
