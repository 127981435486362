import React from "react";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  StatusDefault,
  StatusFull,
  StatusHalf,
  StatusOneQuarter,
  StatusOneSixth,
  StatusPending,
  StatusThreeQuarters,
} from "@alphasights/alphadesign-icons";

export interface SurveyStateKeys {
  [key: string]: SurveyStateConfig;
}

export interface SurveyStateConfig {
  description: string;
  icon: JSX.Element;
  styles: { [key: string]: React.CSSProperties };
}

export const usePillStateConfig = () => {
  const {
    color: { background },
  } = useThemeTokens();

  return {
    answered: {
      description: "Answered",
      icon: (
        <Icon size="small" color="scheduled">
          <StatusThreeQuarters />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.processing.default} !important`,
        border: "none !important",
      },
    },
    available: {
      description: "Available",
      icon: (
        <Icon size="small" color="secondary">
          <StatusPending />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.neutral.default} !important`,
        border: "none !important",
      },
    },
    completed: {
      description: "Completed",
      icon: (
        <Icon size="small" color="success">
          <StatusFull />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.success} !important`,
        border: "none !important",
      },
    },
    partially_completed: {
      description: "Partially Completed",
      icon: (
        <Icon size="small" color="info">
          <StatusHalf />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.info} !important`,
        border: "none !important",
      },
    },
    qc_removal: {
      description: "QC Removal",
      icon: (
        <Icon size="small" color="warning">
          <StatusDefault />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.warning} !important`,
        border: "none !important",
      },
    },
    quota_met: {
      description: "Quota Met",
      icon: (
        <Icon size="small" color="warning">
          <StatusDefault />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.warning} !important`,
        border: "none !important",
      },
    },
    requested: {
      description: "Requested",
      icon: (
        <Icon size="small" color="danger">
          <StatusOneSixth />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.danger.default} !important`,
        border: "none !important",
      },
    },
    screened_out: {
      description: "Screened Out",
      icon: (
        <Icon size="small" color="warning">
          <StatusDefault />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.warning} !important`,
        border: "none !important",
      },
    },
    sent: {
      description: "Sent",
      icon: (
        <Icon size="small" color="info">
          <StatusOneQuarter />
        </Icon>
      ),
      styles: {
        backgroundColor: `${background.info} !important`,
        border: "none !important",
      },
    },
  } as SurveyStateKeys;
};

export interface SurveyStateSortOrderKeys {
  [key: string]: SurveyStateSortOrder;
}

export interface SurveyStateSortOrder {
  sortOrder: number;
}

export const surveyStateSortOrderKeys = [
  "pending",
  "available",
  "requested",
  "sent",
  "partially_completed",
  "answered",
  "qc_removal",
  "quota_met",
  "screened_out",
  "completed",
].reduce(
  (acc, val, idx) => ({
    [val]: { sortOrder: idx },
    ...acc,
  }),
  {}
) as SurveyStateSortOrderKeys;
