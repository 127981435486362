import React from "react";
import { x } from "@xstyled/styled-components";
import AsyncSelect from "react-select/async";
import { reactSelectStyle } from "./styles";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const TranscriptSearchBar = (props) => {
  return (
    <StyledTranscriptSearchBar
      cacheOptions
      hideSelectedOptions
      components={{
        ClearIndicator: () => null,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      isMulti
      noOptionsMessage={() => null}
      overflowY="auto"
      height="42px"
      zIndex={0}
      {...props}
    />
  );
};

const StyledTranscriptSearchBar = (props) => {
  const { spacing, color, shape, font } = useThemeTokens();
  return (
    <StyledSearchBar
      borderRadius={spacing.inner.base}
      boxShadow={`inset 0 0 0 1px ${color.background.neutral.default}`}
      border={`${shape.border.width.sm} solid ${color.border.neutral.default}`}
      fontSize={font.size["03"]}
      height="44px"
      isTranscript
      {...props}
    />
  );
};

const StyledSearchBar = (props) => {
  const propsForAsyncSelect = { ...props, zIndex: undefined };

  return (
    <x.div
      row
      display="block"
      flexDirection="row-reverse"
      zIndex={props.zIndex ?? "2"}
      position="relative"
      flexGrow={0}
      data-testid="search-bar"
    >
      <AsyncSelect styles={reactSelectStyle} {...propsForAsyncSelect} />
    </x.div>
  );
};

const TranscriptSearchBarWithAccess = withAccessControl(TranscriptSearchBar);

export { TranscriptSearchBar, TranscriptSearchBarWithAccess };
