import { CheckboxOption } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/CheckboxFilter";

const TITLE = "Ownership";

enum OWNERSHIP_OPTIONS {
  private = "PRIVATE_COMPANY",
  public = "PUBLIC_COMPANY",
}

const OPTIONS: CheckboxOption[] = [
  {
    label: "Privately held",
    value: OWNERSHIP_OPTIONS.private,
  },
  {
    label: "Publicly held",
    value: OWNERSHIP_OPTIONS.public,
  },
];

export { TITLE, OPTIONS, OWNERSHIP_OPTIONS };
