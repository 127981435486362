import React, { FC } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { sortBy } from "lodash";
import { CompanyPrimerSectionCard } from "pages/AlphaNowPage/primers/CompanyPrimer/components";
import { OutlookType } from "models/primers/constants";
import { PrimerOutlook } from "pages/AlphaNowPage/primers/components";

export const TestIds = {
  strategicOutlookTheme: "strategic-outlook-theme",
};

const SECTION_TITLE = "Outlook";

interface StrategicOutlookProps {
  outlooks: CitableOutlook[];
}

const StrategicOutlook: FC<StrategicOutlookProps> = ({ outlooks }) => {
  const {
    color,
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const getOutlooksOfType = (type: string): CitableOutlook[] =>
    sortBy(
      outlooks.filter((output) => output.impact.value === type),
      (outlook: CitableOutlook) => outlook.outlookOrder.value || 0
    );

  const opportunities = getOutlooksOfType(OutlookType.opportunity);
  const risks = getOutlooksOfType(OutlookType.risk);

  if (!Boolean(risks.length) && !Boolean(opportunities.length)) return null;

  return (
    <CompanyPrimerSectionCard title={SECTION_TITLE}>
      <x.div my={{ xs: inner.base05, sm: 0 }}>
        {!!opportunities.length && (
          <x.div mb={Boolean(risks.length) ? inner.base06 : 0}>
            {opportunities.map((opportunity) => (
              <PrimerOutlook key={opportunity.id.value} outlook={opportunity} outlookType={OutlookType.opportunity} />
            ))}
          </x.div>
        )}
        {!!(risks.length && opportunities.length) && (
          <x.hr
            mb={inner.base06}
            mt={border.width.none}
            borderColor={color.border.neutral.default}
            borderWidth={border.width.none}
            borderTopWidth={border.width.small}
          />
        )}
        {!!risks.length && (
          <>
            {risks.map((risk) => (
              <PrimerOutlook key={risk.id.value} outlook={risk} outlookType={OutlookType.risk} />
            ))}
          </>
        )}
      </x.div>
    </CompanyPrimerSectionCard>
  );
};

export default StrategicOutlook;
