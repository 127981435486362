import React, { FC, useCallback } from "react";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { Row } from "@tanstack/react-table";
import { buildColumns } from "./ColumnsDefBuilder";
import { RecommendedContentTableProps } from "./types";

export const RecommendedContentTable: FC<RecommendedContentTableProps> = ({
  suggestedContent,
  onSelectRecommendedContent,
  selectedRecomendedContent,
}) => {
  const columns = buildColumns();

  const onRowClicked = useCallback(
    (row: Row<ContentResults>) => {
      const { original: content } = row;
      onSelectRecommendedContent(content);
    },
    [onSelectRecommendedContent]
  );
  const rowClassRules = [
    {
      className: "row-selected",
      ruleFunction: (row: Row<ContentResults>) => row.original.id === selectedRecomendedContent?.id,
    },
  ];

  const options = {
    meta: {
      hideSelectColumn: true,
      fullWidth: true,
      onRowClicked,
      rowClassRules,
      rowHoverHighlight: true,
    },
  };

  const table = useAlphaTable(suggestedContent, columns, options);

  return (
    <AlphaTableWrapper>
      <AlphaTable table={table} />
    </AlphaTableWrapper>
  );
};

const AlphaTableWrapper = styled.div`
  min-width: 600px;
  display: grid;
  .row-selected {
    background-color: ${tokens.color.background.selected.subtle};
  }
`;
