import { LinkProps } from "@alphasights/alphadesign-components";
import { Project } from "@alphasights/alphadesign-icons";
import { ProjectsMenu, useAccessControl, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { partition } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SidebarOption } from "../SidebarOption";
import * as S from "components/SidebarNav/SidebarNav.styled";
import { HitAction, HitOrigin, LeanProject } from "@alphasights/portal-api-client";

interface ProjectsOptionProps {
  projectsPath: string;
  closeIntercom: () => void;
}
interface CustomLinkProps extends LinkProps {
  projectToken?: string;
}

export const ProjectsOption = ({ projectsPath, closeIntercom }: ProjectsOptionProps) => {
  const { logHit } = useTrackUserAction();
  const projects = useMyProjects();
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

  const isProjectLaunchEnabled = useMemo(() => currentUser?.projectLaunchEnabled ?? false, [currentUser]);
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const [pastProjects, activeProjects] = useMemo(() => partition(projects, (project) => project.inactive), [projects]);
  const hasAnyProject = useMemo(() => (projects ?? []).length > 0, [projects]);
  const handleMouseEnter = (event: React.MouseEvent) => {
    const myAlphaSightsPattern = new RegExp(myAlphaSightsBaseUrl);
    if (!myAlphaSightsPattern.test(currentPathname)) {
      setAnchorEl(event.currentTarget);
    }
  };
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMyAlphasights = /\/my-alphasights\//.test(currentPathname);
  const selectedProjectToken = isMyAlphasights ? currentPathname.split("/").pop() : currentPathname.split("/")[1];

  const handleMouseLeave = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  const getPastProjectRedirectPath = useCallback(
    (project: LeanProject) => {
      const basePath = currentUser?.portalWorkstreamsEnabled
        ? `${project.token}/workstream/${project.workstreams[0].id}`
        : project.token;

      return `/${basePath}/experts`;
    },
    [currentUser]
  );

  const CustomLink = ({ href, children, ...props }: CustomLinkProps) => {
    const history = useHistory();
    const projectToken = href?.replace("/projects/", "");

    const isProjectInactive = pastProjects.some((p) => p.token === projectToken);

    const to = isProjectInactive
      ? getPastProjectRedirectPath(pastProjects.find((p) => p.token === projectToken)!)
      : `/${myAlphaSightsBaseUrl}${href}`;

    const url = href === "/project-launch" ? "/project-launch" : to;
    const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      history.push(url);
    };

    return (
      <a href={url} onClick={handleNavigation} {...props}>
        {children}
      </a>
    );
  };

  const onLaunchProjectClick = () => {
    logHit({
      origin: HitOrigin.projectsMenu,
      action: HitAction.projectLaunchMenuClicked,
    });
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SidebarOption
        id="projects"
        to={projectsPath}
        permission="view_projects"
        label="Projects"
        icon={<Project />}
        onClick={closeIntercom}
      />
      {hasAnyProject && hasProjectsPermission && open && (
        <S.Popover open anchorEl={anchorEl} placement="right-start" onClick={closeIntercom} closeOnMouseLeave>
          <ProjectsMenu
            isLoading={!hasAnyProject}
            projects={activeProjects}
            pastProjects={pastProjects}
            LinkComponent={CustomLink}
            isProjectLaunchEnabled={isProjectLaunchEnabled}
            selectedProjectToken={selectedProjectToken}
            onLaunchProjectClick={onLaunchProjectClick}
            defaultOpened={true}
          />
        </S.Popover>
      )}
    </div>
  );
};
