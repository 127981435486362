export const languages = [
  { locale: "en", language: "English" },
  { locale: "zh", language: "Mandarin" },
  { locale: "ko", language: "Korean" },
  { locale: "zh-HK", language: "Cantonese" },
  { locale: "fr", language: "French" },
  { locale: "es", language: "Spanish" },
  { locale: "it", language: "Italian" },
  { locale: "pt", language: "Portuguese" },
  { locale: "vi", language: "Vietnamese" },
  { locale: "ar", language: "Arabic" },
  { locale: "af", language: "Afrikaans" },
  { locale: "sq", language: "Albanian" },
  { locale: "hy", language: "Armenian" },
  { locale: "bn", language: "Bengali" },
  { locale: "bg", language: "Bulgarian" },
  { locale: "km", language: "Central Khmer" },
  { locale: "hr", language: "Croatian" },
  { locale: "cs", language: "Czech" },
  { locale: "da", language: "Danish" },
  { locale: "nl", language: "Dutch" },
  { locale: "et", language: "Estonian" },
  { locale: "fi", language: "Finnish" },
  { locale: "ka", language: "Georgian" },
  { locale: "de", language: "German" },
  { locale: "gu", language: "Gujarati" },
  { locale: "he", language: "Hebrew" },
  { locale: "hi", language: "Hindi" },
  { locale: "hu", language: "Hungarian" },
  { locale: "is", language: "Icelandic" },
  { locale: "id", language: "Indonesian" },
  { locale: "ga", language: "Irish" },
  { locale: "jp", language: "Japanese" },
  { locale: "lv", language: "Latvian" },
  { locale: "lt", language: "Lithuanian" },
  { locale: "mk", language: "Macedonian" },
  { locale: "ms", language: "Malay" },
  { locale: "ml", language: "Malayalam" },
  { locale: "mt", language: "Maltese" },
  { locale: "nz", language: "Maori" },
  { locale: "mr", language: "Marathi" },
  { locale: "el", language: "Greek" },
  { locale: "mn", language: "Mongolian" },
  { locale: "ne", language: "Nepali" },
  { locale: "pa", language: "Panjabi" },
  { locale: "nb", language: "Norwegian" },
  { locale: "fa", language: "Persian" },
  { locale: "pl", language: "Polish" },
  { locale: "qu", language: "Quechua" },
  { locale: "ro", language: "Romanian" },
  { locale: "ru", language: "Russian" },
  { locale: "sm", language: "Samoan" },
  { locale: "sr", language: "Serbian" },
  { locale: "sk", language: "Slovak" },
  { locale: "sl", language: "Slovenian" },
  { locale: "sw", language: "Swahili" },
  { locale: "sv", language: "Swedish" },
  { locale: "ta", language: "Tamil" },
  { locale: "tt", language: "Tatar" },
  { locale: "te", language: "Telugu" },
  { locale: "th", language: "Thai" },
  { locale: "bo", language: "Tibetan" },
  { locale: "to", language: "Tonga" },
  { locale: "tr", language: "Turkish" },
  { locale: "uk", language: "Ukrainian" },
  { locale: "ur", language: "Urdu" },
  { locale: "uz", language: "Uzbek" },
  { locale: "cy", language: "Welsh" },
  { locale: "ca", language: "Catalan" },
  { locale: "eu", language: "Basque" },
];

export const getLanguageFromCode = (code) => languages.find((language) => language.locale === code)?.language;

export const toMinutesAndSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formatted = [hours, minutes, seconds].map((v) => {
    return v.toLocaleString("en-US", { minimumIntegerDigits: 2 });
  });

  if (hours > 0) {
    return `${formatted[0]}:${formatted[1]}:${formatted[2]}`;
  }

  return `${formatted[1]}:${formatted[2]}`;
};

const convertToDate = (dateString) => (dateString === "Current" ? new Date() : new Date(dateString));

export const getRolePeriodDuration = (rolePeriod) => {
  const [startDate, endDate] = rolePeriod.split(" - ");
  return convertToDate(endDate) - convertToDate(startDate);
};
