import { format } from "date-fns";

function standardizeDateFormat(dateFormat: string) {
  const INCORRECT_DAY_PATTERN = "D";
  const INCORRECT_YEAR_PATTERN = "Y";
  const AM_PM_PATTERN = "a";

  let standardizedFormat = dateFormat;

  // Removing date field patterns to comply with Unicode Data Markup Language
  // for Dates

  // ref https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  if (dateFormat.includes(INCORRECT_DAY_PATTERN)) {
    // D incorrect format for day of month
    standardizedFormat = standardizedFormat.replace(new RegExp(INCORRECT_DAY_PATTERN, "g"), "d");
    console.warn(`Invalid date pattern provided: Replacing ${INCORRECT_DAY_PATTERN} with 'd'`);
  }
  if (dateFormat.includes(INCORRECT_YEAR_PATTERN)) {
    // Y Incorrect format for calendar year
    standardizedFormat = standardizedFormat.replace(new RegExp(INCORRECT_YEAR_PATTERN, "g"), "y");
    console.warn(`Invalid date pattern provided: Replacing ${INCORRECT_YEAR_PATTERN} with 'y'`);
  }
  if (dateFormat.includes(AM_PM_PATTERN)) {
    // no am , pm support in date-fns, using a,p pattern and addign the m after
    standardizedFormat = standardizedFormat.replace(AM_PM_PATTERN, "aaaaa'm'");
  }

  return standardizedFormat;
}

const formatDate = (
  date: Date | string | null | undefined,
  dateFormat: string = "MMM dd, yyyy",
  timeZone: string = "Etc/Universal"
) => {
  if (!date) return "-";
  const dateObj = new Date(date);
  return format(new Date(dateObj.toLocaleString("en-US", { timeZone })), standardizeDateFormat(dateFormat));
};

export { formatDate };
