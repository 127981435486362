import React, { useRef } from "react";
import { RadioButton, RadioGroup, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { DAY_FORMAT, TIME_FORMAT } from "helpers/interactionHelpers";
import Content from "react-content-loader";
import { getStateColorFlyout, getStateName } from "components/AdvisorCard/stateFunctions";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { FlyoutConfiguration } from "pages/InteractionPage/enums";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const InteractionChainList = ({
  loading,
  chainInteractions,
  chainIdSelected,
  onChangeChainIdSelected,
  clientRequests,
  showCalendarView,
  isExpertHidden,
  flyoutConfiguration,
  ...props
}) => {
  if (chainInteractions == null || (!loading && chainInteractions.length === 0)) return null;

  return (
    <>
      {loading ? (
        <>
          <LinkLoader />
          <LinkLoader />
        </>
      ) : (
        <RadioGroup value={chainIdSelected} {...props}>
          {chainInteractions.map((interaction) => {
            return (
              <RadioButton
                key={interaction.id}
                value={interaction.id}
                onClick={() => onChangeChainIdSelected(interaction.id)}
                data-testid={`chain-link-${interaction.id}`}
              >
                <InteractionInfo
                  interaction={interaction}
                  clientRequests={clientRequests}
                  showCalendarView={showCalendarView}
                  flyoutConfiguration={flyoutConfiguration}
                  isExpertHidden={isExpertHidden}
                />
              </RadioButton>
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

const InteractionInfo = ({ interaction, clientRequests, showCalendarView, flyoutConfiguration, isExpertHidden }) => {
  const { color } = useThemeTokens();
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref, flyoutConfiguration);

  const content = (
    <>
      {capitalize(interaction.interactionType === "Industry Survey" ? "Survey" : interaction.interactionType)}
      {" • "}
      <Typography
        component="span"
        color={isExpertHidden ? color.text.secondary : getStateColorFlyout(interaction.state, color)}
      >
        {capitalize(
          getStateName({
            pendingRequest: clientRequests?.find((r) => r.interactionId === interaction.id),
            showCalendarView,
            ...interaction,
          })
        )}
      </Typography>
      {interaction.interactionType !== "Industry Survey" && !["proposed", "requested"].includes(interaction.state) && (
        <>
          {" • "}
          <FormattedDateTime date={interaction.scheduledCallTime} format={`${DAY_FORMAT}, ${TIME_FORMAT}`} />
        </>
      )}
      {interaction.invoiceNumber && (
        <Typography component="span" color="secondary">
          {" • "}
          {`ID: ${interaction.invoiceNumber}`}
        </Typography>
      )}
    </>
  );

  return (
    <PortalTooltip title={<Typography>{content}</Typography>} disabled={!isOverflow} maxWidth="45ch" position="bottom">
      <Typography
        ref={ref}
        maxWidth={flyoutConfiguration === FlyoutConfiguration.Standard ? "45ch" : undefined}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        data-testid={`other-interaction-${interaction.id}`}
      >
        {content}
      </Typography>
    </PortalTooltip>
  );
};

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const LinkLoader = () => (
  <Content viewBox="0 0 360 20" className="aui-flex aui-bg-white aui-my-2">
    <rect x="0" y="0" width="230" height="15" />
  </Content>
);
