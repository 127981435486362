import { ComparisonViewAngle } from "./ComparisonViewHeader/ComparisonViewHeader";
import { ExpertCompareQuestion, NOT_YET_ANSWERED } from "models/ExpertCompare";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { compact, entries, first, groupBy, orderBy, values } from "lodash";
import { mainInteractionFor } from "components/InteractionsPage/helpers/Interaction";
import { useMemoizedValue } from "hooks/useMemoizedValue";

const useComparisonViewParams = () => {
  const {
    interactions,
    state: { appliedFilters, filterOptions },
  } = useProjectInteractionsContext();
  const { questions: rawQuestions } = useExpertCompareContext();
  const questions = useMemoizedValue(filterAndSortQuestions(rawQuestions, interactions));
  const angles = getComparisonViewAngles(questions, filterOptions?.groups ?? []);
  const selectedAngle = getSelectedComparisonViewAngle(angles, first(appliedFilters.groups ?? []));
  return { questions, angles, selectedAngle };
};

const getSelectedComparisonViewAngle = (angles: ComparisonViewAngle[], selectedAngleId?: string) => {
  const selectedAngle = angles
    .flatMap((angle) => [angle, ...(angle.children ?? [])])
    .find((angle) => angle.id === selectedAngleId);

  return selectedAngle;
};

const getComparisonViewAngles = (questions: ExpertCompareQuestion[], angles: FilterOption[]) => {
  const questionsAngles = questions.map((q) => q.angle);
  const angleIdsWithQuestions = questionsAngles.map((angle) => angle.id);
  const getIsExpertsCapExceeded = (angleId: string) =>
    questionsAngles.find((qAngle) => qAngle.id === angleId)?.isExpertsCapExceeded ?? false;

  return angles
    .filter((angle) => angleIdsWithQuestions.includes(angle.value))
    .map(
      (angle) =>
        ({
          ...angle,
          children: angle.children
            ?.filter((subAngle) => angleIdsWithQuestions.includes(subAngle.value))
            .map(
              (subAngle) =>
                ({
                  ...subAngle,
                  isExpertsCapExceeded: getIsExpertsCapExceeded(subAngle.value),
                } as ComparisonViewAngle)
            ),
          isExpertsCapExceeded: getIsExpertsCapExceeded(angle.value),
        } as ComparisonViewAngle)
    );
};

const getInitialAngleId = (
  interactions: Interaction[],
  questions: ExpertCompareQuestion[],
  initialAngleId?: string
) => {
  const mainInteractions = values(groupBy(interactions, "advisorId")).map(
    (interactions) => mainInteractionFor(interactions) as Interaction
  );

  const interactionsGroupedByAngle = groupBy(
    mainInteractions.flatMap((interaction) =>
      interaction.angles.flatMap((angle) => {
        const angleId = angle.id;
        const parentId = angle.parent?.id;
        return compact([angleId, parentId]).map((id) => ({
          angleId: id,
          angleTitle: id === angleId ? angle.title : angle.parent?.title,
          interaction,
        }));
      })
    ),
    "angleId"
  );

  const angles = entries(interactionsGroupedByAngle).map(([angleId, interactions]) => ({
    angleId,
    angleTitle: first(interactions)?.angleTitle ?? "",
    numInteractions: interactions.length,
  }));

  // Sort by number of interactions and then by the angle name
  const angleTitlePriority = [
    "Suppliers",
    "Intermediaries",
    "Industry Authorities",
    "Formers",
    "Customers",
    "Competitors",
  ];
  const sortedAngles = angles.sort(
    (a, b) =>
      b.numInteractions - a.numInteractions ||
      angleTitlePriority.indexOf(b.angleTitle) - angleTitlePriority.indexOf(a.angleTitle)
  );

  return compact([initialAngleId, ...sortedAngles.map((angle) => angle.angleId)])
    .filter((id) => questions.map((q) => q.angle.id).includes(id))
    .at(0);
};

const filterAndSortQuestions = (questions: ExpertCompareQuestion[], interactions: Interaction[]) => {
  const mainInteractions = values(groupBy(interactions, "advisorId")).map(
    (interactions) => mainInteractionFor(interactions) as Interaction
  );

  // Remove responses from experts that might have been removed from the project or had its angle changed
  const questionsWithFilteredResponses = questions.map((question) => ({
    ...question,
    responses: question.responses.filter((response) => {
      const interaction = mainInteractions.find((interaction) => interaction.advisorId === response.expertId);
      const angles = interaction?.angles;
      return angles?.some((angle) => angle?.id === question.angle.id || angle?.parent?.id === question.angle.id);
    }),
  }));

  return sort(questionsWithFilteredResponses);
};

const sort = (questions: ExpertCompareQuestion[]) => {
  const getNumberOfResponses = (question: ExpertCompareQuestion) =>
    question.responses.filter((response) => response.text.length > 0 && response.text !== NOT_YET_ANSWERED).length;

  const getTotalLengthOfResponses = (question: ExpertCompareQuestion) =>
    question.responses.reduce(
      (acc, response) => acc + (response.text === NOT_YET_ANSWERED ? 0 : response.text.length),
      0
    );

  return orderBy(questions, [getNumberOfResponses, getTotalLengthOfResponses], ["desc", "desc"]);
};

export { useComparisonViewParams, getInitialAngleId };
