import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useGlobalNavStyles = (
  { isMobile, intercomMessengerOpen, intercomBooted } = {
    isMobile: false,
    intercomMessengerOpen: false,
    intercomBooted: false,
  }
) => {
  const {
    color: { icon, text },
    spacing: { inner, layout },
  } = useThemeTokens();

  const compliancePopover = {
    padding: 0,
    w: 402,
  };

  const compliancePopoverIcon = {
    display: "inline-block",
    color: icon.secondary,
    mr: inner.base03,
    cursor: "pointer",
  };

  const compliancePopoverIconSize = {
    width: 24,
    height: 24,
  };

  const compliancePopoverContent = {
    p: inner.base04,
  };

  const compliancePopoverContentLinks = {
    p: inner.base02,
  };

  const complianceListText = {
    color: isMobile ? text.secondary : "",
  };

  const complianceVideoSize = {
    h: "70vh",
    w: "90vw",
  };

  const mobileMenuDialog = {
    overflow: "auto",
    w: "100%",
    h: "100%",
    padding: 0,
    borderRadius: 0,
    border: "0px",
  };

  const mobileMenuWrapper = {
    display: "flex",
    flexDirection: "column",
  };

  const mobileMenuHeaderWrapper = {
    display: "flex",
    justifyContent: "center",
    py: layout.base02,
    px: layout.base03,
  };

  const mobileMenuCloseButton = {
    width: layout.base03,
    height: layout.base03,
  };

  const MobileMenuSpacing = {
    w: layout.base03,
    h: layout.base03,
  };

  const mobileMenuLogo = {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  };

  const mobileMenuAccordion = {
    px: layout.base03,
  };

  const mobileMenuAccordionItem = {
    display: "flex",
    gap: inner.base04,
    flexDirection: "column",
  };

  const mobileMenuAccordionLink = {
    py: inner.base06,
  };

  const mobileMenuComplianceList = {
    py: inner.base06,
    px: inner.base04,
  };

  const intercomLauncherIcon = {
    color: intercomMessengerOpen ? icon.selected : icon.secondary,
    cursor: intercomBooted ? null : "not-allowed",
  };

  const intercomLauncherWrapper = { mr: inner.base03 };

  return {
    compliancePopover,
    compliancePopoverIcon,
    compliancePopoverIconSize,
    compliancePopoverContent,
    compliancePopoverContentLinks,
    complianceListText,
    complianceVideoSize,
    mobileMenuDialog,
    mobileMenuWrapper,
    mobileMenuHeaderWrapper,
    mobileMenuCloseButton,
    MobileMenuSpacing,
    mobileMenuLogo,
    mobileMenuAccordion,
    mobileMenuAccordionItem,
    mobileMenuComplianceList,
    mobileMenuAccordionLink,
    intercomLauncherIcon,
    intercomLauncherWrapper,
  };
};
