import { ClientPortalState } from "app/reducers/ClientPortal.reducer";
import { isAfter, parseISO } from "date-fns";

export function getShouldSkipSignIn(state: Pick<ClientPortalState, "signInConfiguration">): boolean {
  return (
    (state.signInConfiguration &&
      state.signInConfiguration.skipSignIn &&
      isAfter(parseISO(state.signInConfiguration.expiration ?? ""), new Date())) ??
    false
  );
}
