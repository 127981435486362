import { useThemeTokens } from "@alphasights/alphadesign-components";

const useCustomerExpertStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    currentlyUsesVendors: {
      display: "flex",
      gap: inner.base02,
    },
    otherVendors: {
      display: "flex",
      gap: inner.base,
    },
  };
};

export default useCustomerExpertStyles;
