import React, { useEffect, useRef, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Icon, IconButton, TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import ReactHtmlParser from "react-html-parser";
import CopyLinkButton from "components/CopyLinkButton";
import BookmarkButton from "components/BookmarkButton";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import { ICON_BUTTON_DEVICE_PROPS } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AlphaNowProductType, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import { ChevronUp, Purchased } from "@alphasights/alphadesign-icons";
import { formatDate } from "utils/dates";
import useContentApi from "components/BookmarkButton/useContentApi";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { Spacer, WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import {
  CommissionModal,
  CommissionButton as CommissionPrimerButton,
} from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import useModal from "hooks/useModal";
import { usePrimersStore } from "../../state/store";

type PrimersHeaderActionBarPropTypes = {
  isShrinkedPaywallHeader: boolean;
  showShrinkedPaywallHeader: (value: boolean) => void;
  companyIds: number[];
  currentTabIndex?: number;
  handleNavigationClick: (index: number) => void;
  showNavigationOnly?: boolean;
  tabs: string[];
  productType: AlphaNowSRMProductType;
};

interface RotatedIconButtonProps extends React.ComponentProps<typeof IconButton> {
  up: boolean;
}

const RotatedIconButton = styled(IconButton)<RotatedIconButtonProps>`
   {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: ${({ up }) => (up ? "rotate(0deg)" : "rotate(180deg)")};
    transform-origin: center;
    transition: transform 0.5s;
    margin-right: 16px;
  }
`;

const PrimersHeaderActionBar = ({
  isShrinkedPaywallHeader,
  showShrinkedPaywallHeader,
  companyIds,
  currentTabIndex,
  handleNavigationClick,
  showNavigationOnly = false,
  tabs,
  productType,
}: PrimersHeaderActionBarPropTypes) => {
  const {
    shape,
    spacing: { inner },
    color: { icon, background, text, border },
  } = useThemeTokens();
  const { isVisible, onOpen, onClose } = useModal();
  const {
    primerTitle,
    lastUpdatedAt,
    contentId,
    isAccessible,
    downloadFileName,
    purchaseProperties,
  } = usePrimersStore();
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const { isMobile } = useCheckScreen();

  useEffect(() => {
    setIsTitleVisible(isAccessible);
  }, [isAccessible]);

  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;

  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, AlphaNowProductType.marketPrimer);

  const containerRef = useRef<HTMLInputElement>(null);

  enum ProductTitle {
    COMPANY_PRIMER = "Company Primer",
    MARKET_PRIMER = "Market Primer",
    CUSTOMER_PRIMER = "Customer Primer",
  }

  return (
    <x.div>
      {!showNavigationOnly && (
        <x.div
          data-testid="primer-header-bar"
          display="flex"
          justifyContent="space-between"
          boxShadow={!isAccessible && `0 5px 5px 0 rgba(34, 34, 34, 0.2)`}
          ref={containerRef}
        >
          {isTitleVisible ? (
            <x.div
              display="flex"
              gap={inner.base02}
              p={`${inner.base06} ${inner.base06} ${inner.base02}`}
              alignItems="center"
            >
              <Typography variant="body-small-em">
                <span className="mentions-container">
                  <>
                    {ReactHtmlParser(primerTitle)}
                    {` | ${ProductTitle[productType]}`}
                  </>
                </span>
              </Typography>
              <Typography color={text.secondary} variant="body-small">{`Last updated ${formatDate(
                lastUpdatedAt,
                "d MMM yyyy"
              )} `}</Typography>
              <Spacer />
              <>
                <x.span px={inner.base}>
                  <Icon color="success">
                    <Purchased />
                  </Icon>
                </x.span>
                <Typography color={text.success} component="span">
                  {purchaseProperties.purchaseStatus}
                </Typography>
              </>
            </x.div>
          ) : (
            <x.div w="100%" display="flex" alignItems="center">
              <Tabs
                flex={1}
                index={currentTabIndex}
                onChange={handleNavigationClick}
                w="100%"
                style={{ paddingTop: `${isAccessible ? inner.base06 : inner.base02}`, paddingLeft: `${inner.base06}` }}
              >
                {tabs.map((tab) => (
                  <TabItem key={tab} label={tab} />
                ))}
              </Tabs>
              <x.div
                display="flex"
                justifyContent="center"
                alignItems="center"
                h="100%"
                borderBottom={`${shape.border.width.sm} solid ${border.divider}`}
              >
                <RotatedIconButton
                  onClick={() => showShrinkedPaywallHeader(!isShrinkedPaywallHeader)}
                  variant="ghost"
                  key="rounded-button"
                  up={!isShrinkedPaywallHeader}
                >
                  <ChevronUp />
                </RotatedIconButton>
              </x.div>
            </x.div>
          )}
          {isAccessible && (
            <x.div
              p={`${inner.base06} ${inner.base06} ${inner.base02}`}
              data-testid="customer-primer-header-buttons-container"
              display="flex"
              gap={inner.base02}
              alignItems="center"
              borderBottom={!isTitleVisible && `solid 1px ${background.disabled}`}
            >
              {!isMobile && (
                <WatchlistQuickAddButton
                  cdsAlphaCompanyIds={companyIds}
                  productType={productType}
                  contentId={contentId}
                />
              )}
              <CopyLinkButton
                contentId={contentId}
                contentType={AlphaNowProductType.customerPrimer}
                {...iconButtonProps}
              />

              <BookmarkButton
                permissions={[MANAGE_CONTENT_PERMISSION]}
                isBookmarked={isBookmarked}
                onToggleBookmark={onToggleBookmark}
                {...iconButtonProps}
              />
              <DownloadButton
                contentId={contentId}
                contentType={AlphaNowProductType.customerPrimer}
                downloadFileName={downloadFileName}
                {...iconButtonProps}
              />
              <CommissionPrimerButton onClick={onOpen} variant="secondary" />
              <IconButton
                onClick={() => setIsTitleVisible((prevState) => !prevState)}
                variant="basic"
                size="medium"
                color={icon.secondary}
              >
                <ChevronUp data-testid="hide-title-button" />
              </IconButton>
            </x.div>
          )}
        </x.div>
      )}
      {(showNavigationOnly || isTitleVisible) && (
        <Tabs p={`0 ${inner.base06}`} index={currentTabIndex} onChange={handleNavigationClick}>
          {tabs.map((tab) => (
            <TabItem key={tab} label={tab} />
          ))}
        </Tabs>
      )}
      {isVisible && <CommissionModal productType={productType} onClose={onClose} />}
    </x.div>
  );
};

export default PrimersHeaderActionBar;
