export interface ExpertCompareQuestion {
  id: number;
  text: string;
  angle: ExpertCompareQuestionAngle;
  responses: ExpertCompareResponse[];
  type: QuestionType;
}

export interface ExpertCompareQuestionAngle {
  id: string;
  isExpertsCapExceeded: boolean;
}

export interface ExpertCompareResponse {
  text: string;
  expertId: string;
}

export enum QuestionType {
  Explicit = "EXPLICIT",
  Ai = "AI",
  Client = "CLIENT",
}

export const NOT_YET_ANSWERED = "Not yet answered.";
