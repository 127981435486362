import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { x } from "@xstyled/styled-components";
import { Modal, Typography, useThemeTokens, useAlphaToast, Divider } from "@alphasights/alphadesign-components";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { AlphaNowSRMProductType, useTrackUserAction } from "@alphasights/client-portal-shared";

import { contentService } from "services/content";
import { unsuccessfulRequest } from "content/AlphaNow";
import { useMarketPrimersStore } from "../../MarketPrimer/state/store";
import CommissionModalContent from "./CommissionModalContent/CommissionModalContent";
import CommissionPrimerModalHeader from "./CommissionModalHeader";
import CommissionModalSearchRow from "./CommissionModalSearch/CommissionModalSearchRow";
import CaseCodeInfoContent from "./CommissionModalContent/CaseCodeInfoContent";
import TotalCredits from "./CommissionModalContent/TotalCredits";
import { useCompanyPrimersStore } from "../../CompanyPrimer/state/store";
import { useCustomerPrimersStore } from "../../CustomerPrimer/state/store";
import {
  createRequestFormEntry,
  getCreditsValue,
  mapCompanyPageInitialForm,
  mapCompanyPrimerInitialForm,
  mapCustomerPrimerInitialForm,
  mapMarketPrimerInitialForm,
} from "./mappers";
import CommissionModalFooter from "../CommissionFooter/CommissionModalFooter";
import { COMMISSION_URL_PARAM, SUCCESS_COMMISSION } from "../constants";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { RequestForm, ModalPrimerRow } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";

import styles from "./styles/commissionPrimerModal.module.css";

export const testIds = {
  commissionContent: "commission-content",
  recommendedCommissionContent: "recommended-commission-content",
};

export type CommisionModalProps = {
  onClose: () => void;
  onPrimerRequested?: () => void;
  productType?: AlphaNowSRMProductType;
  origin?: HitOrigin;
};

const CommissionModal = ({
  onClose,
  onPrimerRequested,
  productType,
  origin = HitOrigin.alphaNow,
}: CommisionModalProps) => {
  const {
    color: { text, background },
    spacing: { inner },
  } = useThemeTokens();
  const history = useHistory();
  const { toast } = useAlphaToast();
  const { logHit } = useTrackUserAction();
  const { competitorDynamics } = useMarketPrimersStore();
  const companyName = useCompanyPrimersStore(({ companyName }) => companyName);
  const companyLogo = useCompanyPrimersStore(({ companyLogo }) => companyLogo);
  const companyId = useCompanyPrimersStore(({ companyId }) => companyId);
  const {
    overview: { featuredVendors },
  } = useCustomerPrimersStore();
  const { query, updateQuery } = useAppSearchContext();

  const initialFormState = {
    requestedPrimers: [],
    caseCode: "",
  };

  const [requestForm, setRequestForm] = useState<RequestForm>(initialFormState);
  const [isEditing, setIsEditing] = useState(false);

  const hasRecommendations = requestForm.requestedPrimers.some((primer) => primer.isRecommended);
  const totalCredits = requestForm.requestedPrimers.reduce((sum, { credits, isRecommended }) => {
    if (!isRecommended) return sum + credits;
    return sum;
  }, 0);
  const isCompanyPage = origin === HitOrigin.companyPage;

  const { mutate: sendPrimerRequest } = useMutation(
    () =>
      contentService.postPrimerRequest({
        ...requestForm,
        requestedPrimers: requestForm.requestedPrimers
          .filter((primer) => !primer.isRecommended)
          .map((primer) => {
            const { requestedCompanyName, requestedCompanyFormat, requestedCompanyType, rush } = primer;

            return {
              requestedCompanyName,
              requestedCompanyFormat,
              requestedCompanyType,
              rush,
            };
          }),
      }),
    {
      onError: () => toast.error({ message: unsuccessfulRequest }),
      onSuccess: () => {
        toast.success({
          message: SUCCESS_COMMISSION,
        });
        onHandleCloseModal();
        onPrimerRequested?.();
      },
    }
  );

  const onConfirm = () => {
    sendPrimerRequest();
    logHit({
      origin,
      action: HitAction.alphaNowRequestPrimer,
      details: {
        commission: requestForm.requestedPrimers
          .filter((primer) => !primer.isRecommended)
          .map((primer) => {
            const {
              requestedCompanyName,
              requestedCompanyFormat,
              requestedCompanyType,
              requestedCompanyId,
              rush,
            } = primer;

            return {
              requestedCompanyId,
              requestedCompanyName,
              requestedCompanyFormat,
              requestedCompanyType,
              rush,
            };
          }),
      },
    });
  };

  const addRow = (formRow: ModalPrimerRow) => {
    const {
      requestedCompanyId: companyId,
      requestedCompanyName: companyName,
      requestedCompanyFormat: format,
      requestedCompanyType: type,
      logoLinks,
      rush,
      credits,
    } = formRow;

    setRequestForm((prevRequestForm) => {
      prevRequestForm.requestedPrimers.push(
        createRequestFormEntry({
          companyId,
          companyName,
          type,
          format,
          logoLinks,
          credits,
          rush,
          isRecommended: false,
        })
      );

      return prevRequestForm;
    });
  };

  const deleteRow = (index: number) => {
    setRequestForm((prevRequestForm) => ({
      ...prevRequestForm,
      requestedPrimers: prevRequestForm.requestedPrimers.filter((_, i) => i !== index),
    }));
  };

  const onChangeCaseCode = (e: React.FormEvent<HTMLInputElement>) => {
    setRequestForm((prevRequestForm) => {
      prevRequestForm.caseCode = e.currentTarget.value;

      return { ...prevRequestForm };
    });
  };

  const onChangePrimerType = (value: any, index: number) => {
    setRequestForm((prevRequestForm) => {
      const primer = prevRequestForm.requestedPrimers[index];
      const companyFormat = primer.requestedCompanyFormat;

      primer.requestedCompanyType = value;

      primer.credits = getCreditsValue(companyFormat, value);

      return { ...prevRequestForm };
    });
  };
  const onChangePrimerRush = (value: any, index: number) => {
    setRequestForm((prevRequestForm) => {
      const primer = prevRequestForm.requestedPrimers[index];
      const companyFormat = primer.requestedCompanyFormat;
      const companyType = primer.requestedCompanyType;

      primer.rush = !!value;
      primer.credits = getCreditsValue(companyFormat, companyType, !!value);

      return { ...prevRequestForm };
    });
  };

  const onAddRecommendedPrimer = (value: any, index: number) => {
    setRequestForm((prevRequestForm) => {
      const primer = prevRequestForm.requestedPrimers[index];

      primer.isRecommended = value;

      return { ...prevRequestForm };
    });
  };

  const onHandleCloseModal = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    searchParams.set(COMMISSION_URL_PARAM, "false");
    history.push(currentUrl.pathname + "?" + searchParams.toString());

    if (origin !== HitOrigin.myAlphasightsPage) updateQuery({ ...query, commissionPrimers: false });

    onClose();
  };
  const formMapping = {
    [AlphaNowSRMProductType.companyPrimer]: () =>
      mapCompanyPrimerInitialForm({ requestForm, setRequestForm, companyLogo, companyName, companyId }),
    [AlphaNowSRMProductType.marketPrimer]: () =>
      mapMarketPrimerInitialForm({ requestForm, competitorDynamics, setRequestForm }),
    [AlphaNowSRMProductType.customerPrimer]: () =>
      mapCustomerPrimerInitialForm({ requestForm, setRequestForm, featuredVendors }),
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(COMMISSION_URL_PARAM, "true");
    history.push(currentUrl.pathname + currentUrl.search);

    if (origin !== HitOrigin.myAlphasightsPage) updateQuery({ ...query, commissionPrimers: true });

    if (isCompanyPage) {
      mapCompanyPageInitialForm({
        productType,
        requestForm,
        setRequestForm,
        companyLogo,
        companyName,
        companyId,
      });
    } else if (productType) {
      formMapping[productType]?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType, origin]);

  useEffect(() => {
    const { requestedPrimers } = requestForm;
    const isFormValid = requestedPrimers.some(({ requestedCompanyName, requestedCompanyFormat }) =>
      Boolean(requestedCompanyName && requestedCompanyFormat)
    );

    setIsEditing(!isFormValid);
  }, [requestForm]);

  const renderPrimerRows = () => {
    const recommendedPrimers: any = [];
    const nonRecommendedPrimers: any = [];

    // eslint-disable-next-line array-callback-return
    requestForm.requestedPrimers.map((primer, index) => {
      const {
        requestedCompanyId,
        requestedCompanyName,
        requestedCompanyFormat,
        requestedCompanyType,
        logoLinks,
        rush,
        credits,
        isRecommended,
      } = primer;

      const modalProps = {
        onChangePrimerType,
        logoLinks,
        credits,
        index,
        format: requestedCompanyFormat,
        companyId: requestedCompanyId,
        companyName: requestedCompanyName,
        rush,
        deleteRow: () => deleteRow(index),
        primerType: requestedCompanyType,
        onChangePrimerRush,
        isRecommended,
      };

      if (isRecommended) {
        recommendedPrimers.push(
          <x.div backgroundColor={background.neutral.subtle}>
            <CommissionModalContent
              dataTestId={testIds.recommendedCommissionContent}
              onAddRecommendedPrimer={onAddRecommendedPrimer}
              {...modalProps}
            />
          </x.div>
        );
      } else {
        nonRecommendedPrimers.push(<CommissionModalContent dataTestId={testIds.commissionContent} {...modalProps} />);
      }
    });

    return [nonRecommendedPrimers, recommendedPrimers];
  };

  const [nonRecommendedPrimers, recommendedPrimers] = renderPrimerRows();

  return (
    <Modal
      className={styles.modal}
      title={
        <Typography variant="h3" color={text.strong._} style={{ textTransform: "none" }}>
          Commission Primers
        </Typography>
      }
      open
      onClose={onHandleCloseModal}
      slotWidth="736px"
      slotHeight="438px"
      variant="complex"
      zIndex={10}
      paddingTop={inner.base06}
    >
      <x.div display="flex" flexDirection="column" justifyContent="space-between" w="100%">
        <x.div flex="1 1 auto" overflowY="auto" overflowX="hidden">
          <CommissionPrimerModalHeader />
          <Divider />
          <div>
            {nonRecommendedPrimers}
            <x.div backgroundColor={background.neutral.subtle}>
              <CommissionModalSearchRow addRow={addRow} isEditing={isEditing} setIsEditing={setIsEditing} />
            </x.div>
            {hasRecommendations && (
              <>
                <Divider />
                <x.div mx={inner.base06} my={inner.base05}>
                  <Typography variant="body-small-em">Recommended</Typography>
                </x.div>
              </>
            )}
            <Divider />
            {hasRecommendations && recommendedPrimers}
            <TotalCredits totalCredits={totalCredits} />
            <Divider />
            <CaseCodeInfoContent onChange={onChangeCaseCode} requestForm={requestForm} />
          </div>
        </x.div>
        <x.div flex="0 0 auto">
          <CommissionModalFooter
            onConfirm={onConfirm}
            isEditing={isEditing}
            onClose={onHandleCloseModal}
            totalCredits={totalCredits}
          />
        </x.div>
      </x.div>
    </Modal>
  );
};

export default CommissionModal;
