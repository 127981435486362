import { Typography } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-self: center;
`;
export { StyledTypography };
