import React from "react";

export const CommentSkeleton = () => {
  return (
    <svg width="208" height="101" viewBox="0 0 208 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_352_13100)">
        <rect x="5" y="4" width="198" height="91" rx="4" fill="white" />
      </g>
      <circle cx="33" cy="32" r="12" fill="#E7E8EA" />
      <rect x="53" y="24" width="47" height="4" rx="2" fill="#E7E8EA" />
      <rect x="53" y="40" width="87" height="4" rx="2" fill="#E7E8EA" />
      <rect x="21" y="55" width="166" height="8" rx="4" fill="#E7E8EA" />
      <rect x="21" y="71" width="37" height="8" rx="4" fill="#E7E8EA" />
      <defs>
        <filter
          id="filter0_d_352_13100"
          x="0"
          y="0"
          width="208"
          height="101"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_13100" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_13100" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
