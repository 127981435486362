import React, { useContext } from "react";
import { Message } from "models/AlphaGpt";

export const AlphaGPTMessageContext = React.createContext<undefined | Message>(undefined);

export const AlphaGPTMessageProvider = ({ message, children }: { message: Message; children: JSX.Element }) => (
  <AlphaGPTMessageContext.Provider value={message} children={children} />
);

export const useAlphaGPTMessageContext = () => {
  const context = useContext(AlphaGPTMessageContext);

  if (!context) throw new Error("AlphaGPTMessageContext should only be used within the AlphaGPTMessageProvider");

  return context;
};
