import React, { FC, ReactElement, useCallback } from "react";
import { Icon, Link, Tooltip, TooltipContent, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { MoreInfo } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { entries, sortBy } from "lodash/fp";

import {
  CompanyPrimerCompetitorCard,
  CompanyPrimerSectionCard,
} from "pages/AlphaNowPage/primers/CompanyPrimer/components";
import CompetitorDynamicsTableHeaderItem, { GroupingType } from "./CompetitorDynamicsHeader";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const SECTION_TITLE = "Competitors";

const createHeaderItems = (groupingType?: string) => [
  {
    label: "RANK",
    col: 1 / 10,
    maxWidth: "72px",
  },
  {
    label: "COMPETITOR",
    col: 1 / 6,
    minWidth: 185,
    maxWidth: 185,
  },
  {
    label: "MARKET SHARE",
    col: 1 / 6,
    maxWidth: 128,
  },
  {
    label: groupingType || "GROUP",
  },
];

interface CompetitorDynamicsMoreInfoItemProps {
  groupingType: string;
  textDescription: string;
}

const CompetitorDynamicsMoreInfoItem: FC<CompetitorDynamicsMoreInfoItemProps> = ({ groupingType, textDescription }) => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return (
    <Typography component="p" variant={isMobile ? "body-large" : "body"} mr={spacing.inner.base}>
      <Typography variant={isMobile ? "body-large-em" : "body-em"} mr={spacing.inner.base}>
        {`${groupingType}:`}
      </Typography>{" "}
      {textDescription}
    </Typography>
  );
};

interface CompetitorDynamicsProps {
  groupingType: CitableValue<string>;
  competitors: CitableCompetitor[];
  primerCompanyNameId: CitableValue<number>;
  companyName?: CitableValue<string>;
  companyNameIdToCDSCompanyNamesMap: Record<number, CDSCompanyNames>;
}

const CompetitorDynamics: FC<CompetitorDynamicsProps> = ({
  groupingType,
  competitors = [],
  primerCompanyNameId,
  companyName,
  companyNameIdToCDSCompanyNamesMap,
}): ReactElement | null => {
  const { spacing, color, shape } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const competitorList = sortBy<CitableCompetitor>("rank.value")(competitors);

  const descriptionByGroupingType = {
    Rank:
      "The ranking is based on market share value, where competitors do not operate in the same market this is only indicative.",
    Competitor:
      "Name of the competitor. If the entity or brand is part of a larger company, the larger company is designated in brackets.",
    "Market share": "Directional indication of the market share trend (within its market).",
    "Relevant grouping":
      "The last column categorizes the competitors into groups, with the most relevant grouping being chosen for each primer and displayed as the column header (e.g. Geography, Main verticals, Business model).",
  };

  const doesRelevantGroupDataExist = competitorList.some(
    (competitor) => competitor.hasOwnProperty("competitorGroup") && !!competitor.competitorGroup.value
  );

  const headerItems = useCallback(() => {
    const items = createHeaderItems(GroupingType[groupingType.value as keyof typeof GroupingType]);
    const headerElements = items
      .map((item, index) => {
        const isCustomGroup = index === items.length - 1;
        if (!isCustomGroup || (!!isCustomGroup && doesRelevantGroupDataExist)) {
          return <CompetitorDynamicsTableHeaderItem {...item} />;
        }
        return null;
      })
      .filter((element) => element !== null);

    return headerElements;
  }, [groupingType, doesRelevantGroupDataExist]);

  const Info = () => (
    <Tooltip
      position="bottom"
      // @ts-ignore
      borderRadius={shape.border.radius.medium}
      minW={isMobile ? "fit-content" : 480}
      maxW={isMobile ? "fit-content" : 480}
      left={{ xs: spacing.inner.base06, sm: spacing.layout.base15 }}
      color={{ xs: color.text.strong._, sm: color.text.inverse }}
      backgroundColor={{
        xs: color.background.surface.overlay,
        sm: color.background.dark,
      }}
    >
      <TooltipContent>
        <x.div spaceY="24px">
          {entries(descriptionByGroupingType).map(([groupingType, textDescription]) => (
            <Typography variant={isMobile ? "body-large" : "body"} key={groupingType}>
              <CompetitorDynamicsMoreInfoItem {...{ groupingType, textDescription }} />
            </Typography>
          ))}
        </x.div>
      </TooltipContent>
      <Link onClick={(event) => event.stopPropagation()}>
        <x.span
          color={{
            _: color.text.secondary,
            hover: color.text.danger,
          }}
        >
          <Icon size={isMobile ? "medium" : "small"}>
            <MoreInfo width={spacing.inner.base04} height={spacing.inner.base04} />
          </Icon>
        </x.span>
      </Link>
    </Tooltip>
  );

  return (
    <CompanyPrimerSectionCard title={SECTION_TITLE} info={isMobile ? <Info /> : undefined}>
      {!isMobile && (
        <x.div display="flex" row mb={spacing.inner.base} px={spacing.inner.base06} alignItems="center">
          {!isMobile && (
            <x.div ml={`-${spacing.inner.base06}`} mt={spacing.inner.base02} mr="30px" position="relative">
              <Info />
            </x.div>
          )}
          {headerItems()}
        </x.div>
      )}
      <x.div
        spaceY={{ xs: 0, sm: spacing.inner.base03 }}
        borderTop={{
          xs: {
            first: `${shape.border.width.sm} solid ${color.border.divider}`,
          },
          sm: { first: 0 },
        }}
      >
        {competitorList.map((competitor) => (
          <CompanyPrimerCompetitorCard
            key={competitor.id.value}
            {...{
              competitor,
              primerCompanyNameId,
              companyName,
              companyNameIdToCDSCompanyNamesMap,
            }}
            {...(isMobile ? { grouping: { groupingType, doesRelevantGroupDataExist } } : {})}
          />
        ))}
      </x.div>
    </CompanyPrimerSectionCard>
  );
};

export default CompetitorDynamics;
