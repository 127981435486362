/**
 * A way to produce Promise objects and to complete them later with a value or error.
 */
class Completer<T> {
  private _promise: Promise<T>;
  private _resolve: (value: T | PromiseLike<T>) => void = () => void 0;
  private _reject: (value: T | PromiseLike<T>) => void = () => void 0;
  private _isComplete = false;

  public constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    }).finally(() => (this._isComplete = true));
  }

  public complete(value: T) {
    this._resolve(value);
  }

  public completeError(error: T) {
    this._reject(error);
  }

  public get isComplete() {
    return this._isComplete || false;
  }

  public get promise() {
    return this._promise;
  }
}

export { Completer };
