import { sortBy } from "lodash";

type Transform<T, K> = (element: T) => K;

interface SortByOrderOfParams<Input, Reference> {
  /** The collection to operate on */
  collection: Input[];

  /** Another reference collection to order based off of */
  orderedBy: Reference[];

  /**
   * A {@link Transform} from the {@link Input} object to a {@link Reference}
   * that can be used to compare with {@link orderedBy}
   */
  compareBy: Transform<Input, Reference>;
}

/**
 * Returns a new copy of {@link collection} {@link orderedBy} the collection provided.
 *
 * {@link compareBy} can be provided to run a transformation on each element of {@link collection}
 * before doing the comparison.
 *
 * If a value is not within {@link orderedBy}, it will float to the top of the list (index = -1).
 */
const sortByOrderOf = <T, K>({ collection, orderedBy, compareBy }: SortByOrderOfParams<T, K>) =>
  sortBy(collection, (element) => orderedBy.indexOf(compareBy(element)));

export { sortByOrderOf };
