import styled from "styled-components";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";

const StyledIcon = styled(Icon)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.secondary,
  };
});

const CompanyPrimerLogoContainer = styled.img(() => {
  const { spacing } = useThemeTokens();
  return {
    maxWidth: spacing.inner.base10,
    maxHeight: spacing.inner.base10,
  };
});

const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding: 2px;
  height: 100%;
  grid-auto-rows: 1fr;
  width: 100%;
  text-align: center;
  align-items: center;
`;

const StyledLogo = styled.div(
  ({ logoSrc, title }: { logoSrc: string; title: string }) => `
  background-image: url(${logoSrc});
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  title: ${`${title ?? "company"} logo`};
`
);

export { StyledIcon, CompanyPrimerLogoContainer, LogoContainer, StyledLogo };
