import React from "react";
import { useThemeTokens, Divider } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SectionTitle, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { useCompanyPrimerV3Store } from "../../store/store";
import SectionWrapper from "../styled";
import CommercialModelSection from "./CommercialModelSection";

const CommercialModel = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const {
    goToMarket,
    monetization: { contracts, profitability },
  } = useCompanyPrimerV3Store(({ commercialModel }) => commercialModel);

  return (
    <SectionWrapper ref={ref}>
      <x.div display="flex" flexDirection="column" gap={inner.base06}>
        <SectionTitle
          heading
          dataTestId="commercial-model-section-title"
          text="Commercial Model"
          xtraProps={{ paddingLeft: inner.base10, textTransform: "none" }}
        />
        <Divider />
        <CommercialModelSection label="Go-to-market" data={goToMarket} heading isGoToMarket />
        <Divider />
        <x.div display="flex" flexDirection="column" gap={inner.base06}>
          <PrimerFieldTitle text="Monetization" xtraProps={{ px: inner.base10 }} variant="body-large-em" />
          <CommercialModelSection label="Profitability" data={profitability} />
          <CommercialModelSection label="Contracts" data={contracts} />
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(CommercialModel));
