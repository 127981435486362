import { useReducer } from "react";
import { withThunk } from "../../withThunk";
import { FlyoutMode } from "./enums";

export const useAdvisorFlyoutReducer = (initialState) => withThunk(useReducer(reducer, initialState, init));

const reducer = (state, action) => {
  switch (action.type) {
    case "showAllAvailability":
      return {
        ...state,
        availabilityState: "show_all_availability",
      };
    case "requestRescheduleInteraction":
      return {
        ...state,
        availabilityState: "reschedule_interaction",
      };
    case "reschedulingRequested":
    case "cancelRescheduleInteraction":
    case "retractCancelRequestInteraction":
    case "retractRequestRescheduleInteraction":
      return {
        ...state,
        availabilityState: "scheduled_interaction",
      };
    case "requestMoreAvailability":
    case "clientAvailabilitySaved":
    case "cancelProvideAvailability":
      return {
        ...state,
        availabilityState: "request_more_availability",
      };
    case "provideAvailability":
      return {
        ...state,
        availabilityState: "provide_availability",
      };
    case "setState":
      return {
        ...state,
        availabilityState: action.data.availabilityState,
      };
    default:
      throw new Error("Action must be one of the defined ACTION_TYPES.");
  }
};

export const setAvailabilityState = ({
  currentAvailabilityState,
  hasMutualAvailability,
  interactionState,
  advisorAvailability,
  flyoutMode,
  pendingAvailabilityRequest,
}) => {
  let availabilityState;
  if (currentAvailabilityState === "provide_availability") {
    availabilityState = "provide_availability";
  } else if (flyoutMode === FlyoutMode.RequestReschedule) {
    availabilityState = "reschedule_interaction";
  } else if (flyoutMode === FlyoutMode.ProvideAvailability) {
    availabilityState = "provide_availability";
  } else if (interactionState === "scheduled") {
    availabilityState = "scheduled_interaction";
  } else if ((interactionState === "requested" && !advisorAvailability?.length) || pendingAvailabilityRequest) {
    availabilityState = "request_more_availability";
  } else if (!hasMutualAvailability) {
    availabilityState = "show_all_availability";
  } else {
    availabilityState = "show_mutual_availability";
  }

  return {
    type: "setState",
    data: { availabilityState },
  };
};

export const requestMoreAvailability = () => {
  return {
    type: "requestMoreAvailability",
  };
};

export const cancelRescheduleInteraction = () => {
  return {
    type: "cancelRescheduleInteraction",
  };
};

export const showAllAvailability = () => {
  return {
    type: "showAllAvailability",
  };
};

export const reschedulingRequested = () => {
  return {
    type: "reschedulingRequested",
  };
};

export const retractCancelRequestInteraction = () => {
  return {
    type: "retractCancelRequestInteraction",
  };
};

export const requestRescheduleInteraction = () => {
  return {
    type: "requestRescheduleInteraction",
  };
};

export const retractRequestRescheduleInteraction = () => {
  return {
    type: "retractRequestRescheduleInteraction",
  };
};

export const cancelProvideAvailability = () => {
  return {
    type: "cancelProvideAvailability",
  };
};

export const clientAvailabilitySaved = () => {
  return {
    type: "clientAvailabilitySaved",
  };
};

export const provideAvailability = () => {
  return {
    type: "provideAvailability",
  };
};

export const init = (props) => ({
  availabilityState: "show_mutual_availability",
  ...props,
});
