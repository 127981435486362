import { SynthesisAnswer, SynthesisModule, SynthesisOverview, SynthesisQuote } from "@alphasights/portal-api-client";
import { useCallback, useEffect, useRef, useState } from "react";

export interface EditSynthesisModuleOperations {
  updateQuestion: (newQuestion: string, revisionIdx: number) => void;
  updateOverview: (newOverview: SynthesisOverview, revisionIdx: number) => void;
  deleteOverview: (deletedOverview: SynthesisOverview, revisionIdx: number) => void;
  updateQuote: (newQuote: SynthesisQuote, revisionIdx: number) => void;
  deleteQuote: (deletedQuote: SynthesisQuote, revisionIdx: number) => void;
  updateAnswer: (newAnswer: SynthesisAnswer, revisionIdx: number) => void;
  deleteAnswer: (deletedAnswer: SynthesisAnswer, revisionIdx: number) => void;
}

export const useEditSynthesisModule = (
  module?: SynthesisModule
): {
  editedModule: SynthesisModule | undefined;
  editOperations: EditSynthesisModuleOperations;
  cancelChanges: () => void;
  undo: () => void;
  redo: () => void;
  clearUndoRedo: () => void;
} => {
  const [editedModule, setEditedModule] = useState(module);
  const undoStack = useRef<SynthesisModule[]>([]);
  const redoStack = useRef<SynthesisModule[]>([]);

  useEffect(() => {
    setEditedModule(module);
  }, [module]);

  const updateStacksAndReturn = useCallback((prevModule: SynthesisModule, newModule: SynthesisModule) => {
    undoStack.current.push(prevModule);
    redoStack.current = [];
    return newModule;
  }, []);

  const updateQuestion = useCallback(
    (newQuestion: string, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    question: newQuestion,
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateOverview = useCallback(
    (newOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    overview: revision.overview.map((overview) =>
                      overview.id === newOverview.id ? newOverview : overview
                    ),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteOverview = useCallback(
    (deletedOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    overview: revision.overview.filter((overview) => overview.id !== deletedOverview.id),
                    answers: revision.answers.map((answer) => ({
                      ...answer,
                      quotes: answer.quotes.filter((quote) => quote.topic !== deletedOverview.title),
                    })),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateQuote = useCallback(
    (newQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    answers: revision.answers.map((answer) => ({
                      ...answer,
                      quotes: answer.quotes.map((quote) => (quote.id === newQuote.id ? newQuote : quote)),
                    })),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteQuote = useCallback(
    (deletedQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    answers: revision.answers.map((answer) => ({
                      ...answer,
                      quotes: answer.quotes.filter((quote) => quote.id !== deletedQuote.id),
                    })),
                    overview: revision.overview.map((overview) =>
                      overview.title === deletedQuote.topic
                        ? {
                            ...overview,
                            expertCount: overview.expertCount - 1,
                          }
                        : overview
                    ),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateAnswer = useCallback(
    (newAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    answers: revision.answers.map((answer) => (answer.id === newAnswer.id ? newAnswer : answer)),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteAnswer = useCallback(
    (deletedAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            questionRevisions: prevModule.questionRevisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    expertCount: revision.expertCount ? revision.expertCount - 1 : 0,
                    answers: revision.answers.filter((answer) => answer.id !== deletedAnswer.id),
                    overview: revision.overview.map((overview) =>
                      deletedAnswer.quotes.map((q) => q.topic).includes(overview.title)
                        ? {
                            ...overview,
                            expertCount: overview.expertCount - 1,
                          }
                        : overview
                    ),
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const clearUndoRedo = useCallback(() => {
    undoStack.current = [];
    redoStack.current = [];
  }, []);

  const cancelChanges = useCallback(() => {
    setEditedModule(module);
    undoStack.current = [];
    redoStack.current = [];
  }, [module]);

  const undo = useCallback(() => {
    if (undoStack.current.length === 0) return;
    editedModule && redoStack.current.push(editedModule);
    setEditedModule(undoStack.current.pop());
  }, [editedModule]);

  const redo = useCallback(() => {
    if (redoStack.current.length === 0) return;
    editedModule && undoStack.current.push(editedModule);
    setEditedModule(redoStack.current.pop());
  }, [editedModule]);

  const handleUndoRedoShortcut = useCallback(
    (event: KeyboardEvent) => {
      const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;
      const isMac = platform.toLowerCase().indexOf("mac") >= 0;
      const ctrlKey = isMac ? event.metaKey : event.ctrlKey;

      if (ctrlKey === true && event.shiftKey === false && event.key === "z") {
        event.preventDefault();
        undo();
      }
      if (
        (ctrlKey === true && event.shiftKey === true && event.key === "z") ||
        (ctrlKey === true && event.key === "y")
      ) {
        event.preventDefault();
        redo();
      }
    },
    [redo, undo]
  );

  useEffect(() => {
    const targetNode = document;

    targetNode && targetNode.addEventListener("keydown", handleUndoRedoShortcut as EventListener);

    return () => targetNode && targetNode.removeEventListener("keydown", handleUndoRedoShortcut as EventListener);
  }, [handleUndoRedoShortcut]);

  const editOperations = {
    updateQuestion,
    updateOverview,
    deleteOverview,
    updateQuote,
    deleteQuote,
    updateAnswer,
    deleteAnswer,
  };

  return {
    editedModule,
    editOperations,
    cancelChanges,
    undo,
    redo,
    clearUndoRedo,
  };
};
