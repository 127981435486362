import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Alert,
  Icon,
  IconButton,
  Pill,
  Skeleton,
  TextArea,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { ArrowRight, ChevronLeft, ChevronRight, Company, Copy, Delete, Expert } from "@alphasights/alphadesign-icons";
import { useStyles } from "./SynthesisModuleContent.styles";
import {
  HitAction,
  SynthesisAnswer,
  SynthesisModule,
  SynthesisOverview,
  SynthesisQuestion,
  SynthesisQuote,
} from "@alphasights/portal-api-client";
import { copyTextToClipboard, FloatingActions } from "components/FloatingActions";
import { useHistory } from "react-router-dom";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import _ from "lodash";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { NewSynthesisModuleContent } from "./NewSynthesisModuleContent";
import { useHideDeliverablesContent } from "views/DeliverablesView/NewDeliverablesPage/useHideDeliverablesContent";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { removeSurroundQuotes } from "../../../utils/quotes";

export const SynthesisModuleContent = () => {
  const { selectedModule, revision, selectedRevisionIdx, mode, setMode } = useProjectSynthesisContext();
  const { moduleWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });
  const { contentStyle } = useHideDeliverablesContent();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedModuleId = useMemo(() => selectedModule?.id, [selectedModule]);

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("synthesisPosition") ?? "0");
    sessionStorage.removeItem("synthesisPosition");

    wrapperRef.current?.scrollTo(0, position);
  }, [selectedModuleId, selectedRevisionIdx]);

  if (!revision || !selectedModule) return <x.div {...moduleWrapper} data-testid="empty-module-content"></x.div>;

  return (
    <x.div
      ref={wrapperRef}
      {...moduleWrapper}
      {...contentStyle}
      data-testid="synthesis-module-content"
      data-synthesis-scrollable-area
    >
      <ModuleContentSwitch revision={revision} mode={mode} setMode={setMode} selectedModule={selectedModule} />
    </x.div>
  );
};

const ModuleContentSwitch = ({
  revision,
  mode,
  setMode,
  selectedModule,
}: {
  revision: SynthesisQuestion;
  mode: Mode;
  setMode: (mode: Mode) => void;
  selectedModule: SynthesisModule;
}) => {
  if (mode === Mode.NEW) return <NewSynthesisModuleContent />;

  if (revision.status === "FAILED")
    return <FailedModule revision={revision} setMode={setMode} module={selectedModule} />;

  if (revision.status === "COMPLETED")
    return (
      <CompleteModule
        revision={revision}
        mode={mode}
        setMode={setMode}
        type={selectedModule.type}
        module={selectedModule}
      />
    );

  return <ProcessingModuleContent revision={revision} />;
};

const FailedModule = ({
  revision,
  setMode,
  module,
}: {
  revision: SynthesisQuestion;
  setMode: (mode: Mode) => void;
  module: SynthesisModule;
}) => {
  const { saveModuleChanges, editOperations, synthesisLogHit, newSynthesisModule } = useProjectSynthesisContext();
  const hasError = useMemo(() => newSynthesisModule.questionHasError(revision.question), [
    newSynthesisModule,
    revision.question,
  ]);

  const submitQuestion = useCallback(
    (question: string) => {
      if (hasError) return;
      saveModuleChanges(
        {
          ...module,
          aiRegenerationEnabled: true,
          questionRevisions: module.questionRevisions.map((rev) =>
            rev.id !== revision.id ? rev : { ...rev, question }
          ),
        },
        { originator: "input-enter-pressed", promptShown: false }
      );
    },
    [hasError, saveModuleChanges, module, revision.id]
  );

  useEffect(() => setMode(Mode.EDIT), [revision, setMode]);

  useEffect(
    function logHitErrorViewed() {
      synthesisLogHit({
        action: HitAction.projectSynthesisModuleFailed,
        details: {
          revision: revision.revision,
          question: revision.question,
        },
        references: {
          moduleId: module.id,
        },
      });
    },
    [revision.revision, module.id, synthesisLogHit] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div px={"24px"} py={"16px"} display={"flex"} flexDirection={"column"} gap={"24px"}>
      <x.div display={"flex"} alignItems={"center"} gap="15px">
        <x.div flexGrow={1}>
          <EditableInputWrapper data-testid="question-failed-edit">
            <EditableInput
              input={revision.question}
              onChange={(e) =>
                editOperations.updateQuestion(e.currentTarget?.value, module.questionRevisions.indexOf(revision))
              }
              onEnterPressed={(e) => submitQuestion(e.currentTarget.value)}
              errorMessage={hasError ? "Input too brief. Please rephrase the question and try again" : undefined}
            />
          </EditableInputWrapper>
        </x.div>
        <RevisionToggler />
      </x.div>
      <Alert w="100%" variant="danger" data-testid="failed-module-banner">
        We were unable to generate insights for this question. Please try rephrasing your question.
      </Alert>
    </x.div>
  );
};

const CompleteModule = ({
  revision,
  mode,
  setMode,
  type,
  module,
}: {
  revision: SynthesisQuestion;
  mode: Mode;
  setMode: (mode: Mode) => void;
  type: string;
  module: SynthesisModule;
}) => {
  const { overviewWrapper, overviewTitle, overviewContent, answersWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });
  const { synthesisLogHit } = useProjectSynthesisContext();

  const failed = useMemo(() => revision.status === "FAILED", [revision]);

  useEffect(() => {
    if (failed) {
      setMode(Mode.EDIT);
    }
  }, [failed, setMode]);

  useEffect(
    function logHitModuleViewed() {
      synthesisLogHit({
        action: HitAction.projectSynthesisModuleViewed,
        details: {
          revision: revision.revision,
          question: revision.question,
          angleType: module.angleType,
        },
        references: {
          moduleId: module.id,
        },
      });
    },
    [revision.revision, module.id, synthesisLogHit] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const maxScrollReached = useRef(0);
  const openedAt = useRef(Date.now());
  useEffect(() => {
    if (mode !== Mode.VIEW) return;
    maxScrollReached.current = 0;
    openedAt.current = Date.now();
    const scrollableArea = document.querySelector("[data-synthesis-scrollable-area]");

    const computeMaxScrollReached = () => {
      if (!scrollableArea) return;
      const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;
      maxScrollReached.current = _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]) ?? 0;
    };

    const logHitDuration = (openedAt: number, maxScrollReached: number, action: HitAction) => {
      const closedAt = Date.now();
      const durationInSeconds = (closedAt - openedAt) / 1000;
      synthesisLogHit({
        action,
        details: {
          revision: revision.revision,
          question: revision.question,
          angleType: module.angleType,
          durationInSeconds,
          maxScrollReached,
        },
        references: {
          moduleId: module.id,
        },
      });
    };

    scrollableArea?.addEventListener("scroll", computeMaxScrollReached);

    window.addEventListener("beforeunload", () =>
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead)
    );

    return () => {
      window.removeEventListener("beforeunload", () =>
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead)
      );
      scrollableArea?.removeEventListener("scroll", computeMaxScrollReached);
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead);
    };
  }, [revision, module, synthesisLogHit, mode]);

  return (
    <x.div data-testid={`revision-${revision.revision}-content`}>
      <x.div {...overviewWrapper}>
        <x.div {...overviewTitle}>
          <Question question={revision.question} />
          {mode === Mode.VIEW && <RevisionToggler />}
        </x.div>

        <x.div {...overviewContent}>
          {revision?.overview?.map((overviewTopic) => (
            <OverviewItem
              key={overviewTopic.id}
              overviewTopic={overviewTopic}
              expertCount={revision.expertCount ?? 0}
            />
          ))}
        </x.div>
      </x.div>

      <x.div {...answersWrapper}>
        {mode === Mode.VIEW && revision.answers?.length === 0 ? (
          <EmptyAnswers />
        ) : (
          <>
            {revision.answers?.map((answer) => (
              <ExpertAnswer key={answer.id} answer={answer} type={type} />
            ))}
          </>
        )}
      </x.div>
    </x.div>
  );
};

const Question = ({ question }: { question: string }) => {
  const { mode, selectedRevisionIdx, editOperations, newSynthesisModule } = useProjectSynthesisContext();
  const user = useCurrentUser();

  const onEditQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
    editOperations.updateQuestion(e.target.value, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT && user?.enableAiInteractivity) {
    return (
      <EditableInputWrapper data-testid="question-edit">
        <EditableInput
          input={question}
          onChange={onEditQuestion}
          errorMessage={
            newSynthesisModule.questionHasError(question)
              ? "Input too brief. Please rephrase the question and try again"
              : undefined
          }
        />
      </EditableInputWrapper>
    );
  }

  return (
    <Typography variant="h3" shouldCapitalizeHeadline={false}>
      {question}
    </Typography>
  );
};

const EditableInputWrapper = styled.div`
  width: 100%;
  textarea {
    font-size: 1.125rem;
    font-weight: 600;
  }
  display: flex;
`;

const OverviewItem = ({ overviewTopic, expertCount }: { overviewTopic: SynthesisOverview; expertCount: number }) => {
  const { spacing } = useThemeTokens();
  const { overviewItem, borderedBox, editHeader } = useStyles();
  const { mode, editOperations, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();

  const onEditOverview = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedOverviewTopic = { ...overviewTopic, summary: e.target.value };
    editOperations.updateOverview(updatedOverviewTopic, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div {...borderedBox} py={spacing.inner.base04} data-testid={`overview-edit-${overviewTopic.id}`}>
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base04}>
            <TopicPill title={overviewTopic.title} />
            <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
          </x.div>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => editOperations.deleteOverview(overviewTopic, selectedRevisionIdx)}
            testId={`delete-overview-${overviewTopic.id}`}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </x.div>
        <EditableInput input={overviewTopic.summary} onChange={onEditOverview} />
      </x.div>
    );
  }

  return (
    <x.div {...overviewItem} key={overviewTopic.id}>
      <x.div>
        {overviewTopic.title && <TopicPill title={overviewTopic.title} />}
        <Typography component="span">{overviewTopic.summary}</Typography>
      </x.div>
      <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
    </x.div>
  );
};

const TopicPill = ({ title }: { title?: string }) => (
  <>
    {title && (
      <Pill size="x-small" mr="4px" isInteractive={false}>
        {title}
      </Pill>
    )}
  </>
);

const ExpertCounter = ({ relevantCount, totalExpertCount }: { relevantCount: number; totalExpertCount: number }) => (
  <Typography color="assistive" component="span" flexShrink="0">
    {(totalExpertCount ?? 0) > 0 ? `${relevantCount}/${totalExpertCount} experts` : "0 experts"}
  </Typography>
);

const RevisionToggler = () => {
  const {
    selectedModule,
    selectedRevisionIdx,
    prevRevision,
    nextRevision,
    synthesisLogHit,
    revision,
  } = useProjectSynthesisContext();

  const onClickPrev = () => {
    synthesisLogHit({
      action: HitAction.projectSynthesisRevisionTogglerClicked,
      details: {
        revision: revision?.revision,
        question: revision?.question,
        direction: "prev",
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    prevRevision();
  };

  const onClickNext = () => {
    synthesisLogHit({
      action: HitAction.projectSynthesisRevisionTogglerClicked,
      details: {
        revision: revision?.revision,
        question: revision?.question,
        direction: "next",
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    nextRevision();
  };

  if (!selectedModule?.questionRevisions || selectedModule.questionRevisions.length <= 1) return null;

  return (
    <Pill
      data-testid="revision-toggler"
      leftAccessories={
        <Tooltip title="Switch to previous question">
          <IconButton size="x-small" onClick={onClickPrev} variant="basic" testId="prev-revision">
            <ChevronLeft />
          </IconButton>
        </Tooltip>
      }
      rightAccessories={
        <Tooltip title="Switch to next question">
          <IconButton size="x-small" onClick={onClickNext} variant="basic" testId="next-revision">
            <ChevronRight />
          </IconButton>
        </Tooltip>
      }
      isInteractive={false}
      variant="outline"
      size="small"
    >
      <x.span w="30px" display="flex" justifyContent="center">
        <Typography component="span" variant="body-small">
          {selectedRevisionIdx + 1}/{selectedModule.questionRevisions.length}
        </Typography>
      </x.span>
    </Pill>
  );
};

const ExpertAnswer = ({ answer, type }: { answer: SynthesisAnswer; type: string }) => {
  const { borderedBox, companyLogoWrapper, answerTitle, answerTitleWrapper } = useStyles();
  const {
    mode,
    editOperations,
    selectedRevisionIdx,
    saveInProgress,
    synthesisLogHit,
    revision,
    selectedModule,
  } = useProjectSynthesisContext();

  const handleCopy = () => {
    answer && copyTextToClipboard(formatSynthesisAnswer(answer));
    synthesisLogHit({
      action: HitAction.projectSynthesisCopy,
      details: {
        revision: revision?.revision,
        question: revision?.question,
        contentCopiedType: "answer",
        copiedAnswer: answer.text,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  const ActionIcon = () =>
    mode === Mode.EDIT ? (
      <IconButton
        size="small"
        variant="ghost"
        onClick={() => editOperations.deleteAnswer(answer, selectedRevisionIdx)}
        testId={`delete-answer-${answer.id}`}
        disabled={saveInProgress}
      >
        <Delete />
      </IconButton>
    ) : type === "CUSTOM" ? (
      <Tooltip title="Copy insight">
        <HideableIconButton size="small" variant="ghost" onClick={() => handleCopy()}>
          <Copy data-testid={`copy-answer-${answer.id}`} />
        </HideableIconButton>
      </Tooltip>
    ) : (
      <></>
    );

  return (
    <x.div id="answer-block" {...borderedBox}>
      <x.div {...answerTitleWrapper}>
        <x.div {...answerTitle}>
          <x.div {...companyLogoWrapper}>
            {answer.expert.companyLogo ? (
              <img src={answer.expert.companyLogo} alt={answer.expert.companyName} height="12px" />
            ) : (
              <Icon>
                <Company />
              </Icon>
            )}
          </x.div>
          <Typography color="assistive" component="span" variant="body-small">
            {answer.expert.companyName} - {answer.expert.role}
          </Typography>
        </x.div>
        <ActionIcon />
      </x.div>

      <ExpertAnswerText answer={answer} />

      {answer.quotes.map((quote) => (
        <ExpertQuote key={quote.id} quote={quote} />
      ))}
    </x.div>
  );
};

const EmptyAnswers = () => {
  const { emptyAnswersWrapper, emptyIconWrapper } = useStyles();

  return (
    <x.div {...emptyAnswersWrapper} data-testid="empty-answers">
      <x.div {...emptyIconWrapper}>
        <Icon size="small" color="secondary">
          <Expert />
        </Icon>
      </x.div>
      <Typography variant="body-large-em" color="secondary">
        No Experts Insights
      </Typography>
      <Typography color="secondary">Refresh to generate expert insights for this module</Typography>
    </x.div>
  );
};

const ExpertAnswerText = ({ answer }: { answer: SynthesisAnswer }) => {
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onEditAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedAnswer = { ...answer, text: e.target.value };
    editOperations.updateAnswer(updatedAnswer, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div data-testid={`answer-edit-${answer.id}`}>
        <EditableInput input={answer.text} onChange={onEditAnswer} />
      </x.div>
    );
  }

  return (
    <Typography component="div" variant="body-small-spaced">
      {answer.text}
    </Typography>
  );
};

const ExpertQuote = ({ quote }: { quote: SynthesisQuote }) => {
  const history = useHistory();
  const { spacing } = useThemeTokens();
  const { quoteWrapper, borderedBox, editHeader } = useStyles();
  const {
    mode,
    editOperations,
    selectedRevisionIdx,
    saveInProgress,
    synthesisLogHit,
    revision,
    selectedModule,
  } = useProjectSynthesisContext();
  const { project } = useCurrentProjectContext();

  const navTo = useCallback(
    ({
      interactionId,
      transcriptIndex,
      tooltip,
    }: {
      interactionId: string;
      transcriptIndex: number;
      tooltip: string;
    }) => ({
      icon: <ArrowRight />,
      tooltip: tooltip,
      testid: "nav",
      onClick: () => {
        synthesisLogHit({
          action: HitAction.projectSynthesisViewInTranscript,
          details: {
            revision: revision?.revision,
            question: revision?.question,
            quote: quote.text,
          },
          references: {
            moduleId: selectedModule?.id,
          },
        });
        history.push(
          `/${project?.token}/experts/deliverables-view/?transcriptFocus=${transcriptIndex}&f=&selectedInteraction=${interactionId}`
        );
      },
    }),
    [history, project, quote.text, revision, selectedModule, synthesisLogHit]
  );

  const onEditQuote = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedQuote = { ...quote, text: e.target.value };
    editOperations.updateQuote(updatedQuote, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div
        {...borderedBox}
        py={spacing.inner.base03}
        px={spacing.inner.base04}
        data-testid={`quote-edit-${quote.id}`}
      >
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base02}>
            <TopicPill title={quote.topic} />
          </x.div>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => editOperations.deleteQuote(quote, selectedRevisionIdx)}
            testId={`delete-quote-${quote.id}`}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </x.div>
        <EditableInput input={quote.text} onChange={onEditQuote} quoted={true} />
      </x.div>
    );
  }

  return (
    <x.div {...quoteWrapper} key={quote.id}>
      <FloatingActions
        actions={_.compact([
          quote.transcriptIndex &&
            quote.interactionId &&
            navTo({
              interactionId: quote.interactionId,
              transcriptIndex: quote.transcriptIndex,
              tooltip: quote.edited ? "View original quote in transcript" : "View in transcript",
            }),
        ])}
      >
        <x.span data-testid={`quote-${quote.id}`}>
          {quote.topic && <TopicPill title={quote.topic} />}
          <Typography component="span" variant="body-small-spaced" fontStyle="italic">
            "{removeSurroundQuotes(quote.text)}"
          </Typography>
        </x.span>
      </FloatingActions>
      {quote.edited && (
        <Typography component="span" variant="body-small-spaced" color="assistive">
          Edited
        </Typography>
      )}
    </x.div>
  );
};

const EditableInput = ({
  input,
  quoted = false,
  onChange,
  onEnterPressed,
  errorMessage,
}: {
  input: string;
  onChange?: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
  quoted?: boolean;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string;
}) => {
  const { saveInProgress, revision } = useProjectSynthesisContext();
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [content, setContent] = useState(input);
  useEffect(() => setContent(input), [input]);
  useEffect(() => {
    if (revision?.status === "FAILED" && ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div display="flex" flexDirection="column" w="100%">
      <TextArea
        ref={ref}
        value={quoted ? `"${removeSurroundQuotes(content)}"` : content}
        onSubmit={onEnterPressed}
        onChange={(e) => {
          const value = quoted ? removeSurroundQuotes(e.target.value) : e.target.value;
          const target = { ...e.target, value };
          onChange && onChange({ ...e, target });
          setContent(value);
        }}
        isControlled={true}
        width="100%"
        height="unset"
        isDynamic={true}
        disabled={saveInProgress}
        error={!!errorMessage?.trim()}
      />
      {!!errorMessage?.trim() && <Typography color="danger">{errorMessage}</Typography>}
    </x.div>
  );
};

const ProcessingModuleContent = ({ revision }: { revision?: SynthesisQuestion }) => {
  const { borderedBox } = useStyles();

  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        {revision?.question ? <Question question={revision.question} /> : <Skeleton variant="noMargin" width="300px" />}
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>

      <x.div {...borderedBox}>
        <Skeleton variant="noMargin" width="300px" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};

const HideableIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  #answer-block:hover & {
    opacity: 1;
  }
`;

export const formatSynthesisAnswer = (answer: SynthesisAnswer): string => {
  let formattedString = `
  <p style="font-size: 13px;">
    <span style="color: #666B7A;">${answer.expert.companyName}</span> -
    <span style="color: #666B7A;">${answer.expert.role}</span>
  </p>
  <br>
  <p style="font-size: 13px;">${answer.text}</p>
  <br>`;

  answer.quotes.forEach((quotes) => {
    formattedString += `
    `;
    if (quotes.topic) {
      formattedString += `<span style="font-weight: bold; font-size: 13px; ">${quotes.topic}</span>:`;
    }
    formattedString += `<i>"${quotes.text}"</i><br>`;
  });

  return formattedString + `<br>`;
};
