import _ from "lodash";

type AppliedFilters = {
  [key: string]: string[];
};

type filterDefinitionProps = {
  options: {
    groups: FilterOption[];
    alphaNowTypesSet?: string[];
  };
  appliedFilters: AppliedFilters;
  transcriptTypesSet: Set<String>;
  aiSynthesisOnly?: boolean;
};
export const filterDefinitions = ({
  options = { groups: [] },
  appliedFilters = {},
  transcriptTypesSet = new Set(),
  aiSynthesisOnly = false,
}: filterDefinitionProps) => {
  const filterOptions: FilterDefinition[] = [];

  const transcriptsTypes = [...transcriptTypesSet, ...(options.alphaNowTypesSet ?? [])];

  filterOptions.push({
    title: "Date",
    type: "slider",
    name: "scheduled_call_period",
    value: appliedFilters.scheduled_call_period || [],
    options: [
      { value: "today", label: "Today" },
      { value: "yesterday", label: "Yesterday" },
      { value: "this week", label: "This Week" },
      { value: "before this week", label: "Before This Week" },
    ],
  });

  filterOptions.push({
    title: "Bookmarked",
    type: "boolean",
    name: "bookmarked",
    value: appliedFilters.bookmarked || [],
    options: [],
  });

  filterOptions.push({
    title: "Angle",
    name: "groups",
    value: appliedFilters.groups || [],
    options: options.groups,
  });

  filterOptions.push({
    title: "Type",
    name: "transcript_types",
    value: appliedFilters.transcript_types || [],
    options: _.compact([
      !aiSynthesisOnly && { value: "regular", label: "Human Transcript" },
      !aiSynthesisOnly && { value: "summary", label: "Synthesized Transcript" },
      !aiSynthesisOnly && { value: "ai", label: "AI Transcript" },
      !aiSynthesisOnly && { value: "recording", label: "Recording" },
      { value: "primer", label: "Primers" },
      { value: "moderated-content", label: "Moderated Calls" },
      { value: "investor-led-content", label: "Investor-led" },
    ]).filter(({ value }) => (transcriptsTypes.length ? transcriptsTypes.indexOf(value) >= 0 : true)),
  });

  return filterOptions;
};
