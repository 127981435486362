import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMobileComplianceStyles = () => {
  const {
    color: { border: borderColor },
    shape: { border },
    spacing,
  } = useThemeTokens();

  const video = {
    theatre: {
      overflow: "hidden",
      position: "absolute",
      w: "100%",
      h: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: border.radius.medium,
      border: `1px ${borderColor.strong._} solid`,
    },
    thumb: {
      w: "100%",
    },
  };

  return {
    video,
    wrapper: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing.inner.base04,
    },
    icon: {
      width: spacing.layout.base10,
      height: spacing.layout.base10,
    },
  };
};
