import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { color, spacing } = useThemeTokens();
  return {
    headerWrapper: {
      display: "flex",
      h: spacing.layout.base08,
      w: "100%",
      justifyContent: "space-between",
      px: spacing.inner.base06,
      py: spacing.inner.base05,
      bg: color.background.surface.raised,
    },
    leftGroup: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base04,
    },
    rightGroup: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base04,
    },
    separator: {
      borderBottom: `1px solid ${color.border.divider}`,
      borderTop: `1px solid ${color.border.divider}`,
      h: spacing.inner.base,
      bg: color.background.surface.recessed,
    },
  };
};
