import React, { FC, useRef, useEffect, useCallback } from "react";
import ReactHtmlParser from "react-html-parser";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Typography, Button } from "@alphasights/alphadesign-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import useKeywordSearch from "hooks/useKeywordSearch";
import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import {
  errorLoadingComprehensiveSummaryView,
  comprehensiveSummaryViewUnavailableTryAgainContent,
  generatedByAITag,
  viewInTranscriptText,
} from "content/AlphaNow";
import { View } from "models/Header";
import { desktopContentContainerWidth } from "constants/AlphaNow";
import { useAppSearchContext } from "providers/AppSearchProvider";

interface ComprehensiveSummaryViewProps {
  contentId: string;
  comprehensiveSummaryFetched: boolean;
  comprehensiveSummaryViewData: any[];
  selectedIndex: any;
  setViewMode: (value: string) => void;
}

const ComprehensiveSummaryView: FC<ComprehensiveSummaryViewProps> = ({
  contentId,
  comprehensiveSummaryFetched,
  comprehensiveSummaryViewData,
  selectedIndex,
  setViewMode,
}) => {
  const {
    spacing: { inner },
    color: { border, text },
    font,
    shape: {
      border: { width },
    },
  } = useThemeTokens();
  const { updateQuery } = useAppSearchContext();
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);
  const { addRefToKeyword } = useKeywordSearch(selectedIndex);

  const handleClick = useCallback(
    (fragmentId: any) => {
      updateQuery({ fragmentIds: [fragmentId] });
      setViewMode(View.Transcript);

      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowViewQuote,
        details: { contentId, fragmentPosition: fragmentId },
      });
    },
    [updateQuery, setViewMode, contentId, logHit]
  );

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;

    const timeSpentAction = HitAction.alphaNowTimeSpentOnComprehensiveSummary;
    const scrollAction = HitAction.alphaNowComprehensiveSummaryScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenComprehensiveSummaryView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!comprehensiveSummaryFetched) {
    return (
      <ErrorMessage
        header={errorLoadingComprehensiveSummaryView}
        body={comprehensiveSummaryViewUnavailableTryAgainContent}
      />
    );
  }
  return (
    <x.div margin={{ xs: inner.base06, sm: "0 auto" }} maxW={{ xs: "none", sm: desktopContentContainerWidth }}>
      <Typography
        display="flex"
        justifyContent="flex-end"
        color={text.secondary}
        variant="body-small"
        pb={inner.base04}
      >
        {generatedByAITag}
      </Typography>
      <x.div color={text.strong._} className="mentions-container">
        {comprehensiveSummaryViewData.map((data) => {
          const { theme, points, quotes } = data;
          return (
            <>
              <Typography variant="body-em">
                {theme && ReactHtmlParser(theme, { transform: addRefToKeyword })}
              </Typography>
              <x.ul
                listStyleType="disc"
                fontSize={font.size["01"]}
                m={`${inner.base03} 0 ${inner.base02} ${inner.base04}`}
              >
                {points?.map((point: string) => (
                  <li key={point.toString()}>
                    <Typography variant="body" pb={inner.base}>
                      {points &&
                        ReactHtmlParser(point, {
                          transform: addRefToKeyword,
                        })}
                    </Typography>
                  </li>
                ))}
              </x.ul>
              <ul>
                {quotes?.map((data: { quote: any[]; fragmentId: number }) => (
                  <li key={data.quote.toString()}>
                    <Typography
                      borderLeft={`${width.sm} solid`}
                      borderColor={border.strong._}
                      ml={inner.base02}
                      mb={inner.base04}
                      variant="body"
                      fontStyle="italic"
                      pl={inner.base02}
                    >
                      {quotes &&
                        ReactHtmlParser(`"${data.quote}"`, {
                          transform: addRefToKeyword,
                        })}
                    </Typography>
                    <x.div display="flex" justifyContent="flex-end">
                      <Button
                        variant="link"
                        size="small"
                        endIcon={<ChevronRight />}
                        onClick={() => handleClick(data.fragmentId)}
                        mb={inner.base02}
                      >
                        {viewInTranscriptText}
                      </Button>
                    </x.div>
                  </li>
                ))}
              </ul>
            </>
          );
        })}
      </x.div>
    </x.div>
  );
};

export default ComprehensiveSummaryView;
export type { ComprehensiveSummaryViewProps };
