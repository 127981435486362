import { AlphaNowSRMProductType } from "@alphasights/client-portal-shared";

const primerDescription = (format: AlphaNowSRMProductType, numExpertContributors?: number) => {
  const expertContributors = numExpertContributors ? `**${numExpertContributors}**` : "";

  switch (format) {
    case AlphaNowSRMProductType.companyPrimer: {
      return `Synthesized insights on the company from ${expertContributors} former executives, competitors and customers.`;
    }
    case AlphaNowSRMProductType.marketPrimer: {
      return `Synthesized insights on the market from ${expertContributors} industry executives.`;
    }
    case AlphaNowSRMProductType.customerPrimer: {
      return `Synthesized insights on vendors from ${expertContributors} key customer decision makers.`;
    }
  }
};

export { primerDescription };
