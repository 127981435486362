import React from "react";
import { x } from "@xstyled/styled-components";
import { ListOption } from "@alphasights/alphadesign-components";

const ActionBarMenuItem = ({
  label,
  onClick,
  icon,
  dataTestId,
  size = "large",
}: {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
  dataTestId: string;
  size?: string;
}) => (
  <x.div w="100%" onClick={onClick} data-testid={dataTestId}>
    <ListOption leftIcon={icon} type="text" label={label} size={size} />
  </x.div>
);

export default ActionBarMenuItem;
