import styled from "styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const RecommendedContentCardCarouselContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    padding: spacing.inner.base08,
    backgroundColor: color.background.surface.page.default,
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
  };
});

export { RecommendedContentCardCarouselContainer };
