import React, { useReducer, useCallback, useContext } from "react";
import { AlertReducer } from "./AlertReducer";
import { AlertBannerContainer } from "./AlertBannerContainer";
import uuid from "uuid";

export const AlertMessengerContext = React.createContext({});

export const AlertContextProvider = ({ children, autoHideTimeout = 10000 }) => {
  const [state, dispatch] = useReducer(AlertReducer, { messages: [] });

  const addPermanentMessage = useCallback(
    (data) => {
      const id = uuid();
      dispatch({ type: "ADD_MESSAGE", payload: { id, ...data } });
    },
    [dispatch]
  );

  const addMessage = useCallback(
    (data) => {
      const id = uuid();
      dispatch({ type: "ADD_MESSAGE", payload: { id, ...data } });
      setTimeout(() => {
        dispatch({ type: "REMOVE_MESSAGE", payload: { id } });
      }, autoHideTimeout);
    },
    [dispatch, autoHideTimeout]
  );

  const removeMessage = useCallback(
    (id) => {
      dispatch({ type: "REMOVE_MESSAGE", payload: { id } });
    },
    [dispatch]
  );

  return (
    <AlertMessengerContext.Provider value={{ state, addMessage, addPermanentMessage, removeMessage }}>
      {children}
      <AlertBannerContainer messages={state.messages} removeMessage={removeMessage} />
    </AlertMessengerContext.Provider>
  );
};

export const useAlertMessengerContext = () => useContext(AlertMessengerContext);
