import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponseStatusType } from "types";

export const useMessengerQuestionCardStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const card = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    w: "80%",
    p: inner.base04,
  };

  return {
    card,
  };
};

export const useMessengerAdvisorThreadCardStyles = () => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const card = {
    gap: inner.base02,
    w: "80%",
    p: inner.base04,
    display: "flex",
    flexDirection: "column",
  };

  const repliesContainer = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    marginLeft: inner.base06,
  };

  const repliesButton = {
    color: {
      _: text.strong._,
      hover: text.strong.hover,
    },
  };

  return {
    card,
    repliesContainer,
    repliesButton,
  };
};

export const useMessengerMessageStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const rightInfo = {
    display: "flex",
    gap: inner.base03,
    marginLeft: "auto",
    alignItems: "center",
  };

  const senderName = {
    cursor: "pointer",
  };

  return {
    container,
    rightInfo,
    senderName,
  };
};

export const useMessengerAttachmentStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const attachmentContainer = {
    display: "flex",
    alignItems: "center",
    p: inner.base02,
    gap: inner.base02,
  };

  const attachmentsListContainer = {
    display: "flex",
    gap: inner.base02,
    flexWrap: "wrap",
  };

  return {
    attachmentContainer,
    attachmentsListContainer,
  };
};

export const useMessageHeaderStyles = ({ status }: { status?: ResponseStatusType }) => {
  const {
    color: { text },
  } = useThemeTokens();

  const statusColorMap = {
    [ResponseStatusType.AwaitingResponse]: text.processing,
    [ResponseStatusType.AwaitingApproval]: text.processing,
    [ResponseStatusType.Replied]: text.success,
    [ResponseStatusType.Sent]: text.info,
    [ResponseStatusType.Declined]: text.danger,
  } as { [key in ResponseStatusType]: string };

  const senderName = {
    cursor: "pointer",
  };

  const statusLabel = {
    color: (status && statusColorMap[status]) || text.secondary,
  };

  return {
    senderName,
    statusLabel,
  };
};
