import { AlphaNowContentType, AlphaNowProductType, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";
import { panel } from "content/AlphaNow";
import { getContentTitle } from "content/AlphaNow/utils";
import { getNonModeratorSpeakers, hasMultipleSpeakers, parseText } from "pages/AlphaNowPage/utils";
import { RecommendedContent } from "pages/AlphaNowPage/components/RecommendedContentCard";

const getAngleType = (content: RecommendedContent): string => {
  const { contentType, speakers, description } = content;
  if (contentType === AlphaNowContentType.srm) {
    return "";
  }
  if (hasMultipleSpeakers(speakers)) {
    return panel;
  }
  return description;
};

const getCardTitle = (content: RecommendedContent) => {
  const { productType, externalTitle, contentType, companies, displayTitle } = content;
  const contentTitle = getContentTitle(productType, companies, externalTitle);

  switch (contentType) {
    case AlphaNowContentType.srm:
      return `${CONTENT_TYPE_DISPLAY_NAME[productType]}: ${contentTitle}`;
    case AlphaNowContentType.alphaview:
      return contentTitle;
    default:
      return displayTitle;
  }
};

const getInvestorLedContentDescription = (speakers: Speaker[]): string => {
  const [nonModeratorSpeaker] = getNonModeratorSpeakers(speakers);
  return parseText(nonModeratorSpeaker.bio);
};

const getCardDescription = (content: RecommendedContent): string => {
  switch (content.productType) {
    case AlphaNowProductType.alphaview:
      return parseText(content.agenda);

    case AlphaNowProductType.pcc:
      return getInvestorLedContentDescription(content.speakers);

    default:
      return parseText(content.summary ?? "");
  }
};

export { getAngleType, getCardTitle, getCardDescription };
