import React from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";
import { Button, Icon, Typography } from "@alphasights/alphadesign-components";
import { Error, Info, Warning } from "@alphasights/alphadesign-icons";

import { useBannerStyles } from "./Banner.styles";
import { ButtonWithMargin } from "components/Button";

const VARIANT = {
  info: {
    bg: "background-info",
    iconColor: "info",
    icon: <Info />,
  },
  warning: {
    bg: "background-warning",
    iconColor: "warning",
    icon: <Warning />,
  },
  error: {
    bg: "background-danger-default",
    iconColor: "danger",
    icon: <Error />,
  },
};

const Banner = ({ children, onConfirm, onCancel, ...props }) => {
  const { bannerContainer, bannerBody } = useBannerStyles();
  return (
    <x.div {...bannerContainer} bg={props.bg} color={props.color || "neutral900"} {...props}>
      <x.div>
        <Icon size="medium" color={props.iconColor}>
          {props.icon}
        </Icon>
      </x.div>
      <x.div {...bannerBody}>
        <Typography>{children}</Typography>
        {props.confirmation && (
          <x.div>
            <Button size="small" variant="secondary" onClick={onConfirm}>
              Confirm
            </Button>
            <ButtonWithMargin size="small" variant="ghost" onClick={onCancel}>
              Cancel
            </ButtonWithMargin>
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};

Banner.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.element,
  iconColor: PropTypes.string,
  confirmation: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

const ErrorBanner = (props) => <Banner {...VARIANT.error} {...props} />;

const WarningBanner = (props) => <Banner {...VARIANT.warning} {...props} />;

const InfoBanner = (props) => <Banner {...VARIANT.info} {...props} />;

const ConfirmationBanner = ({ children, variant, onConfirm, onCancel }) => (
  <Banner children={children} confirmation={true} {...VARIANT[variant]} onConfirm={onConfirm} onCancel={onCancel} />
);

export { Banner as default, ErrorBanner, WarningBanner, InfoBanner, ConfirmationBanner };
