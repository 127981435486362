import styled from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const CheckboxOptionsWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: isMobile ? spacing.inner.base02 : spacing.inner.base04,
    marginLeft: isMobile ? spacing.inner.base02 : spacing.inner.base,
    marginRight: isMobile ? spacing.inner.base02 : 0,
  };
});

export { CheckboxOptionsWrapper };
