import React, { useEffect, useMemo, useRef, useState } from "react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { SurveysMainContent } from "components/SurveysPage/SurveysMainContent";
import { x } from "@xstyled/styled-components";
import { Button, Typography } from "@alphasights/alphadesign-components";
import useStyles from "./styles";
import { Download } from "@alphasights/alphadesign-icons";
import { CidCompaniesModal } from "components/CidCompaniesModal";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useShowCid } from "./useShowCid";
import { buildDownloadCompaniesCidUrl } from "helpers/urlHelpers";
import { filterSurveysWithInteractions, fromWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import useQueryParams from "hooks/useQueryParams";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { AdvisorsTableViewSkeleton } from "views/AdvisorsTableView";
import { SurveyTableHeader } from "./SurveyTableHeader";
import { useNewNavigation } from "@alphasights/client-portal-shared";

export const SurveyTableView = ({ selectedInteractionId, onToggleFilter }) => {
  const {
    onFiltersChange,
    state: { interactions, isInteractionsLoading, appliedFilters, isBackgroundSyncRunning },
    allInteractionsForProject,
    onSelectCard,
  } = useProjectInteractionsContext();
  const { project } = useCurrentProjectContext();
  const { selectedWorkstream: workstream, selectedSurveyId, setSelectedSurveyId } = useCurrentWorkstreamContext();
  const queryParams = useQueryParams();
  const styles = useStyles();
  const newNavigationEnabled = useNewNavigation();
  const showCid = useShowCid() && !newNavigationEnabled;

  const [showingCidModal, setShowingCidModal] = useState(false);
  const [selectedExpertIds, setSelectedExpertIds] = useState([]);

  const validSurveys = useMemo(
    () => filterSurveysWithInteractions(allInteractionsForProject, workstream.clientSurveys),
    [allInteractionsForProject, workstream.clientSurveys]
  );

  useEffect(
    function setInitialSurveySelected() {
      if (!validSurveys.length) return;
      if (isBackgroundSyncRunning) return;

      const querySurvey = queryParams.get("currentSurvey");
      if (querySurvey) {
        setSelectedSurveyId(querySurvey);
      } else if (selectedInteractionId) {
        const selectedInteractionSurveyId = interactions.find((int) => int.id === selectedInteractionId)?.surveyResponse
          ?.clientSurveyId;

        setSelectedSurveyId((prev) => prev || selectedInteractionSurveyId || validSurveys[0].id);
      } else {
        const nonEmptySurvey = validSurveys.find((s) =>
          interactions.find((i) => i.surveyResponse?.clientSurveyId === s.id)
        );
        setSelectedSurveyId((prev) => prev || nonEmptySurvey?.id || validSurveys[0].id);
      }
    },
    [interactions, validSurveys, queryParams, setSelectedSurveyId, isBackgroundSyncRunning, selectedInteractionId]
  );

  const queryTextRef = useRef();
  const workstreamInteractions = fromWorkstream(interactions, workstream.id);

  const selectedOrFirstSurveyId = useMemo(
    () => validSurveys.find((s) => s.id === selectedSurveyId)?.id ?? validSurveys[0].id,
    [validSurveys, selectedSurveyId]
  );

  const surveyType = useMemo(() => {
    const clientSurvey =
      workstream.clientSurveys && workstream.clientSurveys.find((e) => e.id === selectedOrFirstSurveyId);
    return clientSurvey?.surveyType;
  }, [selectedOrFirstSurveyId, workstream.clientSurveys]);

  const onDownloadCid = () => {
    window.open(buildDownloadCompaniesCidUrl(project.token, selectedExpertIds));
  };

  if (isBackgroundSyncRunning) {
    return <AdvisorsTableViewSkeleton />;
  }

  return (
    <x.div {...styles.wrapper}>
      <x.div {...styles.contentWrapper}>
        <x.div {...(!newNavigationEnabled || showCid ? styles.topBarWrapper : {})}>
          <SurveyTableHeader
            selectedSurveyId={selectedOrFirstSurveyId}
            surveyType={surveyType}
            onFiltersSidebarToggle={onToggleFilter}
            onSubmitSearch={(e) => {
              e.preventDefault();
              onFiltersChange(appliedFilters, queryTextRef.current.value);
            }}
            onClearSearch={() => onFiltersChange(appliedFilters, "")}
            queryTextRef={queryTextRef}
          />
          {showCid && (
            <CidControls
              showingCidModal={showingCidModal}
              setShowingCidModal={setShowingCidModal}
              selectedIds={selectedExpertIds}
              surveyType={surveyType}
              interactions={workstreamInteractions}
              selectedSurveyId={selectedOrFirstSurveyId}
              onDownloadCid={onDownloadCid}
            />
          )}
        </x.div>
        <SurveysMainContent
          selectedExpertIds={selectedExpertIds}
          setSelectedExpertIds={setSelectedExpertIds}
          surveyType={surveyType}
          selectedInteractionId={selectedInteractionId}
          interactions={workstreamInteractions}
          isLoading={isInteractionsLoading}
          selectedSurveyId={selectedOrFirstSurveyId}
          onSelectCard={onSelectCard}
          showCid={showCid}
          onDownloadCid={onDownloadCid}
        />
      </x.div>
    </x.div>
  );
};

const UploadCidButton = ({ onClick, ...props }) => {
  return (
    <Button
      variant="outline"
      size="small"
      onClick={onClick}
      startIcon={<Download className="aui-rotate-180" />}
      data-testid="upload-cid"
      {...props}
    >
      <Typography variant="body-em" className="aui-inline">
        Upload CID
      </Typography>
    </Button>
  );
};

const DownloadCidButton = ({ onClick, ...props }) => {
  return (
    <Button
      variant="secondary"
      size="small"
      onClick={onClick}
      startIcon={<Download />}
      data-testid="download-cid"
      {...props}
    >
      <Typography variant="body-em" className="aui-inline">
        Download CID
      </Typography>
    </Button>
  );
};

const CidControls = ({ showingCidModal, setShowingCidModal, selectedIds, onDownloadCid }) => {
  const { project } = useCurrentProjectContext();
  const { syncCidStatusChanges } = useProjectInteractionsContext();

  return (
    <x.div display={"flex"}>
      <UploadCidButton onClick={() => setShowingCidModal(true)} />
      {selectedIds?.length ? <DownloadCidButton onClick={onDownloadCid} className="aui-ml-4" /> : null}
      <CidCompaniesModal
        projectToken={project.token}
        isModalOpen={showingCidModal}
        onCidCompaniesModalUpload={syncCidStatusChanges}
        onClose={() => setShowingCidModal(false)}
      />
    </x.div>
  );
};
