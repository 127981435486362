import React, { useContext, useEffect, useState } from "react";
import { fetch } from "../hooks/useApi";
import { useProjectBadgeContext, EXPERT_REMARKS_BADGE } from "./BadgeProvider";
import { useNotifications } from "@alphasights/client-portal-shared";

export const ExpertRemarksContext = React.createContext();

export const ExpertRemarksProvider = ({ project, ...props }) => {
  const [expertRemarks, setExpertRemarks] = useState([]);
  const { hasProjectBadge } = useProjectBadgeContext();
  const { showErrorBanner } = useNotifications();

  const defaultExpertRemarks = {
    starred: false,
    hidden: false,
  };

  useEffect(() => {
    if (project && hasProjectBadge(EXPERT_REMARKS_BADGE)) {
      fetch({
        url: `/api/projects/${project.token}/experts/remarks`,
      })
        .then((response) => response.json())
        .then((remarks) => setExpertRemarks(remarks));
    } else {
      setExpertRemarks([]);
    }
  }, [project, hasProjectBadge]);

  const getExpertRemark = ({ angleId, expertId }) => {
    return expertRemarks.find((r) => r.angleId === angleId && r.expertId === expertId) || defaultExpertRemarks;
  };

  const isExpertHidden = (data) => {
    const angleId = data.angles?.at(0)?.id;
    const expertId = data.advisorId;

    return hasProjectBadge(EXPERT_REMARKS_BADGE) ? getExpertRemark({ angleId, expertId }).hidden : data.hidden;
  };

  const isExpertStarred = (data) => {
    const angleId = data.angles[0].id;
    const expertId = data.advisorId;

    return hasProjectBadge(EXPERT_REMARKS_BADGE) ? getExpertRemark({ angleId, expertId }).starred : data.starred;
  };

  const saveExpertRemark = ({ angleId, expertId, starred, hidden }) => {
    return fetch({
      url: `/api/projects/${project.token}/experts/remarks`,
      method: "PATCH",
      body: JSON.stringify({
        angleId: angleId,
        expertId: expertId,
        starred: starred,
        hidden: hidden,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        const index = expertRemarks.findIndex((r) => r.angleId === res.angleId && r.expertId === res.expertId);

        if (index !== -1) {
          const updatedRemarks = expertRemarks.map((r) => {
            if (r.angleId === res.angleId && r.expertId === res.expertId) {
              return res;
            }
            return r;
          });
          setExpertRemarks(updatedRemarks);
        } else {
          setExpertRemarks([...expertRemarks, res]);
        }
      })
      .catch(() => showErrorBanner("Error saving Expert Remarks."));
  };

  const context = {
    getExpertRemark,
    isExpertHidden,
    isExpertStarred,
    saveExpertRemark,
  };

  return <ExpertRemarksContext.Provider value={context} {...props} />;
};

export const useExpertRemarksContext = () => useContext(ExpertRemarksContext);
