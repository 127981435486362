import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { shouldShowTransId } from "../helpers";

const TransIdCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  return (
    <div data-testid={`survey-transId-column-${interaction.id}`}>
      {shouldShowTransId(interaction) && props.getValue()}
    </div>
  );
};

export const TransIdColumn = {
  id: "TransIdColumn",
  accessorKey: "interaction.surveyResponse.transId",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <TransIdCell props={props} />,
  header: "Survey ID",
  minSize: 50,
};
