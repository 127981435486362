import React from "react";
import { CallUnavailableAlert } from "./CallUnavailableAlert";
import { CallAvailabilityStatus } from "./constants";
import { Button } from "@alphasights/alphadesign-components";
import { ExternalLink } from "@alphasights/alphadesign-icons";

export const OpenExternalLink = ({ isOpen, callAvailabilityStatus, projectLead, joinUrl, textToShow }) => {
  const openLink = () => {
    window.open(joinUrl, "_blank");
  };

  function isUnavailable() {
    return callAvailabilityStatus && callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE;
  }

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {isUnavailable() && (
        <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
      )}
      <div className="aui-mt-4">{textToShow}</div>
      <div className="aui-mt-4">
        <Button onClick={openLink} startIcon={<ExternalLink />} size="large" disabled={isUnavailable()}>
          Join Call
        </Button>
      </div>
    </div>
  );
};
