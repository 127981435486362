import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { toPx } from "pages/AlphaNowPage/utils";

const CardContainer = styled.div({
  position: "relative",
  zIndex: 0,
});

const HeaderContainer = styled.div(({ hasMultipleCards }: { hasMultipleCards: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    display: hasMultipleCards ? "flex" : "none",
    justifyContent: "space-between",
    marginBottom: inner.base03,
  };
});

const CardStackContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    gap: inner.base03,
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    position: "relative" as "relative",
  };
});

const styledStackedCard = (index: number, expanded: boolean, numDisplayedCards: number) => {
  const visibleHeightBackgroundCard = 8;
  const reductionBackgroundCardWidth = 0.96 ** index;
  const reductionBackgroundCardOpacity = 0.25 * index;

  return expanded
    ? {
        animation: "fade-in 0.5s both",
      }
    : {
        position: index === 0 ? ("relative" as "relative") : ("absolute" as "absolute"),
        paddingBottom: numDisplayedCards > 1 ? toPx((numDisplayedCards - 1) * visibleHeightBackgroundCard) : 0,
        filter: `opacity(${1 - reductionBackgroundCardOpacity})`,
        top: toPx(index * visibleHeightBackgroundCard),
        width: `${100 * reductionBackgroundCardWidth}%`,
        alignSelf: "center",
        zIndex: index * -1,
        animation: "fade-in 0.3s both",
      };
};

export { CardStackContainer, styledStackedCard, HeaderContainer, CardContainer };
