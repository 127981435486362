import React from "react";
import { Typography, RoundedButtonProps } from "@alphasights/alphadesign-components";
import * as S from "./ToggleButton.styled";

export type ToggleButtonProps = {
  selected: boolean;
  title: string;
  onClick: () => void;
} & RoundedButtonProps;

export const ToggleButton = ({ selected, title, onClick, ...props }: ToggleButtonProps) => (
  <S.RoundedButton selected={selected} aria-selected={selected} onClick={onClick} role="option" {...props}>
    <Typography variant="body-small" color={selected ? "selected" : "secondary"}>
      {title}
    </Typography>
  </S.RoundedButton>
);
