import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";

export const Label = ({ text = "Label", required = false }) => {
  return (
    <x.div display="inline-flex">
      <Typography color="secondary" component="span">
        {text}
        {required && (
          <Typography color="danger" component="span">
            {" "}
            *
          </Typography>
        )}
      </Typography>
    </x.div>
  );
};
