import React, { useMemo } from "react";
import { Skeleton } from "@alphasights/alphadesign-components";

import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { MessengerStatusPill } from "pages/MessengerPage/components/MessengerStatusPill/MessengerStatusPill";
import { statusVariantMap } from "pages/MessengerPage/components/MessengerHeader/MessengerHeader";
import { MessageThreadHeaderResponse, ThreadType } from "types";
import { DAY_FORMAT, TIME_FORMAT } from "helpers/interactionHelpers";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { priority } from "providers/MessengerProvider";

export const getThreadType = (thread: MessageThreadHeaderResponse) =>
  thread.workRequest != null && thread.threadType === ThreadType.Messenger
    ? "Work Request"
    : thread.threadType === ThreadType.CallGuide
    ? "Call Guide"
    : "Clarification";

const MessageStatusColumnComponent = ({ row, tooltipOpen = undefined }: { row: any; tooltipOpen?: boolean }) => {
  const value = row.original;

  const { getWorkRequestStatus, isLoading } = useMessageThreadContext();

  const { onOpenMessageStatus } = useProjectInteractionsContext();

  const renderTooltip = (status: MessageThreadHeaderResponse) => (
    <>
      {getThreadType(status)}
      {" • "}
      {status.state.description}
      {" • "}
      <FormattedDateTime date={status.createdAt} format={`${DAY_FORMAT}, ${TIME_FORMAT}`} />
    </>
  );

  const renderMorePillTooltip = (statuses: MessageThreadHeaderResponse[]) => {
    const validSize = statuses.length <= 3;
    const top = validSize ? statuses : statuses.slice(0, 2);
    const othersCount = validSize ? 0 : statuses.length - 2;

    return (
      <ul>
        {top.map((status, index) => (
          <li key={index}>
            {getThreadType(status)}
            {" • "}
            {status.state.description}
            {" • "}
            <FormattedDateTime date={status.createdAt} format={`${DAY_FORMAT}`} />
          </li>
        ))}
        {othersCount > 0 && <li>+ {othersCount} more that can be viewed in the expert flyout</li>}
      </ul>
    );
  };

  const pills = useMemo(() => {
    const statuses = getWorkRequestStatus({ expertId: value.advisorId }).sort(
      (a, b) => priority.indexOf(a.state.description) - priority.indexOf(b.state.description)
    );

    let status = statuses[0];
    const firstPill = status ? (
      <MessengerStatusPill
        variant={statusVariantMap[status.state.description]}
        text={status.state.description}
        showCount={false}
        tooltip={[renderTooltip(status)]}
        tooltipOpen={tooltipOpen}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onOpenMessageStatus(value);
        }}
      />
    ) : null;

    status = statuses[statuses.length - 1];
    const morePill =
      statuses.length > 1 ? (
        <MessengerStatusPill
          variant={statusVariantMap[status.state.description]}
          onlyCount={statuses.length - 1}
          tooltip={[renderMorePillTooltip(statuses.slice(1))]}
          overrideColor="neutral-default"
          tooltipOpen={tooltipOpen}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            onOpenMessageStatus(value);
          }}
        />
      ) : null;

    return {
      firstPill: firstPill,
      morePill: morePill,
    };
  }, [getWorkRequestStatus, tooltipOpen, onOpenMessageStatus, value]);

  return (
    <div
      className="aui-capitalize aui-flex aui-space-x-2 aui-items-center aui-w-full aui-h-full"
      data-testid={`message-status-column-${value.id}`}
    >
      {isLoading ? (
        <div data-testid="message-status-loading">
          <Skeleton />
        </div>
      ) : (
        <>
          {pills.firstPill}
          {pills.morePill}
        </>
      )}
    </div>
  );
};

export const MessageStatusColumn = React.memo(MessageStatusColumnComponent);
