import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useNewMessagePanelStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const toggleMessageType = {
    mb: inner.base02,
  };

  const tooltipContent = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    padding: inner.base,
  };

  const toggleMessageTypeContainer = {
    display: "flex",
    gap: inner.base02,
  };

  const tooltipDiv = {
    h: "fit-content",
  };

  return {
    toggleMessageType,
    tooltipContent,
    toggleMessageTypeContainer,
    tooltipDiv,
  };
};
