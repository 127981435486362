import React, { FC } from "react";
import { useThemeTokens, IconButton, IconButtonProps } from "@alphasights/alphadesign-components";
import { BookmarkSelected, BookmarkUnselected } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { IconButtonVariant, IconButtonSizes } from "@alphasights/alphadesign-components";

import { withAccessControl, Variant as CONTROL_VARIANT } from "components/AccessControl/AccessControl";

type BookmarkButtonProps = {
  onToggleBookmark: (isBookmarked: boolean) => void;

  isBookmarked?: boolean;
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
  pl?: number | string;
  permissions?: string[];
};

const DataTestIds = {
  bookmarkButton: "bookmark-button",
  bookmarked: "bookmarked",
  unbookmarked: "not-bookmarked",
};

const ButtonWithAccess = withAccessControl(IconButton);

const BookmarkButton: FC<BookmarkButtonProps> = ({
  onToggleBookmark,
  isBookmarked,
  variant = "basic",
  size = "medium",
  permissions,
}) => {
  const {
    color: { text },
  } = useThemeTokens();

  const Button = (props: IconButtonProps) =>
    permissions ? (
      <ButtonWithAccess
        accessControl={{
          allowedPermissions: permissions,
          variant: CONTROL_VARIANT.Disabled,
        }}
        {...props}
      />
    ) : (
      <IconButton {...props} />
    );

  return (
    <x.div data-testid={DataTestIds.bookmarkButton} position="relative" top={-1} display="flex" alignItems="center">
      <Button onClick={() => onToggleBookmark(!isBookmarked)} color={text.secondary} size={size} variant={variant}>
        {isBookmarked ? (
          <BookmarkSelected data-testid={DataTestIds.bookmarked} />
        ) : (
          <BookmarkUnselected data-testid={DataTestIds.unbookmarked} />
        )}
      </Button>
    </x.div>
  );
};

export { BookmarkButton as default, DataTestIds };
export type { BookmarkButtonProps };
