import React, { FC, useEffect, useRef, useState } from "react";
import { Popover, ListOption, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { SortAZ } from "@alphasights/alphadesign-icons";

import { FilterButton } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import {
  SORT,
  SORT_LABELS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/SortByFilter/constants";
import useOnClickOutside from "hooks/useOnClickHooks";

type SortByFilterPropsType = {
  value: string;
  isOpenable: boolean;
  onChange: (value: string) => void;
};

const SortByFilter: FC<SortByFilterPropsType> = ({ value, isOpenable, onChange }) => {
  const { color } = useThemeTokens();
  const [showSortPopover, setShowSortPopover] = useState(false);

  const sortButtonRef = useRef<HTMLButtonElement>(null);
  const sortPopoverRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sortPopoverRef, () => {
    setShowSortPopover(false);
  });

  useEffect(() => {
    if (!isOpenable) {
      setShowSortPopover(false);
    }
  }, [isOpenable]);

  const handleClick = () => {
    setShowSortPopover(!showSortPopover);
  };

  const handleSortChange = (value: string) => {
    onChange(value);
    setShowSortPopover(false);
  };

  return (
    <>
      <FilterButton
        ref={sortButtonRef}
        startIcon={
          <Icon size="small" color={color.icon.secondary}>
            <SortAZ />
          </Icon>
        }
        onClick={handleClick}
      >
        {SORT}
      </FilterButton>
      <Popover
        ref={sortPopoverRef}
        anchorEl={sortButtonRef.current!}
        placement="bottom-start"
        open={showSortPopover}
        w="200px"
      >
        {Object.entries(SORT_LABELS).map(([sortValue, sortLabel]) => (
          <ListOption
            dataAttributes={{ "data-testid": `sort-option-${sortValue}` }}
            key={sortValue}
            type="text"
            size="small"
            label={sortLabel}
            selected={sortValue === value}
            onChange={(_) => handleSortChange(sortValue)}
          />
        ))}
      </Popover>
    </>
  );
};

export default SortByFilter;
