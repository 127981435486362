import { CheckboxGroup, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const CheckboxGroupWrapper = styled(CheckboxGroup)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    marginLeft: inner.base,
    marginBlock: inner.base03,
    ">:first-child": {
      gap: inner.base05,
    },
  };
});

export { CheckboxGroupWrapper };
