import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useSchedulingSlotsStyles = () => {
  const { spacing } = useThemeTokens();

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.inner.base06,
  };

  return {
    containerStyles,
  };
};
