import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAdvisorsPageStyles = ({ newNavigationEnabled } = {}) => {
  const {
    color: { text, background },
    spacing: { layout, inner },
  } = useThemeTokens();

  const boldPrimarySpan = {
    component: "span",
    variant: "body-em",
    color: text.danger,
  };

  const header = {
    maxW: 1024,
    mx: "auto",
    display: "flex",
    w: "100%",
  };

  const shortRow = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: layout.base02,
    alignItems: "center",
  };

  const collapsedSearchStyles = {
    w: "88px",
    bg: background.surface.recessed,
    pt: inner.base06,
    display: "flex",
    justifyContent: "center",
  };

  const iconButtonStyles = {
    h: inner.base06,
    w: inner.base06,
  };

  const list = {
    pt: newNavigationEnabled ? inner.base06 : undefined,
  };

  return {
    boldPrimarySpan,
    header,
    shortRow,
    collapsedSearchStyles,
    iconButtonStyles,
    list,
  };
};
