import { POPOVER_GAP } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/constants";

const getNumAppliedFilters = (initialState: Object, filters: Object) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    if (JSON.stringify(value) !== JSON.stringify(initialState[key as keyof typeof initialState])) {
      acc += 1;
    }
    return acc;
  }, 0);

const getContainerMaxHeight = (parentElem?: HTMLElement | null) => {
  const windowHeight = window.innerHeight;
  const { bottom: buttonBottom } = parentElem?.getBoundingClientRect() ?? { bottom: 0 };
  const maxHeight = windowHeight - (buttonBottom + POPOVER_GAP);
  return maxHeight;
};

export { getNumAppliedFilters, getContainerMaxHeight };
