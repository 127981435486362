import { subDays, subMonths, subWeeks, subYears } from "date-fns";

import { SliderOption } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";

const DATE = "Date";

// if the lengths of these two arrays change, the corresponding index.css file should be updated to match the new lengths

const DATE_MARKS: SliderOption[] = [
  { label: "Over one year ago", value: 0 },
  { label: "1 year ago", value: 1 },
  { label: "11 months ago", value: 2 },
  { label: "10 months ago", value: 3 },
  { label: "9 months ago", value: 4 },
  { label: "8 months ago", value: 5 },
  { label: "7 months ago", value: 6 },
  { label: "6 months ago", value: 7 },
  { label: "5 months ago", value: 8 },
  { label: "4 months ago", value: 9 },
  { label: "3 months ago", value: 10 },
  { label: "2 months ago", value: 11 },
  { label: "1 month ago", value: 12 },
  { label: "3 weeks ago", value: 13 },
  { label: "2 weeks ago", value: 14 },
  { label: "1 week ago", value: 15 },
  { label: "6 days ago", value: 16 },
  { label: "5 days ago", value: 17 },
  { label: "4 days ago", value: 18 },
  { label: "3 days ago", value: 19 },
  { label: "2 days ago", value: 20 },
  { label: "1 day ago", value: 21 },
  { label: "Today", value: 22 },
];

const DATE_VALUES = [
  null,
  subYears(Date.now(), 1).getTime(),
  subMonths(Date.now(), 11).getTime(),
  subMonths(Date.now(), 10).getTime(),
  subMonths(Date.now(), 9).getTime(),
  subMonths(Date.now(), 8).getTime(),
  subMonths(Date.now(), 7).getTime(),
  subMonths(Date.now(), 6).getTime(),
  subMonths(Date.now(), 5).getTime(),
  subMonths(Date.now(), 4).getTime(),
  subMonths(Date.now(), 3).getTime(),
  subMonths(Date.now(), 2).getTime(),
  subMonths(Date.now(), 1).getTime(),
  subWeeks(Date.now(), 3).getTime(),
  subWeeks(Date.now(), 2).getTime(),
  subWeeks(Date.now(), 1).getTime(),
  subDays(Date.now(), 6).getTime(),
  subDays(Date.now(), 5).getTime(),
  subDays(Date.now(), 4).getTime(),
  subDays(Date.now(), 3).getTime(),
  subDays(Date.now(), 2).getTime(),
  subDays(Date.now(), 1).getTime(),
  null,
];

export { DATE, DATE_MARKS, DATE_VALUES };
