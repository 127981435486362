import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type TitleAndCompanyDetailsProps = {
  jobTitle: string;
  company: string;
};

const TitleAndCompanyDetails: FC<TitleAndCompanyDetailsProps> = ({ company, jobTitle }) => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <Typography color={text.strong._} mb={inner.base} variant={isMobile ? "body-large" : "body"}>
      <Typography component="span" variant={isMobile ? "body-large-em" : "body-em"} flex="1 1 auto">
        {company}
      </Typography>
      {` - ${jobTitle}`}
    </Typography>
  );
};

export default TitleAndCompanyDetails;
