import { Button, Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import classNames from "classnames";
import { ResponsiveModal } from "components/ResponsiveModal";
import React, { useEffect, useState } from "react";
import { CurrentTimezone, useTimezone } from "../../providers/TimezoneProvider";
import { TimezoneSearchInput } from "../TimezoneSearchInput";
import styled, { x } from "@xstyled/styled-components";
import { Edit } from "@alphasights/alphadesign-icons";

const OverflowYUnsetResponsiveModal = styled(ResponsiveModal)`
  div > div {
    overflow-y: unset;
  }
`;

export const TimezoneSearchInputModal = ({ onClose, onTimezoneChanged, ...props }) => {
  const tz = useTimezone();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [selectedTimezone, setSelectedTimezone] = useState(tz.currentTimezone);

  useEffect(() => {
    setSelectedTimezone(tz.currentTimezone);
  }, [tz.currentTimezone]);

  const onSelectTimezone = () => {
    onTimezoneChanged && onTimezoneChanged(selectedTimezone);
    tz.setCurrentTimezone(selectedTimezone);
    onClose();
  };

  return (
    <OverflowYUnsetResponsiveModal
      {...props}
      onClose={onClose}
      title="Select Time Zone"
      data-testid="modal-tz-selector"
      slotWidth="35vw"
      zIndex={160}
      primaryButton={
        <Button variant="secondary" onClick={onSelectTimezone}>
          <Typography variant="body-em">Confirm Time Zone</Typography>
        </Button>
      }
    >
      <x.div mt={inner.base} mb={inner.base06}>
        <Typography>Change your default time zone for the platform.</Typography>
      </x.div>
      <TimezoneSearchInput onTimezoneChange={setSelectedTimezone} timezone={selectedTimezone} />
    </OverflowYUnsetResponsiveModal>
  );
};

export const TimeSearchInputModalActivator = ({ format, className, onTimezoneChanged, showIcon, ...props }) => {
  const [isTimezoneModalOpen, toggleTimezoneModalOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => toggleTimezoneModalOpen(true)}
        className={classNames("aui-cursor-pointer", className)}
        {...props}
      >
        {showIcon ? (
          <Icon size="small" color="secondary">
            <Edit />
          </Icon>
        ) : (
          <CurrentTimezone formatOnly format={format} />
        )}
      </div>
      <TimezoneSearchInputModal
        open={isTimezoneModalOpen}
        onTimezoneChanged={onTimezoneChanged}
        onClose={() => toggleTimezoneModalOpen(false)}
      />
    </>
  );
};
