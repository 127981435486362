import { useThemeTokens, Button as ButtonAds } from "@alphasights/alphadesign-components";
import { withAccessControl } from "components/AccessControl/AccessControl";
import React, { useState } from "react";

import {
  PrimaryButton as AuiPrimaryButton,
  PrimaryButtonSmall as AuiPrimaryButtonSmall,
  SecondaryButton as AuiSecondaryButton,
  SecondaryButtonSmall as AuiSecondaryButtonSmall,
  TernaryButton as AuiTernaryButton,
  TernaryButtonSmall as AuiTernaryButtonSmall,
  Icon,
} from "../alphaui";

import { Spinner } from "../Spinner";

const CenteredSpinner = () => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
    children={<Spinner style={{ height: "1em" }} />}
  />
);

const IsLoadingContainer = ({ isActive, children }) =>
  isActive ? (
    <>
      <CenteredSpinner />
      <div className="aui-invisible">{children}</div>
    </>
  ) : (
    children
  );

const isPromise = (obj) => Promise.resolve(obj) == obj; // eslint-disable-line eqeqeq

const withLoadingOnPromiseClick = (Button) => (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = (e) => {
    if (!props.onClick) return;

    const ret = props.onClick(e);

    if (isPromise(ret)) {
      setIsLoading(true);
      return ret.finally(() => setIsLoading(false));
    } else {
      return ret;
    }
  };

  return <Button {...props} onClick={onClick} isLoading={isLoading || props.isLoading} />;
};

export const PrimaryButton = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", children, isLoading, tooltip, ...props }) => (
    <AuiPrimaryButton
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-button-primary aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-solid md:aui-leading-none aui-bg-${className} ${
        props.disabled ? "" : "hover:aui-border-primary-2 hover:aui-bg-primary-2"
      }`}
      {...props}
      children={<IsLoadingContainer isActive={isLoading} children={children} />}
    />
  ))
);

export const SecondaryButton = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", children, isLoading, tooltip, ...props }) => (
    <AuiSecondaryButton
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-solid md:aui-leading-none ${className} hover:aui-border-primary-2`}
      {...props}
      children={<IsLoadingContainer isActive={isLoading} children={children} />}
    />
  ))
);

export const TernaryButton = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", children, isLoading, tooltip, ...props }) => (
    <AuiTernaryButton
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-none md:aui-leading-none ${className} hover:aui-text-primary-2`}
      {...props}
      children={<IsLoadingContainer isActive={isLoading} children={children} />}
    />
  ))
);

export const PrimaryButtonSmall = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", children, isLoading, tooltip, ...props }) => (
    <AuiPrimaryButtonSmall
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-button-primary aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-solid md:aui-leading-none ${className} ${
        props.disabled ? "" : "hover:aui-border-primary-2 hover:aui-bg-primary-2"
      }`}
      {...props}
    >
      <IsLoadingContainer isActive={isLoading} children={children} />
    </AuiPrimaryButtonSmall>
  ))
);

export const SecondaryButtonSmall = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", isLoading, children, tooltip, ...props }) => (
    <AuiSecondaryButtonSmall
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-solid md:aui-leading-none ${className} hover:aui-border-primary-2`}
      {...props}
      children={<IsLoadingContainer isActive={isLoading} children={children} />}
    />
  ))
);

export const TernaryButtonSmall = withAccessControl(
  withLoadingOnPromiseClick(({ className = "", isLoading, children, tooltip, ...props }) => (
    <AuiTernaryButtonSmall
      loadingOnClick={false}
      style={{ marginBottom: 0 }}
      data-tip={tooltip}
      className={`${
        isLoading ? "aui-pointer-events-none" : ""
      } aui-text-grey-5 aui-font-light aui-inline-flex aui-items-center aui-justify-center aui-relative aui-text-base aui-leading-normal aui-font-sans aui-border-none md:aui-leading-none ${className} hover:aui-text-primary-2`}
      children={<IsLoadingContainer isActive={isLoading} children={children} />}
      {...props}
    />
  ))
);

export const ResetButton = withAccessControl(({ label, className = "", ...props }) => (
  <div
    {...props}
    className={`aui-text-grey-4 aui-font-semibold aui-flex aui-items-center aui-space-x-2 aui-cursor-pointer aui-uppercase aui-text-sm aui-tracking-tight ${className}`}
  >
    <Icon className="aui-align-middle" icon="reset" />
    {label && <span>{label}</span>}
  </div>
));

export const ButtonLink = withAccessControl(
  ({ children, textColor = "primary-1", font = "normal", className = "", ...props }) => (
    <a
      {...props}
      className={`aui-cursor-pointer aui-text-${textColor} aui-inline-block aui-leading-none aui-font-${font} aui-no-underline ${className}`}
    >
      {children}
    </a>
  )
);

export const ImageButton = withAccessControl(({ src, alt, onClick, ...props }) => (
  <img
    {...props}
    src={src}
    alt={alt}
    className={`aui-mr-2 ${props.disabled ? "aui-cursor-not-allowed" : "aui-cursor-pointer"}`}
    onClick={props.disabled ? () => {} : onClick}
  />
));

export const withMargin = (Button) => ({ size = "medium", ...props }) => {
  const { spacing } = useThemeTokens();

  return <Button size={size} mx={size === "small" ? spacing.inner.base02 : spacing.inner.base03} {...props} />;
};

export const ButtonWithMargin = withMargin(ButtonAds);
