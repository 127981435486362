import { differenceInSeconds } from "date-fns";
import { isPast } from "date-fns";
import redirectToCalendarView from "views/ProjectCalendarView/redirectToCalendarView";

export const eventHandler = (input) => (type, args) => {
  const { deps } = input;

  if (eventHandlers[type]) {
    return eventHandlers[type](input, args);
  } else if (deps[type]) {
    return deps[type](args);
  }

  return Promise.resolve();
};

const selectEvent = ({ setPreselected }, args) => {
  if (!args?.preselected) {
    setPreselected(
      args && {
        id: args.id,
        type: args.type,
        start: args.start,
        duration: differenceInSeconds(args.end, args.start),
      }
    );
  }
};

const selectTimeslots = ({ setPreselected }, args) => {
  !isPast(args.start) &&
    setPreselected({
      type: "clientAvailability",
      start: args.start,
      duration: differenceInSeconds(args.end, args.start),
    });
};

const clickEvent = ({ projectToken }, args) => {
  if (args.source === "upcomingCall" && args.raw.projectToken !== projectToken)
    redirectToCalendarView({
      interactionId: args.raw.id,
      projectToken: args.raw.projectToken,
      date: args.raw.scheduledCallTime,
    });
};

const submitCancelRequest = ({ onRequestChangeInteraction }, args) => {
  onRequestChangeInteraction({
    id: args.id,
    reason: args.reason,
    type: args.type,
    projectToken: args.projectToken,
  });
};

const attendeesUpdated = ({ onAttendeesUpdated }, args) => {
  onAttendeesUpdated({
    id: args.id,
    attendees: args.attendees,
  });
};

const saveClientAvailability = ({ projectToken, onSaveClientAvailability }, args) => {
  return onSaveClientAvailability({ projectToken, ...args });
};

const eventHandlers = {
  attendeesUpdated,
  clickEvent,
  submitCancelRequest,
  selectEvent,
  selectTimeslots,
  saveClientAvailability,
};
