import { CommissionPrimerCredits, CommissionPrivatePrimerCredits, PrimerTypes } from "models/primers/constants";
import { AlphaNowSRMProductType, CONTENT_TYPE, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";
import { ModalPrimerRow, RequestForm } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";

const primersOrderMap = {
  [AlphaNowSRMProductType.companyPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: true,
    },
  ],
  [AlphaNowSRMProductType.marketPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: true,
    },
  ],
  [AlphaNowSRMProductType.customerPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: true,
    },
  ],
};

// Default primersOrder
const defaultOrder = [
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
    credits: CommissionPrimerCredits.COMPANY_PRIMER,
    isRecommended: false,
  },
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
    credits: CommissionPrimerCredits.MARKET_PRIMER,
    isRecommended: false,
  },
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
    credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
    isRecommended: false,
  },
];

export const getCreditsValue = (format: string, type: string, isRush?: boolean) => {
  let formatKey = format?.toUpperCase().split(" ").join("_");

  if (isRush) formatKey = `${formatKey}_RUSH`;

  if (type === PrimerTypes.ALPHA_NOW) {
    return CommissionPrimerCredits[formatKey as keyof typeof CommissionPrimerCredits];
  }
  return CommissionPrivatePrimerCredits[formatKey as keyof typeof CommissionPrivatePrimerCredits];
};

export const createRequestFormEntry = ({
  companyName,
  companyId,
  type,
  format,
  credits,
  logoLinks,
  rush,
  isRecommended,
}: {
  companyName: string;
  companyId?: string | string[];
  type: string;
  format: string;
  credits: number;
  logoLinks: string[];
  rush: boolean;
  isRecommended: boolean;
}) => {
  return {
    requestedCompanyId: companyId,
    requestedCompanyName: companyName,
    requestedCompanyType: type,
    requestedCompanyFormat: format,
    logoLinks,
    credits,
    rush,
    isRecommended,
  };
};

export const mapMarketPrimerInitialForm = ({
  requestForm,
  competitorDynamics,
  setRequestForm,
}: {
  requestForm: RequestForm;
  competitorDynamics: CompetitorDynamicsEntry[];
  setRequestForm: (requestForm: RequestForm) => void;
}) => {
  let updatedRows = [...requestForm.requestedPrimers];
  const leadingCompetitors: { companyName: string; logoLink: string; cdsAlphaCompanyId: string }[] = [];

  updatedRows = competitorDynamics.map((competitor) => {
    const { companyName, logoLink, otherNotableCompetitor, cdsAlphaCompanyId } = competitor;

    if (!otherNotableCompetitor) {
      leadingCompetitors.push({ companyName, logoLink, cdsAlphaCompanyId });
    }

    return createRequestFormEntry({
      companyName,
      companyId: cdsAlphaCompanyId,
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      logoLinks: [logoLink],
      rush: false,
      isRecommended: false,
    });
  });

  if (leadingCompetitors.length > 0) {
    updatedRows.push(
      createRequestFormEntry({
        companyId: leadingCompetitors.map(({ cdsAlphaCompanyId }) => cdsAlphaCompanyId),
        companyName: leadingCompetitors.map(({ companyName }) => companyName).join(", "),
        type: PrimerTypes.ALPHA_NOW,
        format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
        credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
        logoLinks: leadingCompetitors.map(({ logoLink }) => logoLink),
        rush: false,
        isRecommended: false,
      })
    );
  }

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCompanyPrimerInitialForm = ({
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
}) => {
  let updatedRows: {
    requestedCompanyId?: string | string[];
    requestedCompanyName: string;
    requestedCompanyType: string;
    requestedCompanyFormat: string;
    logoLinks: string[];
    credits: number;
    rush: boolean;
    isRecommended: boolean;
  }[] = [];

  let primersOrder = [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: false,
    },
  ];

  primersOrder.forEach(({ format, credits }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        rush: false,
        isRecommended: false,
      })
    );
  });

  setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCustomerPrimerInitialForm = ({
  requestForm,
  setRequestForm,
  featuredVendors,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  featuredVendors: CustomerPrimerFeaturedVendor[];
}) => {
  let updatedRows = [];

  updatedRows = featuredVendors.map((vendor) => {
    const { companyName: companyVendorName, logoLink, cdsAlphaCompanyId } = vendor;

    return createRequestFormEntry({
      companyId: cdsAlphaCompanyId ? cdsAlphaCompanyId.toString() : "",
      companyName: companyVendorName,
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      logoLinks: [logoLink],
      rush: false,
      isRecommended: false,
    });
  });

  updatedRows.push(
    createRequestFormEntry({
      companyId: featuredVendors.map(({ cdsAlphaCompanyId }) =>
        cdsAlphaCompanyId ? cdsAlphaCompanyId.toString() : ""
      ),
      companyName: featuredVendors.map(({ companyName }) => companyName).join(", "),
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      logoLinks: featuredVendors.map(({ logoLink }) => logoLink),
      rush: false,
      isRecommended: false,
    })
  );

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCompanyPageInitialForm = ({
  productType,
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
  productType?: AlphaNowSRMProductType;
}) => {
  let updatedRows: ModalPrimerRow[] = [];

  const primersOrder = primersOrderMap[productType!] ?? defaultOrder;

  primersOrder.forEach(({ format, credits, isRecommended }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        rush: false,
        isRecommended,
      })
    );
  });

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};
