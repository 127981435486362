import React from "react";
import { Option, Select, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CurrentTimezone, FormattedDateTime } from "providers/TimezoneProvider";
import { PopupDataArea, ScheduledCallTime } from "./common";
import { x } from "@xstyled/styled-components";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";

export const SelectDateTime = ({
  optionsStart,
  optionsEnd,
  selectedStart,
  setSelectedStart,
  duration,
  setDuration,
  icon,
  addMarginTop = true,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  if (optionsStart.length === 1 && optionsEnd.length === 1) {
    return <ScheduledCallTime date={selectedStart} duration={duration} />;
  }

  return (
    <PopupDataArea
      icon={icon ? icon : <CalendarAvailable />}
      firstLine={
        <Typography variant="body" color="strong">
          <FormattedDateTime date={selectedStart} format="iiii d MMM" />
          <CurrentTimezone formatOnly format=" (O)" />
        </Typography>
      }
      addMarginTop={addMarginTop}
    >
      {(optionsStart.length > 1 || optionsEnd.length > 1) && (
        <x.div display="flex" alignItems="center" pt={inner.base02}>
          <x.div flex="1">
            <Select
              value={selectedStart.getTime()}
              onChange={(value) => setSelectedStart(new Date(value))}
              data-testid="schedule-start-dropdown"
            >
              {optionsStart.map((option) => (
                <div key={option.value.getTime()} data-testid="start-option">
                  <Option value={option.value.getTime()} label={option.label} data-testid="start-option" />
                </div>
              ))}
            </Select>
          </x.div>
          <x.div mx={inner.base04}>-</x.div>
          {optionsEnd.length > 1 ? (
            <x.div flex="1">
              <Select value={duration} onChange={setDuration} data-testid="schedule-end-dropdown">
                {optionsEnd.map((option) => (
                  <div key={option.value} data-testid="end-option">
                    <Option value={option.value} label={option.label} />
                  </div>
                ))}
              </Select>
            </x.div>
          ) : (
            <Typography flex="1" data-testid="schedule-end-dropdown-read-only">
              {optionsEnd[0].label}
            </Typography>
          )}
        </x.div>
      )}
    </PopupDataArea>
  );
};
