import { fetch } from "../../hooks/useApi";

const commentService = {
  getComments: async ({ projectId, angleId, expertId }) => {
    const query = new URLSearchParams({ angleId, expertId }).toString();
    const response = await fetch({
      url: `/api/projects/${projectId}/comments?${query}`,
      method: "GET",
    });
    return response.json();
  },

  submitComment: async ({ projectId, ...payload }) => {
    const response = await fetch({
      url: `/api/projects/${projectId}/comments`,
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  },

  editComment: async ({ projectId, editingCommentId, editingContent }) => {
    const response = await fetch({
      url: `/api/projects/${projectId}/comments/${editingCommentId}`,
      method: "PATCH",
      body: JSON.stringify({ content: editingContent }),
    });
    return await response.json();
  },

  deleteComment: async ({ projectId, commentId }) => {
    return fetch({
      url: `/api/projects/${projectId}/comments/${commentId}`,
      method: "DELETE",
    });
  },

  markAsRead: async ({ projectId, comments }) => {
    return await fetch({
      url: `/api/projects/${projectId}/comments/read`,
      method: "POST",
      body: JSON.stringify({ commentIds: comments.map(({ id }) => id) }),
    });
  },
};

export default commentService;
