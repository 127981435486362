import { format, parse } from "date-fns";
import {
  INITIAL_WATCHLIST_PAYLOAD,
  PlaceholderWatchlistDataProps,
  TRIGGER_TYPE,
  UpdateWatchlistSettingsPayload,
  WATCHLIST_DELIVERY_DAY,
  WATCHLIST_FREQUENCY,
  WatchlistDataProps,
  WatchlistTriggerProps,
} from "hooks/useWatchlist";
import { isEqual } from "lodash";
import { NewTrigger } from "./SearchBar/types";

const getSelectedWatchlistFrequency = (availableWatchlists: WatchlistDataProps[], watchlistId: string) => {
  const selectedWatchlist = availableWatchlists.find((watchlist) => watchlist?.id === watchlistId);
  return selectedWatchlist?.frequency;
};

const getSelectedWatchlist = (availableWatchlists: WatchlistDataProps[], watchlistId: string) =>
  availableWatchlists.find((watchlist) => watchlist.id === watchlistId) as WatchlistDataProps;

const getWatchlistSettings = (selectedWatchlist: WatchlistDataProps | PlaceholderWatchlistDataProps) => ({
  name: selectedWatchlist.name,
  frequency: selectedWatchlist.frequency,
  deliveryDayOfWeek: selectedWatchlist.deliveryDayOfWeek ?? null,
  deliveryTime: selectedWatchlist.deliveryTime ?? null,
  deliveryTimeZone: selectedWatchlist.deliveryTimeZone ?? null,
});

const getUpdatedDeliverySettings = (
  previousWatchlistSettings: UpdateWatchlistSettingsPayload,
  watchlistFrequency: WATCHLIST_FREQUENCY
): UpdateWatchlistSettingsPayload => {
  const { deliveryDayOfWeek, deliveryTime, deliveryTimeZone, ...restSettings } = previousWatchlistSettings;

  const defaultWatchlistData = {
    deliveryDayOfWeek: deliveryDayOfWeek ?? WATCHLIST_DELIVERY_DAY.monday,
    deliveryTime: deliveryTime ?? INITIAL_WATCHLIST_PAYLOAD.deliveryTime,
    deliveryTimeZone: deliveryTimeZone ?? INITIAL_WATCHLIST_PAYLOAD.deliveryTimeZone,
    ...restSettings,
  };

  const commonSettings = {
    ...defaultWatchlistData,
    frequency: watchlistFrequency,
  };

  const handlers = {
    [WATCHLIST_FREQUENCY.immediate]: () => ({
      name: defaultWatchlistData.name,
      frequency: watchlistFrequency,
    }),
    [WATCHLIST_FREQUENCY.daily]: () => ({
      ...commonSettings,
      deliveryDayOfWeek: null,
    }),
    [WATCHLIST_FREQUENCY.monthly]: () => commonSettings,
    [WATCHLIST_FREQUENCY.weekly]: () => commonSettings,
  };

  return handlers[watchlistFrequency]();
};

const getParsedDeliveryTime = (deliveryTime: string) => {
  const parsedDeliveryTime = parse(deliveryTime, "HH:mm:ss", new Date());
  return format(parsedDeliveryTime, "HH:mm");
};

const generateWatchlistDeliveryTime = () => {
  const times = [];
  let currentTime = new Date();
  currentTime.setHours(0, 0, 0, 0);

  const startDate = currentTime.getDate();
  while (currentTime.getDate() === startDate) {
    let hours = currentTime.getHours().toString().padStart(2, "0");
    let minutes = currentTime.getMinutes().toString().padStart(2, "0");
    let seconds = currentTime.getSeconds().toString().padStart(2, "0");

    times.push(`${hours}:${minutes}:${seconds}`);
    currentTime.setMinutes(currentTime.getMinutes() + 30);
  }

  return times;
};

const hasWatchlistSettingsChanged = (
  prevWatchlistSettings: UpdateWatchlistSettingsPayload,
  currentWatchlistSettings: UpdateWatchlistSettingsPayload
): boolean => !isEqual(prevWatchlistSettings, currentWatchlistSettings);

const getTriggerValues = (triggers: NewTrigger[] | WatchlistTriggerProps[]) => {
  let companyIds = [] as number[];
  let keywords = [] as string[];

  triggers.forEach((trigger: NewTrigger | WatchlistTriggerProps) => {
    trigger.type === TRIGGER_TYPE.company
      ? companyIds.push(trigger.cdsAlphaCompanyId as number)
      : keywords.push(trigger.token as string);
  });

  return {
    ...(companyIds.length && { companyIds }),
    ...(keywords.length && { keywords }),
  };
};
const numTriggersOfType = (triggers: WatchlistTriggerProps[], triggerType: TRIGGER_TYPE) =>
  triggers.filter(({ type }) => type === triggerType).length;

export {
  getSelectedWatchlistFrequency,
  generateWatchlistDeliveryTime,
  getUpdatedDeliverySettings,
  getWatchlistSettings,
  getParsedDeliveryTime,
  getSelectedWatchlist,
  hasWatchlistSettingsChanged,
  getTriggerValues,
  numTriggersOfType,
};
