import { CLIENT_ROLE_PERMISSION } from "constants/AlphaNow";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export function useAccessControl(allowedPermissions, allowClientRoleAccess = true) {
  const hasPermission = (userPermissions, allowedPermissions) => {
    if (allowedPermissions.length === 0) {
      return true;
    }
    return userPermissions.some((permission) => allowedPermissions.includes(permission));
  };

  const userPermissions = useCurrentUser()?.permissions;

  return (
    userPermissions == null ||
    hasPermission(userPermissions, [...allowedPermissions, allowClientRoleAccess ? CLIENT_ROLE_PERMISSION : null])
  );
}
