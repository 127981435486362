import React, { FC } from "react";
import { Company } from "@alphasights/alphadesign-icons";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";
import {
  CompanyPrimerLogoContainer,
  LogoContainer,
  StyledIcon,
  StyledLogo,
} from "pages/AlphaNowPage/components/CompanyLogos/CompanyLogos.styled";

type CompanyLogosProps = {
  productType: AlphaNowProductType;
  title: string;
  logos: string[];
};

const CompanyLogos: FC<CompanyLogosProps> = ({ productType, title = "", logos = [] }) => {
  const isCompanyPrimer = productType === AlphaNowProductType.companyPrimer;
  const isCustomerPrimer = productType === AlphaNowProductType.customerPrimer;
  const isMarketPrimer = productType === AlphaNowProductType.marketPrimer;

  if (!logos) return null;

  //Company Primer
  if (isCompanyPrimer) {
    const [companyPrimerLogo] = logos;
    if (!companyPrimerLogo) {
      return (
        <div data-testid="placeholder-icon">
          <StyledIcon size="large">
            <Company />
          </StyledIcon>
        </div>
      );
    }

    return <CompanyPrimerLogoContainer title={title} src={companyPrimerLogo} />;
  }

  //Market Primer & Customer Primer
  if (isCustomerPrimer || isMarketPrimer) {
    if (logos.length === 0) {
      return (
        <div data-testid="placeholder-icon">
          <StyledIcon size="large">
            <Company />
          </StyledIcon>
        </div>
      );
    }

    return (
      <LogoContainer>
        {logos?.map((logoSrc: string) => {
          return <StyledLogo key={logoSrc} logoSrc={logoSrc} title={title} />;
        })}
      </LogoContainer>
    );
  }

  //All non-primer content
  return null;
};

export { CompanyLogos as default };
