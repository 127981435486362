import { CallToActionColumn } from "./CallToActionColumn";
import { CommentColumn } from "./CommentColumn";
import { TransIdColumn } from "./TransIdColumn";
import { SurveyStatusColumn } from "./surveyStatus/SurveyStatusColumn";
import { ExpertColumn } from "./ExpertColumn";
import { RelevantCompanyColumn } from "./RelevantCompanyColumn";
import { RelevantPositionColumn } from "./RelevantPositionColumn";
import { TenureColumn } from "./TenureColumn";
import { AngleColumn } from "./AngleColumn";
import { FollowUpCallStatusColumn } from "./FollowUpCallStatusColumn";
import { HideExpertColumn } from "./HideExpertColumn";
import { CidColumn } from "./CidColumn";

interface SurveyColumnDefinitionProps {
  showCallFollowUps: boolean;
  doubleBlinded: boolean;
  showCid: boolean;
}

export const columnDefinitions = ({ showCallFollowUps, doubleBlinded, showCid }: SurveyColumnDefinitionProps) => {
  return [
    CallToActionColumn,
    CommentColumn,
    showCid && CidColumn,
    TransIdColumn,
    SurveyStatusColumn,
    !doubleBlinded && ExpertColumn,
    !doubleBlinded && RelevantCompanyColumn,
    !doubleBlinded && RelevantPositionColumn,
    !doubleBlinded && TenureColumn,
    AngleColumn,
    showCallFollowUps && FollowUpCallStatusColumn,
    !doubleBlinded && HideExpertColumn,
  ].filter((e) => {
    return typeof e === "object";
  });
};
