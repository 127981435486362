import { useEffect } from "react";

const useScrollToRef = ({ refs, pageLoaded, selectedIndex }) => {
  useEffect(() => {
    if (pageLoaded && refs?.length) {
      const nextSelectedRef = refs[selectedIndex];
      if (nextSelectedRef?.current) {
        nextSelectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  });
};

export default useScrollToRef;
