import React, { useContext, useState, useCallback } from "react";
import { fetch } from "hooks/useApi";
import { useCurrentUser } from "@alphasights/portal-auth-react";

const FILTERED_USER_LIMIT = 4;

export interface ProjectMembersContextState {
  refresh: () => Promise<void>;
  csts: ProjectMember[];
  clients: ProjectMember[];
  loading: boolean;
  initialized: boolean;
}

export const ProjectMembersContext = React.createContext<ProjectMembersContextState>({
  refresh: () => Promise.resolve(),
  csts: [],
  clients: [],
  loading: false,
  initialized: false,
});

export const ProjectMembersProvider = ({
  projectToken,
  fetchFn = fetch,
  children,
  ...props
}: {
  projectToken: string;
  fetchFn?: typeof fetch;
  children: JSX.Element;
}) => {
  const currentUser = useCurrentUser();
  const [clients, setClients] = useState<ProjectMember[]>([]);
  const [csts, setCsts] = useState<ProjectMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const userFilter = (user: ProjectMember, filter: string) =>
    user.displayName.toLowerCase().includes(filter.toLowerCase());
  const getFilteredClients = useCallback(
    (filter: string) => {
      return clients.filter((client) => userFilter(client, filter)).slice(0, FILTERED_USER_LIMIT);
    },
    [clients]
  );
  const getFilteredCsts = useCallback(
    (filter: string) => {
      return csts.filter((client) => userFilter(client, filter)).slice(0, FILTERED_USER_LIMIT);
    },
    [csts]
  );

  const refresh = useCallback(() => {
    setLoading(true);
    setInitialized(true);
    return fetchFn({ url: `/api/projects/${projectToken}/members` })
      .then((resp) => resp.json() as Promise<ProjectMember[]>)
      .then((members) => {
        setClients(members.filter((m) => m.type === "CLIENT" && m.emailAddress !== currentUser?.email));
        setCsts(members.filter((m) => m.type === "INTERNAL" && m.emailAddress !== currentUser?.email));
        setLoading(false);
      });
  }, [projectToken, currentUser, fetchFn]);

  const value = { clients, csts, loading, refresh, initialized, getFilteredClients, getFilteredCsts, ...props };

  return <ProjectMembersContext.Provider value={value}>{children}</ProjectMembersContext.Provider>;
};

export const useProjectMembersContext = () => {
  const ctx = useContext(ProjectMembersContext);
  // Lazy fetch
  !ctx.initialized && ctx.refresh();
  return ctx;
};
