import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { StatusFull, StatusHalf, StatusOneQuarter, StatusPending } from "@alphasights/alphadesign-icons";
import React from "react";

const useFollowUpStyles = ({ newChipCount }: { newChipCount: boolean } = { newChipCount: false }) => {
  const {
    color: { background, text },
    flex: { gap },
    spacing: { inner },
  } = useThemeTokens();

  return {
    followUpDiv: {
      display: "flex",
      gap: gap.sm,
    },
    chipIcons: {
      available: (
        <Icon size="small" color="secondary">
          <StatusPending />
        </Icon>
      ),
      requested: (
        <Icon size="small" color="danger">
          <StatusOneQuarter />
        </Icon>
      ),
      scheduled: (
        <Icon size="small" color="scheduled">
          <StatusHalf />
        </Icon>
      ),
      completed: (
        <Icon size="small" color="success">
          <StatusFull />
        </Icon>
      ),
    },
    chipStyle: {
      textTransform: "capitalize",
      padding: `${inner.base} ${inner.base02}`,
      borderRadius: inner.base02,
    },
    chipColors: {
      available: {
        backgroundColor: `${background.neutral.default} !important`,
        color: text.strong._,
        border: {
          _: "none !important",
          hover: "none !important",
        },
        cursor: "pointer",
      },
      requested: {
        backgroundColor: `${newChipCount ? background.neutral.default : background.danger.default} !important`,
        color: text.danger,
        border: "none !important",
        cursor: "pointer",
      },
      scheduled: {
        backgroundColor: `${newChipCount ? background.neutral.default : background.processing.default} !important`,
        color: text.processing,
        border: "none !important",
        cursor: "pointer",
      },
      completed: {
        backgroundColor: `${newChipCount ? background.neutral.default : background.success} !important`,
        color: text.success,
        border: "none !important",
        cursor: "pointer",
      },
    },
  };
};

export default useFollowUpStyles;
