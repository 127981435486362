import { PrimerVersion } from "@alphasights/client-portal-shared";
import { NotableEvent, IdTitleDescription } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/overview";
import {
  CommercialModel,
  CommercialModelProperties,
  MarketSplit,
} from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/commercialModel";
import {
  AdjacentCompetitor,
  AdjacentCompetitorCompany,
  CompetitorTrend,
  Competitors,
  CoreCompetitor,
  MarketCompetitor,
} from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/competitors";
import { Section, Segment } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";
import { Outlook, OutlookProperties } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/outlook";

const replaceMatchingElements = <T extends { id: CitableValue<string> }>(fullList: T[], replacingItems: T[]): T[] => {
  replacingItems.forEach((replacingItem) => {
    fullList.splice(
      fullList.findIndex((replaceableItem) => replaceableItem.id.value === replacingItem.id.value),
      1,
      replacingItem
    );
  });

  return fullList;
};

const mergeSegmentations = (currentSegmentations: Section[], mentionsSegmentations: Section[]) => {
  const newMentionsSegmentation = mentionsSegmentations.map((section) => {
    const currentSection = currentSegmentations.find((currentSection) => currentSection.id.value === section.id.value);

    return {
      ...section,
      segments: currentSection
        ? replaceMatchingElements<Segment>([...currentSection.segments], section.segments)
        : section.segments,
    };
  });

  return replaceMatchingElements<Section>([...currentSegmentations], newMentionsSegmentation);
};

const mergeCommercialModelProperties = (
  currentComercialModelProperties: CommercialModelProperties,
  mentionsComercialModelProperties: CommercialModelProperties
): CommercialModelProperties => {
  const newCommercialModel = {
    ...currentComercialModelProperties,
  };

  newCommercialModel.summary = mentionsComercialModelProperties.summary;

  newCommercialModel.marketSplits = replaceMatchingElements<MarketSplit>(
    [...currentComercialModelProperties.marketSplits],
    mentionsComercialModelProperties.marketSplits
  );

  return newCommercialModel;
};

const mergeCommercialModel = (currentCommercialModel: CommercialModel, mentionsCommercialModel: CommercialModel) => {
  const newCommercialModel = {
    ...currentCommercialModel,
  };

  newCommercialModel.goToMarket = mergeCommercialModelProperties(
    newCommercialModel.goToMarket,
    mentionsCommercialModel.goToMarket
  );

  newCommercialModel.monetization.contracts = mergeCommercialModelProperties(
    newCommercialModel.monetization.contracts,
    mentionsCommercialModel.monetization.contracts
  );

  newCommercialModel.monetization.profitability = mergeCommercialModelProperties(
    newCommercialModel.monetization.profitability,
    mentionsCommercialModel.monetization.profitability
  );

  return newCommercialModel;
};

const mergeCompetitors = (currentCompetitors: Competitors, mentionsCompetitors: Competitors) => {
  const newCompetitors = {
    ...currentCompetitors,
  };
  newCompetitors.markets = replaceMatchingElements<MarketCompetitor>(
    [...newCompetitors.markets],
    mentionsCompetitors.markets
  );

  const newCoreCompetitors = mentionsCompetitors.core.map((competitor) => {
    const currentCoreCompetitor = newCompetitors.core.find(
      (coerCompetitor) => coerCompetitor.id.value === competitor.id.value
    );

    return {
      ...competitor,
      markets: currentCoreCompetitor
        ? replaceMatchingElements<CompetitorTrend>([...currentCoreCompetitor.markets], competitor.markets)
        : competitor.markets,
    };
  });

  newCompetitors.core = replaceMatchingElements<CoreCompetitor>([...newCompetitors.core], newCoreCompetitors);

  const newAdjacentCompetitors = mentionsCompetitors.adjacent.map((competitor) => {
    const currentAdjacentCompetitor = newCompetitors.adjacent.find(
      (adjacentCompetitor) => adjacentCompetitor.id.value === competitor.id.value
    );

    return {
      ...competitor,
      companies: currentAdjacentCompetitor
        ? replaceMatchingElements<AdjacentCompetitorCompany>(
            [...currentAdjacentCompetitor.companies],
            competitor.companies
          )
        : competitor.companies,
      markets: currentAdjacentCompetitor
        ? replaceMatchingElements<CompetitorTrend>([...currentAdjacentCompetitor.markets], competitor.markets)
        : competitor.markets,
    };
  });

  newCompetitors.adjacent = replaceMatchingElements<AdjacentCompetitor>(
    [...newCompetitors.adjacent],
    newAdjacentCompetitors
  );

  return newCompetitors;
};

const mergeOutlook = (currentOutlook: Outlook, mentionsOutlook: Outlook) => {
  const newOutlook = {
    ...currentOutlook,
    growthDrivers: replaceMatchingElements<OutlookProperties>(
      [...currentOutlook.growthDrivers],
      mentionsOutlook.growthDrivers
    ),
    opportunitiesAndRisks: replaceMatchingElements<OutlookProperties>(
      [...currentOutlook.opportunitiesAndRisks],
      mentionsOutlook.opportunitiesAndRisks
    ),
  };

  return newOutlook;
};

export const mergeMentionsPrimerWithActualPrimer = (
  currentPrimer: CompanyPrimerContentV1 | CompanyPrimerContentV2 | CompanyPrimerContentV3,
  mentionsPrimer: any
) => {
  if (
    [PrimerVersion.PRIMER_VERSION_1, PrimerVersion.PRIMER_VERSION_2].includes(
      currentPrimer.srmTypeVersion as PrimerVersion
    )
  ) {
    return { ...currentPrimer, ...mentionsPrimer };
  }

  let newPrimer = { ...currentPrimer } as CompanyPrimerContentV3;

  // merge overview
  const newMarketContextParticipants = replaceMatchingElements<IdTitleDescription>(
    [...newPrimer.overview.marketContext.participants],
    mentionsPrimer.overview.marketContext.participants
  );
  const newServicesAndProducts = replaceMatchingElements<IdTitleDescription>(
    [...newPrimer.overview.companyOverview.servicesAndProducts],
    mentionsPrimer.overview.companyOverview.servicesAndProducts
  );
  const newUseCases = replaceMatchingElements<IdTitleDescription>(
    [...newPrimer.overview.companyOverview.useCases],
    mentionsPrimer.overview.companyOverview.useCases
  );
  const newNotableEvents = replaceMatchingElements<NotableEvent>(
    [...newPrimer.overview.notableEvents],
    mentionsPrimer.overview.notableEvents
  );

  newPrimer.overview = {
    ...mentionsPrimer.overview,
    marketContext: {
      ...mentionsPrimer.overview.marketContext,
      participants: newMarketContextParticipants,
    },
    companyOverview: {
      servicesAndProducts: newServicesAndProducts,
      useCases: newUseCases,
    },
    notableEvents: newNotableEvents,
  };

  // merge segmentations
  if (newPrimer.segmentations) {
    newPrimer.segmentations = [...mergeSegmentations(newPrimer.segmentations, mentionsPrimer.segmentations)];
  }

  // merge commercial model
  newPrimer.commercialModel = mergeCommercialModel(newPrimer.commercialModel, mentionsPrimer.commercialModel);

  // merge competitors
  newPrimer.competitors = mergeCompetitors(newPrimer.competitors, mentionsPrimer.competitors);

  // merge outlook
  newPrimer.outlook = mergeOutlook(newPrimer.outlook, mentionsPrimer.outlook);

  return newPrimer;
};
