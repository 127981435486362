import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const WatchlistSettingsContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    gap: inner.base04,
    paddingTop: inner.base04,
    display: "flex",
    flexDirection: "column" as "column",
  };
});

const AlertFrequencyContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    gap: inner.base02,
    paddingBlock: inner.base,
  };
});

const TextFieldContainer = styled.div`
  div:last-child {
    padding-top: 0;
  }
`;

export { WatchlistSettingsContainer, AlertFrequencyContainer, TextFieldContainer };
