import { IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { Refresh } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import React, { useState } from "react";

export const SynthesisReprocessButton = ({ angleTypeName }: { angleTypeName?: string }) => {
  const permissions = useCurrentUser()?.permissions || [];
  const { reprocess } = useProjectSynthesisContext();
  const [enabled, setEnabled] = useState(true);

  const handleRefresh = () => {
    setEnabled(false);
    reprocess({ angleTypeName });
    alert("Please wait a couple of minutes then refresh the page.");
  };

  if (!permissions.includes("access_transcripts_and_recordings")) return null;

  return (
    <Tooltip variant="dark" title={angleTypeName ? `Reprocess ${angleTypeName}` : "Reprocess all project"}>
      <IconButton
        onClick={handleRefresh}
        disabled={!enabled}
        variant="outline"
        size="small"
        testId="synthesis-header-refresh"
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  );
};
