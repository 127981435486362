import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCaptchaPageStyles = () => {
  const {
    color: { text, border },
    spacing: { inner, layout },
  } = useThemeTokens();

  const modal = {
    slotWidth: "442px",
    slotHeight: "680px",
  };

  const modalWrapper = {
    display: "flex",
    gap: inner.base06,
    flexDirection: "column",
    alignItems: "stretch",
    textAlign: "center",
    mx: inner.base12,
  };

  const captcha = {
    alignSelf: "center",
  };

  const returnButton = {
    borderColor: border.neutral.default,
  };

  const title = {
    marginTop: layout.base08,
    color: text.strong._,
  };

  const description = {
    color: text.strong._,
  };

  const buttonDiv = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  const footer = {
    marginTop: { _: "0", md: "56px" },
  };

  return {
    modal,
    modalWrapper,
    captcha,
    returnButton,
    title,
    description,
    buttonDiv,
    footer,
  };
};
