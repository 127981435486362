import { useThemeTokens } from "@alphasights/alphadesign-components";

export const usePurchaseAlphaGPTAccessModalStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const modalDiv = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base06,
  };

  const buttonBar = {
    marginLeft: "auto",
    marginRight: "0",
  };

  const cancelButton = {
    marginRight: inner.base06,
  };

  return {
    modalDiv,
    buttonBar,
    cancelButton,
  };
};
