import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const tokens = useThemeTokens();

  return {
    connectedWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    buttonsWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base04,
    },
    connectionDataWrapper: {
      flex: 0.5,
      display: "flex",
      gap: tokens.spacing.inner.base04,
      alignItems: "center",
      justifyContent: "center",
    },
    connectedLabelWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base02,
      alignItems: "center",
      flex: "1",
    },
    callDuration: {
      flex: "1",
      textAlign: "center",
    },
    disconnectBtn: {
      flex: 0.5,
    },
    alert: {
      mb: tokens.spacing.inner.base04,
    },
  };
};
