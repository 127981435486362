import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useExpertChargesListStyles = () => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const container = {
    gap: inner.base02,
  };

  const expertDiv = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pt: inner.base,
    gap: inner.base02,
  };

  const expertName = {
    color: text.strong._,
  };

  return {
    container,
    expertName,
    expertDiv,
  };
};
