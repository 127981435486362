import { INITIAL_WATCHLIST_PAYLOAD, DEFAULT_WATCHLIST_NAME } from "./consts";
import { PlaceholderWatchlistDataProps, WatchlistDataProps } from "./types";

const getOrderedWatchlists = (availableWatchlists: WatchlistDataProps[]) =>
  availableWatchlists.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

const getInitialWatchlistData = (availableWatchlists: WatchlistDataProps[] | PlaceholderWatchlistDataProps[]) => {
  const newWatchlists = availableWatchlists
    ?.map(({ name }: { name: string }) => name)
    ?.filter((watchlistName: string) => watchlistName.startsWith(DEFAULT_WATCHLIST_NAME));

  const numNewWatchlists = newWatchlists?.length ?? 0;
  const newWatchlistName =
    numNewWatchlists > 0 ? `${DEFAULT_WATCHLIST_NAME} ${numNewWatchlists + 1}` : DEFAULT_WATCHLIST_NAME;

  const watchlistData = {
    ...INITIAL_WATCHLIST_PAYLOAD,
    name: newWatchlistName,
  };

  return watchlistData;
};

export { getInitialWatchlistData, getOrderedWatchlists };
