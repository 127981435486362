import React from "react";
import { Popover } from "../Popover";
import { Typography, Avatar, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const HighlyRecommendedPopover = ({ reason, primaryContact, children }) => {
  const hasCustomReason = reason && primaryContact;

  return (
    <Popover
      disableArrow={true}
      borderless={true}
      style={hasCustomReason ? { width: 360, padding: 0 } : {}}
      className="aui-border aui-border-solid aui-border-grey-2 aui-rounded"
      body={hasCustomReason ? <CustomReason reason={reason} primaryContact={primaryContact} /> : <DefaultReason />}
    >
      {children}
    </Popover>
  );
};

const DefaultReason = () => (
  <Typography variant="body-small" component="div" className="aui-text-center aui-max-w-xs">
    This expert is highly recommended.
  </Typography>
);

export const CustomReason = ({ reason, primaryContact }) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" p={inner.base03}>
      <Typography component="span">
        <Typography variant="body-em" component="span">
          Highly Recommended:{" "}
        </Typography>
        {reason}
      </Typography>
      <x.div pt={inner.base02} display="flex" alignItems="center">
        <Avatar size="small" src={primaryContact.avatar.thumbUrl} text={primaryContact.name} />
        <x.div pl={inner.base02}>
          <Typography component="span" color={text.secondary}>
            {primaryContact.name}
          </Typography>
        </x.div>
      </x.div>
    </x.div>
  );
};
