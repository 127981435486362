function unwrapEmTags(node: HTMLElement) {
  function unwrapEmTagsRecursive(element: Node) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      const el = element as HTMLElement;
      if (el.tagName.toLowerCase() === "em") {
        const parent = el.parentNode;
        if (parent) {
          const siblings = [...parent.childNodes].filter((ea) => (ea as HTMLElement).tagName?.toLowerCase() === "em");
          (parent as HTMLElement).innerHTML = siblings.reduce(
            (agg, em) => agg.replaceAll((em as HTMLElement).outerHTML, (em as HTMLElement).innerHTML),
            (parent as HTMLElement).innerHTML
          );
        }
      } else {
        el.childNodes.forEach((child) => unwrapEmTagsRecursive(child));
      }
    }
  }
  unwrapEmTagsRecursive(node);
}

function wrapWordsInEm(node: HTMLElement, keywords: string[]): number {
  const escapedWords = keywords.map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  const regex = new RegExp(`(?<=\\W|^)(${escapedWords.join("|")})(?=\\W|$)`, "gi");
  var matchCount = 0;
  function wrapNodeTextRecursive(element: Node) {
    if (element.nodeType === Node.TEXT_NODE) {
      const text = element.textContent;
      if (text && regex.test(text)) {
        const newContent = text.replace(regex, "<em data-transcript-highlight>$&</em>");
        matchCount = matchCount + (newContent.split("</em>").length - 1);
        const parentHtml = element.parentNode as HTMLElement;
        if (parentHtml) parentHtml.innerHTML = parentHtml.innerHTML.replaceAll(text, newContent);
      }
    } else {
      element.childNodes.forEach(
        (child) => (child as HTMLElement).tagName?.toLowerCase() !== "em" && wrapNodeTextRecursive(child)
      );
    }
  }
  wrapNodeTextRecursive(node);
  return matchCount;
}

function decodeHTMLEntities(html: string): string {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = html;
  return textarea.value;
}

// Highlights the string range provided in the selector node keeping
// its children structure.
// Removes the tags after the highlighting finishes.
async function highlightRange(
  selector: string,
  {
    ixStart,
    length,
    tagOpen = "++",
    tagClose = "--",
    elementsSelector,
  }: { ixStart: number; length: number; tagOpen?: string; tagClose?: string; elementsSelector?: string }
) {
  const node = document.querySelector(selector);
  if (!node) return;

  const ixEnd = ixStart + length;
  let currentIndex = 0;

  function wrap(content: string, start: number, end: number): string {
    return content.slice(0, start) + "+mark+" + content.slice(start, end) + "-mark-" + content.slice(end);
  }

  function processTextNode(textNode: Text) {
    const content = textNode.textContent ?? "";
    const localStart = Math.max(ixStart - currentIndex, 0);
    const localEnd = Math.min(ixEnd - currentIndex, content.length);
    const withinRange = ixStart <= currentIndex + content.length && ixEnd >= currentIndex;

    if (withinRange) {
      const parent = textNode.parentNode as HTMLElement;
      const wrappedContent = wrap(content, localStart, localEnd);
      const parentHTMLDecoded = decodeHTMLEntities(parent.innerHTML);

      parent.innerHTML = parentHTMLDecoded.replace(content, wrappedContent);
    }
    currentIndex += content.length;
  }

  function processNode(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      processTextNode(node as Text);
    } else {
      node.childNodes.forEach(processNode);
    }
  }

  processNode(node);

  node.innerHTML = node.innerHTML.replace(/\+mark\+/g, tagOpen).replace(/-mark-/g, tagClose);

  if (!elementsSelector) return;

  const elements = [...document.querySelectorAll(elementsSelector)] as HTMLElement[];

  if (elements.length === 0) return;

  elements[0].scrollIntoView && elements[0].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });

  await Promise.all(elements.map((el) => fadeHighlight(el as HTMLElement, 2000)));

  node.innerHTML = elements.reduce((acc, el) => {
    return acc.replaceAll(el.outerHTML, el.innerHTML);
  }, node.innerHTML);
}

const highlightWordsInElement = (targetEl: HTMLElement, keywords: string[], clearBody: boolean = true) => {
  if (!targetEl || !keywords) return 0;
  unwrapEmTags(clearBody ? document.body : targetEl);
  const matchCount = (keywords.length && wrapWordsInEm(targetEl, keywords)) || 0;
  return matchCount;
};

const fadeHighlight = (element: HTMLElement, timeout: number) => {
  if (!element.style || !element.animate) return;

  const color = (opacity: number) => `rgba(49, 129, 255, ${opacity})`;
  element.style.backgroundColor = color(0.5);
  element.animate(
    {
      backgroundColor: color(0),
    },
    timeout
  );
  return new Promise<void>((res) => {
    setTimeout(() => {
      element.style.backgroundColor = color(0);
      res();
    }, timeout - 50);
  });
};

const focusOnElementText = (selector: string | Element, text?: string) => {
  const cue = typeof selector === "string" ? document.querySelector(selector) : selector;
  var betterCue = undefined;
  if (cue && text && cue.parentNode?.textContent) {
    const paragraphText = cue.parentNode.textContent.replaceAll(/<\/?[^>]+(>|$)/g, "");
    const splitted = paragraphText?.split(text.replaceAll(/<\/?[^>]+(>|$)/g, ""));
    const estimateOffsetPct = splitted[0].length / paragraphText?.length;
    const bits = cue.parentNode?.childNodes;
    const idx = Math.floor((bits.length - 1) * estimateOffsetPct);
    betterCue = splitted.length > 1 && ([...bits][idx] as HTMLElement);
  }
  const target = (betterCue ?? cue) as HTMLElement;
  if (target) {
    target.scrollIntoView && target.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
    fadeHighlight(target, 2000);
  }
};

export { highlightWordsInElement, focusOnElementText, highlightRange };
