import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon, Skeleton, Loading, Popover, TextField, Tooltip, ListOption } from "@alphasights/alphadesign-components";
import { Add, Delete, Edit } from "@alphasights/alphadesign-icons";
import { useLabelsContext } from "providers/LabelsProvider";
import { x } from "@xstyled/styled-components";
import { useManageLabelsPopoverStyles } from "./ManageLabelsPopover.styles";
import useOnClickOutside from "hooks/useOnClickHooks";
import { ConfirmationResponsiveModal } from "components/ResponsiveModal";
import _ from "lodash";

export const LABEL_MAX_LENGTH = 16;

interface ManageLabelsPopoverProps {
  anchorRef?: React.MutableRefObject<HTMLElement | null>;
  open: boolean;
  onClose: () => void;
  interactions: Interaction[];
}

export const ManageLabelsPopover = ({ anchorRef, open, onClose, interactions }: ManageLabelsPopoverProps) => {
  const [selectedLabelId, setSelectedLabelId] = useState<string | undefined>(undefined);
  const { radioGroup } = useManageLabelsPopoverStyles();
  const ref = useRef(null);
  const { createLabel, getLabelledExpert, addLabel, labels } = useLabelsContext();
  const labelledExperts = useMemo(
    () =>
      _.compact(
        interactions.map((interaction) => {
          const expertId = interaction.advisorId;
          const angleId = interaction.angles[0].id;
          return angleId ? getLabelledExpert(expertId, angleId) : undefined;
        })
      ),
    [getLabelledExpert, interactions]
  );

  useEffect(() => {
    const groupedLabelledExperts = _.groupBy(labelledExperts, "label.id");
    if (Object.keys(groupedLabelledExperts).length === 1 && labelledExperts.length === interactions.length) {
      setSelectedLabelId(Object.keys(groupedLabelledExperts)[0]);
      return;
    }
    setSelectedLabelId(undefined);
  }, [interactions, labelledExperts]);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onCreateLabel = (value: string) => {
    const existingLabel = labels.find((label) => label.text === value);
    return existingLabel ? onAddLabel(existingLabel.id) : createLabel(value).then((label) => onAddLabel(label.id));
  };

  const onAddLabel = (labelId: string) => {
    setSelectedLabelId(labelId);
    const experts = interactions
      .map((interaction) => ({
        expertId: interaction.advisorId,
        angleId: interaction.angles[0].id,
      }))
      .filter(({ angleId }) => angleId !== undefined);

    addLabel(labelId, experts as { expertId: string; angleId: string }[]);
  };

  useOnClickOutside(_.compact([ref, anchorRef]), () => !isConfirmationModalOpen && onClose());

  return (
    <Popover
      size="small"
      anchorEl={anchorRef?.current ?? undefined}
      open={open}
      onClick={(event) => event.stopPropagation()}
      data-testid="manage-labels-popover"
      title="Label"
      closeOnClickOutside={false}
      ref={ref}
      zIndex={2}
    >
      <x.div {...radioGroup}>
        {labels?.map((label) => (
          <Label
            label={label}
            key={`label-${label.id}`}
            setConfirmationModalOpen={setConfirmationModalOpen}
            selected={label.id === selectedLabelId}
            onAddLabel={(labelId: string) => onAddLabel(labelId)}
          />
        ))}
      </x.div>
      <LabelTextField onApply={onCreateLabel} />
    </Popover>
  );
};

const Label = ({
  label,
  setConfirmationModalOpen,
  selected,
  onAddLabel,
}: {
  label: Label;
  setConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  selected: boolean;
  onAddLabel: (labelId: string) => void;
}) => {
  const { editLabel, labelledExperts } = useLabelsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newLabel, setNewLabel] = useState(label.text);
  const groupedLabelledExperts = useMemo(() => _.groupBy(labelledExperts, "label.id"), [labelledExperts]);

  const onEditLabel = useCallback(
    (newLabel: string) => {
      if (groupedLabelledExperts[label.id]?.length > 1) {
        setIsModalOpen(true);
        setNewLabel(() => newLabel);
      } else {
        setIsLoading(true);
        editLabel(label.id, newLabel).then(() => {
          setIsLoading(false);
          setIsEditing(false);
        });
      }
    },
    [editLabel, groupedLabelledExperts, label.id]
  );

  const onCancelEditLabel = () => {
    setIsEditing(false);
    setIsModalOpen(false);
  };

  const onConfirmEditlabel = () => {
    setIsLoading(true);
    editLabel(label.id, newLabel).then(() => {
      setIsLoading(false);
      setIsEditing(false);
      setIsModalOpen(false);
    });
  };

  useEffect(() => {
    setConfirmationModalOpen(isModalOpen);
  }, [isModalOpen, setConfirmationModalOpen]);

  const EditLabelButton = () => (
    <Tooltip title="Edit label for all" variant="dark" size="small" position="bottom">
      <Icon
        color="secondary"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          setIsEditing(true);
        }}
      >
        <Edit data-testid="edit-label-button" />
      </Icon>
    </Tooltip>
  );

  return (
    <x.div data-testid={`label-${label.id}`}>
      {isLoading ? (
        <Skeleton variant="noMargin" />
      ) : isEditing ? (
        <x.div>
          <LabelTextField label={label.text} onApply={(value) => onEditLabel(value)} />
        </x.div>
      ) : (
        <ListOption
          type="text"
          inputType="radio"
          label={truncateLabel(label.text)}
          rightIcon={<EditLabelButton />}
          secondaryRightIcon={<DeleteLabelButton label={label} setConfirmationModalOpen={setConfirmationModalOpen} />}
          selected={selected}
          onChange={() => onAddLabel(label.id)}
        />
      )}
      <ConfirmationResponsiveModal
        title="Edit Label"
        message="Are you sure you want to edit this label? This will edit the label for all experts."
        confirmText="Save"
        cancelText="Cancel"
        open={isModalOpen}
        onClose={onCancelEditLabel}
        onClick={onConfirmEditlabel}
        isLoading={false}
        testId="edit-label-confirmation-modal"
      />
    </x.div>
  );
};

const DeleteLabelButton = ({
  label,
  setConfirmationModalOpen,
}: {
  label: Label;
  setConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { deleteLabel, labelledExperts } = useLabelsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const groupedLabelledExperts = useMemo(() => _.groupBy(labelledExperts, "label.id"), [labelledExperts]);

  const onClickDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (groupedLabelledExperts[label.id]?.length > 1) {
        setIsModalOpen(true);
      } else {
        setIsLoading(true);
        deleteLabel(label.id).then(() => {
          setIsLoading(false);
        });
      }
    },
    [deleteLabel, groupedLabelledExperts, label.id]
  );

  const onConfirmDeleteLabel = useCallback(() => {
    setIsLoading(true);
    return deleteLabel(label.id).then(() => {
      setIsLoading(false);
    });
  }, [deleteLabel, label.id]);

  useEffect(() => {
    setConfirmationModalOpen(isModalOpen);
  }, [isModalOpen, setConfirmationModalOpen]);

  return (
    <>
      {isLoading ? (
        <Loading size="sm" />
      ) : (
        <Tooltip title="Delete label for all" variant="dark" size="small" position="bottom">
          <Icon onClick={onClickDelete} color="secondary">
            <Delete data-testid={`delete-label-${label.text}`} />
          </Icon>
        </Tooltip>
      )}
      {isModalOpen && (
        <ConfirmationResponsiveModal
          title="Delete Label"
          message="Are you sure you want to delete this label? This will delete the label for all experts."
          confirmText="Delete"
          cancelText="Cancel"
          onClose={() => setIsModalOpen(false)}
          onClick={onConfirmDeleteLabel}
          isLoading={isLoading}
          testId="delete-label-confirmation-modal"
          open
        />
      )}
    </>
  );
};

const LabelTextField = ({
  label = "",
  onApply: onApplyInput,
}: {
  label?: string;
  onApply: (value: string) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newLabel, setNewLabel] = useState(label);
  const [error, setError] = useState<string | undefined>(undefined);
  const { labelTextField } = useManageLabelsPopoverStyles({ error });

  const onSetNewLabel = (value: string) => {
    if (value.trim().length > 35) {
      setError("35 character limit");
    } else {
      setError(undefined);
      setNewLabel(value.trim());
    }
  };

  const onApply = () => {
    if (!error && newLabel) {
      onApplyInput(newLabel);
      if (!label) setNewLabel("");
    }
  };

  useEffect(() => {
    ref.current && (ref.current as any).focus();
  }, []);

  return (
    <TextField
      size="small"
      value={newLabel}
      endAdornment={
        <Icon onClick={onApply} color="secondary" data-testid="add-label-button">
          <Add />
        </Icon>
      }
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSetNewLabel(e.target.value)}
      onKeyPress={(e) => {
        e.key === "Enter" && onApply();
      }}
      error={!!error}
      errorText={error}
      ref={ref}
      placeholder="Add label"
      data-testid="label-text-field"
      {...labelTextField}
    />
  );
};

export const truncateLabel = (label: string) => label?.replace(/(.{16})..+/, "$1…");
