export enum OutlookType {
  opportunity = "Opportunity",
  risk = "Risk",
}

export enum PrimerVersion {
  PRIMER_VERSION_1 = "PRIMER_V1",
  PRIMER_VERSION_2 = "PRIMER_V2",
  PRIMER_VERSION_3 = "PRIMER_V3",
}
export enum CustomerDynamicsLabels {
  SEGMENT = "SEGMENT",
  REVENUE_SPLIT = "REVENUE SPLIT",
  PENETRATION = "PENETRATION",
  TREND = "TREND",
  KEY_CUSTOMERS = "EXAMPLE CUSTOMERS",
}

export enum CommissionPrimerLabels {
  COMPANY = "COMPANY",
  FORMAT = "FORMAT",
  TYPE = "TYPE",
  TIMELINE = "TIMELINE",
  CREDITS = "CREDITS",
}
export enum PrimerTypes {
  ALPHA_NOW = "AlphaNow",
  PRIVATE = "Private",
}

export enum CommissionPrimerCredits {
  COMPANY_PRIMER = 3,
  MARKET_PRIMER = 6,
  CUSTOMER_PRIMER = 6,
  COMPANY_PRIMER_RUSH = 5,
  MARKET_PRIMER_RUSH = 8,
  CUSTOMER_PRIMER_RUSH = 8,
}

//TODO CHECK REAL VALUES
export enum CommissionPrivatePrimerCredits {
  COMPANY_PRIMER = 10,
  MARKET_PRIMER = 20,
  CUSTOMER_PRIMER = 20,
  COMPANY_PRIMER_RUSH = 12,
  MARKET_PRIMER_RUSH = 22,
  CUSTOMER_PRIMER_RUSH = 22,
}
