import React, { FC } from "react";
import {
  IconButton,
  useThemeTokens,
  IconButtonVariant,
  IconButtonSizes,
  Loading,
} from "@alphasights/alphadesign-components";
import { VolumeUp } from "@alphasights/alphadesign-icons";

interface AudioButtonProps {
  onAudioClick: () => void;
  audioDownloadStatus?: string;
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
}

const AudioButton: FC<AudioButtonProps> = ({
  onAudioClick,
  audioDownloadStatus,
  variant = "basic",
  size = "medium",
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  if (audioDownloadStatus === "idle") return null;

  return (
    <>
      {audioDownloadStatus === "loading" ? (
        <Loading size="sm" padding={inner.base} mx={{ xs: inner.base03, sm: 0 }} />
      ) : (
        <IconButton
          testId="audio-button"
          onClick={onAudioClick}
          color={text.secondary}
          disabled={audioDownloadStatus === "error"}
          variant={variant}
          size={size}
        >
          <VolumeUp data-testid="audio-play-button" />
        </IconButton>
      )}
    </>
  );
};

export default AudioButton;
