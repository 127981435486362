import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

type ChartLegendProps = {
  elements: {
    color: string;
    label: string;
  }[];
};

const ChartLegend = ({ elements }: ChartLegendProps) => {
  const { color: auiColor, spacing } = useThemeTokens();

  return (
    <x.div display="flex" columnGap={spacing.inner.base04} justifyContent="center" pt={spacing.inner.base04}>
      {elements.map(({ color, label }) => (
        <x.div key={label}>
          <span
            style={{
              verticalAlign: "middle",
              display: "inline-block",
              borderRadius: "50%",
              width: spacing.inner.base02,
              height: spacing.inner.base02,
              marginRight: spacing.inner.base,
              backgroundColor: color,
            }}
          ></span>
          <Typography component="span" color={auiColor.text.strong._} variant="body-small">
            {label}
          </Typography>
        </x.div>
      ))}
    </x.div>
  );
};

export default ChartLegend;
