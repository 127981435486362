import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, Ref, useRef } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";

import "./index.css";

export const DataTestIds = {
  Input: "search-input",
};

export type InputProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;

  size?: SearchSizeVariant;
  placeholder?: string;
  value?: string;
  isLastItem?: boolean;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
};

const Input = React.forwardRef(
  (
    {
      size = SearchSizeVariant.Medium,
      placeholder = "",
      value = "",
      isLastItem = false,
      onChange,
      onKeyDown,
      onPaste,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { spacing } = useThemeTokens();
    const { current: styles } = useRef(STYLE_CONFIG[size]);
    const {
      typography: typographyStyles,
      input: { height: minHeight },
    } = styles;

    const width = placeholder ? "-webkit-fill-available" : `${value.length + 1}ch`;

    const handlePaste = async (event: ClipboardEvent<HTMLInputElement>) => {
      if (onPaste) {
        event.preventDefault();
        onPaste(event);
      }
    };

    return (
      <x.input
        data-testid={DataTestIds.Input}
        ref={ref}
        id="search-input"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={handlePaste}
        minH={minHeight}
        w={width}
        mr={isLastItem ? spacing.inner.base : 0}
        outline="none"
        autoComplete="off"
        size={0}
        {...typographyStyles}
      />
    );
  }
);

export default Input;
