import styled from "styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const LandingPageContentContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    flexGrow: 1,
    width: 0,
    overflow: "auto" as "auto",
    padding: spacing.layout.base03,
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base04,
  };
});

const CarouselsContainer = styled.div(() => {
  const { spacing, shape } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base,
    "div: last-of-type": {
      borderBottomLeftRadius: shape.border.radius.medium,
      borderBottomRightRadius: shape.border.radius.medium,
    },
    "div:first-of-type": {
      borderTopLeftRadius: shape.border.radius.medium,
      borderTopRightRadius: shape.border.radius.medium,
    },
  };
});

export { LandingPageContentContainer, CarouselsContainer };
