import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const SectionWrapper = styled.div(({ px, py }: { px?: string; py?: string }) => {
  const {
    spacing: { inner },
    color: { base },
  } = useThemeTokens();

  return {
    borderRadius: 10,
    border: `1px solid ${base.grey[200]}`,
    backgroundColor: base.white,
    padding: `${py ?? inner.base06} ${px ?? "0px"}`,
  };
});

export default SectionWrapper;
