import { useEffect, useState } from "react";

export const useHiddenBodyOverflow = (enable = true) => {
  const [originalOverflow] = useState(window.getComputedStyle(document.body).overflow);

  useEffect(() => {
    if (enable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalOverflow;
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [enable]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useHiddenBodyOverflow;
