export const PROPERTIES: Record<string, string> = {
  goToMarket: "goToMarket",
  description: "description",
  monetization: "monetization",
};

export const LABEL_TYPES = {
  [PROPERTIES.goToMarket]: "Go-to-market",
  [PROPERTIES.description]: "Description",
  [PROPERTIES.monetization]: "Monetization",
};

export const PROPERTY_ORDER: string[] = [PROPERTIES.description, PROPERTIES.goToMarket, PROPERTIES.monetization];
