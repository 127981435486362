import React from "react";
import { MarketSplit } from "../../types/commercialModel";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CitationWrapper } from "pages/AlphaNowPage/primers/components";
import MarketSplits from "./MarketSplits";

type PropTypes = {
  label: string;
  data: {
    summary: V3Summary;
    marketSplits: MarketSplit[];
  };
  heading?: boolean;
  isGoToMarket?: boolean;
};

const CommercialModelSection = ({ label, data, heading = false, isGoToMarket = false }: PropTypes) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base06} px={inner.base10}>
      <CitationWrapper
        label={label}
        value={data.summary.content}
        verticalLabel
        titleProperties={{
          variant: heading ? "body-large-em" : "body",
          color: text.secondary,
        }}
      />
      <MarketSplits splits={data.marketSplits} isGoToMarket={isGoToMarket} />
    </x.div>
  );
};

export default CommercialModelSection;
