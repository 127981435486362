import React, { useState } from "react";
import { isPast, parseISO } from "date-fns";
import { useLockedExpert } from "hooks/useLockedExpert";
import {
  CalendarPopup,
  ExpertData,
  PopupDataArea,
  PopupDataAreaLeftPad,
  PopupMenus,
  ScheduledCallTime,
  SelectAttendees,
} from "./PopupRenderers/common";
import { InteractionRequestSection } from "./InteractionRequests/InteractionRequestSection";
import { accessCodeForHuman, CallMeButton, CallMeModal } from "components/CallMe";
import { CtaButton, FollowUpButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { MobilePhone } from "@alphasights/alphadesign-icons";
import { enrichInteraction } from "pages/InteractionPage";
import { HitOrigin } from "@alphasights/portal-api-client";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { MessageType } from "types";
import { onGracePeriod } from "pages/InteractionPage/utils";

export const ScheduledInteractionPopup = ({ event, onClose, onCancel, onPopupClose }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { interaction, projectToken, requestPending } = event;
  const { disableAction } = useLockedExpert(interaction);

  const richInteraction = enrichInteraction({ interaction });

  const cancelLabel = onGracePeriod(interaction) ? "Cancel" : "Request to cancel";
  const rescheduleLabel = onGracePeriod(interaction) ? "Reschedule" : "Request to reschedule";
  const menuActions = [];

  (!requestPending || requestPending.type !== "CANCEL_REQUEST") && menuActions.push(cancelLabel);

  event.deps.onRequestReschedule && !disableAction && !requestPending && menuActions.push(rescheduleLabel);

  const runAction = (actionName) => {
    actionName === cancelLabel && onCancel && onCancel(interaction.id);
    actionName === rescheduleLabel &&
      (() => {
        onPopupClose && onPopupClose();
        return event.deps.onRequestReschedule(interaction.id);
      })();
  };
  const pastScheduledTime = isPast(parseISO(interaction.scheduledCallTime));
  const onOpenFlyout = () => {
    event.deps.onSelectCard(event.interaction);
    onClose();
  };
  const [isCallMeModalOpen, setIsCallMeModalOpen] = useState(false);
  event.isModalOpen = isCallMeModalOpen;
  event.setIsModalOpen = setIsCallMeModalOpen;

  return (
    <CalendarPopup data-testid={`interaction-slot-popup-${interaction.id}`}>
      <PopupMenus
        showMenu={!pastScheduledTime && menuActions.length > 0}
        items={menuActions}
        onItemClick={runAction}
        onClose={onClose}
      />

      <ExpertData interaction={interaction} requestPending={requestPending} onOpenFlyout={onOpenFlyout} />
      <ScheduledCallTime date={interaction.scheduledCallTime || event.start} duration={interaction.expectedDuration} />

      {interaction.state === "scheduled" && (
        <>
          <SelectAttendees interaction={interaction} onSave={(args) => event.deps.onAttendeesUpdated(args)} />
          <x.div mt={inner.base04} display="flex" alignItems="center" gap={inner.base04}>
            <JoinCallSection event={event} />
            {!event.needComplianceReview && (
              <CtaButton
                variant="outline"
                onClick={() => event.deps.onSendNewMessage({ interaction: [interaction], type: MessageType.CallGuide })}
                size="small"
              >
                Send Call Guide
              </CtaButton>
            )}
          </x.div>
        </>
      )}

      {interaction.state === "completed" && !interaction.followUpId && (
        <PopupDataAreaLeftPad marginTop="base04">
          <FollowUpButton
            interaction={richInteraction}
            hasClientPortalMessages={event.hasClientPortalMessages}
            token={projectToken}
            onRequestFollowUp={() => {
              event.deps.onRequestFollowUp({
                id: interaction.id,
                origin: HitOrigin.calendarViewPopup,
              });
            }}
            backgroundSyncRunning={event.backgroundSyncRunning}
            singleButton={interaction.isSurvey}
            size="medium"
            data-testid="follow-up-button"
          />
        </PopupDataAreaLeftPad>
      )}

      <InteractionRequestSection
        interaction={interaction}
        onCancelPendingRequest={event.deps.onCancelPendingRequest}
        requestPending={requestPending}
        variant="calendar-popup-v1"
      />
    </CalendarPopup>
  );
};

const JoinCallSection = ({ event }) => {
  const {
    newspeakProvider,
    clientAccessNumber,
    clientAccessCode,
    dialInNumbers,
    projectToken,
    callMeUrl,
    id: interactionId,
  } = event.interaction;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PopupDataArea
        icon={<MobilePhone />}
        addMarginTop={false}
        firstLine={
          <CallMeButton
            callMeUrl={callMeUrl}
            provider={newspeakProvider}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            projectToken={event.token}
            variant={"link"}
            interaction={event.interaction}
          />
        }
      />
      <CallMeModal
        id="call-me-modal"
        mainNumber={clientAccessNumber}
        accessCode={accessCodeForHuman(clientAccessCode, newspeakProvider)}
        otherNumbers={dialInNumbers}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        projectToken={projectToken}
        interactionId={interactionId}
        newspeakProvider={newspeakProvider}
        callMeUrl={callMeUrl}
      />
    </>
  );
};
