import React, { ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import { Tooltip } from "@alphasights/alphadesign-components";

type ConditionalTooltipProps = {
  tooltip: string;
  children: ReactNode;
};

const ConditionalTooltip = ({ tooltip, children }: ConditionalTooltipProps) => {
  if (tooltip && tooltip.length > 0) {
    return (
      <Tooltip variant="dark" title={tooltip} position="bottom">
        <x.div display="inline-flex">{children}</x.div>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

export default ConditionalTooltip;
