import { differenceInSeconds } from "date-fns";

export const eventHandler = (input) => (type, args) => {
  const { deps } = input;

  if (eventHandlers[type]) {
    return eventHandlers[type](input, args);
  } else if (deps[type]) {
    return deps[type](args);
  }

  return Promise.resolve();
};

const selectEvent = ({ setPreselected }, args) => {
  if (!args?.preselected) {
    setPreselected(
      args && {
        id: args.id,
        type: args.source,
        start: args.start,
        duration: differenceInSeconds(args.end, args.start),
        selectedType: args.subtype,
        label: args.label,
      }
    );
  }
};

const eventHandlers = {
  selectEvent,
};
