import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useOneOffApprovalPanelStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const amendAlert = {
    display: "flex",
    marginBottom: inner.base04,
  };

  const oneOffAlert = {
    display: "flex",
    marginBottom: inner.base04,
  };

  return {
    amendAlert,
    oneOffAlert,
  };
};
