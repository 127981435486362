import React, { useCallback, useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { ExpertLabel } from "components/ExpertLabel";
import { MultipleSelect } from "components/MultipleSelect";
import { TextBox } from "components/TextBox";
import { useOnClick } from "hooks/useOnClickHooks";
import { SendCallGuideModal } from "components/SendMessageModals";
import { useCallGuideSectionStyles } from "./CallGuideSection.styles";
import { Attachment, Expert } from "types";
import {
  Alert,
  Link,
  SelectItem,
  Typography,
  useAlphaToast,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { searchSync } from "components/InteractionsPage/reducer";
import { find } from "lodash";
import { MobileAttachmentsList, MobileTextBox } from "components/MobileTextBox";

const CALL_GUIDE_SIZE_LIMIT = 300;
interface CallGuideSectionProps {
  allExperts: Expert[];
  selectedExperts: Expert[];
  setSelectedExperts: Function;
  handleSubmit: (...args: any) => Promise<void>;
  switchToWorkRequest: () => void;
  isLoading: boolean;
  blinded: boolean;
  sendMessageButtonRef?: React.MutableRefObject<any>;
  externalLinkLabel?: string;
  onClickExternalLink?: () => void;
  messageText: string;
  setMessageText: (messageText: string) => void;
  mobileVariant?: boolean;
  onMessageSent?: () => void;
  attachTextBoxToNavbar?: boolean;
  onMobileTextBoxHeightChange?: (newHeight: number) => void;
}

export const CallGuideSection: React.FC<CallGuideSectionProps> = ({
  allExperts = [],
  selectedExperts = [],
  setSelectedExperts,
  handleSubmit,
  switchToWorkRequest,
  isLoading,
  blinded,
  sendMessageButtonRef,
  externalLinkLabel,
  onClickExternalLink,
  messageText,
  setMessageText,
  mobileVariant = false,
  onMessageSent,
  attachTextBoxToNavbar = false,
  onMobileTextBoxHeightChange,
}) => {
  const { spacing } = useThemeTokens();
  const { toast } = useAlphaToast();
  const [expertErrors, setExpertErrors] = useState<string | null>();
  const [messageErrors, setMessageErrors] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const { dispatch, state } = useProjectInteractionsContext();

  const [validSelectedExperts, setValidSelectedExperts] = useState<Expert[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [nonElegibleExperts, setNonElegibleExperts] = useState<Expert[]>([]);

  const { wrapperDiv } = useCallGuideSectionStyles();

  const expertItems = useMemo(() => {
    setNonElegibleExperts([]);
    return allExperts
      .filter((expert) => {
        const locked = expert.advisorLockState && expert.advisorLockState.locked;
        const hasOnlyCompletedOrScheduled = expert.hasOnlyCompletedOrScheduled;
        const canSendMessage = expert.canSendMessage;
        if (hasOnlyCompletedOrScheduled && selectedExperts.includes(expert))
          setNonElegibleExperts((value) => [...value, expert]);
        return canSendMessage && !locked;
      })
      .map((expert) => {
        return {
          key: expert.id,
          label: (
            <ExpertLabel
              expert={expert}
              customDisabled={expert.hasOnlyCompletedOrScheduled ? "Expert has already completed a call" : undefined}
              showSecondaryInformation
            />
          ),
          hideSelected: expert.hasOnlyCompletedOrScheduled,
          disabled: expert.hasOnlyCompletedOrScheduled,
        };
      });
  }, [allExperts, selectedExperts]);

  useEffect(() => {
    const nonElegibleExpertsIds = nonElegibleExperts.map((expertItem) => expertItem.id);
    const validSelectedExperts = selectedExperts.filter(
      (selectedExpert) =>
        !nonElegibleExpertsIds.includes(selectedExpert.id) && !(selectedExpert.advisorLockState?.locked ?? false)
    );
    setValidSelectedExperts(validSelectedExperts);
  }, [selectedExperts, nonElegibleExperts]);

  const enteredInputs = useMemo(() => {
    const selectedExpertEntered = selectedExperts?.length > 0;
    const messageEntered = messageText?.length > 0;
    return selectedExpertEntered && messageEntered;
  }, [messageText, selectedExperts]);

  useOnClick(
    ({ didClickInsideRef }) => {
      if (sendMessageButtonRef && didClickInsideRef(sendMessageButtonRef) && (!mobileVariant || enteredInputs)) {
        openModal();
      }
    },
    [validSelectedExperts, attachments, messageText, mobileVariant, enteredInputs]
  );

  const invalidAttachmentsMessage =
    "Check if your attachment can be opened and if applicable, remove password protection";

  const onSendCallGuide = () => {
    if (performValidation()) {
      handleSubmit(
        {
          advisors: validSelectedExperts.map((expert) => ({
            id: expert.id,
            name: expert.label,
            multiplier: expert.alphaCircleMultiplier,
          })),
          proposedInteractionIds: validSelectedExperts
            .filter((expert) => expert.interaction.state === "proposed")
            .map((expert) => expert.interaction.id),
          content: messageText,
        },
        attachments
      )
        .then(() => {
          dispatch(searchSync(state));
          setShowModal(false);
          onMessageSent && onMessageSent();
        })
        .catch((error: Response) => {
          setShowModal(false);
          error.json().then((json) => {
            const type = json.type;

            if (type === "INVALID_ATTACHMENTS") {
              const invalidAttachments = Object.getOwnPropertyNames(json.context);

              const newFiles = attachments.map((attachment: Attachment) => {
                if (invalidAttachments.includes(attachment.name)) {
                  return { ...attachment, valid: false };
                } else {
                  return attachment;
                }
              });

              setAttachments(newFiles);
              setMessageErrors(invalidAttachmentsMessage);
            } else {
              toast.error({ message: "An error has occurred: please try refreshing" });
            }
          });
        });
    }
  };

  const performValidation = () => {
    let isOk = true;
    isOk = checkExpertError(isOk);
    isOk = checkMessageError(isOk);
    return isOk;
  };

  const checkExpertError = useCallback(
    (isOk: boolean) => {
      if (!validSelectedExperts || validSelectedExperts.length === 0) {
        setExpertErrors("No experts selected");
        return false;
      } else {
        setExpertErrors(null);
      }
      return isOk;
    },
    [validSelectedExperts]
  );

  const checkMessageError = useCallback(
    (isOk: boolean) => {
      if (attachments.some((attachment) => !attachment.valid)) {
        setMessageErrors(invalidAttachmentsMessage);
        return false;
      } else if (!messageText || messageText.length === 0) {
        setMessageErrors("The message is empty");
        return false;
      } else {
        if (messageText.length > CALL_GUIDE_SIZE_LIMIT) {
          setMessageErrors("You have exceeded the character limit for a Call Guide.");
          return false;
        }
        setMessageErrors(undefined);
      }
      return isOk;
    },
    [messageText, attachments]
  );

  const openModal = () => {
    if (performValidation()) {
      (document.activeElement as HTMLInputElement)?.blur();
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (expertErrors) {
      checkExpertError(false);
    }
    if (messageErrors) {
      checkMessageError(false);
    }
  }, [validSelectedExperts, expertErrors, messageErrors, checkExpertError, checkMessageError]);

  const onAttachmentChange = (attachments: any[]) => {
    setAttachments(attachments);
  };

  const onSelectedExpertsChange = (values: string[]) => {
    const allSelectedExperts = allExperts.filter((expert) => values && values.includes(expert.id));
    setSelectedExperts(allSelectedExperts);
  };

  const renderSelected = (selectedExpert: SelectItem) => ({
    ...selectedExpert,
    label: <ExpertLabel expert={find(allExperts, (expert: Expert) => expert.id === selectedExpert.value)} />,
  });

  return (
    <>
      <x.div {...wrapperDiv}>
        {nonElegibleExperts.length > 0 && (
          <Alert variant="warning">
            This Call Guide cannot be sent to {nonElegibleExperts.length} expert(s) because they have already completed
            a call.
          </Alert>
        )}
        <MultipleSelect
          error={expertErrors as any}
          required={true}
          value={selectedExperts.map((expert) => expert.id) as any}
          items={expertItems}
          label="Expert(s)"
          placeholder="Select experts..."
          noResultsMessage="No experts found"
          onChange={onSelectedExpertsChange}
          externalLinkLabel={externalLinkLabel}
          onClickExternalLink={onClickExternalLink}
          renderSelected={renderSelected as any}
          size={mobileVariant ? "small" : "medium"}
        />

        {!mobileVariant ? (
          <TextBox
            name="message-content"
            error={messageErrors as any}
            required
            label="Message"
            placeholder="Begin typing your message here..."
            maxLength={CALL_GUIDE_SIZE_LIMIT as any}
            onChange={setMessageText as any}
            defaultValue={messageText as any}
            resizable={false}
            withAttachments
            attachments={attachments}
            onAttachmentChange={onAttachmentChange}
          />
        ) : (
          <MobileTextBox
            name="message-content"
            error={messageErrors as any}
            placeholder="Begin typing your message here..."
            maxLength={CALL_GUIDE_SIZE_LIMIT as any}
            onChange={setMessageText as any}
            defaultValue={messageText as any}
            resizable={false}
            withAttachments
            attachments={attachments}
            onAttachmentChange={onAttachmentChange}
            sendMessageButtonRef={sendMessageButtonRef}
            checkMessageError={checkMessageError}
            disableSend={!enteredInputs}
            attachToNavbar={attachTextBoxToNavbar}
            onHeightChange={onMobileTextBoxHeightChange}
          />
        )}

        {mobileVariant && <MobileAttachmentsList attachments={attachments} onAttachmentChange={onAttachmentChange} />}

        {messageText && messageText.length > CALL_GUIDE_SIZE_LIMIT && !mobileVariant && (
          <Alert mt={spacing.inner.base} variant="warning">
            <x.div data-testid="clarification-size-limit-exceeded">
              <TextExceeded switchToWorkRequest={switchToWorkRequest} />
            </x.div>
          </Alert>
        )}
      </x.div>
      {showModal && (
        <SendCallGuideModal
          isOpen={true}
          onCancel={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
          onSend={onSendCallGuide}
          experts={validSelectedExperts.length}
          isLoading={isLoading}
          blinded={blinded}
        />
      )}
    </>
  );
};

const TextExceeded = ({ switchToWorkRequest }: { switchToWorkRequest: Function }) => {
  return (
    <>
      You have exceeded the character limit for a Call Guide. Reduce the amount of characters typed or switch to{" "}
      <Link onClick={switchToWorkRequest as any} component="button">
        <Typography variant="body-em">Work Request</Typography>
      </Link>{" "}
      for larger requests.
    </>
  );
};
