import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AudioPlayer } from "components/AdvisorCard/AudioPlayer";
import {
  Button,
  Checkbox,
  Icon,
  IconOnlyButton,
  ListItem,
  Option,
  Popover,
  Select,
  Typography,
} from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";
import { Download, InsertLink, PlayArrow, Search, Show } from "@alphasights/alphadesign-icons";
import { useInteractioUpgradesStyles } from "./InteractionUpgrades.styles";
import { ConfirmationBanner } from "components/Banner";
import { getLanguageFromCode } from "helpers/displayHelpers";
import ExpiringComponent from "components/ExpiringComponent";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { NavLink } from "react-router-dom";
import { useRememberSearchParams } from "providers/RememberSearchParamsProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const typeUIRef = {
  ai: {
    product: "AI Transcript",
    icon: "transcripts-ai",
    select: (req) => req.transcriptType === "ai",
    reject: (req) => req.transcriptType !== "ai",
  },
  regular: {
    product: "Transcript",
    icon: "transcripts",
    select: (req) => req.transcriptType === "regular",
    reject: (req) => req.transcriptType !== "regular",
  },
  summary: {
    product: "Interaction Summary",
    icon: "summary",
    select: (req) => req.transcriptType === "summary",
    reject: (req) => req.transcriptType !== "summary",
    iconCss: "aui-text-xl",
  },
  for: (transcriptType) => typeUIRef[transcriptType],
};

export const UpgradeItem = ({
  name,
  price,
  language,
  description,
  deliveryTime,
  availableMessage,
  expiredMessage,
  icon,
  selected: selectedInput,
  disabled,
  disabledInPost,
  postInteraction,
  available,
  expired,
  downloadable,
  interaction,
  transcript,
  transcriptRequests,
  onAddUpgrade,
  onRemoveUpgrade,
  onPlayReadAlong,
  recording,
  recordingExpired,
  type,
  banner,
  innerBanner,
  requireLanguageSelection,
  selectedLanguage,
  requireRemovalConfirmation,
  removalConfirmationText,
  languageNotAvailableMessage,
  sourceLanguage,
  languages,
  shareLink,
  notAvailableOnPlatformMessage,
  dependsOn,
  selectedUpgrades = [],
  pendingUpgrades = [],
  setPendingUpgrades = () => {},
  indeterminateStateHint,
  deliverablesViewEnabled,
  projectToken,
  transcriptRequestType,
}) => {
  const { id: interactionId } = interaction;
  const [confirmedLanguage, setConfirmedLanguage] = useState(undefined);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selected, setSelected] = useState(selectedInput);
  const selectedOrDisabledInPost = selectedInput || disabledInPost;
  const isNotSelectable = recordingExpired || (selectedOrDisabledInPost && postInteraction);
  const indeterminate =
    showLanguageSelection || showAddConfirmation || showRemovalConfirmation || pendingUpgrades.includes(type);

  const {
    upgradeListItemStyle,
    upgradeDisabledListItemStyle,
    upgradeItemStyle,
    upgradeIconStyle,
    upgradeIconContainerStyle,
    disabledItemTitleStyle,
    upgradeItemTextStyle,
    upgradeActionsStyle,
  } = useInteractioUpgradesStyles({
    selected: selected || indeterminate,
    disabled,
  });

  const languageName = getLanguageFromCode(language) ?? language;
  const secondaryText = [
    price,
    indeterminate && indeterminateStateHint ? indeterminateStateHint : languageName,
    description,
    deliveryTime,
  ]
    .filter(Boolean)
    .join("&nbsp&nbsp|&nbsp&nbsp");

  const secondaryTextAvailable = [languageName, availableMessage].join("&nbsp&nbsp|&nbsp&nbsp");

  const secondaryTextExpired = [languageName, expiredMessage].join("&nbsp&nbsp|&nbsp&nbsp");

  const onChange = (checked) => {
    setSelected(checked);
    checked ? onAddUpgrade(type, confirmedLanguage) : onRemoveUpgrade(type);
    setConfirmedLanguage(undefined);
  };

  const dependenciesMet = useMemo(() => !dependsOn || [...selectedUpgrades, ...pendingUpgrades].includes(dependsOn), [
    dependsOn,
    pendingUpgrades,
    selectedUpgrades,
  ]);

  const onCancelAddition = useCallback(
    (removePending = true) => {
      setShowAddConfirmation(false);
      setShowLanguageSelection(false);
      setConfirmedLanguage(undefined);
      removePending && setPendingUpgrades(pendingUpgrades.filter((p) => p !== dependsOn && p !== type));
    },
    [dependsOn, pendingUpgrades, setPendingUpgrades, type]
  );

  const onCancelRemoval = useCallback(() => {
    setShowRemovalConfirmation(false);
  }, []);

  useEffect(() => {
    setSelected(selectedInput);
  }, [selectedInput]);

  useEffect(() => {
    !dependenciesMet && onCancelAddition(false);
  }, [dependenciesMet, onCancelAddition]);

  const onClick = (event) => {
    if (indeterminate) {
      event.preventDefault();
      !selected ? onCancelAddition() : onCancelRemoval();
      return;
    }

    if (selected && requireRemovalConfirmation) {
      event.preventDefault();
      setShowRemovalConfirmation(true);
      return;
    }

    const shouldAskForLanguageBeforeAdding = !selected && requireLanguageSelection && !confirmedLanguage;
    const shouldConfirmBeforeAdding = !selected && postInteraction;
    if (shouldAskForLanguageBeforeAdding || shouldConfirmBeforeAdding) {
      event.preventDefault();
      !dependenciesMet && setPendingUpgrades([...pendingUpgrades, dependsOn].filter(Boolean));
      shouldAskForLanguageBeforeAdding ? setShowLanguageSelection(true) : setShowAddConfirmation(true);
      return;
    }
  };

  const onTogglePlayRecording = () => {
    setIsPlaying(!isPlaying);
  };

  return isNotSelectable ? (
    <ListItem variant="compact" spacing="sm" data-testid={`${type}-upgrade`}>
      {!isPlaying ? (
        <x.div {...upgradeDisabledListItemStyle}>
          <Icon {...upgradeIconStyle}>{icon}</Icon>
          <x.div {...disabledItemTitleStyle}>
            <Typography>{name}</Typography>
            <Typography
              variant="body-small"
              color="secondary"
              dangerouslySetInnerHTML={{
                __html: expired ? secondaryTextExpired : available ? secondaryTextAvailable : secondaryText,
              }}
            />
          </x.div>
          {available && !expired && (
            <x.div {...upgradeActionsStyle}>
              {recording && !downloadable && (
                <PlayRecordingButton
                  type={type}
                  interaction={interaction}
                  recording={recording}
                  deliverablesViewEnabled={deliverablesViewEnabled}
                  projectToken={projectToken}
                  transcriptRequests={transcriptRequests}
                  onPlayReadAlong={onPlayReadAlong}
                  onPlayRecording={onTogglePlayRecording}
                  accessControl={{
                    allowedPermissions: ["access_transcripts_and_recordings"],
                  }}
                />
              )}
              {downloadable && !notAvailableOnPlatformMessage && (
                <>
                  {deliverablesViewEnabled ? (
                    <ViewInDeliverablesTabButton
                      projectToken={projectToken}
                      interaction={interaction}
                      transcriptRequestType={transcriptRequestType}
                    />
                  ) : (
                    <DownloadTranscriptButton
                      interaction={interaction}
                      transcript={transcript}
                      accessControl={{
                        allowedPermissions: ["access_transcripts_and_recordings"],
                      }}
                    />
                  )}
                </>
              )}
              {shareLink && (
                <ShareLinkButton
                  deliverablesViewEnabled={deliverablesViewEnabled}
                  projectToken={projectToken}
                  interactionId={interaction.id}
                  transcriptRequestType={transcriptRequestType}
                />
              )}
              {notAvailableOnPlatformMessage && (
                <ViewTranslatedTranscriptButton
                  message={notAvailableOnPlatformMessage}
                  transcriptRequests={transcriptRequests}
                />
              )}
            </x.div>
          )}
        </x.div>
      ) : (
        <AudioPlayer
          recording={recording}
          onClose={onTogglePlayRecording}
          interactionId={interactionId}
          projectToken={interaction.projectToken}
          origin={HitOrigin.flyout}
          language={language}
        />
      )}
    </ListItem>
  ) : (
    <ListItem variant="compact" spacing="sm" data-testid={`${type}-upgrade`}>
      <x.div {...upgradeListItemStyle}>
        <x.div {...upgradeItemStyle}>
          <x.div onClick={disabled ? undefined : onClick} flexGrow={1}>
            <Checkbox
              disabled={disabled}
              indeterminate={indeterminate}
              checked={!indeterminate && selected}
              onChange={({ currentTarget: { checked } }) => onChange(checked)}
              secondaryText={
                <Typography
                  variant="body-small"
                  dangerouslySetInnerHTML={{ __html: secondaryText }}
                  {...upgradeItemTextStyle}
                />
              }
              data-testid={`upgrade-checkbox-${type}`}
            >
              <Typography component="span" {...upgradeItemTextStyle}>
                {name}
              </Typography>
            </Checkbox>
          </x.div>
          <x.div {...upgradeIconContainerStyle}>
            <Icon {...upgradeIconStyle}>{icon}</Icon>
          </x.div>
        </x.div>
        {showLanguageSelection && (
          <>
            <LanguageSelection
              selectedLanguage={selectedLanguage}
              setConfirmedLanguage={setConfirmedLanguage}
              onConfirmLanguage={() => {
                if (!confirmedLanguage) return;
                setShowLanguageSelection(false);
                postInteraction ? setShowAddConfirmation(true) : onChange(true);
              }}
              languageNotAvailableMessage={languageNotAvailableMessage}
              sourceLanguage={sourceLanguage}
              languages={languages}
            />
            {innerBanner}
          </>
        )}
        {showRemovalConfirmation && (
          <ConfirmationBanner
            variant="warning"
            onConfirm={() => {
              setShowRemovalConfirmation(false);
              onChange(false);
            }}
            onCancel={() => setShowRemovalConfirmation(false)}
          >
            {removalConfirmationText ?? "Confirm upgrade removal?"}
          </ConfirmationBanner>
        )}
        {showAddConfirmation && (
          <ConfirmationBanner
            variant="info"
            onConfirm={() => {
              setShowAddConfirmation(false);
              onChange(true);
            }}
            onCancel={onCancelAddition}
          >
            Upgrades added after the call is completed cannot be removed.
          </ConfirmationBanner>
        )}
        {!showAddConfirmation && !showRemovalConfirmation && banner}
      </x.div>
    </ListItem>
  );
};

const SelectLanguage = styled(Select)`
  ${(props) => props.isMobile && "width: unset;"};
`;

const LanguageSelection = ({
  selectedLanguage,
  setConfirmedLanguage,
  onConfirmLanguage,
  languageNotAvailableMessage,
  sourceLanguage,
  languages,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    languageSelectionStyle,
    languageSelectionButtonStyle,
    languageSelectionInputStyle,
    languageSelectionLanguageTextStyle,
  } = useInteractioUpgradesStyles({ isMobile });

  useEffect(() => {
    setConfirmedLanguage(selectedLanguage);
  }, [setConfirmedLanguage, selectedLanguage]);

  const renderLabel = (language, sourceLanguage) => {
    return sourceLanguage ? `${sourceLanguage} to ${language}` : language;
  };

  const renderSelectedValue = ({ value }) => {
    return renderLabel(getLanguageFromCode(value), sourceLanguage);
  };

  return (
    <x.div {...languageSelectionStyle} data-testid="language-selection">
      <SelectLanguage
        input
        isMobile={isMobile}
        placeholder="Select language"
        rightAccessory={
          <Icon color="secondary">
            <Search />
          </Icon>
        }
        allowRightAccessoryRotation={false}
        value={selectedLanguage}
        onChange={(value) => {
          setConfirmedLanguage(value);
        }}
        noResultsMessage={languageNotAvailableMessage}
        {...languageSelectionInputStyle}
        renderSelectedValue={renderSelectedValue}
        data-testid="language-select"
      >
        {languages.map(({ locale, language }) => (
          <Option value={locale} label={language} key={locale}>
            <x.div display="flex" justifyContent="space-between" w="100%" data-testid={`language-select-${locale}`}>
              <x.div>
                <Typography {...languageSelectionLanguageTextStyle}>{renderLabel(language, sourceLanguage)}</Typography>
              </x.div>
            </x.div>
          </Option>
        ))}
      </SelectLanguage>
      <Button variant="secondary" onClick={onConfirmLanguage} {...languageSelectionButtonStyle}>
        <Typography variant="body-em">Confirm Language</Typography>
      </Button>
    </x.div>
  );
};

const ShareLinkButton = ({ projectToken, deliverablesViewEnabled, interactionId, transcriptRequestType }) => {
  const { actionButtonStyle, linkCopiedStyle } = useInteractioUpgradesStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef(null);
  const handleClick = (event) => {
    const target = event.currentTarget;
    const deliverablesTabRef = `${window.location.protocol}//${
      window.location.host
    }/${projectToken}/experts/deliverables-view/?selectedInteraction=${interactionId}${
      transcriptRequestType ? `&transcriptType=${transcriptRequestType}` : ""
    }`;
    navigator.clipboard
      .writeText(deliverablesViewEnabled ? deliverablesTabRef : window.location.href)
      .then(() => setAnchorEl(target));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconOnlyButton
        variant="ghost"
        size="small"
        onClick={handleClick}
        data-testid="upgrade-share-button"
        {...actionButtonStyle}
      >
        <InsertLink />
      </IconOnlyButton>
      {open && (
        <ExpiringComponent delay={3000} onExpire={handleClose}>
          <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClose} placement="bottom-end">
            <x.div {...linkCopiedStyle}>
              <Icon color="brandSecondary">
                <InsertLink />
              </Icon>
              <Typography variant="body-em">Link copied!</Typography>
            </x.div>
          </Popover>
        </ExpiringComponent>
      )}
    </>
  );
};

const DownloadTranscriptButton = withAccessControl(({ interaction, transcript, disabled }) => {
  const { actionButtonStyle } = useInteractioUpgradesStyles({ disabled });
  const { logHit } = useTrackUserAction();
  const formatedCallTime = interaction.scheduledCallTime ? ` - ${interaction.scheduledCallTime.slice(0, 10)}` : "";
  const transcriptFileName = `${interaction.projectTitle} - ${interaction.advisorName} ${formatedCallTime}.docx`.replace(
    /[[\],]/g,
    ""
  );
  const transcriptFileDownload = () => {
    logHit({
      origin: HitOrigin.flyout,
      action: "DOWNLOAD_TRANSCRIPT",
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
      details: { transcriptType: transcript.transcriptType },
      references: { transcriptId: transcript.id },
    });

    return `/api/projects/${interaction.projectToken}/transcripts/${transcript.token}?filename=${encodeURIComponent(
      transcriptFileName
    )}`;
  };

  return (
    <IconOnlyButton
      variant="ghost"
      size="small"
      onClick={() => window.open(transcriptFileDownload(), "_self")}
      disabled={disabled}
      data-testid="upgrade-download-button"
      {...actionButtonStyle}
    >
      <Download />
    </IconOnlyButton>
  );
});

const PlayRecordingButton = withAccessControl(
  ({
    type,
    interaction,
    recording,
    deliverablesViewEnabled,
    projectToken,
    transcriptRequests,
    onPlayReadAlong,
    onPlayRecording,
    disabled,
  }) => {
    const { actionButtonStyle } = useInteractioUpgradesStyles({ disabled });

    const readAlongCompatibleTranscript = transcriptRequests.find(typeUIRef.ai.select);

    if (!recording.visibleToClient && !readAlongCompatibleTranscript?.completed) {
      return null;
    }

    const onPlay = () => {
      if (readAlongCompatibleTranscript?.completed) {
        return onPlayReadAlong(interaction, recording, readAlongCompatibleTranscript);
      } else {
        return onPlayRecording();
      }
    };

    return (
      <>
        {deliverablesViewEnabled && readAlongCompatibleTranscript?.completed ? (
          <ViewInDeliverablesTabButton
            projectToken={projectToken}
            interaction={interaction}
            transcriptRequestType="ai"
          />
        ) : (
          <IconOnlyButton
            variant="ghost"
            size="small"
            onClick={onPlay}
            disabled={disabled}
            data-testid="upgrade-play-button"
            {...actionButtonStyle}
          >
            {type === "transcript_ai" ? <Show /> : <PlayArrow />}
          </IconOnlyButton>
        )}
      </>
    );
  }
);

const ViewTranslatedTranscriptButton = ({ message, transcriptRequests }) => {
  const { actionButtonStyle } = useInteractioUpgradesStyles();
  const regularTranscript = transcriptRequests.find(typeUIRef.regular.select);

  if (!regularTranscript?.completed) return null;

  return (
    <PortalTooltip title={message} position="left" dark>
      <IconOnlyButton
        variant="ghost"
        size="small"
        data-tip={message}
        data-testid="upgrade-unavaible-view-button"
        disabled
        {...actionButtonStyle}
      >
        <Show />
      </IconOnlyButton>
    </PortalTooltip>
  );
};

const ViewInDeliverablesTabButton = ({ projectToken, interaction, transcriptRequestType }) => {
  const { actionButtonStyle } = useInteractioUpgradesStyles();
  const { withSearchParams } = useRememberSearchParams();

  const deliverablesTabWithSearchParams = withSearchParams("/experts/deliverables-view");
  const deliverablesTabRef = `/${projectToken}${deliverablesTabWithSearchParams}${
    deliverablesTabWithSearchParams.includes("?") ? "&" : "?"
  }selectedInteraction=${interaction.id}${transcriptRequestType ? `&transcriptType=${transcriptRequestType}` : ""}`;

  return (
    <NavLink to={deliverablesTabRef} style={{ textDecoration: "none" }}>
      <IconOnlyButton
        variant="ghost"
        size="small"
        data-testid="upgrade-view-in-deliverables-tab-button"
        {...actionButtonStyle}
      >
        <Show />
      </IconOnlyButton>
    </NavLink>
  );
};
