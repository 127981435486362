import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useExpertFiltersStyles = () => {
  const {
    color: { border },
    spacing: { inner },
    flex: { gap },
  } = useThemeTokens();

  const itemsWrapper = {
    display: "flex",
    flexDirection: "column",
    padding: inner.base,
  };

  const buttonsWrapper = {
    display: "flex",
    gap: gap.xl,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: border.divider,
    padding: inner.base04,
  };

  const containerStyles = {
    display: "flex",
    alignItems: "center",
  };

  const containerStylesCalendar = {
    maxWidth: "calc(525px - 4rem)",
  };

  const clearAllStyles = {
    whiteSpace: "nowrap",
  };

  const listStyles = {
    display: "flex",
    flexDirection: "row",
    gap: inner.base02,
  };

  return {
    itemsWrapper,
    buttonsWrapper,
    containerStyles,
    containerStylesCalendar,
    clearAllStyles,
    listStyles,
  };
};
