import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTSidebarStyles = ({ showUserFilter = false, hasValidAlphaGPTPurchase = true }) => {
  const {
    color: { background },
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    background: background.surface.recessed,
    py: inner.base06,
    px: inner.base08,
    w: "500px",
    flexShrink: 0,
    overflow: "auto",
  };

  const buttons = {
    display: "flex",
    justifyContent: showUserFilter ? "space-between" : "end",
    alignItems: "center",
  };

  const newChatButton = {
    variant: !hasValidAlphaGPTPurchase ? "primary" : "outline",
    cursor: !hasValidAlphaGPTPurchase ? "default" : "pointer",
  };

  const label = {
    marginTop: inner.base06,
  };

  return {
    wrapper,
    buttons,
    newChatButton,
    label,
  };
};
