import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { color, spacing } = useThemeTokens();
  return {
    viewWrapper: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
    },
    sidebarWrapper: {
      w: "500px",
      flexShrink: 0,
      borderRight: `1px solid ${color.border.divider}`,
      display: "flex",
      flexDirection: "column",
    },
    moduleWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minW: "1px",
    },
    loadingBars: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base05,
      alignItems: "center",
      overflow: "hidden",
      p: spacing.inner.base06,
    },
  };
};
