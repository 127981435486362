import React, { useEffect, useState } from "react";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCaptchaPageStyles } from "./CaptchaPage.styles";
import { Help, SignInFooter } from "pages/Login";
import { FormFooter } from "pages/Login/Form";
import { ModalForm } from "pages/Login/BackgroundPage";

const Recaptcha = require("react-recaptcha");

export const Captcha = ({ recaptchaSiteKey, onConfirm, onExpire }) => (
  <Recaptcha
    sitekey={recaptchaSiteKey}
    render="explicit"
    onloadCallback={() => {}}
    expiredCallback={onExpire}
    verifyCallback={onConfirm}
  />
);

export const CaptchaPageWrapper = ({ env }) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = (response) => {
    const init = {
      method: "POST",
      body: response,
    };

    setLoading(true);
    fetch("/api/captcha/verify", init)
      .then(() => window.location.reload())
      .catch(() => setLoading(false));
  };

  return <CaptchaPage recaptchaSiteKey={env.recaptchaSiteKey} onConfirm={onConfirm} loading={loading} />;
};

export const CaptchaPage = ({ recaptchaSiteKey, onConfirm, loading }) => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  const { modal } = useCaptchaPageStyles();

  return (
    <x.div data-testid="captcha-page">
      <ModalForm {...modal} open={true}>
        {!showHelpModal && (
          <CaptchaModal
            recaptchaSiteKey={recaptchaSiteKey}
            onConfirm={onConfirm}
            loading={loading}
            onClickHelp={() => setShowHelpModal(true)}
          />
        )}
        {showHelpModal && <HelpModal onClickReturn={() => setShowHelpModal(false)} />}
      </ModalForm>
    </x.div>
  );
};

const CaptchaModal = ({ recaptchaSiteKey, onConfirm, loading, onClickHelp }) => {
  const [response, setResponse] = useState(null);
  const [renderCaptcha, setRenderCaptcha] = useState(false);

  useEffect(() => {
    setRenderCaptcha(true);
  }, []);

  const { modalWrapper, captcha, returnButton, title, description, buttonDiv, footer } = useCaptchaPageStyles();

  return (
    <>
      <x.div {...modalWrapper}>
        <x.div {...title}>
          <Typography variant="h3">Disclaimer</Typography>
        </x.div>
        <Typography {...description}>
          By accessing this secure webpage, you agree to use AlphaSights’ domain, website, webpage and online platform
          (i) in a manner that does not infringe or violate any applicable terms of use, terms of service or
          AlphaSights’ intellectual property, proprietary or confidentiality rights and (ii) in accordance with
          applicable laws. This includes not downloading, storing, recording, copying, reproducing, republishing,
          transmitting, displaying, distributing, extracting information from or taking screenshots of any portion of
          any Industry Professional "Evaluation Profile" or sharing the same with a third party.
        </Typography>
        <x.div {...captcha}>
          {renderCaptcha && (
            <Captcha recaptchaSiteKey={recaptchaSiteKey} onExpire={() => setResponse(null)} onConfirm={setResponse} />
          )}
        </x.div>
        <x.div {...buttonDiv}>
          <Button disabled={!response} onClick={() => onConfirm(response)} loading={loading} variant="secondary">
            <Typography variant="body-em">Agree & Enter Platform</Typography>
          </Button>
          <Button
            onClick={() => (window.location.href = `/sign-in`)}
            disabled={loading}
            variant="outline"
            {...returnButton}
          >
            <Typography variant="body-em">Return To Sign In</Typography>
          </Button>
        </x.div>
      </x.div>
      <FormFooter {...footer}>
        <SignInFooter showSignUp={false} onClickHelp={onClickHelp} />
      </FormFooter>
    </>
  );
};

const HelpModal = ({ onClickReturn }) => {
  return (
    <x.div pb="64px">
      <Help onClickReturn={onClickReturn} />
    </x.div>
  );
};
