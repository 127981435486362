import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useResponsiveModalStyles = ({ centerTitle }) => {
  const {
    color: { border, text },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  const drawerHeader = {
    display: "flex",
    alignItems: "center",
  };

  const separator = {
    borderBottom: `${shape.border.width.sm} solid ${border.divider}`,
    my: inner.base04,
    mx: "-" + inner.base06,
  };

  const mobileTitle = {
    color: text.strong._,
    display: "flex",
    justifyContent: centerTitle && "center",
    flex: 1,
  };

  const drawer = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  };

  const closeButton = {
    w: inner.base05,
    h: inner.base05,
  };

  const drawerButtons = {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    spaceY: inner.base02,
  };

  return {
    drawerHeader,
    separator,
    mobileTitle,
    drawer,
    closeButton,
    drawerButtons,
  };
};

export const useResponsiveConfirmationModalStyles = ({ isMobile }) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  const bodyContainer = {
    color: color.text.strong._,
    textAlign: "justify",
    flex: "1",
    style: {
      overflowWrap: "break-word",
    },
    mb: inner.base06,
  };

  const buttonsContainer = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row-reverse",
    justifyContent: "end",
    flexWrap: "wrap",
    gap: inner.base04,
  };

  return {
    bodyContainer,
    buttonsContainer,
  };
};
