import { Button, Typography } from "@alphasights/alphadesign-components";
import React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const EmptySearchResults = ({
  hasAppliedFilter,
  onResetAll,
  onResetTextFilter,
  totalResults,
  projectLeadName,
}) => {
  const { isMobile } = useCheckScreen();

  if (isMobile)
    return (
      <EmptySearchResultsMobile
        hasAppliedFilter={hasAppliedFilter}
        onResetAll={onResetAll}
        onResetTextFilter={onResetTextFilter}
        totalResults={totalResults}
        projectLeadName={projectLeadName}
      />
    );
  else
    return (
      <EmptySearchResultsDesktop
        hasAppliedFilter={hasAppliedFilter}
        onResetAll={onResetAll}
        onResetTextFilter={onResetTextFilter}
        totalResults={totalResults}
        projectLeadName={projectLeadName}
      />
    );
};

const EmptySearchResultsDesktop = ({
  hasAppliedFilter,
  onResetAll,
  onResetTextFilter,
  totalResults,
  projectLeadName,
}) => {
  const { color, spacing } = useThemeTokens();

  const resetTextFilterAndFocus = () => {
    onResetTextFilter();
    const searchInput = document.getElementById("advisor-page-search-input");
    searchInput && searchInput.focus();
  };
  const clickAction = hasAppliedFilter ? onResetAll : resetTextFilterAndFocus;

  return (
    <div className="aui-flex aui-flex-col aui-items-center aui-text-grey-6">
      <Typography variant="h3" color={color.text.secondary}>
        {totalResults === 0 ? "No experts have been published on this project" : "No experts found"}
      </Typography>

      {totalResults === 0 && (
        <Typography variant="body-large" mt={spacing.inner.base02}>
          Your project lead {projectLeadName} is gathering relevant expert profiles and will publish them shortly.
        </Typography>
      )}

      {totalResults > 0 && (
        <>
          <Typography variant="body-large" mt={spacing.inner.base02}>
            {hasAppliedFilter ? "Try removing the applied filters." : "Try rephrasing your search."}
          </Typography>

          <x.div className="aui-space-x-4" mt={spacing.inner.base04}>
            {clickAction && (
              <Button variant="outline" size="small" onClick={clickAction} data-testid="clear-search-btn">
                {hasAppliedFilter ? "Remove All Filters" : "Edit Search"}
              </Button>
            )}
          </x.div>
        </>
      )}
    </div>
  );
};

const EmptySearchResultsMobile = ({
  hasAppliedFilter,
  onResetAll,
  onResetTextFilter,
  totalResults,
  projectLeadName,
}) => {
  const { spacing } = useThemeTokens();

  const clickAction = hasAppliedFilter ? onResetAll : onResetTextFilter;

  return (
    <x.div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h="100%"
      p={spacing.inner.base02}
      data-testid="empty-search-results-mobile"
    >
      <Typography variant="body-em" color="secondary">
        {totalResults === 0 ? "No experts have been published on this project" : "No experts found"}
      </Typography>
      {totalResults === 0 && (
        <Typography
          variant="body"
          mt={spacing.inner.base03}
          mb={spacing.inner.base03}
          color="secondary"
          textAlign="center"
        >
          Your project lead {projectLeadName} is gathering relevant expert profiles and will publish them shortly.
        </Typography>
      )}
      {totalResults > 0 && (
        <>
          <Typography variant="body" mt={spacing.inner.base03} mb={spacing.inner.base03} color="secondary">
            Try removing the applied filters.
          </Typography>
          {clickAction && (
            <Button variant="outline" size="small" onClick={clickAction} w="100%" data-testid="clear-search-btn">
              {hasAppliedFilter ? "Remove Filters" : "Clear Search"}
            </Button>
          )}
        </>
      )}
    </x.div>
  );
};
