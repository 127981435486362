import React from "react";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

type LabeledIconProps = {
  fontFormat?: { fontFamily: string; fontWeight: number; fontSize: string; lineHeight: string };
  iconMargin?: string;
  iconColor?: string;
  labelColor?: string;
  labelText: string;
  iconItem: JSX.Element;
  mt?: string;
  mb?: string;
};

const LabeledIcon = ({
  fontFormat,
  iconMargin,
  iconColor,
  labelColor,
  labelText,
  iconItem,
  mb,
  mt,
}: LabeledIconProps) => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div display="flex" alignItems="flex-start" mb={mb ? mb : ""} mt={mt ? mt : ""}>
      <Icon size="small" color={iconColor ? iconColor : color.icon.secondary}>
        {iconItem}
      </Icon>
      <Typography
        {...(fontFormat ? fontFormat : typography.body.small)}
        marginLeft={iconMargin ? iconMargin : spacing.inner.base}
        className="aui-inline"
        color={labelColor ? labelColor : color.text.secondary}
      >
        {labelText}
      </Typography>
    </x.div>
  );
};

export default LabeledIcon;
