import React from "react";
import { x } from "@xstyled/styled-components";

const ErrorMessage = ({ header, body }) => (
  <x.div display="flex" justifyContent="center" alignItems="center" h="100%">
    <x.div mx="auto" fontSize="450" lineHeight="450" color="neutral700" textAlign="center" mt="1000">
      <x.div fontSize="500" lineHeight="500" fontWeight="bold">
        {header}
      </x.div>
      {body}
    </x.div>
  </x.div>
);

export default ErrorMessage;
