import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const Wrapper = styled.div(() => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  return `
    display: flex;
    align-items: center;
    gap: ${inner.base02};
    background-color: ${background.surface.page.default};
    padding: ${inner.base04} ${inner.base06};
    overflow: auto;
  `;
});
