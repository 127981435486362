import { SliderOption } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";

const TITLE = "Headcount";

// if the lengths of these two arrays change, the corresponding index.css file should be updated to match the new lengths

const HEADCOUNT_MARKS: SliderOption[] = [
  {
    label: "1",
    value: 0,
  },
  {
    label: "10",
    value: 1,
  },
  {
    label: "50",
    value: 2,
  },
  {
    label: "200",
    value: 3,
  },
  {
    label: "500",
    value: 4,
  },
  {
    label: "1,000",
    value: 5,
  },
  {
    label: "5,000",
    value: 6,
  },
  {
    label: "10,000",
    value: 7,
  },
  {
    label: "> 10,000",
    value: 8,
  },
];

const HEADCOUNT_VALUES = [null, 10, 50, 200, 500, 1000, 5000, 10000, null];

export { TITLE, HEADCOUNT_MARKS, HEADCOUNT_VALUES };
