import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTCitationStyles = () => {
  const {
    color: { border, icon },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  const info = {
    display: "flex",
    alignItems: "center",
    gap: inner.base,
    pb: inner.base02,
  };

  const title = {
    pt: inner.base02,
  };

  const footer = {
    pt: inner.base,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: inner.base,
  };

  const wrapper = {
    borderColor: {
      _: border.divider,
      hover: border.selected,
    },
    borderWidth: shape.border.width.sm,
    borderRadius: inner.base02,
    borderStyle: "solid !important",
    cursor: "pointer",
    padding: inner.base04,
  };

  const primerWrapper = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const primerLogo = {
    w: "44px",
    h: "44px",
    minWidth: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: inner.base,
    borderColor: border.divider,
    borderWidth: shape.border.width.sm,
    borderStyle: "solid !important",
  };

  const primerImg = {
    maxW: inner.base06,
    maxH: inner.base06,
  };

  const primerNoLogo = {
    color: icon.secondary,
  };

  const primerDescription = {
    pt: inner.base02,
  };

  return {
    info,
    title,
    footer,
    wrapper,
    primerWrapper,
    primerLogo,
    primerImg,
    primerNoLogo,
    primerDescription,
  };
};
