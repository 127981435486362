import React, { FC, useState } from "react";
import {
  Alert,
  Checkbox,
  CheckboxGroup,
  Modal,
  Typography,
  useThemeTokens,
  Drawer,
  TextArea,
} from "@alphasights/alphadesign-components";
import { Request } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import "./index.css";
import "pages/AlphaNowPage/components/AlphaNowModal/index.css";
import { RequestExpertButton } from "pages/AlphaNowPage/components";
import { RequestExpertButtonType } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions/constants";
import {
  addANote,
  cancelContent,
  cancelRequestContent,
  requestContent,
  selectExperts,
  updateRequestContent,
  requestExpertsTitle,
  notePlaceholder,
} from "content/AlphaNow";
import { requestExperts } from "content/AlphaNow/utils";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { useAccessControl } from "hooks/useAccessControl";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Label } from "components/Label";
import CancelButton, { DataTestIds as CancelButtonDataTestIds } from "pages/AlphaNowPage/components/CancelButton";

const DataTestIds = {
  speakerRequestOption: "speaker-option",
  requestOverlay: "request-expert-overlay",
  requestExpertList: "request-expert-list",
  speakerJobTitle: (id: number) => `speaker-job-title-${id}`,
  submitUpdateRequest: RequestExpertButtonType.submitRequest,
  requestExpert: "request-expert",
  requestExpertSubmit: "submit-request-expert",
};

type OnSubmitType = ((ids: number[]) => void) | ((speakerIds: number[], note: string) => void) | (() => void);
interface RequestExpertOverlayProps {
  projectToken?: string;
  onClose: () => void;
  speakers: Speaker[];
  onSubmit: OnSubmitType;

  expertRequests?: Partial<ClientExpertInquiryDto[]>;
  selectedSpeakers?: number[];
  onCancel?: () => void;
  disclaimer?: string;
  error?: string;
}

type RequestExpertProps = {
  projectToken?: string;
  numberOfExperts: number;
  speakers: Speaker[];
  selectedSpeakersIds?: number[];
  handleOnChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setSelectedSpeakerIds: (e: React.SetStateAction<number[]>) => void;

  disclaimer?: string;
  error?: string;
};

const ExpertContent: FC<RequestExpertProps> = ({
  projectToken,
  numberOfExperts,
  speakers,
  selectedSpeakersIds,
  handleOnChangeNote,
  setSelectedSpeakerIds,

  disclaimer,
  error,
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleCheckboxChange = (selectedSpeakerIds: any) => {
    if (errorMessage && !!selectedSpeakerIds.length) {
      setErrorMessage(undefined);
    }
    setSelectedSpeakerIds(selectedSpeakerIds);
  };

  return (
    <>
      {!!numberOfExperts && (
        <x.div className="request-expert-options" mb={inner.base06} data-testid={DataTestIds.requestOverlay}>
          {isMobile && (
            <Typography
              data-testid="mobile-checkbox-description"
              component="span"
              whiteSpace="nowrap"
              variant="body"
              color={text.secondary}
              fontSize={size["04"]}
            >
              {selectExperts}
            </Typography>
          )}
          <CheckboxGroup
            variant={isMobile ? "boxed" : "default"}
            label={!isMobile && ((<Label text={selectExperts} required />) as any)}
            error={errorMessage}
            value={selectedSpeakersIds}
            onChange={handleCheckboxChange}
          >
            <x.div maxH="280px" overflow="scroll" data-testid={DataTestIds.requestExpertList}>
              {speakers.map(({ company, jobTitle, id, isModerator, speakerId }) => {
                const value = projectToken ? speakerId : id;
                return (
                  !isModerator && (
                    <Checkbox
                      key={`request-expert-${id}`}
                      data-testid={DataTestIds.speakerRequestOption}
                      value={value}
                      size="large"
                    >
                      <p>
                        <Typography
                          component="span"
                          whiteSpace="nowrap"
                          variant="body-em"
                          fontSize={{ xs: size["04"], md: size["03"] }}
                        >
                          {company}
                        </Typography>
                        <Typography
                          fontSize={{ xs: size["04"], md: size["03"] }}
                          component="span"
                          variant="body"
                          data-testid={DataTestIds.speakerJobTitle(id)}
                        >
                          {" "}
                          - {jobTitle}
                        </Typography>
                      </p>
                    </Checkbox>
                  )
                );
              })}
            </x.div>
          </CheckboxGroup>
        </x.div>
      )}
      <x.div>
        <Typography
          component="div"
          mb={inner.base}
          variant={isMobile ? "body-large" : "body"}
          color={text.secondary}
          fontSize={{ xs: size["04"], md: size["03"] }}
        >
          {addANote}
        </Typography>
        <div data-testid="request-expert-note">
          <TextArea width="100%" height="100px" placeholder={notePlaceholder} onChange={handleOnChangeNote} />
        </div>
        {disclaimer && (
          <Typography variant="body-small" color="secondary">
            {disclaimer}
          </Typography>
        )}
      </x.div>
      {error && (
        <Alert variant="warning" mt={inner.base02}>
          {error}
        </Alert>
      )}
    </>
  );
};

const RequestExpertOverlay: FC<RequestExpertOverlayProps> = ({
  projectToken,
  onClose,
  speakers,
  onSubmit,
  expertRequests,
  selectedSpeakers,
  onCancel,
  disclaimer,
  error,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    font: { size },
  } = useThemeTokens();

  const [note, setNote] = useState("");

  const experts = speakers.filter((element) => !element.isModerator);
  const numberOfExperts = experts.length;
  const firstExpertId = numberOfExperts === 1 ? (projectToken ? [experts[0].speakerId] : [experts[0].id]) : [];

  const selectedExperts = (expertRequests ?? []).reduce((acc, request) => {
    if (request?.speakerIds) {
      acc = acc.concat(request.speakerIds);
    }
    return acc;
  }, selectedSpeakers || firstExpertId || ([] as number[]));

  const [selectedSpeakerIds, setSelectedSpeakerIds] = useState<number[]>(selectedExperts);

  const hasPermission = useAccessControl([MANAGE_CONTENT_PERMISSION]);

  const handleSubmit = () =>
    !!selectedSpeakerIds.length &&
    (onSubmit as (speakerIds?: number[], note?: string) => void)(selectedSpeakerIds, note);

  const requireExpertButtonProps = hasPermission
    ? {
        onClick: handleSubmit,
      }
    : {
        onClick: () => {},
        disabled: true,
        cursor: "not-allowed",
        outline: { focus: "none" },
      };

  const isExistingExpertRequest = expertRequests && expertRequests.some((request) => !!request?.clientExpertInquiryId);
  const existingRequestLabel = isExistingExpertRequest ? updateRequestContent : requestContent;
  const cancelExistingRequestLabel = isExistingExpertRequest ? cancelRequestContent : cancelContent;

  const handleOnChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setNote(newNote);
  };

  if (isMobile) {
    return (
      <Drawer
        data-testid="drawer-expert-request"
        variant="complex"
        primaryButtonProps={{
          children: existingRequestLabel,
          fontSize: size["04"],
          dataAttributes: { "data-testid": DataTestIds.requestExpertSubmit },
          ...requireExpertButtonProps,
        }}
        secondaryButtonProps={{
          fontSize: size["04"],
          children: cancelExistingRequestLabel,
          dataAttributes: {
            "data-testid": isExistingExpertRequest
              ? CancelButtonDataTestIds.cancelExistingRequest
              : CancelButtonDataTestIds.cancelRequest,
          },
          onClick: onCancel,
        }}
        onClose={onClose}
        title={requestExperts(numberOfExperts)}
        open
      >
        <ExpertContent
          projectToken={projectToken}
          numberOfExperts={numberOfExperts}
          speakers={speakers}
          selectedSpeakersIds={selectedSpeakerIds}
          handleOnChangeNote={handleOnChangeNote}
          setSelectedSpeakerIds={setSelectedSpeakerIds}
          disclaimer={disclaimer}
          error={error}
        />
      </Drawer>
    );
  }

  const primaryButton = (
    <RequestExpertButton
      disabled={!selectedSpeakerIds.length}
      data-testid={DataTestIds.requestExpertSubmit}
      buttonActionVariant={RequestExpertButtonType.submitRequest}
      text={existingRequestLabel}
      onClick={handleSubmit}
      startIcon={<Request />}
    />
  );

  const secondaryButton = (
    <CancelButton isExistingExpertRequest={isExistingExpertRequest} handleCancelRequest={onCancel} onClose={onClose} />
  );

  return (
    <>
      <Modal
        title={<x.span textTransform={"none"}>{requestExpertsTitle}</x.span>}
        open
        onClose={onClose}
        // @ts-ignore
        role="heading"
        slotHeight="unset"
        variant={"complex"}
        slotWidth={"555px"}
        shouldShowFooter={true}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        zIndex={10}
      >
        <ExpertContent
          projectToken={projectToken}
          numberOfExperts={numberOfExperts}
          speakers={speakers}
          selectedSpeakersIds={selectedSpeakerIds}
          handleOnChangeNote={handleOnChangeNote}
          setSelectedSpeakerIds={setSelectedSpeakerIds}
          disclaimer={disclaimer}
          error={error}
        />
      </Modal>
    </>
  );
};

export { RequestExpertOverlay as default, DataTestIds };
