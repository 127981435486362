import React, { useRef, useState, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import showdown from "showdown";
import styled, { x } from "@xstyled/styled-components";
import { Icon, Pill, Typography, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company, Expert, Info, Purchased } from "@alphasights/alphadesign-icons";
import {
  CONTENT_TYPE,
  CONTENT_TYPE_DISPLAY_NAME,
  ContentStatus,
  SEARCH_SUGGESTION_TYPES,
} from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { formatDate } from "utils/dates";
import { Markdown } from "components/alphaui";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { COMPANY_RELATIONSHIP } from "constants/AlphaNow";
import MarketPill from "pages/AlphaNowPage/components/MarketPill";
import {
  moderatedContentPopover,
  investorLedContentPopover,
  privateContentPopover,
  topics,
  featuredExperts,
  upcoming,
  relevantColleaguesTitle,
  industryPerspective,
} from "content/AlphaNow";
import { available, purchased } from "content/AlphaNow";
import { DATE_FORMAT } from "content/AlphaNow/constants";
import TranscriptQuestions from "../TranscriptQuestions/TranscriptQuestions";
import useDimensions from "hooks/useDimensions";
import { contentService } from "services/content";
import { Spacer } from "pages/AlphaNowPage/components";
import {
  getOrderedAnchorCompanies,
  getOrderedItemsWithSearchPriority,
  getAngleType,
  browsersLocalTime,
  SortOrder,
  getColleaguesFromSearchQuery,
  getColleagueNames,
  getCompaniesFromSearchQuery,
} from "content/AlphaNow/utils";
import { AlphaNowAccessLevel } from "models/content/constants";
import PillSection from "./PillSection";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import useContentClientSettings from "hooks/useContentClientSettings";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { buildSearchQuery } from "content/AlphaNow/utils";

import { TranscriptHeaderWrapper, TranscriptInfoRowWrapper } from "./TranscriptDetails.styled";
import "./index.css";

const DataTestIds = {
  CompanyRelationship: "company-relationship",
  RelevantColleagues: "relevant-colleagues",
};

const StyledTypography = styled(Typography)`
  li::marker {
    font-size: 10px;
  }
`;

const TranscriptDetails = ({
  content,
  detailsCollapsed = true,
  transcriptActions,
  searchQuery = [],
  isAccessible = false,
  questionsData = [],
  showTranscript,
  transcriptDetailsInnerHeight,
}) => {
  const {
    color: { text, primary, icon },
    spacing: { inner },
    font,
    shape,
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const { hasOnlyPremiumContent, contentTypeMapping } = useAlphaNowContentAccessLevelContext();
  const { updateQuery } = useAppSearchContext();

  const { hasUserBadge } = useUserBadgeContext();
  // TODO [CON-3541]: Remove Private Contributor Badge
  const hasRelevantColleaguesBadge = hasUserBadge(Badge.privateContributor);

  const ref = useRef(null);

  const {
    agenda,
    companies,
    contentType,
    externalTitle,
    status,
    speakers,
    scheduledTimeUTC,
    id: contentId,
    displayTitle,
    market,
  } = content;

  // TODO [RD1-133]: Remove Markets Badge
  const displayMarkets = !isMobile && hasUserBadge(Badge.markets) && !!market;

  const isModeratedContent = contentType === CONTENT_TYPE.alphaview;
  const isPrivate = contentType === CONTENT_TYPE.privateEnterprise;
  const formattedDate = formatDate(browsersLocalTime(scheduledTimeUTC), DATE_FORMAT);
  const nonModeratorSpeakers = speakers.filter((s) => !s.isModerator);
  const multipleSpeakers = nonModeratorSpeakers.length > 1;
  const showAngleType = (isModeratedContent && !multipleSpeakers) || !isModeratedContent;
  const [transcriptOverviewRef, { height: transcriptOverviewHeight }] = useDimensions();

  const { clientSettings } = useContentClientSettings();
  const containerRef = useRef();

  const shouldFetchRelevantColleagues =
    !!contentId && isPrivate && hasRelevantColleaguesBadge && !!clientSettings?.showRelevantColleagues;
  const { data: relevantColleaguesData } = useQuery(
    ["relevant-colleagues", contentId],
    () => contentService.fetchRelevantColleagues(contentId),
    {
      enabled: shouldFetchRelevantColleagues,
    }
  );

  const hasRelevantColleaguesEnabled = relevantColleaguesData?.length > 0;

  const transcriptQuestionsHeight = transcriptDetailsInnerHeight - transcriptOverviewHeight;

  const contentTypeAccessLevel = contentTypeMapping.find((content) => content.contentType === contentType);
  const isContentAvailable = contentTypeAccessLevel?.accessLevel === AlphaNowAccessLevel.premium || isPrivate;
  const purchaseMessage = isContentAvailable ? available : purchased;

  const searchedCompanyIds = getCompaniesFromSearchQuery(searchQuery).map(({ id }) => id);
  const anchorCompanies = getOrderedAnchorCompanies(companies, searchedCompanyIds, SortOrder.None);

  const anchorCompanyPillData = anchorCompanies.map(({ companyName, companyId }) => ({
    name: companyName,
    icon: <Company />,
    highlight: searchedCompanyIds.includes(companyId),
    id: companyId,
  }));

  const handleSelectCompanyPill = (companyId, companyName) => {
    const newSearchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Company,
      value: companyName,
      id: companyId,
    });
    updateQuery({
      selectedContentId: undefined,
      searchQuery: newSearchQuery,
    });
  };

  const relevantColleagues = getColleagueNames(relevantColleaguesData ?? []);
  const searchRelevantColleagues = getColleaguesFromSearchQuery(searchQuery);
  const orderedRelevantColleagues = getOrderedItemsWithSearchPriority(
    relevantColleagues,
    searchRelevantColleagues,
    SortOrder.Length
  );
  const relevantColleaguesPillData = orderedRelevantColleagues.map((colleague) => ({
    name: colleague,
    icon: <Expert />,
    highlight: searchRelevantColleagues.includes(colleague),
  }));

  const hasKeywords = searchQuery.length > 0;
  const newKeywords = [...new Set(searchQuery.map((keyword) => keyword.value))];
  let companyRelationship;
  if (showAngleType) {
    const angleType = getAngleType(speakers);
    companyRelationship = COMPANY_RELATIONSHIP[angleType];
  } else {
    companyRelationship = COMPANY_RELATIONSHIP.Panel;
  }

  let popOver;
  if (isModeratedContent) {
    popOver = moderatedContentPopover;
  } else if (isPrivate) {
    popOver = privateContentPopover;
  } else {
    popOver = investorLedContentPopover;
  }

  const isPrePurchaseQuestionView = questionsData.length > 0 && !showTranscript;
  const isPrePurchaseNoQuestionsMobileView = !showTranscript && isMobile && !isPrePurchaseQuestionView;

  const shouldSearchAgenda = !!contentId && hasKeywords;

  const { data: refetchedAgenda, isSuccess } = useQuery(
    ["Agenda", contentId, newKeywords],
    () => contentService.searchAgenda(contentId, newKeywords),
    {
      enabled: shouldSearchAgenda,
    }
  );

  const converter = new showdown.Converter();
  const fetchedAgendaPresent = isSuccess && refetchedAgenda?.agenda != null;
  const parsedText = converter.makeHtml(fetchedAgendaPresent ? refetchedAgenda?.agenda : agenda);

  const Title = () => {
    return (
      <x.div flex="1 1 auto">
        <Typography variant="body-large-em" mt="10px">
          {isModeratedContent ? externalTitle : displayTitle}
        </Typography>
      </x.div>
    );
  };

  const SpeakerDetails = () => {
    const nonModeratorSpeakerTitles = nonModeratorSpeakers.map(({ company, jobTitle }) => (
      <x.li listStyleType={multipleSpeakers ? "disc" : "none"}>
        <Typography component="span" variant="body-em">
          {company}
        </Typography>
        <Typography component="span"> - {jobTitle}</Typography>
      </x.li>
    ));

    if (multipleSpeakers && isModeratedContent) {
      return (
        <>
          <Typography variant="body-em" component="span" color={text.secondary}>
            {featuredExperts}
          </Typography>
          <x.div pl={inner.base02}>{nonModeratorSpeakerTitles}</x.div>
        </>
      );
    }

    return (
      <>
        <Typography variant="body-em" component="span" color={text.secondary}>
          {isModeratedContent ? nonModeratorSpeakerTitles : "Relevant Experience"}
        </Typography>
        <Markdown className="aui-my-0">{nonModeratorSpeakers[0].bio}</Markdown>
      </>
    );
  };

  const ContentFlags = () => {
    const [isWrapped, setIsWrapped] = useState(false);

    const isParentContainerWrapped = () => {
      const parentHeight = ref.current?.clientHeight;
      const firstChildHeight = ref.current?.children?.[0]?.clientHeight;
      if (parentHeight && firstChildHeight && parentHeight >= 2 * firstChildHeight) {
        return true;
      }
      return false;
    };

    useLayoutEffect(() => {
      const checkWrapping = () => {
        if (ref?.current) {
          const wrapped = isParentContainerWrapped();
          setIsWrapped(wrapped);
        }
      };

      // Initial check
      checkWrapping();

      window.addEventListener("resize", checkWrapping);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", checkWrapping);
      };
    }, []);

    const renderFlags = () => [
      !isWrapped && <Spacer />,
      status === ContentStatus.upcoming && (
        <>
          <Typography component="span" color={primary}>
            {upcoming}
          </Typography>
          <Spacer />
        </>
      ),
      <Typography component="span">{formattedDate}</Typography>,
      <x.div display="inherit" alignItems="inherit">
        <Spacer />
        <x.span display="inherit" alignItems="center" alignSelf="baseline">
          <Typography component="span" pr={inner.base02}>
            {CONTENT_TYPE_DISPLAY_NAME[contentType]}
          </Typography>
          <x.div col={{ sm: inner.base05, md: inner.base04 }} alignItems="inherit">
            <Tooltip position="bottom" maxWidth="unset" leaveDelay={isMobile ? 3000 : 1000} title={popOver}>
              <x.span color={{ _: icon.secondary, hover: icon.strong.hover }}>
                <Icon size={isMobile ? "medium" : "small"}>
                  <Info />
                </Icon>
              </x.span>
            </Tooltip>
          </x.div>
        </x.span>
      </x.div>,
      isAccessible && !hasOnlyPremiumContent && (
        <x.div display="inherit" alignItems="inherit">
          <Spacer />
          <x.div display="inherit" alignItems="inherit" spaceX={inner.base} color={icon.success}>
            <Icon>
              <Purchased />
            </Icon>
            <Typography component="span">{purchaseMessage}</Typography>
          </x.div>
        </x.div>
      ),
    ];
    if (isMobile) {
      return (
        <x.div display="flex" flexDirection="row" alignItems="center">
          {renderFlags()}
        </x.div>
      );
    }
    return renderFlags();
  };

  const renderCompanyPills = () => {
    if (anchorCompanyPillData.length === 0) {
      return (
        <Pill key={industryPerspective} size="small" w="fit-content">
          {industryPerspective}
        </Pill>
      );
    }
    return (
      <PillSection
        title={companyRelationship}
        items={anchorCompanyPillData}
        onClick={handleSelectCompanyPill}
        isInteractive
      />
    );
  };

  return (
    <>
      {!detailsCollapsed && (
        <TranscriptHeaderWrapper
          borderBottomWidth={isPrePurchaseQuestionView || isMobile ? 0 : shape.border.width.small}
        >
          <x.div
            pb={isPrePurchaseNoQuestionsMobileView ? inner.base06 : inner.base04}
            ref={transcriptOverviewRef}
            spaceY={inner.base04}
          >
            <x.div display="flex" alignItems="start" color={text.strong._}>
              <Title />
              {!isMobile && transcriptActions}
            </x.div>
            <x.div color={text.strong._} fontSize={font.size["03"]} spaceY={inner.base04}>
              <x.div id="markdown" pb={isPrePurchaseNoQuestionsMobileView ? inner.base02 : 0}>
                <SpeakerDetails />
              </x.div>
              {agenda && (
                <x.div id="markdown" pb={isPrePurchaseNoQuestionsMobileView ? inner.base02 : 0}>
                  <Typography variant="body-em" component="span" color={text.secondary}>
                    {topics}
                  </Typography>
                  <StyledTypography className="mentions-container">{ReactHtmlParser(parsedText)}</StyledTypography>
                </x.div>
              )}
            </x.div>
            {hasRelevantColleaguesEnabled && (
              <TranscriptInfoRowWrapper data-testid={DataTestIds.RelevantColleagues}>
                <PillSection title={relevantColleaguesTitle} items={relevantColleaguesPillData} />
              </TranscriptInfoRowWrapper>
            )}
            <TranscriptInfoRowWrapper data-testid={DataTestIds.CompanyRelationship} ref={containerRef}>
              {renderCompanyPills()}
              {displayMarkets && (
                <>
                  <Spacer />
                  <MarketPill market={market} containerRef={containerRef} />
                </>
              )}
              <ContentFlags />
            </TranscriptInfoRowWrapper>
          </x.div>
          {isPrePurchaseQuestionView && (
            <TranscriptQuestions questionsData={questionsData} transcriptQuestionsHeight={transcriptQuestionsHeight} />
          )}
        </TranscriptHeaderWrapper>
      )}
    </>
  );
};

TranscriptDetails.propTypes = {
  content: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        companyType: PropTypes.string,
        companyName: PropTypes.string,
      })
    ),
    contentType: PropTypes.string,
    externalTitle: PropTypes.string,
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        angleTypes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          })
        ),
        bio: PropTypes.string,
        company: PropTypes.string,
        id: PropTypes.number,
        jobTitle: PropTypes.string,
      })
    ),
    scheduledTimeUTC: PropTypes.string,
  }).isRequired,
  detailsCollapsed: PropTypes.bool,
  transcriptActions: PropTypes.node,
  searchQuery: PropTypes.arrayOf(PropTypes.object),
  isAccessible: PropTypes.bool,
};

export { TranscriptDetails as default, DataTestIds };
