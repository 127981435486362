import { useThemeTokens } from "@alphasights/alphadesign-components";

export const usePastProjectsBannerStyle = ({ isMobile = false }) => {
  const {
    color: { border, text },
    spacing: { inner },
  } = useThemeTokens();

  const modalContainer = {
    h: "100%",
    display: "flex",
    flexDirection: "column",
    gap: inner.base06,
    flexGrow: 1,
  };

  const textArea = {
    w: "100%",
    px: inner.base04,
    py: inner.base03,
    borderWidth: 200,
    borderStyle: "solid",
    borderColor: border.divider,
    borderRadius: "200",
    outlineColor: { focusVisible: border.selected },
    color: { _: text.strong._, placeholder: text.secondary },
    overflowY: "auto",
    resize: "none",
  };

  const buttonMargin = {
    mx: inner.base04,
  };

  return {
    modalContainer,
    textArea,
    buttonMargin,
  };
};
