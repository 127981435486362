export const useReschedulingGhostEventStyles = (step) => {
  const popupDiv = {
    position: "fixed",
    top: 0,
    right: 0,
    w: "100%",
    h: "100%",
    zIndex: 150,
  };

  return {
    popupDiv,
  };
};
