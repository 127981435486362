import { projects as projectsApi, interactions as interactionsApi } from "@alphasights/portal-api-client";
import { useCachedValue } from "hooks/useCachedValue";
import { useMemo } from "react";

export const useProjectInteractionCache = () => {
  const { value: project, setRequestedValue: setProject, isLoading: projectLoading } = useCachedValue<Project>({
    fn: (token) => projectsApi.getProject(token),
  });

  const { value: interaction, setRequestedValue: setInteraction, isLoading: interactionLoading } = useCachedValue<
    Interaction
  >({
    fn: (key) => {
      const [token, id] = key.split(",");

      return interactionsApi.getSync({ token }, id);
    },
  });

  const data = useMemo(() => {
    if (!project) return null;
    if (!interaction) return null;

    return { project, interaction };
  }, [project, interaction]);

  return useMemo(
    () => ({
      fetchProjectInteraction: ({ projectToken, interactionId }: { projectToken: string; interactionId: string }) => {
        setProject(projectToken);
        setInteraction(`${projectToken},${interactionId}`);
      },
      data,
      isLoading: projectLoading || interactionLoading,
    }),
    [data, projectLoading, interactionLoading] // eslint-disable-line react-hooks/exhaustive-deps
  );
};
