import { CONTENT_TYPE } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useOnClick } from "hooks/useOnClickHooks";
import { useEffect, useRef, useState } from "react";

const isPrimer = (content: Content) =>
  [CONTENT_TYPE.companyPrimer, CONTENT_TYPE.marketPrimer].includes(content?.contentType?.toString());

/**
 * The logic for whether the AlphaNow Sidebar should be expanded or collapsed.
 *
 * - If a Primer is selected, the sidebar should collapse
 *   - Otherwise (AlphaView, PCC), the sidebar should remain expanded
 * - `onSidebarExpanded` from the return response can be used to forcefully expand the sidebar
 *   - E.g. if the hamburger menu is clicked
 * - Clicking on the document (outside of the sidebar (which should apply `sidebarRef`
 *   from the response)) should collapse the sidebar.
 */
export const useCollapsableSidebar = ({
  selectedContent,
  isRequestPrimer = false,
}: {
  selectedContent: Content;
  isRequestPrimer?: boolean;
}) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const { isMobile } = useCheckScreen();

  useEffect(() => {
    if (!selectedContent && !isRequestPrimer) {
      setIsSidebarExpanded(true);
    } else if (isPrimer(selectedContent) || isMobile) {
      setIsSidebarExpanded(false);
    } else if (!isPrimer(selectedContent) && !isMobile) {
      setIsSidebarExpanded(true);
    } else if (isRequestPrimer) {
      setIsSidebarExpanded(false);
    }
  }, [selectedContent, isMobile, isRequestPrimer]);

  const contentContainerRef = useRef(null);

  useOnClick(
    ({ didClickInsideRef }) => {
      if (!isPrimer(selectedContent)) return;

      if (didClickInsideRef(contentContainerRef)) {
        setIsSidebarExpanded(false);
      }
    },
    [selectedContent]
  );

  return {
    isSidebarExpanded,
    contentContainerRef,
    onSidebarExpanded: () => setIsSidebarExpanded(true),
    onSidebarClose: () => setIsSidebarExpanded(false),
  };
};
