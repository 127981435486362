import { RefObject, useCallback, useEffect } from "react";
import { eventOnElement } from "utils";

type ClickEvent = (this: Document, event: MouseEvent | TouchEvent) => void;

type DidClickInsideRefCallback<Element> = (ref: RefObject<Element>) => boolean;

type DidClickInsideHandler<Element> = ({
  event,
  didClickInsideRef,
}: {
  event: MouseEvent | TouchEvent;
  didClickInsideRef: DidClickInsideRefCallback<Element>;
}) => void;

export const didClickOnScrollbar = (event: MouseEvent | TouchEvent) =>
  (event as any).offsetX > (event.target as HTMLElement).clientWidth;

/**
 * A hook that calls {@link handler} whenever **any** element on the screen is clicked.
 *
 * @see useOnClick to provide a utility function to determine if a ref was clicked.
 * @see useOnClickOutside to filter out events from a particular ref.
 */
export function useOnDocumentClick(handler: ClickEvent) {
  const mousedown = "mousedown",
    touchstart = "touchstart";

  useEffect(() => {
    document.addEventListener(mousedown, handler);
    document.addEventListener(touchstart, handler);

    return () => {
      document.removeEventListener(mousedown, handler);
      document.removeEventListener(touchstart, handler);
    };
  }, [handler]);
}

/**
 * A hook that calls {@link handler} whenever **any** element is clicked.
 *
 * {@link handler} provides a {@link DidClickInsideRefCallback} function that takes a ref and returns whether the click was
 * within the element attached to the ref.
 *
 * @see useOnDocumentClick if {@link DidClickInsideRefCallback} is not needed.
 * @see useOnClickOutside to filter out events from a particular ref.
 */
export function useOnClick<Element extends HTMLElement>(
  handler: DidClickInsideHandler<Element>,
  dependencies: any[] = []
) {
  const onDocumentClick = useCallback<ClickEvent>(
    (event) => {
      const didClickInsideRef = (ref: RefObject<Element>) => !ref.current || eventOnElement(event, ref.current);

      handler({ event, didClickInsideRef });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies]
  );

  useOnDocumentClick(onDocumentClick);
}

/**
 * A hook that calls {@link handler} whenever an element is clicked, and it is **not** within the element associated with ref.
 *
 * @see useOnDocumentClick to invoke {@link handler} on any click, not only the ones outside of {@link ref}
 * @see useOnClick to instead receive a function ({@link DidClickInsideRefCallback}) that accepts a ref and returns if the click was within the ref.
 */
export default function useOnClickOutside<Element extends HTMLElement>(
  ref: RefObject<Element> | RefObject<Element>[],
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useOnClick(
    ({ event, didClickInsideRef }) => {
      const refArray = Array.isArray(ref) ? ref : [ref];

      // Do nothing if clicking ref's element or descendent elements
      if (refArray.some(didClickInsideRef)) return;

      handler(event);
    },
    [ref, handler]
  );
}
