import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  Avatar,
  Button,
  Loading,
  MobileSidebar,
  MobileSidebarDivider,
  MobileSidebarOption,
  MobileSidebarSubOption,
  ToastContainer,
  useAlphaToast,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { CalendarAvailable, Dashboard, Info, Library, Settings } from "@alphasights/alphadesign-icons";
import { openLoginUrl } from "@alphasights/auth-components";
import { CookiePolicyScript, useEnv, useNewNavigation } from "@alphasights/client-portal-shared";
import { useCurrentUser, useMyProjects, useMyTopProjects } from "@alphasights/portal-auth-react";
import { x } from "@xstyled/styled-components";
import { compact } from "lodash";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { BrowserRouter, Route as ReactRouterRoute, Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useFeatures } from "app/wrappers/FeatureFlagsWrapper";
import ErrorBoundary from "components/ErrorPage/ErrorBoundary";
import { GlobalNav } from "components/GlobalNav";
import { withNavigationContainer } from "components/NavigationContainer/NavigationContainer";
import { Icon } from "components/alphaui";
import { VIEW_ALPHA_GPT_PERMISSION, VIEW_CONTENT_PERMISSION } from "constants/AlphaNow";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { useAccessControl } from "hooks/useAccessControl";
import useQueryParams from "hooks/useQueryParams";
import useRedirect from "hooks/useRedirect";
import { useLogger } from "logging/Logger";
import { AlphaGPTPage } from "pages/AlphaGPTPage";
import { AlphaNowPage } from "pages/AlphaNowPage";
import { AlphaNowContentContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import useContentAccessLevel from "pages/AlphaNowPage/hooks/useContentAccessLevel";
import { CallMePage } from "pages/CallMePage";
import { ProtectedRoute as LoginProtectedRoute } from "pages/Login";
import { CreatePasswordPage } from "pages/Login/CreatePasswordPage";
import { LoginPage } from "pages/Login/LoginPage";
import { MagicLinkLogin } from "pages/Login/MagicLinkLogin";
import { ReactivateAccount } from "pages/Login/ReactivateAccount";
import { ResetPasswordPage } from "pages/Login/ResetPasswordPage";
import NewsletterPreferencesPage from "pages/NewsletterPreferencesPage";
import { ProjectLaunchPage } from "pages/ProjectLaunchPage/ProjectLaunchPage";
import { ProjectPage } from "pages/ProjectPage";
import { UpcomingCallsPage } from "pages/UpcomingCallsPage";
import { AppSearchProvider } from "providers/AppSearchProvider";
import { BackdropProvider } from "providers/BackdropProvider";
import { UserBadgeProvider } from "providers/BadgeProvider";
import { MobileSidebarProvider, useMobileSidebarContext } from "providers/MobileSidebarProvider";
import { RealTimeCreditBalanceProvider } from "providers/RealTimeCreditBalanceProvider";
import { TwilioProvider } from "providers/TwilioProvider";
import { MobileComplianceResourcesPage } from "views/MobileComplianceResoucesPage";
import { TypographyEllipsis } from "views/MobileDeliverablesView/MobileContentCommons.component";
import { MobileProfilePage } from "../../pages/MyProfilePage/MobileProfilePage";
import { MyAlphasightsPage } from "./realtime/MyAlphasightsPage";

import "./index.css";

const App = React.memo(({ honeybadger }) => {
  const currentUser = useCurrentUser();
  const { isMobile } = useCheckScreen();
  const newNavigationEnabled = useNewNavigation();
  const hasAlphaGptPermission = useAccessControl([VIEW_ALPHA_GPT_PERMISSION]);
  const hasContentPermission = useAccessControl([VIEW_CONTENT_PERMISSION]);
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const alphaNowEnabled = currentUser ? currentUser.alphaNowEnabled && hasContentPermission : false;
  const alphaGPTEnabled = currentUser
    ? currentUser.alphaGPTEnabled && (!currentUser.internalUser || hasAlphaGptPermission)
    : false;
  const myAlphaSightsEnabled = !isMobile && !currentUser?.accessOnly;

  const features = useFeatures();

  const { toast } = useAlphaToast();
  const toastRef = useRef(toast);

  useLayoutEffect(() => {
    window.toast = toastRef.current;
  }, []);

  return (
    <ErrorBoundary honeybadger={honeybadger}>
      <Providers>
        <DoNotUseIe />
        <BrowserRouter>
          {/* AppSearchProvider uses react router location and therefore has to be inside BrowserRouter */}
          <AppSearchProvider>
            <PortalMobileSidebar
              showMobileView={isMobile}
              currentUser={currentUser}
              alphaNowEnabled={alphaNowEnabled}
              hasProjectsPermission={hasProjectsPermission}
            />
            <Switch>
              <Route exact path={"/"}>
                <Redirect to="/sign-in" />
              </Route>
              <Route path={"/services"}>
                <Redirect to="/" />
              </Route>
              <Route
                path={["/sign-in", "/sign-up"]}
                component={features.launchNewLogin ? RedirectToLogin : LoginPage}
              />
              <Route path={"/login-success"} component={RedirectToNextRoute} />
              <Route
                path={"/reactivations/:token"}
                component={features.launchNewLogin ? RedirectToReactivations : ReactivateAccount}
              />
              <Route
                path={"/deactivated-account"}
                component={features.launchNewLogin ? RedirectToDeactivatedAccount : ReactivateAccount}
              />
              <Route
                path="/invitations/:token"
                component={features.launchNewLogin ? RedirectToInvitations : CreatePasswordPage}
              />
              <Route
                path="/magic-links/:token"
                component={features.launchNewLogin ? RedirectToMagicLinkLogin : MagicLinkLogin}
              />
              <Route
                path="/password-reset/:token"
                component={features.launchNewLogin ? RedirectToResetPassword : ResetPasswordPage}
              />
              <Route path="/:token/call-me/:interactionId" component={CallMePage} />
              <ProtectedRoute path="/alphagpt" component={AlphaGPTPageWrapper} isDisabled={!alphaGPTEnabled} />
              <ProtectedRoute path="/alphanow" component={AlphaNowPageWrapper} isDisabled={!alphaNowEnabled} />
              <ProtectedRoute path="/content" isDisabled={true} disabledRedirectTo="/alphanow" />
              <ProtectedRoute path="/projects" isDisabled={true} disabledRedirectTo={`/${myAlphaSightsBaseUrl}`} />
              <ProtectedRoute
                path="/project-launch"
                isDisabled={!currentUser?.projectLaunchEnabled}
                component={ProjectLaunchPage}
              />
              <ProtectedRoute path="/my-projects" isDisabled={true} disabledRedirectTo={`/${myAlphaSightsBaseUrl}`} />
              <ProtectedRoute
                path="/upcoming-calls"
                component={UpcomingCallsWrapper}
                isDisabled={!isMobile || currentUser?.accessOnly}
                disabledRedirectTo={`/${myAlphaSightsBaseUrl}`}
              />
              <ProtectedRoute
                path="/compliance-resources"
                component={MobileComplianceResourcesPage}
                isDisabled={!isMobile}
              />
              <ProtectedRoute path="/my-profile" component={MobileProfilePage} isDisabled={!isMobile} />
              <ProtectedRoute
                path={[`/${myAlphaSightsBaseUrl}`]}
                isDisabled={!myAlphaSightsEnabled}
                exact={newNavigationEnabled && myAlphaSightsEnabled}
                disabledRedirectTo="/upcoming-calls"
                component={newNavigationEnabled ? MyAlphasightsRedirector : MyAlphasightsPage}
              />
              <Route path="/newsletter/content/:trackingId/unsubscribe" component={NewsletterPreferencesPageWrapper} />
              <Route
                path={compact([newNavigationEnabled && `/${myAlphaSightsBaseUrl}/projects/:token`, "/:token"])}
                component={ProjectPage}
              />
              <Route component={NoMatch} />
            </Switch>
          </AppSearchProvider>
        </BrowserRouter>
        <ReactTooltip className="custom-react-tooltip" multiline={true} arrowColor="transparent" />
        <ToastContainer />
      </Providers>
      <CookiePolicyScript />
    </ErrorBoundary>
  );
});

export default App;

const MyAlphasightsRedirector = (props) => {
  const projects = useMyProjects() ?? [];
  const firstActiveProject = React.useMemo(() => projects.find((project) => !project.inactive), [projects]);
  return firstActiveProject && props.match.path === `/${myAlphaSightsBaseUrl}` ? (
    <Redirect {...props} to={`/${myAlphaSightsBaseUrl}/projects/${firstActiveProject.token}`} />
  ) : (
    <MyAlphasightsPage {...props} />
  );
};

const RedirectToInvitations = ({ match: { params } }) => {
  const { token } = params;
  const env = useEnv();
  const logger = useLogger();

  useEffect(() => {
    logger.log("new login launched");

    const invitationUrl = `${env.login.loginUrl}/invitations/${token}?redirectHost=${env.login.loginRedirectHost}&next=/my-projects`;
    window.location.href = invitationUrl;
  }, [env.login.loginRedirectHost, env.login.loginUrl, token, logger]);

  return null;
};

const RedirectToDeactivatedAccount = () => {
  const env = useEnv();

  useEffect(() => {
    const deactivatedAccountUrl = `${env.login.loginUrl}/deactivated-account?redirectHost=${env.login.loginRedirectHost}&next=/my-projects`;
    window.location.href = deactivatedAccountUrl;
  }, [env.login.loginRedirectHost, env.login.loginUrl]);

  return null;
};

const RedirectToResetPassword = ({ match: { params } }) => {
  const { token } = params;
  const env = useEnv();

  useEffect(() => {
    const resetPasswordUrl = `${env.login.loginUrl}/password-reset/${token}?redirectHost=${env.login.loginRedirectHost}&next=/my-projects`;
    window.location.href = resetPasswordUrl;
  }, [env.login.loginRedirectHost, env.login.loginUrl, token]);

  return null;
};

const RedirectToReactivations = ({ match: { params } }) => {
  const { token } = params;
  const env = useEnv();

  useEffect(() => {
    const reactivationUrl = `${env.login.loginUrl}/reactivations/${token}?redirectHost=${env.login.loginRedirectHost}&next=/my-projects`;
    window.location.href = reactivationUrl;
  }, [env.login.loginRedirectHost, env.login.loginUrl, token]);

  return null;
};

const RedirectToMagicLinkLogin = ({ match: { params } }) => {
  const { token } = params;
  const env = useEnv();

  useEffect(() => {
    const magicLinkUrl = `${env.login.loginUrl}/magic-links/${token}?redirectHost=${env.login.loginRedirectHost}&next=/my-projects`;
    window.location.href = magicLinkUrl;
  }, [env.login.loginRedirectHost, env.login.loginUrl, token]);

  return null;
};

const RedirectToLogin = () => {
  const env = useEnv();

  useEffect(() => {
    openLoginUrl({
      redirectHost: env.login.loginRedirectHost,
      loginUrl: env.login.loginUrl,
      next: "/login-success",
    });
  }, [env.login.loginRedirectHost, env.login.loginUrl]);

  return null;
};

/**
 * This component is used to redirect the user to the next route after login.
 * It assumes that the user is already logged in. So it waits for the currentUser to be available.
 */
const RedirectToNextRoute = () => {
  const redirect = useRedirect();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      redirect();
    }
  }, [redirect, currentUser]);

  if (!currentUser) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return null;
};

const Route = ({ component, ...props }) => {
  const componentWithNavigationContainer = React.useMemo(() => withNavigationContainer(component), [component]);
  return <ReactRouterRoute {...props} component={componentWithNavigationContainer} />;
};

const ProtectedRoute = ({ component, ...props }) => {
  const componentWithNavigationContainer = React.useMemo(() => withNavigationContainer(component), [component]);
  return <LoginProtectedRoute {...props} component={componentWithNavigationContainer} />;
};

const NoMatch = ({ location }) => (
  <div className="aui-mx-8">
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

const UpcomingCallsWrapper = () => (
  <x.div className="aui-h-screen aui-bg-grey-1" h="100dvh">
    <GlobalNav hideAdvisorsLink showLogin={true} url={"../"} />
    <UpcomingCallsPage />
  </x.div>
);

const AlphaNowPageWrapper = () => {
  const contentAccessLevel = useContentAccessLevel();

  return (
    <div className="aui-flex aui-flex-col aui-h-screen aui-overflow-hidden">
      <x.div flex="0 0 auto">
        <GlobalNav hideAdvisorsLink showLogin={true} url={"../"} />
      </x.div>
      <AlphaNowContentContext.Provider value={contentAccessLevel}>
        <BackdropProvider>
          <AlphaNowPage />
        </BackdropProvider>
      </AlphaNowContentContext.Provider>
    </div>
  );
};

const AlphaGPTPageWrapper = () => (
  <div className="aui-flex aui-flex-col aui-h-screen aui-overflow-hidden">
    <div style={{ flex: "0 0 auto" }}>
      <GlobalNav hideAdvisorsLink showLogin={true} url={"../"} />
    </div>
    <AlphaGPTPage />
  </div>
);

const NewsletterPreferencesPageWrapper = ({
  match: {
    params: { trackingId },
  },
}) => (
  <>
    <GlobalNav url={"../"} />
    <NewsletterPreferencesPage trackingId={trackingId} />
  </>
);

const DoNotUseIe = () => {
  if (navigator.userAgent.indexOf("MSIE") < 0 && navigator.userAgent.indexOf("Trident") < 0) {
    return null;
  }

  return (
    <div className="aui-border-0 aui-border-b aui-border-solid aui-border-warning-2 aui-bg-warning-1 aui-p-5 aui-text-center aui-text-grey-5">
      <Icon icon="alert-triangle" className="aui-mr-2" />
      Browser not supported. Please use{" "}
      <a className="aui-no-underline aui-text-primary-1 aui-cursor-pointer" href="https://www.microsoft.com/en-us/edge">
        Microsoft Edge
      </a>
      ,{" "}
      <a className="aui-no-underline aui-text-primary-1 aui-cursor-pointer" href="https://www.google.com/chrome/">
        Google Chrome
      </a>{" "}
      or{" "}
      <a
        className="aui-no-underline aui-text-primary-1 aui-cursor-pointer"
        href="https://www.mozilla.org/en-US/firefox/new/"
      >
        Mozilla Firefox
      </a>{" "}
      to access the AlphaSights Client Platform.
    </div>
  );
};

const PortalMobileSidebar = ({ showMobileView, currentUser, alphaNowEnabled, hasProjectsPermission }) => {
  const { isOpen: isNewMobileSidebarOpen, toggleSidebar: toggleNewMobileSidebar } = useMobileSidebarContext();
  const allProjects = useMyTopProjects();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const { openLoginModal } = useClientPortalOperations();
  const {
    spacing: { inner },
  } = useThemeTokens();

  useEffect(() => {
    if (showMobileView && queryParams.get("openSidebar")) {
      toggleNewMobileSidebar();
      queryParams.delete("openSidebar");
    }
  }, [showMobileView, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const onActionLoginNeeded = useCallback(
    (action = () => {}) => () => {
      if (currentUser) {
        action();
      } else {
        toggleNewMobileSidebar();
        openLoginModal({ allowAnonymousContinue: true });
      }
    },
    [openLoginModal, toggleNewMobileSidebar, currentUser]
  );

  if (!showMobileView) return null;

  const footerContent = currentUser ? (
    <x.div display="flex" flexDirection="row" alignItems="center" gap={inner.base02}>
      <Avatar size="large" text={currentUser.name} />
      <x.div maxWidth="200px">
        <TypographyEllipsis variant="body">{currentUser.name}</TypographyEllipsis>
        <TypographyEllipsis variant="body" color="text-assistive">
          {currentUser.email}
        </TypographyEllipsis>
      </x.div>
    </x.div>
  ) : (
    <x.div display="flex" flexDirection="row" alignItems="center" gap={inner.base02} flex="1">
      <Button variant="outline" flex="1" onClick={onActionLoginNeeded()}>
        Sign In
      </Button>
    </x.div>
  );

  const onOpenNewPage = (url) => {
    history.push(url);
    toggleNewMobileSidebar();
  };

  const projectsToShow = hasProjectsPermission && allProjects?.length > 0 ? allProjects : [];

  const termsUrl = currentUser?.accessOnly
    ? "https://www.alphasights.com/subscription-user-platform-terms/"
    : "https://www.alphasights.com/clientfacingdomainterms-platformterms/";
  const privacyPolicyUrl = "https://www.alphasights.com/policies/";
  const dataProcessingAgreementUrl = "https://www.alphasights.com/clientfacingdomainterms-dpa/";

  const isActive = (path) => {
    return pathname.includes(path);
  };

  const tokenMatches = pathname.matchAll(/^\/(\w{15})\//g).next().value;
  const projectToken = tokenMatches ? tokenMatches[1] : null;

  return (
    <>
      <MobileSidebar
        isOpen={isNewMobileSidebarOpen}
        onClose={toggleNewMobileSidebar}
        footerContent={footerContent}
        data-testid="mobile-side-bar"
        h="100dvh"
      >
        {!currentUser?.accessOnly && (
          <MobileSidebarOption
            title="Upcoming Calls"
            leftDecorator={<CalendarAvailable />}
            onClick={onActionLoginNeeded(() => onOpenNewPage("/upcoming-calls"))}
            isActive={isActive("/upcoming-calls")}
          />
        )}
        {alphaNowEnabled && (
          <MobileSidebarOption
            title="Library"
            leftDecorator={<Library />}
            onClick={onActionLoginNeeded(() => onOpenNewPage("/alphanow"))}
            isActive={isActive("/alphanow")}
          />
        )}
        <MobileSidebarDivider />
        {!currentUser && (
          <>
            <MobileSidebarOption
              title="View all projects"
              leftDecorator={<Dashboard />}
              onClick={onActionLoginNeeded()}
            />
            <MobileSidebarDivider />
          </>
        )}
        {!currentUser?.accessOnly && projectsToShow.length > 0 && (
          <MobileSidebarOption title="View all projects" leftDecorator={<Dashboard />}>
            {projectsToShow.map((project) => (
              <MobileSidebarSubOption
                key={project.token}
                title={project.title}
                onClick={() => onOpenNewPage(`/${project.token}/experts`)}
                isActive={isActive(`/${project.token}`)}
              />
            ))}
          </MobileSidebarOption>
        )}
        {!currentUser?.accessOnly &&
          projectsToShow
            .slice(0, 3)
            .map((project) => (
              <MobileSidebarOption
                key={project.token}
                title={project.title}
                onClick={() => onOpenNewPage(`/${project.token}/experts`)}
                isActive={isActive(`/${project.token}`)}
              />
            ))}
        {!currentUser?.accessOnly && projectsToShow.length > 0 && <MobileSidebarDivider />}
        <MobileSidebarOption title="Info" leftDecorator={<Info />}>
          <MobileSidebarSubOption
            title="AlphaSights Compliance"
            isActive={isActive("/compliance-resources")}
            onClick={() =>
              (window.location.href = projectToken ? `/${projectToken}/compliance-resources` : "/compliance-resources")
            }
          />
          <MobileSidebarSubOption title="Platform Terms" onClick={() => (window.location.href = termsUrl)} />
          <MobileSidebarSubOption
            title="Data Processing Agreement"
            onClick={() => (window.location.href = dataProcessingAgreementUrl)}
          />
          <MobileSidebarSubOption
            title="Privacy and Cookie Policy"
            onClick={() => (window.location.href = privacyPolicyUrl)}
          />
          <MobileSidebarSubOption title="Manage Cookies" className="cky-banner-element" />
        </MobileSidebarOption>
        <MobileSidebarOption
          title="Preferences"
          leftDecorator={<Settings />}
          onClick={onActionLoginNeeded(() => onOpenNewPage("/my-profile"))}
          isActive={isActive("/my-profile")}
        />
      </MobileSidebar>
    </>
  );
};

const Providers = ({ children }) => {
  const currentUser = useCurrentUser();

  return (
    <UserBadgeProvider badges={currentUser?.badges}>
      <MobileSidebarProvider>
        <TwilioProvider>
          <RealTimeCreditBalanceProvider>{children}</RealTimeCreditBalanceProvider>
        </TwilioProvider>
      </MobileSidebarProvider>
    </UserBadgeProvider>
  );
};
