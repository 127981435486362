import { useRef } from "react";
import { Completer } from "utils/completer";

export const AsyncDebounceErrors = {
  positiveDelay: "You must have a positive delay to use debounce",
};

/**
 * TODO: Move changes made here to ADS Community package and import from there
 *
 * A variant of useDebounce meant for async calls.
 *
 * The main difference is that useAsyncDebounce will track the request promises (e.g. search)
 * and if the query takes some time, will debounce the query to only call transform with the latest results.
 **/
export const useAsyncDebounce = <TArg extends unknown[], TResponse>(params: {
  promise: (...args: TArg) => Promise<TResponse>;
  delay: number;
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const promiseRef = useRef<Promise<TResponse>>();

  const debounce = async (...args: TArg) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (params.delay <= 0) throw AsyncDebounceErrors.positiveDelay;

    const completer = new Completer<TResponse>();

    timeoutRef.current = setTimeout(() => {
      const promise = params.promise(...args);

      void promise.then((value) => {
        if (promise !== promiseRef.current) return;

        completer.complete(value);
      });

      promiseRef.current = promise;
    }, params.delay);

    return await completer.promise;
  };

  debounce.cancel = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
    promiseRef.current = undefined;
  };

  return debounce;
};
