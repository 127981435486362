import styled from "styled-components";
import { th } from "@xstyled/styled-components";

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  border-radius: ${th.radius("small")};
  margin-top: ${th.space("inner-base02")};
  margin-bottom: ${th.space("inner-base02")};
  padding-top: ${th.space("inner-base02")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const ProgressBarBackground = styled.div`
  width: 100%;
  background-color: ${th.color("background-neutral-default")};
  border-radius: ${th.radius("small")};
`;

export const ProgressBarFill = styled.div`
  background: ${th.color("background-action-secondary-default")};
  height: 8px;
  border-radius: ${th.radius("small")};
  width: ${(props) => props.progress}%;
`;
