import React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Quote } from "@alphasights/alphadesign-icons";
import Tag from "components/Tag";

export const MentionsFlag = ({ hitCount }: { hitCount: number }) => {
  const { shape, color } = useThemeTokens();

  return hitCount ? (
    <Tag
      data-testid="mentions-flag"
      name={`${hitCount}`}
      icon={<Quote />}
      py="2px"
      color={color.text.secondary}
      style={{ spaceX: shape.border.width.large, bg: color.background.neutral.default }}
    />
  ) : null;
};
