import { useState, useEffect } from "react";

const useFetchStatus = (fetchStatuses: any) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isLoading = Object.values(fetchStatuses).some((status) => status === "loading");
    setLoading(isLoading);
  }, [fetchStatuses]);

  return { isLoading: loading };
};

export { useFetchStatus as default };
