import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import useStyles from "./styles";
import { useAudioPlayer, useAutoScroll } from "hooks/useAudioPlayer";
import { TopBar } from "./TopBar";
import {
  getAvailableRecordings,
  getAvailableTranscripts,
  getDefaultRecording,
  getDefaultTranscript,
  getTranscriptForType,
} from "./helpers";
import { ContentView, MAIN_CONTENT_VIEWS } from "./MainContentViews";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { noop } from "lodash";
import { Icon, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@alphasights/alphadesign-components";
import { AudioPlayer } from "./AudioPlayer";
import {
  ENABLE_AI_SUMMARIZATION,
  ENABLE_NEW_DELIVERABLES_UX,
  ProjectBadged,
  SHOW_DISABLED_AI_SUMMARIZATION,
  useProjectBadgeContext,
} from "providers/BadgeProvider";
import { Sentence, Summary, SummaryStatus, SummaryType } from "./AiSummaries/AiSummaries.types";
import { AiSummary } from "./AiSummaries";
import { ChevronUp, Close } from "@alphasights/alphadesign-icons";
import { useIsVisibleOnScreen } from "hooks/useIsVisibleOnScreen";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import useQueryParams from "hooks/useQueryParams";
import { useAlertMessengerContext } from "components/InteractionsPage/AlertContextProvider";
import { formatDistanceToNow, parseISO } from "date-fns";

type TranscriptView = "transcript" | SummaryType;

interface TranscriptMainContentProps {
  interaction: Interaction;
  isFlyoutOpen: boolean;
  appliedKeywords: string[];
  initialTranscriptType: string;
  initialView: TranscriptView;
  fragmentIds: number[];
}

const TranscriptMainContent = ({
  interaction,
  isFlyoutOpen,
  appliedKeywords,
  initialTranscriptType,
  initialView = "transcript",
  fragmentIds,
}: TranscriptMainContentProps) => {
  const { hasProjectBadge } = useProjectBadgeContext();

  const fixedTopRef = useRef(null);
  const transcriptContentRef = useRef(null);
  const { logHit } = useTrackUserAction();
  const [currentRecording, setCurrentRecording] = useState<Recording>(getDefaultRecording(interaction));
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [currentTranscript, setCurrentTranscript] = useState<TranscriptRequest>();
  const [viewMode, setViewMode] = useState(
    interaction?.score > 0 ? MAIN_CONTENT_VIEWS.Mentions : MAIN_CONTENT_VIEWS.Transcript
  );
  const [contentSearchTerms, setContentSearchTerms] = useState<string[]>(interaction?.score > 0 ? appliedKeywords : []);

  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [mentions, setMentions] = useState([]);

  const { fetchDeliverable, getAiSummaries } = useDeliverableContext();

  const availableRecordings = useMemo(() => {
    return getAvailableRecordings(interaction);
  }, [interaction]);

  const availableTranscripts = useMemo(() => {
    return getAvailableTranscripts(currentRecording);
  }, [currentRecording]);

  useEffect(
    function resetView() {
      if (!contentSearchTerms || contentSearchTerms.length === 0) {
        setViewMode(MAIN_CONTENT_VIEWS.Transcript);
      }
    },
    [contentSearchTerms]
  );

  const summaryFrom = useCallback(
    (selectedViewType: TranscriptView) => {
      return (
        summaries.find((s) => s.type === selectedViewType) ||
        ({
          type: selectedViewType,
          status: SummaryStatus.NotRequested,
        } as Summary)
      );
    },
    [summaries]
  );

  const canPlayAudio = useMemo(() => {
    return (
      currentRecording && currentRecording.visibleToClient && !currentRecording.purgedAt && !currentRecording.obfuscated
    );
  }, [currentRecording]);

  const [showingPlayer, setShowingPlayer] = useState<Boolean>(canPlayAudio);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [isAiSummariesLoading, setIsAiSummariesLoading] = useState(false);
  const [totalMentionsMatches, setTotalMentionsMatches] = useState(0);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [selectedKeyQuoteIds, setSelectedKeyQuoteIds] = useState<number[]>([]);
  const [totalSummaryMatches, setTotalSummaryMatches] = useState(0);
  const [selectedSummaryIndex, setSelectedSummaryIndex] = useState(0);
  const [selectedTranscriptView, setSelectedTranscriptView] = useState<TranscriptView>(initialView);

  const onSummaryHighlight = (totalHighlights: number) => {
    setTotalSummaryMatches(totalHighlights);
  };

  const onReloadTranscript = ({ transcript = currentTranscript, keywords = contentSearchTerms }) => {
    if (!transcript) return;
    setIsTranscriptLoading(true);
    fetchDeliverable(transcript, keywords)
      .then(({ sentences, mentions, totalMatches }: any) => {
        setMentions(mentions);
        setSentences(sentences);
        setTotalMentionsMatches(totalMatches);
      })
      .catch((e: any) => {
        setMentions([]);
        setSentences([]);
        setTotalMentionsMatches(0);
        console.error(e);
      })
      .finally(() => setIsTranscriptLoading(false));
  };

  const loadSummariesContents = () => {
    setIsAiSummariesLoading(true);
    getAiSummaries(interaction.id, currentRecording.id)
      .then((aiSummaries: Summary[]) => {
        setSummaries(aiSummaries);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsAiSummariesLoading(false);
      });
  };

  const onFeedbackUpdated = (feedbackType: string) => {
    setSummaries([
      ...summaries.filter((s) => s.type !== selectedTranscriptView),
      {
        ...summaries.find((s) => s.type === selectedTranscriptView)!,
        feedbackType: feedbackType.toUpperCase(),
      },
    ]);
  };

  const onGenerateClicked = (summariesStatus: Summary[]) => {
    setSummaries(summariesStatus);
  };

  const onChangeTranscript = (transcript: TranscriptRequest) => {
    if (currentTranscript) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.transcriptToggled,
        advisorshipId: interaction.id,
        projectToken: interaction.projectToken,
        details: {
          from: currentTranscript.transcriptType,
          to: transcript.transcriptType,
        },
        references: {
          fromTranscriptId: currentTranscript.id,
          toTranscriptId: transcript.id,
        },
      });
    }

    onReloadTranscript({ transcript });
    setCurrentTranscript(transcript);

    if (hasProjectBadge(ENABLE_AI_SUMMARIZATION) && transcript) {
      if (transcript.transcriptType === "summary") {
        setSelectedTranscriptView("transcript");
      }
      loadSummariesContents();
    }
  };

  const fadeHighlight = (element: HTMLElement, timeout: number) => {
    const color = (opacity: number) => `rgba(49, 129, 255, ${opacity})`;
    element.style.backgroundColor = color(0.5);
    element.animate(
      {
        backgroundColor: color(0),
      },
      timeout
    );
    setTimeout(() => {
      element.style.backgroundColor = color(0);
    }, timeout - 50);
  };

  const queryParams = useQueryParams();
  // @ts-ignore
  const { addPermanentMessage } = useAlertMessengerContext();

  useEffect(
    function highlightPartFromURL() {
      const highlighPart = queryParams.get("part");
      if (!highlighPart) return;
      if (sentences.length === 0) return;

      const ids = [parseInt(highlighPart)];

      if (ids.length === 0) {
        addPermanentMessage({
          message: (
            <Typography mr="24px" data-testid="deliverable-not-found-alert">
              Could not match the requested quote. Please search for keywords across transcripts at top left.
            </Typography>
          ),
          variant: "warning",
          marginLeft: {
            md: "375px",
            xl: "475px",
          },
        });
      } else {
        setSelectedKeyQuoteIds(ids);
      }
      queryParams.delete("part");
    },
    [queryParams, sentences, addPermanentMessage]
  );

  useEffect(() => {
    onChangeTranscript(
      getTranscriptForType(currentRecording, initialTranscriptType) || getDefaultTranscript(currentRecording)
    );
  }, [currentRecording, initialTranscriptType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(function () {
      const elements = selectedKeyQuoteIds.map(
        (id) => document.querySelector(`span[data-transcript-id='${id}']`) as HTMLElement
      );

      elements[0] &&
        elements[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

      elements.forEach((element, idx) => {
        fadeHighlight(element, 2000);
      });
    }, 500);
  }, [selectedKeyQuoteIds]);

  // @ts-ignore
  const styles = useStyles({ obfuscated: currentRecording?.obfuscated });

  const audioPlayer = useAudioPlayer({
    audioUrl: currentRecording.url,
    vttUrl: null,
    disabled: !canPlayAudio,
  });

  const scrollableAudioPlayer = useAutoScroll({
    audioPlayer,
    onNonAutomaticScrollEnded: noop,
  });

  const {
    transcriptProps,
    isLoading: isRecordingLoading,
    renderElements: playerRenderElements,
    controlsProps,
    progressBarProps,
  } = useLoggableAudioPlayer({
    audioPlayer: scrollableAudioPlayer,
    currentTranscript,
    interaction,
  });

  const onTogglePlayer = () => {
    if (showingPlayer) {
      controlsProps.playing && controlsProps.onTogglePlay();
      setShowingPlayer(false);
    } else {
      setShowingPlayer(true);
    }
  };

  const onContentSearchQueryChange = (keywords: string[]) => {
    onReloadTranscript({ keywords });
    setContentSearchTerms(keywords);

    if (keywords.length > 0)
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.transcriptSearch,
        projectToken: interaction.projectToken,
        advisorshipId: interaction.id,
        details: { filters: keywords },
      });
  };

  const findNextMatch = (selectedIndex: number, totalMatches: number, setSelectedIndex: (idx: number) => void) => {
    if (totalMatches === 0) return;

    const isLastKeywordSelected = selectedIndex === totalMatches - 1;
    const nextIndex = isLastKeywordSelected ? 0 : selectedIndex + 1;
    setSelectedIndex(nextIndex);
  };

  const onFindNextMatch = () => {
    if (selectedTranscriptView === "transcript") {
      findNextMatch(selectedMentionIndex, totalMentionsMatches, setSelectedMentionIndex);
    } else {
      findNextMatch(selectedSummaryIndex, totalSummaryMatches, setSelectedSummaryIndex);
    }
  };

  const findPreviousMatch = (selectedIndex: number, totalMatches: number, setSelectedIndex: (idx: number) => void) => {
    if (totalMatches === 0) return;

    const isFirstKeywordSelected = selectedIndex === 0;
    const previousIndex = isFirstKeywordSelected ? totalMatches - 1 : selectedIndex - 1;

    setSelectedIndex(previousIndex);
  };

  const onFindPreviousMatch = () => {
    if (selectedTranscriptView === "transcript") {
      findPreviousMatch(selectedMentionIndex, totalMentionsMatches, setSelectedMentionIndex);
    } else {
      findPreviousMatch(selectedSummaryIndex, totalSummaryMatches, setSelectedSummaryIndex);
    }
  };

  useEffect(
    function resetIndex() {
      if (totalMentionsMatches <= selectedMentionIndex) {
        setSelectedMentionIndex(0);
      }
      if (totalSummaryMatches <= selectedSummaryIndex) {
        setSelectedSummaryIndex(0);
      }
    },
    [
      totalMentionsMatches,
      selectedMentionIndex,
      setSelectedMentionIndex,
      totalSummaryMatches,
      selectedSummaryIndex,
      setSelectedSummaryIndex,
    ]
  );

  useEffect(
    function savePlayerPosition() {
      if (progressBarProps.value === 0) return;

      sessionStorage.setItem(currentRecording.id, progressBarProps.value);
    },
    [progressBarProps.value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function restorePlayerPosition() {
      if (isRecordingLoading || !currentRecording || fragmentIds?.length) return;

      const seconds = sessionStorage.getItem(currentRecording.id);

      if (!seconds) return;

      progressBarProps.onChange(seconds);
    },
    [isRecordingLoading, currentRecording] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSelectedTranscriptViewChange = (value: any) => {
    setSelectedTranscriptView(value);

    if (value !== "transcript") {
      setViewMode(MAIN_CONTENT_VIEWS.Transcript);
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.aiSummaryChangeTab,
        advisorshipId: interaction.id,
        projectToken: interaction.projectToken,
        details: {
          viewSelected: value,
        },
        references: { transcriptId: currentTranscript?.id },
      });
    }
  };

  const hasAiSummarization = hasProjectBadge(ENABLE_AI_SUMMARIZATION);
  const englishTranscript = interaction.language === "en";
  const hasShowDisabledAiSummarization = hasProjectBadge(SHOW_DISABLED_AI_SUMMARIZATION);

  const showAiSummaryBar = useMemo(() => {
    return (
      currentTranscript &&
      englishTranscript &&
      (hasAiSummarization || hasShowDisabledAiSummarization) &&
      currentTranscript.transcriptType !== "summary"
    );
  }, [currentTranscript, englishTranscript, hasAiSummarization, hasShowDisabledAiSummarization]);

  const showAiSummaryContent = useCallback(
    (hasAiSummarization: boolean) => {
      return currentTranscript && hasAiSummarization && selectedTranscriptView !== "transcript";
    },
    [currentTranscript, selectedTranscriptView]
  );

  const allowSummaryDownload = useMemo(() => {
    const hasRegularTranscript = currentRecording.transcriptRequests.find((tr) => tr.transcriptType === "regular");
    const hasSummaryCompleted = summaries.find(
      (s) => s.type === SummaryType.Comprehensive && s.status === SummaryStatus.Generated
    );

    return hasAiSummarization && hasRegularTranscript && hasSummaryCompleted;
  }, [hasAiSummarization, currentRecording, summaries]);

  return (
    <x.div data-testid="deliverables-main-content" {...styles.mainContent}>
      {playerRenderElements}
      <TopBar
        interaction={interaction}
        togglePlayer={onTogglePlayer}
        showingPlayer={canPlayAudio && showingPlayer}
        canPlayAudio={canPlayAudio}
        availableRecordings={availableRecordings}
        currentRecording={currentRecording}
        setCurrentRecording={setCurrentRecording}
        currentTranscript={currentTranscript}
        setCurrentTranscript={onChangeTranscript}
        availableTranscripts={availableTranscripts}
        isFlyoutOpen={isFlyoutOpen}
        selectedTranscriptView={selectedTranscriptView}
        viewMode={viewMode}
        setViewMode={setViewMode}
        contentSearchTerms={contentSearchTerms}
        onContentSearchQueryChange={onContentSearchQueryChange}
        selectedMentionIndex={selectedTranscriptView !== "transcript" ? selectedSummaryIndex : selectedMentionIndex}
        totalMentionsMatches={selectedTranscriptView !== "transcript" ? totalSummaryMatches : totalMentionsMatches}
        onFindPreviousMatch={onFindPreviousMatch}
        onFindNextMatch={onFindNextMatch}
        isTranscriptLoading={isTranscriptLoading}
        onTranscriptToggling={() => setSentences([])}
        allowSummaryDownload={allowSummaryDownload}
        controlsProps={controlsProps}
        progressBarProps={progressBarProps}
      />
      <x.div
        data-testid="deliverables-main-text"
        data-transcript-scrollable-area
        {...styles.transcriptContentText}
        ref={transcriptContentRef}
      >
        {showAiSummaryBar && (
          <x.div {...styles.fixedTopStyles} ref={fixedTopRef}>
            <ToggleButtonGroup
              value={selectedTranscriptView}
              onChange={onSelectedTranscriptViewChange}
              allowUnselection={false}
              data-testid="ai-summaries-toggle"
            >
              <ToggleButton value="transcript" data-testid="toggle-transcript">
                Transcript
              </ToggleButton>
              <ToggleButton value={SummaryType.Comprehensive} data-testid="toggle-comprehensive">
                Comprehensive summary
              </ToggleButton>
              <ToggleButton value={SummaryType.Condensed} data-testid="toggle-condensed">
                Condensed summary
              </ToggleButton>
              <ToggleButton value={SummaryType.KeyQuotes} data-testid="toggle-key-quotes">
                Key quotes
              </ToggleButton>
            </ToggleButtonGroup>
          </x.div>
        )}
        <x.div {...styles.transcriptMainContent}>
          {selectedTranscriptView === "transcript" && (
            <ContentView
              interaction={interaction}
              currentTranscript={currentTranscript}
              canPlayAudio={canPlayAudio}
              transcriptProps={transcriptProps}
              showingPlayer={showingPlayer}
              onHidePlayer={onTogglePlayer}
              contentSearchTerms={contentSearchTerms}
              viewMode={viewMode}
              setViewMode={setViewMode}
              selectedMentionIndex={selectedMentionIndex}
              setSelectedMentionIndex={setSelectedMentionIndex}
              totalMentionsMatches={totalMentionsMatches}
              isTranscriptLoading={isTranscriptLoading}
              sentences={sentences}
              mentions={mentions}
              recording={currentRecording}
              fragmentIds={fragmentIds}
              obfuscated={currentRecording?.obfuscated}
            />
          )}
          {showAiSummaryContent(hasAiSummarization) && (
            <AiSummary
              interactionId={interaction.id}
              recordingId={currentRecording.id}
              transcriptId={currentTranscript!.id}
              summary={summaryFrom(selectedTranscriptView)}
              contentSearchTerms={contentSearchTerms}
              selectedSummaryIndex={selectedSummaryIndex}
              isAiSummariesLoading={isAiSummariesLoading}
              isTranscriptLoaded={!!sentences?.length}
              sentences={sentences}
              onSummaryHighlight={onSummaryHighlight}
              onGenerateClicked={onGenerateClicked}
              onSummaryGenerated={() => loadSummariesContents()}
              onFeedbackUpdated={onFeedbackUpdated}
              onTranscriptLinkClick={(cueIds) => {
                if (cueIds.length) {
                  setSelectedTranscriptView("transcript");
                  setSelectedKeyQuoteIds(cueIds);
                }
              }}
              obfuscated={currentRecording?.obfuscated}
            />
          )}
          {showAiSummaryContent(!hasAiSummarization) && (
            <x.div {...styles.disabledAiSummarizationWrapper} data-testid="disabled-ai-summarization">
              <Icon color="danger" children={<Close />} />
              <Typography variant="body-small">
                Your organization has not enabled generative AI summarization.
              </Typography>
            </x.div>
          )}
        </x.div>
        <ProjectBadged badge={ENABLE_AI_SUMMARIZATION}>
          <ScrollToTop transcriptContentRef={transcriptContentRef} fixedTopRef={fixedTopRef} />
        </ProjectBadged>
      </x.div>
      {canPlayAudio &&
        showingPlayer &&
        currentTranscript &&
        !(hasProjectBadge(ENABLE_NEW_DELIVERABLES_UX) && selectedTranscriptView !== "transcript") && (
          <x.div data-testid="deliverables-player" {...styles.player}>
            <AudioPlayer
              controlsProps={controlsProps}
              progressBarProps={progressBarProps}
              onClose={onTogglePlayer}
              // @ts-ignore
              progressBarChildren={
                currentRecording.expiresAt && (
                  <Typography variant="body-small" color="assistive">
                    Audio expires in {formatDistanceToNow(parseISO(currentRecording.expiresAt!!))}
                  </Typography>
                )
              }
            />
          </x.div>
        )}
    </x.div>
  );
};

interface ScrollToTopProps {
  transcriptContentRef: RefObject<HTMLDivElement>;
  fixedTopRef: RefObject<HTMLDivElement>;
}

export const ScrollToTop = ({ transcriptContentRef, fixedTopRef }: ScrollToTopProps) => {
  const isVisible = useIsVisibleOnScreen(fixedTopRef);
  const styles = useStyles();

  const goToTop = useCallback(() => {
    transcriptContentRef.current?.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [transcriptContentRef]);

  return (
    <>
      {!isVisible && (
        <x.div {...styles.scrollToTop} data-testid="scroll-to-top">
          <IconButton onClick={goToTop} variant="outline" key="rounded-button">
            <ChevronUp />
          </IconButton>
        </x.div>
      )}
    </>
  );
};

export const useLoggableAudioPlayer = ({ audioPlayer, interaction, currentTranscript }: any) => {
  const { logHit } = useTrackUserAction();

  return {
    ...audioPlayer,
    controlsProps: {
      ...audioPlayer.controlsProps,
      onTogglePlay() {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptPlayer,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: {
            playing: !audioPlayer.controlsProps.playing,
            readalong: audioPlayer.transcriptProps.autoScroll,
            seconds: Math.floor(audioPlayer.progressBarProps.value),
            duration: Math.floor(audioPlayer.progressBarProps.duration),
            transcriptType: currentTranscript?.transcriptType,
          },
          references: { transcriptId: currentTranscript?.id },
        });

        audioPlayer.controlsProps.onTogglePlay();
      },
      onClickRefocus: () => {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptRefocus,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: {
            transcriptType: currentTranscript?.transcriptType,
          },
          references: { transcriptId: currentTranscript?.id },
        });

        audioPlayer.controlsProps.onClickRefocus();
      },
    },
  };
};

export { TranscriptMainContent };
