import React from "react";
import { x } from "@xstyled/styled-components";

const SensitiveDataContainer = ({
  children,
  isSensitive = false,
  style = {},
}: {
  children: JSX.Element;
  isSensitive?: boolean;
  style?: any;
}) => (
  //@ts-ignore
  <x.div className={isSensitive ? "obfuscated-sensitive-data" : ""} {...style}>
    {children}
  </x.div>
);

export default SensitiveDataContainer;
