import { AccordionItem, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x, th } from "@xstyled/styled-components";

export const useMobileFilterStyles = () => {
  const {
    color: { border, background },
    spacing: { inner, layout },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      background: background.surface.page.default,
      marginBottom: layout.base08,
    },
    hasAvailabilityWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: inner.base04,
      h: layout.base08,
      alignItems: "center",
    },
    filterButtonsPanel: {
      position: "fixed",
      bottom: "0",
      w: "100%",
      backgroundColor: background.surface.page.default,
      borderTopWidth: "1px",
      borderTopColor: border.divider,
    },
  };
};

export const MobileFilterFormButtons = styled(x.div)`
  display: flex;
  gap: ${th.space("inner-base03")};
  padding: ${th.space("inner-base04")};
  justify-content: space-between;

  & > button {
    flex-grow: 1;
  }
`;

export const MobileAccordionItem = styled(AccordionItem)`
  & > div:first-child {
    padding: ${th.space("inner-base04")};
    height: ${th.space("layout-base08")};
  }
`;
