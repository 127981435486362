import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import classNames from "classnames";
import {
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  format,
  getDay,
  getHours,
  getMinutes,
  isSameDay,
  isSameHour,
  isWeekend,
  isWithinInterval,
  parse,
  setHours,
  startOfDay,
  startOfHour,
  startOfWeek,
} from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { dateFnsLocalizer } from "react-big-calendar";
import { useUncontrolled } from "uncontrollable";
import { Calendar, locales } from "../../components/Calendar";
import { TimeSearchInputModalActivator } from "../../components/GlobalNav/TimezoneSelectModal";
import { useTimezone } from "../../providers/TimezoneProvider";
import { interactionMinDuration } from "./AvailabilityPopup";
import { CalendarContext } from "./CalendarContext";
import { OneDay, ThreeDays, Week } from "./CalendarCustomDaysView";
import { Event, eventProps, Wrapper } from "./CalendarEventWrappers";
import "./CalendarFull.css";
import { CustomToolbar } from "./CustomToolbar";
import dayAlgorithm from "./dayAlgorithm";
import { popupOpen, useCalendarReducer } from "./reducer";
import { useNewNavigation } from "@alphasights/client-portal-shared";

const scrollCalendarToTime = (hour) => {
  const minutes = getHours(hour) * 60 + getMinutes(hour);
  const minutesInADay = 24 * 60;

  const scrollArea = document.querySelector(".rbc-time-content");
  scrollArea.scrollTo({
    top: (minutes / minutesInADay) * scrollArea.scrollHeight,
    behavior: "smooth",
  });
};

const SUNDAY_WORKDAY_TZS = ["GMT+3", "GMT+4"];
const NON_SCHEDULABLE = [
  "Exclusive Direct Engagement",
  "No Interaction",
  "Work Product",
  "Direct Engagement",
  "Work Request",
];
/**
 * Adds some millis to the resulting hour to force react
 * to propagate the change and scroll calendar properly even
 * when setting scrollToTime to the same hour/value again.
 */
export const scrollStartingHour = (date) => {
  const currentUrl = window.location.href;

  if (currentUrl.includes("experts")) {
    const minHours = 2;
    const hour = getHours(date);
    const adjusted = startOfHour(setHours(date, Math.max(0, hour - minHours)));
    const randomMillis = Math.random() * 100;
    return new Date(adjusted.getTime() + randomMillis);
  } else if (currentUrl.includes("calendar-view")) {
    const adjusted = new Date(date);
    adjusted.setHours(7);
    adjusted.setMinutes(50);
    return adjusted;
  }

  return startOfHour(date);
};

export const scrollToTemporarySlot = () => {
  const temporarySlot = document.querySelector("#temporary-scheduled-slot");

  temporarySlot && temporarySlot.scrollIntoView();
};

export const Header = ({ date }) => {
  const newNavigationEnabled = useNewNavigation();
  const today = new Date();
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
  const {
    color: { text, background },
  } = useThemeTokens();

  const isTodayAndNewNav = isToday && newNavigationEnabled;

  const color = isTodayAndNewNav ? text.strong._ : isToday ? text.danger : text.secondary;
  const dayColor = isTodayAndNewNav ? text.inverse : text.secondary;
  const backgroundColor = isToday ? background.selected.strong.hover : "transparent";

  return newNavigationEnabled ? (
    <div className={"aui-flex aui-flex-row aui-my-4"}>
      <div>
        <Typography variant="body-small-em" color={color}>
          {format(date, "EEE")}
        </Typography>
      </div>
      <div style={{ marginLeft: "4px", backgroundColor: backgroundColor, padding: "0px 4px", borderRadius: "4px" }}>
        <Typography variant="body-small-em" color={dayColor}>
          {format(date, "d")}
        </Typography>
      </div>
    </div>
  ) : (
    <div className={"aui-flex aui-flex-col"}>
      <div>
        <Typography variant="pre-title" color={color}>
          {format(date, "EEE")}
        </Typography>
      </div>
      <div>
        <Typography variant="h2" color={color}>
          {format(date, "d")}
        </Typography>
      </div>
    </div>
  );
};

const views = {
  day: OneDay,
  week: Week,
  three_days: ThreeDays,
};

export const CalendarFull = ({
  className,
  toolbarChildren,
  toolbarShowViewOptions = true,
  toolbarShowViewAvailability = true,
  openEventPopupState,
  events,
  onRangeChange,
  dataTestId = "calendar",
  onTimezoneChanged,
  setShowClientAvailability,
  showClientAvailability,
  newFlyout = false,
  ...props
}) => {
  const tz = useTimezone();
  const { spacing } = useThemeTokens();
  const [selected, setSelected] = useState(null);
  const useSundayWorkday = SUNDAY_WORKDAY_TZS.includes(tz.format(new Date(), "O"));
  const newNavigationEnabled = useNewNavigation();

  // this cb lives inside a component created by big calendar, but
  // as props of this component never changes, it's never rerendered
  // as cb reference changes, so it always reference the first "version"
  // of the cb, usually taking to misbehaviours
  const onTimezoneChangedRef = useRef();
  onTimezoneChangedRef.current = onTimezoneChanged;

  const {
    showWeekend,
    setShowWeekend,
    sundayWorkday,
    setSundayWorkday,
    view,
    setView,
    scrollToTime,
    setScrollToTime,
    currentDate,
    setCurrentDate,
  } = useUncontrolled(
    {
      defaultShowWeekend: useSundayWorkday,
      defaultSundayWorkday: false,
      defaultView: "week",
      defaultScrollTime: null,
      defaultCurrentDate: new Date(),
      ...props,
    },
    {
      showWeekend: "setShowWeekend",
      sundayWorkday: "setSundayWorkday",
      view: "setView",
      scrollToTime: "setScrollToTime",
      currentDate: "setCurrentDate",
    }
  );

  const dateList = useMemo(() => views[view].range(currentDate, showWeekend, sundayWorkday), [
    currentDate,
    showWeekend,
    sundayWorkday,
    view,
  ]);

  useEffect(() => {
    const start = startOfDay(dateList[0]);
    const end = endOfDay(dateList[dateList.length - 1]);

    onRangeChange && onRangeChange({ start, end });
  }, [dateList]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentUser = useCurrentUser();
  const [state, dispatch] = useCalendarReducer({
    currentUser,
  });

  useEffect(() => {
    const preselected = events.find((e) => e.preselected);
    if (!preselected !== !selected) {
      setSelected(preselected);
    }
  }, [events, selected]);

  useEffect(() => {
    if (selected) {
      const time = scrollStartingHour(tz.parseDateZoned(selected.start));
      const isClose = Math.abs(differenceInMinutes(time, scrollToTime)) < 10;
      const isPendingCliAvailability = selected.source === "clientAvailability" && selected.subtype === "pending";
      !isPendingCliAvailability && !isClose && setScrollToTime(time);

      const visibleDays = views[view].range(currentDate, showWeekend, sundayWorkday);
      const isVisible = isWithinInterval(time, {
        start: visibleDays[0],
        end: endOfDay(visibleDays.slice(-1)[0]),
      });
      !isVisible && !isClose && setCurrentDate(time);
    }
  }, [currentDate, scrollToTime, selected, setCurrentDate, setScrollToTime, showWeekend, sundayWorkday, tz, view]);

  useEffect(() => {
    if (!openEventPopupState) return;

    const [openEventPopup, setOpenEventPopup] = openEventPopupState;

    if (openEventPopup) {
      const { interactionId, date, selectedStart } = openEventPopup;

      const sameDayEvents = events
        .filter(
          (event) =>
            event.interaction?.id === interactionId &&
            isSameDay(event.start, date) &&
            differenceInSeconds(event.end, event.start) >= interactionMinDuration(event.interaction)
        )
        .sort((a, b) => a.start - b.start);
      const event =
        sameDayEvents.find((event) => {
          return isSameHour(event.start, selectedStart || date);
        }) || sameDayEvents[0];

      if (event) {
        const angle = event.interaction.angles[0];
        dispatch(
          popupOpen({
            interactionId: event.interaction.id,
            token: event.interaction.projectToken,
            source: event.source,
            selectedStart: selectedStart || event.start,
            duration: event.interaction.expectedDuration || 3600,
            angleId: angle?.id,
          })
        );
        const targetTime = scrollStartingHour(event.start);
        if (isWeekend(targetTime)) setShowWeekend(true);
        setScrollToTime(targetTime);
        setOpenEventPopup(null);
      }
    }
  }, [openEventPopupState, events, dispatch, setScrollToTime, scrollToTime, selected, setShowWeekend]);

  useEffect(() => {
    scrollCalendarToTime(tz.parseDateZoned(scrollToTime));
  }, [scrollToTime, tz]);

  const onNavigate = (action) => {
    setCurrentDate(
      views[view].navigate(currentDate, action, {
        sundayWorkday,
        showWeekend,
      })
    );
  };

  const ghost = {
    id: "ghost",
    start: startOfDay(currentDate),
    end: startOfDay(currentDate),
    source: "clientAvailability",
    emit: () => {},
  };

  const filteredEvents = [
    ...events.filter(({ source, subtype, interaction, preselected }) => {
      const isUnschedulable = interaction && NON_SCHEDULABLE.includes(interaction.interactionType);
      if (isUnschedulable) return false;
      const clientAvailabilityCheck =
        showClientAvailability || source !== "clientAvailability" || subtype === "pending" || preselected;
      return clientAvailabilityCheck;
    }),
    ghost,
  ];

  const styles = newFlyout
    ? {
        paddingTop: spacing.layout.base03,
      }
    : {};

  const {
    color: { text },
  } = useThemeTokens();

  return (
    <CalendarContext.Provider value={{ state, dispatch }}>
      <div className="aui-flex aui-flex-col aui-w-full aui-h-full" data-testid={dataTestId} style={styles}>
        <CustomToolbar
          setShowWeekend={setShowWeekend}
          showWeekend={showWeekend}
          setSundayWorkday={setSundayWorkday}
          sundayWorkday={sundayWorkday}
          setShowClientAvailability={setShowClientAvailability}
          showClientAvailability={showClientAvailability}
          toolbarChildren={toolbarChildren}
          toolbarShowViewOptions={toolbarShowViewOptions}
          toolbarShowViewAvailability={toolbarShowViewAvailability}
          date={currentDate}
          dateList={dateList}
          onNavigate={onNavigate}
          onView={setView}
          view={view}
          isFlyout={newFlyout}
        />
        <Calendar
          formats={{
            timeGutterFormat: newNavigationEnabled
              ? (date, culture, localizer) => localizer.format(date, "ha", culture)
              : "h a",
            eventTimeRangeFormat: () => "",
            eventTimeRangeStartFormat: () => "",
            eventTimeRangeEndFormat: () => "",
          }}
          views={views}
          draggableAccessor={() => false}
          components={{
            event: Event,
            eventWrapper: Wrapper,
            header: Header,
            timeSlotWrapper: ({ value, children }) => {
              return React.Children.map(children, (child) =>
                React.cloneElement(child, { "data-date": value.getTime() })
              );
            },
            timeGutterHeader: (props) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    fontSize: "14px",
                    color: text.assistive,
                  }}
                >
                  <TimeSearchInputModalActivator
                    format="O"
                    onTimezoneChanged={(tz) => onTimezoneChangedRef.current && onTimezoneChangedRef.current(tz)}
                    data-testid="calendar-tz-selector"
                  />
                </div>
              );
            },
          }}
          toolbar={false}
          date={currentDate}
          view={view}
          showWeekend={showWeekend}
          setShowWeekend={setShowWeekend}
          onNavigate={setCurrentDate}
          getNow={() => tz.parseDateZoned(new Date())}
          onView={(view) => setView(view)}
          className={classNames("calendar-full calendar-full-panel", className)}
          eventPropGetter={eventProps}
          selected={null}
          onSelectEvent={() => null}
          showMultiDayTimes={true}
          sundayWorkday={sundayWorkday}
          setSundayWorkday={setSundayWorkday}
          startAccessor={(event) => tz.parseDateZoned(event.start)}
          endAccessor={(event) => tz.parseDateZoned(event.end)}
          localizer={dateFnsLocalizer({
            format,
            parse,
            startOfWeek,
            getDay,
            locales,
          })}
          toolbarShowViewOptions={toolbarShowViewOptions}
          toolbarChildren={toolbarChildren} // is this needed?
          events={filteredEvents}
          scrollToTime={scrollToTime}
          style={{ flex: "1", height: "initial", overflow: "auto" }}
          step={15}
          timeslots={4}
          dayLayoutAlgorithm={dayAlgorithm}
          enableAutoScroll={true}
          {...props}
        />
      </div>
    </CalendarContext.Provider>
  );
};
