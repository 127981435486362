import React from "react";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { StatusError, StatusPending, TickOutline } from "@alphasights/alphadesign-icons";

export const useMobileExpertCardStyles = ({ hidden }: { hidden: Boolean } = { hidden: false }) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return {
    expertWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: inner.base04,
      gap: inner.base03,
      borderColor: color.border.neutral.default,
      borderBottomWidth: "1px",
      bg: color.background.surface.page.default,
      ...(hidden ? { color: color.text.disabled } : {}),
    },
    badgesWrapper: {
      display: "flex",
      gap: inner.base02,
    },
    companyAndRole: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    role: {
      ...(hidden ? { color: color.text.disabled } : {}),
    },
    roleAndHide: {
      display: "flex",
      justifyContent: "space-between",
    },
    pillText: {
      ...(hidden ? { color: color.text.disabled } : {}),
    },
    iconOnly: {
      padding: `${inner.base} ${inner.base02}`,
      borderRadius: inner.base02,
      bg: color.background.neutral.default,
    },
    cid: {
      cleared: {
        bgColor: color.background.success + " !important",
        icon: (
          <Icon size="small" color="success">
            <TickOutline />
          </Icon>
        ),
      },
      blocked: {
        bgColor: color.background.danger.default + " !important",
        icon: (
          <Icon size="small" color="danger">
            <StatusError />
          </Icon>
        ),
      },
      pending: {
        icon: (
          <Icon size="small" color="secondary">
            <StatusPending />
          </Icon>
        ),
      },
    },
  };
};
