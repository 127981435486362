import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@alphasights/alphadesign-components";
import { PrimerTypes } from "models/primers/constants";

interface CustomToggleButtonGroupProps {
  value: string;
  onChange: (value: string) => void;
}

const PrimerTypeButtonGroup: React.FC<CustomToggleButtonGroupProps> = ({ value, onChange }) => (
  <ToggleButtonGroup allowUnselection={false} value={value} onChange={(newValue: any) => onChange(newValue)}>
    <ToggleButton sizeVariant="small" value={PrimerTypes.ALPHA_NOW}>
      AlphaNow
    </ToggleButton>
    <ToggleButton sizeVariant="small" value={PrimerTypes.PRIVATE}>
      Private
    </ToggleButton>
  </ToggleButtonGroup>
);

export default PrimerTypeButtonGroup;
