export const SEGMENTATION_LEADERS_INFO_PROPERTIES: Record<
  string,
  {
    value: string;
    label: string;
  }
> = {
  revenue: {
    value: "revenue",
    label: "Revenue",
  },
  trend: {
    value: "trend",
    label: "Trend",
  },
  overview: {
    value: "overview",
    label: "sub-segment overview",
  },
  dynamics: {
    value: "dynamics",
    label: "sub-segment dynamics",
  },
  outlook: {
    value: "outlook",
    label: "sub-segment outlook",
  },
  notableCompetitors: {
    value: "notableCompetitors",
    label: "notable competitors",
  },
};

export enum NotableCompetitorsLabels {
  RANK = "rank",
  COMPANY_NAME = "company name",
  COMPETITOR_TYPE = "competitor type",
  TREND = "trend",
}
