import React, { FC } from "react";
import { useThemeTokens, Button, Status, Skeleton } from "@alphasights/alphadesign-components";
import { TickOutline } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { DISABLED_BUTTON_STYLES, MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { withAccessControl } from "components/AccessControl/AccessControl";

const ButtonWithAccess = withAccessControl(Button);

export type RequestSpeakExpertButtonProps = {
  selectedExpertId: number;
  allExpertsRequest: ClientExpertInquiryDto[];
  isRequestExpertLoading: boolean;
  expertRequestExists: boolean;
  handleOnSpeakToExpertClick: (e: React.MouseEvent<HTMLButtonElement>) => void;

  disabled?: boolean;
};

const RequestSpeakExpertButton: FC<RequestSpeakExpertButtonProps> = ({
  selectedExpertId,
  allExpertsRequest,
  isRequestExpertLoading,
  expertRequestExists,
  handleOnSpeakToExpertClick,
  disabled = false,
}) => {
  const {
    color: {
      background: {
        action: { secondary },
        success,
      },
    },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isRequestExpertLoading) {
    return (
      <x.div w="70%" data-testid="request-expert-loading-section">
        <Skeleton />
      </x.div>
    );
  }

  const allExpertsRequestsIds = allExpertsRequest.map((expert) => expert.speakerIds).flat();

  const isSelectedExpertRequired = expertRequestExists ? allExpertsRequestsIds.includes(selectedExpertId) : false;

  if (isSelectedExpertRequired) {
    return (
      <Status
        size="small"
        text="Requested"
        textColor={secondary.hover}
        color={success}
        iconColor={secondary.hover}
        leftIcon={<TickOutline />}
      />
    );
  }

  return (
    <ButtonWithAccess
      accessControl={{ allowedPermissions: [MANAGE_CONTENT_PERMISSION] }}
      variant="secondary"
      size={isMobile ? "medium" : "small"}
      onClick={handleOnSpeakToExpertClick}
      data-testid="request-expert-button-section"
      disabled={disabled}
      {...(disabled ? DISABLED_BUTTON_STYLES : {})}
    >
      Request Expert
    </ButtonWithAccess>
  );
};

export default RequestSpeakExpertButton;
