import { messageThreadService } from "services/messageThread";
import useSWR from "swr";
import { MessageThreadHeaderResponse } from "types";

export type ThreadHeadersRequest = {
  projectToken: string;
  shouldFetch: boolean;
  advisorId: string;
};

export const useThreadHeaders = ({ projectToken, shouldFetch = true, advisorId }: ThreadHeadersRequest) => {
  const customFetcher = (advisorId: string): Promise<MessageThreadHeaderResponse[]> =>
    messageThreadService.fetchThreadHeaders(projectToken, advisorId);

  const { data, isLoading, isValidating, mutate, error } = useSWR<MessageThreadHeaderResponse[]>(
    shouldFetch ? advisorId : null,
    customFetcher,
    {
      refreshInterval: (data) => {
        if (data && data.length > 0 && data[0].state.code === "CREATING") {
          return 10000;
        }
        return 0;
      },
      revalidateOnFocus: false,
    }
  );

  return {
    threadHeaders: data?.sort((a, b) => b.createdAt.localeCompare(a.createdAt)) ?? [],
    isThreadHeadersLoading: isLoading || isValidating,
    threadHeadersMutate: mutate,
    threadHeadersError: error,
  };
};
