import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTConversationStyles = () => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const messagesContainer = {
    background: background.surface.page.default,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minH: 0,
    alignItems: "center",
    overflow: "auto",
    position: "relative",
  };

  const errorWrapper = {
    pb: inner.base06,
  };

  const selectedLibrary = {
    position: "absolute",
    top: inner.base06,
    right: inner.base08,
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: inner.base,
  };

  return {
    wrapper,
    messagesContainer,
    errorWrapper,
    selectedLibrary,
  };
};
