import _, { sortBy } from "lodash";
import { RowState } from "@alphasights/alphadesign-table";

export const columnPreferenceExists = (columnPreference) => {
  return columnPreference && columnPreference.id && Object.keys(columnPreference.attributes).length > 0;
};

export const hasOrderChanged = (updatedColumnOrders, columnPreference) => {
  return columnPreference?.id && JSON.stringify(columnOrder(columnPreference)) !== JSON.stringify(updatedColumnOrders);
};

export const hasVisibilityChanged = (updatedColumnVisibilities, columnPreference, columns) => {
  return (
    columnPreference?.id &&
    updatedColumnVisibilities &&
    Object.keys(updatedColumnVisibilities).length > 0 &&
    !_.isEqual(columnVisibility(columns, columnPreference), updatedColumnVisibilities)
  );
};

export const hasPinningChange = (updatedColumnPinning, columnPreference) => {
  return (
    columnPreference?.id &&
    Object.keys(updatedColumnPinning?.left).length > 0 &&
    !_.isEqual(["_selection", ...pinnedColumns(columnPreference)], updatedColumnPinning.left)
  );
};

export const columnIds = (columns) => {
  return columns.map((col) => col.id);
};

const pinnedColumns = (columnPreference) => {
  return columnPreference
    ? Object.keys(columnPreference.attributes).filter((k) => columnPreference.attributes[k].pinned)
    : [];
};

const columnOrder = (columnPreference) => {
  return columnPreference
    ? sortBy(Object.keys(columnPreference.attributes), (k) => columnPreference.attributes[k].position)
    : [];
};

const columnVisibility = (columns, columnPreference) => {
  const hasColumnPreferences = !!columnPreference;
  const columnIdsInPreferences = hasColumnPreferences ? Object.keys(columnPreference.attributes) : [];
  return columns
    .filter(
      (column) =>
        (columnIdsInPreferences.includes(column.id) && columnPreference.attributes[column.id].hide) ||
        (column.hideIf && column.hideIf({ hasColumnPreferences }))
    )
    .reduce((acc, column) => ({ ...acc, [column.id]: false }), {});
};

const newColumns = (columns, columnPreference) => {
  return columnIds(columns).filter((column) => !columnOrder(columnPreference).includes(column));
};

const rowState = (row) => {
  if (row.original.newlyAdded) return RowState.HIGHLIGHTED;
  return RowState.NONE;
};

export const buildColumnsOptions = ({
  columnPreference,
  columns,
  hideSelectColumn,
  onRowClicked,
  rowClassRules,
  hideHeader,
  enableRowSelection,
  onRowSelectionChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onColumnPinningChange,
}) => {
  return {
    meta: {
      hideSelectColumn,
      onRowClicked,
      rowClassRules,
      hideHeader,
      enableRowState: true,
      fullWidth: true,
      rowState,
    },
    state: {
      columnPinning: {
        left: [...new Set(["actionColumn", ...pinnedColumns(columnPreference)])],
        right: ["showProfile"],
      },
      columnOrder: buildColumnOrder(columns, columnPreference),
      columnVisibility: columnVisibility(columns, columnPreference),
      sorting: [],
    },
    enableRowSelection,
    onRowSelectionChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    onColumnPinningChange,
  };
};

const buildColumnOrder = (columns, columnPreference) => {
  // new columns should take some precedence to show up new features
  const order = [...newColumns(columns, columnPreference), ...columnOrder(columnPreference)];

  // always show messageStatusColumn after statusColumn
  if (order.includes("messageStatusColumn") && order.includes("statusColumn")) {
    order.splice(order.indexOf("messageStatusColumn"), 1);
    order.splice(order.indexOf("statusColumn") + 1, 0, "messageStatusColumn");
  }

  return order;
};
