import { isEqual } from "lodash";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { BooleanTypes, Operator, Symbol } from "components/Search";
import { isOperand, infixToPostfix, BooleanExpressionError } from "components/Search/utils";
import { SearchOption } from "components/Search";

interface QueryItem extends SearchOption {
  type: number;
  id?: number;
}

type SearchCompany = {
  id: number;
  name: string;
};

type BooleanGroupType = {
  company?: SearchCompany | null;
  keyword?: string | null;
  market_id?: number | null;
  colleague_id?: number | null;
  and?: BooleanGroup[];
  or?: BooleanGroup[];
  not?: BooleanGroup[];
};

enum QueryItemType {
  Company = "company",
  Keyword = "keyword",
  Market = "market_id",
  Colleague = "colleague_id",
}

class BooleanGroup {
  company: SearchCompany | null = null;
  keyword: string | null = null;
  market_id: number | null = null;
  colleague_id: number | null = null;
  and: BooleanGroup[] = [];
  or: BooleanGroup[] = [];
  not: BooleanGroup[] = [];

  constructor({
    company = null,
    keyword = null,
    market_id = null,
    colleague_id = null,
    and = [],
    or = [],
    not = [],
  }: BooleanGroupType) {
    this.company = company;
    this.keyword = keyword;
    this.market_id = market_id;
    this.colleague_id = colleague_id;
    this.and = and;
    this.or = or;
    this.not = not;
  }
}

const getTermType = (term: QueryItem) => {
  switch (term.type) {
    case SEARCH_SUGGESTION_TYPES.Company:
    case SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch:
      return QueryItemType.Company;
    case SEARCH_SUGGESTION_TYPES.Market:
      return QueryItemType.Market;
    case SEARCH_SUGGESTION_TYPES.Colleague:
      return QueryItemType.Colleague;
    default:
      return QueryItemType.Keyword;
  }
};

const getOrCreateGroup = (
  operand: string | BooleanGroup | undefined,
  termMap: Record<number, string | QueryItem>
): BooleanGroup | undefined => {
  let newGroup;
  if (!operand) return undefined;
  if (operand instanceof BooleanGroup) {
    newGroup = operand;
  } else {
    const term = termMap[Number(operand)] as QueryItem;
    if (!term) {
      throw new Error(`Invalid operand ${operand}`);
    }
    const key = getTermType(term as QueryItem);
    let value;
    switch (key) {
      case QueryItemType.Company:
        value = { id: term.id, name: term.value };
        break;
      case QueryItemType.Colleague:
        value = term.id;
        break;
      case QueryItemType.Market:
        value = term.id;
        break;
      default:
        value = term.value;
    }
    newGroup = new BooleanGroup({ [key]: value });
  }
  return newGroup;
};

// Function to build expression tree from postfix expression array
const buildExpressionTree = (exp: string[], termMap: Record<number, string | QueryItem>) => {
  const postfixExp = infixToPostfix(exp);

  if (isEqual(postfixExp, exp)) {
    throw new BooleanExpressionError("Search terms chained incorrectly");
  }
  const nodesStack: (string | BooleanGroup)[] = [];
  postfixExp.forEach((item) => {
    if (isOperand(item)) {
      nodesStack.push(item);
    } else {
      let newGroup;
      if (item === Operator.NOT) {
        const operand = nodesStack.pop();
        const group = getOrCreateGroup(operand, termMap);

        // validation
        if (!group) {
          throw new BooleanExpressionError("NOT operator used incorrectly");
        }

        newGroup = new BooleanGroup({
          [item.toLowerCase()]: [group],
        });
      } else {
        let rightGroup, leftGroup;
        const rightOperand = nodesStack.pop();
        const leftOperand = nodesStack.pop();

        if (rightOperand) {
          rightGroup = getOrCreateGroup(rightOperand, termMap);
        }
        if (leftOperand) {
          leftGroup = getOrCreateGroup(leftOperand, termMap);
        }

        // validation
        if (!(rightGroup && leftGroup)) {
          throw new BooleanExpressionError(`${item} operator used incorrectly`);
        }

        newGroup = new BooleanGroup({
          [item.toLowerCase()]: [leftGroup, rightGroup],
        });
      }
      nodesStack.push(newGroup);
    }
  });
  if (nodesStack.length > 1) {
    throw new BooleanExpressionError("Search terms chained incorrectly");
  }
  return nodesStack.pop();
};

//Function to processes search query and return it in a format readable by the BE
const processSearchQuery = (query: QueryItem[]) => {
  const termMap: QueryItem[] = [];
  const processedQuery: string[] = [];

  query.forEach((item, index) => {
    switch (String(item?.type)) {
      case BooleanTypes.AND:
        processedQuery.push(Operator.AND);
        break;
      case BooleanTypes.NOT:
        const lastItem = processedQuery[processedQuery.length - 1];
        // if NOT is used on its own, add an AND operator before it
        if (index !== 0 && ![Symbol.LEFT_BRACKET, Operator.AND, Operator.OR].includes(lastItem as string)) {
          processedQuery.push(Operator.AND);
        }
        processedQuery.push(Operator.NOT);
        break;
      case BooleanTypes.OR:
        processedQuery.push(Operator.OR);
        break;
      case BooleanTypes.LEFT_BRACKET:
        processedQuery.push(Symbol.LEFT_BRACKET);
        break;
      case BooleanTypes.RIGHT_BRACKET:
        processedQuery.push(Symbol.RIGHT_BRACKET);
        break;
      default:
        termMap.push(item);
        processedQuery.push((termMap.length - 1).toString());
    }
  });
  return buildExpressionTree(processedQuery, termMap);
};

export { getTermType, getOrCreateGroup, buildExpressionTree, processSearchQuery, BooleanGroup };
export type { QueryItem, SearchCompany, BooleanGroupType };
