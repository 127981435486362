import { CallAvailabilityStatus } from "components/CallMePanel/constants";

export type CallAvailability = {
  fetch_error?: string;
  status: string;
  clientAccessCodeFormatted?: string;
  availability: CallAvailabilityStatus;
};

export type UseCallAvailabilityResult = {
  callAvailability?: CallAvailability;
};

export enum PreferenceType {
  TABLE_VIEW_COLUMNS = "TABLE_VIEW_COLUMNS",
  SURVEY_TABLE_COLUMNS = "SURVEY_TABLE_COLUMNS",
  DEFAULT_ADVISOR_VIEW = "DEFAULT_ADVISOR_VIEW",
  CALENDAR = "CALENDAR",
  TIMEZONE = "TIMEZONE",
  TWILIO = "TWILIO",
  PROJECT_SYNTHESIS = "PROJECT_SYNTHESIS",
}

export type Preference = {
  id?: number;
  type: string;
  scope: string;
  projectToken?: string;
  attributes: { [key: string]: any };
};
