import { Action } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/useCompanyPageTracking";

const SETTLED_STATUS = "settled";
const LOADING_STATUS = "loading";
const disabledPrimerCardTitle = "Available to Commission";

enum FetchStatus {
  FetchOverviewStatus = "FETCH_OVERVIEW_STATUS",
  FetchPrimersStatus = "FETCH_PRIMERS_STATUS",
  FetchInsightsStatus = "FETCH_INSIGHTS_STATUS",
  FetchCompetitorsStatus = "FETCH_COMPETITORS_STATUS",
  FetchCustomersStatus = "FETCH_CUSTOMERS_STATUS",
  FetchExpertsStatus = "FETCH_EXPERTS_STATUS",
}

const initialFetchStatuses = {
  [FetchStatus.FetchOverviewStatus]: LOADING_STATUS,
  [FetchStatus.FetchPrimersStatus]: LOADING_STATUS,
  [FetchStatus.FetchInsightsStatus]: LOADING_STATUS,
  [FetchStatus.FetchCompetitorsStatus]: LOADING_STATUS,
  [FetchStatus.FetchCustomersStatus]: LOADING_STATUS,
  [FetchStatus.FetchExpertsStatus]: LOADING_STATUS,
};

const DataFetchAction = {
  overview: {
    trackingAction: Action.FetchOverview,
    status: FetchStatus.FetchOverviewStatus,
  },
  primers: {
    trackingAction: Action.FetchPrimers,
    status: FetchStatus.FetchPrimersStatus,
  },
  insights: {
    trackingAction: Action.FetchInsights,
    status: FetchStatus.FetchInsightsStatus,
  },
  competitors: {
    trackingAction: Action.FetchCompetitors,
    status: FetchStatus.FetchCompetitorsStatus,
  },
  customers: {
    trackingAction: Action.FetchCustomers,
    status: FetchStatus.FetchCustomersStatus,
  },
  experts: {
    status: FetchStatus.FetchExpertsStatus,
  },
};

export { SETTLED_STATUS, LOADING_STATUS, DataFetchAction, FetchStatus, initialFetchStatuses, disabledPrimerCardTitle };
