import React, { useEffect, useState } from "react";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { x } from "@xstyled/styled-components";
import { addHours, isAfter, parseISO } from "date-fns";
import { sortBy } from "lodash";
import { UpcomingCallCard, UpcomingCallsLoading } from "./UpcomingCallCard";
import { Divider, Loading } from "@alphasights/alphadesign-components";
import { useStyles } from "./UpcomingCallsPage.styles";
import { NoUpcomingCalls } from "./NoUpcomingCalls";
import { UpcomingCallsFlyout } from "./UpcomingCallsFlyout";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { apiClient, HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { usePromise } from "@alphasights/ads-community-hooks";
import { UpcomingInteraction } from "@alphasights/portal-api-client";
import { useProjectInteractionCache } from "./useProjectInteractionCache";

export const UpcomingCallsPage = () => {
  const styles = useStyles();
  const { data, isPending } = usePromise({
    fn: () =>
      (apiClient.get(`/api/auth-user/upcoming-calls`) as Promise<UpcomingInteraction[]>)
        .then((upcoming) => upcoming.filter((u) => isAfter(parseISO(u.scheduledCallTime), addHours(new Date(), -24))))
        .then((upcoming) => sortBy(upcoming, (u) => parseISO(u.scheduledCallTime).valueOf())),
  });
  const { logHit } = useTrackUserAction();
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const { data: flyoutData, fetchProjectInteraction, isLoading: isLoadingFlyout } = useProjectInteractionCache();

  useEffect(() => {
    logHit({
      origin: HitOrigin.upcomingCalls,
      action: HitAction.viewUpcomingCallsPage,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div data-testid="mobile-upcoming-calls-page" {...styles.wrapper}>
      {(!isFlyoutOpen || !flyoutData) && <PortalMobileTopBar title="Upcoming Calls" />}
      {!isFlyoutOpen && !isLoadingFlyout && (
        <UpcomingCallsContent
          isLoading={isPending}
          interactions={data || []}
          onOpenFlyout={(interaction: UpcomingInteraction) => {
            setIsFlyoutOpen(true);
            fetchProjectInteraction({ interactionId: interaction.id, projectToken: interaction.projectToken });
          }}
        />
      )}
      {isFlyoutOpen && isLoadingFlyout && (
        <x.div display="flex" alignItems="center" justifyContent="center" flex={1}>
          <Loading />
        </x.div>
      )}
      {isFlyoutOpen && flyoutData && <UpcomingCallsFlyout data={flyoutData} onClose={() => setIsFlyoutOpen(false)} />}
    </x.div>
  );
};

type UpcomingCallsContentProps = {
  isLoading: boolean;
  interactions: UpcomingInteraction[];
  onOpenFlyout: (i: UpcomingInteraction) => void;
};

const UpcomingCallsContent = ({ isLoading, interactions, onOpenFlyout }: UpcomingCallsContentProps) => {
  const styles = useStyles();
  return (
    <>
      {!isLoading && interactions.length === 0 && <NoUpcomingCalls />}
      <x.div {...styles.contentWrapper}>
        {isLoading && (
          <>
            <UpcomingCallsLoading withButtons />
            <Divider />
            <UpcomingCallsLoading />
            <Divider />
            <UpcomingCallsLoading />
            <Divider />
          </>
        )}
        {!isLoading &&
          interactions.map((upcomingInteraction: UpcomingInteraction) => {
            return (
              <React.Fragment key={upcomingInteraction.id}>
                <UpcomingCallCard upcomingInteraction={upcomingInteraction} onOpenFlyout={onOpenFlyout} />
                <Divider />
              </React.Fragment>
            );
          })}
      </x.div>
    </>
  );
};
