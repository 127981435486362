import React from "react";
import { Checkbox } from "@alphasights/alphadesign-components";
import styles from "./styles/commissionModalRush.module.css";

const CommissionModalRush = ({
  rush,
  onChange,
  disabled = false,
  dataTestId = "commission-modal-rush",
}: {
  rush: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  dataTestId?: string;
}) => {
  return (
    <div className={styles.rush}>
      <Checkbox
        data-testid={dataTestId}
        checked={rush}
        size="small"
        onChange={() => onChange(!rush)}
        disabled={disabled}
        marginBottom="0px"
      >
        Rush
      </Checkbox>
    </div>
  );
};

export default CommissionModalRush;
