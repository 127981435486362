import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

import { FilterViewWrapper } from "../AlphaNowFilters.styled";

const CompanyDataFiltersNavigationRowWrapper = styled.div(
  ({ isCompanyDataFiltersView }: { isCompanyDataFiltersView: boolean }) => {
    const { color, spacing } = useThemeTokens();
    return {
      display: "flex",
      flexDirection: isCompanyDataFiltersView ? ("row-reverse" as "row-reverse") : ("row" as "row"),
      justifyContent: isCompanyDataFiltersView ? "left" : "space-between",
      alignItems: "center",
      color: color.text.secondary,
      gap: isCompanyDataFiltersView ? spacing.inner.base02 : 0,
      "&:hover": {
        cursor: "pointer",
      },
    };
  }
);

const StyledNavIcon = styled(Icon).attrs({ size: "small" })(() => {
  const { color } = useThemeTokens();
  return {
    "&:hover": {
      backgroundColor: `${color.background.surface.page.default} !important`,
    },
  };
});

const FilterTitle = styled(Typography).attrs({ variant: "body-small-em", component: "span" })(() => {
  const { color, spacing } = useThemeTokens();
  return {
    color: color.text.secondary,
    padding: `${spacing.inner.base04} 0`,
  };
});

export { CompanyDataFiltersNavigationRowWrapper, StyledNavIcon, FilterTitle, FilterViewWrapper };
