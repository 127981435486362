import { useTheme } from "@alphasights/alphadesign-components";

const useInteractionDeliverablesStyles = ({ isActive }: { isActive: boolean }) => {
  const {
    tokens: { components },
  } = useTheme();

  return {
    sound: {
      ...(isActive
        ? {
            color: components.icon.active.color,
            bg: components.icon.active.background,
          }
        : {
            bg: {
              _: components.icon.base.background._,
              hover: components.icon.base.background.hover,
            },
          }),
    },
  };
};

export { useInteractionDeliverablesStyles };
