import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const BulletPoints = ({ textColor, items, small = false }: { textColor?: string; items: string; small?: boolean }) => {
  const newItems = items.split("- ");
  const {
    spacing: { inner },
    color: {
      background: {
        action: { secondary },
      },
    },
  } = useThemeTokens();

  return (
    <x.ul
      listStyleType="disc"
      marginLeft={inner.base05}
      color={textColor || secondary.hover}
      data-testid="relevant-experience-bullet-list"
    >
      {newItems.map((item) => {
        if (!Boolean(item)) return null;

        return (
          <x.li key={item}>
            <Typography color={textColor || secondary.hover} variant={small ? "body-small" : "body"}>
              {item}
            </Typography>
          </x.li>
        );
      })}
    </x.ul>
  );
};

export default BulletPoints;
