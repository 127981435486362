import { ClientContactMetadata } from "../models/ClientContactMetadata";
import { fetch } from "../hooks/useApi";

export const authUserService = {
  async getIntercomHash(): Promise<String> {
    return fetch({
      url: "/api/auth-user/intercom-hash",
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.text());
  },

  async getAuthUserDetails(): Promise<ClientContactMetadata> {
    return fetch({
      url: "/api/auth-user/details",
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};
