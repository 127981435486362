import { Button, ContentCard, Icon, Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Angle, Expert, MoreVert } from "@alphasights/alphadesign-icons";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import classNames from "classnames";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import useOnClickOutside from "hooks/useOnClickHooks";
import { compact } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { default as Content } from "react-content-loader";
import { getStateColor, getStateName } from "../../components/AdvisorCard/stateFunctions";
import { DropdownMenu } from "../../components/alphaui";
import { SecondaryButtonSmall } from "../../components/Button";
import { groupedRepresentationForExperts } from "../../components/InteractionsPage/helpers/Interaction";
import { CancelRequestPopup } from "./RequestModal";

export const InteractionsList = ({
  experts,
  className,
  onCancelRequest,
  onRequestCancel,
  onRequestReschedule,
  onRequestFollowUp,
  onSchedule,
  loading,
  selectedInteractionIds,
  onToggleInteractionId,
  onRequestMoreAvailability,
  onSelectCard,
  clientRequests,
  selectedTab,
  appliedFilters = {},
  onResetAllFilters,
  footer,
  allowMenuActions = true,
}) => {
  const [cancellingId, setCancellingId] = useState(null);
  const hasFiltersApplied = Object.keys(appliedFilters).find(
    (key) => appliedFilters[key] && appliedFilters[key].length > 0
  );

  const cards = groupedRepresentationForExperts(experts);

  return (
    <>
      {loading && (
        <div className="aui-bg-transparent aui-flex aui-flex-col aui-items-center aui-justify-around">
          <CardLoader />
          <CardLoader />
          <CardLoader />
        </div>
      )}
      {!loading && (
        <div className={classNames("aui-text-dark-1 aui-flex aui-flex-col aui-flex-grow", className)}>
          {cards.length > 0 && (
            <div className="aui-flex aui-flex-col aui-flex-grow aui-space-y-4">
              {cards.map((card) => (
                <InteractionCard
                  key={`card-${card.id}`}
                  interaction={card}
                  onCancelRequest={onCancelRequest}
                  onRequestReschedule={onRequestReschedule}
                  onRequestCancel={() => setCancellingId(card.id)}
                  selectedInteractionIds={selectedInteractionIds}
                  onToggleInteractionId={onToggleInteractionId}
                  onRequestFollowUp={onRequestFollowUp}
                  onSchedule={onSchedule}
                  onRequestMoreAvailability={onRequestMoreAvailability}
                  onSelectCard={onSelectCard}
                  othersCounts={card.othersCounts}
                  pendingRequest={clientRequests && clientRequests.find((req) => req.interactionId === card.id)}
                  allowMenuActions={allowMenuActions}
                />
              ))}
            </div>
          )}
          {cards.length === 0 && (
            <div
              data-testid="calendar-empty-list"
              className="aui-font-semibold aui-text-xl aui-text-grey-4 aui-text-center aui-my-5 aui-flex-col"
            >
              <div>{emptyMessages[selectedTab]}</div>
              {hasFiltersApplied && (
                <SecondaryButtonSmall data-testid="clear-filters" className="aui-my-4" onClick={onResetAllFilters}>
                  Clear Filters
                </SecondaryButtonSmall>
              )}
            </div>
          )}
          {footer}
        </div>
      )}
      {cancellingId && (
        <CancelRequestPopup
          id={cancellingId}
          onClose={() => setCancellingId(null)}
          onSubmit={onRequestCancel}
          origin={HitOrigin.calendarView}
        />
      )}
    </>
  );
};

const InteractionCard = ({
  interaction,
  onCancelRequest,
  onRequestCancel,
  onRequestReschedule,
  selectedInteractionIds,
  onRequestFollowUp,
  onToggleInteractionId,
  onRequestMoreAvailability,
  pendingRequest,
  onSelectCard,
  allowMenuActions = true,
}) => {
  const {
    color: { text },
  } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();
  const [hoveringCard, setHoveringCard] = useState(false);
  const ref = useRef();
  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useOnClickOutside(
    {
      current: ref.current && ref.current.querySelector(".aui-dropdown-toggle"),
    },
    (e) => {
      if (e.composedPath()?.indexOf(ref.current) === -1) {
        setHoveringCard(false);
      }
    }
  );

  const getStatePillVariant = (state) =>
    ({
      proposed: "light",
      requested: "red",
      completed: "green",
      scheduled: "violet",
    }[state]);

  const isSelected = selectedInteractionIds.includes(interaction.id);
  const stateColor = getStateColor(interaction.state);
  const pillVariant = getStatePillVariant(interaction.state);

  const menuActions = useMemo(() => {
    if (!allowMenuActions) return [];
    return compact([
      interaction.canCancel && interaction.state === "requested" && cancelLabel,
      interaction.state === "scheduled" && requestCancelLabel,
      interaction.state === "scheduled" && requestRescheduleLabel,
    ]);
  }, [allowMenuActions, interaction.canCancel, interaction.state]);

  const runAction = (actionName, event) => {
    event.stopPropagation();

    actionName === cancelLabel && onCancelRequest && onCancelRequest(interaction.id, HitOrigin.calendarView);
    actionName === requestCancelLabel && onRequestCancel && onRequestCancel(interaction.id);
    actionName === requestRescheduleLabel && onRequestReschedule && onRequestReschedule(interaction.id);
  };

  const openFlyout = onSelectCard
    ? () => {
        if (!isSelected) {
          onToggleInteractionId && onToggleInteractionId(interaction.id);
        }
        onSelectCard && onSelectCard(interaction);
      }
    : undefined;

  const stopPropagation = (cb) => (e) => {
    e.stopPropagation();
    cb && cb();
  };

  const angle = interaction.angles[0];

  const onMouseEnter = () => setHoveringCard(true);
  const onMouseLeave = () => setHoveringCard(false);

  return (
    <ContentCard
      ref={ref}
      className="aui-relative aui-cursor-pointer"
      data-testid={`card-${interaction.id}`}
      onClick={() => onToggleInteractionId && onToggleInteractionId(interaction.id)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selected={isSelected}
      aria-selected={isSelected}
    >
      <div className="aui-flex aui-items-center aui-justify-between">
        <div>
          <div>
            <Typography variant="body-em" component="span" color={text.strong._}>
              {interaction.advisorCompany}
            </Typography>
            <Typography component="span" color={text.strong._}>
              {` - ${interaction.role}`}
            </Typography>
          </div>
          <Typography variant="body-small" color={text.secondary} component="div">
            {interaction.rolePeriod}
          </Typography>

          <div className="aui-flex aui-items-center aui-text-grey-4 aui-flex-wrap">
            <Icon size="medium" style={{ marginRight: "4px" }}>
              <Angle />
            </Icon>
            <Typography variant="body-small" component="span">
              {angle?.parent?.title ? `${angle.parent.title}, ` : ""}
              {angle?.title}&nbsp;&nbsp;|&nbsp;&nbsp;
            </Typography>

            {newNavigationEnabled ? (
              <TooltipStateDetail interaction={interaction} pendingRequest={pendingRequest}>
                <Pill variant={pillVariant} size="x-small">
                  <Typography variant="body-small" component="span">
                    {capitalizeFirstLetter(getStateName({ ...interaction, pendingRequest, showCalendarView: true }))}
                  </Typography>
                </Pill>
              </TooltipStateDetail>
            ) : (
              <TooltipStateDetail interaction={interaction} pendingRequest={pendingRequest}>
                <Typography
                  variant="body-small-em"
                  component="span"
                  className={`aui-text-${stateColor} aui-capitalize`}
                >
                  {getStateName({ ...interaction, pendingRequest, showCalendarView: true })}
                </Typography>
              </TooltipStateDetail>
            )}
            <Typography variant="body-small" component="span">
              &nbsp;&nbsp;•&nbsp;&nbsp;{interaction.advisorName}
            </Typography>
          </div>

          <div
            className={classNames("aui-absolute aui-right-0 aui-top-0 aui-p-4", {
              "aui-hidden": !hoveringCard,
              "aui-flex": hoveringCard,
            })}
            style={{ lineHeight: 0 }}
            data-testid="menus"
          >
            <div
              style={{
                width: 20,
                height: 24,
              }}
            />
            <div style={{ height: 24, width: 16 }} />
            <div className="aui-flex aui-justify-between " style={{ width: 35 }}>
              {openFlyout && (
                <div style={{ paddingTop: 6 }}>
                  <div
                    style={{ width: 30, height: 30 }}
                    className="aui-p-1 aui--m-1 hover:aui-bg-grey-0 aui-items-center aui-justify-center aui-rounded aui-cursor-pointer aui-flex aui-text-grey-4"
                    data-testid="open-flyout"
                    onClick={stopPropagation(openFlyout)}
                  >
                    <Icon size="large">
                      <Expert />
                    </Icon>
                  </div>
                </div>
              )}

              {menuActions.length === 0 && <div />}

              {menuActions.length > 0 && (
                <div
                  className={classNames(hoveringCard ? "aui-block" : "aui-hidden")}
                  style={{ paddingTop: 5 }}
                  data-testid="options"
                >
                  <DropdownMenu
                    items={menuActions}
                    hideDropdownToggle={true}
                    onItemClick={runAction}
                    dropdownWidth={64}
                    dropdownPosition={DropdownMenu.POSITION.RIGHT}
                    mouseEvent={DropdownMenu.EVENT.CLICK}
                    onHandleClick={stopPropagation()}
                    handle={
                      <Icon size="large" style={{ fill: "rgba(128, 128, 128, 1)" }}>
                        <MoreVert />
                      </Icon>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {interaction.showFollowUp && onRequestFollowUp && (
        <Button
          size="small"
          onClick={stopPropagation(() => onRequestFollowUp({ id: interaction.id, origin: HitOrigin.calendarView }))}
          className="aui-mt-3"
        >
          <Typography variant="body-em" component="span">
            Follow-up
          </Typography>
        </Button>
      )}
      {isSelected && interaction.state === "requested" && onRequestMoreAvailability && (
        <Button
          size="small"
          variant="secondary"
          onClick={stopPropagation(onRequestMoreAvailability)}
          className="aui-mt-3"
        >
          <Typography variant="body-em" component="span">
            {interaction.clientTimeslots.length > 0 ? "Edit my availability" : "Request more availability"}
          </Typography>
        </Button>
      )}
    </ContentCard>
  );
};

const cancelLabel = "Cancel Request";
const requestRescheduleLabel = "Reschedule Interaction...";
const requestCancelLabel = "Cancel Interaction...";

const CardLoader = () => (
  <Content
    viewBox="0 0 460 80"
    className="aui-border-solid aui-border-grey-2 aui-border-2 aui-flex aui-box-border aui-bg-white aui-rounded-lg aui-mb-4"
    data-testid="interaction-card-placeholder"
  >
    <rect x="17" y="15" width="150" height="18" />
    <rect x="177" y="15" width="265" height="18" />

    <rect x="17" y="50" width="100" height="18" />
    <rect x="127" y="50" width="100" height="18" />
    <rect x="237" y="50" width="205" height="18" />
  </Content>
);

const emptyMessages = {
  requested: "No requested experts available",
  today: "No experts available today",
  "this week": "No experts available this week",
  all: "No experts available",
};
