import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { DatePicker as AdsDatePicker, Icon, Popover, useThemeTokens } from "@alphasights/alphadesign-components";
import { CalendarToday } from "@alphasights/alphadesign-icons";
import { format } from "date-fns";

const RightAccessory = () => {
  const { spacing } = useThemeTokens();
  return (
    <x.div mr={spacing.inner.base02}>
      <Icon style={{ backgroundColor: "none" }} color="secondary">
        <CalendarToday />
      </Icon>
    </x.div>
  );
};

const Content = ({ value }) => {
  const { spacing } = useThemeTokens();
  return <x.div pl={spacing.inner.base02}>{value}</x.div>;
};

export const Datepicker = ({
  date,
  setDate,
  dateFormat = "MMM dd, yyyy",
  minDate,
  placeholder = "Select date",
  className,
  styles,
  disabled = false,
}) => {
  const { color } = useThemeTokens();
  const ref = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [formattedDate, setFormattedDate] = useState(date ? format(date || new Date(), dateFormat) : "");

  const [classNameForText, setClassNameForText] = useState("aui-text-grey-3");

  const handleSelectDate = (date) => {
    // update date and close the calendar component
    setDate(date);
    setFormattedDate(format(date, dateFormat));
    handleClose();
  };

  useEffect(() => {
    setFormattedDate(date ? format(date || new Date(), dateFormat) : "");
  }, [date, dateFormat]);

  useEffect(() => {
    setClassNameForText(formattedDate ? "aui-text-grey-5" : "aui-text-grey-3");
  }, [formattedDate]);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setShowCalendar(true);
    } else {
      setAnchorEl(null);
      setShowCalendar(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowCalendar(false);
  };

  return (
    <div style={styles} className={`datepicker aui-relative aui-border-2 aui-border-solid ${className}`}>
      <div
        className={`${classNameForText} aui-rounded aui-flex aui-flex-row aui-justify-between aui-items-center aui-h-full`}
        style={
          disabled
            ? {
                background: color.background.disabled,
                color: color.text.disabled,
                cursor: "not-allowed",
              }
            : {}
        }
        onClick={handleClick}
        data-testid="date-picker"
      >
        <Content value={formattedDate ? formattedDate : placeholder} />
        <RightAccessory />
      </div>
      {showCalendar && !disabled && (
        <Popover ref={ref} anchorEl={anchorEl} open onClose={handleClose}>
          <AdsDatePicker firstDate={date} onChange={handleSelectDate} minDate={minDate} />
        </Popover>
      )}
    </div>
  );
};
