import { FlyoutMode } from "pages/InteractionPage/enums";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { messageThreadService } from "services/messageThread";
import { MessageType } from "types";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { interactionsToExperts } from "components/MessengerPage/helper";
import { useThreadHeaders } from "hooks/messenger/useThreadHeaders";

export const FLYOUT_SECTIONS = Object.freeze({
  expandExpert: "expandExpert",
  expandUpgrades: "expandUpgrades",
  expandAvailability: "expandAvailability",
});

export const FlyoutContext = React.createContext();

export const FlyoutProvider = ({
  dispatch,
  state,
  projectToken,
  selectedInteraction,
  flyoutAction = null,
  upgrades = [],
  hasClientPortalMessages,
  flyoutMode,
  flyoutConfiguration,
  messageService = messageThreadService,
  newMessageType,
  newMessageOrigin,
  allInteractions = [],
  changeFlyoutMode,
  selectedExpertsIds = [],
  ...props
}) => {
  const [selectedMessageThread, setSelectedMessageThread] = useState();
  const isLoggedIn = useIsAuthenticated();

  const { threadHeaders: messageThreads, isThreadHeadersLoading, threadHeadersMutate } = useThreadHeaders({
    projectToken,
    shouldFetch: hasClientPortalMessages && isLoggedIn && selectedInteraction,
    advisorId: selectedInteraction?.advisorId,
  });

  useEffect(() => {
    if (isThreadHeadersLoading) {
      setSelectedMessageThread(undefined);
    } else if (messageThreads.length > 0) {
      setSelectedMessageThread(messageThreads[0]);
    }
  }, [messageThreads, isThreadHeadersLoading]);

  const onSelectMessageThread = useCallback((thread) => {
    setSelectedMessageThread(thread);
  }, []);

  const onSubmitMessage = useCallback(
    (params, attachments = null) => {
      const onSubmitFunction =
        newMessageType === MessageType.CallGuide ? messageService.createCallGuide : messageService.createThreads;

      return onSubmitFunction(projectToken, params, attachments).then(() => {
        void threadHeadersMutate();
        changeFlyoutMode(FlyoutMode.Messages);
      });
    },
    [newMessageType, messageService, projectToken, threadHeadersMutate, changeFlyoutMode]
  );

  const onSubmitReply = useCallback(
    (threadId, params) => messageService.createMessage(projectToken, threadId, params),
    [messageService, projectToken]
  );

  const onReplyCreated = (threadId, params) => threadHeadersMutate();

  const shouldExpandUpgrades = useMemo(
    () =>
      selectedInteraction?.state !== "completed" ||
      (selectedInteraction?.recordings?.length ?? 0) > 0 ||
      upgrades.map((u) => u.value).some((u) => ["recorded", "transcript_ai", "transcript", "summary"].includes(u)),
    [upgrades, selectedInteraction]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const isExpandedExpertProfile = flyoutAction
    ? flyoutAction === FLYOUT_SECTIONS.expandExpert
    : selectedInteraction?.state === "proposed" || selectedInteraction?.state === "requested";

  const isExpandedUpgrade = flyoutAction
    ? flyoutAction === FLYOUT_SECTIONS.expandUpgrades
    : state.availabilityState !== "provide_availability" && shouldExpandUpgrades;

  const isExpandedAvailability = flyoutAction
    ? flyoutAction === FLYOUT_SECTIONS.expandAvailability
    : selectedInteraction?.state !== "completed";

  const experts = useMemo(() => interactionsToExperts(allInteractions, true), [allInteractions]);

  const context = {
    isExpandedExpertProfile,
    isExpandedUpgrade,
    isExpandedAvailability,
    dispatch,
    state,
    flyoutMode,
    flyoutConfiguration,
    messageThreads,
    selectedMessageThread,
    onSelectMessageThread,
    onSubmitMessage,
    interaction: selectedInteraction,
    newMessageType,
    newMessageOrigin,
    allInteractions,
    projectToken,
    selectedExpertsIds,
    experts,
    onSubmitReply,
    onReplyCreated,
  };

  return <FlyoutContext.Provider value={context} {...props} />;
};

export const useFlyoutContext = () => useContext(FlyoutContext);
