import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const HeaderContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingBlock: spacing.inner.base04,
    paddingInline: spacing.inner.base08,
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base,
  };
});

const StyledTypography = styled(Typography)(() => {
  const { font, color } = useThemeTokens();
  return {
    fontSize: font.size["07"],
    background: color.background.brand.gradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
});

const StyledHeader = styled(Typography)(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    flexWrap: "wrap" as "wrap",
    gap: spacing.inner.base,
    height: spacing.inner.base06,
  };
});

export { HeaderContainer, StyledTypography, StyledHeader };
