import React from "react";
import { Transition } from "react-spring/renderprops.cjs";

const MountTransition = ({ isMounted, children, ...props }) => (
  <Transition items={isMounted} {...props}>
    {(show) => show && children}
  </Transition>
);

export const ShadowedByFlyout = ({ children, isFlyoutOpen, onOverlayClick, className, style = {} }) => {
  return (
    <div className={`aui-relative ${className ? className : ""}`} style={style}>
      {children}
      <FadeInOut isActive={isFlyoutOpen} from={0} to={0.15} leave={{ delay: 200 }}>
        <Backdrop onClick={onOverlayClick} />
      </FadeInOut>
    </div>
  );
};

export const FadeInOut = ({ isActive, from, to, children, leave = {}, enter = {} }) => (
  <MountTransition
    isMounted={isActive}
    from={{ opacity: from }}
    enter={{ opacity: to, ...enter }}
    leave={{ opacity: from, ...leave }}
  >
    {(props) => <div style={props} children={children} />}
  </MountTransition>
);

export const Backdrop = (props) => (
  <div className="aui-bg-black aui-absolute aui-inset-0 aui-pointer-events-auto" data-testid="backdrop" {...props} />
);
