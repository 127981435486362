import { useState } from "react";

type UseFilterPropsType = {
  onSave: (value: any) => void;
  filterState?: any;
};

// debounce saving filter changes by 1 second so that multiple changes within
// a short timeframe don't result in multiple unnecessary requests
const useSaveFilterTimeout = ({ onSave, filterState }: UseFilterPropsType) => {
  const [timeoutId, setTimeoutId] = useState<number>();

  const saveFilter = (value?: any) => {
    timeoutId && window.clearTimeout(timeoutId);
    const id = window.setTimeout(() => {
      onSave(value);
    }, 1000);
    setTimeoutId(id);
  };

  return {
    filterState,
    saveFilter,
  };
};

export default useSaveFilterTimeout;
