import {
  MessageThreadHeaderResponse,
  WorkRequestResponse,
  InboxResponse,
  MessageMarkAsReadResponse,
  MessageResponse,
  ThreadResponse,
} from "types";
import { fetch } from "../hooks/useApi";
import { Cookie } from "../Cookie";

export const messageThreadService = {
  fetchThreadsClientInbox(projectToken: string): Promise<InboxResponse[]> {
    const url = `/api/projects/${projectToken}/message-threads/client-inbox`;
    return fetch({
      url,
      handleForbidden: true,
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  fetchThread(projectToken: string, threadId?: string, groupId?: string): Promise<ThreadResponse[]> {
    const searchParams = new URLSearchParams();
    threadId && searchParams.append("threadId", threadId);
    groupId && searchParams.append("groupId", groupId);
    const url = `/api/projects/${projectToken}/message-threads?${searchParams}`;
    return fetch({
      url,
      handleForbidden: true,
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  fetchThreadsSummaryForProject(projectToken: string) {
    const url = `/api/projects/${projectToken}/message-threads/summary?groupByAdvisor=true`;
    return fetch({
      url,
      handleForbidden: true,
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  updateWorkRequest(projectToken: string, threadToken: string, content: any): Promise<WorkRequestResponse> {
    const url = `/api/projects/${projectToken}/message-threads/token/${threadToken}/work-request`;
    return fetch({
      url,
      handleForbidden: true,
      method: "PATCH",
      body: JSON.stringify(content),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  batchUpdateWorkRequest(projectToken: string, content: any): Promise<WorkRequestResponse[]> {
    const url = `/api/projects/${projectToken}/message-threads/batch-update-work-request`;
    return fetch({
      url,
      handleForbidden: true,
      method: "PATCH",
      body: JSON.stringify(content),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  addExperts(projectToken: string, threadId: string, content: any): Promise<ThreadResponse[]> {
    const url = `/api/projects/${projectToken}/message-threads/${threadId}/add-advisors`;
    return fetch({
      url,
      handleForbidden: true,
      method: "POST",
      body: JSON.stringify(content),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  fetchThreadHeaders(projectToken: string, expertId?: string): Promise<MessageThreadHeaderResponse[]> {
    const url = `/api/projects/${projectToken}/message-threads/header${expertId ? `?advisorId=${expertId}` : ``}`;
    return fetch({
      url,
      handleForbidden: true,
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  createThreads(projectToken: string, params: any, attachments: any[] | null): Promise<ThreadResponse[]> {
    const formData = new FormData();
    formData.append("messageThreadData", new Blob([JSON.stringify(params)], { type: "application/json" }));

    if (attachments) {
      attachments.map((attachment, index) => formData.append(`attachment${index}`, attachment.file, attachment.name));
    }

    return fetch({
      url: `/api/projects/${projectToken}/message-threads`,
      headers: {
        "X-COOKIE-POLICY-STATE": Cookie.findByName("_iub_cs-"),
      },
      handleForbidden: true,
      method: "POST",
      body: formData,
      skipAlert: true,
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  createCallGuide(projectToken: string, params: any, attachments: any[] | null): Promise<ThreadResponse[]> {
    const formData = new FormData();
    formData.append("messageThreadData", new Blob([JSON.stringify(params)], { type: "application/json" }));

    if (attachments) {
      attachments.map((attachment, index) => formData.append(`attachment${index}`, attachment.file, attachment.name));
    }

    return fetch({
      url: `/api/projects/${projectToken}/message-threads/call-guides`,
      headers: {
        "X-COOKIE-POLICY-STATE": Cookie.findByName("_iub_cs-"),
      },
      handleForbidden: true,
      method: "POST",
      body: formData,
      skipAlert: true,
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  createMessage(projectToken: string, threadId: string, request: any): Promise<MessageResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/message-threads/${threadId}/messages`,
      handleForbidden: true,
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },

  async markMessageAsRead(projectToken: string, messageId: string): Promise<MessageMarkAsReadResponse> {
    try {
      let res = await fetch({
        url: `/api/projects/${projectToken}/messages/${messageId}/mark-as-read`,
        method: "PATCH",
        handleForbidden: true,
        skipAlert: true,
      });
      return await res.json();
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  },
};
