import React from "react";
import { x } from "@xstyled/styled-components";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  SECTION_TITLES,
  SectionHeader,
  SectionWrapper,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import { useSurveysStore } from "pages/AlphaNowPage/primers/request-survey/state/store";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

type RequestSurveyCompanyPageProps = {
  companyName?: string;
};

const DataTestIds = {
  AlphaNowCompanyRequestSurveySection: "request-survey-section",
};

const RequestSurveyCompanyPage = ({ companyName }: RequestSurveyCompanyPageProps) => {
  const { logHit } = useTrackUserAction();
  const { spacing } = useThemeTokens();
  const setIsModalOpen = useSurveysStore(({ setIsModalOpen }) => setIsModalOpen);

  return (
    <SectionWrapper
      data-testid={`${DataTestIds.AlphaNowCompanyRequestSurveySection}-${SECTION_TITLES.RequestSurvey.toLowerCase()}`}
      hasDivider={true}
    >
      <x.div display="flex" columnGap={spacing.layout.base} alignItems="center">
        <x.div flex="1 1 auto">
          <SectionHeader title="Customer Survey" companyName={companyName ?? ""} noMarginBottom />
        </x.div>
        <x.div flex="0 0 auto">
          <Button
            size="small"
            variant="secondary"
            onClick={() => {
              logHit({
                origin: HitOrigin.companyPage,
                action: HitAction.alphaNowRequestSurveyAttempt,
              });
              setIsModalOpen(true);
            }}
          >
            Launch Customer Survey
          </Button>
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default RequestSurveyCompanyPage;
