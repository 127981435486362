import { ForwardedRef, useRef } from "react";

/**
 * {@link ForwardedRef} can either be a {@link MutableRefObject} or a function that accepts the
 * element. This allows accessing the .current property of the {@link MutableRefObject} on the forwarded ref.
 */
function useForwardedRef<T>(ref: ForwardedRef<T>) {
  const forwardedRef = useRef<T | null>(null);

  return {
    ref: forwardedRef,
    updateRef: (element: T) => {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }

      forwardedRef.current = element;
    },
  };
}

export default useForwardedRef;
