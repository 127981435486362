import React, { useMemo, useState } from "react";
import { Button, Icon, IconButton, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  Close,
  Forward10,
  Forward_30 as Forward30,
  Pause,
  PlayArrow,
  Replay10,
  Replay_30 as Replay30,
  Tick,
} from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { toMinutesAndSeconds } from "helpers/displayHelpers";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ENABLE_NEW_DELIVERABLES_UX, ProjectBadged, useProjectBadgeContext } from "providers/BadgeProvider";

const PlaybackSpeedIcon = ({ onChangeSpeed, order, size, speed }) => {
  const [anchorEl, setAnchorEl] = useState();
  const possibleSpeeds = [0.5, 0.75, 1.0, 1.25, 1.5];

  const {
    playbackSpeedStyles,
    selectedPlaybackSpeedStyles,
    playbackSpeedOptionsStyles,
    popoverStyles,
    playbackSpeedButtonStyles,
  } = usePlaybackSpeedStyles(size);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSpeedChange = (newSpeed) => {
    onChangeSpeed(newSpeed);
    handlePopoverClose();
  };

  return (
    <>
      <Button
        dataAttributes={{ "data-testid": "playback-speed-btn" }}
        variant="outline"
        onClick={onClick}
        active={anchorEl}
        size={size}
        {...playbackSpeedButtonStyles}
        order={order}
      >
        <Typography color="secondary" variant="body-em">
          {speed}x
        </Typography>
      </Button>
      <Popover anchorEl={anchorEl} open={anchorEl} placement="bottom" onClose={handlePopoverClose} {...popoverStyles}>
        <x.div {...playbackSpeedOptionsStyles}>
          {possibleSpeeds.map((s) => (
            <Button
              key={s}
              variant="ghost"
              onClick={() => handleSpeedChange(s)}
              endIcon={speed === s ? <Icon color="secondary" children={<Tick />} /> : null}
              {...playbackSpeedStyles}
              {...(speed === s && selectedPlaybackSpeedStyles)}
            >
              <Typography variant="body">{s}x</Typography>
            </Button>
          ))}
        </x.div>
      </Popover>
    </>
  );
};

const usePlaybackSpeedStyles = (size) => {
  const {
    color: { text, background },
    spacing: { inner },
  } = useThemeTokens();

  const popoverStyles = {
    p: 0,
    borderRadius: inner.base02,
  };

  const playbackSpeedStyles = {
    p: inner.base02,
    color: text.strong._,
    justifyContent: "space-between",
    backgroundColor: {
      hover: background.surface.page.hover,
    },
    minW: "110px",
  };

  const selectedPlaybackSpeedStyles = {
    ml: "auto",
    backgroundColor: {
      _: background.neutral.hover,
      hover: background.neutral.hover,
    },
  };

  const playbackSpeedOptionsStyles = {
    gap: inner.base,
    display: "flex",
    flexDirection: "column",
    p: inner.base02,
  };

  const playbackSpeedButtonStyles = {
    p: 0,
    w: size === "small" ? "32px" : "44px",
    minH: size === "small" ? "32px" : "44px",
  };

  return {
    popoverStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
    playbackSpeedStyles,
    playbackSpeedButtonStyles,
  };
};

export const AudioPlayer = ({
  controlsProps,
  progressBarProps,
  onClose,
  mobile = false,
  progressBarChildren = null,
  enableRefocus = false,
}) => {
  const {
    containerStyles,
    progressBarDraggingStyles,
    progressBarWithTimerWrapperStyles,
    progressBarWrapperStyles,
    progressBarContentStyles,
    progressPositionStyles,
    timeStyles,
  } = useStyles(progressBarProps, mobile);

  const { isMobile } = useCheckScreen();

  const { hasProjectBadge } = useProjectBadgeContext();

  const buttonProps = {
    variant: "outline",
    size: mobile ? "small" : "medium",
    color: "secondary",
  };

  const textProps = {
    variant: mobile ? "body" : "body-small",
    color: "secondary",
  };

  const mouseTouchEvents = {
    onMouseDown: progressBarProps.onMouseDown,
    ...(mobile
      ? {
          onTouchStart: progressBarProps.onMouseDown,
        }
      : {}),
  };

  const seekDistance = useMemo(() => (hasProjectBadge(ENABLE_NEW_DELIVERABLES_UX) ? 10 : 30), [hasProjectBadge]);

  return (
    <x.div {...containerStyles} data-testid="audio-player">
      <OrderedIconButton
        {...buttonProps}
        onClick={controlsProps.onClickRewind(seekDistance)}
        dataAttributes={{ "data-testid": "transcript-audio-replay" }}
      >
        {hasProjectBadge(ENABLE_NEW_DELIVERABLES_UX) ? <Replay10 /> : <Replay30 />}
      </OrderedIconButton>

      <OrderedIconButton {...buttonProps} onClick={controlsProps.onTogglePlay} testId="player-toggle-play">
        {controlsProps.playing ? <Pause /> : <PlayArrow />}
      </OrderedIconButton>

      <OrderedIconButton
        {...buttonProps}
        onClick={controlsProps.onClickForward(seekDistance)}
        dataAttributes={{ "data-testid": "transcript-audio-forward" }}
      >
        {hasProjectBadge(ENABLE_NEW_DELIVERABLES_UX) ? <Forward10 /> : <Forward30 />}
      </OrderedIconButton>

      <PlaybackSpeedIcon
        onChangeSpeed={controlsProps.onChangeSpeed}
        order={mobile ? -1 : 1}
        size={buttonProps.size}
        speed={controlsProps.speed}
      />

      {onClose && (
        <OrderedIconButton {...buttonProps} onClick={onClose} testId="close-audio-player" order={2}>
          <Close />
        </OrderedIconButton>
      )}

      <x.div {...progressBarWithTimerWrapperStyles}>
        <x.div display="flex" w="100%" alignItems="center" gap="8px">
          <Typography {...textProps} {...timeStyles} data-testid="audio-player-current-time">
            {toMinutesAndSeconds(
              progressBarProps.draggingValue != null ? progressBarProps.draggingValue : progressBarProps.value
            )}
          </Typography>

          <x.div {...mouseTouchEvents} {...progressBarDraggingStyles} data-testid="audio-player-progress-bar">
            <x.div {...progressBarWrapperStyles}>
              <x.div {...progressBarContentStyles} />
              <x.div {...progressPositionStyles} />
            </x.div>
          </x.div>

          <Typography {...textProps} {...timeStyles}>
            {toMinutesAndSeconds(progressBarProps.duration)}
          </Typography>
        </x.div>

        <ProjectBadged badge={ENABLE_NEW_DELIVERABLES_UX}>{progressBarChildren}</ProjectBadged>
      </x.div>
      {enableRefocus && (
        <ProjectBadged badge={ENABLE_NEW_DELIVERABLES_UX}>
          <Button
            variant="outline"
            dataAttributes={{ "data-testid": "transcript-refocus" }}
            size={isMobile ? "small" : "medium"}
            onClick={controlsProps.onClickRefocus}
          >
            <Typography variant={isMobile ? "body-small-em" : "body-em"} color="secondary">
              Refocus
            </Typography>
          </Button>
        </ProjectBadged>
      )}
    </x.div>
  );
};

export const MiniPlayer = ({ controlsProps, progressBarProps, onClose }) => {
  const {
    miniPlayerWrapper,
    miniPlayerProgressBarWrapper,
    progressBarDraggingStyles,
    progressBarWrapperStyles,
    progressBarContentStyles,
    progressPositionStyles,
    timeStyles,
  } = useStyles(progressBarProps, false);

  return (
    <x.div {...miniPlayerWrapper} data-testid="mini-audio-player">
      <IconButton onClick={controlsProps.onTogglePlay} variant="ghost" size="small" testId="player-toggle-play">
        {controlsProps.playing ? <Pause /> : <PlayArrow />}
      </IconButton>
      <x.div {...miniPlayerProgressBarWrapper}>
        <x.div {...progressBarProps} {...progressBarDraggingStyles} data-testid="audio-player-progress-bar">
          <x.div {...progressBarWrapperStyles}>
            <x.div {...progressBarContentStyles} />
            <x.div {...progressPositionStyles} />
          </x.div>
        </x.div>
        <Typography color="secondary" {...timeStyles}>
          {toMinutesAndSeconds(
            progressBarProps.draggingValue != null ? progressBarProps.draggingValue : progressBarProps.value
          )}
        </Typography>
      </x.div>
      <IconButton
        onClick={controlsProps.onClickRewind(10)}
        variant="ghost"
        size="small"
        testId="player-toggle-forward-10"
      >
        <Replay10 />
      </IconButton>
      <IconButton variant="ghost" size="small" testId="close-audio-player" onClick={onClose}>
        <Close />
      </IconButton>
    </x.div>
  );
};

const OrderedIconButton = styled(IconButton)`
  order: ${({ order }) => order || "undefined"};
`;

const useStyles = (progressBarProps, mobile) => {
  const {
    color: { background, border },
    spacing: { inner },
  } = useThemeTokens();

  const progressBarDraggingStyles = {
    flex: 1,
    alignItems: "center",
    display: "flex",
    h: "25px",
    cursor: "pointer",
  };

  const progressBarWrapperStyles = {
    flex: 1,
    backgroundColor: background.neutral.default,
    h: "5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  };

  const progressBarPercentage =
    progressBarProps.duration === 0
      ? 0
      : (progressBarProps.draggingValue != null ? progressBarProps.draggingValue : progressBarProps.value) /
        progressBarProps.duration;

  const progressBarContentStyles = {
    w: Math.floor(progressBarPercentage * 100) + "%",
    h: "100%",
    borderRadius: "4px",
    backgroundColor: border.info,
  };

  const progressPositionStyles = {
    w: inner.base04,
    h: inner.base04,
    borderRadius: inner.base04,
    backgroundColor: border.info,
    borderColor: border.dividerInverse,
    borderWidth: inner.base,
    boxShadow: "0px 4px 12px rgba(0, 29, 58, 0.12)",
    transform: "translateX(-8px)",
    left: Math.floor(progressBarPercentage * 100) + "%",
    position: "absolute",
  };

  const timeStyles = {
    w: "40px",
    textAlign: "center",
  };

  return {
    containerStyles: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      columnGap: mobile ? inner.base06 : inner.base04,
      rowGap: inner.base03,
      flexGrow: 1,
    },
    progressBarDraggingStyles,
    progressBarWithTimerWrapperStyles: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      w: mobile ? "100%" : undefined,
      order: mobile ? 10 : undefined,
    },
    progressBarWrapperStyles,
    progressBarContentStyles,
    progressPositionStyles,
    timeStyles,
    miniPlayerWrapper: {
      lineHeight: 0,
      display: "flex",
      gap: inner.base04,
    },
    miniPlayerProgressBarWrapper: {
      display: "flex",
      gap: inner.base02,
      alignItems: "center",
      minW: 240,
      flex: 1,
    },
  };
};
