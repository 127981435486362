import { Company, ThirdParty, Industry, Lock, Transcript } from "@alphasights/alphadesign-icons";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";

const OUR_RESEARCH_TITLE = "Our Research";
const OUR_RESEARCH_SUMMARY_DESCRIPTION = "Click on a research type to see examples in the library";
const FREE_RESEARCH_TYPE_LABEL = "Free";

const RESEARCH_TYPE_DESCRIPTIONS: Record<string, string> = {
  [AlphaNowProductType.alphaview]: "Expert calls moderated by former buyside investors",
  [AlphaNowProductType.pcc]: "Transcripts shared by AlphaSights investor clients",
  [AlphaNowProductType.companyPrimer]:
    "Synthesized insights on a company from former executives, competitors and customers",
  [AlphaNowProductType.customerPrimer]: "Synthesized insights on vendors from key customer decision makers",
  [AlphaNowProductType.marketPrimer]: "Synthesized insights on a market from industry executives",
  [AlphaNowProductType.privateEnterprise]: "Transcripts of your organization’s expert calls",
};

const RESEARCH_TYPE_ICONS: Record<string, Function> = {
  [AlphaNowProductType.alphaview]: Transcript,
  [AlphaNowProductType.pcc]: Transcript,
  [AlphaNowProductType.companyPrimer]: Company,
  [AlphaNowProductType.customerPrimer]: ThirdParty,
  [AlphaNowProductType.marketPrimer]: Industry,
  [AlphaNowProductType.privateEnterprise]: Lock,
};

const FREE_RESEARCH_CONTENT_IDS = {
  [AlphaNowProductType.alphaview]: "30cc748e-0281-4f70-843f-d18eea18774c",
  [AlphaNowProductType.pcc]: "",
  [AlphaNowProductType.companyPrimer]: "e03ef7a7-0d76-416b-b6bc-385f7e6778d1",
  [AlphaNowProductType.customerPrimer]: "c69f87e5-08b5-484b-a129-c4e68bc0b4c3",
  [AlphaNowProductType.marketPrimer]: "e59eed24-db2f-45b5-a57b-d76923bb68d7",
  [AlphaNowProductType.privateEnterprise]: "",
};

const ORDERED_PRODUCT_TYPES = [
  AlphaNowProductType.alphaview,
  AlphaNowProductType.pcc,
  AlphaNowProductType.companyPrimer,
  AlphaNowProductType.marketPrimer,
  AlphaNowProductType.customerPrimer,
  AlphaNowProductType.privateEnterprise,
];

export {
  OUR_RESEARCH_TITLE,
  OUR_RESEARCH_SUMMARY_DESCRIPTION,
  FREE_RESEARCH_TYPE_LABEL,
  RESEARCH_TYPE_DESCRIPTIONS,
  RESEARCH_TYPE_ICONS,
  ORDERED_PRODUCT_TYPES,
  FREE_RESEARCH_CONTENT_IDS,
};
