import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMessengerSettingsStyles = () => {
  const {
    spacing: { inner },
    color: { background, border },
  } = useThemeTokens();

  const container = {
    w: "500px",
    display: "flex",
    flexDirection: "column",
    pt: inner.base08,
    pr: inner.base08,
    pl: inner.base06,
    background: background.surface.page.default,
    borderLeftWidth: 1,
    borderColor: border.divider,
    flexShrink: 0,
  };

  const headerContainer = {
    display: "flex",
    alignItems: "center",
    gap: inner.base03,
  };

  const contentContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: inner.base04,
    mt: inner.base06,
  };

  const buttonsContainer = {
    display: "flex",
    gap: inner.base04,
    marginLeft: "auto",
  };

  return {
    container,
    headerContainer,
    contentContainer,
    buttonsContainer,
  };
};
