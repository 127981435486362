import React from "react";
import { Alert, AlertVariant, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import useStyles from "./styles";

export type ErrorType = "genericPurchaseError" | "insufficientCredits";

interface ErrorBannerMap {
  level: AlertVariant;
  title: string;
  description: string;
}

export const ErrorBanner = ({
  errorType = "genericPurchaseError",
  isContentFlyout = false,
}: {
  errorType: ErrorType;
  isContentFlyout: boolean;
}) => {
  const styles = useStyles({ isContentFlyout });
  const errorsMap = {
    insufficientCredits: {
      level: "warning",
      title: "Insufficient credits:",
      description: "Reach out to your AlphaSights point of contact to purchase more credits.",
    },
    genericPurchaseError: {
      level: "danger",
      title: "Action failed:",
      description: "There was a problem with purchasing this content. Please try again.",
    },
  } as Record<ErrorType, ErrorBannerMap>;

  return (
    <x.div {...styles.purchaseInDeliverablesErrorBanner} data-testid="content-purchase-error-banner">
      <Alert variant={errorsMap[errorType].level} flexGrow="1">
        <Typography variant="body-small-em" component="span">
          {errorsMap[errorType].title}{" "}
        </Typography>
        <Typography variant="body-small" component="span">
          {errorsMap[errorType].description}
        </Typography>
      </Alert>
    </x.div>
  );
};
