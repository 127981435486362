import React, { createRef, useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { Divider, IconButton, TabItem, Tabs } from "@alphasights/alphadesign-components";
import { ArrowLeft, Search } from "@alphasights/alphadesign-icons";
import { useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import { MobileTopBarOptionalBottomBorder } from "./MobileContent.component";
import { HighLevelOverview, ThemeSection } from "views/DeliverablesView/ProjectSummaries/ProjectSummary";
import { useProjectSummarySearch } from "views/DeliverablesView/ProjectSummaries/useProjectSummarySearch";
import { CallbackProvider } from "providers/CallbackProvider";
import _, { noop } from "lodash";
import { HitNav } from "./InteractionDeliverable.component";
import { ProjectSummaryStatus } from "views/DeliverablesView/ProjectSummaries/ProjectSummaries.types";
import { ProjectSummaryPageSkeleton } from "views/DeliverablesView/ProjectSummaries/ProjectSummarySkeletons";
import { ProjectSummaryError } from "views/DeliverablesView/ProjectSummaries/ProjectSummaryError";

export interface MobileProjectSummaryProps {
  onClose: () => void;
}

export const MobileProjectSummary = ({ onClose }: MobileProjectSummaryProps) => {
  const { fullScreenFixedWrapper, topbarFixedWrapper, fullScreenFlexColWrapper } = useMobileDeliverablesStyles();

  const [variant, setVariant] = useState<"regular" | "search">("regular");
  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>();
  const [tabContainerRef, setTabContainerRef] = useState<HTMLDivElement | null>();
  const [highlightedSectionIx, setHighlightedSectionIx] = useState(0);

  const { selectedSummary, isSelectedSummaryLoading } = useProjectSummariesContext();

  const {
    searchTerms,
    onSearchTerms,
    setSearchIx,
    searchIx,
    searchCounts,
    searchedSummary,
    searchElHighlight,
  } = useProjectSummarySearch({ summary: selectedSummary });

  const themes = useMemo(() => {
    return [{ title: "Overview" }, ...(selectedSummary?.themes || [])];
  }, [selectedSummary]);

  const sectionRefs = useMemo(
    () => [createRef<HTMLDivElement>(), ...(selectedSummary?.themes.map(() => createRef<HTMLDivElement>()) ?? [])],
    [selectedSummary]
  );

  const onSearchClose = () => {
    onSearchTerms([]);
    setVariant("regular");
  };

  useEffect(() => {
    // IntersectionObserver and scrollIntoView were not working
    // as expected on some scenarios on ios, that's why
    // these custom implementations

    if (sectionRefs.length <= 1) return;
    const tabButtons = tabContainerRef?.querySelectorAll("nav div button") || [];

    const scrollTabs = _.debounce((ix: number) => {
      setHighlightedSectionIx(ix);
      const tabButton = tabButtons[ix];

      const tabButtonBounds = tabButton.getBoundingClientRect();

      const centering = Math.max(
        ((tabContainerRef?.getBoundingClientRect().width || 0) - tabButtonBounds.width) / 2,
        0
      );

      const left = Math.max(tabButtonBounds.left + (tabContainerRef?.scrollLeft || 0) - centering, 0);

      tabContainerRef?.scrollTo({ left, behavior: "smooth" });
    }, 300);

    const calculateMostVisible = () => {
      if (!containerRef?.isConnected) return;

      const bounds = sectionRefs.map((ref) => ref.current?.getBoundingClientRect());

      const top = (containerRef?.scrollTop || 0) + (headerRef?.clientHeight || 0);
      const containerHeight = (containerRef?.clientHeight || 0) - (headerRef?.clientHeight || 0);

      const containerVisibleTop = top;
      const containerVisibleBottom = top + containerHeight;

      const percentages = bounds.map((b, ix) => {
        const elTop = (b?.top || 0) + top;
        const elBottom = elTop + (b?.height || 0);

        const elVisibleTop = Math.max(containerVisibleTop, elTop);
        const elVisibleBottom = Math.min(containerVisibleBottom, elBottom);

        const elVisibleHeight = elVisibleBottom - elVisibleTop;

        if (elVisibleHeight < 0) return [ix, 0];
        else if (elVisibleHeight > containerHeight) return [ix, 1];
        else return [ix, elVisibleHeight / containerHeight];
      });

      const mostVisible = _.maxBy(percentages, ([ix, percentage]) => percentage)!!;

      scrollTabs(mostVisible[0]);
    };

    const onScroll = _.throttle(calculateMostVisible, 300);
    const onClick = () => setTimeout(calculateMostVisible, 300);

    containerRef?.addEventListener("scroll", onScroll);
    containerRef?.addEventListener("click", onClick);

    return () => {
      containerRef?.removeEventListener("scroll", onScroll);
      containerRef?.removeEventListener("click", onClick);
    };
  }, [sectionRefs, headerRef, containerRef, tabContainerRef]);

  const onChangeHighlightedSectionIx = (ix: number) => {
    const top =
      (sectionRefs[ix].current?.getBoundingClientRect().top || 0) +
      (containerRef?.scrollTop || 0) -
      (headerRef?.clientHeight || 0) -
      10;

    containerRef?.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  if (!selectedSummary) return null;

  return (
    <CallbackProvider callbacks={{ onSelectCard: noop }}>
      <x.div {...fullScreenFixedWrapper} ref={setContainerRef} data-testid="mobile-project-summary">
        <x.div {...topbarFixedWrapper} zIndex="10" ref={setHeaderRef}>
          <MobileTopBarOptionalBottomBorder
            noBorder
            title="Project Transcript Summary"
            mainButton={
              <IconButton size="large" variant="ghost" onClick={onClose} testId="close-summary-btn">
                <ArrowLeft />
              </IconButton>
            }
            onSearchClose={onSearchClose}
            keywords={searchTerms}
            onKeywordsMatch={onSearchTerms}
            autoFocusSearch
            variant={variant}
            regularButtons={
              <>
                <IconButton
                  size="large"
                  variant="ghost"
                  onClick={() => setVariant("search")}
                  testId="mobile-summary-search-open-btn"
                >
                  <Search />
                </IconButton>
              </>
            }
          />

          <x.div overflowX="auto" className="scrollable-wo-scrollbar" ref={setTabContainerRef}>
            <x.div minWidth="max-content" minHeight="max-content">
              <Tabs size="small" px="16px" index={highlightedSectionIx} onChange={onChangeHighlightedSectionIx}>
                {themes.map((theme) => (
                  <TabItem key={theme.title} label={theme.title} />
                ))}
              </Tabs>
            </x.div>
          </x.div>
        </x.div>
        <x.div {...fullScreenFlexColWrapper} mt={`${headerRef?.clientHeight}px`}>
          <x.div py="24px" px="16px">
            {(isSelectedSummaryLoading ||
              selectedSummary.status === ProjectSummaryStatus.Processing ||
              selectedSummary.status === ProjectSummaryStatus.Pending) && <ProjectSummaryPageSkeleton />}
            {!isSelectedSummaryLoading && selectedSummary.status === ProjectSummaryStatus.Error && (
              <ProjectSummaryError />
            )}
            {!isSelectedSummaryLoading && selectedSummary.status === ProjectSummaryStatus.Complete && (
              <>
                <HighLevelOverview overview={searchedSummary.overview} ref={sectionRefs[0]} />
                <WideDivider />
                {searchedSummary.themes.map((theme, idx) => (
                  <React.Fragment key={theme.title}>
                    <ThemeSection
                      theme={theme}
                      ref={sectionRefs[idx + 1]}
                      index={idx + 1}
                      searchElHighlight={searchElHighlight}
                      insightsSearchCounts={searchCounts?.insights[idx]}
                    />
                    {idx + 1 < searchedSummary.themes.length && <WideDivider />}
                  </React.Fragment>
                ))}
              </>
            )}
          </x.div>
        </x.div>
        {variant === "search" && searchTerms.length > 0 && (
          <HitNav
            totalHits={searchCounts!!.summary}
            currentHit={searchIx!!}
            setCurrentHit={(fn) => {
              const newIx = fn(searchIx!!);
              setSearchIx(newIx);
            }}
          />
        )}
      </x.div>
    </CallbackProvider>
  );
};

const WideDivider = () => (
  <x.div mx="-16px">
    <Divider spacing="lg"></Divider>
  </x.div>
);
