import React, { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "../../components/alphaui";
import { default as Content } from "react-content-loader";
import { validateEmail } from "../../pages/Login/validators";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { CreatableSelect } from "../../components/Select";
import { Typography } from "@alphasights/alphadesign-components";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { emailValidatorService } from "services/emailValidatorService";

export const AttendeesList = ({ attendees, removeAttendee, toggleOptionalAttendee }) => {
  useEffect(ReactTooltip.rebuild, []);
  const currentUser = useCurrentUser();

  return (
    <>
      {!currentUser && <Typography>Include your own email to receive an invite</Typography>}
      <div style={{ maxHeight: 150 }} className="aui-overflow-auto">
        {attendees.map((attendee) => (
          <Attendee
            key={attendee.emailAddress}
            label={attendee.displayName || attendee.emailAddress}
            tooltip={attendee.emailAddress}
            optional={attendee.optional}
            onRemove={(attendees.length > 1 && removeAttendee(attendee)) || null}
            onToggleOptional={toggleOptionalAttendee(attendee)}
          />
        ))}
      </div>
    </>
  );
};

const RoundInitials = ({ name, className }) => {
  const label = name
    ?.split(" ")
    .map((word) => word.substring(0, 1))
    .join("");
  return (
    <div
      className={classNames(
        "aui-flex aui-items-center aui-justify-center",
        "aui-rounded-full aui-w-8 aui-h-8",
        " hover:aui-bg-red",
        "aui-bg-grey-1 aui-text-grey-4 aui-uppercase",
        className
      )}
    >
      <Typography variant="pre-title" component="span">
        {label && label.substring(0, 2)}
      </Typography>
    </div>
  );
};

export const AttendeesSelect = ({ options, currentAttendees, setCurrentAttendees }) => {
  const [invalid, setInvalid] = useState(false);
  const [input, setInput] = useState("");
  const ref = useRef(null);

  const loadOptions = (input, updateOptions) => {
    updateOptions(options.filter((o) => o.displayName.toLowerCase().includes(input.toLowerCase())));
  };

  const notSelectedOptions = useMemo(() => {
    return options.filter((opt) => !currentAttendees.map((e) => e.emailAddress).includes(opt.emailAddress));
  }, [options, currentAttendees]);

  const onCreateOption = (input) => {
    emailValidatorService.validateCalendarInvitationEmails(input.emailAddress || input).then((response) => {
      if (response.isValid) {
        setInvalid(false);
        setInput("");
        addAttendees(input);
      } else {
        setInvalid(true);
        ref.current.select.select.select.inputRef.focus();
        ref.current.select.select.select.inputRef.blur();
      }
    });
  };

  const addAttendees = (input) => {
    if (typeof input === "object" && !input.emailAddress.includes(",")) {
      setCurrentAttendees([...currentAttendees, { ...input, optional: false }]);
    } else {
      const emails = (input.emailAddress || input)
        .split(",")
        .map((entry) => entry.trim())
        .filter((entry) => entry);
      setCurrentAttendees([...currentAttendees, ...emails.map((email) => ({ emailAddress: email, optional: false }))]);
    }
  };

  const onChange = (option, { action }) => {
    return action === "select-option" && onCreateOption(option);
  };

  const validateInput = (input) => !validateEmail(input);

  const onInputChange = (value, action) => {
    if (action.action === "input-change") setInput(value);
  };

  return (
    <>
      <CreatableSelect
        ref={ref}
        getOptionValue={(option) => option.emailAddress}
        getOptionLabel={(option) => option.displayName || option.emailAddress}
        defaultOptions={notSelectedOptions}
        noOptionsMessage={() => "Enter valid emails separated by comma"}
        getNewOptionData={(emailAddress) => {
          const emailCount = emailAddress.split(",").filter((val) => val).length;
          return emailCount > 1
            ? { emailAddress, displayName: `Add ${emailCount} emails...` }
            : { emailAddress, displayName: emailAddress };
        }}
        isValidNewOption={(input) =>
          input &&
          !input
            .split(",")
            .map((entry) => entry.trim())
            .filter((entry) => entry)
            .find((entry) => !validateInput(entry))
        }
        placeholderMenuOpen="Add colleagues"
        placeholderMenuClosed="Add colleagues"
        onChange={onChange}
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        inputValue={input}
        onInputChange={onInputChange}
      />
      {invalid && (
        <Typography color="danger" variant="body-small" mt="8px">
          Invalid email address
        </Typography>
      )}
    </>
  );
};

export const LoadingBar = () => (
  <Content
    viewBox="0 0 360 30"
    className="aui-flex aui-box-border aui-bg-white"
    data-testid="attendees-section-placeholder"
  >
    <rect x="17" y="10" width="250" height="22" />
  </Content>
);

const Attendee = ({ label, optional, tooltip, onClick, onRemove, onToggleOptional }) => {
  const isAlphasightsEmail = (label || "").toLowerCase().endsWith("@alphasights.com");
  return (
    <div
      onClick={onClick}
      className="aui-group aui-flex aui-justify-between aui-items-center aui-p-2 aui-pl-0 aui-text-grey-5"
      data-testid={`attendee-${label}`}
    >
      <div className="aui-flex aui-items-center" title={tooltip}>
        <RoundInitials className="aui-mr-2" name={label} />
        <div
          className={classNames(
            "aui-overflow-hidden",
            "aui-whitespace-no-wrap",
            isAlphasightsEmail && "aui-flex aui-bg-warning-2 aui-px-2"
          )}
          style={{
            maxWidth: 180,
            textOverflow: "ellipsis",
          }}
        >
          {isAlphasightsEmail && (
            <div
              className="aui-mr-2 aui-mt-1"
              data-tip="Alphasights emails will be saved even though they won't show here once you close this popup"
              data-testid="alert-as-email"
            >
              <Icon icon="alert-triangle" />
            </div>
          )}
          <Typography component="span">{label}</Typography>
        </div>
      </div>
      <div className="aui-flex aui-items-center">
        <PortalTooltip dark title={optional ? "Mark Required" : "Mark Optional"}>
          <div data-testid="toggle-optional" className="aui-flex aui-items-center">
            <Icon
              icon="advisor"
              onClick={() => {
                onToggleOptional();
              }}
              className={classNames(
                "aui-cursor-pointer",
                optional ? "aui-text-white group-hover:aui-text-grey-3" : "aui-text-grey-5"
              )}
            />
          </div>
        </PortalTooltip>
        <PortalTooltip dark title={onRemove ? "Remove" : "Can't remove last attendee"}>
          <div className="aui-flex aui-items-center ">
            <Icon
              icon="close"
              onClick={onRemove}
              className="aui-ml-2 aui-cursor-pointer aui-text-xs aui-cursor-pointer aui-text-white group-hover:aui-text-grey-3"
            />
          </div>
        </PortalTooltip>
      </div>
    </div>
  );
};
