import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const getRowColumnsConfig = (numColumns: number) => {
  switch (numColumns) {
    case 1:
      return { gridTemplateColumns: "97%" };
    case 2:
      return { gridTemplateColumns: "repeat(2,50%)" };
    case 3:
      return { gridTemplateColumns: "28% 47% 25%" };
    case 4:
      return { gridTemplateColumns: "19.5% repeat(2,31%) 18.5%" };
    case 5:
      return { gridTemplateColumns: "16% repeat(3,23.5%) 13.5%" };
    case 6:
      return { gridTemplateColumns: "13.5% repeat(4,18.9%) 10.8%" };
    case 7:
      return { gridTemplateColumns: "11.6% repeat(5,15.8%) 9.4%" };
    case 8:
      return { gridTemplateColumns: "10.5% repeat(6,13.4%) 9%" };
    case 9:
      return { gridTemplateColumns: "10.5% repeat(7,11.5%) 9%" };
    case 10:
      return { gridTemplateColumns: "8.5% repeat(8,10.5%) 7.5%" };
    default:
      return { gridTemplateColumns: `repeat(${numColumns}, ${100 / numColumns}%)` };
  }
};

const CompetitivePerformanceXAxis = ({ kpcs }: { kpcs: string[][] }) => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div display="flex" marginBottom={spacing.inner.base02}>
      <x.div flex="1 1 auto" display="grid" marginLeft="90px" alignItems="start" {...getRowColumnsConfig(kpcs.length)}>
        {kpcs.map((multilinedName, index) => (
          <x.div
            key={multilinedName.join(" ")}
            w="100%"
            alignItems={
              index === 0 && kpcs.length > 1 ? "start" : index === kpcs.length - 1 && kpcs.length > 1 ? "end" : "center"
            }
            display="flex"
            justifyContent="center"
            flexDirection="column"
            {...typography.body.small}
            color={color.text.strong._}
          >
            <x.div textAlign="center" display="flex" flexDirection="column">
              {multilinedName.map((line) => (
                <x.div textAlign="center">{line}</x.div>
              ))}
            </x.div>
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

export default CompetitivePerformanceXAxis;
