import { useBuildColumnDefinitions, filterColumnDefinitions } from "views/AdvisorsTableView";
import {
  CurrentVendorColumn,
  calculateMainKnowledge,
} from "../../pages/CustomerExpertPage/customersColumns/CurrentVendorColumn";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import { getUniqCustomerKnowledges } from "pages/CustomerExpertPage/helpers";
import { OtherVendorsColumn } from "../../pages/CustomerExpertPage/customersColumns/OtherVendorsColumn";
import { RoleColumn } from "../../pages/CustomerExpertPage/customersColumns/RoleColumn";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";

const useColumnDefinitionsCustomerView = ({ project, preferencesEnabled }) => {
  const defaultColumnConfig = {
    enablePin: preferencesEnabled,
    enableDragDrop: preferencesEnabled,
    sortAscMeta: {
      label: "Sort A-Z",
    },
    sortDescMeta: {
      label: "Sort Z-A",
    },
  };

  const { appliedFilters } = useCustomerKnowledgeContext();
  const sortCurrentVendor = (row1, row2) => {
    const a = calculateMainKnowledge(
      getUniqCustomerKnowledges(row1.original.customerKnowledges),
      appliedFilters,
      project
    );
    const b = calculateMainKnowledge(
      getUniqCustomerKnowledges(row2.original.customerKnowledges),
      appliedFilters,
      project
    );

    if (!a?.vendor?.name || !b?.vendor.name) {
      return 0;
    }

    return a.vendor.name.localeCompare(b.vendor.name);
  };

  return {
    currentVendorColumn: {
      id: "currentVendorColumn",
      header: "Currently Uses",
      sortingFn: sortCurrentVendor,
      accessorKey: "customerKnowledges",
      cell: CurrentVendorColumn,
      minSize: 200,
      meta: { ...defaultColumnConfig },
    },

    otherVendorsColumn: {
      id: "otherVendorsColumn",
      header: "Other Vendors",
      cell: OtherVendorsColumn,
      minSize: 200,
      meta: { ...defaultColumnConfig, enableSorting: false },
    },

    roleColumn: {
      id: "roleColumn",
      header: "Role",
      cell: RoleColumn,
      meta: { ...defaultColumnConfig, enableSorting: false },
    },
  };
};

export const useCustomerExpertColumnDefinitions = (
  { currentUser, allProjects, interactions },
  hasCommentBadge,
  props
) => {
  const { threadHeaders } = useMessageThreadContext();
  const baseColumns = {
    ...useBuildColumnDefinitions({ ...props, origin: HitOrigin.customerView }),
    ...useColumnDefinitionsCustomerView(props),
  };

  const showComments =
    (!currentUser || allProjects.find(({ token }) => token === props.project.token)) &&
    hasCommentBadge &&
    props.project.active;

  const removeColumns = ["companyColumn", "positionColumn", "geographicKnowledgeColumn", "tenureColumn", "labelColumn"];

  return filterColumnDefinitions({
    columns: baseColumns,
    showComments,
    isCustomerKnowledgeEnabled: true,
    threadHeaders,
    interactions,
    ...props,
  }).filter(({ id }) => !removeColumns.includes(id));
};
