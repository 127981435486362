import React, { FC, useState } from "react";
import { Typography, useThemeTokens, Icon } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const relevantExperienceLabel = "Relevant Experience";

type RelevantExperienceProps = {
  bio: string;
  textColor?: string;
};

const DataTestIds = {
  RelevantExperienceList: "relevant-experience-bullet-list",
  RelevantExperienceMobileSection: "relevant-experience-mobile-section",
  RelevantExperienceSection: "relevant-experience-section",
  RelevantExperienceBrowserSection: "relevant-experience-browser-section",
};

const BulletPoints = ({ items, textColor }: { items: string[]; textColor?: string }) => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.ul
      listStyleType="disc"
      marginLeft={inner.base05}
      color={textColor}
      data-testid={DataTestIds.RelevantExperienceList}
    >
      {items.map((item) => {
        if (!Boolean(item)) return null;

        return (
          <x.li key={item}>
            <Typography color={textColor} variant={isMobile ? "body" : "body-small"}>
              {item}
            </Typography>
          </x.li>
        );
      })}
    </x.ul>
  );
};

const MobileRelevantExperience = ({ bioElements, textColor }: { bioElements: string[]; textColor?: string }) => {
  const {
    spacing: { inner },
    color: {
      background: { decorative },
    },
  } = useThemeTokens();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleOnClickDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsDetailsOpen((prevState) => !prevState);
  };

  return (
    <x.div
      display="flex"
      flexDirection="column"
      gap={inner.base02}
      data-testid={DataTestIds.RelevantExperienceMobileSection}
    >
      <x.div display="flex" alignItems="center" gap={inner.base02} onClick={(e) => handleOnClickDetails(e)}>
        <Typography color={textColor || decorative.base02} variant="body-em">
          {relevantExperienceLabel}
        </Typography>
        <Icon>{isDetailsOpen ? <ChevronUp /> : <ChevronDown />}</Icon>
      </x.div>
      {isDetailsOpen && <BulletPoints items={bioElements} textColor={textColor} />}
    </x.div>
  );
};

const RelevantExperience: FC<RelevantExperienceProps> = ({ bio, textColor }) => {
  const bioElements = bio?.split("- ");
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
    color: {
      background: {
        action: { secondary },
      },
    },
  } = useThemeTokens();

  const fontColor = textColor ?? secondary.hover;

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base03} data-testid={DataTestIds.RelevantExperienceSection}>
      {isMobile ? (
        <MobileRelevantExperience bioElements={bioElements} textColor={textColor} />
      ) : (
        <x.div
          display="flex"
          flexDirection="column"
          gap={inner.base02}
          data-testid={DataTestIds.RelevantExperienceBrowserSection}
        >
          <Typography color={fontColor} variant={"body-small-em"}>
            {relevantExperienceLabel}
          </Typography>
          <BulletPoints items={bioElements} textColor={fontColor} />
        </x.div>
      )}
    </x.div>
  );
};

export { RelevantExperience as default, DataTestIds };
