import React, { useState, useRef } from "react";
import { identity, max, uniq } from "lodash";
import { x } from "@xstyled/styled-components";
import {
  Typography,
  Button,
  Link,
  IconOnlyButton,
  Popover,
  Icon,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  Option,
} from "@alphasights/alphadesign-components";
import {
  BookmarkSelected,
  BookmarkUnselected,
  MoreVert,
  SummarizedTranscript,
  Download,
  InsertLink,
  HumanTranscript,
  AiTranscript,
  ChevronUp,
  ChevronDown,
} from "@alphasights/alphadesign-icons";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useAudioPlayer } from "hooks/useAudioPlayer";
import { AudioPlayer } from "./AudioPlayer";
import useStyles from "./styles";
import { useDeliverableContext } from "../../providers/DeliverableProvider";
import { useCallbackContext } from "providers/CallbackProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { FollowUpButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { enrichInteraction } from "pages/InteractionPage";
import { FLYOUT_SECTIONS } from "../../providers/FlyoutProvider";
import { MAIN_CONTENT_VIEWS, TooltipedButton } from "./MainContentViews";
import { SearchBar } from "./SearchBar";
import { trySingularAngleName } from "./helpers";
import { ENABLE_AI_SUMMARIZATION, ProjectBadged } from "providers/BadgeProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { ExpandSidebarButton } from "./ExpandSidebarButton/ExpandSidebarButton";
import { RecordingToggler } from "./RecordingToggler";

const TopBar = ({
  interaction,
  togglePlayer,
  showingPlayer,
  currentRecording,
  setCurrentRecording,
  currentTranscript,
  setCurrentTranscript,
  canPlayAudio,
  availableTranscripts,
  availableRecordings,
  isFlyoutOpen,
  viewMode,
  setViewMode,
  contentSearchTerms,
  selectedMentionIndex,
  totalMentionsMatches,
  onContentSearchQueryChange,
  onFindPreviousMatch,
  onFindNextMatch,
  onTranscriptToggling,
  isTranscriptLoading,
  selectedTranscriptView,
  allowSummaryDownload,
  controlsProps,
  progressBarProps,
}) => {
  const styles = useStyles();

  return (
    <x.div data-testid="deliverables-topbar" {...styles.topBar}>
      <InteractionHeader
        interaction={interaction}
        currentRecording={currentRecording}
        isFlyoutOpen={isFlyoutOpen}
        allowSummaryDownload={allowSummaryDownload}
      />
      {currentTranscript && (
        <TranscriptControl
          viewMode={viewMode}
          setViewMode={setViewMode}
          viewing={currentTranscript}
          togglePlayer={togglePlayer}
          showingPlayer={showingPlayer}
          availableTranscripts={availableTranscripts}
          currentTranscript={currentTranscript}
          setCurrentTranscript={setCurrentTranscript}
          availableRecordings={availableRecordings}
          currentRecording={currentRecording}
          setCurrentRecording={setCurrentRecording}
          contentSearchTerms={contentSearchTerms}
          selectedMentionIndex={selectedMentionIndex}
          totalMentionsMatches={totalMentionsMatches}
          onContentSearchQueryChange={onContentSearchQueryChange}
          onFindPreviousMatch={onFindPreviousMatch}
          onFindNextMatch={onFindNextMatch}
          onTranscriptToggling={onTranscriptToggling}
          isTranscriptLoading={isTranscriptLoading}
          selectedTranscriptView={selectedTranscriptView}
          controlsProps={controlsProps}
          progressBarProps={progressBarProps}
        />
      )}
      {!currentTranscript && canPlayAudio && currentRecording && (
        <RecordingOnlyControl
          currentRecording={currentRecording}
          availableRecordings={availableRecordings}
          setCurrentRecording={setCurrentRecording}
        />
      )}
    </x.div>
  );
};

const RecordingOnlyControl = ({ currentRecording, availableRecordings, setCurrentRecording }) => {
  const styles = useStyles();
  const { controlsProps, progressBarProps, renderElements } = useAudioPlayer({
    audioUrl: currentRecording.url,
  });

  return (
    <x.div {...styles.topBarAudioPlayer}>
      {renderElements}
      <AudioPlayer controlsProps={controlsProps} progressBarProps={progressBarProps} />
      {availableRecordings.length > 1 && (
        <RecordingToggler
          showingPlayer
          currentRecording={currentRecording}
          setCurrentRecording={setCurrentRecording}
          availableRecordings={availableRecordings}
        />
      )}
    </x.div>
  );
};

const TranscriptControl = ({
  viewMode,
  setViewMode,
  togglePlayer,
  showingPlayer,
  availableTranscripts,
  currentRecording,
  setCurrentRecording,
  currentTranscript,
  setCurrentTranscript,
  availableRecordings,
  contentSearchTerms,
  onContentSearchQueryChange,
  selectedMentionIndex,
  onFindPreviousMatch,
  onFindNextMatch,
  onTranscriptToggling,
  totalMentionsMatches,
  isTranscriptLoading,
  selectedTranscriptView,
  controlsProps,
  progressBarProps,
}) => {
  const showMentionHits = totalMentionsMatches > 0 || (!isTranscriptLoading && contentSearchTerms.length > 0);
  const styles = useStyles({ hasTranscriptSearch: showMentionHits });

  return (
    <x.div {...styles.topBarSearchRow}>
      <ProjectBadged badge={ENABLE_AI_SUMMARIZATION}>
        <TranscriptToggler
          availableTranscripts={availableTranscripts}
          currentTranscript={currentTranscript}
          setCurrentTranscript={setCurrentTranscript}
          onTranscriptToggling={onTranscriptToggling}
        />
      </ProjectBadged>
      <x.div {...styles.topBarSearch}>
        <SearchBar
          placeholder="Search within the transcript..."
          onTextSearch={onContentSearchQueryChange}
          initialValue={contentSearchTerms}
        />
      </x.div>
      {showMentionHits && (
        <>
          {totalMentionsMatches > 0 ? (
            <>
              <x.div data-testid="deliverables-main-content-result" {...styles.topBarSearchResultsRow}>
                <x.div {...styles.topBarSearchResultsActions}>
                  <Icon
                    data-testid="previous-match"
                    size="medium"
                    onClick={onFindPreviousMatch}
                    {...styles.mentionsTotal}
                  >
                    <ChevronUp />
                  </Icon>
                  <Icon data-testid="next-match" size="medium" onClick={onFindNextMatch} {...styles.mentionsTotal}>
                    <ChevronDown />
                  </Icon>
                </x.div>
                <x.div
                  {...styles.mentionsRow}
                  minWidth={`${totalMentionsMatches} / ${totalMentionsMatches} `.length * 6 + "px"}
                >
                  <Typography variant="body-em" {...styles.mentionsCursor}>
                    {selectedMentionIndex + 1}
                  </Typography>
                  &nbsp;
                  <Typography variant="body-em" {...styles.mentionsTotal}>
                    / {totalMentionsMatches}
                  </Typography>
                </x.div>
              </x.div>
            </>
          ) : (
            <x.div data-testid="deliverables-main-content-no-result" {...styles.topBarSearchResultsRow}>
              <Typography variant="body-em" {...styles.mentionsCursor}>
                No Results
              </Typography>
            </x.div>
          )}
        </>
      )}

      <x.div {...styles.flexBreak} />

      {currentTranscript?.transcriptType !== "summary" && selectedTranscriptView === "transcript" && showMentionHits && (
        <ToggleButtonGroup
          value={viewMode}
          data-testid="deliverable-view-toggle"
          wrapperProps={styles.topBarSearchMentionsToggleWrapper}
          allowUnselection={false}
        >
          <ToggleButton
            data-testid="deliverables-main-content-toggle-mentions-view"
            onClick={() => setViewMode(MAIN_CONTENT_VIEWS.Mentions)}
            value={MAIN_CONTENT_VIEWS.Mentions}
          >
            Mentions
          </ToggleButton>
          <ToggleButton
            data-testid="deliverables-main-content-toggle-transcript-view"
            onClick={() => setViewMode(MAIN_CONTENT_VIEWS.Transcript)}
            value={MAIN_CONTENT_VIEWS.Transcript}
          >
            Transcript
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <x.div {...styles.topBarToggleActions}>
        {currentRecording?.visibleToClient && (
          <RecordingToggler
            togglePlayer={togglePlayer}
            showingPlayer={showingPlayer}
            currentRecording={currentRecording}
            setCurrentRecording={setCurrentRecording}
            availableRecordings={availableRecordings}
            selectedTranscriptView={selectedTranscriptView}
            controlsProps={controlsProps}
            progressBarProps={progressBarProps}
          />
        )}
        <ProjectBadged not badge={ENABLE_AI_SUMMARIZATION}>
          <TranscriptToggler
            availableTranscripts={availableTranscripts}
            currentTranscript={currentTranscript}
            setCurrentTranscript={setCurrentTranscript}
            onTranscriptToggling={onTranscriptToggling}
          />
        </ProjectBadged>
      </x.div>
    </x.div>
  );
};

const TranscriptToggler = ({ availableTranscripts, currentTranscript, setCurrentTranscript, onTranscriptToggling }) => {
  const styles = useStyles();

  const props = {
    summary: {
      icon: <SummarizedTranscript />,
      tooltip: "Synthesized Transcript",
      minWidth: 200,
    },
    regular: {
      icon: <HumanTranscript />,
      tooltip: "Human Transcript",
      minWidth: 170,
    },
    ai: {
      icon: <AiTranscript />,
      tooltip: "AI Transcript",
      minWidth: null,
    },
  };

  const onTranscriptChanged = (transcriptRequest) => {
    currentTranscript !== transcriptRequest && onTranscriptToggling && onTranscriptToggling();
    setCurrentTranscript(transcriptRequest);
  };

  const onSelectValueChanged = (transcriptType) => {
    onTranscriptChanged(availableTranscripts.find((tr) => tr.transcriptType === transcriptType));
  };

  if (availableTranscripts.length <= 1) return null;

  const transcriptTypes = availableTranscripts.map((tr) => tr.transcriptType);
  const minWidth = max(
    Object.entries(props)
      .filter(([key]) => transcriptTypes.indexOf(key) >= 0)
      .map(([, { minWidth }]) => minWidth)
  );

  return (
    <ProjectBadged
      badge={ENABLE_AI_SUMMARIZATION}
      fallback={
        <x.div data-testid="transcript-toggle" {...styles.topBarRow}>
          {availableTranscripts.map((transcriptRequest) => {
            const { icon, tooltip } = props[transcriptRequest.transcriptType];

            return (
              <TooltipedButton
                key={transcriptRequest.transcriptType}
                tooltip={tooltip}
                icon={icon}
                active={currentTranscript === transcriptRequest}
                data-testid={`toggle-transcript-${transcriptRequest.transcriptType}`}
                onClick={() => onTranscriptChanged(transcriptRequest)}
              />
            );
          })}
        </x.div>
      }
    >
      <Select
        value={currentTranscript.transcriptType}
        onChange={onSelectValueChanged}
        minWidth={minWidth}
        data-testid="transcript-toggle-select"
        {...styles.transcriptSelect}
      >
        {availableTranscripts.map((tr) => {
          return (
            <Option key={tr.transcriptType} value={tr.transcriptType}>
              {props[tr.transcriptType].tooltip}
            </Option>
          );
        })}
      </Select>
    </ProjectBadged>
  );
};

const InteractionHeader = ({ interaction, currentRecording, isFlyoutOpen, allowSummaryDownload }) => {
  const { toggleInteractionBookmark } = useDeliverableContext();
  const {
    callbacks: { onSelectCard },
  } = useCallbackContext();
  const styles = useStyles();

  const tooltipProps = interaction.bookmarked
    ? {
        "data-testid": "selected-deliverables-topbar-bookmark",
        tooltip: "Remove Bookmark",
        icon: <BookmarkSelected />,
      }
    : {
        "data-testid": "unselected-deliverables-topbar-bookmark",
        tooltip: "Bookmark",
        icon: <BookmarkUnselected />,
      };

  return (
    <x.div>
      <x.div {...styles.topBarTitleRow}>
        <ExpandSidebarButton />
        <x.div flexGrow={1}>
          <Typography component="span" variant="body-large-em">
            {interaction.companyName}
          </Typography>
          <Typography component="span" px={2}>
            -
          </Typography>
          <Typography component="span" variant="body-large">
            {interaction.role}
          </Typography>
        </x.div>
        <x.div {...styles.topBarTitleActionsWrapper}>
          <TooltipedButton {...tooltipProps} padding={0} onClick={() => toggleInteractionBookmark(interaction)} />
          <MoreOptionsButton
            interaction={interaction}
            currentRecording={currentRecording}
            allowSummaryDownload={allowSummaryDownload}
          />
          <MainButton interaction={interaction} isFlyoutOpen={isFlyoutOpen} />
        </x.div>
      </x.div>
      <x.div>
        <Typography component="span" variant="body" color="secondary">
          {trySingularAngleName(interaction)}
        </Typography>
        <Typography component="span" variant="body" color="secondary" px={2}>
          |
        </Typography>
        <Typography component="span" variant="body" color="secondary">
          {interaction.advisorName}
        </Typography>
        <Typography component="span" variant="body" color="secondary" px={2}>
          |
        </Typography>
        <Typography component="span" variant="body" color="secondary" pr={2}>
          <FormattedDateTime date={interaction.scheduledCallTime} format="d LLL yyyy" />
        </Typography>
        <Link
          pl={4}
          href="#"
          size="small"
          onClick={() =>
            onSelectCard({
              ...interaction,
              flyoutAction: FLYOUT_SECTIONS.expandExpert,
            })
          }
        >
          View expert profile
        </Link>
      </x.div>
    </x.div>
  );
};

const MainButton = ({ interaction, isFlyoutOpen }) => {
  const { project } = useDeliverableContext();
  const {
    callbacks: { onSelectCard, onRequestFollowUp },
  } = useCallbackContext();

  const transcriptTypes = uniq(
    interaction.recordings.flatMap((r) =>
      r.transcriptRequests.filter((tr) => tr.completed).map((tr) => tr.transcriptType)
    )
  );

  const hasHumanUpgrade = project.transcriptEnabled || project.summaryEnabled;

  const hasOnlyAi = transcriptTypes.length === 1 && transcriptTypes[0] === "ai";

  const upgradable = hasOnlyAi || transcriptTypes.length === 0;

  if (hasHumanUpgrade && upgradable)
    return (
      <Button
        variant="primary"
        size="small"
        onClick={() =>
          onSelectCard({
            ...interaction,
            flyoutAction: FLYOUT_SECTIONS.expandUpgrades,
          })
        }
        data-testid="upgrade-transcript"
      >
        Upgrade
      </Button>
    );

  const hasClientPortalMessages = project.enablePortalMessages;
  const enriched = enrichInteraction({ interaction, hasClientPortalMessages });
  const showFollowupButton = isFlyoutOpen ? !enriched.showFollowUp : true;

  return (
    <>
      {showFollowupButton && (
        <FollowUpButton
          interaction={enriched}
          hasClientPortalMessages={hasClientPortalMessages}
          token={project.token}
          onRequestFollowUp={() =>
            onRequestFollowUp({
              id: interaction.id,
              origin: HitOrigin.deliverablesView,
            })
          }
          singleButton={interaction.isSurvey}
        />
      )}
    </>
  );
};

const MoreOptionsButton = ({ interaction, currentRecording, allowSummaryDownload }) => {
  const styles = useStyles();
  const [moreOpen, setMoreOpen] = useState(false);
  const popoverAnchor = useRef();
  return (
    <>
      <IconOnlyButton
        data-testid="deliverables-topbar-more-options"
        ref={popoverAnchor}
        onClick={() => setMoreOpen((a) => !a)}
        variant="ghost"
        {...styles.topBarButton}
      >
        <MoreVert />
      </IconOnlyButton>
      {popoverAnchor.current && (
        <Popover
          placement="left-start"
          anchorEl={popoverAnchor.current}
          open={moreOpen}
          data-testid="more-options-popover"
          p={0}
          onClose={() => setMoreOpen(false)}
        >
          <x.div {...styles.topBarPopover}>
            <MoreOptionsDropdown
              interaction={interaction}
              currentRecording={currentRecording}
              onClose={() => setMoreOpen(false)}
              allowSummaryDownload={allowSummaryDownload}
            />
          </x.div>
        </Popover>
      )}
    </>
  );
};

export const transcriptFileDownload = ({ id, token, transcriptType }, interaction, number) => {
  const formatedCallTime = interaction.scheduledCallTime ? ` - ${interaction.scheduledCallTime.slice(0, 10)}` : "";

  const transcriptFileName = `${interaction.projectTitle} - ${interaction.advisorName} ${formatedCallTime}${
    number ? " - " + number : ""
  }.docx`.replace(/[[\],]/g, "");

  return `/api/projects/${interaction.projectToken}/transcripts/${token}?filename=${encodeURIComponent(
    transcriptFileName
  )}`;
};

const DownloadTranscriptButton = ({ label, url, type, id, target, interaction, suffix }) => {
  const styles = useStyles();

  const { logHit } = useTrackUserAction();
  const currentUser = useCurrentUser();
  const blockDownload = !!currentUser?.internalUser;
  const startDownload = () => {
    if (blockDownload) return;
    window.open(url, target ?? "_self");
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.downloadTranscript,
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
      details: { transcriptType: type },
      references: { transcriptId: id },
    });
  };

  return (
    <Button
      variant="ghost"
      startIcon={<Download {...styles.iconSecondary} />}
      data-testid={`deliverables-topbar-more-options-download-${suffix || 0}`}
      {...styles.topBarMoreOptionsButton}
      {...(blockDownload && { cursor: { hover: "not-allowed" } })}
      onClick={startDownload}
    >
      {label}
    </Button>
  );
};

const MoreOptionsDropdown = ({ interaction, currentRecording, allowSummaryDownload }) => {
  const styles = useStyles();
  const [shareText, setShareText] = useState("Share");
  const { logHit } = useTrackUserAction();

  const transcriptTypeLabel = {
    regular: "Human Transcript",
    summary: "Synthesized Transcript",
    ai: "AI Transcript",
    recording: "Recording",
  };

  const downloadables = [
    ...currentRecording.transcriptRequests
      .filter(({ purgedAt, completed, transcriptType }) => completed && !purgedAt && transcriptType !== "ai")
      .map((tr, ix) => ({
        label: transcriptTypeLabel[tr.transcriptType],
        type: tr.transcriptType,
        id: tr.id,
        url: transcriptFileDownload(tr, interaction, ix),
        target: "_blank",
      })),
    allowSummaryDownload && {
      label: "Call Summary",
      type: "ai-summary",
      id: null,
      url: `/api/projects/${interaction.projectToken}/ai-summaries/${interaction.id}/${currentRecording.id}/export/pdf`,
      target: "_blank",
    },
  ].filter(identity);

  const onShare = () => {
    navigator.clipboard.writeText(window.location.toString());
    setShareText("Link Copied!");

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptShared,
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
    });
  };

  return (
    <x.div {...styles.topBarMoreOptionsDropdown}>
      {downloadables.length > 0 && (
        <>
          <Typography variant="body-small-em" color="secondary" {...styles.topBarMoreOptionsDownloadLabel}>
            Download
          </Typography>
          {downloadables.map((downloadable, index, all) => {
            const number = all.length > 1 ? index + 1 : null;
            const id = `deliverables-topbar-more-options-download-${index + 1}`;
            return <DownloadTranscriptButton key={id} interaction={interaction} suffix={number} {...downloadable} />;
          })}
          <x.div {...styles.topBarMoreOptionsSeparator} />
        </>
      )}
      <Button
        variant="ghost"
        startIcon={<InsertLink {...styles.iconSecondary} />}
        onClick={onShare}
        data-testid="deliverables-more-options-share"
        {...styles.topBarMoreOptionsButton}
      >
        {shareText}
      </Button>
    </x.div>
  );
};

export { TopBar };
