import styled from "styled-components";
import { Accordion, useThemeTokens } from "@alphasights/alphadesign-components";

const StyledAccordion = styled(Accordion)(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    paddingInline: spacing.inner.base06,
    backgroundColor: color.background.surface.page.default,
    borderLeft: `${color.border.divider} ${shape.border.width.sm} solid`,
    li: {
      borderWidth: `0 !important`,
    },
  };
});

const AccordionItemContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base06,
  };
});

const OurResearchSectionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base04,
  };
});

const ResearchContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    gap: spacing.inner.base03,
    cursor: "pointer",
  };
});

const ResearchTitleContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    gap: spacing.inner.base02,
    alignItems: "center",
  };
});

const ResearchDescriptionContainer = styled.div`
  width: 80%;
`;

const HeaderContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base02,
    alignItems: "center",
  };
});

const CommissionPrimerContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  const borderStyling = `${color.border.divider} ${shape.border.width.sm} solid`;

  return {
    backgroundColor: color.background.surface.page.default,
    paddingInline: spacing.inner.base06,
    paddingBottom: spacing.inner.base06,
    borderLeft: borderStyling,
    borderBottom: borderStyling,
    button: {
      width: "100%",
    },
  };
});

export {
  HeaderContainer,
  ResearchDescriptionContainer,
  ResearchContainer,
  OurResearchSectionContainer,
  StyledAccordion,
  ResearchTitleContainer,
  AccordionItemContainer,
  CommissionPrimerContainer,
};
