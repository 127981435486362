import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Tabs, TabItem } from "@alphasights/alphadesign-components";
import { useMarketPrimersStore } from "../../../state/store";
import { ConditionalTooltip, DotLabel } from "pages/AlphaNowPage/primers/components";
import CompetitorDynamicsTabContent from "./CompetitorDynamicsTabContent";
import CompetitorDynamicsOtherNotableCompetitors from "./CompetitorDynamicsOtherNotableCompetitors";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

interface CompetitorDynamicsTabsProps {
  chartColors: string[];
  selectedChartSegment: number | null;
  onClick: (index: number) => void;
}

const CompetitorDynamicsTabs = ({ chartColors, selectedChartSegment, onClick }: CompetitorDynamicsTabsProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { overview, competitorDynamics } = useMarketPrimersStore();
  const { isAccessible } = usePrimersStore();
  const leaders = competitorDynamics.filter((competitor) => !competitor.otherNotableCompetitor);
  const names = [...leaders.map((entry) => entry.companyName), "Other notable competitors"];

  const [selectedTab, setSelectedTab] = useState<number | null>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment);
  }, [selectedChartSegment]);

  return (
    <x.div py={inner.base04}>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <Tabs
          data-testid="marketPrimerCompetitorTabs"
          colorVariant="primary"
          index={selectedTab || 0}
          onChange={(index) => {
            handleTabChange(index);
            onClick(index);
          }}
        >
          {names.map((name, index) => {
            const [companyName, tooltip] = getTruncatedTextAndTooltip(name, 30);

            return (
              <TabItem
                key={name}
                label={
                  <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                    <DotLabel dotColor={chartColors[index]} label={(companyName as string) ?? ""} />
                  </ConditionalTooltip>
                }
              />
            );
          })}
        </Tabs>
      </SensitiveDataContainer>
      {competitorDynamics &&
        (selectedTab === leaders.length ? (
          <SensitiveDataContainer isSensitive={!isAccessible}>
            <CompetitorDynamicsOtherNotableCompetitors
              segments={competitorDynamics.filter(({ otherNotableCompetitor }) => otherNotableCompetitor)}
            />
          </SensitiveDataContainer>
        ) : (
          <x.div display="flex" gap="20px" flexDirection="column" pt="6px">
            <CompetitorDynamicsTabContent currency={overview.currency} segment={competitorDynamics[selectedTab ?? 0]} />
          </x.div>
        ))}
    </x.div>
  );
};

export default CompetitorDynamicsTabs;
