import { useCurrentUser } from "@alphasights/portal-auth-react";
import React from "react";

const CID_FORBIDDEN_EMAILS = ["nick.smith+alphademo@alphasights.com"];

export const useUserCidEnabled = () => {
  const currentUser = useCurrentUser();

  const isCidEnabled = React.useMemo(
    () =>
      !!(
        currentUser &&
        !CID_FORBIDDEN_EMAILS.includes(currentUser.email) &&
        (currentUser.internalUser || currentUser.cidEnabled)
      ),
    [currentUser]
  );

  return isCidEnabled;
};
