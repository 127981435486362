import React from "react";
import { TitleCell, RelevantCompanyCell, ContentTypeCell, PitchedAtCell, PublicationDateCell } from "./cells";
import { useRecommendedContentTableStyles } from "./RecommendedContentTable.styles";
import { Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { ColumnDef } from "./types";
import { getCompaniesText } from "./helpers";
import { RESEARCH_TYPE_DESCRIPTION } from "./text";

const ContentTypeColumnDescription = () => {
  const { contentTypeColumnDescription } = useRecommendedContentTableStyles();

  return (
    <x.div {...contentTypeColumnDescription}>
      {Object.entries(RESEARCH_TYPE_DESCRIPTION).map(([key, value]) => (
        <React.Fragment key={key}>
          <Typography variant="body-small-em" color="secondary">
            {key}
          </Typography>
          <Typography>{value}</Typography>
        </React.Fragment>
      ))}
    </x.div>
  );
};

const TABLE_COLUMN_IDS = {
  title: "title",
  relevantCompany: "relevantCompany",
  contentType: "contentType",
  publicationDate: "publicationDate",
  markAsNotRelevant: "markAsNotRelevant",
  pitched: "pitched",
};

const TABLE_HEADERS = {
  [TABLE_COLUMN_IDS.title]: "Title",
  [TABLE_COLUMN_IDS.relevantCompany]: "Relevant Company",
  [TABLE_COLUMN_IDS.contentType]: "Research Type",
  [TABLE_COLUMN_IDS.publicationDate]: "Publication Date",
  [TABLE_COLUMN_IDS.pitched]: "Proposed by",
};

const TitleColumn: ColumnDef = {
  id: TABLE_COLUMN_IDS.title,
  accessorKey: "externalTitle",
  cell: TitleCell,
  percentageSize: "20%",
};

const RelevantCompanyColumn: ColumnDef = {
  id: TABLE_COLUMN_IDS.relevantCompany,
  accessorFn: (row: ContentResults) => getCompaniesText(row.companies),
  cell: RelevantCompanyCell,
  percentageSize: "20%",
};

const ContentTypeColumn: ColumnDef = {
  id: TABLE_COLUMN_IDS.contentType,
  accessorKey: "contentType",
  cell: ContentTypeCell,
  percentageSize: "20%",
  meta: {
    columnDescription: <ContentTypeColumnDescription />,
    columnDescriptionPopoverProps: {
      maxW: "520px",
      placement: "bottom-start",
    },
  },
};

const PublicationDateColumn: ColumnDef = {
  id: TABLE_COLUMN_IDS.publicationDate,
  accessorKey: "scheduledTime",
  cell: PublicationDateCell,
  percentageSize: "20%",
};

const PitchedColumn: ColumnDef = {
  id: TABLE_COLUMN_IDS.pitched,
  accessorKey: "pitchedAt",
  cell: PitchedAtCell,
  percentageSize: "14%",
  sortingFn: ({ original: { pitchedAt: pitchedAtA } }, { original: { pitchedAt: pitchedAtB } }) => {
    // Check if either value is undefined
    if (pitchedAtA === undefined && pitchedAtB === undefined) {
      return 0; // Both are undefined, so considered equal
    } else if (pitchedAtA === undefined) {
      return 1; // undefined should come after defined values
    } else if (pitchedAtB === undefined) {
      return -1; // defined should come before undefined
    }

    // Normal comparison if neither value is undefined
    if (pitchedAtA < pitchedAtB) {
      return -1;
    } else if (pitchedAtA > pitchedAtB) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const buildColumns = () => {
  const columns = [TitleColumn, RelevantCompanyColumn, ContentTypeColumn, PublicationDateColumn, PitchedColumn];

  return columns.map((column) => ({
    header: TABLE_HEADERS[column.id as keyof typeof TABLE_HEADERS],
    ...column,
  }));
};
