import React from "react";
import { Modal } from "../Modal";

export const MoreDialInNumbersModal = ({ ...props }) => {
  return (
    <Modal {...props} title="More Dial-In Numbers" className="aui-w-auto md:aui-max-w-1/2 aui-text-grey-5">
      <div
        className="aui-flex aui-space-x-2 aui-p-6 aui-overflow-y-scroll aui-overscroll-auto"
        style={{ maxHeight: "80vh" }}
      >
        <div className="aui-block md:aui-flex-col aui-space-y-3 md:aui-space-y-0">
          {props.dialInNumbers &&
            props.dialInNumbers.map((dialIn, idx) => (
              <div key={`dialInNumbers-${idx}`} className="aui-w-full md:aui-flex md:aui-space-x-3">
                <div key={dialIn.country} className="aui-flex-grow">
                  <b className="aui-inline-block">{dialIn.country}</b>
                </div>
                <div key={`number-${idx}`} className="aui-right">
                  <a className="aui-text-primary-1 aui-no-underline" href={`tel:${dialIn.number}`}>
                    {dialIn.number}
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};
