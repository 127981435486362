import React from "react";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { useCompanyPrimerV3Store } from "../../../store/store";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const ServicesAndProducts = () => {
  const {
    color: { text },
    spacing: { inner },
    font,
  } = useThemeTokens();

  const {
    companyOverview: { servicesAndProducts, useCases },
  } = useCompanyPrimerV3Store(({ overview }) => overview);

  return (
    <x.div display="flex" flexDirection="column" px={inner.base10}>
      <Typography variant="navigation" color={text.secondary} pb={inner.base08}>
        services and products
      </Typography>
      <x.div display="flex" flexDirection="column" gap={inner.base03} pb={inner.base08}>
        {servicesAndProducts.map((sap, index) => (
          <React.Fragment key={index}>
            <x.div
              w="100%"
              display="grid"
              columnGap={inner.base04}
              gridTemplateColumns={"205px auto"}
              alignItems="start"
            >
              <SensitiveDataContainer isSensitive={sap.title.isSensitive}>
                <Typography variant="body-em" color={text.strong._}>
                  <CitationTextWrapper key={index} value={sap.title} />
                </Typography>
              </SensitiveDataContainer>
              <CitationTextWrapper value={sap.description} />
            </x.div>
            {index < servicesAndProducts.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </x.div>
      {useCases.map((useCase, index) => (
        <x.div display="flex" flexDirection="column" pt={inner.base06} gap={inner.base02}>
          <x.div display="flex" gap={inner.base} alignItems="baseline">
            <PrimerFieldTitle text="example use case -" />
            <SensitiveDataContainer isSensitive={useCase.title.isSensitive}>
              <CitationTextWrapper
                value={useCase.title}
                style={{
                  color: text.secondary,
                  fontSize: font.size["03"],
                  textTransform: "uppercase",
                  fontWeight: "600",
                  lineHeight: font.lineHeight["02"],
                }}
              />
            </SensitiveDataContainer>
          </x.div>
          <CitationTextWrapper key={index} value={useCase.description} />
        </x.div>
      ))}
    </x.div>
  );
};

export default ServicesAndProducts;
