import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useBarGraphStyles = () => {
  const {
    spacing: { inner },
    color,
    shape,
  } = useThemeTokens();

  return {
    closedBarChartWrapper: {
      margin: inner.base04,
    },
    openBarChartWrapper: {
      p: inner.base06,
      minWidth: "328px",
    },
    groupedBarChartHeader: {
      display: "flex",
      mb: inner.base06,
      alignItems: "center",
      justifyContent: "space-between",
    },
    vendorGraphWrapper: {
      marginBottom: inner.base06,
    },
    vendorName: {
      marginBottom: inner.base03,
    },
    bar: {
      h: inner.base04,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      borderRadius: inner.base02,
      overflow: "hidden",
      opacity: 0.5,
      transition: "opacity 0.5s",
    },
    selectedBar: {
      opacity: 1,
    },
    filledBar: {
      h: inner.base04,
      transition: "flex-grow 1s",
    },
    infoHeader: {
      display: "flex",
      alignItems: "center",
    },
    infoPadding: {
      p: inner.base02,
    },
    infoLinesWrapper: {
      maxW: "400px",
      display: "flex",
      flexDirection: "column",
      gap: inner.base,
    },
    infoLine: {
      display: "flex",
      p: inner.base02,
      alignItems: "center",
    },
    infoLineIcon: {
      alignItems: "center",
      mr: inner.base02,
    },

    chartDetailsPopover: {
      display: "flex",
      flexDirection: "column",
      w: "240px",
      margin: `-${inner.base02}`,
    },
    chartDetailsPopoverTitle: {
      p: `${inner.base04} ${inner.base04} ${inner.base02} ${inner.base04}`,
      borderBottomWidth: shape.border.width.sm,
      borderBottomColor: color.border.divider,
    },
    chartDetailsPopoverContent: {
      p: inner.base02,
    },
    chartDetailsPopoverItem: {
      p: inner.base02,
      display: "flex",
      justifyContent: "space-between",
      gap: inner.base,
      alignItems: "center",
    },
  };
};
