import React from "react";
import { x } from "@xstyled/styled-components";
import { Accordion, Typography } from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { useHistory } from "react-router-dom";
import { FlyoutFooter } from "../ContentFlyoutFooter/ContentFlyoutFooter";
import { RelevantExperience } from "pages/InteractionPage/sections/RelevantExperience/RelevantExperience";
import { CloseButton, Details, Topbar } from "../ContentFlyout/ContentFlyout";
import { useContentFlyoutStyles } from "../ContentFlyout/ContentFlyout.styles";

export interface PrivateTranscriptFlyoutProps {
  interaction: Interaction;
  transcriptRequest: TranscriptRequest;
  onClose: () => void;
  fragmentIds?: string[];
}

export const PrivateTranscriptFlyout = ({
  interaction,
  transcriptRequest,
  onClose,
  fragmentIds,
}: PrivateTranscriptFlyoutProps) => {
  const { project } = useDeliverableContext();
  const { body, header, container, accordion } = useContentFlyoutStyles();
  const history = useHistory();
  const {
    location: { protocol, host },
  } = window;

  const angle = interaction.angles[0];
  const angleName = angle?.title;

  const onClick = () => {
    let url = `deliverables-view?selectedInteraction=${interaction.id}&transcriptType=${transcriptRequest.transcriptType}`;
    if (fragmentIds) {
      url += `&fragmentIds=${fragmentIds.join(",")}`;
    }
    history.push(url);
  };

  const Separator = () => (
    <Typography variant="body-small" color="secondary">
      |
    </Typography>
  );

  return (
    <x.div {...container}>
      <x.div {...header}>
        <CloseButton onClick={onClose} />
        <Topbar
          title={
            <>
              <Typography variant="body-large-em" component="span">
                {interaction.advisorCompany}
              </Typography>
              <Typography variant="body-large" component="span">
                {" "}
                {`- ${interaction.role}`}
              </Typography>
            </>
          }
          shareableUrl={`${protocol}//${host}/${project.token}/experts/deliverables-view/?selectedInteraction=${interaction.id}&transcriptType=${transcriptRequest.transcriptType}`}
        >
          <Details>
            <>
              {angleName && (
                <Typography variant="body" color="secondary" component="span">
                  {angleName}
                </Typography>
              )}
              {angleName && <Separator />}
              <Typography variant="body" color="secondary" component="span">
                {interaction.advisorName}
              </Typography>
              {transcriptRequest.visibleToClientUpdatedAt && <Separator />}
              {transcriptRequest.visibleToClientUpdatedAt && (
                <Typography variant="body-small" color="secondary" component="span">
                  <FormattedDateTime date={transcriptRequest.visibleToClientUpdatedAt} format="d MMM yyyy" />
                </Typography>
              )}
            </>
          </Details>
        </Topbar>
      </x.div>
      <x.div {...body}>
        <Accordion {...accordion}>
          <RelevantExperience
            title="Relevance Statement"
            relevanceStatement={interaction.relevanceStatement}
            expanded
            showBorderTop={false}
          />
        </Accordion>
      </x.div>
      <x.div data-testid="private-transcript-flyout-footer">
        <FlyoutFooter onClick={onClick} status="Available" />
      </x.div>
    </x.div>
  );
};
