import { pendingRequestLabels } from "../InteractionsPage/helpers/Interaction";

export const getStateColorFlyout = (state, colorTokens) =>
  ({
    proposed: colorTokens.text.secondary,
    requested: colorTokens.text.danger,
    completed: colorTokens.text.success,
    scheduled: colorTokens.text.processing,
  }[state]);

export const getSurveyStateColorFlyout = (state, colorTokens) =>
  ({
    available: colorTokens.text.secondary,
    requested: colorTokens.text.danger,
    sent: colorTokens.text.info,
    partially_completed: colorTokens.text.info,
    answered: colorTokens.text.processing,
    completed: colorTokens.text.success,
  }[state] || colorTokens.base.yellow[500]);

export const getStateColor = (state) =>
  ({
    proposed: "grey-5",
    requested: "primary-2",
    completed: "success-2",
    scheduled: "scheduled-purple",
  }[state]);

export const getStateName = ({
  state,
  previousAdvisorshipId,
  isExpertHidden,
  followUpId,
  showCalendarView,
  pendingRequest,
}) => {
  if (isExpertHidden) return "Hidden";

  if (showCalendarView) {
    if (previousAdvisorshipId) return appendPendingInformation(`Follow-up ${state}`, pendingRequest);
  } else {
    if (followUpId) return appendPendingInformation("Follow-up Requested", pendingRequest);
  }

  if (state === "proposed") return "Available";

  return appendPendingInformation(state, pendingRequest);
};

const appendPendingInformation = (state, pendingRequest) => {
  if (pendingRequest && pendingRequestLabels[pendingRequest.type]) {
    return `${state}, Pending ${pendingRequestLabels[pendingRequest.type]}`;
  }

  return state;
};
