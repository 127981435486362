import useStyles from "./styles";
import { x } from "@xstyled/styled-components";
import { Button, Icon, Skeleton } from "@alphasights/alphadesign-components";
import { Mention } from "./transcripts/Mention";
import React, { useEffect } from "react";
import { DeliverableTranscript } from "./DeliverableTranscript";
import { PortalTooltip } from "../../components/Tooltip/Tooltip";
import { isEmptyTranscriptRequests, selectIndexHighlight } from "./helpers";
import { DefaultView } from "components/DefaultView";
import { LANDING_PAGE } from "./text";

const mainContentTestId = "deliverables-main-content-message";
const TranscriptContentView = ({
  interaction,
  currentTranscript,
  canPlayAudio,
  transcriptProps,
  showingPlayer,
  isLoading,
  contentSearchTerms,
  sentences,
  recording,
  fragmentIds,
}) => {
  if (!currentTranscript) return null;

  return (
    <DeliverableTranscript
      interaction={interaction}
      canPlayAudio={canPlayAudio && showingPlayer}
      recording={recording}
      transcriptRequest={currentTranscript}
      transcriptProps={transcriptProps}
      contentSearchTerms={contentSearchTerms}
      sentences={sentences}
      isLoading={isLoading}
      fragmentIds={fragmentIds}
    />
  );
};

const MainContentTranscriptProcessing = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.processing.title}
    subtitle={LANDING_PAGE.processing.subtitle}
  />
);
const MainContentAudioOnly = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.noTranscript.title}
    subtitle={LANDING_PAGE.noTranscript.subtitle}
  />
);
const MainContentEmpty = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.selectContent.title}
    subtitle={LANDING_PAGE.selectContent.subtitle}
  />
);
const MainContentNoDeliverable = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.noContent.title}
    subtitle={LANDING_PAGE.noContent.subtitle}
  />
);

const MainContentMentions = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.mentionsView.title}
    subtitle={LANDING_PAGE.mentionsView.subtitle}
  />
);

const MainContentError = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.error.title}
    subtitle={LANDING_PAGE.error.subtitle}
  />
);

const MentionsContentView = ({ mentions, isLoading, contentSearchTerms, setViewMode, setSelectedMentionIndex }) => {
  const onTranscriptLinkClick = (transcriptHighlightIdx) => {
    setViewMode(MAIN_CONTENT_VIEWS.Transcript);
    setSelectedMentionIndex(transcriptHighlightIdx);
  };

  return (
    <>
      {contentSearchTerms.length === 0 ? (
        <MainContentMentions />
      ) : (
        <Mention sentences={mentions} isLoading={isLoading} onTranscriptLinkClick={onTranscriptLinkClick} />
      )}
    </>
  );
};
const ContentView = ({
  interaction,
  currentTranscript,
  canPlayAudio,
  transcriptProps,
  showingPlayer,
  onHidePlayer,
  contentSearchTerms,
  viewMode,
  setViewMode,
  selectedMentionIndex,
  setSelectedMentionIndex,
  totalMentionsMatches,
  isTranscriptLoading,
  sentences,
  mentions,
  recording,
  fragmentIds,
  obfuscated,
}) => {
  const styles = useStyles({ obfuscated });
  useEffect(
    function showSelectedHighlight() {
      if (!isTranscriptLoading) {
        selectIndexHighlight(selectedMentionIndex, totalMentionsMatches);
      }
    },
    [viewMode, totalMentionsMatches, selectedMentionIndex, isTranscriptLoading]
  );

  if (isEmptyTranscriptRequests(recording)) {
    return <MainContentAudioOnly />;
  }

  if (!currentTranscript) {
    return <MainContentTranscriptProcessing />;
  }

  if (isTranscriptLoading && !sentences?.length) {
    return (
      <x.div data-testid="deliverable-loading">
        <TranscriptSentenceSkeleton count={7} />
      </x.div>
    );
  }

  if (!isTranscriptLoading && contentSearchTerms.length > 0 && totalMentionsMatches === 0) return null;

  if (!sentences?.length) {
    return <MainContentError />;
  }

  return (
    <x.div {...styles.contentView} data-testid="transcript-content-view">
      {(currentTranscript?.transcriptType === "summary" || viewMode === MAIN_CONTENT_VIEWS.Transcript) && (
        <TranscriptContentView
          interaction={interaction}
          currentTranscript={currentTranscript}
          canPlayAudio={canPlayAudio}
          transcriptProps={transcriptProps}
          showingPlayer={showingPlayer}
          onHidePlayer={onHidePlayer}
          contentSearchTerms={contentSearchTerms}
          sentences={sentences}
          isLoading={isTranscriptLoading}
          recording={recording}
          fragmentIds={fragmentIds}
        />
      )}
      {currentTranscript?.transcriptType !== "summary" && viewMode === MAIN_CONTENT_VIEWS.Mentions && (
        <MentionsContentView
          mentions={mentions}
          isLoading={isTranscriptLoading}
          contentSearchTerms={contentSearchTerms}
          setViewMode={setViewMode}
          setSelectedMentionIndex={setSelectedMentionIndex}
        />
      )}
    </x.div>
  );
};
const TooltipedButton = ({
  tooltip,
  icon,
  active = null,
  variant = "ghost",
  style = null,
  children = null,
  ...props
}) => {
  const styles = useStyles();
  return (
    <PortalTooltip title={tooltip} position={"bottom"} dark>
      <Button variant={variant} {...(style || styles.topBarDeliverableButton)} {...props}>
        <x.div display="flex">
          <Icon active={active}>{icon}</Icon>
          {children ? children : null}
        </x.div>
      </Button>
    </PortalTooltip>
  );
};

export const MAIN_CONTENT_VIEWS = Object.freeze({
  Transcript: "transcript",
  Mentions: "mentions",
});

export {
  ContentView,
  TranscriptContentView,
  MainContentEmpty,
  MainContentNoDeliverable,
  TooltipedButton,
  MainContentTranscriptProcessing,
  MainContentAudioOnly,
};

export const TranscriptSentenceSkeleton = ({ count = 1 }) => {
  return (
    <>
      {[...Array(count)].map((el, idx) => (
        <x.div key={idx}>
          <x.div display="flex">
            <x.div>
              <Skeleton circle height="25px" />
            </x.div>
            <Skeleton width="25%" />
          </x.div>
          <Skeleton count={3} />
        </x.div>
      ))}
    </>
  );
};
