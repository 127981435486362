import React from "react";
import { x } from "@xstyled/styled-components";
import { useAlphaGPTConversationListStyles } from "./AlphaGPTConversarionList.styles";
import { AlphaGPTConversationListItem } from "../AlphaGPTConversationListItem";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";

export const AlphaGPTConversationList = () => {
  const { wrapper } = useAlphaGPTConversationListStyles();
  const { conversations, conversation: selectedConversation } = useAlphaGPTContext();
  return (
    <x.ul {...wrapper} data-testid="conversation-list">
      {conversations.map((conversation) => (
        <AlphaGPTConversationListItem
          key={conversation.id}
          selected={conversation.id === selectedConversation?.id}
          title={conversation.title}
          conversation={conversation}
        />
      ))}
    </x.ul>
  );
};

export const AlphaGPTConversationListSkeleton = () => {
  const { wrapper } = useAlphaGPTConversationListStyles();
  return (
    <x.div {...wrapper}>
      <NoXMarginSkeleton height="36px" />
      <NoXMarginSkeleton height="36px" />
      <NoXMarginSkeleton height="36px" />
      <NoXMarginSkeleton height="36px" />
      <NoXMarginSkeleton height="36px" />
      <NoXMarginSkeleton height="36px" />
    </x.div>
  );
};
