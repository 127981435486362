import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCidOneOffApprovalModalStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const errorAlert = {
    marginBottom: inner.base06,
  };

  const companiesDiv = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base03,
    marginTop: inner.base06,
  };

  const footer = {
    display: "flex",
    gap: inner.base04,
    marginTop: inner.base06,
  };

  const dialog = {
    zIndex: 51,
  };

  return {
    errorAlert,
    companiesDiv,
    footer,
    dialog,
  };
};
