import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { inner } = tokens.spacing;

export const Wrapper = styled.div(({ newNavigationEnabled }: { newNavigationEnabled?: boolean }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${newNavigationEnabled ? 0 : inner.base05};
    margin-bottom: ${newNavigationEnabled ? 0 : inner.base04};
    padding: ${newNavigationEnabled ? `${inner.base04} 0` : `0 ${inner.base08}`};
    gap: ${newNavigationEnabled ? inner.base02 : 0};
    ${newNavigationEnabled ? "overflow: auto;" : ""}
  `;
});

export const Group = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${inner.base06};
`;

export const GroupWithGap = styled(Group)`
  gap: ${inner.base02};
  padding-left: 0;
  padding-right: ${inner.base06};
`;

export const Spacing = styled.div`
  padding-right: ${inner.base02};
`;
