import {
  AlphaGptLibrary,
  Conversation,
  FindMessagesResponse,
  ProjectAlphaGptPurchase,
  ProjectAlphaGptServicePlan,
  FollowUpQuestionResponse,
} from "models/AlphaGpt";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";
import { fetch } from "../hooks/useApi";

export const alphaGPTService = {
  async listConversations(projectToken?: string, clientProfileIds?: number[]): Promise<Conversation[]> {
    const searchParams = new URLSearchParams();
    projectToken && searchParams.set("projectToken", projectToken);
    clientProfileIds && searchParams.set("clientProfileIds", clientProfileIds.join(","));

    return fetch({
      url: "/api/alphagpt/conversations?" + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async createConversation(
    title: string,
    projectToken?: string,
    libraries?: AlphaGptLibrary[]
  ): Promise<{ id: string }> {
    return fetch({
      url: "/api/alphagpt/conversations",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        title,
        projectToken,
        libraries,
      }),
    }).then((res) => res.json());
  },
  async sendMessage(conversationId: string, text: string): Promise<{ id: string }> {
    return fetch({
      url: `/api/alphagpt/conversations/${conversationId}/messages/`,
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        text,
      }),
    }).then((res) => res.json());
  },
  async findMessages(conversationId: string, afterMessageId?: string): Promise<FindMessagesResponse> {
    return fetch({
      url: `/api/alphagpt/conversations/${conversationId}/messages${afterMessageId ? `?after=${afterMessageId}` : ""}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async deleteConversation(conversationId: string): Promise<void> {
    return fetch({
      url: `/api/alphagpt/conversations/${conversationId}`,
      method: "DELETE",
      handleForbidden: true,
      skipAlert: true,
    }).then(() => Promise.resolve());
  },

  async getCurrentAlphaGPTPurchase(projectToken: string): Promise<ProjectAlphaGptPurchase> {
    return fetch({
      url: `/api/alphagpt/purchases/${projectToken}/current`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async getAlphaGPTServicePlan(projectToken: string): Promise<ProjectAlphaGptServicePlan> {
    return fetch({
      url: `/api/alphagpt/service-plan/${projectToken}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async getFollowUpQuestions(conversationId: string, messageId: string): Promise<FollowUpQuestionResponse[]> {
    return fetch({
      url: `/api/alphagpt/conversations/${conversationId}/messages/${messageId}/follow-up-questions`,
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async createAlphaGPTAccess(projectToken: string, caseCode?: string): Promise<void> {
    return fetch({
      url: `/api/alphagpt/purchases`,
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        projectToken,
        caseCode,
      }),
    }).then(() => Promise.resolve());
  },
  async searchContent(
    keywords: string[],
    pageSize?: number,
    contentTypes?: AlphaNowContentType[],
    projectToken?: string
  ): Promise<ContentResults[]> {
    const searchParams = new URLSearchParams();
    searchParams.set("keywords", keywords.join(","));
    pageSize && searchParams.set("pageSize", pageSize.toString());
    contentTypes && searchParams.set("contentTypes", contentTypes.join(","));
    projectToken && searchParams.set("projectToken", projectToken);

    return fetch({
      url: "/api/alphagpt/content/search?" + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async fetchFreePrimerIds(): Promise<string[]> {
    return fetch({
      url: "/api/alphagpt/content/free-primer-ids",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};
