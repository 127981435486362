import { Typography } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const EmphasisTypography = styled(Typography)`
  em {
    background-color: rgba(49, 129, 255, 0.2);
    font-style: inherit;
  }
  em.selected {
    background-color: rgba(49, 129, 255, 0.4);
    font-style: inherit;
  }
`;
