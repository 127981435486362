import React, { useState } from "react";
import { useApi } from "../../hooks/useApi";
import { PageFooter } from "@alphasights/client-portal-shared";
import { Close, Loading } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { Button, Typography } from "@alphasights/alphadesign-components";

interface DismissClientRequestProps {
  match: {
    params: {
      id: string;
      token: string;
    };
  };
}

export const DismissClientRequest = ({
  match: {
    params: { id, token },
  },
}: DismissClientRequestProps) => {
  const [handled, setHandled] = useState(false);

  const dismissRequest = async () => {
    const url = `/api/projects/${token}/interaction-requests/${id}`;
    await fetch(url, {
      method: "DELETE",
    });
    setHandled(true);
  };

  const [isLoading, clientRequest] = useApi(
    {
      url: `/api/projects/${token}/interaction-requests/${id}`,
      method: "GET",
    },
    [token]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <x.div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <x.div
        style={{
          maxWidth: "1024px",
          margin: "2rem auto",
          color: "rgb(102, 102, 102)",
          padding: "2rem",
        }}
      >
        <Typography variant="h1">
          {clientRequest?.userName || "Client"} wants to {clientRequest?.type?.toLowerCase() || "perform an action in"}{" "}
          this interaction.
        </Typography>
        <div>
          {clientRequest?.reason ? (
            <>
              <Typography variant="h2">Reason given:</Typography>
              <Typography variant="h2">{clientRequest.reason}</Typography>
            </>
          ) : (
            <Typography variant="h2" color="disabled">
              Client did not provide a justification
            </Typography>
          )}
        </div>
        {clientRequest?.processedAt && <div>This request was addressed at {clientRequest.processedAt}</div>}
        {!clientRequest?.processedAt && (
          <Button startIcon={<Close />} variant="secondary" onClick={dismissRequest} disabled={handled}>
            <span>{handled ? "Done!" : "Dismiss this request"}</span>
          </Button>
        )}
      </x.div>
      <PageFooter />
    </x.div>
  );
};
