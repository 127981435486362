import { useDeliverableContext } from "providers/DeliverableProvider";
import { useEffect } from "react";

type Args = {
  recommendationId?: string;
  contentId: string;
};

const useContentVisit = ({ recommendationId, contentId }: Args) => {
  const { createContentVisit } = useDeliverableContext();

  useEffect(() => {
    const triggerContentVisit = async () => {
      try {
        await createContentVisit({ recommendationId, contentId });
      } catch (error) {
        console.error(error);
      }
    };
    if (recommendationId) {
      triggerContentVisit();
    }
  }, [recommendationId]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useContentVisit;
