import _ from "lodash";
import { toSeconds } from "./adaptedTranscript";
import { toMinutesAndSeconds } from "helpers/displayHelpers";

const matchAfter = (orderedMatches, seconds, duration) => {
  const next = orderedMatches.find(({ start }) => {
    return toSeconds(start) > seconds;
  });

  if (next) return toSeconds(next.start);

  return duration;
};

const toTimestamp = (rawSeconds) => {
  return (
    toMinutesAndSeconds(rawSeconds) +
    "." +
    Math.floor((rawSeconds % 1) * 1000).toLocaleString("en-US", {
      minimumIntegerDigits: 3,
    })
  );
};

const basicMatch = (regularSentences, aiSentences) => {
  const orderedMatches = [];

  aiSentences.forEach((aiPart) => {
    const clearAi = aiPart.value.replace(/\W/g, " ");

    if (clearAi.split(" ").length < 4) return;

    regularSentences.forEach((regularPart) => {
      if (regularPart.hasHighlight) return;

      const clearRegular = regularPart.value.replace(/\W/g, " ");

      const substringIx = clearRegular.indexOf(clearAi);
      if (substringIx >= 0) {
        const matchCopy = { ...aiPart, valid: true };

        regularPart.matches.push(matchCopy);
        orderedMatches.push(matchCopy);
      }
    });
  });

  return orderedMatches;
};

const invalidateUnorderedMatches = (matches) => {
  let lastValidEnding = 0;

  matches.forEach((match, ix) => {
    if (ix === matches.length - 1) {
      return;
    }

    const startAfter = toSeconds(matches[ix + 1].start);

    const currentStart = toSeconds(match.start);

    if (lastValidEnding <= currentStart && currentStart <= startAfter) {
      lastValidEnding = toSeconds(match.end);
    } else {
      match.valid = false;
    }
  });
};

const invalidateMatchesFromDifferentSpeakersInSameSentence = (sentences) => {
  sentences.forEach(({ matches, value }) => {
    if (matches.length === 0) return;

    const counts = _.countBy(matches, (m) => m.speaker);
    const mostMatches = _.maxBy(Object.entries(counts), (e) => e[1]);
    const speaker = mostMatches[0];

    matches.forEach((m) => {
      m.valid = m.valid && m.speaker === speaker;
    });
  });
};

const invalidateMatchesTooFar = (sentences) => {
  const MAX_DISTANCE = 150;

  sentences.forEach(({ matches, value }) => {
    if (matches.length === 0) return;

    let position = matches[0].position;

    matches.slice(1).forEach((match, ix) => {
      matches[ix].valid = matches[ix].valid && match.position - position <= MAX_DISTANCE;
      position = match.position;
    });
  });
};

const invalidateMultipleEqualMatches = (part) => {
  let content = part.value.replace(/\W/g, " ");

  part.matches.forEach((match) => {
    const clearAi = match.value.replace(/\W/g, " ");

    if (content.indexOf(clearAi) >= 0) {
      content = content.replace(clearAi, "");
    } else {
      match.valid = false;
    }
  });
};

const invalidateOOOMatches = (sentences) => {
  sentences.forEach((part) => {
    let cursor = part.value;

    part.matches.forEach((match) => {
      const [, ix] = matchingData(match.value, cursor);

      if (ix >= 0) {
        cursor = cursor.slice(ix + match.value.length);
      } else {
        match.valid = false;
      }
    });
  });
};

const matchingData = (aiValue, regularCleanValue) => {
  const clearAi = aiValue.replace(/\W/g, " ");
  const clearRegular = regularCleanValue.replace(/\W/g, " ");
  const substringIx = clearRegular.indexOf(clearAi);

  return [clearAi.length, substringIx];
};

const interpolateMatches = (regularSentences, validMatches, duration) => {
  let lastEnding = 0;

  const transcript = [];

  regularSentences.forEach((part) => {
    if (part.matches.length === 0) {
      transcript.push({
        position: transcript.length,
        speaker: part.speaker,
        value: part.value,
        start: lastEnding,
        end: matchAfter(validMatches, lastEnding, duration),
        hasHighlight: part.hasHighlight,
      });
      return;
    }

    part.matches.forEach((match, matchIx) => {
      const [length, substringIx] = matchingData(match.value, part.value);

      const before = part.value.slice(0, substringIx);

      const matching = part.value.slice(substringIx, substringIx + length);

      if (matching === "") return;

      const after = part.value.slice(substringIx + length);

      if (before.trim().length > 0) {
        transcript.push({
          position: transcript.length,
          speaker: part.speaker,
          value: before.trim(),
          start: lastEnding,
          end: toSeconds(match.start),
        });
      }

      transcript.push({
        position: transcript.length,
        speaker: part.speaker,
        value: matching.trim(),
        start: toSeconds(match.start),
        end: toSeconds(match.end),
        aiMatch: true,
      });

      lastEnding = toSeconds(match.end);

      if (match === _.last(part.matches) && after.trim() !== "") {
        const nextMatch = validMatches[validMatches.indexOf(match) + 1];
        const end = nextMatch ? toSeconds(nextMatch.start) : matchAfter(validMatches, lastEnding, duration);

        transcript.push({
          position: transcript.length,
          speaker: part.speaker,
          value: after.trim(),
          start: lastEnding,
          end: end,
        });
      } else {
        part.value = after;
      }
    });
  });

  return transcript;
};

const cleanUp = (sentences) => {
  sentences.forEach((part) => {
    part.matches = part.matches.filter(({ valid }) => valid);
  });
};

export default function interpolateMatchingSentences(regularSentencesOriginal, aiSentences, duration) {
  const regularSentences = regularSentencesOriginal.map((sentence) => ({
    ...sentence,
    matches: [],
  }));

  const matches = basicMatch(regularSentences, aiSentences);

  invalidateMatchesFromDifferentSpeakersInSameSentence(regularSentences);
  cleanUp(regularSentences);

  regularSentences.forEach((part) => invalidateMultipleEqualMatches(part));
  cleanUp(regularSentences);

  invalidateMatchesTooFar(regularSentences);
  cleanUp(regularSentences);

  invalidateOOOMatches(regularSentences);
  cleanUp(regularSentences);

  invalidateUnorderedMatches(matches);
  cleanUp(regularSentences);

  invalidateUnorderedMatches(regularSentences.map(({ matches }) => [...matches]).flat());
  cleanUp(regularSentences);

  const validMatches = matches.filter(({ valid }) => valid);

  const transcriptWithSeconds = interpolateMatches(regularSentences, validMatches, duration);

  const timestamped = transcriptWithSeconds.map(({ start, end, ...part }) => {
    return {
      ...part,
      start: toTimestamp(start),
      end: toTimestamp(end),
    };
  });

  const rawForgedText = transcriptWithSeconds.reduce(
    (acc, { value }) => acc + value.replace(new RegExp("[^\\S]", "g"), ""),
    ""
  );
  const rawOriginalText = regularSentencesOriginal.reduce(
    (acc, { value }) => acc + value.replace(new RegExp("[^\\S]", "g"), ""),
    ""
  );

  return [timestamped, rawForgedText === rawOriginalText, rawForgedText, rawOriginalText];
}
