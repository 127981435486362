import {
  ListOptionGroupItemType,
  ListOptionInputType,
  ListOptionType,
  SelectedOptions,
} from "@alphasights/alphadesign-components";

import {
  Geography,
  GEOGRAPHIES,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HqLocationFilter/constants";

const TEST_ID_PREFIX = "checkbox-hq-location";

const formatName = (country: string) =>
  country
    .toLowerCase()
    .replace("-", " ")
    .replace(/[^a-zA-Z\s]/g, "")
    .replace(/\s+/g, "-");

const createTestId = (country: string, isSelected: boolean) => {
  const formattedCountry = formatName(country);
  return `${TEST_ID_PREFIX}-${formattedCountry}${isSelected ? "-selected" : ""}`;
};

const createHqLocationOptions: (value: string[]) => ListOptionGroupItemType[] = (value) => {
  let index = 0;
  const options = GEOGRAPHIES.map(({ region, countries }: Geography) => {
    const parentIndex = index;

    let numSelectedCountries = 0;
    const subOptions = countries.map((country: string) => {
      index += 1;
      const isSelected = value.includes(country);
      isSelected && numSelectedCountries++;
      const testId = createTestId(country, isSelected);
      return {
        id: index.toString(),
        type: "text" as ListOptionType,
        inputType: "checkbox" as ListOptionInputType,
        label: country,
        selected: isSelected,
        size: "small",
        dataAttributes: {
          "data-testid": testId,
        },
      };
    });

    const isSelected = numSelectedCountries === countries.length;
    const isIndeterminate = numSelectedCountries > 0 && !isSelected;
    const testId = createTestId(region, isSelected);

    const parentOption = {
      id: parentIndex.toString(),
      type: "text" as ListOptionType,
      inputType: "checkbox" as ListOptionInputType,
      size: "small",
      label: region,
      subOptions,
      selected: isSelected,
      indeterminate: isIndeterminate,
      dataAttributes: {
        "data-testid": testId,
      },
    };
    index += 1;
    return parentOption;
  });
  return options;
};

const getSelectedOptions = (options: ListOptionGroupItemType[]) => {
  const selectedOptions: SelectedOptions[] = [];
  options.forEach(({ id, label, selected, subOptions }) => {
    if (selected) {
      selectedOptions.push({ id, label } as SelectedOptions);
    }
    if (subOptions) {
      selectedOptions.push(...getSelectedOptions(subOptions));
    }
  });
  return selectedOptions;
};

const getCountries = (entries: string[]) =>
  entries.filter((entry) => !GEOGRAPHIES.some((geography) => geography.region === entry));

export { formatName, createTestId, createHqLocationOptions, getCountries, getSelectedOptions };
