import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { x, th } from "@xstyled/styled-components";
import { useAlphaGPTFooterStyles } from "./AlphaGPTFooter.styles";
import { Icon, IconButton, Link, Status, TextField, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { Close, Info, Send, Search, Tick, Show } from "@alphasights/alphadesign-icons";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { AlphaGptLibrary } from "models/AlphaGpt";

export const AlphaGPTFooter = ({ isMessagesOverflow = false }) => {
  const { wrapper } = useAlphaGPTFooterStyles({ isMessagesOverflow });
  const { isConversationFromCurrentUser, conversation } = useAlphaGPTContext();

  return !conversation || isConversationFromCurrentUser ? (
    <x.div {...wrapper}>
      <SearchField />
      <FooterInformation />
    </x.div>
  ) : (
    <ViewOnlyInformation />
  );
};

const ViewOnlyInformation = () => {
  const { viewOnlyInformationContainer, viewOnlyStatus } = useAlphaGPTFooterStyles();

  return (
    <x.div {...viewOnlyInformationContainer} data-testid="view-only-information-banner">
      <Typography>You have selected a colleague’s search</Typography>
      <Status text="View only" leftIcon={<Show />} {...viewOnlyStatus} />
    </x.div>
  );
};

const SearchField = () => {
  const {
    submitQuery,
    isLoading,
    errorMessage,
    hasValidAlphaGPTPurchase,
    query: initialQuery,
    conversation,
  } = useAlphaGPTContext();
  const currentUser = useCurrentUser()!;
  const { searchWrapper, searchInputWrapper, inputIcon } = useAlphaGPTFooterStyles();

  const ref = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string>();

  const onSubmit = useCallback(() => {
    if (query && !isLoading) {
      submitQuery(query);
      setQuery("");
    }
  }, [submitQuery, query, isLoading]);

  const onKeyDown = useCallback(
    ({ key }: { key: string }) => {
      if (key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    if (!isLoading && ref?.current) {
      ref.current.focus();
    }
  }, [isLoading]);

  const selectQueryPlaceholder = useCallback((query: string, input: HTMLInputElement) => {
    const start = query.indexOf("[");
    const end = query.indexOf("]") + 1;

    input.focus();
    start > 0 && input.setSelectionRange(start, end);
  }, []);

  useEffect(() => {
    setQuery(initialQuery);
    const input = ref?.current;
    setTimeout(() => {
      if (initialQuery && input) {
        selectQueryPlaceholder(initialQuery, input);
      }
    });
  }, [initialQuery, selectQueryPlaceholder]);

  const isLibraryDisabled =
    (conversation?.libraries ?? []).includes(AlphaGptLibrary.Private) && !currentUser.privateTranscriptLibraryEnabled;

  return (
    <x.div {...searchWrapper} data-testid="search-field">
      <x.div {...searchInputWrapper}>
        <SearchTextField
          value={query}
          disabled={isLoading || (errorMessage?.length ?? 0) > 0 || !hasValidAlphaGPTPurchase || isLibraryDisabled}
          ref={ref}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Send a message..."
          startAdornment={
            <x.span {...inputIcon}>
              <Icon color="secondary">
                <Search />
              </Icon>
            </x.span>
          }
          data-testid="alphagpt-query"
        />
      </x.div>
      <IconButton onClick={onSubmit} variant="ghost" disabled={!hasValidAlphaGPTPurchase}>
        <Send />
      </IconButton>
    </x.div>
  );
};

const FooterInformation = () => {
  const { infoContainer, link } = useAlphaGPTFooterStyles();
  const { hideLibraryContext, projectToken } = useAlphaGPTContext();
  const currentUser = useCurrentUser();

  const termsUrl = currentUser?.accessOnly
    ? "https://www.alphasights.com/subscription-user-platform-terms/"
    : "https://www.alphasights.com/clientfacingdomainterms-platformterms/";
  const privacyPolicyUrl = "https://www.alphasights.com/policies/";
  const disclaimer = projectToken ? (
    <>
      Natural language search may produce inaccurate information. Please reference source citations to read underlying
      expert insights. AlphaSights accepts no responsibility for any information, including confidential information,
      you enter into natural language search. Queries will not be used to train any model. Use of natural language
      search is subject to the
    </>
  ) : (
    <>
      AlphaGPT may produce inaccurate information. Please reference source citations to read underlying expert insights.
      AlphaSights accepts no responsibility for any information, including confidential information, you enter into
      AlphaGPT. Search queries will not be used to train AlphaGPT. Use of AlphaGPT is subject to the
    </>
  );

  return (
    <x.div {...infoContainer} data-testid="footer-information">
      {!hideLibraryContext && <LibraryContext />}
      <Typography variant="body-small-spaced" color="assistive" component="div">
        {disclaimer}{" "}
        <Link href={termsUrl} target="_blank">
          <Typography variant="body-small-spaced" {...link}>
            Platform Terms
          </Typography>
        </Link>{" "}
        and{" "}
        <Link href={privacyPolicyUrl} target="_blank">
          <Typography variant="body-small-spaced" {...link}>
            Privacy Policy
          </Typography>
        </Link>
        .
      </Typography>
    </x.div>
  );
};

const LibraryContext = () => {
  const { libraryContext } = useAlphaGPTFooterStyles();
  const {
    privateTranscriptLibraryEnabled,
    privateTranscriptLibrarySettingInAlphaGptEnabled,
    privateTranscriptLibraryEnabledInAlphaGpt,
  } = useCurrentUser()!;

  return (
    <x.div {...libraryContext} data-testid="alpha-gpt-library-context">
      <LibraryItem name="AlphaSights Library" checked />
      {privateTranscriptLibrarySettingInAlphaGptEnabled && (
        <LibraryItem
          name="Private Library"
          checked={privateTranscriptLibraryEnabledInAlphaGpt && privateTranscriptLibraryEnabled}
          tooltip={
            privateTranscriptLibraryEnabled && !privateTranscriptLibraryEnabledInAlphaGpt
              ? "Your organization has not enabled AlphaGPT to search your Private Library."
              : "Your organization has not enabled the Private Transcript Library feature."
          }
        />
      )}
    </x.div>
  );
};

const LibraryItem = ({ checked = false, name, tooltip }: { checked?: boolean; name: string; tooltip?: string }) => {
  const { libraryItem } = useAlphaGPTFooterStyles();

  return (
    <x.div {...libraryItem}>
      <Icon size="small" color={checked ? "success" : "danger"}>
        {checked ? <Tick /> : <Close />}
      </Icon>
      <Typography variant="body-small" color="assistive">
        {name}
      </Typography>
      {!checked && tooltip && (
        <Tooltip title={tooltip} variant="dark">
          <x.div>
            <Icon size="small" color="disabled">
              <Info />
            </Icon>
          </x.div>
        </Tooltip>
      )}
    </x.div>
  );
};

const SearchTextField = styled(TextField)`
  outline-width: ${th.borderWidth("sm")};
  padding-left: ${th.space("inner-base02")};
  > input {
    padding: ${th.space("inner-base04")} ${th.space("inner-base03")} ${th.space("inner-base04")}
      ${th.space("inner-base02")};
    font-size: ${th.fontSize("03")};
    line-height: ${th.lineHeight("02")};
  }
`;
