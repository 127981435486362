import { keys } from "lodash";
import { map, find } from "lodash/fp";

const labelByMagnitude = {
  1_000: "k",
  1_000_000: "m",
  1_000_000_000: "bn",
  1_000_000_000_000: "tn",
};

const numberFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

/**
 * Returns {@link value} formatted as “<ddd.dd<magnitude>” where magnitude is ["k", "m", "bn", "tn"]
 *
 * E.g. “43.22m”, “50bn”
 */
export const formatCurrency = (value: number): string => {
  const floorMagnitude = find<number>((magnitude) => value >= magnitude)(
    map(parseInt)(keys(labelByMagnitude).reverse())
  );

  if (!floorMagnitude) return numberFormat.format(value);

  const label = labelByMagnitude[floorMagnitude as keyof typeof labelByMagnitude] || "";

  return `${numberFormat.format(value / floorMagnitude)}${label}`;
};
