import React, { useCallback, useEffect, useMemo } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Modal, Typography, useThemeTokens, Icon } from "@alphasights/alphadesign-components";
import { CallMePanel } from "components/CallMePanel";
import { CtaButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { noop } from "lodash";
import { ArrowLeft } from "@alphasights/alphadesign-icons";

export const providerLinkLabel = (newspeakProvider) => {
  if (newspeakProvider === "teams") {
    return "Join Via Teams";
  } else {
    return "Join Call";
  }
};

export const providerModalTitle = (newspeakProvider) => {
  if (newspeakProvider === "zoom") {
    return (
      <Typography variant="h3" style={{ textTransform: "none" }}>
        Join Call Via Zoom
      </Typography>
    );
  } else {
    return (
      <Typography variant="h3" style={{ textTransform: "none" }}>
        Join Call
      </Typography>
    );
  }
};

export const accessCodeForHuman = (accessCode, provider) => {
  if (provider === "zoom") {
    return accessCode && accessCode.length >= 14 ? accessCode.substring(0, 14) + "#" : "";
  } else if (provider === "teams") {
    return "";
  } else if (provider === "263") {
    return accessCode && accessCode.length >= 12 ? accessCode.substring(0, 12) + "#" : "";
  } else {
    return accessCode && accessCode.length >= 8 ? accessCode.substring(0, 8) + "#" : "";
  }
};

export const CallMeButton = ({
  callMeUrl,
  provider,
  isModalOpen,
  setIsModalOpen,
  order,
  showPrimaryOption,
  showMessagingOptions,
  interaction,
  onSendNewMessage,
  variant,
  size,
}) => {
  const providerLabel = useMemo(() => providerLinkLabel(provider), [provider]);

  const {
    color: { text },
  } = useThemeTokens();

  const buttonStyle = {
    ...(variant === "link" && { color: text.link }),
    order,
  };

  if (provider === "teams") {
    return (
      <CtaButton
        variant={variant ?? "outline"}
        data-testid={`connect-via-${provider}-button`}
        onClick={() => {
          if (callMeUrl?.length > 0) {
            window.open(callMeUrl);
          }
        }}
        size={size ?? "medium"}
        primaryOptionLabel={showPrimaryOption && providerLabel}
        showMessagingOptions={showMessagingOptions}
        interaction={interaction}
        onSendNewMessage={onSendNewMessage}
        {...buttonStyle}
      >
        {providerLabel}
      </CtaButton>
    );
  } else {
    return (
      <>
        <CallMeModal
          mainNumber={interaction.clientAccessNumber}
          accessCode={accessCodeForHuman(interaction.clientAccessCode, interaction.newspeakProvider)}
          otherNumbers={interaction.dialInNumbers}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          projectToken={interaction.projectToken}
          interactionId={interaction.id}
          scheduledCallTime={interaction.scheduledCallTime}
          newspeakProvider={interaction.newspeakProvider}
          data-testid="call-me-modal"
          callMeUrl={callMeUrl}
        />
        <CtaButton
          variant={variant ?? "outline"}
          data-testid="dial-in-options-button"
          onClick={(event) => {
            event.stopPropagation();
            setIsModalOpen(true);
          }}
          size={size ?? "medium"}
          primaryOptionLabel={showPrimaryOption && providerLabel}
          showMessagingOptions={showMessagingOptions}
          interaction={interaction}
          onSendNewMessage={onSendNewMessage}
          {...buttonStyle}
        >
          {providerLabel}
        </CtaButton>
      </>
    );
  }
};

export const CallMeModal = ({
  mainNumber,
  accessCode,
  otherNumbers,
  projectToken,
  interactionId,
  isOpen,
  setIsOpen,
  newspeakProvider,
  callMeUrl,
  ...props
}) => {
  const { isMobile } = useCheckScreen();

  const isUnsuportedProvider = useMemo(() => newspeakProvider === "teams", [newspeakProvider]);

  const onModalKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        event.preventDefault();
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (isUnsuportedProvider) {
      setIsOpen(false);
    }
  }, [isUnsuportedProvider, setIsOpen]);

  useEffect(() => {
    document.addEventListener("keydown", onModalKeyPress);
    return () => {
      document.removeEventListener("keydown", onModalKeyPress);
    };
  }, [onModalKeyPress]);

  if (!isOpen || isUnsuportedProvider) {
    return null;
  }

  if (isMobile) {
    return (
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        showCloseButton={false}
        zIndex={250}
        border="none"
        onBackdropClick={noop}
        {...props}
      >
        <div className="aui-w-screen aui-h-screen">
          <div className="aui-p-2 aui-flex aui-flex-row">
            <Icon>
              <ArrowLeft />
            </Icon>
          </div>
          <CallMePanel
            mainNumber={mainNumber}
            accessCode={accessCode}
            otherNumbers={otherNumbers}
            projectToken={projectToken}
            interactionId={interactionId}
            newspeakProvider={newspeakProvider}
            isClosed={!isOpen}
            callMeUrl={callMeUrl}
          />
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        open={isOpen}
        title={providerModalTitle(newspeakProvider)}
        onClose={() => setIsOpen(false)}
        showCloseButton={true}
        zIndex={250}
        slotWidth="max-content"
        slotHeight="max-content"
        border="none"
        onBackdropClick={noop}
        {...props}
      >
        <CallMePanel
          mainNumber={mainNumber}
          accessCode={accessCode}
          otherNumbers={otherNumbers}
          projectToken={projectToken}
          interactionId={interactionId}
          newspeakProvider={newspeakProvider}
          isClosed={!isOpen}
          callMeUrl={callMeUrl}
        />
      </Modal>
    );
  }
};
