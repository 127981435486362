import React from "react";
import { currencyRendererWithSymbol, rangeOf, renderRange } from "components/CitationContext/PropertyRenderers";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import { Citation } from "components/CitationContext/Citation";
import { mapSegmentation } from "./mappers";

export const revenueCitation = ({
  currency,
  value,
}: {
  currency: string;
  value: {
    max: CitableValue<number>;
    min: CitableValue<number>;
    year: CitableValue<number>;
    quarter?: CitableValue<number> | null;
  };
}) => {
  const { min, max, year, quarter } = value;

  const currencySymbol = currency ? CURRENCY_SYMBOL[currency] : "";
  const CurrencyRenderer = currencyRendererWithSymbol(currencySymbol);
  const desc = quarter?.value ? `Q${quarter.value}, ${year.value}` : year.value;

  return (
    <Citation
      style={{ fontSize: "14px" }}
      value={rangeOf(min, max)}
      renderer={renderRange({
        renderer: CurrencyRenderer,
        desc,
      })}
    />
  );
};

const replaceMatchingElements = <T extends { id: CitableValue<string> }>(fullList: T[], replacingItems: T[]): T[] => {
  replacingItems.forEach((replacingItem) => {
    fullList.splice(
      fullList.findIndex((replaceableItem) => replaceableItem.id.value === replacingItem.id.value),
      1,
      replacingItem
    );
  });

  return fullList;
};

const mergeSizeAndGrowth = (
  currentSizeAndGrowth: SizeAndGrowth,
  mentionsSizeAndGrowth: SizeAndGrowth
): SizeAndGrowth => {
  return {
    ...currentSizeAndGrowth,
    data: [...replaceMatchingElements<SizeAndGrowthData>([...currentSizeAndGrowth.data], mentionsSizeAndGrowth.data)],
  };
};

const mergeCompetitorDynamics = (
  currentCompetitors: CompetitorDynamicsEntry[],
  mentionsCompetitors: CompetitorDynamicsEntry[]
): CompetitorDynamicsEntry[] =>
  replaceMatchingElements<CompetitorDynamicsEntry>([...currentCompetitors], mentionsCompetitors);

const mergeSegmentsType = (currentSegmentation: Segment[], mentionsSegmentation: Segment[]): Segment[] => {
  let newSegmentation = [...currentSegmentation];

  if (mentionsSegmentation.length > 0) {
    // we need to replace all the entries with mentions to the current segments' list
    mentionsSegmentation.forEach((entry) => {
      const entryIndex = newSegmentation.findIndex((customer: Segment) => customer.id.value === entry.id.value);

      if (entryIndex >= 0) {
        // create a new temp segment
        const newEntry: Segment = {
          ...entry,
          outlook: [...newSegmentation[entryIndex].outlook],
        };

        // we need to replace all the outlook entries with mentions to the current primer segment outlooks
        newEntry.outlook = replaceMatchingElements<CitableOutlook>([...newEntry.outlook], entry.outlook);

        newSegmentation.splice(entryIndex, 1, newEntry);
      }
    });
  }

  return newSegmentation;
};

const mergeOutlook = (currentOutlook: CitableOutlook[], mentionsOutlook: CitableOutlook[]): CitableOutlook[] =>
  replaceMatchingElements<CitableOutlook>([...currentOutlook], mentionsOutlook);

// this is needed because the mentions endpoint only returns the objects that matches the search keywords
export const mergeMentionsPrimerWithActualPrimer = (currentPrimer: any, mentionsPrimer: any) => {
  let newPrimer = { ...currentPrimer };

  // merge overview
  newPrimer.overview = {
    ...newPrimer.overview,
    ...mentionsPrimer.overview,
  };

  // merge size and growth section
  newPrimer.sizeAndGrowth = mergeSizeAndGrowth(newPrimer.sizeAndGrowth, mentionsPrimer.sizeAndGrowth);

  // merge competitor dynamics section
  if (newPrimer.competitorDynamics.leaders && mentionsPrimer.competitorDynamics) {
    newPrimer.competitorDynamics.leaders = [
      ...mergeCompetitorDynamics(newPrimer.competitorDynamics.leaders, mentionsPrimer.competitorDynamics.leaders),
    ];
  }

  // merge segmentation section
  const mentionsSegmentation = mapSegmentation(mentionsPrimer.segmentation);

  newPrimer.segmentation.customers = [
    ...mergeSegmentsType(newPrimer.segmentation.customers, mentionsSegmentation.customers),
  ];
  newPrimer.segmentation.products = [
    ...mergeSegmentsType(newPrimer.segmentation.products, mentionsSegmentation.products),
  ];
  newPrimer.segmentation.regions = [...mergeSegmentsType(newPrimer.segmentation.regions, mentionsSegmentation.regions)];

  newPrimer.segmentation.customised = newPrimer.segmentation.customised.map(
    (customisedSegmentation: CustomisedSegmentation) => {
      const entryMatch = mentionsPrimer.segmentation.customised.find(
        (entry: CustomisedSegmentation) => entry.id.value === customisedSegmentation.id.value
      ) as CustomisedSegmentation;

      // not found on mentions, so use the current
      if (!entryMatch) {
        return { ...customisedSegmentation };
      } else {
        // found on mentions, so copy the object and merge the segments
        return {
          ...entryMatch,
          segments: [...mergeSegmentsType(customisedSegmentation.segments, entryMatch.segments)],
        };
      }
    }
  );

  // merge outlook
  newPrimer.outlook = [...mergeOutlook(newPrimer.outlook, [...mentionsPrimer.outlooks])];

  return newPrimer;
};
