import { ValidationResponse } from "types";
import { fetch } from "../hooks/useApi";

export const emailValidatorService = {
  validateCalendarInvitationEmails(emails: string[]): Promise<ValidationResponse> {
    const url = `/api/email-validators/calendar-invitation/valid?emails=${emails}`;
    return fetch({
      url,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
};
