import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing } = useThemeTokens();

  return {
    handle: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base,
      ml: spacing.inner.base06,
    },
    modal: {
      mx: "-" + spacing.inner.base06,
      my: "-" + spacing.inner.base02,
      maxH: "600px",
      w: "555px",
      display: "flex",
      flexDirection: "column",
    },
    modalTitleWrapper: {
      display: "flex",
      py: spacing.inner.base04,
      px: spacing.inner.base06,
      alignItems: "center",
      gap: spacing.inner.base04,
      justifyContent: "space-between",
    },
    modalBodyWrapper: {
      overflowY: "auto",
      px: spacing.inner.base06,
      py: spacing.inner.base04,
    },
    modalButtonsWrapper: {
      display: "flex",
      justifyContent: "end",
      py: spacing.inner.base04,
      px: spacing.inner.base06,
      gap: spacing.inner.base04,
    },
    accordion: {
      mt: spacing.inner.base06,
    },
    themesWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
      mb: spacing.inner.base03,
    },
    themesTitle: {
      display: "flex",
      gap: spacing.inner.base,
    },
  };
};
