import React, { useCallback, useMemo, useRef } from "react";
import { Popover, PopoverProps, useThemeTokens } from "@alphasights/alphadesign-components";

import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { SearchOption, OptionalOptionProps, OptionsPopoverFooterProps, OptionSections } from "components/Search/types";
import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import Footer from "./Footer";
import { getOptionsPerSection } from "./utils";

import "./index.css";
import { PopoverContentContainer, StyledTypography } from "./OptionsPopover.styled";
import { MAX_NUMBER_RESULTS, NUM_OPTIONS_DEFAULT_POPOVER, NUM_OPTIONS_POPOVER_WITH_SECTIONS } from "./consts";

const DataTestIds = {
  Popover: "advanced-search-popover",
  OptionSectionTitle: "option-section-title",
  OptionSection: "option-section",
};

export interface OptionsPopoverProps extends PopoverProps {
  onClose: () => void;
  options: SearchOption[];
  onSelect: () => void;
  onOptionHighlight: (index: number) => void;
  searchContainerWidth: number;
  components: Record<"Option", Function>;
  allowBooleanExpressions: boolean;

  size?: SearchSizeVariant;
  isOpen?: boolean;
  anchorEl?: HTMLElement;
  highlightedOptionIndex?: number;
  optionProps?: OptionalOptionProps;
  footerProps?: OptionsPopoverFooterProps;
  optionSections?: OptionSections[];
}

const OptionsPopover = ({
  onClose,
  options,
  onSelect,
  onOptionHighlight,
  searchContainerWidth,
  anchorEl,
  allowBooleanExpressions,
  components: { Option },
  highlightedOptionIndex = -1,
  isOpen = false,
  optionProps = {},
  footerProps,
  size = SearchSizeVariant.Medium,
  optionSections = [],
  ...props
}: OptionsPopoverProps) => {
  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const { spacing } = useThemeTokens();

  const numSections = optionSections.length;
  const hasSections = numSections > 0;
  const paddingTop = hasSections ? spacing.inner.base02 : toPx(0);
  const sectionsHeight = hasSections ? numSections * fromPx(styles.sectionHeader.height) : 0;

  const numVisibleOptions = hasSections ? NUM_OPTIONS_POPOVER_WITH_SECTIONS : NUM_OPTIONS_DEFAULT_POPOVER;

  const maxHeight = useMemo(
    () => toPx(fromPx(styles.height) * numVisibleOptions + sectionsHeight + fromPx(paddingTop)),
    [styles, numVisibleOptions, sectionsHeight, paddingTop]
  );

  // Limiting the number of results to avoid slowness - see CON-3159
  const displayedOptions = options.slice(0, MAX_NUMBER_RESULTS).map((option, index) => ({
    ...option,
    index,
  }));

  const optionsPerSection = getOptionsPerSection(displayedOptions, optionSections);

  const renderOptions = useCallback(
    (optionList: SearchOption[] = displayedOptions) => {
      return optionList.map((option) => {
        const isHighlighted = option.index === highlightedOptionIndex;
        return (
          <Option
            key={option.index}
            size={size}
            data={option}
            highlight={isHighlighted}
            onSelect={onSelect}
            onHighlight={() => onOptionHighlight(option.index as number)}
            {...optionProps}
          />
        );
      });
    },
    [highlightedOptionIndex, onSelect, onOptionHighlight, optionProps, size, displayedOptions]
  );

  return (
    <Popover
      {...props}
      data-testid={DataTestIds.Popover}
      className={DataTestIds.Popover}
      anchorEl={anchorEl}
      open={isOpen}
      closeOnClickOutside
      onClose={onClose}
      onFloatingData={() => ({ w: toPx(searchContainerWidth) })}
    >
      <PopoverContentContainer maxHeight={maxHeight} paddingTop={paddingTop}>
        {optionsPerSection.map(({ title, options }) => (
          <Wrapper title={title} height={styles.sectionHeader.height}>
            {renderOptions(options)}
          </Wrapper>
        ))}
      </PopoverContentContainer>
      <Footer {...footerProps!} size={size} allowBooleanExpressions={allowBooleanExpressions} />
    </Popover>
  );
};

const Wrapper = ({ children, title, height }: { children: React.ReactNode; title: string; height: string }) => {
  return (
    <div key={title} data-testId={DataTestIds.OptionSection}>
      {title && (
        <StyledTypography variant="body-small-em" data-testId={DataTestIds.OptionSectionTitle} height={height}>
          {title}
        </StyledTypography>
      )}
      {children}
    </div>
  );
};

export { OptionsPopover as default, DataTestIds };
