import React, { useCallback, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, ToggleButton, useThemeTokens } from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { MessageType } from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { NewMessageBody } from "pages/MessengerPage/components/NewMessageBody/NewMessageBody";
import { ENABLE_MESSAGES_COMPLIANCE_REVIEW, useProjectBadgeContext } from "providers/BadgeProvider";

export const MobileNewMessage = ({
  onClose,
  onSent,
  attachTextBoxToNavbar = false,
  selectedExpertId,
}: {
  onClose: () => void;
  onSent: () => void;
  attachTextBoxToNavbar?: boolean;
  selectedExpertId?: string;
}) => {
  const { newMessageType, onSendNewMessage } = useMessengerContext();
  const { wrapper, content } = useMobileNewMessageStyles();
  const sendMessageButtonRef = useRef(null);
  const [bottomElementsHeight, setBottomElementsHeight] = useState(0);

  const { hasProjectBadge } = useProjectBadgeContext();
  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  const handleSubmit = () => {
    onSent();
    onClose();
  };

  const onMobileTextBoxHeightChange = useCallback(
    (newHeight: number) => {
      const height = attachTextBoxToNavbar ? newHeight + 65 : newHeight;
      setBottomElementsHeight(height);
    },
    [attachTextBoxToNavbar]
  );

  const backButton = (
    <IconButton size="medium" variant="ghost" onClick={onClose} testId="mobile-top-bar-menu-button">
      <ArrowLeft />
    </IconButton>
  );

  if (!newMessageType) return null;

  return (
    <x.div data-testid="mobile-new-message-page" {...wrapper}>
      <PortalMobileTopBar
        title="New Message"
        defaultNavValue={newMessageType}
        {...(!attachTextBoxToNavbar ? { mainButton: backButton } : {})}
        zIndex={1}
      >
        <>
          {!needComplianceReview && (
            <ToggleButton
              value={MessageType.WorkRequest}
              sizeVariant="small"
              onClick={() => onSendNewMessage(MessageType.WorkRequest)}
            >
              Work Request
            </ToggleButton>
          )}
        </>
        <ToggleButton
          value={MessageType.Clarification}
          sizeVariant="small"
          onClick={() => onSendNewMessage(MessageType.Clarification)}
        >
          Clarification
        </ToggleButton>
        <>
          {!needComplianceReview && (
            <ToggleButton
              value={MessageType.CallGuide}
              sizeVariant="small"
              onClick={() => onSendNewMessage(MessageType.CallGuide)}
            >
              Call Guide
            </ToggleButton>
          )}
        </>
      </PortalMobileTopBar>

      <x.div {...content} mb={`${bottomElementsHeight}px`}>
        <NewMessageBody
          sendMessageButtonRef={sendMessageButtonRef}
          mobileVariant={true}
          onMessageSent={handleSubmit}
          attachTextBoxToNavbar={attachTextBoxToNavbar}
          selectedExpertId={selectedExpertId}
          onMobileTextBoxHeightChange={onMobileTextBoxHeightChange}
        />
      </x.div>
    </x.div>
  );
};

const useMobileNewMessageStyles = () => {
  const {
    color: { background },
    spacing: { inner },
  } = useThemeTokens();

  return {
    wrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      h: "100dvh",
      overflow: "auto",
    },
    content: {
      p: inner.base04,
      bg: background.inverse,
      flexGrow: 1,
    },
    sendMessage: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
    },
  };
};
