import { useCurrentUser } from "@alphasights/portal-auth-react";
import { fetch } from "hooks/useApi";
import React, { useContext, useEffect, useState } from "react";

export const CommentContext = React.createContext();

export const CommentProvider = ({ project, ...props }) => {
  const currentUser = useCurrentUser();
  const [commentsCount, setCommentsCount] = useState([]);

  useEffect(() => {
    if (currentUser && project) {
      fetch({
        url: `/api/projects/${project.token}/comments/counts`,
        method: "GET",
      })
        .then((response) => response.json())
        .then((comments) => setCommentsCount(comments));
    } else {
      setCommentsCount([]);
    }
  }, [project, currentUser]);

  const markThreadRead = ({ angleId, expertId }) => {
    setCommentsCount((commentsCount) =>
      commentsCount.map((counter) => {
        if (angleId === counter.angleId && expertId === counter.expertId) {
          return {
            ...counter,
            pendingCount: 0,
          };
        }

        return counter;
      })
    );
  };

  const getCounts = ({ angleId, expertId }) => {
    return (
      commentsCount.find((c) => c.angleId === angleId && c.expertId === expertId) || {
        pendingCount: 0,
      }
    );
  };

  const context = {
    commentsCount,
    getCounts,
    markThreadRead,
  };

  return <CommentContext.Provider value={context} {...props} />;
};

export const useCommentContext = () => useContext(CommentContext);
