import React, { FC } from "react";
import { x } from "@xstyled/styled-components";
import { Tooltip, Typography, TypographyVariation, useThemeTokens } from "@alphasights/alphadesign-components";
import { Remove } from "@alphasights/alphadesign-icons";
import DirectionalArrow from "components/DirectionalArrow";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const GROWTH_DESCRIPTIONS = {
  increasing: "Gaining",
  decreasing: "Declining",
  constant: "Stable",
};

enum CompetitorGrowth {
  Increasing = "Increasing",
  Decreasing = "Decreasing",
  Constant = "Constant",
}

export enum GrowthSymbolIconPosition {
  left,
  right,
}

interface GrowthSymbolProps extends React.HTMLAttributes<HTMLImageElement> {
  growth: string;
  variant?: TypographyVariation;
  color?: string;
  customLabel?: string;
  maxChars?: number;
  iconPosition?: GrowthSymbolIconPosition;
}

const GrowthSymbol: FC<GrowthSymbolProps> = ({
  growth,
  variant,
  color,
  customLabel,
  maxChars,
  iconPosition = GrowthSymbolIconPosition.left,
}): JSX.Element | null => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const isKnownGrowthType = growth in CompetitorGrowth;

  let ellipsize = false;

  if (!isKnownGrowthType) return null;

  let fontVariant;
  if (isMobile) {
    fontVariant = "body-large" as TypographyVariation;
  } else if (variant) {
    fontVariant = variant;
  } else {
    fontVariant = "body" as TypographyVariation;
  }

  let finalLabel = customLabel;

  if (customLabel && customLabel.length > 0) {
    finalLabel = maxChars && customLabel.length > maxChars ? `${customLabel.substring(0, maxChars)}...` : customLabel;
    ellipsize = true;
  }

  if (growth === CompetitorGrowth.Constant) {
    return (
      <x.span display="inline-flex" alignItems="center" backgroundColor="inherit">
        {iconPosition === GrowthSymbolIconPosition.left && <Remove height={20} width={20} color={text.secondary} />}
        <Typography color={color ?? text.strong._} component="span" ml={inner.base} variant={fontVariant}>
          {ellipsize && (
            <Tooltip variant="dark" title={customLabel} position="top">
              {finalLabel ?? GROWTH_DESCRIPTIONS.constant}
            </Tooltip>
          )}
          {!ellipsize && (finalLabel ?? GROWTH_DESCRIPTIONS.constant)}
        </Typography>
        {iconPosition === GrowthSymbolIconPosition.right && <Remove height={20} width={20} color={text.secondary} />}
      </x.span>
    );
  }

  const isIncreasing = growth === CompetitorGrowth.Increasing;

  return (
    <x.span
      display="inline-flex"
      alignItems="center"
      color={isIncreasing ? text.success : text.danger}
      backgroundColor="inherit"
    >
      {iconPosition === GrowthSymbolIconPosition.left && <DirectionalArrow isUp={isIncreasing} />}
      <Typography color={color ?? text.strong._} component="span" ml={inner.base} variant={fontVariant}>
        {ellipsize && (
          <Tooltip variant="dark" title={customLabel} position="top">
            {finalLabel ?? (isIncreasing ? GROWTH_DESCRIPTIONS.increasing : GROWTH_DESCRIPTIONS.decreasing)}
          </Tooltip>
        )}
        {!ellipsize && (finalLabel ?? (isIncreasing ? GROWTH_DESCRIPTIONS.increasing : GROWTH_DESCRIPTIONS.decreasing))}
      </Typography>
      {iconPosition === GrowthSymbolIconPosition.right && <DirectionalArrow isUp={isIncreasing} />}
    </x.span>
  );
};

export { GrowthSymbol as default, CompetitorGrowth };
