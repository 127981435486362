import React from "react";
import { useThemeTokens, Typography, TypographyVariation } from "@alphasights/alphadesign-components";

type SpacerProps = {
  variant?: TypographyVariation;
};

const Spacer = ({ variant = "body" }: SpacerProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <Typography variant={variant} component="span" px={inner.base02}>
      |
    </Typography>
  );
};

export default Spacer;
