import { IconButton, ListOption, Popover, Typography } from "@alphasights/alphadesign-components";
import { Call, Copy, Mail, MobilePhone } from "@alphasights/alphadesign-icons";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { ProjectLeadAvatar, useNewNavigation } from "@alphasights/client-portal-shared";
import { Lead } from "@alphasights/portal-api-client";
import styled from "@xstyled/styled-components";
import { compact } from "lodash";
import React, { useState } from "react";
import { ProjectLeadCard } from "../Project/ProjectLeadCard";
import "./index.css";
import ExpiringComponent from "components/ExpiringComponent";

export const ContactInfoDropdown = ({ lead }: { lead: Lead }) => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<Element | undefined>(undefined);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const newNavigationEnabled = useNewNavigation();

  const copyToClipboard = (text: string, event: React.MouseEvent) => {
    navigator.clipboard.writeText(text);
    setTooltipAnchorEl(event.currentTarget);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handlePopoverOpen = ({ currentTarget }: { currentTarget: Element }) => {
    setAnchorEl(currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const open = Boolean(anchorEl);
  const phoneNumberCount = compact([lead.desktopNumber, lead.mobileNumber]).length;

  if (!newNavigationEnabled) {
    return <ContactInfoDropdownOld lead={lead} />;
  }

  return (
    <>
      <div onMouseEnter={handlePopoverOpen}>
        <ProjectLeadAvatar lead={{ ...lead, name: capitalizeWords(lead.name) }} className="aui-w-8 aui-h-8" />
      </div>
      <Popover
        closeOnMouseLeave={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        leaveDelay={100}
        zIndex={100}
        placement="bottom-end"
      >
        <PopoverContainer>
          <ListOption type="title" label="Project Lead" />
          <div className="aui-ml-2 aui-flex aui-flex-row aui-gap-2 aui-py-2">
            <ProjectLeadAvatar
              lead={{ ...lead, name: capitalizeWords(lead.name) }}
              className="aui-w-5 aui-h-5 aui-flex"
            />
            <Typography>{capitalizeWords(lead.name)}</Typography>
          </div>

          {lead.desktopNumber && (
            <ContactWrapper>
              <Link href={`tel:${lead.desktopNumber}`} className="aui-flex-grow">
                <ListOption type="text" label={lead.desktopNumber} leftIcon={<Call />} />
              </Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.desktopNumber, e)}
              >
                <Copy />
              </IconButton>
            </ContactWrapper>
          )}

          {lead.mobileNumber && (
            <ContactWrapper className="aui-flex aui-flex-row aui-gap-3 aui-items-center">
              <Link href={`tel:${lead.mobileNumber}`} className="aui-flex-grow">
                <ListOption
                  type="text"
                  label={lead.mobileNumber}
                  leftIcon={phoneNumberCount > 1 ? <MobilePhone /> : <Call />}
                />
              </Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.mobileNumber, e)}
              >
                <Copy />
              </IconButton>
            </ContactWrapper>
          )}

          {lead.email && (
            <ContactWrapper className="aui-flex aui-flex-row aui-gap-3 aui-items-center">
              <Link href={`mailto:${lead.email}`} className="aui-flex-grow">
                <ListOption type="text" label={lead.email} leftIcon={<Mail />} />
              </Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.email, e)}
              >
                <Copy />
              </IconButton>
            </ContactWrapper>
          )}
        </PopoverContainer>
      </Popover>

      {tooltipOpen && (
        <ExpiringComponent delay={2000} onExpire={() => setTooltipOpen(false)}>
          <CopiedPopover
            anchorEl={tooltipAnchorEl}
            open={tooltipOpen}
            placement="bottom"
            onClose={() => setTooltipOpen(false)}
          >
            <Typography variant="body-small">Copied!</Typography>
          </CopiedPopover>
        </ExpiringComponent>
      )}
    </>
  );
};

const ContactInfoDropdownOld = ({ lead }: { lead: Lead }) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
  const ref = React.useRef(null);
  const handlePopoverOpen = ({ currentTarget }: { currentTarget: Element }) => {
    setAnchorEl(currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div data-testid="cst-dropdown" className="aui-ml-auto">
        <div
          className="aui-flex aui-items-center aui-group aui-cursor-pointer aui-my-1"
          onMouseEnter={handlePopoverOpen}
        >
          <ProjectLeadAvatar lead={lead} className="aui-w-8 aui-h-8" />
          <div className="aui-flex aui-flex-col aui-leading-tight aui-ml-2">
            <div className="aui-font-bold aui-text-white">{lead.name && lead.name.split(" ")[0]}</div>
            <div className="aui-text-white aui-text-xs">
              {lead.role === "PROJECT_LEAD" ? "My Project Lead" : "Account Lead"}
            </div>
          </div>
        </div>
      </div>

      <Popover
        closeOnMouseLeave={true}
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        leaveDelay={100}
        zIndex={100}
        placement="bottom-end"
      >
        <ProjectLeadCard ref={ref} lead={lead} />
      </Popover>
    </>
  );
};

const Link = styled.a`
  color: inherit;
  text-decoration: none;
   &:hover {
      background-color: transparent !important;
`;

const PopoverContainer = styled.div`
  div[type="title"] {
    padding-bottom: ${tokens.spacing.inner.base};
  }
`;

const CopiedPopover = styled(Popover)`
  background-color: ${tokens.color.background.dark};
  color: ${tokens.color.text.inverse};
  padding: ${tokens.spacing.inner.base};
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacing.inner.base};
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: ${tokens.spacing.inner.base02};

  &:hover {
    background-color: ${tokens.color.background.neutral.hover};
  }

  .aui-flex-grow {
    flex-grow: 1;
  }

  button {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover button {
    opacity: 1;
  }
`;
