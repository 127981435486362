export const manageWatchlists = "Manage Watchlists";
export const newWatchlistButton = "New Watchlist";
export const watchlists = "Watchlists";
export const addToWatchlist = "Add to Watchlist";
export const addedToWatchlist = "Added to Watchlist";

export const ToastText = {
  addedTo: "added to",
  removedFrom: "removed from",
  leadingCompetitors: "leading competitors",
  featuredVendors: "featured vendors",
  companies: "companies",
  relevantCompanies: "relevant companies",
  newWatchlist: "new watchlist",
  company: "Company",
  errorMessage: "not successfully",
};

export enum RequestType {
  add = "ADD",
  delete = "DELETE",
}
