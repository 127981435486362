import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

type ChartTooltipProps = {
  children: React.ReactNode | React.ReactNode[] | string;
};

const ChartTooltip = ({ children }: ChartTooltipProps) => {
  const { components, typography } = useThemeTokens();
  const tooltipProps = {
    ...components.tooltip.body,
    ...components.tooltip.variant["dark"].body,
    ...typography.body.regular,
    border: "0",
    role: "tooltip",
    maxWidth: 480,
  };

  return <x.div {...tooltipProps}>{children}</x.div>;
};

export default ChartTooltip;
