//ADD FREE CUSTOMER PRIMER WHEN AVAILABLE
export enum freePrimers {
  companyPrimer = "https://portal.alphasights.com/alphanow?selectedContentId=6e240555-50a8-4175-b0dc-250b2df23da9",
  marketPrimer = "https://portal.alphasights.com/alphanow?selectedContentId=041882fe-9e4b-4045-8161-48926a8ed8cb",
  customerPrimer = "https://portal.alphasights.com/alphanow?selectedContentId=c69f87e5-08b5-484b-a129-c4e68bc0b4c3",
}

export const CURRENCY_SYMBOL: Record<string, string> = {
  USD: "$",
  GBP: "£",
  EUR: "€",
  CAD: "CA$",
};

export const primersHeaderActionTabs = {
  companyPrimer: ["Overview", "Segmentation", "Commercial Model", "Competitors", "Outlook"],
  marketPrimer: ["Overview", "Size and Growth", "Competitor Dynamics", "Segmentation", "Outlook"],
  customerPrimer: [
    "Overview",
    "Usage and Awareness",
    "Key Purchasing Criteria",
    "Net Promoter Score",
    "Vendor Switching",
    "Outlook",
  ],
};
