import { useAlphaToast } from "@alphasights/alphadesign-components";
import { differenceInMinutes } from "date-fns";
import { useEffect, useMemo, useState } from "react";

export function useCachedValue<T>({ fn }: { fn: (value: string) => Promise<T> }) {
  const CACHE_EXPIRE_MINUTES = 5;

  const { toast } = useAlphaToast();

  const [isLoading, setIsLoading] = useState(false);
  const [cache, setCache] = useState<{ [key: string]: T & { __loadedAt: Date } }>({});
  const [requestedValue, setRequestedValue] = useState<string>();

  useEffect(() => {
    if (!requestedValue) return;

    const cached = cache[requestedValue];

    if (cached && differenceInMinutes(new Date(), cached.__loadedAt) < CACHE_EXPIRE_MINUTES) return;

    setIsLoading(true);

    fn(requestedValue)
      .then((value) => {
        setCache((previous) => {
          return {
            ...previous,
            [requestedValue]: { ...value, __loadedAt: new Date() },
          };
        });
      })
      .catch(() =>
        toast.error({
          message: "There was an error. Please refresh the page.",
        })
      )
      .finally(() => setIsLoading(false));
  }, [requestedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(() => {
    if (!requestedValue) return null;
    if (!cache[requestedValue]) return null;

    return cache[requestedValue];
  }, [cache, requestedValue]);

  return useMemo(() => {
    return {
      setRequestedValue,
      isLoading,
      value,
    };
  }, [setRequestedValue, value, isLoading]);
}
