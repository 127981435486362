import { create } from "zustand";
import { mapOverview, mapSizeAndGrowth, mapCompetitorDynamics, mapSegmentation } from "../utils/mappers";
import { mapCompanies, mapCompaniesV2 } from "../../utils/utils";

type storeState = {
  companies: { id: string; name: string; logoLink?: string }[];
  companiesV2: { name: string; logoLink?: string }[];
  overview: Overview;
  sizeAndGrowth: SizeAndGrowth;
  segmentation: Segmentation;
  competitorDynamics: CompetitorDynamicsEntry[];
  outlook: CitableOutlook[];
  chartColors: string[];
  setOverview: (data: OverviewAPI) => void;
  setSizeAndGrowth: (data: SizeAndGrowthAPI) => void;
  setSegmentation: (data: SegmentationAPI) => void;
  setCompetitorDynamics: (data: any) => void;
  setOutlook: (data: CitableOutlook[]) => void;
  setCompanies: (companies: Record<number, CDSCompanyNames>) => void;
  setCompaniesV2: (companies: Record<string, string>) => void;
};

export const useMarketPrimersStore = create<storeState>((set) => ({
  companies: [],
  companiesV2: [],
  overview: {
    currency: "",
    description: { citedBy: [] },
    exampleUseCases: { citedBy: [] },
    customers: { citedBy: [] },
    products: { citedBy: [] },
    regions: { citedBy: [] },
    keyOperatingMetrics: { citedBy: [] },
    marketPrimerTitle: { citedBy: [] },
  },
  sizeAndGrowth: {
    currentMarketSizeYear: 0,
    data: [],
  },
  segmentation: {
    customers: [],
    regions: [],
    products: [],
    customised: [],
  },
  outlook: [],
  competitorDynamics: [],
  chartColors: ["#3C83F6", "#EC4699", "#0CC09E", "#EF4343", "#F59F0A", "#D535ED", "#F97415", "#8055F7", "#9CA1B0"],
  setOverview: (data: OverviewAPI) => set({ overview: mapOverview(data) }),
  setSizeAndGrowth: (data: SizeAndGrowthAPI) => set({ sizeAndGrowth: mapSizeAndGrowth(data) }),
  setCompetitorDynamics: (data: CompetitorDynamicsEntryAPI) => set({ competitorDynamics: mapCompetitorDynamics(data) }),
  setSegmentation: (data: SegmentationAPI) => set({ segmentation: mapSegmentation(data) }),
  setOutlook: (data: CitableOutlook[]) => set({ outlook: data }),
  setCompanies: (data: Record<number, CDSCompanyNames>) => set({ companies: mapCompanies(data) }),
  setCompaniesV2: (data: Record<string, string>) => set({ companiesV2: mapCompaniesV2(data) }),
}));
