import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon, Logo, LogoVariation } from "@alphasights/alphadesign-components";
import { GenerativeAi, Intercom as IntercomIcon, Library } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { UserNotificationsProvider } from "components/GlobalNav/UserNotificationsContextProvider";
import { VIEW_ALPHA_GPT_PERMISSION, VIEW_CONTENT_PERMISSION } from "constants/AlphaNow";
import { AvatarOption } from "./AvatarOption";
import { NotificationsOption } from "./NotificationsOption";
import * as S from "./SidebarNav.styled";
import { SidebarOption } from "./SidebarOption";
import { ProjectsOption } from "./ProjectsOption/ProjectsOption";
import { useEnv, useNewNavigation } from "@alphasights/client-portal-shared";
import { useLocation } from "react-router-dom";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";

export const SidebarNavContent = () => {
  const newNavigationEnabled = useNewNavigation();
  const currentUser = useCurrentUser();
  const [isSelected, setIsSelected] = useState(false);
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);
  const env = useEnv();
  const location = useLocation();
  const previousLocation = useRef(location);

  const memoizedSetIsSelected = useCallback((value: boolean) => {
    setIsSelected(value);
  }, []);

  const closeIntercom = () => {
    if (isIntercomOpen) {
      window.Intercom("hide");
      setIsIntercomOpen(false);
    }
  };

  useEffect(() => {
    if (previousLocation.current !== location && isIntercomOpen) {
      window.Intercom("hide");
      setIsIntercomOpen(false);
    }
    previousLocation.current = location;
  }, [location, isIntercomOpen]);

  if (!newNavigationEnabled) {
    return null;
  }

  const projectsPath = `/${myAlphaSightsBaseUrl}`;

  return (
    <S.SidebarNavWrapper>
      <S.TopContainer>
        <S.NavLogoContainer>
          <Logo data-testid="vertical-nav-logo" variation={LogoVariation.Light} size="xxs" />
        </S.NavLogoContainer>

        <S.OptionsContainer>
          {currentUser?.alphaNowEnabled && (
            <SidebarOption
              id="library"
              to="/alphanow"
              permission={VIEW_CONTENT_PERMISSION}
              label="Library"
              icon={<Library />}
              onClick={closeIntercom}
            />
          )}

          {!currentUser?.accessOnly && <ProjectsOption projectsPath={projectsPath} closeIntercom={closeIntercom} />}

          {currentUser?.alphaGPTEnabled && (
            <SidebarOption
              id="alphagpt"
              to="/alphagpt"
              permission={VIEW_ALPHA_GPT_PERMISSION}
              label="AlphaGPT"
              icon={<GenerativeAi />}
              onClick={closeIntercom}
            />
          )}
        </S.OptionsContainer>
      </S.TopContainer>

      <S.BottomContainer>
        <>
          {currentUser && (
            <>
              {env?.enableIntercom && (
                <IntercomLauncher
                  isIntercomOpen={isIntercomOpen}
                  toggleIntercom={() => setIsIntercomOpen(!isIntercomOpen)}
                />
              )}
              <NotificationsOption
                isSelected={isSelected}
                setIsSelected={memoizedSetIsSelected}
                closeIntercom={closeIntercom}
              />
            </>
          )}
        </>
        <AvatarOption closeIntercom={closeIntercom} />
      </S.BottomContainer>
    </S.SidebarNavWrapper>
  );
};

export const SidebarNav = () => {
  const currentUser = useCurrentUser();
  return (
    <UserNotificationsProvider enabled={!!currentUser}>
      <SidebarNavContent />
    </UserNotificationsProvider>
  );
};

interface IntercomLauncherProps {
  isIntercomOpen: boolean;
  toggleIntercom: () => void;
}

const IntercomLauncher: React.FC<IntercomLauncherProps> = ({ isIntercomOpen, toggleIntercom }) => {
  const [intercomBooted, setIntercomBooted] = useState(false);
  const Intercom = window.Intercom;

  const openIntercomPanel = () => {
    Intercom(isIntercomOpen ? "hide" : "show");
    toggleIntercom();
  };

  const checkIntercomBooted = useCallback(() => {
    const timeout = setTimeout(() => clearInterval(interval), 30000);
    const interval = setInterval(() => {
      if (Intercom.booted) {
        setIntercomBooted(true);
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 100);
  }, [Intercom]);

  useEffect(() => {
    checkIntercomBooted();
    Intercom("onHide", () => {
      if (isIntercomOpen) {
        toggleIntercom();
      }
    });
  }, [Intercom, checkIntercomBooted, isIntercomOpen, toggleIntercom]);

  return (
    <S.IntercomWrapper
      open={isIntercomOpen}
      intercomBooted={intercomBooted}
      onClick={openIntercomPanel}
      data-testid="intercom-launcher"
    >
      <Icon size="large" color="inverse">
        <IntercomIcon />
      </Icon>
    </S.IntercomWrapper>
  );
};
