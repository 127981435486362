import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTContentRecommendationStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const contentRecomendation = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    pt: inner.base06,
  };

  const viewAllContentButton = {
    w: "fit-content",
    mt: inner.base06,
  };

  const requestPrimerButton = {
    w: "fit-content",
  };

  const requestPrimerSection = {
    mt: inner.base06,
  };

  const freePrimerSkeleton = {
    mt: inner.base06,
  };

  return {
    contentRecomendation,
    viewAllContentButton,
    requestPrimerButton,
    requestPrimerSection,
    freePrimerSkeleton,
  };
};
