const innerContent = (content: string, wrapper: string = "'") => {
  return content.startsWith(wrapper) && content.endsWith(wrapper) ? content.substring(1, content.length - 1) : content;
};

const cleanKeyQuote = (quote: string) => {
  const cleaned = innerContent(innerContent(quote.trim()), '"');
  return (cleaned.endsWith(".") ? cleaned.substring(0, cleaned.length - 1) : cleaned).toLowerCase();
};

export { cleanKeyQuote };
