import { fetch } from "hooks/useApi";
import { suggestionsInfo } from "./helpers";
import { contentService } from "services/content";

const dropExpertBio = (result) => {
  // drop initial entries that have expert name/role/etc as these are in topbar/flyout already
  const firstSectionPosition = result.sentences.findIndex((s) => s.speaker === "section");
  const sentencesFiltered = result.sentences.slice(firstSectionPosition);
  const mentionsFiltered = result.mentions.filter(({ position }) => position >= sentencesFiltered[0].position);
  const highlightsInDisposedPart = result.sentences
    .slice(0, firstSectionPosition)
    .reduce((agg, cur) => (cur.value.match(/<em>/g) || []).length + agg, 0);
  const totalMatchesUpdated = result.totalMatches - highlightsInDisposedPart;

  return {
    ...result,
    sentences: sentencesFiltered,
    totalMatches: totalMatchesUpdated,
    mentions: mentionsFiltered,
  };
};

const transformResult = (result) => {
  const isSummary = result.sentences.find(({ speaker }) => speaker === "section");
  return isSummary ? dropExpertBio(result) : result;
};

export default {
  getBookmarks: (project) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverable-bookmarks`,
    })
      .then((res) => res.json())
      .then(({ interactionsIds }) => interactionsIds);
  },
  setBookmark: ({ project, id, bookmarked }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverable-bookmarks`,
      method: "POST",
      body: JSON.stringify({ interactionId: id, toBookmark: bookmarked }),
    })
      .then((res) => res.json())
      .then(({ bookmarked }) => bookmarked);
  },
  fetchDeliverable: ({ project, transcriptRequest, keywords, fetchRaw = false }) => {
    const keywordParams = keywords ? `keywords=${keywords.map(encodeURIComponent).join(",")}` : "";

    return fetch({
      url: `/api/projects/${project.token}/transcripts/${transcriptRequest.token}/json?${keywordParams}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((json) => (fetchRaw ? json : transformResult(json)));
  },
  fetchAlphaNowContent: ({ project, keywords }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverables/content${keywords ? `?keywords=${keywords}` : ""}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then(({ suggestedContent, privateContent, pitchedContent = [], ...others }) => {
        return {
          ...others,
          suggestedContent: suggestedContent.map(suggestionsInfo),
          privateContent: privateContent.map(suggestionsInfo),
          pitchedContent: pitchedContent.map(suggestionsInfo),
        };
      })
      .catch((err) => {
        console.error(err);
        return {};
      });
  },
  fetchAlphaNowContentById: ({ project, id }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverables/content/${id}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((res) => suggestionsInfo(res));
  },
  getInteractionsWithDeliverables: (token) => {
    return fetch({
      url: `/api/projects/${token}/interactions/find`,
      method: "POST",
      skipAlert: true,
      body: JSON.stringify({
        filters: {
          transcript_status: ["Available", "Requested", "Completed"],
          profile_activity: ["HiddenOrNot"],
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        return {
          ...json,
          edges: json.edges.filter((edge) =>
            edge.node.recordings.find((rec) => rec.visibleToClient || rec.transcriptRequests.find((tr) => tr.completed))
          ),
        };
      });
  },
  fetchContentBookmark: (contentIds) => {
    return fetch({
      url: `/api/client-bookmarks?contentIds=${contentIds}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    })
      .then((res) => res.json())
      .then((bookmarks) => bookmarks[0]);
  },
  postContentBookmark: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/bookmark`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
    });
  },
  deleteContentBookmark: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/bookmark`,
      handleForbidden: true,
      skipAlert: true,
      method: "DELETE",
    });
  },
  fetchContentCredits: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/credits`,
      skipAlert: true,
    }).then((res) => res.json());
  },
  getAiSummaries: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  getAiSummariesStatus: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/status`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  generateAiSummary: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
    }).then((res) => res.json());
  },
  deleteAiSummaries: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "DELETE",
    }).then((res) => res.json());
  },
  submitAiSummaryFeedback: (projectToken, interactionId, recordingId, summaryType, feedbackType) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/${summaryType}/feedback`,
      handleForbidden: true,
      skipAlert: false,
      method: "PUT",
      body: JSON.stringify({ type: feedbackType.toUpperCase() }),
    });
  },
  removeAiSummaryFeedback: (projectToken, interactionId, recordingId, summaryType) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/${summaryType}/feedback`,
      handleForbidden: true,
      skipAlert: false,
      method: "DELETE",
    });
  },
  fetchContentById: (contentId, projectToken) => contentService.fetchContentById(contentId, undefined, projectToken),
  createContentVisit: ({ recommendationId, contentId, projectToken }) =>
    fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/visit`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({ recommendationId }),
    }),
};
