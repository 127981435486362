import { create } from "zustand";
import { mapOutlook, mapUsageAndAwareness, mapKeyPurchasingCriteria, mapNetPromoterScore } from "../utils/mappers";
import { mapCompanies, mapCompaniesV2 } from "../../utils/utils";

type storeState = {
  isSidebarExpanded: boolean;
  companies: { id: string; name: string; logoLink: string | undefined }[];
  companiesV2: { name: string; logoLink?: string }[];
  overview: CustomerPrimerOverviewAPI;
  usageAndAwareness: UsageAndAwareness;
  keyPurchasingCriteria: KeyPurchasingCriteria;
  nps: NetPromoterScore[];
  churn: VendorSwitchingAPI;
  outlook: CustomerPrimerOutlook;
  setOverview: (data: CustomerPrimerOverviewAPI) => void;
  setUsageAndAwareness: (data: UsageAndAwareness) => void;
  setKeyPurchasingCriteria: (data: KeyPurchasingCriteria) => void;
  setNetPromoterScore: (data: NetPromoterScore[]) => void;
  setChurn: (data: VendorSwitchingAPI) => void;
  setOutlook: (data: CustomerPrimerOutlook) => void;
  setCompanies: (companies: Record<number, CDSCompanyNames>) => void;
  setCompaniesV2: (companies: Record<string, string>) => void;
  setIsSidebarExpanded: (data: boolean) => void;
  setPrimerState: (data: any) => void;
};

const emptyCitableValue = {
  citedBy: [],
  value: "",
  isSensible: false,
};
export const useCustomerPrimersStore = create<storeState>((set) => ({
  isSidebarExpanded: true,
  companies: [],
  companiesV2: [],
  overview: {
    id: "",
    cdsAlphaCompanyId: 0,
    title: "",
    lastUpdatedAt: "",
    marketContext: {
      id: emptyCitableValue,
      value: emptyCitableValue,
    },
    featuredVendors: [],
  },
  usageAndAwareness: {
    vendors: [],
    customers: [],
  },
  keyPurchasingCriteria: {
    ranked: [],
    vendors: [],
  },
  nps: [],
  churn: {
    ranks: [],
    barriersToSwitch: {
      summary: {
        id: emptyCitableValue,
        value: emptyCitableValue,
      },
      categories: [],
    },
    driveToSwitch: {
      summary: {
        id: emptyCitableValue,
        value: emptyCitableValue,
      },
      categories: [],
    },
  },
  outlook: {
    change: {
      ranks: [],
      summary: {
        id: emptyCitableValue,
        value: emptyCitableValue,
      },
      categories: [],
    },
    allocation: [],
    emerging: [],
    excited: {
      summary: {
        id: emptyCitableValue,
        value: emptyCitableValue,
      },
      categories: [],
    },
    concerned: {
      summary: {
        id: emptyCitableValue,
        value: emptyCitableValue,
      },
      categories: [],
    },
  },
  setPrimerState: (data: any) =>
    set({
      companiesV2: mapCompaniesV2(data.mapOfCompanyNameToLogoLinks),
      overview: data.overview,
      usageAndAwareness: mapUsageAndAwareness(data.usageAndAwareness),
      keyPurchasingCriteria: mapKeyPurchasingCriteria(data.keyPurchasingCriteria),
      nps: mapNetPromoterScore(data.netPromoterScore),
      churn: data.vendorSwitching,
      outlook: mapOutlook(data.outlook),
    }),
  setOverview: (data: any) => set({ overview: data }),
  setUsageAndAwareness: (data: any) => set({ usageAndAwareness: mapUsageAndAwareness(data) }),
  setKeyPurchasingCriteria: (data: any) => set({ keyPurchasingCriteria: mapKeyPurchasingCriteria(data) }),
  setNetPromoterScore: (data: any) => set({ nps: mapNetPromoterScore(data) }),
  setChurn: (data: any) => set({ churn: data }),
  setOutlook: (data: any) => set({ outlook: mapOutlook(data) }),
  setCompanies: (data: Record<number, CDSCompanyNames>) => set({ companies: mapCompanies(data) }),
  setCompaniesV2: (data: Record<string, string>) => set({ companiesV2: mapCompaniesV2(data) }),
  setIsSidebarExpanded: (data: boolean) => set({ isSidebarExpanded: data }),
}));
