import { useEffect, useReducer } from "react";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

enum Action {
  Initialize = "INITIALIZE",
  FetchOverview = "FETCH_OVERVIEW",
  FetchPrimers = "FETCH_PRIMERS",
  FetchInsights = "FETCH_INSIGHTS",
  FetchCompetitors = "FETCH_COMPETITORS",
  FetchCustomers = "FETCH_CUSTOMERS",
}

type InitializeAction = {
  type: Action.Initialize;
  payload: {};
};

// OVERVIEW

type FetchOverviewActionPayloadType = {
  companyId: number;
};

type FetchOverviewAction = {
  type: Action.FetchOverview;
  payload: FetchOverviewActionPayloadType;
};

// PRIMERS

type FetchPrimersActionPayloadType = {
  contentIds: string[];
};

type FetchPrimersAction = {
  type: Action.FetchPrimers;
  payload: FetchPrimersActionPayloadType;
};

// INSIGHTS

type FetchInsightsActionPayloadType = {
  contentIds: string[];
  contentType: string;
  theme: string;
};

type FetchInsightsAction = {
  type: Action.FetchInsights;
  payload: FetchInsightsActionPayloadType;
};

// ANGLE SECTIONS

type FetchAngleSectionActionPayloadType = {
  companyIds: number[];
};

type FetchAngleSectionAction = {
  payload: FetchAngleSectionActionPayloadType;
  type?: any;
};

// COMPETITORS

interface FetchCompetitorsSectionAction extends FetchAngleSectionAction {
  type: Action.FetchCompetitors;
}

// CUSTOMERS

interface FetchCustomersSectionAction extends FetchAngleSectionAction {
  type: Action.FetchCustomers;
}

////////////////////////////////////////////////////////////////////////

type PayloadType =
  | FetchOverviewActionPayloadType
  | FetchPrimersActionPayloadType
  | FetchInsightsActionPayloadType
  | FetchAngleSectionActionPayloadType;

type ActionType =
  | InitializeAction
  | FetchOverviewAction
  | FetchPrimersAction
  | FetchInsightsAction
  | FetchCompetitorsSectionAction
  | FetchCustomersSectionAction;

type TrackedDataType = {
  companyId: number | null;
  primerContentIds: string[] | null;
  insightsContentIds: string[] | null;
  insightsContentType: string | null;
  insightsTheme: string | null;
  competitorCompanyIds: number[] | null;
  customerCompanyIds: number[] | null;
};

const isTrackableDataReady = (data: TrackedDataType) => {
  return !Object.values(data).some((value) => value === null);
};

const initialState: TrackedDataType = {
  companyId: null,
  primerContentIds: null,
  insightsContentIds: null,
  insightsContentType: null,
  insightsTheme: null,
  competitorCompanyIds: null,
  customerCompanyIds: null,
};

const reducer = (state: TrackedDataType, action: ActionType) => {
  switch (action.type) {
    case Action.FetchOverview:
      return {
        ...state,
        companyId: action.payload.companyId,
      };
    case Action.FetchPrimers:
      return {
        ...state,
        primerContentIds: action.payload.contentIds,
      };
    case Action.FetchInsights:
      const { contentType, contentIds, theme } = action.payload;
      return {
        ...state,
        insightsContentIds: contentIds,
        insightsContentType: contentType,
        insightsTheme: theme,
      };
    case Action.FetchCompetitors:
      return {
        ...state,
        competitorCompanyIds: action.payload.companyIds,
      };
    case Action.FetchCustomers:
      return {
        ...state,
        customerCompanyIds: action.payload.companyIds,
      };
    default:
      return { ...state };
  }
};

const useCompanyPageTracking = () => {
  const { logHit } = useTrackUserAction();
  const [data, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isTrackableDataReady(data)) {
      logHit({
        origin: HitOrigin.companyPage,
        action: HitAction.alphaNowOpenCompanyPage,
        details: data,
      });
    }
  }, [data, logHit]);

  const onDataFetch = (action: ActionType) => {
    dispatch(action);
  };

  return {
    onDataFetch,
  };
};

export { useCompanyPageTracking as default, Action };
export type {
  ActionType,
  PayloadType,
  FetchOverviewActionPayloadType,
  FetchPrimersActionPayloadType,
  FetchInsightsActionPayloadType,
  FetchAngleSectionActionPayloadType,
};
