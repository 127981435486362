import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { RoundedButton as AdsRoundedButton } from "@alphasights/alphadesign-components";

export const RoundedButton = styled(AdsRoundedButton)(({ selected }: { selected: boolean }) => {
  return `
    height: ${tokens.spacing.inner.base08};
    white-space: nowrap;
    ${selected ? `background: ${tokens.color.background.infoSubtle} !important;` : ""}
    ${selected ? `border-color: ${tokens.color.border.selected} !important;` : ""}
  `;
});
