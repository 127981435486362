import React from "react";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { x } from "@xstyled/styled-components";
import NotableCompetitors from "./NotableCompetitors/NotableCompetitors";
import { revenueCitation } from "../../../utils/utils";
import OutlookEntries from "../../../components/OutlookEntries/OutlookEntries";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { Citation } from "components/CitationContext/Citation";
import GrowthSymbol from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const SegmentationTab = ({ currency, segment }: { currency: string; segment: Segment }) => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();
  const { revenue, trend, predictedGrowth, overview, outlook, notableCompetitors, dynamics } = segment;

  const rangeFormatSign = (min: number, max: number) => {
    // Add "+" or "-" based on the sign of the values
    const formattedMin = min >= 0 ? `${min > 0 ? "+" : ""}${min}` : min;
    const formattedMax = max >= 0 ? `${max > 0 ? "+" : ""}${max}` : max;

    return `(${formattedMin}% to ${formattedMax}% Predicted 3 yr CAGR)`;
  };

  return (
    <x.div marginTop={layout.base03}>
      <x.div display="grid" gap={inner.base04}>
        <div>
          <CitationWrapper value={revenue} label="Revenue" labelWidth="120px">
            {revenue.max.value && revenue.min.value ? revenueCitation({ currency, value: revenue }) : "—"}
          </CitationWrapper>
        </div>
        <div>
          <x.div display="flex" gap={inner.base03} alignItems="center">
            <PrimerFieldTitle text="trend" w="120px" />
            <SensitiveDataContainer isSensitive={trend.isSensitive}>
              <Citation
                value={trend}
                renderer={({ value: trend }) => {
                  return (
                    <x.div display="flex" gap={inner.base}>
                      <GrowthSymbol {...{ growth: trend, variant: "body" }} />
                      {predictedGrowth.min.value != null && predictedGrowth.max.value != null && (
                        <Typography>{rangeFormatSign(predictedGrowth.min.value, predictedGrowth.max.value)}</Typography>
                      )}
                    </x.div>
                  );
                }}
              />
            </SensitiveDataContainer>
          </x.div>
        </div>
      </x.div>
      <x.div display="grid" gap={inner.base05} marginTop={inner.base05}>
        <div>
          <CitationWrapper
            value={overview}
            label="Sub-segment Overview"
            verticalLabel
            titleToContentGap={inner.base02}
          />
        </div>
        <div>
          <CitationWrapper
            value={dynamics}
            label="Sub-segment Dynamics"
            verticalLabel
            titleToContentGap={inner.base02}
          />
        </div>
        <x.div display="flex" flexDirection="column" gap={inner.base02}>
          <PrimerFieldTitle text="Sub-segment Outlook" />
          <OutlookEntries entries={outlook} />
        </x.div>
        <x.div display="flex" flexDirection="column" gap={inner.base06}>
          <PrimerFieldTitle text="Notable Competitors" />
          <div>
            <NotableCompetitors notableCompetitors={notableCompetitors} />
          </div>
        </x.div>
      </x.div>
    </x.div>
  );
};

export default SegmentationTab;
