import React, { FC } from "react";

import { FilterButton } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { UpcomingValue } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/UpcomingFilter/types";
import {
  UPCOMING,
  UPCOMING_FILTER_OPTIONS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/UpcomingFilter";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";

interface UpcomingFilterProps {
  value: UpcomingValue;
  onChange?: (value: Record<SearchFilters.upcomingFilter, UpcomingValue>) => void;
}

const UpcomingFilter: FC<UpcomingFilterProps> = ({ value = UPCOMING_FILTER_OPTIONS.all, onChange = () => {} }) => {
  const upcomingFilterEnabled = value === UPCOMING_FILTER_OPTIONS.upcoming;

  const handleFilterChange = () => {
    const newValue = upcomingFilterEnabled ? UPCOMING_FILTER_OPTIONS.all : UPCOMING_FILTER_OPTIONS.upcoming;
    onChange({ [SearchFilters.upcomingFilter]: newValue });
  };

  return (
    <FilterButton onClick={handleFilterChange} selected={upcomingFilterEnabled}>
      {UPCOMING}
    </FilterButton>
  );
};

export default UpcomingFilter;
