import { useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, isEmpty, isEqual, merge } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { useNewNavigation } from "@alphasights/client-portal-shared";

import { getQueryString } from "utils/query-string";
import { LIBRARY_PATHNAME } from "pages/AlphaNowPage/constants";

import { INITIAL_QUERY_STATE } from "./constants";
import { isLibraryPath } from "./utils";
import { Query } from "./types";

// this hook should not be used on its own as updates will not propagate correctly through the app
// it should only be used within useAppSearchContext
const useAlphaNowQuery = () => {
  // TODO [RD1-209] remove old navigation
  const newNavigationEnabled = useNewNavigation();

  const history = useHistory();
  const location = useLocation();

  // TODO [RD1-209] remove old navigation
  /* -------------------------- OLD NAVIGATION -------------------------- */
  const [isBackDisabled, setIsBackDisabled] = useState<boolean>(true);
  const [isForwardDisabled, setIsForwardDisabled] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [queryHistory, setQueryHistory] = useState<Query[]>([
    merge({}, cloneDeep(INITIAL_QUERY_STATE), getQueryString()),
  ]);

  const lastQueryIndex = useMemo(() => queryHistory.length - 1, [queryHistory]);

  useEffect(() => {
    if (!newNavigationEnabled && !isLibraryPath(location.pathname)) {
      // Reset query history when user navigates away from the library
      setIsBackDisabled(true);
      setIsForwardDisabled(true);
      setCurrentIndex(0);
      setQueryHistory([cloneDeep(INITIAL_QUERY_STATE)]);
    }
  }, [location, newNavigationEnabled]);

  useEffect(() => {
    if (!newNavigationEnabled && isLibraryPath(location.pathname)) {
      setIsBackDisabled(currentIndex === 0);
      setIsForwardDisabled(currentIndex === lastQueryIndex);
      const handler = setTimeout(() => {
        const searchParams = qs.stringify(queryHistory[currentIndex]);
        history.push({ search: `?${searchParams}` });
      }, 10);
      return () => clearTimeout(handler);
    }
  }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useCallback(
    (step: number) => {
      const isForwardAndNotLast = step === 1 && currentIndex !== lastQueryIndex;
      const isBackAndNotFirst = step === -1 && currentIndex !== 0;
      const canNavigate = isForwardAndNotLast || isBackAndNotFirst;
      canNavigate && setCurrentIndex(currentIndex + step);
    },
    [currentIndex, lastQueryIndex, setCurrentIndex]
  );

  const goBack = () => navigate(-1);
  const goForward = () => navigate(1);

  const _updateQueryOld = useCallback(
    (queryUpdate: Partial<Query>) => {
      const currQuery = queryHistory[currentIndex];
      const newQuery = { ...cloneDeep(currQuery), ...cloneDeep(queryUpdate) };

      if (isEqual(currQuery, newQuery)) return;

      const newIndex = currentIndex + 1;
      const updatedHistory =
        currentIndex === lastQueryIndex ? [...queryHistory] : queryHistory.slice(0, currentIndex + 1);
      updatedHistory.push({ ...newQuery });

      setQueryHistory(cloneDeep(updatedHistory));
      setCurrentIndex(newIndex);
    },
    [queryHistory, currentIndex, lastQueryIndex, setQueryHistory, setCurrentIndex]
  );

  /* -------------------------------------------------------------------- */

  /* -------------------------- NEW NAVIGATION -------------------------- */
  const [query, setQuery] = useState<Query>(merge(cloneDeep(INITIAL_QUERY_STATE), getQueryString()));

  useEffect(() => {
    if (newNavigationEnabled) {
      if (isLibraryPath(location.pathname)) {
        const newQuery = merge(cloneDeep(INITIAL_QUERY_STATE), cloneDeep(getQueryString(location?.search)));
        if (!isEqual(query, newQuery)) {
          setQuery(cloneDeep(newQuery));
        }
      } else if (!isEmpty(query)) {
        // clear query when user has navigated away from the library
        setQuery(cloneDeep(INITIAL_QUERY_STATE));
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const _updateQueryNew = useCallback(
    (queryUpdate: Partial<Query>) => {
      const newQuery = { ...cloneDeep(query), ...cloneDeep(queryUpdate) };

      if (isEqual(query, newQuery)) return;

      const searchParams = qs.stringify(newQuery);
      history.push({ pathname: `/${LIBRARY_PATHNAME}`, search: `?${searchParams}` });
    },
    [query, history]
  );
  /* -------------------------------------------------------------------- */

  const updateQuery = newNavigationEnabled ? _updateQueryNew : _updateQueryOld; // eslint-disable-line react-hooks/exhaustive-deps
  const currentQuery = newNavigationEnabled ? query : queryHistory[currentIndex];

  return {
    query: currentQuery,
    updateQuery,
    navigate,
    goBack,
    goForward,
    isBackDisabled,
    isForwardDisabled,
  };
};

export default useAlphaNowQuery;
