export const COUNTRY_CODES = [
  {
    name: "Afghanistan",
    code: "93",
    isoCode: "af",
    example: "70 123 4567",
    order: 2,
  },
  {
    name: "Albania",
    code: "355",
    isoCode: "al",
    example: "66 212 3456",
    order: 2,
  },
  {
    name: "Algeria",
    code: "213",
    isoCode: "dz",
    example: "551 23 45 67",
    order: 2,
  },
  {
    name: "American Samoa",
    code: "1684",
    isoCode: "as",
    example: "733-1234",
    order: 2,
  },
  { name: "Andorra", code: "376", isoCode: "ad", example: "312 345", order: 2 },
  {
    name: "Angola",
    code: "244",
    isoCode: "ao",
    example: "923 123 456",
    order: 2,
  },
  {
    name: "Anguilla",
    code: "1264",
    isoCode: "ai",
    example: "235-1234",
    order: 2,
  },
  {
    name: "Antigua and Barbuda",
    code: "1268",
    isoCode: "ag",
    example: "464-1234",
    order: 2,
  },
  {
    name: "Argentina",
    code: "54",
    isoCode: "ar",
    example: "9 11 2345-6789",
    order: 2,
  },
  {
    name: "Armenia",
    code: "374",
    isoCode: "am",
    example: "77 123456",
    order: 2,
  },
  { name: "Aruba", code: "297", isoCode: "aw", example: "560 1234", order: 2 },
  {
    name: "Australia",
    code: "61",
    isoCode: "au",
    example: "412 345 678",
    order: 2,
  },
  {
    name: "Austria",
    code: "43",
    isoCode: "at",
    example: "664 123456",
    order: 2,
  },
  {
    name: "Azerbaijan",
    code: "994",
    isoCode: "az",
    example: "40 123 45 67",
    order: 2,
  },
  {
    name: "Bahamas",
    code: "1242",
    isoCode: "bs",
    example: "359-1234",
    order: 2,
  },
  {
    name: "Bahrain",
    code: "973",
    isoCode: "bh",
    example: "3600 1234",
    order: 2,
  },
  {
    name: "Bangladesh",
    code: "880",
    isoCode: "bd",
    example: "1812-345678",
    order: 2,
  },
  {
    name: "Barbados",
    code: "1246",
    isoCode: "bb",
    example: "250-1234",
    order: 2,
  },
  {
    name: "Belarus",
    code: "375",
    isoCode: "by",
    example: "29 491-19-11",
    order: 2,
  },
  {
    name: "Belgium",
    code: "32",
    isoCode: "be",
    example: "470 12 34 56",
    order: 2,
  },
  { name: "Belize", code: "501", isoCode: "bz", example: "622-1234", order: 2 },
  {
    name: "Benin",
    code: "229",
    isoCode: "bj",
    example: "90 01 12 34",
    order: 2,
  },
  {
    name: "Bermuda",
    code: "1441",
    isoCode: "bm",
    example: "370-1234",
    order: 2,
  },
  {
    name: "Bhutan",
    code: "975",
    isoCode: "bt",
    example: "61 123 456",
    order: 2,
  },
  {
    name: "Bolivia",
    code: "591",
    isoCode: "bo",
    example: "71234567",
    order: 2,
  },
  {
    name: "Bosnia and Herzegovina",
    code: "387",
    isoCode: "ba",
    example: "61 123 456",
    order: 2,
  },
  {
    name: "Botswana",
    code: "267",
    isoCode: "bw",
    example: "71 123 456",
    order: 2,
  },
  {
    name: "Brazil",
    code: "55",
    isoCode: "br",
    example: "11 96123-4567",
    order: 2,
  },
  {
    name: "British Indian Ocean Territory",
    code: "246",
    isoCode: "io",
    example: "380 1234",
    order: 2,
  },
  {
    name: "British Virgin Islands",
    code: "1284",
    isoCode: "vg",
    example: "300-1234",
    order: 2,
  },
  { name: "Brunei", code: "673", isoCode: "bn", example: "712 3456", order: 2 },
  {
    name: "Bulgaria",
    code: "359",
    isoCode: "bg",
    example: "48 123 456",
    order: 2,
  },
  {
    name: "Burkina Faso",
    code: "226",
    isoCode: "bf",
    example: "70 12 34 56",
    order: 2,
  },
  {
    name: "Burundi",
    code: "257",
    isoCode: "bi",
    example: "79 56 12 34",
    order: 2,
  },
  {
    name: "Cambodia",
    code: "855",
    isoCode: "kh",
    example: "91 234 567",
    order: 2,
  },
  {
    name: "Cameroon",
    code: "237",
    isoCode: "cm",
    example: "6 71 23 45 67",
    order: 2,
  },
  {
    name: "Canada",
    code: "1",
    isoCode: "ca",
    example: "506-234-5678",
    order: 2,
  },
  {
    name: "Cape Verde",
    code: "238",
    isoCode: "cv",
    example: "991 12 34",
    order: 2,
  },
  {
    name: "Caribbean Netherlands",
    code: "599",
    isoCode: "bq",
    example: "318 1234",
    order: 2,
  },
  {
    name: "Cayman Islands",
    code: "1345",
    isoCode: "ky",
    example: "323-1234",
    order: 2,
  },
  {
    name: "Central African Republic",
    code: "236",
    isoCode: "cf",
    example: "70 01 23 45",
    order: 2,
  },
  {
    name: "Chad",
    code: "235",
    isoCode: "td",
    example: "63 01 23 45",
    order: 2,
  },
  {
    name: "Chile",
    code: "56",
    isoCode: "cl",
    example: "2 2123 4567",
    order: 2,
  },
  {
    name: "China",
    code: "86",
    isoCode: "cn",
    example: "131 2345 6789",
    order: 2,
  },
  {
    name: "Christmas Island",
    code: "61",
    isoCode: "cx",
    example: "412 345 678",
    order: 2,
  },
  {
    name: "Cocos Islands",
    code: "61",
    isoCode: "cc",
    example: "412 345 678",
    order: 2,
  },
  {
    name: "Colombia",
    code: "57",
    isoCode: "co",
    example: "321 1234567",
    order: 2,
  },
  {
    name: "Comoros",
    code: "269",
    isoCode: "km",
    example: "321 23 45",
    order: 2,
  },
  {
    name: "Congo (Congo-Brazzaville)",
    code: "242",
    isoCode: "cg",
    example: "06 123 4567",
    order: 2,
  },
  {
    name: "Cook Islands",
    code: "682",
    isoCode: "ck",
    example: "71 234",
    order: 2,
  },
  {
    name: "Costa Rica",
    code: "506",
    isoCode: "cr",
    example: "8312 3456",
    order: 2,
  },
  {
    name: "Croatia",
    code: "385",
    isoCode: "hr",
    example: "92 123 4567",
    order: 2,
  },
  { name: "Cuba", code: "53", isoCode: "cu", example: "5 1234567", order: 2 },
  {
    name: "Curacao",
    code: "599",
    isoCode: "cw",
    example: "9 518 1234",
    order: 2,
  },
  {
    name: "Cyprus",
    code: "357",
    isoCode: "cy",
    example: "96 123456",
    order: 2,
  },
  {
    name: "Czech Republic",
    code: "420",
    isoCode: "cz",
    example: "601 123 456",
    order: 2,
  },
  {
    name: "Democratic Republic of the Congo",
    code: "243",
    isoCode: "cd",
    example: "991 234 567",
    order: 2,
  },
  {
    name: "Denmark",
    code: "45",
    isoCode: "dk",
    example: "32 12 34 56",
    order: 2,
  },
  {
    name: "Djibouti",
    code: "253",
    isoCode: "dj",
    example: "77 83 10 01",
    order: 2,
  },
  {
    name: "Dominica",
    code: "1767",
    isoCode: "dm",
    example: "225-1234",
    order: 2,
  },
  {
    name: "Dominican Republic",
    code: "1809",
    isoCode: "do",
    example: "809-234-5678",
    order: 2,
  },
  {
    name: "East Timor",
    code: "670",
    isoCode: "tl",
    example: "123-4567-8",
    order: 2,
  },
  {
    name: "Ecuador",
    code: "593",
    isoCode: "ec",
    example: "99 123 4567",
    order: 2,
  },
  {
    name: "Egypt",
    code: "20",
    isoCode: "eg",
    example: "100 123 4567",
    order: 2,
  },
  {
    name: "El Salvador",
    code: "503",
    isoCode: "sv",
    example: "7012 3456",
    order: 2,
  },
  {
    name: "Equatorial Guinea",
    code: "240",
    isoCode: "gq",
    example: "222 123 456",
    order: 2,
  },
  {
    name: "Eritrea",
    code: "291",
    isoCode: "er",
    example: "7 123 456",
    order: 2,
  },
  {
    name: "Estonia",
    code: "372",
    isoCode: "ee",
    example: "5123 4567",
    order: 2,
  },
  {
    name: "Ethiopia",
    code: "251",
    isoCode: "et",
    example: "91 123 4567",
    order: 2,
  },
  {
    name: "Falkland Islands",
    code: "500",
    isoCode: "fk",
    example: "51234",
    order: 2,
  },
  {
    name: "Faroe Islands",
    code: "298",
    isoCode: "fo",
    example: "211234",
    order: 2,
  },
  { name: "Fiji", code: "679", isoCode: "fj", example: "701 2345", order: 2 },
  {
    name: "Finland",
    code: "358",
    isoCode: "fi",
    example: "41 2345678",
    order: 2,
  },
  {
    name: "France",
    code: "33",
    isoCode: "fr",
    example: "6 12 34 56 78",
    order: 2,
  },
  {
    name: "French Guiana",
    code: "594",
    isoCode: "gf",
    example: "694 20 12 34",
    order: 2,
  },
  {
    name: "French Polynesia",
    code: "689",
    isoCode: "pf",
    example: "87 12 34 56",
    order: 2,
  },
  {
    name: "Gabon",
    code: "241",
    isoCode: "ga",
    example: "06 03 12 34",
    order: 2,
  },
  { name: "Gambia", code: "220", isoCode: "gm", example: "301 2345", order: 2 },
  {
    name: "Georgia",
    code: "995",
    isoCode: "ge",
    example: "555 12 34 56",
    order: 2,
  },
  {
    name: "Germany",
    code: "49",
    isoCode: "de",
    example: "1512 3456789",
    order: 2,
  },
  {
    name: "Ghana",
    code: "233",
    isoCode: "gh",
    example: "23 123 4567",
    order: 2,
  },
  {
    name: "Gibraltar",
    code: "350",
    isoCode: "gi",
    example: "57123456",
    order: 2,
  },
  {
    name: "Greece",
    code: "30",
    isoCode: "gr",
    example: "691 234 5678",
    order: 2,
  },
  {
    name: "Greenland",
    code: "299",
    isoCode: "gl",
    example: "22 12 34",
    order: 2,
  },
  {
    name: "Grenada",
    code: "1473",
    isoCode: "gd",
    example: "403-1234",
    order: 2,
  },
  { name: "Guam", code: "1671", isoCode: "gu", example: "300-1234", order: 2 },
  {
    name: "Guatemala",
    code: "502",
    isoCode: "gt",
    example: "5123 4567",
    order: 2,
  },
  {
    name: "Guernsey",
    code: "44",
    isoCode: "gg",
    example: "7781 123456",
    order: 2,
  },
  {
    name: "Guinea",
    code: "224",
    isoCode: "gn",
    example: "601 12 34 56",
    order: 2,
  },
  {
    name: "Guinea-Bissau",
    code: "245",
    isoCode: "gw",
    example: "955 012 345",
    order: 2,
  },
  { name: "Guyana", code: "592", isoCode: "gy", example: "609 1234", order: 2 },
  {
    name: "Haiti",
    code: "509",
    isoCode: "ht",
    example: "34 10 1234",
    order: 2,
  },
  {
    name: "Honduras",
    code: "504",
    isoCode: "hn",
    example: "9123-4567",
    order: 2,
  },
  {
    name: "Hong Kong",
    code: "852",
    isoCode: "hk",
    example: "5123 4567",
    order: 2,
  },
  {
    name: "Hungary",
    code: "36",
    isoCode: "hu",
    example: "20 123 4567",
    order: 2,
  },
  {
    name: "Iceland",
    code: "354",
    isoCode: "is",
    example: "611 1234",
    order: 2,
  },
  {
    name: "India",
    code: "91",
    isoCode: "in",
    example: "81234 56789",
    order: 2,
  },
  {
    name: "Indonesia",
    code: "62",
    isoCode: "id",
    example: "812-345-678",
    order: 2,
  },
  {
    name: "Iran",
    code: "98",
    isoCode: "ir",
    example: "912 345 6789",
    order: 2,
  },
  {
    name: "Iraq",
    code: "964",
    isoCode: "iq",
    example: "791 234 5678",
    order: 2,
  },
  {
    name: "Ireland",
    code: "353",
    isoCode: "ie",
    example: "85 012 3456",
    order: 2,
  },
  {
    name: "Isle of Man",
    code: "44",
    isoCode: "im",
    example: "7924 123456",
    order: 2,
  },
  {
    name: "Israel",
    code: "972",
    isoCode: "il",
    example: "50-234-5678",
    order: 2,
  },
  {
    name: "Italy",
    code: "39",
    isoCode: "it",
    example: "312 345 6789",
    order: 2,
  },
  {
    name: "Ivory Coast",
    code: "225",
    isoCode: "ci",
    example: "01 23 45 67",
    order: 2,
  },
  {
    name: "Jamaica",
    code: "1876",
    isoCode: "jm",
    example: "210-1234",
    order: 2,
  },
  {
    name: "Japan",
    code: "81",
    isoCode: "jp",
    example: "90-1234-5678",
    order: 2,
  },
  {
    name: "Jersey",
    code: "44",
    isoCode: "je",
    example: "7797 712345",
    order: 2,
  },
  {
    name: "Jordan",
    code: "962",
    isoCode: "jo",
    example: "7 9012 3456",
    order: 2,
  },
  {
    name: "Kazakhstan",
    code: "7",
    isoCode: "kz",
    example: "771 000 9998",
    order: 2,
  },
  {
    name: "Kenya",
    code: "254",
    isoCode: "ke",
    example: "712 123456",
    order: 2,
  },
  {
    name: "Kiribati",
    code: "686",
    isoCode: "ki",
    example: "72001234",
    order: 2,
  },
  {
    name: "Kosovo",
    code: "383",
    isoCode: "xk",
    example: "43 201 234",
    order: 2,
  },
  {
    name: "Kuwait",
    code: "965",
    isoCode: "kw",
    example: "500 12345",
    order: 2,
  },
  {
    name: "Kyrgyzstan",
    code: "996",
    isoCode: "kg",
    example: "700 123 456",
    order: 2,
  },
  {
    name: "Laos",
    code: "856",
    isoCode: "la",
    example: "20 23 123 456",
    order: 2,
  },
  {
    name: "Latvia",
    code: "371",
    isoCode: "lv",
    example: "21 234 567",
    order: 2,
  },
  {
    name: "Lebanon",
    code: "961",
    isoCode: "lb",
    example: "71 123 456",
    order: 2,
  },
  {
    name: "Lesotho",
    code: "266",
    isoCode: "ls",
    example: "5012 3456",
    order: 2,
  },
  {
    name: "Liberia",
    code: "231",
    isoCode: "lr",
    example: "77 012 3456",
    order: 2,
  },
  {
    name: "Libya",
    code: "218",
    isoCode: "ly",
    example: "91-2345678",
    order: 2,
  },
  {
    name: "Liechtenstein",
    code: "423",
    isoCode: "li",
    example: "660 234 567",
    order: 2,
  },
  {
    name: "Lithuania",
    code: "370",
    isoCode: "lt",
    example: "612 34567",
    order: 2,
  },
  {
    name: "Luxembourg",
    code: "352",
    isoCode: "lu",
    example: "628 123 456",
    order: 2,
  },
  { name: "Macau", code: "853", isoCode: "mo", example: "6612 3456", order: 2 },
  {
    name: "Macedonia",
    code: "389",
    isoCode: "mk",
    example: "72 345 678",
    order: 2,
  },
  {
    name: "Madagascar",
    code: "261",
    isoCode: "mg",
    example: "32 12 345 67",
    order: 2,
  },
  {
    name: "Malawi",
    code: "265",
    isoCode: "mw",
    example: "991 23 45 67",
    order: 2,
  },
  {
    name: "Malaysia",
    code: "60",
    isoCode: "my",
    example: "12-345 6789",
    order: 2,
  },
  {
    name: "Maldives",
    code: "960",
    isoCode: "mv",
    example: "771-2345",
    order: 2,
  },
  {
    name: "Mali",
    code: "223",
    isoCode: "ml",
    example: "65 01 23 45",
    order: 2,
  },
  { name: "Malta", code: "356", isoCode: "mt", example: "9696 1234", order: 2 },
  {
    name: "Marshall Islands",
    code: "692",
    isoCode: "mh",
    example: "235-1234",
    order: 2,
  },
  {
    name: "Martinique",
    code: "596",
    isoCode: "mq",
    example: "696 20 12 34",
    order: 2,
  },
  {
    name: "Mauritania",
    code: "222",
    isoCode: "mr",
    example: "22 12 34 56",
    order: 2,
  },
  {
    name: "Mauritius",
    code: "230",
    isoCode: "mu",
    example: "5251 2345",
    order: 2,
  },
  {
    name: "Mayotte",
    code: "262",
    isoCode: "yt",
    example: "639 01 23 45",
    order: 2,
  },
  {
    name: "Mexico",
    code: "52",
    isoCode: "mx",
    example: "1 222 123 4567",
    order: 2,
  },
  {
    name: "Micronesia",
    code: "691",
    isoCode: "fm",
    example: "350 1234",
    order: 2,
  },
  {
    name: "Moldova",
    code: "373",
    isoCode: "md",
    example: "621 12 345",
    order: 2,
  },
  {
    name: "Monaco",
    code: "377",
    isoCode: "mc",
    example: "6 12 34 56 78",
    order: 2,
  },
  {
    name: "Mongolia",
    code: "976",
    isoCode: "mn",
    example: "8812 3456",
    order: 2,
  },
  {
    name: "Montenegro",
    code: "382",
    isoCode: "me",
    example: "67 622 901",
    order: 2,
  },
  {
    name: "Montserrat",
    code: "1664",
    isoCode: "ms",
    example: "492-3456",
    order: 2,
  },
  {
    name: "Morocco",
    code: "212",
    isoCode: "ma",
    example: "650-123456",
    order: 2,
  },
  {
    name: "Mozambique",
    code: "258",
    isoCode: "mz",
    example: "82 123 4567",
    order: 2,
  },
  {
    name: "Myanmar",
    code: "95",
    isoCode: "mm",
    example: "9 212 3456",
    order: 2,
  },
  {
    name: "Namibia",
    code: "264",
    isoCode: "na",
    example: "81 123 4567",
    order: 2,
  },
  { name: "Nauru", code: "674", isoCode: "nr", example: "555 1234", order: 2 },
  {
    name: "Nepal",
    code: "977",
    isoCode: "np",
    example: "984-1234567",
    order: 2,
  },
  {
    name: "Netherlands",
    code: "31",
    isoCode: "nl",
    example: "6 12345678",
    order: 2,
  },
  {
    name: "Netherlands Antilles",
    code: "599",
    isoCode: "an",
    example: "325-1234",
    order: 2,
  },
  {
    name: "New Caledonia",
    code: "687",
    isoCode: "nc",
    example: "75-12-34",
    order: 2,
  },
  {
    name: "New Zealand",
    code: "64",
    isoCode: "nz",
    example: "21 123 4567",
    order: 2,
  },
  {
    name: "Nicaragua",
    code: "505",
    isoCode: "ni",
    example: "8123 4567",
    order: 2,
  },
  {
    name: "Niger",
    code: "227",
    isoCode: "ne",
    example: "93 12 34 56",
    order: 2,
  },
  {
    name: "Nigeria",
    code: "234",
    isoCode: "ng",
    example: "802 123 4567",
    order: 2,
  },
  { name: "Niue", code: "683", isoCode: "nu", example: "888 4012", order: 2 },
  {
    name: "Norfolk Island",
    code: "672",
    isoCode: "nf",
    example: "3 81234",
    order: 2,
  },
  {
    name: "North Korea",
    code: "850",
    isoCode: "kp",
    example: "192 123 4567",
    order: 2,
  },
  {
    name: "Northern Mariana Islands",
    code: "1670",
    isoCode: "mp",
    example: "234-5678",
    order: 2,
  },
  {
    name: "Norway",
    code: "47",
    isoCode: "no",
    example: "406 12 345",
    order: 2,
  },
  { name: "Oman", code: "968", isoCode: "om", example: "9212 3456", order: 2 },
  {
    name: "Pakistan",
    code: "92",
    isoCode: "pk",
    example: "301 2345678",
    order: 2,
  },
  { name: "Palau", code: "680", isoCode: "pw", example: "620 1234", order: 2 },
  {
    name: "Palestine",
    code: "970",
    isoCode: "ps",
    example: "599 123 456",
    order: 2,
  },
  {
    name: "Panama",
    code: "507",
    isoCode: "pa",
    example: "6123-4567",
    order: 2,
  },
  {
    name: "Papua New Guinea",
    code: "675",
    isoCode: "pg",
    example: "7012 3456",
    order: 2,
  },
  {
    name: "Paraguay",
    code: "595",
    isoCode: "py",
    example: "961 456789",
    order: 2,
  },
  { name: "Peru", code: "51", isoCode: "pe", example: "912 345 678", order: 2 },
  {
    name: "Philippines",
    code: "63",
    isoCode: "ph",
    example: "905 123 4567",
    order: 2,
  },
  {
    name: "Pitcairn",
    code: "64",
    isoCode: "pn",
    example: "925253251",
    order: 2,
  },
  {
    name: "Poland",
    code: "48",
    isoCode: "pl",
    example: "512 345 678",
    order: 2,
  },
  {
    name: "Portugal",
    code: "351",
    isoCode: "pt",
    example: "912 345 678",
    order: 2,
  },
  {
    name: "Puerto Rico",
    code: "1787",
    isoCode: "pr",
    example: "787-234-5678",
    order: 2,
  },
  { name: "Qatar", code: "974", isoCode: "qa", example: "3312 3456", order: 2 },
  {
    name: "Republic of the Congo",
    code: "242",
    isoCode: "cg",
    example: "06 123 4567",
    order: 2,
  },
  {
    name: "Reunion",
    code: "262",
    isoCode: "re",
    example: "692 12 34 56",
    order: 2,
  },
  {
    name: "Romania",
    code: "40",
    isoCode: "ro",
    example: "712 034 567",
    order: 2,
  },
  {
    name: "Russia",
    code: "7",
    isoCode: "ru",
    example: "912 345-67-89",
    order: 2,
  },
  {
    name: "Rwanda",
    code: "250",
    isoCode: "rw",
    example: "720 123 456",
    order: 2,
  },
  {
    name: "Saint Barthelemy",
    code: "590",
    isoCode: "bl",
    example: "690 00 12 34",
    order: 2,
  },
  {
    name: "Saint Helena",
    code: "290",
    isoCode: "sh",
    example: "51234",
    order: 2,
  },
  {
    name: "Saint Kitts and Nevis",
    code: "1869",
    isoCode: "kn",
    example: "765-2917",
    order: 2,
  },
  {
    name: "Saint Lucia",
    code: "1758",
    isoCode: "lc",
    example: "284-5678",
    order: 2,
  },
  {
    name: "Saint Martin",
    code: "590",
    isoCode: "mf",
    example: "690 00 12 34",
    order: 2,
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "508",
    isoCode: "pm",
    example: "55 12 34",
    order: 2,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "1784",
    isoCode: "vc",
    example: "430-1234",
    order: 2,
  },
  { name: "Samoa", code: "685", isoCode: "ws", example: "72 12345", order: 2 },
  {
    name: "San Marino",
    code: "378",
    isoCode: "sm",
    example: "66 66 12 12",
    order: 2,
  },
  {
    name: "Sao Tome and Principe",
    code: "239",
    isoCode: "st",
    example: "981 2345",
    order: 2,
  },
  {
    name: "Saudi Arabia",
    code: "966",
    isoCode: "sa",
    example: "51 234 5678",
    order: 2,
  },
  {
    name: "Senegal",
    code: "221",
    isoCode: "sn",
    example: "70 123 45 67",
    order: 2,
  },
  {
    name: "Serbia",
    code: "381",
    isoCode: "rs",
    example: "60 1234567",
    order: 2,
  },
  {
    name: "Seychelles",
    code: "248",
    isoCode: "sc",
    example: "2 510 123",
    order: 2,
  },
  {
    name: "Sierra Leone",
    code: "232",
    isoCode: "sl",
    example: "25 123456",
    order: 2,
  },
  {
    name: "Singapore",
    code: "65",
    isoCode: "sg",
    example: "8123 4567",
    order: 2,
  },
  {
    name: "Sint Maarten",
    code: "1721",
    isoCode: "sx",
    example: "520-5678",
    order: 2,
  },
  {
    name: "Slovakia",
    code: "421",
    isoCode: "sk",
    example: "912 123 456",
    order: 2,
  },
  {
    name: "Slovenia",
    code: "386",
    isoCode: "si",
    example: "31 234 567",
    order: 2,
  },
  {
    name: "Solomon Islands",
    code: "677",
    isoCode: "sb",
    example: "74 21234",
    order: 2,
  },
  {
    name: "Somalia",
    code: "252",
    isoCode: "so",
    example: "7 1123456",
    order: 2,
  },
  {
    name: "South Africa",
    code: "27",
    isoCode: "za",
    example: "71 123 4567",
    order: 2,
  },
  {
    name: "South Korea",
    code: "82",
    isoCode: "kr",
    example: "2-312-3456",
    order: 2,
  },
  {
    name: "South Sudan",
    code: "211",
    isoCode: "ss",
    example: "977 123 456",
    order: 2,
  },
  {
    name: "Spain",
    code: "34",
    isoCode: "es",
    example: "612 34 56 78",
    order: 2,
  },
  {
    name: "Sri Lanka",
    code: "94",
    isoCode: "lk",
    example: "71 234 5678",
    order: 2,
  },
  {
    name: "Sudan",
    code: "249",
    isoCode: "sd",
    example: "91 123 1234",
    order: 2,
  },
  {
    name: "Suriname",
    code: "597",
    isoCode: "sr",
    example: "741-2345",
    order: 2,
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "47",
    isoCode: "sj",
    example: "412 34 567",
    order: 2,
  },
  {
    name: "Swaziland",
    code: "268",
    isoCode: "sz",
    example: "7612 3456",
    order: 2,
  },
  {
    name: "Sweden",
    code: "46",
    isoCode: "se",
    example: "70 123 45 67",
    order: 2,
  },
  {
    name: "Switzerland",
    code: "41",
    isoCode: "ch",
    example: "78 123 45 67",
    order: 2,
  },
  {
    name: "Syria",
    code: "963",
    isoCode: "sy",
    example: "944 567 890",
    order: 2,
  },
  {
    name: "Taiwan",
    code: "886",
    isoCode: "tw",
    example: "912 345 678",
    order: 2,
  },
  {
    name: "Tajikistan",
    code: "992",
    isoCode: "tj",
    example: "917 12 3456",
    order: 2,
  },
  {
    name: "Tanzania",
    code: "255",
    isoCode: "tz",
    example: "621 234 567",
    order: 2,
  },
  {
    name: "Thailand",
    code: "66",
    isoCode: "th",
    example: "81 234 5678",
    order: 2,
  },
  {
    name: "Togo",
    code: "228",
    isoCode: "tg",
    example: "90 11 23 45",
    order: 2,
  },
  { name: "Tokelau", code: "690", isoCode: "tk", example: "7290", order: 2 },
  { name: "Tonga", code: "676", isoCode: "to", example: "771 5123", order: 2 },
  {
    name: "Trinidad and Tobago",
    code: "1868",
    isoCode: "tt",
    example: "291-1234",
    order: 2,
  },
  {
    name: "Tunisia",
    code: "216",
    isoCode: "tn",
    example: "20 123 456",
    order: 2,
  },
  {
    name: "Turkey",
    code: "90",
    isoCode: "tr",
    example: "501 234 56 78",
    order: 2,
  },
  {
    name: "Turkmenistan",
    code: "993",
    isoCode: "tm",
    example: "66 123456",
    order: 2,
  },
  {
    name: "Turks and Caicos Islands",
    code: "1649",
    isoCode: "tc",
    example: "231-1234",
    order: 2,
  },
  { name: "Tuvalu", code: "688", isoCode: "tv", example: "901234", order: 2 },
  {
    name: "U.S. Virgin Islands",
    code: "1340",
    isoCode: "vi",
    example: "642-1234",
    order: 2,
  },
  {
    name: "Uganda",
    code: "256",
    isoCode: "ug",
    example: "712 345678",
    order: 2,
  },
  {
    name: "Ukraine",
    code: "380",
    isoCode: "ua",
    example: "39 123 4567",
    order: 2,
  },
  {
    name: "United Arab Emirates",
    code: "971",
    isoCode: "ae",
    example: "50 123 4567",
    order: 2,
  },
  {
    name: "United Kingdom",
    code: "44",
    isoCode: "gb",
    example: "7400 123456",
    order: 1,
  },
  {
    name: "United States",
    code: "1",
    isoCode: "us",
    example: "201-555-0123",
    order: 0,
  },
  {
    name: "Uruguay",
    code: "598",
    isoCode: "uy",
    example: "94 231 234",
    order: 2,
  },
  {
    name: "Uzbekistan",
    code: "998",
    isoCode: "uz",
    example: "91 234 56 78",
    order: 2,
  },
  {
    name: "Vanuatu",
    code: "678",
    isoCode: "vu",
    example: "591 2345",
    order: 2,
  },
  {
    name: "Vatican",
    code: "379",
    isoCode: "va",
    example: "312 345 6789",
    order: 2,
  },
  {
    name: "Venezuela",
    code: "58",
    isoCode: "ve",
    example: "412-1234567",
    order: 2,
  },
  {
    name: "Vietnam",
    code: "84",
    isoCode: "vn",
    example: "91 234 56 78",
    order: 2,
  },
  {
    name: "Wallis and Futuna",
    code: "681",
    isoCode: "wf",
    example: "50 12 34",
    order: 2,
  },
  {
    name: "Western Sahara",
    code: "212",
    isoCode: "eh",
    example: "650-123456",
    order: 2,
  },
  {
    name: "Yemen",
    code: "967",
    isoCode: "ye",
    example: "712 345 678",
    order: 2,
  },
  {
    name: "Zambia",
    code: "260",
    isoCode: "zm",
    example: "95 5123456",
    order: 2,
  },
  {
    name: "Zimbabwe",
    code: "263",
    isoCode: "zw",
    example: "71 234 5678",
    order: 2,
  },
];
