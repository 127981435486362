import React, { useEffect, useMemo, useRef } from "react";
import { x } from "@xstyled/styled-components";
import FeedbackButtons from "components/FeedbackButtons/FeedbackButtons";
import { useDeliverableContext } from "providers/DeliverableProvider";
import {
  AiSummaryPart,
  HighlightAiSummaryPart,
  Sentence,
  Summary,
  SummaryStatus,
  SummaryType,
} from "./AiSummaries.types";
import { DocumentSummary } from "./DocumentSummary";
import { KeyQuotes } from "./KeyQuotes";
import { CopyButton } from "../../../components/CopyButton/CopyButton";
import { selectIndexHighlight } from "../helpers";
import { GenerateSummary } from "./GenerateSummary";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { InternalDeleteSummary } from "./InternalDeleteSummary";
import { TranscriptSentenceSkeleton } from "../MainContentViews";
import useStyles from "../styles";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { frontEndSearch } from "hooks/useFrontEndSearch";

interface AiSummaryProps {
  transcriptId: string;
  recordingId: string;
  summary: Summary;
  contentSearchTerms: string[];
  selectedSummaryIndex: number;
  interactionId: string;
  isTranscriptLoaded: boolean;
  sentences: Sentence[];
  isAiSummariesLoading?: boolean;
  onSummaryHighlight: (totalHighlights: number) => void;
  onGenerateClicked: (summariesStatus: Summary[]) => void;
  onSummaryGenerated: () => void;
  onFeedbackUpdated: (feedbackType: string) => void;
  onTranscriptLinkClick: (cueIds: number[]) => void;
  obfuscated?: boolean;
  mobile?: boolean;
}

export const AiSummary = ({
  transcriptId,
  recordingId,
  summary,
  contentSearchTerms,
  selectedSummaryIndex,
  interactionId,
  isAiSummariesLoading = false,
  isTranscriptLoaded,
  sentences,
  onSummaryHighlight,
  onGenerateClicked,
  onSummaryGenerated,
  onFeedbackUpdated,
  onTranscriptLinkClick,
  obfuscated = false,
  mobile = false,
}: AiSummaryProps) => {
  const { project } = useDeliverableContext();

  const styles = useStyles({ isKeyQuotes: summary.type === SummaryType.KeyQuotes, obfuscated });
  const summaryRef = useRef(null);
  const { logHit } = useTrackUserAction();
  const currentUser = useCurrentUser();

  const highlightTranscriptContent = useMemo<HighlightAiSummaryPart[]>(() => {
    const parts =
      summary.content?.parts?.filter((part: AiSummaryPart) => {
        if (summary.type === SummaryType.KeyQuotes) {
          return part.type === "quote";
        }
        return true;
      }) ?? [];

    const search = frontEndSearch({
      object: parts,
      paths: ["text"],
      terms: contentSearchTerms,
    });

    return search as HighlightAiSummaryPart[];
  }, [summary.content, contentSearchTerms, summary.type]);

  useEffect(() => {
    const numHighlights = highlightTranscriptContent.reduce((acc, val) => acc + val.highlightCounts, 0);
    onSummaryHighlight(numHighlights);
    selectIndexHighlight(selectedSummaryIndex, numHighlights);
  }, [highlightTranscriptContent, selectedSummaryIndex, onSummaryHighlight]);

  useEffect(
    function logHitAiSummaryViewed() {
      const isInternalUser = (currentUser?.internalUser as any)?.clientProfileId;
      if (!isInternalUser && summary.status === SummaryStatus.Generated) {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.aiSummaryViewed,
          projectToken: project.token,
          advisorshipId: interactionId,
          details: {
            aiSummaryType: summary.type,
          },
          references: { transcriptId: transcriptId },
        });
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.aiSummaryViewedOnce,
          projectToken: project.token,
          advisorshipId: interactionId,
          details: {
            aiSummaryType: summary.type,
          },
          references: { transcriptId: transcriptId },
        });
      }
    },
    [logHit, project.token, interactionId, summary.type, transcriptId, project, summary.status, currentUser]
  );

  if (isAiSummariesLoading) {
    return (
      <x.div data-testid="ai-summary-loading">
        <TranscriptSentenceSkeleton count={7} />
      </x.div>
    );
  }

  if (summary.status !== SummaryStatus.Generated) {
    return (
      <>
        <InternalDeleteSummary
          interactionId={interactionId}
          recordingId={recordingId}
          transcriptId={transcriptId}
          onGenerateClicked={onGenerateClicked}
        />
        <GenerateSummary
          interactionId={interactionId}
          recordingId={recordingId}
          transcriptId={transcriptId}
          onGenerateClicked={onGenerateClicked}
          onSummaryGenerated={onSummaryGenerated}
          summaryType={summary.type}
          isTranscriptLoaded={isTranscriptLoaded}
          initialSummaryStatus={summary.status}
          mobile={mobile}
        />
      </>
    );
  }

  const content =
    summary.type === SummaryType.KeyQuotes ? (
      <KeyQuotes
        transcriptContent={highlightTranscriptContent}
        interactionId={interactionId}
        transcriptId={transcriptId}
        sentences={sentences}
        onTranscriptLinkClick={onTranscriptLinkClick}
      />
    ) : (
      <DocumentSummary transcriptContent={highlightTranscriptContent} summaryRef={summaryRef} />
    );

  return (
    <>
      <InternalDeleteSummary
        interactionId={interactionId}
        recordingId={recordingId}
        transcriptId={transcriptId}
        onGenerateClicked={onGenerateClicked}
      />
      <x.div {...styles.aiContentWrapper} data-testid="ai-summary-content-view">
        {content}
      </x.div>
      <x.div {...styles.feedbackButtonsWrapper}>
        <FeedbackButtons
          interactionId={interactionId}
          transcriptId={transcriptId}
          recordingId={recordingId}
          summaryType={summary.type}
          feedbackType={summary.feedbackType?.toLowerCase() ?? ""}
          onFeedbackUpdated={onFeedbackUpdated}
        />
        {summary.type !== SummaryType.KeyQuotes && (
          <CopyButton
            messageRef={summaryRef}
            onClick={() =>
              logHit({
                origin: HitOrigin.deliverablesView,
                action: HitAction.aiSummaryCopy,
                projectToken: project.token,
                advisorshipId: interactionId,
                details: {
                  aiSummaryType: summary.type,
                },
                references: { transcriptId: transcriptId },
              })
            }
          />
        )}
      </x.div>
    </>
  );
};
