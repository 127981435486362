import React, { useState, useEffect } from "react";
import useScrollToRef from "./useScrollToRef";
import { convertNodeToElement } from "react-html-parser";

const useKeywordSearch = (selectedIndex: any) => {
  const keywordRefs: any = [];
  let renderedKeywords = 0;
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, [keywordRefs]); // eslint-disable-line react-hooks/exhaustive-deps

  useScrollToRef({
    refs: keywordRefs,
    pageLoaded,
    selectedIndex,
  });

  const addRefToKeyword = (node: any, index: any) => {
    // keywords come from the search api with an <em> tag
    if (node.type === "tag" && node.name === "em") {
      const nodeRef = React.createRef();
      keywordRefs.push(nodeRef);
      node.attribs.ref = nodeRef;
      if (selectedIndex === renderedKeywords) {
        node.attribs.class = "selected";
      }
      renderedKeywords++;
      return convertNodeToElement(node, index, addRefToKeyword);
    }
  };

  return { addRefToKeyword };
};

export default useKeywordSearch;
