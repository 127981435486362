import React, { useRef } from "react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { FilterBar } from "components/SurveysPage/FilterBar";
import styled, { x } from "@xstyled/styled-components";
import { Divider, TextField, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import useStyles from "./styles";
import { buildSurveyData } from "components/SurveysPage/helpers";
import { WorkstreamToggler } from "components/InteractionsPage/WorkstreamToggler";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";
import { useObserveOverflow } from "hooks/useObserveOverflow";

type SurveyTableHeaderProps = {
  selectedSurveyId: string;
  surveyType: SurveyType;
  onFiltersSidebarToggle: () => void;
  onSubmitSearch: React.FormEventHandler<HTMLFormElement>;
  onClearSearch: () => void;
  queryTextRef?: React.Ref<HTMLInputElement>;
};

export const SurveyTableHeader = ({
  selectedSurveyId,
  surveyType,
  onFiltersSidebarToggle,
  onSubmitSearch,
  onClearSearch,
  queryTextRef,
}: SurveyTableHeaderProps) => {
  const newNavigationEnabled = useNewNavigation();
  const {
    state: { searchQuery },
  } = useProjectInteractionsContext();
  const styles = useStyles();
  const workstreamTogglerRef = useRef(null);
  const { elementRef, isOverflow } = useObserveOverflow();

  const renderSearchComponent = () =>
    newNavigationEnabled ? (
      <CollapsibleSearch
        id="advisor-page-search-input"
        data-testid="search-form"
        onSubmit={onSubmitSearch}
        ref={queryTextRef}
        name="query"
        value={searchQuery}
        autoComplete="off"
        onClear={onClearSearch}
      />
    ) : (
      <x.form flexGrow={1} data-testid="search-form" onSubmit={onSubmitSearch}>
        <TextField
          id="advisor-page-search-input"
          data-testid="keyword-search-input"
          ref={queryTextRef}
          className="aui-w-full"
          name="query"
          placeholder="Search"
          value={searchQuery}
          autoComplete="off"
          {...styles.searchInput}
        />
      </x.form>
    );

  const Wrapper = newNavigationEnabled ? SurveyTableHeaderWrapper : React.Fragment;
  return (
    <HeaderPortal>
      <Wrapper ref={elementRef}>
        <SearchWrapper>
          <WorkstreamToggler ref={workstreamTogglerRef} />
          {workstreamTogglerRef.current && newNavigationEnabled && <Divider vertical h="30px" mx="10px" />}
          {renderSearchComponent()}
        </SearchWrapper>
        <x.div {...(newNavigationEnabled ? {} : styles.pillCountWrapper)}>
          <FilterBar
            toggleFilterSidebar={onFiltersSidebarToggle}
            surveyType={surveyType}
            isCompact={newNavigationEnabled && isOverflow}
            searchQuery={searchQuery}
          />
          {!newNavigationEnabled && <Count selectedSurveyId={selectedSurveyId} surveyType={surveyType} />}
        </x.div>
      </Wrapper>
    </HeaderPortal>
  );
};

const Count = ({ selectedSurveyId, surveyType }: { selectedSurveyId: string; surveyType: SurveyType }) => {
  const {
    state: { projectWithInteractions, interactions },
  } = useProjectInteractionsContext();
  const doubleBlinded = surveyType === "double_blinded";

  const styles = useStyles();

  const total = new Set(
    buildSurveyData({
      interactions: projectWithInteractions?.interactions || [],
      selectedSurveyId: selectedSurveyId,
      doubleBlinded: doubleBlinded,
    })
  ).size;

  const current = new Set(
    buildSurveyData({
      interactions: interactions,
      selectedSurveyId: selectedSurveyId,
      doubleBlinded: doubleBlinded,
    })
  ).size;

  return (
    <Typography component={"div"} variant="body-small" {...styles.count}>
      <x.span>Showing </x.span>
      <x.span {...styles.colorRed}>{current}</x.span>
      <x.span> / </x.span>
      <x.span>{total}</x.span>
    </Typography>
  );
};

const SurveyTableHeaderWrapper = styled(x.div)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const newNavigationEnabled = useNewNavigation();

  return newNavigationEnabled
    ? `
      display: flex;
      align-items: center;
      gap: ${inner.base02};
      padding: ${inner.base04} ${inner.base06};
    `
    : "";
});

const SearchWrapper = styled(x.div)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const newNavigationEnabled = useNewNavigation();

  return `
    display: flex;
    align-items: center;
    column-gap: ${newNavigationEnabled ? 0 : inner.base02};
    z-index: 5;
  `;
});
