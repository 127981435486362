import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useProjectAlphaGPTPageStyles = () => {
  const {
    spacing: { inner },
    shape: { border },
    color: { background, border: borderColor },
  } = useThemeTokens();

  const noAccessBanner = {
    position: "absolute",
    bottom: "0",
    w: "100%",
    backgroundColor: background.surface.page.default,
    py: inner.base10,
    borderTopColor: borderColor.divider,
    borderTopWidth: border.width.small,
  };

  const noAccessBannerContent = {
    w: "610px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: inner.base03,
  };

  return {
    noAccessBanner,
    noAccessBannerContent,
  };
};
