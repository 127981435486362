import { OptionSections, SearchOption } from "components/Search/types";

export const getOptionsPerSection = (options: SearchOption[] = [], sections: OptionSections[] = []) => {
  const categorizedOptions = [];

  sections.forEach(({ title, numOptionsPerSection }) => {
    const matchingOptions = options.filter((option) => option.section === title).slice(0, numOptionsPerSection);

    // Add section if it has options associated with it
    if (matchingOptions.length > 0) {
      categorizedOptions.push({
        title,
        options: matchingOptions,
      });
    }
  });

  // Filter options that do not fit into any defined sections into "Other" section
  const uncategorizedOptions = options.filter((option) => !sections.some(({ title }) => title === option.section));

  if (uncategorizedOptions.length > 0) {
    categorizedOptions.push({
      title: sections.length > 0 ? "Other" : "",
      options: uncategorizedOptions,
    });
  }

  return categorizedOptions;
};
