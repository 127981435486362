import { useThemeTokens } from "@alphasights/alphadesign-components";

const useCkFiltersStyles = ({ newNavigationEnabled }: { newNavigationEnabled?: boolean } = {}) => {
  const {
    color: { background, text },
    spacing: { inner },
  } = useThemeTokens();

  return {
    filtersList: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: inner.base02,
      pl: newNavigationEnabled ? inner.base02 : undefined,
    },
    filterPillWrapper: {
      backgroundColor: background.surface.page.default,
      borderRadius: inner.base06,
    },
    clearAllButton: {
      color: text.strong,
    },
  };
};

export default useCkFiltersStyles;
