import _ from "lodash";
import { useEffect, useState } from "react";

export const useMemoizedValue = <T>(value: T) => {
  const [state, setState] = useState(value);

  useEffect(
    function updateStateIfValueChanged() {
      !_.isEqual(value, state) && setState(value);
    },
    [state, value]
  );

  return state;
};
