import { addSeconds, isFuture, isWithinInterval, subMinutes } from "date-fns";
import { distinctBy } from "../../../helpers/arrayHelpers";
import { parseISO } from "providers/TimezoneProvider";

export const pendingRequestLabels = {
  CANCEL_REQUEST: "Cancellation",
  RESCHEDULE_REQUEST: "Reschedule",
  SCHEDULE_REQUEST: "Schedule",
};

export const pendingRequestTooltip = {
  CANCEL_REQUEST: "cancel",
  RESCHEDULE_REQUEST: "reschedule",
  SCHEDULE_REQUEST: "schedule",
};

export const enrichInteraction = (interaction) => {
  const nonCallInteractionTypes = [
    "Scheduling Issues",
    "Referral Payment",
    "Miscellaneous Payment",
    "Expert Expense",
    "Consultant Payment",
  ];

  const isSurvey = interaction.interactionType === "Industry Survey";
  const isNonCallInteraction = nonCallInteractionTypes.includes(interaction.interactionType);
  const hasAdvisorAvailability = interaction.advisorAvailability?.length > 0;
  const hasClientAvailability = interaction.clientTimeslots?.find(({ startsAt }) => isFuture(parseISO(startsAt)));
  const requestedSchedulable = interaction.state === "requested" && !isSurvey;
  const showInstantSchedule =
    interaction.state === "proposed" && interaction.autoBookEnabled && hasAdvisorAvailability && !isSurvey;

  return {
    isSurvey,
    isNonCallInteraction,
    hasAdvisorAvailability,
    hasClientAvailability,
    showInstantSchedule,
    showRequest: interaction.state === "proposed" && !showInstantSchedule,
    showSchedule: requestedSchedulable && hasAdvisorAvailability,
    showLeaveAvailability: requestedSchedulable,
    showFollowUp:
      /completed/.test(interaction.state) &&
      !(interaction.interactionType === "Industry Survey" ? interaction.hasPendingFollowUp : interaction.followUpId) &&
      interaction.isActiveProject,
    ...interaction,
  };
};

export const groupByInteractionGroupAndExpertAngle = (experts) =>
  groupByInteractionGroups(groupedRepresentationForExperts(experts));

const applicableDate = (interaction) =>
  parseISO(interaction.scheduledCallTime).getTime() || parseISO(interaction.requestedAt).getTime() || 0;

export const sortInteractionChain = (interactions) =>
  interactions.sort(
    (first, second) => second.priorityHit - first.priorityHit || applicableDate(second) - applicableDate(first)
  );

export const filterOtherInteractions = (interactions, mainInteraction) =>
  interactions
    .filter((interaction) => interaction.interactionType !== "Work Product" && interaction !== "Work Request")
    .filter((interaction) => interaction !== mainInteraction);

export const mainInteractionFor = (interactions) => sortInteractionChain(interactions)[0];

const countStates = (interactions) =>
  interactions.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.state]: (acc[curr.state] || 0) + 1,
    }),
    {}
  );
// should this be deprecated in favor of groupByInteractionGroupAndExpertAngle?
export const groupedRepresentationForExperts = (experts, chainIdSelected) =>
  experts.map(({ interactions }) => groupedRepresentationFor(interactions, chainIdSelected));

export const groupedRepresentationFor = (interactions, chainIdSelected) => {
  let mainInteraction =
    (chainIdSelected && interactions.find((i) => i.id === chainIdSelected)) || mainInteractionFor(interactions);

  let otherInteractions = filterOtherInteractions(interactions, mainInteraction);

  if (
    (mainInteraction?.interactionType === "Work Product" || mainInteraction?.interactionType === "Work Request") &&
    otherInteractions.length !== 0
  ) {
    mainInteraction = otherInteractions[0];
    otherInteractions = filterOtherInteractions(interactions, mainInteraction);
  }

  const othersStates = otherInteractions.map(({ state, requestedAt, scheduledCallTime, completedDate }) => ({
    description: state,
    period: [completedDate, scheduledCallTime, requestedAt].find((ts) => ts),
  }));

  return {
    ...mainInteraction,
    othersCounts: countStates(otherInteractions),
    othersStates: othersStates,
  };
};

export const flatExpertsWithRequests = (experts, clientRequests) => {
  return groupByInteractionGroupAndExpertAngle(experts)
    .flatMap((group) =>
      group.interactions.map((interaction) => ({
        ...interaction,
        group: group,
        pendingRequest: clientRequests.find((cr) => cr.interactionId === interaction.id),
      }))
    )
    .filter(distinctBy("id"));
};

export const groupByInteractionGroups = (interactions) =>
  interactions
    .flatMap((interaction) => interaction.group)
    .filter(distinctBy("id"))
    .map((group) => ({
      ...group,
      type: group.parent ? group.parent.name : null,
      interactions: interactions.filter((interaction) => interaction.group.id === group.id).filter(distinctBy("id")),
    }))
    .filter(({ interactions }) => interactions.length > 0);

export const isDuringCall = ({ state, scheduledCallTime, expectedDuration }) =>
  state === "scheduled" &&
  scheduledCallTime &&
  expectedDuration &&
  isWithinInterval(new Date(), {
    start: parseISO(scheduledCallTime),
    end: addSeconds(parseISO(scheduledCallTime), expectedDuration),
  });

export const isCallAboutToStart = ({ state, scheduledCallTime }) =>
  state === "scheduled" &&
  scheduledCallTime &&
  isWithinInterval(new Date(), {
    start: subMinutes(parseISO(scheduledCallTime), 10),
    end: parseISO(scheduledCallTime),
  });

export const isCallAvailable = ({ state, scheduledCallTime, expectedDuration }) =>
  isCallAboutToStart({ state, scheduledCallTime }) || isDuringCall({ state, scheduledCallTime, expectedDuration });
