import React from "react";
import { SecondaryButtonSmall } from "components/Button";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";
import * as S from "./InteractionRequestSection.styled";
import { pendingRequestLabels } from "components/InteractionsPage/helpers/Interaction";
import { InteractionRequestReschedulingSection } from "./InteractionRequestReschedulingSection";
import { Button, Typography, useThemeTokens, Icon } from "@alphasights/alphadesign-components";
import { PopupDataAreaLeftPad } from "../PopupRenderers/common";

export const InteractionRequestSection = ({ interaction, requestPending, onCancelPendingRequest, variant }) => {
  const {
    spacing: { inner, layout },
    color,
  } = useThemeTokens();

  if (!requestPending) return null;

  if (requestPending.type === "RESCHEDULE_REQUEST" && requestPending.payload.requestedTime)
    return (
      <InteractionRequestReschedulingSection
        interaction={interaction}
        requestPending={requestPending}
        onCancelPendingRequest={onCancelPendingRequest}
        variant={variant}
      />
    );

  if (variant === "calendar-popup-v1") {
    return (
      <PopupDataAreaLeftPad marginTop="base04">
        <Typography variant="body-small" color="secondary">
          Your AlphaSights project lead has been notified of your request to{" "}
          {requestPending.type === "CANCEL_REQUEST"
            ? "cancel"
            : pendingRequestLabels[requestPending.type]?.toLowerCase()}{" "}
          this interaction.
        </Typography>
        <Button
          variant="outline"
          loading={interaction.runningAction === "requestChangeInteraction"}
          onClick={() => onCancelPendingRequest(requestPending)}
          data-testid="cancel-pending-request-button"
          w="100%"
          mt={inner.base04}
        >
          Retract {requestPending.type === "SCHEDULE_REQUEST" ? "Schedule" : "Cancellation"} Request
        </Button>
      </PopupDataAreaLeftPad>
    );
  }

  return (
    <S.Wrapper>
      <S.IconContainer>
        <Icon size="large" color={color.icon.secondary} marginTop={layout.base}>
          <CalendarAvailable />
        </Icon>
      </S.IconContainer>
      <S.Content data-testid="pending-request">
        <div>
          Your AlphaSights project lead has been notified of your request to{" "}
          {requestPending.type === "CANCEL_REQUEST"
            ? "cancel"
            : pendingRequestLabels[requestPending.type]?.toLowerCase()}{" "}
          this interaction.
        </div>
        <S.StyledButtonWrapper>
          <SecondaryButtonSmall
            data-testid="cancel-pending-request-button"
            isLoading={interaction.runningAction === "requestChangeInteraction"}
            onClick={() => onCancelPendingRequest(requestPending)}
          >
            Cancel Request
          </SecondaryButtonSmall>
        </S.StyledButtonWrapper>
      </S.Content>
    </S.Wrapper>
  );
};
