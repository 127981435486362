import { BooleanTypes, Operator, Symbol } from "components/Search";
import { Click, SearchOption } from "../types";

class BooleanExpressionError extends Error {
  constructor(msg: string) {
    super(`Invalid Boolean Expression: ${msg}`);
  }
}

const hasBooleanOperatorInString = (inputValue: string) => {
  const hasOperator = Object.values(Operator).some((value) => inputValue.includes(value + " "));
  const hasSymbol = Object.values(Symbol).some((value) => inputValue.includes(value));
  return hasOperator || hasSymbol;
};

const booleanSearchQueryArray = (inputValue: string) =>
  inputValue
    .split(/(\bAND\b|\bOR\b|\bNOT\b|\(|\))/)
    .map((item) => item.trim())
    .filter((item) => item !== "");

const isBooleanSearch = (query: SearchOption[]) =>
  !!query.find(({ type }) => Object.values(BooleanTypes).includes(type as string));

// operands should always be numbers e.g. 1 or "1"
const isOperand = (item: string) => !Number.isNaN(parseFloat(item));

// Function to return precedence of operators
// When talking about operator precedence we say e.g. "Multiplication has higher precedence than addition",
// which means it has higher priority and executes first
// Using the same logic here, the operation with the higher number will execute first
const getPrecedence = (op: string) => {
  switch (op.toUpperCase()) {
    case Operator.NOT:
      return 3;
    case Operator.AND:
      return 2;
    case Operator.OR:
      return 1;
    default:
      return -1;
  }
};

const isOperatorStackValid = (operatorStack: string[], operandArray: string[]) => {
  // for AND and OR, there must be at least 1 existing operand in the stack
  // for NOT and brackets, we don't care about the existing operands
  let minOperands = 0;
  const andOrOperators = [Operator.AND, Operator.OR];
  operatorStack.forEach((item) => {
    andOrOperators.includes(item) && minOperands++;
  });
  const actualNumOperands = operandArray.filter((item) => isOperand(item)).length;
  if (actualNumOperands < minOperands) {
    return false;
  }
  return true;
};

//Function to convert infix expression array to postfix
const infixToPostfix = (exp: string[]) => {
  let postfix: string[] = [],
    st: string[] = [];

  let numLeftBrackets = 0,
    numRightBrackets = 0;

  let hasOperators = false;

  exp.forEach((item) => {
    if (isOperand(item)) {
      postfix.push(item);
    } else {
      switch (item) {
        case Symbol.LEFT_BRACKET:
          numLeftBrackets += 1;
          st.push(item);
          break;
        case Operator.NOT:
          st.push(item);
          break;
        case Symbol.RIGHT_BRACKET:
          numRightBrackets += 1;
          while (st.length && st[st.length - 1] !== Symbol.LEFT_BRACKET) {
            postfix.push(st.pop() as string);
          }
          if (!st.length) {
            throw new BooleanExpressionError("Mismatched brackets");
          }
          st.pop();
          if (st[st.length - 1] === Operator.NOT) {
            postfix.push(st.pop() as string);
          }
          break;
        default:
          while (st.length && getPrecedence(item) <= getPrecedence(st[st.length - 1])) {
            postfix.push(st.pop() as string);
          }
          st.push(item);
          break;
      }
      if (!hasOperators && st.length) {
        hasOperators = true;
      }
    }

    if (!isOperatorStackValid(st, postfix)) {
      throw new BooleanExpressionError("Not a boolean expression");
    }
  });

  if (!hasOperators) {
    throw new BooleanExpressionError("Not a boolean expression");
  }

  if (numLeftBrackets !== numRightBrackets) {
    throw new BooleanExpressionError("Mismatched brackets");
  }

  while (st.length) {
    const item = st.pop();
    if (Object.values(Operator).includes(item as string)) {
      postfix.push(item as string);
    } else {
      throw new BooleanExpressionError("Incomplete boolean expression");
    }
  }

  return postfix;
};

const computeNewInputFieldPosition = (click: Click, itemRects: DOMRect[], parentRect: DOMRect) => {
  if (click.x > parentRect.right || click.y > parentRect.bottom) {
    return -1;
  }

  let newInputIndex = -1;
  let pixelMatrix: number[][] = [];
  if (parentRect) {
    // create pixelMatrix of the size of the items container (parentRect) filled with 0s
    pixelMatrix = parentRect
      ? Array.from({ length: Math.floor(parentRect.right + 1) }, () => Array(Math.floor(parentRect.bottom + 1)).fill(0))
      : [];

    // iterate over pixelMatrix cells
    for (let i = 0; i < pixelMatrix.length; i++) {
      for (let j = 0; j < pixelMatrix.length; j++) {
        if (i < parentRect.left || j < parentRect.top) {
          // fill cells that are outside of the items container with -1s to indicate that they are not clickable
          pixelMatrix[i][j] = -1;
        } else if (itemRects.some((item) => i > item.left && i < item.right && j > item.top && j < item.bottom)) {
          // fill cells that correspond to items as per itemRects with 1s
          pixelMatrix[i][j] = 1;
        }
      }
    }

    if (!pixelMatrix.some((row) => row.some((cell) => cell === 1))) {
      return 0;
    }

    // check if click is on an item
    if (pixelMatrix[click.x][click.y] === 1) {
      // find clicked item + index
      const clickedItemIndex = itemRects.findIndex(
        (item) => click.x > item.left && click.x < item.right && click.y > item.top && click.y < item.bottom
      );
      const clickedItem = itemRects[clickedItemIndex];
      if (clickedItem) {
        // check if click is in first/last 30% of the item
        // if it's on the left 30%, move the input field to the left of the item
        // if it's on the right 30%, move the input field to the right of the item
        const clickPercentage = (click.x - clickedItem.left) / clickedItem.width;
        if (clickPercentage < 0.3) {
          newInputIndex = clickedItemIndex;
        } else if (clickPercentage > 0.7) {
          newInputIndex = clickedItemIndex + 1;
        }
        // if click is in the middle 40% of the item, then ignore it
      }
    } else if (pixelMatrix[click.x][click.y] === 0) {
      // if click is between items, then find the item that is to the left of the click
      // and move the input field to the right of that item
      itemRects.forEach((item, index) => {
        const nextItem = itemRects[index + 1];
        if (nextItem) {
          if (
            click.x > item.right &&
            click.x < nextItem?.left &&
            click.y > Math.min(item.top, nextItem.top) &&
            click.y < Math.max(item.bottom, nextItem.bottom)
          ) {
            newInputIndex = index + 1;
          }
        } else {
          // if click is to the right of the last item, move the input field at the end of the query
          if (click.x > item.right && click.y > item.top && click.y < item.bottom) {
            newInputIndex = index + 1;
          }
        }
      });
    }
  }

  return newInputIndex;
};

export {
  hasBooleanOperatorInString,
  booleanSearchQueryArray,
  isOperand,
  isBooleanSearch,
  infixToPostfix,
  getPrecedence,
  isOperatorStackValid,
  computeNewInputFieldPosition,
  BooleanExpressionError,
};
