import React, { ReactNode, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Button, IconButton, ListOption, Switch, Typography } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp, Close } from "@alphasights/alphadesign-icons";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { filterDefinitions } from "views/DeliverablesView/DeliverablesFilters";
import { orderBy } from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";

type AppliedFilters = { [key: string]: string[] };

export const DeliverablesFilterMobile = ({
  onClose,
  appliedFilters,
  options,
  transcriptTypesSet,
  onSubmitFilters,
}: {
  appliedFilters: AppliedFilters;
  options: {
    groups: FilterOption[];
    alphaNowTypesSet?: string[];
  };
  transcriptTypesSet: Set<string>;
  onClose: () => void;
  onSubmitFilters: (fil: AppliedFilters) => void;
}) => {
  const [selectedFilters, setSelectedFilters] = useState({ ...appliedFilters });
  const currentUser = useCurrentUser();

  const filterOptions = orderBy(
    filterDefinitions({
      options,
      appliedFilters,
      transcriptTypesSet,
      aiSynthesisOnly: currentUser?.aiSynthesisOnly,
    }),
    "type",
    "desc"
  );

  const {
    fullScreenFixedWrapper,
    filterHeader,
    filterOptionsWrapper,
    fullScreenFlexColWrapper,
  } = useMobileDeliverablesStyles();

  return (
    <x.div {...fullScreenFixedWrapper}>
      <x.div {...fullScreenFlexColWrapper}>
        <x.div {...filterHeader}>
          <Typography variant="h3">Filter</Typography>
          <IconButton onClick={onClose} variant="ghost" size="large">
            <Close />
          </IconButton>
        </x.div>
        <ListOption type="divider" />
        <x.div {...filterOptionsWrapper} flexGrow={1} overflow={"scroll"}>
          {filterOptions
            .filter((option) => option.type === "boolean" || option.options.length > 0)
            .map((category, idx) => {
              return (
                <React.Fragment key={idx}>
                  <FilterCategory category={category} setSelectedFilters={setSelectedFilters} />
                  {idx < filterOptions.length - 1 && <ListOption type="divider" />}
                </React.Fragment>
              );
            })}
        </x.div>
        <MobileDeliverablesFilterCTAs
          clearFilters={() => {
            onSubmitFilters({});
            onClose();
          }}
          applyFilters={() => {
            onSubmitFilters(selectedFilters);
            onClose();
          }}
        />
      </x.div>
    </x.div>
  );
};

const MobileDeliverablesFilterCTAs = ({
  applyFilters,
  clearFilters,
}: {
  applyFilters: () => void;
  clearFilters: () => void;
}) => {
  const { filterCtaBarWrapper } = useMobileDeliverablesStyles();
  return (
    <x.div data-testid="filter-ctas-bar" {...filterCtaBarWrapper}>
      <Button size="small" variant="outline" onClick={clearFilters} flexGrow={1} data-testid="clear-filters-btn">
        Clear All
      </Button>
      <Button size="small" variant="secondary" onClick={applyFilters} flexGrow={1} data-testid="apply-filters-btn">
        Apply Filters
      </Button>
    </x.div>
  );
};

const FilterCategoryTitle = ({
  title,
  rightElement,
  onClick,
}: {
  title: string;
  rightElement: ReactNode;
  onClick?: () => void;
}) => {
  const { filterCategoryTitle } = useMobileDeliverablesStyles();

  return (
    <x.div {...filterCategoryTitle} onClick={onClick}>
      <Typography variant="body-small-em">{title}</Typography>
      {rightElement}
    </x.div>
  );
};

const FilterCategory = ({
  category,
  setSelectedFilters,
}: {
  category: FilterDefinition;
  setSelectedFilters: React.Dispatch<React.SetStateAction<AppliedFilters>>;
}) => {
  const [expanded, setExpanded] = useState(true);
  const Chevron = expanded ? ChevronUp : ChevronDown;

  if (category.type === "boolean") {
    return <BooleanFilterCategory category={category} setSelectedFilters={setSelectedFilters} />;
  }

  return (
    <>
      <FilterCategoryTitle title={category.title} rightElement={<Chevron />} onClick={() => setExpanded((a) => !a)} />
      {expanded &&
        category.options.map((opt: FilterOption) => (
          <ListOption
            dataAttributes={{ "data-testid": `option-${opt.label}` }}
            indent={1}
            {...{
              type: "text",
              label: opt.label,
              inputType: "checkbox",
              selected: category.value.includes(opt.value),
              id: opt.value,
              onChange: ({ isSelected, value }: { value: string; isSelected: boolean }) => {
                if (isSelected) setSelectedFilters((f) => addToArrayField(category.name, value, f));
                else setSelectedFilters((f) => removeFromArrayField(category.name, value, f));
              },
            }}
          />
        ))}
    </>
  );
};

const BooleanFilterCategory = ({
  category,
  setSelectedFilters,
}: {
  category: FilterDefinition;
  setSelectedFilters: React.Dispatch<React.SetStateAction<AppliedFilters>>;
}) => {
  return (
    <FilterCategoryTitle
      title={category.title}
      rightElement={
        <Switch
          size="small"
          dataAttributes={{ "data-testid": `${category.name}-filter-switch` }}
          isSelected={category.value.includes("true")}
          onClick={(disabled: boolean) => {
            if (disabled) setSelectedFilters((f) => removeFromArrayField(category.name, "true", f));
            else setSelectedFilters((f) => addToArrayField(category.name, "true", f));
          }}
        />
      }
    />
  );
};

const addToArrayField = (field: string, value: string, target: AppliedFilters) => {
  return { ...target, [field]: [...(target[field] || []), value] };
};

const removeFromArrayField = (field: string, value: string, target: AppliedFilters) => {
  return { ...target, [field]: (target[field] || []).filter((v: string) => v !== value) };
};
