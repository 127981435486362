import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

type CommonSliderProps = {
  isAccordion?: boolean;
};

const SliderFilterContainer = styled.div(({ isAccordion = false }: CommonSliderProps) => {
  const { spacing } = useThemeTokens();
  return {
    paddingBottom: isAccordion ? 0 : spacing.inner.base03,
    width: "100%",
    overflowX: "hidden" as "hidden",
  };
});

const SliderWrapper = styled.div(({ isAccordion }: CommonSliderProps) => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return {
    width: isMobile ? "-webkit-fill-available" : "240px",
    paddingBottom: isMobile || isAccordion ? spacing.inner.base03 : 0,
    paddingLeft: spacing.inner.base02,
    paddingRight: isMobile || isAccordion ? spacing.inner.base03 : 0,
  };
});

export { SliderFilterContainer, SliderWrapper };
