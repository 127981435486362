import SearchItem from "./SearchItem";
import Option from "./Option";

const getDefaultComponents = () => ({
  SearchItem,
  Option,
});

export * from "./core";
export { SearchItem, Option, getDefaultComponents };
