import React, { useState, useRef, useEffect } from "react";
import { Loading, Typography, useAlphaToast } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useLocation } from "react-router-dom";
import { ContentCTAs } from "./ContentCTAs";
import { PrimerDetails } from "./PrimerDetails";
import { SuggestedDetailsForTranscript } from "./Suggestions/SuggestedDetailsForTranscript";
import useStyles from "./styles";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { useCitationTrackingContext } from "components/CitationContext/CitationTrackingContext";
import PrimerContent from "pages/AlphaNowPage/primers/CompanyPrimer/CompanyPrimerContentPage";
import MarketPrimersPage from "pages/AlphaNowPage/primers/MarketPrimer/MarketPrimersPage";
import CustomerPrimersPage from "pages/AlphaNowPage/primers/CustomerPrimer/CustomerPrimersPage";
import { noop } from "lodash";
import TranscriptActionBar from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActionBar/TranscriptActionBar";
import { View } from "models/Header";
import { CONTENT_TYPE_PERMISSION } from "constants/AlphaNow";
import ContentView from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView";
import { AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { PurchaseInDeliverables } from "./PurchaseInDeliverables";
import { ExpandSidebarButton } from "./ExpandSidebarButton/ExpandSidebarButton";

const SuggestionMainContent = ({
  suggestion,
  updateDeliverableSuggestion,
  freeSamples,
  appliedKeywords,
  onErrorLoading,
}) => {
  const { id, isAccessible, externalTitle } = suggestion;
  const [isLoading, setIsLoading] = useState(true);
  const { fetchContentById } = useDeliverableContext();

  const blurredContentImgSrc = require("pages/AlphaNowPage/images/Blurred-transcript-double.png");

  const { toast } = useAlphaToast();

  useEffect(() => {
    setIsLoading(true);
    fetchContentById(id)
      .then((data) => {
        updateDeliverableSuggestion(data.id, data);
      })
      .catch(() => {
        toast.error({ message: "Error fetching content" });
        onErrorLoading();
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const styles = useStyles({ obfuscated: true, showContentInDeliverables: isAccessible });

  const { project } = useCurrentProjectContext();

  const [viewMode, setViewMode] = useState(View.Transcript);
  const [searchQuery, setSearchQuery] = useState(
    appliedKeywords.map((keyword) => ({ label: keyword, value: keyword }))
  );
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [detailsCollapsed, setDetailsCollapsed] = useState(false);
  const [isTranscriptDataReady, setIsTranscriptDataReady] = useState(false);
  const [selectedComprehensiveSummaryIndex, setSelectedComprehensiveSummaryIndex] = useState(0);
  const [totalKeywordMatches, setTotalKeywordMatches] = useState(0);

  const keywordSearchProps = {
    selectedMentionIndex,
    setSelectedMentionIndex,
    selectedComprehensiveSummaryIndex,
    setSelectedComprehensiveSummaryIndex,
    totalKeywordMatches,
    setTotalKeywordMatches,
  };

  const { contentType } = suggestion;
  const transcriptActionBarProps = {
    showTranscript: true,
    isTranscriptDataReady,
    hasKeywords: searchQuery.length > 0,
    viewMode,
    searchQuery,
    setSearchQuery,
    detailsCollapsed,
    setDetailsCollapsed,
    contentTypePermission: CONTENT_TYPE_PERMISSION[contentType],
    keywordSearchProps,
  };

  const location = useLocation();
  const freeLink =
    freeSamples && freeSamples[contentType] && `${location.pathname}?selectedContent=${freeSamples[contentType]}`;

  const viewInTranscript = ({ dataset }) => {
    setSelectedMentionIndex(+dataset.mentionIndex);
    setViewMode(View.Transcript);
  };

  return (
    <x.div data-testid="deliverables-suggestion-main-content" {...styles.mainContent} pt={0}>
      <x.div {...styles.suggestionHeaderSeparator} data-testid="suggestion-header">
        <x.div {...styles.suggestionHeaderPaddings}>
          <ExpandSidebarButton />
          {contentType === "COMPANY_PRIMER" ? (
            <PrimerDetails
              suggestion={suggestion}
              updateDeliverableSuggestion={updateDeliverableSuggestion}
              freeLink={freeLink}
            />
          ) : (
            !detailsCollapsed && (
              <SuggestedDetailsForTranscript
                content={suggestion}
                detailsCollapsed={false}
                freeLink={freeLink}
                transcriptActions={
                  <ContentCTAs suggestion={suggestion} updateDeliverableSuggestion={updateDeliverableSuggestion} />
                }
                embeded
              />
            )
          )}
        </x.div>
        {!isAccessible && (
          <BottomBar suggestion={suggestion} updateDeliverableSuggestion={updateDeliverableSuggestion} />
        )}
        {isAccessible && contentType !== AlphaNowContentType.srm && (
          <TranscriptActionBar
            {...transcriptActionBarProps}
            setViewMode={setViewMode}
            transcriptActions={
              <x.div {...styles.suggestionCollapsedTranscriptCTAs}>
                <ContentCTAs
                  suggestion={suggestion}
                  updateDeliverableSuggestion={updateDeliverableSuggestion}
                  downloadFileName={externalTitle}
                />
              </x.div>
            }
            keywordSearchProps={keywordSearchProps}
          />
        )}
      </x.div>
      {isLoading ? (
        <x.div flex={1} display="flex" alignItems="center" justifyContent="center">
          <Loading />
        </x.div>
      ) : (
        <>
          {isAccessible ? (
            <>
              {contentType === "COMPANY_PRIMER" ? (
                <SuggestionContent id={id} />
              ) : (
                <ContentView
                  content={suggestion}
                  isTranscriptDataReady={isTranscriptDataReady}
                  setIsTranscriptDataReady={setIsTranscriptDataReady}
                  setDetailsCollapsed={setDetailsCollapsed}
                  searchQuery={searchQuery}
                  viewMode={viewMode}
                  isAudioView={false}
                  selectedMentionIndex={selectedMentionIndex}
                  setSelectedMentionIndex={setSelectedMentionIndex}
                  viewInTranscript={viewInTranscript}
                  contentError={null}
                  setContentError={noop}
                  contentVtt={null}
                  contentAudio={null}
                  projectToken={project.token}
                  keywordSearchProps={keywordSearchProps}
                />
              )}
            </>
          ) : (
            <x.div w="100%" h="100%" overflow={"hidden"}>
              <x.img
                data-testid={"blurred-suggestion-view"}
                src={blurredContentImgSrc}
                w="100%"
                h="100%"
                objectFit="cover"
                maxWidth="100%"
              />
            </x.div>
          )}
        </>
      )}
    </x.div>
  );
};

const SuggestionContent = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState();
  const { fetchAlphaNowContentById } = useDeliverableContext();

  useEffect(() => {
    fetchAlphaNowContentById({ id })
      .then(setContent)
      .catch(() => setContent(null))
      .finally(() => setLoading(false));
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <x.div flex="1" display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </x.div>
    );
  }

  if (!content) {
    return (
      <x.div flex="1" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body-large-em" color="secondary">
          Unable to load content.
        </Typography>
      </x.div>
    );
  }

  return (
    <PrimerContentWrapper
      content={content}
      setShouldShowPreviewAlert={() => {}}
      isPrimerDataReady={loading}
      isContentLoading={loading}
      isPaymentRequired={false}
      isPrimerFetchAttempted={false}
    />
  );
};
const PrimerContentWrapper = ({ content }) => {
  const primerContentRef = useRef();
  const { registerScrollContainer } = useCitationTrackingContext();

  const isScrollRefSelfManaged = content.contentType === AlphaNowContentType.srm;

  useEffect(() => {
    if (!isScrollRefSelfManaged) registerScrollContainer(primerContentRef);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  let PrimerComponent = null;
  let primerProps = {};

  if (content?.contentType === AlphaNowContentType.srm) {
    switch (content?.productType) {
      case AlphaNowProductType.companyPrimer:
        PrimerComponent = PrimerContent;
        primerProps = {
          content,
          isDeliverables: true,
          setShouldShowPreviewAlert: () => {},
        };
        break;
      case AlphaNowProductType.marketPrimer:
        PrimerComponent = MarketPrimersPage;
        primerProps = {
          selectedContent: content,
          showNavigationOnlyOnHeader: true,
          setShouldShowPreviewAlert: () => {},
        };
        break;
      case AlphaNowProductType.customerPrimer:
        PrimerComponent = CustomerPrimersPage;
        primerProps = {
          selectedContent: content,
          showNavigationOnlyOnHeader: true,
          setShouldShowPreviewAlert: () => {},
        };
        break;
      default:
        break;
    }
  }

  return <PrimerComponent {...primerProps} />;
};

const BottomBar = ({ suggestion, updateDeliverableSuggestion }) => {
  if (suggestion.isAccessible) {
    // 5829
    return null;
  }
  return (
    <PurchaseInDeliverables
      suggestion={suggestion}
      updateDeliverableSuggestion={updateDeliverableSuggestion}
      referrer="deliverables"
    />
  );
};

export { SuggestionMainContent };
