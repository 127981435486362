import { Avatar, Button, Icon, useTheme, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronRight, Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import React, { useMemo, useState } from "react";
import { tagHighlight, appendCues } from "./support";
import { EmphasisTypography } from "./EmphasisTypography";

export const Mention = ({ sentences, onTranscriptLinkClick }) => {
  const { containerStyles, cuesStyles, dividerStyles } = useMentionStyles();

  const transcript = useMemo(() => {
    return appendCues(sentences, false);
  }, [sentences]);

  return (
    <x.div data-testid="mention" {...containerStyles}>
      <x.div {...cuesStyles}>
        {transcript?.map(({ id, speaker, cues }, ix) => (
          <React.Fragment key={speaker + id}>
            <MentionCue key={ix} cues={cues} onTranscriptLinkClick={onTranscriptLinkClick} />
            {ix < transcript.length - 1 && <x.div {...dividerStyles} />}
          </React.Fragment>
        ))}
      </x.div>
    </x.div>
  );
};

const useMentionStyles = () => {
  const { tokens } = useTheme();

  return {
    containerStyles: {
      flexGrow: 1,
      display: "flex",
    },
    cuesStyles: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base04,
      maxWidth: "600px",
      mx: "auto",
      flexGrow: 1,
    },
    dividerStyles: {
      h: "1px",
      backgroundColor: tokens.color.border.divider,
    },
  };
};

const MentionCue = ({ cues, onTranscriptLinkClick }) => {
  const [cuesRef, setCuesRef] = useState();

  const {
    wrapperStyles,
    avatarStyles,
    viewTranscriptButtonStyles,
    contentStyles,
    textStyles,
  } = useMentionTranscriptStyles();

  const transcriptLinkAction = () => {
    const firstHighlightedEm = cuesRef.current?.querySelector("[data-transcript-highlight]");

    if (onTranscriptLinkClick && firstHighlightedEm) {
      const allEms = document.querySelectorAll("[data-transcript-highlight]");
      const foundIdx = [...allEms].indexOf(firstHighlightedEm);
      foundIdx >= 0 && onTranscriptLinkClick(foundIdx);
    }
  };

  return (
    <x.div {...wrapperStyles} className={"transcript-speaker-cue"}>
      <x.div {...contentStyles}>
        <Avatar size="small" {...avatarStyles}>
          <Expert />
        </Avatar>

        <x.div ref={cuesRef} {...textStyles}>
          {cues.map((cue) => {
            return (
              <EmphasisTypography ref={setCuesRef} component="span" data-transcript-id={cue.id}>
                {cue.hasHighlight ? tagHighlight(cue.text) : cue.text}
              </EmphasisTypography>
            );
          })}
        </x.div>
      </x.div>
      <Button
        data-testid="transcript-view-button"
        variant={"ghost"}
        size={"small"}
        onClick={transcriptLinkAction}
        endIcon={
          <Icon size="small">
            <ChevronRight />
          </Icon>
        }
        {...viewTranscriptButtonStyles}
      >
        View in Transcript
      </Button>
    </x.div>
  );
};

const useMentionTranscriptStyles = () => {
  const tokens = useThemeTokens();

  return {
    wrapperStyles: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base,
    },
    avatarStyles: {
      mr: tokens.spacing.inner.base06,
    },
    contentStyles: {
      flex: 1,
      display: "flex",
    },
    speakerStyles: {
      gap: tokens.spacing.inner.base,
      display: "flex",
      alignItems: "baseline",
    },
    cueActiveStyles: {
      backgroundColor: "rgba(242, 208, 36, 0.2)",
    },
    cueClickStyles: {
      cursor: "pointer",
      backgroundColor: {
        hover: tokens.color.background.neutral.default,
      },
    },
    viewTranscriptButtonStyles: {
      alignSelf: "end",
    },
    textStyles: {
      flex: 1,
    },
  };
};
