import React from "react";
import { Portal } from "@alphasights/alphadesign-components";

import SearchBar from "components/NavigationContainer/SearchBar";
import Navigation from "components/NavigationContainer/Navigation";

import * as S from "./TopBar.styled";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { RealTimeCreditBalance } from "components/GlobalNav/RealTimeCreditBalance";

const TopBar = () => (
  <S.TopBarWrapper id="navigation-topbar">
    <S.LeftPanel>
      <Navigation />
      <SearchBar />
    </S.LeftPanel>
    <RightPanel />
  </S.TopBarWrapper>
);

const RightPanel = () => {
  const currentUser = useCurrentUser();
  return (
    <S.RightPanel>
      {currentUser?.accessOnly && <RealTimeCreditBalance />}
      <div id="top-bar-portal-container" data-testid="top-bar-portal-container" style={{ height: "100%" }}></div>
    </S.RightPanel>
  );
};

const TopBarPortal = ({ children }: { children: JSX.Element }) => {
  const [containerEl, setContainerEl] = React.useState<HTMLElement | undefined>(undefined);
  React.useLayoutEffect(() => {
    setContainerEl(document.querySelector("#top-bar-portal-container") as HTMLElement);
  }, []);

  const Wrapper = containerEl ? Portal : React.Fragment;
  return <Wrapper containerEl={containerEl}>{children}</Wrapper>;
};

const useNavigationTopbarHeight = () => {
  const [element, setElement] = React.useState<HTMLElement | undefined>(undefined);
  React.useLayoutEffect(() => {
    setElement(document.querySelector("#navigation-topbar") as HTMLElement);
  }, []);

  return element ? element.getBoundingClientRect().height : 0;
};

export { TopBar, TopBarPortal, useNavigationTopbarHeight };
