import React from "react";
import { Typography } from "@alphasights/alphadesign-components";
import { Container } from "components/DefaultView/DefaultView.styled";

type DefaultViewProps = {
  title: string;
  subtitle: string;
};

const DefaultView = ({ title, subtitle, ...props }: DefaultViewProps) => {
  return (
    <Container {...props}>
      <Typography variant="body-large-em">{title}</Typography>
      <Typography variant="body">{subtitle}</Typography>
    </Container>
  );
};

export default DefaultView;
