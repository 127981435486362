export const thirdPartyAccessTerms =
  "Whereas AlphaSights provides services to Client under separate contractual terms, these Third Party Access Terms (the “Access Terms”) set out the terms on which AlphaSights will provide you as “Third Party” (each a “Party” and, together, the “Parties”) with access to such services, the Platform and any other AlphaSights Client-Facing Domain. These Access Terms will apply to Third Party in its capacity as Client’s Advisor, Client’s Client or Client’s Guest. In order to ensure that all Transactions take place within a secure and legally compliant environment, Third Party agrees to be bound by these Access Terms. Third Party acknowledges and agrees that: (i) participation in a Transaction; (ii) any instance of requesting or completing any engagement with an Expert or Transaction by or involving any of its Representatives, or (iii) access to, or any interaction with the Platform or any AlphaSights Client-Facing Domain, will, each and every time, confirm to AlphaSights that Third Party accepts to be bound by these Access Terms and, where applicable, any AlphaSights Client-Facing Domain Terms.<br />" +
  "Now, therefore, the Parties agree as follows:<br />" +
  "<br />" +
  "1.\tDefinitions and Interpretation<br />" +
  "1.1\tCapitalised terms used in these Access Terms but not otherwise defined will have the meanings given to them below:<br />" +
  "“Affiliate” means, with respect to any Entity, another Entity controlling, controlled by, or under common control with, such Entity. The term “control” for the purposes of this definition means possession of the power, directly or indirectly, whether by contract or ownership, to direct or cause the direction of the management and affairs of such Entity, including business and investment decisions.<br />" +
  "“AlphaSights” means AlphaSights Ltd, a company incorporated in England & Wales with company number 06534480, whose registered office is at Thames Court 3rd Floor, 1 Queenhithe, London EC4V 3DX, United Kingdom, or any Affiliate of AlphaSights Ltd, involved in the provision of services.<br />" +
  "“AlphaSights Client-Facing Domain” means any domain, website, webpage and online platform, including the Platform, made available to, or accessible by Third Party Users.<br />" +
  "“AlphaSights Client-Facing Domain Terms” means the Privacy Policy, the DPA and any terms governing Client’s or Third Party’s access to any AlphaSights Client-Facing Domain, made available or amended by AlphaSights from time to time.<br />" +
  "“AlphaSights Data” means all proprietary information gathered, created, produced, compiled, displayed or owned by AlphaSights and its Affiliates, including Evaluation Profiles, Transaction Profiles, usage reports or reporting materials, but not any Expert Engagement Record, accessible on the Platform or any other AlphaSights Client-Facing Domain, or transmitted to Client or Third Party via email or otherwise.<br />" +
  "“AlphaSights IP” means all Intellectual Property Rights owned or created by AlphaSights, including all Intellectual Property Rights subsisting in AlphaSights Data and the Platform or any other AlphaSights Client-Facing Domain.<br />" +
  "“Blinded Transaction” means a Transaction whereby neither Client nor Third Party’s identity is disclosed to the Expert(s).<br />" +
  "“Client” means a client of AlphaSights or any of Client’s Affiliates accessing any AlphaSights Client-Facing Domain, and/or requesting or completing Transactions.<br />" +
  "“Client’s Advisor” means any Entity that is not an AlphaSights Entity, a Client, Client User, Client’s Affiliate or an Expert, who Client engages for the provision of services to Client in relation to a Scope, including the requesting of or participation in Transactions, engaging with Experts on Client’s behalf, or accessing any AlphaSights Client-Facing Domain.<br />" +
  "“Client’s Client” means any Entity that is not an AlphaSights Entity, a Client, a Client User, Client’s Affiliate or an Expert, whose contractual engagement of Client for the provision of Client’s services gives rise to Client Users engaging with Experts or accessing any AlphaSights Client-Facing Domain.<br />" +
  "“Client-Confidential Expert Engagement Record” means any Expert Engagement Record of an Expert engagement completed by Client Users, Third Party User or by any other third party on behalf of Client, or output solicited from, submitted by or derived from Expert(s) or engagements with Expert(s) for Client, at Client’s request and for Client’s account.<br />" +
  "“Client’s Guest” means any Entity that is not an AlphaSights Entity, a Client, Client User, Client’s Advisor, Client’s Client, Client’s Affiliate or an Expert, who Client wishes to join a Transaction in relation to a Scope, including the requesting of or participation in Transactions, engaging with Experts, or accessing any AlphaSights Client-Facing Domain.<br />" +
  "“Client User” means any Representative of Client accessing the Platform and any other AlphaSights Client-Facing Domain, and/or requesting or completing Transactions.<br />" +
  "“DPA” means the AlphaSights Data Processing Agreement, currently available at www.alphasights.com/clientfacingdomainterms-dpa and as amended by AlphaSights from time to time.\t<br />" +
  "“Entity” means any individual, sole proprietorship, general partnership, limited partnership, limited liability partnership, joint venture, trust, unincorporated association, corporation, limited company, unlimited company, other entity or governmental entity (including any instrumentality, division, agency or department thereof).<br />" +
  "“Evaluation Profile” means profile information for an Expert (i) outlining, in short-view or full-view format, such Expert’s experience and perspectives; and (ii) excluding the details on how to participate in an engagement with such Expert.<br />" +
  "“Excluded Company” means an Entity whose employees Client or Third Party (in its capacity as Client’s Advisor or Client’s Guest) does not wish to engage in any Expert engagement within the context of a Scope in respect of which Expert engagements are sought by Client, having notified AlphaSights of this in writing at the time of the initial request for Expert engagement(s) on such Scope." +
  "<br />" +
  "“Expert” means an individual natural person whose Evaluation Profile, Transaction Profile, Expert Engagement Record, information and data relating to career history and fields of expertise, legal name, contact details or other Personal Data, is recorded on the Platform or any AlphaSights Client-Facing Domain, or otherwise made accessible to Client or Third Party.<br />" +
  "“Fees” means fees, commissions, bonuses or other amounts payable to AlphaSights, including any applicable VAT and any other applicable tax thereon.<br />" +
  "“Inside Information” means any information constituting inside information for the purposes of the UK Financial Services and Markets Act 2000 and the UK Criminal Justice Act 1993, and/or any information constituting material non-public information under the US Securities Exchange Act of 1934 or any similar equivalent Law applicable in any jurisdiction inside or outside the United States and the United Kingdom.<br />" +
  "“Intellectual Property Rights” means: (a) any patent, rights to inventions, designs, copyright and related rights, moral rights, database right, trademark, service mark, trade name, the right to sue for passing off or unfair competition; (b) proprietary rights in domain names; (c) rights to use, and protect the confidentiality of, trade secrets, know-hows, and confidential information; (d) applications, and rights to apply for and be granted registrations, including extensions and renewals of such rights; and (e) all other intellectual property rights of a similar nature or having an equivalent effect under the laws of any jurisdiction, in each case whether registered, registerable, unregistered, statutory, common law or otherwise.<br />" +
  "“Law” means any law (including common law), legislation, statute, rule, regulation, judgment, order, decree, injunction, code, ordinance or other pronouncement or requirement having the effect of law of any governmental body in any relevant jurisdiction.<br />" +
  "“Personal Data” means any personally identifiable information belonging to an identifiable natural person, including but not limited to, an identifier such as a name, an identification number, location data, an online identifier or factors specific to the physical, economic, cultural or social identity of that natural person and for the avoidance of doubt includes the term personal data as defined in the European Union Regulation (EU) 2016/679 (the GDPR).<br />" +
  "“Platform” means the online platform and its domains, incorporating proprietary technology, database and functionality owned, developed, maintained and/or operated by AlphaSights, on or through which Experts can be requested or engaged, and Expert Engagement Records and AlphaSights Data are accessible.<br />" +
  "“Platform Content” means, unless agreed otherwise in writing by the Parties, any AlphaSights Data and Expert Engagement Record that is not a Client-Confidential Expert Engagement Record, that is accessible on the Platform.<br />" +
  "“Privacy Policy” means the privacy and cookie policy made available on any AlphaSights Client-Facing Domain, and as amended by AlphaSights from time to time.<br />" +
  "“Representative” means any director, officer, partner, managing member, equity holder or employee acting for or on behalf of an Entity.<br />" +
  "“Scope” means the specific subject matter under consideration by Client from time to time, in respect of which engagements with Experts are sought, or Platform Content accessed.<br />" +
  "“Terms of Engagement” means the agreement between an Expert and AlphaSights, confirming the expected standards of Expert’s eligibility and conduct in relation to their participation in Expert engagements, or contribution to, submission of, or involvement in the production of, any Expert Engagement Record, as supplemented or amended by AlphaSights from time to time.<br />" +
  "“Third Party User” means a Representative of Third Party accessing the Platform and any other AlphaSights Client-Facing Domain, and/or requesting or completing Transactions.<br />" +
  "“Transaction” means each and every instance of a Client User or Third Party User completing an Expert engagement, or accessing Client-Confidential Expert Engagement Records or Platform Content, on or through any AlphaSights Client-Facing Domain.<br />" +
  "“Transaction Profile” means an Evaluation Profile including the details on how to participate in an engagement with an Expert.<br />" +
  "1.2\tExcept where expressly stated otherwise in these Access Terms, the following rules of interpretation apply: (i) the word “including” has the inclusive meaning with the phrase “including, but not limited to” or “including without limitation”; (ii) the word “or” will be inclusive; (iii) words used will have a comparable meaning when used in a plural or in singular form; (iv) references to any gender will include each other gender; and (v) references to Law include any amendment, replacement, restatement or other modification of such law.<br />" +
  "<br />" +
  "2.\tConditions of Use<br />" +
  "2.1\tThird Party acknowledges and agrees that it may request or complete an engagement with an Expert, participate in Transactions or access the Platform or any AlphaSights Client-Facing Domain in either its capacity as Client’s Advisor Client’s Client or Client’s Guest in accordance with these Access Terms. To the extent that Third Party engages in the foregoing as:<br />" +
  "(a)\tClient’s Advisor, Third Party may participate in such Transactions or access the Platform and any other AlphaSights Client Facing Domain on Client’s behalf, including providing instructions to AlphaSights and requesting Evaluation Profiles on Client’s behalf; <br />" +
  "(b)\tClient’s Client, Third Party may participate in such Transactions or access the Platform and any other AlphaSights Client-Facing Domain in accordance with these Access Terms; or<br />" +
  "(c)\tClient’s Guest, Third Party may participate in such Transactions or access the Platform and any other AlphaSights Client Facing Domain, including providing instructions to AlphaSights and requesting Evaluation Profiles.<br />" +
  "2.2\tUpon requesting services in its capacity as Client’s Advisor or Client’s Guest or, if relevant, accessing any AlphaSights Client-Facing Domain, Third Party will, at the time of the initial request, provide AlphaSights with an instruction that conveys a clear and precise Scope to enable AlphaSights to identify Experts for the relevant Scope and, where applicable, clearly identifies any Excluded Companies.<br />" +
  "2.3\tThird Party acknowledges and agrees that:<br />" +
  "(a)\tExpert is an independent contractor and is not a Representative of AlphaSights, and any Expert Engagement Record or information solicited from, submitted by or derived from Expert or any engagement with Expert, solely constitutes the opinion of such Expert and not the opinion of AlphaSights;<br />" +
  "(b)\tExpert is solely responsible for determining whether their participation in any Expert engagement given the Scope, of such Expert engagement and/or contribution to, submission of, or involvement in the production of, any Expert Engagement Record is appropriate and permissible;<br />" +
  "(c)\tExpert is free in their sole discretion to decline participation in any Expert engagement and/or contribution to, submission of, or involvement in the production of, any Expert Engagement Record at any time, and, once participating in an Expert engagement and/or contributing to, submitting, or being involved in the production of, an Expert Engagement Record, is under no obligation to continue doing so;<br />" +
  "(d)\tunder the Terms of Engagement, Expert is prohibited from:<br />" +
  "(i)\tproviding financial, accountancy, investment, legal, medical or other professional advice; and<br />" +
  "(ii)\tanswering any questions, participating in an Expert engagement, or contributing to, submitting, or being involved in the production of, any Expert Engagement Record if:<br />" +
  "(a)\tdoing so could give rise to:<br />" +
  "(i)\ta breach of applicable Laws or any agreement to which Expert is a party;<br />" +
  "(ii)\ta conflict of interest on the part of Expert, given the Scope;<br />" +
  "(iii)\ta breach of a duty of confidentiality or any other obligation owed by Expert to any third party;<br />" +
  "(iv)\tthe disclosure of any Inside Information; or<br />" +
  "(v)\tthe disclosure of any trade or professional secret;<br />" +
  "(b)\tthe Scope includes any specific examination of an Entity of which Expert is an officer, director or employee;<br />" +
  "(c)\tExpert is a director, officer or other employee of an Entity that has made or is subject to a tender offer, or is employed by an Entity that has acted on behalf of an Entity in connection with such tender offer, and, in either case, the Scope is in any way related to such tender offer or takeover offer;<br />" +
  "(d)\tExpert is an auditor, and the Scope focuses on Entities it currently audits or has audited in the last three years;<br />" +
  "(e)\tExpert is a doctor or medical professional participating in a clinical trial, where the results of such trial have not been made public, and the Scope can reasonably be expected to elicit, or questions on the Scope seek to elicit, material non-public or other confidential information from such trial; or<br />" +
  "(f)\tExpert has worked in the accounting or finance department of any Entity within the last six months, and the Scope or questions pertain in any way to accounting or financial issues relating to that Entity or that Entity’s Affiliates;<br />" +
  "(e)\tAlphaSights does not, and will not, provide any representation, warranty or undertaking, or accept any responsibility or liability whatsoever in respect of:<br />" +
  "(i)\tthe appropriateness (including the non-existence of conflicts), legality or suitability of:<br />" +
  "(a)\tany Expert engagement or AlphaSights Data or Expert Engagement Record accessible on any AlphaSights Client-Facing Domain, or otherwise made accessible to Client or Third Party, or any matters discussed or contained therein; <br />" +
  "(b)\tthe participation of Client, Third Party or Expert in any Expert engagement or Transaction.<br />" +
  "(c)\tClient’s or Third Party’s access to any AlphaSights Client-Facing Domain, AlphaSights Data or Expert Engagement Record; or<br />" +
  "(d)\tExpert’s contribution to, submission of, or involvement in the production of, any Expert Engagement Record.<br />" +
  "(ii)\tthe completeness, reliability, legality, non-infringement of third-party Intellectual Property Rights, accuracy or predictive value of any documents or any other materials in any format provided to Client or Third Party by either Expert or AlphaSights, including, for the avoidance of doubt:<br />" +
  "(a)\tany responses provided leading up to, during and following any Expert engagement, Transaction or any Evaluation Profile being made accessible to Client or Third Party;<br />" +
  "(b)\tany information provided upon receipt and acceptance by AlphaSights of an instruction, or contained in Evaluation Profiles and Transaction Profiles made accessible to Client or Third Party; <br />" +
  "(c)\tany Client Confidential Expert Engagement Record; and <br />" +
  "(d)\tany Platform Content that is accessible on the Platform.<br />" +
  "(iii)\tExpert’s compliance with their obligations and undertakings under the Terms of Engagement; or<br />" +
  "(iv)\tthe accuracy or completeness of any Expert Engagement Records, translation or transcription services provided to Client or Third Party in the context of a Transaction;<br />" +
  "(f)\tEvaluation Profiles and Transaction Profiles are supplied to Representatives of Client or Third Party (in its capacity as Client’s Advisor or Client’s Guest) for reference only and may only be used by Client or such Third Party for the purpose of determining whether to request a Transaction or Expert engagement(s) on the Scope. In the event that Representatives of Third Party (in its capacity as Client’s Client) have access to Evaluation Profiles and/or any Transaction Profiles, such Third Party acknowledges and agrees that these profiles are provided for reference only and may only be used in the context of the specific Scope and for no other purpose whatsoever. Such profiles may be amended or withdrawn by AlphaSights at any time and AlphaSights has absolute discretion concerning the contents of such profiles; and<br />" +
  "(g)\tThird Party’s access and use of any AlphaSights Client-Facing Domain, as made available to it by AlphaSights (including to any links to webpages containing Transaction information, Evaluation Profiles or Transaction Profiles) are governed by the AlphaSights Client-Facing Domain Terms and these Access Terms. Use of, or access to, any AlphaSights Client-Facing Domain is only permitted within the context of the Scope and may not be used or accessed for any other reason. <br />" +
  "2.4\tSubject always to each of AlphaSights’ and Expert’s agreement, Third Party acknowledges and agrees that AlphaSights Data and Expert Engagement Records are provided for and are to be used for the sole purpose of Client’s evaluation of the Scope and, where applicable, the delivery of services to Client on such Scope. While AlphaSights Data and Expert Engagement Records have been prepared in good faith, they are not, and do not purport to be: <br />" +
  "(a)\tinvestment, financial or legal advice or any recommendation from AlphaSights, any of its Representatives, or any Expert as to how to proceed with any business decisions by Client or Third Party or, where applicable, recommendations provided by Third Party (in its capacity as Client’s Advisor) to Client and, in particular, is not intended to be, and should not be construed as a recommendation or advice as regards any business or investment decision, an offer to sell, a solicitation of an offer to buy, or an endorsement or recommendation of any particular financial instrument;<br />" +
  "(b)\tcontaining the opinions, advice, recommendations or views of AlphaSights or any of its Representatives;<br />" +
  "(c)\tindependently verified;<br />" +
  "(d)\tan accurate, comprehensive, or complete record of Transactions or all relevant issues relating to their Scope; or<br />" +
  "(e)\ta substitute for Client’s or Third Party’s own analysis, understanding or interpretation of Transactions. <br />" +
  "2.5\tNotwithstanding that AlphaSights may review or modify any Client-Confidential Expert Engagement Record and any Platform Content in line with its internal policies, AlphaSights does not, and will not, provide any representation, guarantee, warranty or undertaking, or accept any responsibility or liability whatsoever that any Client-Confidential Expert Engagement Record and any Platform Content:<br />" +
  "(a)\tis accurate or complete;<br />" +
  "(b)\tis compliant with all applicable Law or in accordance with undertakings given by, and obligations of, any client or Expert under any access terms or Terms of Engagement respectively;<br />" +
  "(c)\tdoes not violate or infringe the intellectual property or other proprietary rights of another party; or<br />" +
  "(d)\tis free of any Inside Information, trade or professional secret, or confidential information belonging to another party.<br />" +
  "<br />" +
  "The Parties further agree that Third Party remains solely responsible for ensuring its compliance with applicable Laws (including all relevant securities laws) when accessing and making use of any Client-Confidential Expert Engagement Record and any Platform Content.<br />" +
  "<br />" +
  "3.\tUndertakings<br />" +
  "3.1\tTo the extent applicable, Third Party undertakes and agrees that:<br />" +
  "(a)\tit will not participate in, and will not instruct Client to request AlphaSights to facilitate, a Transaction with any Expert who Third Party where it has actual knowledge that the Expert is, at the time, employed by an Excluded Company;<br />" +
  "(b)\tit will at the time of requesting an engagement with an Expert, in addition to having identified any Excluded Companies (where applicable), provide sufficient information to AlphaSights on the Scope to enable the Expert to decide whether the Expert is eligible under the Terms of Engagement to participate in an engagement on such Scope;<br />" +
  "(c)\tit will not, and will not request AlphaSights to, engage or communicate with any Expert in circumstances in which it knows (given its knowledge as to the Scope) or becomes aware that engaging or communicating with Expert constitutes or would reasonably be regarded as constituting:<br />" +
  "(i)\ta breach of applicable Laws or any agreement to which Client, Third Party or Expert is a party;<br />" +
  "(ii)\ta conflict of interest on the part of Expert ;<br />" +
  "(iii)\ta breach of a duty of confidentiality or any other obligation owed by Client, Third Party or Expert to any third party; or<br />" +
  "(iv)\ta breach of any of the terms of these Access Terms;<br />" +
  "(d)\tit will not solicit or attempt to solicit, and will not request Client or AlphaSights to solicit or attempt to solicit, from any Expert any:<br />" +
  "(i)\tfinancial, accountancy, investment, legal, medical or other professional advice;<br />" +
  "(ii)\tproprietary or confidential information or any information which Third Party could anticipate would reasonably be regarded as such;<br />" +
  "(iii)\tInside Information; or<br />" +
  "(iv)\ttrade or professional secret, and Third Party is and will always remain solely responsible for ensuring compliance with the requirements of this clause 3.1(d).<br />" +
  "(e)\tit will not disclose Inside Information to Client, AlphaSights or any Expert, and if Third Party comes into possession of any Inside Information through a Transaction or an Expert Engagement Record, Third Party will act in accordance with applicable Law as a possessor of such information;<br />" +
  "(f)\tit will not request Evaluation Profiles for, participate in a Transaction or request or complete any engagement with, or access any Platform Content solicited from, submitted by or derived from an engagement with, any Expert who Third Party knows is a director, officer or employee of any of its or Client’s direct competitors;<br />" +
  "(g)\tit bears and will continue to bear full and sole responsibility for its own business and investment decisions and will not base such decisions or advice, solely on any information obtained from any Expert, any Client-Confidential Expert Engagement Record, any AlphaSights Data or any Platform Content;<br />" +
  "(h)\tit will not disclose any information which could identify any Expert to any third party, or attribute any Expert Engagement Record directly to any Expert or AlphaSights, without the prior written consent of each of AlphaSights and the relevant Expert; <br />" +
  "(i)\twithout prejudice to clause 3.1(h) or clause 8, Third Party will not:<br />" +
  "(i)\tdisclose, sell, market, license, assign or otherwise share any information obtained through Transactions, any engagement with an Expert, or any Evaluation Profile, Client-Confidential Expert Engagement Record, Platform Content, AlphaSights Data, or information contained in any of the aforementioned, to or with any third parties (other than Affiliates and Client), without the prior written consent of each of AlphaSights and, where applicable, the relevant Expert; or<br />" +
  "(ii)\tdisclose to any Affiliate, any information obtained from any Transaction or contained in any Client-Confidential Expert Engagement Record, involving a Representative (other than an equity holder) of any direct competitor of such Affiliate;<br />" +
  "(j)\tfollowing receipt of an Expert’s Evaluation Profile or any participation in a Transaction, and subject to clause 3.1(k) below, Third Party will not communicate with such Expert in any way, other than through AlphaSights or during a Transaction completed on or through the Platform;<br />" +
  "(k)\tit will not directly or indirectly engage with an Expert or enter into any separate business arrangement with such Expert within one year of being provided with the Expert’s Evaluation Profile, unless it provides AlphaSights prior written notice of the same setting out details relating to the scope of services to be provided by Expert, following which AlphaSights will charge Fees in respect of such engagement; and<br />" +
  "(l)\tit will comply with all applicable Laws, including the US Foreign Corrupt Practices Act 1977, the UK Bribery Act 2010 and other anti-bribery and anti-corruption Laws (“Anti-Corruption Laws”) and its obligations under the DPA. Third Party, its Affiliates and Representatives will not engage in any activity practice or conduct which would constitute or would reasonably be perceived to constitute an offence under any Anti-Corruption Laws. Third Party further represents and warrants that each of Third Party, its Representatives and Affiliates are not subject to any applicable sanctions, or directly or indirectly owned or controlled by any person who is subject to sanctions.<br />" +
  "3.2\tIn the event of a Blinded Transaction, Third Party undertakes and agrees not to disclose its identity, or the identity of the Client to Expert (s).<br />" +
  "<br />" +
  "4.\tLimitations of Use<br />" +
  "4.1\tAccess to any AlphaSights Client-Facing Domain is subject to the relevant AlphaSights Client-Facing Domain Terms, and AlphaSights Data and Expert Engagement Records may only be used by Third Party in the evaluation of and the context of the Scope of any Transaction in accordance with these Access Terms .<br />" +
  "4.2\tExcept where necessary to exercise the rights set out in clause 4.1 above, Third Party and Third Party User may not download, store, record, copy, reproduce, republish, transmit, transfer, display, distribute, take screen shots of or otherwise share any portion of any AlphaSights Client-Facing Domain, AlphaSights Data or Platform Content.<br />" +
  "4.3\tThird Party undertakes and agrees that:<br />" +
  "(a)\tthroughout the term of these Access Terms, and whenever accessing any part of any AlphaSights Client-Facing Domain, it will comply with, and ensure that any Third Party Users with access to such AlphaSights Client-Facing Domain comply with, the AlphaSights Client-Facing Domain Terms and these Access Terms;<br />" +
  "(b)\tit will not, and will ensure that Third Party Users do not:<br />" +
  "(i)\taccess or use, or attempt to access or use, any AlphaSights Client-Facing Domain by any automated means or otherwise (including any deep-link, scraper, robot, bot, spider, data mining, computer code or any other device, program, tool, algorithm, process or methodology or process having similar functionality) for the purposes of scraping, extracting, harvesting or otherwise obtaining any AlphaSights Data, AlphaSights or Expert Personal Data, or Expert Engagement Records, or to access, acquire, copy or monitor any part of any AlphaSights Client-Facing Domain;<br />" +
  "(ii)\tcollect, harvest or store any AlphaSights Data, AlphaSights or Expert Personal Data, or Expert Engagement Records from any AlphaSights Client-Facing Domain or any of AlphaSights’ systems, or attempt to decipher any transmission to or from the servers running any AlphaSights Client-Facing Domain;<br />" +
  "(iii)\tmake alterations to, or modifications of, the whole or any part of any AlphaSights Client-Facing Domain, or permit any AlphaSights Client-Facing Domain, or any part of any AlphaSights Client-Facing Domain to be combined with, or become incorporated in, any other programs;<br />" +
  "(iv)\tdecompile, disassemble, decrypt or reverse engineer any portion of any AlphaSights Client-Facing Domain;<br />" +
  "(v)\tuse any AlphaSights Client-Facing Domain in conjunction with any machine learning, neural network, deep learning, predictive analytics or other artificial intelligence computer or software program;<br />" +
  "(vi)\tviolate the security of any AlphaSights Client-Facing Domain, or attempt to gain or facilitate unauthorised access to any AlphaSights Client-Facing Domain, or AlphaSights’ computer systems or networks connected to any server associated with AlphaSights, through hacking, password sharing, password mining, social engineering or any other means; or<br />" +
  "(vii)\tuse any part of AlphaSights Client-Facing Domain in a manner that infringes or violates the Intellectual Property Rights or other proprietary rights of AlphaSights, its Affiliates or any third party, including rights of privacy and publicity;<br />" +
  "(c)\tit will not permit any third party to access any AlphaSights Client-Facing Domain, or complete Transactions, without written authorisation from AlphaSights;<br />" +
  "(d)\tit will not retain any information contained in an Evaluation Profile or in a Transaction Profile. Third Party may not download, store, record, copy, reproduce, republish, transmit, transfer, display, distribute or take screenshots of or otherwise share any portion of an Evaluation Profile or Transaction Profile or other AlphaSights Data;<br />" +
  "(e)\tThird Party, its Affiliates and and Third Party Users will not directly, or indirectly, sell, lease, disclose, otherwise share, distribute, commercially exploit or transfer any AlphaSights Data, Expert or AlphaSights Personal Data, or Expert Engagement Records; and<br />" +
  "(f)\tit will not permit any third party to access any AlphaSights Client-Facing Domain, or complete Transactions, without written authorisation from AlphaSights.<br />" +
  "4.4\tThird Party, its Affiliates and Third Party Users will only use Client-Confidential Expert Engagement Records in connection with evaluation of the Scope in connection with which it and/or Client completed Transaction(s), and for no other purpose. Third Party will not distribute, exploit or permit to be exploited, copy, reproduce, republish, transmit, transfer or otherwise share any Client-Confidential Expert Engagement Records, AlphaSights Data or any Platform Content.<br />" +
  "<br />" +
  "5.\tLiability<br />" +
  "5.1\tNothing in these Access Terms shall limit or exclude (or purport to limit or exclude) AlphaSights’ liability for<br />" +
  "(a)\tdeath or personal injury caused by its negligence, or the negligence of its Representatives, agents or subcontractors;<br />" +
  "(b)\tfraud or fraudulent misrepresentation; or<br />" +
  "(c)\tany other liability that cannot be excluded or limited by applicable Law.<br />" +
  "5.2\tSubject to clause 5.1, under no circumstances will AlphaSights, its Affiliates or its Representatives be liable to Third Party, whether in contract, misrepresentation (whether tortious or statutory), tort (including negligence), breach of statutory duty or otherwise, for<br />" +
  "(a)\tany indirect, special or consequential loss; or<br />" +
  "(b)\tany loss of revenues, loss of profits, loss of contracts, anticipated savings or loss of business of any kind or nature arising under or in connection with these Access Terms or otherwise.<br />" +
  "5.3\tAlphaSights, its Affiliates and its Representatives are not and will not be liable to Third Party for any acts or omissions of any Expert, or for any Client Confidential Expert Engagement Record, Platform Content or AlphaSights Data, and in no event will AlphaSights, its Affiliates or its Representatives be liable for any claims made against Third Party by third parties.<br />" +
  "5.4\tExcept as expressly set out herein, all warranties, conditions representations, undertakings and other terms which might have effect between the Parties or be implied or incorporated into these Access Terms by Law (whether by statute, common law, custom, course of dealing or otherwise) are, to the fullest extent permitted by Law, excluded from these Access Terms.<br />" +
  "5.5\tThird Party agrees, to the maximum extent available under applicable Law, that all AlphaSights Representatives assume no personal duty to Third Party and Third Party hereby disclaims any such reliance on any actual or perceived personal duty by any AlphaSights Representative and undertakes and agrees not to bring any claim against any AlphaSights Representative alleging a breach of any actual or perceived personal duty.<br />" +
  "<br />" +
  "<br />" +
  "6.\tIntellectual Property<br />" +
  "6.1\tThe Parties agree that AlphaSights is the owner or licensee of all AlphaSights IP and all Intellectual Property Rights in the AlphaSights IP.<br />" +
  "6.2\tAlphaSights grants Third Party a limited, non-exclusive, non-transferable, non-sublicensable, revocable, royalty-free licence to access any AlphaSights Client-Facing Domain, and use AlphaSights IP only for the purposes of and subject to the limitations and rights under clause 4: <br />" +
  "(a)\tevaluating AlphaSights Data, Expert or AlphaSights Personal Data, Client-Confidential Expert Engagement Records, Platform Content; and<br />" +
  "(b)\tparticipation in Expert engagements and completion of Transactions, always in accordance with these Access Terms. <br />" +
  "6.3\tThird Party shall not share any AlphaSights IP, AlphaSights Data, Expert or AlphaSights Personal Data, or Platform Content with any third party, and, other than as provided for under these Access Terms, Third Party may not share any Client-Confidential Expert Engagement Records with any third party.<br />" +
  "6.4\tThird Party acknowledges and agrees that it will not acquire any type of right or ownership in relation to the AlphaSights IP other than the licence granted under Clause 6.2, and that the right of access to AlphaSights Client Facing Domains is subject to the licence granted under Clause 6.2.<br />" +
  "6.5\tIn the event that Third Party becomes aware of any third party accessing, processing, downloading, copying or storing any AlphaSights IP, AlphaSights Data, Expert or AlphaSights Personal Data, or Platform Content, Third Party will immediately notify and cooperate with AlphaSights to enable AlphaSights to take appropriate action.<br />" +
  "<br />" +
  "7.\tData Protection<br />" +
  "To the extent that a Party provides to the other Party any Personal Data in connection with any Access Plan, AlphaSights and Third Party will comply with the terms of the DPA, the terms of which are incorporated into these Third Party Terms.<br />" +
  "<br />" +
  "8.\tConfidentiality<br />" +
  "8.1\tThird Party will keep confidential and will at all times, both during the term of these Access Terms and for a period of 2 years from the date on which AlphaSights ceases providing Client with access to any AlphaSights Client-Facing Domain, not disclose to any third party any Confidential Information (as defined below), whether in written or any other form, disclosed to Third Party by Client or AlphaSights.<br />" +
  "8.2\t“Confidential Information” includes (a) the existence and terms of, these Access Terms, any AlphaSights Client Domain Terms, and any documents or arrangements relating to (i) the access to any AlphaSights Client-Facing Domain; or (ii) Transactions under such documents; (b) all AlphaSights Data and Platform Content; (c) the identity of Third Party, Client and Expert; (d) any Transactions (including any matters disclosed therein or related communications between the Parties) and Client-Confidential Expert Engagement Records; and (e) information which is identified as confidential or is clearly by its nature confidential, including usage reports, trade secrets, information and data relating to existing or potential clients, codified content, information relating to products or services, financial information, marketing and sales information, or any information which the receiving party has been informed is confidential or which it might reasonably expect AlphaSights would regard as confidential, including any proprietary and confidential information of third parties.<br />" +
  "8.3\tNotwithstanding clause 8.1:<br />" +
  "(a)\tThird Party may disclose Confidential Information under conditions of confidentiality to its and its Affiliates’ Representatives, Client, or Experts to the extent necessary for the purposes contemplated in these Access Terms; and<br />" +
  "(b)\tThird Party may disclose Confidential Information required to be disclosed by applicable Law, provided that Third Party will, to the extent permissible by Law, promptly notify AlphaSights of the information to be disclosed and of the circumstances in which the disclosure is alleged to be required.<br />" +
  "(c)\tAlphaSights may disclose Confidential Information to its professional advisors in the context of an engagement subject to appropriate professional duties of confidentiality and conduct.<br />" +
  "8.4\tUpon written request from AlphaSights, Third Party will, and will cause its respective Affiliates to, return or destroy any and all Confidential Information of AlphaSights then in its possession or control and will not retain any copies of the same (save as required by applicable Law).<br />" +
  "8.5\tThird Party will promptly, upon becoming aware of any unauthorised disclosure, misuse, theft or other loss of Confidential Information, whether inadvertent or otherwise, give notice of the same to AlphaSights.<br />" +
  "8.6\tThird Party acknowledges and agrees that Transactions in any call format will take place on the Platform and shall be recorded (in visual or audio form) or transcribed by only AlphaSights or its agents and having obtained prior written consent of Experts participating in such Transactions. Third Party hereby consents to such recording and transcription.<br />" +
  "8.7\tAlphaSights may outsource support services, including translation services or transcription services, on the basis that its suppliers agree to keep any information they receive from AlphaSights confidential, and Third Party hereby consents to such outsourcing.<br />" +
  "<br />" +
  "9.\tWaiver<br />" +
  "No delay or failure in exercising any right under these Access Terms, or any partial or single exercise of any right, will constitute a waiver of that right or any other rights. No consent to a breach of any express or implied term of these Access Terms will constitute consent to, or a waiver of any rights in respect of, any subsequent breach.<br />" +
  "<br />" +
  "10.\tThird Parties<br />" +
  "The Parties confirm their intent not to confer any rights on any third parties by virtue of these Access Terms and, accordingly, the Contracts (Rights of Third Parties) Act 1999 will not apply, except that Third Party recognises and agrees that AlphaSights’ Affiliates are third-party beneficiaries under these Access Terms.<br />" +
  "<br />" +
  "11.\tSeverability<br />" +
  "In the event that any part of the terms, conditions or provisions contained in these Access Terms are determined by any competent authority to be invalid, unlawful, or unenforceable to any extent (in whole or in part), such term, condition or provision will to that extent be severed from the remaining terms, conditions or provisions, as the case may be, which will continue to be valid and enforceable to the fullest extent permitted by Law, and these Access Terms will be reformed, construed and enforced as if such invalid, unlawful or unenforceable provision had never been contained herein.<br />" +
  "<br />" +
  "12.\tAssignment<br />" +
  "These Access Terms will be binding upon and will inure to the benefit of the respective successors and assigns of AlphaSights. AlphaSights is entitled to assign or transfer any or all its rights and obligations under these Access Terms without Third Party’s consent.<br />" +
  "<br />" +
  "13.\tAmendments<br />" +
  "AlphaSights may in good faith amend these Access Terms from time to time.<br />" +
  "<br />" +
  "14.\tEntire Agreement<br />" +
  "With the exception of any direct contractual relationship between AlphaSights and Third Party, these Access Terms and the AlphaSights Client-Facing Domain Terms constitute the entire agreement between the Parties and supersede all prior negotiations, communications, representations, agreements and understandings of the Parties with respect to the subject matter thereof. Third Party acknowledges and agrees that in entering into these Access Terms and any AlphaSights Client-Facing Domain Terms, it has not relied upon, and will have no rights in respect of, any oral or written statements, collateral or other warranties, assurances, undertakings or representations which were made by or on behalf of AlphaSights in relation to the subject matter of the Access Terms or any AlphaSights Client-Facing Domain Terms and which are not expressly set out in such terms. Except where required by Law, if there is any conflict between the AlphaSights Client-Facing Domain Terms and these Access Terms, these Access Terms will prevail.<br />" +
  "<br />" +
  "15.\tGoverning Law and Jurisdiction<br />" +
  "These Access Terms (including any non-contractual obligations arising out of or in connection with them) are governed by English law and the Parties hereby submit to the exclusive jurisdiction of the English courts.";
