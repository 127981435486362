import React, { FC } from "react";

import { IconButton, useThemeTokens } from "@alphasights/alphadesign-components";
import { Settings } from "@alphasights/alphadesign-icons";

export const buttonTestId = "private-library-settings-button";

type PrivateSettingsButtonProps = {
  onClick: () => void;
};

const PrivateSettingsButton: FC<PrivateSettingsButtonProps> = ({ onClick }) => {
  const {
    color: { text },
  } = useThemeTokens();

  return (
    <IconButton testId={buttonTestId} onClick={onClick} color={text.secondary} variant="basic" size="medium">
      <Settings />
    </IconButton>
  );
};

export default PrivateSettingsButton;
