export enum EXPERT_ACTION {
  Add = "ADD",
  Select = "SELECT",
  Deselect = "DESELECT",
}

export type ExpertDataAction = {
  type: EXPERT_ACTION;
  payload: { speakers: ExpertRow[] };
};

export type Speaker = {
  speakerId: number;
  companyName: string;
  angleType: string;
  position: string;
  dateHeld: string;
  bio: string;
  cdsAlphaCompanyId: number;
};

export interface ExpertRow extends Speaker {
  isSelected: boolean;
  isSelectedCompany: boolean;
}

export type CachedExpertData = Record<number, ExpertRow>;

export type SpeakerContributionsResponse = {
  currentPage: number;
  content: Speaker[];
  totalElements: number;
  numberOfElements: number;
  hasPrevious: boolean;
  hasNext: boolean;
};
