import React from "react";
import { Alert, Button } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import { useStyles } from "./ProjectSummary.styles";

export const ProjectSummaryError = () => {
  const { selectedSummary, generateSingleProjectSummary, onSelectSummary } = useProjectSummariesContext();

  const styles = useStyles();

  return (
    <x.div {...styles.errorAlertWrapper}>
      <Alert variant="danger">
        <b>Error occurred:</b> unable to generate transcript summary. Please try again by clicking ‘Regenerate Summary’.
      </Alert>
      <Button
        variant="outline"
        size="small"
        data-testid="project-summary-regenerate-btn"
        onClick={() =>
          generateSingleProjectSummary(selectedSummary!!.angleId).then((response) => onSelectSummary(response.id))
        }
      >
        Regenerate Summary
      </Button>
    </x.div>
  );
};
