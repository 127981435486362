import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { x } from "@xstyled/styled-components";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { CONTENT_TYPE, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { contentService } from "services/content";
import { CONTENT_TYPE_PERMISSION } from "constants/AlphaNow";
import {
  errorLoadingTranscript,
  transcriptUnavailableTryAgainContent,
  upcomingTranscript,
  transcriptAvailableSoon,
} from "content/AlphaNow";
import { ERROR } from "content/AlphaNow/constants";
import {
  AlphaNowPurchasableHeader,
  AlphaNowBanner,
  AlphaNowReaderPlaceholder,
  RequestExpertOverlay,
} from "pages/AlphaNowPage/components";
import { useUserBadgeContext } from "providers/BadgeProvider";

import ContentView from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import TranscriptActions from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActions";
import TranscriptDetails from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptDetails/TranscriptDetails";
import TranscriptActionBar from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActionBar/TranscriptActionBar";
import TranscriptActionBarMobile from "./TranscriptActionBar/TranscriptActionBarMobile";
import { View } from "models/Header";
import { parseISO, differenceInMilliseconds } from "date-fns";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { getKeywordQuery } from "../../utils";
import { logTimeSpent } from "../../utils/logHit";
import useModal from "hooks/useModal";
import useDimensions from "hooks/useDimensions";
import useTranscriptAudio from "hooks/useTranscriptAudio";
import useExpertRequest from "pages/AlphaNowPage/hooks/useExpertRequest";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { Badge } from "models/Badge";
import useContentApi from "components/BookmarkButton/useContentApi";
import useContentClientSettings from "hooks/useContentClientSettings";
import { requestExpertContent, editRequestContent } from "content/AlphaNow";

import "./index.css";

export const DataTestIds = {
  transcript: "alpha-now-transcript",
};

const AlphaNowTranscript = ({
  content = {},
  alphaNowSearchQuery = [],
  setAccessSuspended = () => {},
  onBookmarkChanged,
  onPurchasedContentChanged,
  price,
  contentError,
  onContentErrorChanged,
  questionsData = [],
  questionsFetched,
  onReturnToMobileSearch = () => {},
}) => {
  const {
    shape: { border },
    color: { border: borderColor },
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();

  // TODO [CON-2281][CON-2282][CON-2283] add tests once buttons are added back
  const { isMobile } = useCheckScreen();
  const { isVisible, onOpen: onOpenModal, onClose: onCloseModal } = useModal();
  const {
    query: { fragmentIds },
  } = useAppSearchContext();

  const [expertRequest, setExpertRequest] = useState();

  const {
    contentType,
    companies,
    speakers,
    published,
    scheduledTimeUTC,
    purchaseStatus,
    approvalStatus,
    downloadFileName,
  } = content;
  const contentId = content?.id;
  const transcriptId = content?.transcriptId;
  const isAccessible = isContentAccessible(purchaseStatus, approvalStatus);
  const showTranscript = isAccessible && published;

  const showActionBar = !!transcriptId;
  const showDesktopActionBar = !isMobile && showTranscript && showActionBar;
  const showMobileActionBar = isMobile && showActionBar;

  const origin = HitOrigin.alphaNow;

  const {
    handleSubmitRequest,
    handleCancelRequest,
    error,
    expertRequests,
    expertRequestExists,
    isRequestExpertLoading,
  } = useExpertRequest({
    onClose: onCloseModal,
    contents: [content],
    onExpertRequested: setExpertRequest,
    origin,
  });

  const existingExpertRequest = expertRequests[0];

  useEffect(() => {
    existingExpertRequest && setExpertRequest(existingExpertRequest);
  }, [existingExpertRequest]);

  const { contentAudio, contentVtt, isAudioAvailable, audioDownloadStatus } = useTranscriptAudio({
    contentId,
    enableAudioFetch: showTranscript,
  });
  const [transcriptRef, { height: alphaNowTranscriptHeight }] = useDimensions();

  const [searchQuery, setSearchQuery] = useState([]);
  const [viewMode, setViewMode] = useState(View.Transcript);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [selectedComprehensiveSummaryIndex, setSelectedComprehensiveSummaryIndex] = useState(0);
  const [totalKeywordMatches, setTotalKeywordMatches] = useState(0);
  const [detailsCollapsed, setDetailsCollapsed] = useState(showTranscript);
  const [isTranscriptDataReady, setIsTranscriptDataReady] = useState(false);
  const [isUpcomingTransitionActive, setIsUpcomingTransitionActive] = useState(false);
  const [audioToggle, setAudioToggle] = useState();

  const searchQueryRef = useRef(searchQuery);

  const showTranscriptPlaceholder = (isAccessible && !published) || isUpcomingTransitionActive;

  const isContentPreScheduledTime = new Date() < new Date(scheduledTimeUTC);

  const isQuestionViewEnabled = questionsData.length > 0;

  const alphaNowPurchasableHeaderHeight = 92;
  const alphaNowTranscriptPaddingTop = 24;
  const transcriptDetailsInnerHeight =
    alphaNowTranscriptHeight - alphaNowPurchasableHeaderHeight - alphaNowTranscriptPaddingTop;

  const keywordSearchProps = {
    selectedMentionIndex,
    setSelectedMentionIndex,
    selectedComprehensiveSummaryIndex,
    setSelectedComprehensiveSummaryIndex,
    totalKeywordMatches,
    setTotalKeywordMatches,
  };

  const transcriptActionBarProps = {
    showTranscript,
    isTranscriptDataReady,
    hasKeywords: searchQuery.length > 0,
    viewMode,
    searchQuery,
    setSearchQuery,
    detailsCollapsed,
    setDetailsCollapsed,
    contentTypePermission: CONTENT_TYPE_PERMISSION[contentType],
    keywordSearchProps,
  };

  const onAudioSelect = () => {
    setAudioToggle(!audioToggle);
    setViewMode(View.Audio);
    if (searchQuery.length > 0) {
      setSearchQuery([]);
    }
  };

  useEffect(() => {
    logHit({
      origin,
      action: HitAction.alphaNowOpenTranscript,
      details: {
        contentId,
        ...(fragmentIds && { fragmentIds }),
      },
    });

    const startTime = Date.now();
    const action = HitAction.alphaNowTimeSpentOnTranscript;
    return () => logTimeSpent(startTime, { contentId: content?.id, userId: null }, logHit, action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewInTranscript = ({ dataset }) => {
    setSelectedMentionIndex(+dataset.mentionIndex);
    setViewMode(View.Transcript);
  };

  useEffect(() => {
    if (isContentPreScheduledTime) {
      const differenceInMilliSecs = differenceInMilliseconds(parseISO(scheduledTimeUTC), new Date());
      const fiveMinsInMilliSecs = 300_000;

      if (differenceInMilliSecs >= fiveMinsInMilliSecs) {
        setTimeout(() => setIsUpcomingTransitionActive(true), differenceInMilliSecs - fiveMinsInMilliSecs);
        setTimeout(() => setIsUpcomingTransitionActive(false), differenceInMilliSecs);
      } else {
        setTimeout(() => setIsUpcomingTransitionActive(false), differenceInMilliSecs);
        setIsUpcomingTransitionActive(true);
      }
    }
  }, [contentId, isContentPreScheduledTime, scheduledTimeUTC]);

  useEffect(() => {
    if (content?.companies) {
      // In the alpha now search query, check whether we have keywords
      // or companies that are NOT the anchor company of the search result
      // If we do, show them in the transcript search bar and open the mentions view

      const keywords = getKeywordQuery(alphaNowSearchQuery, companies);

      if (keywords.length > 0) {
        setSearchQuery(keywords);
        !isMobile && setViewMode(View.Mentions);
      }
    }
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const prevSearchQuery = searchQueryRef.current;
    if (prevSearchQuery.length === 0 && searchQuery.length > 0 && viewMode === View.Audio) {
      setViewMode(View.Transcript);
      prevSearchQuery.current = searchQuery;
    }
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: comprehensiveSummaryViewData, isSuccess: comprehensiveSummaryFetched } = useQuery(
    ["ComprehensiveSummaryViewRequest", contentId],
    () => contentService.fetchComprehensiveSummary(contentId),
    {
      enabled: !!contentId && showTranscript,
    }
  );

  const summaryContent = comprehensiveSummaryViewData?.summary;
  const comprehensiveSummaryProps = {
    comprehensiveSummaryFetched: comprehensiveSummaryFetched,
    comprehensiveSummaryViewEnabled: summaryContent?.length > 0,
    summary: summaryContent,
  };

  const handleOnPurchaseSuccess = (params) => {
    onPurchasedContentChanged(params);
  };

  const openRequestExpertOverlay = () => {
    onOpenModal();
    logHit({
      origin,
      action: HitAction.alphaNowRequestAnExpertAttempt,
      details: { contentId },
    });
  };

  const { hasUserBadge } = useUserBadgeContext();
  const { clientSettings } = useContentClientSettings();
  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, contentType, onBookmarkChanged);

  const isAlphaView = contentType === CONTENT_TYPE.alphaview;
  const isPrivateLibrary = contentType === CONTENT_TYPE.privateEnterprise;
  const hasPrivateTranscriptDownload = hasUserBadge(Badge.privateTranscriptDownload) && isPrivateLibrary;
  const isDownloadButtonAccessible = isAccessible && !!clientSettings?.downloadResearch;
  const hasDownloadButton =
    (isAlphaView || (isPrivateLibrary && hasPrivateTranscriptDownload)) && isDownloadButtonAccessible;

  const isRequestExpertButtonDisabled = !clientSettings?.requestExperts;
  const requestExpertButtonText = isEmpty(existingExpertRequest) ? requestExpertContent : editRequestContent;

  const transcriptActions = (
    <TranscriptActions
      content={content}
      downloadButtonProps={{ downloadFileName, hasDownloadButton }}
      bookmarkButtonProps={{ isBookmarked, onToggleBookmark }}
      audioButtonProps={{ audioDownloadStatus, onAudioSelect }}
      requestExpertProps={{ openRequestExpertOverlay, isRequestExpertButtonDisabled, requestExpertButtonText }}
      detailsCollapsed={detailsCollapsed}
    />
  );

  const alphaNowBanner = contentError ? (
    <AlphaNowBanner
      contentType={contentType}
      useCase={contentError}
      onClose={() => onContentErrorChanged("")}
      //TODO [CON-3255]: Remove temporary fix for banner placement with toasts
      styles={{ bottom: isQuestionViewEnabled ? "14%" : "8%", zIndex: 1000 }}
    />
  ) : null;

  return (
    <x.div
      data-testid={DataTestIds.transcript}
      position="relative"
      display="flex"
      flexDirection="column"
      h="100%"
      overflowY={!showTranscript ? "hidden" : detailsCollapsed ? "hidden" : "auto"}
      ref={transcriptRef}
    >
      <x.div
        borderBottomWidth={isMobile && !showTranscript ? 0 : border.width.small}
        borderColor={borderColor.neutral.default}
      >
        {showMobileActionBar && (
          <TranscriptActionBarMobile
            {...transcriptActionBarProps}
            onReturnToMobileSearch={onReturnToMobileSearch}
            onBookmarkChanged={onBookmarkChanged}
            audioDownloadStatus={audioDownloadStatus}
            isAudioAvailable={isAudioAvailable}
            onAudioSelect={onAudioSelect}
            openRequestExpertOverlay={openRequestExpertOverlay}
            isRequestExpertLoading={isRequestExpertLoading}
            expertRequestExists={expertRequestExists}
            contentId={contentId}
            downloadFileName={downloadFileName}
            isAccessible={isAccessible}
            contentType={contentType}
          />
        )}
        {isMobile && alphaNowBanner}
        <TranscriptDetails
          content={content}
          detailsCollapsed={detailsCollapsed}
          transcriptActions={transcriptActions}
          searchQuery={alphaNowSearchQuery}
          isAccessible={isAccessible}
          questionsData={questionsData}
          showTranscript={showTranscript}
          transcriptDetailsInnerHeight={transcriptDetailsInnerHeight}
        />
        {showDesktopActionBar && (
          <TranscriptActionBar
            {...transcriptActionBarProps}
            setViewMode={setViewMode}
            transcriptActions={transcriptActions}
            isQuestionViewEnabled={isQuestionViewEnabled}
            comprehensiveSummaryViewEnabled={comprehensiveSummaryProps.comprehensiveSummaryViewEnabled}
          />
        )}
        {!showTranscript && (
          <>
            <Divider />
            <AlphaNowPurchasableHeader
              content={content}
              contentTitle={content.externalTitle}
              price={price}
              onError={onContentErrorChanged}
              onPurchaseSuccess={handleOnPurchaseSuccess}
              scheduledTimeUTC={scheduledTimeUTC}
              isContentPreScheduledTime={isContentPreScheduledTime}
              isUpcomingTransitionActive={isUpcomingTransitionActive}
              contentApprovalStatus={approvalStatus}
              contentPurchaseStatus={purchaseStatus}
            />
            {!showTranscriptPlaceholder && !isMobile && (
              <>
                <x.img
                  h="100%"
                  w="100%"
                  objectFit="fill"
                  maxWidth="100%"
                  src={require("pages/AlphaNowPage/images/blurred-transcript.png")}
                />
                {alphaNowBanner}
              </>
            )}
          </>
        )}
      </x.div>
      {/* TODO: trigger collapse on scroll. Set overflow=visible to scroll the whole
       * AlphaNowTranscript when details are expanded and trigger collapse on scroll */}
      {showTranscript && (
        <x.div display="flex" flexDirection="column" flex="1" overflow="hidden">
          {transcriptId ? (
            <>
              <ContentView
                content={content}
                isTranscriptDataReady={isTranscriptDataReady}
                setIsTranscriptDataReady={setIsTranscriptDataReady}
                setDetailsCollapsed={setDetailsCollapsed}
                searchQuery={searchQuery}
                viewMode={viewMode}
                viewInTranscript={viewInTranscript}
                contentError={contentError}
                setContentError={onContentErrorChanged}
                contentAudio={contentAudio}
                contentVtt={contentVtt}
                setViewMode={setViewMode}
                audioToggle={audioToggle}
                questionsData={questionsData}
                questionsFetched={questionsFetched}
                setAccessSuspended={setAccessSuspended}
                comprehensiveSummaryProps={comprehensiveSummaryProps}
                keywordSearchProps={keywordSearchProps}
              />
              {contentError && (
                <AlphaNowBanner
                  contentType={contentType}
                  useCase={ERROR.DownloadFailed}
                  onClose={() => onContentErrorChanged("")}
                  styles={{ bottom: "8%" }}
                />
              )}
            </>
          ) : (
            <ErrorMessage header={errorLoadingTranscript} body={transcriptUnavailableTryAgainContent} />
          )}
        </x.div>
      )}
      {showTranscriptPlaceholder && (
        <x.div h="100%" position="relative" top="20%">
          <AlphaNowReaderPlaceholder title={upcomingTranscript} subtitle={transcriptAvailableSoon} />
        </x.div>
      )}
      {isVisible && (
        <RequestExpertOverlay
          onSubmit={handleSubmitRequest}
          onClose={onCloseModal}
          onCancel={handleCancelRequest}
          expertRequests={[expertRequest || existingExpertRequest]}
          speakers={speakers}
          error={error}
        />
      )}
    </x.div>
  );
};

AlphaNowTranscript.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    transcriptId: PropTypes.number,
  }),
  alphaNowSearchQuery: PropTypes.arrayOf(PropTypes.shape()),
  price: PropTypes.number,
  contentError: PropTypes.string.isRequired,
  onContentErrorChanged: PropTypes.func.isRequired,
};

export default AlphaNowTranscript;
