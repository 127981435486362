import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { x } from "@xstyled/styled-components";
import React, { useState } from "react";
import { Typography } from "@alphasights/alphadesign-components";
import { Attachment, PlayCircle } from "@alphasights/alphadesign-icons";
import { useMobileComplianceStyles } from "./MobileComplianceResourcesPage.styles";

export const MobileComplianceResourcesPage = () => {
  const { complianceResources: resources } = useCurrentProjectContext();
  return (
    <x.div data-testid="mobile-compliance-resources-page">
      <PortalMobileTopBar title={"AlphaSights Compliance"} />
      <x.div data-testid="compliance-list-wrapper" margin={"16px"}>
        {resources.map((resource) => (resource.type === "video" ? <Video {...resource} /> : <File {...resource} />))}
      </x.div>
    </x.div>
  );
};

const Video = ({ videoId, title, thumbUrl, description }) => {
  const [playing, setPlaying] = useState(false);

  const { video, icon, wrapper } = useMobileComplianceStyles();

  return (
    <x.div {...wrapper}>
      <x.div position={"relative"} borderRadius={"8px"}>
        {playing && <x.div {...video.theatre} zIndex={5} className={`wistia_embed wistia_async_${videoId}`} />}
        <x.div {...video.theatre} zIndex={1}>
          <PlayCircle {...icon} onClick={() => setPlaying(true)} />
        </x.div>
        <x.img alt={title} src={thumbUrl} {...video.thumb} />
      </x.div>
      <Typography variant="body">{description}</Typography>
    </x.div>
  );
};

const File = ({ title, description, file }) => {
  const { icon, wrapper, video } = useMobileComplianceStyles();
  return (
    <x.div {...wrapper}>
      <x.div {...video.theatre} position={"relative"}>
        <a href={file}>
          <Attachment alt={title} {...icon} />
        </a>
      </x.div>
      <Typography variant="body">{description}</Typography>
    </x.div>
  );
};
