import React, { useEffect, useState, useMemo, useCallback } from "react";
import { CalendarFull } from "../../views/ProjectCalendarView/CalendarFull";
import { eventHandler as eventHandlerImported } from "./eventHandler";
import {
  mapAdvisorAvailability,
  mapScheduledCalls,
  mapPendingSchedule,
  mapClientAvailability,
  mapMissingEventFromPreselect,
  selectedInteractionAngleIds,
  createLabel,
} from "../ProjectsCalendar/eventSupport";
import { isPast, max, addMinutes, differenceInSeconds } from "date-fns";
import { useTimezone } from "../../providers/TimezoneProvider";

// to ease transition between calendars
const enrichWithProxy = (ev) => {
  const handler = {
    get(target, prop) {
      if (prop in target) return target[prop];
      return (args) => ev.emit(prop, args);
    },
  };
  const deps = new Proxy({}, handler);
  return { ...ev, deps };
};

const wrapHandler = (handler, { keepPendingChanges, removeAvailability }) => {
  return (type, args) => {
    if (type === "saveClientAvailability") {
      removeAvailability(args);
    }
    if (type === "pendingChangesUpdated") return keepPendingChanges(args);
    return handler(type, args);
  };
};

export const ProjectsCalendarNew = ({
  project,
  eventHandler: handlerInput,
  preselect: preselectInput,
  isFlyout = false,
  show = {
    clientAvailability: true,
    expertAvailability: true,
    interaction: true,
    pending: true,
    ghost: true,
  },
  reschedulingId,
  availabilities,
  setAvailabilites,
  setPreselected,
  removedAvailabilities,
  setRemovedAvailabilities,
  isProvideAvailability,
  ...props
}) => {
  const [events, setEvents] = useState([]);
  const [editing, setEditing] = useState(null);
  const [preselect, setPreselect] = useState(preselectInput);

  const tz = useTimezone();
  useEffect(() => {
    setPreselect(preselectInput);
    if (!preselectInput) setEditing(null);
  }, [preselectInput]);

  const applyAvailabilityChanges = useCallback(
    ({ eventId, subtype, start, end, label }) => {
      const availability = availabilities.find((a) => a.id === eventId);
      if (!availability.edited) {
        setRemovedAvailabilities(removedAvailabilities.concat({ ...availability }));
      }

      availability.advisorshipIds = project.selectedInteractionIds;
      availability.angleIds = selectedInteractionAngleIds(project);
      availability.subtype = subtype ?? availability.subtype;
      availability.start = start ?? availability.start;
      availability.end = end ?? availability.end;
      availability.label = label ?? availability.label;
      availability.edited = true;
    },
    [availabilities, project, removedAvailabilities, setRemovedAvailabilities]
  );

  const eventHandler = useMemo(
    () =>
      wrapHandler(handlerInput, {
        keepPendingChanges: (vals) => {
          applyAvailabilityChanges({
            eventId: preselect.id,
            subtype: vals.selectedType,
            label: vals.label,
          });
          setEditing((v) => ({ ...(v || {}), ...vals }));
        },
        removeAvailability: () => {
          const availability = availabilities.find((a) => a.id === preselect.id);

          setAvailabilites(availabilities.filter((a) => a.id !== availability.id));

          if (!availability.edited) {
            setRemovedAvailabilities(removedAvailabilities.concat({ ...availability }));
          }
        },
      }),
    [
      handlerInput,
      setRemovedAvailabilities,
      preselect,
      availabilities,
      setAvailabilites,
      removedAvailabilities,
      applyAvailabilityChanges,
    ]
  );

  const [savedValidAvailabilities] = useState(
    mapClientAvailability({
      projects: [project],
      preselect,
      eventHandler,
    })
  );

  useEffect(() => {
    setAvailabilites(savedValidAvailabilities);
  }, [savedValidAvailabilities, setAvailabilites]);

  const eventsFromInput = useMemo(() => {
    return [
      ...mapScheduledCalls({ projects: [project], preselect, eventHandler }),
      ...mapPendingSchedule({ projects: [project], preselect, eventHandler }),
      ...mapAdvisorAvailability({
        projects: [{ ...project, clientAvailability: [] }],
        preselect,
        eventHandler,
        reschedulingId,
        isProvideAvailability,
      }),
    ]
      .map(enrichWithProxy)
      .flatMap((ev) =>
        tz.breakPeriodByDay(ev).map((a) => ({
          ...ev,
          start: a.start,
          end: a.end,
        }))
      );
  }, [preselect, eventHandler, reschedulingId, tz, project, isProvideAvailability]);

  useEffect(() => {
    let events = eventsFromInput;
    if (preselect) {
      const preselectedEvent = eventsFromInput.find((ev) => ev.preselected);
      const selectedEvent =
        preselectedEvent ||
        mapMissingEventFromPreselect({
          preselect,
          editing,
          eventHandler,
          project,
        });
      const defaultValues = !preselectedEvent && {
        advisorshipIds: project.selectedInteractionIds,
        angleIds: selectedInteractionAngleIds(project),
      };

      const extraData = editing || defaultValues || {};
      const extraEvent = { ...selectedEvent, ...extraData };
      events = [...events.filter((e) => e !== preselectedEvent), extraEvent];
    }

    setEvents(
      events.map((e) => ({
        ...e,
        isFlyout,
        saveInBatch: true,
        isProvideAvailability,
      }))
    );
  }, [preselect, eventHandler, editing, eventsFromInput, project, isFlyout, isProvideAvailability]);

  const minSlotMinutes = 15;

  const onResizeOrDrop = ({ event, start: startInput, end: endInput }) => {
    if (isPast(startInput)) return false;
    const start = tz.zonedTimeToUtc(startInput);
    const end = tz.zonedTimeToUtc(max([endInput, addMinutes(startInput, minSlotMinutes)]));
    setEditing((v) => ({
      ...(v || {}),
      event,
      start,
      end,
    }));

    applyAvailabilityChanges({ eventId: event.id, start, end });
  };

  const preSelect = (event) => {
    setPreselected({
      id: event.id,
      type: "clientAvailability",
      start: event.start,
      duration: differenceInSeconds(event.end, event.start),
      label: event.label,
      selectedType: event.subtype,
    });
  };

  const onSelectSlot = ({ start, end, action }) => {
    const effectiveEnd = action === "click" ? addMinutes(start, 60) : end;
    const eventStart = tz.zonedTimeToUtc(start);
    const eventEnd = tz.zonedTimeToUtc(max([effectiveEnd, addMinutes(start, minSlotMinutes)]));

    if (isPast(eventStart)) {
      return;
    }
    createAvailability({ eventStart, eventEnd });
  };

  const createAvailability = ({ eventStart, eventEnd }) => {
    const newAvailability = {
      start: eventStart,
      end: eventEnd,
      source: "clientAvailability",
      edited: true,
      subtype: project.anglesTaggedToClient?.length > 0 ? "angle" : "project",
      advisorshipIds: [],
      angles: project.angles,
      angleIds: project.anglesTaggedToClient?.length > 0 ? selectedInteractionAngleIds(project) : [],
      emit: () => {},
      id: availabilities.length,
    };

    setAvailabilites(
      availabilities
        .map((a, ix) => {
          return {
            ...a,
            id: a.edited ? ix : a.id,
          };
        })
        .concat({
          ...newAvailability,
          label: createLabel(newAvailability),
        })
    );
  };

  const selectTimeslots = (event) => {
    if (event == null) {
      setPreselected(null);
      return;
    }

    if (availabilities.find((av) => av.id === event.id)) {
      preSelect(event);
    } else {
      createAvailability({ eventStart: event.start, eventEnd: event.end });
    }
  };

  return (
    <>
      <CalendarFull
        events={[
          ...events.filter((e) => show[e?.source] && !removedAvailabilities.some((a) => a.id === e.id)),
          ...availabilities.filter((av) => av.id !== preselect?.id),
        ]}
        selectable={"ignoreEvents"}
        onSelectEvent={(event) => selectTimeslots(event)}
        onSelectSlot={onSelectSlot}
        onSelecting={({ start }) => !isPast(start)}
        onEventDrop={onResizeOrDrop}
        onEventResize={onResizeOrDrop}
        draggableAccessor={({ source }) => source === "clientAvailability"}
        showClientAvailability={true}
        onDrillDown={() => null}
        toolbarShowViewAvailability={false}
        newFlyout={true}
        className={isProvideAvailability ? "provide-availability" : ""}
        {...props}
      />
    </>
  );
};

export const eventHandler = eventHandlerImported;
