import React, { useCallback, useMemo, useState } from "react";
import { Button, Icon, Loading, Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { GenerativeAi } from "@alphasights/alphadesign-icons";
import { CardSkeleton, PROJECT_SUMMARY_ID } from "../Cards";
import { x } from "@xstyled/styled-components";
import { ProjectSummaryStatus, ProjectSummaryStatusResponse } from "./ProjectSummaries.types";
import { formatRelative, parseISO } from "date-fns";
import { noop } from "lodash";
import { useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import { useEnv } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { HitAction } from "@alphasights/portal-api-client";
import { CustomHoverContentCard } from "components/CustomHoverContentCard";

enum ProjectSummaryCardStatus {
  NotRequested,
  Loading,
  Generated,
}

export const ProjectSummaryCard = ({
  onSelect,
  currentSelection,
}: {
  onSelect: (selection: { id: string }) => void;
  currentSelection: { id: string };
}) => {
  const { isMobile } = useCheckScreen();
  const styles = useProjectSummaryCardStyles();
  const [isRequestingSummaries, setIsRequestingSummaries] = useState(false);
  const {
    uniqSummariesStatusByAngle,
    isSummaryStatusLoading,
    generateProjectSummaries,
    onSelectSummary,
    onDeleteCurrentSummary,
    projectSummaryLogHit,
  } = useProjectSummariesContext();

  const onClickGenerate: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setIsRequestingSummaries(true);
      generateProjectSummaries().finally(() => setIsRequestingSummaries(false));
      projectSummaryLogHit({ action: HitAction.projectSummaryGenerate });
      e.stopPropagation();
    },
    [generateProjectSummaries, projectSummaryLogHit]
  );

  const cardStatus = useMemo(() => {
    if (isRequestingSummaries) return ProjectSummaryCardStatus.Loading;
    if (uniqSummariesStatusByAngle.length === 0) return ProjectSummaryCardStatus.NotRequested;
    if (
      uniqSummariesStatusByAngle.every(
        (summary) => summary.status !== ProjectSummaryStatus.Complete && summary.status !== ProjectSummaryStatus.Error
      )
    )
      return ProjectSummaryCardStatus.Loading;
    return ProjectSummaryCardStatus.Generated;
  }, [uniqSummariesStatusByAngle, isRequestingSummaries]);

  const cardConfig = useMemo(() => {
    switch (cardStatus) {
      case ProjectSummaryCardStatus.NotRequested:
        return {
          rightDecorator: (
            <Button
              variant="secondary"
              size="small"
              data-testid="project-summary-generate-btn"
              onClick={onClickGenerate}
            >
              Generate
            </Button>
          ),
          subtitle: "Create a free project summary. You only have to do this once per project.",
          onSelectCard: noop,
        };
      case ProjectSummaryCardStatus.Loading:
        return {
          rightDecorator: <Loading data-testid="project-summary-card-loading" size="sm" />,
          subtitle: "Generating your project summary... \nThis can take up to 30 minutes.",
          onSelectCard: noop,
        };
      case ProjectSummaryCardStatus.Generated:
        return {
          rightDecorator: (
            <Pill variant="blue" size="small" isInteractive={false} data-testid="project-summary-card-beta-pill">
              Beta
            </Pill>
          ),
          subtitle: `Automatically updated ${getLastUpdatedAt(uniqSummariesStatusByAngle)}`,
          onSelectCard: () => {
            onSelect({ id: PROJECT_SUMMARY_ID });
            onSelectSummary(uniqSummariesStatusByAngle.find((el) => !el.angleId)?.id!);
          },
        };
    }
  }, [cardStatus, uniqSummariesStatusByAngle, onClickGenerate, onSelect, onSelectSummary]);

  //@ts-ignore
  const internalUser = useEnv().internalUser;

  if (isMobile && uniqSummariesStatusByAngle.find((s) => !s.angleId)?.status !== ProjectSummaryStatus.Complete)
    return null;

  return (
    <x.div w="100%">
      {isSummaryStatusLoading ? (
        <CardSkeleton width="100%" height="105px" />
      ) : (
        <CustomHoverContentCard
          selected={currentSelection?.id === PROJECT_SUMMARY_ID}
          onClick={cardConfig.onSelectCard}
          data-testid="deliverables-project-summary-card"
          isMobile={isMobile}
        >
          <x.div {...styles.cardContentWrapper}>
            <x.div {...styles.iconWrapper}>
              <Icon
                size="large"
                color="decorativeBase"
                children={<GenerativeAi />}
                onClick={
                  internalUser &&
                  ((e) => {
                    e.stopPropagation();
                    onSelect({ id: "" });
                    onDeleteCurrentSummary();
                  })
                }
              />
            </x.div>
            <x.div {...styles.titleSubtitleWrapper}>
              <Typography variant="body-em">Project Transcript Summary</Typography>
              <x.div {...styles.subtitle} whiteSpace="pre-wrap">
                <Typography variant="body-small" color="secondary">
                  {cardConfig?.subtitle}
                </Typography>
                {cardConfig?.rightDecorator}
              </x.div>
            </x.div>
          </x.div>
        </CustomHoverContentCard>
      )}
    </x.div>
  );
};

const getLastUpdatedAt = (summariesStatus: ProjectSummaryStatusResponse[]) => {
  return formatRelative(
    summariesStatus
      .filter((el) => !!el?.lastUpdatedAt)
      .map((el) => parseISO(el.lastUpdatedAt!))
      .sort()
      .reverse()[0],
    new Date()
  );
};

const useProjectSummaryCardStyles = () => {
  const { color, spacing, shape } = useThemeTokens();

  return {
    cardContentWrapper: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
    },
    titleSubtitleWrapper: {
      display: "flex",
      gap: spacing.inner.base,
      flexDirection: "column",
      flexGrow: "1",
    },
    iconWrapper: {
      w: spacing.layout.base08,
      h: spacing.layout.base08,
      backgroundColor: color.background.decorative.base,
      borderRadius: shape.border.radius.medium,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: "0",
    },
    subtitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: spacing.inner.base02,
    },
  };
};
