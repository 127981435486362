import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { shape, spacing, color } = tokens;

export const Form = styled.form`
  transition: width 0.3s ease-in-out;
  width: 200px;
  &:has(input:placeholder-shown:not(:focus)) {
    width: 40px;
    [data-testid="text-input-container"] {
      cursor: pointer !important;
    }
  }
  [data-testid="text-input-container"] {
    border-radius: ${shape.border.radius.xlarge};
  }
  [data-testid="clear-search"] {
    display: none;
  }
  &:has(input:not(:placeholder-shown)) {
    &:hover,
    &:has(input:focus) {
      [data-testid="clear-search"] {
        display: block;
      }
    }
  }
  input:placeholder-shown:not(:focus) {
    padding: 0;
    width: 0;
  }
  input:autofill {
    box-shadow: 0 0 0 ${spacing.inner.base08} ${color.background.surface.page.default} inset;
  }
  div:has(> input:-internal-autofill-selected) {
    background-color: transparent;
  }
}
`;
