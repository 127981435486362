import React from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Close } from "@alphasights/alphadesign-icons";
import { Typography, Drawer, Modal, IconOnlyButton, Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { useResponsiveConfirmationModalStyles, useResponsiveModalStyles } from "./ResponsiveModal.styles";
import styled, { x } from "@xstyled/styled-components";
import classnames from "classnames";

export const ResponsiveADSModal = styled(Modal)(({ isFullscreen }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const fullScreenStyles = `
    footer {
      flex-direction: column;
      align-items: stretch;
    }
    & > div {
      width: 100dvw;
      height: 100dvh;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }
    [data-testid="modal-slot"] {
      min-width: auto;
      max-width: none;
      min-height: auto;
      max-height: none;
      flex-shrink: 1;
    }
  `;

  return `
    header {
      padding-bottom: ${inner.base04};
    }
    [data-testid="modal-slot"] {
      padding: ${inner.base06};
    }
    footer {
      gap: ${inner.base04};
    }
    ${isFullscreen && fullScreenStyles}
  `;
});

export const ResponsiveModal = ({
  open,
  onClose,
  showCloseButton = true,
  centerTitle = false,
  title,
  children,
  variant = "fullscreen",
  testId,
  tertiaryButton = null,
  secondaryButton,
  primaryButton,
  ...props
}) => {
  const { isMobile } = useCheckScreen();

  const { drawerHeader, separator, mobileTitle, drawer, closeButton, drawerButtons } = useResponsiveModalStyles({
    centerTitle,
  });
  const isFullscreen = variant === "fullscreen" && isMobile;

  return (
    open && (
      <>
        {!isMobile || variant === "fullscreen" ? (
          <ResponsiveADSModal
            isFullscreen={isFullscreen}
            open={open}
            onClose={onClose}
            showCloseButton={!!(showCloseButton && onClose)}
            title={title}
            centerTitle={centerTitle}
            zIndex={30}
            data-testid={testId}
            variant="complex"
            slotHeight="unset"
            slotWidth="unset"
            shouldShowFooter={tertiaryButton || secondaryButton || primaryButton}
            tertiaryButton={tertiaryButton}
            secondaryButton={secondaryButton}
            primaryButton={primaryButton}
            {...props}
            className={classnames(props.className, "responsive-modal")}
          >
            {children}
          </ResponsiveADSModal>
        ) : (
          variant === "drawer" && (
            <Drawer className="responsive-modal" open={open} onClose={onClose} zIndex={5} {...drawer} {...props}>
              <x.div {...drawerHeader}>
                <x.div {...mobileTitle}>
                  <Typography variant="body-large-em">{title}</Typography>
                </x.div>
                {showCloseButton && onClose && (
                  <IconOnlyButton variant="ghost" onClick={onClose} {...closeButton}>
                    <Close />
                  </IconOnlyButton>
                )}
              </x.div>
              <x.div {...separator} />
              {children}
              {(tertiaryButton || secondaryButton || primaryButton) && (
                <>
                  <x.div {...drawerButtons}>
                    <x.div {...separator} />
                    {primaryButton}
                    {secondaryButton}
                    {tertiaryButton}
                  </x.div>
                </>
              )}
            </Drawer>
          )
        )}
      </>
    )
  );
};

export const ConfirmationResponsiveModal = ({
  title = "Please Confirm",
  message,
  confirmText,
  cancelText,
  open,
  onClose,
  onClick,
  isLoading,
  testId,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const { bodyContainer, buttonsContainer } = useResponsiveConfirmationModalStyles({ isMobile });

  return (
    <ResponsiveModal title={title} testId={testId} open={open} onClose={onClose} zIndex={160} padding={inner.base06}>
      <Typography {...bodyContainer}>{message}</Typography>
      <x.div {...buttonsContainer}>
        <Button
          variant="secondary"
          onClick={() => {
            onClick();
            onClose();
          }}
          loading={isLoading}
          data-testid="modal-confirm-button"
          size="small"
        >
          {confirmText}
        </Button>
        <Button variant="ghost" onClick={onClose} data-testid="modal-cancel-button" size="small">
          {cancelText}
        </Button>
      </x.div>
    </ResponsiveModal>
  );
};
