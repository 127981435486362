import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@alphasights/alphadesign-components";

const CardSubtitle = ({ subtitleString }) => {
  return (
    <div data-testid="card-subtitle-container">
      <Typography variant="body-small">{subtitleString}</Typography>
    </div>
  );
};

CardSubtitle.propTypes = {
  subtitleString: PropTypes.string.isRequired,
};

export default CardSubtitle;
