import React from "react";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import * as S from "./AdvisorsTableView.styled";

export const AdvisorsTableViewSkeleton = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div>
      <x.div maxW="1024px" marginLeft="auto" marginRight="auto" marginTop={inner.base08} marginBottom={inner.base14}>
        <Skeleton height={inner.base12} />
        <x.div display="flex">
          <x.div flexGrow={2}>
            <Skeleton />
          </x.div>
          <x.div flexGrow={1}>
            <Skeleton />
          </x.div>
        </x.div>
      </x.div>
      <AdvisorsTableSkeleton />
    </x.div>
  );
};

export const AdvisorsTableSkeleton = ({ ...props }) => (
  <x.div {...props}>
    <S.SkeletonWithMarginBottom count={6} />
  </x.div>
);
