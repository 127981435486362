import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export interface AlphaNowReaderPlaceholderProps {
  title: string;
  subtitle: string;
  mt?: string;
}

const AlphaNowReaderPlaceholder: FC<AlphaNowReaderPlaceholderProps> = ({ title, subtitle, ...props }) => {
  const {
    color: { text },
  } = useThemeTokens();

  return (
    <x.div mx="auto" textAlign="center" color={text.secondary} {...props}>
      <Typography variant="body-large-em">{title}</Typography>
      <Typography variant="body">{subtitle}</Typography>
    </x.div>
  );
};

export default AlphaNowReaderPlaceholder;
