import { create } from "zustand";

type storeState = {
  expertsMentionedSection: string;
  selectedMentionedExpertsIds: number[];
  setExpertsMentionedSectionAndExpertsIds: (expertsMentionedSection: string, ids: number[]) => void;
  resetExpertsMentioned: () => void;
};

export const useMentionedExpertsStore = create<storeState>((set) => ({
  expertsMentionedSection: "",
  selectedMentionedExpertsIds: [],
  setExpertsMentionedSectionAndExpertsIds: (expertsMentionedSection: string, ids: number[]) =>
    set({ expertsMentionedSection, selectedMentionedExpertsIds: ids }),
  resetExpertsMentioned: () => set({ expertsMentionedSection: "", selectedMentionedExpertsIds: [] }),
}));
