import { Icon } from "@alphasights/alphadesign-components";
import { useAccessControl } from "@alphasights/client-portal-shared";
import React, { useState, useCallback } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import * as S from "./SidebarNav.styled";

type SidebarOptionProps = {
  id: string;
  to: string;
  permission: string;
  label: string;
  icon: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

export const SidebarOption = ({ id, to, permission, label, icon, onClick }: SidebarOptionProps) => {
  const hasPermission = useAccessControl([permission]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) {
        onClick();
      }

      if (!hasPermission) {
        e.preventDefault();
        return;
      }

      if (isActive && to) {
        history.push(to);
      }
    },
    [isActive, hasPermission, to, history, onClick]
  );

  const checkActiveState = (match: any, id: string) => {
    return (
      !!match ||
      (id === "projects" && (location.pathname.includes("/experts/") || location.pathname.includes("/calendar-view")))
    );
  };

  return (
    <S.NavOptionWrapper data-testid={`option-${id}`}>
      <NavLink
        to={to}
        isActive={(match) => {
          const active = checkActiveState(match, id);
          setIsActive(active);
          return active;
        }}
        onClick={handleClick}
        activeClassName="active"
        style={{ display: "flex", flexDirection: "column", alignItems: "center", textDecoration: "none" }}
      >
        <S.NavIconWrapper isSelected={isActive} disabled={!hasPermission}>
          <Icon size="large" color="inverse">
            {icon}
          </Icon>
        </S.NavIconWrapper>
        <S.LabelWrapper data-testid="option-label">{label}</S.LabelWrapper>
      </NavLink>
    </S.NavOptionWrapper>
  );
};
