import React from "react";
import { Toolbar } from "components/InteractionsPage/Toolbar";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ProjectLeadCard } from "./ProjectLeadCard";
import { PageFooter } from "@alphasights/client-portal-shared";
import { Lead } from "@alphasights/portal-api-client";
import { ProjectMoreOptionsProvider } from "providers/ProjectMoreOptionsProvider";

export const ProjectClosedPage = ({ project }: { project: Project }) => {
  const { isMobile } = useCheckScreen();
  const { wrapper, content, text } = useClosedPageStyles();
  const currentView = "table-view";

  return (
    <x.div {...wrapper}>
      <ProjectMoreOptionsProvider currentView={currentView}>
        <Toolbar
          token={project.token}
          currentView={currentView}
          isMobile={isMobile}
          lead={project.lead as Lead}
          projectTitle={project.title}
          isCalendarViewEnabled
          isMessagesViewEnabled={project.enablePortalMessages}
          isDeliverablesViewEnabled
          isActiveProject={project.active}
          workstreamId={undefined}
          isSurveyOnlyProject={false}
        />
      </ProjectMoreOptionsProvider>
      <x.div {...content}>
        <x.div>
          <Typography variant="h3">Project link unavailable</Typography>
          <Typography {...text}>
            Reach out to your project lead {project.lead.name} to launch a new project or restore access.
          </Typography>
          <ProjectLeadCard lead={project.lead as Lead} />
        </x.div>
      </x.div>
      <PageFooter />
    </x.div>
  );
};

const useClosedPageStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const content = {
    p: inner.base06,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  };

  const text = {
    mt: inner.base02,
    mb: inner.base06,
  };

  return {
    wrapper,
    content,
    text,
  };
};
