import difflib from "difflib";
import _ from "lodash";

const isDebugging = document.location.search.includes("debug-transcript");

export const debugTranscript = (...message: any[]) => {
  if (!isDebugging) return;

  console.info(...message);
};

const cleanSentence = (sentence: string) =>
  sentence
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, " ");

export const findLongestMatchingQuote = (sentence: string, match: string) => {
  const sentenceCleaned = cleanSentence(sentence);
  const matchCleaned = cleanSentence(match);

  const ix = sentenceCleaned.indexOf(matchCleaned);
  if (ix >= 0) {
    debugTranscript("Perfect match!");
    return {
      ix,
      length: matchCleaned.length,
    };
  } else {
    const match = (a: string, b: string, subjectIx: number) => {
      const blocks = _.initial(new difflib.SequenceMatcher(null, a, b).getMatchingBlocks()).filter(
        ([ix1, ix2, len]) => len > 10
      );
      debugTranscript(blocks);

      const top4 = _.sortBy(blocks, ([ix1, ix2, len]) => len)
        .reverse()
        .slice(0, 4);
      const matching = _.sumBy(top4, ([ix1, ix2, len]) => len);
      const start: number = _.min(_.map(top4, (array) => array[subjectIx]))!!;
      const end: number = _.max(_.map(top4, (array) => array[subjectIx] + array[2]))!!;

      return { top4, matching, start, end };
    };

    const matchAB = match(sentenceCleaned, matchCleaned, 0);
    const matchBA = match(matchCleaned, sentenceCleaned, 1);

    const best = matchAB.matching > matchBA.matching ? matchAB : matchBA;

    if (best.matching / matchCleaned.length > 0.5) {
      return {
        ix: best.start,
        length: best.end - best.start,
      };
    }
  }

  return null;
};
