import {
  Button,
  IconButton,
  Portal,
  MobileTopBar,
  Typography,
  useThemeTokens,
  CheckboxGroup,
  Checkbox,
  TextArea,
} from "@alphasights/alphadesign-components";
import { ArrowLeft, Request } from "@alphasights/alphadesign-icons";

import { x } from "@xstyled/styled-components";
import { MarkdownReplacements, parseInlineCitations } from "components/CitationContext/Citation";
import { CitationRendererProps, ToStringRenderer } from "components/CitationContext/PropertyRenderers";
import { reduce } from "lodash";
import { useHighlightHtmlContext } from "providers/HighlightHtmlProvider";
import React, { memo, useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled, { createGlobalStyle } from "styled-components";
import { findParentWithDataAttribute } from "utils/htmlElement";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import { PurchaseInDeliverables } from "views/DeliverablesView/PurchaseInDeliverables";
import { ContentSuggestion } from "views/DeliverablesView/types";
import useRequestForProject from "views/DeliverablesView/useRequestForProject";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import useStyles from "views/DeliverablesView/styles";
import { DataTestIds } from "pages/AlphaNowPage/components/AlphaNowContent/RequestExpertOverlay/RequestExpertOverlay";
import { DataTestIds as CancelButtonDataTestIds } from "pages/AlphaNowPage/components/CancelButton";

type handleSubmitProps = (selectedExperts: number[], reason: string) => void;

export const RequestExpertsButton = ({ speakers, variant = "outline" }: { speakers: Speaker[]; variant?: string }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [canRequestForProject, setCanRequestForProject] = useState(false);
  const { requestExpertsTextArea } = useStyles();

  const { submit, submitting, validSpeakers } = useRequestForProject({ speakers, setCanRequestForProject });
  const [selectedExperts, setSelectedExperts] = useState(speakers.length === 1 ? [speakers[0].id] : []);
  const [reason, setReason] = useState("");
  const [noneSelectedError, setNoneSelectedError] = useState("Please select at least one option");

  const {
    fullScreenFixedWrapper,
    stickyAtTheBottom,
    contentPurchaseCTA,
    stickyAtTheTop,
    flexColumn,
  } = useMobileDeliverablesStyles();

  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const handleSubmit: handleSubmitProps = async (selectedExperts, reason) => {
    await submit(selectedExperts, reason);
    setModalOpen(false);
  };

  const onClose = () => setModalOpen(false);

  const handleExpertSelect = (value: any) => {
    setNoneSelectedError("");
    setSelectedExperts(value);
  };

  return (
    <>
      <Button
        size="small"
        variant={variant}
        disabled={!canRequestForProject}
        w="100%"
        onClick={() => setModalOpen(true)}
        dataAttributes={{ "data-testid": "content-request-expert-btn" }}
      >
        {speakers.length > 1 ? "Request Experts" : "Request Expert"}
      </Button>
      {modalOpen && (
        <Portal>
          <x.div {...fullScreenFixedWrapper} {...flexColumn}>
            <x.div {...stickyAtTheTop}>
              <MobileTopBar
                title={"Request Experts"}
                mainButton={
                  <IconButton size="large" variant="ghost" onClick={onClose} testId="close-content-btn">
                    <ArrowLeft />
                  </IconButton>
                }
              />
            </x.div>
            <x.div padding={spacing.inner.base04} overflow={"scroll"} flexGrow={1}>
              <x.div display={"flex"} flexDirection={"column"} rowGap={"16px"}>
                <CheckboxGroup
                  label={"Select the expert(s) you want to request *"}
                  value={selectedExperts}
                  error={noneSelectedError}
                  onChange={handleExpertSelect}
                >
                  <x.div
                    data-testid={DataTestIds.requestExpertList}
                    maxHeight="170px"
                    overflowY="auto"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    {validSpeakers.map((speaker) => (
                      <Checkbox size={"small"} value={speaker.speakerId}>
                        <x.div display={"flex"} overflow={"hidden"} paddingTop={"2px"} gap={spacing.inner.base}>
                          <Typography component={"span"} variant={"body-small"} whiteSpace={"nowrap"}>
                            {speaker.company}
                          </Typography>
                          <Typography
                            color={text.secondary}
                            variant={"body-small"}
                            component={"span"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                          >
                            {speaker.jobTitle}
                          </Typography>
                        </x.div>
                      </Checkbox>
                    ))}
                  </x.div>
                </CheckboxGroup>
                <TextArea
                  dataAttributes={{ "data-testid": "request-expert-note" }}
                  label="Add a note"
                  value={reason}
                  onChange={({ target: { value } }) => setReason(value)}
                  placeholder="Let us know why you’re requesting an expert and any other details you think will help e.g project info, availability, etc..."
                  {...requestExpertsTextArea}
                />
                <Typography variant="body-small" color="secondary">
                  Clicking 'Request For Project' will notify your AlphaSights team, who will follow up with you shortly.
                  Any expert requests will be checked against your project compliance requirements for bespoke
                  interactions.
                </Typography>
              </x.div>
            </x.div>
            <x.div {...stickyAtTheBottom} {...contentPurchaseCTA}>
              <Button
                size="small"
                flexGrow={1}
                flexBasis={0}
                variant="outline"
                onClick={onClose}
                data-testid={CancelButtonDataTestIds.cancelRequest}
              >
                Cancel
              </Button>
              {/* @ts-ignore */}
              <Button
                loading={submitting}
                onClick={() => handleSubmit(selectedExperts, reason)}
                startIcon={<Request />}
                variant="secondary"
                data-testid={DataTestIds.requestExpertSubmit}
                size="small"
                flexGrow={1}
                flexBasis={0}
              >
                Request Expert
              </Button>
            </x.div>
          </x.div>
        </Portal>
      )}
    </>
  );
};

const PullPurchaseDrawerTop = createGlobalStyle`
  [role='region']:has(> #access-content-drawer) {
    z-index: 10;
  }
`;

export const ContentPurchaseCTA = ({
  content,
  updateContent,
}: {
  content: ContentSuggestion;
  updateContent: (id: string, updates: any) => void;
}) => {
  return (
    <>
      {/* @ts-ignore */}
      <PullPurchaseDrawerTop />
      <PurchaseInDeliverables
        suggestion={content}
        updateDeliverableSuggestion={updateContent}
        isContentFlyout={false}
        fetchSuggestions={() => Promise.resolve()}
      />
    </>
  );
};

export const TypographyNoWrap = styled(Typography)`
  text-wrap: nowrap;
`;

export const TypographyEllipsis = styled(Typography)`
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// --- Citation related
export const ContributedSpan = ({
  citation,
  renderer,
  color,
}: {
  citation: CitableValue<any>;
  renderer?: (props: CitationRendererProps<any>) => React.ReactNode;
  color?: string;
}) => {
  const divider = ";";
  const contributorIdsString = (citedByArr: number[]) =>
    citedByArr.reduce((acc, id) => `${acc}${id}${divider}`, divider);
  const actualRenderer = renderer ?? ToStringRenderer;
  const citationArray = parseInlineCitations(citation)
    ?.map((ea) => ea.citation)
    .filter((ea) => ea.value?.trim().length) ?? [citation];

  const { selectHighlight } = useHighlightHtmlContext();
  const onClick = useCallback(
    (e: any) => {
      const highlight = findParentWithDataAttribute(e.target, "contributorIds");
      selectHighlight(highlight);
    },
    [selectHighlight]
  );
  const isSensitive = hasSensitiveData(citation);

  return citation ? (
    <>
      {citationArray.map((cit) => (
        <x.span
          className={isSensitive ? "obfuscated-sensitive-data" : ""}
          color={color}
          onClick={onClick}
          data-contributor-ids={contributorIdsString(cit.citedBy.length ? cit.citedBy : citation.citedBy)}
        >
          {actualRenderer({ value: cit.value })}
        </x.span>
      ))}
    </>
  ) : null;
};

export const ContributedMarkdown = memo(({ value }: { value: CitableValue<any> }) => {
  const markdown = value.value ?? "";
  return (
    <ReactMarkdown
      className="aui-markdown-content cited-markdown mentions-container"
      renderers={{
        text: ({ value: text }: CitableValue<any>) => (
          <ContributedSpan
            citation={{
              value: text,
              citedBy: /{{.*}}/.test(markdown) ? [] : value.citedBy,
            }}
          />
        ),
      }}
      source={replaceAll(markdown, MarkdownReplacements)}
    />
  );
});

const replaceAll = (text: string, replacements: Record<string, string>) =>
  reduce(
    Object.entries(replacements),
    (accumulator, [toReplace, replacement]) => accumulator.replace(new RegExp(toReplace, "g"), replacement),
    text
  );

// --- Citation related end
