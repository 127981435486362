import React, { useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { Button } from "@alphasights/alphadesign-components";

export interface ShowHiddenExpertsButtonProps {
  interactions: Interaction[];
  isShowing: boolean;
  onClick: () => void;
}

export const ShowHiddenExpertsButton = ({ interactions, isShowing, onClick }: ShowHiddenExpertsButtonProps) => {
  const anyHiddenExpert = useMemo(() => interactions.filter(({ hidden }) => hidden).length > 0, [interactions]);

  return anyHiddenExpert ? (
    <x.div data-testid="show-hidden-experts-btn" display="flex" py="12px" px="16px" gap="10px">
      <Button size="small" variant="outline" w="100%" onClick={onClick}>
        {isShowing ? "Collapse" : "Show"} Hidden Experts
      </Button>
    </x.div>
  ) : null;
};
