import React from "react";
import { Container, ListOption, Typography } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import * as S from "components/SidebarNav/SidebarNav.styled";
import { useEnv } from "@alphasights/client-portal-shared";
import { Env } from "@alphasights/portal-api-client";
import { ExternalLink } from "@alphasights/alphadesign-icons";
import { compact } from "lodash";

type AvatarMenuProps = {
  onWatchlistClick: () => void;
  onPreferencesClick: () => void;
  onClick: (() => void) | undefined;
  anchorElement: Element | undefined;
  onClose: () => void;
};

export const AvatarMenu = ({
  onWatchlistClick,
  onPreferencesClick,
  onClick,
  anchorElement,
  onClose,
}: AvatarMenuProps) => {
  const options = useAvatarMenuOptions({ onWatchlistClick, onPreferencesClick });
  const user = useCurrentUser();
  const userName = user?.name;
  const closeMenuAfter = (fn: () => void) => () => {
    fn();
    onClose();
  };

  return (
    <S.Popover
      open
      placement="right-start"
      onClick={onClick}
      closeOnClickOutside
      anchorEl={anchorElement}
      onClose={onClose}
      isAvatarMenu
    >
      <Container>
        <ListOption type="text" isInteractive={false} indent={0}>
          <Typography variant="body-large-em">{userName}</Typography>
        </ListOption>
        {options.map((option, index) => (
          <ListOption key={index} type="text" size="regular" {...option} onChange={closeMenuAfter(option.onChange)} />
        ))}
      </Container>
    </S.Popover>
  );
};

type AvatarMenuOption = {
  label: string;
  onChange: () => void;
  rightIcon?: React.ReactNode;
  dataAttributes: { [key: string]: string };
};

export const useAvatarMenuOptions = ({
  onWatchlistClick,
  onPreferencesClick,
}: {
  onWatchlistClick: () => void;
  onPreferencesClick: () => void;
}): AvatarMenuOption[] => {
  const env = useEnv() as (Env & { chargebeeSubdomain: string }) | undefined;
  const user = useCurrentUser() as
    | (User & {
        servicePlan?: { currency?: string; trial: boolean };
      })
    | undefined;

  if (!env || !user) return [];

  const addCreditsUrl = `https://${env.chargebeeSubdomain}.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=credits-${user.servicePlan?.currency}&subscription_items[quantity][0]=1&layout=full_page`;
  const chargebeePortalUrl = `https://${env.chargebeeSubdomain}.chargebeeportal.com`;

  return compact([
    user?.alphaNowEnabled && {
      label: "Watchlist",
      onChange: onWatchlistClick,
      dataAttributes: { "data-testid": `watchlist-link` },
    },
    user?.accessOnly &&
      !user.servicePlan?.trial && {
        label: "Add Credits",
        onChange: () => window.open(addCreditsUrl, "_blank"),
        rightIcon: <ExternalLink />,
        dataAttributes: { "data-testid": "add-credits-button" },
      },
    user?.accessOnly && {
      label: "Manage Subscription",
      onChange: () => window.open(chargebeePortalUrl, "_blank"),
      rightIcon: <ExternalLink />,
      dataAttributes: { "data-testid": "manage-subscription-button" },
    },
    {
      label: "Preferences",
      onChange: onPreferencesClick,
      dataAttributes: { "data-testid": `preferences-link` },
    },
  ]);
};
