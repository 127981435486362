import React, { useRef } from "react";
import { x } from "@xstyled/styled-components";
import { useMessengerContext } from "providers/MessengerProvider";
import { NewMessageHeader } from "../NewMessagerHeader/NewMessageHeader";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { NewMessageBody } from "../NewMessageBody/NewMessageBody";
import { useHistory } from "react-router-dom";

export const NewMessageForm = () => {
  const { newMessageType } = useMessengerContext();
  const { container, body, header } = useNewMessageStyles();
  const sendMessageButtonRef = useRef(null);
  const history = useHistory();
  const expertId = new URLSearchParams(history.location.search).get("expertId") || undefined;

  if (!newMessageType) return null;

  return (
    <x.div {...container}>
      <x.div data-testid="new-message-header" {...header}>
        <NewMessageHeader buttonRef={sendMessageButtonRef} />
      </x.div>
      <x.div data-testid="new-message-body" {...body}>
        <NewMessageBody sendMessageButtonRef={sendMessageButtonRef} selectedExpertId={expertId} />
      </x.div>
    </x.div>
  );
};

const useNewMessageStyles = () => {
  const {
    color: { border, background },
    spacing: { inner, layout },
  } = useThemeTokens();

  const container = {
    borderLeftWidth: 1,
    borderColor: border.divider,
    position: "relative",
    flexGrow: 1,
    overflowY: "auto",
    pb: inner.base06,
    backgroundColor: background.surface.page.default,
  };

  const body = {
    maxW: "670px",
    mx: "auto",
    pt: inner.base06,
    px: layout.base04,
  };

  const header = {
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  return {
    container,
    body,
    header,
  };
};
