export const CONTRIBUTOR_PROGRAM = {
  label: "Contributor Program",
  title: "Contribute transcript and receive discount",
  description: "Changes to your project settings will only apply to interactions within your opt-out window.",
  summaryDetails: {
    emptyDescription: "All applicable interactions will match your project settings.",
  },
};

export const PRIVATE_TRANSCRIPT_LIBRARY = {
  label: "Private Transcript Library",
  title: "Share with my enterprise",
  description: "Changes to project settings will apply to all eligible interactions.",
  note: "Any Private Primers on this project will not be impacted.",
};

export const optedIn = "Opted In";
export const optedOut = "Opted Out";
export const noteHeader = "Note:";
