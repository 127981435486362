const LANDING_PAGE_DESCRIPTION =
  "Each week, we add hundreds of new expert insights to our library. Browse our research below, or search for a company or topic of interest.";
const IN_THE_NEWS_TITLE = "In the News";
const RECOMMENDED_INVESTOR_LED_TITLE = "Recommended Investor-led Calls";
const PURCHASED_RESEARCH_TITLE = "Purchased by You and Your Colleagues";
const BOOKMARKED_RESEARCH_TITLE = "Bookmarked";
const RECOMMENDED_CONTENT_TITLE = "Recommended for You";

export {
  LANDING_PAGE_DESCRIPTION,
  IN_THE_NEWS_TITLE,
  RECOMMENDED_INVESTOR_LED_TITLE,
  PURCHASED_RESEARCH_TITLE,
  BOOKMARKED_RESEARCH_TITLE,
  RECOMMENDED_CONTENT_TITLE,
};
