import { useEffect, useState } from "react";

import { ERROR, PURCHASE_ERROR, PURCHASE_INFO, BANNER_TYPE } from "content/AlphaNow/constants";
import {
  accessDeniedBody,
  accessDeniedHeader,
  actionNotCompleted,
  alert,
  downloadFailedBody,
  insufficientCredits,
  pricingLoadError,
  primerAlreadyPurchased,
  purchaseMoreCredits,
  systemError,
  transcriptAlreadyPurchased,
  requestConfirmed,
  contentLeversChanged,
} from "content/AlphaNow";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";

const useBannerData = (contentType, useCase) => {
  const [bannerVariant, setBannerVariant] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");

  useEffect(() => {
    let newBannerVariant, newBannerTitle, newBannerMessage;

    switch (useCase) {
      case PURCHASE_ERROR.PricingError:
        newBannerVariant = BANNER_TYPE.Danger;
        newBannerTitle = systemError;
        newBannerMessage = pricingLoadError;
        break;
      case PURCHASE_ERROR.AlreadyPurchased:
        newBannerVariant = BANNER_TYPE.Info;
        newBannerTitle = alert;
        newBannerMessage =
          contentType === AlphaNowContentType.srm ? primerAlreadyPurchased : transcriptAlreadyPurchased;
        break;
      case PURCHASE_ERROR.InsufficientCredits:
        newBannerVariant = BANNER_TYPE.Warning;
        newBannerTitle = insufficientCredits;
        newBannerMessage = purchaseMoreCredits;
        break;
      case PURCHASE_ERROR.ClientLeversRestrictContentView:
        newBannerVariant = BANNER_TYPE.Warning;
        newBannerTitle = alert;
        newBannerMessage = contentLeversChanged;
        break;
      case ERROR.AccessDenied:
        newBannerVariant = BANNER_TYPE.Warning;
        newBannerTitle = accessDeniedHeader;
        newBannerMessage = accessDeniedBody;
        break;
      case ERROR.DownloadFailed:
        newBannerVariant = BANNER_TYPE.Danger;
        newBannerTitle = systemError;
        newBannerMessage = downloadFailedBody;
        break;
      case PURCHASE_INFO.AccessRequested:
        newBannerVariant = BANNER_TYPE.Info;
        newBannerTitle = "";
        newBannerMessage = requestConfirmed;
        break;
      default:
        newBannerVariant = BANNER_TYPE.Danger;
        newBannerTitle = systemError;
        newBannerMessage = actionNotCompleted;
    }

    setBannerVariant(newBannerVariant);
    setBannerTitle(newBannerTitle);
    setBannerMessage(newBannerMessage);
  }, [contentType, useCase]);

  return { bannerVariant, bannerTitle, bannerMessage };
};

export default useBannerData;
