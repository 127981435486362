import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useUserInitialsMenu = () => {
  const {
    color: { text, border },
    flex: { gap },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  const body = {
    display: "flex",
    gap: inner.base,
    cursor: "pointer",
    w: "48px",
    h: "24px",
  };

  const dropdownButton = {
    color: text.secondary,
    display: "flex",
    alignItems: "center",
  };

  const loggedInAsWrapper = {
    display: "flex",
    gap: gap.sm,
    alignItems: "center",
  };

  const clientProfileUsersDivider = {
    borderBottom: `${shape.border.width.sm} solid ${border.divider}`,
    my: inner.base02,
    mx: `-${inner.base}`,
  };

  const clientProfileUsersSpinner = {
    marginTop: inner.base,
    marginLeft: inner.base02,
  };

  return {
    body,
    dropdownButton,
    loggedInAsWrapper,
    clientProfileUsersDivider,
    clientProfileUsersSpinner,
  };
};
