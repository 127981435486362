export const OVERVIEW_PROPERTIES: Record<string, string> = {
  revenue: "revenue",
  fullTimeEquivalent: "fullTimeEquivalent",
  ownershipType: "ownershipType",
  description: "description",
  location: "location",
  markets: "markets",
  history: "history",
  exampleUseCase: "exampleUseCase",
  customerDescription: "customerDescription",
  monetization: "monetization",
  differentiation: "differentiation",
};
export const OVERVIEW_PROPERTIES_V2: Record<string, string> = {
  revenue: "revenue",
  fullTimeEquivalent: "fullTimeEquivalent",
  ownershipType: "ownershipType",
  description: "description",
  location: "location",
  markets: "markets",
  history: "history",
  exampleUseCase: "exampleUseCase",
};

export const LABEL_TYPES = {
  [OVERVIEW_PROPERTIES.revenue]: "Revenue",
  [OVERVIEW_PROPERTIES.fullTimeEquivalent]: "FTEs",
  [OVERVIEW_PROPERTIES.ownershipType]: "Ownership",
  [OVERVIEW_PROPERTIES.description]: "Description",
  [OVERVIEW_PROPERTIES.location]: "HQ",
  [OVERVIEW_PROPERTIES.markets]: "Markets",
  [OVERVIEW_PROPERTIES.history]: "History",
  [OVERVIEW_PROPERTIES.exampleUseCase]: "Example use case",
  [OVERVIEW_PROPERTIES.customerDescription]: "Customers description",
  [OVERVIEW_PROPERTIES.monetization]: "Monetization",
  [OVERVIEW_PROPERTIES.differentiation]: "Differentiation",
};
export const LABEL_TYPES_V2 = {
  [OVERVIEW_PROPERTIES.revenue]: "Revenue",
  [OVERVIEW_PROPERTIES.fullTimeEquivalent]: "FTEs",
  [OVERVIEW_PROPERTIES.ownershipType]: "Ownership",
  [OVERVIEW_PROPERTIES.description]: "Description",
  [OVERVIEW_PROPERTIES.location]: "HQ",
  [OVERVIEW_PROPERTIES.markets]: "Markets",
  [OVERVIEW_PROPERTIES.history]: "History",
  [OVERVIEW_PROPERTIES.exampleUseCase]: "Example use case",
};

export const OVERVIEW_PROPERTY_ORDER: string[] = [
  OVERVIEW_PROPERTIES.location,
  OVERVIEW_PROPERTIES.revenue,
  OVERVIEW_PROPERTIES.fullTimeEquivalent,
  OVERVIEW_PROPERTIES.markets,
  OVERVIEW_PROPERTIES.ownershipType,
  OVERVIEW_PROPERTIES.description,
  OVERVIEW_PROPERTIES.history,
  OVERVIEW_PROPERTIES.exampleUseCase,
  OVERVIEW_PROPERTIES.customerDescription,
  OVERVIEW_PROPERTIES.monetization,
  OVERVIEW_PROPERTIES.differentiation,
];

export const OVERVIEW_PROPERTY_V2: string[] = [
  OVERVIEW_PROPERTIES.location,
  OVERVIEW_PROPERTIES.revenue,
  OVERVIEW_PROPERTIES.fullTimeEquivalent,
  OVERVIEW_PROPERTIES.markets,
  OVERVIEW_PROPERTIES.ownershipType,
  OVERVIEW_PROPERTIES.description,
  OVERVIEW_PROPERTIES.history,
  OVERVIEW_PROPERTIES.exampleUseCase,
];
