import React from "react";
import usePopover from "hooks/usePopover";
import { Button, Divider, IconButton, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { CONTENT_TYPE, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";
import { Delete } from "@alphasights/alphadesign-icons";
import MultiplePrimerCompanyLogo from "pages/AlphaNowPage/primers/components/primer-icon/MultiplePrimerCompanyLogo";
import { truncateLabel } from "components/InteractionLabel/ManageLabelsPopover";
import CommissionModalPrimerFormat from "./CommissionModalPrimerFormat";
import PrimerTypeButtonGroup from "./PrimerTypeButtonGroup";
import { COMMISSION_MODAL_FIT, COMMISSION_MODAL_INPUT_MAX } from "../../constants";
import CommissionModalRush from "./CommissionModalRush";

type PropTypes = {
  format: string;
  companyName: string;
  logoLinks: string[];
  deleteRow: () => void;
  onChangePrimerType: (value: any, index: number) => void;
  onChangePrimerRush: (value: any, index: number) => void;
  index: number;
  primerType: string;
  credits: number;
  isRecommended?: boolean;
  rush: boolean;
  onAddRecommendedPrimer?: (value: boolean, index: number) => void;
  dataTestId: string;
};

export const testIds = {
  addModalRowButton: "add-modal-row-button",
  addModalRowButtonSearchRow: "add-modal-row-button-search-row",
  creditValue: "product-credit-value",
  deleteRowButton: "delete-row-button",
};

export const AddModalRowButton = ({
  onClick,
  variant,
  index,
}: {
  onClick: () => void;
  variant: string;
  index?: number;
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <Button
      variant={variant}
      onClick={onClick}
      size="small"
      data-testid={index ? `${testIds.addModalRowButton}-${index}` : testIds.addModalRowButtonSearchRow}
      w={inner.base12}
    >
      <Typography variant="body-small-em">Add</Typography>
    </Button>
  );
};

const CommissionModalContent = ({
  dataTestId,
  format,
  companyName,
  logoLinks,
  deleteRow,
  onChangePrimerType,
  onChangePrimerRush,
  credits,
  index,
  rush,
  isRecommended = false,
  onAddRecommendedPrimer = () => {},
  primerType,
}: PropTypes) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const { anchor, onClose: onClosePopover, onClick: handlePopoverClick, ref: modalPopoverRef } = usePopover();

  const renderLabel = () => {
    const maxLength = COMMISSION_MODAL_INPUT_MAX;
    const shouldTruncateLabel = companyName.split(",").length > 1 || companyName.length > maxLength;
    const isCustomerPrimer = format === CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer];

    if (isCustomerPrimer || shouldTruncateLabel) {
      return (
        <Typography
          maxWidth="115px"
          onMouseEnter={handlePopoverClick}
          onMouseLeave={onClosePopover}
          variant="body-small-em"
          color={text.strong._}
        >
          {truncateLabel(companyName) ?? "-"}
        </Typography>
      );
    }

    return (
      <Typography variant="body-small-em" color={text.strong._}>
        {companyName ?? "-"}
      </Typography>
    );
  };

  const onHandleChangePrimerType = (e: any) => {
    onChangePrimerType(e, index);
  };

  return (
    <>
      <x.div
        data-testid={dataTestId}
        py={inner.base02}
        ml={inner.base06}
        alignItems="center"
        display="grid"
        columnGap="10px"
        gridTemplateColumns={COMMISSION_MODAL_FIT}
        w="100%"
      >
        <x.div display="flex" gap="2" alignItems="center">
          {logoLinks.length > 1 ? (
            <MultiplePrimerCompanyLogo companyLogos={logoLinks} />
          ) : (
            <PrimerCompanyLogo size={inner.base08} src={logoLinks[0]} />
          )}
          {renderLabel()}
        </x.div>
        <CommissionModalPrimerFormat format={format} isRush={rush} />
        <PrimerTypeButtonGroup value={primerType} onChange={onHandleChangePrimerType} />
        <CommissionModalRush
          onChange={(value) => onChangePrimerRush(value, index)}
          rush={rush}
          dataTestId={`commission-rush-${index}`}
        />
        <Typography
          color={isRecommended ? text.disabled : text.strong._}
          variant="body-small"
          textAlign="center"
          data-testid={testIds.creditValue}
        >
          {credits}
        </Typography>
        {isRecommended ? (
          <AddModalRowButton
            variant="outline"
            onClick={() => {
              onAddRecommendedPrimer(false, index);
            }}
            index={index}
          />
        ) : (
          <x.div textAlign="center" data-testid={testIds.deleteRowButton}>
            <IconButton onClick={deleteRow} size="small" variant="outline" key="rounded-button">
              <Delete />
            </IconButton>
          </x.div>
        )}
      </x.div>
      <Divider />
      <Popover
        closeOnMouseLeave={true}
        ref={modalPopoverRef}
        anchorEl={anchor}
        open={!!anchor}
        onClose={onClosePopover}
        leaveDelay={500}
      >
        {companyName}
      </Popover>
    </>
  );
};

export default CommissionModalContent;
