import { matchesCount, useFrontEndSearch } from "hooks/useFrontEndSearch";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { selectIndexHighlight } from "../helpers";
import { ProjectSummaryContent } from "./ProjectSummaries.types";

export const useProjectSummarySearch = ({ summary }: { summary?: ProjectSummaryContent }) => {
  const [searchIx, setSearchIx] = useState<number | null>(null);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [searchElHighlight, setSearchElHighlight] = useState<Element | null>(null);

  const searchedSummary = useFrontEndSearch({
    object: summary,
    paths: [
      "overview.description",
      "overview.parts[].text",
      "themes[].title",
      "themes[].description",
      "themes[].overview.parts[].text",
      "themes[].expertInsights[].parts[].text",
    ],
    terms: searchTerms,
  }) as ProjectSummaryContent;

  const searchCounts = useMemo(() => {
    if (!searchedSummary) return null;
    if (searchTerms.length === 0) return null;

    return {
      summary: matchesCount(searchedSummary),
      overview: matchesCount(searchedSummary.overview),
      themes: searchedSummary.themes.map(matchesCount),
      insights: searchedSummary.themes.map((theme) => theme.expertInsights.map(matchesCount)),
    };
  }, [searchedSummary, searchTerms]);

  const onSearchTerms = useCallback(
    (terms: string[]) => {
      if (terms.length === 0) {
        setSearchIx(null);
      } else {
        setSearchIx(_.clamp(searchIx || 0, 0, terms.length));
      }
      setSearchTerms(terms);
    },
    [searchIx]
  );

  useEffect(() => {
    if (searchIx == null) {
      setSearchElHighlight(null);
      return;
    }

    setTimeout(() => {
      // waits until maybe opening expert insights section
      selectIndexHighlight(searchIx, searchCounts!!.summary);
    }, 100);

    const elements = document.querySelectorAll("[data-transcript-highlight]");
    setSearchElHighlight(elements[searchIx!!]);
  }, [searchIx]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchNavPrevious = useCallback(() => {
    if (searchIx === 0) setSearchIx(searchCounts!!.summary - 1);
    else setSearchIx(searchIx!! - 1);
  }, [searchCounts, searchIx]);

  const onSearchNavNext = useCallback(() => {
    if (searchIx === searchCounts!!.summary - 1) setSearchIx(0);
    else setSearchIx(searchIx!! + 1);
  }, [searchCounts, searchIx]);

  return useMemo(() => {
    return {
      searchTerms,
      onSearchTerms,
      setSearchIx,
      onSearchNavPrevious,
      onSearchNavNext,
      searchIx,
      searchCounts,
      searchedSummary,
      searchElHighlight,
    };
  }, [
    searchTerms,
    onSearchTerms,
    onSearchNavPrevious,
    onSearchNavNext,
    setSearchIx,
    searchIx,
    searchCounts,
    searchedSummary,
    searchElHighlight,
  ]);
};
