import React from "react";
import PropTypes from "prop-types";
import { NotFoundPage } from "@alphasights/client-portal-shared";

export const ErrorPage = ({ error }) =>
  error.status === 404 ? <NotFoundPage /> : <pre>{JSON.stringify(error, null, 2)}</pre>;

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
};
