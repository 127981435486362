import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMessengerContentStyles = () => {
  const {
    color: { background },
  } = useThemeTokens();

  const container = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    background: background.surface.page.default,
  };

  const wrapper = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  };

  const content = {
    bg: background.inverse,
    flexGrow: 1,
  };

  return {
    container,
    wrapper,
    content,
  };
};
