const readyWithinMap = Object.freeze({
  regular: {
    en: 10,
    zh: 8,
    es: 24,
    de: 24,
    fr: 24,
    jp: 24,
    ko: 24,
    nl: 48,
    it: 48,
    pt: 48,
    sv: 48,
    ru: 48,
    "zh-HK": 48,
    id: 48,
    vi: 48,
  },
});

export const getTranscriptReadyWithinHours = (transcriptType, language) => {
  return readyWithinMap[transcriptType] ? readyWithinMap[transcriptType][language] : null;
};

export const getTranscriptReadyWithinText = (transcriptType, language) => {
  return getTranscriptReadyWithinHours(transcriptType, language)
    ? `Ready within ${getTranscriptReadyWithinHours(transcriptType, language)} hours`
    : "";
};
