import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useEnv } from "@alphasights/client-portal-shared";
import {
  cancelRequestAdvisor,
  cancelRequestChangeInteraction,
  cancelTranscript,
  fetchClientRequests,
  requestTranscript,
  requestTranscriptPostCall,
  setFlyoutMode,
  useInteractionsPageReducer,
  useReducerCallbacks,
} from "components/InteractionsPage/reducer";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { normalizeProjectBadges, ProjectBadgeProvider } from "providers/BadgeProvider";
import { ExpertRemarksProvider } from "providers/ExpertRemarksProvider";
import { HideExpertProvider } from "providers/HideExpertProvider";
import { CommentProvider } from "components/CommentThread/CommentContext";
import { MessageThreadProvider } from "pages/MessengerPage/context/MessageThreadContext";
import { HitOrigin } from "@alphasights/portal-api-client";
import { FlyoutMode } from "pages/InteractionPage/enums";
import { AdvisorSidebar } from "pages/InteractionPage";
import { LabelsProvider } from "providers/LabelsProvider";
import { ProjectMembersProvider } from "providers/ProjectMembersProvider";
import { CurrentProjectProvider } from "providers/CurrentProjectProvider";
import { ProjectInteractionsProvider } from "providers/ProjectInteractionsProvider";
import { StarExpertProvider } from "providers/StarExpertProvider";

export const UpcomingCallsFlyout = ({ data, onClose }) => {
  const env = useEnv();
  const trackUserAction = useTrackUserAction();
  const currentUser = useCurrentUser();
  const history = useHistory();

  const [state, dispatch, originalDispatch] = useInteractionsPageReducer({
    env,
    trackUserAction,
    currentUser,
    history,
    project: data.project,
  });

  const {
    onToggleHidden,
    onToggleStar,
    onSaveHideExpertReason,
    onRequestFollowUp,
    onRequestRescheduleInteraction,
    onCancelRequest,
  } = useReducerCallbacks({
    state,
    dispatch,
    originalDispatch,
  });

  useDeepCompareEffect(() => {
    if (!data) return;

    dispatch(fetchClientRequests({ projects: [data.project] }));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelPendingRequest = useCallback(
    (request) =>
      dispatch(cancelRequestChangeInteraction(request)).then(() => {
        if (request.type === "SCHEDULE_REQUEST") {
          return dispatch(cancelRequestAdvisor({ id: request.interactionId }));
        }
      }),
    [dispatch]
  );

  const onRequestTranscript = useCallback(
    ({ id, origin }) => {
      return dispatch(requestTranscript({ id, origin }));
    },
    [dispatch]
  );

  const onRequestTranscriptPostCall = useCallback(
    ({ id, origin }) => {
      return dispatch(requestTranscriptPostCall({ id, origin }));
    },
    [dispatch]
  );
  const onCancelRequestTranscript = useCallback((id) => dispatch(cancelTranscript(id)), [dispatch]);

  const onOpenComments = useCallback(() => {
    dispatch(setFlyoutMode(FlyoutMode.Comments));
  }, [dispatch]);

  if (!data) return null;

  return (
    <LabelsProvider project={data.project}>
      <DispatchContext.Provider value={dispatch}>
        <CurrentProjectProvider project={data.project} isLoading={false}>
          <ProjectBadgeProvider badges={normalizeProjectBadges(data.project)}>
            <ExpertRemarksProvider project={data.project}>
              <ProjectMembersProvider projectToken={data.project.token}>
                <HideExpertProvider onSaveHideExpertReason={onSaveHideExpertReason} onToggleHidden={onToggleHidden}>
                  <StarExpertProvider onToggleStar={onToggleStar}>
                    <CommentProvider project={data.project}>
                      <MessageThreadProvider project={data.project}>
                        <ProjectInteractionsProvider
                          state={state}
                          dispatch={dispatch}
                          project={data.project}
                          originalDispatch={originalDispatch}
                        >
                          <AdvisorSidebar
                            showMobileView
                            isOpen
                            isActiveProject
                            flyoutShowsCalendar
                            hidePrimaryContent
                            projectToken={data.project.token}
                            aiTranscriptEnabled={data.project.aiTranscriptEnabled ?? false}
                            allClientInteractions={state.allClientInteractions}
                            allInteractions={state.allClientInteractions}
                            chainInteractions={state.chainInteractions}
                            clientRequests={state.clientRequests}
                            flyoutMode={state.flyoutMode}
                            hasClientPortalMessages={data.project.enablePortalMessages}
                            interaction={data.interaction}
                            interactionUpgradesNewUxEnabled={state.interactionUpgradesNewUxEnabled}
                            isCalendarView={false}
                            onCancelPendingRequest={onCancelPendingRequest}
                            onCancelRequest={onCancelRequest}
                            onCancelRequestTranscript={onCancelRequestTranscript}
                            onChainIdSelectedUpdated={() => {}}
                            onClose={onClose}
                            onOpenComments={onOpenComments}
                            onRequestFollowUp={onRequestFollowUp}
                            onRequestRescheduleInteraction={onRequestRescheduleInteraction}
                            onRequestTranscript={onRequestTranscript}
                            onRequestTranscriptPostCall={onRequestTranscriptPostCall}
                            onSchedule={() => {}}
                            hideStarOrigin={HitOrigin.flyout}
                            projectLead={data.project.lead}
                            readAlong={state.readAlong}
                            summaryEnabled={data.project.summaryEnabled ?? false}
                            relevantStatementType={state.relevantStatementType}
                            token={data.project.token}
                            transcriptEnabled={data.project.transcriptEnabled}
                            videoCallAvailable={data.project.videoCallAvailable ?? false}
                            currentView="mobile-view"
                            upgrades={state.upgrades}
                            loadingUpgrades={state.loadingUpgrades}
                            deliverablesViewEnabled={state.interactionUpgradesNewUxEnabled && data.project.active}
                            requiresComplianceApproval={data.project.requiresComplianceApproval ?? false}
                            recordedEnabled={data.project.recordedEnabled ?? false}
                          />
                        </ProjectInteractionsProvider>
                      </MessageThreadProvider>
                    </CommentProvider>
                  </StarExpertProvider>
                </HideExpertProvider>
              </ProjectMembersProvider>
            </ExpertRemarksProvider>
          </ProjectBadgeProvider>
        </CurrentProjectProvider>
      </DispatchContext.Provider>
    </LabelsProvider>
  );
};
