import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMobileTextBoxStyles = ({
  error,
  disabled,
  resizable,
  attachToNavbar,
}: {
  error?: boolean;
  disabled?: boolean;
  resizable: boolean;
  attachToNavbar: boolean;
}) => {
  const { spacing, shape, color, font } = useThemeTokens();
  return {
    wrapper: {
      ...(!attachToNavbar && {
        position: "fixed",
        bottom: "0",
        right: "0",
        left: "0",
      }),
      padding: spacing.inner.base04,
      borderTopWidth: shape.border.width.small,
      borderTopColor: color.border.neutral.default,
      zIndex: 2,
      backgroundColor: color.background.inverse,
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base02,
    },
    textBoxAndCTAsWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      gap: spacing.inner.base03,
    },
    textAreaWrapper: {
      position: "relative",
      w: "100%",
      display: "inline-grid",
    },
    textAreaStyle: {
      w: "100%",
      borderRadius: shape.border.radius.small,
      outlineWidth: shape.border.width.small,
      outlineStyle: "solid",
      outlineColor: { _: error ? color.border.danger : color.border.neutral.default, focus: color.border.selected },
      background: disabled ? color.background.disabled : color.background.none,
      fontFamily: font.family.text.regular,
      fontWeight: font.weight.regular,
      fontSize: font.size["02"],
      lineHeight: font.lineHeight["01"],
      resize: "none",
      padding: spacing.inner.base02,
      paddingLeft: spacing.inner.base03,
      paddingRight: spacing.inner.base03,
      minH: spacing.inner.base08,
    },
    counterDivStyle: {
      position: "absolute",
      bottom: shape.border.width.small,
      right: resizable ? spacing.inner.base05 : spacing.inner.base,
      lineHeight: font.lineHeight["01"],
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
  };
};
