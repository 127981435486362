import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { x } from "@xstyled/styled-components";
import { Alert, Button, TextField, Typography } from "@alphasights/alphadesign-components";
import { RichTextEditorLexical } from "@alphasights/alphadesign-rte";
import { Edit, Folder, Rocket } from "@alphasights/alphadesign-icons";
import { useProjectLaunchPageStyles } from "./ProjectLaunchPage.styles";
import { useEffect, useState } from "react";
import {
  ContentContainer,
  HeaderContainer,
  useNewNavigation,
  useTrackUserAction,
} from "@alphasights/client-portal-shared";
import { ProjectLauncherProvider, useProjectLauncherContext } from "providers/ProjectLauncherProvider";
import { GlobalNav } from "components/GlobalNav";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { CompaniesOfInterest } from "./CompaniesOfInterest";
import { Colleagues } from "./Colleagues";
import RecommendedResearchPanel from "./RecommendedResearchPanel";

type FieldName = "brief" | "numberOfCalls" | "companySearch";

type ProjectLaunchHeaderProps = {
  fieldsWithError: { fieldName: FieldName; fieldEl?: HTMLElement }[];
  onClickLaunch: () => void;
  validate: boolean;
};

type ProjectLaunchDetailsProps = {
  headerHeight?: number;
  validate: boolean;
  onValidate: (fieldName: FieldName, fieldEl?: HTMLElement, error?: string) => void;
};

export const ProjectLaunchPage = () => {
  const newNavigationEnabled = useNewNavigation();

  const Wrapper = newNavigationEnabled ? React.Fragment : x.div;
  const globalNavProps = {
    hideLimitationsOfUse: true,
    hideAdvisorsLink: true,
    url: "../",
  };

  return (
    <Wrapper h="100dvh" display="flex" flexDirection="column">
      <GlobalNav {...(globalNavProps as any)} />
      <Wrapper overflow="auto">
        <ProjectLauncherProvider>
          <ProjectLaunchPageImpl />
        </ProjectLauncherProvider>
      </Wrapper>
    </Wrapper>
  );
};

const ProjectLaunchPageImpl = () => {
  const styles = useProjectLaunchPageStyles();
  const [fieldsWithError, setFieldsWithError] = useState<{ fieldName: FieldName; fieldEl?: HTMLElement }[]>([]);
  const headerRef = useRef<HTMLDivElement>(null);
  const [validate, setValidate] = useState(false);
  const onValidateField = (fieldName: FieldName, fieldEl?: HTMLElement, error?: string) => {
    if (error) {
      setFieldsWithError((prev) => [...prev, { fieldName, fieldEl }]);
    } else {
      setFieldsWithError((prev) => prev.filter((field) => field.fieldName !== fieldName));
    }
  };

  return (
    <x.div {...styles.wrapper}>
      <x.div {...styles.mainContent}>
        <ProjectLaunchHeader
          fieldsWithError={fieldsWithError}
          validate={validate}
          ref={headerRef}
          onClickLaunch={() => setValidate(true)}
        />
        <SideBySide>
          <x.div {...styles.detailsContent}>
            <ProjectLaunchDetails
              headerHeight={headerRef.current?.getBoundingClientRect().height}
              validate={validate}
              onValidate={onValidateField}
            />
            <Colleagues />
            <CompaniesOfInterest
              validate={validate}
              onValidate={(fieldEl, error) => onValidateField("companySearch", fieldEl, error)}
            />
          </x.div>
          <x.div {...styles.searchContent} data-testid="search-content">
            <RecommendedResearchPanel />
          </x.div>
        </SideBySide>
      </x.div>
    </x.div>
  );
};

const fieldsOrder = new Map<FieldName, number>([
  ["brief", 1],
  ["numberOfCalls", 2],
]);

const ProjectLaunchHeader = React.forwardRef<HTMLDivElement, ProjectLaunchHeaderProps>(
  ({ fieldsWithError, onClickLaunch: onClickLaunchInput }, ref) => {
    const { logHit } = useTrackUserAction();
    const styles = useProjectLaunchPageStyles();
    const history = useHistory();
    const {
      onLaunchProject,
      projectBrief,
      numberOfCalls,
      clientContacts,
      companies: companiesOfInterest,
    } = useProjectLauncherContext();
    const [isLoading, setIsLoading] = useState(false);

    const sortedFieldsWithError = fieldsWithError.sort(
      (a, b) => fieldsOrder.get(a.fieldName)! - fieldsOrder.get(b.fieldName)!
    );

    const onClickLaunch = () => {
      onClickLaunchInput();
      if (sortedFieldsWithError.length > 0) {
        sortedFieldsWithError[0].fieldEl?.scrollIntoView?.({ behavior: "smooth" });
      } else {
        setIsLoading(true);
        onLaunchProject()
          .then((token) => {
            history.push(`/${myAlphaSightsBaseUrl}/projects/${token}`);
          })
          .finally(() => {
            setIsLoading(false);
            logHit({
              origin: HitOrigin.projectLaunchPage,
              action: HitAction.projectLaunchClicked,
              details: {
                projectBrief,
                numberOfCalls,
                clientContacts,
                companiesOfInterest,
              },
            });
          });
      }
    };

    const onClickCancel = () => {
      history.goBack();
      logHit({
        origin: HitOrigin.projectLaunchPage,
        action: HitAction.projectLaunchCancelClicked,
        details: {
          projectBrief,
          numberOfCalls,
          clientContacts,
          companiesOfInterest,
        },
      });
    };

    return (
      <x.div {...styles.header} ref={ref}>
        <HeaderContainer
          icon={<Folder />}
          startContent={<ProjectName />}
          endContent={
            <>
              <Button variant="ghost" onClick={onClickCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="primary"
                endIcon={<Rocket />}
                onClick={onClickLaunch}
                dataAttributes={{ "data-testid": "launch-button" }}
                loading={isLoading}
                disabled={isLoading}
              >
                Launch
              </Button>
            </>
          }
        />
      </x.div>
    );
  }
);

const ProjectName = () => {
  const { projectName } = useProjectLauncherContext();

  return (
    <Typography variant="body-large" color="strong">
      {projectName}
    </Typography>
  );
};

const ProjectLaunchDetails = (props: ProjectLaunchDetailsProps) => {
  const { surveyRequested } = useProjectLauncherContext();

  return (
    <ContentContainer icon={<Edit />} title="Project Details">
      <>
        <ProjectBrief {...props} />
        <NumberOfCalls {...props} />
        {surveyRequested ? <SurveyConfirmedAlert /> : <RecommendSurveyAlert />}
      </>
    </ContentContainer>
  );
};

const ProjectBrief = ({ headerHeight, validate, onValidate }: ProjectLaunchDetailsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isTouched, setIsTouched] = useState(false);
  const { projectBrief, onProjectBriefChange } = useProjectLauncherContext();
  const showErrorMessage = isTouched || validate;
  const errorMessage = projectBrief.trim().length < 100 ? "Minimum of 100 characters required." : undefined;

  useEffect(
    function validateField() {
      onValidate("brief", ref.current ?? undefined, errorMessage);
    },
    [errorMessage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // We're using this hidden value to type the brief
  // on cypress tests, since cypress `type` function
  // doesn't seem to work well with RichTextEditor
  const [hiddenValue, setHiddenValue] = useState("");
  return (
    <x.div ref={ref} style={{ scrollMarginTop: headerHeight }}>
      <input
        type="text"
        data-testid="hidden-brief-input"
        style={{ display: "none" }}
        onBlur={(ev) => setHiddenValue(ev.target.value)}
      />
      <RichTextEditorLexical
        value={hiddenValue}
        label={
          <Typography variant="body-em" component="span">
            Project Brief
          </Typography>
        }
        shouldDisplayToolbar={false}
        shouldDisplayAttachFile={false}
        placeholder="Write your brief here..."
        required
        error={showErrorMessage && errorMessage}
        onChange={(value) => onProjectBriefChange(value)}
        onBlur={() => setIsTouched(true)}
        maxHeight={612}
        dataAttributes={{ "data-testid": "project-brief-textbox" }}
      />
    </x.div>
  );
};

const NumberOfCalls = ({ headerHeight, validate, onValidate }: ProjectLaunchDetailsProps) => {
  const styles = useProjectLaunchPageStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [isTouched, setIsTouched] = useState(false);
  const { numberOfCalls, onNumberOfCallsChange } = useProjectLauncherContext();
  const showErrorMessage = isTouched || validate;
  const errorMessage = (numberOfCalls ?? 0) <= 0 ? "Number must be greater than zero." : undefined;

  useEffect(
    function validateField() {
      onValidate("numberOfCalls", ref.current ?? undefined, errorMessage);
    },
    [errorMessage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div marginTop={styles.projectDetails.gap} ref={ref} style={{ scrollMarginTop: headerHeight }}>
      <TextField
        required
        value={numberOfCalls?.toString()}
        label={
          <Typography variant="body-em" component="span">
            How many calls would you like to take?
          </Typography>
        }
        placeholder="e.g 12"
        error={showErrorMessage && !!errorMessage}
        errorText={errorMessage}
        onBlur={() => setIsTouched(true)}
        onChange={(e) => onNumberOfCallsChange(Number(e.target.value.trim()))}
        type={"number"}
        pattern={/^[0-9]*$/}
        min={1}
        maxHeight={72}
        dataAttributes={{ "data-testid": "number-calls-textfield" }}
      />
    </x.div>
  );
};

const RecommendSurveyAlert = () => {
  const styles = useProjectLaunchPageStyles();
  const { numberOfCalls, onSurveyRequestedChange } = useProjectLauncherContext();
  if ((numberOfCalls ?? 0) < 20) return null;

  return (
    <x.div marginTop={styles.projectDetails.gap}>
      <Typography>
        <Alert
          variant={"info"}
          size="small"
          buttonsPosition="right"
          buttonPrimaryProps={{
            size: "small",
            children: "Request Survey ",
            variant: "outline",
            onClick: () => onSurveyRequestedChange(true),
          }}
        >
          {" "}
          Our Survey offering could enhance your project. Easily gather evidence, test hypotheses, and identify insights
          at scale to scale scale to complement your 1-on-1 calls.
        </Alert>
      </Typography>
    </x.div>
  );
};

const SurveyConfirmedAlert = () => {
  const styles = useProjectLaunchPageStyles();
  const { numberOfCalls, surveyRequested, onSurveyRequestedChange } = useProjectLauncherContext();

  if ((numberOfCalls ?? 0) < 20) {
    surveyRequested && onSurveyRequestedChange(false);
    return null;
  }

  return (
    <x.div marginTop={styles.projectDetails.gap}>
      <Typography>
        <Alert variant={"success"}>
          {" "}
          We will let the team know that you're interested in a survey once you launch your project.
        </Alert>
      </Typography>
    </x.div>
  );
};
interface SideBySideProps {
  children: React.ReactNode[];
}

const SideBySide: React.FC<SideBySideProps> = ({ children }) => {
  const { sideBySideContainer, sideBySidePanel } = useProjectLaunchPageStyles();
  return (
    <x.div {...sideBySideContainer}>
      {children.map((child, index) => (
        <x.div key={index} {...sideBySidePanel}>
          {child}
        </x.div>
      ))}
    </x.div>
  );
};
