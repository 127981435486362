import React, { useEffect, useState } from "react";
import { CallUnavailableAlert } from "./CallUnavailableAlert.js";
import { COUNTRY_CODES } from "constants/Country/index.js";
import { SelectCountry } from "components/SelectCountry/index.js";
import { Link } from "@alphasights/alphadesign-components";
import { CallAvailabilityStatus } from "./constants";
import { FormattedDateTime, useTimezone, hasTimeZoneAbbreviation } from "../../providers/TimezoneProvider";

export const DialIn = ({
  isOpen,
  mainNumber,
  otherNumbers,
  interactionId,
  accessCode,
  callAvailabilityStatus,
  projectLead,
}) => {
  const timezone = useTimezone();
  const DATE_FORMAT = `E d MMM, h:mm aaa (zzz${hasTimeZoneAbbreviation(timezone.currentTimezone) ? ", O" : ""})`;

  const [selected, setSelected] = useState();
  const [dialNumbers, setDialNumbers] = useState([]);
  const [scheduledFor, setScheduledFor] = useState();

  useEffect(() => {
    if (callAvailabilityStatus) {
      setScheduledFor(callAvailabilityStatus.startDate);
    }
  }, [callAvailabilityStatus]);

  useEffect(() => {
    if (otherNumbers && otherNumbers.length > 0) {
      setDialNumbers(
        otherNumbers.map((number) => {
          const code = COUNTRY_CODES.find((country) => number.country.includes(country.name))?.isoCode;
          return {
            value: number.number,
            children: number.country,
            code: code,
          };
        })
      );

      const mainDialNumber = otherNumbers.find((dialNumber) => dialNumber.number === mainNumber);

      if (mainDialNumber) {
        setSelected(mainNumber);
      } else {
        setSelected(otherNumbers[0].number);
      }
    } else {
      setDialNumbers([]);
      setSelected(null);
    }
  }, [mainNumber, otherNumbers, interactionId]);

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {callAvailabilityStatus && callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE && (
        <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
      )}

      <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-mt-4">
        Please dial this phone number followed by the code to join the call. Ensure the country matches your current
        location.
      </div>
      <div className="aui-mt-6">
        <SelectCountry
          value={selected}
          onChange={(value) => setSelected(value)}
          children={dialNumbers}
          allowMultiple={false}
          input={true}
          placeholder={"Dial-in Numbers"}
        />
      </div>
      <div className={`aui-mt-4 aui-flex-row aui-flex aui-text-grey-5 aui-text-body aui-font-regular`}>
        <div>Scheduled for:</div>
        <div className="aui-ml-1 aui-text-grey-5 aui-text-body aui-font-regular">
          {scheduledFor && <FormattedDateTime date={new Date(scheduledFor)} format={DATE_FORMAT} />}
        </div>
      </div>
      <div className={`aui-mt-3 aui-flex-row aui-flex aui-text-grey-5 aui-text-body aui-font-regular`}>
        <div>Number:</div>
        <div className="aui-ml-1 aui-text-grey-5 aui-text-body aui-font-regular">
          <Link href={`tel:${selected}`}>{selected}</Link>
        </div>
      </div>
      <div className={`aui-mt-2 aui-flex-row aui-flex aui-text-grey-5 aui-text-body aui-font-regular`}>
        <div>Code:</div>
        <div className="aui-ml-1 aui-text-grey-5 aui-text-body aui-font-regular">{accessCode}</div>
      </div>
    </div>
  );
};
