import { useMemo } from "react";

const highlightTerms = (fieldName: string, text: string, terms: string[]) => {
  if (typeof text !== "string") {
    throw new Error(`Expects a string on field '${fieldName}' but got object with '${Object.keys(text)}' keys`);
  }

  const highlights = terms.reduce(
    ({ text, count }, term) => {
      const regex = new RegExp(`${term}`, "gi");
      const matchers = [...text.matchAll(regex)];

      const { replacing: newText, lastReplaceIx } = matchers.reduce(
        ({ lastReplaceIx, replacing }: any, match: any) => {
          const before = text.slice(lastReplaceIx, match.index);

          return {
            lastReplaceIx: match.index + String(match).length,
            replacing: replacing + before + "<em>" + match + "</em>",
          };
        },
        { lastReplaceIx: 0, replacing: "" }
      );

      return {
        text: newText + text.slice(lastReplaceIx, text.length),
        count: count + matchers.length,
      };
    },
    { text, count: 0 }
  );

  return {
    [fieldName]: highlights.text,
    hasHighlight: highlights.count > 0,
    highlightCounts: highlights.count,
  };
};

const search = (object: any, [field, ...rest]: string[], terms: string[]): any => {
  if (!object) return object;

  const shouldBeArray = field.endsWith("[]");
  const fieldName = shouldBeArray ? field.replace("[]", "") : field;
  const fieldValue = object[fieldName];

  if (!fieldValue) return object;

  if (rest.length === 0) {
    return {
      ...object,
      ...highlightTerms(fieldName, fieldValue, terms),
    };
  } else {
    if (shouldBeArray) {
      return {
        ...object,
        [fieldName]: fieldValue.map((nested: any) => {
          return search(nested, rest, terms);
        }),
      };
    }

    return {
      ...object,
      [field]: search(fieldValue, rest, terms),
    };
  }
};

const removeSurroundingQuotes = (term: string) => {
  return ["'", '"'].reduce((acc, it) => acc.replace(new RegExp(`^${it}(.+)${it}$`), "$1"), term);
};

const searchOverObject = ({ object, terms, paths }: { object: any; terms: string[]; paths: string[] }) => {
  const cleanTerms = terms.map(removeSurroundingQuotes);
  return paths.reduce((object, path) => {
    return search(object, path.split("."), cleanTerms);
  }, object);
};

export const frontEndSearch = ({ object, terms, paths }: { object: any; terms: string[]; paths: string[] }) => {
  if (object instanceof Array) {
    return object.map((element) => searchOverObject({ object: element, terms, paths }));
  }

  return searchOverObject({ object, terms, paths });
};

export const useFrontEndSearch = ({ object, terms, paths }: { object: any; terms: string[]; paths: string[] }) => {
  return useMemo(() => {
    return frontEndSearch({ object, terms, paths });
  }, [object, terms, paths]);
};

export const matchesCount = (object: any): number => {
  if (!object) return 0;
  if (typeof object !== "object") return 0;

  var count = 0;

  count += object["highlightCounts"] || 0;

  count += Object.values(object).reduce((acc: number, nested) => {
    if (nested instanceof Array) {
      return nested.reduce((acc, obj) => acc + matchesCount(obj), acc);
    }

    return acc + matchesCount(nested);
  }, 0);

  return count;
};
