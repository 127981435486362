import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTConversationListItemStyles = ({ selected }) => {
  const {
    color: { border, background },
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    background: background.surface.page.default,
    borderWidth: 1,
    borderColor: {
      _: selected ? border.selected : border.divider,
      hover: border.selected,
    },
    borderRadius: inner.base02,
    px: inner.base04,
    py: inner.base03,
    cursor: "pointer",
    display: "flex",
    gap: inner.base02,
    justifyContent: "space-between",
  };

  const titleDiv = {
    display: "flex",
    gap: inner.base02,
  };

  const icon = {
    flexShrink: 0,
  };

  const text = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    textOverflow: "ellipsis",
  };

  return {
    wrapper,
    titleDiv,
    icon,
    text,
  };
};
