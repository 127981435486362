import React from "react";
import classnames from "classnames";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const DataArea = ({ icon, children, className, iconClassName = "aui-text-xl", ...others }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <div className={classnames("aui-flex", className)} {...others}>
      <x.div pr={spacing.inner.base02}>{icon && <Icon color={color.icon.secondary}>{icon}</Icon>}</x.div>
      <div className="aui-flex-1">{children}</div>
    </div>
  );
};
