import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Pill, useThemeTokens } from "@alphasights/alphadesign-components";

import useStyles from "./styles";
import { Close, Search } from "@alphasights/alphadesign-icons";
import "./SearchBar.css";
import styled from "styled-components";

const SearchBar = ({ appliedKeywords, placeholder, onTextSearch, initialValue, loading, isCollapsible }) => {
  const [searchBarInputText, setSearchBarInputText] = useState("");
  const [queryKeywords, setQueryKeywords] = useState(initialValue || []);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const inputSearchRef = useRef();

  const addSearchKeyword = (event) => {
    event && event.preventDefault() && event.stopPropagation();
    if (searchBarInputText && searchBarInputText !== "") {
      const keywords = queryKeywords.includes(searchBarInputText)
        ? queryKeywords
        : [...queryKeywords, searchBarInputText];

      updateExecuteSearch(keywords);
      resetInputSearch();
    }
  };

  const updateExecuteSearch = useCallback(
    (keywords) => {
      setQueryKeywords(keywords);
      onTextSearch(keywords);
    },
    [onTextSearch]
  );

  const removeSearchKeyword = useCallback(
    (searchKeyword) => {
      return (event) => {
        event.preventDefault();
        const remainingKeywords = queryKeywords.filter((queryKeyword) => queryKeyword !== searchKeyword);
        updateExecuteSearch(remainingKeywords);
      };
    },
    [queryKeywords, updateExecuteSearch]
  );

  useEffect(() => {
    appliedKeywords && setQueryKeywords(appliedKeywords);
    if (inputSearchRef.current) {
      inputSearchRef.current.style.width = "1px";
    }
  }, [appliedKeywords]);

  const resetInputSearch = () => {
    if (inputSearchRef.current) {
      setSearchBarInputText("");
      inputSearchRef.current.value = "";
      inputSearchRef.current.style.width = "1px";
    }
  };

  const hasQueryKeywords = useMemo(() => queryKeywords.length > 0, [queryKeywords.length]);

  const placeholderMessage = () => {
    const realPlaceHolder = placeholder || "Search Deliverables...";
    return hasQueryKeywords ? "" : realPlaceHolder;
  };

  const handleFocusClick = (event) => {
    event && event.preventDefault();
    inputSearchRef.current && inputSearchRef.current.focus();
  };

  const removeLastKeyword = (event) => {
    if (queryKeywords.length === 0) return;
    if (inputSearchRef.current.value.length !== 0) return;

    removeSearchKeyword(queryKeywords[queryKeywords.length - 1])(event);
  };

  const handleInputKeyDown = (event) => {
    if (loading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (inputSearchRef.current) {
      inputSearchRef.current.style.width = (inputSearchRef.current.value.length + 1) * 8 + "px";
    }

    if (event.key === "Enter") {
      addSearchKeyword(event);
    }
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      resetInputSearch();
    }

    if (event.key === "Backspace") {
      removeLastKeyword(event);
    }
  };

  const styles = useStyles({
    isSearchFocused,
    isCollapsible,
  });

  const Wrapper = isCollapsible ? CollapsedWrapper : x.div;

  return (
    <Wrapper
      data-testid="deliverables-searchbar"
      onClick={handleFocusClick}
      isSearchEmpty={!hasQueryKeywords && (appliedKeywords ?? []).length === 0}
      {...styles.searchBar}
      {...(loading && styles.noHoverNoClick)}
    >
      {isCollapsible && (
        <Icon size="small" color="secondary">
          <Search />
        </Icon>
      )}
      <x.div data-testid="deliverables-searchbar-div" {...styles.searchBarDiv}>
        <x.div className="scrollable-wo-scrollbar" {...styles.searchBarDivChip}>
          {queryKeywords.map((value) => {
            return (
              <Pill
                key={value}
                data-testid={`deliverables-searchbar-chip-${value}`}
                variant="blue"
                whiteSpace="nowrap"
                onClose={removeSearchKeyword(value)}
                isInteractive={false}
                size={isCollapsible ? "small" : "medium"}
              >
                {value}
              </Pill>
            );
          })}
          <x.input
            {...styles.searchBarTextField}
            data-testid="deliverables-searchbar-input"
            ref={inputSearchRef}
            placeholder={placeholderMessage()}
            onKeyDown={handleInputKeyDown}
            onChange={(e) => {
              setSearchBarInputText(e.target.value);
            }}
            onBlur={(event) => {
              addSearchKeyword(event);
              setIsSearchFocused(false);
            }}
            onFocus={() => {
              setIsSearchFocused(true);
            }}
          />
        </x.div>
      </x.div>
      {hasQueryKeywords && (
        <Icon
          size={isCollapsible ? "small" : "medium"}
          color="secondary"
          data-testid="deliverables-searchbar-clear-icon"
          onClick={() => updateExecuteSearch([])}
        >
          <Close />
        </Icon>
      )}
    </Wrapper>
  );
};

const CollapsedWrapper = styled(x.div)(({ isSearchEmpty }) => {
  const {
    shape,
    color: { background },
    spacing,
  } = useThemeTokens();

  return `
    transition: width 0.3s ease-in-out;
    width: 200px;
    height: ${spacing.inner.base08};
    border-radius: ${shape.border.radius.xlarge};
    &:has(input:not(:focus)) {
      &:hover {
        background-color: ${background.action.outline.hover};
      }
      &:not(:hover) {
        [data-testid="deliverables-searchbar-clear-icon"] {
          display: none;
        }
      }
    }
    ${
      isSearchEmpty &&
      `
      &:has(input:not(:focus)) {
        width: 40px;
        cursor: pointer !important;
        [data-testid="deliverables-searchbar-div"] {
          pointer-events: none;
        }
      }
    `
    }
  `;
});

export { SearchBar };
