import React, { FC, useEffect, useMemo, useState } from "react";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { Tile, Typography, useThemeTokens, TabItem, Button } from "@alphasights/alphadesign-components";
import {
  watchlists,
  saveButton,
  cancel,
  deleteWatchlistLabel,
  watchlistSettingsLabel,
  editWatchlistLabel,
} from "./consts";
import { Delete, Show } from "@alphasights/alphadesign-icons";
import { ModalWrapper, TabWrapper, TitleWrapper } from "./WatchlistModal.styled";
import EditWatchlistView from "./EditWatchlistView";
import { getWatchlistSettings, hasWatchlistSettingsChanged } from "./utils";
import useWatchlist, {
  WatchlistDataProps,
  UpdateWatchlistSettingsPayload,
  INITIAL_WATCHLIST_DATA,
  FilterWatchlistTriggerProps,
  INITIAL_FILTERS,
} from "hooks/useWatchlist";
import WatchlistSettingsView from "./WatchlistSettingsView";
import DeleteWatchlistModal from "./DeleteWatchlistModal";
import useModal from "hooks/useModal";
import WatchlistSelect from "./WatchlistSelect";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

interface WatchlistModalProps {
  onClose: () => void;
  availableWatchlists: WatchlistDataProps[];
  selectedWatchlistId?: string;
  setSelectedWatchlistId: (watchlistId: string) => void;
}
const DataTestIds = {
  watchlistModal: "watchlist-modal",
  watchlistIcon: "watchlist-icon",
  watchlistSelect: "watchlist-select",
  watchlistOption: "watchlist-option",
};

const Title = () => {
  return (
    <TitleWrapper>
      <Tile variant="icon" icon={<Show data-testid={DataTestIds.watchlistIcon} />} />
      <Typography variant="body-large-em" style={{ textTransform: "none" }}>
        {watchlists}
      </Typography>
    </TitleWrapper>
  );
};

const WatchlistModal: FC<WatchlistModalProps> = ({
  onClose,
  availableWatchlists,
  selectedWatchlistId,
  setSelectedWatchlistId,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [watchlistSettings, setWatchlistSettings] = useState<UpdateWatchlistSettingsPayload>(
    getWatchlistSettings(availableWatchlists[0] ?? INITIAL_WATCHLIST_DATA) as UpdateWatchlistSettingsPayload
  );
  const [triggerFilters, setTriggerFilters] = useState<FilterWatchlistTriggerProps>(INITIAL_FILTERS);

  const {
    color: { icon },
    spacing: { inner },
  } = useThemeTokens();
  const { createWatchlist, updateWatchlist, deleteWatchlist, useFetchWatchlist } = useWatchlist();
  const { logHit } = useTrackUserAction();

  const { data: watchlistData, isFetching, refetch: fetchWatchlist } = useFetchWatchlist({
    watchlistId: selectedWatchlistId,
    sort: triggerFilters.sort,
    filter: triggerFilters.filter,
  });

  const {
    isVisible: isDeleteWatchlistModalVisible,
    onOpen: onOpenDeleteWatchlist,
    onClose: onCloseDeleteWatchlist,
  } = useModal();

  const isEditWatchlistView = selectedTabIndex === 0;
  const isSettingsView = selectedTabIndex === 1;
  const hasDeleteWatchlistButton = isSettingsView && !!selectedWatchlistId;
  const hasUpdatedSettings =
    watchlistSettings &&
    hasWatchlistSettingsChanged(
      getWatchlistSettings(watchlistData ?? INITIAL_WATCHLIST_DATA) as any,
      getWatchlistSettings(watchlistSettings as any) as any
    );
  const hasSaveWatchlistDisabled = watchlistSettings?.name?.length === 0 || !hasUpdatedSettings;
  const hasUpdatedWatchlistFrequency = selectedWatchlistId
    ? watchlistSettings.frequency !== watchlistData?.frequency
    : watchlistSettings.frequency !== INITIAL_WATCHLIST_DATA.frequency;

  const onTabChange = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  useDeepCompareEffect(() => {
    selectedWatchlistId && fetchWatchlist();
  }, [selectedWatchlistId, triggerFilters, fetchWatchlist]);

  useDeepCompareEffect(() => {
    if (watchlistData) {
      const watchlistSettingsData = getWatchlistSettings(watchlistData);
      setWatchlistSettings(watchlistSettingsData as UpdateWatchlistSettingsPayload);
    }
  }, [watchlistData, setWatchlistSettings]);

  useEffect(() => {
    logHit({
      origin: HitOrigin.watchlist,
      action: HitAction.viewWatchlist,
    });
  }, [logHit]);

  const handleSaveWatchlist = async () => {
    let res;
    try {
      if (selectedWatchlistId) {
        res = await updateWatchlist({
          watchlistId: selectedWatchlistId,
          watchlistSettings: watchlistSettings as UpdateWatchlistSettingsPayload,
        });
        hasUpdatedWatchlistFrequency &&
          logHit({
            origin: HitOrigin.watchlist,
            action: HitAction.updateWatchlistSettings,
            details: {
              watchlistId: res.id,
              frequency: { previous: watchlistData.frequency, current: watchlistSettings.frequency },
            },
          });
      } else {
        res = await createWatchlist({ ...watchlistSettings, triggers: [] });
        logHit({
          origin: HitOrigin.watchlist,
          action: HitAction.createWatchlist,
          details: {
            watchlistId: res?.id,
            ...(hasUpdatedWatchlistFrequency && {
              frequency: { previous: INITIAL_WATCHLIST_DATA.frequency, current: watchlistSettings.frequency },
            }),
          },
        });
      }
    } catch (e) {
      console.error(`Failed to save Watchlist settings: ${e}`);
    }
  };

  const handleDeleteWatchlist = async () => {
    try {
      await deleteWatchlist(selectedWatchlistId as string);
      logHit({
        origin: HitOrigin.watchlist,
        action: HitAction.deleteWatchlist,
        details: {
          watchlistId: selectedWatchlistId,
        },
      });
    } catch (e) {
      console.error(`Failed to delete Watchlist settings: ${e}`);
    } finally {
      onClose();
    }
  };

  const saveWatchlistButton = useMemo(
    () =>
      isSettingsView && (
        <Button
          variant="secondary"
          size="small"
          ml={inner.base04}
          onClick={handleSaveWatchlist}
          disabled={hasSaveWatchlistDisabled}
        >
          {saveButton}
        </Button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSettingsView, watchlistSettings, handleSaveWatchlist, hasSaveWatchlistDisabled]
  );

  const deleteWatchlistButton = useMemo(
    () =>
      hasDeleteWatchlistButton && (
        <Button variant="ghost" startIcon={<Delete />} size="small" color={icon.danger} onClick={onOpenDeleteWatchlist}>
          {deleteWatchlistLabel}
        </Button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasDeleteWatchlistButton, onOpenDeleteWatchlist]
  );

  const cancelWatchlistButton = useMemo(
    () =>
      isSettingsView && (
        <Button variant="ghost" size="small" onClick={onClose}>
          {cancel}
        </Button>
      ),
    [isSettingsView, onClose]
  );

  return (
    <>
      <ModalWrapper
        title={<Title />}
        open
        onClose={onClose}
        data-testid={DataTestIds.watchlistModal}
        variant="complex"
        shouldShowFooter={true}
        slotWidth="676px"
        transition="opacity 0.3s ease-out"
        slotHeight={isEditWatchlistView ? "578px" : "424px"}
        primaryButton={saveWatchlistButton}
        secondaryButton={cancelWatchlistButton}
        tertiaryButton={deleteWatchlistButton}
      >
        <WatchlistSelect
          availableWatchlists={availableWatchlists}
          watchlistId={selectedWatchlistId}
          onChange={setSelectedWatchlistId}
        />
        <TabWrapper variant="default" size="small" colorVariant="primary" onChange={onTabChange}>
          <TabItem label={editWatchlistLabel} />
          <TabItem label={watchlistSettingsLabel} />
        </TabWrapper>
        {isEditWatchlistView && (
          <EditWatchlistView
            isLoading={isFetching}
            watchlistId={selectedWatchlistId}
            availableWatchlists={availableWatchlists}
            watchlistTriggers={watchlistData?.triggers ?? []}
            filter={triggerFilters}
            updateFilter={setTriggerFilters}
          />
        )}
        {isSettingsView && watchlistSettings && (
          <WatchlistSettingsView
            watchlistSettings={watchlistSettings}
            onSettingsChange={setWatchlistSettings}
            isLoading={isFetching}
          />
        )}
      </ModalWrapper>
      {isDeleteWatchlistModalVisible && (
        <DeleteWatchlistModal onClose={onCloseDeleteWatchlist} onDeleteWatchlist={handleDeleteWatchlist} />
      )}
    </>
  );
};

export { WatchlistModal as default, DataTestIds };
