import styled from "styled-components";

const WatchlistHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const WatchlistTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export { WatchlistHeaderContainer, WatchlistTitleContainer };
