import { useLayoutEffect, useState } from "react";

export const useIsVisibleOnScreen = (ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(current);
      return () => observer.disconnect();
    }
  }, [ref]);

  return isVisible;
};
