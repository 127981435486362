import React from "react";
import { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import {
  EmailField,
  PasswordField,
  PasswordConfirmationField,
  PasswordRulesList,
  FormHeader,
  FormBody,
  FormFooter,
  FormContainer,
  FormContent,
} from "./Form";
import { validatePasswordRequired, getValidationRules, validatePasswordForm } from "./validators";
import { fetch, useApi } from "../../hooks/useApi";
import "./index.css";
import useQuery from "../../hooks/useQuery";
import useQueryParams from "../../hooks/useQueryParams";
import { Redirect, Route } from "react-router";
import {
  LimitationOfUsageText,
  CookiePolicyModal,
  LoadingOverlay,
  useEnv,
  useNotifications,
  ProjectLeadAvatar,
} from "@alphasights/client-portal-shared";
import {
  Button,
  Checkbox as CheckboxAds,
  Icon as IconAds,
  Link,
  Typography,
  Skeleton,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Edit, Mail, MobilePhone } from "@alphasights/alphadesign-icons";
import { useLoginStyles } from "./index.styles";
import isEmpty from "lodash/isEmpty";
import useRedirect from "hooks/useRedirect";
import {
  getUserIsLoading,
  useCurrentUser,
  usePortalStore,
  usePortalStoreSelector,
  useUserAuthIsReady,
} from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useLogger } from "logging/Logger";

export const SignInForm = ({
  onSubmit,
  onContinue,
  initialEmail = "",
  allowAnonymousContinue,
  isLoading,
  title = "Sign In",
  message = "Welcome to the AlphaSights Client Platform.",
  onClickHelp,
  showSignUp,
  onClickSignUp,
}) => {
  const { modalTitle, modalDescription, nextButton, skipSignInButton } = useLoginStyles();

  return (
    <>
      <FormContainer
        initialValues={{
          email: initialEmail,
        }}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        {({ isValid }) => (
          <>
            <FormHeader
              title={
                <Typography variant="h3" {...modalTitle}>
                  {title}
                </Typography>
              }
              description={<Typography {...modalDescription}>{message}</Typography>}
            />
            <FormBody>
              <FormContent data-testid="signin-form">
                <EmailField />
                <Button variant="secondary" type="submit" disabled={!isValid} {...nextButton}>
                  <Typography variant="body-em">Next</Typography>
                </Button>
              </FormContent>
              {allowAnonymousContinue && (
                <>
                  <Button variant="outline" onClick={() => onContinue({ skipSignIn: true })} {...skipSignInButton}>
                    <Typography variant="body-em">Or Continue With Limited Access</Typography>
                  </Button>
                </>
              )}
            </FormBody>
            <FormFooter>
              <SignInFooter
                showSignUp={showSignUp}
                onClickSignUp={onClickSignUp}
                onClickHelp={onClickHelp}
                showExpertAccess
              />
            </FormFooter>
          </>
        )}
      </FormContainer>
    </>
  );
};

export const SignInFooter = ({
  showLimitationsOfUse = false,
  showSignUp = true,
  showSignIn = false,
  showExpertAccess = false,
  onClickLOU,
  onClickHelp,
  onClickSignUp,
  onClickSignIn,
}) => {
  const { signInFooterDiv, helpButton, textStyle, limitationsOfUseButton } = useLoginStyles();

  const env = useEnv();

  return (
    <x.div {...signInFooterDiv}>
      <Button variant="ghost" onClick={onClickHelp} {...helpButton}>
        <Typography variant="body-small-em" {...textStyle}>
          Help
        </Typography>
      </Button>
      {showLimitationsOfUse && !env?.enableCookiePolicy && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | "}
          </Typography>
          <Button variant="ghost" data-testid="lou-button" onClick={onClickLOU} {...limitationsOfUseButton}>
            <Typography variant="body-small-em" {...textStyle}>
              Limitations of Use
            </Typography>
          </Button>
        </>
      )}
      {showSignUp && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | No account? "}
          </Typography>
          <Link onClick={onClickSignUp} cursor="pointer" size="small">
            <Typography variant="body-small-em">Sign Up</Typography>
          </Link>
        </>
      )}
      {showSignIn && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | Have an account? "}
          </Typography>
          <Link onClick={onClickSignIn} cursor="pointer" size="small">
            <Typography variant="body-small-em">Sign in</Typography>
          </Link>
        </>
      )}
      {showExpertAccess && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | "}
          </Typography>
          <x.div display="inline-block">
            <Typography variant="body-small" component="span" {...textStyle}>
              Are you an expert?{" "}
            </Typography>
            <Link
              href="https://www.alphasights.com/experts/#how-can-i-sign-in-to-your-expert-platform"
              cursor="pointer"
              size="small"
            >
              <Typography variant="body-small-em">Request Access</Typography>
            </Link>
          </x.div>
        </>
      )}
    </x.div>
  );
};

export const SignUpForm = ({ onSubmit, isLoading, onClickHelp, onClickSignIn, initialEmail }) => {
  const { modalTitle, modalDescription, nextButton } = useLoginStyles();
  const { emailDomainsBlacklist } = useEnv();

  const validateEmailDomain = (value) => {
    if (emailDomainsBlacklist.some((domain) => value.toLowerCase().includes(`@${domain.toLowerCase()}.`)))
      return "Enter your company email to sign up.";
  };

  return (
    <>
      <FormContainer
        initialValues={{
          email: initialEmail,
        }}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        {({ isValid }) => (
          <>
            <FormHeader
              title={
                <Typography variant="h3" {...modalTitle}>
                  Sign Up
                </Typography>
              }
              description={
                <Typography {...modalDescription}>Create an account to view and manage your projects.</Typography>
              }
            />
            <FormBody>
              <FormContent data-testid="signup-form">
                <EmailField customValidation={validateEmailDomain} />
                <Button variant="secondary" type="submit" disabled={!isValid} {...nextButton}>
                  <Typography variant="body-em">Create Account</Typography>
                </Button>
              </FormContent>
            </FormBody>
            <FormFooter>
              <SignInFooter showSignUp={false} showSignIn onClickHelp={onClickHelp} onClickSignIn={onClickSignIn} />
            </FormFooter>
          </>
        )}
      </FormContainer>
    </>
  );
};

export const SignUpSuccess = ({ email, onClickHelp }) => {
  const { modalTitle, modalDescription } = useLoginStyles();

  return (
    <>
      <FormContainer>
        {() => (
          <>
            <FormHeader
              title={
                <Typography variant="h3" {...modalTitle}>
                  Check your inbox
                </Typography>
              }
              description={<Typography {...modalDescription}>We have sent an email to {email}.</Typography>}
            />
            <FormFooter>
              <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
            </FormFooter>
          </>
        )}
      </FormContainer>
    </>
  );
};

export const ContactCustomerSupport = () => {
  const { spacing, color } = useThemeTokens();
  return (
    <x.div color="grey-5" display="flex" mt={spacing.layout.base02} flexDirection="column" alignItems="center">
      <Typography my={spacing.layout.base06} variant="h2">
        Contact Technical Support
      </Typography>
      <x.div display="inline-flex" mt={spacing.layout.base02} alignItems="center">
        <IconAds size="medium">
          <Mail />
        </IconAds>
        <Typography
          ml={spacing.layout.base}
          mb="2px"
          component="a"
          color={color.text.strong._}
          textDecoration="none"
          variant="body-large"
          href={`mailto:customersupport@alphasights.com?subject=Help with deactivated AlphaSights Portal`}
          target="_blank"
          rel="noopener noreferrer"
        >
          customersupport@alphasights.com
        </Typography>
      </x.div>
    </x.div>
  );
};

export const EnterPasswordForm = ({
  onSubmit,
  onChangeRememberMe,
  onClickResetPassword,
  onRequestMagicLink,
  onClickEmail,
  onClickLOU,
  email,
  isLoading,
  isRememberMeChecked,
  onClickHelp,
  onClickSignUp,
}) => {
  const {
    modalTitle,
    checkboxLabel,
    forgotPasswordLink,
    checkbox,
    signInButton,
    sendSignInLinkButton,
    checkboxDiv,
    emailInput,
  } = useLoginStyles();

  return (
    <FormContainer
      initialValues={{
        email,
        password: "",
      }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      {({ isValid }) => (
        <>
          <FormHeader
            title={
              <Typography variant="h3" {...modalTitle}>
                Sign In
              </Typography>
            }
            description={<EmailHeader email={email} onClickEmail={onClickEmail} />}
          />
          <FormBody>
            <FormContent>
              <x.input type="email" name="email" value={email} {...emailInput} />
              <PasswordField autoFocus validate={validatePasswordRequired} />
              <x.div {...checkboxDiv}>
                <CheckboxAds
                  checked={isRememberMeChecked}
                  onChange={onChangeRememberMe}
                  label={<Typography {...checkboxLabel}>Remember me</Typography>}
                  {...checkbox}
                />
                <Link onClick={onClickResetPassword} data-testid="forgot-pw-button" {...forgotPasswordLink}>
                  <Typography variant="body-em">Forgot password?</Typography>
                </Link>
              </x.div>
              <Button variant="secondary" type="submit" disabled={!isValid} {...signInButton}>
                <Typography variant="body-em">Sign In</Typography>
              </Button>
            </FormContent>
            <Button
              variant="outline"
              data-testid="magic-link-button"
              onClick={() => onRequestMagicLink({ email })}
              {...sendSignInLinkButton}
            >
              <Typography variant="body-em">Send Sign In Link</Typography>
            </Button>
          </FormBody>
          <FormFooter>
            <SignInFooter
              showLimitationsOfUse
              onClickLOU={onClickLOU}
              onClickHelp={onClickHelp}
              onClickSignUp={onClickSignUp}
              showExpertAccess
            />
          </FormFooter>
        </>
      )}
    </FormContainer>
  );
};

export const ResetPasswordForm = ({ onSubmit, email, error, isLoading, onClickResendResetPassword, onClickHelp }) => {
  const { modalTitle, validationMessage, passwordField, createPasswordButton } = useLoginStyles();

  const [validationRules, setValidationRules] = useState([]);

  useEffect(() => {
    getValidationRules().then((res) => setValidationRules(res));
  }, []);

  const processedError =
    error === "This request is expired." ? (
      <Typography component="div">
        The password reset link you are trying to use has expired. To resend a new link&nbsp;
        <Link component="button" onClick={onClickResendResetPassword}>
          <Typography variant="body-em">click here.</Typography>
        </Link>
      </Typography>
    ) : (
      error
    );

  return (
    <FormContainer
      initialValues={{
        email,
        password: "",
        passwordConfirmation: "",
      }}
      onSubmit={onSubmit}
      isLoading={isLoading}
      validate={(props) => validatePasswordForm({ ...props, validationRules, source: "reset" })}
    >
      {({ touched, errors }) => (
        <>
          <FormHeader
            title={
              <Typography variant="h3" {...modalTitle}>
                Reset password
              </Typography>
            }
            error={processedError}
          />
          <FormBody data-testid="reset-password-form">
            <FormContent>
              <PasswordField showErrorText={false} />
              <x.div {...passwordField}>
                <PasswordConfirmationField showErrorText={false} />
                <Typography variant="body-small" {...validationMessage}>
                  {!isEmpty(touched) && errors.password}
                </Typography>
              </x.div>
              <x.div {...passwordField}>
                <PasswordRulesList validationRules={validationRules} />
              </x.div>
              <Button variant="secondary" type="submit" isLoading={isLoading} {...createPasswordButton}>
                <Typography variant="body-em">Reset Password</Typography>
              </Button>
            </FormContent>
          </FormBody>
          <FormFooter>
            <SignInFooter showSignUp={false} onClickHelp={onClickHelp} />
          </FormFooter>
        </>
      )}
    </FormContainer>
  );
};
export const MagicLinkRequested = ({ email, onReturnToSignIn, onClickHelp }) => {
  const { modalTitle, modalDescription, backButton } = useLoginStyles();
  return (
    <FormContainer>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            Check your inbox
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            We just emailed a sign in link to {email}. If you did not receive an email, please click Help below.
          </Typography>
        }
      />
      <FormBody>
        <Button variant="outline" onClick={onReturnToSignIn} {...backButton}>
          <Typography variant="body-em">Return to Sign In</Typography>
        </Button>
      </FormBody>
      <FormFooter>
        <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
      </FormFooter>
    </FormContainer>
  );
};

export const ResetPasswordRequested = ({
  email,
  isLoading,
  onClickResetPassword,
  onClickReturnSignIn,
  onClickHelp,
  error,
}) => {
  const { returnSignInButton, modalTitle, modalDescription, buttonLink } = useLoginStyles();
  return (
    <FormContainer isLoading={isLoading}>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            Check your inbox
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            We just emailed a password reset link to {email}. Click the link in the email to reset your password.&nbsp;
            <Button onClick={() => onClickResetPassword({ resend: true })} variant="link" {...buttonLink}>
              <Typography variant="body-em">Didn’t receive the email? Resend.</Typography>
            </Button>
          </Typography>
        }
        error={error}
      />
      <FormBody>
        <Button onClick={onClickReturnSignIn} variant="outline" {...returnSignInButton}>
          <Typography variant="body-em">Return to Sign In</Typography>
        </Button>
      </FormBody>
      <FormFooter>
        <SignInFooter showSignUp={false} onClickHelp={onClickHelp} />
      </FormFooter>
    </FormContainer>
  );
};

export const AccountDeactivated = ({ email, onClickEmail }) => {
  const { spacing } = useThemeTokens(); // Use the hook at the top level of the component

  return (
    <FormContainer>
      <FormHeader
        title="Account Deactivated"
        description={
          <>
            <EmailHeader email={email} onClickEmail={onClickEmail} />
            <x.div mt={spacing.layout.base02}>
              Your account has been deactivated. Please contact our team to reactivate your account.
            </x.div>
          </>
        }
      />
      <ContactCustomerSupport />
    </FormContainer>
  );
};

export const AccountDeactivatedSignUp = ({ email, onClickEmail, onClickHelp }) => {
  const [isLoading, pointOfContact] = useApi(
    {
      url: `/api/auth-user/point-of-contact-by-email/${email}`,
      method: "GET",
    },
    [email]
  );

  const { modalTitle, deactivatedAccountMessage } = useLoginStyles();

  return (
    <FormContainer>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            Account deactivated
          </Typography>
        }
        description={
          <>
            <EmailHeader email={email} onClickEmail={onClickEmail} />
            {isLoading ? (
              <x.div>
                <Skeleton count={2} height="20px" />
              </x.div>
            ) : (
              <Typography {...deactivatedAccountMessage}>
                Your account has been deactivated. Please contact your account lead {pointOfContact?.name} to reactivate
                your account.
              </Typography>
            )}
          </>
        }
      />
      <FormBody>{isLoading ? <AccountLeadInfoSkeleton /> : <AccountLeadInfo lead={pointOfContact} />}</FormBody>
      <FormFooter>
        <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
      </FormFooter>
    </FormContainer>
  );
};

const AccountLeadInfo = ({ lead }) => {
  const { accountLeadContainer, accountLeadInfo, accountLeadInfoIcon } = useLoginStyles();

  if (!lead) return null;

  return (
    <x.div data-testid="lead-container" {...accountLeadContainer}>
      <x.div display="flex" justifyContent="center" alignItems="center" w="3rem" h="3rem">
        <ProjectLeadAvatar
          lead={{
            avatar: lead.avatar,
            name: lead.name,
          }}
        />
      </x.div>
      <x.div data-testid="account-lead-info" {...accountLeadInfo}>
        <x.div>
          <Typography>{lead.name}</Typography>
          <Typography color="secondary">Account Lead</Typography>
        </x.div>
        <Typography
          component="a"
          href={`mailto:${lead.email}`}
          style={{ wordBreak: "break-all" }}
          {...accountLeadInfoIcon}
        >
          <IconAds color="secondary" flexShrink={0}>
            <Mail />
          </IconAds>
          {lead.email}
        </Typography>
        <Typography component="a" href={`tel:${lead.mobileNumber}`} {...accountLeadInfoIcon}>
          <IconAds color="secondary">
            <MobilePhone />
          </IconAds>
          {lead.mobileNumber}
        </Typography>
      </x.div>
    </x.div>
  );
};

const AccountLeadInfoSkeleton = () => (
  <x.div display="flex">
    <x.div w="20%">
      <Skeleton circle={true} height="3rem" />
    </x.div>
    <Skeleton count={4} height="20px" />
  </x.div>
);

export const EmailHeader = ({ email, onClickEmail }) => {
  const { emailHeaderDiv, emailLabel, editEmailButton } = useLoginStyles();

  return (
    <x.div onClick={onClickEmail} cursor="pointer" {...emailHeaderDiv}>
      <Typography component="span" {...emailLabel}>
        {email}
      </Typography>
      <x.div {...editEmailButton}>
        <IconAds size="small" color="secondary">
          <Edit />
        </IconAds>
      </x.div>
    </x.div>
  );
};

export const LimitationOfUsage = ({ onClickReturn, onClickHelp, returnText = "Back" }) => {
  const { modalTitle, modalDescription, backButton } = useLoginStyles();

  return (
    <FormContainer>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            AlphaSights’ Limitations of Use
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            <LimitationOfUsageText />
          </Typography>
        }
      />
      <FormBody>
        <Button variant="outline" onClick={onClickReturn} {...backButton}>
          <Typography variant="body-em">{returnText}</Typography>
        </Button>
      </FormBody>
      <FormFooter>
        <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
      </FormFooter>
    </FormContainer>
  );
};

export const Help = ({ onClickReturn }) => {
  const { modalTitle, modalDescription, backButton } = useLoginStyles();

  return (
    <FormContainer>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            Help
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            {"Need help? Contact our team by sending an email to "}
            <Link
              href={`mailto:clientsupport@alphasights.com?subject=Help with AlphaSights Platform`}
              target="_blank"
              rel="noopener noreferrer"
            >
              clientsupport@alphasights.com
            </Link>
          </Typography>
        }
      />
      <FormBody>
        <Button variant="outline" onClick={onClickReturn} {...backButton}>
          <Typography variant="body-em">Back</Typography>
        </Button>
      </FormBody>
    </FormContainer>
  );
};

export const Login = ({
  onLogin,
  onContinue,
  onRedirect,
  allowAnonymousContinue,
  message,
  onSetStep,
  initialEmail,
  loginByLink,
  projectToken,
  initialStep,
}) => {
  const query = useQuery();
  const queryParams = useQueryParams();
  const portal = useClientPortalOperations();
  const currentUser = useCurrentUser();
  const isReady = useUserAuthIsReady();
  const isUserLoading = usePortalStoreSelector(getUserIsLoading);
  const [error, setError] = useState();
  const [previousStep, setPreviousStep] = useState();
  const [currentStep, setCurrentStep] = useState(initialStep ?? (loginByLink ? "link" : "email"));
  const [email, setEmail] = useState(initialEmail);
  const [isLoading, setIsLoading] = useState(isUserLoading);
  const [rememberMe, setRememberMe] = useState(true);
  const { isMobile } = useCheckScreen();
  const logger = useLogger();
  const { showSuccessBanner } = useNotifications();

  const setStep = (step) => {
    setPreviousStep(currentStep);
    setCurrentStep(step);
    onSetStep && onSetStep(step);
  };

  const onClickEmail = () => {
    setStep("email");
  };

  const step = currentStep;

  useEffect(() => {
    if (isReady && step === "email" && !currentUser) {
      setIsLoading(false);
    }
  }, [isReady, currentUser, step]);

  const handleErrorResponse = (message, setDefaultErrorCallback) => {
    const errorMapping = {
      "User is disabled": "deactivated",
      "User account has expired": "deactivated_inactivity",
      "User account is locked": "locked",
    };
    const step = errorMapping[message];
    if (step) {
      setStep(step);
    } else {
      setDefaultErrorCallback();
    }
  };

  const onSubmitEmail = async ({ email }, { setErrors }) => {
    setEmail(email);
    setErrors(null);
    setStep("password");
  };

  const onEnterPassword = async ({ email, password }, { setErrors }) => {
    try {
      const user = await portal.loginWithCredentials(email, password, rememberMe, {
        throwError: true,
      });
      logger.log("login success", user);
      onLogin && onLogin(user);
      setErrors(null);
    } catch (err) {
      const { message } = err.json ? await err.json() : err;

      logger.log("login failed", { message });

      if (message === "Bad credentials") {
        setErrors({
          password: "Your email and/or password is incorrect.",
        });
      } else {
        handleErrorResponse(message, () => {
          setErrors({ password: message });
        });
      }
    }
  };

  const onSignUp = async ({ email }, { setErrors }) => {
    try {
      await fetch({
        url: "/api/auth/sign-up", // TODO: move this and all "auth" endpoints to the client-api package
        method: "POST",
        body: JSON.stringify({
          email,
          projectToken,
          redirectUrl: query.get("next"),
        }),
        skipAlert: true,
      });
      setErrors(null);

      setEmail(email);
      setStep("sign-up-success");
    } catch (err) {
      const { message } = await err.json();
      setErrors({
        email: message || "An error occurred when trying to sign you up. Please contact support.",
      });
    }
  };

  const onRequestMagicLink = async ({ email }) => {
    try {
      await fetch({
        url: `/api/auth/magic-links`,
        method: "POST",
        body: JSON.stringify({ email, projectToken }),
        skipAlert: true,
      });

      setError(null);
      setStep("magic-link-requested");
    } catch (err) {
      const { message } = await err.json();
      handleErrorResponse(message, () => {
        setError(message);
      });
    }
  };

  const onClickResetPassword = async ({ resend = false }) => {
    try {
      await fetch({
        url: "/api/auth/password-reset-requests",
        method: "POST",
        body: JSON.stringify({ email }),
        skipAlert: true,
      });

      setError(null);
      setStep("reset-password-requested");
      if (resend) showSuccessBanner("Password reset email resent.");
    } catch (err) {
      const { message } = await err.json();
      handleErrorResponse(message, () => {
        setError(message);
      });
    }
  };

  const withLoading = (fun) => {
    return async (...args) => {
      setIsLoading(true);
      await fun(...args);
      setIsLoading(false);
    };
  };

  const onClickReturnSignIn = () => {
    setStep("email");
  };

  const onClickReturn = () => setStep(previousStep);

  const performRedirect = useRedirect({
    onRedirect,
    onContinue,
    mobileRedirect: isMobile,
  });

  useEffect(() => {
    if (isReady && currentUser && (step === "password" || step === "email")) {
      if (currentUser.redirectUrl) {
        queryParams.set("next", currentUser.redirectUrl);
      }
      performRedirect();
    }
  }, [currentUser, isMobile, isReady, onLogin, queryParams, step, performRedirect]);

  const onClickHelp = () => {
    setStep("help");
  };

  const onClickSignUp = () => {
    setStep("sign-up");
  };

  return (
    <SwitchTransition>
      <CSSTransition
        key={step}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames="LoginTransition"
      >
        <>
          {step === "email" && (
            <SignInForm
              isLoading={isLoading}
              onSubmit={withLoading(onSubmitEmail)}
              onContinue={onContinue}
              error={error}
              message={message}
              allowAnonymousContinue={allowAnonymousContinue}
              onClickHelp={onClickHelp}
              initialEmail={email}
              onClickSignUp={onClickSignUp}
            />
          )}
          {step === "link" && (
            <SignInForm
              isLoading={isLoading}
              onSubmit={withLoading(onSubmitEmail)}
              onContinue={onContinue}
              error={error}
              allowAnonymousContinue={allowAnonymousContinue}
              onClickHelp={onClickHelp}
              initialEmail={email}
              title="The link has already been used"
              message="This sign in link has already been used. Sign in below."
              showSignUp={false}
            />
          )}
          {step === "password" && (
            <EnterPasswordForm
              error={error}
              email={email}
              isLoading={isLoading}
              isRememberMeChecked={rememberMe}
              onChangeRememberMe={({ currentTarget: { checked } }) => setRememberMe(checked)}
              onRequestMagicLink={withLoading(onRequestMagicLink)}
              onClickEmail={onClickEmail}
              onSubmit={withLoading(onEnterPassword)}
              onClickResetPassword={withLoading(onClickResetPassword)}
              onClickLOU={() => setStep("lou")}
              onClickHelp={onClickHelp}
              onClickSignUp={onClickSignUp}
            />
          )}
          {step === "lou" && (
            <LimitationOfUsage
              onClickReturn={() => setStep("password")}
              onClickHelp={onClickHelp}
              returnText="Return to Sign In"
            />
          )}
          {step === "help" && <Help onClickReturn={onClickReturn} />}
          {step === "magic-link-requested" && (
            <MagicLinkRequested email={email} onReturnToSignIn={onClickEmail} onClickHelp={onClickHelp} />
          )}
          {step === "reset-password-requested" && (
            <ResetPasswordRequested
              error={error}
              email={email}
              isLoading={isLoading}
              onClickResetPassword={withLoading(onClickResetPassword)}
              onClickReturnSignIn={onClickReturnSignIn}
              onClickHelp={onClickHelp}
            />
          )}
          {step === "sign-up" && (
            <SignUpForm
              error={error}
              isLoading={isLoading}
              onSubmit={withLoading(onSignUp)}
              onClickHelp={onClickHelp}
              onClickSignIn={onClickReturnSignIn}
              initialEmail={email}
            />
          )}
          {step === "sign-up-success" && <SignUpSuccess email={email} onClickHelp={onClickHelp} />}
          {step === "deactivated" && (
            <AccountDeactivatedSignUp email={email} onClickEmail={onClickReturnSignIn} onClickHelp={onClickHelp} />
          )}
          {["deactivated_inactivity", "locked"].includes(step) && (
            <Redirect
              to={{
                pathname: "/deactivated-account",
                state: { email, step: step },
              }}
            />
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

export const ProtectedRoute = ({
  component: Component,
  requiredBadges = [],
  isDisabled = false,
  disabledRedirectTo = "/my-projects",
  ...props
}) => {
  const isReady = useUserAuthIsReady();
  const currentUser = useCurrentUser();
  const portalStoreState = usePortalStore();

  if (!isReady) return <LoadingOverlay />;

  if (!currentUser) {
    const next = props.location.pathname + props.location.search;
    return <Redirect to={"/sign-in?" + new URLSearchParams({ next })} />;
  }

  if (isDisabled || requiredBadges.find((badge) => !currentUser.badges?.includes(badge)))
    return <Redirect {...props} to={disabledRedirectTo} />;

  return (
    <>
      <Route {...props} component={Component} />
      <CookiePolicyModal portalStoreState={portalStoreState} />
    </>
  );
};
