import React, { useEffect } from "react";
import { AlphaGPTProvider } from "providers/AlphaGPTProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { AlphaGPTConversation } from "./components/AlphaGPTConversation/AlphaGPTConversation";
import { AlphaGPTSidebar } from "./components/AlphaGPTSidebar";
import styled from "@xstyled/styled-components";
import { AlphaNowContentContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import useContentAccessLevel from "pages/AlphaNowPage/hooks/useContentAccessLevel";

export const AlphaGPTPage = () => {
  const trackUserAction = useTrackUserAction();

  useEffect(() => {
    trackUserAction.logHit({
      origin: "alpha-gpt-page",
      action: "ALPHA_GPT_VIEW_PAGE",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contentAccessLevel = useContentAccessLevel();

  return (
    <Container>
      <AlphaGPTProvider>
        <AlphaNowContentContext.Provider value={contentAccessLevel}>
          <AlphaGPTSidebar />
          <AlphaGPTConversation />
        </AlphaNowContentContext.Provider>
      </AlphaGPTProvider>
    </Container>
  );
};

const Container = styled.div(() => ({
  display: "flex",
  flex: "1 1 auto",
  "flex-direction": "row",
  "min-height": "0",
}));
