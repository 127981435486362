import { useState } from "react";

export const useTimeoutToggle = ({ initialValue = false, delay = 4000 } = {}) => {
  const [value, setValue] = useState(initialValue);
  const [lastTimeout, setLastTimeout] = useState();

  const toggle = () => {
    if (lastTimeout) clearTimeout(lastTimeout);

    const timeout = setTimeout(() => {
      setValue(false);
    }, delay);

    setValue(true);
    setLastTimeout(timeout);
  };

  return [value, toggle];
};
