import React, { ChangeEvent, FC } from "react";
import {
  Icon,
  Loading,
  SelectMenu,
  SelectOption,
  SelectValue,
  TextField,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import {
  alertFrequencyTitle,
  deliveryDayTitle,
  deliveryTimeTitle,
  deliveryTimeZoneTitle,
  monthlyAlertFrequencyDetails,
  title,
  WATCHLIST_DELIVERY_DAY_DISPLAY_NAME,
  WATCHLIST_FREQUENCY_DISPLAY_NAME,
} from "components/WatchlistModal/consts";
import { UpdateWatchlistSettingsPayload, WATCHLIST_DELIVERY_DAY, WATCHLIST_FREQUENCY } from "hooks/useWatchlist";
import { x } from "@xstyled/styled-components";
import { TextFieldContainer, WatchlistSettingsContainer } from "./styled";
import {
  generateWatchlistDeliveryTime,
  getParsedDeliveryTime,
  getUpdatedDeliverySettings,
} from "components/WatchlistModal/utils";
import { getGmtTimezones } from "providers/TimezoneProvider";
import { Info } from "@alphasights/alphadesign-icons";
import { AlertFrequencyContainer } from "./styled";
import { LoadingContainer } from "../EditWatchlistView/EditWatchlistView.styled";

const DataTestIds = {
  alertFrequencyTooltip: "alert-frequency-tooltip",
  watchlistNameSelect: "watchlist-name-select",
  deliveryFrequencySelect: "delivery-frequency-select",
  deliveryDaySelect: "delivery-day-select",
  deliveryTimeSelect: "delivery-time-select",
  deliveryTimeZoneSelect: "delivery-timezone-select",
};

type WatchlistSettingsViewProps = {
  watchlistSettings: UpdateWatchlistSettingsPayload;
  onSettingsChange: React.Dispatch<React.SetStateAction<UpdateWatchlistSettingsPayload>>;
  isLoading: boolean;
};

const AlertFrequencyLabel = ({ alertFrequency }: { alertFrequency: WATCHLIST_FREQUENCY }) => (
  <AlertFrequencyContainer>
    <Typography variant="body-small">{alertFrequencyTitle}</Typography>
    {alertFrequency === WATCHLIST_FREQUENCY.monthly && (
      <Tooltip title={monthlyAlertFrequencyDetails} position="right" zIndex={1000} size="small">
        <Icon size="small" dataAttributes={{ "data-testid": DataTestIds.alertFrequencyTooltip }}>
          <Info />
        </Icon>
      </Tooltip>
    )}
  </AlertFrequencyContainer>
);

const orderedFrequencyOptions = [
  WATCHLIST_FREQUENCY.immediate,
  WATCHLIST_FREQUENCY.daily,
  WATCHLIST_FREQUENCY.weekly,
  WATCHLIST_FREQUENCY.monthly,
];

const orderedDayOptions = [
  WATCHLIST_DELIVERY_DAY.monday,
  WATCHLIST_DELIVERY_DAY.tuesday,
  WATCHLIST_DELIVERY_DAY.wednesday,
  WATCHLIST_DELIVERY_DAY.thursday,
  WATCHLIST_DELIVERY_DAY.friday,
];

const renderFrequencyOptions = () =>
  orderedFrequencyOptions.map((frequency) => (
    <SelectOption key={frequency} value={frequency}>
      {WATCHLIST_FREQUENCY_DISPLAY_NAME[frequency]}
    </SelectOption>
  ));

const renderDayOptions = () =>
  orderedDayOptions.map((deliveryDay) => (
    <SelectOption key={deliveryDay} value={deliveryDay}>
      {WATCHLIST_DELIVERY_DAY_DISPLAY_NAME[deliveryDay]}
    </SelectOption>
  ));

const renderTimeOptions = () =>
  generateWatchlistDeliveryTime().map((time) => (
    <SelectOption key={time} value={time}>
      {getParsedDeliveryTime(time)}
    </SelectOption>
  ));

const renderTimezoneOptions = () =>
  getGmtTimezones().map((gmtTimezone, index) => (
    <SelectOption key={index} value={gmtTimezone.timezone}>
      {gmtTimezone.formattedTimezone}
    </SelectOption>
  ));

const WatchlistSettingsView: FC<WatchlistSettingsViewProps> = ({ watchlistSettings, onSettingsChange, isLoading }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const isWatchlistNameEmpty = watchlistSettings.name.length === 0;
  const watchlistDeliveryFrequency = watchlistSettings.frequency;
  const showDeliverySettings = watchlistDeliveryFrequency !== WATCHLIST_FREQUENCY.immediate;
  const showDeliveryDayDropdown = watchlistDeliveryFrequency === WATCHLIST_FREQUENCY.weekly;

  const handleUpdateWatchlistSettings = (newData: Partial<UpdateWatchlistSettingsPayload>) => {
    onSettingsChange((prevState: UpdateWatchlistSettingsPayload) => ({ ...prevState, ...newData }));
  };

  const handleUpdateWatchlistFrequency = (deliveryFrequency: SelectValue | SelectValue[]) => {
    onSettingsChange((prevState: UpdateWatchlistSettingsPayload) =>
      getUpdatedDeliverySettings(prevState, deliveryFrequency as WATCHLIST_FREQUENCY)
    );
  };

  if (isLoading)
    return (
      <LoadingContainer>
        <Loading size="sm" />
      </LoadingContainer>
    );

  return (
    <WatchlistSettingsContainer>
      <TextFieldContainer>
        <TextField
          dataAttributes={{ "data-testid": DataTestIds.watchlistNameSelect }}
          label={title}
          required
          error={isWatchlistNameEmpty}
          size="small"
          value={watchlistSettings.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateWatchlistSettings({ name: e.target.value })}
        />
      </TextFieldContainer>
      <SelectMenu
        size="small"
        dataAttributes={{ "data-testid": DataTestIds.deliveryFrequencySelect }}
        label={<AlertFrequencyLabel alertFrequency={watchlistDeliveryFrequency} />}
        value={watchlistDeliveryFrequency}
        onChange={handleUpdateWatchlistFrequency}
      >
        {renderFrequencyOptions()}
      </SelectMenu>
      {showDeliverySettings && (
        <>
          {showDeliveryDayDropdown && (
            <SelectMenu
              size="small"
              dataAttributes={{ "data-testid": DataTestIds.deliveryDaySelect }}
              label={deliveryDayTitle}
              value={watchlistSettings.deliveryDayOfWeek as WATCHLIST_DELIVERY_DAY}
              onChange={(day) => handleUpdateWatchlistSettings({ deliveryDayOfWeek: day as WATCHLIST_DELIVERY_DAY })}
            >
              {renderDayOptions()}
            </SelectMenu>
          )}
          <x.div display="flex" flexDirection="row" gap={inner.base02}>
            <SelectMenu
              size="small"
              dataAttributes={{ "data-testid": DataTestIds.deliveryTimeSelect }}
              label={deliveryTimeTitle}
              value={watchlistSettings.deliveryTime as string}
              onChange={(time) => handleUpdateWatchlistSettings({ deliveryTime: time as string })}
              scrollToSelectedOnOpen
            >
              {renderTimeOptions()}
            </SelectMenu>
            <SelectMenu
              size="small"
              dataAttributes={{ "data-testid": DataTestIds.deliveryTimeZoneSelect }}
              label={deliveryTimeZoneTitle}
              value={watchlistSettings.deliveryTimeZone as string}
              onChange={(timezone) => handleUpdateWatchlistSettings({ deliveryTimeZone: timezone as string })}
              scrollToSelectedOnOpen
            >
              {renderTimezoneOptions()}
            </SelectMenu>
          </x.div>
        </>
      )}
    </WatchlistSettingsContainer>
  );
};

export { WatchlistSettingsView as default, DataTestIds };
