import React from "react";
import { Alert, Button, Modal, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import {
  legalDisclaimerFirstParagraph,
  legalDisclaimerSecondParagraph,
  legalDisclaimerAcknowledgement,
  disclaimer,
  legalDisclaimerText,
  agreeAndEnter,
} from "content/AlphaNow";
import { auth } from "@alphasights/portal-api-client";

const LegalDisclaimerModal = () => {
  const acceptDisclaimer = auth.acceptAlphaNowLegalDisclaimer;

  return (
    <Modal open showCloseButton={false} slotWidth="520px" slotHeight="unset">
      <x.div spaceY="700" color="neutral900">
        <x.img w="280px" m="0 auto" pt="300" src={require("../../images/alphanow-beta-logo.png")} />
        <x.div spaceY="700">
          <x.div spaceY="500">
            <Typography>{legalDisclaimerFirstParagraph}</Typography>
            <Typography>{legalDisclaimerSecondParagraph}</Typography>
          </x.div>
          <Alert variant="info">
            <Typography component="span">{legalDisclaimerAcknowledgement}</Typography>
          </Alert>
          <x.div color="neutral700" data-testid="disclaimer-text" lineHeight="400">
            <Typography variant="body-small-em" component="span">
              {disclaimer}
            </Typography>
            <Typography variant="body-small" component="span">
              {legalDisclaimerText}
            </Typography>
          </x.div>
          <Button variant="primary" onClick={acceptDisclaimer} style={{ width: "100%" }}>
            {agreeAndEnter}
          </Button>
        </x.div>
      </x.div>
    </Modal>
  );
};

export default LegalDisclaimerModal;
