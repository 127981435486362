import { useTheme } from "@alphasights/alphadesign-components";

export const useEditQuestionsModalStyles = ({ disableDelete }: { disableDelete?: boolean } = {}) => {
  const {
    tokens: { spacing },
  } = useTheme();

  const modalTitle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    mr: spacing.inner.base04,
  };

  const clearChangesButton = {
    marginRight: spacing.inner.base04,
  };

  const questionsDiv = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.inner.base05,
  };

  const addedQuestionsDiv = {
    display: "flex",
    gap: spacing.inner.base02,
    alignItems: "end",
  };

  const questionListOption = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    w: "100%",
  };

  const textContainer = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flex: "1",
    marginRight: spacing.inner.base02,
  };

  const addQuestionButton = {
    w: "fit-content",
  };

  const addQuestionAlert = {
    w: "100%",
  };

  const questionTextField = {
    w: "100%",
  };

  const deleteQuestionIcon = {
    color: disableDelete ? "disabled" : "secondary",
  };

  const anglesPopover = {
    marginTop: `-${spacing.inner.base}`,
  };

  return {
    modalTitle,
    clearChangesButton,
    questionsDiv,
    addedQuestionsDiv,
    questionListOption,
    addQuestionButton,
    addQuestionAlert,
    questionTextField,
    deleteQuestionIcon,
    anglesPopover,
    textContainer,
  };
};
