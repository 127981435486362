import React from "react";
import { Typography, Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { OutlookProperties } from "../../types/outlook";
import OutlookContent from "./OutlookContent";

const OutlookSection = ({
  title,
  content,
  headerItems,
  hasDivider,
}: {
  title: string;
  content: OutlookProperties[];
  headerItems: string[];
  hasDivider?: boolean;
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <>
      <x.div display="flex" flexDirection="column" px={inner.base10} gap={inner.base04}>
        <Typography variant="body-large-em" color={text.secondary}>
          {title}
        </Typography>
        <OutlookContent content={content} headerItems={headerItems} isGrowthDrivers={title === "GROWTH DRIVERS"} />
      </x.div>
      {hasDivider && <Divider />}
    </>
  );
};

export default OutlookSection;
