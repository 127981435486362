import React, { useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { DeliverablesCards } from "views/DeliverablesView/Cards";
import { IconButton, ListOption, MobileTopBar } from "@alphasights/alphadesign-components";
import { ArrowLeft, BookmarkSelected, BookmarkUnselected, MoreVert, Search } from "@alphasights/alphadesign-icons";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import deliverablesService from "views/DeliverablesView/deliverablesService";
import { ModeratedContentInfo, ModeratedContentRenderer } from "./MobileModeratedContent.component";
import { PrimerRenderer } from "./MobilePrimer.component";
import styled from "styled-components";
import { ContentSuggestion } from "views/DeliverablesView/types";

export const ContentList = ({
  contentList,
  onSelect,
  privateContent,
  pitchedContent,
}: {
  contentList: ContentSuggestion[];
  privateContent: ContentSuggestion[];
  pitchedContent: ContentSuggestion[];
  onSelect: (c: ContentSuggestion) => void;
}) => (
  <x.div data-testid="content-list">
    <DeliverablesCards
      order={["privateContent", "pitchedContent", "suggestions"]}
      alphaNowContent={{ suggestedContent: contentList, privateContent, pitchedContent }}
      onSelect={onSelect}
    />
  </x.div>
);

export const MobileContentView = ({
  content,
  onClose,
  freeSamples,
  updateContent,
  project,
}: {
  content: ContentSuggestion;
  freeSamples: any[];
  onClose: () => void;
  updateContent: (id: string, updates: any) => void;
  project: Project;
}) => {
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const { fullScreenFixedWrapper, stickyAtTheTop, flexColumn } = useMobileDeliverablesStyles();
  const [keywords, setKeywords] = useState<string[]>([]);
  const purchasedPrimer = !!content.primer && !content.paymentRequired;
  const topBarButtonAreaRef = useRef(null);
  return (
    <x.div {...fullScreenFixedWrapper} {...flexColumn} data-testid="mobile-content-view">
      <x.div {...stickyAtTheTop}>
        <ContentTopBar
          onViewMore={() => setViewMoreOpen(true)}
          onClose={onClose}
          content={content}
          onSearch={!content.paymentRequired && !content.primer ? setKeywords : undefined}
          hideBorder={purchasedPrimer}
          topBarButtonAreaRef={topBarButtonAreaRef}
        />
      </x.div>
      {content.isPrimer ? (
        <PrimerRenderer
          content={content}
          freeSamples={freeSamples}
          updateContent={updateContent}
          topBarButtonAreaRef={topBarButtonAreaRef}
        />
      ) : (
        <ModeratedContentRenderer
          content={content}
          updateContent={updateContent}
          project={project}
          keywords={keywords}
        />
      )}
      {viewMoreOpen && (
        <ContentViewMore
          content={content}
          onClose={() => setViewMoreOpen(false)}
          project={project}
          updateContent={updateContent}
        />
      )}
    </x.div>
  );
};

const ContentViewMore = ({
  onClose,
  content,
  updateContent,
  project,
}: {
  onClose: () => void;
  content: ContentSuggestion;
  updateContent: (id: string, updates: any) => void;
  project: Project;
}) => {
  const { fullScreenFixedWrapper, stickyAtTheTop, contentWrapper, flexColumn } = useMobileDeliverablesStyles();

  const Bookmarked = content.bookmarked ? BookmarkSelected : BookmarkUnselected;
  const onToggleBookmark = (bookmarked: boolean) => {
    const toggle = bookmarked
      ? deliverablesService.deleteContentBookmark(project.token, content.id)
      : deliverablesService.postContentBookmark(project.token, content.id);
    toggle.then(() => updateContent(content.id, { bookmarked: !bookmarked }));
  };
  return (
    <x.div {...fullScreenFixedWrapper} {...flexColumn} data-testid="mobile-content-view-more">
      <x.div {...stickyAtTheTop}>
        <ContentTopBar hideTitle onClose={onClose} content={content} />
      </x.div>
      <x.div {...contentWrapper}>
        {!content.primer && <ModeratedContentInfo content={content} />}
        <x.div>
          <ListOption
            dataAttributes={{ "data-testid": "view-more-bookmark-btn" }}
            type="text"
            leftIcon={<Bookmarked />}
            label={content.bookmarked ? "Unbookmark Transcript" : "Bookmark Transcript"}
            onChange={() => onToggleBookmark(content.bookmarked)}
            size="small"
          />
          <DownloadButton
            size="small"
            asListOption
            contentId={content.id}
            projectToken={project.token}
            downloadFileName={content.downloadFileName}
            label="Download Transcript"
          />
        </x.div>
      </x.div>
    </x.div>
  );
};

export const ContentTopBar = ({
  content,
  onClose,
  onViewMore = undefined,
  hideTitle = false,
  onSearch = undefined,
  hideBorder = false,
  topBarButtonAreaRef = undefined,
}: {
  content: ContentSuggestion;
  onClose: () => void;
  onViewMore?: () => void;
  onSearch?: (vals: string[]) => void;
  hideTitle?: boolean;
  hideBorder?: boolean;
  topBarButtonAreaRef?: any;
}) => {
  const [topBarVariant, setTopBarVariant] = useState("regular");
  const [keywords, setKeywords] = useState<string[]>([]);

  const title =
    content.externalTitle ||
    (content.anchorCompanies?.length && content.anchorCompanies[0].companyName) ||
    content.companies.find((c) => c.companyType === "ANCHOR")?.companyName ||
    content.companies[0]?.companyName ||
    "Content";
  const showMore = !!onViewMore && !content.paymentRequired;

  return (
    <MobileTopBarOptionalBottomBorder
      noBorder={hideBorder}
      title={hideTitle ? "" : title}
      mainButton={
        <IconButton size="large" variant="ghost" onClick={onClose} testId="close-content-btn">
          <ArrowLeft />
        </IconButton>
      }
      // @ts-ignore
      variant={topBarVariant}
      onSearchClose={() => setTopBarVariant("regular")}
      onKeywordsMatch={
        !!onSearch
          ? (keywords: string[]) => {
              onSearch(keywords);
              setKeywords(keywords);
            }
          : undefined
      }
      keywords={keywords}
      autoFocusSearch
      regularButtons={
        <>
          {topBarButtonAreaRef && <x.div display="flex" ref={topBarButtonAreaRef}></x.div>}
          {!!onSearch ? (
            <IconButton
              size="large"
              variant="ghost"
              onClick={() => setTopBarVariant("search")}
              testId="mobile-top-bar-content-search-btn"
            >
              <Search />
            </IconButton>
          ) : undefined}
          {showMore ? (
            <IconButton size="large" variant="ghost" onClick={onViewMore} testId="mobile-content-view-more-btn">
              <MoreVert />
            </IconButton>
          ) : undefined}
        </>
      }
    />
  );
};

export const MobileTopBarOptionalBottomBorder = styled(MobileTopBar)`
  ${({ noBorder = false }: { noBorder?: boolean }) => (noBorder ? "border-bottom: none" : "")}
`;
