import React from "react";
import { x } from "@xstyled/styled-components";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CompanyPrimerSectionCard } from "pages/AlphaNowPage/primers/CompanyPrimer/components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { CustomerDynamicsLabels } from "models/primers/constants";
import CustomerDynamicsSectionRow from "./CustomerDynamicsSectionRow";
import CustomerDynamicsMarkdownSection from "./CustomerDynamicsMarkdownSection";
import styles from "./styles/DynamicSection.module.css";

type CustomerDynamicsPropTypes = {
  customerDynamics: CitableCustomerDynamics;
};

const CustomerDynamicsSectionV1 = ({ customerDynamics }: CustomerDynamicsPropTypes) => {
  const {
    spacing: { inner },
    shape,
    color: { border, text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const hasRevenueSplit = customerDynamics.segments.some((segment) => Boolean(segment.revenueSplit.value));
  const hasKeyCustomers = customerDynamics.segments.some((segment) => Boolean(segment.keyCustomers.value));
  const headerItems = [
    {
      label: CustomerDynamicsLabels.SEGMENT,
    },
    {
      label: hasRevenueSplit ? CustomerDynamicsLabels.REVENUE_SPLIT : "",
    },
    {
      label: CustomerDynamicsLabels.PENETRATION,
    },
    {
      label: CustomerDynamicsLabels.TREND,
    },
    {
      label: hasKeyCustomers ? CustomerDynamicsLabels.KEY_CUSTOMERS : "",
    },
  ];
  const numberOfColumns = headerItems.filter(({ label }) => label.length > 0).length;

  const DynamicsDesktopContent = () => {
    return (
      <>
        <x.div
          className={styles[`segment${numberOfColumns}Columns`]}
          display="grid"
          columnGap="10px"
          w="100%"
          mb={inner.base06}
        >
          {headerItems
            .filter(({ label }) => label.length > 0)
            .map((item) => {
              return (
                <Typography component="div" color={text.secondary} variant={isMobile ? "body-em" : "body-small-em"}>
                  {item.label}
                </Typography>
              );
            })}
        </x.div>
        <Divider />
        <x.div
          marginTop={inner.base03}
          marginBottom={inner.base06}
          spaceY={{ xs: 0, sm: inner.base03 }}
          borderTop={{
            xs: {
              first: `${shape.border.width.sm} solid ${border.divider}`,
            },
            sm: { first: 0 },
          }}
        >
          {customerDynamics.segments.map((segmentItem, index) => {
            const { segment, revenueSplit, penetration, trend, keyCustomers } = segmentItem;

            return (
              <>
                <CustomerDynamicsSectionRow
                  hasRevenueSplit={hasRevenueSplit}
                  hasKeyCustomers={hasKeyCustomers}
                  key={index.toString()}
                  segment={segment}
                  revenueSplit={revenueSplit}
                  penetration={penetration}
                  trend={trend}
                  keyCustomers={keyCustomers}
                  numberOfColumns={numberOfColumns}
                />
                <Divider />
              </>
            );
          })}
        </x.div>
      </>
    );
  };

  return (
    <CompanyPrimerSectionCard title="Customer Dynamics">
      {isMobile ? (
        customerDynamics.segments.map((segmentItem, index) => {
          const { segment, revenueSplit, penetration, trend, keyCustomers } = segmentItem;

          return (
            <CustomerDynamicsSectionRow
              key={index.toString()}
              segment={segment}
              revenueSplit={revenueSplit}
              penetration={penetration}
              trend={trend}
              keyCustomers={keyCustomers}
              hasRevenueSplit={hasRevenueSplit}
              hasKeyCustomers={hasKeyCustomers}
              numberOfColumns={numberOfColumns}
            />
          );
        })
      ) : (
        <DynamicsDesktopContent />
      )}
      <x.div mt={inner.base06}>
        <CustomerDynamicsMarkdownSection
          companyPrimerOverview={{
            description: customerDynamics.customersDescription,
            goToMarket: customerDynamics.goToMarket,
            monetization: customerDynamics.monetization,
          }}
        />
      </x.div>
    </CompanyPrimerSectionCard>
  );
};

export default CustomerDynamicsSectionV1;
