import React, { ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, XStyledAndHTMLProps } from "@alphasights/alphadesign-components";
import Card from "components/Card";

type FilterPopupPropsType = {
  children: ReactNode | ReactNode[];
} & XStyledAndHTMLProps<HTMLDivElement>;

const FilterPopup = ({ children, ...props }: FilterPopupPropsType) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return (
    <Card
      position="absolute"
      mt={inner.base}
      p="0"
      boxShadow="dark"
      zIndex="20"
      right={{ xs: "0", sm: "unset" }}
      {...props}
    >
      <x.div px={inner.base06} pt={inner.base06} mb={inner.base06}>
        {children}
      </x.div>
    </Card>
  );
};

export default FilterPopup;
