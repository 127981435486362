import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useInteractionsFilterStyles = () => {
  const {
    color: { text, border, background },
    spacing: { layout, inner },
    shape,
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    background: background.surface.page.default,
    position: "sticky",
    overflowY: "auto",
    overflowX: "hidden",
    top: 0,
    transition: "height 0.2s ease-in-out",
    minWidth: "360px",
    borderRight: `${shape.border.width.sm} solid ${border.divider}`,
    h: "100%",
  };

  const contentWrapper = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const headerWrapper = {
    display: "flex",
    py: layout.base03,
    px: layout.base04,
    position: "sticky",
    top: 0,
    background: background.surface.page.default,
    zIndex: 1,
    alignItems: "center",
  };

  const buttonsWrapper = {
    display: "flex",
    py: layout.base03,
    px: layout.base04,
    position: "sticky",
    bottom: 0,
    background: background.surface.page.default,
    justifyContent: "space-between",
  };

  const divider = {
    borderBottom: `${shape.border.width.sm} solid ${border.divider}`,
    mt: inner.base02,
    mb: inner.base04,
  };

  const filterLabelOnly = {
    color: text.secondary,
    flexGrow: 1,
  };

  const closeIcon = {
    cursor: "pointer",
  };

  const clearAll = {
    color: text.secondary,
  };

  const filterAccordionItem = {
    px: layout.base04,
  };

  return {
    wrapper,
    contentWrapper,
    headerWrapper,
    buttonsWrapper,
    divider,
    filterLabelOnly,
    closeIcon,
    clearAll,
    filterAccordionItem,
  };
};
