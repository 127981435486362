import React from "react";
import { Button, Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { COMMISSION_BUTTON_CANCEL, COMMISSION_BUTTON_SUBMIT } from "../constants";

export const testIds = {
  submitCommission: "submit-commission",
};

const CommissionModalFooter = ({
  totalCredits,
  isEditing,
  onConfirm,
  onClose,
}: {
  totalCredits: number;
  isEditing: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <>
      <Divider />
      <x.div display="flex" justifyContent="space-between" padding={inner.base06} alignItems="center">
        <Typography color={text.secondary} variant="body-small">
          {`${totalCredits} credits`}
        </Typography>
        <x.div display="flex" gap={inner.base04}>
          <Button variant="ghost" onClick={onClose}>
            {COMMISSION_BUTTON_CANCEL}
          </Button>
          <Button
            disabled={isEditing}
            variant="secondary"
            size="small"
            onClick={onConfirm}
            dataAttributes={{ "data-testid": testIds.submitCommission }}
          >
            {COMMISSION_BUTTON_SUBMIT}
          </Button>
        </x.div>
      </x.div>
    </>
  );
};

export default CommissionModalFooter;
