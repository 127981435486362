import React, { FC, useCallback } from "react";
import {
  ListOptionProps,
  SelectItem,
  SelectMenu,
  SelectOption,
  SelectValue,
  Typography,
} from "@alphasights/alphadesign-components";
import { WATCHLIST_FREQUENCY_DISPLAY_NAME } from "../consts";
import { INITIAL_WATCHLIST_DATA, INITIAL_WATCHLIST_PAYLOAD } from "hooks/useWatchlist/consts";
import { PlaceholderWatchlistDataProps, WatchlistDataProps } from "hooks/useWatchlist";
import { getSelectedWatchlist } from "components/WatchlistModal/utils";
import CreateWatchlistButton from "components/WatchlistModal/CreateWatchlistButton";

const DataTestIds = {
  watchlistSelect: "watchlist-select",
  watchlistOption: "watchlist-option",
};

interface WatchlistSelectProps {
  availableWatchlists: WatchlistDataProps[];
  watchlistId?: string;
  onChange: (watchlistId: string) => void;
}

const WatchlistSelect: FC<WatchlistSelectProps> = ({ availableWatchlists, watchlistId, onChange }) => {
  const hasWatchlists = availableWatchlists.length > 0;
  const defaultWatchlists = hasWatchlists
    ? availableWatchlists
    : ([INITIAL_WATCHLIST_PAYLOAD] as WatchlistDataProps[] | PlaceholderWatchlistDataProps[]);

  const renderSelectedWatchlistInput = useCallback(
    (selectedItems: SelectItem[]) => {
      const watchlistId = selectedItems[0]?.value;
      const selectedWatchlist = hasWatchlists
        ? getSelectedWatchlist(availableWatchlists, watchlistId as string)
        : INITIAL_WATCHLIST_DATA;

      return (
        <>
          <Typography component="span" variant="body-small">
            {selectedWatchlist?.name}
          </Typography>
          <Typography component="span" variant="body-small" color="secondary" pl="2px">
            • {WATCHLIST_FREQUENCY_DISPLAY_NAME[selectedWatchlist?.frequency]}
          </Typography>
        </>
      );
    },
    [availableWatchlists, hasWatchlists]
  );

  const handleSelectWatchlist = (value: SelectValue | SelectValue[]) => {
    hasWatchlists && onChange(value as string);
  };

  return (
    <SelectMenu
      isClearButtonEnabled={false}
      size="small"
      customSelectedItemRendererInInput={renderSelectedWatchlistInput}
      dataAttributes={{ "data-testid": DataTestIds.watchlistSelect }}
      maxHeight="300px"
      value={watchlistId ?? INITIAL_WATCHLIST_DATA.name}
      onChange={handleSelectWatchlist}
      customFooter={<CreateWatchlistButton hasWatchlists={hasWatchlists} watchlistData={defaultWatchlists} />}
    >
      {defaultWatchlists.map(({ id, name, frequency }, index) => (
        <SelectOption
          key={index}
          label={name}
          value={id ?? INITIAL_WATCHLIST_DATA.name}
          data-testid={DataTestIds.watchlistOption}
          listOptionAccessories={
            {
              type: "text",
              secondaryText: WATCHLIST_FREQUENCY_DISPLAY_NAME[frequency],
              secondaryTextMarginLeft: "auto",
            } as ListOptionProps
          }
        >
          <Typography variant="body-small">{name}</Typography>
        </SelectOption>
      ))}
    </SelectMenu>
  );
};

export { WatchlistSelect as default, DataTestIds };
