import { useState, useCallback, useEffect, useMemo } from "react";
import { requestChangeInteractionFn } from "components/InteractionsPage/reducer";
import { interactions as interactionsApi } from "@alphasights/portal-api-client";
import { useAlphaToast } from "@alphasights/alphadesign-components";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

type UseRequestForProjectProps = {
  project?: any;
  contents?: { speakers: Speaker[] }[];
  speakers?: Speaker[];
  setCanRequestForProject?: (value: boolean) => void;
};

// Convert speaker to option format
const speakerToOption = ({ speakerId, company, jobTitle }: Speaker) => ({
  id: speakerId,
  description: `${company} - ${jobTitle}`,
});

const useRequestForProject = ({ contents, speakers, setCanRequestForProject }: UseRequestForProjectProps) => {
  const { toast } = useAlphaToast();
  const [alreadyRequested, setAlreadyRequested] = useState<number[]>([]); // This is an array of speaker IDs
  const [submitting, setSubmitting] = useState(false);
  const [clientRequests, setClientRequests] = useState<any[]>([]);
  const [isRequestExpertLoading, setIsRequestExpertLoading] = useState(false);
  const { project } = useCurrentProjectContext();

  const trackUserAction = useTrackUserAction();
  useEffect(() => {
    const fetchRequests = async () => {
      if (!project) return;

      setIsRequestExpertLoading(true);
      try {
        const requests = await interactionsApi.fetchClientRequests({
          projects: [project],
        });
        setClientRequests(requests);
      } catch (error) {
        console.error("Failed to fetch client requests:", error);
      } finally {
        setIsRequestExpertLoading(false);
      }
    };

    fetchRequests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const speakerIds = contents
      ? contents.flatMap((c) => c.speakers).map((sp) => sp.speakerId)
      : speakers?.map((sp) => sp.speakerId);
    const pendingClientRequests = clientRequests
      .filter((req) => req.type === "ADD_SINGLE_EXPERT_IN_PROJECT_REQUEST")
      .map((req) => req.payload.interactionId)
      .filter((id) => speakerIds?.includes(id));
    setAlreadyRequested(pendingClientRequests);
    setCanRequestForProject && speakers && setCanRequestForProject(speakers?.length > pendingClientRequests.length);
  }, [clientRequests, speakers, contents, setCanRequestForProject]);

  const validSpeakers = useMemo(() => {
    return speakers?.filter(({ speakerId, isModerator }) => !alreadyRequested.includes(speakerId) && !isModerator);
  }, [speakers, alreadyRequested]);

  useEffect(() => {
    if (alreadyRequested.length) {
      setCanRequestForProject?.(!!validSpeakers?.length);
    }
  }, [alreadyRequested, validSpeakers, setCanRequestForProject]);

  const submit = useCallback(
    async (selectedExperts: number[], reason: string) => {
      setSubmitting(true);
      const expertsToRequest = speakers
        ?.filter(({ speakerId, id }) => selectedExperts.includes(speakerId) || selectedExperts.includes(id))
        .map(speakerToOption);
      if (!expertsToRequest) return;
      try {
        await requestChangeInteractionFn({
          reason,
          type: "ADD_EXPERTS_IN_PROJECT_REQUEST",
          projectToken: project?.token,
          payload: {
            interactions: expertsToRequest,
          },
          origin: "deliverables-view",
          trackUserAction,
        });
        setAlreadyRequested((experts) => [...experts, ...expertsToRequest.map(({ id }) => id)]);
        toast.success({
          message: `${project?.lead?.name} has been notified of your request to add the selected expert(s) to your project.`,
        });
      } catch (error) {
        console.error("Error during submit:", error);
      } finally {
        setSubmitting(false);
      }
    },
    [project, speakers, toast, trackUserAction]
  );

  return {
    validSpeakers: validSpeakers ?? [],
    submit,
    submitting,
    requestedSpeakersIds: alreadyRequested,
    setRequestedSpeakersIds: setAlreadyRequested,
    isExpertRequestForProjectLoading: isRequestExpertLoading,
  };
};

export default useRequestForProject;
