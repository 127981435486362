import React from "react";
import { TabItem, Tabs, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import SizeAndGrowthTabContent, { SizeAndGrowthExpert } from "./SizeAndGrowthTabContent";

type SizeAndGrowthTabsProps = {
  selectedTab: number;
  tabsData: SizeAndGrowthExpert[];
  chartColors: string[];
  handleTabChange: (index: number) => void;
  onHighlightChartSegment: (index: number) => void;
};

const SizeAndGrowthTabs = ({
  selectedTab,
  tabsData,
  chartColors,
  handleTabChange,
  onHighlightChartSegment,
}: SizeAndGrowthTabsProps) => {
  const { color, spacing } = useThemeTokens();

  const allTabsData = [{ name: "" }, ...tabsData];

  return (
    <x.div pt={spacing.inner.base04} data-testid="sizeAndGrowthTabs">
      <Tabs
        colorVariant="primary"
        index={selectedTab || 0}
        onChange={(index) => {
          handleTabChange(index);
          onHighlightChartSegment(index);
        }}
      >
        {allTabsData.map((tabData, index) =>
          index === 0 ? (
            <TabItem key="Overview" label="Overview" data-testid="overview-tab-0" color={color.text.secondary} />
          ) : (
            <TabItem
              key={tabData?.name}
              label={
                <DotLabel
                  dotColor={chartColors[index - 1]}
                  labelColor={selectedTab !== index ? color.text.secondary : ""}
                  label={tabData?.name}
                  dataTestId={`sizeAndGrowthTab-${index}`}
                />
              }
              color={color.text.secondary}
            />
          )
        )}
      </Tabs>

      <SizeAndGrowthTabContent expertColors={chartColors} selectedTab={selectedTab} tabsData={tabsData} />
    </x.div>
  );
};

export default SizeAndGrowthTabs;
