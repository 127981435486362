import React from "react";
import { x } from "@xstyled/styled-components";
import { Experience } from "models/Interaction";
import { Icon, Pill, useThemeTokens } from "@alphasights/alphadesign-components";
import { StatusClose, StatusDefault, StatusPending, StatusUnaware, TickOutline } from "@alphasights/alphadesign-icons";

export const ExperienceIcon = ({
  experience,
  title,
  maxChars,
}: {
  experience: Experience;
  title?: string | number;
  maxChars?: number;
}) => {
  const styles = useExperienceIconStyles();

  const iconColor = styles.customPillStyle[experience]?.color;

  const pillStyle = {
    ...styles.pillStyle,
    leftAccessories: <Icon size="small">{styles.customPillStyle[experience].icon}</Icon>,
    color: iconColor,
  };

  if (title) {
    return (
      <Pill {...pillStyle} size={"small"} isInteractive={false} maxChars={maxChars}>
        {title}
      </Pill>
    );
  }

  return (
    <x.div {...styles.icon}>
      <Icon size="small" color={iconColor}>
        {styles.customPillStyle[experience].icon}
      </Icon>
    </x.div>
  );
};

export const useExperienceIconStyles = () => {
  const {
    spacing: { inner },
    color: { background, icon, text, chart },
  } = useThemeTokens();

  return {
    icon: {
      padding: inner.base,
      borderRadius: inner.base02,
      color: icon.secondary,
    },
    otherVendors: {
      display: "flex",
      gap: inner.base,
    },
    pillStyle: {
      padding: `${inner.base} ${inner.base02}`,
      borderRadius: inner.base02,
      bg: background.neutral.default,
      color: text.secondary,
      whiteSpace: "nowrap",
    },
    customPillStyle: {
      [Experience.Uses]: {
        icon: <TickOutline />,
        color: chart.categorical.base03,
      },
      [Experience.Churned]: {
        icon: <StatusClose />,
        color: chart.categorical.base04,
      },
      [Experience.Evaluated]: {
        icon: <StatusDefault />,
        color: chart.categorical.base07,
      },
      [Experience.Aware]: {
        icon: <StatusPending />,
        color: chart.categorical.base,
      },
      [Experience.Unaware]: {
        icon: <StatusUnaware />,
        color: chart.categorical.base09,
      },
    },
  };
};
