import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const CardContainer = styled.div(({ isSelected }: { isSelected: boolean }) => {
  const {
    color: { border: borderColor, background },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();
  return {
    padding: inner.base04,
    backgroundColor: background.surface.page.default,
    position: "relative" as "relative",
    borderRadius: border.radius.medium,
    borderWidth: border.width.sm,
    borderColor: isSelected ? borderColor.selected : borderColor.neutral.default,
    display: "flex",
    flexDirection: "row" as "row",
    cursor: "pointer",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const CompanyTitleContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    gap: inner.base,
  };
});

const CompanyDetailsContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "flex",
    gap: inner.base02,
  };
});

export { CardContainer, CompanyTitleContainer, CompanyDetailsContainer };
