import styled from "styled-components";

const LandingPageSideBarContainer = styled.div`
  width: 358px;
  overflow-y: auto;
  gap: 2px;
  display: flex;
  flex-direction: column;
`;

export { LandingPageSideBarContainer };
