import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { th, x } from "@xstyled/styled-components";

export const useMobileSchedulingPanelStyles = () => {
  const {
    spacing: { inner },
    color: { border, background, text },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      background: background.surface.page.default,
    },
    bodyWrapper: {
      display: "flex",
      flexDirection: "column",
      pt: inner.base04,
      px: inner.base04,
      gap: inner.base06,
    },
    scheduleButtonPanel: {
      position: "fixed",
      bottom: "0",
      w: "100%",
      backgroundColor: background.surface.page.default,
      borderTopWidth: "1px",
      borderTopColor: border.divider,
    },
    headerDescription: {
      component: "span",
      color: text.strong._,
    },
    footerPeriod: {
      display: "flex",
      flexDirection: "column",
      color: text.secondary,
    },
  };
};

export const MobileScheduleFormButton = styled(x.div)`
  display: flex;
  gap: ${th.space("inner-base03")};
  padding: ${th.space("inner-base04")};
  justify-content: space-between;
  align-items: center;

  & > * {
    flex-grow: 1;
    width: 100%;
  }
`;
