import { ContentCard } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const CustomHoverContentCard = styled(ContentCard)`
  ${(props) =>
    props.isMobile &&
    `
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
  `}
`;
