import React from "react";
import { LandingPageSideBarContainer } from "pages/AlphaNowPage/components/LandingPage/LandPageSidebar/LandingPageSidebar.styled";
import OurResearchSection from "pages/AlphaNowPage/components/LandingPage/OurResearchSection";
import WatchlistSection from "pages/AlphaNowPage/components/LandingPage/WatchlistSection";

const LandingPageSidebar = () => {
  return (
    <LandingPageSideBarContainer>
      <OurResearchSection />
      <WatchlistSection />
    </LandingPageSideBarContainer>
  );
};

export { LandingPageSidebar as default };
