import React from "react";
import { x } from "@xstyled/styled-components";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { useCompanyPrimerV3Store } from "../../../store/store";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const MarketContext = () => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const {
    marketContext: { participants, overview },
  } = useCompanyPrimerV3Store(({ overview }) => overview);

  return (
    <x.div display="flex" flexDirection="column" px={inner.base10}>
      <PrimerFieldTitle
        text="Market Context"
        variant="body-large-em"
        xtraProps={{
          pb: inner.base08,
        }}
      />
      <PrimerFieldTitle
        text="participants"
        xtraProps={{
          pb: inner.base06,
        }}
      />
      <x.div display="flex" flexDirection="column" gap={inner.base03}>
        {participants.map((participant, index) => (
          <React.Fragment key={index}>
            <x.div
              w="100%"
              display="grid"
              columnGap={inner.base04}
              gridTemplateColumns={"205px auto"}
              alignItems="center"
            >
              <SensitiveDataContainer isSensitive={participant.title.isSensitive}>
                <Typography variant="body-em" color={text.strong._}>
                  <CitationTextWrapper value={participant.title} />
                </Typography>
              </SensitiveDataContainer>
              <CitationTextWrapper value={participant.description} />
            </x.div>
            {index < participants.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </x.div>
      <x.div pt={inner.base08}>
        <CitationWrapper label="Overview" value={overview.content} verticalLabel titleToContentGap={inner.base06} />
      </x.div>
    </x.div>
  );
};

export default MarketContext;
