import styled, { th } from "@xstyled/styled-components";

export const NotificationsWrapper = styled.div<{ isSelected: boolean }>`
  border-radius: small;
  padding: inner-base02;

  background-color: ${({ isSelected }) => (isSelected ? "rgba(255, 255, 255, 0.2)" : "transparent")};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.1)")};
    cursor: pointer;
  }

  [data-testid="notification-icon-badged"],
  [data-testid="notification-icon"] {
    color: ${th.color("text-inverse")};
  }

  [data-testid="notifications-widget"] {
    display: flex;
  }
`;
