enum SearchFilters {
  contentType = "contentType",
  purchasedFilter = "purchasedFilter",
  callDate = "callDate",
  angle = "angle",
  upcomingFilter = "upcomingFilter",
  bookmarkFilter = "bookmarkFilter",
  sortBy = "sortBy",
  headcount = "headcount",
  revenue = "revenue",
  ownershipType = "ownershipType",
  hqLocation = "hqLocation",
}

const FILTERS = "Filters";

export { FILTERS, SearchFilters };
