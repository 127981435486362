import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTLandingPageStyles = ({ isMessagesOverflow = false } = {}) => {
  const {
    spacing: { inner, layout },
    color: { text },
  } = useThemeTokens();

  const searchesContainer = {
    mt: "92px",
    gap: layout.base02,
    display: "flex",
    flexDirection: "column",
    w: "708px",
  };

  const wrapper = {
    py: inner.base06,
    px: inner.base08,
  };

  const suggestedSearches = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    flexWrap: "wrap",
  };

  const librarySelectorContainer = {
    ml: inner.base08,
    mt: inner.base06,
    alignSelf: "start",
  };

  const searchSuggestionButton = {
    cursor: "pointer",
    textAlign: "left",
    w: "fit-content",
  };

  const searchSuggestionTextPlaceholder = {
    color: text.decorative.base04,
    fontStyle: "italic",
  };

  return {
    searchesContainer,
    suggestedSearches,
    librarySelectorContainer,
    wrapper,
    searchSuggestionButton,
    searchSuggestionTextPlaceholder,
  };
};
