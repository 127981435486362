import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const HeaderContainer = styled.div(({ isDisabled }: { isDisabled: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: isDisabled ? 0 : inner.base04,
    ...(isDisabled && {
      ">:last-child": {
        position: "relative" as "relative",
        left: inner.base05,
      },
    }),
  };
});

const CompanyDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

export { HeaderContainer, CompanyDetailsContainer };
