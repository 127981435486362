import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useReadAlongFlyoutStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const headerStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const backButtonStyles = {
    h: inner.base05,
    w: inner.base05,
  };

  return {
    headerStyles,
    backButtonStyles,
  };
};
