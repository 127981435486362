/**
 * Replaces each element of {@link elements} with the matching value
 * from {@link replacements} for the key associated to the given element.
 *
 * If {@link replacements} does not contain a key for a particular element,
 * the original element will be used instead.
 *
 * Example:
 * ```
 * const result = replaceEachByMap([0, 1, 2], new Map([0, 9], [2, 20]));
 *
 * console.log(result); // [9, 1, 20]
 * ```
 */
const replaceEachByMap = <T, K>(elements: T[], replacements: Map<T, K>): (T | K)[] =>
  elements.map((element) => replacements.get(element) || element);

export { replaceEachByMap };
