import React, { ReactNode, useCallback, useContext, useState } from "react";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { EXPERT_REMARKS_BADGE, useProjectBadgeContext } from "./BadgeProvider";
import { useExpertRemarksContext } from "./ExpertRemarksProvider";

type OnToggleStar = (interaction: Interaction, origin: HitOrigin) => Promise<void>;

interface StarExpertProviderState {
  onToggleStar: OnToggleStar;
  isLoading: (interactionId: string) => boolean;
}

interface StarExpertProviderProps {
  children: ReactNode;
  onToggleStar: any;
}

export const StarExpertContext = React.createContext<StarExpertProviderState | null>(null);

export const StarExpertProvider = ({ children, onToggleStar, ...props }: StarExpertProviderProps) => {
  const { getExpertRemark, saveExpertRemark } = useExpertRemarksContext();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { logHit } = useTrackUserAction();
  const [interactionIdsLoading, setInteractionIdsLoading] = useState<string[]>([]);

  const toggleStar: OnToggleStar = useCallback(
    (interaction, origin) => {
      const expertId = interaction.advisorId;
      const angleId = interaction.angles[0].id;
      setInteractionIdsLoading((prev) => [...prev, interaction.id]);

      if (hasProjectBadge(EXPERT_REMARKS_BADGE)) {
        const starred = !getExpertRemark({ angleId, expertId }).starred;

        return saveExpertRemark({ angleId, expertId, starred })
          .finally(() => {
            setInteractionIdsLoading((prev) => prev.filter((id) => id !== interaction.id));
          })
          .then(() => {
            logHit({
              origin: origin,
              action: starred ? HitAction.starAdvisor : HitAction.unstarAdvisor,
              advisorshipId: interaction.id,
              projectToken: interaction.projectToken,
            });
          });
      } else {
        return onToggleStar(interaction.id, !interaction.starred, origin).finally(() => {
          setInteractionIdsLoading((prev) => prev.filter((id) => id !== interaction.id));
        });
      }
    },
    [getExpertRemark, hasProjectBadge, logHit, onToggleStar, saveExpertRemark]
  );

  const value: StarExpertProviderState = {
    onToggleStar: toggleStar,
    isLoading: (interactionId) => interactionIdsLoading.includes(interactionId),
  };

  return (
    <StarExpertContext.Provider value={value} {...props}>
      {children}
    </StarExpertContext.Provider>
  );
};

export const useStarExpertContext = () => useContext(StarExpertContext);
