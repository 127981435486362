const BOOLEAN_OPTIONS_FOOTER_TEXT = "Select Boolean operators by typing AND, OR, NOT and ()";

const Operator: Record<string, string> = Object.freeze({
  AND: "AND",
  OR: "OR",
  NOT: "NOT",
});

const OperatorFirstCharacter: Record<string, string> = Object.freeze({
  AND: "A",
  OR: "O",
  NOT: "N",
});

const Symbol: Record<string, string> = Object.freeze({
  LEFT_BRACKET: "(",
  RIGHT_BRACKET: ")",
});

const BooleanTypes: Record<string, string> = {
  AND: "AND",
  NOT: "NOT",
  OR: "OR",
  LEFT_BRACKET: "LEFT_BRACKET",
  RIGHT_BRACKET: "RIGHT_BRACKET",
};

export { BOOLEAN_OPTIONS_FOOTER_TEXT, Operator, OperatorFirstCharacter, Symbol, BooleanTypes };
