import React, { ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Divider, Typography } from "@alphasights/alphadesign-components";
import { Citation } from "components/CitationContext/Citation";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import GrowthSymbol from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import CompetitorDynamicsTableHeaderItem from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/CompetitorDynamicsHeader";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { CustomerDynamicsLabels } from "models/primers/constants";
import styles from "./styles/notableCompetitors.module.css";
import { ConditionalTooltip, PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

type NotableCompetitorRowProps = {
  rank: CitableValue<number>;
  logoLink: string;
  companyName: CitableValue<string>;
  trend: CitableValue<string>;
  competitorType: CitableValue<string>;
};

interface MetricColumnWrapperProps {
  children: ReactNode;
}

const MetricColumnWrapper = ({ children }: MetricColumnWrapperProps) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div display="flex" flexDirection="column" spaceY={spacing.inner.base02}>
      {children}
    </x.div>
  );
};

interface MetricWrapperProps {
  isSensitive?: boolean;
  children: ReactNode;
}

const MetricWrapper = ({ isSensitive = false, children }: MetricWrapperProps) => {
  const { spacing } = useThemeTokens();

  const Container = () => (
    <x.div display="flex" flexDirection="column" minH={spacing.inner.base14}>
      {children}
    </x.div>
  );
  if (isSensitive) {
    return (
      <SensitiveDataContainer isSensitive={isSensitive} style={{ minHeight: spacing.inner.base14 }}>
        <Container />
      </SensitiveDataContainer>
    );
  }
  return <Container />;
};

const NotableCompetitorsRow = ({ rank, companyName, competitorType, trend, logoLink }: NotableCompetitorRowProps) => {
  const {
    spacing: { inner },
    color,
    typography,
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(companyName, 30);

  if (isMobile) {
    return (
      <>
        <Typography component="div" color={color.text.strong._} variant="body-large">
          <x.div display="flex" flexDirection="column">
            <x.div display="flex" pt={inner.base06} gap="40%">
              <MetricColumnWrapper>
                <MetricWrapper>
                  <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.SEGMENT} />
                  <Typography color={color.text.strong._} variant="body-em">
                    <Citation value={rank} />
                  </Typography>
                </MetricWrapper>
              </MetricColumnWrapper>
              <MetricColumnWrapper>
                <MetricWrapper>
                  <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.SEGMENT} />
                  <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                    <Typography color={color.text.strong._} variant="body-em">
                      <Citation value={companyNameTruncated as CitableValue<string>} />
                    </Typography>
                  </ConditionalTooltip>
                </MetricWrapper>
              </MetricColumnWrapper>
              <MetricColumnWrapper>
                <MetricWrapper>
                  <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.SEGMENT} />
                  <Typography color={color.text.strong._} variant="body-em">
                    <Citation value={competitorType} />
                  </Typography>
                </MetricWrapper>
              </MetricColumnWrapper>

              <MetricColumnWrapper>
                <MetricWrapper isSensitive={trend.isSensitive}>
                  <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.TREND} />
                  <Citation value={trend} renderer={({ value: trend }) => <GrowthSymbol {...{ growth: trend }} />} />
                </MetricWrapper>
              </MetricColumnWrapper>
            </x.div>
          </x.div>
        </Typography>
        <Divider mt={inner.base06} />
      </>
    );
  }

  return (
    <x.div
      alignItems="center"
      display="grid"
      columnGap="10px"
      gridTemplateColumns="50px 1fr 1fr 1fr"
      className={styles.segment}
      w="100%"
    >
      <x.div color={color.text.strong._} {...typography.body.em}>
        <SensitiveDataContainer isSensitive={rank.isSensitive}>
          <Citation value={rank} isEnabled={false} />
        </SensitiveDataContainer>
      </x.div>
      <x.div display="flex" gap="2" alignItems="center" color={color.text.strong._} {...typography.body.regular}>
        <PrimerCompanyLogo size="30px" padding="2px" src={logoLink} />

        <SensitiveDataContainer isSensitive={companyName.isSensitive}>
          <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
            <Citation value={companyNameTruncated as CitableValue<string>} />
          </ConditionalTooltip>
        </SensitiveDataContainer>
      </x.div>
      <x.div color={color.text.strong._} {...typography.body.regular}>
        <SensitiveDataContainer isSensitive={competitorType.isSensitive}>
          <Citation value={competitorType} />
        </SensitiveDataContainer>
      </x.div>
      <x.div color={color.text.strong._} {...typography.body.regular}>
        <SensitiveDataContainer isSensitive={trend.isSensitive}>
          <Citation value={trend} renderer={({ value: trend }) => <GrowthSymbol {...{ growth: trend }} />} />
        </SensitiveDataContainer>
      </x.div>
    </x.div>
  );
};

export default NotableCompetitorsRow;
