import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const Container = styled.div(() => {
  const {
    color: { text },
    spacing: { layout },
  } = useThemeTokens();

  return `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    text-align: center;
    color: ${text.secondary};
    margin-top: ${layout.base08};
  `;
});
