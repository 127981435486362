import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { suggestionsInfo } from "views/DeliverablesView/helpers";
import TruncatedTextCell from "./TruncatedTextCell";

const ContentTypeCell = ({ row: { original: content } }: AlphaTableTypes.CellContext<ContentResults, any>) => {
  const { typeTitle } = suggestionsInfo(content);
  return <TruncatedTextCell text={typeTitle} testId={`content-type-${content.id}`} />;
};

export default ContentTypeCell;
