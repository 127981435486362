import { useExpiration } from "hooks/useExpiration";

const ExpiringComponent = ({ delay, children, onExpire }) => {
  return useExpiration(delay, onExpire) ? children : null;
};

ExpiringComponent.defaultProps = {
  delay: 3000,
};

export default ExpiringComponent;
