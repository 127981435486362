import React from "react";
import { Button } from "@alphasights/alphadesign-components";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

type PropTypes = {
  variant?: "primary" | "secondary";
  size?: "small" | "medium";
  onClick: () => void;
  isCompanyPage?: boolean;
};
export const testIds = {
  commissionPrimerButton: "commission-primer-btn",
};

const CommissionButton = ({ variant = "primary", size = "small", onClick, isCompanyPage = false }: PropTypes) => {
  const { logHit } = useTrackUserAction();

  return (
    <Button
      size={size}
      data-testid={testIds.commissionPrimerButton}
      whiteSpace="nowrap"
      onClick={() => {
        if (!isCompanyPage) {
          logHit({
            origin: HitOrigin.alphaNow,
            action: HitAction.alphaNowRequestPrimerAttempt,
          });
        }

        onClick();
      }}
      variant={variant}
    >
      Commission Primers
    </Button>
  );
};

export default CommissionButton;
