import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Tabs, TabItem } from "@alphasights/alphadesign-components";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import SegmentationTabContent from "./SegmentationTabContent";
import { Segment } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";
interface SegmentationTabsProps {
  onClick: (index: number) => void;
  selectedChartSegment: number | null;
  segments: Segment[];
}

const SegmentationTabs = ({ onClick, selectedChartSegment, segments }: SegmentationTabsProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [selectedTab, setSelectedTab] = useState<number | null>(0);

  //check if this should be set in store
  const chartColors = [
    "#3C83F6",
    "#EC4699",
    "#0CC09E",
    "#EF4343",
    "#F59F0A",
    "#D535ED",
    "#F97415",
    "#8055F7",
    "#9CA1B0",
  ];

  const names = [...segments.map((segment) => (segment.other ? { ...segment.title, value: "Other" } : segment.title))];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment);
  }, [selectedChartSegment]);

  return (
    <x.div pt={inner.base05}>
      <Tabs
        data-testid="PrimerV3Tabs"
        colorVariant="primary"
        index={selectedTab || 0}
        onChange={(index) => {
          handleTabChange(index);
          onClick(index);
        }}
      >
        {names.map((name, index) => (
          <TabItem key={name.value} label={<DotLabel dotColor={chartColors[index]} label={name ?? ""} />} />
        ))}
      </Tabs>
      <SegmentationTabContent segment={segments[selectedTab ?? 0]} />
    </x.div>
  );
};

export default SegmentationTabs;
