import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useRecordingPlayerStyles = () => {
  const {
    color: { icon, border, text, background },
    spacing: { inner },
  } = useThemeTokens();

  const headerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const titleStyles = {
    display: "flex",
    alignItems: "flex-start",
    gap: inner.base02,
  };

  const iconStyles = {
    width: inner.base05,
    height: inner.base05,
    minWidth: inner.base05,
    minHeight: inner.base05,
    color: icon.secondary,
  };

  const controlStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const progressBarStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    ml: inner.base08,
  };

  const durationsStyles = {
    display: "flex",
    justifyContent: "space-between",
  };

  const wrapperStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const lightGreyTextStyles = {
    color: text.secondary,
  };

  const darkGreyTextStyles = {
    color: text.strong._,
  };

  const controlButtonStyles = {
    minW: inner.base05,
    minH: inner.base05,
    w: inner.base08,
    h: inner.base08,
    borderWidth: 1,
    borderRadius: inner.base,
    borderColor: {
      _: border.neutral.default,
      hover: border.selected,
    },
    color: {
      _: icon.secondary,
      hover: icon.secondary,
    },
  };

  const playSpeedButtonStyles = {
    ...controlButtonStyles,
    borderColor: {
      _: border.neutral.default,
      hover: border.selected,
      focus: border.selected,
    },
  };

  const popoverStyles = {
    p: 0,
  };

  const playbackSpeedOptionsStyles = {
    gap: inner.base,
    display: "flex",
    flexDirection: "column",
    p: inner.base02,
  };

  const playbackSpeedStyles = {
    px: inner.base04,
    justifyContent: "space-between",
    color: text.strong._,
    backgroundColor: {
      hover: background.surface.page.hover,
    },
    minW: "110px",
  };

  const selectedPlaybackSpeedStyles = {
    backgroundColor: {
      _: background.surface.page.hover,
      hover: background.surface.page.hover,
    },
  };

  const backgroundBarStyles = {
    width: "100%",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
  };

  return {
    headerStyles,
    titleStyles,
    iconStyles,
    controlStyles,
    progressBarStyles,
    durationsStyles,
    lightGreyTextStyles,
    darkGreyTextStyles,
    controlButtonStyles,
    playSpeedButtonStyles,
    wrapperStyles,
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
    backgroundBarStyles,
  };
};
