import React, { useEffect, useState, useRef } from "react";
import { getLanguageFromCode, toMinutesAndSeconds } from "../../helpers/displayHelpers";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import {
  AudioRecording,
  Close,
  Forward_30 as Forward30,
  Pause,
  PlayArrow,
  Playback,
  Replay_30 as Replay30,
  Tick,
} from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { Button, IconButton, Loading, Popover, Typography, Icon as IconAds } from "@alphasights/alphadesign-components";
import { useRecordingPlayerStyles } from "./RecordingPlayer.styles";
import * as S from "./ProgressBar.styled";

const MinuteAndSecond = ({ duration, ...props }) => {
  return (
    <Typography component="span" variant="body-small" {...props}>
      {toMinutesAndSeconds(duration)}
    </Typography>
  );
};

export const RecordingPlayer = ({
  controlsProps,
  progressBarProps,
  playbackProps,
  disabled = false,
  isError,
  isLoading,
  audioError,
  interactionId,
  projectToken,
  recordingId,
  origin,
  language,
  onClose,
  setAutoScroll,
}) => {
  const { logHit } = useTrackUserAction();

  const {
    headerStyles,
    titleStyles,
    iconStyles,
    controlStyles,
    progressBarStyles,
    durationsStyles,
    wrapperStyles,
    lightGreyTextStyles,
    darkGreyTextStyles,
  } = useRecordingPlayerStyles();

  useEffect(() => {
    if (!disabled && controlsProps.playing) {
      logHit({
        origin: origin,
        action: "TRACK_RECORDING_PLAY",
        advisorshipId: interactionId,
        projectToken: projectToken,
        references: { recordingId },
      });
      setAutoScroll && setAutoScroll(true);
    }
  }, [disabled, controlsProps.playing, origin, projectToken, recordingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const languageName = getLanguageFromCode(language);
  const progressBar = (
    <ProgressBar
      progressBarProps={{
        ...progressBarProps,
        onMouseUp: (event) => {
          controlsProps.playing && setAutoScroll && setAutoScroll(true);
          progressBarProps.onMouseUp(event);
        },
      }}
      disabled={disabled}
    />
  );

  return (
    <x.div {...wrapperStyles}>
      <x.div {...headerStyles}>
        <x.div {...titleStyles}>
          <IconAds {...iconStyles}>
            <AudioRecording />
          </IconAds>

          <x.div>
            <Typography {...darkGreyTextStyles}>Audio Recording</Typography>
            <Typography variant="body-small" {...lightGreyTextStyles}>
              {isError ? (
                audioError || "Recording file no longer available."
              ) : (
                <>
                  {languageName}
                  {languageName && (
                    <x.span
                      dangerouslySetInnerHTML={{
                        __html: "&nbsp&nbsp|&nbsp&nbsp",
                      }}
                    />
                  )}
                  Recording available
                </>
              )}
            </Typography>
          </x.div>
        </x.div>
        <x.div {...controlStyles}>
          <RecordingPlayerControls
            playing={controlsProps.playing}
            isLoading={isLoading}
            onChangeSpeed={controlsProps.onChangeSpeed}
            onClickRewind={controlsProps.onClickRewind(30)}
            onClickForward={controlsProps.onClickForward(30)}
            onClose={onClose}
            onTogglePlay={controlsProps.onTogglePlay}
            disabled={disabled}
            isError={isError}
          />
        </x.div>
      </x.div>
      {!isError && (
        <x.div {...progressBarStyles}>
          {progressBar}
          <x.div {...durationsStyles}>
            <MinuteAndSecond duration={playbackProps.currentTime} {...lightGreyTextStyles} />
            <MinuteAndSecond duration={playbackProps.duration} {...lightGreyTextStyles} />
          </x.div>
        </x.div>
      )}
    </x.div>
  );
};

const CloseButton = ({ ...props }) => (
  <IconButton variant="ghost" {...props}>
    <Close />
  </IconButton>
);

const PlayButton = ({ newControls, ...props }) => (
  <>
    <IconButton variant="ghost" {...props}>
      <PlayArrow />
    </IconButton>
  </>
);
const PauseButton = ({ newControls, ...props }) => (
  <>
    <IconButton variant="ghost" {...props}>
      <Pause />
    </IconButton>
  </>
);
const FastForwardButton = ({ newControls, ...props }) => (
  <>
    <IconButton variant="ghost" {...props}>
      <Forward30 />
    </IconButton>
  </>
);
const RewindButton = ({ newControls, ...props }) => (
  <>
    <IconButton variant="ghost" {...props}>
      <Replay30 />
    </IconButton>
  </>
);

const PlaySpeedButton = ({ onPlaySpeedSelected, ...props }) => {
  const {
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
  } = useRecordingPlayerStyles();
  const [speed, setSpeed] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);
  const possibleSpeeds = [0.5, 0.75, 1.0, 1.25, 1.5];

  const handlePopoverOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget.lastElementChild);
    } else {
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    onPlaySpeedSelected(newSpeed);
    handlePopoverClose();
  };

  return (
    <>
      <IconButton variant="ghost" onClick={handlePopoverOpen} {...props}>
        <Playback />
      </IconButton>
      <Popover
        ref={ref}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        placement="bottom"
        {...popoverStyles}
      >
        <x.div {...playbackSpeedOptionsStyles}>
          {possibleSpeeds.map((s) => (
            <Button
              key={s}
              variant="ghost"
              onClick={() => handleSpeedChange(s)}
              endIcon={
                speed === s && (
                  <IconAds color="secondary">
                    <Tick />
                  </IconAds>
                )
              }
              {...playbackSpeedStyles}
              {...(speed === s && selectedPlaybackSpeedStyles)}
            >
              <Typography variant="body">{s}x</Typography>
            </Button>
          ))}
        </x.div>
      </Popover>
    </>
  );
};

const ProgressBar = ({ progressBarProps, disabled }) => {
  const { duration, value, draggingValue, onMouseDown, onMouseUp, onMouseMove } = progressBarProps;

  const progress = duration === 0 ? 0 : ((draggingValue || value) / duration) * 100;

  return (
    <S.ProgressBarWrapper disabled={disabled} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
      <S.ProgressBarBackground>
        <S.ProgressBarFill progress={progress} />
      </S.ProgressBarBackground>
    </S.ProgressBarWrapper>
  );
};

const RecordingPlayerControls = ({
  playing,
  isLoading,
  onChangeSpeed,
  onClickRewind,
  onClickForward,
  onClose,
  onTogglePlay,
  disabled,
  isError,
}) => {
  const { controlButtonStyles, playSpeedButtonStyles } = useRecordingPlayerStyles();

  return (
    <>
      {isLoading ? (
        <Loading size="sm" />
      ) : (
        <>
          {!isError && (
            <>
              <PlaySpeedButton onPlaySpeedSelected={onChangeSpeed} disabled={disabled} {...playSpeedButtonStyles} />
              <RewindButton onClick={onClickRewind} disabled={disabled} {...controlButtonStyles} />
              {playing ? (
                <PauseButton onClick={onTogglePlay} {...controlButtonStyles} />
              ) : (
                <PlayButton onClick={onTogglePlay} disabled={disabled} {...controlButtonStyles} />
              )}
              <FastForwardButton onClick={onClickForward} disabled={disabled} {...controlButtonStyles} />
            </>
          )}
          {onClose && <CloseButton onClick={onClose} {...controlButtonStyles} />}
        </>
      )}
    </>
  );
};
