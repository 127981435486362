export enum MatchedOnField {
  // Company name matches
  AlternateName = "ALTERNATE_NAME",
  FormerName = "FORMER_NAME",
  LegalName = "LEGAL_NAME",
  NativeName = "NATIVE_NAME",
  OtherName = "OTHER_NAME",
  PrimaryName = "PRIMARY_NAME",
  // Non company name matches
  Ticker = "TICKER",
  Website = "WEBSITE",
}

export enum MatchedOnType {
  Exact = "EXACT",
  Partial = "PARTIAL",
  Fuzzy = "FUZZY",
}
