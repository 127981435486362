import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const TitleContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base,
  };
});

export { TitleContainer };
