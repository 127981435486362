import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useDropdownButtonStyles = ({ size = "", open = false, variant = "" }) => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  const buttonMainStyle = {
    paddingLeft: inner.base03,
    paddingRight: inner.base03,
    backgroundColor: open && variant === "outline" ? background.action.outline.hover : undefined,
  };

  const popoverStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: inner.base02,
    gap: inner.base,
    zIndex: 600,
  };

  const buttonIconStyle = {
    w: size === "small" ? "16px" : "20px",
    h: size === "small" ? "16px" : "20px",
    fontSize: size === "small" ? "16px" : "20px",
    transition: "transform 0.2s ease-in-out",
  };

  return {
    buttonMainStyle,
    popoverStyle,
    buttonIconStyle,
  };
};
