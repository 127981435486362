import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useQuery } from "react-query";
import { contentService } from "services/content";

const useContentClientSettings = () => {
  const currentUser = useCurrentUser();
  const { data: clientSettings, isLoading } = useQuery(
    ["client-settings"],
    () => contentService.fetchClientSettings(),
    { enabled: !!currentUser }
  );

  return { clientSettings, isLoading };
};

export default useContentClientSettings;
