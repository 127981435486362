import React from "react";
import { Chat } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import classnames from "classnames";
import { useCommentContext } from "./CommentContext";
import { Icon } from "@alphasights/alphadesign-components";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const CommentIcon = ({
  angleId,
  expertId,
  onClick: onClickInput,
  className,
  size = "small",
  color = "secondary",
  tooltip,
  interaction,
}) => {
  const { getCounts } = useCommentContext();
  const { locked } = useLockedExpert(interaction);
  const { onOpenComments } = useProjectInteractionsContext();
  const { pendingCount } = getCounts({ angleId, expertId });
  const newMessage = pendingCount > 0;

  const onClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      onOpenComments(interaction);
    },
    [interaction, onOpenComments]
  );

  return (
    <PortalTooltip title={tooltip} disabled={locked} dark>
      <x.div
        display="flex"
        alignItems="center"
        className={classnames(`aui-cursor-pointer ${locked ? "aui-bg-grey-1" : "hover:aui-text-primary-2"}`, className)}
        maxWidth="max-content"
        data-testid="comment-icon"
      >
        <Icon size={size} color={color} badged={newMessage} onClick={locked ? null : onClickInput ?? onClick}>
          <Chat />
        </Icon>
      </x.div>
    </PortalTooltip>
  );
};
