import React, { FC, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  Button,
  ComponentSize,
  Divider,
  ListOption,
  useAlphaToast,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Add, ArrowRight, Plus, Tick } from "@alphasights/alphadesign-icons";
import { addedToWatchlist, addToWatchlist, watchlists } from "./consts";
import { AlphaNowProductType, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import useWatchlist, { WatchlistDataProps } from "hooks/useWatchlist";
import useModal from "hooks/useModal";
import WatchlistModal from "components/WatchlistModal";
import { getInitialWatchlistData } from "hooks/useWatchlist/utils";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import WatchlistOption from "pages/AlphaNowPage/components/WatchlistQuickAddButton/WatchlistOption";
import {
  createCompanyTriggers,
  getToastMessage,
  hasSelectedWatchlists,
} from "pages/AlphaNowPage/components/WatchlistQuickAddButton/utils";
import {
  manageWatchlists,
  newWatchlistButton,
  RequestType,
} from "pages/AlphaNowPage/components/WatchlistQuickAddButton/consts";

enum Variant {
  Complex = "complex",
  Basic = "basic",
}

const DataTestIds = {
  QuickAddWatchlistButton: "quick-add-watchlist-button",
};

type WatchlistQuickAddButtonProps = {
  cdsAlphaCompanyIds: number[];

  productType?: AlphaNowProductType | AlphaNowSRMProductType;
  size?: ComponentSize;
  contentId?: string;
  variant?: Variant;
};

type SearchWatchlistProps = {
  cdsAlphaCompanyIds: number[];
  handleOpenWatchlistModal: () => void;
  contentId?: string;
  productType?: AlphaNowProductType | AlphaNowSRMProductType;
};

const SearchWatchlist = ({
  cdsAlphaCompanyIds,
  handleOpenWatchlistModal,
  productType,
  contentId,
}: SearchWatchlistProps) => {
  const [displayedWatchlists, setDisplayedWatchlists] = useState<WatchlistDataProps[]>([]);
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();
  const { toast } = useAlphaToast();

  const { availableWatchlists, createWatchlist } = useWatchlist();

  useEffect(() => {
    setDisplayedWatchlists(availableWatchlists);
  }, [availableWatchlists, setDisplayedWatchlists]);

  const handleCreateNewWatchlist = async () => {
    const newWatchlistTriggers = createCompanyTriggers(cdsAlphaCompanyIds);
    const newWatchlistData = { ...getInitialWatchlistData(displayedWatchlists), triggers: newWatchlistTriggers };
    try {
      setDisplayedWatchlists([newWatchlistData as WatchlistDataProps, ...displayedWatchlists]);
      const newWatchlist = await createWatchlist({ triggers: newWatchlistTriggers });
      const addedTriggers = newWatchlist.triggers;
      toast.success({ message: getToastMessage(newWatchlist.name, addedTriggers, RequestType.add, true, productType) });
      logHit({
        origin: contentId ? HitOrigin.alphaNow : HitOrigin.companyPage,
        action: HitAction.createWatchlist,
        details: {
          watchlistId: newWatchlist?.id,
          contentId,
          triggers: { companyIds: cdsAlphaCompanyIds },
        },
      });
    } catch {
      setDisplayedWatchlists(displayedWatchlists);
      toast.error({ message: getToastMessage("", [], RequestType.add, false, productType, cdsAlphaCompanyIds) });
    }
  };
  return (
    <x.div minWidth="260px">
      <ListOption
        type="text"
        size="small"
        label={newWatchlistButton}
        // @ts-ignore
        leftIconProps={{ style: { width: inner.base03, height: inner.base03, marginLeft: "2px" } }}
        leftIcon={<Plus />}
        onChange={handleCreateNewWatchlist}
      />
      {displayedWatchlists.map(({ id, name, triggers }) => (
        <WatchlistOption
          contentId={contentId}
          key={id}
          name={name}
          id={id}
          cdsAlphaCompanyIds={cdsAlphaCompanyIds}
          triggers={triggers}
          productType={productType}
        />
      ))}
      <Divider my={inner.base} />
      <ListOption
        type="text"
        size="small"
        label={manageWatchlists}
        rightIcon={<ArrowRight />}
        onChange={handleOpenWatchlistModal}
      />
    </x.div>
  );
};

const WatchlistQuickAddButton: FC<WatchlistQuickAddButtonProps> = ({
  cdsAlphaCompanyIds,
  size = "small",
  contentId,
  variant = Variant.Basic,
  productType,
}) => {
  const { isVisible, onOpen, onClose } = useModal();
  const { availableWatchlists, selectedWatchlistId, setSelectedWatchlistId } = useWatchlist();
  const hasWatchlistSelected = hasSelectedWatchlists(availableWatchlists, cdsAlphaCompanyIds);
  const complexVariantTitle = hasWatchlistSelected ? addedToWatchlist : addToWatchlist;

  return (
    <>
      <Button
        dataAttributes={{ "data-testid": DataTestIds.QuickAddWatchlistButton }}
        startIcon={hasWatchlistSelected ? <Tick /> : <Add />}
        variant="outline"
        size={size}
        whiteSpace="nowrap"
        dropdown
        splitPopoverContent={
          <SearchWatchlist
            cdsAlphaCompanyIds={cdsAlphaCompanyIds}
            handleOpenWatchlistModal={onOpen}
            contentId={contentId}
            productType={productType}
          />
        }
        splitPopoverProps={{ placement: "bottom-end" }}
      >
        {variant === Variant.Complex ? complexVariantTitle : watchlists}
      </Button>
      {isVisible && (
        <WatchlistModal
          availableWatchlists={availableWatchlists}
          onClose={onClose}
          selectedWatchlistId={selectedWatchlistId}
          setSelectedWatchlistId={setSelectedWatchlistId}
        />
      )}
    </>
  );
};

export { WatchlistQuickAddButton as default, DataTestIds, Variant };
