import React, { useState } from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { noop } from "lodash";
import { CompanyPill, COMPANY_TYPE } from "@alphasights/client-portal-shared";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { usePlusMore } from "hooks/usePlusMore";
import Tag from "components/Tag";
import { getCompanyRelationship } from "views/DeliverablesView/helpers";
import { View } from "models/Header";
import ContentView from "pages/AlphaNowPage/components/AlphaNowTranscript/ContentView";
import { HitNav } from "./InteractionDeliverable.component";
import styled from "styled-components";
// @ts-ignore
import { Markdown } from "components/alphaui";
import {
  ContentPurchaseCTA,
  RequestExpertsButton,
  TypographyEllipsis,
  TypographyNoWrap,
} from "./MobileContentCommons.component";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { ContentSuggestion } from "views/DeliverablesView/types";
import PitchedDetails from "components/PitchedDetails";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import useContentVisit from "hooks/useContentVisit";

export const ModeratedContentRenderer = ({
  content,
  updateContent,
  project,
  keywords,
}: {
  content: ContentSuggestion;
  updateContent: (id: string, updates: any) => void;
  project: Project;
  keywords: string[];
}) => {
  const { renderedWrapper } = useMobileDeliverablesStyles();
  const isAccessible =
    content.purchaseStatus &&
    content.approvalStatus &&
    isContentAccessible(content.purchaseStatus, content.approvalStatus);
  return (
    <x.div {...renderedWrapper}>
      {!isAccessible && <ModeratedContentPreview content={content} updateContent={updateContent} />}
      {isAccessible && <ModeratedContentView content={content} project={project} keywords={keywords} />}
    </x.div>
  );
};

const ModeratedContentView = ({
  content,
  project,
  keywords,
}: {
  content: ContentSuggestion;
  project: Project;
  keywords: string[];
}) => {
  const { contentWrapper } = useMobileDeliverablesStyles();
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [isTranscriptDataReady, setIsTranscriptDataReady] = useState(false);
  const suggestion = content;

  const [totalKeywordMatches, setTotalKeywordMatches] = useState(0);
  const keywordSearchProps = {
    selectedMentionIndex,
    setSelectedMentionIndex,
    totalKeywordMatches,
    setTotalKeywordMatches,
    selectedComprehensiveSummaryIndex: 0,
    setSelectedComprehensiveSummaryIndex: noop,
  };

  return (
    <x.div>
      <x.div {...contentWrapper}>
        <FeaturedExperts content={content} />
      </x.div>
      <ContentView
        // @ts-ignore
        projectToken={project.token}
        content={suggestion}
        viewMode={View.Transcript}
        isTranscriptDataReady={isTranscriptDataReady}
        setIsTranscriptDataReady={setIsTranscriptDataReady}
        searchQuery={keywords.map((ea) => ({ value: ea }))}
        keywordSearchProps={keywordSearchProps}
        // irrelevant for moderated content
        viewInTranscript={noop}
        setDetailsCollapsed={noop}
        setViewMode={noop}
        questionsData={{}}
        questionsFetched={false}
        comprehensiveSummaryProps={{}}
        // irrelevant for moderated content without audio
        audioToggle={{}}
        contentAudio={null}
        contentVtt={null}
      />
      {isTranscriptDataReady && keywords.length ? (
        <HitNav
          setCurrentHit={setSelectedMentionIndex}
          currentHit={selectedMentionIndex}
          totalHits={totalKeywordMatches}
        />
      ) : undefined}
    </x.div>
  );
};

const ModeratedContentPreview = ({
  content,
  updateContent,
}: {
  content: ContentSuggestion;
  updateContent: (id: string, updates: any) => void;
}) => {
  const { spacing } = useThemeTokens();
  const { stickyAtTheBottom, contentWrapper } = useMobileDeliverablesStyles();
  const { project } = useCurrentProjectContext();

  const { recommendationId, id, pitchedAt } = content;

  useContentVisit({ recommendationId, contentId: id });

  return (
    <>
      <x.div {...contentWrapper}>
        {pitchedAt && (
          <x.div alignSelf="flex-end">
            <PitchedDetails
              pitchedAt={pitchedAt}
              pitchedBy={project?.lead}
              hasTooltip
              hasDescription
              stylingProps={{ marginLeft: spacing.inner.base }}
            />
          </x.div>
        )}
        <ModeratedContentInfo content={content} />
        <FeaturedExperts content={content} />
      </x.div>
      <x.div {...stickyAtTheBottom}>
        <ContentPurchaseCTA content={content} updateContent={updateContent} />
      </x.div>
    </>
  );
};

export const ModeratedContentInfo = ({ content }: { content: ContentSuggestion }) => {
  const agenda = content.agenda;
  const anchorCompanies = content.companies
    .filter((c) => c.companyType === COMPANY_TYPE.anchor)
    .map((c) => c.companyName);
  const { setContainerEl, regularPills, morePill } = usePlusMore({
    labels: anchorCompanies,
  });
  const companyRelationship = getCompanyRelationship(content.contentType, content.speakers);
  const { moderatedContentSection } = useMobileDeliverablesStyles();
  const { color, spacing } = useThemeTokens();

  return (
    <>
      <x.div {...moderatedContentSection}>
        <x.div
          display={"flex"}
          gap={spacing.inner.base02}
          alignItems={"center"}
          overflow={"scroll"}
          ref={setContainerEl}
        >
          <TypographyNoWrap variant="body-small" color={color.text.assistive}>
            {companyRelationship}
          </TypographyNoWrap>
          {regularPills.map((pill) => (
            <CompanyPill name={pill.original} />
          ))}
          {/* @ts-ignore */}
          {morePill.length > 0 ? <Tag name={"+" + morePill.length + " more"} /> : undefined}
          {content.scheduledTime && (
            <>
              <TypographyNoWrap variant="body-small" color={color.text.assistive}>
                |
              </TypographyNoWrap>
              <TypographyNoWrap variant="body-small" color={color.text.assistive}>
                <FormattedDateTime date={content.scheduledTime} format="d MMM yyyy" />
              </TypographyNoWrap>
            </>
          )}
        </x.div>
      </x.div>
      {agenda && (
        <x.div {...moderatedContentSection} id="markdown">
          <Typography variant="body-small-em" color={color.text.assistive}>
            Topics
          </Typography>
          <MarkdownSmall className="aui-my-0">{agenda}</MarkdownSmall>
        </x.div>
      )}
    </>
  );
};

export const MarkdownSmall = styled(Markdown)`
  font-size: 0.75em;
  font-weight: 200;
`;

const FeaturedExperts = ({ content }: { content: ContentSuggestion }) => {
  const { color, spacing } = useThemeTokens();
  const { moderatedContentSection } = useMobileDeliverablesStyles();
  const speakers = content.speakers;
  return (
    <x.div {...moderatedContentSection}>
      <Typography variant="body-small-em" color={color.text.assistive}>
        Featured Experts
      </Typography>
      {speakers.map((speaker) => (
        <x.div display={"flex"} w={"100%"} columnGap={spacing.inner.base02}>
          <TypographyNoWrap variant="body-small" color={color.text.strong._}>
            {speaker.company}
          </TypographyNoWrap>
          <TypographyEllipsis variant="body-small" color={color.text.secondary}>
            {speaker.jobTitle}
          </TypographyEllipsis>
        </x.div>
      ))}
      <RequestExpertsButton speakers={content.speakers} />
    </x.div>
  );
};
