import React, { FC } from "react";
import { noop } from "lodash";

import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";
import { CheckboxFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { PurchasedValues } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/PurchasedFilter/types";
import Filter from "components/Filter";
import {
  PURCHASE_TYPE,
  PURCHASE_TYPE_TEXT,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/PurchasedFilter/constants";
import { PURCHASED } from "./constants";

interface PurchasedFilterProps extends Omit<FilterProps, "value"> {
  value: PurchasedValues;
}

const PurchasedFilter: FC<PurchasedFilterProps> = ({ value, onChange, onClear = noop }) => {
  const options = Object.values(PURCHASE_TYPE).map((type) => ({
    label: PURCHASE_TYPE_TEXT[type],
    value: type,
  }));

  const handleClear = () => onClear(SearchFilters.purchasedFilter, []);

  return (
    <Filter onClear={handleClear} title={PURCHASED}>
      <CheckboxFilter filterKey={SearchFilters.purchasedFilter} value={value} options={options} onChange={onChange} />
    </Filter>
  );
};

export default PurchasedFilter;
