import { Citation } from "models/AlphaGpt";

export const sampleConversation = {
  id: "sampleConversationId",
  title: "Tell me about CorEvitas?",
};

export const sampleMessage = [
  {
    id: "1",
    text: "Tell me about CorEvitas?",
    sender: "User",
  },
  {
    id: "2",
    text:
      "CorEvitas, previously known as Corrona, was founded in 2001 and specializes in building patient datasets known as registries. These datasets provide insights into patient behaviors, encounters, outcomes, and drug use over time. CorEvitas sells these insights to pharmaceutical companies on a long-term subscription basis [1]. The company's unique offering is their data set, which is built for purpose and never gets shipped, and the knowledge of their service bureau [2]. CorEvitas is PE backed and its target customers are pharmaceutical companies [3][4]. The company's M&A strategy focuses on acquiring datasets in other specialties [5][6]. CorEvitas' data is valuable for marketing as it allows for better targeting [7]. The company's insights are valuable as they provide longitudinal data over long periods of time for chronic autoimmune patients using costly drugs or therapeutics [8]. CorEvitas' competitors include EMR vendors such as Cerner and Athena, boutique consultancies, private equity-funded companies like TriNetX and Target RWE, and clinical-side companies like Verana [9][3][10].",
    sender: "GPT",
    citations: [
      {
        position: 1,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 2,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-01-12T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 3,
        title: "CorEvitas - Primer",
        id: "2",
        publishedAt: "2023-05-30T08:00:00Z",
        contentType: "COMPANY_PRIMER",
        showLink: true,
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 4,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 5,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 6,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 7,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 8,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 9,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 10,
        title: "CorEvitas - Former",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
    ],
  },
  {
    id: "3",
    text: "Who are their main competitors?",
    sender: "User",
  },
  {
    id: "4",
    text:
      "CorEvitas operates in a competitive market with several key players. The company's competitors include EMR vendors such as Cerner and Athena, boutique consultancies, and clinical-side companies like Verana [1]. Other competitors include private equity-funded companies like TriNetX and Target RWE [1][2]. Additional competitors include IQVIA, Flatiron Health, OM1, and LynxCare [2]. Aetion is also considered a potential competitor [3]. These companies offer similar services and compete for the same target market. However, CorEvitas differentiates itself by being a leading provider of pharmaceutical safety use cases, having strong methodological rigor and longitudinal data, and offering strong customer service and technical expertise [2].",
    sender: "GPT",
    citations: [
      {
        position: 1,
        title: "CorEvitas - Former Member BoD & Audit Committee",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 2,
        title: "CorEvitas - Primer",
        id: "56c4cb5b-5910-4d72-9eea-cc7bd5517975",
        publishedAt: "2023-05-30T08:00:00Z",
        showLink: true,
        contentType: "COMPANY_PRIMER",
        companies: ["CorEvitas"],
      } as Citation,
      {
        position: 3,
        title: "CorEvitas - Former Member BoD & Audit Committee",
        id: "1",
        publishedAt: "2023-05-16T08:00:00Z",
        contentType: "PEER_CONTRIBUTED",
        companies: ["CorEvitas"],
      } as Citation,
    ],
  },
];
