import styled from "styled-components";
import { Loading, useThemeTokens } from "@alphasights/alphadesign-components";

const LandingPageContainer = styled.div(() => {
  const { color } = useThemeTokens();
  return {
    display: "flex",
    flex: "1 1 auto",
    minHeight: 0,
    flexDirection: "row" as "row",
    backgroundColor: color.background.surface.recessed,
  };
});

const StyledLoadingSpinner = styled(Loading)`
  position: fixed;
  left: 50%;
  top: 50%;
`;

export { LandingPageContainer, StyledLoadingSpinner };
