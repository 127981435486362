import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { RoundedButton as AdsRoundedButton } from "@alphasights/alphadesign-components";

const {
  spacing: { inner },
  color: { background },
} = tokens;

export const RoundedButton = styled(AdsRoundedButton)(
  ({ newNavigationEnabled, open }: { newNavigationEnabled: boolean; open: boolean }) => `
    height: ${newNavigationEnabled ? inner.base08 : "44px"};
    ${newNavigationEnabled && open ? `background-color: ${background.action.outline.pressed};` : ""}
  `
);
