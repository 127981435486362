import React, { FC, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useMutation, useQueryClient } from "react-query";
import {
  Typography,
  Modal,
  Button,
  TextArea,
  Drawer,
  useAlphaToast,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { contentService } from "services/content";
import TitleAndCompanyDetails from "components/Experts/ExpertCard/TitleAndCompanyDetails/TitleAndCompanyDetails";
import RelevantDurationDetails from "components/Experts/ExpertCard/RelevantDurationDetails/RelevantDurationDetails";
import SecondaryExpertDetails from "components/Experts/ExpertCard/SecondaryExpertDetails/SecondaryExpertDetails";
import BulletPoints from "components/Experts/ExpertCard/RelevanceStatements/BulletPoints/BulletPoints";

const speakToExpertLabel = "Request Expert";
const confirmRequestLabel = "Confirm Request";
const cancelLabel = "Cancel";

type SpeakerProps = Pick<
  Speaker,
  "company" | "jobTitle" | "jobDuration" | "bio" | "secondaryCompany" | "secondaryJobDuration" | "secondaryJobTitle"
>;

type RequestExpertContentProps = {
  handleOnChangeNotes: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
} & SpeakerProps;

type RequestSpeakExpertModalProps = {
  contentId: string;
  expertId: number;
  onCloseModal: () => void;
} & SpeakerProps;

type RequestExpertRequestData = {
  contentId: string;
  note: string;
  speakerIds: number[];
};

const RequestExpertContent: FC<RequestExpertContentProps> = ({
  company,
  jobTitle,
  jobDuration,
  bio,
  secondaryCompany,
  secondaryJobDuration,
  secondaryJobTitle,
  handleOnChangeNotes,
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" overflowX="auto">
      <x.div display="flex" flexDirection="column" mb={inner.base05}>
        <Typography component="div" color={text.secondary} variant="body-small" marginBottom={inner.base03}>
          <TitleAndCompanyDetails company={company} jobTitle={jobTitle} />
          <RelevantDurationDetails jobDuration={jobDuration} />
          <SecondaryExpertDetails
            secondaryCompany={secondaryCompany}
            secondaryJobDuration={secondaryJobDuration}
            secondaryJobTitle={secondaryJobTitle}
          />
        </Typography>
        <Typography marginBottom={inner.base} color={text.secondary} variant={"body-small-em"}>
          Relevant Experience
        </Typography>
        <BulletPoints textColor={text.secondary} items={bio} />
      </x.div>

      <x.div>
        <Typography marginBottom={inner.base} color={text.secondary} variant={"body"}>
          Add a note (optional)
        </Typography>
        <x.div marginLeft={inner.base}>
          <TextArea
            width="95%"
            height="104px"
            placeholder="Share your availability or leave a note for your account manager."
            onChange={handleOnChangeNotes}
          />
        </x.div>
      </x.div>
    </x.div>
  );
};

const RequestSpeakExpertModal: FC<RequestSpeakExpertModalProps> = ({
  contentId,
  expertId,
  company,
  jobTitle,
  jobDuration,
  bio,
  secondaryCompany,
  secondaryJobDuration,
  secondaryJobTitle,
  onCloseModal,
}) => {
  const queryClient = useQueryClient();
  const { isMobile } = useCheckScreen();
  const { toast } = useAlphaToast();
  const [note, setNote] = useState("");
  const {
    spacing: { inner },
  } = useThemeTokens();

  const handleOnChangeNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNotes = e.target.value;
    setNote(newNotes);
  };

  const createClientExpertInquiry = useMutation(
    (clientExpertInquiry: RequestExpertRequestData) => contentService.postExpertRequest(clientExpertInquiry),
    {
      onError: () =>
        toast.error({
          message: "System Error: The action could’t be completed. Please try again later.",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["expert-request", contentId],
          exact: true,
        });
        toast.success({
          message: "We have received your request and your account manager will be in touch within 24 hours.",
        });
      },
      onSettled: () => onCloseModal(),
    }
  );

  const handleOnConfirmRequest = () => {
    createClientExpertInquiry.mutate({
      contentId,
      note,
      speakerIds: [expertId],
    });
  };

  if (isMobile) {
    return (
      <Drawer
        open
        data-testid="speak-to-expert-drawer"
        title={speakToExpertLabel}
        variant="complex"
        onClose={onCloseModal}
        primaryButtonProps={{
          onClick: handleOnConfirmRequest,
          children: confirmRequestLabel,
        }}
        secondaryButtonProps={{
          children: cancelLabel,
          onClick: onCloseModal,
        }}
      >
        <RequestExpertContent
          company={company}
          jobTitle={jobTitle}
          jobDuration={jobDuration}
          bio={bio}
          secondaryCompany={secondaryCompany}
          secondaryJobDuration={secondaryJobDuration}
          secondaryJobTitle={secondaryJobTitle}
          handleOnChangeNotes={handleOnChangeNotes}
        />
      </Drawer>
    );
  }

  return (
    <Modal
      title={speakToExpertLabel}
      open
      onClose={onCloseModal}
      slotWidth="555px"
      slotHeight="316px"
      variant="complex"
      shouldShowFooter={true}
      data-testid="speak-to-expert-modal"
      primaryButton={
        <Button variant="secondary" onClick={handleOnConfirmRequest}>
          {confirmRequestLabel}
        </Button>
      }
      secondaryButton={
        <Button marginRight={inner.base04} variant="ghost" onClick={onCloseModal}>
          {cancelLabel}
        </Button>
      }
    >
      <RequestExpertContent
        company={company}
        jobTitle={jobTitle}
        jobDuration={jobDuration}
        bio={bio}
        secondaryCompany={secondaryCompany}
        secondaryJobDuration={secondaryJobDuration}
        secondaryJobTitle={secondaryJobTitle}
        handleOnChangeNotes={handleOnChangeNotes}
      />
    </Modal>
  );
};

export default RequestSpeakExpertModal;
