import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const useSchedulingModalStyles = ({ selectedTime = false } = {}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const selectedTimeStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
    color: selectedTime ? text.secondary : text.assistive,
    justifyContent: "center",
  };

  const modalStyles = {
    p: inner.base06,
    pt: inner.base04,
    minW: !isMobile ? "640px" : undefined,
    h: isMobile ? "90%" : undefined,
    display: "flex",
    flexDirection: "column",
  };

  const alertContainerStyles = {
    display: isMobile ? "block" : "inline-block",
    mt: inner.base06,
  };

  const emailContainerStyles = {
    mt: inner.base06,
    display: "flex",
    flexDirection: "column",
    alignItems: isMobile ? "normal" : "baseline",
  };

  const emailLabelStyles = {
    mb: inner.base02,
  };

  const emailStyles = {
    minWidth: isMobile ? "auto" : "525px",
  };

  return {
    selectedTimeStyles,
    modalStyles,
    alertContainerStyles,
    emailStyles,
    emailLabelStyles,
    emailContainerStyles,
  };
};
