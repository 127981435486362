import React from "react";
import {
  ToastRequestConfig,
  defaultToastConfigWithError,
  usePortalStoreOperations,
} from "@alphasights/portal-auth-react";
import { User } from "@alphasights/portal-api-client";
import { ClientPortalState } from "app/reducers/ClientPortal.reducer";
import { findCookieByName } from "@alphasights/ads-community-utils";
import { addMinutes } from "date-fns";
import { useEnv } from "@alphasights/client-portal-shared";
import { ClientPortalContext } from "./ClientPortalWrapper";
import { useFeatures } from "../FeatureFlagsWrapper";
import { openLogoutUrl } from "@alphasights/auth-components";
import { useLogger } from "logging/Logger";

export const useClientPortal = () => React.useContext(ClientPortalContext);
export const useClientPortalState = () => useClientPortal().state;
export const useClientPortalActions = () => useClientPortal().actions;
export const useClientPortalSelector = <T = unknown>(selector: (state: ClientPortalState) => T) =>
  selector(useClientPortalState());

export const useClientPortalOperations = () => {
  const actions = useClientPortalActions();
  const state = useClientPortalState();
  const features = useFeatures();
  const env = useEnv();
  const logger = useLogger();

  const {
    user: { operations: userOperations },
  } = usePortalStoreOperations();

  const legalTermsFlow = async (user: User | undefined) => {
    const legalDisclaimerAccepted = findCookieByName("legal_disclaimer");

    if (legalDisclaimerAccepted && !user?.hasAgreedToLegalDisclaimer) {
      userOperations.acceptLegalDisclaimer();
    }
  };

  const openLoginModal = (modalProps: Partial<ClientPortalState["modal"]["extraProps"]> = {}) => {
    actions.setModalExtraProps(modalProps);
    actions.setIsModalOpen(true);
  };

  const logOutCurrentUser = async () => {
    logger.log("logout", { newLogout: features.launchNewLogin });

    if (features.launchNewLogin) {
      openLogoutUrl({
        redirectHost: env?.login?.loginRedirectHost ?? "",
        logoutUrl: env?.login?.logoutUrl ?? "",
        next: window.location.pathname,
      });
    } else {
      userOperations.logout({ ignoreForError: false, ignoreForSuccess: false });
    }
  };

  const loginAsClient = async (client: string) => {
    return await userOperations.loginAsClient(client);
  };

  const authenticate = async () => {
    let user: User | undefined;
    user = await userOperations.login();
    legalTermsFlow(user);
    return user;
  };

  const loginWithCredentials = (
    email: string,
    password: string,
    rememberMe: boolean = false,
    alertConfig: ToastRequestConfig = defaultToastConfigWithError
  ) => {
    return new Promise<User | undefined>((resolve, reject) => {
      userOperations
        .loginWithCredentials(email, password, rememberMe, alertConfig)
        .then((user: User) => {
          legalTermsFlow(user);
          resolve(user);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  };

  return {
    state,
    authenticate,
    loginWithCredentials,
    openLoginModal,
    logOutCurrentUser,
    loginAsClient,
  };
};

export const useClientPortalEvents = () => {
  const actions = useClientPortalActions();
  const env = useEnv();

  const onCloseLoginModal = ({ skipSignIn }: { skipSignIn?: boolean } = {}) => {
    if (skipSignIn) {
      actions.setSignInConfiguration({
        skipSignIn: true,
        expiration: addMinutes(new Date(), env?.skipSignInValidityInMinutes ?? 0).toISOString(),
      });
    }
    actions.setIsModalOpen(false);
  };

  return {
    onCloseLoginModal,
  };
};
