import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useInteractioUpgradesStyles = ({ selected = false, disabled = false, isMobile = false } = {}) => {
  const {
    color: { border, icon, text },
    spacing: { inner },
  } = useThemeTokens();

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base08,
  };

  const updgradeTypeHeaderStyle = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const helpButtonStyle = {
    padding: 0,
    w: inner.base05,
    h: inner.base05,
  };

  const upgradeListStyle = {
    gap: inner.base04,
    marginTop: inner.base06,
  };

  const upgradeDisabledListStyle = {
    display: "flex",
    gap: inner.base02,
    marginTop: inner.base04,
  };

  const upgradeListItemStyle = {
    display: "flex",
    flexDirection: "column",
    w: "100%",
    gap: inner.base02,
  };

  const upgradeDisabledListItemStyle = {
    display: "flex",
    flexGrow: 1,
    gap: inner.base02,
  };

  const upgradeItemStyle = {
    borderWidth: 1,
    borderColor: selected ? border.selected : border.divider,
    borderRadius: inner.base,
    w: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: inner.base02,
    paddingLeft: inner.base04,
    paddingBottom: inner.base02,
    paddingRight: inner.base04,
  };

  const upgradeIconStyle = {
    width: inner.base05,
    height: inner.base05,
    color: icon.secondary,
  };

  const upgradeIconContainerStyle = {
    pl: inner.base04,
  };

  const upgradeItemTextStyle = {
    color: text.strong._,
    pl: inner.base,
  };

  const popoverStyle = {
    maxWidth: "480px",
    zIndex: 5,
  };

  const bannerStyle = {
    pt: inner.base02,
  };

  const lockedEnhancementsTitleStyle = {
    color: text.strong._,
  };

  const lockedEnhancementsDetailStyle = {
    color: text.secondary,
  };

  const disabledItemTitleStyle = {
    flexGrow: 1,
  };

  const languageSelectionStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "stretch" : "center",
    gap: inner.base04,
    pt: inner.base02,
  };

  const languageSelectionButtonStyle = {
    flexGrow: 1,
  };

  const languageSelectionInputStyle = {
    flexDirection: "row-reverse",
    gap: inner.base02,
    w: "260px",
    h: "43px",
    justifyContent: "left",
    border: `1px solid ${border.neutral.default}`,
  };

  const languageSelectionLanguageTextStyle = {
    color: text.strong._,
  };

  const tooltipContentStyle = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const upgradeActionsStyle = {
    display: "flex",
    gap: inner.base02,
  };

  const actionButtonStyle = {
    w: inner.base08,
    h: inner.base08,
    borderWidth: 1,
    borderRadius: inner.base,
    borderColor: {
      _: border.neutral.default,
      hover: border.selected,
    },
    color: {
      _: icon.secondary,
      hover: icon.secondary,
    },
    ...(disabled && {
      cursor: "not-allowed",
    }),
  };

  const linkCopiedStyle = {
    display: "flex",
    gap: inner.base02,
    color: icon.strong._,
  };

  return {
    bannerStyle,
    disabledItemTitleStyle,
    helpButtonStyle,
    lockedEnhancementsDetailStyle,
    lockedEnhancementsTitleStyle,
    sectionStyle,
    popoverStyle,
    updgradeTypeHeaderStyle,
    upgradeIconStyle,
    upgradeIconContainerStyle,
    upgradeItemStyle,
    upgradeListStyle,
    upgradeDisabledListStyle,
    upgradeListItemStyle,
    upgradeDisabledListItemStyle,
    languageSelectionStyle,
    languageSelectionButtonStyle,
    languageSelectionInputStyle,
    upgradeItemTextStyle,
    languageSelectionLanguageTextStyle,
    tooltipContentStyle,
    upgradeActionsStyle,
    actionButtonStyle,
    linkCopiedStyle,
  };
};
