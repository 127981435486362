export enum FlyoutMode {
  Schedule = "schedule",
  RequestReschedule = "request-reschedule",
  RequestAvailability = "request-availability",
  ProvideAvailability = "provide-availability",
  Calendar = "calendar",
  Comments = "comments",
  Interaction = "standard",
  ReadAlong = "readAlong",
  Messages = "messages",
  NewMessage = "new-message",
}

export enum NewMessageOrigin {
  Standard = "standard",
  TableView = "tableView",
}

export enum FlyoutConfiguration {
  Fullscreen = "fullscreen",
  Extended = "extended",
  Standard = "standard",
}
