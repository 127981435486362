import { useThemeTokens, ComponentSize } from "@alphasights/alphadesign-components";

export const useStyles = ({ thick }: { thick: boolean }) => {
  const tokens = useThemeTokens();

  return {
    joinMethodWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base02,
      alignItems: "center",
      mb: tokens.spacing.inner.base04,
    },
    joinMethodLabel: {
      color: tokens.color.text.secondary,
    },
    halfDiv: {
      flex: 0.5,
    },
    halfBtn: {
      flex: 0.5,
      size: (thick ? undefined : "small") as ComponentSize,
      position: "unset",
      whiteSpace: "nowrap",
    },
    fullBtn: {
      flex: 1,
      size: (thick ? undefined : "small") as ComponentSize,
      position: "unset",
    },
    rightBtn: {
      ml: "auto",
    },
    buttonsWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base04,
    },
  };
};
