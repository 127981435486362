import React, { useMemo, useState } from "react";
import { useProjectAlphaGPTContext } from "providers/ProjectAlphaGPTProvider";
import { x } from "@xstyled/styled-components";
import { Alert, Button, Skeleton, TextField, Typography } from "@alphasights/alphadesign-components";
import { usePurchaseAlphaGPTAccessModalStyles } from "./PurchaseAlphaGPTAccessModal.styles";
import { PurchaseModal } from "components/PurchaseModal/PurchaseModal";

interface PurchaseAlphaGPTAccessModalProps {
  onCloseModal: () => void;
}

export const PurchaseAlphaGPTAccessModal = ({ onCloseModal }: PurchaseAlphaGPTAccessModalProps) => {
  const { modalDiv, cancelButton, buttonBar } = usePurchaseAlphaGPTAccessModalStyles();
  const { purchaseAccess, alphaGPTServicePlan } = useProjectAlphaGPTContext()!;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const charge = useMemo(() => alphaGPTServicePlan?.charge, [alphaGPTServicePlan]);
  const [caseCode, setCaseCode] = useState<string | undefined>();
  const isServicePlanLoaded = charge !== undefined;

  const handleOnConfirmRequest = () => {
    setIsLoading(true);
    setIsError(false);
    purchaseAccess(caseCode)
      .then(() => onCloseModal())
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <PurchaseModal
      title="Confirm Purchase"
      open
      onClose={onCloseModal}
      slotWidth="393px"
      slotHeight={isError ? "372px" : "284px"}
      zIndex={10}
    >
      <x.div {...modalDiv} data-testid="purchase-alpha-gpt-access-modal">
        <Typography>30 days Search access</Typography>
        {isServicePlanLoaded ? (
          <Typography data-testid="confirmation-text">Confirm purchase for {charge} credits.</Typography>
        ) : (
          <Skeleton variant="noMargin" height="20px" />
        )}
        <div>
          <TextField
            value={caseCode}
            label={<span>Case code (optional)</span>}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCaseCode(e.target.value)}
          />
        </div>
        {isError && <ErrorBanner />}
        <x.div {...buttonBar}>
          <Button variant="ghost" onClick={onCloseModal} {...cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleOnConfirmRequest} loading={isLoading} disabled={!isServicePlanLoaded}>
            Purchase
          </Button>
        </x.div>
      </x.div>
    </PurchaseModal>
  );
};

const ErrorBanner = () => (
  <x.div>
    <Alert variant="danger" flexGrow="1">
      <Typography variant="body-small-em" component="span">
        Action failed:{" "}
      </Typography>
      <Typography variant="body-small" component="span">
        There was a problem with purchasing Search access. Please try again.
      </Typography>
    </Alert>
  </x.div>
);
