import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const useInlinePadding = (isNewNav: boolean) => {
  const { spacing } = useThemeTokens();

  //TODO [RD1-209]: Remove when new Navigation enabled
  if (isNewNav) {
    return {
      paddingLeft: spacing.inner.base06,
      paddingRight: spacing.inner.base04,
    };
  } else {
    return {
      paddingLeft: spacing.inner.base08,
      paddingRight: spacing.inner.base06,
    };
  }
};

const LoadingSpinnerContainer = styled.div(() => {
  const {
    spacing: { layout },
  } = useThemeTokens();
  return {
    paddingBlock: layout.base08,
    gap: layout.base03,
    display: "flex",
    flexDirection: "column" as "column",
  };
});

const NoSearchResultsContainer = styled.div(({ isNewNav }: { isNewNav: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const inlinePadding = useInlinePadding(isNewNav);
  return {
    ...inlinePadding,
    paddingTop: inner.base06,
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  };
});

const SearchResultsContainer = styled.div(() => {
  return {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  };
});

const RequestPrimerContainer = styled.div(({ isNewNav }: { isNewNav: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const inlinePadding = useInlinePadding(isNewNav);
  return {
    ...inlinePadding,
    paddingTop: inner.base,
  };
});

const ContentCardContainer = styled.div(({ isNewNav }: { isNewNav: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const inlinePadding = useInlinePadding(isNewNav);

  return {
    display: "flex",
    flexDirection: "column" as "column",
    paddingLeft: isMobile ? inner.base06 : inlinePadding.paddingLeft,
    paddingRight: inner.base06,
    paddingTop: isMobile ? inner.base04 : inlinePadding.paddingRight,
    gap: inner.base03,
  };
});

export {
  LoadingSpinnerContainer,
  NoSearchResultsContainer,
  SearchResultsContainer,
  RequestPrimerContainer,
  ContentCardContainer,
};
