import { useEnv } from "@alphasights/client-portal-shared";

export const useLockedExpert = (interaction) => {
  const advisorLockState = interaction.advisorLockState || {};
  const env = useEnv();
  const disableAction = env.enableLockedExperts && advisorLockState.locked;
  return {
    disableAction: disableAction,
    locked: advisorLockState.locked,
    canRequest: env.enableLockedExperts ? advisorLockState.canRequest : true,
    canSchedule: env.enableLockedExperts ? advisorLockState.canSchedule : true,
    canFollowUp: env.enableLockedExperts ? advisorLockState.canFollowUp : true,
    tooltipText: disableAction ? "Expert is no longer available" : null,
  };
};

export const useAnyLockedExperts = (interactions) => {
  const env = useEnv();
  const lockedExperts = interactions.filter((interaction) => interaction.advisorLockState.locked);
  const disableAction = env.enableLockedExperts && lockedExperts.length > 0;

  const expertNames = lockedExperts?.map((e) => e.name)?.join(", ") || "";

  return {
    disableAction: disableAction,
    tooltipText: disableAction
      ? `${expertNames} ${lockedExperts.length > 1 ? " are" : " is"}
      no longer available. Reach out to your Project Lead for more information.`
      : null,
  };
};
