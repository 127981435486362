import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing } = useThemeTokens();
  return {
    wrapper: {
      backgroundColor: "white",
      h: "100%",
      p: spacing.inner.base06,
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
    },
    suggestionWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
    },
    suggestionAngle: {
      color: "assistive",
      m: spacing.inner.base02,
    },
  };
};
