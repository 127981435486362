import React from "react";
import styled from "@xstyled/styled-components";
import { Tooltip } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import { buildSearchQuery } from "content/AlphaNow/utils";
import { HighlightablePill, SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";

import { useAppSearchContext } from "providers/AppSearchProvider";

const CompanyPill = styled(HighlightablePill)`
  width: 100%;
  max-width: fit-content;
  span:nth-child(2) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const CellEllipsis = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsisCell = (props: any) => <CellEllipsis title={props.getValue()}>{props.getValue()}</CellEllipsis>;

const CompanyCell = (props: any) => {
  const { updateQuery } = useAppSearchContext();
  const isSelectedCompany = props.row.original.isSelectedCompany;
  const companyName = props.getValue();
  const companyId = props.row.original.companyId;

  const handleClickCompanyPill = () => {
    const newSearchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Company,
      value: companyName,
      id: companyId,
    });
    updateQuery({
      searchQuery: newSearchQuery,
    });
  };

  return (
    <Tooltip title={companyName} position="bottom">
      <CompanyPill
        name={companyName}
        icon={<Company />}
        highlight={isSelectedCompany}
        onClick={handleClickCompanyPill}
        isInteractive
      />
    </Tooltip>
  );
};

const COLUMN_CONFIG = {
  minSize: 208,
  cell: EllipsisCell,
};

const COLUMNS = [
  { id: "companyName", header: "Company", cell: CompanyCell },
  { id: "angleType", header: "Angle" },
  { id: "position", header: "Position" },
  { id: "dateHeld", header: "Tenure" },
];

const buildColumnDefs = () =>
  COLUMNS.map((columnDef) => ({
    ...COLUMN_CONFIG,
    accessorKey: columnDef.id,
    ...columnDef,
  }));

export { buildColumnDefs, COLUMNS };
