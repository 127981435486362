import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { x } from "@xstyled/styled-components";
import { PrimerDataProps } from "./components/PrimerCard/types";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import useModal from "hooks/useModal";
import PrimerCard from "pages/AlphaNowPage/components/AlphaNowCompanyPage/PrimerSection/components/PrimerCard";
import { contentService } from "services/content";
import { COMPANY_TYPE, CONTENT_TYPE, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import {
  SectionHeader,
  SectionWrapper,
  SECTION_TITLES,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import { useAppSearchContext } from "providers/AppSearchProvider";
import {
  CommissionModal,
  CommissionButton as CommissionPrimerButton,
} from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import { useScroll } from "pages/AlphaNowPage/components/ScrollContext";
import useGetModalFromUrl from "pages/AlphaNowPage/primers/hooks/useGetModalFromUrl";
import { COMMISSION_URL_PARAM } from "pages/AlphaNowPage/primers/CommissionPrimer/constants";

import { PrimersContainer } from "./styled";

const DataTestIds = {
  PrimerSection: "company-page-primer-section",
};

type PrimerSectionProps = {
  companyName: string;
  companyId: number;
  onDataFetch?: (data: any) => void;
  isLoadingContent?: boolean;
};

const primerProducts = [
  AlphaNowSRMProductType.companyPrimer,
  AlphaNowSRMProductType.marketPrimer,
  AlphaNowSRMProductType.customerPrimer,
];

const PrimerSection: FC<PrimerSectionProps> = ({ companyName, companyId, onDataFetch, isLoadingContent }) => {
  const { logHit } = useTrackUserAction();
  const { updateQuery } = useAppSearchContext();
  const {
    isVisible: isCommissionModalVisible,
    onOpen: onOpenCommissionModal,
    onClose: onCloseCommissionModal,
  } = useModal();
  const { scrollToElement } = useScroll();
  const [requestedPrimerFormat, setRequestedPrimerFormat] = useState<AlphaNowSRMProductType>();

  const handleOpenModal = (primerFormat?: AlphaNowSRMProductType) => {
    setRequestedPrimerFormat(primerFormat);
    onOpenCommissionModal();
    logHit({
      origin: HitOrigin.companyPage,
      action: HitAction.alphaNowRequestPrimerAttempt,
      details: {
        companyId,
      },
    });
  };

  useGetModalFromUrl(onOpenCommissionModal, COMMISSION_URL_PARAM);

  const { data, isSuccess, isError } = useQuery(
    ["CompanyPrimer", companyId],
    () =>
      contentService.fetchContent({
        page: 0,
        pageSize: 3,
        filters: {
          content_types: [CONTENT_TYPE.companyPrimer],
        },
        // @ts-ignore - ignoring due to content.js not being typed
        companyType: COMPANY_TYPE.anchor,
        // @ts-ignore - ignoring due to content.js not being typed
        companies: [{ id: companyId, name: companyName }],
      }),
    {
      enabled: !!companyId && !!companyName,
    }
  );

  const isSettled = isSuccess || isError;

  const orderedPrimers = primerProducts.map(
    (item) => data?.results?.find((value: PrimerDataProps) => value?.productType === item) || null
  );

  useEffect(() => {
    if (onDataFetch && isSettled) {
      const contentIds = orderedPrimers.map((item: PrimerDataProps) => item?.id).filter((item) => item !== undefined);
      onDataFetch({ contentIds });
    }
  }, [isSettled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCardClick = (contentId?: string, primerFormat?: AlphaNowSRMProductType) => {
    if (contentId) {
      updateQuery({ selectedContentId: contentId });
      scrollToElement();
      logHit({
        origin: HitOrigin.companyPage,
        action: HitAction.alphaNowViewCompanyPrimer,
        details: {
          companyId,
          contentId,
        },
      });
    } else {
      handleOpenModal(primerFormat);
    }
  };

  if (!isSuccess) return null;

  return (
    <SectionWrapper data-testid={DataTestIds.PrimerSection} hasDivider={false}>
      <x.div display="flex" alignItems="center" justifyContent="space-between">
        <SectionHeader title={SECTION_TITLES.Primers} companyName={companyName} />
        <CommissionPrimerButton onClick={handleOpenModal} isCompanyPage />
      </x.div>
      {isCommissionModalVisible && (
        <CommissionModal
          productType={requestedPrimerFormat}
          onClose={onCloseCommissionModal}
          origin={HitOrigin.companyPage}
        />
      )}
      <PrimersContainer>
        {orderedPrimers.map((primerData, index) => (
          <PrimerCard key={index} index={index} primerData={primerData} onClick={handleCardClick} />
        ))}
      </PrimersContainer>
    </SectionWrapper>
  );
};

export { PrimerSection as default, DataTestIds };
