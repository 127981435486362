import React, { FC, useMemo } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";
import { groupBy, sortBy, kebabCase, entries } from "lodash";
import { filter, isEmpty, negate } from "lodash/fp";
import pluralize from "pluralize";

import ExpertCard, { formatAngleTypeName } from "../ExpertCard";
import ExpertCount from "../ExpertCount";
import { EXPERT_CATEGORIZATION_ORDER } from "constants/CompanyPrimers";
import { sortByOrderOf } from "utils/sorting";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import "components/Experts/index.css";
import { RequestSurveyBanner } from "pages/AlphaNowPage/primers/components";
import { RequestSurveyBannerViewModes } from "pages/AlphaNowPage/primers/request-survey/banner/RequestSurveyBanner";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const ExpertsCategorizedByAngleContainer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }
`;

export const angleTypeName = (expert: Speaker) => expert.angleTypes[0].name;

export const DataTestIds = {
  expertsCategorizedByAngle: "experts-categorized-by-angle-container",
  sectionForAngleType: (angleType: string) => kebabCase(`${angleType} Section`),
  header: "experts-catagorized-by-angle-header",
};

/**
 * Uses the specified angle type ordering for primers ({@link EXPERT_CATEGORIZATION_ORDER})
 * to generate an ordered map of angle types to the experts in that angle type.
 */
export const groupAndSortExpertsByAngleTypeName = (experts: Speaker[]): Map<string, Speaker[]> => {
  const orderedExperts = sortByOrderOf({
    collection: experts,
    compareBy: angleTypeName,
    orderedBy: EXPERT_CATEGORIZATION_ORDER,
  });

  return new Map(entries(groupBy(orderedExperts, angleTypeName)));
};

interface ExpertsCategorizedByAngleProps {
  contentId: string;
  productType?: string;
  selectedExperts: Speaker[];
  unselectedExperts: Speaker[];
  setIsExpertsView: (any: boolean) => void;
  isAccessible: boolean;
}

const ExpertsCategorizedByAngle: FC<ExpertsCategorizedByAngleProps> = ({
  contentId,
  productType,
  selectedExperts,
  unselectedExperts,
  setIsExpertsView,
  isAccessible,
}) => {
  const allExperts = [...selectedExperts, ...unselectedExperts];
  if (!allExperts.length) return null;

  const expertsByAngleType = groupAndSortExpertsByAngleTypeName(allExperts);

  const isNotEmpty = negate(isEmpty);

  const sortedExperts = [...expertsByAngleType.values()];
  const unselectedExpertIds = unselectedExperts.map((it) => it.id);
  const expertSections = filter(isNotEmpty)(sortedExperts).map((experts) => {
    const key = DataTestIds.sectionForAngleType(angleTypeName(formatAngleTypeName(experts[0])));

    return (
      <ExpertSection
        data-testid={key}
        contentId={contentId}
        productType={productType}
        unselectedExpertIds={unselectedExpertIds}
        haveExpertsSelected={selectedExperts.length > 0}
        listTestId={key}
        {...{
          key,
          experts,
          isAccessible,
          setIsExpertsView,
        }}
      />
    );
  });

  return (
    <ExpertsCategorizedByAngleContainer data-testid={DataTestIds.expertsCategorizedByAngle}>
      {expertSections.length && expertSections}
    </ExpertsCategorizedByAngleContainer>
  );
};

interface ExpertSectionProps {
  contentId: string;
  productType?: string;
  experts: Speaker[];
  haveExpertsSelected: boolean;
  unselectedExpertIds: number[];
  setIsExpertsView: (any: boolean) => void;
  isAccessible?: boolean;
  listTestId?: string;
}

const ExpertSection: FC<ExpertSectionProps> = ({
  contentId,
  productType,
  experts,
  haveExpertsSelected,
  unselectedExpertIds,
  isAccessible,
  listTestId,
  setIsExpertsView,
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  // checks for request survey banner
  const isNotFreePrimer = ![
    freePrimers.companyPrimer as string,
    freePrimers.marketPrimer as string,
    freePrimers.customerPrimer as string,
  ].includes(contentId);
  const { isAccessible: isPrimerAccessible } = usePrimersStore();
  const shouldShowRequestSurveyBanner =
    isNotFreePrimer && isPrimerAccessible && productType === AlphaNowProductType.customerPrimer && !haveExpertsSelected;

  // we want to display the expert in the same location when clicked if in paywall
  const sortedExperts = useMemo(
    () =>
      sortBy(experts, (expert) => expert.order || 0).filter((it) =>
        isAccessible ? unselectedExpertIds.includes(it.id) : experts
      ),
    [experts, isAccessible, unselectedExpertIds]
  );

  const angleName = angleTypeName(formatAngleTypeName(experts[0]));

  return (
    <>
      <x.div
        alignItems="center"
        display="flex"
        pb={inner.base02}
        textTransform="uppercase"
        data-testid={DataTestIds.header}
      >
        <Typography variant={isMobile ? "body-em" : "body-small-em"} color={text.secondary}>
          {pluralize(angleName)}
          <ExpertCount count={experts.length} />
        </Typography>
      </x.div>

      {shouldShowRequestSurveyBanner && <RequestSurveyBanner viewMode={RequestSurveyBannerViewModes.CustomerPrimer} />}

      <ExpertsContainer data-testid={listTestId}>
        {sortedExperts.map((expert) => (
          <ExpertCard
            className="alphanow-fade-in"
            key={expert.id}
            speaker={expert}
            contentId={contentId}
            isSelected={!isAccessible && !unselectedExpertIds.includes(expert.id)}
            {...{ isAccessible, setIsExpertsView }}
          />
        ))}
      </ExpertsContainer>
    </>
  );
};

const ExpertsContainer = styled.div`
  margin-bottom: 24px;
  div:first-child {
    margin-top: 0;
  }
`;

export default ExpertsCategorizedByAngle;
