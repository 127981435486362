import React, { useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { Avatar, Button, Divider, Icon } from "@alphasights/alphadesign-components";
import { ChevronRight, Quote } from "@alphasights/alphadesign-icons";
import { useKeyQuotesStyles } from "./KeyQuotes.style";
import { HighlightAiSummaryPart, SummaryType, Sentence, AccumulatedCue } from "./AiSummaries.types";
import { accumulatedCues, tagHighlight } from "../transcripts/support";
import { EmphasisTypography } from "../transcripts/EmphasisTypography";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { cleanKeyQuote } from "./helpers";
import { getSentencesIds } from "../helpers";

interface KeyQuotesProps {
  transcriptContent: HighlightAiSummaryPart[];
  interactionId: string;
  transcriptId: string;
  sentences: Sentence[];
  onTranscriptLinkClick: (cueIds: number[]) => void;
}

export const KeyQuotes = ({
  transcriptContent,
  interactionId,
  transcriptId,
  sentences,
  onTranscriptLinkClick,
}: KeyQuotesProps) => {
  const styles = useKeyQuotesStyles();

  const accumulated = useMemo<AccumulatedCue[]>(() => accumulatedCues(sentences), [sentences]);

  return (
    <x.div {...styles.quotesListWrapper} data-testid="ai-summary-key-quotes">
      {transcriptContent.map((keyQuote, idx) => (
        <React.Fragment key={idx}>
          <KeyQuote
            keyQuote={keyQuote}
            interactionId={interactionId}
            transcriptId={transcriptId}
            accCues={accumulated}
            onTranscriptLinkClick={onTranscriptLinkClick}
          />
          {idx + 1 < transcriptContent.length && <Divider spacing="zero" {...styles.divider}></Divider>}
        </React.Fragment>
      ))}
    </x.div>
  );
};

const KeyQuote = ({
  keyQuote,
  interactionId,
  transcriptId,
  accCues,
  onTranscriptLinkClick,
}: {
  keyQuote: HighlightAiSummaryPart;
  interactionId: string;
  transcriptId: string;
  accCues: AccumulatedCue[];
  onTranscriptLinkClick: (cueIds: number[]) => void;
}) => {
  const styles = useKeyQuotesStyles();
  const { logHit } = useTrackUserAction();
  const { project } = useDeliverableContext();

  const quoteContent = cleanKeyQuote(keyQuote.text);
  const cueIds = getSentencesIds(accCues, quoteContent);

  const transcriptLinkAction = () => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.aiSummaryViewTranscript,
      projectToken: project.token,
      advisorshipId: interactionId,
      details: {
        aiSummaryType: SummaryType.KeyQuotes,
      },
      references: { transcriptId: transcriptId },
    });
    cueIds.length && onTranscriptLinkClick && onTranscriptLinkClick(cueIds);
  };

  return (
    <x.div role="quote" {...styles.quoteWrapper}>
      <x.div display="flex">
        <x.div>
          <Avatar size="small">
            <Quote />
          </Avatar>
        </x.div>
        <EmphasisTypography component="i" {...styles.quote}>
          "{keyQuote.hasHighlight ? <>{tagHighlight(keyQuote.text)}</> : keyQuote.text}"
        </EmphasisTypography>
      </x.div>

      {cueIds.length > 0 && (
        <Button
          data-testid="transcript-view-button"
          variant={"ghost"}
          size={"small"}
          onClick={transcriptLinkAction}
          endIcon={
            <Icon size="small">
              <ChevronRight />
            </Icon>
          }
          {...styles.viewTranscriptButtonStyles}
        >
          View in Transcript
        </Button>
      )}
    </x.div>
  );
};
