import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { CidBadge } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";

const CidCell = ({ props }: SurveyCellProps) => {
  const userCidEnabled = useUserCidEnabled();
  return <CidBadge userCidEnabled={userCidEnabled} externalCidStatus={props.getValue()} />;
};

export const CidColumn = {
  id: "CidColumn",
  accessorKey: "interaction.externalCidStatus",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <CidCell props={props} />,
  header: "CID Status",
  minSize: 50,
};
