import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { color, shape } = tokens;

export const reactSelectStyle = {
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: color.background.info,
    color: color.text.strong._,
    margin: "4px",
    borderRadius: "8px",
  }),
  multiValueLabel: (styles, { selectProps: { fontSize } }) => ({
    ...styles,
    color: color.text.info,
    fontSize: fontSize,
    fontWeight: "500",
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    borderRadius: "8px",
  }),

  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: state.isFocused ? color.background.neutral.subtle : "",
  }),
  control: (
    base,
    { selectProps: { border, borderRadius, boxShadow, height, maxHeight, overflowY, isTranscript }, isFocused, options }
  ) => {
    const focusBoxShadow = isFocused ? `${isTranscript ? "inset" : ""} 0 0 0 1px #3c83f6 ` : boxShadow;

    const style = {
      border: 0,
      boxShadow: focusBoxShadow,
      color: color.text.secondary,
      height: height,
      maxHeight: maxHeight,
      overflowY: overflowY,
      cursor: "pointer",
      "&:hover": {
        border: 0,
      },
    };

    if (options.length > 0) {
      if (!isTranscript) {
        style.borderRadius = 0;
        style.borderTopLeftRadius = borderRadius;
        style.borderTopRightRadius = borderRadius;
      } else {
        style.border = border;
      }
    }

    return {
      ...base,
      ...style,
    };
  },
  container: (styles, { selectProps: { height, fontSize } }) => ({
    ...styles,
    height: height,
    fontSize: fontSize,
  }),
  menu: (styles, { selectProps: { border, borderRadius, fontSize, isTranscript }, options }) => ({
    ...styles,
    border: isTranscript ? border : 0,
    borderTop: isTranscript && `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    borderRadius: 0,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    boxShadow: isTranscript ? 0 : shape.shadow.tertiary,
    fontSize: fontSize,
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: isTranscript ? getSearchBarWidth(options) : "100%",
  }),

  menuList: (styles) => {
    // There should be 6 options in the dropdown. Each of these have 8px padding on bottom and top.
    // 23px icon used which sets height of span
    // 8px padding at bottom of drop should be added
    const optionHeight = `${(8 * 2 + 23) * 6 + 8}px`;
    return {
      ...styles,
      maxHeight: optionHeight,
      paddingTop: 0,
    };
  },

  valueContainer: (styles, { selectProps: { maxHeight } }) => {
    return {
      ...styles,
      paddingLeft: "16px",
      paddingRight: 0,
      maxHeight: maxHeight,
      overflow: "auto",
    };
  },
  dropdownIndicator: (styles, { selectProps: { showSeparator } }) => ({
    ...styles,
    paddingLeft: 0,
    borderRight: showSeparator && `${shape.border.width.lg} solid ${color.border.divider}`,
  }),

  placeholder: (base) => {
    return {
      ...base,
      color: color.text.secondary,
    };
  },
};

const getSearchBarWidth = (options, isFocused, hasValue, values) => {
  // When the searchbar contains values, the width needs to shrink in order to show the `add` icon next to the last pill
  // The width is made up of a fixed width + a variable one:
  // The 16px left padding + 6px input indicator + 22px dropdown indicator are always there and are the starting width
  // Each pill consists of 8px/letter + an extra 17px for paddings/margins + 22px for the delete cross
  const isDropdownOpen = options.length > 0;
  const widthWhenValues =
    hasValue && values.reduce((previous, current) => Number(previous) + Number(current.value.length * 8) + 17 + 22, 67);

  if (isFocused || !hasValue || isDropdownOpen) {
    return "50vh";
  } else {
    return `${widthWhenValues}px`;
  }
};
