import React, { useCallback, useContext, useEffect, useState } from "react";
import deliverablesService from "views/DeliverablesView/deliverablesService";
import { useCookiePolicy } from "@alphasights/client-portal-shared";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import useQueryParams from "hooks/useQueryParams";
import { useLocation } from "react-router-dom";
import { currentProjectView } from "helpers/currentView";
import { PARAM_CONTENT_SELECTED } from "views/DeliverablesView/DeliverablesView";

export const DeliverableContext = React.createContext();

export const DeliverableProvider = ({ project, service = deliverablesService, ...props }) => {
  const { logHit } = useTrackUserAction();
  const [bookmarkedInteractionIds, setBookmarkedInteractionIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasInteractionDeliverables, setHasInteractionDeliverables] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const { pathname } = useLocation();
  const queryParams = useQueryParams();
  const isDeliverablesView = currentProjectView(pathname) === "deliverables-view";

  const projectToken = project?.token;
  const currentUser = useCurrentUser();
  const { isPolicyAccepted } = useCookiePolicy();

  const projectAccessible = project && currentUser && (project.active || currentUser.pastProjectsEnabled);
  const canFetch = projectAccessible && isPolicyAccepted && currentUser;

  useEffect(
    function checkDeliverablesExist() {
      if (!projectToken) return;
      if (!canFetch) {
        setIsLoading(false);
        setHasInteractionDeliverables(false);
        setHasContent(false);
        return;
      }
      service
        .getInteractionsWithDeliverables(projectToken)
        .then((res) => {
          if (res?.edges?.length > 0) {
            setHasInteractionDeliverables(true);
          } else if (currentUser) {
            const param = { project: { token: projectToken } };
            return service.fetchAlphaNowContent(param).then((res) => {
              setHasContent(
                (previousValue) =>
                  previousValue ||
                  !!res.suggestedContent?.length ||
                  !!res.privateContent?.length ||
                  !!res.pitchedContent?.length
              );
            });
          }
        })
        .finally(() => setIsLoading(false));
    },
    [projectToken, currentUser, service, canFetch]
  );

  useEffect(
    function makeDeliverablesAvailableWhenFetchingContent() {
      const contentId = queryParams.get(PARAM_CONTENT_SELECTED);
      if (isDeliverablesView && contentId) {
        setHasContent(true);
      }
    },
    [isDeliverablesView, queryParams]
  );

  useEffect(
    function fetchDeliverableBookmark() {
      if (canFetch) {
        service.getBookmarks(project).then((ids) => setBookmarkedInteractionIds(ids));
      }
    },
    [service, project, canFetch]
  );

  const toggleInteractionBookmark = useCallback(
    (interaction) => {
      const { id, bookmarked, projectToken } = interaction;
      const toggleTo = !bookmarked;
      interaction.bookmarked = !interaction.bookmarked;
      service.setBookmark({ project, id, bookmarked: toggleTo }).then((bookmarked) => {
        const newBookmarkedIds = bookmarked
          ? [id, ...bookmarkedInteractionIds]
          : bookmarkedInteractionIds.filter((it) => it !== id);
        setBookmarkedInteractionIds(newBookmarkedIds);
      });

      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.transcriptBookmark,
        advisorshipId: id,
        projectToken,
        details: {
          toggleTo,
        },
      });
    },
    [service, project, bookmarkedInteractionIds, logHit]
  );

  const fetchDeliverable = useCallback(
    (transcriptRequest, keywords, fetchRaw = false) => {
      return service.fetchDeliverable({
        project,
        transcriptRequest,
        keywords,
        fetchRaw,
      });
    },
    [service, project]
  );

  const fetchAlphaNowContent = useCallback(
    (keywords) => {
      return service.fetchAlphaNowContent({
        project,
        keywords,
      });
    },
    [service, project]
  );

  const fetchAlphaNowContentById = useCallback(
    (params) => {
      return service.fetchAlphaNowContentById({
        project,
        ...params,
      });
    },
    [service, project]
  );

  const fetchContentById = useCallback(
    (id) => {
      return service.fetchContentById(id, project?.token);
    },
    [service, project]
  );

  const fetchContentCredits = useCallback(
    (contentId) => {
      return service.fetchContentCredits(project?.token, contentId);
    },
    [service, project]
  );

  const getAiSummaries = useCallback(
    (interactionId, recordingId) => {
      return service.getAiSummaries(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const getAiSummariesStatus = useCallback(
    (interactionId, recordingId) => {
      return service.getAiSummariesStatus(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const generateAiSummary = useCallback(
    (interactionId, recordingId) => {
      return service.generateAiSummary(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const deleteAiSummaries = useCallback(
    (interactionId, recordingId) => {
      return service.deleteAiSummaries(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const submitAiSummaryFeedback = useCallback(
    (interactionId, recordingId, transcriptId, summaryType, feedbackType) => {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.aiSummaryGiveFeedback,
        projectToken: project?.token,
        advisorshipId: interactionId,
        details: {
          aiSummaryType: summaryType,
          feedbackType: feedbackType,
        },
        references: { transcriptId: transcriptId },
      });

      return service.submitAiSummaryFeedback(project?.token, interactionId, recordingId, summaryType, feedbackType);
    },
    [service, project, logHit]
  );

  const removeAiSummaryFeedback = useCallback(
    (interactionId, recordingId, transcriptId, summaryType) => {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.aiSummaryGiveFeedback,
        projectToken: project?.token,
        advisorshipId: interactionId,
        details: {
          aiSummaryType: summaryType,
          feedbackType: "neutral",
        },
        references: { transcriptId: transcriptId },
      });

      return service.removeAiSummaryFeedback(project?.token, interactionId, recordingId, summaryType);
    },
    [service, project, logHit]
  );

  const createContentVisit = useCallback(
    ({ recommendationId, contentId }) => {
      return service.createContentVisit({ recommendationId, contentId, projectToken });
    },
    [service, projectToken]
  );

  const context = {
    project,
    bookmarkedInteractionIds,
    toggleInteractionBookmark,
    fetchDeliverable,
    fetchAlphaNowContent,
    fetchAlphaNowContentById,
    fetchContentById,
    hasDeliverables: hasInteractionDeliverables || hasContent,
    hasInteractionDeliverables,
    isLoading,
    fetchContentCredits,
    getAiSummaries,
    getAiSummariesStatus,
    generateAiSummary,
    deleteAiSummaries,
    submitAiSummaryFeedback,
    removeAiSummaryFeedback,
    createContentVisit,
  };

  return <DeliverableContext.Provider value={context} {...props} />;
};

export const useDeliverableContext = () => useContext(DeliverableContext);
