import React, { useEffect, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton } from "@alphasights/alphadesign-components";
import { useAlphaGPTCollapsibleListStyles } from "./AlphaGPTCollapsible.styles";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { ChevronDown } from "@alphasights/alphadesign-icons";

const ExpandButon = ({ onClick }: { onClick: () => void }) => {
  const { expandButton } = useAlphaGPTCollapsibleListStyles();

  return (
    <x.div {...expandButton} data-testid="expand-citations">
      <IconButton size="small" variant="outline" color="secondary" onClick={onClick}>
        <ChevronDown />
      </IconButton>
    </x.div>
  );
};

export const AlphaGptCollapsibleList = ({ collapse = false, items }: { collapse?: boolean; items: JSX.Element[] }) => {
  const { messages } = useAlphaGPTContext();
  const { wrapper } = useAlphaGPTCollapsibleListStyles();
  const [isCollapsed, setIsCollapsed] = useState(collapse && items.length > 1);
  const [initialHeight, setInitialHeight] = useState<number | undefined>(undefined);
  const wrapperRef = useRef<HTMLUListElement>(null);
  const firstElRef = useRef<HTMLElement>(null);

  useEffect(function saveInitialHeight() {
    setInitialHeight(wrapperRef.current?.getBoundingClientRect().height);
  }, []);

  useEffect(
    function collapseOnMessagesLengthChange() {
      setIsCollapsed(collapse && items.length > 1);
    },
    [items.length, collapse, messages.length]
  );

  return (
    <x.ul
      h={
        isCollapsed && firstElRef.current
          ? `${firstElRef.current.getBoundingClientRect().height + 54}px`
          : `${initialHeight}px`
      }
      ref={wrapperRef}
      {...wrapper}
    >
      {items.map((ListItem, idx) => React.cloneElement(ListItem, { ref: idx === 0 ? firstElRef : null }))}
      {isCollapsed && <ExpandButon onClick={() => setIsCollapsed(false)} />}
    </x.ul>
  );
};
