import { Popover } from "@alphasights/alphadesign-components";
import React, { forwardRef } from "react";
import { autoUpdate } from "@floating-ui/react-dom";
import styled from "styled-components";
import { shift } from "@floating-ui/react-dom";

const ZeroPaddingPopover = styled(Popover)`
  .body-content {
    padding: 0px;
  }
`;

export const CalendarPopover = forwardRef(({ referenceElement, children }, ref) => {
  return (
    <ZeroPaddingPopover
      ref={ref}
      open
      anchorEl={referenceElement}
      placement="left-start"
      whileElementsMounted={(ref, pop, update) =>
        autoUpdate(ref, pop, update, {
          ancestorScroll: false,
          ancestorResize: false,
          layoutShift: false,
          elementResize: true,
        })
      }
      middleware={[
        shift({
          padding: 12,
        }),
      ]}
      zIndex={100}
    >
      {children}
    </ZeroPaddingPopover>
  );
});
