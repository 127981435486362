import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { ConversationFragmentUtil } from "utils/conversationFragment";
import { ElementUtil } from "utils/element";

/**
 * Wrapper around useAudioPlayer to automatically scroll the transcript
 * The scrollable div around the transcript needs to contain `data-transcript-scrollable-area` tag
 * to try to detect when sroll was done automatically or by the user, in this case,
 * disabling the auto scroll.
 * Each transcript cue needs to contain `data-transcript-id=X` tag.
 */
export const useAutoScroll = ({ audioPlayer, onNonAutomaticScrollEnded }) => {
  const autoScroll = useRef(true);
  const scrollingAutomatically = useRef(true);
  const cbRef = useRef();

  cbRef.current = onNonAutomaticScrollEnded;

  const { controlsProps, progressBarProps, transcriptProps } = audioPlayer;
  const { activeCues } = transcriptProps;

  const scrollToCurrentFragment = () => {
    const trackId = +activeCues[0].id;
    const transcript = ConversationFragmentUtil.getConversationFragmentElement(trackId);
    transcript.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (!activeCues || activeCues.length === 0 || !autoScroll.current) return;

    const transcript = document.querySelector(`[data-transcript= 'highlightedCue']`);

    if (!transcript) return;

    if (ElementUtil.isElementXPercentInViewport(transcript, 1)) {
      transcript.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      scrollingAutomatically.current = true;
    }
  }, [activeCues]);

  useEffect(() => {
    const scrollableArea = document.querySelector("[data-transcript-scrollable-area]");

    if (!scrollableArea) return;

    const handleScrollingEnding = debounce(() => {
      if (autoScroll.current && scrollingAutomatically.current) {
        scrollingAutomatically.current = false;
        return;
      }

      autoScroll.current = false;
      cbRef.current && cbRef.current();
    }, 200);

    scrollableArea.addEventListener("scroll", handleScrollingEnding);

    return () => {
      scrollableArea.removeEventListener("scroll", handleScrollingEnding);
    };
  }, []);

  return {
    scrollToCurrentFragment,
    ...audioPlayer,
    controlsProps: {
      ...controlsProps,
      onTogglePlay: () => {
        if (!controlsProps.playing) autoScroll.current = true;

        controlsProps.onTogglePlay();
      },
    },
    progressBarProps: {
      ...progressBarProps,
      onMouseUp: (e) => {
        autoScroll.current = true;

        progressBarProps.onMouseUp(e);
      },
    },
    transcriptProps: {
      ...transcriptProps,
      movePlayerTo: (timestamp) => {
        autoScroll.current = true;

        transcriptProps.movePlayerTo(timestamp);
      },
    },
  };
};
