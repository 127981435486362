import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { Alert, Button, Icon, Loading, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { ArrowRight, Edit, GenerativeAi, Remove, Add } from "@alphasights/alphadesign-icons";
import { useStyles } from "./QuestionsTab.styles";
import { FloatingActions, useHelperActions } from "components/FloatingActions";
import { ViewType } from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import { HitAction, HitOrigin, StringWithReference, TranscriptQuestion } from "@alphasights/portal-api-client";
import { GenericMessagePage } from "../GenericMessagePage";
import { AccessControl, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { useCurrentUser, useIsInternalUser } from "@alphasights/portal-auth-react";
import _ from "lodash";
import { differenceInMinutes, parseISO } from "date-fns";
import { useHideDeliverablesContent } from "../useHideDeliverablesContent";
import { AiAskQuestionInput } from "components/AiAskQuestionInput";

export const QuestionsTab = () => {
  const {
    questions,
    currentTranscript,
    regenerateQuestions,
    selectedView,
    logHitDuration,
  } = useInteractionDeliverablesContext()!;
  const styles = useStyles();
  const { logHit } = useTrackUserAction();

  const maxScrollReached = useRef(0);
  const openedAt = useRef(Date.now());
  useEffect(() => {
    if (selectedView !== ViewType.Questions) return;
    openedAt.current = Date.now();
    const scrollableArea = document.querySelector("[data-questions-scrollable-area]");

    const computeMaxScrollReached = () => {
      if (!scrollableArea) return;
      const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;
      maxScrollReached.current = _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]) ?? 0;
    };

    scrollableArea?.addEventListener("scroll", computeMaxScrollReached);

    window.addEventListener("beforeunload", () =>
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptQuestionsRead)
    );

    return () => {
      window.removeEventListener("beforeunload", () =>
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptQuestionsRead)
      );
      scrollableArea?.removeEventListener("scroll", computeMaxScrollReached);
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptQuestionsRead);
    };
  }, [selectedView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedView === ViewType.Questions && (!questions || questions?.length === 0)) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewError,
        details: { tab: "Questions", message: "No user requested or generated questions found" },
      });
    }
  }, [selectedView, questions, logHit]);

  return (
    <x.div>
      <RegenerateButton
        questions={questions || []}
        currentTranscript={currentTranscript}
        regenerateQuestions={regenerateQuestions}
      />
      {questions ? (
        <Questions questions={questions} />
      ) : (
        <x.div {...styles.errorWrapper}>
          <Alert variant="danger" w="100%">
            <b>Error occurred:</b> unable to generate questions view. Please try refreshing the page.
          </Alert>
        </x.div>
      )}
    </x.div>
  );
};

const Questions = ({ questions }: { questions: TranscriptQuestion[] }) => {
  const { contentStyle, showContent } = useHideDeliverablesContent();
  const user = useCurrentUser();
  const { keywordSearchTargetEl, selectedView } = useInteractionDeliverablesContext()!;
  const { logHit } = useTrackUserAction();

  const [suggested, commonQuestions] = useMemo(
    () => _.partition(questions, (question) => question.type === "SUGGESTED"),
    [questions]
  );

  useEffect(() => {
    if (selectedView === ViewType.Questions && !questions.length) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewProcessing,
        details: { tab: "Questions", message: "No questions found" },
      });
    }
  }, [selectedView, questions, logHit]);

  if (selectedView === ViewType.Questions && !questions.length) {
    return (
      <GenericMessagePage
        title={"Questions view is generating"}
        message={"Check back in a few minutes..."}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  return (
    <x.div {...contentStyle} data-testid="questions-content">
      {user?.enableAiInteractivity && showContent && questions.length > 1 && (
        <Section>
          <NewQuestion suggestions={suggested} />
        </Section>
      )}
      <x.div ref={selectedView === ViewType.Questions ? keywordSearchTargetEl : undefined}>
        {commonQuestions.map((q) => (
          <Section key={q.id}>
            <Question question={q} />
          </Section>
        ))}
      </x.div>
    </x.div>
  );
};

const NewQuestion = ({ suggestions }: { suggestions: TranscriptQuestion[] }) => {
  const styles = useStyles();
  const { interaction, submitQuestion, currentTranscript, selectedView } = useInteractionDeliverablesContext()!;
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();
  const [submissionError, setSubmissionError] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitQuestion = (questionText: string, suggestion: boolean = false): string | Promise<void> => {
    if (!suggestion && questionText.length < 25) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewError,
        details: { tab: "Questions", message: "Input question too short" },
      });

      return Promise.reject("Input too brief. Please rephrase the question and try again.");
    }

    setIsLoading(true);
    const action = suggestion
      ? HitAction.transcriptQuestionsSelectedSuggestion
      : HitAction.transcriptQuestionsAddedQuestion;

    return submitQuestion({
      question: questionText,
      quotes: [],
      status: "PROCESSING",
      custom: true,
      transcriptRequestId: currentTranscript!.id,
      id: "",
      interactionId: interaction.id!,
    })
      .then((createdQuestion) => {
        logHit({
          origin: HitOrigin.deliverablesView,
          action,
          advisorshipId: interaction.id,
          projectToken: project?.token,
          details: { question: questionText },
          references: { questionId: createdQuestion?.id },
        });
      })
      .catch((createdQuestionAttempt) => {
        setSubmissionError(true);
        if (selectedView === ViewType.Questions) {
          logHit({
            origin: HitOrigin.deliverablesView,
            action: HitAction.deliverablesViewError,
            details: {
              jobId: createdQuestionAttempt.jobId || null,
              tab: "Questions",
              message: "Error submitting question",
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitQuestion = (questionText: string, suggestion: boolean = false): Promise<void> => {
    setCurrentQuestion(questionText);
    return onSubmitQuestion(questionText, suggestion) as Promise<void>;
  };

  return (
    <x.div {...styles.questionLoading} data-testid="ask-question-section">
      <EmphasisTypography component="span" variant="body-large-em">
        Add new question
      </EmphasisTypography>
      <AiAskQuestionInput
        text={""}
        onSubmit={(question: string) => handleSubmitQuestion(question)}
        onBlur={() => {}}
        isNewQuestion={true}
        isLoading={isLoading}
        data-testid="question-add"
      />
      <SuggestionsPanel
        suggestions={suggestions}
        currentQuestion={currentQuestion}
        handleSubmitQuestion={handleSubmitQuestion}
      />
      {submissionError && (
        <Alert
          w="100%"
          size="small"
          variant="danger"
          buttonsPosition="right"
          buttonPrimaryProps={{
            children: "Generate",
            variant: "outline",
            size: "small",
            onClick: () => onSubmitQuestion(currentQuestion),
          }}
        >
          <Typography>Unable to generate response. Please try again.</Typography>
        </Alert>
      )}
    </x.div>
  );
};

const SuggestionsPanel = ({
  suggestions,
  currentQuestion,
  handleSubmitQuestion,
}: {
  suggestions: TranscriptQuestion[];
  currentQuestion: string;
  handleSubmitQuestion: (question: string, suggestion: boolean) => Promise<void>;
}) => {
  const styles = useStyles();

  const MAX_SUGGESTIONS = 3;

  const currentSuggestions = useMemo(() => suggestions.filter(({ question }) => question !== currentQuestion), [
    suggestions,
    currentQuestion,
  ]);

  return (
    <>
      {currentSuggestions.length > 0 && (
        <x.div data-testid="suggestions-panel" {...styles.suggestions}>
          <Typography variant="body-em" {...styles.suggestionLabel}>
            Suggestions
          </Typography>
          <x.ul>
            {currentSuggestions.slice(0, MAX_SUGGESTIONS).map((suggestion) => (
              <x.li
                key={suggestion.id}
                {...styles.suggestionItem}
                onClick={() => handleSubmitQuestion(suggestion.question, true)}
              >
                <Icon size="medium" {...styles.addSuggestion}>
                  <Add />
                </Icon>
                <Typography>{suggestion.question}</Typography>
              </x.li>
            ))}
          </x.ul>
        </x.div>
      )}
    </>
  );
};

const Section = ({ children }: { children: ReactElement }) => {
  const styles = useStyles();
  return (
    <x.div {...styles.questions}>
      <x.div {...styles.questionWrapper}>{children}</x.div>
    </x.div>
  );
};

const Question = ({ question }: { question: TranscriptQuestion }) => {
  return (
    <x.div data-testid="question-section">
      {question.status === "PROCESSING" ? (
        <ProcessingQuestion question={question} />
      ) : (
        <CompleteQuestion question={question} />
      )}
    </x.div>
  );
};

const ProcessingQuestion = ({ question }: { question: TranscriptQuestion }) => {
  const styles = useStyles();
  return (
    <x.div data-testid={`processing-question-${question.id}`} {...styles.questionLoading}>
      <EmphasisTypography component="span" variant="body-em">
        {question.question}
      </EmphasisTypography>
      <x.div {...styles.loadingMessageWrapper}>
        <Icon size="medium" {...styles.purple}>
          <Loading />
        </Icon>
        <Typography>Searching for expert insights...</Typography>
      </x.div>
      <x.div {...styles.skeletonWrapper}>
        <Skeleton variant="noMargin" width="70%" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </x.div>
  );
};
const CompleteQuestion = ({ question }: { question: TranscriptQuestion }) => {
  const styles = useStyles();
  const wrapperRef = useRef(null);
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();
  const { copyNoFormatTextAction, copyQuoteAction, copyRefAction } = useHelperActions();
  const {
    interaction,
    focusOnTranscriptIndex,
    setSelectedView,
    updateQuestion,
    deleteQuestion,
    transcriptFeaturesEnabled,
    selectedView,
  } = useInteractionDeliverablesContext()!;

  const onEditQuestion = (questionText: string) => {
    if (questionText.length < 25) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewError,
        details: { tab: "Questions", message: "Input question too short" },
      });

      return Promise.reject("Input too brief. Please rephrase the question and try again.");
    }

    return updateQuestion({ ...question, question: questionText })
      .then(() => {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptQuestionsEditedQuestion,
          advisorshipId: interaction.id,
          projectToken: project?.token,
          details: { question: questionText },
          references: { questionId: question.id },
        });
      })
      .finally(() => setEditing(false));
  };
  const onRemoveQuestion = () =>
    deleteQuestion(question).then(() => {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.transcriptQuestionsDeletedQuestion,
        advisorshipId: interaction.id,
        projectToken: project?.token,
        references: { questionId: question.id },
      });
    });
  const user = useCurrentUser();

  const [editing, setEditing] = useState(false);

  const navTo = useCallback(
    ({ quote, linkType }: { quote: StringWithReference; linkType: string }) => ({
      icon: <ArrowRight />,
      tooltip: "View in transcript",
      testid: "nav",
      onClick: () => {
        focusOnTranscriptIndex({ transcriptIx: quote.transcriptIndex, text: quote.value });
        setSelectedView(ViewType.Transcript);
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptQuestionsViewInTranscript,
          advisorshipId: interaction.id,
          projectToken: project?.token,
          details: { linkType },
        });
      },
    }),
    [interaction, project, focusOnTranscriptIndex, setSelectedView, logHit]
  );
  const onCopy = useCallback(() => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptQuestionsCopy,
      advisorshipId: interaction.id,
      projectToken: project?.token,
    });
  }, [interaction, project, logHit]);
  const quoteAuthor = `${interaction.role || ""}, ${interaction.advisorCompany || ""}`;

  const Wrapper = !editing ? FloatingActions : x.div;

  useEffect(
    function logHitError() {
      if (question.status !== "FAILED" || selectedView !== ViewType.Questions) return;
      logHit({
        origin: HitOrigin.deliverablesView,
        action: "INTERACTION_QUESTIONS_ERROR_GENERATE_QUESTION" as HitAction,
        advisorshipId: interaction.id,
        projectToken: project?.token,
        references: { questionId: question.id },
      });
    },
    [question, interaction, project, logHit, selectedView]
  );

  return (
    <x.div data-testid={`question-${question.id}`} {...styles.question} ref={wrapperRef}>
      {user?.enableAiInteractivity && editing && (
        <AiAskQuestionInput
          text={question.question}
          onSubmit={onEditQuestion}
          onBlur={() => setEditing(false)}
          data-testid="question-edit"
        />
      )}
      <Wrapper
        data-testid="floating-actions-question"
        highlightOnHover={false}
        actions={[
          copyRefAction(wrapperRef),
          ...(user?.enableAiInteractivity
            ? [
                { icon: <Edit />, tooltip: "Edit question", onClick: () => setEditing(true), testid: "edit" },
                { icon: <Remove />, tooltip: "Remove question", onClick: onRemoveQuestion, testid: "remove" },
              ]
            : []),
        ]}
      >
        <EmphasisTypography data-testid={`question-text-${question.id}`} {...styles.questionText} variant="body-em">
          {question.question}
        </EmphasisTypography>
        {question.answer && (
          <FloatingActions
            data-testid="floating-actions-answer"
            highlightOnHover={true}
            actions={_.compact([
              copyNoFormatTextAction(question.answer!!, "Copy summary", onCopy),
              transcriptFeaturesEnabled &&
                question.quotes[0] &&
                navTo({ quote: question.quotes[0]!!, linkType: "answer" }),
            ])}
          >
            <x.div {...styles.answerWrapper}>
              <Icon color="rgba(101, 52, 234, 1)" size="large">
                <GenerativeAi />
              </Icon>
              <EmphasisTypography
                component={"span"}
                data-testid={`question-answer-${question.id}`}
                id={`question-answer-${question.id}`}
              >
                {question.answer}
              </EmphasisTypography>
            </x.div>
          </FloatingActions>
        )}

        {question.quotes?.map((q) => (
          <x.div key={q.transcriptIndex}>
            <FloatingActions
              data-testid="floating-actions-quote"
              highlightOnHover={true}
              actions={_.compact([
                copyQuoteAction(q.value, quoteAuthor, "Copy quote"),
                transcriptFeaturesEnabled && navTo({ quote: q, linkType: "quote" }),
              ])}
            >
              <EmphasisTypography component="div" data-testid={`question-quote-${question.id}`} {...styles.quote}>
                "{q.value}"
              </EmphasisTypography>
            </FloatingActions>
          </x.div>
        ))}
      </Wrapper>
      {question.status === "FAILED" && (
        <Alert variant="danger" w="100%" data-testid={`failed-question-${question.id}`} {...styles.errorAlert}>
          <b>Error occurred:</b> unable to process this question. Please try rephrasing it.
        </Alert>
      )}
    </x.div>
  );
};

const RegenerateButton = ({
  questions,
  regenerateQuestions,
  currentTranscript,
}: {
  questions: TranscriptQuestion[];
  regenerateQuestions: () => void;
  currentTranscript?: TranscriptRequest;
}) => {
  const isInternal = useIsInternalUser();
  const [clicked, setClicked] = useState(false);

  const shouldHaveCompletedAlready = useMemo(
    () =>
      currentTranscript?.completed &&
      currentTranscript?.visibleToClientUpdatedAt &&
      differenceInMinutes(new Date(), parseISO(currentTranscript?.visibleToClientUpdatedAt)) > 90,
    [currentTranscript]
  );

  const noQuestions = useMemo(() => questions.length === 0, [questions]);
  if (!currentTranscript?.completed) return null;

  return isInternal || (shouldHaveCompletedAlready && noQuestions) ? (
    <AccessControl allowedPermissions={["access_transcripts_and_recordings"]}>
      <Section>
        <x.div textAlign={"center"}>
          <Button
            variant="outline"
            disabled={!!clicked}
            startIcon={clicked ? <Loading /> : undefined}
            onClick={() => {
              regenerateQuestions();
              setClicked(true);
            }}
            dataAttributes={{ "data-testid": "regenerate-questions" }}
          >
            {clicked ? "Restarting..." : "Restart Questions Extraction"}
          </Button>
        </x.div>
      </Section>
    </AccessControl>
  ) : null;
};
