import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { Row } from "@tanstack/react-table";
import { getRolePeriodDuration } from "helpers/displayHelpers";

const TenureCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  return <div data-testid={`survey-tenure-column-${interaction.id}`}>{props.getValue()}</div>;
};

const sortTenure = (rowA: Row<any>, rowB: Row<any>) => {
  const period1 = getRolePeriodDuration(rowA.original.interaction.rolePeriod);
  const period2 = getRolePeriodDuration(rowB.original.interaction.rolePeriod);

  return period1 > period2 ? 1 : -1;
};

export const TenureColumn = {
  id: "TenureColumn",
  accessorKey: "interaction.rolePeriod",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <TenureCell props={props} />,
  header: "Tenure",
  minSize: 50,
  sortingFn: sortTenure,
  meta: {
    sortAscMeta: {
      label: "Sort Shortest-Longest",
    },
    sortDescMeta: {
      label: "Sort Longest-Shortest",
    },
  },
};
