import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@alphasights/alphadesign-components";

const SpeakerTitles = ({ speakers, typographyVariant = "body", color, id = "" }) => (
  <div id={id}>
    {speakers.map(({ jobTitle, company }, index) => (
      <Typography key={index} variant={typographyVariant} component="span" color={color}>
        {index !== 0 && " • "}
        <Typography variant={`${typographyVariant}-em`} component="span">
          {company}
        </Typography>
        <Typography variant={typographyVariant} component="span">
          {` - ${jobTitle}`}
        </Typography>
      </Typography>
    ))}
  </div>
);

SpeakerTitles.propTypes = {
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      isModerator: PropTypes.bool,
      jobTitle: PropTypes.string,
      company: PropTypes.string,
    })
  ).isRequired,
  typographyVariant: PropTypes.string,
};

export default SpeakerTitles;
