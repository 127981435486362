import React, { useCallback, useEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

export const SwipeableItem = ({
  children,
  onItemSwiped,
  previewSwipe = false,
  ...props
}: {
  children: JSX.Element;
  onItemSwiped: () => void;
  previewSwipe?: boolean;
}) => {
  const { wrapper, background, bouncingListItem, dataList, deleteGroup } = useSwipeableItemStyles();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listElementRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  const dragStartXRef = useRef(0);
  const leftRef = useRef(0);
  const draggedRef = useRef(false);

  const updatePosition = useCallback(() => {
    if (listElementRef.current) {
      listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
    }
  }, []);

  const onTouchMove = useCallback(
    (evt: TouchEvent) => {
      const touchTarget = evt.targetTouches[0];
      const left = touchTarget.clientX - dragStartXRef.current;
      if (left < 0) {
        leftRef.current = left;
        updatePosition();
      }
    },
    [updatePosition]
  );

  const onTouchStart = useCallback(
    (evt: React.TouchEvent<HTMLDivElement>) => {
      window.addEventListener("touchmove", onTouchMove);

      const touchTarget = evt.targetTouches[0];
      draggedRef.current = true;
      dragStartXRef.current = touchTarget.clientX;
    },
    [onTouchMove]
  );

  const onTouchEnd = useCallback(() => {
    window.removeEventListener("touchmove", onTouchMove);
    if (draggedRef.current) {
      draggedRef.current = false;
      // This defines how much the user will need to drag the item to trigger the action.
      // The greater the number, the more the user will need to drag the item to trigger the action.
      const threshold = 0.5;

      if (leftRef.current < listElementRef.current!.offsetWidth * threshold * -1) {
        leftRef.current = listElementRef.current!.offsetWidth * -2;
        updatePosition();
        onItemSwiped();
      } else {
        leftRef.current = 0;
        updatePosition();
      }
    }
  }, [onItemSwiped, onTouchMove, updatePosition]);

  useEffect(() => {
    window.addEventListener("touchend", onTouchEnd);
    return () => {
      window.removeEventListener("touchend", onTouchEnd);
    };
  }, [onTouchEnd]);

  const animatePreviewSwipe = useCallback(() => {
    listElementRef.current!.style.transition = "transform 0.6s ease-out";
    leftRef.current = -100;
    updatePosition();
    setTimeout(() => {
      leftRef.current = 0;
      updatePosition();
      setTimeout(() => {
        listElementRef.current!.style.transition = bouncingListItem.transition;
      }, 600);
    }, 600);
  }, [updatePosition, bouncingListItem.transition]);

  useEffect(() => {
    previewSwipe && animatePreviewSwipe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <x.div ref={wrapperRef} {...wrapper}>
        <x.div ref={listElementRef} onTouchStart={onTouchStart} data-testid="swipeable-item" {...bouncingListItem}>
          <x.div {...dataList} {...props}>
            {children}
          </x.div>
        </x.div>
        <x.div ref={backgroundRef} {...background} data-testid="delete-bg">
          <x.div {...deleteGroup}>
            <Icon size="large" color="danger">
              <Delete />
            </Icon>
            <Typography color="danger">Delete</Typography>
          </x.div>
        </x.div>
      </x.div>
    </>
  );
};

const useSwipeableItemStyles = () => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  return {
    wrapper: {
      position: "relative",
      backgroundColor: background.danger.pressed,
    },
    background: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: inner.base08,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    bouncingListItem: {
      position: "relative",
      zIndex: 1,
      transition: "transform 0.1s ease-out",
    },
    dataList: {
      backgroundColor: background.inverse,
    },
    deleteGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };
};
