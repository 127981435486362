import { compact, isEmpty, omit } from "lodash";
import pluralize from "pluralize";
import { AlphaNowProductType, PromiseStatus, SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";

import {
  COLLEAGUE_SEARCH_ID,
  COMPANY_SEARCH_ID,
  MARKET_SEARCH_ID,
  MAX_RESULTS_DISPLAY,
} from "pages/AlphaNowPage/components/AlphaNowSearch/constants";
import { Filters } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { DATE_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/DateFilter";
import { SORT_OPTIONS } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/SortByFilter";
import { HEADCOUNT_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HeadcountFilter";
import { REVENUE_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/RevenueFilter";
import {
  AlphaNowSearchResult,
  CompanyDataAttributePayload,
  FiltersPayload,
} from "pages/AlphaNowPage/components/AlphaNowSearch/types";
import { AngleValues } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/AngleFilter";
import { SPEAKER_ANGLE_TYPE_NAME } from "constants/AlphaNow";
import { over20 } from "content/AlphaNow";
import { QueryItem } from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import {
  ColleagueSearchResult,
  CompanySearchResult,
  SearchPromise,
  MarketSearchResult,
} from "pages/AlphaNowPage/components/AlphaNowSearch/types";
import { Operator, Symbol, SearchOption } from "components/Search";
import { isBooleanSearch } from "components/Search/utils";

const pluralizeResults = (num: number) => pluralize("result", num);

const getNumberResultsDisplay = (numResults: number, productTypes: AlphaNowProductType[]) => {
  const hasOverThresholdCustomerPrimers =
    productTypes.includes(AlphaNowProductType.customerPrimer) && numResults > 20 && numResults <= MAX_RESULTS_DISPLAY;
  return hasOverThresholdCustomerPrimers ? over20 : numResults;
};

const hasSearchFailure = (results: { status: string }[]) =>
  results.some((result) => result.status === PromiseStatus.Rejected);

const getMarketCompetitorCompanyIds = (marketCompetitors: { company: CompanyInfo; numberOfContents: number }[] = []) =>
  marketCompetitors.map(({ company }) => company?.cdsAlphaCompanyId);
// TODO [RD1-134]: Remove Company Metadata Badge
const getContentFiltersPayload: (filters: Filters, hasCompanyMetadataBadge?: boolean) => FiltersPayload = (
  filters,
  hasCompanyMetadataBadge = false
) => {
  const {
    callDate: { min, max },
    contentType,
    angle,
    sortBy,
    bookmarkFilter,
    upcomingFilter,
    purchasedFilter: purchasedOptions,
    headcount,
    revenue,
    ownershipType,
    hqLocation,
  } = filters;

  // call date
  const callDateFilter: Pick<FiltersPayload, "scheduled_time_greater_than" | "scheduled_time_less_than"> = {};

  const minDate = DATE_VALUES[min];
  const maxDate = DATE_VALUES[max];

  if (minDate) callDateFilter.scheduled_time_greater_than = String(minDate);
  if (maxDate) callDateFilter.scheduled_time_less_than = String(maxDate);

  // content type
  const productTypeFilter: Pick<FiltersPayload, "product_types"> | {} = contentType.length
    ? { product_types: contentType }
    : {};

  // angle type
  const angleTypeFilter: Pick<FiltersPayload, "angle_types"> = {};
  if (angle.length > 0) {
    angleTypeFilter.angle_types = compact(
      angle.map((angleType) =>
        Object.keys(SPEAKER_ANGLE_TYPE_NAME).find((key) =>
          SPEAKER_ANGLE_TYPE_NAME[key as keyof typeof SPEAKER_ANGLE_TYPE_NAME].startsWith(pluralize.singular(angleType))
        )
      ) as AngleValues
    );
  }

  // sort by
  const sortOption = SORT_OPTIONS[sortBy];
  const sortByFilter = {
    sort_by: sortOption.sortBy,
    sort_by_direction: sortOption.direction,
  };

  // purchased by
  const purchasedFilter = purchasedOptions.reduce((filters, entry) => {
    filters[entry.toLowerCase() as "available" | "purchased_by_me" | "purchased_by_colleagues"] = true;
    return filters;
  }, {} as Pick<FiltersPayload, "available" | "purchased_by_me" | "purchased_by_colleagues">);

  let companyDataFilters = {};
  if (hasCompanyMetadataBadge) {
    // headcount
    const headCountFilter: Pick<CompanyDataAttributePayload, "headcount_greater_than" | "headcount_less_than"> = {};

    const minHeadcount = HEADCOUNT_VALUES[headcount.min];
    const maxHeadcount = HEADCOUNT_VALUES[headcount.max];

    if (minHeadcount) headCountFilter.headcount_greater_than = minHeadcount;
    if (maxHeadcount) headCountFilter.headcount_less_than = maxHeadcount;

    if (headcount.min === headcount.max) {
      if (headcount.min === 0) {
        headCountFilter.headcount_less_than = HEADCOUNT_VALUES[headcount.min + 1] as number;
      }
      if (headcount.min === HEADCOUNT_VALUES.length - 1) {
        headCountFilter.headcount_greater_than = HEADCOUNT_VALUES[headcount.min - 1] as number;
      }
    }

    // revenue
    const revenueFilter: Pick<CompanyDataAttributePayload, "revenue_greater_than" | "revenue_less_than"> = {};

    const minRevenue = REVENUE_VALUES[revenue.min];
    const maxRevenue = REVENUE_VALUES[revenue.max];

    if (minRevenue) revenueFilter.revenue_greater_than = minRevenue;
    if (maxRevenue) revenueFilter.revenue_less_than = maxRevenue;

    if (revenue.min === revenue.max) {
      if (revenue.min === 0) {
        revenueFilter.revenue_less_than = REVENUE_VALUES[revenue.min + 1] as number;
      }
      if (revenue.max === REVENUE_VALUES.length - 1) {
        revenueFilter.revenue_greater_than = REVENUE_VALUES[revenue.max - 1] as number;
      }
    }

    // ownership type
    const ownershipTypeFilter = ownershipType.length > 0 ? { ownership_types: ownershipType } : undefined;

    // HQ location
    const hqLocationFilter = hqLocation.length > 0 ? { hq_countries: hqLocation } : undefined;

    companyDataFilters = {
      ...headCountFilter,
      ...revenueFilter,
      ...ownershipTypeFilter,
      ...hqLocationFilter,
    };

    companyDataFilters = isEmpty(companyDataFilters) ? {} : { company_data_attribute: companyDataFilters };
  }

  return {
    ...callDateFilter,
    ...productTypeFilter,
    ...purchasedFilter,
    ...angleTypeFilter,
    ...sortByFilter,
    ...companyDataFilters,
    search_in_bookmarks: bookmarkFilter,
    status: upcomingFilter,
  };
};

type SearchItems = {
  keywords: string[];
  companies: { id: number; name: string }[];
  colleagues: number[];
  markets: number[];
};

const extractSearchItemsFromQuery: (searchQuery: QueryItem[], isBoolean?: boolean) => SearchItems = (
  searchQuery,
  isBoolean
) =>
  searchQuery.reduce(
    (acc: SearchItems, { type, value, id }: QueryItem) => {
      const isBooleanQuery = isBoolean ?? isBooleanSearch(searchQuery);

      if (!isBooleanQuery) {
        switch (type) {
          case SEARCH_SUGGESTION_TYPES.Company:
          case SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch:
            acc.companies.push({ id: id!, name: value });
            break;
          case SEARCH_SUGGESTION_TYPES.Colleague:
            acc.colleagues.push(id as number);
            break;
          case SEARCH_SUGGESTION_TYPES.Market:
            acc.markets.push(id as number);
            break;
          default:
            acc.keywords.push(value.replace(/["]+/g, ""));
            acc.keywords = [...new Set(acc.keywords)];
            break;
        }
      }

      return acc;
    },
    { keywords: [], companies: [], colleagues: [], markets: [] }
  );

const processSearchResults = (results: PromiseSettledResult<AlphaNowSearchResult[]>[], fetches: SearchPromise[]) => {
  let companies: CompanySearchResult[] = [];
  let colleagues: ColleagueSearchResult[] = [];
  let markets: MarketSearchResult[] = [];

  results.forEach((result, index) => {
    if (result.status === PromiseStatus.Fulfilled) {
      switch (fetches[index].id) {
        case COMPANY_SEARCH_ID:
          companies = result.value as CompanySearchResult[];
          break;
        case COLLEAGUE_SEARCH_ID:
          colleagues = result.value as ColleagueSearchResult[];
          break;
        case MARKET_SEARCH_ID:
          markets = result.value as MarketSearchResult[];
          break;
        default:
          break;
      }
    }
  });

  return { companies, colleagues, markets };
};

const cleanUpQuery = (query: SearchOption[]) =>
  query?.map((item) => omit(item, ["secondaryLabel", "StartIcon", "inputValue", "logo"])) ?? [];

const getMarketValues = (searchQuery: QueryItem[] = [], isBoolean?: boolean) => {
  const isBooleanQuery = isBoolean ?? isBooleanSearch(searchQuery);
  return isBooleanQuery
    ? []
    : searchQuery
        .filter(({ type }) => type === SEARCH_SUGGESTION_TYPES.Market)
        .map(({ value, id }) => ({ name: value, id }));
};

const getProjectSearchQuery = (searchQuery: SearchOption[] = []) =>
  searchQuery.find(({ type }) => type === SEARCH_SUGGESTION_TYPES.Project);

const getNumBooleanSearchOptions = (searchEntry: string): number => {
  const normalisedInput = searchEntry.trim().toUpperCase();

  if (normalisedInput.length === 0) {
    return 0;
  }

  const operatorMatch = Object.values(Operator).find((value) => value.startsWith(normalisedInput));
  // If operator match (i.e. AND, OR, NOT) 1 search option is returned
  // as these begin with different letters i.e. should never get more than one at the same time
  if (operatorMatch) return 1;

  const symbolMatch = Object.values(Symbol).find((value) => value.startsWith(normalisedInput));
  //If symbolMatch match (i.e. "(", ")") 2 options should be returned
  // as we return both "(" and ")" as matches
  if (symbolMatch) return 2;

  return 0;
};

const getNumCompaniesVisible = (
  inputValue: string,
  numMarkets: number,
  numColleagues: number,
  maxNumCompanies: number
) => {
  const numBooleanSearchOptions = getNumBooleanSearchOptions(inputValue);
  const numCompaniesVisible = maxNumCompanies - (numColleagues + numMarkets + numBooleanSearchOptions);
  // Ensure that the number of visible companies is not negative
  return Math.max(numCompaniesVisible, 0);
};

export {
  getNumberResultsDisplay,
  pluralizeResults,
  hasSearchFailure,
  getMarketCompetitorCompanyIds,
  getContentFiltersPayload,
  extractSearchItemsFromQuery,
  processSearchResults,
  cleanUpQuery,
  getMarketValues,
  getProjectSearchQuery,
  getNumCompaniesVisible,
};
