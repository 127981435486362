import React, { FC, ReactElement } from "react";
import { AvatarGroup, AvatarProps } from "@alphasights/alphadesign-components";
import { AvatarWrapper, LogoWrapper } from "./styled";

import { AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import useStyles from "components/PrimerCard/PrimerCard.styles";
import { Company, Industry, Remove, ThirdParty } from "@alphasights/alphadesign-icons";

const DataTestIds = {
  companyLogo: "primer-card-company-logo",
  placeholderPrimerLogo: "primer-card-placeholder-logo",
  companyPrimerAvatar: "primer-card-company-primer-avatar",
  marketPrimerAvatar: "primer-card-market-primer-avatar",
  customerPrimerAvatar: "primer-card-customer-primer-avatar",
  unknownPrimerAvatar: "primer-card-unknown-primer-avatar",
};

const NUMBER_PLACEHOLDER_ICONS_COMPANY_PRIMER = 1;
const NUMBER_PLACEHOLDER_ICONS = 4;

interface CompanyLogosProps {
  primerType: AlphaNowSRMProductType;
  logos: string[];
  disabled: boolean;
}

const getAvatar = (primerType: AlphaNowSRMProductType) => {
  switch (primerType) {
    case AlphaNowSRMProductType.companyPrimer:
      return <Company data-testid={DataTestIds.companyPrimerAvatar} />;
    case AlphaNowSRMProductType.marketPrimer:
      return <Industry data-testid={DataTestIds.marketPrimerAvatar} />;
    case AlphaNowSRMProductType.customerPrimer:
      return <ThirdParty data-testid={DataTestIds.customerPrimerAvatar} />;
    default:
      return <Remove data-testid={DataTestIds.unknownPrimerAvatar} />;
  }
};

const CompanyLogos: FC<CompanyLogosProps> = ({ primerType, logos, disabled }) => {
  const logoStyles = useStyles(primerType, disabled);

  const numberOfPlaceholderAvatars =
    primerType === AlphaNowSRMProductType.companyPrimer
      ? NUMBER_PLACEHOLDER_ICONS_COMPANY_PRIMER
      : NUMBER_PLACEHOLDER_ICONS;

  const renderPlaceholderLogos = () => {
    return Array.from({ length: numberOfPlaceholderAvatars }, (_, index) => (
      <AvatarWrapper key={index} data-testid={DataTestIds.placeholderPrimerLogo}>
        <></>
      </AvatarWrapper>
    )) as AvatarProps;
  };

  const renderCompanyLogos = () => logos.map((logo, index) => <AvatarWrapper key={index} src={logo} />) as AvatarProps;
  const renderLogos = () => (disabled ? renderPlaceholderLogos() : renderCompanyLogos()) as ReactElement;

  return (
    <LogoWrapper backgroundColor={logoStyles.backgroundColor} data-testid={DataTestIds.companyLogo}>
      <AvatarGroup size="large" color="base02">
        {renderLogos()}
        <AvatarWrapper>{getAvatar(primerType)}</AvatarWrapper>
      </AvatarGroup>
    </LogoWrapper>
  );
};

export { CompanyLogos as default, DataTestIds, NUMBER_PLACEHOLDER_ICONS_COMPANY_PRIMER, NUMBER_PLACEHOLDER_ICONS };
