import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const CardContainer = styled.div(() => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "center",
    minHeight: layout.base08,
    justifyContent: "space-between",
    paddingInline: inner.base06,
  };
});

const TriggerDetailsContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "center",
    gap: inner.base02,
  };
});

const CompanyLogoWrapper = styled.div(() => {
  const {
    spacing: { inner },
    color: { border },
    shape: {
      border: { radius, width },
    },
  } = useThemeTokens();
  return {
    width: inner.base08,
    height: inner.base08,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `${width.sm}, solid, ${border.divider}`,
    borderRadius: radius.medium,
  };
});

export { CardContainer, TriggerDetailsContainer, CompanyLogoWrapper };
