import useAvatarColors from "hooks/useAvatarColors";
import { useMemo } from "react";

const useCreatorColors = (comments) => {
  const orderedUniqueCreators = useMemo(
    () =>
      comments
        .filter((c) => !c.creatorAvatarUrl)
        .reduce((acc, comment) => {
          if (acc.find(({ creatorName }) => creatorName === comment.creatorName)) return acc;

          return [...acc, { creatorName: comment.creatorName }];
        }, []),
    [comments]
  );

  return useAvatarColors(orderedUniqueCreators);
};

export default useCreatorColors;
