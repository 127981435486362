import React, { useEffect, useMemo, useRef, useState } from "react";
import { Skeleton, useThemeTokens, Icon, Typography, List, ContentCard } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { MessengerThreadCard } from "../MessengerThreadCard/MessengerThreadCard";
import { useMessengerSidebarStyles } from "./MessengerSidebar.styles";
import { DropdownButton } from "components/DropdownButton";
import { Inbox, Mail } from "@alphasights/alphadesign-icons";
import { useMessengerContext } from "providers/MessengerProvider";
import { useHistory } from "react-router-dom";
import { MessageType, RequestType } from "types";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { FilterItem } from "components/InteractionsPage/FilterItem";
import { ENABLE_MESSAGES_COMPLIANCE_REVIEW, useProjectBadgeContext } from "providers/BadgeProvider";
import { useIsOverflow, useNewNavigation } from "@alphasights/client-portal-shared";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { ToggleButton } from "components/ToggleButton/ToggleButton";
import { isEqual } from "lodash";

export const MessengerSidebar = () => {
  const { inboxes, inboxLoading, isFlyoutOpened, selectedInbox } = useMessengerContext();

  const { container } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  const history = useHistory();

  const { onSelectThread, onSelectCard, onSendNewMessage } = useMessengerContext();

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    const selectedThread = search.get("selectedThread");
    const newMessage = Object.values(MessageType).includes(search.get("newMessage") as MessageType)
      ? (search.get("newMessage") as MessageType)
      : undefined;

    if (newMessage) {
      onSendNewMessage(newMessage, true);
    } else if (selectedThread) {
      onSelectThread(selectedThread);
    } else if (!selectedInbox && inboxes?.length) {
      onSelectCard(inboxes[0].groupId);
    }
  }, [history.location.search, inboxes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div {...container} data-testid="messenger-sidebar">
      <ExpandSidebarButton />
      {inboxLoading ? <MessengerSidebarSkeleton /> : <ExpandedSidebar />}
    </x.div>
  );
};

const ExpandSidebarButton = () => {
  const {
    setIsSettingsOpened,
    onCloseAdvisorFlyout,
    isFlyoutOpened,
    isSettingsOpened,
    isAdvisorFlyoutOpened,
  } = useMessengerContext();

  const { sidebarCollapsedContent } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  const onExpandSideber = () => {
    if (isSettingsOpened) {
      setIsSettingsOpened(false);
    } else if (isAdvisorFlyoutOpened) {
      onCloseAdvisorFlyout();
    }
  };

  return (
    <x.div {...sidebarCollapsedContent}>
      <Icon size="large" color="secondary" onClick={isFlyoutOpened ? onExpandSideber : undefined}>
        <Inbox />
      </Icon>
    </x.div>
  );
};

const ExpandedSidebar = () => {
  const {
    inboxes,
    selectedInbox,
    onSelectCard,
    onSendNewMessage,
    inboxLoading,
    isFlyoutOpened,
  } = useMessengerContext();

  const [messageTypeFilter, setMessageTypeFilter] = useState<RequestType[]>([]);
  const listRef = useRef(null);
  const isOverflow = useIsOverflow(listRef, [inboxLoading, inboxes], "vertical");
  const newNavigationEnabled = useNewNavigation();

  const { sidebarExpandedContent, messagesList, messagesListViewPort, topBar } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
    isOverflow,
    newNavigationEnabled,
  });

  const { hasProjectBadge } = useProjectBadgeContext();
  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  const newMessageOptions = [
    ...(!needComplianceReview
      ? [
          {
            label: "Work Request",
            action: () => onSendNewMessage(MessageType.WorkRequest, true),
          },
        ]
      : []),
    {
      label: "Clarification",
      action: () => onSendNewMessage(MessageType.Clarification, true),
    },
    ...(!needComplianceReview
      ? [
          {
            label: "Call Guide",
            action: () => onSendNewMessage(MessageType.CallGuide, true),
          },
        ]
      : []),
  ];

  return (
    <x.div {...sidebarExpandedContent}>
      {inboxes.length > 0 ? (
        <>
          <HeaderPortal>
            <x.div {...topBar}>
              <div>
                <DropdownButton
                  size="small"
                  label="New Message"
                  startIcon={<Mail />}
                  options={newMessageOptions}
                  data-testid="new-message-button"
                />
              </div>
              <MessageTypeFilter
                appliedFilter={messageTypeFilter}
                onFilterChange={(newFilter) => setMessageTypeFilter(newFilter)}
              />
            </x.div>
          </HeaderPortal>
          <x.div ref={listRef} {...messagesListViewPort}>
            <x.div {...messagesList}>
              {inboxes
                .filter((inbox) => messageTypeFilter.length === 0 || messageTypeFilter.includes(inbox.requestType))
                .map((inbox) => (
                  <MessengerThreadCard
                    inbox={inbox}
                    isSelected={selectedInbox?.groupId === inbox.groupId}
                    onClick={() => onSelectCard(inbox.groupId)}
                    key={inbox.groupId}
                  />
                ))}
            </x.div>
          </x.div>
        </>
      ) : (
        <NoMessagesPanel />
      )}
    </x.div>
  );
};

const NoMessagesPanel = () => {
  const { isFlyoutOpened } = useMessengerContext();

  const { noMessagesPanel } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  return (
    <x.div {...noMessagesPanel}>
      <Typography variant="body-em" color="secondary">
        No messages
      </Typography>
      <Typography variant="body-small" color="secondary">
        Messages sent to experts will display here after they have been sent
      </Typography>
    </x.div>
  );
};

const MessengerSidebarSkeleton = () => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  const listRef = useRef(null);
  const isOverflow = useIsOverflow(listRef, [], "vertical");

  const { messagesList, messagesListViewPort, topBar, sidebarExpandedContent } = useMessengerSidebarStyles({
    isSidebarCollapsed: false,
    isOverflow,
  });

  return (
    <x.div {...sidebarExpandedContent}>
      <x.div {...topBar}>
        <x.div ml="auto">
          <NoXMarginSkeleton width={layout.base15} />
        </x.div>
      </x.div>
      <x.div ref={listRef} {...messagesListViewPort}>
        <x.div {...messagesList}>
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
        </x.div>
      </x.div>
    </x.div>
  );
};

const InboxCardSkeleton = () => {
  return (
    <ContentCard w="100%">
      <x.div display="flex" flexDirection="column" gap="4px">
        <Skeleton variant="noMargin" width="30%" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" width="70%" />
      </x.div>
    </ContentCard>
  );
};

const MessageTypeFilter = ({
  appliedFilter,
  onFilterChange,
}: {
  appliedFilter: RequestType[];
  onFilterChange: (filters: RequestType[]) => void;
}) => {
  const { inboxes } = useMessengerContext();
  const { spacing } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();
  const { filterToggleButtons } = useMessengerSidebarStyles();

  const filterOption: FilterDefinition = useMemo(
    () => ({
      title: "Message Type",
      type: "checkbox",
      name: "message_type",
      value: appliedFilter,
      options: [
        { value: RequestType.CallGuide, label: "Call Guide" },
        { value: RequestType.WorkRequest, label: "Work Request" },
        { value: RequestType.Clarification, label: "Clarification" },
      ].filter((option) => inboxes.some((inbox) => inbox.requestType === option.value)),
    }),
    [appliedFilter, inboxes]
  );

  const toggleButtonOptions = useMemo(
    () =>
      [{ value: [] as RequestType[], label: "All" }].concat(
        [
          { value: [RequestType.WorkRequest], label: "Work Request" },
          { value: [RequestType.Clarification], label: "Clarification" },
          { value: [RequestType.CallGuide], label: "Call Guide" },
        ].filter((option) => inboxes.some((inbox) => option.value.includes(inbox.requestType)))
      ),
    [inboxes]
  );

  return newNavigationEnabled ? (
    <x.div {...filterToggleButtons}>
      {toggleButtonOptions.map((option) => (
        <React.Fragment key={option.label}>
          <ToggleButton
            title={option.label}
            selected={isEqual(appliedFilter, option.value)}
            onClick={() => onFilterChange(option.value)}
          />
        </React.Fragment>
      ))}
    </x.div>
  ) : (
    <List>
      <FilterItem
        filterDefinition={filterOption}
        onApplyFilter={(newFilters: string[]) => onFilterChange(newFilters as RequestType[])}
        h={spacing.inner.base08}
      />
    </List>
  );
};
