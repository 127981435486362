export const useInteractionWrapperStyles = () => {
  const scheduledInteractionPopup = {
    position: "fixed",
    top: "0",
    right: "0",
    w: "100%",
    h: "100%",
    zIndex: "100",
  };

  return {
    scheduledInteractionPopup,
  };
};
