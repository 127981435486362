import styled from "styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

type NavigationButtonWrapperProps = {
  shouldHide: boolean;
  isNewNavigation: boolean;
};

const NavigationButtonWrapper = styled.div(({ shouldHide, isNewNavigation }: NavigationButtonWrapperProps) => {
  const { spacing } = useThemeTokens();

  const baseStyles = shouldHide
    ? {
        width: 0,
        height: 0,
        opacity: 0,
      }
    : {};

  const experienceStyles = isNewNavigation
    ? {
        alignItems: "center",
        gap: spacing.inner.base04,
      }
    : {
        gap: spacing.inner.base,
      };

  return {
    display: "flex",
    transition: "all 1.5s ease-in-out",
    ...baseStyles,
    ...experienceStyles,
  };
});

export { NavigationButtonWrapper };
