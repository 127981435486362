import React, { FC } from "react";

import {
  TITLE,
  REVENUE_MARKS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/RevenueFilter/constants";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";
import { SliderRange } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";
import { SliderFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";

import "./index.css";

interface RevenueFilterProps extends FilterProps {
  value: SliderRange;
}

const RevenueFilter: FC<RevenueFilterProps> = ({ value, onChange }) => (
  <SliderFilter
    id="revenue-filter"
    filterKey={SearchFilters.revenue}
    title={TITLE}
    value={value}
    options={REVENUE_MARKS}
    onChange={onChange}
    enableOverlap
    isAccordion
  />
);

export default RevenueFilter;
export type { RevenueFilterProps };
