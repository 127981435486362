export const active = "Active";
export const availableNow = "Available This Week";
export const completed = "Completed";
export const contactAccountManager = "Please contact your account manager to start a new project.";
export const excel = "Excel";
export const inactive = "Inactive";
export const expertUpdates = "Recent Expert Updates:";
export const flyOutOptIn = "Opt in contributor program";
export const flyOutOptOut = "Opt out contributor program";
export const lastInteraction = "Last Interaction:";
export const noActiveProjects = "You have 0 active projects";
export const pastProjects = "Past Projects";
export const pccContributor = "Contributor (Opted In)";
export const pccContributorOptOut = "Contributor (Opted Out)";
export const recommended = "Highly Recommended";
export const schedule = "Schedule";
export const scheduled = "Scheduled";
export const starred = "Starred by You";
export const pendingCommentsCount = "Unread Comment Threads";
export const usage = "Usage";
export const viewCompletedExperts = "View Completed Experts";
export const viewAllExperts = "View All Experts";
export const settings = "Settings";
export const expertScheduled = (count) => `${count} requested ${count > 1 ? "experts" : "expert"} to be
scheduled.`;

// pccSettings Modal
export const contributorProgram = "Contributor Program";
export const interactionSettings = "Contributor Program - Interaction Settings";
export const projectSettings = "Contributor Program - Project Settings";
export const hdTranscript = "HD Transcript";
export const releaseTranscript =
  "We will release your transcripts ASAP. HD transcript would cost an additional 0.25 credit per call";
export const interactionOptOutBeforeCompletion =
  "By opting out of the contributor program your discount will no longer be applied to this interaction. If you would still like a transcript please reach out to your CST point of contact.";
export const interactionOptOutWithin72h =
  "By opting out of the contributor program your discount will no longer be applied.";
export const interactionOptOutAfter72h =
  "The opt-out period has elapsed. If you have any questions, please reach out to your CST point of contact";
export const interactionSureToOptOut =
  "Are you sure you would like to opt-out of the contributor program for this interaction?";
export const projectOptOut =
  "By opting out of the contributor program your discount will no longer be applied to future interactions. If you would still like a transcript please reach out to your CST point of contact.";
export const projectSureToOptOut = "Are you sure you would like to opt-out of the contributor program?";
export const yesOptOut = "Yes, Opt Out";
export const yesOptIn = "Yes, Opt In";
export const optInInfo = "Changing your contributor program settings only impacts future interactions.";
export const info = "Info:";
export const cancel = "Cancel";
