import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const topBarHeight = 61;

const MobileFiltersWrapper = styled.div(() => {
  const { color, shape } = useThemeTokens();
  return {
    height: window.innerHeight - topBarHeight,
    width: window.innerWidth,
    backgroundColor: color.background.surface.page.default,
    position: "absolute" as "absolute",
    top: topBarHeight,
    left: 0,
    zIndex: 1000,
    borderTop: `${shape.border.width.small} solid ${color.border.divider}`,
  };
});

export { MobileFiltersWrapper };
