export const LANDING_PAGE = {
  processing: {
    title: "Transcript is processing",
    subtitle: "Check back later to view your transcript",
  },
  noTranscript: {
    title: "No Transcripts",
    subtitle: "There are no transcripts associated with this interaction",
  },
  selectContent: {
    title: "Select content",
    subtitle: "Select an interaction card to view more details.",
  },
  noContent: {
    title: "No content available",
    subtitle: "There is no deliverable associated with this interaction",
  },
  mentionsView: {
    title: "Search for Mentions",
    subtitle: "Add a keyword to the search above to view all mentions of that keyword within this transcript.",
  },
  error: {
    title: "Something went wrong",
    subtitle: "We cannot show this deliverable at the moment. Please try again later.",
  },
  aiSummaryError: {
    title: "Something went wrong",
    subtitle: "We cannot generate this AI summary at the moment. Please try again later.",
  },
};
