import React, { FC, useCallback } from "react";

import { useDeliverableContext } from "providers/DeliverableProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { Button } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { Summary } from "./AiSummaries.types";

interface InternalDeleteSummaryProps {
  interactionId: string;
  recordingId: string;
  transcriptId: string;
  onGenerateClicked: (summariesStatus: Summary[]) => void;
}

export const InternalDeleteSummary: FC<InternalDeleteSummaryProps> = ({
  interactionId,
  recordingId,
  transcriptId,
  onGenerateClicked,
}) => {
  const { deleteAiSummaries } = useDeliverableContext();
  const { logHit } = useTrackUserAction();
  const { project } = useDeliverableContext();
  const currentUser = useCurrentUser();
  const styles = useStyles();

  const hasPermission = useCallback(() => {
    return (
      // @ts-ignore
      currentUser?.internalUser
    );
  }, [currentUser]);

  const onClickDelete = () => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.aiSummaryDelete,
      projectToken: project.token,
      advisorshipId: interactionId,
      references: { transcriptId: transcriptId },
    });
    deleteAiSummaries(interactionId, recordingId).then((response: Summary[]) => {
      onGenerateClicked(response);
    });
  };

  return hasPermission() ? (
    <x.div {...styles.div}>
      <Button
        variant="outline"
        size="small"
        loading={false}
        disabled={false}
        onClick={onClickDelete}
        data-testid="remove-summary-btn"
      >
        Remove AI Summaries
      </Button>
    </x.div>
  ) : (
    <></>
  );
};

const useStyles = () => {
  return {
    div: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
  };
};
