/*
 withThunk is a dispatch middleware. When dispatch is invoked if the action is a function it will call
 the function passing down the dispatch itself, if the action is not a function (an object) it will delegate
 to dispatch
 */
export function withThunk([state, dispatch]) {
  return [
    state,
    (actionOrThunk) => {
      return typeof actionOrThunk === "function" ? actionOrThunk(dispatch, state) : dispatch(actionOrThunk);
    },
    dispatch,
  ];
}
