import React from "react";
import { Portal, useThemeTokens } from "@alphasights/alphadesign-components";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import styled from "@xstyled/styled-components";

export const HeaderPortal = ({ children }: { children: JSX.Element }) => {
  const newNavigationEnabled = useNewNavigation();
  const [containerEl, setContainerEl] = React.useState<HTMLElement | undefined>(undefined);
  React.useLayoutEffect(() => {
    setContainerEl(document.querySelector("#interaction-page-header-container") as HTMLElement);
  }, []);

  if (newNavigationEnabled && containerEl) {
    return (
      <Portal containerEl={containerEl}>
        {children}
        {newNavigationEnabled && <Separator />}
      </Portal>
    );
  }

  return (
    <>
      {children}
      {newNavigationEnabled && <Separator />}
    </>
  );
};

export const Separator = styled.div(() => {
  const {
    spacing: { inner },
    shape,
    color,
  } = useThemeTokens();
  return `
    height: ${inner.base};
    border-bottom: ${shape.border.width.small} solid ${color.border.divider};
    border-top: ${shape.border.width.small} solid ${color.border.divider};
    flex-shrink: 0;
    background: ${color.background.surface.recessed};
  `;
});
