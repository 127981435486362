import React from "react";
import { x } from "@xstyled/styled-components";
import { useRecommendedContentCardStyles } from "./RecommendedContentCard.styles";
import { ContentCard, Logo, LogoVariation, Typography } from "@alphasights/alphadesign-components";

export const RecommendedContentCard = ({ selected, onClick }: { selected?: boolean; onClick: () => void }) => {
  const { wrapper, logo, text } = useRecommendedContentCardStyles();

  return (
    <ContentCard onClick={onClick} selected={selected} data-testid="recommended-content-card" {...wrapper}>
      <x.div role="img" {...logo}>
        <Logo variation={LogoVariation.Color} size="xxs" />
      </x.div>
      <x.div {...text}>
        <Typography variant="body-em" color="strong">
          Recommended Research
        </Typography>
        <Typography variant="body-small" color="secondary">
          Recommended based on your project
        </Typography>
      </x.div>
    </ContentCard>
  );
};
