import React from "react";
import PropTypes from "prop-types";

import { x } from "@xstyled/styled-components";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { BookmarkSelected, Purchased, Quote } from "@alphasights/alphadesign-icons";

import { FLAGS, ContentStatus } from "@alphasights/client-portal-shared";
import { upcoming } from "content/AlphaNow";
import Tag from "components/Tag";
import { Spacer } from "pages/AlphaNowPage/components";
import { StyledTypography } from "./Flags.styled";

const DataTestIds = {
  purchasedFlagIcon: "purchased-flag-icon",
  dateFlag: "content-date",
  bookmarkFlag: "bookmark-para",
  angleTypeFlag: "angle-type",
};

const PurchasedFlag = () => (
  <Icon color="success">
    <Purchased data-testid={DataTestIds.purchasedFlagIcon} />
  </Icon>
);

const AngleTypeFlag = ({ angleType }) => (
  <StyledTypography data-testid={DataTestIds.angleTypeFlag}>
    <StyledTypography variant="body-small">{angleType}</StyledTypography>
    <Spacer />
  </StyledTypography>
);

const MentionsFlag = ({ mentions }) => {
  const { color, shape } = useThemeTokens();

  return (
    <Tag name={mentions} icon={<Quote />} style={{ spaceX: shape.border.width.large, bg: color.background.info }} />
  );
};

const BookmarkedFlag = () => (
  <Icon color="secondary">
    <BookmarkSelected data-testid={DataTestIds.bookmarkFlag} />
  </Icon>
);

const DateFlag = ({ date, isUpcoming = false }) => (
  <StyledTypography data-testid={DataTestIds.dateFlag} variant="body-small">
    {isUpcoming ? upcoming : date}
  </StyledTypography>
);

export const FLAG_COMPONENTS = {
  [FLAGS.Purchased]: PurchasedFlag,
  [FLAGS.Bookmarked]: BookmarkedFlag,
  [FLAGS.Mentions]: MentionsFlag,
  [FLAGS.ContentDate]: DateFlag,
  [FLAGS.AngleType]: AngleTypeFlag,
};

const Flags = ({ items, ...props }) => {
  const { spacing } = useThemeTokens();

  const flags = items.map((item) => {
    const Flag = FLAG_COMPONENTS[item];
    const flagProps = {};

    switch (item) {
      case FLAGS.Mentions:
        flagProps.mentions = `${props?.mentions}`;
        break;
      case FLAGS.AngleType:
        flagProps.angleType = props?.angleType;
        break;
      case FLAGS.ContentDate:
        if (props?.status === ContentStatus.upcoming) {
          flagProps.isUpcoming = true;
          break;
        } else {
          flagProps.date = props?.contentDate;
        }
        break;
      default:
    }

    return <Flag key={item} {...flagProps} />;
  });

  return (
    <x.div display="inline-flex" spaceX={spacing.inner.base}>
      {flags}
    </x.div>
  );
};

Flags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { Flags as default, DataTestIds };
