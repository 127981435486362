import React from "react";
import { TranscriptReadAlong } from "../TranscriptReadAlong";
import { parseISO } from "../../providers/TimezoneProvider";
import { isPast } from "date-fns";
import { x } from "@xstyled/styled-components";
import { IconOnlyButton, Typography } from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { useReadAlongFlyoutStyles } from "./ReadAlongFlyOut.styles";
import { HitOrigin } from "@alphasights/portal-api-client";

export const ReadAlongFlyOut = ({
  readAlongContent,
  onClose,
  onBackToAdvisorDetails,
  onExpand,
  onCollapse,
  onRequestTranscriptPostCall,
  ...props
}) => {
  const { headerStyles, backButtonStyles } = useReadAlongFlyoutStyles();

  const isRecordingExpired = isPast(parseISO(readAlongContent.recording.expiresAt));

  const audioError = getAudioErrorMessage({
    isRecordingExpired,
    readAlongContent,
  });

  return (
    <div className={"md:aui-w-full"}>
      <>
        <x.div {...headerStyles}>
          <IconOnlyButton
            variant="ghost"
            {...backButtonStyles}
            onClick={onBackToAdvisorDetails}
            data-testid="back-button"
          >
            <ArrowLeft />
          </IconOnlyButton>
          <Typography>Back to expert profile</Typography>
        </x.div>
      </>

      {readAlongContent && (
        <div className={"aui-mt-8"}>
          <TranscriptReadAlong
            audioUrl={readAlongContent.recording.url}
            vttUrl={`/api/projects/${props.token}/transcripts/${readAlongContent.transcript.token}/vtt`}
            compactPlayer={props.isExpanded}
            onExpand={onExpand}
            onCollapse={onCollapse}
            obfuscated={readAlongContent.recording.obfuscated}
            language={props.language}
            audioError={audioError}
            interactionId={props.id}
            projectToken={props.token}
            recording={readAlongContent.recording}
            origin={HitOrigin.readAlongFlyout}
          />
        </div>
      )}
    </div>
  );
};

const getAudioErrorMessage = ({ isRecordingExpired }) => {
  if (isRecordingExpired) {
    return "Recording expired";
  }
  return "Error loading recording file";
};
