import { useEffect, useRef } from "react";

// store previous value of a prop when it changes
// this is the equivalent of prevProps in class components lifecycle methods
const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
