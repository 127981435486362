export const labelToMultiline = (label: string, maxLengthPerLine: number) => {
  let tempName = "";
  let fullName = [];

  const splittedName = label.split(" ") as string[];

  splittedName.forEach((nameSegment) => {
    // current segment bigger than limit
    if (nameSegment.length > maxLengthPerLine) {
      // flush the current temp name because the current segment already overlaps the limit
      if (tempName.length > 0) {
        fullName.push(tempName);
        tempName = "";
      }
      fullName.push(nameSegment);
    } else {
      // adding the current segment overlapps the limit
      if (`${tempName} ${nameSegment}`.length > maxLengthPerLine) {
        // we flush the current name line and set the current segment as current line
        fullName.push(tempName);
        tempName = nameSegment;
      } else {
        // the limit was not reached so we concatenate the current segment and current line
        tempName = `${tempName} ${nameSegment}`;
      }
    }
  });

  // flush last segment
  if (tempName.length > 0) {
    fullName.push(tempName);
  }

  return fullName;
};

export const labelsToMultiline = (labels: string[], maxLengthPerLine: number) => {
  return labels.length > 0 ? labels.map((label) => labelToMultiline(label, maxLengthPerLine)) : [];
};
