import React from "react";
import { CallDurationSelector } from "./CallDurationSelector";
import { AvailabilitySelector } from "../AvailabilitySelector";
import { x } from "@xstyled/styled-components";
import { useSchedulingSlotsStyles } from "./SchedulingSlots.styles";

export const SchedulingSlots = ({
  durationOptions,
  durationOptionValue,
  expectedDuration,
  mutualSlots,
  onChangeExpectedDuration,
  oneHourMinimum,
  onSelectSlot,
  selectedBulkAvailability,
  slots,
  isMobileView = false,
}) => {
  const { containerStyles } = useSchedulingSlotsStyles();

  return (
    <x.div {...containerStyles} data-testid="scheduling-slots">
      <CallDurationSelector
        isMobileView={isMobileView}
        durationOptions={durationOptions}
        value={durationOptionValue}
        onChangeExpectedDuration={onChangeExpectedDuration}
      />
      <AvailabilitySelector
        isMobileView={isMobileView}
        expectedDuration={expectedDuration}
        mutualSlots={mutualSlots}
        onSelectSlot={onSelectSlot}
        selectedBulkAvailability={selectedBulkAvailability}
        slots={slots}
        oneHourMinimum={oneHourMinimum}
      />
    </x.div>
  );
};
