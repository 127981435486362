import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useSize } from "@xstyled/styled-components";

export const useCallGuideSectionStyles = () => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  const wrapperDiv = {
    gap: layout.base02,
    w: useSize("full"),
    display: "flex",
    flexDirection: "column",
  };

  return {
    wrapperDiv,
  };
};
