import React from "react";
import { x } from "@xstyled/styled-components";
import { useSidebarStyles } from "./Sidebar.styles";
import { ContentFlyout, SIDEBAR_WIDTH } from "./ContentFlyout/ContentFlyout";
import { PrivateTranscriptFlyout } from "./PrivateTranscriptFlyout/PrivateTranscriptFlyout";

interface FreeSamples {
  COMPANY_PRIMER: string;
  MARKET_PRIMER: string;
  CUSTOMER_PRIMER: string;
  ALPHAVIEW: string;
  PEER_CONTRIBUTED: string;
  ALPHAVIEW_DIAL_IN: string;
}

export interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  content?: ContentResults;
  interaction?: Interaction;
  transcriptRequest?: TranscriptRequest;
  updateDeliverableSuggestion?: (contentId: string, changes: any) => void;
  freeSamples?: FreeSamples;
  fetchSuggestions?: () => Promise<void>;
  fragmentIds?: string[];
}

export const Sidebar = ({
  isOpen,
  onClose,
  content,
  interaction,
  transcriptRequest,
  updateDeliverableSuggestion = () => {},
  freeSamples,
  fetchSuggestions,
  fragmentIds,
}: SidebarProps) => {
  const { sidebar } = useSidebarStyles();

  if (!content && (!interaction || !transcriptRequest)) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <x.div flex={`${SIDEBAR_WIDTH}px 0 0`} {...sidebar} data-testid="deliverables-sidebar-right">
          {content ? (
            <x.div data-testid="content-sidebar" flexGrow="1">
              <ContentFlyout
                onClose={onClose}
                content={content}
                updateDeliverableSuggestion={updateDeliverableSuggestion}
                freeSamples={freeSamples}
                fetchSuggestions={fetchSuggestions}
                fragmentIds={fragmentIds}
              />
            </x.div>
          ) : (
            <x.div data-testid="private-transcript-sidebar" flexGrow="1">
              <PrivateTranscriptFlyout
                onClose={onClose}
                interaction={interaction!}
                transcriptRequest={transcriptRequest!}
                fragmentIds={fragmentIds}
              />
            </x.div>
          )}
        </x.div>
      )}
    </>
  );
};
