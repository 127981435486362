import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTFooterStyles = ({ isMessagesOverflow = false } = {}) => {
  const {
    color: { background, icon, text, border: borderColor },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const wrapper = {
    ...(isMessagesOverflow && {
      borderTop: `${border.width.sm} solid`,
    }),
    borderTopColor: borderColor.divider,
    p: inner.base06,
    backgroundColor: background.surface.page.default,
  };

  const inputIcon = {
    pl: inner.base02,
  };

  const searchWrapper = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
    maxW: "50rem",
    margin: "0 auto",
  };

  const searchInputWrapper = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  };

  const infoContainer = {
    maxW: "60rem",
    margin: "0 auto",
    pt: inner.base04,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: inner.base06,
  };

  const libraryContext = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: inner.base04,
  };

  const libraryItem = {
    display: "flex",
    alignItems: "center",
    gap: inner.base,
  };

  const link = {
    color: "assistive",
    textDecoration: "underline",
  };

  const viewOnlyInformationContainer = {
    display: "flex",
    justifyContent: "space-between",
    padding: inner.base04,
    alignItems: "center",
    backgroundColor: background.surface.page.default,
    borderTopColor: borderColor.divider,
    borderTopWidth: border.width.small,
  };

  const viewOnlyStatus = {
    textColor: text.strong._,
    color: background.info,
    iconColor: icon.info,
  };

  return {
    wrapper,
    inputIcon,
    searchWrapper,
    searchInputWrapper,
    infoContainer,
    libraryContext,
    libraryItem,
    link,
    viewOnlyInformationContainer,
    viewOnlyStatus,
  };
};
