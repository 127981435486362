import { Avatar, AvatarProps, Icon, Popover, Typography } from "@alphasights/alphadesign-components";
import { Mail, Phone } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { identity } from "lodash";
import React, { ForwardedRef, forwardRef, useState } from "react";
import useStyles from "./ProjectLeadV2.styles";

interface ProjectLeadV2Props {
  lead: UserData;
  workstreamType?: WorkstreamType;
  showDetails: boolean;
}

export const ProjectLeadV2 = ({ lead, workstreamType, showDetails }: ProjectLeadV2Props) => {
  const styles = useStyles();

  const [ref, setRef] = useState<HTMLElement | null>();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const onOpenDetails = () => setDetailsOpen(true);
  const onCloseDetails = () => setDetailsOpen(false);
  const leadCopy = workstreamType === "survey" ? "Survey Lead" : "Calls Lead";

  return (
    <>
      <x.div ref={setRef} {...styles.wrapper} onClick={showDetails ? onOpenDetails : undefined}>
        <ProjectLeadAvatar lead={lead} />
        <x.div {...styles.leadData}>
          <Typography variant="body-small-em" color="link">
            {lead.name}
          </Typography>
          <Typography variant="body-small" color="secondary">
            {leadCopy}
          </Typography>
        </x.div>
      </x.div>

      <Popover open={detailsOpen} anchorEl={ref!!} placement="top-start" onClose={onCloseDetails}>
        <x.div {...styles.popoverContent}>
          <ProjectLeadAvatar lead={lead} size="large" />
          <x.div {...styles.popoverLeadData}>
            <Typography variant="body-small-em" color="strong">
              {lead.name}
            </Typography>
            <Typography variant="body-small" color="strong">
              {leadCopy}
            </Typography>

            <x.div {...styles.popoverLeadContacts}>
              {[lead.desktopNumber, lead.mobileNumber].filter(identity).map((number, ix) => (
                <x.div key={number} {...styles.popoverContactWrapper}>
                  {ix === 0 && (
                    <Icon color="secondary">
                      <Phone />
                    </Icon>
                  )}
                  {ix !== 0 && <x.div {...styles.iconPlaceholder} />}
                  <x.a href={`tel:${number}`} {...styles.detailsLink}>
                    <Typography variant="body-small">{number}</Typography>
                  </x.a>
                </x.div>
              ))}

              {lead.email && (
                <x.div {...styles.popoverContactWrapper}>
                  <Icon color="secondary">
                    <Mail />
                  </Icon>
                  <x.a href={`mailto:${lead.email}`} {...styles.detailsLink}>
                    <Typography variant="body-small">{lead.email}</Typography>
                  </x.a>
                </x.div>
              )}
            </x.div>
          </x.div>
        </x.div>
      </Popover>
    </>
  );
};

interface ProjectLeadAvatarProps {
  lead: UserData | PtoUser;
  size?: AvatarProps["size"];
  stylingProps?: Record<string, string>;
  dataTestId?: string;
}

const DEFAULT_THUMB = "default_thumb.png";

export const ProjectLeadAvatar = forwardRef(
  ({ lead, size = "medium", stylingProps, dataTestId }: ProjectLeadAvatarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const hasThumb = !!lead.avatar?.thumbUrl && !lead.avatar.thumbUrl?.match(DEFAULT_THUMB);

    const additionalProps = hasThumb ? { src: lead.avatar!.thumbUrl } : { text: lead.name };
    return <Avatar size={size} ref={ref} data-testid={dataTestId} {...stylingProps} {...additionalProps} />;
  }
);
