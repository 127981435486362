import React, { FC, useState } from "react";
import { useMutation } from "react-query";
import styled, { x } from "@xstyled/styled-components";
import {
  Modal,
  CheckboxGroup,
  Checkbox,
  Accordion,
  AccordionItem,
  Typography,
  useThemeTokens,
  useAlphaToast,
  ToastContainer,
  TextArea,
} from "@alphasights/alphadesign-components";
import { RequestExpertButton } from "pages/AlphaNowPage/components";
import { RequestExpertButtonType } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions/constants";
import {
  primerExpertRequestNotePlaceholder,
  requestContent,
  requestMultipleExperts,
  selectExperts,
  successfulRequest,
  unsuccessfulRequest,
} from "content/AlphaNow";
import { requestExperts } from "content/AlphaNow/utils";
import { Label } from "components/Label";
import RelevantExperience from "components/Experts/ExpertCard/RelevantExperience";
import CancelButton from "pages/AlphaNowPage/components/CancelButton";
import { ExpertRow } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Experts/types";
import { contentService } from "services/content";
import useModal from "hooks/useModal";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import useContentClientSettings from "hooks/useContentClientSettings";

const DataTestIds = {
  ExpertDetails: "expert-details",
};

interface RequestExpertModalProps {
  selectedSpeakers: ExpertRow[];
  handleSubmitExpertRequest: () => void;
}

interface ExpertDetailsProps {
  selectedSpeakers: ExpertRow[];
  selectedSpeakerIds: number[];
}

const ExpertDetailsWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 315px;
`;

const ExpertDetails: FC<ExpertDetailsProps> = ({ selectedSpeakers, selectedSpeakerIds }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  return (
    <ExpertDetailsWrapper>
      <Label text={selectExperts} required />
      <CheckboxGroup
        my={inner.base06}
        value={selectedSpeakerIds}
        onChange={(selectedSpeakerIds: any) => {
          selectedSpeakerIds.push(selectedSpeakerIds);
        }}
      >
        <Accordion>
          {selectedSpeakers.map(({ speakerId, companyName, position, dateHeld, bio }) => (
            <AccordionItem
              dataAttributes={{ "data-testid": DataTestIds.ExpertDetails }}
              key={speakerId}
              titleContent={
                <Checkbox value={speakerId} size="large">
                  <x.p pl={inner.base02}>
                    <Typography component="span" whiteSpace="nowrap" variant="body-em">
                      {companyName}
                    </Typography>
                    <Typography component="span" variant="body" pl={inner.base}>
                      - {position}
                    </Typography>
                    <Typography variant="body-small">{dateHeld}</Typography>
                  </x.p>
                </Checkbox>
              }
            >
              <RelevantExperience bio={bio} textColor={text.secondary} />
            </AccordionItem>
          ))}
        </Accordion>
      </CheckboxGroup>
    </ExpertDetailsWrapper>
  );
};

const RequestExpertModal: FC<RequestExpertModalProps> = ({ selectedSpeakers, handleSubmitExpertRequest }) => {
  const { toast } = useAlphaToast();
  const { isVisible, onOpen, onClose } = useModal();
  const { logHit } = useTrackUserAction();
  const { clientSettings, isLoading: isClientSettingsLoading } = useContentClientSettings();
  const [note, setNote] = useState("");

  const createClientExpertInquiry = useMutation(
    (clientExpertInquiry: ClientExpertInquiry) => contentService.postExpertRequest(clientExpertInquiry as any),
    {
      onSuccess: () => toast.success({ message: successfulRequest }),
      onError: () => toast.error({ message: unsuccessfulRequest }),
    }
  );

  const selectedSpeakerIds = selectedSpeakers.map((expert) => expert.speakerId);
  const hasSelectedSpeakers = selectedSpeakerIds.length > 0;
  const canRequestExpert = clientSettings?.requestExperts ?? false;
  const isRequestExpertsButtonDisabled = !(canRequestExpert && hasSelectedSpeakers);

  const clientExpertInquiry = {
    note,
    speakerIds: selectedSpeakerIds,
  };

  const handleRequestExpert = () => {
    createClientExpertInquiry.mutate(clientExpertInquiry);
    onClose();
    handleSubmitExpertRequest();
    logHit({
      origin: HitOrigin.companyPage,
      action: HitAction.alphaNowRequestAnExpert,
      details: { expertIds: selectedSpeakerIds },
    });
  };

  const handleOpenModal = () => {
    onOpen();
    logHit({
      origin: HitOrigin.companyPage,
      action: HitAction.alphaNowRequestAnExpertAttempt,
      details: {
        expertIds: selectedSpeakerIds,
      },
    });
  };

  const handleOnChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setNote(newNote);
  };

  return (
    <>
      <RequestExpertButton
        buttonActionVariant={RequestExpertButtonType.openModal}
        text={requestMultipleExperts}
        onClick={handleOpenModal}
        loading={isClientSettingsLoading}
        disabled={isRequestExpertsButtonDisabled}
        size="small"
      />
      {isVisible && (
        <Modal
          title={requestExperts(selectedSpeakerIds.length)}
          variant="complex"
          slotHeight="unset"
          slotWidth="553px"
          zIndex={10}
          open={isVisible}
          onClose={onClose}
          shouldShowFooter={true}
          primaryButton={
            <RequestExpertButton
              buttonActionVariant={RequestExpertButtonType.submitRequest}
              text={requestContent}
              onClick={handleRequestExpert}
            />
          }
          secondaryButton={<CancelButton isExistingExpertRequest={false} handleCancelRequest={onClose} />}
        >
          <ExpertDetails selectedSpeakers={selectedSpeakers} selectedSpeakerIds={selectedSpeakerIds} />
          <div data-testid="request-expert-note">
            <TextArea
              width="100%"
              height="100px"
              placeholder={primerExpertRequestNotePlaceholder}
              onChange={handleOnChangeNote}
            />
          </div>
        </Modal>
      )}
      <ToastContainer />
    </>
  );
};

export { RequestExpertModal as default, DataTestIds };
