import React, { ForwardedRef, forwardRef, useMemo } from "react";

import { x } from "@xstyled/styled-components";
import { XStyledAndHTMLProps, useThemeTokens } from "@alphasights/alphadesign-components";

import { CONTENT_TYPE, Card, FLAGS, AlphaNowProductType, AlphaNowContentType } from "@alphasights/client-portal-shared";
import { CardTitle, CardSubtitle, CardHeader, Flags } from "pages/AlphaNowPage/components";

import "./index.css";
import { getContentTitle } from "content/AlphaNow/utils";
import { getNonModeratorSpeakers } from "pages/AlphaNowPage/utils";
import CompanyLogos from "pages/AlphaNowPage/components/CompanyLogos";

export const DataTestIds = {
  contentCard: "content-card",
};

const ContentCard = forwardRef(
  (
    {
      isBookmarked = false,
      showPurchasedFlag = false,
      keywordHits = 0,
      searchQuery = [],
      content,
      isSelected,
      onClick,
      ...props
    }: ContentCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { spacing } = useThemeTokens();

    const { contentType, externalTitle, speakers, companies, primer, description, productType, companyLogos } = content;

    const isAlphaView = contentType === CONTENT_TYPE.alphaview;
    const isCompanyPrimer = productType === AlphaNowProductType.companyPrimer;
    const isMarketPrimer = productType === AlphaNowProductType.marketPrimer;
    const isCustomerPrimer = productType === AlphaNowProductType.customerPrimer;
    const isPrimerContent = contentType === AlphaNowContentType.srm;

    const title = getContentTitle(productType, companies, externalTitle);
    const nonModeratorSpeakers = getNonModeratorSpeakers(speakers);
    const logos = productType === AlphaNowProductType.companyPrimer ? [primer?.logoLink?.value] : companyLogos;

    const flagItems = [...(showPurchasedFlag ? [FLAGS.Purchased] : []), ...(isBookmarked ? [FLAGS.Bookmarked] : [])];

    const cardHeader = useMemo(
      () => (
        <CardHeader
          content={content as any}
          searchQuery={searchQuery as any}
          mentions={keywordHits}
          isPrimer={isPrimerContent}
        />
      ),
      [content, searchQuery, keywordHits, isPrimerContent]
    );

    const cardTitle = useMemo(
      () => (
        <CardTitle
          isAlphaView={isAlphaView}
          isCompanyPrimer={isCompanyPrimer}
          isMarketPrimer={isMarketPrimer}
          isCustomerPrimer={isCustomerPrimer}
          title={title}
          //@ts-ignore
          speakers={nonModeratorSpeakers}
        />
      ),
      [isAlphaView, isCompanyPrimer, isMarketPrimer, isCustomerPrimer, title, nonModeratorSpeakers]
    );

    const cardSubtitle = useMemo(
      () => (
        <x.div row alignItems="center" minH={spacing.inner.base06}>
          {description && <CardSubtitle subtitleString={description} />}
          <x.div row flexDirection="row-reverse">
            <Flags items={flagItems} />
          </x.div>
        </x.div>
      ),
      [description, flagItems] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
      <Card
        ref={ref}
        data-testid={DataTestIds.contentCard}
        logo={
          isPrimerContent && (
            <CompanyLogos
              productType={productType as AlphaNowProductType}
              title={getContentTitle(productType, companies, externalTitle)}
              logos={logos as string[]}
            />
          )
        }
        headerContent={cardHeader}
        titleContent={cardTitle}
        subtitleContent={cardSubtitle}
        isSelected={isSelected}
        onClick={onClick}
        {...props}
      />
    );
  }
);

type ContentCardProps = {
  content: {
    companies: {
      companyType: string;
      companyName: string;
    }[];
    companyLogos?: string[];
    contentType: string;
    description?: string;
    externalTitle?: string;
    productType?: string;
    primer?: {
      logoLink?: {
        value: string;
      };
    };
    speakers: {
      jobTitle: string;
      company: string;
      isModerator: boolean;
    }[];
    scheduledTimeUTC?: string;
  };
  isBookmarked?: boolean;
  showPurchasedFlag?: boolean;
  isSelected: boolean;
  keywordHits?: number;
  searchQuery?: { type: number | undefined; value: string }[];
  onClick: Function;
} & XStyledAndHTMLProps<HTMLDivElement>;

export default ContentCard;
