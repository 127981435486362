import { pick } from "lodash";

import { INITIAL_FILTERS_STATE } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { FilterFormState } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/types";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";

const FORM_FILTER_KEYS = [
  SearchFilters.contentType,
  SearchFilters.callDate,
  SearchFilters.angle,
  SearchFilters.purchasedFilter,
  SearchFilters.headcount,
  SearchFilters.revenue,
  SearchFilters.ownershipType,
  SearchFilters.hqLocation,
];

// these are the filters displayed in the Filters popover
const INITIAL_FORM_STATE = Object.freeze({ ...pick(INITIAL_FILTERS_STATE, FORM_FILTER_KEYS) } as FilterFormState);

const POPOVER_GAP = 100;

export { INITIAL_FORM_STATE, FORM_FILTER_KEYS, POPOVER_GAP };
