export const Cookie = {
  findByName: (cookieName) => {
    return (document.cookie || "")
      .split("; ")
      .filter((row) => row.startsWith(cookieName))
      .map((row) => row.split("=").pop())
      .pop();
  },
  create(name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  },
};
