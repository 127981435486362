import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { AlphaNowAudioPlayer } from "pages/AlphaNowPage/components/AlphaNowAudioPlayer";
import { useAudioPlayer } from "pages/AlphaNowPage/hooks/useAlphaNowAudioPlayer";
import {
  useThemeTokens,
  Avatar,
  Tooltip,
  TooltipContent,
  Typography,
  IconButton,
} from "@alphasights/alphadesign-components";
import { Expert, PlayArrow, Info, VolumeUp } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useAutoScroll } from "hooks/useAutoScroll";
import { useAudioPlayerIdleTimer } from "hooks/useAudioPlayerIdleTimer";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

const ICON_BACKGROUND = Object.freeze({
  Interviewer: "base",
  Expert: "base03",
  "Expert 1": "base03",
  "Expert 2": "base04",
  "Expert 3": "base05",
  "Expert 4": "base06",
  "Expert 5": "base02",
  "Expert 6": "base02",
  "Expert 7": "base02",
  "Expert 8": "base02",
  "Expert 9": "base02",
});

export const AudioFragment = React.forwardRef(
  (
    {
      highlightedCueIndex,
      onClick,
      cues,
      speakers,
      participant,
      speakerDummyName,
      controlsProps,
      fragmentActive,
      ...props
    },
    ref
  ) => {
    const {
      color,
      spacing: { inner, layout },
    } = useThemeTokens();
    const { isMobile } = useCheckScreen();
    const [isHovered, setIsHovered] = useState(false);

    const isModerator = participant === "Interviewer";
    const hasMultipleExperts = speakers.length > 2;

    const speakerIconColor = ICON_BACKGROUND[participant];
    const speakerAvatar = hasMultipleExperts && !isModerator && !isHovered ? speakerDummyName : "";

    const expertSpeaker = speakers.find((s) => s.transcriptParticipantRole === participant);

    const currentFragmentPlaying = fragmentActive && controlsProps.playing;

    const speakerIcon = () => {
      if (currentFragmentPlaying) {
        return <VolumeUp data-testid="volume-icon" />;
      }
      if (isHovered) {
        return <PlayArrow data-testid="play-icon" />;
      }
      return <Expert data-testid="expert-icon" />;
    };

    const expertDisplayName = `${expertSpeaker.company} - ${expertSpeaker.jobTitle}`;
    const speakerDisplayName = isModerator ? participant : expertDisplayName;

    const placeholder = isModerator ? null : (
      <x.span display="inline-flex" flexDirection="row" color={color.text.secondary} alignItems="center">
        <Typography component="span" variant="body" px={inner.base}>
          |
        </Typography>
        <Typography component="span" variant="body" data-testid="placeholder-name" pr={inner.base}>
          {speakerDummyName}
        </Typography>
        {!isMobile && (
          <Tooltip maxWidth="unset">
            <TooltipContent>
              <Typography>Placeholder name to retain the expert's anonymity.</Typography>
            </TooltipContent>
            <IconButton size="small" variant="basic" color={color.text.secondary} testId="information-icon">
              <Info />
            </IconButton>
          </Tooltip>
        )}
      </x.span>
    );
    return (
      <x.div flex flexDirection="row" data-testid="audio-conversation-fragment">
        <x.div
          margin="auto"
          maxW={{ xs: "none", sm: "606px" }}
          mb={inner.base04}
          backgroundColor={isHovered ? color.background.surface.page.hover : null}
          borderRadius={inner.base02}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <x.div display="flex" flexDirection="column" spaceX={{ xs: 0, sm: inner.base06 }}>
            <div>
              <x.div
                display="flex"
                flexDirection="row"
                spaceX={{ xs: inner.base04, sm: inner.base06 }}
                alignItems="center"
                mb={inner.base}
              >
                <Avatar
                  size="small"
                  color={speakerIconColor}
                  text={speakerAvatar}
                  data-testid="avatar-icon"
                  cursor="pointer"
                  onClick={onClick}
                  minWidth={inner.base06}
                >
                  {speakerIcon()}
                </Avatar>
                <div>
                  <Typography component="span" flexWrap="wrap" variant="body-em" color={color.text.strong._}>
                    {speakerDisplayName}
                  </Typography>
                  {placeholder}
                </div>
              </x.div>
              <x.div data-testid="speaker-text" ml={{ sm: layout.base06 }}>
                {cues.map((cue) => (
                  <Typography
                    component="span"
                    variant="body"
                    ref={ref}
                    backgroundColor={highlightedCueIndex === cue.id ? color.background.surface.page.hover : null}
                    color={color.text.strong._}
                    {...props}
                    data-transcript={highlightedCueIndex === cue.id ? "highlightedCue" : null}
                  >
                    {cue.text}
                  </Typography>
                ))}
              </x.div>
            </div>
          </x.div>
        </x.div>
      </x.div>
    );
  }
);

export const AudioView = ({
  audioUrl,
  vttUrl,
  audioError,
  obfuscated = false,
  interactionId,
  projectToken,
  recording,
  origin,
  language,
  contentId,
  conversation,
  speakers,
  setViewMode,
  audioToggle,
}) => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  const [showPlayerScrolled, setShowPlayerScrolled] = useState(false);
  const [playerVisible, setPlayerVisible] = useState(true);
  useAudioPlayerIdleTimer(setPlayerVisible);
  const debounceHidePlayer = useCallback(
    debounce(() => {
      setShowPlayerScrolled(false);
    }, 3000),
    []
  );
  const audioPlayingRef = useRef(false);

  const showPlayerMomentarily = () => {
    if (!showPlayerScrolled) {
      setShowPlayerScrolled(true);
    }
    debounceHidePlayer();
  };

  const audioPlayer = useAudioPlayer({
    audioUrl,
    vttUrl,
    disabled: obfuscated,
  });

  const {
    isError,
    isLoading,
    transcriptProps,
    controlsProps,
    progressBarProps,
    playbackProps,
    renderElements,
    scrollToCurrentFragment,
  } = useAutoScroll({
    audioPlayer,
    onNonAutomaticScrollEnded: showPlayerMomentarily,
  });

  const playerRef = useRef();
  const playerWrapperRef = useRef();
  const cuesRef = useRef({});
  const { logHit } = useTrackUserAction();
  const { isMobile } = useCheckScreen();

  useEffect(() => {
    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowAudioEnabled,
      details: { contentId },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transcriptProps.movePlayerTo(0);
    controlsProps.onPlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioToggle]);

  useEffect(() => {
    if (audioPlayingRef.current !== controlsProps.playing) {
      logHit({
        origin: HitOrigin.alphaNow,
        action: controlsProps.playing ? HitAction.alphaNowAudioPlay : HitAction.alphaNowAudioPause,
        details: { contentId },
      });
    }
    audioPlayingRef.current = controlsProps.playing;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsProps.playing]);

  useEffect(() => {
    const target = playerWrapperRef.current;
    const handleMouseEnter = () => debounceHidePlayer.cancel();
    const handleMouseLeave = () => debounceHidePlayer();

    if (target) {
      target.addEventListener("mouseenter", handleMouseEnter);
      playerWrapperRef.current.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (target) {
        target.removeEventListener("mouseenter", handleMouseEnter);
        target.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [playerWrapperRef, debounceHidePlayer]);

  const onCueClick = (cue) => {
    const activeCue = transcriptProps.activeCues ? transcriptProps.activeCues[0] : null;

    if (activeCue?.id === cue.id) {
      controlsProps.onTogglePlay();
    } else {
      let isCueInActiveFragment = false;

      if (activeCue) {
        isCueInActiveFragment = transcriptProps.transcriptSections?.reduce((acc, section) => {
          const cues = section.cues.filter((sectionCue) => [cue.id, activeCue.id].includes(sectionCue.id));
          const bothCuesInFragment = cues.length === 2;
          return bothCuesInFragment || acc;
        }, false);
      }

      if (activeCue && isCueInActiveFragment) {
        controlsProps.onTogglePlay();
      } else {
        transcriptProps.movePlayerTo(cue.startTime);
        controlsProps.onPlay();
      }
    }

    if (controlsProps.playing) {
      showPlayerMomentarily();
    }
  };

  return (
    <>
      <x.div ref={playerWrapperRef}>
        {renderElements}
        {recording.visibleToClient && (
          <x.div
            position="fixed"
            bottom={0}
            w="-webkit-fill-available"
            transition="all .5s ease"
            opacity={playerVisible || isMobile ? "1" : "0"}
            visibility={playerVisible || isMobile ? "visible" : "hidden"}
            zIndex="1"
          >
            <AlphaNowAudioPlayer
              onClickRefocus={scrollToCurrentFragment}
              controlsProps={controlsProps}
              progressBarProps={progressBarProps}
              playbackProps={playbackProps}
              isError={isError}
              isLoading={isLoading}
              ref={playerRef}
              src={audioUrl}
              vttUrl={vttUrl}
              audioError={audioError}
              disabled={obfuscated}
              interactionId={interactionId}
              projectToken={projectToken}
              recordingId={recording.id}
              origin={origin}
              variant="new-flyout"
              language={language}
              playerVisible={playerVisible}
              setViewMode={setViewMode}
              contentId={contentId}
            />
          </x.div>
        )}
      </x.div>
      <x.div
        display="flex"
        flexDirection="column"
        p={inner.base06}
        paddingTop={0}
        paddingBottom={layout.base10}
        overflowY="auto"
        data-testid="conversation-view"
      >
        {transcriptProps.transcriptSections &&
          conversation.map((fragment, index) => {
            const { participant, speakerDummyName } = fragment;

            const fragmentCues = transcriptProps.transcriptSections[index].cues;
            const cue = fragmentCues[0];
            const activeCueId = transcriptProps.activeCues ? transcriptProps.activeCues[0]?.id : -1;
            const activeFragmentCues = fragmentCues.find((cue) => cue.id === activeCueId?.toString());

            return (
              <AudioFragment
                ref={(element) => (cuesRef.current[cue.id] = element)}
                key={`section-${index}-cue-${cue.id}`}
                cue={cue}
                onClick={() => onCueClick(cue)}
                highlightedCueIndex={activeCueId}
                data-transcript-id={activeFragmentCues?.id}
                fragmentActive={
                  transcriptProps.activeCues
                    ? fragmentCues.some((c) => c.id === transcriptProps.activeCues[0]?.id)
                    : false
                }
                cues={fragmentCues}
                participant={participant}
                speakerDummyName={speakerDummyName}
                speakers={speakers}
                controlsProps={controlsProps}
              />
            );
          })}
      </x.div>
    </>
  );
};
