import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { logBookmarkStatus } from "pages/AlphaNowPage/utils/logHit";
import { contentService } from "services/content";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { AlphaNowContentType, AlphaNowProductType, useTrackUserAction } from "@alphasights/client-portal-shared";

export type OnBookmarkChangedProps = {
  contentId: string;
  isBookmarked: boolean;
};

const useContentApi = (
  contentId: string,
  productType: AlphaNowContentType | AlphaNowProductType,
  onBookmarkChanged?: (props: OnBookmarkChangedProps) => void
) => {
  const currentUser = useCurrentUser();
  const userId = String(currentUser?.id ?? "");
  const { logHit } = useTrackUserAction();
  const [clientBookmarkId, setClientBookmarkId] = useState<string | null>(null);
  const isBookmarked: boolean = !!clientBookmarkId;

  const fetchBookmarks = async () => {
    try {
      return await contentService.fetchContentBookmark(contentId);
    } catch {
      setClientBookmarkId(null);
    }
  };

  const { data: bookmark } = useQuery(["client-bookmark", contentId], fetchBookmarks, {
    enabled: !!contentId,
  });

  useEffect(() => {
    setClientBookmarkId(bookmark?.id || null);
  }, [bookmark]);

  const toggleBookmarkMutation = useMutation(
    () =>
      isBookmarked
        ? contentService.deleteContentBookmark(clientBookmarkId!)
        : contentService.postContentBookmark({ contentId }),
    {
      onSuccess: (bookmark) => {
        setClientBookmarkId(bookmark?.id || null);
        if (onBookmarkChanged) {
          onBookmarkChanged({ contentId, isBookmarked: !isBookmarked });
        }
      },
    }
  );

  const onToggleBookmark = () => {
    logBookmarkStatus(isBookmarked, { contentId, userId }, logHit, productType);
    toggleBookmarkMutation.mutate();
  };

  return { isBookmarked, onToggleBookmark };
};

export default useContentApi;
