import React from "react";
import { useMarketPrimersStore } from "../../state/store";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import OutlookEntries from "../../components/OutlookEntries/OutlookEntries";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Outlook = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { outlook } = useMarketPrimersStore();

  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <SectionWrapper ref={ref} px={inner.base06}>
      <x.div display="flex" flexDirection="column" gap={inner.base08}>
        <Typography variant="body-large-em">Outlook</Typography>
        <x.div mx={inner.base04}>
          <OutlookEntries entries={outlook} />
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Outlook));
