import React from "react";
import { x } from "@xstyled/styled-components";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";

export const SearchAndFilterSkeleton = () => {
  return (
    <x.div>
      <NoXMarginSkeleton height="40px" />
      <x.div display="flex">
        <x.div flexGrow={2}>
          <NoXMarginSkeleton />
        </x.div>
        <x.div flexGrow={1}>
          <NoXMarginSkeleton />
        </x.div>
      </x.div>
    </x.div>
  );
};
