import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const TranscriptHeaderWrapper = styled.div(({ borderBottomWidth }: { borderBottomWidth: number }) => {
  const { spacing, color } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return {
    top: 0,
    paddingTop: isMobile ? 0 : spacing.inner.base06,
    paddingInline: spacing.inner.base08,
    backgroundColor: color.background.surface.page.default,
    maxHeight: isMobile ? window.innerHeight - 285 : undefined,
    overflowY: "scroll" as "scroll",
    borderBottomColor: color.border.neutral.default,
    borderBottomWidth,
  };
});

const TranscriptInfoRowWrapper = styled.div(() => {
  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return {
    display: "flex",
    flexDirection: isMobile ? ("column" as "column") : ("row" as "row"),
    flexWrap: "wrap" as "wrap",
    color: color.text.secondary,
    rowGap: spacing.inner.base02,
  };
});

export { TranscriptHeaderWrapper, TranscriptInfoRowWrapper };
