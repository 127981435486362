import React from "react";

import { IconButton, IconButtonProps, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronLeft, ChevronRight } from "@alphasights/alphadesign-icons";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { useAppSearchContext } from "providers/AppSearchProvider";

import * as S from "./Navigation.styled";

const DataTestIds = {
  backButton: "go-back",
  forwardButton: "go-forward",
};

enum NavigationButtonVariant {
  left,
  right,
}

const NAV_BUTTON_CONFIG = {
  [NavigationButtonVariant.left]: {
    testId: DataTestIds.backButton,
    ChevronIcon: ChevronLeft,
  },
  [NavigationButtonVariant.right]: {
    testId: DataTestIds.forwardButton,
    ChevronIcon: ChevronRight,
  },
};

type NavigationButtonProps = {
  variant: NavigationButtonVariant;
  onClick: () => void;

  disabled?: boolean;
};

const Navigation = () => {
  const { isMobile } = useCheckScreen();
  const newNavigationEnabled = useNewNavigation();
  const { goBack, goForward, isBackDisabled, isForwardDisabled } = useAppSearchContext();
  const shouldHideNavigation = isMobile || (!newNavigationEnabled && isBackDisabled && isForwardDisabled);
  return (
    <S.NavigationButtonWrapper shouldHide={shouldHideNavigation} isNewNavigation={newNavigationEnabled}>
      <NavigationButton variant={NavigationButtonVariant.left} onClick={goBack} disabled={isBackDisabled} />
      <NavigationButton variant={NavigationButtonVariant.right} onClick={goForward} disabled={isForwardDisabled} />
    </S.NavigationButtonWrapper>
  );
};

const NavigationButton = ({ variant, disabled = false, onClick }: NavigationButtonProps) => {
  const { color, spacing } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();

  const { testId, ChevronIcon } = NAV_BUTTON_CONFIG[variant];

  const buttonProps: Partial<IconButtonProps> = newNavigationEnabled
    ? {
        variant: "basic",
        color: "inverse",
      }
    : {
        variant: "outline",
        size: "small",
        color: color.icon.strong._,
        buttonAttributes: {
          style: {
            width: spacing.inner.base10,
            height: spacing.inner.base10,
          },
        },
      };

  return (
    <IconButton
      dataAttributes={{
        "data-testid": testId,
      }}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <ChevronIcon />
    </IconButton>
  );
};

export { Navigation as default, DataTestIds };
