import { create } from "zustand";
import { ContentPurchaseStatus } from "@alphasights/client-portal-shared";
import { ContentApprovalStatus } from "models/AlphaShield";

type PurchaseProperties = {
  price: number;
  purchaseStatus: ContentPurchaseStatus;
  approvalStatus: ContentApprovalStatus;
};

type storeState = {
  primerTitle: string;
  lastUpdatedAt: string;
  contentId: string;
  purchaseProperties: PurchaseProperties;
  isAccessible: boolean;
  experts: Speaker[];
  expertCount: number;
  downloadFileName: string;
  currency: string;
  companies: PrimerCompany[];
  setPrimerState: (data: any) => void;
};

export const usePrimersStore = create<storeState>((set) => ({
  primerTitle: "",
  lastUpdatedAt: "",
  contentId: "",
  purchaseProperties: {
    price: 0,
    purchaseStatus: ContentPurchaseStatus.NA,
    approvalStatus: ContentApprovalStatus.NA,
  },
  isAccessible: false,
  experts: [],
  expertCount: 0,
  downloadFileName: "",
  currency: "USD",
  companies: [],
  setPrimerState: (data: any) => {
    const {
      primerTitle,
      lastUpdatedAt,
      contentId,
      isAccessible,
      purchaseProperties,
      speakers,
      downloadFileName,
      currency,
      companies,
    } = data;

    set({
      primerTitle,
      lastUpdatedAt,
      contentId,
      isAccessible,
      purchaseProperties,
      experts: speakers,
      expertCount: speakers?.length % 2 === 0 ? speakers?.length : speakers?.length + 1,
      downloadFileName,
      currency,
      companies,
    });
  },
}));
