import React, { useEffect, useMemo, useRef } from "react";
import styled, { x, th } from "@xstyled/styled-components";
import AlphaGPTMessage, { AlphaGPTMessageSkeleton, MessageSeparator } from "../AlphaGPTMessage/AlphaGPTMessage";
import { AlphaGPTFooter } from "../AlphaGPTFooter/AlphaGPTFooter";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useAlphaGPTConversationStyles } from "./AlphaGPTConversation.styles";
import AlphaGPTLoading from "../AlphaGPTLoading";
import { Alert, Pill } from "@alphasights/alphadesign-components";
import { AlphaGPTLandingPage } from "../AlphaGPTLandingPage/AlphaGPTLandingPage";
import _ from "lodash";
import { AlphaGPTMessageProvider } from "providers/AlphaGPTMessageProvider";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export interface AlphaGPTConversationProps {
  initialQuery?: string;
}

export const AlphaGPTConversation = ({ initialQuery = undefined }: AlphaGPTConversationProps) => {
  const { isLoading, errorMessage, messages } = useAlphaGPTContext();
  const { wrapper, messagesContainer, errorWrapper } = useAlphaGPTConversationStyles();
  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref, [messages.length], "vertical");
  const showLibrary = messages.length > 0;

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length, isLoading, errorMessage]);

  return (
    <x.div {...wrapper}>
      <x.div ref={ref} {...messagesContainer}>
        {showLibrary && <ConversationLibrary />}
        {messages.length ? (
          <>
            {messages.map((message, index) => (
              <AlphaGPTMessageProvider key={message.id} message={message}>
                <AlphaGPTMessage
                  showSeparator={messages.length - 1 !== index || !errorMessage?.length}
                  collapseCitations={index !== messages.length - 1}
                />
              </AlphaGPTMessageProvider>
            ))}
            {isLoading && <AlphaGPTLoading />}
          </>
        ) : (
          <AlphaGPTLandingPage />
        )}
        {(errorMessage?.length ?? 0) > 0 && (
          <>
            <x.div {...errorWrapper}>
              <Alert variant="danger" data-testid="error-message-alert">
                {errorMessage}
              </Alert>
            </x.div>
            <MessageSeparator />
          </>
        )}
      </x.div>
      <AlphaGPTFooter isMessagesOverflow={isOverflow} />
    </x.div>
  );
};

export const AlphaGPTConversationSkeleton = () => {
  const { wrapper, messagesContainer } = useAlphaGPTConversationStyles();
  return (
    <x.div {...wrapper}>
      <x.div {...messagesContainer}>
        <AlphaGPTMessageSkeleton showSeparator />
        <AlphaGPTMessageSkeleton showSeparator />
        <AlphaGPTMessageSkeleton showSeparator={false} />
      </x.div>
      <AlphaGPTFooter />
    </x.div>
  );
};

const ConversationLibrary = () => {
  const { conversation, libraryOptions } = useAlphaGPTContext();
  const { selectedLibrary } = useAlphaGPTConversationStyles();
  const selectedLibraryOption = useMemo(() => {
    const conversationLibraries = conversation?.libraries ?? [];
    if (conversationLibraries.length > 1) return libraryOptions.find((l) => l.isAllLibraries);
    return libraryOptions.find((l) => _.isEqual(l.libraries, conversationLibraries) && !l.isAllLibraries);
  }, [conversation, libraryOptions]);

  if (!selectedLibraryOption) {
    return null;
  }

  return (
    <x.div {...selectedLibrary} data-testid="conversation-library-pills">
      <ConversationLibraryPill size="small">{selectedLibraryOption.label}</ConversationLibraryPill>
    </x.div>
  );
};

const ConversationLibraryPill = styled(Pill)`
  &:active {
    background-color: ${th.color("background-neutral-default")};
  }
`;
