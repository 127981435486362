import React, { useState } from "react";
import { CalendarFull, scrollStartingHour } from "./CalendarFull";
import { zonedTimeToUtc } from "date-fns-tz";
import { areIntervalsOverlapping, min, max, addMinutes, isPast, startOfDay } from "date-fns";
import { useTimezone } from "../../providers/TimezoneProvider";

const fixIntersections = (slots, current) => {
  if (slots.length === 0) return [current];

  return slots.reduce((acc, other, ix) => {
    if (areIntervalsOverlapping(current, other, { inclusive: true })) {
      const start = min([current.start, other.start]);
      const end = max([current.end, other.end]);

      return [...acc, { ...current, start, end, source: "request" }];
    } else {
      const last = ix === slots.length - 1;
      if (last) return [...acc, other, current];
      else return [...acc, other];
    }
  }, []);
};

export const mergeIntersecting = (events) => {
  events.sort(({ start: startX }, { start: startY }) => startX.getTime() - startY.getTime());

  return events
    .reduce((merged, current) => fixIntersections(merged, current), [])
    .map((event, ix) => ({ ...event, id: ix }));
};

export const RequestAvailabilityCalendar = ({
  showingEvents = [],
  availabilities,
  setAvailabilites,
  token,
  currentDate,
  setCurrentDate,
  allowNonHourAutoBook,
  ...props
}) => {
  const [scrollToTime, setScrollToTime] = useState(scrollStartingHour(new Date()));
  const tz = useTimezone();
  const availabilitiesEvents = availabilities.map((event) => ({
    ...event,
    onRemoveEvent: () => onRemoveEvent(event),
  }));

  const adjustEnd = (start, slotEnd) => {
    const minSlotLength = allowNonHourAutoBook ? 30 : 60;
    const minEnd = addMinutes(start, minSlotLength);
    const selectedEnd = zonedTimeToUtc(slotEnd, tz.currentTimezone);
    return max([minEnd, selectedEnd]);
  };

  const onSelectSlot = (slot) => {
    const start = zonedTimeToUtc(slot.start, tz.currentTimezone);
    const end = adjustEnd(start, slot.end);

    if (isPast(start)) {
      return;
    }

    const event = {
      start,
      end,
      source: "request",
    };

    setAvailabilites(mergeIntersecting([...availabilities, event]));
  };

  const onRemoveEvent = ({ id }) => {
    const otherEvents = availabilities.filter((e) => e.id !== id);
    setAvailabilites(otherEvents);
  };

  const onEventUpdate = ({ event, start, end }) => {
    if (event.source !== "request") return;

    start = zonedTimeToUtc(start, tz.currentTimezone);
    end = adjustEnd(start, end);

    if (isPast(start)) {
      return;
    }

    const otherEvents = availabilities.filter((e) => e.id !== event.id);

    const updated = [...otherEvents, { start, end, source: "request" }];

    setAvailabilites(mergeIntersecting(updated));
  };

  const slotPropGetter = (date) => {
    const start = zonedTimeToUtc(date, tz.currentTimezone);
    return isPast(start) ? { className: "past-timeslot" } : {};
  };

  // This is a workaround for a DnD bug. When the first day/week/month is rendered
  // if there are no events in this period, DnD works funky requiring you to click twice to DnD
  // https://github.com/jquense/react-big-calendar/issues/1683
  const ghostEvent = {
    start: startOfDay(currentDate),
    end: startOfDay(currentDate),
    source: "ghost",
  };

  return (
    <CalendarFull
      dataTestId="submit-availability-calendar"
      token={token}
      className="aui-h-screen aui-w-full no-scrollbar"
      scrollToTime={scrollToTime}
      setScrollToTime={setScrollToTime}
      onSelectSlot={onSelectSlot}
      onEventDrop={onEventUpdate}
      onEventResize={onEventUpdate}
      onSelecting={({ start }) => !isPast(start)}
      events={[...showingEvents, ...availabilitiesEvents, ghostEvent]}
      slotPropGetter={slotPropGetter}
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
      draggableAccessor={() => true}
      selectable={true}
      {...props}
    />
  );
};
