import React, { useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, useThemeTokens } from "@alphasights/alphadesign-components";
import { getAvailableRecordings, getAvailableTranscripts } from "../helpers";
import { SearchBar as OldSearchBar } from "../SearchBar";
import { DeliverablesFilters } from "../DeliverablesFilters";
import {
  default as NewSearchBar,
  OptionProps,
  SearchItemProps,
  SearchOption,
  SearchSizeVariant,
  SearchVariant,
} from "components/Search";
import { SEARCH_ITEM_TYPE, SEARCH_ITEM_CONFIG, SEARCH_PLACEHOLDER_TEXT } from "../constants";
import { getDefaultComponents } from "components/Search/components";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { useBackdropContext } from "providers/BackdropProvider";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import * as S from "./CardsSidebarHeader.styled";
import { Separator } from "components/InteractionsPage/HeaderPortal";
import { Close } from "@alphasights/alphadesign-icons";

const getDeliverableTypesAvailable = (interactions: Interaction[]) =>
  new Set(
    (interactions ?? []).flatMap(getAvailableRecordings).flatMap((rec) => {
      const transcriptTypes = getAvailableTranscripts(rec).map((tr: TranscriptRequest) => tr.transcriptType);
      return rec.visibleToClient ? ["recording", ...transcriptTypes] : transcriptTypes;
    })
  );

type AppliedFilters = {
  [key: string]: string[];
};

export type CardsSidebarHeaderProps = {
  appliedFilters: AppliedFilters;
  appliedKeywords: string[];
  filterOptions: {
    groups: FilterOption[];
    alphaNowTypesSet?: string[];
  };
  interactions: Interaction[];
  sortCriteria: string;
  onFilter: (filters: AppliedFilters) => void;
  onSort: (sortBy: string) => void;
  onTextSearch: (keywords: string[]) => void;
  loading: boolean;
  numInteractions: number;
  onClearFilters: () => void;
  alphaNowContent?: { suggestedContent?: { isAccessible: boolean; bookmarked: boolean }[] };
};

export const CardsSidebarHeader = ({
  appliedFilters,
  appliedKeywords,
  filterOptions,
  interactions,
  sortCriteria,
  onFilter,
  onSort,
  onTextSearch,
  loading,
  numInteractions,
  onClearFilters,
  alphaNowContent,
}: CardsSidebarHeaderProps) => {
  const newNavigationEnabled = useNewNavigation();
  const { showBackdrop, hideBackdrop } = useBackdropContext();

  const transcriptTypesSet = useMemo(() => getDeliverableTypesAvailable(interactions), [interactions]);

  const accessibleContent = useMemo(() => {
    const suggestedContent = alphaNowContent?.suggestedContent ?? [];
    return suggestedContent.filter((suggestion: { isAccessible: boolean }) => suggestion.isAccessible);
  }, [alphaNowContent]);

  const hasAppliedKeyword = appliedKeywords.length > 0;
  const hasAppliedFilterOrKeyword =
    hasAppliedKeyword || Object.values(appliedFilters).some((filter) => filter?.length > 0);

  const hasBookmarkedContent = useMemo(
    () =>
      accessibleContent.some((content) => content.bookmarked) ||
      interactions.some((interaction) => interaction.bookmarked),
    [accessibleContent, interactions]
  );

  const searchBar = (
    <SearchBar
      keywords={appliedKeywords}
      onSearch={onTextSearch}
      loading={loading}
      placeholder={SEARCH_PLACEHOLDER_TEXT}
      onFocus={showBackdrop}
      onBlur={hideBackdrop}
    />
  );

  const filters = (
    <DeliverablesFilters
      numInteractions={numInteractions}
      appliedFilters={appliedFilters}
      transcriptTypesSet={transcriptTypesSet}
      options={filterOptions}
      sortCriteria={sortCriteria}
      onSubmitFilters={onFilter}
      onSortBy={onSort}
      loading={loading}
      hasAppliedKeyword={hasAppliedKeyword}
      hasBookmarkedContent={hasBookmarkedContent}
    />
  );

  return newNavigationEnabled ? (
    <>
      <S.Wrapper>
        {searchBar}
        {filters}
        {hasAppliedFilterOrKeyword && <ClearAllButton onClick={onClearFilters} />}
      </S.Wrapper>
      <Separator />
    </>
  ) : (
    <>
      <x.div mx="24px">{searchBar}</x.div>
      <x.div mx="24px" my="16px">
        {filters}
      </x.div>
    </>
  );
};

const ClearAllButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton variant="ghost" color="strong" onClick={onClick} size="small" testId="clear-all-filters-button">
      <Close />
    </IconButton>
  );
};

const SearchBar = ({
  keywords,
  onSearch,
  onFocus,
  onBlur,
  loading,
  placeholder = "",
}: {
  keywords: string[];
  onSearch: (keywords: string[]) => void;
  onFocus: () => void;
  onBlur: () => void;
  loading: boolean;
  placeholder?: string;
}) => {
  const newNavigationEnabled = useNewNavigation();
  const { shape } = useThemeTokens();
  const { hasUserBadge } = useUserBadgeContext();
  const hasNewDeliverablesSearchBadge = hasUserBadge(Badge.newDeliverablesSearch);
  const searchQuery = keywords.map((keyword) => ({ label: keyword, value: keyword, type: SEARCH_ITEM_TYPE.Keyword }));
  const loadSearchOptions = (inputValue: string) => {
    if (inputValue.length > 0) {
      return [{ label: inputValue, value: inputValue, type: SEARCH_ITEM_TYPE.Keyword }];
    }
    return [];
  };

  const handleSearch = (updatedItems: SearchOption[]) => {
    const queryKeywords = updatedItems
      .filter((item) => item?.type === SEARCH_ITEM_TYPE.Keyword)
      .map(({ value }) => value);
    onSearch(queryKeywords);
    onBlur();
  };

  if (hasNewDeliverablesSearchBadge) {
    return (
      <NewSearchBar
        variant={SearchVariant.Complex}
        query={searchQuery}
        placeholder={placeholder}
        loadOptions={loadSearchOptions}
        onChange={handleSearch}
        onFocus={onFocus}
        onBlur={onBlur}
        allowBooleanOperators={false}
        components={{ Option: SearchSuggestion, SearchItem }}
        style={{ flex: 1, mt: shape.border.width.sm }}
        isCollapsible={newNavigationEnabled}
        size={newNavigationEnabled ? SearchSizeVariant.Small : SearchSizeVariant.Medium}
      />
    );
  }

  // TODO [CON-3762] Remove once the old search bar is deprecated
  return (
    <OldSearchBar
      placeholder={placeholder}
      appliedKeywords={keywords}
      onTextSearch={onSearch}
      loading={loading}
      isCollapsible={newNavigationEnabled}
      initialValue={undefined}
    />
  );
};

const enhanceData = (option: SearchOption) => ({
  ...option,
  ...(option.type && SEARCH_ITEM_CONFIG[option.type as SEARCH_ITEM_TYPE]),
});

const SearchSuggestion = ({ data, ...props }: OptionProps) => {
  const enhancedData = enhanceData(data);
  const { Option: BaseOption } = getDefaultComponents();
  return <BaseOption data={enhancedData} {...props} />;
};

const SearchItem = ({ data, ...props }: SearchItemProps) => {
  const enhancedData = enhanceData(data);
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  return <BaseSearchItem data={enhancedData} {...props} />;
};
