import styled, { th } from "@xstyled/styled-components";
import { Modal } from "@alphasights/alphadesign-components";

export const PurchaseModal = styled(Modal)`
  header {
    height: auto;
    padding: ${th.space("inner-base06")} ${th.space("inner-base06")} 0;
    & > div {
      padding-top: 0;
    }
    & > button {
      width: ${th.space("inner-base05")};
      height: ${th.space("inner-base05")};
    }
  }
`;
