import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { isEqual } from "lodash";
import styled, { x } from "@xstyled/styled-components";
import { useThemeTokens, Tooltip, IconButton } from "@alphasights/alphadesign-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  useEnv,
  useTrackUserAction,
  AlphaNowContentType,
  AlphaNowProductType,
  useNewNavigation,
} from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";

import { selectResearch, selectATranscriptOrPrimerDescriptionContent } from "content/AlphaNow";
import Backdrop from "components/Backdrop";
import {
  AlphaNowBanner,
  AlphaNowCompanyPage,
  AlphaNowSearch,
  AlphaNowTranscript,
  LegalDisclaimerModal,
} from "pages/AlphaNowPage/components";
import { DefaultView } from "components/DefaultView";
import LandingPage from "./components/LandingPage";
import { ExpertsInfoView } from "components/Experts/ExpertsInfoView";
import { CitationProvider } from "components/CitationContext/CitationContext";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import ExpiredServicePlanModal from "components/ExpiredServicePlanModal/ExpiredServicePlanModal";
import {
  CLIENT_ROLE_PERMISSION,
  COLLAPSED_LEFT_SIDEBAR_WIDTH,
  EXPERTS_SIDEBAR_WIDTH,
  SIDEBAR_ANIMATION_SPEED,
  TOO_MANY_REQUESTS_STATUS,
} from "constants/AlphaNow";
import { ERROR, PURCHASE_ERROR, REQUEST_PRIMER } from "content/AlphaNow/constants";
import { accessSuspendedTitle, accessSuspendedSubtitle } from "content/AlphaNow";
import { useCollapsableSidebar } from "pages/AlphaNowPage/hooks/useCollapsableSidebar";
import { contentService } from "services/content";
import { default as useLocationQuery } from "hooks/useQuery";
import RequestPrimerView from "./components/RequestPrimer/RequestPrimerView/RequestPrimerView";
import { isBooleanSearch } from "components/Search/utils";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import CompanyPrimerPage from "./primers/CompanyPrimer/CompanyPrimerContentPage";
import MarketPrimersPage from "./primers/MarketPrimer/MarketPrimersPage";
import CustomerPrimersPage from "./primers/CustomerPrimer/CustomerPrimersPage";
import { isDefaultQuery, toPx } from "pages/AlphaNowPage/utils";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { ScrollProvider } from "pages/AlphaNowPage/components/ScrollContext";
import { useBackdropContext } from "providers/BackdropProvider";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";

export const DataTestIds = {
  accessDenied: "access-denied-view",
  companyPrimer: "company-primer-view",
  marketPrimer: "market-primer-view",
  expertsSidebar: "experts-sidebar-view",
};

export const AlphaNowPage = () => {
  const currentUser = useCurrentUser();
  const env = useEnv();
  const { logHit } = useTrackUserAction();
  const query = useLocationQuery();
  const { isMobile } = useCheckScreen();
  const { hasUserBadge } = useUserBadgeContext();

  const { hasBackdrop, showBackdrop, hideBackdrop } = useBackdropContext();

  const [accessDenied, setAccessDenied] = useState(false);
  const [accessSuspended, setAccessSuspended] = useState(false);
  const [trackingId, setTrackingId] = useState(null);
  const [bookmarksChanged, setBookmarksChanged] = useState([]);
  const [purchasedContentChanged, setPurchasedContentChanged] = useState([]);
  const [contentPrice, setContentPrice] = useState();
  const [contentError, setContentError] = useState("");
  const [questionsData, setQuestionsData] = useState([]);
  const [hasServicePlanExpired, setServicePlanExpired] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const {
    query: { selectedContentId, searchQuery, filters, fragmentIds },
    updateQuery,
  } = useAppSearchContext();

  const showLegalDisclaimer =
    !env?.enableCookiePolicy &&
    currentUser?.permissions?.includes(CLIENT_ROLE_PERMISSION) &&
    !currentUser?.hasAgreedToAlphaNowLegalDisclaimer;

  const isRequestPrimer = selectedContentId === REQUEST_PRIMER;
  const isBooleanQuery = isBooleanSearch(searchQuery);

  const isCompanyPage = !!selectedCompanyId && !selectedContentId && !isMobile;
  //TODO: [RD1-196]: Remove Landing Page Badge,
  const hasLandingPageBadge = hasUserBadge(Badge.landingPage);
  const hasDefaultQuery = isDefaultQuery(selectedContentId, filters, searchQuery);
  const isLandingPageEnabled = useMemo(() => !isMobile && hasDefaultQuery && hasLandingPageBadge, [
    isMobile,
    hasDefaultQuery,
    hasLandingPageBadge,
  ]);

  useEffect(
    () => {
      logHit({
        origin: "alpha-now",
        action: "ALPHA_NOW_FRONT_PAGE",
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const trackingId = query.get("tracking-id") || "";
    setTrackingId(trackingId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fragmentIdsFromQuery = query.get("fragmentIds");
    const newFragmentIds = fragmentIdsFromQuery ? fragmentIdsFromQuery.split(",") : [];
    if (!isEqual(newFragmentIds, fragmentIds)) {
      updateQuery({ fragmentIds: newFragmentIds });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleBackdrop = useCallback(
    (show) => {
      if (Boolean(show) === true) {
        showBackdrop();
      } else {
        hideBackdrop();
      }
    },
    [showBackdrop, hideBackdrop]
  );

  const onContentErrorChanged = (error) => {
    setContentError(error ?? ERROR.TechnicalError);
  };

  const onBookmarkChanged = useCallback(
    (changedBookmark) => {
      const newBookmarksChanged = bookmarksChanged.filter(
        (bookmark) => bookmark.contentId !== changedBookmark.contentId
      );

      newBookmarksChanged.push(changedBookmark);

      setBookmarksChanged([...newBookmarksChanged]);
    },
    [bookmarksChanged]
  );

  const onPurchasedContentChanged = useCallback(
    (changedPurchasedContent) => {
      const newPurchasedContentChanged = purchasedContentChanged.filter(
        (purchased) => purchased.contentId !== changedPurchasedContent.contentId
      );

      newPurchasedContentChanged.push(changedPurchasedContent);

      setPurchasedContentChanged([...newPurchasedContentChanged]);
    },
    [purchasedContentChanged]
  );

  const { data: selectedContent, isFetching: isFetchingContent } = useQuery(
    ["content", selectedContentId, trackingId],
    () => {
      return contentService.fetchContentById(selectedContentId, trackingId);
    },
    {
      enabled: !!selectedContentId && !isRequestPrimer && trackingId !== null,
      onError: (res) => {
        if (res.status === TOO_MANY_REQUESTS_STATUS) {
          setAccessSuspended(true);
        } else {
          setAccessSuspended(false);
          setAccessDenied(true);
        }
      },
      onSuccess: () => {
        setAccessSuspended(false);
        setAccessDenied(false);
        setContentError("");
      },
    }
  );

  const isContentPriceQueryReady = !!selectedContentId && !isRequestPrimer;

  useQuery(["contentPrice", selectedContentId], () => contentService.fetchContentPurchasePrice(selectedContentId), {
    enabled: isContentPriceQueryReady,
    onSuccess: (res) => {
      typeof res?.rate === "number" ? setContentPrice(res?.rate) : setContentError(PURCHASE_ERROR.PricingError);
    },
    onError: () => setContentError(PURCHASE_ERROR.PricingError),
  });

  const { isSuccess: questionsFetched } = useQuery(
    ["questionViewRequest", selectedContentId],
    () => contentService.fetchContentQuestionView(selectedContentId),
    {
      enabled: !!selectedContentId && !isRequestPrimer,
      onSuccess: (res) => {
        setQuestionsData(res?.questions);
      },
    }
  );

  const { isSidebarExpanded, contentContainerRef, onSidebarExpanded } = useCollapsableSidebar({
    selectedContent,
    isRequestPrimer,
  });

  const isCompanyPrimer = selectedContent?.productType === AlphaNowProductType.companyPrimer;
  const isMarketPrimer = selectedContent?.productType === AlphaNowProductType.marketPrimer;
  const isCustomerPrimer = selectedContent?.productType === AlphaNowProductType.customerPrimer;

  const showExpertsSidebar = !isMobile && selectedContent?.contentType === AlphaNowContentType.srm;

  const onReturnToMobileSearch = useCallback(() => {
    onSidebarExpanded();
    updateQuery({ selectedContentId: undefined });
  }, [onSidebarExpanded, updateQuery]);

  const contentContainerContent = () => {
    if (accessDenied) {
      return (
        <span data-testid={DataTestIds.accessDenied}>
          <DefaultView title={selectResearch} subtitle={selectATranscriptOrPrimerDescriptionContent} />
          <AlphaNowBanner
            contentType={null}
            useCase={ERROR.AccessDenied}
            onClose={() => setAccessDenied(false)}
            styles={{ bottom: "8%" }}
          />
        </span>
      );
    }

    if (accessSuspended) {
      return <ErrorMessage header={accessSuspendedTitle} body={accessSuspendedSubtitle} />;
    }

    if (isRequestPrimer) {
      return <RequestPrimerView onReturnToMobileSearch={onReturnToMobileSearch} />;
    }

    if (isCompanyPage) return <AlphaNowCompanyPage key={selectedCompanyId} companyId={selectedCompanyId} />;

    if (!selectedContent) {
      return isMobile ? null : (
        <DefaultView title={selectResearch} subtitle={selectATranscriptOrPrimerDescriptionContent} />
      );
    }
    if (isMarketPrimer) {
      return (
        <MarketPrimersPage
          selectedContent={selectedContent}
          price={contentPrice}
          alphaNowSearchQuery={isBooleanQuery ? [] : searchQuery}
          isSidebarExpanded={isSidebarExpanded}
          contentError={contentError}
          onContentErrorChanged={onContentErrorChanged}
          onPurchasedContentChanged={onPurchasedContentChanged}
        />
      );
    }

    if (isCustomerPrimer) {
      return (
        <CustomerPrimersPage
          alphaNowSearchQuery={isBooleanQuery ? [] : searchQuery}
          selectedContent={selectedContent}
          price={contentPrice}
          contentError={contentError}
          onContentErrorChanged={onContentErrorChanged}
          onPurchasedContentChanged={onPurchasedContentChanged}
        />
      );
    }
    if (isCompanyPrimer) {
      return (
        <CompanyPrimerPage
          key={selectedContent.id}
          content={selectedContent}
          contentError={contentError}
          onContentErrorChanged={onContentErrorChanged}
          onBookmarkChanged={onBookmarkChanged}
          onPurchasedContentChanged={onPurchasedContentChanged}
          isSidebarExpanded={isSidebarExpanded}
          price={contentPrice}
          isFetchingContent={isFetchingContent}
          // not cascading boolean search queries to transcript until the relationship between
          // the alphanow search and transcript search is reevaluated
          alphaNowSearchQuery={isBooleanQuery ? [] : searchQuery}
          onReturnToMobileSearch={onReturnToMobileSearch}
        />
      );
    }

    return (
      <AlphaNowTranscript
        key={selectedContent?.transcriptId}
        accessDenied={accessDenied}
        setAccessDenied={setAccessDenied}
        setAccessSuspended={setAccessSuspended}
        onBookmarkChanged={onBookmarkChanged}
        onPurchasedContentChanged={onPurchasedContentChanged}
        content={selectedContent}
        // not cascading boolean search queries to transcript until the relationship between
        // the alphanow search and transcript search is reevaluated
        alphaNowSearchQuery={isBooleanQuery ? [] : searchQuery}
        price={contentPrice}
        contentError={contentError}
        onContentErrorChanged={onContentErrorChanged}
        questionsData={questionsData}
        questionsFetched={questionsFetched}
        onReturnToMobileSearch={onReturnToMobileSearch}
      />
    );
  };
  if (showLegalDisclaimer) {
    return (
      <x.div textAlign="center">
        <x.img h="100%" w="100%" objectFit="fill" maxWidth="100%" src={require("./images/blurred-alphanow.png")} />
        <LegalDisclaimerModal />
      </x.div>
    );
  }

  if (hasServicePlanExpired) {
    return <ExpiredServicePlanModal />;
  }

  if (isLandingPageEnabled) return <LandingPage />;

  return (
    <CitationProvider
      selectedContentId={selectedContentId}
      contentPaymentRequired={selectedContent?.paymentRequired}
      speakers={selectedContent?.speakers}
    >
      <ScrollProvider>
        <PortalMobileTopBar title="AlphaNow" />
        <AlphaNowContainer>
          {hasBackdrop && <Backdrop zIndex="30" />}
          <x.div display="flex" flex="1 1 auto" minH="0">
            <LeftSidebarContainer isExpanded={isSidebarExpanded}>
              {!isMobile && <MenuIcon isSidebarExpanded={isSidebarExpanded} onClick={onSidebarExpanded} />}

              <x.div
                display="flex"
                flexDirection="column"
                minH="0"
                h="-webkit-fill-available"
                pointerEvents={!isSidebarExpanded ? "none" : undefined}
                opacity={isSidebarExpanded ? "1" : "0"}
                transition={`opacity ${SIDEBAR_ANIMATION_SPEED}`}
                position={isSidebarExpanded ? "static" : "absolute"}
              >
                <AlphaNowSearch
                  activeSearchBar={hasBackdrop}
                  setActiveSearchBar={toggleBackdrop}
                  {...{
                    isRequestPrimer,
                    isSidebarExpanded,
                    accessDenied,
                    bookmarksChanged,
                    purchasedContentChanged,
                    setAccessDenied,
                    setServicePlanExpired,
                    setSelectedCompanyId,
                    selectedCompanyId,
                  }}
                />
              </x.div>
            </LeftSidebarContainer>
            <ContentContainer
              isSidebarExpanded={isSidebarExpanded}
              isCompanyPage={isCompanyPage}
              ref={contentContainerRef}
            >
              {contentContainerContent()}
            </ContentContainer>
            {showExpertsSidebar && (
              <ExpertsSidebarContainer isSidebarExpanded={isSidebarExpanded}>
                <ExpertsInfoView
                  contentId={selectedContent.id}
                  productType={selectedContent.productType}
                  speakers={selectedContent.speakers}
                  isPaymentRequired={selectedContent?.paymentRequired}
                />
              </ExpertsSidebarContainer>
            )}
          </x.div>
        </AlphaNowContainer>
      </ScrollProvider>
    </CitationProvider>
  );
};

const ExpertsSidebarContainer = styled.div(({ isSidebarExpanded }) => ({
  width: toPx(EXPERTS_SIDEBAR_WIDTH),
  marginRight: isSidebarExpanded ? toPx(-EXPERTS_SIDEBAR_WIDTH) : "0",
  transition: `margin-right ${SIDEBAR_ANIMATION_SPEED}`,
  flexShrink: 0,
}));

const MenuHitbox = styled.div(({ isSidebarExpanded }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    alignSelf: "end",
    padding: inner.base08,
    position: "absolute", // Overlap with search contents to fade between them
    zIndex: "2",
    pointerEvents: isSidebarExpanded ? "none" : undefined,
    opacity: isSidebarExpanded ? "0" : "1",
    transition: `opacity ${SIDEBAR_ANIMATION_SPEED}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& > *": {
      position: "absolute",
      right: 0,
      top: inner.base06,
      transform: "translateX(50%)",
    },
  };
});

const MenuIcon = ({ isSidebarExpanded, ...props }) => {
  return (
    <MenuHitbox {...{ isSidebarExpanded }} {...props}>
      {!isSidebarExpanded ? <ChevronRightExpansionButton /> : null}
    </MenuHitbox>
  );
};

const ChevronRightExpansionButton = () => {
  const { color } = useThemeTokens();

  return (
    <Tooltip title="Expand" variant="dark">
      <IconButton variant="outline" size="small" color={color.icon.strong._}>
        <ChevronRight />
      </IconButton>
    </Tooltip>
  );
};

const AlphaNowContainer = styled.div(() => {
  const { color } = useThemeTokens();

  return {
    background: color.background.surface.page.default,
    display: "flex",
    flex: "1 1 auto",
    "flex-direction": "column",
    "min-height": "0",
  };
});

const LeftSidebarContainer = React.forwardRef(({ isExpanded, children }, ref) => {
  const { color } = useThemeTokens();

  const { isMobile } = useCheckScreen();
  //TODO [RD1-209]: Remove when new Navigation enabled
  const newNavigationEnabled = useNewNavigation();
  return (
    <x.div
      {...{ ref }}
      marginLeft={isExpanded ? 0 : `calc(${(isMobile ? -1 : -1 / 3) * 100}% + ${toPx(COLLAPSED_LEFT_SIDEBAR_WIDTH)})`}
      marginRight={isMobile && !isExpanded ? `-${toPx(COLLAPSED_LEFT_SIDEBAR_WIDTH)}` : null}
      transition={["margin-left", "padding-top"].map((it) => `${it} ${SIDEBAR_ANIMATION_SPEED} ease`).join(", ")}
      col={{ xs: 1, md: 1 / 3 }}
      bg={newNavigationEnabled ? color.background.surface.recessed : color.interfaces.highlights}
      display="flex"
      flexDirection="column"
    >
      {children}
    </x.div>
  );
});

const ContentContainer = styled.div(({ isSidebarExpanded, isCompanyPage }) => {
  const { color } = useThemeTokens();

  return {
    flexBasis: isSidebarExpanded ? { sm: "100%", md: (2 / 3) * 100 + "%" } : "auto",
    maxWidth: {
      xs: "100%",
      md: isSidebarExpanded ? (2 / 3) * 100 + "%" : "100%",
    },
    overflow: isCompanyPage ? "scroll" : "hidden",
    flexGrow: 1,
    flexShrink: 1,
    background: color.background.surface.page.default,
    padding: 0,
    transition: ["flex-basis", "max-width"].map((it) => `${it} ${SIDEBAR_ANIMATION_SPEED}`).join(", "),
  };
});
