import { Hit, HitAction } from "@alphasights/portal-api-client";
import { currentOrigin } from "pages/AlphaNowPage/utils/logHit";

export type Citation = CitableValue<any> | null;

interface ViewCitationDetails extends BaseContentDetails {
  citation: Citation;
}

interface OnSelectCitationArgs {
  citation: Citation;
  details: ViewCitationDetails;
  shouldUpdateCitation: boolean;
  logHit: (args: Hit) => void;
  setSelectedCitation: React.Dispatch<React.SetStateAction<Citation>>;
  setCitationContributors: () => void;
}

export const onSelectCitationEffect = ({
  citation,
  details,
  shouldUpdateCitation,
  logHit,
  setSelectedCitation,
  setCitationContributors,
}: OnSelectCitationArgs) => {
  if (!shouldUpdateCitation) return;
  const origin = currentOrigin();
  if (!!citation?.citedBy?.length) {
    logHit({
      origin,
      action: HitAction.alphaNowViewCompanyPrimerCitation,
      details,
    });
  }

  setSelectedCitation(citation);
  setCitationContributors();
};
