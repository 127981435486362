import React, { useMemo } from "react";
import { NotableCompetitorsLabels } from "pages/AlphaNowPage/primers/MarketPrimer/utils/constants";
import styles from "./styles/notableCompetitors.module.css";
import { x } from "@xstyled/styled-components";
import { sortBy } from "lodash";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import NotableCompetitorsRow from "./NotableCompetitorsRow";
import useGetCompanyProperties from "pages/AlphaNowPage/primers/hooks/useGetCompanyProperties";
import { useMarketPrimersStore } from "pages/AlphaNowPage/primers/MarketPrimer/state/store";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

type NotableCompetitorsProps = {
  notableCompetitors: NotableCompetitor[];
};

const NotableCompetitors = ({ notableCompetitors }: NotableCompetitorsProps) => {
  const {
    spacing: { inner },
    shape,
    color: { border, text },
  } = useThemeTokens();
  const { companies, companiesV2 } = useMarketPrimersStore();
  const { isAccessible } = usePrimersStore();
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);

  const getSortedNotableCompetitor = useMemo(
    () => (entries: NotableCompetitor[]): NotableCompetitor[] =>
      sortBy(entries, (notableCompetitor: NotableCompetitor) => notableCompetitor.rank.value || 0),
    []
  );

  const headerItems = [
    {
      label: NotableCompetitorsLabels.RANK,
    },
    {
      label: NotableCompetitorsLabels.COMPANY_NAME,
    },
    {
      label: NotableCompetitorsLabels.COMPETITOR_TYPE,
    },
    {
      label: NotableCompetitorsLabels.TREND,
    },
  ];

  return (
    <>
      <x.div
        className={styles.segment}
        display="grid"
        columnGap="10px"
        w="100%"
        gridTemplateColumns="50px 1fr 1fr 1fr"
        color={text.assistive}
        padding={`0 ${inner.base06} ${inner.base05}`}
      >
        {headerItems.map((item) => {
          return (
            <Typography key={item.label} color={text.assistive} variant="pre-title">
              {item.label}
            </Typography>
          );
        })}
      </x.div>
      <Divider />
      <x.div
        borderTop={{
          xs: {
            first: `${shape.border.width.sm} solid ${border.divider}`,
          },
          sm: { first: 0 },
        }}
      >
        {getSortedNotableCompetitor(notableCompetitors).map((competitor, index) => {
          const { rank, companyName, competitorType, trend } = competitor;
          const lastEntry = index === notableCompetitors.length - 1;

          return (
            <SensitiveDataContainer isSensitive={!isAccessible}>
              <div>
                <x.div key={index.toString()} padding={`${inner.base04} ${inner.base06}`}>
                  <NotableCompetitorsRow
                    rank={rank}
                    companyName={companyName}
                    competitorType={competitorType}
                    trend={trend}
                    logoLink={getCompanyProperties(companyName.value).logoLink}
                  />
                </x.div>
                {!lastEntry && <Divider />}
              </div>
            </SensitiveDataContainer>
          );
        })}
      </x.div>
    </>
  );
};

export default NotableCompetitors;
