interface BuildSurveyProps {
  interactions: Interaction[];
  selectedSurveyId: string;
  doubleBlinded: boolean;
}

export const buildSurveyData = ({ interactions, selectedSurveyId, doubleBlinded }: BuildSurveyProps) => {
  const surveyInteractionRows = interactions.filter(isResponseFromSurvey(selectedSurveyId, doubleBlinded));

  return surveyInteractionRows.map((interaction) => {
    return {
      interaction,
      followUps: filterSurveyResponseFollowUps({
        refInteraction: interaction,
        interactions,
      }),
      doubleBlinded,
    };
  });
};

const excludedStates: string[] = ["not_right"];

const filterSurveyResponseFollowUps = ({
  refInteraction,
  interactions,
}: {
  refInteraction: Interaction;
  interactions: Interaction[];
}) => {
  return interactions.filter((interaction) => {
    return (
      !interaction.surveyResponse &&
      interaction.group.id === refInteraction.group.id &&
      interaction.advisorId === refInteraction.advisorId &&
      interaction.previousAdvisorshipId
    );
  });
};

const isResponseFromSurvey = (selectedSurveyId: string, doubleBlinded: boolean) => (interaction: Interaction) =>
  interaction.surveyResponse?.clientSurveyId === selectedSurveyId &&
  interaction.surveyResponse?.state &&
  !excludedStates.some((s) => s === interaction.surveyResponse?.state) &&
  (!doubleBlinded || interaction.surveyResponse?.state === "completed");

export const isPotentialSurveyResponse = (interaction: Interaction) =>
  !interaction.surveyResponse &&
  !interaction.previousAdvisorshipId &&
  ["proposed", "requested"].includes(interaction.state);

export const shouldShowTransId = (interaction: Interaction): boolean => {
  return interaction.surveyResponse && interaction.surveyResponse.state !== "available";
};
