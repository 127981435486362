//Modal
export const cancel = "Cancel";

// Button content
export const cancelContent = "Cancel";
export const clearContent = "Clear";
export const dismissContent = "Dismiss";
export const applyContent = "Apply";

// Expert Request Button Content
export const cancelRequestContent = "Cancel Request";
export const editRequestContent = "Edit Request";
export const requestContent = "Request";
export const requestExpertContent = "Request Expert";
export const requestMultipleExperts = "Request Experts";
export const updateRequestContent = "Update Request";

// Content Card
export const available = "Available";
export const purchased = "Purchased";
export const panel = "Panel";
export const expert = "Expert";
export const upcoming = "Upcoming";
export const featuredExperts = "Featured experts";

// Transcript Details
export const topics = "Topics";
export const relevantColleaguesTitle = "Relevant Colleagues";

// Mentions
export const keywordNotMentioned = "not been mentioned in this transcript. Try rephrasing your search.";
export const viewInTranscriptText = "View In Transcript";

// Request an Expert
export const addANote = "Add a note";
export const notePlaceholder =
  "Let us know why you're requesting an expert and any other details you think will help e.g project info, availability, etc...";
export const primerExpertRequestNotePlaceholder = "Share your availability or leave a note for your account manager.";
export const selectExperts = "Select the expert(s) you want to request";
export const requestExpertsTitle = "Which expert(s) would you like to speak to?";
export const unsuccessfulRequest =
  "The request was unsuccessful. Please contact your dedicated account manager for further assistance.";
export const unsuccessfulFetchRequest = "Error fetching expert request";
export const successfulRequest = "We have received your request, and your account manager will be in touch shortly.";

// Search
export const addKeywordToSearch =
  "Add a keyword to the search above to view all mentions of that keyword within this transcript.";
export const over100 = "100+";
export const over20 = "20+";
export const noResultsContent = "No Results";
export const noResultsFound = "No Results Found";
export const noSearchResults = "Your search generated no results.";
export const suggestions = "Suggestions";
export const searchForMentions = "Search for Mentions";
export const searchWithinTranscript = "Search within transcript...";
export const rephraseYourSearch = "Try rephrasing your search";
export const contactManagerForCall = "Contact your account manager to schedule experts for a 1:1 call";
export const searchAlert = "Search for a company or keyword to explore more research on AlphaNow";

// Transcript
export const accessDeniedHeader = "Alert: ";
export const accessDeniedBody = "The research you're trying to access is not available.";
export const selectATranscriptContent = "Select a Transcript";
export const selectATranscriptDescriptionContent =
  "Select a Transcript to view more details and read the full transcript.";
export const errorLoadingTranscript = "Error loading transcript";
export const transcriptUnavailableTryAgainContent =
  "The transcript is currently not available. Please try again later.";
export const moderatedContentPopover = "Transcripts of expert calls moderated by former buyside investors";
export const investorLedContentPopover = "Transcripts shared by AlphaSights investor clients";
export const privateContentPopover = "Transcripts of your organization's expert calls";
export const purchaseTranscript = "Purchase the transcript to gain full access";
export const perTranscript = "per transcript";
export const accessSuspendedTitle = "Access suspended";
export const accessSuspendedSubtitle =
  "Your access has been temporarily suspended. Please reach out to your AlphaSights point of contact for further information.";

// Questions View
export const errorLoadingQuestionView = "Error loading questions";
export const questionUnavailableTryAgainContent = "The questions are currently not available. Please try again later.";
export const purchasableTranscriptQuestionsHeader = "Questions asked in this transcript";
export const generatedByAITag = "Summarized by Generative AI";

// Comprehensive Summary View
export const errorLoadingComprehensiveSummaryView = "Error loading comprehensive summary";
export const comprehensiveSummaryViewUnavailableTryAgainContent =
  "The comprehensive summary is currently not available. Please try again later.";

// Primer
export const purchaseCompanyPrimer = "Purchase the Company Primer to gain full access";
export const perPrimer = "per Company Primer";

// Market Primer
export const purchaseMarketPrimer = "Purchase the Market Primer to gain full access";
export const perMarketPrimer = "per Market Primer";

//Customer Primer
export const purchaseCustomerPrimer = "Purchase the Customer Primer to gain full access";
export const perCustomerPrimer = "per Customer Primer";

export const lastUpdatedAtHeader = "Last updated ";

// Purchasable Content
export const accessNow = "Access Now";
export const confirmRequest = "Confirm Request";
export const credit = "credit";

// Content - Transcript or Company Primer
export const selectResearch = "Select Research";
export const selectATranscriptOrPrimerDescriptionContent = "Select a Transcript or Primer to view expert insights.";
export const errorLoadingResearch = "Error loading research";
export const unavailableResearch = "The research is currently not available. Please try again later.";
export const searchForExpertInsights = "Search for expert insights...";

// Purchase Transcript
export const accessTranscript = "Access Transcript";
export const optionalCaseCode = "Case code (Optional)";
export const actionNotCompleted = "The action couldn't be completed.";
export const systemError = "System Error: ";
export const alert = "Alert: ";
export const insufficientCredits = "Insufficient credits: ";
export const pricingLoadError =
  "Pricing failed to load. Please reach out to your account manager for pricing information.";
export const primerAlreadyPurchased = "This Company Primer has already been purchased.";
export const transcriptAlreadyPurchased = "This transcript has already been purchased.";
export const purchaseMoreCredits = "Reach out to your AlphaSights point of contact to purchase more credits.";
export const contentLeversChanged =
  "This research is not accessible. Please reach out to your AlphaSights point of contact for further information.";

// Purchase Company Primer
export const accessCompanyPrimer = "Access Company Primer";
export const accessMarketPrimer = "Access Market Primer";
export const accessCustomerPrimer = "Access Customer Primer";

//Service plan access
export const expiredAccessPlanTitle = "Access plan expired";
export const expiredAccessPlanDescription =
  "Your access plan has expired, please reach out to your AlphaSights point of contact to resume access.";

// Legal Disclaimer
export const legalDisclaimerFirstParagraph =
  "The AlphaNow content platform provides expert perspectives on-demand to accelerate your research.";
export const legalDisclaimerSecondParagraph =
  "All AlphaNow content features industry-leading experts, and is moderated by experienced investment professionals, strategy consultants, or in-house analysts.";
export const legalDisclaimerAcknowledgement =
  "I acknowledge and agree that content made available on AlphaNow is governed by the Terms of Service agreed between my employer and AlphaSights.";
export const disclaimer = "Disclaimer: ";
export const legalDisclaimerText =
  "Content made available on AlphaNow is “Work Product”. AlphaSights does not accept any responsibility or liability in respect of the completeness, reliability, legality or non-infringement of third-party intellectual property rights of any such Work Product. Work Product cannot be disclosed, shared, licensed or otherwise made available to any third party without AlphaSights’ express prior written consent. ";
export const agreeAndEnter = "Agree and Enter";

export const industryPerspective = "Industry Perspective";

// Download Transcript
export const downloadFailedBody =
  "The download was unsuccessful, please try again or reach out to your account manager.";

// Upcoming AlphaView
export const upcomingHeaderText = "This Moderated Call will take place on";
export const forAccess = "for access";
export const requestAccess = "Request Access";
export const requestTranscript = "Request access";
export const requestAccessMessage =
  "You can dial into this Moderated Call for no additional cost. We will send you a calendar invitation to the call, and notify you when the transcript is available.";
export const transcriptOnlyRequestAccessMessage = "We will notify you when the transcript is available.";
export const availableSoonText = "The transcript will be available soon";
export const availableAccessMessage = "We will notify you when the transcript is available.";
export const requestConfirmed = "Request confirmed, we will notify you when the transcript is available.";
export const upcomingTranscript = "Upcoming Transcript";
export const transcriptAvailableSoon = "This transcript will be available soon.";
export const callHasAlreadyBegun =
  "The Moderated Call has already begun. Confirm your request to access the transcript when available.";
export const purchasedWithinOrganization = "This research output has already been purchased by your organization.";
export const transcriptSelectedAlert = "We will notify you when the transcript is available";
export const dialInSelectedAlert = "We will notify you when the dial-in is available";
export const transcriptAndDialInSelectedAlert = "We will notify you when the transcript and dial-in are available";

export const companyPrimerDefinition =
  "* Company Primers are expert-led company overviews that support the first week of desktop research with trusted expert perspectives\n* Our internal research team engage the best-in-class experts, including former executives, competitors and customers of the company\n* Click on a sentence to see the contributing expert(s) and request a call";
