import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const tokens = useThemeTokens();

  return {
    card: {
      p: tokens.spacing.inner.base04,
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base03,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base03,
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    scheduledCallTimeLabel: {
      color: tokens.color.text.secondary,
    },
    scheduledCallTimeWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base02,
      alignItems: "center",
    },
  };
};
