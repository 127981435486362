import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

export const ExpertLabel = ({
  expert,
  noWrittenWork = false,
  locked = false,
  customDisabled = "",
  showSecondaryInformation = false,
}) => {
  const disabledStatus = noWrittenWork
    ? "Opted out of written work"
    : locked
    ? "Expert is no longer available"
    : customDisabled
    ? customDisabled
    : null;

  const { spacing } = useThemeTokens();

  return (
    <Typography component="span" color={disabledStatus ? "disabled" : "strong"}>
      {expert.name}
      {(showSecondaryInformation || disabledStatus) && (
        <Typography
          variant="body-small"
          component="span"
          ml={spacing.layout.base}
          color={disabledStatus ? "disabled" : "secondary"}
        >
          {disabledStatus ? disabledStatus : `${expert.relevantCompany} - ${expert.relevantPosition}`}
        </Typography>
      )}
    </Typography>
  );
};
