import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useTranscriptSearchBarStyles = ({
  isSearchFocused = false,
  loading = false,
  variant = "inline",
}: {
  isSearchFocused?: boolean;
  loading?: boolean;
  variant?: "inline" | "popover";
} = {}) => {
  const {
    color: { border, background, text },
    spacing: { inner },
    typography: { body },
    shape,
  } = useThemeTokens();

  return {
    popoverVariantWrapper: {
      w: "417px",
      display: "flex",
      gap: inner.base04,
      alignItems: "center",
    },

    resultsNavigatorWrapper: {
      display: "flex",
      gap: inner.base04,
      alignItems: "center",
      whiteSpace: "nowrap",
      ml: inner.base04,
    },
    resultsNavigatorActions: {
      display: "flex",
      gap: inner.base02,
    },
    resultsNavigatorMentions: {
      display: "flex",
      alignItems: "center",
    },

    searchInputWrapper: {
      w: "-webkit-fill-available",
      display: "flex",
      alignItems: "center",
      background: background.input.default,
      border: `${shape.border.width.sm} solid;`,
      borderColor: isSearchFocused ? border.selected : border.neutral.default,
      borderRadius: `${shape.border.radius.small}`,
      justifyContent: "space-between",
      paddingRight: inner.base03,
      ...(loading && {
        pointerEvents: "none",
        color: {
          hover: null,
        },
      }),
    },
    searchInputDiv: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      gap: inner.base02,
      px: inner.base04,
      w: "1px",
      h: variant === "popover" ? "30px" : "44px",
    },
    searchInputPillsContainer: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      gap: inner.base02,
      overflow: "auto",
    },
    searchInput: {
      border: "0px",
      padding: 0,
      color: text.strong._,
      background: "transparent",
      outline: "none",
      flexGrow: 1,
      ...body.regular,
    },
  };
};
