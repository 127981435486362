import React from "react";
import { Alert, Icon } from "@alphasights/alphadesign-components";
import { Close } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useAlertBannerContainerStyles } from "./AlertBannerContainer.styles";

const Banner = ({ id, children, icon, variant = "info", removeMessage, ...props }) => {
  const { banner, closeButton } = useAlertBannerContainerStyles();

  return (
    <Alert variant={variant} icon={icon} {...props}>
      <x.div {...banner}>
        {children}
        <x.span {...closeButton} onClick={() => removeMessage(id)}>
          <Icon>
            <Close />
          </Icon>
        </x.span>
      </x.div>
    </Alert>
  );
};

export const AlertBannerContainer = ({ messages, removeMessage }) => {
  const { bannersContainer, bannerList } = useAlertBannerContainerStyles();

  if (messages.length === 0) return null;

  return (
    <x.div {...bannersContainer}>
      <x.div {...bannerList}>
        {messages.map(({ id, message, icon, variant, ...props }) => (
          <Banner id={id} message={message} icon={icon} variant={variant} removeMessage={removeMessage} {...props}>
            {message}
          </Banner>
        ))}
      </x.div>
    </x.div>
  );
};
