import styled from "styled-components";
import { useThemeTokens, Typography } from "@alphasights/alphadesign-components";

const StyledTypography = styled(Typography)(({ height }: { height: string }) => {
  const { color, spacing } = useThemeTokens();
  return {
    paddingLeft: spacing.inner.base04,
    color: color.text.assistive,
    display: "flex",
    alignItems: "flex-end",
    height,
  };
});

const PopoverContentContainer = styled.div(({ maxHeight, paddingTop }: { maxHeight: string; paddingTop: string }) => ({
  paddingTop,
  maxHeight,
  overflow: "auto",
  paddingLeft: 0,
}));

export { StyledTypography, PopoverContentContainer };
