import React, { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { x } from "@xstyled/styled-components";
import { areIntervalsOverlapping, compareAsc, endOfDay, endOfWeek, isFuture, startOfDay } from "date-fns";
import { Button, List, ListItem, Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import { InteractionsList } from "./InteractionsList";
import { scrollStartingHour, scrollToTemporarySlot } from "./CalendarFull";
import { ProjectsCalendar, eventHandler } from "../../components/ProjectsCalendar";
import { enrichInteraction, mainInteractionFor } from "../../components/InteractionsPage/helpers/Interaction";
import { parseISO, useTimezone } from "../../providers/TimezoneProvider";
import { RequestAvailabilityView } from "./RequestAvailabilityView";
import { interactionAsEvents } from "./interactionAsEvents";
import { useHistory } from "react-router-dom";
import { usePreference } from "../../hooks/usePreference";
import { findValidBaseDate, isWeekend } from "./CalendarCustomDaysView";
import { BannerContainer } from "./CalendarBanners";
import { CalendarViewPreferences } from "./CalendarViewPreferences";
import { DispatchContext } from "../../components/InteractionsPage/DispatchContext";
import {
  openReadAlong,
  requestChangeInteraction,
  searchProjects,
  submitInvitation,
} from "../../components/InteractionsPage/reducer";
import { interactionsChain } from "../../helpers/interactionHelpers";
import { CalendarFilters } from "./CalendarFilters";
import { useAdvisorsPageStyles } from "views/AdvisorsPage/AdvisorsPage.styles";
import { PageFooter, useNotifications, useNewNavigation, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useTimeoutToggle } from "hooks/useTimeoutToggle";
import { ReschedulingGhostEvent } from "./ReschedulingGhostEvent";
import { Search } from "@alphasights/alphadesign-icons";
import "./calendar-skeleton.css";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { ProjectCalendarViewHeader } from "./ProjectCalendarViewHeader";
import { usePromise } from "@alphasights/ads-community-hooks";
import { apiClient } from "@alphasights/portal-api-client";
import { useProjectsIsReady } from "@alphasights/portal-auth-react";
import { PreferenceType } from "providers/types";

export const interactionComparator = (x, y) => {
  if (x.state === "requested" && y.state === "requested") {
    const xRequestedAt = parseISO(x.requestedAt).getTime();
    const yRequestedAt = parseISO(y.requestedAt).getTime();

    return xRequestedAt - yRequestedAt;
  }

  if (x.state === "requested") return -1;
  if (y.state === "requested") return 1;

  return 0;
};

const hasAvailabilityUntil = ({ interaction: { advisorAvailability }, start = new Date(), end }) =>
  advisorAvailability.find(({ startsAt, endsAt }) =>
    areIntervalsOverlapping({ start: parseISO(startsAt), end: parseISO(endsAt) }, { start, end })
  );

const tabFilterFun = (sundayWorkday) => ({
  all: (interaction) => true,
  requested: (interaction) => interaction.state === "requested",
  "this week": (interaction) =>
    hasAvailabilityUntil({
      interaction,
      end: endOfWeek(new Date(), { weekStartsOn: sundayWorkday ? 0 : 1 }),
    }),
  available: (interaction) =>
    (interaction.advisorAvailability || []).find(({ startsAt, endsAt }) => isFuture(parseISO(startsAt))),
  today: (interaction) => hasAvailabilityUntil({ interaction, end: endOfDay(new Date()) }),
});

const isSchedulableType = (type) =>
  !["Industry Survey", "Direct Engagement"].find((invalid) => type?.includes(invalid));

export const ProjectCalendarView = ({
  scheduledInteractions = [],
  appliedFilters,
  backgroundSyncRunning,
  clientAvailability,
  clientRequests,
  experts = [],
  filterOptions,
  interactionChainDate,
  interactions,
  isFlyoutOpen,
  isMobile,
  loading,
  onCancelPendingRequest,
  onCancelRequestTranscript,
  onResetAllFilters,
  onCloseFlyout,
  onFiltersChange,
  onRequestFollowUp,
  onRequestTranscript,
  onRequestTranscriptPostCall,
  onSaveClientAvailability,
  onSearch,
  onSearchQueryChange,
  onSelectCard,
  onSubmitClientAvailability,
  onSubmitFilters,
  onToggleFilter,
  preferencesEnabled,
  searchQuery,
  showAngleTypes,
  token,
  totalResults,
  transcriptEnabled,
  hasClientPortalMessages,
  onSendNewMessage,
  ...props
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();
  const { logHit } = useTrackUserAction();
  const [selectedTab, setSelectedTab] = useState("all");
  const [view, setView] = useState("calendar");
  const [reschedulingId, setReschedulingId] = useState(null);
  const [reschedulingRequest, setReschedulingRequest] = useState(null);
  const tz = useTimezone();
  const [reschedulingInteractionId, setReschedulingInteractionId] = useState(null);
  const [showProvideAvailabilityBanner, setShowProvideAvailabilityBanner] = useState(false);
  const [currentDate, setCurrentDate] = useState(startOfDay(new Date()));
  const [selectedInteractionIds, setSelectedInteractionIds] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [openEventPopup, setOpenEventPopup] = useState();
  const [calendarRange, setCalendarRange] = useState({});
  const [scrollToTime, setScrollToTime] = useState(scrollStartingHour(new Date()));
  const dispatchGlobal = useContext(DispatchContext);
  const { showErrorBanner } = useNotifications();
  const projectsReady = useProjectsIsReady();

  const { project, projectWithInteractions = [] } = props;

  const {
    preference: calendarPreference,
    updatePreference: updateCalendarPreference,
    promote: promoteCalendar,
  } = usePreference(PreferenceType.CALENDAR, { token });

  const [showTeamAvailabilityBanner, toggleShowTeamAvailabilityBanner] = useTimeoutToggle();

  const styles = useAdvisorsPageStyles();

  const showWeekend = calendarPreference?.attributes?.showWeekend || false;
  const sundayWorkday = calendarPreference?.attributes?.sundayWorkday || false;
  const calendarView = calendarPreference?.attributes?.view || "week";

  const setShowWeekend = useCallback(
    (showWeekend) => {
      updateCalendarPreference({ showWeekend });
    },
    [updateCalendarPreference]
  );

  const setSundayWorkday = (sundayWorkday) => {
    updateCalendarPreference({ sundayWorkday });
  };

  const setCalendarView = (view) => updateCalendarPreference({ view });

  const selectInteraction = useMemo(
    () => (interaction) => setSelectedInteractionIds([...selectedInteractionIds, interaction.id]),
    [selectedInteractionIds]
  );

  const { updatePreference: updateTimezonePreference, promote: promoteTimezone } = usePreference(
    PreferenceType.TIMEZONE
  );

  const setTimezone = (timezone) => updateTimezonePreference({ timezone });

  const getValidInteractions = useCallback(
    (interactions) =>
      interactions
        .filter((interaction) => isSchedulableType(interaction.interactionType))
        .map((interaction) => enrichInteraction(interaction))
        .sort(interactionComparator),
    []
  );

  const validInteractions = useMemo(() => getValidInteractions(interactions), [interactions, getValidInteractions]);

  const projectTotalCardCount = useMemo(
    () =>
      [
        ...new Set(
          getValidInteractions(projectWithInteractions?.interactions ?? []).map((i) => {
            const angle = i.angles[0];
            return `${i.advisorId}-${angle?.id}`;
          })
        ),
      ].length,
    [projectWithInteractions, getValidInteractions]
  );

  const history = useHistory();

  useEffect(() => {
    const validIds = new Set(interactions.map((interaction) => interaction.id));
    const selection = new Set(selectedInteractionIds);
    const difference = (a, b) => [...a].filter((x) => !b.has(x));
    const intersection = (a, b) => [...a].filter((x) => b.has(x));

    if (difference(selection, validIds).length) {
      setSelectedInteractionIds(intersection(selection, validIds));
    }
  }, [interactions, setSelectedInteractionIds, selectedInteractionIds]);

  useEffect(() => {
    if (interactionChainDate) {
      setCurrentDate(interactionChainDate);
      setScrollToTime(scrollStartingHour(interactionChainDate));
    }
  }, [interactionChainDate]);

  useEffect(() => {
    const validFields = ["groups", "status", "companies"];
    const hasInvalidFields = Object.keys(appliedFilters).find((key) => !validFields.includes(key));
    if (hasInvalidFields) {
      const validFilter = (({ companies }) => ({
        companies,
      }))(appliedFilters);
      onFiltersChange(validFilter);
      return;
    }
    if (firstLoad && !loading && calendarPreference) {
      const params = new URLSearchParams(history.location.search);
      const parsed = parseISO(params.get("date"));
      const date = isNaN(parsed.getTime()) ? null : parsed;

      if (date) {
        isWeekend(date, sundayWorkday) && setShowWeekend(true);
        setCurrentDate(date);
      } else {
        setCurrentDate(findValidBaseDate(new Date(), showWeekend, sundayWorkday));
      }

      if (params.get("request-expert-availability") === "true") {
        setView("request-availability");
      }

      const preSelectInteractionId = params.get("interactionId");
      const preSelectTab = params.get("tab");

      const validInteractionId = validInteractions.find(({ id }) => id === preSelectInteractionId);

      if (preSelectInteractionId && validInteractionId) {
        setSelectedTab("all");
        setSelectedInteractionIds([preSelectInteractionId]);
        setOpenEventPopup({ interactionId: validInteractionId.id, date });
        setTimeout(scrollToTemporarySlot, 1000);

        history.replace(history.location.pathname + "?f=status%3Drequested%2Cproposed%2Cscheduled%2Ccompleted");
        logHit({
          origin: "calendar-view",
          action: "SHOW_CALENDAR_VIEW_PRESELECTED",
          advisorshipId: validInteractionId.id,
          projectToken: token,
        });
      } else if (preSelectTab) {
        params.delete("tab");
        history.replace(`${history.location.pathname}?${params.toString()}`);
        setSelectedTab(preSelectTab);
      } else {
        const tab = "available";
        setSelectedTab(tab);
      }

      if (params.get("showProvideAvailabilityBanner")) {
        setShowProvideAvailabilityBanner(true);
        params.delete("showProvideAvailabilityBanner");
        history.replace({
          search: params.toString(),
        });
      }

      logHit({
        origin: "calendar-view",
        action: "SHOW_CALENDAR_VIEW",
        projectToken: token,
      });
      setFirstLoad(false);
    }
  }, [
    loading,
    validInteractions,
    firstLoad,
    setFirstLoad,
    setSelectedTab,
    history,
    token,
    calendarPreference,
    sundayWorkday,
    setShowWeekend,
    showWeekend,
    appliedFilters,
    onFiltersChange,
    project,
    logHit,
  ]);

  const onRequestReschedule = useCallback((interactionId) => {
    setOpenEventPopup(null);
    setSelectedInteractionIds([interactionId]);
    setReschedulingId(interactionId);
    setSelectedTab("all");
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const preSelectInteractionId = params.get("interactionId");
    const validInteractionId = validInteractions.find(({ id }) => id === preSelectInteractionId);
    const isRequestAvailability = params.get("request-availability") === "true";
    const isRequestReschedule = params.get("request-reschedule") === "true";
    const isSchedule = params.get("schedule") === "true";

    if (preSelectInteractionId && validInteractionId && (isRequestAvailability || isRequestReschedule || isSchedule)) {
      setSelectedTab("all");
      setSelectedInteractionIds([preSelectInteractionId]);
      setCurrentDate(new Date());

      if (isSchedule) {
        const firstAvailability = validInteractionId.advisorAvailability
          .map(({ startsAt, endsAt }) => ({
            startsAt: parseISO(startsAt),
            endsAt: parseISO(endsAt),
          }))
          .filter(({ startsAt }) => isFuture(startsAt))
          .sort(({ startsAt: x }, { startsAt: y }) => compareAsc(x, y))[0];

        if (firstAvailability) {
          setCurrentDate(firstAvailability.startsAt);
          setOpenEventPopup({
            interactionId: validInteractionId.id,
            date: firstAvailability.startsAt.getTime(),
          });
        } else {
          setView("request-availability");
        }
      }

      if (isRequestAvailability) {
        setShowProvideAvailabilityBanner(true);
      }

      if (isRequestReschedule) {
        onRequestReschedule(preSelectInteractionId);
      }

      history.replace(history.location.pathname + "?f=status%3Drequested%2Cproposed%2Cscheduled%2Ccompleted");
    }
  }, [validInteractions, setSelectedTab, history, onRequestReschedule, history.location]);

  const tabInteractions = useMemo(() => {
    return validInteractions.filter(tabFilterFun(sundayWorkday)[selectedTab]);
  }, [validInteractions, sundayWorkday, selectedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabExperts = useMemo(() => {
    const ids = new Set(tabInteractions.map((i) => i.id));
    return experts
      .map((e) => ({
        ...e,
        interactions: e.interactions.filter((i) => ids.has(i.id)),
      }))
      .filter((e) => e.interactions.length > 0)
      .sort((x, y) => interactionComparator(mainInteractionFor(x.interactions), mainInteractionFor(y.interactions)));
  }, [tabInteractions, experts]);

  const selectedInteractions = useMemo(
    () => validInteractions.filter((interaction) => selectedInteractionIds.includes(interaction.id)),
    [validInteractions, selectedInteractionIds]
  );

  const [preselected, setPreselected] = useState(null);

  const onAttendeesUpdated = useCallback(
    (params) => {
      return dispatchGlobal(submitInvitation({ token, ...params }));
    },
    [dispatchGlobal, token]
  );

  const onRequestChangeInteraction = useCallback((state) => dispatchGlobal(requestChangeInteraction(state)), [
    dispatchGlobal,
  ]);

  useEffect(() => {
    if (!projectsReady) return;

    dispatchGlobal(searchProjects({ projects: [project] }));
  }, [projectsReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const onReadAlong = useCallback(
    ({ interaction, recording, transcript }) => {
      onSelectCard(interaction);

      setTimeout(() => dispatchGlobal(openReadAlong(interaction, recording, transcript)), 200);
    },
    [dispatchGlobal, onSelectCard]
  );

  const reloadInteractions = useCallback(() => onSubmitFilters(appliedFilters), [onSubmitFilters, appliedFilters]);

  const events = useMemo(() => {
    const filteredInteractions = selectedInteractions.filter(tabFilterFun(sundayWorkday)[selectedTab]);

    const events = [
      ...filteredInteractions.filter((i) => i.state !== "scheduled" && i.state !== "completed"),
      ...validInteractions.filter((i) => i.state === "scheduled" || i.state === "completed"),
    ]
      .flatMap((interaction) =>
        interactionAsEvents({
          interaction,
          selectedInteractions: filteredInteractions,
          projectToken: token,
          needComplianceReview: project.enableMessagesComplianceReview,
          requestPending: clientRequests.find((req) => req.interactionId === interaction.id),
          deps: {
            onSelectCard,
            onCancelRequestTranscript,
            transcriptEnabled,
            onRequestTranscript,
            onRequestReschedule,
            onRequestFollowUp,
            onCancelPendingRequest,
            onAttendeesUpdated,
            onRequestChangeInteraction,
            onRequestTranscriptPostCall,
            onReadAlong,
            selectInteraction,
          },
        })
      )
      .map((e) => ({ ...e, tz: tz.currentTimezone }));

    const interactionsIdsOnCalendar = events.map(({ interaction: { id } }) => id);

    const scheduledCompletedEvents = scheduledInteractions
      .filter(({ id }) => !interactionsIdsOnCalendar.includes(id))
      .flatMap((interaction) =>
        interactionAsEvents({
          interaction,
          selectedInteractions: filteredInteractions,
          forceHighlight: false,
          projectTitle: null,
          needComplianceReview: project.enableMessagesComplianceReview,
        })
      )
      .map((e) => ({ ...e, tz: tz.currentTimezone }));

    return [...events, ...scheduledCompletedEvents];
  }, [
    selectedInteractions,
    sundayWorkday,
    selectedTab,
    validInteractions,
    scheduledInteractions,
    token,
    clientRequests,
    onSelectCard,
    onCancelRequestTranscript,
    transcriptEnabled,
    onRequestTranscript,
    onRequestReschedule,
    onRequestFollowUp,
    onCancelPendingRequest,
    onAttendeesUpdated,
    onRequestChangeInteraction,
    onRequestTranscriptPostCall,
    onReadAlong,
    selectInteraction,
    tz.currentTimezone,
    project,
  ]);

  const onToggleInteractionId = (interactionId) => {
    const interaction = validInteractions.find((int) => int.id === interactionId);
    const linkIds = interactionsChain(interaction, validInteractions)
      .filter(tabFilterFun(sundayWorkday)[selectedTab])
      .map((int) => int.id);

    if (selectedInteractionIds.includes(interactionId)) {
      setSelectedInteractionIds((previous) => previous.filter((id) => !linkIds.includes(id)));
    } else {
      setSelectedInteractionIds((previous) => [...previous, ...linkIds]);
      logHit({
        origin: "calendar-view",
        action: "SELECT_ADVISOR_CARD",
        projectToken: token,
        advisorshipId: interactionId,
      });
    }
  };

  const onAvailabilityRequestGoBack = () => {
    setReschedulingInteractionId(null);
    setSelectedInteractionIds([]);
    setView("calendar");
  };

  const onShowWeekend = (val) => {
    setShowWeekend(val);
    setCurrentDate(findValidBaseDate(currentDate, val, sundayWorkday));
  };

  const onSundayWorkday = (val) => {
    setSundayWorkday(val);
    setCurrentDate(findValidBaseDate(currentDate, showWeekend, val));
  };

  const onViewUpcoming = (date) => {
    if (isWeekend(date, sundayWorkday)) {
      setShowWeekend(true);
    }
    setCurrentDate(date);
    setScrollToTime(scrollStartingHour(date));
  };

  const selectedToShow = validInteractions
    .filter((i) => selectedInteractionIds.length === 0 || selectedInteractionIds.includes(i.id))
    .filter(tabFilterFun(sundayWorkday)[selectedTab]);

  const notSelectedButShowAnyway = validInteractions.filter(
    (interaction) => ["scheduled", "completed"].includes(interaction.state) && !selectedToShow.includes(interaction)
  );

  const { data: calls } = usePromise({
    fn: () => apiClient.get(`/api/auth-user/upcoming-calls`),
  });

  const otherProjectsScheduled = useMemo(
    () =>
      (calls || []).filter((call) => {
        return call.projectToken !== token;
      }),
    [calls, token]
  );

  const deps = {
    onSelectCard,
    onCancelRequestTranscript,
    transcriptEnabled,
    onRequestTranscript,
    onRequestReschedule,
    onRequestFollowUp,
    onCancelPendingRequest,
    onAttendeesUpdated,
    onRequestChangeInteraction,
    onRequestTranscriptPostCall,
    onReadAlong,
    selectInteraction,
    reloadInteractions,
    onSendNewMessage: ({ interaction, type }) => onSendNewMessage(interaction, type),
  };
  const [showClientAvailability, setShowClientAvailability] = useState(true);

  const hideAvailability = () => setShowClientAvailability(false);

  const onSaveClientAvailabilityWrapper = (args) => {
    return onSaveClientAvailability(args)
      .then((res) => {
        toggleShowTeamAvailabilityBanner();
        setShowProvideAvailabilityBanner(false);

        return res;
      })
      .catch(() => {
        showErrorBanner("Error. Availability could not be saved.");
      });
  };

  const defaultHandler = eventHandler({
    deps,
    onAttendeesUpdated,
    onRequestChangeInteraction,
    setPreselected,
    tz,
    onSubmitClientAvailability,
    onSaveClientAvailability: onSaveClientAvailabilityWrapper,
    projectToken: token,
    selectedInteractions,
  });

  if (isMobile) {
    return <div>View not available for mobile and low resolutions</div>;
  }

  const filterComponent = (
    <CalendarFilters
      appliedFilters={appliedFilters}
      options={filterOptions}
      project={project}
      selectedTabState={[
        selectedTab,
        (tab) => {
          setSelectedTab(tab);
        },
      ]}
      onSubmitFilters={onSubmitFilters}
      onToggleFilter={onToggleFilter}
      onResetAllFilters={onResetAllFilters}
      searchQuery={searchQuery}
    />
  );

  return (
    <Container isFlyoutOpen={isFlyoutOpen} onOverlayClick={onCloseFlyout}>
      <div className="aui-bg-white aui-flex aui-flex-grow aui-min-h-0">
        <div className="aui-flex aui-flex-grow">
          {reschedulingId && (
            <ReschedulingGhostEvent
              currentDate={currentDate}
              interaction={interactions.find(({ id }) => id === reschedulingId)}
              onReschedulingDone={(reschedulingRequest) => {
                setReschedulingId(null);
                setReschedulingRequest(reschedulingRequest);
              }}
              onCancel={() => {
                setReschedulingId(null);
              }}
            />
          )}
          {view === "calendar" && (
            <>
              {isFlyoutOpen && !newNavigationEnabled ? (
                <x.div {...styles.collapsedSearchStyles}>
                  <Button size="large" variant="icon" onClick={onCloseFlyout} {...styles.iconButtonStyles}>
                    <Search />
                  </Button>
                </x.div>
              ) : (
                <div
                  className={`aui-overflow-y-auto ${
                    newNavigationEnabled ? "" : "no-scrollbar"
                  } aui-border-0 aui-border-r aui-border-solid aui-border-gray-300 aui-bg-grey-1 aui-flex`}
                  style={{
                    width: 525,
                    marginLeft: isFlyoutOpen ? -525 : 0,
                    opacity: isFlyoutOpen ? 0 : 1,
                    pointerEvents: isFlyoutOpen ? "none" : "auto",
                    transition: "all 0.2s ease-in 0s",
                  }}
                >
                  <x.div
                    className="aui-flex aui-flex-col aui-flex-grow"
                    p={newNavigationEnabled ? inner.base06 : inner.base08}
                    pb={newNavigationEnabled ? 0 : undefined}
                  >
                    <ProjectCalendarViewHeader
                      loading={loading}
                      onSearch={onSearch}
                      onSearchQueryChange={onSearchQueryChange}
                      filterComponent={filterComponent}
                      searchQuery={searchQuery}
                      isCollapsed={isFlyoutOpen}
                      onClickToExpand={onCloseFlyout}
                    />
                    {!newNavigationEnabled && (
                      <x.div {...styles.shortRow}>
                        {loading ? (
                          <>
                            <x.div flexGrow={1} mt={inner.base06}>
                              <NoXMarginSkeleton />
                            </x.div>
                            <x.div flexGrow={1} mt={inner.base06}>
                              <NoXMarginSkeleton />
                            </x.div>
                          </>
                        ) : (
                          <>
                            <x.div flexGrow={1}>
                              <Button
                                onClick={() =>
                                  setSelectedInteractionIds(
                                    selectedInteractionIds.length > 0
                                      ? []
                                      : tabExperts.flatMap((obj) => obj.interactions.map((i) => i.id))
                                  )
                                }
                                variant="link"
                                data-testid={
                                  selectedInteractionIds.length > 0
                                    ? "interactions-unselect-all"
                                    : "interactions-select-all"
                                }
                                style={{ height: "initial", outline: "none" }}
                              >
                                <Typography variant="body-em">
                                  {selectedInteractionIds.length > 0 ? "Deselect All" : "Select All"}
                                </Typography>
                              </Button>
                            </x.div>
                            <List direction="horizontal" gap={12}>
                              <ListItem my="auto" variant="compact" data-testid="interactions-summary">
                                <Typography {...styles.regularSpan} variant="body-small">
                                  Showing
                                </Typography>
                                &nbsp;
                                <Typography {...styles.boldPrimarySpan} variant="body-small-em">
                                  {tabExperts.length}
                                </Typography>
                                {projectTotalCardCount > 0 && (
                                  <>
                                    &nbsp;
                                    <Typography {...styles.regularSpan} variant="body-small">
                                      / {projectTotalCardCount}
                                    </Typography>
                                  </>
                                )}
                              </ListItem>
                            </List>
                          </>
                        )}
                      </x.div>
                    )}
                    <div
                      className={
                        newNavigationEnabled
                          ? "aui-flex aui-flex-grow"
                          : "aui-relative aui-overflow-y-auto no-scrollbar aui-flex-grow aui-flex aui-flex-col aui-pt-4"
                      }
                    >
                      <InteractionsList
                        selectedInteractionIds={selectedInteractionIds}
                        onToggleInteractionId={onToggleInteractionId}
                        onSelectCard={onSelectCard}
                        loading={loading}
                        {...props}
                        onResetAllFilters={(args) => {
                          setSelectedTab("all");
                          return onResetAllFilters(args);
                        }}
                        experts={tabExperts}
                        selectedTab={selectedTab}
                        clientRequests={clientRequests}
                        onRequestReschedule={onRequestReschedule}
                        appliedFilters={appliedFilters}
                        onRequestCancel={onRequestChangeInteraction}
                        footer={<PageFooter classNames="aui-mt-auto" />}
                      />
                    </div>
                  </x.div>
                </div>
              )}

              {firstLoad ? (
                <CalendarLoading />
              ) : (
                <div className="aui-flex-grow">
                  <BannerContainer
                    relativeTo="#interactions-main-content"
                    offsetProps={{ style: { width: 525 } }}
                    className="aui-h-screen aui-w-full"
                    interactions={selectedInteractions}
                    showWeekend={showWeekend}
                    sundayWorkday={sundayWorkday}
                    start={calendarRange.start}
                    end={calendarRange.end}
                    onViewUpcoming={onViewUpcoming}
                    showTeamAvailabilityBanner={showTeamAvailabilityBanner}
                    onHideTeamAvailability={hideAvailability}
                    showReschedulingSelectBanner={reschedulingId}
                    reschedulingRequest={reschedulingRequest}
                    showProvideAvailabilityBanner={showProvideAvailabilityBanner}
                    onHideProvideAvailabilityBanner={() => setShowProvideAvailabilityBanner(false)}
                  />
                  {projectWithInteractions && (
                    <ProjectsCalendar
                      openEventPopupState={[openEventPopup, setOpenEventPopup]}
                      projects={[
                        {
                          id: projectWithInteractions.id,
                          angles: projectWithInteractions.angles?.filter((a) => !a.parent) || [],
                          allInteractions: projectWithInteractions.interactions || [],
                          anglesTaggedToClient: project.anglesTaggedToClient,
                          token,
                          clientAvailability,
                          selectedInteractionIds: selectedInteractionIds.filter((id) =>
                            selectedToShow.find((a) => a.id === id)
                          ),
                          clientRequests,
                          transcriptEnabled,
                          backgroundSyncRunning,
                          hasClientPortalMessages,
                          interactions: [...selectedToShow, ...notSelectedButShowAnyway],
                          enableMessagesComplianceReview: projectWithInteractions.enableMessagesComplianceReview,
                        },
                      ]}
                      secondaryProjects={[
                        {
                          transcriptEnabled: false,
                          interactions: [],
                        },
                      ]}
                      upcomingCalls={otherProjectsScheduled || []}
                      eventHandler={defaultHandler}
                      preselect={preselected}
                      token={token}
                      className="aui-h-screen aui-w-full no-scrollbar"
                      currentDate={currentDate}
                      setCurrentDate={setCurrentDate}
                      scrollToTime={scrollToTime}
                      setScrollToTime={setScrollToTime}
                      showWeekend={showWeekend}
                      setShowWeekend={onShowWeekend}
                      sundayWorkday={sundayWorkday}
                      setSundayWorkday={onSundayWorkday}
                      view={calendarView}
                      setView={setCalendarView}
                      onRangeChange={setCalendarRange}
                      toolbarChildren={
                        preferencesEnabled && (
                          <div
                            className="aui-ml-auto aui-order-last aui--mr-8"
                            data-testid="calendar-view-preferences-button"
                          >
                            <CalendarViewPreferences
                              promoteCalendar={promoteCalendar}
                              promoteTimezone={promoteTimezone}
                            />
                          </div>
                        )
                      }
                      onTimezoneChanged={setTimezone}
                      showClientAvailability={showClientAvailability}
                      setShowClientAvailability={setShowClientAvailability}
                      reschedulingId={reschedulingId}
                      {...props}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {view === "request-availability" && (
            <RequestAvailabilityView
              experts={tabExperts}
              selectedInteractions={selectedInteractions}
              token={token}
              events={events}
              onGoBack={onAvailabilityRequestGoBack}
              reschedulingInteractionId={reschedulingInteractionId}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              showWeekend={showWeekend}
              setShowWeekend={onShowWeekend}
              sundayWorkday={sundayWorkday}
              setSundayWorkday={onSundayWorkday}
              view={calendarView}
              setView={setCalendarView}
              onSubmitClientAvailability={onSubmitClientAvailability}
              onToggleInteractionId={onToggleInteractionId}
              {...props}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

const CalendarLoading = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div flexGrow={1} data-testid="calendar-loading">
      <x.div mb={inner.base06} mt={inner.base04}>
        <Skeleton width="30%" />
      </x.div>
      <x.div display="flex">
        {[...Array(5).keys()].map((idx) => (
          <x.div key={idx} w="20%">
            <Skeleton width="50px" className="justify-center" />
            <Skeleton count={20} variant="table" />
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

const Container = ({ children }) => {
  return <x.div className="aui-overflow-hidden aui-flex aui-flex-grow aui-min-h-0">{children}</x.div>;
};
