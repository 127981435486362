import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import showdown from "showdown";

import { x } from "@xstyled/styled-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import { Button, useThemeTokens, Divider } from "@alphasights/alphadesign-components";

import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import {
  addKeywordToSearch,
  errorLoadingTranscript,
  noResultsFound,
  searchForMentions,
  keywordNotMentioned,
  viewInTranscriptText,
} from "content/AlphaNow";
import { SPEAKER_ENGLISH_TRANSLATIONS, desktopContentContainerWidth } from "constants/AlphaNow";
import { removeTabs } from "content/AlphaNow/utils";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import SpeakerIcon from "pages/AlphaNowPage/components/AlphaNowTranscript/SpeakerIcon";
import "../../index.css";

showdown.extension("remove-encapsulator-paragraphs", function () {
  return [
    {
      type: "output",
      filter: (text) => text.replace(/<\/?p[^>]*>/g, ""),
    },
  ];
});

const MentionsView = ({
  contentId,
  data,
  isSuccess,
  keywords,
  viewInTranscript,
  isConversation,
  speakers,
  containerWidth,
  contentType,
}) => {
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);

  const {
    font: { size },
    spacing: { layout },
  } = useThemeTokens();
  const [mentions, setMentions] = useState([]);

  const converter = new showdown.Converter({
    extensions: ["remove-encapsulator-paragraphs"],
  });
  const { results, total } = data;
  const hasKeywords = keywords.length > 0;
  const hasMultipleKeywords = keywords.length > 1;
  const keywordLabels = keywords.map((word) => word.label).join(", ");
  let mentionIndex = -1;

  const mentionsNotFound = `The word${hasMultipleKeywords ? `s` : ``} ${keywordLabels} ${
    hasMultipleKeywords ? `have` : `has`
  } ${keywordNotMentioned}`;

  const countMentionIndex = (node) => {
    // mentions come from the search api with an <em> tag
    node.type === "tag" && node.name === "em" && mentionIndex++;
  };

  const handleMentionSelect = (currentTarget) => {
    viewInTranscript(currentTarget);

    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowViewMention,
      details: { contentId },
    });
  };

  useEffect(() => {
    const mentionsData = isConversation
      ? results
      : results && results.reduce((acc, result) => Object.assign(acc, result.highlight.transcript_content), []);
    if (mentionsData) {
      mentionsData.sort((mentionOne, mentionTwo) => mentionOne.position - mentionTwo.position);
      setMentions(mentionsData);
    }
  }, [data, isConversation, results]);

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;
    const timeSpentAction = HitAction.alphaNowTimeSpentOnMentionsView;
    const scrollAction = HitAction.alphaNowMentionsViewScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenMentionsView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSuccess) {
    return <ErrorMessage header={errorLoadingTranscript} />;
  }

  if (!hasKeywords) {
    return <ErrorMessage header={searchForMentions} body={addKeywordToSearch} />;
  }

  if (!total) {
    return <ErrorMessage header={noResultsFound} body={mentionsNotFound} />;
  }

  return (
    <x.div m="0 auto" maxW={{ xs: "none", sm: desktopContentContainerWidth }}>
      {mentions.map((mention, index) => {
        const isLastMention = index === mentions.length - 1;
        const str = `“${removeTabs(isConversation ? mention.speechContent : mention)}”`;
        const speakerInfo =
          isConversation &&
          speakers.find(
            (s) =>
              s.transcriptParticipantRole === mention.participant ||
              s.transcriptParticipantRole === SPEAKER_ENGLISH_TRANSLATIONS[mention.participant]
          );
        return (
          <x.div display="flex" flexDirection="column" key={index} spaceY="300">
            <x.div flex row color="neutral900" fontSize="500" lineHeight="500">
              {isConversation && (
                <SpeakerIcon
                  speaker={speakerInfo}
                  tooltip
                  containerWidth={containerWidth}
                  contentType={contentType}
                  isMentionsView={true}
                />
              )}
              <x.div
                className="mentions-container"
                data-testid={`mention-text-${index}`}
                pl="700"
                maxW={{ xs: "none", sm: "550px" }}
                fontSize={size["03"]}
              >
                {ReactHtmlParser(converter.makeHtml(str), {
                  transform: countMentionIndex,
                })}
              </x.div>
            </x.div>
            <x.div flex row color="neutral700" spaceX="250">
              <Button
                variant="link"
                ml="auto"
                size="small"
                onClick={({ currentTarget }) => {
                  handleMentionSelect(currentTarget);
                }}
                endIcon={<ChevronRight />}
                data-mention-index={mentionIndex}
              >
                {viewInTranscriptText}
              </Button>
            </x.div>
            {!isLastMention && (
              <x.div mt="400">
                <Divider marginTop={layout.base} marginBottom={layout.base02} />
              </x.div>
            )}
          </x.div>
        );
      })}
    </x.div>
  );
};

MentionsView.defaultProps = {
  data: {},
  isSuccess: false,
  keywords: [],
  viewInTranscript: () => {},
};

MentionsView.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        highlight: PropTypes.shape({
          transcript_content: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }),
  isSuccess: PropTypes.bool,
  keywords: PropTypes.arrayOf(PropTypes.shape()),
  viewInTranscript: PropTypes.func,
  isConversation: PropTypes.bool,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      jobTitle: PropTypes.string,
      company: PropTypes.string,
      transcriptParticipantRole: PropTypes.string,
    })
  ),
};

export default MentionsView;
