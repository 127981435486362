import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const tokens = useThemeTokens();

  return {
    wrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
    },
    contentWrapper: {
      p: tokens.spacing.inner.base04,
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base04,
      flex: 1,
    },
    methodOptionWrapper: {
      display: "flex",
      gap: tokens.spacing.inner.base02,
    },
    methodOption: {
      flex: 1,
      borderStyle: "solid",
      borderColor: tokens.color.border.neutral.default,
      borderWidth: "1px",
      borderRadius: tokens.shape.border.radius.small,
      px: tokens.spacing.inner.base03,
      py: tokens.spacing.inner.base02,
    },
    methodOptionSelected: {
      borderColor: tokens.color.border.selected,
    },
    optionsSection: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base02,
    },
    inputsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base04,
    },
  };
};
