import { useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaNowSRMProductType } from "@alphasights/client-portal-shared";

const useStyles = (primerType: AlphaNowSRMProductType, disabled: boolean) => {
  const {
    color: { background },
  } = useThemeTokens();
  if (disabled) {
    return { backgroundColor: background.disabled };
  }
  let backgroundColor;
  switch (primerType) {
    case AlphaNowSRMProductType.companyPrimer:
      backgroundColor = background.decorative.base06;
      break;
    case AlphaNowSRMProductType.marketPrimer:
      backgroundColor = background.decorative.base;
      break;
    default:
      backgroundColor = background.decorative.base04;
  }
  return { backgroundColor };
};

export default useStyles;
