import { DialInNumber } from "@alphasights/portal-api-client";

export {};

// client-portal/backend/src/main/kotlin/com/alphasights/clientportal/web/rest/response/InteractionResponse.kt
declare global {
  interface SearchHighlight {
    fieldName: string;
    terms: string[];
  }

  interface InteractionEdge {
    cursor: string;
    highlights: SearchHighlight[];
    node: Interaction;
    score: number;
    updatedFields: string[];
  }

  interface Interaction {
    id: string;
    advisorId: string;
    previousAdvisorshipId?: string;
    followUpId?: string;
    group: Group;
    language?: string;
    state: string;
    workstreamId: string;
    surveyResponse: SurveyResponse;
    hidden: Boolean;
    highlights?: SearchHighlight[];
    score: number;
    recordings: Recording[];
    projectToken: string; // added by reducer
    isActiveProject: boolean; // added by reducer
    customerKnowledges: CustomerKnowledge[];
    advisorCompany?: string;
    role?: string;
    advisorName: string;
    relevanceStatement?: string;
    proposedAt: string;
    newlyAdded: boolean;
    allowNonHourAutoBook: boolean;
    angles: Angle[];
    scheduledCallTime: string;
    dialInNumbers: DialInNumber[];
    newspeakProvider: string | null;
    clientAccessNumber: string | null;
    clientAccessCode: string | null;
    callMeUrl: string | null;

    [key: string]: any;
  }

  interface Group {
    id: string;
    name: string;
    parent: Group;
    angleTypeName: string;
  }

  interface SurveyResponse {
    clientSurveyId: string;
    transId?: string;
    state: "available" | "requested" | "completed" | "partially_completed" | "sent" | "answered" | string;
  }

  interface AngleType {
    id: Number;
    name: string;
  }

  interface Angle {
    id: string;
    title: string;
    type?: AngleType;
    parent?: Angle;
  }

  interface Recording {
    id: string;
    visibleToClient: boolean;
    expiresAt?: string;
    purgedAt?: string;
    upgradableUntil?: string;
    upgradable: boolean;
    transcriptRequests: TranscriptRequest[];
    url?: string;
    obfuscated: boolean;
    createdAt?: string;
    length?: number;
  }

  interface TranscriptRequest {
    id: string;
    token: string;
    completed: boolean;
    transcriptType: "ai" | "regular" | "summary";
    purgedAt?: string;
    visibleToClientUpdatedAt?: string;
  }

  interface Vendor {
    id: string;
    name: string;
  }

  interface CustomerKnowledge {
    id: number;
    vendor: Vendor;
    experience: Experience;
    role?: Role;
    lastEvaluatedAt?: string;
    rank: number;
    budget?: number;
    solutions: string[];
  }
}

export enum Experience {
  Uses = "uses",
  Churned = "churned",
  Evaluated = "evaluated",
  Aware = "aware",
  Unaware = "unaware",
}

export enum Role {
  Kdm = "kdm",
  EvaluationTeam = "evaluation_team",
  User = "user",
}
