export const useStyles = () => {
  return {
    wrapper: {
      backgroundColor: "white",
      h: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  };
};
