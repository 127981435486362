import { SearchTerm } from "models/content/Search";

export const DataTestIds = {
  companyPrimer: "company-primer-view",
  purchasingPrimer: "company-primer-loading",
  errorToPurchasePrimer: "company-primer-error",
  loadedCompanyPrimer: "company-primer-loaded",
};

export const PrimerStatus = {
  error: "error",
  loading: "loading",
  idle: "idle",
};

export interface AlphaNowCompanyPrimerProps {
  content: CompanyPrimerContentV1 | CompanyPrimerContentV2 | CompanyPrimerContentV3;
  isSidebarExpanded: boolean;
  contentError: string;
  onContentErrorChanged: (error: string) => void;
  onBookmarkChanged: () => void;
  onPurchasedContentChanged: () => void;
  price?: number;
  alphaNowSearchQuery?: SearchTerm[];
  onReturnToMobileSearch: () => void;
  isFetchingContent: boolean;
  isDeliverables?: boolean;
}
