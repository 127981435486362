import React, { useCallback, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, Pill, Popover, Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useBarGraphStyles } from "./GroupedBarChart.styles";
import { Experience } from "models/Interaction";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import { Info, SortAZ } from "@alphasights/alphadesign-icons";
import { ExperienceIcon } from "../ExperienceIcon";
import { IN_ORDER_EXPERIENCES } from "../helpers";
import { HitAction } from "@alphasights/portal-api-client";

interface CustomerKnowledgeCount {
  [Experience.Uses]?: number;
  [Experience.Churned]?: number;
  [Experience.Evaluated]?: number;
  [Experience.Aware]?: number;
  [Experience.Unaware]?: number;
}

export const GroupedBarChart = ({
  isClosed,
  onClose,
  isLoading = false,
  targetCompanies,
}: {
  isClosed: boolean;
  onClose: any;
  isLoading?: boolean;
  targetCompanies?: AlphaCompany[];
}) => {
  const styles = useBarGraphStyles();
  const { interactions, filteredInteractions, logCKHit } = useCustomerKnowledgeContext();
  const [selectedVendor, setSelectedVendor] = useState<string | undefined>(undefined);

  const ckCountGroupedByVendor = useMemo(
    () =>
      countCustomerKnowledges(
        interactions.flatMap((interaction) =>
          interaction.customerKnowledges.filter(
            (ck, index) =>
              index ===
              interaction.customerKnowledges.findIndex(
                (o) => ck.vendor.id === o.vendor.id && ck.experience === o.experience
              )
          )
        )
      ),
    [interactions]
  );

  const filteredCkCountGroupedByVendor = useMemo(
    () =>
      countCustomerKnowledges(
        filteredInteractions.flatMap((interaction) =>
          interaction.customerKnowledges.filter(
            (ck, index) =>
              index ===
              interaction.customerKnowledges.findIndex(
                (o) => ck.vendor.id === o.vendor.id && ck.experience === o.experience
              )
          )
        )
      ),
    [filteredInteractions]
  );

  const maxCount = useMemo(
    () =>
      Math.max(
        ...Object.entries(ckCountGroupedByVendor).flatMap(([_, experienceCounts]) =>
          Object.entries(experienceCounts).reduce((sum, [_, experienceCounts]) => sum + experienceCounts, 0)
        )
      ),
    [ckCountGroupedByVendor]
  );

  const openChart = useCallback(() => {
    onClose();

    logCKHit({
      action: HitAction.customerKnowledgeChartToggle,
      details: {
        value: "open",
      },
    });
  }, [onClose, logCKHit]);

  const sortChartEntriesFn = useCallback(
    (entry1: any[], entry2: any[]) => {
      const targetCompaniesNames = targetCompanies?.map((company) => company.name) ?? [];

      if (targetCompaniesNames.includes(entry1[0]) && !targetCompaniesNames.includes(entry2[0])) {
        return -1;
      } else if (!targetCompaniesNames.includes(entry1[0]) && targetCompaniesNames.includes(entry2[0])) {
        return 1;
      }
      return entry1[0].localeCompare(entry2[0]);
    },
    [targetCompanies]
  );

  if (isLoading) {
    return (
      <x.div {...styles.openBarChartWrapper} data-testid="chart-loading">
        <VendorBarChartSkeleton />
        <VendorBarChartSkeleton />
        <VendorBarChartSkeleton />
      </x.div>
    );
  }

  if (isClosed) {
    return (
      <x.div {...styles.closedBarChartWrapper} data-testid="closed-chart">
        <IconButton size="medium" variant="outline" key="rounded-button" onClick={openChart} testId="open-chart">
          <SortAZ />
        </IconButton>
      </x.div>
    );
  }

  return (
    <x.div {...styles.openBarChartWrapper} data-testid="opened-chart">
      <x.div {...styles.groupedBarChartHeader}>
        <CustomerKnowledgeInfo />
        <Pill variant="blue" isInteractive={false}>
          Beta
        </Pill>
      </x.div>
      {Object.entries(ckCountGroupedByVendor)
        .sort(sortChartEntriesFn)
        .map(([vendor, experienceCounts]) => (
          <VendorBarChart
            key={vendor}
            maxCount={maxCount}
            experienceCounts={experienceCounts}
            filteredExperienceCounts={filteredCkCountGroupedByVendor[vendor]}
            vendor={vendor}
            isSelected={selectedVendor === vendor || !selectedVendor}
            setSelectedVendor={setSelectedVendor}
          />
        ))}
    </x.div>
  );
};

const countCustomerKnowledges = (customerKnowledges: CustomerKnowledge[]) => {
  return customerKnowledges.reduce((acc: { [key: string]: CustomerKnowledgeCount }, customerKnowledge) => {
    const currentExperienceCount =
      acc[customerKnowledge.vendor.name] && acc[customerKnowledge.vendor.name][customerKnowledge?.experience];
    return {
      ...acc,
      [customerKnowledge.vendor.name]: {
        ...acc[customerKnowledge.vendor.name],
        [customerKnowledge?.experience]: (currentExperienceCount ?? 0) + 1,
      },
    };
  }, {});
};

const VendorBarChart = ({
  vendor,
  experienceCounts,
  filteredExperienceCounts = {},
  maxCount,
  isSelected,
  setSelectedVendor,
}: {
  vendor: string;
  experienceCounts: CustomerKnowledgeCount;
  filteredExperienceCounts?: CustomerKnowledgeCount;
  maxCount: number;
  isSelected: boolean;
  setSelectedVendor: (vendor: string | undefined) => void;
}) => {
  const styles = useBarGraphStyles();
  const [anchorEl, setAnchorEl] = React.useState(undefined);

  const detailsPopoverOpen = Boolean(anchorEl);
  const vendorExperiencesCount = Object.entries(experienceCounts).reduce(
    (sum, [_, experienceCounts]) => sum + experienceCounts,
    0
  );
  const filteredExperiencesCount = Object.entries(filteredExperienceCounts).reduce(
    (sum, [_, experienceCounts]) => sum + experienceCounts,
    0
  );

  const onMouseEnterChart = (event: any) => {
    setSelectedVendor(vendor);
    setAnchorEl(event.currentTarget);
  };
  const onMouseLeaveChart = () => {
    setSelectedVendor(undefined);
    setAnchorEl(undefined);
  };

  return (
    <x.div {...styles.vendorGraphWrapper}>
      <Typography variant="body" {...styles.vendorName} data-testid={`chart-title-${vendor}`}>
        {vendor}
      </Typography>
      <x.div
        {...styles.bar}
        {...(isSelected ? styles.selectedBar : {})}
        w={`${(maxCount > 0 ? vendorExperiencesCount / maxCount : 0) * 100}%`}
        onMouseEnter={onMouseEnterChart}
        onMouseLeave={onMouseLeaveChart}
        data-testid={`chart-bar-${vendor}`}
      >
        {IN_ORDER_EXPERIENCES.map((experience) => (
          <VendorExperienceBar
            key={`${vendor}_${experience}`}
            experience={experience}
            vendorExperiencesCount={vendorExperiencesCount}
            filteredCount={filteredExperienceCounts[experience]}
            vendorName={vendor}
          />
        ))}
        <VendorExperienceBar
          key={`${vendor}_empty`}
          vendorExperiencesCount={vendorExperiencesCount}
          filteredCount={vendorExperiencesCount - filteredExperiencesCount}
          vendorName={vendor}
        />
      </x.div>
      <ChartDetailsPopover
        vendorName={vendor}
        filteredExperienceCounts={filteredExperienceCounts}
        open={detailsPopoverOpen}
        anchorEl={anchorEl}
      />
    </x.div>
  );
};

const VendorExperienceBar = ({
  vendorName,
  experience,
  vendorExperiencesCount = 0,
  filteredCount = 0,
}: {
  vendorName: string;
  experience?: Experience;
  vendorExperiencesCount?: number;
  filteredCount?: number;
}) => {
  const styles = useBarGraphStyles();
  const { color } = useThemeTokens();

  const filledbarDefaultColor = {
    [Experience.Uses]: color.chart.categorical.base03,
    [Experience.Churned]: color.chart.categorical.base04,
    [Experience.Evaluated]: color.chart.categorical.base07,
    [Experience.Aware]: color.chart.categorical.base,
    [Experience.Unaware]: color.chart.categorical.base09,
  };

  const width = (vendorExperiencesCount > 0 ? filteredCount / vendorExperiencesCount : 0) * 100;

  return (
    <x.div
      {...styles.filledBar}
      flexGrow={width}
      mx={width ? "1px" : undefined}
      backgroundColor={experience ? filledbarDefaultColor[experience] : color.background.neutral.subtle}
      data-testid={`chart-filtered-bar-${vendorName}-${experience}`}
    />
  );
};

const VendorBarChartSkeleton = () => {
  return (
    <x.div mb="24px">
      <x.div mb="12px">
        <Skeleton variant="noMargin" width="30%" />
      </x.div>
      <x.div display="flex" gap="8px" flexDirection="column">
        <SelectionStatetRowSkeleton barWidth="100%" />
        <SelectionStatetRowSkeleton barWidth="40%" />
        <SelectionStatetRowSkeleton barWidth="25%" />
        <SelectionStatetRowSkeleton barWidth="5%" />
        <SelectionStatetRowSkeleton barWidth="35%" />
      </x.div>
    </x.div>
  );
};

const SelectionStatetRowSkeleton = ({ barWidth }: { barWidth: string }) => {
  return (
    <x.div display="flex" gap="8px">
      <x.div w="24px">
        <Skeleton variant="noMargin" width="24px" />
      </x.div>
      <Skeleton variant="noMargin" width={barWidth} />
    </x.div>
  );
};

const CustomerKnowledgeInfo = () => {
  const styles = useBarGraphStyles();
  const [anchorEl, setAnchorEl] = React.useState(undefined);
  const open = Boolean(anchorEl);

  return (
    <x.div {...styles.infoHeader}>
      <Typography variant="body-large-em">Customer Knowledge</Typography>
      <IconButton variant="ghost" size="small" onMouseEnter={(event: any) => setAnchorEl(event.currentTarget)}>
        <Info />
      </IconButton>

      <Popover
        size="small"
        closeOnMouseLeave={true}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        leaveDelay={200}
        zIndex={2}
      >
        <x.div {...styles.infoPadding}>
          <Typography variant="body-small-em" {...styles.infoPadding} color="#9CA1B0">
            Key
          </Typography>
          <x.div {...styles.infoLinesWrapper}>
            <CustomerKnowledgeInfoLine experience={Experience.Uses} />
            <CustomerKnowledgeInfoLine experience={Experience.Churned} />
            <CustomerKnowledgeInfoLine experience={Experience.Evaluated} />
            <CustomerKnowledgeInfoLine experience={Experience.Aware} />
            <CustomerKnowledgeInfoLine experience={Experience.Unaware} />
          </x.div>
        </x.div>
      </Popover>
    </x.div>
  );
};

const CustomerKnowledgeInfoLine = ({ experience }: { experience: Experience }) => {
  const styles = useBarGraphStyles();
  const infoCopy = {
    [Experience.Uses]: "Uses: Experts are either a current or recent customer.",
    [Experience.Churned]: "Churned: Experts used vendor and then ended their customer relationship.",
    [Experience.Evaluated]:
      "Evaluated, did not select: Experts evaluated the vendor but did not begin a customer relationship.",
    [Experience.Aware]: "Aware: Experts are familiar with the vendor but did not formally evaluate them.",
    [Experience.Unaware]: "Unaware: Experts are unfamiliar with the vendor.",
  };
  return (
    <x.div {...styles.infoLine}>
      <x.div {...styles.infoLineIcon}>
        <ExperienceIcon experience={experience} />
      </x.div>
      <Typography>{infoCopy[experience]}</Typography>
    </x.div>
  );
};

const ChartDetailsPopover = ({
  vendorName,
  filteredExperienceCounts,
  open,
  anchorEl,
}: {
  vendorName: string;
  filteredExperienceCounts: CustomerKnowledgeCount;
  open: boolean;
  anchorEl: any;
}) => {
  const styles = useBarGraphStyles();
  const {
    color: { text },
  } = useThemeTokens();
  const tooltipCopy = {
    [Experience.Uses]: "Uses",
    [Experience.Churned]: "Churned",
    [Experience.Evaluated]: "Evaluated, did not select",
    [Experience.Aware]: "Aware",
    [Experience.Unaware]: "Unaware",
  };

  return (
    <Popover size="small" open={open} anchorEl={anchorEl} leaveDelay={200} zIndex={2}>
      <x.div {...styles.chartDetailsPopover}>
        <Typography {...styles.chartDetailsPopoverTitle} variant="body-large-em">
          {vendorName}
        </Typography>
        <x.div {...styles.chartDetailsPopoverContent}>
          {IN_ORDER_EXPERIENCES.map((experience) => (
            <x.div key={experience} {...styles.chartDetailsPopoverItem}>
              <Typography variant="body" display="flex" alignItems="center" component="span">
                <ExperienceIcon experience={experience} />
                {tooltipCopy[experience]}
              </Typography>
              <Typography variant="body-small" color={text.secondary} component="span">
                {filteredExperienceCounts[experience] ?? 0}
              </Typography>
            </x.div>
          ))}
        </x.div>
      </x.div>
    </Popover>
  );
};
