export const POLICIES_PURPOSES_TYPES = {
  STRICTLY_NECESSARY: "necessary",
  FUNCTIONAL: "functional",
  ANALYTICS: "analytics",
  ADVERTISEMENT: "advertisement",
};

export const LOG_ROCKET = POLICIES_PURPOSES_TYPES.ANALYTICS;
export const USER_FLOW = POLICIES_PURPOSES_TYPES.ANALYTICS;
export const PENDO = POLICIES_PURPOSES_TYPES.ANALYTICS;
export const HEAP = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
export const GOOGLE_ANALYTICS = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
export const DATA_DOG = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
export const WISTIA = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
export const HONEY_BADGER = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
export const MANUALLY_TRACKED_ACTIONS = POLICIES_PURPOSES_TYPES.STRICTLY_NECESSARY;
