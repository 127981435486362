import React, { useMemo } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Avatar, Logo, LogoVariation, useThemeTokens } from "@alphasights/alphadesign-components";
import { Summary } from "../transcripts/Summary";
import { HighlightAiSummaryPart } from "./AiSummaries.types";

interface DocumentSummaryProps {
  transcriptContent: HighlightAiSummaryPart[];
  summaryRef: React.MutableRefObject<any>;
}

export const DocumentSummary = ({ transcriptContent, summaryRef }: DocumentSummaryProps) => {
  const styles = useStyles();

  const sentences = useMemo(() => {
    return transcriptContent.map((part: HighlightAiSummaryPart, ix: Number) => {
      return {
        speaker: part.type === "point" ? "copyablePoint" : part.type,
        position: ix,
        value: part.text,
        hasHighlight: part.hasHighlight,
        keyword: part.keyword,
      };
    });
  }, [transcriptContent]);

  return (
    <x.div {...styles.wrapper} data-testid="ai-summary-document">
      <x.div {...styles.avatarColumn}>
        <Avatar size="small">
          <x.div {...styles.logoWrapper}>
            <Logo variation={LogoVariation.Light} {...styles.logo} />
          </x.div>
        </Avatar>
      </x.div>
      <BorderlessContainer>
        <Summary sentences={sentences} summaryRef={summaryRef} />
      </BorderlessContainer>
    </x.div>
  );
};

export const BorderlessContainer = styled.div`
  * {
    border-style: none;
  }
`;

const useStyles = () => {
  const { spacing } = useThemeTokens();

  return {
    loading: {
      m: "auto",
    },
    wrapper: {
      display: "flex",
      gap: spacing.inner.base06,
      maxWidth: "600px",
    },
    avatarColumn: {
      display: {
        md: "block",
        xs: "none",
      },
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      h: "12px",
      w: "12px",
    },
  };
};
