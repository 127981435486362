import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMobileExpertsFlyoutStyles = ({
  withDivider = false,
}: {
  withDivider?: boolean;
  callAboutToBegin?: boolean;
}) => {
  const { spacing, color } = useThemeTokens();

  return {
    topBar: {
      w: "100%",
      borderBottom: withDivider ? `1px solid ${color.border.divider}` : undefined,
    },
    ctasBar: {
      borderTop: `1px solid ${color.border.divider}`,
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base04,
      padding: spacing.inner.base04,
      backgroundColor: "white",
    },
    ctasBarButton: {
      px: spacing.inner.base04,
      w: "100%",
      flexBasis: 0,
      flexGrow: 1,
    },
  };
};
