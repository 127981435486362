export const SURVEY_MP_INFO = Object.freeze({
  title: "Next steps",
  listItems: [
    {
      value: "Our in-house survey team will review the request and recommend the number of respondents to target",
      property: "root",
    },
    {
      value: "They will draft a suggested survey for comment and confirmation",
      property: "root",
    },

    {
      value: "After confirmation, you can expect to receive initial results within 48 hours",
      property: "root",
    },
  ],
});

export const SURVEY_BUTTON_CANCEL = "Cancel";
export const SURVEY_BUTTON_SUBMIT = "Launch Customer Survey";

export const COMMISSION_SURVEY_HEIGHT = "620px";
export const COMMISSION_SURVEY_WIDTH = "736px";
export const SURVEY_MODAL_INPUT_MIN = 13;

export const SURVEY_URL_PARAM = "commissionSurvey";

export const SUCCESS_COMMISSION = "Your request for a Customer Survey has been taken";
export const SURVEY_NOTES = "(Optional) Add any additional information here, e.g. screening requirements";
