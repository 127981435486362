import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const {
    color: { border },
    spacing: { inner, layout },
  } = useThemeTokens();

  const styles = {
    mentionsWrapper: {
      gap: inner.base06,
      w: "100%",
    },
    mentionsRender: {
      gap: inner.base06,
      padding: `${inner.base05} ${layout.base10} ${inner.base05} ${layout.base10}`,
      borderBottom: `1px solid ${border.divider}`,
      display: "flex",
      justifyContent: "center",
    },
    mentionsObject: {
      display: "flex",
      gap: inner.base03,
      padding: inner.base03,
      w: "668px",
    },
    mentionsText: {
      display: "block",
      w: "600px",
    },
  };

  return styles;
};
