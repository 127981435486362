import { mean } from "lodash";

export const mapUsageAndAwareness = (res: UsageAndAwarenessAPI): UsageAndAwareness => {
  const vendors = [...res];

  const customers: UsageAndAwarenessCustomerEntry[] = [];

  const speakers = [
    ...new Set(
      res.map((entry) => [...entry.uses, ...entry.churned, ...entry.evaluated, ...entry.aware, ...entry.unaware]).flat()
    ),
  ];

  speakers.forEach((speaker) => {
    customers.push({
      speaker,
      uses: res.filter((entry) => entry.uses.includes(speaker)).map((entry) => entry.companyName),
      churned: res.filter((entry) => entry.churned.includes(speaker)).map((entry) => entry.companyName),
      evaluated: res.filter((entry) => entry.evaluated.includes(speaker)).map((entry) => entry.companyName),
      aware: res.filter((entry) => entry.aware.includes(speaker)).map((entry) => entry.companyName),
      unaware: res.filter((entry) => entry.unaware.includes(speaker)).map((entry) => entry.companyName),
    });
  });

  return {
    vendors,
    customers,
  };
};

export const mapKeyPurchasingCriteria = (res: KeyPurchasingCriteriaAPI): KeyPurchasingCriteria => {
  let kpcs: KeyPurchasingCriteria = {
    ranked: [],
    vendors: [],
  };

  res.rankedKpcs.forEach((metric) => {
    kpcs.ranked.push({
      kpc: metric.name,
      avgRank: mean(metric.ranked.map((value) => value.value!)),
      ranks: metric.ranked,
      summary: metric.summary,
      expertsResponses: metric.expertsResponses,
    });
  });

  kpcs.ranked.sort((a, b) => a.avgRank - b.avgRank);

  kpcs.vendors = res.companyMetrics.map((companyData) => ({
    companyName: companyData.companyName,
    summary: companyData.summary,
    kpcs: kpcs.ranked
      .map(({ kpc }) => {
        const companyDataEntry = companyData.kpcs.find((entry) => entry.name === kpc);

        return {
          title: companyDataEntry ? companyDataEntry.name : "",
          expertsResponses: companyDataEntry ? companyDataEntry.expertsResponses : [],
        };
      })
      .filter((entry) => entry.title.length > 0),
    importances: kpcs.ranked
      .map(({ kpc }) => {
        const companyDataEntry = companyData.kpcs.find((entry) => entry.name === kpc);

        return {
          kpcName: companyDataEntry ? companyDataEntry.name ?? "" : "",
          values: companyDataEntry ? companyDataEntry.importance.filter((val) => (val.value ?? 0) > 0) : [],
        };
      })
      .filter((entry) => entry.kpcName.length > 0),
  }));

  return kpcs;
};

export const mapNetPromoterScore = (res: NetPromoterScoreAPI[]): NetPromoterScore[] => {
  return res.map(({ companyName, expertsResponses, expertVotes, score, summary }) => {
    const newObject = {
      companyName,
      expertVotes,
      score,
      summary: {
        id: { value: "", citedBy: [], isSensitive: false },
        value: { value: "", citedBy: [], isSensitive: false },
      },
      npsExpertsResponses: [],
    } as NetPromoterScore;

    const promoterExperts: number[] = expertVotes
      .filter(({ value }) => [9, 10].includes(value!))
      .map(({ citedBy }) => citedBy)
      .flat();
    const passiveExperts: number[] = expertVotes
      .filter(({ value }) => [7, 8].includes(value!))
      .map(({ citedBy }) => citedBy)
      .flat();
    const detractorExperts: number[] = expertVotes
      .filter(({ value }) => value! >= 0 && value! <= 6)
      .map(({ citedBy }) => citedBy)
      .flat();

    newObject.npsExpertsResponses.push({
      npsSentiment: "Promoter",
      expertsResponses: expertsResponses.filter((entry) =>
        entry.response.citedBy.some((speakerId) => promoterExperts.includes(speakerId))
      ),
    });
    newObject.npsExpertsResponses.push({
      npsSentiment: "Passive",
      expertsResponses: expertsResponses.filter((entry) =>
        entry.response.citedBy.some((speakerId) => passiveExperts.includes(speakerId))
      ),
    });
    newObject.npsExpertsResponses.push({
      npsSentiment: "Detractor",
      expertsResponses: expertsResponses.filter((entry) =>
        entry.response.citedBy.some((speakerId) => detractorExperts.includes(speakerId))
      ),
    });

    newObject.summary = summary;

    return newObject;
  });
};

const allocationMatchingPercentages = [undefined, -100, -50, 0, 50, 100];

export const mapOutlook = (res: CustomerPrimerOutlookAPI): CustomerPrimerOutlook => {
  // on allocation we ignore zeros as the invalid values are mapped to zero in the BE
  return {
    ...res,
    allocation: res.allocation.map((entry) => ({
      ...entry,
      values: entry.values
        .filter((value) => (value.value ?? 0) > 0)
        .map((value) => ({
          ...value,
          value: allocationMatchingPercentages[value.value ?? 0],
        })),
    })),
    change: { ...res.change, summary: res.change.summary },
    concerned: { ...res.concerned, summary: res.concerned.summary },
    excited: { ...res.excited, summary: res.excited.summary },
  };
};
