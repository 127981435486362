const TITLE = "HQ Location";

type Geography = { region: string; countries: string[] };

const GEOGRAPHIES: Geography[] = [
  {
    region: "North America",
    countries: ["Canada", "United States"],
  },
  {
    region: "Western Europe",
    countries: [
      "Andorra",
      "Belgium",
      "France",
      "Ireland",
      "Liechtenstein",
      "Luxembourg",
      "Malta",
      "Monaco",
      "Netherlands",
      "San Marino",
      "United Kingdom",
    ],
  },
  {
    region: "Nordics",
    countries: ["Denmark", "Finland", "Iceland", "Norway", "Sweden"],
  },
  { region: "DACH", countries: ["Austria", "Germany", "Switzerland"] },
  { region: "Southern Europe", countries: ["Greece", "Italy", "Portugal", "Spain"] },
  {
    region: "Eastern Europe",
    countries: [
      "Albania",
      "Belarus",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Czech Republic",
      "Estonia",
      "Georgia",
      "Hungary",
      "Kosovo",
      "Latvia",
      "Lithuania",
      "Macedonia",
      "Moldova",
      "Montenegro",
      "Poland",
      "Romania",
      "Russia",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Ukraine",
    ],
  },
  { region: "East Asia", countries: ["China", "Hong Kong", "Japan", "Macau", "North Korea", "South Korea", "Taiwan"] },
  {
    region: "The Caribbean",
    countries: [
      "Anguilla",
      "Aruba",
      "Bahamas",
      "Barbados",
      "Bermuda",
      "Bonaire, Sint Eustatius and Saba",
      "Cayman Islands",
      "Cuba",
      "Curacao",
      "Dominica",
      "Dominican Republic",
      "Grenada",
      "Guadeloupe",
      "Haiti",
      "Jamaica",
      "Martinique",
      "Puerto Rico",
      "Saba",
      "Saint Pierre and Miquelon",
      "Sint Maarten, Dutch",
      "St. Barthelemy",
      "St. Kitts & Nevis",
      "St. Lucia",
      "St. Martin",
      "St. Vincent & the Grenadines",
      "Trinidad & Tobago",
      "Turks and Caicos Islands",
      "Virgin Islands, British",
      "Virgin Islands, U.S.",
    ],
  },
  {
    region: "Latin America",
    countries: [
      "Argentina",
      "Belize",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Costa Rica",
      "Ecuador",
      "El Salvador",
      "French Guiana",
      "Guatemala",
      "Guyana",
      "Honduras",
      "Mexico",
      "Nicaragua",
      "Panama",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela",
    ],
  },
  {
    region: "Western Africa",
    countries: [
      "Benin",
      "Burkina Faso",
      "Cape Verde",
      "Cote d'Ivoire",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Liberia",
      "Mali",
      "Mauritania",
      "Niger",
      "Nigeria",
      "Senegal",
      "Sierra Leone",
      "Togo",
    ],
  },
  { region: "North Africa", countries: ["Algeria", "Libya", "Morocco", "Tunisia", "Western Sahara"] },
  {
    region: "Sub-Saharan Africa",
    countries: [
      "Angola",
      "Botswana",
      "Burundi",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo",
      "Congo, the Democratic Republic of the",
      "Djibouti",
      "Equatorial Guinea",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Kenya",
      "Lesotho",
      "Madagascar",
      "Malawi",
      "Mauritius",
      "Mayotte",
      "Mozambique",
      "Namibia",
      "Reunion",
      "Rwanda",
      "Sao Tome and Principe",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
  },
  {
    region: "Middle East",
    countries: [
      "Cyprus",
      "Egypt",
      "Iran",
      "Iraq",
      "Israel",
      "Jordan",
      "Lebanon",
      "Palestine",
      "Syria",
      "Turkey",
      "Yemen",
    ],
  },
  {
    region: "Gulf Cooperation Council (GCC)",
    countries: ["Bahrain", "Kuwait", "Oman", "Qatar", "Saudi Arabia", "United Arab Emirates"],
  },
  {
    region: "South Asia",
    countries: ["Afghanistan", "Bangladesh", "Bhutan", "India", "Maldives", "Nepal", "Pakistan", "Sri Lanka"],
  },
  {
    region: "Southeast Asia",
    countries: [
      "Brunei",
      "Cambodia",
      "East Timor",
      "Indonesia",
      "Laos",
      "Malaysia",
      "Myanmar",
      "Philippines",
      "Singapore",
      "Thailand",
      "Vietnam",
    ],
  },
  {
    region: "Oceania",
    countries: [
      "Australia",
      "Fiji",
      "Kiribati",
      "Marshall Islands",
      "Micronesia",
      "Nauru",
      "New Zealand",
      "Palau",
      "Papua New Guinea",
      "Samoa",
      "Solomon Islands",
      "Tonga",
      "Tuvalu",
      "Vanuatu",
    ],
  },
];

export { TITLE, GEOGRAPHIES };
export type { Geography };
