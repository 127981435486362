import { parseISO, isWithinInterval } from "date-fns";
import pluralize from "pluralize";

/**
 * Searches all interactions linked (by followUpId and previousAdvisorshipId)
 * to a provided interaction.
 *
 * @param {interaction} interaction - interaction to start search.
 * @param {Array<interaction>} allInteractions - list of all interactions.
 * @returns {Array<interaction>} list of interactions linked to interaction.
 */
export const interactionsChain = (interaction, allInteractions) => {
  const idInteractions = allInteractions.reduce((json, int) => {
    return {
      ...json,
      [int.id]: int,
    };
  }, {});

  const nextInChain = (int, next) => {
    const key = next ? "followUpId" : "previousAdvisorshipId";
    const linked = idInteractions[int[key]];
    return linked ? [linked, ...nextInChain(linked, next)] : [];
  };

  return [...nextInChain(interaction, false), interaction, ...nextInChain(interaction, true)];
};

/**
 * Verify if a given interaction has one of the given stats and
 * has a scheduledCallTime if outside (not in) a given time range.
 *
 * @param {interaction} interaction - interaction to start search.
 * @param {Array<string>} states - list of possible interaction states.
 * @param {Date} start - beginning of a time range.
 * @param {Date} end - end of a time range.
 * @returns {boolean} if interaction satisfy the given condition.
 */
export const isScheduledCallTimeOut = (interaction, states, start, end) => {
  if (states.includes(interaction.state)) {
    const date = parseISO(interaction.scheduledCallTime);
    return start && end && !isWithinInterval(date, { start, end });
  }
  return false;
};

export const DAY_FORMAT = "E d MMM";
export const TIME_FORMAT = "h:mmaaa";
export const DATE_FORMAT = `${DAY_FORMAT} yyyy, ${TIME_FORMAT} (O)`;
export const DATE_FORMAT_WITH_ABBREVIATION = `${DAY_FORMAT} yyyy, ${TIME_FORMAT} (zzz, O)`;

export const formatTimeInHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let label = hours ? pluralize("hour", hours, true) : "";

  if (minutes > 0) {
    label += ` ${minutes} minutes`;
  }

  return label;
};
