import {
  Button,
  Divider,
  IconButton,
  RadioButton,
  SelectMenu,
  SelectOption,
  TextField,
  Typography,
} from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { COUNTRIES } from "components/InputPhoneNumber";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { DialInNumber, HitAction } from "@alphasights/portal-api-client";
import { useTwilioContext, TWILIO_CALL_METHODS, useDialIn, getDefaultJoinMethod } from "providers/TwilioProvider";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useStyles } from "./JoinMethodPage.styles";
import { CallAvailability } from "providers/types";
import { JoinCallInteraction } from "./types";

const MethodOptionWrapper = styled(x.div)`
  display: flex;
  label {
    flex: 1;
  }
`;

export type JoinMethodPageProps = {
  interaction: JoinCallInteraction;
  origin: string;
  dialInNumbers: DialInNumber[];
  callAvailability?: CallAvailability;
  onClose: () => void;
};

export const JoinMethodPage = ({
  dialInNumbers,
  interaction,
  onClose,
  callAvailability,
  origin,
}: JoinMethodPageProps) => {
  const styles = useStyles();

  const { logHit } = useTrackUserAction();

  const { preference, setPreference } = useTwilioContext();

  const [method, setMethod] = useState(getDefaultJoinMethod({ interaction, joinMethod: preference.method }));
  const [countryIsoCode, setCountryIsoCode] = useState(preference.countryIsoCode);
  const [phoneNumber, setPhoneNumber] = useState(preference.phoneNumber);
  const [ext, setExt] = useState(preference.ext);

  const onSaveChanges = useCallback(() => {
    logHit({
      origin,
      action: HitAction.callChangeJoinMethod,
      projectToken: interaction.projectToken,
      references: { interactionId: interaction.id },
      details: { method },
    });

    setPreference({
      method,
      countryIsoCode,
      phoneNumber,
      ext,
    });
    onClose();
  }, [method, countryIsoCode, phoneNumber, ext, onClose, setPreference, interaction, logHit, origin]);

  return (
    <x.div data-testid="join-method-page" {...styles.wrapper}>
      <PortalMobileTopBar
        title="Call Method"
        mainButton={
          <IconButton size="large" variant="ghost" onClick={onClose} testId="back-button">
            <ArrowLeft />
          </IconButton>
        }
      />
      <x.div {...styles.contentWrapper}>
        <Typography variant="body-small">
          Update your call settings below to support your preferred call method.
        </Typography>
        <x.div {...styles.optionsSection}>
          <Typography variant="body-small" color="secondary">
            Preferred call joining method
          </Typography>
          <MethodOptionWrapper {...styles.methodOptionWrapper}>
            {Object.entries(TWILIO_CALL_METHODS)
              .sort(([, a], [, b]) => a.order - b.order)
              .filter(([, x]) => x.enabled({ interaction }))
              .map(([name, { label }]) => {
                return (
                  <RadioButton
                    dataAttributes={{ "data-testid": `call-option-${name.replaceAll("_", "-").toLowerCase()}` }}
                    key={`${name}${method}`}
                    variant="boxed"
                    checked={name === method}
                    onChange={() => setMethod(name)}
                    size="small"
                    flex="1"
                    value={`${name}${method}`}
                  >
                    {label}
                  </RadioButton>
                );
              })}
          </MethodOptionWrapper>
        </x.div>

        {method === "CALL_ME" && (
          <x.div {...styles.inputsWrapper}>
            <Typography variant="body-small" color="secondary">
              Please enter your phone number to have our conferencing system initiate a call to you:
            </Typography>
            <x.div display="flex" gap="12px">
              <CountrySelect onChange={setCountryIsoCode} value={countryIsoCode} maxWidth="30%" />
              <TextField
                onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                value={phoneNumber}
                size="small"
                flex="1"
                width="60%"
              />
              <TextField
                placeholder="Ext."
                onChange={(e) => setExt(e.currentTarget.value)}
                value={ext}
                size="small"
                width="20%"
              />
            </x.div>
          </x.div>
        )}

        {method === "DIAL_IN" && (
          <x.div {...styles.inputsWrapper}>
            <Typography variant="body-small" color="secondary">
              Please dial this phone number followed by the code to join the call. Ensure the country matches your
              current location.
            </Typography>
            <x.div>
              <CountrySelect onChange={setCountryIsoCode} value={countryIsoCode} maxWidth="100%" fullNames />
            </x.div>
            <DialInSection
              dialInNumbers={dialInNumbers}
              interaction={interaction}
              countryIsoCode={countryIsoCode}
              callAvailability={callAvailability}
            />
          </x.div>
        )}
      </x.div>

      <Divider />

      <x.div marginTop="auto" display="flex" p="16px" gap="16px">
        <Button variant="outline" onClick={onClose} flex="1" size="small">
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={onSaveChanges}
          flex="1"
          size="small"
          dataAttributes={{ "data-testid": "save-changes-call-opts" }}
        >
          Save Changes
        </Button>
      </x.div>
    </x.div>
  );
};

const MOBILE_ORDER = ["1", "44", "86"];

const MOBILE_COUNTRIES = [
  ...MOBILE_ORDER.map((code) => COUNTRIES.find((c) => c.countryCode === code)!),
  ...COUNTRIES.filter((c) => MOBILE_ORDER.indexOf(c.countryCode) === -1),
];

type DialInSectionProps = {
  interaction: JoinCallInteraction;
  dialInNumbers: DialInNumber[];
  countryIsoCode: string;
  callAvailability?: CallAvailability;
};

const DialInSection = ({ interaction, dialInNumbers, countryIsoCode, callAvailability }: DialInSectionProps) => {
  const { number, accessCode } = useDialIn({ interaction, dialInNumbers, countryIsoCode, callAvailability });

  return (
    <x.div display="flex" gap="12px" flexDirection="column">
      <Typography variant="body-small">Number: {number}</Typography>
      <Typography variant="body-small">Code: {accessCode}</Typography>
    </x.div>
  );
};

type CountrySelectProps = {
  onChange: (isoCode: string) => void;
  value: string;
  maxWidth: string;
  fullNames?: boolean;
};

const CountrySelect = ({ onChange, value, maxWidth, fullNames }: CountrySelectProps) => {
  return (
    <SelectMenu
      size="small"
      maxWidth={maxWidth}
      isClearButtonEnabled={false}
      value={value}
      onChange={(value) => onChange(value as string)}
      label={fullNames && "Current location"}
    >
      {MOBILE_COUNTRIES.map((option) => {
        return (
          // @ts-ignore
          <SelectOption
            key={option.value}
            value={option.isoCode}
            listOptionAccessories={{
              type: "text",
              avatar: {
                src: option.flag,
                text: option.value,
              },
            }}
            label={fullNames ? option.children : "+" + option.countryCode}
          ></SelectOption>
        );
      })}
    </SelectMenu>
  );
};
