import { useEffect } from "react";
import { useCookiePolicy, useEnv } from "@alphasights/client-portal-shared";
import { PENDO } from "constants/CookiePolicy";
import { useCurrentUser, useUserAuthIsReady } from "@alphasights/portal-auth-react";

const buildMetadata = (data) => ({
  clientContact: data?.name,
  clientAccount: data?.account?.name,
  clientEntity: data?.entity?.name,
  clientSegment: data?.entity?.segment,
  clientTarget: data?.target?.name,
  clientSBU: data?.account?.subBusinessUnit,
  clientBU: data?.account?.businessUnit,
  clientOffice: data?.account?.office?.name,
  clientRegion: data?.account?.office?.firmRegion?.name,
});

export const usePendo = ({ authUserDetails }) => {
  const currentUser = useCurrentUser();
  const isReady = useUserAuthIsReady();
  const env = useEnv();
  const { isPolicyAccepted } = useCookiePolicy();

  useEffect(() => {
    if (env?.enablePendo && isReady && currentUser?.id && isPolicyAccepted(PENDO) && authUserDetails) {
      window.pendo.initialize({
        visitor: {
          id: currentUser?.id,
          full_name: currentUser?.name,
          email: currentUser?.email,
          internal: !!env?.internalUser,
          alphaNowEnabled: currentUser?.alphaNowEnabled,
          ...buildMetadata(authUserDetails),
        },

        account: {
          id: authUserDetails?.target?.name,
        },
      });
    }
  }, [currentUser, isReady, env, isPolicyAccepted, authUserDetails]);
};
