import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useTranscriptActionBarStyles = ({ isMobile }) => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  const transcriptActionBarContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    flexGrow: "0",
    mx: { xs: inner.base02, sm: inner.base08 },
  };

  if (isMobile) {
    transcriptActionBarContainer.h = layout.base08;
  }

  const chevronContainer = isMobile
    ? {
        display: "flex",
        justifyContent: "flex-end",
      }
    : { display: "flex", pl: inner.base04 };

  return { transcriptActionBarContainer, chevronContainer };
};
