import { useCurrentUser } from "@alphasights/portal-auth-react";

const HIDE = {
  showContent: false,
  contentStyle: {
    style: {
      filter: "blur(2.5px) opacity(0.5)",
      userSelect: "none",
      pointerEvents: "none",
    },
  },
};

const SHOW = {
  showContent: true,
  contentStyle: {},
};

export const useHideDeliverablesContent = () => {
  const currentUser = useCurrentUser();

  if (!currentUser?.internalUser) return SHOW;

  if ((currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) return SHOW;

  return HIDE;
};
