import { ArrowLeft } from "@alphasights/alphadesign-icons";
import React, { FC } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";

interface DirectionalArrowProps {
  isUp: boolean;
  [key: string]: any;
  size?: "normal" | "small";
}

const DirectionalArrow: FC<DirectionalArrowProps> = ({ isUp, display, size = "normal", ...unconsumedProps }) => {
  const { spacing } = useThemeTokens();

  return (
    <ArrowLeft
      {...unconsumedProps}
      width={size === "small" ? spacing.inner.base04 : spacing.inner.base05}
      height={"small" ? spacing.inner.base04 : spacing.inner.base05}
      style={{
        transform: `rotate(${isUp ? "90" : "270"}deg)`,
        transition: "transform .5s",
        display: display ?? "block",
      }}
    />
  );
};

export default DirectionalArrow;
