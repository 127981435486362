import React, { useMemo } from "react";
import styled from "@xstyled/styled-components";
import { Link, Typography } from "@alphasights/alphadesign-components";
import reactStringReplace from "react-string-replace";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { noop } from "lodash";
import { AlphaGPTCitations } from "../AlphaGPTCitation/AlphaGPTCitation";
import { useAlphaGPTMessageContentStyles } from "./AlphaGPTMessageContent.styles";
import { useAlphaGPTMessageContext } from "providers/AlphaGPTMessageProvider";
import { Citation, MessageType } from "models/AlphaGpt";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { ContentPurchaseStatus } from "@alphasights/client-portal-shared";

interface AlphaGPTMessageContentProps {
  collapseCitations?: boolean;
}

export const AlphaGPTMessageContent = React.forwardRef<HTMLDivElement, AlphaGPTMessageContentProps>(
  ({ collapseCitations = false }, ref) => {
    const { text, citations: allCitations, type } = useAlphaGPTMessageContext();
    const {
      onCitationClick,
      isAlphaGPTPurchaseExpired,
      hasAlphaGPTPurchase,
      hasValidAlphaGPTPurchase,
    } = useAlphaGPTContext();
    const { messageBodyText, textCitationLink, activeTextCitationLink } = useAlphaGPTMessageContentStyles(
      !hasValidAlphaGPTPurchase
    );
    const messageWithCitations = reactStringReplace(text, /\[(\d+)\]/g, (citationId) => {
      const citation = allCitations?.find((c) => c.position === +citationId);
      const link = citation?.link;
      const id = citation?.id;
      const contentType = citation?.contentType;
      const linkText = (
        <Typography
          variant="body-em"
          component="span"
          {...(citation?.showLink ? activeTextCitationLink : textCitationLink)}
        >
          [{citationId}]
        </Typography>
      );
      const onClick = onCitationClick ?? noop;
      return (link || id) && !isAlphaGPTPurchaseExpired && (hasAlphaGPTPurchase || citation?.showLink) ? (
        <Link
          onClick={() => onClick(citation, contentType)}
          target="_blank"
          href={onCitationClick ? undefined : link}
          data-testid={`citation-link-message-${citation?.position}`}
        >
          {linkText}
        </Link>
      ) : (
        linkText
      );
    });

    const accessibleCitations = useMemo(() => {
      return allCitations
        ?.filter((citation) => isCitationAccessible(citation))
        .sort(sortByPurchasedFirst)
        .slice(0, 10);
    }, [allCitations]);

    const message = useMemo(() => {
      if (type === MessageType.GetQuotes) {
        return accessibleCitations?.length
          ? "Here are some quotes relating to your search from your accessible content:"
          : "Apologies, you don’t own or have access to any relevant content. Please find relevant content below.";
      }
      return messageWithCitations;
    }, [accessibleCitations, messageWithCitations, type]);

    const citations =
      type === MessageType.GetQuotes && accessibleCitations?.length ? accessibleCitations : allCitations;

    return (
      <MessageWrapper>
        <Typography ref={ref} {...messageBodyText} component="div" color="strong" variant="body-large">
          {message}
          {citations && citations.length > 0 && (
            <CitationSection collapseCitations={collapseCitations} citations={citations} />
          )}
        </Typography>
      </MessageWrapper>
    );
  }
);

const CitationSection = ({ collapseCitations, citations }: { collapseCitations: boolean; citations: Citation[] }) => {
  return (
    <div data-testid="alpha-gpt-citations">
      <AlphaGPTCitations collapseCitations={collapseCitations} citations={citations} />
    </div>
  );
};

const MessageWrapper = styled.div`
  * {
    border-style: none;
  }
`;

const isCitationAccessible = (citation: Citation) => {
  return (
    citation.contentType === "PRIVATE_TRANSCRIPT" ||
    (citation.content?.purchaseStatus &&
      citation.content?.approvalStatus &&
      isContentAccessible(citation.content.purchaseStatus, citation.content.approvalStatus))
  );
};

const sortByPurchasedFirst = (citationA: Citation, citationB: Citation) => {
  const isCitationAPurchased = citationA?.content?.purchaseStatus === ContentPurchaseStatus.Purchased;
  const isCitationBPurchased = citationB?.content?.purchaseStatus === ContentPurchaseStatus.Purchased;

  return (isCitationAPurchased ? 0 : 1) - (isCitationBPurchased ? 0 : 1);
};
