export const DIAL_IN_TAB = 0;
export const CALL_ME_TAB = 1;
export const WEB_CONNECT_TAB = 2;
export const ZOOM_APP_TAB = 3;

export enum CallAvailabilityStatus {
  OUT_OF_TIME = "OUT_OF_TIME",
  AVAILABLE = "AVAILABLE",
  HAS_ALREADY_TRANSPIRED = "HAS_ALREADY_TRANSPIRED",
  OVERDUE = "OVERDUE",
  FAILED = "FAILED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  FETCH_ERROR = "FETCH_ERROR",
  NO_ACCESS_CODE = "NO_ACCESS_CODE",
  NOT_SCHEDULED = "NOT_SCHEDULED",
}
