import React, { useCallback, useMemo } from "react";
import { Checkbox, Button, Typography } from "@alphasights/alphadesign-components";
import { Mail } from "@alphasights/alphadesign-icons";
import { enrichInteraction } from "pages/InteractionPage";
import { x } from "@xstyled/styled-components";
import { MessageButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import { MessageType } from "types";
import { useActionBarStyles } from "./ActionBar.styles";
import { Download } from "@alphasights/alphadesign-icons";
import { useLabelsContext } from "providers/LabelsProvider";
import { LabelButton } from "components/InteractionLabel/InteractionLabel";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export interface ActionBarProps {
  interactions: Interaction[];
  selectedInteractions: Interaction[];
  onDownloadCid: () => void;
  pcc?: boolean;
  isActiveProject: boolean;
  hasClientPortalMessages?: boolean;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  isProjectCidEnabled: boolean;
  showLabelButton?: boolean;
}

export const ActionBar: React.FC<ActionBarProps> = ({
  interactions,
  selectedInteractions,
  onDownloadCid,
  pcc,
  isActiveProject,
  hasClientPortalMessages,
  onSelectAll,
  onUnselectAll,
  isProjectCidEnabled,
  showLabelButton: showLabelButtonInput = true,
}) => {
  const { container, selectCheckbox, separador, downloadCidButton, mr } = useActionBarStyles();
  const { enableLabels } = useLabelsContext();
  const { onSendNewMessage: onSendNewMessageInput } = useProjectInteractionsContext();
  const isMobile = useCheckScreen();

  const filteredInteractions = useMemo(
    () =>
      selectedInteractions.map((interaction) =>
        enrichInteraction({
          interaction,
          clientPccFlag: pcc,
          isActiveProject,
          isMobile,
          hasClientPortalMessages,
        } as any)
      ),
    [selectedInteractions, pcc, isActiveProject, isMobile, hasClientPortalMessages]
  );

  const onSelectChange = useCallback(() => {
    if (interactions.length === selectedInteractions.length) {
      onUnselectAll();
    } else onSelectAll();
  }, [interactions.length, selectedInteractions.length, onUnselectAll, onSelectAll]);

  const onSendNewMessage = useCallback(
    (interactions: Interaction[], messageType: MessageType) => {
      onSendNewMessageInput(interactions, messageType, NewMessageOrigin.TableView);
    },
    [onSendNewMessageInput]
  );

  const showMessageButton = filteredInteractions.length > 0 && hasClientPortalMessages;
  const showDownloadCidButton = isProjectCidEnabled && selectedInteractions.length > 0;
  const showLabelButton = enableLabels && showLabelButtonInput && selectedInteractions.length > 0;

  return (
    <x.div {...container}>
      <x.div {...selectCheckbox}>
        <Checkbox
          size="small"
          checked={interactions.length === selectedInteractions.length}
          indeterminate={interactions.length !== selectedInteractions.length}
          onChange={onSelectChange}
          data-testid="select-interactions-checkbox"
        />
      </x.div>
      {showMessageButton && (
        <MessageButton
          variant="ghost"
          size="medium"
          interactions={filteredInteractions}
          onSendNewMessage={onSendNewMessage}
          startIcon={<Mail />}
        />
      )}
      {showDownloadCidButton && (
        <CidButton option={"download"} onClick={onDownloadCid} {...downloadCidButton} marginLeft={"24px"} />
      )}
      {showLabelButton && (
        <>
          <x.div {...separador} {...(showDownloadCidButton || showMessageButton ? mr : {})} />
          <LabelButton interactions={selectedInteractions} onClosePopover={onUnselectAll} />
        </>
      )}
    </x.div>
  );
};

const CidButton = ({
  onClick,
  marginLeft,
  option,
  ...props
}: {
  onClick: () => void;
  option: string;
  marginLeft?: string;
}) => {
  const optionData = {
    download: { label: "Download CID", icon: <Download />, testid: "download-cid" },
  }[option];
  return (
    <Button
      variant="ghost"
      size="small"
      onClick={onClick}
      startIcon={optionData?.icon}
      data-testid={optionData?.testid}
      marginLeft={marginLeft}
      {...props}
    >
      <Typography variant="body-em" className="aui-inline">
        {optionData?.label}
      </Typography>
    </Button>
  );
};
