import React from "react";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { useClientPortalActions } from "app/wrappers/ClientPortalWrapper";
import { ClientPortalReducerActions } from "app/reducers/ClientPortal.reducer";

interface LoginWallProps {
  children: React.ReactElement;
  message?: string;
}

const renderWithLoginWall = (actions: ClientPortalReducerActions, children: React.ReactElement, message: string) => {
  return React.cloneElement(children, {
    onClick: (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      actions.setModalExtraProps({
        allowAnonymousContinue: false,
        message,
      });
      actions.setIsModalOpen(true);
    },
  });
};

const LoginWall = ({ children, message = "You must sign in to view this page." }: LoginWallProps) => {
  const isLoggedIn = useIsAuthenticated();
  const actions = useClientPortalActions();
  if (isLoggedIn) {
    return children;
  }
  return renderWithLoginWall(actions, children, message);
};

export const withLoginWall = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return React.forwardRef(({ loginWall, ...props }: P & { loginWall?: Omit<LoginWallProps, "children"> }, ref) => (
    <LoginWall {...loginWall}>
      <WrappedComponent ref={ref} {...(props as P)} />
    </LoginWall>
  ));
};
