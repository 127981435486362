import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCallDurationSelectorStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
  };

  return {
    containerStyles,
  };
};
