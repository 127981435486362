import React from "react";
import { useCardsSidebarControlContext } from "../CardsSidebarControlProvider/CardsSidebarControlProvider";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { IconButton } from "@alphasights/alphadesign-components";
import { LeftArrow } from "@alphasights/alphadesign-icons";

export const ExpandSidebarButton = () => {
  const newNavigationEnabled = useNewNavigation();
  const { isCollapsed, onExpand } = useCardsSidebarControlContext();

  if (!newNavigationEnabled || !isCollapsed) return null;
  return (
    <IconButton onClick={onExpand} size="small" variant="outline" testId="expand-sidebar-button">
      <LeftArrow />
    </IconButton>
  );
};
