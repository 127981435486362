import appendCues from "./adaptedTranscript";
import ReactHtmlParser from "react-html-parser";

const tagHighlight = (text) => ReactHtmlParser(text.replace(new RegExp("<em>", "g"), "<em data-transcript-highlight>"));

const accumulatedCues = (sentences, groupConsecutiveSpeakers = true) => {
  return appendCues(sentences, groupConsecutiveSpeakers).map((part) => {
    return {
      ...part,
      body: part.cues.reduce((acc, cue) => `${acc} ${cue.text.trim()}`.trim(), ""),
    };
  });
};

export { tagHighlight, appendCues, accumulatedCues };
