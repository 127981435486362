import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useManageLabelsPopoverStyles = ({ error }: { error?: string } = {}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const editLabelField = {
    mb: inner.base02,
  };

  const labelTextField = {
    textColor: error ? text.secondary : undefined,
  };

  const radioGroup = {
    mb: inner.base,
  };

  return {
    editLabelField,
    labelTextField,
    radioGroup,
  };
};
