import { useCallback, useState, useRef } from "react";

export default () => {
  const [anchor, setAnchor] = useState();
  const ref = useRef(null);

  const handlePopoverClick = useCallback(
    (event) => {
      if (!anchor) {
        setAnchor(event.currentTarget);
      } else {
        setAnchor(null);
      }
    },
    [anchor]
  );

  return {
    anchor,
    onClose: () => setAnchor(null),
    onClick: handlePopoverClick,
    ref,
  };
};
