import React, { FC } from "react";
import { noop } from "lodash";

import { Loading } from "@alphasights/alphadesign-components";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";

import Filter from "components/Filter";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";
import { CheckboxFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { ContentTypeValues } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/ContentTypeFilter/types";
import { TYPE } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/ContentTypeFilter/constants";
import { LoadingWrapper } from "./ContentTypeFilter.styled";

interface ContentTypeFilterProps extends Omit<FilterProps, "value"> {
  value: ContentTypeValues;
}

const ContentTypeFilter: FC<ContentTypeFilterProps> = ({ value, onChange, onClear = noop }) => {
  const { contentTypeMapping } = useAlphaNowContentAccessLevelContext();

  const contentTypeOptions =
    contentTypeMapping
      ?.filter((contentTypeAccessObj) => contentTypeAccessObj.contentType !== AlphaNowContentType.alphaviewDialIn)
      .map(({ contentType, contentTypeDisplayName }) => ({ label: contentTypeDisplayName, value: contentType })) ?? [];

  const handleClear = () => onClear(SearchFilters.contentType, []);

  return (
    <Filter onClear={handleClear} title={TYPE}>
      {contentTypeOptions?.length > 0 ? (
        <CheckboxFilter
          filterKey={SearchFilters.contentType}
          value={value}
          options={contentTypeOptions}
          onChange={onChange}
        />
      ) : (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      )}
    </Filter>
  );
};

export default ContentTypeFilter;
