import { create } from "zustand";

type storeState = {
  companyId: string;
  companyName: string;
  companyLogo: string;
  currentMenuItem: number;
  moveToMenuItem: number;
  setCompanyName: (data: string) => void;
  setCompanyLogo: (data: string) => void;
  setCompanyId: (data: number) => void;
  setCurrentMenuItem: (data: number) => void;
  setMoveToMenuItem: (data: number) => void;
  setCompanyPrimerState: (data: any) => void;
};

export const useCompanyPrimersStore = create<storeState>((set) => ({
  companyId: "",
  companyName: "",
  companyLogo: "",
  currentMenuItem: 0,
  moveToMenuItem: 0,
  setCompanyName: (data: string) => set({ companyName: data }),
  setCompanyLogo: (data: string) => set({ companyLogo: data }),
  setCompanyId: (data: number) => set({ companyId: data ? data.toString() : "" }),
  setCurrentMenuItem: (data: number) => set({ currentMenuItem: data }),
  setMoveToMenuItem: (data: number) => set({ moveToMenuItem: data }),
  setCompanyPrimerState: (data: any) =>
    set({
      companyName: data.companyName,
      companyLogo: data.companyLogo,
      companyId: data.companyId,
    }),
}));
