import React from "react";
import { FormattedTimeRange } from "../../../providers/TimezoneProvider";

import { Typography } from "@alphasights/alphadesign-components";
import { Close } from "@alphasights/alphadesign-icons";
import { useRequestEventStyles, IconWrapper } from "./Request.styles";

export const RequestWrapper = ({ event: { id }, children }) => {
  return <div data-testid={`request-wrapper-${id}`}>{children}</div>;
};

export const RequestEvent = ({ event }) => {
  const styles = useRequestEventStyles();

  return (
    <div style={styles.wrapper}>
      <div style={styles.closeIcon}>
        {event.onRemoveEvent && (
          <IconWrapper onClick={event.onRemoveEvent}>
            <Close />
          </IconWrapper>
        )}
      </div>
      <Typography variant="body-em">Team Availability</Typography>
      <Typography>
        <FormattedTimeRange start={event.start} end={event.end} />
      </Typography>
    </div>
  );
};

export const requestEventProps = () => ({
  className: "event-light-blue",
});
