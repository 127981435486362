import { Location } from "history";
import { isEqual } from "lodash";

const isLocationEqual = (locationOne: Location, locationTwo: Location) => {
  return (
    locationOne.pathname === locationTwo.pathname &&
    locationOne.search === locationTwo.search &&
    isEqual(locationOne.state, locationTwo.state)
  );
};

export { isLocationEqual };
