import React from "react";
import { GenericErrorPage } from "@alphasights/client-portal-shared";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.honeybadger) {
      this.props.honeybadger.notify(error, { context: errorInfo });
    }
  }

  render() {
    if (this.state.hasError) {
      return <GenericErrorPage />;
    } else {
      return this.props.children;
    }
  }
}
