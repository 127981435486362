import { fetch } from "../hooks/useApi";
import { AttachmentResponse } from "types";

export const messageAttachmentService = {
  downloadMessageAttachment(projectToken: string, attachment: AttachmentResponse) {
    const url = `/api/projects/${projectToken}/message-attachments/${attachment.id}`;

    const type = "application/octet-stream";

    const headers = {
      "content-type": type,
      accept: type,
    };

    return fetch({
      url,
      handleForbidden: true,
      customHeaders: headers,
      skipAlert: true,
      method: "GET",
    }).then(async (response) => {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute("download", attachment.filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode?.removeChild(downloadLink);
    });
  },
};
