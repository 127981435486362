const DEFAULT_WATCHLIST_NAME = "New Watchlist";
const DEFAULT_WATCHLIST_DELIVERY_TIME = "07:00:00";
const DEFAULT_WATCHLIST_TIME_ZONE = "UTC";

enum WATCHLIST_FREQUENCY {
  immediate = "IMMEDIATE",
  daily = "DAILY",
  weekly = "WEEKLY",
  monthly = "MONTHLY",
}

enum WATCHLIST_DELIVERY_DAY {
  monday = "MONDAY",
  tuesday = "TUESDAY",
  wednesday = "WEDNESDAY",
  thursday = "THURSDAY",
  friday = "FRIDAY",
}

enum TRIGGER_TYPE {
  company = "COMPANY",
  keyword = "KEYWORD",
}

enum WATCHLIST_SORT_BY {
  dateDesc = "createdAt:desc",
  dateAsc = "createdAt:asc",
  nameAsc = "name:asc",
  nameDesc = "name:desc",
}

const INITIAL_FILTERS = {
  sort: WATCHLIST_SORT_BY.dateDesc,
  filter: [],
};

const INITIAL_WATCHLIST_PAYLOAD = {
  name: DEFAULT_WATCHLIST_NAME,
  triggers: [],
  frequency: WATCHLIST_FREQUENCY.weekly,
  deliveryDayOfWeek: WATCHLIST_DELIVERY_DAY.monday,
  deliveryTime: DEFAULT_WATCHLIST_DELIVERY_TIME,
  deliveryTimeZone: DEFAULT_WATCHLIST_TIME_ZONE,
};

const INITIAL_WATCHLIST_DATA = {
  ...INITIAL_WATCHLIST_PAYLOAD,
  id: undefined,
  createdAt: undefined,
};

export {
  WATCHLIST_FREQUENCY,
  WATCHLIST_DELIVERY_DAY,
  TRIGGER_TYPE,
  WATCHLIST_SORT_BY,
  INITIAL_WATCHLIST_PAYLOAD,
  DEFAULT_WATCHLIST_NAME,
  INITIAL_WATCHLIST_DATA,
  INITIAL_FILTERS,
  DEFAULT_WATCHLIST_DELIVERY_TIME,
};
