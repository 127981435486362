import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const useAlphaGPTMessageContentStyles = (isAlphaGPTPurchaseExpired = false) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();

  const messageBodyText = {
    paddingTop: size["03"],
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "16px",
    whiteSpace: "pre-wrap",
  };

  const citationsContainer = {
    mt: inner.base04,
    ...(!isMobile && {
      display: "flex",
      gap: inner.base02,
    }),
  };

  const citationsColumn = {
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
  };

  const citationDate = {
    color: text.secondary,
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "14px",
  };

  const textCitationLink = {
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "14px",
    color: isAlphaGPTPurchaseExpired ? text.disabled : text.link._,
    whiteSpace: "pre",
  };

  const activeTextCitationLink = {
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "14px",
    color: text.link._,
    whiteSpace: "pre",
  };

  const citationLink = {
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "14px",
  };

  const citationTitle = {
    fontFamily: "Proxima Nova,proxima-nova,Arial,sans-serif",
    fontSize: "14px",
  };

  const citationContainer = {
    display: "flex",
  };

  const citationNumbersContainer = {
    whiteSpace: "nowrap",
  };

  return {
    messageBodyText,
    citationsContainer,
    citationsColumn,
    citationDate,
    textCitationLink,
    citationLink,
    citationTitle,
    citationContainer,
    citationNumbersContainer,
    activeTextCitationLink,
  };
};
