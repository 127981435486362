import { IconButton } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

export const PoweredByAiLabel = styled.span`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base02};
`;

export const ComparisonViewOption = styled.span`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base08};
`;

export const MoreOptionsButton = styled(IconButton)`
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &[aria-selected="true"] {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
