import styled from "styled-components";
import { Accordion, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const StyledAccordion = styled(Accordion)(() => {
  const { spacing, color } = useThemeTokens();
  return {
    paddingInline: spacing.inner.base06,
    backgroundColor: color.background.surface.page.default,
    li: {
      borderWidth: `0 !important`,
    },
  };
});

const StyledTypography = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.secondary,
    width: "85%",
  };
});

const NoWatchlistContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    padding: spacing.inner.base06,
    backgroundColor: color.background.surface.page.default,
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    height: "100%",
    gap: spacing.inner.base06,
    color: color.text.disabled,
  };
});

const NoWatchlistDescriptionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    gap: spacing.inner.base02,
  };
});

export { StyledAccordion, StyledTypography, NoWatchlistContainer, NoWatchlistDescriptionContainer };
