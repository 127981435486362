const PURCHASED = "Purchased";

const PURCHASE_TYPE = {
  available: "AVAILABLE",
  byMe: "PURCHASED_BY_ME",
  byColleagues: "PURCHASED_BY_COLLEAGUES",
};

const PURCHASE_TYPE_TEXT = {
  [PURCHASE_TYPE.available]: "Available",
  [PURCHASE_TYPE.byMe]: "Purchased By Me",
  [PURCHASE_TYPE.byColleagues]: "Purchased By Colleagues",
};

export { PURCHASED, PURCHASE_TYPE, PURCHASE_TYPE_TEXT };
