import React, { useMemo } from "react";
import { Icon, RoundedButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { ExpertBadge } from "views/MobileInteractionsPage/MobileExpertsList";

export const Badge = ({
  tooltipText: tooltipTextInput,
  value: valueInput,
  icon,
  backgroundColor = null,
  characterLimit = undefined,
  useFullWidth = false,
  textColor = null,
  ...props
}) => {
  const { isMobile } = useCheckScreen();
  const {
    color: { border, text, background },
    spacing: { inner, layout },
  } = useThemeTokens();

  const componentColor = useMemo(() => backgroundColor ?? background.neutral.default, [
    backgroundColor,
    background.neutral.default,
  ]);
  const value = useMemo(
    () => (valueInput?.length > characterLimit ? valueInput.slice(0, characterLimit) + "..." : valueInput),
    [characterLimit, valueInput]
  );
  const tooltipText = useMemo(() => tooltipTextInput ?? valueInput, [tooltipTextInput, valueInput]);

  const shouldShowTooltip = tooltipText !== value;

  const badgeContent = (
    <x.span w={useFullWidth ? "100%" : undefined}>
      <RoundedButton
        bg={componentColor}
        borderColor={border.neutral.default}
        h={layout.base03}
        minW={"32px"}
        w={useFullWidth ? "100%" : undefined}
        paddingLeft={inner.base02}
        paddingRight={inner.base02}
        {...props}
      >
        <Typography
          variant={"body-small"}
          color={textColor || text.strong._}
          display="flex"
          alignItems="center"
          gap={inner.base}
        >
          {icon && <Icon size={"small"}>{icon}</Icon>}
          {value}
        </Typography>
      </RoundedButton>
    </x.span>
  );

  return isMobile ? (
    <ExpertBadge
      text={value}
      color={componentColor}
      icon={icon && <Icon size={"medium"}>{icon}</Icon>}
      isMobile={isMobile}
    />
  ) : shouldShowTooltip ? (
    <PortalTooltip title={tooltipText} position="bottom">
      {badgeContent}
    </PortalTooltip>
  ) : (
    badgeContent
  );
};
