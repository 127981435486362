import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCompanyCardStyles = () => {
  const { spacing, shape, color } = useThemeTokens();

  return {
    researchCard: {
      borderWidth: shape.border.width.small,
      borderColor: color.border.neutral.default,
      borderStyle: "solid",
      background: color.background.surface.page.default,
      padding: spacing.inner.base03,
      borderRadius: shape.border.radius.medium,
      cursor: "not-allowed",
      backgroundColor: {
        _: color.background.surface.page.default,
        hover: color.background.surface.page.hover,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    companyName: {
      ml: spacing.inner.base02,
    },
    description: {
      mt: spacing.inner.base02,
    },
  };
};
