import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useWorkRequestMaxiumChargeStyles = ({ fontColor }: { fontColor?: string }) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const linkIcon = {
    w: inner.base05,
    h: inner.base05,
    fontSize: inner.base05,
    transition: "transform 0.2s ease-in-out",
  };

  const link = {
    color: fontColor ?? text.secondary,
    outline: {
      focus: "none",
    },
    whiteSpace: "nowrap",
    h: inner.base05,
    gap: 0,
  };

  return {
    linkIcon,
    link,
  };
};
