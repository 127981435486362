import { isEqual } from "lodash";
import { COMPANY_TYPE, SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { INITIAL_FILTERS_STATE } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";

const makeParagraphs = (speechContent) =>
  `<div class="text-block"><p class="text-paragraph">${speechContent.replace(
    /\t| {4}/gm,
    '</p><p class="text-paragraph">'
  )}</p></div>`;

const hasSensitiveData = (data) => {
  if (!data || typeof data === "string") {
    return false;
  }
  if (data.hasOwnProperty("isSensitive")) {
    return data.isSensitive;
  }
  let entries;
  if (Array.isArray(data)) {
    entries = data;
  } else {
    entries = Object.values(data);
  }

  return entries.some((entry) => hasSensitiveData(entry));
};

const isKeywordSearch = (type) => type === SEARCH_SUGGESTION_TYPES.Keyword;

const isAngleCompanySearch = (companies, type, value) =>
  [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch].includes(type) &&
  companies?.some(({ companyName, companyType }) => companyName === value && companyType === COMPANY_TYPE.angle);

const getKeywordQuery = (alphaNowSearchQuery, companies) =>
  alphaNowSearchQuery.filter(
    ({ type, value }) => isKeywordSearch(type) || isAngleCompanySearch(companies, type, value)
  );

const getCompanySearchQuery = (query) =>
  query.find((item) =>
    [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch].includes(item?.type)
  );

const hasAppliedFilters = (appliedFilters) => !isEqual(appliedFilters, INITIAL_FILTERS_STATE);

const isDefaultQuery = (contentId, filters, searchQuery) =>
  !contentId && !hasAppliedFilters(filters) && searchQuery.length === 0;

const toPx = (value) => `${value}px`;

const fromPx = (value) => parseInt(value.replace("px", ""));

const measureTextWidth = (text, font) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    console.warn("2D context is not available.");
    return 0;
  }

  context.font = font;

  const metrics = context.measureText(text);
  return metrics.width;
};

const getNonModeratorSpeakers = (speakers = []) => speakers.filter((s) => !s.isModerator);

const hasMultipleSpeakers = (speakers = []) => getNonModeratorSpeakers(speakers).length > 1;

const parseText = (input = "", numSentences = 3) => {
  // Split the input by new lines and remove any leading dashes followed by spaces
  const lines = input.split("\n").map((line) => line.replace(/^-+\s*/, "").trim());
  // Take the first 'numSentences' lines
  const firstThreeLines = lines.slice(0, numSentences);
  // Join the lines with ". " only if the line is not empty to prevent extra spaces
  const result = firstThreeLines.filter((line) => line).join(". ");
  // Append a period to the result if not empty
  return result ? result + "." : "";
};

export {
  makeParagraphs,
  hasSensitiveData,
  getKeywordQuery,
  fromPx,
  toPx,
  getCompanySearchQuery,
  measureTextWidth,
  hasAppliedFilters,
  getNonModeratorSpeakers,
  hasMultipleSpeakers,
  parseText,
  isDefaultQuery,
};
