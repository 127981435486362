import { RequestType } from "types";
import { MessageResponse } from "../../types";

export const getRequestTypeName = (requestType: RequestType): string => {
  switch (requestType) {
    case RequestType.CallGuide: {
      return "Call Guide";
    }
    case RequestType.WorkRequest: {
      return "Work Request";
    }
    case RequestType.Clarification: {
      return "Clarification";
    }
  }
};

function isContentSanitized(message: MessageResponse): boolean {
  return (
    message.content != null &&
    message.sanitizedContent != null &&
    message.content.length !== message.sanitizedContent.length
  );
}

function getOrDefault(value: string, defaultValue: string): string {
  return value || defaultValue;
}

function replaceLineBreaks(text: string): string {
  return text
    .replace(/(\\\\n)/gm, "<br />")
    .replace(/(\\n)/g, "<br />")
    .replace(/(\n)/g, "<br />");
}

export function sanitizeMessage(message: MessageResponse) {
  const originalContent = replaceLineBreaks(getOrDefault(message.content, ""));
  const sanitizedContent = replaceLineBreaks(getOrDefault(message.sanitizedContent, ""));

  return { sanitizedContent, originalContent, isContentSanitized: isContentSanitized(message) };
}
