import React from "react";
import { LabeledIcon, PrimerFieldTitle, SectionTitle } from "pages/AlphaNowPage/primers/components";
import RankedKPCs from "./RankedKPCs";
import CompetitivePerformanceKPCs from "./CompetitivePerformanceKPCs";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const KeyPurchasingCriteria = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { spacing, color } = useThemeTokens();
  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <SectionTitle dataTestId="KeyPurchasingCriteria" text="Key Purchasing Criteria" />
      <PrimerFieldTitle text="Ranked KPCs" mt={spacing.inner.base05} />
      <LabeledIcon
        labelText={`Customers ranked the importance of KPCs in the ${primerTitle}.`}
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <RankedKPCs />
      <CompetitivePerformanceKPCs />
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(KeyPurchasingCriteria));
