import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTMessageStyles = (isAlphaGPTPurchaseExpired = false) => {
  const {
    color: { border },
    spacing: { inner, layout },
    shape,
  } = useThemeTokens();

  const messageWrapper = {
    padding: layout.base03,
    gap: inner.base08,
    display: "flex",
    alignItems: "flex-start",
    w: "100%",
    maxWidth: "724px",
  };

  const messageBody = {
    gap: inner.base06,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const messageFooter = {
    display: "flex",
    gap: inner.base02,
  };

  const messageSeparator = {
    w: "100%",
    borderTop: `${shape.border.width.sm} solid`,
    borderTopColor: border.divider,
  };

  const debugText = {
    whiteSpace: "pre-wrap",
  };

  const followUpQuestionButton = {
    w: "fit-content",
    h: "fit-content",
    py: inner.base02,
    textAlign: "left",
  };

  const followUpQuestionsSection = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
  };

  const suggestedQuestionsWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
  };

  const suggestedQuestionWrapper = {
    borderWidth: 1,
    borderColor: {
      _: border.divider,
      hover: border.selected,
    },
    borderRadius: inner.base02,
    px: inner.base03,
    py: inner.base02,
    cursor: "pointer",
    display: "inline-flex",
    gap: inner.base02,
    alignItems: "center",
    borderStyle: "solid !important",
  };

  const suggestedQuestionIcon = {
    flexShrink: 0,
  };

  return {
    messageWrapper,
    messageBody,
    messageFooter,
    messageSeparator,
    debugText,
    followUpQuestionButton,
    followUpQuestionsSection,
    suggestedQuestionsWrapper,
    suggestedQuestionWrapper,
    suggestedQuestionIcon,
  };
};
