import React from "react";
import { ArrowRight } from "@alphasights/alphadesign-icons";
import { Icon, Typography } from "@alphasights/alphadesign-components";
import { StyledSeeAllContentCard } from "pages/AlphaNowPage/components/SeeAllContentCard/SeeAllContentCard.styled";
import { SEE_ALL_LABEL } from "pages/AlphaNowPage/components/SeeAllContentCard/consts";

const DATA_TEST_IDS = {
  SeeAllContentCard: "see-all-content-card",
};

const SeeAllContentCard = ({ onClick }: { onClick: any }) => {
  return (
    <StyledSeeAllContentCard onClick={onClick} data-testid={DATA_TEST_IDS.SeeAllContentCard}>
      <Typography variant="body-em">{SEE_ALL_LABEL}</Typography>
      <Icon size="small">
        <ArrowRight />
      </Icon>
    </StyledSeeAllContentCard>
  );
};

export { SeeAllContentCard as default, DATA_TEST_IDS };
