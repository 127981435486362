import React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  StatusError,
  StatusPending,
  StatusPriorityLow,
  StatusUnaware,
  TickOutline,
} from "@alphasights/alphadesign-icons";

type TableHeaderProps = {
  cellsWidth: string;
};

const TableHeader = ({ cellsWidth }: TableHeaderProps) => {
  const { color, typography, spacing } = useThemeTokens();
  const cellsInfo = [
    {
      icon: null,
      iconColor: null,
      title: "Customers",
    },
    {
      icon: <TickOutline />,
      iconColor: color.chart.categorical.base03,
      title: "Uses",
    },
    {
      icon: <StatusError />,
      iconColor: color.chart.categorical.base04,
      title: "Churned",
    },
    {
      icon: <StatusPriorityLow />,
      iconColor: color.chart.categorical.base07,
      title: "Evaluated, did not select",
    },
    {
      icon: <StatusPending />,
      iconColor: color.chart.categorical.base,
      title: "Aware",
    },
    {
      icon: <StatusUnaware />,
      iconColor: color.chart.categorical.base09,
      title: "Unaware",
    },
  ];

  return (
    <x.div
      {...typography.preTitle}
      color={color.text.secondary}
      display="grid"
      gridTemplateColumns={cellsWidth}
      paddingBottom={spacing.inner.base05}
      borderBottom={`1px solid ${color.border.divider}`}
      columnGap="48px"
    >
      {cellsInfo.map(({ icon, iconColor, title }, index) => (
        <x.div display="flex" columnGap={spacing.inner.base} key={index.toString()}>
          {icon && (
            <Icon size="small" color={iconColor ? iconColor : color.icon.secondary}>
              {icon}
            </Icon>
          )}
          {title}
        </x.div>
      ))}
    </x.div>
  );
};

export default TableHeader;
