import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";

import { getNumberResultsDisplay, pluralizeResults } from "pages/AlphaNowPage/components/AlphaNowSearch/utils";

export const DataTestIds = {
  resultsCount: "results-count",
};

type ResultsCounterProps = {
  numberOfResults: number;
  productTypes: AlphaNowProductType[];
};

const ResultsCounter: FC<ResultsCounterProps> = ({ numberOfResults, productTypes }) => {
  const { color, spacing } = useThemeTokens();
  return (
    <Typography variant="body-small" color={color.text.secondary}>
      <Typography
        data-testid={DataTestIds.resultsCount}
        variant="body-small-em"
        component="span"
        color={color.text.danger}
        mx={spacing.inner.base}
      >
        {getNumberResultsDisplay(numberOfResults, productTypes)}
      </Typography>
      {pluralizeResults(Number(numberOfResults))}
    </Typography>
  );
};

export default ResultsCounter;
