import React from "react";
import styled, { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const MultiplePrimerCompanyLogo = ({ companyLogos, title }: { companyLogos: string[]; title?: string }) => {
  const { color, shape, spacing } = useThemeTokens();

  const LogoWrapper = styled.div(() => {
    return {
      display: "flex",
      width: spacing.inner.base08,
      minWidth: spacing.inner.base08,
      height: spacing.inner.base08,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: shape.border.radius.small,
      border: `${shape.border.width.sm} solid ${color.background.secondary}`,
    };
  });

  return (
    <LogoWrapper>
      <x.div
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap="2px"
        padding="2px"
        h="100%"
        gridAutoRows="1fr"
        w="100%"
        textAlign="center"
        alignItems="center"
      >
        {companyLogos?.slice(0, 3).map((logoSrc: string) => {
          return (
            <x.div
              backgroundImage={`url(${logoSrc})`}
              w="100%"
              h="100%"
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
            />
          );
        })}
      </x.div>
    </LogoWrapper>
  );
};

export default MultiplePrimerCompanyLogo;
