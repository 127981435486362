import styled, { x } from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { Tabs as AdsTabs, useThemeTokens } from "@alphasights/alphadesign-components";

export const ToolbarWrapper = styled(x.div)`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base08};
  height: 100%;
`;

export const Tabs = styled(AdsTabs)<{
  newNavigationEnabled: boolean;
  isInactive?: boolean;
}>(({ newNavigationEnabled, isInactive }) => {
  const { color } = useThemeTokens();
  if (!newNavigationEnabled) return "";

  return `
    align-items: center;
    background: transparent !important;
    button {
      background: transparent;
    }
    & > div {
      height: 100%;
    }

    [data-testid="hover-indicator"] {
      background: ${color.border.neutral.default};
    }

    button:has([data-testid="project-title-wrapper"]) {
      pointer-events: ${isInactive ? "none" : "auto"};
    }
  `;
});
