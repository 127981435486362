import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import useUrlParam from "pages/AlphaNowPage/hooks/useUrlParam";
import { useEffect } from "react";

export default (
  onOpenModal: () => void,
  urlParam: string,
  tracking?: {
    origin: HitOrigin;
    action: HitAction;
  }
) => {
  const { logHit } = useTrackUserAction();
  const isModalUrlOpen = useUrlParam(urlParam) === "true";

  useEffect(() => {
    if (isModalUrlOpen && tracking?.origin && tracking?.action) {
      logHit({
        origin: tracking.origin,
        action: tracking.action,
      });
    }
    if (isModalUrlOpen) {
      onOpenModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
