import React, { useContext, useState } from "react";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { Preference, PreferenceType } from "./types";

interface UserPreferencesContextState {
  globalPreferences: Preferences;
  projectPreferences: Map<string, Preferences>;
  setPreferences: React.Dispatch<React.SetStateAction<Preferences>>;
  setProjectPreferences: React.Dispatch<React.SetStateAction<Map<string, Preferences>>>;
}

type Preferences = Map<PreferenceType, Preference | undefined>;

const UserPreferencesContext = React.createContext<undefined | UserPreferencesContextState>(undefined);

export const UserPreferencesProvider = ({ children }: { children: JSX.Element }) => {
  const [globalPreferences, setPreferences] = useState<Preferences>(new Map());
  const [projectPreferences, setProjectPreferences] = useState<Map<string, Preferences>>(new Map());
  const currentUser = useCurrentUser();
  const isLoggedIn = !!currentUser;

  React.useEffect(
    function resetPreferencesOnUserChanged() {
      setPreferences(new Map());
      setProjectPreferences(new Map());
    },
    [isLoggedIn]
  );

  const context = {
    globalPreferences,
    projectPreferences,
    setPreferences,
    setProjectPreferences,
  };

  return <UserPreferencesContext.Provider value={context} children={children} />;
};

export const useUserPreferencesContext = ({ type, token }: { type: PreferenceType; token?: string }) => {
  const context = useContext(UserPreferencesContext);

  if (!context) throw new Error("UserPreferencesContext should only be used within UserPreferencesProvider");

  return {
    preference: token ? context.projectPreferences.get(token)?.get(type) : context.globalPreferences.get(type),
    setPreference: (value: Preference) => {
      if (token) {
        context.setProjectPreferences((prev) =>
          new Map(prev).set(token, (prev.get(token) ?? new Map()).set(type, value))
        );
      } else {
        context.setPreferences((prev) => new Map(prev).set(type, value));
      }
    },
  };
};
