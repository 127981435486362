import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, color, shape } = useThemeTokens();

  return {
    questionInputWrapper: {
      display: "flex",
      gap: spacing.inner.base03,
      p: spacing.inner.base05,
      border: "1px solid",
      borderColor: color.border.selected,
      borderRadius: shape.border.radius.medium,
      fontSize: "medium",
    },
    purple: {
      color: color.icon.ai,
    },
    error: {
      color: color.icon.danger,
    },
  };
};
