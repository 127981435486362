import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Does the same thing as {@link useRef}, but accepts a function to generate the initial value.
 *
 * This provides the benefit of only constructing the initial value once, while otherwise it would be done
 * every render.
 *
 * E.g.
 * ```Jsx
 * const MyComponent = () => {
 *   // IntersectionObserver will be constructed every render
 *   const myRef = useRef(new IntersectionObserver(...));
 *
 *   // Only one IntersectionObserver will be constructed
 *   const myRef2 = useRefEvaluated(() => new IntersectionObserver(...));
 * };
 * ```
 */
export const useRefEvaluated = <R>(defaultValueFactory: () => R): MutableRefObject<R | null> => {
  const ref = useRef<R | null>(null);

  useEffect(() => {
    ref.current = defaultValueFactory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return ref;
};
