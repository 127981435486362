import {
  TRIGGER_TYPE,
  WATCHLIST_DELIVERY_DAY,
  WATCHLIST_FREQUENCY,
  WATCHLIST_SORT_BY,
} from "hooks/useWatchlist/consts";
import { Company, Quote } from "@alphasights/alphadesign-icons";

export const watchlists = "Watchlists";
export const editWatchlistLabel = "Edit Watchlist";
export const watchlistSettingsLabel = "Settings";
export const addWatchlistEntries = "Add Entries";
export const noWatchlistEntriesMessage = "Search for companies, keywords, or paste in a list";
export const searchErrorMessage = "Search could not be completed. Please try again later.";

export const WATCHLIST_FREQUENCY_DISPLAY_NAME = {
  [WATCHLIST_FREQUENCY.immediate]: "Immediate",
  [WATCHLIST_FREQUENCY.daily]: "Daily",
  [WATCHLIST_FREQUENCY.weekly]: "Weekly",
  [WATCHLIST_FREQUENCY.monthly]: "Monthly",
};

export const WATCHLIST_DELIVERY_DAY_DISPLAY_NAME = {
  [WATCHLIST_DELIVERY_DAY.monday]: "Monday",
  [WATCHLIST_DELIVERY_DAY.tuesday]: "Tuesday",
  [WATCHLIST_DELIVERY_DAY.wednesday]: "Wednesday",
  [WATCHLIST_DELIVERY_DAY.thursday]: "Thursday",
  [WATCHLIST_DELIVERY_DAY.friday]: "Friday",
};

// Settings View
export const title = "Title";
export const alertFrequencyTitle = "Alert Frequency";
export const deliveryDayTitle = "Day";
export const deliveryTimeTitle = "Delivery Time";
export const deliveryTimeZoneTitle = "Time Zone";
export const monthlyAlertFrequencyDetails = "Monthly alerts are sent on the first Monday of the month.";
export const deleteWatchlistLabel = "Delete Watchlist";
export const deleteWatchlistConfirmation =
  "This will permanently delete this watchlist and all its entries. Press confirm to continue.";
export const confirmButton = "Confirm";
export const backButton = "Back";
export const saveButton = "Save";
export const cancel = "Cancel";

//Sorting and Filtering
export const filterLabel = "Sort & Filter";

export const WATCHLIST_SORT_DISPLAY_NAME = {
  [WATCHLIST_SORT_BY.dateDesc]: "Date added (newest first)",
  [WATCHLIST_SORT_BY.dateAsc]: "Date added (oldest first)",
  [WATCHLIST_SORT_BY.nameAsc]: "A to Z",
  [WATCHLIST_SORT_BY.nameDesc]: "Z to A",
};

export const TRIGGER_TYPE_ICONS: Record<string, Function> = {
  [TRIGGER_TYPE.company]: Company,
  [TRIGGER_TYPE.keyword]: Quote,
};

export const TRIGGER_TYPE_NAMES: Record<string, string> = {
  [TRIGGER_TYPE.company]: "Company",
  [TRIGGER_TYPE.keyword]: "Keyword",
};
