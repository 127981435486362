import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const EmptyWatchlistContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    gap: spacing.inner.base02,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    position: "sticky" as "sticky",
    top: "50%",
  };
});

const WatchlistContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    maxHeight: "448px",
    overflow: "auto",
    marginInline: `-${spacing.inner.base06}`,
    "hr:last-of-type": {
      display: "none",
    },
  };
});

const LoadingContainer = styled.div({
  position: "sticky",
  top: "50%",
  justifyContent: "center",
  display: "flex",
});

const TriggerSearchContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    justifyContent: "space-between",
    gap: spacing.inner.base02,
    ">:first-child": {
      width: "100%",
    },
  };
});

const SearchContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    display: "grid",
    gap: spacing.inner.base04,
    padding: `${spacing.inner.base04} ${spacing.inner.base06}`,
    borderBottom: `${shape.border.width.sm} solid ${color.border.divider}`,
    marginInline: `-${spacing.inner.base06}`,
  };
});

export { EmptyWatchlistContainer, WatchlistContainer, LoadingContainer, TriggerSearchContainer, SearchContainer };
