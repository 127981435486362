import React, { useContext } from "react";

export const CallbackContext = React.createContext();

export const CallbackProvider = ({ callbacks, ...props }) => {
  const context = { callbacks };

  return <CallbackContext.Provider value={context} {...props} />;
};

export const useCallbackContext = () => useContext(CallbackContext);
