import { useEffect, useMemo, useState } from "react";

interface UsePlusMoreProps {
  labels: string[];
}

interface PillContent {
  original: string;
  text: string;
}

export const usePlusMore = ({ labels }: UsePlusMoreProps) => {
  const [containerEl, setContainerEl] = useState<HTMLElement | null>();

  const [steady, setSteady] = useState(false);
  const [texts, setTexts] = useState<PillContent[]>(labels.map((label) => ({ original: label, text: label })));

  useEffect(() => {
    const currentLabels = texts.map(({ original }) => original).join(",");
    if (currentLabels === labels.join(",")) return;

    setTexts(labels.map((label) => ({ original: label, text: label })));
    setSteady(false);
  }, [labels]); // eslint-disable-line react-hooks/exhaustive-deps

  const regularPills = useMemo<PillContent[]>(() => {
    return texts.filter(({ text }) => text !== "");
  }, [texts]);

  const morePill = useMemo<PillContent[]>(() => {
    return texts.filter(({ text }) => text === "");
  }, [texts]);

  useEffect(() => {
    if (!containerEl) return;
    if (containerEl.children.length === 0) return;

    const observer = new IntersectionObserver(
      ([intersection]) => {
        if (intersection.boundingClientRect.width === intersection.intersectionRect.width) {
          setSteady(true);
          observer.disconnect();
          return;
        }
        const truncating = regularPills[regularPills.length - 1];

        const substring = truncating.text.endsWith("...") ? truncating.text.slice(0, -4) : truncating.text.slice(0, -1);

        const val = substring.trim() + "...";

        if (val === "...") {
          setTexts(texts.map((pill) => (pill === truncating ? { ...truncating, text: "" } : pill)));
        } else {
          setTexts(texts.map((pill) => (pill === truncating ? { ...truncating, text: val } : pill)));
        }
      },
      { root: containerEl }
    );

    const observe = containerEl.children[containerEl.children.length - 1];

    observe && observer.observe(observe);

    return () => observer.disconnect();
  }, [containerEl, texts]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    setContainerEl,
    steady,
    regularPills,
    morePill,
  };
};
