import React, { useState, useRef, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Formik, Form, Field, useFormikContext } from "formik";
import { x } from "@xstyled/styled-components";
import useOnClickOutside from "../../../hooks/useOnClickHooks";
import { parseISO } from "date-fns";
import { DataArea } from "./DataArea";
import {
  Button,
  Checkbox,
  IconOnlyButton,
  Typography,
  Select,
  Option,
  Pill,
} from "@alphasights/alphadesign-components";
import {
  CurrentTimezone,
  FormattedDateTime,
  FormattedTimeRange,
  useTimezone,
} from "../../../providers/TimezoneProvider";
import { Angle, CalendarAvailable, Clock, Close, Delete, Expert } from "@alphasights/alphadesign-icons";
import { useClientAvailabilityStyles } from "./ClientAvailability.styles";
import { createLabel, selectedInteractionAngleIds } from "components/ProjectsCalendar/eventSupport";
import { CalendarPopover } from "./CalendarPopover";
import { useMyProjects } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";

export const ClientAvailabilityWrapper = ({ event, children, referenceElement, ...others }) => {
  const emit = event.emit;
  const closePopup = () => {
    emit("selectEvent", null);
  };
  const openPopup = () => {
    !event.preselected && emit("selectEvent", event);
  };

  const popperRef = useRef();
  const eventRef = useRef();
  useOnClickOutside([popperRef, eventRef], closePopup);

  return (
    <div
      className={`aui-cursor-pointer ${event.id === "ghost" ? "aui-hidden" : ""}`}
      onClick={openPopup}
      ref={eventRef}
    >
      <div>{children}</div>
      {event.preselected && (
        <CalendarPopover ref={popperRef} referenceElement={referenceElement}>
          <ClientAvailabilityPopup event={event} onClose={closePopup} />
        </CalendarPopover>
      )}
    </div>
  );
};

const isCurrentlyMoving = (element) => {
  if (!element) return false;

  if (
    element.className.indexOf("rbc-addons-dnd-dragged-event") >= 0 ||
    element.className.indexOf("rbc-addons-dnd-drag-preview") >= 0
  )
    return true;

  return isCurrentlyMoving(element.parentElement);
};

const createEventLabel = (event) => {
  const type = event.selectedType ?? event.type ?? event.subtype;
  const params = {
    advisorshipIds: (type === "expert" && event.advisorshipIds) || [],
    angleIds: (type === "angle" && event.angleIds) || [],
    angles: event.project?.angles || [],
    advisorships: event.project?.allInteractions || [],
    subtype: type,
  };
  return createLabel(params);
};

export const ClientAvailabilityEvent = ({ event }) => {
  const { start, end, id } = event;
  const tz = useTimezone();
  const ref = useRef();

  return (
    <div data-testid={`slot-${id}`} className="aui-flex aui-flex-col aui-leading-tight aui-text-xs" ref={ref}>
      <div className="aui-font-semibold" style={{ whiteSpace: "nowrap" }}>
        {event.label || "(Availability)"}
      </div>
      <div data-testid={`event-period-slot-${id}`}>
        <FormattedTimeRange
          start={isCurrentlyMoving(ref.current) ? tz.zonedTimeToUtc(start) : start}
          end={isCurrentlyMoving(ref.current) ? tz.zonedTimeToUtc(end) : end}
        />
      </div>
    </div>
  );
};

export const clientAvailabilityEventProps = (event) => {
  return {
    className: classNames("event-light-blue"),
  };
};

const EventTime = ({ event }) => {
  const style = useClientAvailabilityStyles();
  return (
    <DataArea icon={<CalendarAvailable />} className="aui-items-center">
      <div className="aui-flex-col">
        <div className="aui-flex aui-justify-between">
          <Typography {...style.textStrong}>
            <FormattedDateTime date={event.start} format="EEEE d LLL, " />
            <FormattedTimeRange start={event.start} end={event.end} />
            <CurrentTimezone formatOnly={true} format=" (O)" />
          </Typography>
        </div>
      </div>
    </DataArea>
  );
};

const LastUpdated = ({ event }) => {
  const style = useClientAvailabilityStyles();
  const firstOriginal = event.originals && event.originals[0];
  const canShow = firstOriginal && firstOriginal.updatedAt && firstOriginal.userName;
  const allProjects = useMyProjects();
  if (!canShow) return null;

  const userBelongsToProject = allProjects?.find((p) => p.token === event.project.token);
  return (
    (userBelongsToProject && (
      <DataArea>
        <Typography variant="body-small" {...style.lastUpdated}>
          Last updated: {firstOriginal.userName},{" "}
          {FormattedDateTime({
            date: parseISO(firstOriginal.updatedAt),
            format: "eeee d MMM yyyy",
          })}
        </Typography>
      </DataArea>
    )) ||
    null
  );
};

const FormikReactMultiSelect = ({ options, field, form, icon, label, type, initialValues, createLabel, event }) => {
  const hasError = form.errors[field.name];
  useEffect(() => {
    if (form.values.type !== type) {
      form.setFieldValue(field.name, []);
    } else {
      form.setFieldValue(field.name, initialValues);
    }
  }, [form.values.type, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const { selectLabel, chip } = useClientAvailabilityStyles();

  const [selectedValues, setSelectedValues] = useState(
    options?.filter((o) => field.value?.find((v) => v === o.value)).map((o) => o.value)
  );

  const makeRemoveHandler = (item) => {
    return (event) => {
      event.preventDefault();
      const newSelected = selectedValues.filter((value) => value !== item.value);
      onChange(newSelected || []);
    };
  };

  const renderSelectedAsChips = (items) => {
    if (Array.isArray(items)) {
      return items.map(renderSelectedAsChips);
    }
    return (
      <Pill data-testid={`chip-${items.value}`} onClose={makeRemoveHandler(items)} maxChars="8" {...chip}>
        {items.label}
      </Pill>
    );
  };

  const onChange = (selectedVals) => {
    form.setFieldValue(field.name, selectedVals);
    event.emit("pendingChangesUpdated", {
      [field.name]: selectedVals,
      label: createLabel({
        [field.name]: selectedVals,
      }),
    });
    setSelectedValues(selectedVals || []);
  };

  return options && type === form.values.type ? (
    <DataArea icon={icon} data-testid={`parent-select-ids`}>
      <Typography {...selectLabel}>Specific {label.toLowerCase()} you are providing availability for</Typography>
      <Select
        data-testid={`select-ids-${type}`}
        renderSelectedValue={renderSelectedAsChips}
        allowMultiple
        placeholder={`Select ${label}`}
        value={selectedValues}
        input
        onChange={onChange}
        wrapItems={false}
        maxW={"324px"}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            onClick={(event) => event.preventDefault()}
          >
            <Checkbox checked={!!selectedValues.find((value) => value === option.value)}>{option.label}</Checkbox>
          </Option>
        ))}
      </Select>
      {hasError && <span className="aui-text-error-2">{hasError}</span>}
    </DataArea>
  ) : null;
};

const FormikReactSelect = ({ options, field, form, event, createLabel }) => {
  const { selectLabel } = useClientAvailabilityStyles();

  return (
    <DataArea icon={<Clock />} data-testid="select-type-area">
      <Select
        data-testid="select-type"
        label={<Typography {...selectLabel}>You are providing availability for {event.isFlyout && "this"}</Typography>}
        renderSelectedValue={({ value }) => {
          return <x.div>{value.label}</x.div>;
        }}
        value={options?.find((option) => option.value === field.value)}
        onChange={(selectedVals) => {
          form.setFieldValue(field.name, selectedVals);
          event.emit("pendingChangesUpdated", {
            selectedType: selectedVals,
            label: createLabel({
              selectedType: selectedVals,
            }),
          });
        }}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </Select>
    </DataArea>
  );
};

const ReadOnlyField = ({ project }) => {
  const { values } = useFormikContext();
  const isAngle = values.type === "angle";

  const advisorshipIds = values.advisorshipIds?.length > 0 ? values.advisorshipIds : project.selectedInteractionIds;
  const angleIds = values.angleIds?.length > 0 ? values.angleIds : selectedInteractionAngleIds(project);

  const angle = project.angles.find((a) => angleIds.includes(a.parent?.id || a.id));
  const interaction = project.interactions.find((a) => advisorshipIds[0]);
  const { textStrong, textSecondary } = useClientAvailabilityStyles();
  return values.type !== "project" ? (
    <DataArea icon={isAngle ? <Angle /> : <Expert />} data-testid="single-match">
      {isAngle && (
        <div className="aui-flex-col aui-flex">
          <Typography {...textStrong}>{angle?.title}</Typography>
        </div>
      )}
      {!isAngle && (
        <div className="aui-flex-col aui-flex">
          <Typography {...textStrong}>
            {interaction?.companyName} - {interaction?.role}
          </Typography>
          <Typography {...textSecondary}>{angle?.title}</Typography>
        </div>
      )}
    </DataArea>
  ) : null;
};

const calculateInitialValues = (event) => {
  const hasExpertSelected = event.project?.selectedInteractionIds?.length;
  const isFlyout = event.isFlyout;
  const isNew = event.subtype === "pending";

  if (isNew) {
    return {
      type:
        event.selectedType ||
        (hasExpertSelected && "expert") ||
        (event.project?.anglesTaggedToClient?.length && "angle") ||
        "project",
      advisorshipIds: event.advisorshipIds?.length ? event.advisorshipIds : event.project?.selectedInteractionIds,
      angleIds: event.angleIds?.length
        ? event.angleIds
        : hasExpertSelected
        ? event.project.selectedInteractionIds
            .map((i) => {
              const group = event.project.allInteractions?.find((ai) => ai.id === i)?.group;
              return group?.parent?.id || group?.id;
            })
            .filter((a) => a)
        : event.project?.anglesTaggedToClient,
    };
  }

  if (isFlyout) {
    return {
      type: event.selectedType || event.subtype,
      advisorshipIds: event.selectedInteractionIds || [],
      angleIds: [event.interactions.find((i) => event.selectedInteractionIds.includes(i.id))]
        .map((i) => i.group?.parent?.id || i.group?.id)
        .filter((angleId) => angleId),
    };
  }

  return {
    type: event.selectedType || event.subtype,
    advisorshipIds: event.advisorshipIds || [],
    angleIds: event.angleIds || [],
  };
};

export const ClientAvailabilityPopup = ({ event, onClose }) => {
  const [saving, setSaving] = useState(false);
  const [ref, setRef] = useState();

  useOnClickOutside(ref, onClose);

  const types = [
    { value: "expert", label: event.isFlyout ? "Expert" : "Expert(s)" },
    {
      value: "angle",
      label: event.isFlyout ? "Expert's angle" : "Expert angle(s)",
    },
    { value: "project", label: "Project" },
  ];

  const angleOptions =
    event.project?.angles?.map((angle) => ({
      value: angle.id,
      label: angle.title,
    })) || [];

  const expertOptions = useMemo(() => {
    // create experts keys to "count" the number of experts and add an 'Interaction N' label if more than one.
    const experts = event.project?.allInteractions?.reduce((acc, i) => {
      const key = `${i.companyName}|${i.advisorName}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(i.id);
      return acc;
    }, {});

    return Object.entries(experts).reduce((acc, [key, ids]) => {
      const [companyName, advisorName] = key.split("|");

      ids.forEach((id, index) => {
        const interactionLabel = ids.length > 1 ? ` (Interaction ${index + 1})` : "";
        const label = `${companyName} • ${advisorName}${interactionLabel}`;
        acc.push({
          value: id,
          label,
        });
      });
      return acc;
    }, []);
  }, [event.project.allInteractions]);

  const initialValues = calculateInitialValues(event);
  const styles = useClientAvailabilityStyles();

  const createLabel = (args) =>
    createEventLabel({
      type: initialValues.type,
      ...event,
      ...(event.isFlyout
        ? {
            advisorshipIds: event.project.selectedInteractionIds,
            angleIds: selectedInteractionAngleIds(event.project),
          }
        : {}),
      ...args,
    });

  useEffect(() => {
    event.emit("pendingChangesUpdated", {
      label: createLabel(),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteAvailability = () => {
    setSaving(true);
    event
      .emit("saveClientAvailability", {
        before: [
          {
            start: event.start,
            end: event.end,
            angleIds: event.angleIds,
            advisorshipIds: event.advisorshipIds,
          },
        ],
        after: null,
        origin: HitOrigin.calendarView,
        projectWide: initialValues.type === "project",
        interactionIds: initialValues.type === "expert" ? event.advisorshipIds : [],
        angleIds: initialValues.type === "angle" ? event.angleIds : [],
      })
      .then(onClose)
      .catch(() => setSaving(false));
  };

  return (
    <div
      ref={setRef}
      data-testid="client-availability-popup"
      style={{
        width: "400px",
      }}
    >
      <x.div {...styles.title}>
        <Typography variant="body-em" {...styles.textStrong}>
          Team Availability
        </Typography>
        <AvailabilityActions event={event} onClose={onClose} onDelete={deleteAvailability} />
      </x.div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        validate={(values) => {
          const errors = {};
          if (values.type === "angle" && !values.angleIds?.length) errors.angleIds = "Please select an option.";
          if (values.type === "expert" && !values.advisorshipIds?.length)
            errors.advisorshipIds = "Please select an option.";
          return errors;
        }}
        onSubmit={(values, bla) => {
          setSaving(true);
          event
            .emit("saveClientAvailability", {
              before:
                event.subtype === "pending"
                  ? null
                  : [
                      {
                        start: event.origin.startsAt,
                        end: event.origin.endsAt,
                        angleIds: event.originals.map((a) => a.angleId).filter((a) => a),
                        advisorshipIds: event.originals.map((a) => a.advisorshipId).filter((a) => a),
                      },
                    ],
              after: [
                {
                  start: event.start,
                  end: event.end,
                  angleIds: values.type === "angle" ? values.angleIds : [],
                  advisorshipIds: values.type === "expert" ? values.advisorshipIds : [],
                },
              ],
              origin: HitOrigin.calendarView,
              projectWide: values.type === "project",
              interactionIds: values.type === "expert" ? values.advisorshipIds : [],
              angleIds: values.type === "angle" ? values.angleIds : [],
            })
            .then(onClose)
            .catch(() => setSaving(false));
        }}
        initialValues={initialValues}
      >
        <Form>
          <x.div {...styles.formWrapper}>
            <EventTime event={event} />
            <x.div {...styles.fields}>
              <Field
                name={"type"}
                component={FormikReactSelect}
                options={types}
                event={event}
                createLabel={createLabel}
              />
              {!event.isFlyout && (
                <>
                  <Field
                    name="angleIds"
                    component={FormikReactMultiSelect}
                    options={angleOptions}
                    icon={<Angle />}
                    label="Expert angle(s)"
                    type="angle"
                    initialValues={initialValues.angleIds}
                    createLabel={createLabel}
                    event={event}
                  />
                  <Field
                    name="advisorshipIds"
                    component={FormikReactMultiSelect}
                    options={expertOptions}
                    icon={<Expert />}
                    label="Expert(s)"
                    type="expert"
                    initialValues={initialValues.advisorshipIds}
                    createLabel={createLabel}
                    event={event}
                  />
                </>
              )}
              {event.isFlyout && <ReadOnlyField project={event.project} />}
            </x.div>
            <LastUpdated event={event} />
          </x.div>
          {!event.saveInBatch && (
            <x.div {...styles.save}>
              <Button
                variant="secondary"
                size="small"
                data-testid="save-availability-button"
                loading={saving}
                type="submit"
              >
                <Typography variant="body-small-em">Save Availability</Typography>
              </Button>
            </x.div>
          )}
        </Form>
      </Formik>
    </div>
  );
};

const AvailabilityActions = ({ event, onClose, onDelete }) => {
  const styles = useClientAvailabilityStyles();
  return (
    <div className="aui-flex aui-items-end aui-justify-end aui-space-x-5">
      {(event.subtype !== "pending" || event.saveInBatch) && (
        <IconOnlyButton
          data-testid="delete-client-availability"
          variant="ghost"
          {...styles.deleteIcon}
          onClick={onDelete}
        >
          <Delete />
        </IconOnlyButton>
      )}
      <IconOnlyButton
        data-testid="close-client-availability-popup"
        variant="ghost"
        onClick={onClose}
        {...styles.closeIcon}
      >
        <Close />
      </IconOnlyButton>
    </div>
  );
};
