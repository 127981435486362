import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  ReactElement,
  useCallback,
} from "react";
import { debounce, isEqual } from "lodash";
import { Icon, Loading, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Search as SearchIcon, Settings } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import {
  bringToFront,
  sendToBack,
  hasBooleanOperatorInString,
  booleanSearchQueryArray,
  computeNewInputFieldPosition,
} from "./utils";
import { Input, OptionsPopover, getDefaultComponents } from "components/Search/components";
import {
  Keys,
  SearchVariant,
  BooleanTypes,
  Operator,
  Symbol,
  OperatorFirstCharacter,
  SearchSizeVariant,
  STYLE_CONFIG,
  SearchError,
} from "components/Search/consts";
import { SearchProps, SearchOption } from "./types";
import usePrevious from "hooks/usePrevious";
import useOnClickOutside from "hooks/useOnClickHooks";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { useAsyncDebounce } from "hooks/useDebounce";
import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import { useAlphaNowPageStore } from "pages/AlphaNowPage/store/useAlphaNowPageStore";

const DataTestIds = {
  SearchContainer: "search-container",
  SearchBarIcon: "search-bar-icon",
};

const Search = ({
  variant,
  query,
  options,
  size = SearchSizeVariant.Medium,
  placeholder = "",
  components = {},
  loadOptions = () => [],
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClipboardPaste, // this prop should not have a default value
  // calling onError with no arguments signifies that there should be no error message
  // and the error message should be cleared
  onError = () => {},
  optionProps,
  optionsPopoverFooterProps,
  allowSingleCharSearch = true,
  debounceSearch = false,
  allowBooleanOperators = false,
  autoSearchOnChange = true, // use carefully with empty queries as it may not be obvious that onChange has not been triggered
  isSingleSelect = false,
  errorMessage = "",
  autoHideSearchIcon = false,
  autoHideSearchLoadingIcon = true,
  style,
  isCollapsible,
  dataTestId,
  optionSections,
  booleanSearchProps,
}: SearchProps) => {
  const { color, spacing, shape } = useThemeTokens();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>("");
  // assume format { value, label } for all options
  // where label is missing, use value for display
  const [listOptions, setListOptions] = useState<SearchOption[]>(options ?? []);
  const [items, setItems] = useState<SearchOption[]>(variant === SearchVariant.Complex ? query ?? [] : []);
  const [optionsAnchor, setOptionsAnchor] = useState<HTMLElement>();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState<number>();
  const [searchContainerWidth, setSearchContainerWidth] = useState(0);
  const [inputFieldIndex, setInputFieldIndex] = useState(query?.length ?? 0);
  const [isReady, setIsReady] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const isMounted = useRef(false);
  const previousItems = (usePrevious(items) as unknown) as SearchOption[];
  const previousQuery = (usePrevious(query) as unknown) as SearchOption[];
  const hideSearchIcon = autoHideSearchIcon && items.length;
  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const minHeight = styles.height;
  const maxHeight = useMemo(() => toPx(fromPx(styles.height) * 2), [styles]);
  const {
    icon: { size: iconSize, xStyledProps: iconStyles },
    searchItem: { xStyledProps: searchItemStyles },
    loadingSpinner: { xStyledProps: loadingSpinnerStyles },
  } = styles;
  const { unfocusSearch, setUnfocusSearch } = useAlphaNowPageStore((state) => state);

  useOnClickOutside(ref, () => {
    handleInputBlur();
  });
  const allowBoolean = variant === SearchVariant.Complex && allowBooleanOperators;

  const loadOptionsDebounced = useAsyncDebounce({
    promise: loadOptions,
    delay: 200,
  });

  const loadSearchOptions = async (selectedBooleanSearchQueryInput?: string) => {
    const searchEntry = selectedBooleanSearchQueryInput ?? inputValue;

    let updatedSearchOptions: SearchOption[] = [];
    // if boolean expressions are allowed, add boolean operators to the list of options
    if (allowBoolean && searchEntry.length > 0) {
      // trim and uppercase the input to make it case-insensitive and allow for spaces
      const normalisedInput = searchEntry.trim().toUpperCase();
      const operatorMatch =
        normalisedInput.length && Object.values(Operator).find((value) => value.startsWith(normalisedInput));
      const symbolMatch =
        normalisedInput.length && Object.values(Symbol).find((value) => value.startsWith(normalisedInput));
      // if there is an operator match, add the operator to the list of options
      if (operatorMatch) {
        updatedSearchOptions = [
          {
            label: operatorMatch,
            value: operatorMatch,
            secondaryLabel: "Boolean",
            type: BooleanTypes[operatorMatch],
            StartIcon: Settings,
          },
        ];
        // if there is a symbol (bracket) match, add both brackets to the list of options
      } else if (symbolMatch) {
        const brackets = [
          {
            label: Symbol.LEFT_BRACKET,
            value: Symbol.LEFT_BRACKET,
            type: BooleanTypes.LEFT_BRACKET,
          },
          {
            label: Symbol.RIGHT_BRACKET,
            value: Symbol.RIGHT_BRACKET,
            type: BooleanTypes.RIGHT_BRACKET,
          },
        ];
        // always put the matching bracket at the top of the list
        if (searchEntry.includes(Symbol.RIGHT_BRACKET)) {
          brackets.reverse();
        }
        updatedSearchOptions = [...brackets];
      }
    }

    const booleanSection = booleanSearchProps?.section;
    updatedSearchOptions = booleanSection
      ? updatedSearchOptions.map((option) => ({
          ...option,
          section: booleanSection,
        }))
      : updatedSearchOptions;

    // avoid load options on operator and symbol inputs from a selected boolean search query
    const selectedInputFromBooleanSearchQuery = selectedBooleanSearchQueryInput !== undefined;
    const booleanMatch = updatedSearchOptions.length > 0;

    // load options from API/wherever they come from
    if (!selectedInputFromBooleanSearchQuery || !booleanMatch) {
      // only debounce search if the input was not parsed from a boolean search query
      const search = !selectedInputFromBooleanSearchQuery && debounceSearch ? loadOptionsDebounced : loadOptions;
      const searchOptions = (await search(searchEntry)) ?? [];
      updatedSearchOptions = [...updatedSearchOptions, ...searchOptions];
    }

    // set options and open the options popover if there are options to show
    if (!selectedInputFromBooleanSearchQuery) {
      setListOptions(updatedSearchOptions);
      setIsOptionsOpen(!!inputValue.length && !!updatedSearchOptions.length);
    }

    return updatedSearchOptions;
  };

  useDeepCompareEffect(() => {
    const resetInputFieldIndex = () => moveInputField(query?.length ?? 0, false);
    if (variant === SearchVariant.Simple) {
      setListOptions(options);
      resetInputFieldIndex();
    } else if (previousQuery && !isEqual(query, items)) {
      handleSearchChange(query, autoSearchOnChange);
      resetInputFieldIndex();
    }
  }, [query]); //eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (ref?.current) {
      setOptionsAnchor(ref.current);
    }

    const observer = new ResizeObserver(
      debounce(() => {
        if (ref?.current) {
          const { width } = ref.current.getBoundingClientRect();
          setSearchContainerWidth(width);
        }
      }, 200)
    );
    !!ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (variant === SearchVariant.Simple) {
      const matchingOptions = options.filter((option: SearchOption) => option.label.startsWith(inputValue));
      if (matchingOptions.length) {
        setListOptions(matchingOptions);
      } else {
        setListOptions(options);
      }
    } else {
      setIsOptionsLoading(true);
      loadSearchOptions().then(() => setIsOptionsLoading(false));
    }
  }, [inputValue]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isOptionsOpen && setHighlightedOptionIndex(undefined);
  }, [isOptionsOpen]);

  useEffect(() => {
    if (isReady) {
      focusInputField();
    }
  }, [inputFieldIndex, isReady]);

  useEffect(() => {
    if (isMounted.current) {
      if (autoSearchOnChange && !isEqual(items, query)) {
        onChange(items);
      }
    } else {
      isMounted.current = true;
    }
  }, [autoSearchOnChange]); //eslint-disable-line react-hooks/exhaustive-deps

  const searchComponents = useMemo(() => {
    const defaultComponents = getDefaultComponents();
    return { ...defaultComponents, ...components };
  }, [components]);

  const handleSearchChange = (updatedItems: SearchOption[], shouldCallOnChange = true) => {
    setItems(updatedItems);
    if (shouldCallOnChange || (!shouldCallOnChange && updatedItems.length === 0)) {
      onChange(updatedItems); // callback should handle check for unchanged values
    }
    if (!updatedItems?.length && previousItems?.length) {
      handleBlur();
    }
    variant === SearchVariant.Simple && setIsOptionsOpen(false);
  };

  const focusInputField = () => {
    inputRef?.current?.focus();
  };

  const blurInputField = () => {
    inputRef?.current?.blur();
  };

  const moveInputField = (index: number, shouldFocus = true) => {
    setInputFieldIndex(index);
    shouldFocus && handleInputFocus();
  };

  const handleFocus = () => {
    onFocus();
    bringToFront(wrapperRef.current);
  };

  const handleBlur = useCallback(() => {
    onBlur();
    sendToBack(wrapperRef.current);
  }, [onBlur]);

  const handleInputFocus = () => {
    handleFocus();
    focusInputField();
    listOptions.length && setIsOptionsOpen(true);
  };

  const handleInputBlur = () => {
    handleBlur();
    blurInputField();
  };

  useEffect(() => {
    if (unfocusSearch) {
      handleBlur();
      setUnfocusSearch(false);
    }
  }, [unfocusSearch, setUnfocusSearch, handleBlur]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value;
    newInputValue.length === 1 && handleInputFocus();

    if (isSingleSelect && items.length > 0) {
      return;
    }
    setInputValue(newInputValue);
  };

  const handleOptionSelect = async ({ shouldCallOnChange } = { shouldCallOnChange: true }) => {
    onError();
    const booleanSearchQueryInputs = booleanSearchQueryArray(inputValue);

    if (!allowSingleCharSearch) {
      const hasNonOperatorSingleChar = booleanSearchQueryInputs.some(
        (item) =>
          item.length === 1 && !Object.values({ ...Symbol, ...OperatorFirstCharacter }).includes(item.toUpperCase())
      );
      if (hasNonOperatorSingleChar) {
        setIsOptionsOpen(false);
        onError(SearchError.invalidCharacterNumber);
        return;
      }
    }

    if (!isOptionsLoading && isOptionsOpen && listOptions.length) {
      const newItems: SearchOption[] = [];
      if (hasBooleanOperatorInString(inputValue)) {
        const handleSearch = booleanSearchQueryInputs.map(async (selectedInput) => {
          const searchOptions = await loadSearchOptions(selectedInput);
          return searchOptions[0];
        });
        const searchItems = await Promise.all(handleSearch);
        newItems.push(...searchItems);
      } else {
        const selectedOption = listOptions[highlightedOptionIndex ?? 0];
        newItems.push(selectedOption);
      }
      const updatedItems = items.slice(0, inputFieldIndex).concat(newItems, items.slice(inputFieldIndex));
      handleSearchChange(updatedItems, shouldCallOnChange);
      moveInputField(inputFieldIndex + newItems.length, false);
      setInputValue("");
    }
  };

  const handleItemRemove = (index?: number) => {
    let updatedItems = [...items];
    let removedItemIndex;

    if (index === undefined) {
      if (!items.length) return;
      removedItemIndex = inputFieldIndex - 1;
    } else {
      removedItemIndex = index;
    }
    updatedItems = items.slice(0, removedItemIndex).concat(items.slice(removedItemIndex + 1));
    handleSearchChange(updatedItems, autoSearchOnChange);
    inputFieldIndex > removedItemIndex && moveInputField(inputFieldIndex - 1, updatedItems.length > 0);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case Keys.Enter:
        if (inputValue.length || highlightedOptionIndex !== undefined) {
          handleOptionSelect({ shouldCallOnChange: autoSearchOnChange });
        } else {
          if (!(inputValue.length || autoSearchOnChange)) {
            onChange(items);
          }
          handleInputBlur();
          setIsOptionsOpen(false);
        }
        break;
      case Keys.Backspace:
        !inputValue.length && handleItemRemove();
        break;
      case Keys.ArrowUp:
        switch (highlightedOptionIndex) {
          case undefined:
          case 0:
            setHighlightedOptionIndex(listOptions.length - 1);
            break;
          default:
            setHighlightedOptionIndex(highlightedOptionIndex - 1);
        }
        break;
      case Keys.ArrowDown:
        switch (highlightedOptionIndex) {
          case undefined:
          case listOptions.length - 1:
            setHighlightedOptionIndex(0);
            break;
          default:
            setHighlightedOptionIndex(highlightedOptionIndex + 1);
        }
        break;
      case Keys.ArrowLeft:
        const shouldMoveInputLeft = !isSingleSelect && !inputValue.length && inputFieldIndex > 0;

        if (shouldMoveInputLeft) moveInputField(inputFieldIndex - 1);

        break;
      case Keys.ArrowRight:
        const shouldMoveInputRight = !isSingleSelect && !inputValue.length && inputFieldIndex < items.length;

        if (shouldMoveInputRight) moveInputField(inputFieldIndex + 1);

        break;
      case Keys.Escape:
        handleInputBlur();
        break;
      default:
        break;
    }
  };

  // calculate the new position of the input field based on the click position
  const handleSearchContainerClick = (e: MouseEvent<HTMLElement>) => {
    setIsReady(true);
    handleInputFocus();

    // establish if click was between items or on the left/right edge of an item
    // if it was, move the input field to that position

    // X and Y coordinates of the click
    const click = { x: e.clientX, y: e.clientY };

    // ignore click if it is on input field
    const inputFieldDimensions = inputRef?.current?.getBoundingClientRect();
    if (inputFieldDimensions) {
      if (
        click.x > inputFieldDimensions.left &&
        click.x < inputFieldDimensions.right &&
        click.y > inputFieldDimensions.top &&
        click.y < inputFieldDimensions.bottom
      ) {
        return;
      }
    }

    // check if click is within the search container bounds
    const parentDimensions = document.getElementById("search-item-0")?.parentElement?.getBoundingClientRect();
    if (parentDimensions) {
      if (
        click.x < parentDimensions.left ||
        click.x > parentDimensions.right ||
        click.y < parentDimensions.top ||
        click.y > parentDimensions.bottom
      ) {
        return;
      }

      const itemDimensions = items
        .map((_, index) => document.getElementById(`search-item-${index}`)?.getBoundingClientRect())
        .filter((item): item is DOMRect => !!item);

      const newInputIndex = computeNewInputFieldPosition(click, itemDimensions, parentDimensions);
      newInputIndex > -1 && moveInputField(newInputIndex);
    }
  };

  const renderItems = () => {
    const elements = [] as ReactElement[];
    const inputElem = (
      <Input
        ref={inputRef}
        size={size}
        value={inputValue}
        placeholder={items.length ? "" : placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={onClipboardPaste}
      />
    );
    let isInputAdded = false;

    if (inputFieldIndex === 0) {
      const inputKey = "input-start";
      elements.push(React.cloneElement(inputElem, { key: inputKey, "data-testid": inputKey }));
      isInputAdded = true;
    }

    if (items.length === 0) {
      return elements;
    }

    items.forEach((item, index) => {
      const { SearchItem } = searchComponents;
      const booleanTypes = Object.values(BooleanTypes) as string[];
      const isBoolean = booleanTypes.includes(item.type as string);
      elements.push(
        <SearchItem
          key={index}
          size={size}
          index={index}
          onClick={handleFocus}
          onRemove={() => handleItemRemove(index)}
          data={item}
          isBoolean={isBoolean}
          asPlainText={isBoolean}
        />
      );
      if (index === inputFieldIndex - 1 && !isInputAdded) {
        const inputKey = `input-${index}`;
        elements.push(React.cloneElement(inputElem, { key: inputKey, "data-testid": inputKey }));
        isInputAdded = true;
      }
    });

    if (inputFieldIndex === items.length && !isInputAdded) {
      const inputKey = "input-end";
      elements.push(React.cloneElement(inputElem, { key: inputKey, "data-testid": inputKey, isLastItem: true }));
      return elements;
    }

    const lastIndex = items.length - 1;
    elements[lastIndex] = React.cloneElement(elements[lastIndex], { isLastItem: true });
    return elements;
  };

  const Wrapper = isCollapsible ? CollapsedWrapper : x.div;

  return (
    <Wrapper
      ref={wrapperRef}
      position="relative"
      background={color.background.surface.overlay}
      zIndex="50"
      minH={minHeight}
      maxH={maxHeight}
      borderRadius={shape.border.radius.small}
      {...style}
      isSearchEmpty={!items.length}
      onClick={isCollapsible ? handleSearchContainerClick : undefined}
    >
      <x.div
        ref={ref}
        display="flex"
        alignItems="baseline"
        pl={iconStyles.pl}
        gap={spacing.inner.base02}
        borderRadius="inherit"
        overflowX="hidden"
        overflowY="clip"
        outline={
          !!errorMessage
            ? `${shape.border.width.small} solid ${color.border.danger}`
            : {
                _: `${shape.border.width.small} solid ${color.border.neutral.default}`,
                focusWithin: `${shape.border.width.small} solid ${color.border.selected}`,
              }
        }
      >
        {!hideSearchIcon && (
          <x.div alignSelf="center" data-testid={DataTestIds.SearchBarIcon}>
            {autoHideSearchLoadingIcon ? (
              <Icon color={color.icon.secondary} size={iconSize}>
                <SearchIcon />
              </Icon>
            ) : (
              <Loading {...loadingSpinnerStyles} />
            )}
          </x.div>
        )}
        <x.div
          data-testid={dataTestId ?? DataTestIds.SearchContainer}
          w="-webkit-fill-available"
          maxH={maxHeight}
          display="flex"
          flexWrap="wrap"
          py={spacing.inner.base}
          mx={items.length ? spacing.inner.base : 0}
          alignItems="center"
          gap={searchItemStyles.gap}
          cursor="pointer"
          onClick={handleSearchContainerClick}
          overflowX="hidden"
          overflowY="auto"
        >
          {renderItems()}
        </x.div>
      </x.div>
      {errorMessage && (
        <Typography
          backgroundColor={color.background.neutral.subtle}
          pt={spacing.inner.base02}
          variant="body-small"
          color={color.border.danger}
        >
          {errorMessage}
        </Typography>
      )}
      <OptionsPopover
        size={size}
        anchorEl={optionsAnchor}
        isOpen={isOptionsOpen}
        onClose={() => setIsOptionsOpen(!isOptionsOpen)}
        options={listOptions}
        optionSections={optionSections}
        onSelect={() => handleOptionSelect({ shouldCallOnChange: autoSearchOnChange })}
        highlightedOptionIndex={highlightedOptionIndex}
        searchContainerWidth={searchContainerWidth}
        optionProps={optionProps}
        footerProps={optionsPopoverFooterProps}
        onOptionHighlight={setHighlightedOptionIndex}
        components={{ Option: searchComponents.Option }}
        allowBooleanExpressions={allowBoolean}
      />
    </Wrapper>
  );
};

const CollapsedWrapper = styled(x.div)(({ isSearchEmpty }: { isSearchEmpty: boolean }) => {
  const {
    shape,
    color: { background },
    spacing,
  } = useThemeTokens();

  return `
    transition: width 0.3s ease-in-out;
    width: 200px;
    height: ${spacing.inner.base08};
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    border-radius: ${shape.border.radius.xlarge};
    &:has(input:not(:focus)) {
      &:hover, &:hover input {
        background-color: ${background.action.outline.hover};
      }
      &:not(:hover) {
        [data-testid="deliverables-searchbar-clear-icon"] {
          display: none;
        }
      }
    }
    [data-testid="search-container"] {
      flex-wrap: nowrap;
    }
    ${
      isSearchEmpty &&
      `
      &:has(input:not(:focus)) {
        width: 40px;
        cursor: pointer !important;
        [data-testid="search-container"] {
          pointer-events: none;
        }
      }
    `
    }
  `;
});

export { Search as default, DataTestIds };
