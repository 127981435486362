const emphasiseLabel = (label: string, inputValue: string, shouldEmphasise: boolean, matchAnywhere: boolean) => {
  if (inputValue && shouldEmphasise) {
    const normalisedLabelValue = label.toLowerCase();
    const normalisedInputValue = inputValue.toLowerCase();

    if (matchAnywhere) {
      const matchPos = normalisedLabelValue.indexOf(normalisedInputValue);
      if (matchPos === -1) {
        return [{ text: label, isEmphasised: false }];
      }

      const matchEndIndex = matchPos + inputValue.length;

      const parts = [
        { text: label.substring(0, matchPos), isEmphasised: false },
        { text: label.substring(matchPos, matchEndIndex), isEmphasised: true },
      ];
      if (matchEndIndex < label.length) {
        parts.push({ text: label.substring(matchEndIndex), isEmphasised: false });
      }
      return parts;
    }

    if (normalisedLabelValue.startsWith(normalisedInputValue)) {
      return [
        { text: label.substring(0, inputValue.length), isEmphasised: true },
        { text: label.substring(inputValue.length), isEmphasised: false },
      ];
    }
  }

  return [{ text: label, isEmphasised: false }];
};

export { emphasiseLabel };
