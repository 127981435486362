import { ContentSuggestion } from "../types";

export const getCompaniesText = (companies: Company[]) => {
  return companies.map((company) => company.companyName).join(", ");
};

export const getPublicationDate = (content: ContentSuggestion) => {
  if (content.isPrimer) {
    return content.lastUpdatedAt;
  } else {
    return content.scheduledTimeUTC;
  }
};

export const isEllipsisActive = (element: HTMLElement) => element.offsetWidth < element.scrollWidth;
