import useTwilioClient from "../../hooks/twilio/useTwilioClient";
import React, { useEffect } from "react";
import { Button, Typography, IconButton } from "@alphasights/alphadesign-components";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction } from "@alphasights/portal-api-client";
import { TwilioMobileConnection } from "./TwilioMobileConnection";
import { AudioRecording, MicOff, Tick } from "@alphasights/alphadesign-icons";

const Desktop = ({ isConnected, actionConnect, actionMute, dialStatus, isMute, elapsedTime }) => {
  const renderDialStatus = (isConnected, dialStatus) => {
    if (isConnected) {
      return dialStatus === "reconnecting" ? "Reconnecting..." : "Connected";
    } else {
      return dialStatus === "testing" ? "Testing connection..." : "Connecting...";
    }
  };

  return (
    <div className="aui-flex aui-mt-6">
      {isConnected || dialStatus !== "none" ? (
        <>
          <Button disabled={!isConnected} onClick={actionConnect} variant="outline" size="medium">
            Disconnect
          </Button>

          <div className="aui-flex-grow" />

          <div
            className={`${
              dialStatus === "testing" ? "aui-text-grey-4" : "aui-text-success-2"
            } aui-flex aui-flex-row aui-font-semibold aui-items-center`}
          >
            {isConnected && (
              <IconButton variant="ghost" color="success" size="medium">
                <Tick />
              </IconButton>
            )}{" "}
            <div className={`${!isConnected ? "aui-mr-3" : ""}`}>{renderDialStatus(isConnected, dialStatus)}</div>
            {elapsedTime && (
              <div className={"aui-ml-3"}>
                <Typography variant="body-small" color="secondary">
                  {elapsedTime}
                </Typography>
              </div>
            )}
            <>
              {isConnected && (
                <IconButton size="medium" variant="ghost" onClick={actionMute}>
                  {isMute ? <MicOff /> : <AudioRecording />}
                </IconButton>
              )}
            </>
          </div>
        </>
      ) : (
        <Button onClick={actionConnect} variant="secondary" size="medium">
          Connect
        </Button>
      )}
    </div>
  );
};

export const TwilioClientConnection = ({
  variant,
  thick,
  children,
  mainNumber,
  accessCode,
  projectToken,
  interactionId,
  isClosed,
  setIsWebConnectionActive = () => {},
  setWebConnectError = () => {},
  setWebConnectWarning = () => {},
  setIsConnectionDegraded = () => {},
  setWebConnectInfo = () => {},
  setIsConnected = () => {},
  globalTwilioClient,
  origin,
  setSelectedTabIndex,
}) => {
  const twilioClient = useTwilioClient({ mainNumber, setSelectedTabIndex });

  const {
    isMute,
    setIsMute,
    isConnected,
    dialStatus,
    isConnectionDegraded,
    disconnect,
    connect,
    error,
    warning,
    info,
    activeAccessCode,
    elapsedTime,
    subjectAccessCode,
  } = globalTwilioClient || twilioClient;

  const { logHit } = useTrackUserAction();

  useEffect(() => {
    setIsConnectionDegraded(isConnectionDegraded);
  }, [isConnectionDegraded, setIsConnectionDegraded]);

  useEffect(() => {
    if (error) {
      console.error(`Error on Twilio Web Connect: ${error}`);
      setWebConnectError(error);
    } else {
      setWebConnectError(null);
    }
  }, [error, setWebConnectError]);

  useEffect(() => {
    if (warning) {
      setWebConnectWarning(warning);
    } else {
      setWebConnectWarning(null);
    }
  }, [warning, setWebConnectWarning]);

  useEffect(() => {
    if (info) {
      setWebConnectInfo(info);
    } else {
      setWebConnectInfo(null);
    }
  }, [info, setWebConnectInfo]);

  useEffect(() => {
    setIsConnected(isConnected);
  }, [isConnected, setIsConnected]);

  useEffect(() => {
    setIsWebConnectionActive(isConnected);
  }, [isConnected, setIsWebConnectionActive]);

  // Disconnects if the modal containing the panel is closed
  useEffect(() => {
    if (isConnected && isClosed) {
      disconnect();
    }
  }, [isClosed, isConnected, disconnect]);

  // Disconnects if the access code changes, i.e. if the panel is rendered for another interaction
  // while a connection is active (for example, when navigating between expert table interactions using the keyboard)
  useEffect(() => {
    if (globalTwilioClient) return;

    if (isConnected && accessCode !== activeAccessCode) {
      disconnect();
    }
  }, [accessCode, activeAccessCode, isConnected, disconnect, globalTwilioClient]);

  const actionConnect = () => {
    if (isConnected) {
      disconnect();
    } else {
      logHit({
        origin,
        action: HitAction.callJoin,
        projectToken,
        advisorshipId: interactionId,
        details: { method: "WEB_CONNECT" },
      });
      connect(accessCode, projectToken, interactionId);
    }
  };

  const actionMute = () => {
    setIsMute((current) => !current);
  };

  const RenderComponent = variant === "mobile" ? TwilioMobileConnection : Desktop;

  return (
    <RenderComponent
      accessCode={accessCode}
      thick={thick}
      children={children}
      isConnected={isConnected}
      actionConnect={actionConnect}
      actionMute={actionMute}
      dialStatus={dialStatus}
      isMute={isMute}
      elapsedTime={elapsedTime}
      error={error}
      warning={warning}
      info={info}
      isConnectionDegraded={isConnectionDegraded}
      activeAccessCode={activeAccessCode}
      subjectAccessCode={subjectAccessCode}
    />
  );
};
