import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const LoadingWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    justifyContent: "center",
    paddingBottom: spacing.inner.base02,
  };
});

export { LoadingWrapper };
