import * as testUtils from "./test-utils";
export * from "./boolean-utils";

export const bringToFront = (el: HTMLElement | null | undefined, zIndex = "50") => {
  if (el) {
    el.style.zIndex = zIndex ?? "50";
  }
};

export const sendToBack = (el: HTMLElement | null | undefined, zIndex = "0") => {
  if (el) {
    el.style.zIndex = zIndex ?? "0";
  }
};

export { testUtils };
