import React, { memo, useEffect, useMemo, useRef } from "react";
import { x } from "@xstyled/styled-components";
import {
  AudioPlayer,
  FocusedTranscriptPart,
  useInteractionDeliverablesContext,
} from "providers/InteractionDeliverableProvider";
import { Sentence, ViewType } from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import { Avatar, Icon, Loading, Typography } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { toMinutesAndSeconds } from "helpers/displayHelpers";
import _ from "lodash";
import { GenericMessagePage } from "../GenericMessagePage";
import { Summary } from "views/DeliverablesView/transcripts/Summary";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { useStyles } from "../NewDeliverablesPage.styles";
import { focusOnElementText, highlightRange } from "utils/frontendElementHighlight";
import { HitAction } from "@alphasights/portal-api-client";
import { useHideDeliverablesContent } from "../useHideDeliverablesContent";

export const TranscriptTab = () => {
  const { contentStyle } = useHideDeliverablesContent();
  const {
    interaction,
    currentTranscript,
    sentences,
    isLoading,
    keywordSearchTargetEl,
    selectedView,
    audioPlayer,
    transcriptIndexToFocus,
    hasTranscripts,
    focusOnTranscriptIndex,
  } = useInteractionDeliverablesContext()!;

  if (!currentTranscript) {
    return hasTranscripts ? (
      <GenericMessagePage title={"Processing..."} message={"Transcript is being produced. Please try again later."} />
    ) : (
      <GenericMessagePage title={"No Transcript"} message={"No transcript was requested for this interaction."} />
    );
  }

  if (isLoading) {
    return (
      <GenericMessagePage
        title={"Transcript view is loading"}
        message={"Loading data..."}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  if (!sentences.length) {
    return <GenericMessagePage title={"Error"} message={"Failed loading this transcript. Please try again later."} />;
  }

  return (
    <x.div {...contentStyle} data-testid="transcript-content">
      {currentTranscript.transcriptType === "summary" ? (
        // Summary product is sunsetting, so no refactoring it
        <Summary sentences={sentences} summaryRef={null} />
      ) : (
        <TranscriptRender
          sentences={sentences}
          keywordSearchTargetEl={keywordSearchTargetEl}
          selectedView={selectedView}
          audioPlayer={audioPlayer}
          transcriptIndexToFocus={transcriptIndexToFocus}
          focusOnTranscriptIndex={focusOnTranscriptIndex}
          interaction={interaction}
          currentTranscript={currentTranscript}
        />
      )}
    </x.div>
  );
};

const TranscriptRender = ({
  sentences,
  keywordSearchTargetEl,
  selectedView,
  audioPlayer,
  transcriptIndexToFocus,
  focusOnTranscriptIndex,
  interaction,
  currentTranscript,
}: {
  sentences: Sentence[];
  keywordSearchTargetEl: any;
  selectedView: ViewType;
  audioPlayer: AudioPlayer;
  transcriptIndexToFocus?: FocusedTranscriptPart;
  focusOnTranscriptIndex: any;
  interaction: Partial<Interaction>;
  currentTranscript: TranscriptRequest;
}) => {
  const orderedUniqueSpeakers = useMemo(() => _.uniq(_.map(sentences, "speaker")), [sentences]);
  const avatarColors = ["base", "base02", "base03", "base04", "base05", "base06"];
  const paragraphs = useMemo(
    () => (currentTranscript.transcriptType === "ai" ? groupSentencesBySpeaker(sentences) : sentences.map((s) => [s])),
    [currentTranscript, sentences]
  );
  const style = useStyles();

  const maxScrollReached = useRef(0);
  const { logHitDuration } = useInteractionDeliverablesContext()!;
  const openedAt = useRef(Date.now());
  useEffect(() => {
    if (selectedView !== ViewType.Transcript) return;
    openedAt.current = Date.now();
    const scrollableArea = document.querySelector("[data-transcript-scrollable-area]");

    const computeMaxScrollReached = () => {
      if (!scrollableArea) return;
      const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;
      maxScrollReached.current = _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]) ?? 0;
    };

    scrollableArea?.addEventListener("scroll", computeMaxScrollReached);

    window.addEventListener("beforeunload", () =>
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptRead)
    );

    return () => {
      window.removeEventListener("beforeunload", () =>
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptRead)
      );
      scrollableArea?.removeEventListener("scroll", computeMaxScrollReached);
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.transcriptRead);
    };
  }, [selectedView]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div
      data-testid="transcript-render"
      {...style.transcriptRender}
      ref={selectedView === ViewType.Transcript ? keywordSearchTargetEl : undefined}
    >
      {paragraphs.map((p) => (
        <Paragraph
          key={`paragraph-${p[0].startSeconds}`}
          sentences={p}
          color={avatarColors[orderedUniqueSpeakers.indexOf(p[0].speaker) % avatarColors.length]}
          audioPlayer={audioPlayer}
          focusOnTranscriptIndex={focusOnTranscriptIndex}
          transcriptIndexToFocus={transcriptIndexToFocus}
        />
      ))}
    </x.div>
  );
};

const SentenceCue = ({
  sentence,
  audioPlayer,
  focusOnTranscriptIndex,
  transcriptIndexToFocus,
}: {
  sentence: Sentence;
  audioPlayer: AudioPlayer;
  focusOnTranscriptIndex: any;
  transcriptIndexToFocus?: FocusedTranscriptPart;
}) => {
  const id = `cue-${sentence.position}`;
  const readAlong = useMemo(() => audioPlayer.visible && sentence.startSeconds, [sentence, audioPlayer.visible]);
  const onClick = readAlong ? () => audioPlayer.progress.onChange(sentence.startSeconds) : undefined;
  const isActive = useMemo(
    () => readAlong && _.inRange(audioPlayer.progress.value, sentence.startSeconds!, sentence.endSeconds!),
    [audioPlayer.progress.value, readAlong, sentence]
  );

  useEffect(
    function scrollToFocused() {
      if (
        (transcriptIndexToFocus?.transcriptIx ?? -1) >= 0 &&
        sentence.position === transcriptIndexToFocus?.transcriptIx
      ) {
        focusOnTranscriptIndex({});
        setTimeout(() => {
          if ((transcriptIndexToFocus.matchIxStart ?? -1) >= 0) {
            highlightRange(`#cue-${transcriptIndexToFocus.transcriptIx}`, {
              ixStart: transcriptIndexToFocus.matchIxStart!!,
              length: transcriptIndexToFocus.matchLength!!,
              tagOpen: `<span class="dynamic-highlight">`,
              tagClose: "</span>",
              elementsSelector: ".dynamic-highlight",
            });
          } else {
            focusOnElementText(`#cue-${transcriptIndexToFocus.transcriptIx}`);
          }
        }, 200);
      }
    },
    [transcriptIndexToFocus, sentence.position, sentence.value, focusOnTranscriptIndex, id]
  );

  const styles = useMemo(
    () =>
      readAlong
        ? {
            bg: {
              _: isActive ? "rgba(242, 208, 36, 0.2)" : undefined,
              hover: "#f6f7f9",
            },
            cursor: "pointer",
            "data-transcript-active": isActive || undefined,
          }
        : {},
    [isActive, readAlong]
  );

  return (
    <EmphasisTypography component={"span"} variant="body" {...styles} onClick={onClick} data-testid={id} id={id}>
      <>
        {/* Temporary approach: removing BE highlights and using FE highlights only */}
        {sentence.value.includes("<em>")
          ? sentence.value.replaceAll("<em>", "").replaceAll("</em>", "").trim()
          : sentence.value.trim()}{" "}
      </>
    </EmphasisTypography>
  );
};

const Paragraph = memo(
  ({
    color,
    sentences,
    audioPlayer,
    focusOnTranscriptIndex,
    transcriptIndexToFocus,
  }: {
    color: any;
    sentences: Sentence[];
    audioPlayer: AudioPlayer;
    focusOnTranscriptIndex: any;
    transcriptIndexToFocus?: FocusedTranscriptPart;
  }) => {
    const style = useStyles();
    if (!sentences.length) return null;

    const { speaker, startSeconds } = sentences[0];
    const showTimestamp = typeof startSeconds === "number" && audioPlayer.visible;
    return (
      <x.div {...style.transcriptParagraph}>
        <x.div>
          <Avatar size="small" color={color}>
            <Expert />
          </Avatar>
        </x.div>
        <x.div>
          <x.div {...style.transcriptParagraphSpeaker}>
            <Typography variant="body-large-em">{speaker.replace("Advisor", "Expert")}</Typography>
            {showTimestamp && (
              <Typography color="secondary" data-testid="cue-seconds">
                {toMinutesAndSeconds(startSeconds)}
              </Typography>
            )}
          </x.div>
          <x.div>
            {sentences.map((s) => (
              <SentenceCue
                key={`cue-${s.startSeconds}`}
                sentence={s}
                focusOnTranscriptIndex={focusOnTranscriptIndex}
                transcriptIndexToFocus={transcriptIndexToFocus}
                audioPlayer={audioPlayer}
              />
            ))}
          </x.div>
        </x.div>
      </x.div>
    );
  },
  (prev, cur) =>
    prev.sentences[0].position === cur.sentences[0].position &&
    prev.transcriptIndexToFocus?.transcriptIx === cur.transcriptIndexToFocus?.transcriptIx &&
    // if has no start, there's no readalong
    (!cur.sentences[0].start ||
      !prev.sentences[0].start ||
      (cur.audioPlayer.visible === prev.audioPlayer.visible &&
        prev.audioPlayer.progress.value === cur.audioPlayer.progress.value))
);

const groupSentencesBySpeaker = (sentences: Sentence[]) => {
  return sentences.reduce((paragraphs: Sentence[][], cur) => {
    const last = _.last(paragraphs) || [];
    const sameSpeaker = last[0] && last[0].speaker === cur.speaker;
    if (sameSpeaker) last.push(cur);
    else paragraphs.push([cur]);
    return paragraphs;
  }, []);
};
