import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetch } from "./useApi";

const useUserInteractions = ({ status = null, autoFetch = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [interactionsByProject, setInteractionsByProject] = useState([]);
  const isLoggedIn = useIsAuthenticated();

  const refetch = useCallback(() => {
    if (!isLoggedIn) return;

    setIsLoading(true);
    setIsError(false);
    let url = "/api/auth-user/interactions";

    if (status) url += `?status=${status}`;

    fetch({
      url,
    })
      .then((res) => res.json())
      .then((interactions) => {
        setInteractionsByProject(interactions);
      })
      .catch(() => {
        setInteractionsByProject([]);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [status, isLoggedIn]);

  useEffect(() => {
    if (!autoFetch) return;

    refetch();
  }, [status, autoFetch, refetch]);

  return useMemo(() => {
    return {
      isLoading,
      interactionsByProject,
      refetch,
      isError,
    };
  }, [isLoading, interactionsByProject, refetch, isError]);
};

export default useUserInteractions;
