import React, { Component, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import "./popover.css";
import { useHover } from "../../hooks/useHover";
import { Typography } from "@alphasights/alphadesign-components";
import { PortalTooltip } from "components/Tooltip/Tooltip";

export const Popover = ({
  children,
  body,
  placement,
  isActive,
  disableHover = false,
  disableArrow = false,
  borderless = false,
  className = "",
  textClassName = "",
  style = {},
}) => {
  const [referenceElement, isHovered] = useHover(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [0, 10] } },
      { name: "flip", options: { fallbackPlacements: [] } },
    ],
  });

  return (
    <>
      <span className={textClassName} ref={referenceElement}>
        {children}
      </span>
      {(isActive || (isHovered && !disableHover)) &&
        createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, margin: 0, ...style }}
            {...attributes.popper}
            className={`aui-popover aui-bg-white ${
              borderless ? "aui-popover-borderless" : ""
            } aui-shadow aui-px-4 aui-py-2 aui-relative aui-text-black aui-z-20 ${className}`}
            data-testid="popover-wrapper"
          >
            {!disableArrow && (
              <>
                <div className="aui-popover-arrow" ref={setArrowElement} style={styles.arrow} />
                <div className="aui-absolute aui-inset-0 aui-bg-white" style={{ zIndex: -1 }} />
              </>
            )}
            {body}
          </div>,
          document.getElementById("portal-root")
        )}
    </>
  );
};

export class WrappingPopover extends Component {
  constructor(props) {
    super(props);
    this.state = { textWrapped: false };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      const cur = this.wrapperRef.current;
      if (cur) {
        const textWrapped = Math.abs(cur.scrollWidth - cur.offsetWidth) > 2;
        if (textWrapped !== this.state.textWrapped) {
          this.setState({ textWrapped });
        }
      }
    }, 200);
  }

  render() {
    return (
      <div className="aui-overflow-hidden" data-testid={this.props["data-testid"]}>
        <PortalTooltip title={this.state.textWrapped ? this.props.text : ""} position="bottom">
          <div
            ref={this.wrapperRef}
            className={`aui-overflow-hidden ${this.state.className} ${this.props.className}`}
            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          >
            {this.props.children || (
              <Typography variant="body-small" component="span">
                {this.props.text}
              </Typography>
            )}
          </div>
        </PortalTooltip>
      </div>
    );
  }
}

export const SimplePopover = ({ text, children, disableHover, textClassName = "", className = "" }) => {
  const simpleBody = (
    <Typography
      variant="body-small"
      component="div"
      className="aui-text-grey-5 aui-whitespace-nowrap aui-text-center"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
  return (
    <Popover body={simpleBody} disableHover={disableHover} textClassName={textClassName} className={className}>
      {children}
    </Popover>
  );
};
