import React from "react";
import { PortalTooltip } from "../../Tooltip/Tooltip";
import { Button } from "@alphasights/alphadesign-components";
import { HideAlt, ShowAlt } from "@alphasights/alphadesign-icons";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { ENABLE_PORTAL_COMMENTS, useProjectBadgeContext } from "providers/BadgeProvider";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";

const HideExpertCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  const { hasProjectBadge } = useProjectBadgeContext();
  const currentUser = useCurrentUser();
  const { setHideRef, onToggleHiddenWithMessage } = useHideExpertContext()!;

  const enablePortalComments = hasProjectBadge(ENABLE_PORTAL_COMMENTS);

  let tooltip = interaction.hidden
    ? "Show expert"
    : // @ts-ignore
    currentUser && enablePortalComments
    ? "Hide expert with reason"
    : "Hide expert";

  return (
    <div
      ref={(ref) => setHideRef(interaction.id, ref, "workstreams-table")}
      data-testid={`survey-hide-expert-column-${interaction.id}`}
    >
      {/*@ts-ignore*/}
      <PortalTooltip title={tooltip} dark>
        <Button
          variant="icon"
          h="20px"
          data-testid={`survey-hide-expert-button-${interaction.id}`}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onToggleHiddenWithMessage(interaction, HitOrigin.workstreamView, "workstreams-table");
          }}
        >
          {interaction.hidden ? <ShowAlt /> : <HideAlt />}
        </Button>
      </PortalTooltip>
    </div>
  );
};

export const HideExpertColumn = {
  id: "HideExpertColumn",
  accessorKey: "",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <HideExpertCell props={props} />,
  header: "",
  minSize: 25,
  maxSize: 50,
};
