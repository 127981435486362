import { useThemeTokens } from "@alphasights/alphadesign-components";

const useDeliverablesFiltersStyles = ({ newNavigationEnabled = false } = {}) => {
  const {
    color: { background, border, text },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  return {
    filters: {
      display: "flex",
      flexWrap: newNavigationEnabled ? "nowrap" : "wrap",
    },
    noHoverNoClick: {
      pointerEvents: "none",
      color: {
        hover: null,
      },
    },
    bookmarkFilter: {
      h: "100%",
    },
    filtersRight: {
      display: "flex",
      marginLeft: "auto",
      alignItems: "center",
    },
    filtersSortBy: {
      ...(newNavigationEnabled
        ? {
            h: inner.base08,
            ml: inner.base02,
          }
        : {
            display: "flex",
            pl: "8px",
            cursor: "pointer",
            color: {
              hover: text.strong.hover,
            },
          }),
    },
    filtersSortBySelected: {
      ...(newNavigationEnabled
        ? {
            bg: background.action.outline.pressed,
          }
        : {
            color: {
              _: text.danger,
            },
          }),
    },
    booleanFilter: {
      px: inner.base,
    },
    buttonsWrapper: {
      display: "flex",
      borderTopWidth: shape.border.width.sm,
      borderTopStyle: "solid",
      borderTopColor: border.divider,
      px: inner.base04,
      py: inner.base03,
      alignItems: "center",
      justifyContent: "space-between",
    },
  };
};

export default useDeliverablesFiltersStyles;
