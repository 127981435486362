import React, { useMemo } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { ConditionalTooltip } from "pages/AlphaNowPage/primers/components";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

type CompetitorPillProps = {
  iconUrl: string;
  label: string;
  isSelected?: boolean;
  isGrayscale?: boolean;
  isLeading?: boolean;
  variant?: string;
  nameMaxLength?: number;
  onClick?: () => void;
};

const CompetitorPill = ({
  iconUrl,
  label,
  isLeading = false,
  isSelected = false,
  isGrayscale = false,
  variant = "light",
  nameMaxLength = Number.MAX_VALUE,
  onClick,
}: CompetitorPillProps) => {
  const { color, spacing, typography, shape } = useThemeTokens();

  const getBGColor = useMemo(() => {
    switch (variant) {
      case "blue":
        return color.background.info;
      default:
        return color.background.surface.page.default;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  const [labelTruncated, tooltip] = getTruncatedTextAndTooltip(label, nameMaxLength);

  return (
    <x.div
      display="inline-flex"
      borderRadius={spacing.inner.base04}
      border={{
        _: `${shape.border.width.sm} solid ${isSelected ? color.border.selected : color.border.divider}`,
        hover: `${shape.border.width.sm} solid ${color.border.neutral.hover}`,
      }}
      p={isLeading ? spacing.inner.base04 : spacing.inner.base02}
      backgroundColor={getBGColor}
      alignItems="center"
      cursor={!onClick ? "default" : "pointer"}
      onClick={!onClick ? () => {} : onClick}
      data-testid={`leadingCompetitor${label}`}
    >
      <x.div
        borderRadius={spacing.inner.base02}
        border={`${shape.border.width.sm} solid ${color.border.divider}`}
        w="32px"
        h="32px"
        display="flex"
        backgroundColor={color.background.surface.page.default}
      >
        <x.div
          flex="1 1 auto"
          margin={spacing.inner.base}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundImage={`url(${iconUrl})`}
          style={{
            filter: isGrayscale ? "grayscale(100%)" : "none",
            opacity: isGrayscale ? "0.3" : "1",
          }}
        ></x.div>
      </x.div>
      <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
        <Typography
          {...typography.body.largeEm}
          marginLeft={spacing.inner.base}
          className="aui-inline"
          color={isGrayscale ? color.text.disabled : color.icon.strong._}
          display="inline-flex"
        >
          {labelTruncated as string}
        </Typography>
      </ConditionalTooltip>
    </x.div>
  );
};

export default CompetitorPill;
