import React, { useRef } from "react";
import { x } from "@xstyled/styled-components";
import { CompanyLogo } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import { Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Spacer, WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import { OverviewDataType } from "pages/AlphaNowPage/components/AlphaNowCompanyPage//AlphaNowCompanyOverview/types";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { CompanyDetailsContainer, HeaderContainer } from "./OverviewHeader.styled";
import { Variant } from "pages/AlphaNowPage/components/WatchlistQuickAddButton";
import { Market, Markets, SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { AlphaNowSearchQuery } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { buildSearchQuery, getMarketsFromSearchQuery } from "content/AlphaNow/utils";

const DataTestIds = {
  alphaNowCompanyOverviewHeader: "company-page-header",
};

const OverViewHeader = ({ data, isDisabled }: { data: OverviewDataType; isDisabled: boolean }) => {
  const {
    color: { text },
    spacing: { layout, inner },
  } = useThemeTokens();

  const {
    updateQuery,
    query: { searchQuery },
  } = useAppSearchContext();

  const { logo, name, headquarters, url, id, market } = data;

  const headquartersDisplayText = headquarters
    ?.split(", ")
    .filter((value) => value !== "null")
    .join(", ");

  // TODO [RD1-133]: Remove Markets Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasMarketsBadge = hasUserBadge(Badge.markets);
  const displayMarkets = hasMarketsBadge && market.length > 0;

  const containerRef = useRef<HTMLSpanElement | null>(null) as any;
  const searchMarketIds = (getMarketsFromSearchQuery(searchQuery) as AlphaNowSearchQuery[]).map(({ id }) => id);

  const handleSelectMarketPill = (market: Market) => {
    const newSearchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Market,
      value: market.name,
      id: market.id,
    });
    updateQuery({
      selectedContentId: undefined,
      searchQuery: newSearchQuery,
    });
  };

  return (
    <HeaderContainer isDisabled={isDisabled}>
      <CompanyDetailsContainer data-testid={DataTestIds.alphaNowCompanyOverviewHeader}>
        <CompanyLogo logo={logo} companyName={name} />
        <x.div paddingLeft={layout.base03} flexGrow={1}>
          <Typography variant="body-small-em" color={text.assistive} pb={inner.base}>
            Company Page
          </Typography>
          <Typography variant="h3" style={{ textTransform: "capitalize" }} pb={inner.base}>
            {name}
          </Typography>
          <x.div display="flex" onClick={(event) => event.stopPropagation()}>
            <Typography variant="body" component="span" whiteSpace="nowrap">
              {headquartersDisplayText}
            </Typography>
            {url && headquartersDisplayText && <Spacer />}
            <Typography variant="body" component="span">
              {url && <Link href={url}>Website</Link>}
            </Typography>
            {displayMarkets && (
              <x.div display="flex" flexDirection="row" flexGrow={1} ref={containerRef}>
                <Spacer />
                <Markets
                  markets={market}
                  searchMarketIds={searchMarketIds}
                  //@ts-ignore
                  onSelect={handleSelectMarketPill}
                  isInteractive
                  containerRef={containerRef}
                />
              </x.div>
            )}
          </x.div>
        </x.div>
      </CompanyDetailsContainer>
      <WatchlistQuickAddButton variant={Variant.Complex} cdsAlphaCompanyIds={id ? [id] : []} />
    </HeaderContainer>
  );
};

export { OverViewHeader as default, DataTestIds };
