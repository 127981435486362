import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const StyledSeeAllContentCard = styled.div(() => {
  const { color, shape, spacing } = useThemeTokens();
  return {
    width: "128px",
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    borderRadius: shape.border.radius.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: spacing.inner.base02,
    height: "100%",
    "&:hover": {
      backgroundColor: `${color.background.action.outline.hover} !important`,
      cursor: "pointer",
    },
  };
});

export { StyledSeeAllContentCard };
