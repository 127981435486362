import { InboxResponse } from "types";
import { fetch } from "../hooks/useApi";

export const complianceReviewService = ({ projectToken }: { projectToken: string }) => {
  const baseUrl = `/api/projects/${projectToken}/messages/compliance-review`;

  const acceptSuggestion = ({
    contentId,
    versionId,
  }: {
    contentId: number;
    versionId: number;
  }): Promise<InboxResponse[]> => {
    const url = `${baseUrl}/accept`;

    return fetch({
      url,
      handleForbidden: true,
      method: "POST",
      body: JSON.stringify({ contentId, versionId }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };

  const declineSuggestion = ({
    contentId,
    versionId,
  }: {
    contentId: number;
    versionId: number;
  }): Promise<InboxResponse[]> => {
    const url = `${baseUrl}/decline`;

    return fetch({
      url,
      handleForbidden: true,
      method: "POST",
      body: JSON.stringify({ contentId, versionId }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };

  return {
    acceptSuggestion,
    declineSuggestion,
  };
};
