import styled from "styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const SubtitleContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base04,
    paddingTop: spacing.inner.base03,
  };
});

const StyledCardDescription = styled(Typography)(() => {
  const { spacing } = useThemeTokens();
  return {
    height: spacing.layout.base06,
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical" as "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: spacing.layout.base06,
  };
});

export { SubtitleContainer, StyledCardDescription };
