import React from "react";
import { Loading } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const DataTestIds = {
  LoadingSpinner: "loading-spinner",
};

const LoadingSpinner = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null;

  return (
    <SpinnerContainer data-testid={DataTestIds.LoadingSpinner}>
      <Loading size="sm" />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 10;
`;

export { LoadingSpinner as default, DataTestIds };
