import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCidStatusRadioButtonsStyles = () => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  const radioGroup = {
    display: "flex",
    gap: inner.base05,
    marginBottom: "-8px",
    flex: 1,
  };

  const radioGroupDiv = {
    gap: inner.base04,
    marginTop: inner.base02,
    display: "flex",
    alignItems: "center",
    flex: 1,
  };

  const approvedLabel = {
    display: "flex",
    color: color.text.secondary,
    gap: inner.base,
  };

  const radioButton = {
    marginLeft: "0",
  };

  const cidControls = {
    display: "flex",
  };

  return {
    radioGroup,
    radioGroupDiv,
    approvedLabel,
    radioButton,
    cidControls,
  };
};
