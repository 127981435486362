import React, { forwardRef, useContext, useLayoutEffect, useRef, useState } from "react";
import { Button, Icon, ListOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Advisor, CalendarAvailable, Close, MoreVert } from "@alphasights/alphadesign-icons";
import { getStateColorFlyout, getStateName } from "components/AdvisorCard/stateFunctions";
import { AttendeesList, AttendeesSelect, LoadingBar } from "../AttendeesSection";
import { setSelectedAttendees, updateInitialAttendees } from "../reducer";
import { CalendarContext } from "../CalendarContext";
import { addSeconds } from "date-fns";
import { CurrentTimezone, FormattedDateTime, FormattedTimeRange, parseISO } from "providers/TimezoneProvider";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";
import useOnClickOutside from "hooks/useOnClickHooks";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";

export const CalendarPopup = forwardRef(({ children, ...props }, ref) => {
  const { spacing } = useThemeTokens();

  const styles = {
    p: spacing.inner.base06,
    w: "360px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  };

  return (
    <x.div ref={ref} {...styles} {...props}>
      {children}
    </x.div>
  );
});

export const PopupMenus = ({ showMenu = false, items, onItemClick, onClose }) => {
  const anchorEl = useRef();
  const {
    spacing: { inner },
  } = useThemeTokens();
  const [popoverOpen, setPopoverOpen] = React.useState(null);
  const [popoverRef, setPopoverRef] = React.useState();

  const onMenuOpen = () => {
    setPopoverOpen(!popoverOpen);
  };

  const { styles, attributes } = usePopper(anchorEl.current, popoverRef, {
    placement: "bottom-end",
  });

  useOnClickOutside({ current: popoverRef }, () => setPopoverOpen(false));

  return (
    <x.div ml="auto" display="flex">
      {showMenu && (
        <>
          <x.div ref={anchorEl}>
            <Icon
              onClick={onMenuOpen}
              color="secondary"
              size="small"
              active={popoverOpen}
              data-testid="popup-menu-handle"
            >
              <MoreVert />
            </Icon>
          </x.div>
          {popoverOpen &&
            createPortal(
              <x.div
                id="popup-menu"
                ref={setPopoverRef}
                style={{ ...styles.popper, zIndex: 200 }}
                {...attributes.popper}
                backgroundColor="white"
                padding={inner.base04}
                borderRadius={inner.base02}
                boxShadow="0px 1px 5px rgba(34, 34, 34, 0.2)"
              >
                {items.map((item, ix) => (
                  <div key={item}>
                    <ListOption
                      type="text"
                      label={item}
                      onChange={() => {
                        setPopoverOpen(false);
                        onItemClick(item);
                      }}
                    ></ListOption>
                  </div>
                ))}
              </x.div>,
              document.querySelector("body")
            )}
        </>
      )}
      <x.div ml={inner.base02}>
        <Icon onClick={onClose} color="secondary" size="small" data-testid="popup-close">
          <Close />
        </Icon>
      </x.div>
    </x.div>
  );
};

const iconSize = { name: "small", size: "16px" };

export const PopupDataAreaLeftPad = ({ children, marginTop }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div display="flex" mt={marginTop ? inner[marginTop] : undefined}>
      <x.div w={iconSize.size} mr={inner.base04} />
      <x.div flex="1">{children}</x.div>
    </x.div>
  );
};

export const PopupDataArea = ({ icon, firstLine, children, addMarginTop = true, ...props }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" mt={addMarginTop ? inner.base04 : null} {...props}>
      <x.div display="flex" alignItems="center">
        <Icon size={iconSize.name} color="secondary">
          {icon}
        </Icon>
        <x.div flex="1" ml={inner.base04}>
          {firstLine}
        </x.div>
      </x.div>
      <PopupDataAreaLeftPad children={children} />
    </x.div>
  );
};

export const ExpertData = ({ interaction, onOpenFlyout, requestPending }) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return (
    <PopupDataArea
      icon={<Advisor />}
      firstLine={
        <x.div onClick={onOpenFlyout} cursor={onOpenFlyout ? "pointer" : null}>
          <TooltipStateDetail interaction={interaction} pendingRequest={requestPending}>
            <Typography
              variant="body-em"
              color={getStateColorFlyout(interaction.state, color)}
              mr={inner.base}
              textTransform="capitalize"
              component="span"
              data-testid="interaction-state"
            >
              {getStateName({
                ...interaction,
                showCalendarView: true,
                pendingRequest: requestPending,
              })}
            </Typography>
          </TooltipStateDetail>
          {" • "}
          <Typography variant="body-em" color={color.text.info} ml={inner.base} component="span">
            {interaction.advisorName}
          </Typography>
        </x.div>
      }
    >
      <div onClick={onOpenFlyout} cursor={onOpenFlyout ? "pointer" : null}>
        <Typography variant="body-large-em" color="strong">
          {interaction.companyName} - {interaction.role}
        </Typography>
        <Typography variant="body-small" color="secondary">
          {interaction.angles[0].title}
        </Typography>
      </div>
    </PopupDataArea>
  );
};

export const SelectAttendees = ({ interaction, onSave }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { state, dispatch: dispatchCalendar } = useContext(CalendarContext);
  const [hasChanges, setHasChanges] = useState(false);

  const initialAttendees = state.popup.attendees.initial || [];
  const currentAttendees = state.popup.attendees.selected || [];

  const setCurrentAttendees = (val) => dispatchCalendar(setSelectedAttendees(val));

  const removeAttendee = (attendee) => () => setCurrentAttendees(currentAttendees.filter((att) => att !== attendee));

  const toggleOptionalAttendee = (attendee) => () =>
    setCurrentAttendees(
      currentAttendees.map((att) => (att !== attendee ? att : { ...att, optional: !attendee.optional }))
    );

  useLayoutEffect(() => {
    const initial = initialAttendees.map(JSON.stringify);
    const current = currentAttendees.map(JSON.stringify);

    const hasChanges = current.length !== initial.length || current.find((attendees) => !initial.includes(attendees));

    setHasChanges(hasChanges);
  }, [initialAttendees, currentAttendees]);

  const saveAndResetInitialAttendeesState = () => {
    onSave({ id: interaction.id, attendees: state.popup.attendees.selected });
    dispatchCalendar(updateInitialAttendees(state.popup.attendees.selected));
  };

  if (!interaction.autoBookEnabled) return null;

  return (
    <PopupDataArea
      icon={<Advisor />}
      data-testid="attendees-list-select"
      firstLine={
        state.popup.attendees.isLoading ? (
          <LoadingBar />
        ) : (
          <AttendeesSelect
            options={state.popup.attendees.options}
            currentAttendees={currentAttendees}
            setCurrentAttendees={setCurrentAttendees}
          />
        )
      }
    >
      {!state.popup.attendees.isLoading && (
        <x.div pt={inner.base02}>
          <AttendeesList
            attendees={currentAttendees}
            removeAttendee={removeAttendee}
            toggleOptionalAttendee={toggleOptionalAttendee}
          />
        </x.div>
      )}

      {hasChanges && onSave && (
        <Button
          variant="primary"
          data-testid="save-attendees-button"
          onClick={saveAndResetInitialAttendeesState}
          mt={inner.base04}
        >
          Save
        </Button>
      )}
    </PopupDataArea>
  );
};

export const ScheduledCallTime = ({ date, duration, addMarginTop = true, icon }) => {
  const asDate = parseISO(date);

  return (
    <PopupDataArea
      addMarginTop={addMarginTop}
      icon={icon || <CalendarAvailable />}
      firstLine={
        <Typography variant="body" color="strong">
          <FormattedDateTime date={asDate} format="iiii d MMM, " />
          <FormattedTimeRange start={asDate} end={addSeconds(asDate, duration || 3600)} />

          <CurrentTimezone formatOnly format=" (O)" />
        </Typography>
      }
    />
  );
};
