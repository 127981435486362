import React, { FC, useRef, useState, useLayoutEffect } from "react";
import {
  Icon,
  IconButton,
  IconButtonSizes,
  IconButtonVariant,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { InsertLink } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import Card from "components/Card";
import ExpiringComponent from "components/ExpiringComponent";
import useOnClickOutside from "hooks/useOnClickHooks";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { logCopyLink } from "pages/AlphaNowPage/utils/logHit";
import ActionBarMenuItem from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActionBar/ActionBarMenuItem/ActionBarMenuItem";
import { getQueryString, createURLFromParams } from "utils/query-string";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const DataTestIds = {
  pageLinkButton: "page-link-button",
  mobilePageLinkButton: "mobile-page-link-button",
};

const POPUP_CARD_ID = "link-copied-card";

interface CopyLinkButtonProps {
  contentId: string;
  contentType: AlphaNowContentType | AlphaNowProductType;
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
  asListOption?: boolean;
  onCloseMobilePopover?: () => void;
}

const CopyLinkButton: FC<CopyLinkButtonProps> = ({
  contentId,
  contentType,
  variant = "basic",
  size = "medium",
  asListOption,
  onCloseMobilePopover = () => {},
}) => {
  const currentUser = useCurrentUser();

  // @ts-ignore
  const userId = currentUser?.id ? String(currentUser?.id) : "";
  const { logHit } = useTrackUserAction();
  const {
    spacing: { inner },
    shape: { border },
    color: { icon },
  } = useThemeTokens();

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    const card = document.getElementById(POPUP_CARD_ID);
    if (card && isOpen) {
      const rectLeft = card?.getBoundingClientRect()?.left ?? 0;
      if (rectLeft < 0) {
        card.style.right = "unset";
        card.style.left = "0px";
      } else {
        card.style.right = "0px";
        card.style.left = "unset";
      }
    }
  }, [isOpen]);

  useOnClickOutside(ref, () => setIsOpen(false));

  const onCopyClick = () => {
    const selectedContentId = getQueryString()?.selectedContentId;
    const url = createURLFromParams({ selectedContentId });
    navigator.clipboard.writeText(url).then(() => setIsOpen(true));
    logCopyLink(
      {
        contentId,
        userId,
        // @ts-ignore
        ...(currentUser?.internalUser && {
          // @ts-ignore
          internalUser: currentUser?.internalUser,
        }),
      },
      logHit,
      contentType
    );
  };

  const CopyLinkButtonWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <x.div display="flex" alignItems="center" position="relative" ref={ref}>
        {children}
      </x.div>
    );
  };

  return (
    <div>
      {asListOption ? (
        <ActionBarMenuItem
          label="Copy link"
          onClick={onCopyClick}
          icon={<InsertLink />}
          dataTestId={DataTestIds.mobilePageLinkButton}
        />
      ) : (
        <CopyLinkButtonWrapper>
          <IconButton onClick={onCopyClick} color={icon.secondary} size={size} variant={variant}>
            <InsertLink data-testid={DataTestIds.pageLinkButton} />
          </IconButton>
        </CopyLinkButtonWrapper>
      )}
      {isOpen && (
        <CopyLinkButtonWrapper>
          <ExpiringComponent
            delay={3000}
            onExpire={() => {
              setIsOpen(false);
              onCloseMobilePopover();
            }}
          >
            <Card
              id={POPUP_CARD_ID}
              position="absolute"
              right="0"
              mt={inner.base}
              p={inner.base03}
              borderWidth={border.width.small}
              boxShadow="dark"
              zIndex="20"
            >
              <Typography component="span" color={icon.strong._} variant="body-em">
                <x.div display="flex" alignItems="center" px={inner.base02} py={inner.base} whiteSpace="nowrap">
                  <x.div position="relative" top={-1} display="flex" alignItems="center" mr="10px">
                    <Icon size="medium">
                      <InsertLink />
                    </Icon>
                  </x.div>
                  <x.div pb={border.width.large}>Link copied</x.div>
                </x.div>
              </Typography>
            </Card>
          </ExpiringComponent>
        </CopyLinkButtonWrapper>
      )}
    </div>
  );
};

export default CopyLinkButton;
