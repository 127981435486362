import React from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, TextField } from "@alphasights/alphadesign-components";
import { SearchAndFilterSkeleton } from "components/SearchAndFilterSkeleton";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";
import { ArrowLeft } from "@alphasights/alphadesign-icons";

export type ProjectCalendarViewHeaderProps = {
  filterComponent: React.ReactNode;
  loading: boolean;
  onSearch: (searchQuery?: FormDataEntryValue) => void;
  onSearchQueryChange: (searchQuery: string) => void;
  searchQuery: string;
  isCollapsed?: boolean;
  onClickToExpand: () => void;
};

export const ProjectCalendarViewHeader = ({
  filterComponent,
  loading,
  onSearch,
  onSearchQueryChange,
  searchQuery,
  isCollapsed,
  onClickToExpand,
}: ProjectCalendarViewHeaderProps) => {
  const styles = useProjectCalendarViewHeaderStyles();
  const newNavigationEnabled = useNewNavigation();
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(new FormData(event.target as HTMLFormElement).get("query")!);
  };
  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onSearchQueryChange("");
    onSearch("");
  };

  if (!newNavigationEnabled && loading) {
    return <SearchAndFilterSkeleton />;
  }

  return newNavigationEnabled ? (
    <HeaderPortal>
      <x.div {...styles.wrapper}>
        {isCollapsed ? (
          <IconButton variant="outline" onClick={onClickToExpand} size="small" testId="calendar-header-expand">
            <ArrowLeft />
          </IconButton>
        ) : (
          <x.div {...styles.togglerAndSearch}>
            <CollapsibleSearch
              onSubmit={onSubmit}
              onValueChange={onSearchQueryChange}
              onClear={onClear}
              value={searchQuery}
              name="query"
            />
            {filterComponent}
          </x.div>
        )}
        <div id="calendar-controls-header-placeholder" data-testid="calendar-controls-header-placeholder" />
      </x.div>
    </HeaderPortal>
  ) : (
    <x.div {...styles.header}>
      <x.div flexGrow={1}>
        <x.div {...styles.togglerAndSearch}>
          <x.form data-testid="search-form" onSubmit={onSubmit} flexGrow={1}>
            <TextField
              width="100%"
              name="query"
              onChange={(e) => onSearchQueryChange(e.target.value)}
              placeholder="Search"
              value={searchQuery}
              autoComplete="off"
            />
          </x.form>
        </x.div>
        <x.div {...styles.row}>
          <x.div flexGrow={1}>{filterComponent}</x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};

const useProjectCalendarViewHeaderStyles = () => {
  const {
    spacing: { layout, inner },
  } = useThemeTokens();

  const header = {
    maxW: 1024,
    mx: "auto",
    display: "flex",
    w: "100%",
  };

  const togglerAndSearch = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const row = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    py: layout.base03,
    gap: layout.base02,
  };

  const wrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    px: inner.base06,
    py: inner.base04,
  };

  return {
    header,
    togglerAndSearch,
    row,
    wrapper,
  };
};
