import React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { SectionWithTopics } from "pages/AlphaNowPage/primers/CustomerPrimer/components";

type CompetitivePerformanceKPCsTabProps = {
  vendor: VendorKPCPerformance;
};

const CompetitivePerformanceKPCsTab = ({ vendor }: CompetitivePerformanceKPCsTabProps) => {
  const { spacing } = useThemeTokens();
  const { summary, kpcs } = vendor;

  return <SectionWithTopics sectionTitle="" categories={kpcs} summary={summary} mt={spacing.layout.base03} />;
};

export default CompetitivePerformanceKPCsTab;
