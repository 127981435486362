import React, { useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Select, Option, Icon, Checkbox, Typography, useThemeTokens, Link } from "@alphasights/alphadesign-components";
import { Add, Close } from "@alphasights/alphadesign-icons";
import { Label } from "components/Label";

const RightAccessory = ({ selected, onClose }) => {
  const { spacing } = useThemeTokens();
  const { icon, style } = selected
    ? {
        icon: <Close />,
        style: { paddingBottom: spacing.inner.base02, pointerEvents: "auto" },
      }
    : { icon: <Add />, style: {} };

  return (
    <x.div style={style}>
      <Icon
        style={{ backgroundColor: "none" }}
        color="secondary"
        onClick={() => {
          onClose();
        }}
      >
        {icon}
      </Icon>
    </x.div>
  );
};

const Item = ({ item, onClose }) => {
  const { color, spacing, shape } = useThemeTokens();
  return (
    <x.div
      cursor="default"
      display="inline-flex"
      backgroundColor={color.background.info}
      pt={spacing.inner.base}
      pb={spacing.inner.base}
      pl={spacing.inner.base02}
      pr={spacing.inner.base02}
      gap={spacing.inner.base}
      borderRadius={shape.border.radius.medium}
    >
      <Typography color="strong">{item.label}</Typography>
      <Icon
        color="secondary"
        onClick={(event) => {
          onClose(item.value);
          event.stopPropagation();
        }}
      >
        <Close />
      </Icon>
    </x.div>
  );
};

export const MultipleSelect = ({
  label = "Item(s)",
  helperText = "",
  placeholder = "Select items...",
  noResultsMessage = "No items found",
  error = null,
  disabled = false,
  required = false,
  items = [],
  value = null,
  onChange = (selectedValues) => {},
  externalLinkLabel = "",
  onClickExternalLink = () => {},
  open = false,
  renderSelected: renderSelectedInput = null,
  size = "medium",
}) => {
  const { color, spacing, shape } = useThemeTokens();

  const [selectedItems, setSelectedItems] = useState(value);

  const hasSelected = () => {
    return Array.isArray(selectedItems) && selectedItems.length > 0;
  };

  const isSelected = (value, disabled = false) => {
    return Array.isArray(selectedItems) && selectedItems.includes(value) && !disabled;
  };

  const removeAllHandler = () => {
    setSelectedItems(null);
    onChange(null);
  };

  const removeHandler = (value) => {
    const filtered = selectedItems.filter((v) => String(v) !== String(value));
    const newSelected = filtered.length > 0 ? filtered : null;
    setSelectedItems(newSelected);
    onChange(newSelected);
  };

  const addHandler = (value) => {
    const newSelected = Array.isArray(selectedItems) ? selectedItems.concat([value]) : [value];
    setSelectedItems(newSelected);
    onChange(newSelected);
  };

  const selectHandler = (value) => {
    const handler = isSelected(value) ? removeHandler : addHandler;
    handler(value);
  };

  const renderSelected = (selectedItems) => {
    const hideSelectedItems = items
      .filter((item) => item.hideSelected)
      .reduce((map, obj) => {
        map[obj.key] = obj;
        return map;
      }, {});
    return selectedItems
      .filter((item) => !hideSelectedItems[item.value])
      .map((item, index) => (
        <x.div pr={spacing.inner.base02} pb={spacing.inner.base02} data-testid="selected-item" key={index}>
          <Item item={renderSelectedInput ? renderSelectedInput(item) : item} onClose={removeHandler} />
        </x.div>
      ));
  };

  const matchQuery = (item, query) => {
    const cleanQuery = query.toLowerCase();
    const expertName = item.label.props.expert.name.toLowerCase();
    return expertName.startsWith(cleanQuery);
  };

  const style = {
    borderColor: error ? color.border.danger : color.border.neutral.default,
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const optionStyles = {
    overflow: "hidden",
  };

  if (hasSelected()) {
    style.paddingBottom = shape.border.width.none;
  }

  React.useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  return (
    <x.div data-testid="multiple-select">
      <StyledSelect
        input
        allowMultiple
        style={style}
        error={error}
        disabled={disabled}
        value={selectedItems}
        placeholder={placeholder}
        noResultsMessage={noResultsMessage}
        label={
          <x.div {...labelStyles}>
            <Label text={label} required={required} />
            {externalLinkLabel && <Link onClick={onClickExternalLink}>{externalLinkLabel}</Link>}
          </x.div>
        }
        renderSelectedValue={renderSelected}
        allowRightAccessoryRotation={false}
        rightAccessory={<RightAccessory selected={hasSelected()} onClose={removeAllHandler} />}
        data-testid="select"
        helperText={helperText}
        open={open}
        wrapItems={false}
        size={size}
        matchFunction={matchQuery}
      >
        {items.map((item) => (
          <x.div key={item.key} pt={spacing.inner.base} pb={spacing.inner.base} pl={spacing.inner.base02}>
            <Option
              disabled={item.disabled}
              cursor="default"
              key={item.key}
              value={item.key}
              label={item.label}
              className="option"
              backgroundColor={item.disabled ? color.background.disabled : color.background.none}
              onClick={(event) => {
                event.preventDefault();
                if (!item.disabled) {
                  selectHandler(item.key);
                }
              }}
              style={optionStyles}
              data-testid="select-option"
            >
              <Checkbox
                disabled={item.disabled}
                checked={isSelected(item.key, item.disabled)}
                onChange={() => selectHandler(item.key)}
              >
                <x.div display="flex" gap={spacing.inner.base02}>
                  <Typography color="strong" pl={spacing.inner.base}>
                    {item.label}
                  </Typography>
                </x.div>
              </Checkbox>
            </Option>
          </x.div>
        ))}
      </StyledSelect>
    </x.div>
  );
};

const StyledSelect = styled(Select)`
  input {
    max-height: 22px;
  }
`;
