import React from "react";
import { Modal } from "components/Modal";
import { SecondaryButton } from "components/Button";

export const MessageCantBeSent = ({ isOpen, setIsOpen, selectedExperts, setSelectedExperts }) => {
  const incompletedExperts = selectedExperts.filter((expert) => !expert.hasCompletedOrScheduled);
  const thereIsValidExperts = incompletedExperts.length < selectedExperts.length;

  const onRemoveExperts = () => {
    if (setSelectedExperts) {
      setSelectedExperts(selectedExperts.filter((expert) => expert.hasCompletedOrScheduled));
    }
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Message cannot be sent"}
      className="aui-mx-auto md:aui-w-5/6 aui-max-w-6xl aui-flex aui-flex-col aui-p-5"
      headerClassName="aui-text-grey-5 aui-border-transparent"
      showCloseIcon={true}
      closeOnOutsideClick
    >
      <div className="aui-p-4 aui-bg-white aui-flex aui-flex-col aui-flex-1 ">
        <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-my-2">
          You cannot send follow-up messages to experts who have not yet completed an interaction.
        </div>
        {thereIsValidExperts && (
          <>
            <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-my-1">
              {`To proceed with sending this message, please remove the expert${
                incompletedExperts.length > 1 ? "s" : ""
              } without complete interactions from the selection:`}
              <span className="aui-text-grey-5"> {incompletedExperts.map((expert) => expert.label).join(", ")}</span>
            </div>
            <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-mt-2">
              <SecondaryButton onClick={onRemoveExperts}>
                {`Remove expert${incompletedExperts.length > 1 ? "s" : ""}`}
              </SecondaryButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
