import React from "react";
import { Alert, Button, Icon, IconButton, Typography } from "@alphasights/alphadesign-components";
import { AudioRecording, Call, MicOff, Tick } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./TwilioClientConnection.styles";

export const TwilioMobileConnection = ({
  thick,
  children,
  isConnected,
  actionConnect,
  actionMute,
  dialStatus,
  isMute,
  elapsedTime,
  error,
  warning,
  info,
  isConnectionDegraded,
  accessCode,
  subjectAccessCode,
}) => {
  const styles = useStyles();

  if (isConnected && subjectAccessCode === accessCode) {
    return (
      <x.div {...styles.connectedWrapper} data-testid={`web-connect-${accessCode}`}>
        <>
          {error && (
            <Alert variant="danger" width="100%" {...styles.alert}>
              {error}
            </Alert>
          )}
          {isConnectionDegraded && (
            <Alert variant="warning" width="100%" {...styles.alert}>
              Call quality has dropped.
            </Alert>
          )}
          {warning && (
            <Alert variant="warning" width="100%" {...styles.alert}>
              {warning}
            </Alert>
          )}
          {info && (
            <Alert variant="info" width="100%" {...styles.alert}>
              {info}
            </Alert>
          )}
          {!error && (
            <x.div {...styles.buttonsWrapper}>
              <Button
                variant="outline"
                size={thick ? "medium" : "small"}
                {...styles.disconnectBtn}
                onClick={actionConnect}
              >
                Disconnect
              </Button>
              <x.div {...styles.connectionDataWrapper}>
                <x.div {...styles.connectedLabelWrapper}>
                  <Icon color="success">
                    <Tick />
                  </Icon>
                  <Typography variant="body-small-em" color="success">
                    Connected
                  </Typography>
                </x.div>
                {elapsedTime && (
                  <Typography variant="body-small" color="secondary" {...styles.callDuration}>
                    {elapsedTime}
                  </Typography>
                )}
                <IconButton variant="basic" onClick={actionMute}>
                  {isMute ? <MicOff /> : <AudioRecording />}
                </IconButton>
              </x.div>
            </x.div>
          )}
        </>
      </x.div>
    );
  }

  return (
    <x.div {...styles.connectedWrapper} data-testid={`web-connect-${accessCode}`}>
      {isConnected && accessCode !== subjectAccessCode && (
        <Alert variant="warning" width="100%" {...styles.alert}>
          To join please disconnect from your current call.
        </Alert>
      )}

      {error && accessCode === subjectAccessCode && (
        <Alert variant="danger" width="100%" {...styles.alert}>
          {error}
        </Alert>
      )}

      <x.div {...styles.buttonsWrapper}>
        {children}
        <Button
          variant="primary"
          size={thick ? "medium" : "small"}
          startIcon={thick ? <Call /> : null}
          flex={0.5}
          disabled={!error && subjectAccessCode && subjectAccessCode !== accessCode}
          loading={dialStatus !== "none" && subjectAccessCode === accessCode}
          onClick={() => dialStatus === "none" && actionConnect()}
          data-testid="connect-btn"
        >
          Connect
        </Button>
      </x.div>
    </x.div>
  );
};
