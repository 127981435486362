import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const Backdrop = styled.div(({ zIndex, ...props }) => {
  const { color } = useThemeTokens();
  return {
    backgroundColor: color.background.dark,
    opacity: 0.6,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: zIndex ?? 1,
    ...props,
  };
});

export default Backdrop;
