import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useEmploymentHistoryStyles = () => {
  const {
    color: { text, error },
    spacing: { inner },
  } = useThemeTokens();

  const title = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
  };

  const cidClearanceBanner = {
    display: "flex",
    marginBottom: inner.base04,
  };

  const jobPeriod = {
    color: text.secondary,
  };

  const jobError = {
    color: error,
  };

  const jobCidError = {
    marginBottom: inner.base04,
  };

  const jobsDiv = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base05,
  };

  return {
    cidClearanceBanner,
    title,
    jobPeriod,
    jobError,
    jobCidError,
    jobsDiv,
  };
};
