export const COMMISSION_MP_INFO = Object.freeze({
  title: "Information",
  listItems: [
    {
      value:
        "Private Primers are visible to you and colleagues on your project. AlphaNow Primers can be purchased by other AlphaSights clients",
      property: "root",
    },
    {
      value:
        "You will receive Company and Customer Primers within 6-10 business days, and Market Primers within 9-12 business days. You can request these on a ‘Rush’ timeline",
      property: "root",
    },

    {
      value: "Payment is taken on delivery",
      property: "root",
    },
  ],
});

export const PRIMER_INFO = Object.freeze({
  companyPrimer: "Synthesized insights on the company from former executives, competitors and customers.",
  marketPrimer: "Synthesized insights on the market from industry executives.",
  customerPrimer: "Synthesized insights on vendors from key customer decision makers.",
});

export const COMMISSION_BUTTON_CANCEL = "Cancel";
export const COMMISSION_BUTTON_SUBMIT = "Commission Now";

export const PRIMER_WORKING_DAYS = Object.freeze({
  companyPrimer: "6 - 10 business days",
  marketPrimer: "9 - 12 business days",
  customerPrimer: "6 - 10 business days",
});
export const PRIMER_WORKING_DAYS_RUSH = Object.freeze({
  companyPrimer: "<6 business days",
  marketPrimer: "<9 business days",
  customerPrimer: "<6 business days",
});

export enum CommissionPrimerProperties {
  //COMMISSION PRIMER
  REQUESTED_COMPANY_ID = "requestedCompanyId",
  REQUESTED_COMPANY_NAME = "requestedCompanyName",
  REQUESTED_COMPANY_FORMAT = "requestedCompanyFormat",
  REQUESTED_COMPANY_TYPE = "requestedCompanyType",
  REQUESTED_COMPANY_RUSH = "rush",
  REQUESTED_COMPANY_LOGO = "logoLink",

  //REQUEST PRIMER
  REQUESTED_COMPANY_WEBSITE = "requestedCompanyWebsite",
}

export const COMMISSION_MODAL_FIT = "160px 154px 154px 76px 58px 47px";
export const COMMISSION_MODAL_INPUT_MIN = 13;
export const COMMISSION_MODAL_INPUT_MAX = 20;

export const COMMISSION_URL_PARAM = "commissionPrimers";

export const SUCCESS_COMMISSION = "Your request to commission Primers has been taken";
export const CASE_CODE = "Case Code (Optional)";

export const companyErrorMessage = "Please add a company";
export const formatErrorMessage = "Please select Primer format";
