import pluralize from "pluralize";
import { uniq } from "lodash";

import { SEARCH_SUGGESTION_TYPES, COMPANY_TYPE, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { requestExpertContent } from ".";
import { credit } from "content/AlphaNow";
import { utcToZonedTime } from "date-fns-tz";

// Request an Expert
const requestExperts = (num) => pluralize(requestExpertContent, num);

// Purchase Transcript
const confirmTranscriptPurchase = (price) => `Confirm transcript purchase for ${pluralize(credit, price, true)}.`;

// Purchase Company Primer
const confirmCompanyPrimerPurchase = (price) =>
  `Confirm Company Primer purchase for ${pluralize(credit, price, true)}.`;

// Purchase Market Primer
const confirmMarketPrimerPurchase = (price) => `Confirm Market Primer purchase for ${pluralize(credit, price, true)}.`;
// Purchase Customer Primer
const confirmCustomerPrimerPurchase = (price) =>
  `Confirm Customer Primer purchase for ${pluralize(credit, price, true)}.`;

// Purchase Upcoming Transcript
const confirmUpcomingPurchase = (price) => `Confirm purchase for ${pluralize(credit, price, true)}.`;

const removeTabs = (str) => str.replace(/( {4})|[\t]+/gm, "");

const moreItems = (num) => `+${num} more`;

const SortOrder = {
  Alphabetical: "ALPHABETICAL",
  Length: "LENGTH",
  None: "NONE",
};

const getCompaniesFromSearchQuery = (searchQuery) =>
  searchQuery.filter(({ type }) =>
    [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch].includes(parseInt(type))
  );

const getMarketsFromSearchQuery = (searchQuery) =>
  searchQuery.filter((it) => parseInt(it.type) === SEARCH_SUGGESTION_TYPES.Market).map((it) => it.value);

const getColleaguesFromSearchQuery = (searchQuery) =>
  searchQuery.filter((it) => parseInt(it.type) === SEARCH_SUGGESTION_TYPES.Colleague).map((it) => it.value);

const getColleagueNames = (colleagues) => colleagues?.map(({ name }) => name);

const buildSearchQuery = ({ type, value, id }) => [
  {
    id,
    value,
    label: value,
    type,
  },
];

const sortItems = (items, orderBy, getItemValue = (item) => item) => {
  let compareFn;
  switch (orderBy) {
    case SortOrder.Alphabetical:
      compareFn = (itemOne, itemTwo) => itemOne.localeCompare(itemTwo);
      break;
    case SortOrder.Length:
      compareFn = (itemOne, itemTwo) => itemOne.length - itemTwo.length;
      break;
    default:
      return items;
  }
  return [...items].sort((itemOne, itemTwo) => compareFn(getItemValue(itemOne), getItemValue(itemTwo)));
};

const getOrderedItemsWithSearchPriority = (allItems, searchItems, orderBy = SortOrder.None) => {
  const searchMatchedItems = searchItems.filter((item) => allItems.includes(item));
  const nonSearchItems = allItems.filter((item) => !searchItems.includes(item));

  const orderedNonSearchItems = sortItems(nonSearchItems, orderBy);

  return uniq([...searchMatchedItems, ...orderedNonSearchItems]);
};

const getOrderedAnchorCompanies = (companies, searchCompanyIds, orderBy = SortOrder.None) => {
  const anchorCompanies = getAnchorCompanies(companies);
  const searchMatchedItems = anchorCompanies.filter((item) => searchCompanyIds.includes(item.companyId));
  const nonSearchItems = anchorCompanies.filter((item) => !searchCompanyIds.includes(item.companyId));

  const orderedNonSearchItems = sortItems(nonSearchItems, orderBy, (item) => item.companyName);

  return uniq([...searchMatchedItems, ...orderedNonSearchItems]);
};

const getAnchorCompanies = (companies = []) =>
  companies.filter(({ companyType }) => companyType === COMPANY_TYPE.anchor);

const getAnchorCompanyIds = (companies = []) => getAnchorCompanies(companies).map(({ companyId }) => companyId);

const getAngleType = (speakers) =>
  speakers.reduce(
    (list, speaker) => (speaker.isModerator ? [...list] : [...list, ...speaker.angleTypes.map(({ name }) => name)]),
    []
  )[0];

const getContentTitle = (productType, companies, externalTitle) =>
  productType === AlphaNowProductType.companyPrimer
    ? companies.find((it) => it.companyType === COMPANY_TYPE.anchor)?.companyName
    : externalTitle ?? "";

// Convert time into local timezone
const browsersLocalTime = (time) => utcToZonedTime(time, Intl.DateTimeFormat().resolvedOptions().timeZone);

const hasAnyPrimer = (contentTypeMapping) =>
  contentTypeMapping ? contentTypeMapping.some(({ contentType }) => contentType.toLowerCase().includes("primer")) : [];

export {
  SortOrder,
  requestExperts,
  confirmTranscriptPurchase,
  confirmCompanyPrimerPurchase,
  confirmMarketPrimerPurchase,
  confirmCustomerPrimerPurchase,
  confirmUpcomingPurchase,
  removeTabs,
  moreItems,
  getCompaniesFromSearchQuery,
  getOrderedAnchorCompanies,
  getOrderedItemsWithSearchPriority,
  getAngleType,
  browsersLocalTime,
  getContentTitle,
  getColleaguesFromSearchQuery,
  getColleagueNames,
  getAnchorCompanyIds,
  hasAnyPrimer,
  buildSearchQuery,
  getMarketsFromSearchQuery,
};
