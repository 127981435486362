import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlphaGPTCollapsibleListStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    pt: inner.base06,
    position: "relative",
    overflow: "hidden",
    transition: "height 0.2s ease-in-out",
  };

  const expandButton = {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    h: 42,
    background: "linear-gradient(0deg, white, transparent)",
    display: "flex",
    justifyContent: "center",
  };

  return {
    wrapper,
    expandButton,
  };
};
