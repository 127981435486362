import React, { FC } from "react";
import { Divider, IconButton, Tile, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company, Delete, Quote } from "@alphasights/alphadesign-icons";
import { CardContainer, TriggerDetailsContainer } from "./WatchlistTriggerCard.styled";
import useWatchlist, { TRIGGER_TYPE, WatchlistTriggerProps } from "hooks/useWatchlist";
import { x } from "@xstyled/styled-components";
import { CompanyLogoWrapper } from "./WatchlistTriggerCard.styled";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { getTriggerValues } from "components/WatchlistModal/utils";

const DataTestIds = {
  deleteWatchlistTriggerButton: "delete-watchlist-trigger-button",
  triggerCard: "watchlist-trigger-card",
};

type WatchlistTriggerCardProps = {
  trigger: WatchlistTriggerProps;
  watchlistId: string;
};

const CompanyTriggerLogo = ({ trigger }: { trigger: WatchlistTriggerProps }) => (
  <CompanyLogoWrapper>
    {(trigger.companyInfo as CompanyInfo)?.logo ? (
      <x.img src={(trigger.companyInfo as CompanyInfo).logo} maxW="90%" maxH="90%" />
    ) : (
      <Tile variant="icon" size="small" icon={<Company />} />
    )}
  </CompanyLogoWrapper>
);

const WatchlistTriggerCard: FC<WatchlistTriggerCardProps> = ({ trigger, watchlistId }) => {
  const {
    color: { text, icon },
    spacing: { inner },
  } = useThemeTokens();

  const { deleteWatchlistTriggers } = useWatchlist();
  const { logHit } = useTrackUserAction();
  const { type, companyInfo, id, token } = trigger;

  let title, subtitle, startIcon;

  if (type === TRIGGER_TYPE.company) {
    title = companyInfo?.name as string;
    subtitle = companyInfo?.headquarters;
    startIcon = <CompanyTriggerLogo trigger={trigger} />;
  } else {
    title = token as string;
    startIcon = <Tile variant="icon" size="small" icon={<Quote />} />;
  }

  const handleDeleteTrigger = async () => {
    try {
      await deleteWatchlistTriggers({ watchlistId, triggerIds: [id] });
      logHit({
        origin: HitOrigin.watchlist,
        action: HitAction.deleteWatchlistTriggers,
        details: {
          watchlistId,
          triggers: getTriggerValues([trigger]),
        },
      });
    } catch (error) {
      console.error(`Failed to delete Watchlist trigger: ${error}`);
    }
  };

  return (
    <>
      <CardContainer data-testid={DataTestIds.triggerCard}>
        <TriggerDetailsContainer>
          {startIcon}
          <div>
            <Typography variant="body-small-em">{title}</Typography>
            <Typography variant="body-small" color={text.secondary}>
              {subtitle}
            </Typography>
          </div>
        </TriggerDetailsContainer>
        <IconButton
          dataAttributes={{ "data-testid": DataTestIds.deleteWatchlistTriggerButton }}
          onClick={handleDeleteTrigger}
          disabled={false}
          size="small"
          variant="basic"
          color={icon.danger}
          xStyledProps={{ marginRight: inner.base }}
        >
          <Delete />
        </IconButton>
      </CardContainer>
      <Divider />
    </>
  );
};

export { WatchlistTriggerCard as default, DataTestIds };
