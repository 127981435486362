import React from "react";
import PropTypes from "prop-types";
import { QueryClient as Client, QueryClientProvider as Provider } from "react-query";

export const QueryClient = new Client({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const QueryClientProvider = ({ children }) => {
  return <Provider client={QueryClient}>{children}</Provider>;
};

QueryClientProvider.propTypes = {
  children: PropTypes.node,
};
