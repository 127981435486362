import React, { useEffect } from "react";
import { useState } from "react";
import "./index.css";
import { useEnv } from "@alphasights/client-portal-shared";
import { useLoginStyles } from "./index.styles";
import { LoginLogoHeader, UnpaddedModal } from "./BackgroundPage";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useFeatures } from "app/wrappers/FeatureFlagsWrapper";
import { LoginModal as AuthLoginModal } from "@alphasights/auth-components";
import { Login } from "./index";
import { useClientPortalActions } from "app/wrappers/ClientPortalWrapper";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useLogger } from "logging/Logger";
import useQuery from "hooks/useQuery";

export const LoginModal = ({ isOpen, onClose, message = undefined, allowAnonymousContinue = true, projectToken }) => {
  const [step, setStep] = useState();
  const { loginModal } = useLoginStyles(step);
  const { isMobile } = useCheckScreen();
  const features = useFeatures();
  const { setIsModalOpen } = useClientPortalActions();
  const user = useCurrentUser();
  const shouldOpen = isOpen && !user;
  const logger = useLogger();
  const query = useQuery();

  const env = useEnv();

  const onSetStep = (step) => {
    setStep(step);
  };

  useEffect(
    function trackOpenModal() {
      if (shouldOpen && features.launchNewLogin) {
        logger.log("new login modal launched");
      } else {
        logger.log("login modal launched");
      }
    },
    [shouldOpen, features.launchNewLogin, logger]
  );

  // New Login Modal
  // TODO: Remove this condition after migration is completed
  if (shouldOpen && features.launchNewLogin) {
    return (
      <AuthLoginModal
        isOpen={shouldOpen}
        onClose={onClose}
        onSuccess={(user) => {
          logger.log("login success", user);
          setIsModalOpen(false);
        }}
        onFail={(error) => {
          logger.log("login failed", error);
        }}
        loginUrl={env.login.loginModalUrl}
        loginProps={{
          allowAnonymousContinue,
          emailStepMessage: message,
          projectToken,
          redirectUrl: query.get("next"),
        }}
      />
    );
  }

  return (
    <>
      {shouldOpen && (
        <UnpaddedModal
          onClose={onClose}
          open={shouldOpen}
          showCloseButton={false}
          slotHeight={loginModal.h}
          slotWidth={loginModal.w}
          zIndex={30}
        >
          {isMobile && <LoginLogoHeader />}
          <Login
            onContinue={onClose}
            onRedirect={onClose}
            message={message}
            allowAnonymousContinue={allowAnonymousContinue}
            onSetStep={onSetStep}
            projectToken={projectToken}
          />
        </UnpaddedModal>
      )}
    </>
  );
};
