import { create } from "zustand";
import { Outlook } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/outlook";
import { CommercialModel } from "../types/commercialModel";
import { OverviewDetails } from "../types/overview";
import { Competitors } from "../types/competitors";
import { Section } from "../types/segmentation";

type storeState = {
  overview: OverviewDetails;
  segmentations: Section[];
  commercialModel: CommercialModel;
  competitors: Competitors;
  outlook: Outlook;

  setOverview: (data: any) => void;
  setCompanyPrimerState: (data: any) => void;
};

const emptyCitableValue = {
  citedBy: [],
  value: undefined,
  isSensible: false,
};

export const useCompanyPrimerV3Store = create<storeState>((set) => ({
  overview: {
    id: emptyCitableValue,
    logoLink: "",
    companyName: "",
    currency: "",
    cdsAlphaCompanyId: 0,
    primerTitle: "",
    businessSummary: emptyCitableValue,
    location: {
      id: emptyCitableValue,
      year: emptyCitableValue,
      city: emptyCitableValue,
      country: emptyCitableValue,
      state: emptyCitableValue,
    },
    revenue: {
      id: emptyCitableValue,
      max: emptyCitableValue,
      min: emptyCitableValue,
      expertRevenueGrowthYear: emptyCitableValue,
      expertRevenueYear: emptyCitableValue,
      expertRevenueQuarter: emptyCitableValue,
      growthMax: emptyCitableValue,
      growthMin: emptyCitableValue,
    },
    fullTimeEquivalent: {
      id: emptyCitableValue,
      min: emptyCitableValue,
      max: emptyCitableValue,
      year: 0,
    },
    ownership: {
      id: emptyCitableValue,
      companyName: emptyCitableValue,
      cdsAlphaCompanyId: 0,
      ownershipType: emptyCitableValue,
      vcBackedSeries: emptyCitableValue,
      ownershipYear: 0,
      ownershipQuarter: 0,
    },
    markets: [],
    marketContext: {
      id: emptyCitableValue,
      content: emptyCitableValue,
      participants: [],
      overview: {
        id: emptyCitableValue,
        content: emptyCitableValue,
      },
    },
    companyOverview: {
      servicesAndProducts: [],
      useCases: [],
    },
    notableEvents: [],
  },
  segmentations: [],
  commercialModel: {
    goToMarket: {
      summary: {
        id: emptyCitableValue,
        content: emptyCitableValue,
      },
      marketSplits: [],
    },
    monetization: {
      contracts: {
        summary: {
          id: emptyCitableValue,
          content: emptyCitableValue,
        },
        marketSplits: [],
      },
      profitability: {
        summary: {
          id: emptyCitableValue,
          content: emptyCitableValue,
        },
        marketSplits: [],
      },
    },
  },
  competitors: {
    markets: [],
    core: [],
    adjacent: [],
  },
  outlook: {
    growthDrivers: [],
    opportunitiesAndRisks: [],
  },
  setOverview: (data: any) => set({ overview: data }),
  setCompanyPrimerState: (data: any) =>
    set({
      overview: data.overview,
      segmentations: data.segmentations,
      commercialModel: data.commercialModel,
      competitors: data.competitors,
      outlook: data.outlook,
    }),
}));
