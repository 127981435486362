export const AlertReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return {
        ...state,
        messages: [{ ...action.payload }, ...state.messages],
      };
    case "REMOVE_MESSAGE":
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.payload.id),
      };
    default:
      throw new Error("Action must be one of the defined ACTION_TYPES.");
  }
};
