import React from "react";
import { x } from "@xstyled/styled-components";
import { Pill, useThemeTokens } from "@alphasights/alphadesign-components";

type XAxisProps = {
  kpcs: {
    name: string;
    multilinedName: string[];
    score: string;
  }[];
};

// we need an extra padding on the last column
const getColumnPaddingLeft = (index: number, numColumns: number): string => {
  // not the last column or only one column
  if (index !== numColumns - 1 || numColumns === 1) return "0";

  switch (numColumns) {
    case 2:
      return "35%";
    case 3:
      return "29%";
    case 4:
      return "25%";
    case 5:
      return "12%";
    case 6:
      return "14%";
    case 7:
      return "12%";
    case 8:
      return "12%";
    case 9:
      return "13%";
    case 10:
      return "12%";
    default:
      return "8%";
  }
};

// we need an extra padding on the first column
const getColumnPaddingRight = (index: number, numColumns: number): string => {
  // not the first column or only one column
  if (index !== 0 || numColumns === 1) return "0";

  switch (numColumns) {
    case 2:
      return "34%";
    case 3:
      return "27%";
    case 4:
      return "23%";
    case 5:
      return "9%";
    case 6:
      return "10%";
    case 7:
      return "10%";
    case 8:
      return "11%";
    case 9:
      return "10%";
    case 10:
      return "9%";
    default:
      return "8%";
  }
};

const getRowColumnsConfig = (numColumns: number) => {
  switch (numColumns) {
    case 1:
      return { gridTemplateColumns: "100%" };
    case 2:
      // 1st: 34%
      // last: 35%
      return { gridTemplateColumns: "repeat(2, 50%)" };
    case 3:
      // 1st: 27%
      // last: 29%
      return { gridTemplateColumns: "31% 38.4% 30.5%" };
    case 4:
      // 1st: 23%
      // last: 25%
      return { gridTemplateColumns: "22.4% repeat(2,27.7%) 22.1%" };
    case 5:
      // 1st: 9%
      // last: 12%
      return { gridTemplateColumns: "18.8% repeat(3,20.8%) 18.7%" };
    case 6:
      // 1st: 10%
      // last: 14%
      return { gridTemplateColumns: "15.5% repeat(4,17.3%) 15.2%" };
    case 7:
      // 1st: 10%
      // last: 12%
      return { gridTemplateColumns: "13.2% repeat(5,14.75%) 13%" };
    case 8:
      // 1st: 11%
      // last: 12%
      return { gridTemplateColumns: "11.5% repeat(6,12.9%) 11%" };
    case 9:
      // 1st: 10%
      // last: 13%
      return { gridTemplateColumns: "10.2% repeat(7,11.4%) 9.9%" };
    case 10:
      // 1st: 9%
      // last: 12%
      return { gridTemplateColumns: "9.2% repeat(8,10.25%) 9.1%" };
    default:
      // 1st: 8%
      // last: 8%
      return { gridTemplateColumns: `repeat(${numColumns}, ${100 / numColumns}%)` };
  }
};

const XAxis = ({ kpcs }: XAxisProps) => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div display="flex">
      <x.div flex="0 0 auto" {...typography.body.small} marginTop="14px" fontWeight="400">
        Rank (avg.)
      </x.div>
      <x.div
        flex="1 1 auto"
        display="grid"
        margin="10px 5px 0 0"
        alignItems="start"
        {...getRowColumnsConfig(kpcs.length)}
      >
        {kpcs.map(({ multilinedName, name, score }, index) => (
          <x.div
            key={`${name}-${index}`}
            w="100%"
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            columnGap={spacing.inner.base02}
            {...typography.body.small}
            color={color.text.strong._}
            rowGap={spacing.inner.base04}
            paddingLeft={getColumnPaddingLeft(index, kpcs.length)}
            paddingRight={getColumnPaddingRight(index, kpcs.length)}
          >
            <Pill size="small" variant="light">
              {score}
            </Pill>
            <div>
              {multilinedName.map((line, index) => (
                <x.div key={`${score}-${index}`} textAlign="center">
                  {line}
                </x.div>
              ))}
            </div>
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

export default XAxis;
