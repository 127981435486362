import React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CitationWrapper } from "pages/AlphaNowPage/primers/components";
import NpsTopics from "../topics/NpsTopics";

const NpsTab = ({ summary, expertsResponses }: { summary: PrimerSummary; expertsResponses: NpsExpertsRemark[] }) => {
  const { spacing, typography, color } = useThemeTokens();

  return (
    <x.div {...typography.body.regular} color={color.text.strong._}>
      <CitationWrapper
        titleProperties={{ variant: "body", isTitleSensitive: false }}
        verticalLabel
        value={summary?.value}
        mt={spacing.inner.base06}
        titleToContentGap={spacing.inner.base02}
        label="NPS FEEDBACK"
      />
      <NpsTopics npsExpertsResponses={expertsResponses} />
    </x.div>
  );
};

export default NpsTab;
