import React, { FC } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

interface ExpertCountProps {
  count: number;
  className?: string;
}

const ExpertCount: FC<ExpertCountProps> = ({ count, className }) => (
  <span className={className}>
    <x.span display="inline-block" verticalAlign="top" mx="5px">
      <Icon size="small">
        <Expert />
      </Icon>
    </x.span>
    {count}
  </span>
);

export default ExpertCount;
