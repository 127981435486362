export const getCompanyPrimerTitle = (
  companyName?: CitableValue<string>,
  companyNameId?: CitableValue<number>,
  companyNameIdToCDSCompanyNamesMap?: Record<number, CDSCompanyNames>
): string => {
  let primerTitle = "";

  if (companyName?.value) {
    primerTitle = companyName?.value;
  } else {
    const anchor = companyNameId?.value ? companyNameIdToCDSCompanyNamesMap?.[companyNameId.value] : null;

    primerTitle = anchor?.displayName ?? "";
  }

  return primerTitle;
};

export const mapCompanies = (res: Record<number, CDSCompanyNames>) => {
  const keys = Object.keys(res);
  const values = Object.values(res);

  return values.map((value, index) => ({
    id: keys[index],
    name: value.displayName,
    logoLink: value.logoLink,
  }));
};

export const mapCompaniesV2 = (res: Record<string, string>) => {
  const keys = Object.keys(res);
  const values = Object.values(res);

  return values.map((value, index) => ({
    name: keys[index],
    logoLink: value,
  }));
};

export const getTruncatedTextAndTooltip = (text: CitableValue<string> | string, maxLength: number) => {
  if (!text) return [null, null];

  if (typeof text === "string") {
    let name = text;
    let tooltip = null;

    if (text && text.length > maxLength) {
      tooltip = text;
      name = `${text.substring(0, maxLength)}...`;
    }

    return [name, tooltip];
  } else {
    const name = { ...text };
    let tooltip = null;

    if (text.value && text.value.length > maxLength) {
      tooltip = text.value;
      name.value = `${text.value.substring(0, maxLength)}...`;
    }

    return [name, tooltip];
  }
};
