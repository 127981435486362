import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAlertBannerContainerStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const bannersContainer = {
    position: "absolute",
    display: "flex",
    bottom: 0,
    mb: inner.base06,
    w: "100%",
    mx: "auto",
    pointerEvents: "auto",
  };

  const bannerList = {
    display: "flex",
    alignItems: "center",
    flex: "1",
    flexDirection: "column",
    gap: inner.base02,
  };

  const banner = {
    display: "flex",
    alignItems: "center",
  };

  const closeButton = {
    cursor: "pointer",
    ml: inner.base02,
  };

  return {
    bannersContainer,
    banner,
    closeButton,
    bannerList,
  };
};
