import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useCompanySearchStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    optionWrapper: {
      display: "flex",
      alignItems: "center",
      gap: inner.base02,
    },
    selectedCompaniesWrapper: {
      pt: inner.base04,
    },
    selectedCompanyWrapper: {
      h: inner.base12,
      display: "flex",
      alignItems: "center",
      gap: inner.base02,
    },
    selectedCompanyActionButtons: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      gap: inner.base02,
      justifyContent: "end",
    },
  };
};

export const useCompanyLogoStyles = ({ size }: { size: "small" | "medium" }) => {
  const {
    spacing: { inner },
    color: { background },
    shape: {
      border: { radius, width },
    },
  } = useThemeTokens();

  return {
    wrapper: {
      w: size === "small" ? inner.base05 : inner.base08,
      h: size === "small" ? inner.base05 : inner.base08,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `${width.sm} solid ${background.neutral.default}`,
      bg: background.neutral.default,
      borderRadius: radius.small,
      overflow: "hidden",
    },
  };
};
