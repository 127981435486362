import { fetch } from "../../hooks/useApi";
import { toastError } from "@alphasights/client-portal-shared";

export const getValidationRules = () => {
  return fetch({
    url: `/api/auth-user/validation-rules`,
    method: "GET",
    skipAlert: true,
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.error(error);
      toastError({ message: "Error reaching validation endpoint" });
    });
};

export const validateEmail = (value) => {
  if (!value) {
    return "Enter your email.";
  }

  if (!/^[A-Z0-9._%+-\\']+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
    return "Enter a valid email format.";
  }
};

export const validatePassword = (value, validationRules) => {
  let passwordRequired = validatePasswordRequired(value);
  if (passwordRequired) return passwordRequired;

  if (validationRules) {
    for (let i = 0; i < validationRules.length; i++) {
      const invalid = buildValidationRule(value, validationRules[i]);
      if (invalid) return "Password is too weak. Fulfill criteria below.";
    }
  }
};

export const buildValidationRule = (value, rule) => {
  const regex = new RegExp(rule.regexRule);
  if (!regex.test(value)) return rule.invalidMessage;
};

export const validatePasswordRequired = (value) => {
  if (!value) return "Enter your password.";
};

export const validatePasswordConfirmation = ({ password, passwordConfirmation }) => {
  if (passwordConfirmation && passwordConfirmation !== password) {
    return { passwordConfirmation: "Passwords don’t match." };
  }
};

export const validatePasswordForm = ({ password, passwordConfirmation, validationRules, source }) => {
  if (!password) {
    return buildPasswordValidationResponse(source === "reset" ? "Enter password." : "Enter your password.");
  }

  if (passwordConfirmation && passwordConfirmation !== password) {
    return buildPasswordValidationResponse("Passwords don’t match.");
  }

  if (validationRules) {
    for (let i = 0; i < validationRules.length; i++) {
      const invalid = buildValidationRule(password, validationRules[i]);
      if (invalid) {
        return buildPasswordValidationResponse("Password is too weak. Fulfill criteria below.");
      }
    }
  }
};

const buildPasswordValidationResponse = (error) => {
  return {
    password: error,
    passwordConfirmation: error,
  };
};
