import React from "react";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { LabeledIcon, SectionTitle } from "pages/AlphaNowPage/primers/components";
import { Info } from "@alphasights/alphadesign-icons";
import { useCompanyPrimerV3Store } from "../../store/store";
import SegmentSection from "./SegmentSection";
import SectionWrapper from "../styled";

const Segmentations = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const segmentations = useCompanyPrimerV3Store(({ segmentations }) => segmentations);

  return (
    <SectionWrapper ref={ref}>
      <x.div w="100%">
        <x.div display="flex" flexDirection="column" gap={inner.base06}>
          <x.div display="flex" flexDirection="column" gap={inner.base02} px={inner.base10}>
            <SectionTitle dataTestId="SegmentationSectionTitle" text="Segmentation" heading />
            <LabeledIcon
              labelText="All revenue splits are illustrative. They are taken from expert estimates and corroborated with public sources where available."
              iconItem={<Info />}
            />
          </x.div>
          {segmentations.map((segmentSection, index) => {
            return (
              <x.div key={index} display="flex" flexDirection="column" gap={inner.base06}>
                <Divider />
                <SegmentSection segmentSection={segmentSection} sectionIndex={index} />
              </x.div>
            );
          })}
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Segmentations));
