import { PillVariants } from "@alphasights/alphadesign-components";
import { color } from "@alphasights/alphadesign-tokens/dist/js/base/tokens-docs";

type PillConfig = Record<PillVariants, { iconColor: string; pillVariant: string }>;

const PILL_CONFIG: PillConfig = {
  light: {
    iconColor: color.icon.secondary.value,
    pillVariant: "light",
  },
  blue: {
    iconColor: color.icon.info.value,
    pillVariant: "blue",
  },
  green: {
    iconColor: color.text.success.value,
    pillVariant: "green",
  },
  red: {
    iconColor: color.text.danger.value,
    pillVariant: "red",
  },
  yellow: {
    iconColor: color.text.warning.value,
    pillVariant: "yellow",
  },
  violet: {
    iconColor: color.text.processing.value,
    pillVariant: "violet",
  },
  outline: {
    iconColor: color.text.strong._.value,
    pillVariant: "outline",
  },
  orange: {
    iconColor: color.icon.decorative_base03.value,
    pillVariant: "orange",
  },
  purple: {
    iconColor: color.icon.ai.value,
    pillVariant: "purple",
  },
};

export { PILL_CONFIG };
