import React, { useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { HitAction, SynthesisModule } from "@alphasights/portal-api-client";
import { useStyles } from "./SynthesisSidebarContent.styles";
import { Add, CloseFilled, DragIndicator } from "@alphasights/alphadesign-icons";
import { Icon, IconButton, Loading, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _ from "lodash";

export const SynthesisSidebarCard = ({
  synthesisModule,
  isDragging,
  index,
}: {
  synthesisModule: SynthesisModule;
  isDragging: boolean;
  index: number;
}) => {
  const styles = useStyles();
  const { selectedModule, promoteModule, mode, synthesisLogHit } = useProjectSynthesisContext();
  const [isHovered, setIsHovered] = useState(false);

  const isSelected = mode !== Mode.NEW && synthesisModule.id === selectedModule?.id;

  const lastRevision = synthesisModule.questionRevisions[synthesisModule.questionRevisions.length - 1];

  const lastTopic = useMemo(
    () =>
      _.findLast(
        synthesisModule.questionRevisions.map((rev) => rev.topic),
        (t) => t
      ),
    [synthesisModule]
  );

  const onPromoteModule = () => {
    promoteModule(synthesisModule);
    synthesisLogHit({
      action: HitAction.projectSynthesisModulePromoted,
      references: { moduleIds: [synthesisModule.id] },
      details: { cta: "card" },
    });
  };

  if (!lastRevision) {
    return null;
  }

  return (
    <x.div
      {...styles.cardWrapper}
      {...(synthesisModule.type.toLowerCase() === "custom" ? styles.isCustom : styles.isExtracted)}
      {...(isSelected ? styles.isActive : styles.isInactive)}
      aria-selected={isSelected}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`synthesis-sidebar-card-${lastRevision.topic ?? "processing"}`}
    >
      <Typography variant="body" color={"black"} lineHeight="32px">
        {lastTopic ? <>{lastTopic}</> : <Skeleton variant="noMargin" width="250px" height="20px" />}
      </Typography>
      <x.div display={"flex"} alignItems={"center"}>
        {synthesisModule.type.toLowerCase() === "extracted" && !isDragging && (
          <IconButton variant={"ghost"} size={"small"} onClick={onPromoteModule}>
            <Add data-testid="promote-button" />
          </IconButton>
        )}
        {lastRevision?.status === "FAILED" && (
          <Icon color="red" dataAttributes={{ "data-testid": `card-failed-${synthesisModule.id}` }}>
            <CloseFilled />
          </Icon>
        )}
        {lastRevision?.status === "PROCESSING" && (
          <Icon dataAttributes={{ "data-testid": `card-processing-${synthesisModule.id}` }}>
            <Loading />
          </Icon>
        )}
        {(isDragging || (isHovered && synthesisModule.type.toLowerCase() === "custom")) && <DragIndicator />}
      </x.div>
    </x.div>
  );
};
