import React from "react";
import { Info } from "@alphasights/alphadesign-icons";
import { CustomAccordion, useTrackUserAction } from "@alphasights/client-portal-shared";
import { Tile } from "@alphasights/alphadesign-components";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import useWatchlist, { DEFAULT_WATCHLIST_NAME, WatchlistDataProps, WatchlistTriggerProps } from "hooks/useWatchlist";
import {
  NUM_WATCHLIST_TRIGGERS_DISPLAYED,
  NO_WATCHLIST_DESCRIPTION,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/constants";
import WatchlistItem from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/WatchlistItem";
import { getOrderedTriggers } from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/utils";
import { getTriggerValues } from "components/WatchlistModal/utils";
import WatchlistHeader from "./WatchlistHeader";

import { NoWatchlistContainer, NoWatchlistDescriptionContainer, StyledTypography } from "./WatchlistSection.styled";

const DATA_TEST_IDS = {
  WatchlistSection: "watchlist-section",
  DefaultWatchlistSection: "default-watchlist-section",
};

const WatchlistSection = () => {
  const { availableWatchlists } = useWatchlist();
  const { logHit } = useTrackUserAction();

  const handleClickItem = (trigger: WatchlistTriggerProps, id: string) => {
    logHit({
      origin: HitOrigin.landingPage,
      action: HitAction.selectWatchlistTrigger,
      details: {
        watchlistId: id,
        triggers: getTriggerValues([trigger]),
      },
    });
  };

  if (availableWatchlists.length === 0) {
    return (
      <NoWatchlistContainer data-testId={DATA_TEST_IDS.DefaultWatchlistSection}>
        <WatchlistHeader watchlistName={DEFAULT_WATCHLIST_NAME} />
        <NoWatchlistDescriptionContainer>
          <Tile variant="icon" size="small" icon={<Info />} />
          <StyledTypography>{NO_WATCHLIST_DESCRIPTION}</StyledTypography>
        </NoWatchlistDescriptionContainer>
      </NoWatchlistContainer>
    );
  }

  return (
    <div data-testId={DATA_TEST_IDS.WatchlistSection}>
      {(availableWatchlists as WatchlistDataProps[]).map(({ name, triggers, id }) => (
        <CustomAccordion
          key={id}
          disableShowMore={triggers.length <= NUM_WATCHLIST_TRIGGERS_DISPLAYED}
          titleContent={<WatchlistHeader watchlistName={name} watchlistId={id} />}
        >
          {getOrderedTriggers(triggers).map((trigger) => (
            <WatchlistItem trigger={trigger} onClick={() => handleClickItem(trigger, id)} />
          ))}
        </CustomAccordion>
      ))}
      ;
    </div>
  );
};

export { WatchlistSection as default, DATA_TEST_IDS };
