import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const SectionWrapper = styled.div(({ hasDivider = true }: { hasDivider?: boolean }) => {
  const {
    spacing,
    shape: { border },
    color,
  } = useThemeTokens();
  return {
    marginInline: spacing.layout.base04,
    gap: spacing.layout.base03,
    paddingTop: hasDivider ? spacing.layout.base04 : 0,
    borderTop: hasDivider ? `${border.width.sm} solid ${color.background.disabled}` : "none",
  };
});

export default SectionWrapper;
