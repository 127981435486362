import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaTable, AlphaTableTypes, useAlphaTable } from "@alphasights/alphadesign-table";
import { PageFooter, useEnv } from "@alphasights/client-portal-shared";
import { Env } from "@alphasights/portal-api-client";
import styled, { th, x } from "@xstyled/styled-components";
import { useMemoizedValue } from "hooks/useMemoizedValue";
import { sortBy } from "lodash";
import { ExpertCompareQuestion } from "models/ExpertCompare";
import pluralize from "pluralize";
import { useExpertRemarksContext } from "providers/ExpertRemarksProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import React, { useCallback, useMemo, useState } from "react";
import { ComparisonTableData, buildColumns, buildData, buildOptions } from "./utils";

export const ComparisonTable = ({
  questions,
  interactions,
  selectedInteractionId,
  selectedAngle,
}: {
  questions: ExpertCompareQuestion[];
  interactions: Interaction[];
  selectedInteractionId?: string;
  selectedAngle: FilterOption;
}) => {
  const { isExpertHidden } = useExpertRemarksContext();
  const { comparisonViewMaxExperts } = useEnv() as Env & { comparisonViewMaxExperts: number };
  const {
    onSelectCard,
    state: { appliedFilters },
  } = useProjectInteractionsContext();
  const [showHiddenExperts, setShowHiddenExperts] = useState(false);

  const onRowClicked = useCallback(
    (row: AlphaTableTypes.Row<ComparisonTableData>) => {
      const interaction = row.original.interaction;
      if (!isExpertHidden(interaction)) {
        onSelectCard({
          ...interaction,
          showChainList: false,
          scrollToCardId: interaction.id,
        });
      }
    },
    [onSelectCard, isExpertHidden]
  );

  const data = useMemoizedValue<ComparisonTableData[]>(
    buildData(selectedAngle, questions, interactions, comparisonViewMaxExperts)
  );
  const columns = useMemo(() => buildColumns(questions, appliedFilters.groups ?? []), [
    appliedFilters.groups,
    questions,
  ]);
  const options = buildOptions({
    onRowClicked,
    rowClassRules: [
      {
        className: "row-selected",
        ruleFunction: (row: AlphaTableTypes.Row<ComparisonTableData>) =>
          row.original.interaction.id === selectedInteractionId,
      },
      {
        className: "row-hidden",
        ruleFunction: (row: AlphaTableTypes.Row<ComparisonTableData>) => isExpertHidden(row.original.interaction),
      },
    ],
  });

  const filteredData = useMemoizedValue<ComparisonTableData[]>(
    sortBy(
      data.filter((expert) => showHiddenExperts || !isExpertHidden(expert.interaction)),
      [(expert) => isExpertHidden(expert.interaction)]
    )
  );
  const table = useAlphaTable<ComparisonTableData>(filteredData, columns, options);

  return (
    <x.div overflow="auto" flexGrow="1">
      <AlphaTableWrapper data-testid="comparison-table">
        <AlphaTable table={table} />
      </AlphaTableWrapper>
      <x.div position="sticky" left="0">
        <ChangeHiddenExpertsVisibilityButton
          data={data}
          showHiddenExperts={showHiddenExperts}
          setShowHiddenExperts={setShowHiddenExperts}
        />
        <PageFooter />
      </x.div>
    </x.div>
  );
};

type ChangeHiddenExpertsVisibilityButtonProps = {
  data: ComparisonTableData[];
  showHiddenExperts: boolean;
  setShowHiddenExperts: (show: boolean) => void;
};

const ChangeHiddenExpertsVisibilityButton = React.forwardRef<HTMLDivElement, ChangeHiddenExpertsVisibilityButtonProps>(
  ({ data, showHiddenExperts, setShowHiddenExperts }, ref) => {
    const { controlHiddenExpertsButtonWrapper } = useComparisonTableStyles();
    const { isExpertHidden } = useExpertRemarksContext();
    const hiddenExperts = useMemo(() => data.filter((expert) => isExpertHidden(expert.interaction)), [
      data,
      isExpertHidden,
    ]);

    return (
      <x.div ref={ref}>
        {hiddenExperts.length > 0 && (
          <x.div {...controlHiddenExpertsButtonWrapper}>
            <Button variant="outline" size="small" onClick={() => setShowHiddenExperts(!showHiddenExperts)}>
              {showHiddenExperts ? "Remove" : "Show"} Hidden {pluralize("Expert", hiddenExperts.length)}
            </Button>
          </x.div>
        )}
      </x.div>
    );
  }
);

const useComparisonTableStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const controlHiddenExpertsButtonWrapper = {
    display: "flex",
    justifyContent: "center",
    pt: inner.base06,
  };

  return {
    controlHiddenExpertsButtonWrapper,
  };
};

const AlphaTableWrapper = styled(x.div)`
  & > div {
    overflow: visible;
  }
  tr:has(th:not(:first-child):hover) th:not(:first-child) {
    background-color: ${th.color("background-neutral-hover")};
  }
  tr:has(td:not(:first-child):hover) td:not(:first-child) {
    background-color: ${th.color("background-neutral-hover")};
  }
  th {
    border-bottom: ${th.borderWidth("sm")} solid ${th.color("border-divider")};
    padding: ${th.space("inner-base03")} ${th.space("inner-base04")};
    [data-testid="alpha-table-header-inner"] {
      padding: 0;
    }
    &:hover {
      background-color: ${th.color("background-neutral-hover")} !important;
    }
    * {
      white-space: normal;
    }
    &:not(:first-child) {
      vertical-align: top;
    }
  }
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    background-color: ${th.color("background-inverse")};
    &:after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      right: -5px;
      border-left: ${th.borderWidth("sm")} solid ${th.color("border-divider")};
      border-right: ${th.borderWidth("xlg")} solid ${th.color("border-divider")};
    }
  }
  th:first-child {
    z-index: 1;
  }
  td {
    border-bottom: ${th.borderWidth("sm")} solid ${th.color("border-divider")};
    background-color: ${th.color("background-inverse")};
    padding: ${th.space("inner-base03")} ${th.space("inner-base04")};
    vertical-align: top;
    &:first-child {
      padding: 0 !important;
      & > div {
        padding: ${th.space("inner-base03")} ${th.space("inner-base04")};
      }
      &:hover {
        background-color: ${th.color("background-neutral-hover")};
      }
    }
  }
  tr.row-hidden td {
    background-color: ${th.color("background-surface-recessed")};
    color: ${th.color("text-disabled")} !important;
  }
  tr.row-selected td {
    background-color: ${th.color("background-selected-subtle")};
  }
  [data-testid="table-wrapper"] {
    overflow: visible;
    & > div {
      display: none;
    }
  }
  td:has(> span.response-loading) {
    vertical-align: middle;
  }
  thead {
    z-index: 2;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
`;
