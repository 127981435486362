import React, { useEffect, useState, useRef, useCallback } from "react";
import { toMinutesAndSeconds } from "helpers/displayHelpers";
import {
  Forward_30 as Forward30,
  Pause,
  PlayArrow,
  Replay_30 as Replay30,
  Tick,
  Close,
  ChevronDown,
  ChevronUp,
} from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import {
  Button,
  Loading,
  Popover,
  Typography,
  IconButton,
  Icon as IconAds,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { useRecordingPlayerStyles } from "./AlphaNowAudioPlayer.styles";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { View } from "models/Header";

export const DataTestIds = {
  audioPlayer: "audio-player",
  audioProgressBar: "audio-player-progress-bar",
  playButton: "audio-play",
  pauseButton: "audio-pause",
  skipButton: "audio-skip",
  rewindButton: "audio-rewind",
  closeButton: "audio-close",
  speedControlButton: "speed-control",
  speedOptions: "speed-option",
  refocusButton: "recenter-button",
  collapseAudioButton: "audio-collapse",
  playerControls: "recording-player-controls",
};

const POSSIBLE_SPEEDS = [0.75, 1, 1.25, 1.5, 1.75, 2, 2.5];

const MinuteAndSecond = ({ duration, ...props }) => {
  return (
    <Typography component="span" variant="body-small" {...props}>
      {toMinutesAndSeconds(duration)}
    </Typography>
  );
};

export const AlphaNowAudioPlayer = ({
  controlsProps,
  progressBarProps,
  playbackProps,
  disabled = false,
  isError,
  isLoading,
  audioError,
  projectToken,
  recordingId,
  origin,
  variant,
  onClose,
  setAutoScroll,
  onClickRefocus,
  playerVisible,
  setViewMode,
  contentId,
}) => {
  const { spacing, shape, color } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const [playerOpen, setIsPlayerOpen] = useState(true);

  useEffect(() => {
    if (!disabled && controlsProps.playing) {
      setAutoScroll && setAutoScroll(true);
    }
  }, [disabled, controlsProps.playing, origin, projectToken, recordingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const progressBar = (
    <ProgressBar
      progressBarProps={{
        ...progressBarProps,
        onMouseUp: (event) => {
          controlsProps.playing && setAutoScroll && setAutoScroll(true);
          progressBarProps.onMouseUp(event);
        },
      }}
      disabled={disabled}
    />
  );

  return (
    <x.div
      display="flex"
      flexDirection={isMobile && playerOpen ? "column" : "row"}
      p={{
        xs: spacing.inner.base06,
        sm: `${spacing.inner.base04} 0 ${spacing.inner.base04} ${spacing.inner.base08}`,
      }}
      bg="white"
      borderTopWidth={shape.border.width.sm}
      borderTopColor={color.border.neutral.default}
      data-testid={DataTestIds.audioPlayer}
      alignItems="center"
    >
      <Typography variant="body-small">{isError && (audioError || "Recording file no longer available.")}</Typography>
      <RecordingPlayerControls
        playing={controlsProps.playing}
        isLoading={isLoading}
        onChangeSpeed={controlsProps.onChangeSpeed}
        onClickRewind={controlsProps.onClickRewind(30)}
        onClickForward={controlsProps.onClickForward(30)}
        onClose={onClose}
        onTogglePlay={controlsProps.onTogglePlay}
        disabled={disabled}
        newControls={true}
        isError={isError}
        variant={variant}
        onClickRefocus={onClickRefocus}
        playerVisible={playerVisible}
        setViewMode={setViewMode}
        contentId={contentId}
        playerOpen={playerOpen}
        setIsPlayerOpen={setIsPlayerOpen}
      />

      {!isError && (
        <x.div
          display="flex"
          alignItems="center"
          flexDirection="row"
          flex={1}
          justifyContent="center"
          pr={!playerOpen ? spacing.inner.base12 : 0}
          m={{
            xs: `${playerOpen ? spacing.inner.base06 : 0} ${spacing.inner.base04} 0 ${spacing.inner.base04}`,
            sm: `0 258px 0 ${spacing.inner.base04}`,
          }}
          gap={2}
          data-testid={DataTestIds.audioProgressBar}
          color={color.text.secondary}
          w={{ xs: "100%", sm: "auto" }}
        >
          <MinuteAndSecond duration={playbackProps.currentTime} />
          {progressBar}
          <MinuteAndSecond duration={playbackProps.duration} />
          {isMobile && (
            <CloseButton
              variant="basic"
              size="large"
              onClick={() => setViewMode(View.Transcript)}
              contentId={contentId}
              color={color.icon.secondary}
            />
          )}
        </x.div>
      )}
    </x.div>
  );
};

const PlayButton = (props) => (
  <IconButton {...props} testId={DataTestIds.playButton}>
    <PlayArrow />
  </IconButton>
);
const PauseButton = (props) => (
  <IconButton {...props} testId={DataTestIds.pauseButton}>
    <Pause />
  </IconButton>
);
const FastForwardButton = (props) => (
  <IconButton {...props} testId={DataTestIds.skipButton}>
    <Forward30 />
  </IconButton>
);

const RewindButton = (props) => (
  <IconButton {...props} testId={DataTestIds.rewindButton}>
    <Replay30 />
  </IconButton>
);

export const CloseButton = ({ onClick, contentId, ...props }) => {
  const { logHit } = useTrackUserAction();
  const handleCloseAudio = () => {
    onClick();
    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowAudioDisabled,
      details: { contentId },
    });
  };

  return (
    <IconButton testId={DataTestIds.closeButton} onClick={handleCloseAudio} {...props}>
      <Close />
    </IconButton>
  );
};

export const PlaySpeedButton = ({ onPlaySpeedSelected, playerVisible, contentId, ...props }) => {
  const {
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
  } = useRecordingPlayerStyles();
  const { logHit } = useTrackUserAction();
  const { spacing } = useThemeTokens();
  const [speed, setSpeed] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget.lastElementChild);
    } else {
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    onPlaySpeedSelected(newSpeed);
    handlePopoverClose();

    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowAudioSpeedChange,
      details: { contentId: contentId, selectedSpeed: newSpeed },
    });
  };

  return (
    <>
      <Button
        onClick={handlePopoverOpen}
        {...props}
        data-testid={DataTestIds.speedControlButton}
        w={spacing.layout.base05}
      >
        {speed}x
      </Button>
      {playerVisible && (
        <Popover
          ref={ref}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          placement="top"
          zIndex="2"
          position="fixed"
          {...popoverStyles}
        >
          <x.div {...playbackSpeedOptionsStyles}>
            {POSSIBLE_SPEEDS.map((speedOption) => (
              <Button
                key={speedOption}
                variant="ghost"
                onClick={() => handleSpeedChange(speedOption)}
                endIcon={
                  speed === speedOption && (
                    <IconAds color="secondary">
                      <Tick />
                    </IconAds>
                  )
                }
                {...playbackSpeedStyles}
                {...(speed === speedOption && selectedPlaybackSpeedStyles)}
              >
                <Typography variant="body" data-testid={DataTestIds.speedOptions}>
                  {speedOption}x
                </Typography>
              </Button>
            ))}
          </x.div>
        </Popover>
      )}
    </>
  );
};

const ProgressBar = ({ progressBarProps }) => {
  const { color, shape, spacing } = useThemeTokens();
  const { duration, value, draggingValue, onMouseDown, onMouseUp, onMouseMove } = progressBarProps;

  const progress = duration === 0 ? 0 : ((draggingValue || value) / duration) * 100;

  return (
    <div
      style={{ width: "-webkit-fill-available" }}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <x.div
        cursor="pointer"
        backgroundColor={color.background.neutral.default}
        borderRadius={shape.border.radius.medium}
      >
        <x.div
          bg={color.border.info}
          style={{
            width: `${progress}%`,
            height: spacing.inner.base02,
          }}
          borderRadius={shape.border.radius.medium}
        />
      </x.div>
    </div>
  );
};

// TODO CON-2483 - create a mobile view version for this component
export const RecordingPlayerControls = ({
  playing,
  isLoading,
  onChangeSpeed,
  onClickRewind,
  onClickForward,
  onTogglePlay,
  disabled,
  isError,
  onClickRefocus,
  playerVisible,
  setViewMode,
  contentId,
  playerOpen,
  setIsPlayerOpen,
}) => {
  const { isMobile } = useCheckScreen();
  const { spacing, color } = useThemeTokens();
  const handleKeyDown = useCallback(
    (event) => {
      let shortcutFn;
      switch (event.code) {
        case "Space":
          shortcutFn = onTogglePlay;
          break;
        case "ArrowLeft":
          shortcutFn = onClickRewind;
          break;
        case "ArrowRight":
          shortcutFn = onClickForward;
          break;
        default:
          return;
      }

      event.preventDefault();
      shortcutFn();
    },
    [onTogglePlay, onClickForward, onClickRewind]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const audioButtonProps = {
    variant: "outline",
    size: isMobile ? "large" : "medium",
    color: color.icon.secondary,
  };

  const speedButton = (
    <PlaySpeedButton
      onPlaySpeedSelected={onChangeSpeed}
      disabled={disabled}
      playerVisible={playerVisible}
      contentId={contentId}
      {...audioButtonProps}
    />
  );

  return (
    <div data-testid={DataTestIds.playerControls}>
      {isLoading ? (
        <Loading size="sm" />
      ) : (
        <>
          {!isError && (
            <x.div gap={spacing.inner.base04} display="flex">
              {isMobile && playerOpen && speedButton}
              {playerOpen && <RewindButton onClick={onClickRewind} disabled={disabled} {...audioButtonProps} />}
              {playing ? (
                <PauseButton onClick={onTogglePlay} color={color.icon.secondary} {...audioButtonProps} />
              ) : (
                <PlayButton onClick={onTogglePlay} disabled={disabled} {...audioButtonProps} />
              )}
              {playerOpen && <FastForwardButton onClick={onClickForward} disabled={disabled} {...audioButtonProps} />}
              <x.div
                position={!(isMobile && playerOpen) ? "absolute" : "initial"}
                right={isMobile ? spacing.inner.base06 : spacing.inner.base08}
                gap={spacing.inner.base04}
                display="flex"
              >
                {!isMobile && (
                  <>
                    <Button
                      onClick={onClickRefocus}
                      px={spacing.inner.base04}
                      data-testid={DataTestIds.refocusButton}
                      {...audioButtonProps}
                    >
                      Refocus
                    </Button>
                    {speedButton}
                    <CloseButton
                      onClick={() => setViewMode(View.Transcript)}
                      contentId={contentId}
                      {...audioButtonProps}
                    />
                  </>
                )}
                {isMobile && (
                  <IconButton
                    testId={DataTestIds.collapseAudioButton}
                    onClick={() => setIsPlayerOpen(!playerOpen)}
                    {...audioButtonProps}
                  >
                    {playerOpen ? <ChevronDown /> : <ChevronUp />}
                  </IconButton>
                )}
              </x.div>
            </x.div>
          )}
        </>
      )}
    </div>
  );
};
