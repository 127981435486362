import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import {
  Alert,
  Button,
  ButtonProps,
  Icon,
  IconButton,
  ListOption,
  Modal,
  Popover,
  RoundedButton,
  TextArea,
  TextField,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import { Add, Angle, Delete, Edit } from "@alphasights/alphadesign-icons";
import { ExpertCompareQuestion } from "models/ExpertCompare";
import { useEditQuestionsModalStyles } from "./EditQuestionsModal.styles";
import { CreateQuestionRequest, DeleteQuestionRequest, UpdateQuestionsResponse } from "services/expertCompareService";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useTrackUserAction, useNewNavigation } from "@alphasights/client-portal-shared";
import { Hit, HitAction, HitOrigin } from "@alphasights/portal-api-client";

const uuid = require("uuid");

export const EditQuestionsButton = ({
  selectedAngle,
  questions,
  angles,
}: {
  selectedAngle: FilterOption;
  questions: ExpertCompareQuestion[];
  angles: FilterOption[];
}) => {
  const [open, setOpen] = useState(false);
  const newNavigationEnabled = useNewNavigation();

  return (
    <>
      <Button
        size={newNavigationEnabled ? "small" : "medium"}
        onClick={() => setOpen(true)}
        variant="outline"
        startIcon={<Edit />}
        dataAttributes={{ "data-testid": "edit-questions-button" }}
      >
        Edit Questions
      </Button>
      {open && (
        <EditQuestionsModal
          selectedAngle={selectedAngle}
          questions={questions}
          angles={angles}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export const EditQuestionsModal = ({
  selectedAngle: selectedAngleInput,
  questions: questionsInput,
  angles,
  onClose,
}: {
  selectedAngle: FilterOption;
  questions: ExpertCompareQuestion[];
  angles: FilterOption[];
  onClose: () => void;
}) => {
  const {
    modalTitle,
    clearChangesButton,
    questionsDiv,
    addQuestionButton,
    addQuestionAlert,
  } = useEditQuestionsModalStyles();
  const { fetchQuestions, updateQuestions } = useExpertCompareContext();
  const { project } = useCurrentProjectContext();
  const addQuestionButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedAngle, setSelectedAngle] = useState(selectedAngleInput);
  const [addedQuestions, setAddedQuestions] = useState<CreateQuestionRequest[]>([]);
  const [deletedQuestions, setDeletedQuestions] = useState<DeleteQuestionRequest[]>([]);
  const [questions] = useState(questionsInput);

  const { bulkLogHit, logHit } = useTrackUserAction();

  const angleQuestions = useMemo(() => questions.filter((question) => question.angle.id === selectedAngle.id), [
    questions,
    selectedAngle.id,
  ]);

  const addedAngleQuestions = useMemo(
    () => addedQuestions.filter((question) => question.angleId === selectedAngle.id),
    [addedQuestions, selectedAngle.id]
  );

  const deleteAngleQuestions = useMemo(
    () => deletedQuestions.filter((question) => question.angleId === selectedAngle.id),
    [deletedQuestions, selectedAngle.id]
  );

  const logHitEditedQuestions = useCallback(
    (editedQuestions: UpdateQuestionsResponse) => {
      const addedQuestionsHits: Hit[] = editedQuestions.addedIds.map((questionId) => ({
        projectToken: project?.token,
        origin: HitOrigin.comparisonView,
        action: HitAction.comparisonViewAddQuestion,
        details: { questionId },
      }));

      const deletedQuestionsHits: Hit[] = editedQuestions.deletedIds.map((questionId) => ({
        projectToken: project?.token,
        origin: HitOrigin.comparisonView,
        action: HitAction.comparisonViewDeleteQuestion,
        details: { questionId },
      }));

      bulkLogHit([...addedQuestionsHits, ...deletedQuestionsHits]);

      return editedQuestions;
    },
    [bulkLogHit, project]
  );

  useEffect(function logHitOpenModal() {
    logHit({
      projectToken: project?.token,
      origin: HitOrigin.comparisonView,
      action: HitAction.comparisonViewOpenEditQuestionsModal,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addQuestion = () => {
    setAddedQuestions((prev: CreateQuestionRequest[]) => [
      ...prev,
      { id: uuid(), text: "", angleId: selectedAngle.id! },
    ]);
    setTimeout(() => {
      addQuestionButtonRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  };

  const clearChanges = () => {
    setAddedQuestions([]);
    setDeletedQuestions([]);
  };

  const onAngleSelected = (selectedAngle: FilterOption) => {
    setSelectedAngle(selectedAngle);
  };

  const editAddedQuestion = (e: FormEvent<HTMLInputElement>, questionId: number) => {
    const value = e.currentTarget.value;
    setAddedQuestions((prev: CreateQuestionRequest[]) => {
      prev.find((question) => question.id === questionId)!.text = value;
      return [...prev];
    });
  };

  const deleteAddedQuestion = (questionId: number) => {
    setAddedQuestions((prev: CreateQuestionRequest[]) => {
      const index = prev.findIndex((question) => question.id === questionId);
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const deleteQuestion = (question: DeleteQuestionRequest) => {
    setDeletedQuestions((prev: DeleteQuestionRequest[]) => [...prev, question]);
  };

  const saveChanges = () => {
    updateQuestions({
      toAdd: addedQuestions.filter((question) => question.text.trim()),
      toDelete: deletedQuestions,
    })
      .then(logHitEditedQuestions)
      .then(() => {
        fetchQuestions();
        onClose();
        clearChanges();
      });
  };

  const disableDelete = addedAngleQuestions.length + (angleQuestions.length - deleteAngleQuestions.length) <= 1;

  const Title = () => (
    <x.div {...modalTitle}>
      <Typography variant="h3" color="text.primary" style={{ textTransform: "none" }} lineHeight="200px">
        Edit Questions
      </Typography>
      <SelectAngleButton selectedAngle={selectedAngle} angles={angles} onAngleSelected={onAngleSelected} />
    </x.div>
  );

  return (
    <>
      <Modal
        open
        title={<Title />}
        onClose={onClose}
        slotWidth="865px"
        slotHeight="292px"
        variant="complex"
        shouldShowFooter={true}
        primaryButton={
          <Button onClick={saveChanges} variant="secondary" dataAttributes={{ "data-testid": "save-changes-button" }}>
            Save Changes
          </Button>
        }
        secondaryButton={
          <Button
            {...clearChangesButton}
            variant="ghost"
            onClick={clearChanges}
            dataAttributes={{ "data-testid": "clear-changes-button" }}
          >
            Clear Changes
          </Button>
        }
        zIndex={3}
      >
        <x.div {...questionsDiv} data-testid="questions-list">
          {angleQuestions
            .filter((question) => !deletedQuestions.map((question) => question.id).includes(question.id))
            .map((question: ExpertCompareQuestion) => (
              <Question
                key={question.id}
                question={question}
                onDeleteQuestion={deleteQuestion}
                disableDelete={disableDelete}
              />
            ))}

          {addedAngleQuestions.length > 0 && (
            <>
              {addedAngleQuestions.map((question: CreateQuestionRequest) => {
                return (
                  <AddedQuestion
                    question={question}
                    editAddedQuestion={editAddedQuestion}
                    deleteAddedQuestion={deleteAddedQuestion}
                    disableDelete={disableDelete}
                  />
                );
              })}

              <Alert variant="info" {...addQuestionAlert}>
                Adding a new question will search for existing information in each expert's relevance statement.
              </Alert>
            </>
          )}
          <Button
            ref={addQuestionButtonRef}
            startIcon={<Add />}
            variant="outline"
            onClick={addQuestion}
            dataAttributes={{ "data-testid": "add-question-button" }}
            {...addQuestionButton}
          >
            Add Question
          </Button>
        </x.div>
      </Modal>
    </>
  );
};

const AddedQuestion = ({
  question,
  editAddedQuestion,
  deleteAddedQuestion,
  disableDelete,
}: {
  question: CreateQuestionRequest;
  editAddedQuestion: (e: FormEvent<HTMLInputElement>, questionId: number) => void;
  deleteAddedQuestion: (questionId: number) => void;
  disableDelete: boolean;
}) => {
  const { addedQuestionsDiv, questionTextField } = useEditQuestionsModalStyles();

  return (
    <x.div key={question.id} {...addedQuestionsDiv}>
      <x.div {...questionTextField}>
        <TextField
          label="Question"
          value={question.text}
          onChange={(e: FormEvent<HTMLInputElement>) => editAddedQuestion(e, question.id!)}
          placeholder="Write your question here..."
          dataAttributes={{ "data-testid": "add-question-field" }}
        />
      </x.div>
      <DeleteDisabledTooltip disableDelete={disableDelete}>
        <IconButton
          onClick={() => deleteAddedQuestion(question.id!)}
          variant="outline"
          dataAttributes={{ "data-testid": "delete-added-question-button" }}
          disabled={disableDelete}
        >
          <Delete />
        </IconButton>
      </DeleteDisabledTooltip>
    </x.div>
  );
};

const SelectAngleButton = ({
  selectedAngle,
  angles,
  onAngleSelected,
}: {
  selectedAngle: FilterOption;
  angles: FilterOption[];
  onAngleSelected: (selectedAngle: FilterOption) => void;
}) => {
  const { anglesPopover } = useEditQuestionsModalStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>();
  const ref = React.useRef(null);
  const handleClick = (event: React.MouseEvent) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  const parentAngle = angles.find((angle) => angle.children?.map((angle) => angle.id).includes(selectedAngle.id));

  return (
    <>
      <RoundedButton
        variant="filter"
        startIcon={<Angle />}
        onClick={handleClick}
        dataAttributes={{ "data-testid": "select-angle-button" }}
      >
        <p>
          <Typography variant="body-small-em" color="secondary" component="span">
            {(parentAngle ?? selectedAngle).label}
          </Typography>
          {parentAngle && (
            <Typography variant="body-small-em" color="info" component="span">
              {` ${selectedAngle.label}`}
            </Typography>
          )}
        </p>
      </RoundedButton>
      <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClose} {...anglesPopover}>
        <x.ul data-testid="angles-list">
          {angles.map((angle: FilterOption) => (
            <>
              <ListOption
                type="text"
                label={angle.label}
                size="small"
                onChange={() => onAngleSelected(angle)}
                dataAttributes={{ "data-testid": `angle-option-${angle.id}` }}
                key={`angle-${angle.id}`}
                selected={angle.id === selectedAngle.id}
              />
              {angle.children?.map((subAngle: FilterOption) => (
                <ListOption
                  type="text"
                  label={subAngle.label}
                  size="small"
                  onChange={() => onAngleSelected(subAngle)}
                  indent={1}
                  dataAttributes={{ "data-testid": `angle-option-${subAngle.id}` }}
                  key={`angle-${subAngle.id}`}
                  selected={subAngle.id === selectedAngle.id}
                />
              ))}
            </>
          ))}
        </x.ul>
      </Popover>
    </>
  );
};

const Question = ({
  question,
  onDeleteQuestion,
  disableDelete,
}: {
  question: ExpertCompareQuestion;
  onDeleteQuestion: (question: DeleteQuestionRequest) => void;
  disableDelete: boolean;
}) => {
  const { questionListOption, deleteQuestionIcon, textContainer } = useEditQuestionsModalStyles({ disableDelete });

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>();

  const onClickDelete = (event: React.MouseEvent) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };

  return (
    <x.div
      onMouseLeave={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      data-testid="question-option"
    >
      <QuestionListOptionStyled type="text">
        <x.div {...questionListOption}>
          <x.div {...textContainer}>{question.text}</x.div>
          {(isHovered || anchorEl) && (
            <DeleteDisabledTooltip disableDelete={disableDelete}>
              <Icon
                onClick={disableDelete ? undefined : onClickDelete}
                dataAttributes={{ "data-testid": "delete-question-button" }}
                {...deleteQuestionIcon}
              >
                <Delete />
              </Icon>
            </DeleteDisabledTooltip>
          )}
        </x.div>
      </QuestionListOptionStyled>

      <DeleteQuestionPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        question={question}
        onDeleteQuestion={onDeleteQuestion}
      />
    </x.div>
  );
};

const DeleteQuestionPopover = ({
  anchorEl,
  setAnchorEl,
  question,
  onDeleteQuestion,
}: {
  anchorEl: Element | undefined;
  setAnchorEl: (anchorEl: Element | undefined) => void;
  question: ExpertCompareQuestion;
  onDeleteQuestion: (question: DeleteQuestionRequest) => void;
}) => {
  const open = Boolean(anchorEl);
  const [reason, setReason] = useState<string | undefined>();
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const primaryButtonProps: ButtonProps = {
    variant: "secondary",
    size: "medium",
    children: "Delete",
    onClick: () => onDeleteQuestion({ id: question.id, reason, angleId: question.angle.id }),
    dataAttributes: { "data-testid": "delete-question-confirmation-button" },
  };

  const secondaryButtonProps: ButtonProps = {
    variant: "ghost",
    size: "medium",
    children: "Cancel",
    w: "95px",
    onClick: handleClose,
  };

  const onChangeOther = ({ isSelected }: { isSelected: boolean }) => {
    setIsOtherSelected(isSelected);
  };

  return (
    <DeleteQuestionPopoverStyled
      portal
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      buttonPrimaryProps={primaryButtonProps}
      buttonSecondaryProps={secondaryButtonProps}
      data-testid="delete-question-popover"
    >
      <ul>
        <ListOption type="title" label="Why do you want to remove this question?" size="small" />
        <ListOption
          type="text"
          label="Not relevant"
          inputType="checkbox"
          size="small"
          onChange={() => setReason("Not relevant")}
          dataAttributes={{ "data-testid": "not-relevant-reason" }}
        />
        <ListOption
          type="text"
          label="Project direction has changed"
          inputType="checkbox"
          size="small"
          onChange={() => setReason("Project direction has changed")}
          dataAttributes={{ "data-testid": "project-direction-changed-reason" }}
        />
        <ListOption
          type="text"
          label="Other"
          inputType="checkbox"
          onChange={onChangeOther}
          size="small"
          dataAttributes={{ "data-test-id": "other-reason" }}
        />
        {isOtherSelected && (
          <TextArea
            placeholder="Provide your reason here..."
            height="80px"
            width="-webkit-fill-available"
            onChange={(e) => setReason(e.target.value)}
          />
        )}
      </ul>
    </DeleteQuestionPopoverStyled>
  );
};

const DeleteDisabledTooltip = ({ disableDelete, children }: { disableDelete: boolean; children: JSX.Element }) => {
  return (
    <Tooltip title="Question cannot be deleted until another question has been added." disabled={!disableDelete}>
      {children}
    </Tooltip>
  );
};

const DeleteQuestionPopoverStyled = styled(Popover)`
  width: 224px;
`;

const QuestionListOptionStyled = styled(ListOption)`
  & > div:first-child,
  & > div:first-child > div:last-child {
    width: 100%;
  }
  p {
    width: 100%;
  }
`;
