import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useNewNavigation } from "@alphasights/client-portal-shared";

export const useProjectLaunchPageStyles = () => {
  const newNavigationEnabled = useNewNavigation();
  const {
    spacing,
    color: { background },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      pb: spacing.layout.base03,
    },
    header: {
      position: "sticky",
      top: 0,
      pt: spacing.layout.base03,
      zIndex: 1,
      background: newNavigationEnabled ? background.surface.recessed : background.inverse,
    },
    mainContent: {
      w: "1026px",
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
    },
    projectDetails: {
      gap: spacing.inner.base04,
    },
    detailsContent: {
      display: "flex",
      flexDirection: "column",
      w: "664px",
      flex: 1,
      gap: spacing.inner.base,
    },
    searchContent: {
      flex: 1,
      w: 357,
      h: "100%",
    },
    sideBySideContainer: {
      display: "flex",
      alignItems: "stretch",
    },
    sideBySidePanel: {
      flex: 1,
      flexGrow: 1,
      marginRight: 1,
    },
  };
};
