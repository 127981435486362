import { noop } from "lodash";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import "./highlightProvider.css";
import { findElements, focus, getElementValues, traverseArray } from "utils/htmlElement";

export interface HighlightHtmlContextState {
  currentHighlight: HTMLElement | null;
  getHighlightDataValues: (el: HTMLElement) => string[];
  selectNextHighlight: () => void;
  selectPreviousHighlight: () => void;
  selectHighlight: (el: HTMLElement | null) => void;
  highlights: HTMLElement[];
  setCriteriaValue: (val: string | null) => void;
  highlightCriteria: HighlightCriteria | null;
  setOnSelectCallback: (callback: (el: HTMLElement | null) => void) => void;
  focus: (el: HTMLElement) => void;
}

interface HighlightCriteria {
  dataField: string;
  divider?: string;
}

const HighlightHtmlContext = React.createContext<HighlightHtmlContextState>({
  currentHighlight: null,
  selectNextHighlight: () => null,
  selectPreviousHighlight: () => null,
  selectHighlight: () => null,
  highlights: [],
  focus: () => null,
  highlightCriteria: null,
  setOnSelectCallback: () => null,
  getHighlightDataValues: () => [],
  setCriteriaValue: () => null,
});

export const HighlightHtmlProvider = ({
  children,
  highlightCriteria,
}: {
  children: JSX.Element;
  highlightCriteria: HighlightCriteria;
}) => {
  const [criteriaValue, setCriteriaValue] = useState<string | null>(null);
  const [highlights, setHighlights] = useState<HTMLElement[]>([]);
  const [currentHighlight, setCurrentHighlight] = useState<HTMLElement | null>(null);
  const [onSelectCallback, setOnSelectCallback] = useState<(highlight: HTMLElement | null) => void>(() => noop);
  const rootRef = useRef(null);

  useEffect(
    function updateHighlightsPaint() {
      const previouslyHighlighted = findElements(rootRef.current!, { dataField: "highlight-level" });
      clearHighlights(previouslyHighlighted);
      paintHighlights(highlights);
    },
    [highlights]
  );

  useEffect(
    function updateSelectedHighlightPaint() {
      const previouslySelected = findElements(rootRef.current!, { dataField: "highlight-level" }, "2");
      paintHighlights(previouslySelected);
      currentHighlight && updateElementHighlight(currentHighlight, 2);
    },
    [currentHighlight, highlights]
  );

  useEffect(
    function focusCurrentHighlight() {
      focus(currentHighlight);
    },
    [currentHighlight]
  );

  const selectHighlight = useCallback(
    (highlight: HTMLElement | null) => {
      setCurrentHighlight((previouslySelected) => {
        const highlightWithNoValidValue = highlight && !getElementValues(highlight, highlightCriteria).length;
        if (highlightWithNoValidValue) {
          return previouslySelected;
        }
        onSelectCallback(highlight);
        return highlight;
      });
    },
    [setCurrentHighlight, onSelectCallback, highlightCriteria]
  );

  useEffect(
    function onCriteriaChangeUpdateHighlightsAndSelectOne() {
      const foundElements = criteriaValue ? findElements(rootRef.current!, highlightCriteria, criteriaValue) : [];
      setHighlights(foundElements);

      const idx = currentHighlight ? foundElements.indexOf(currentHighlight) : 0;
      selectHighlight(foundElements[Math.max(idx, 0)]);
    },
    [highlightCriteria, criteriaValue, selectHighlight] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const navigateHighlight = useCallback(
    (diff: number) => {
      if (!highlights.length) {
        return;
      }

      const newHighlight = traverseArray(highlights, currentHighlight, diff);
      selectHighlight(newHighlight);
    },
    [currentHighlight, highlights, selectHighlight]
  );

  const getHighlightDataValues = useCallback(
    (highlight: HTMLElement) => getElementValues(highlight, highlightCriteria),
    [highlightCriteria]
  );

  const value = {
    currentHighlight,
    selectNextHighlight: useCallback(() => navigateHighlight(1), [navigateHighlight]),
    selectPreviousHighlight: useCallback(() => navigateHighlight(-1), [navigateHighlight]),
    selectHighlight,
    highlights,
    focus,
    setCriteriaValue,
    highlightCriteria,
    setOnSelectCallback,
    getHighlightDataValues,
  };

  return (
    <HighlightHtmlContext.Provider value={value}>
      <x.div ref={rootRef}>{children}</x.div>
    </HighlightHtmlContext.Provider>
  );
};

export const useHighlightHtmlContext = () => {
  return useContext(HighlightHtmlContext);
};

const updateElementHighlight = (el: HTMLElement, highlightLevel: number) => {
  if (highlightLevel > 0) {
    el.dataset.highlightLevel = highlightLevel.toString();
  } else {
    delete el.dataset.highlightLevel;
  }
};

const paintHighlights = (els: HTMLElement[]) => {
  els.forEach((el) => updateElementHighlight(el, 1));
};

const clearHighlights = (els: HTMLElement[]) => {
  els.forEach((el) => updateElementHighlight(el, 0));
};
