import React from "react";
import {
  Market,
  MarketPill as BaseMarketPill,
  SEARCH_SUGGESTION_TYPES,
  useDynamicChildren,
} from "@alphasights/client-portal-shared";
import { PillProps, useThemeTokens } from "@alphasights/alphadesign-components";
import { buildSearchQuery, getMarketsFromSearchQuery } from "content/AlphaNow/utils";
import { AlphaNowSearchQuery } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { measureTextWidth } from "pages/AlphaNowPage/utils";
import { LEFT_ACCESSORY_GAP, PILL_LEFT_ACCESSORY_WIDTH } from "./consts";

type MarketPillProps = {
  market: Market;
  containerRef?: React.RefObject<HTMLInputElement>;
} & PillProps;

const MarketPill = ({ market, containerRef }: MarketPillProps) => {
  const {
    updateQuery,
    query: { searchQuery },
  } = useAppSearchContext();

  const {
    typography: { body },
  } = useThemeTokens();

  const searchMarketIds = (getMarketsFromSearchQuery(searchQuery) as AlphaNowSearchQuery[]).map(({ id }) => id);
  const isHighlighted = searchMarketIds.includes(market?.id);
  const marketPillWidth =
    PILL_LEFT_ACCESSORY_WIDTH +
    LEFT_ACCESSORY_GAP +
    measureTextWidth(market?.name, `${body.regular.fontSize} ${body.regular.fontFamily}`);

  const { minimizeChild: isMinimized } = useDynamicChildren({
    containerRef,
    collapsedChildWidth: PILL_LEFT_ACCESSORY_WIDTH,
    childWidth: marketPillWidth,
  });

  const handleSelectMarket = () => {
    const newSearchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Market,
      value: market.name,
      id: market.id,
    });
    updateQuery({
      selectedContentId: undefined,
      searchQuery: newSearchQuery,
    });
  };

  return (
    <BaseMarketPill
      name={market.name}
      highlight={isHighlighted}
      isInteractive
      onClick={handleSelectMarket}
      isMinimized={isMinimized}
    />
  );
};

export { MarketPill as default };
