import _ from "lodash";
import { Experience } from "models/Interaction";

export const EXPERIENCE_DESCRIPTION = {
  [Experience.Uses]: "Uses",
  [Experience.Churned]: "Churned",
  [Experience.Evaluated]: "Evaluated, did not select",
  [Experience.Aware]: "Aware of",
  [Experience.Unaware]: "Unaware of",
};

export const IN_ORDER_EXPERIENCES = [
  Experience.Uses,
  Experience.Churned,
  Experience.Evaluated,
  Experience.Aware,
  Experience.Unaware,
];

export const getUniqCustomerKnowledges = (customerKnowledges: CustomerKnowledge[]) => {
  return _.uniqBy(customerKnowledges, (value) => value.vendor.id + value.experience);
};

export const buildExperienceTooltipCopy = (experience: Experience, customerKnowledges: CustomerKnowledge[]) => {
  if (!customerKnowledges?.length) return null;
  const vendorNames = customerKnowledges?.map((ck) => ck.vendor.name);
  return `${EXPERIENCE_DESCRIPTION[experience]} ${
    vendorNames.length > 1
      ? `${vendorNames.slice(0, -1).join(", ")} and ${vendorNames[vendorNames.length - 1]}`
      : vendorNames[0]
  }`;
};

export const hasCustomerExperts = (allInteractions: Interaction[]) => {
  return allInteractions?.some((i) => i.angles.some((angle) => angle?.type?.name === "End Customers"));
};

export const hasSufficientCustomerKnowledge = (experts?: { angleTypeName: string }[]) => {
  const endCustomers = experts?.filter((expert) => expert.angleTypeName === "End Customers") ?? [];
  if (!endCustomers.length) return false;

  const endCustomersWithEnoughCks = endCustomers.filter(
    (expert: any) => Math.max(...expert.interactions.map((i: Interaction) => i.customerKnowledges.length)) >= 3
  );

  const validPercentil = endCustomersWithEnoughCks.length / endCustomers.length;
  return validPercentil >= 0.65;
};

export const isEndCustomerInteraction = (interaction: Interaction) => {
  return interaction.angles?.some((angle) => angle?.type?.name === "End Customers");
};
