import React from "react";
import { ToggleButtonGroup, ToggleButton as AdsToggleButton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useTrackUserAction, useNewNavigation } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import * as S from "./CustomerKnowledgeViewToggler.styled";
import { ToggleButton } from "components/ToggleButton/ToggleButton";

export type CustomerKnowledgeViewTogglerProps = {
  onChangeLayout: (layout: string) => void;
  currentView?: string;
};

export const CustomerKnowledgeViewToggler = ({
  onChangeLayout,
  currentView = "customer-view",
}: CustomerKnowledgeViewTogglerProps) => {
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();
  const newNavigationEnabled = useNewNavigation();

  const handleOnChangeLayout = (layout: string) => {
    if (layout === "table-view") {
      logHit({
        projectToken: project!.token,
        origin: HitOrigin.customerView,
        action: HitAction.allExpertsTabClicked,
      });
    } else if (layout === "customer-view") {
      logHit({
        projectToken: project!.token,
        origin: HitOrigin.tableView,
        action: HitAction.customerKnowledgeTabClicked,
      });
    }
    onChangeLayout(layout);
  };

  return newNavigationEnabled ? (
    <S.Wrapper role="listbox">
      <ToggleButton
        title="All"
        onClick={() => handleOnChangeLayout("table-view")}
        selected={currentView === "table-view"}
        data-testid="all-experts-toggle"
      />
      <ToggleButton
        title="Customers"
        onClick={() => handleOnChangeLayout("customer-view")}
        selected={currentView === "customer-view"}
        data-testid="customers-toggle"
      />
    </S.Wrapper>
  ) : (
    <x.div display={"flex"} alignItems={"center"}>
      <ToggleButtonGroup value={currentView} allowUnselection={false}>
        <AdsToggleButton
          value={"table-view"}
          onClick={() => handleOnChangeLayout("table-view")}
          data-testid="all-experts-toggle"
          style={{ whiteSpace: "nowrap" }}
        >
          All Experts
        </AdsToggleButton>
        <AdsToggleButton
          value={"customer-view"}
          onClick={() => handleOnChangeLayout("customer-view")}
          data-testid="customers-toggle"
          style={{ whiteSpace: "nowrap" }}
        >
          Customers
        </AdsToggleButton>
      </ToggleButtonGroup>
    </x.div>
  );
};
