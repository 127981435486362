import React, { useState, useEffect } from "react";
import { ContentPurchaseStatus } from "@alphasights/client-portal-shared";
import { AlphaNowPurchasableHeader } from "pages/AlphaNowPage/components";
import { differenceInMilliseconds, parseISO } from "date-fns";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { ErrorBanner, ErrorType } from "./ErrorBanner";
import { ContentSuggestion } from "./types";
import { Divider } from "@alphasights/alphadesign-components";

export interface PurchaseInDeliverablesProps {
  suggestion: ContentSuggestion;
  updateDeliverableSuggestion: (contentId: string, changes: any) => void;
  isContentFlyout: boolean;
  fetchSuggestions?: () => Promise<void>;
}

export const PurchaseInDeliverables = ({
  suggestion,
  updateDeliverableSuggestion,
  isContentFlyout = false,
  fetchSuggestions,
}: PurchaseInDeliverablesProps) => {
  const { project } = useCurrentProjectContext();

  const [errorType, setErrorType] = useState<ErrorType | undefined>();

  const [isUpcomingTransitionActive, setIsUpcomingTransitionActive] = useState(false);

  const isContentPreScheduledTime = new Date() < new Date(suggestion.scheduledTimeUTC!!);

  const onError = (error?: string) => {
    if (error === "NotEnoughCreditsLeftException") {
      setErrorType("insufficientCredits");
    } else {
      setErrorType("genericPurchaseError");
    }
  };

  useEffect(() => {
    if (isContentPreScheduledTime) {
      const differenceInMilliSecs = differenceInMilliseconds(parseISO(suggestion.scheduledTimeUTC!!), new Date());

      const fiveMinsInMilliSecs = 300_000;

      if (differenceInMilliSecs >= fiveMinsInMilliSecs) {
        setTimeout(() => setIsUpcomingTransitionActive(true), differenceInMilliSecs - fiveMinsInMilliSecs);
        setTimeout(() => setIsUpcomingTransitionActive(false), differenceInMilliSecs);
      } else {
        setTimeout(() => setIsUpcomingTransitionActive(false), differenceInMilliSecs);
        setIsUpcomingTransitionActive(true);
      }
    }
  }, [suggestion.id, isContentPreScheduledTime, suggestion.scheduledTimeUTC]);

  return (
    <>
      {errorType && <ErrorBanner errorType={errorType} isContentFlyout={isContentFlyout} />}
      <Divider />
      <AlphaNowPurchasableHeader
        content={suggestion}
        contentTitle={suggestion.externalTitle ?? ""}
        price={suggestion.credits}
        onError={onError}
        onPurchaseSuccess={() => {
          fetchSuggestions && fetchSuggestions();
          updateDeliverableSuggestion &&
            updateDeliverableSuggestion(suggestion.id, {
              paymentRequired: false,
              purchaseStatus: ContentPurchaseStatus.Purchased,
            });
        }}
        scheduledTimeUTC={suggestion.scheduledTimeUTC}
        isContentPreScheduledTime={isContentPreScheduledTime}
        isUpcomingTransitionActive={isUpcomingTransitionActive}
        contentApprovalStatus={suggestion.approvalStatus!!}
        contentPurchaseStatus={suggestion.purchaseStatus!!}
        projectToken={project!!.token}
        origin={HitOrigin.deliverablesView}
        size="small"
        handleButtonSize={isContentFlyout ? "small" : "medium"}
        onRequestAccessSuccess={() => fetchSuggestions && fetchSuggestions()}
      />
    </>
  );
};
