import React, { useContext, useMemo, useState } from "react";

interface CurrentWorkstreamContextState {
  project: Project;
  selectedWorkstream: Workstream | undefined;
  selectedSurveyId: string | undefined;
  setSelectedSurveyId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const CurrentWorkstreamContext = React.createContext<undefined | CurrentWorkstreamContextState>(undefined);

export const CurrentWorkstreamProvider = ({
  project,
  workstreamId,
  ...props
}: {
  project: Project;
  workstreamId: string;
}) => {
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();

  const selectedWorkstream = useMemo(() => project.workstreams.find((w) => w.id === workstreamId), [
    workstreamId,
    project.workstreams,
  ]);

  const context = {
    project,
    selectedWorkstream,
    selectedSurveyId,
    setSelectedSurveyId,
  };

  return <CurrentWorkstreamContext.Provider value={context} {...props} />;
};

export const useCurrentWorkstreamContext = () => {
  const context = useContext(CurrentWorkstreamContext);

  if (!context) throw new Error("CurrentWorkstreamContext should only be used within the CurrentWorkstreamProvider");

  return context;
};
