const SECTION_TITLES = {
  Experts: "Experts",
  Competitors: "Competitors",
  Customers: "Customers",
  Primers: "Primers",
  Insights: "Latest Insights",
  RequestSurvey: "Customer Survey",
};

const SECTION_DESCRIPTIONS = {
  [SECTION_TITLES.Experts]: (companyName: string) => `Speak to top industry experts familiar with **${companyName}**.`,
  [SECTION_TITLES.Competitors]: (companyName: string) => `Access research about **${companyName}**'s competitors.`,
  [SECTION_TITLES.Customers]: (companyName: string) => `Access research about **${companyName}**'s select customers.`,
  [SECTION_TITLES.Primers]: (companyName: string) =>
    `Ramp up on **${companyName}**, its market, and customer perspectives.`,
  [SECTION_TITLES.Insights]: () => "Summarized insights from the latest research, powered by Generative AI.",
  [SECTION_TITLES.RequestSurvey]: (companyName: string) =>
    `Build your understanding of how ${companyName} and its competitors are viewed by their customers.`,
};

const SECTION_HELP_MESSAGES = {
  [SECTION_TITLES.RequestSurvey]:
    "Our surveys are typically run with key customer decision makers and users. They cover these topics: NPS score, competitive performance on KPCs, vendor switching and trends in annual spend.",
};

export { SECTION_TITLES, SECTION_DESCRIPTIONS, SECTION_HELP_MESSAGES };
