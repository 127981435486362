export const toSeconds = (timestamp) => {
  if (!timestamp) return null;

  const splitted = timestamp
    .split(":")
    .map((s) => s.split("."))
    .flat()
    .map(parseFloat);

  const [hours, minutes, seconds, milliseconds] = splitted.length === 4 ? splitted : [0, ...splitted];

  return hours * 60 * 60 + minutes * 60 + seconds + milliseconds / 1000;
};

const concatSpace = (text) => {
  if (text.match(/\s$/)) return text;

  return text + " ";
};

const newCue = ({ speaker, position, value, start, end, hasHighlight, aiMatch }) => ({
  id: position,
  speaker,
  timestamp: toSeconds(start),
  cues: [
    {
      id: position,
      text: concatSpace(value),
      startTime: toSeconds(start),
      endTime: toSeconds(end),
      hasHighlight,
      aiMatch,
      start,
      end,
    },
  ],
});

const appendCue = (record, { position, value, start, end, hasHighlight, aiMatch }) => ({
  ...record,
  cues: [
    ...record.cues,
    {
      id: position,
      text: concatSpace(value),
      startTime: toSeconds(start),
      endTime: toSeconds(end),
      hasHighlight,
      aiMatch,
      start,
      end,
    },
  ],
});

export default function adaptedTranscript(sentences, groupConsecutiveSpeakers = true) {
  if (sentences.length === 0) return [];

  const [head, ...tail] = sentences;

  return tail.reduce(
    (acc, record) => {
      const last = acc[acc.length - 1];

      if (last.speaker === record.speaker && groupConsecutiveSpeakers) {
        return [...acc.slice(0, -1), appendCue(last, record)];
      } else {
        return [...acc, newCue(record)];
      }
    },
    [newCue(head)]
  );
}
