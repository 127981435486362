import React from "react";
import { x } from "@xstyled/styled-components";
import { useCompanyCardStyles } from "./ResearchSummarizationCard.styles";
import { CompanyLogo } from "../CompanySearch";
import { ResearchSummary } from "models/ResearchSummary";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";
import { Typography } from "@alphasights/alphadesign-components";

interface ResearchSummarizationCardProps {
  company: ExternalAlphaCompany;
  recommendedResearch: ResearchSummary;
  onClick: (recommendedResearch: ResearchSummary) => void;
}

export const ResearchSummarizationCard: React.FC<ResearchSummarizationCardProps> = ({
  recommendedResearch,
  company,
  onClick,
}) => {
  const { researchCard, title, companyName, description } = useCompanyCardStyles();

  return (
    <x.div {...researchCard} onClick={() => onClick(recommendedResearch)}>
      <x.div {...title}>
        <CompanyLogo logoUrl={company.logo} size="medium" />
        <Typography variant="body-em" color="strong" {...companyName}>
          {company.name}
        </Typography>
      </x.div>
      <Typography color="secondary" {...description}>
        {`${recommendedResearch.primers} Primers • ${recommendedResearch.moderatedCalls} Moderated Calls • ${recommendedResearch.investorLedCalls} Investor-led Calls`}
      </Typography>
    </x.div>
  );
};

export default ResearchSummarizationCard;
