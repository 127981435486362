import React, { useEffect, useState } from "react";
import { ComparisonViewHeader, ComparisonViewHeaderProps } from "./ComparisonViewHeader/ComparisonViewHeader";
import { ComparisonTable } from "./ComparisonTable/ComparisonTable";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useHistory } from "react-router-dom";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useNewNavigation, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { getInitialAngleId, useComparisonViewParams } from "./helpers";

type ComparisonViewProps = {
  interactions: Interaction[];
  loading: boolean;
  selectedCardId?: string;
} & ComparisonViewHeaderProps;

export const ComparisonView = ({
  interactions,
  loading: interactionsLoading,
  selectedCardId: selectedInteractionId,
  onSubmitFilters,
  onToggleFilter,
  onResetAllFilters,
}: ComparisonViewProps) => {
  const {
    allInteractionsForProject,
    onFiltersChange,
    state: { appliedFilters },
  } = useProjectInteractionsContext();
  const {
    loading: loadingQuestions,
    referrerOrigin,
    setReferrerOrigin,
    enableComparisonView,
  } = useExpertCompareContext();
  const history = useHistory();
  const { logHit } = useTrackUserAction();
  const [initialAngleId, setInitialAngleId] = useState<string>();
  const { project } = useCurrentProjectContext();
  const newNavigationEnabled = useNewNavigation();
  const { questions, angles, selectedAngle } = useComparisonViewParams();
  const origin = new URLSearchParams(history.location.search).get("origin");

  useEffect(
    function redirectToTableView() {
      const noQuestions = questions.length === 0 && !loadingQuestions;
      if (!enableComparisonView || noQuestions) {
        history.replace(`/${project!.token}/experts/table-view`);
      }
    },
    [history, loadingQuestions, project, questions.length, enableComparisonView]
  );

  useEffect(
    function readInitialAngleIdFromUrl() {
      const params = new URLSearchParams(history.location.search);
      const selectedAngleId = params.get("selectedAngleId");
      if (selectedAngleId) {
        setInitialAngleId(selectedAngleId);
        params.delete("selectedAngleId");
        history.replace({
          search: params.toString(),
        });
      }
    },
    [history]
  );

  useEffect(
    function applyInitialAngleFilter() {
      if (loadingQuestions) return;

      const appliedAngleFilter = appliedFilters.groups ?? [];
      const angleId = getInitialAngleId(allInteractionsForProject, questions, initialAngleId);
      if (angleId && appliedAngleFilter.length === 0) {
        onFiltersChange({
          ...appliedFilters,
          groups: [angleId],
        });
      }
    },
    [allInteractionsForProject, onFiltersChange, initialAngleId, appliedFilters, loadingQuestions, questions]
  );

  useEffect(
    function logHitVisit() {
      logHit({
        action: HitAction.viewComparisonView,
        projectToken: project?.token,
        origin: referrerOrigin ?? origin ?? HitOrigin.comparisonView,
      });
      setReferrerOrigin(undefined);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div flexGrow="1" display="flex" flexDirection="column" minH="0">
      {loadingQuestions || interactionsLoading || !selectedAngle ? (
        <ComparisonViewSkeleton />
      ) : (
        <>
          {!newNavigationEnabled && (
            <ComparisonViewHeader
              onSubmitFilters={onSubmitFilters}
              onToggleFilter={onToggleFilter}
              onResetAllFilters={onResetAllFilters}
              angles={angles}
              selectedAngle={selectedAngle}
              questions={questions}
            />
          )}
          <ComparisonTable
            selectedAngle={selectedAngle}
            questions={questions}
            interactions={interactions}
            selectedInteractionId={selectedInteractionId}
          />
        </>
      )}
    </x.div>
  );
};

const ComparisonViewSkeleton = () => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();
  const newNavigationEnabled = useNewNavigation();

  return (
    <x.div>
      {!newNavigationEnabled && (
        <x.div px={inner.base08} py={inner.base05} display="flex">
          <x.div flexGrow={2}>
            <Skeleton height={inner.base08} />
          </x.div>
          <x.div flexGrow={1}>
            <Skeleton />
          </x.div>
        </x.div>
      )}
      <Skeleton height={layout.base08} count={6} />
    </x.div>
  );
};
