import { ContentApprovalStatus } from "models/AlphaShield";
import { ContentPurchaseStatus } from "@alphasights/client-portal-shared";

export const passedPayment = (purchaseStatus: ContentPurchaseStatus) =>
  [
    ContentPurchaseStatus.Available,
    ContentPurchaseStatus.FreePilotAccess,
    ContentPurchaseStatus.AllAvailable,
    ContentPurchaseStatus.Purchased,
  ].includes(purchaseStatus);

export const passedApproval = (approvalStatus: ContentApprovalStatus) =>
  [ContentApprovalStatus.ApprovalNotNeeded, ContentApprovalStatus.Approved].includes(approvalStatus);

export const isContentAccessible = (purchaseStatus: ContentPurchaseStatus, approvalStatus: ContentApprovalStatus) =>
  passedPayment(purchaseStatus) && passedApproval(approvalStatus);
