import React from "react";
import { Alert } from "@alphasights/alphadesign-components";

export const ErrorNotification = ({ children, message }) => (
  <NotificationContainer>
    <Alert fontSize="aui-text-md" variant="danger">
      {children || message}
    </Alert>
  </NotificationContainer>
);

export const NotificationContainer = ({ children }) => (
  <>
    <div
      className="aui-relative aui-hidden md:aui-inline-block"
      style={{
        top: 30,
        left: "50%",
        width: "90%",
        maxWidth: 500,
        transform: "translateX(-50%)",
        color: "black",
      }}
    >
      {children}
    </div>
    <div
      className="aui-relative md:aui-hidden"
      style={{
        top: 30,
        left: "50%",
        width: "90%",
        maxWidth: 350,
        transform: "translateX(-50%)",
        color: "black",
      }}
    >
      {children}
    </div>
  </>
);
