import { useCookiePolicy, useEnv } from "@alphasights/client-portal-shared";
import { Env } from "@alphasights/portal-api-client";
import { datadogLogs } from "@datadog/browser-logs";
import { DATA_DOG } from "constants/CookiePolicy";
import { useEffect } from "react";
import { LoggerMessageInfo } from "./logger.messages";

export class Logger {
  private static instance: Logger;
  public static readonly DATADOG_SITE = "datadoghq.com";
  public static readonly DATADOG_SERVICE = "client-portal";
  public isInitialized = false;

  private constructor() {
    // private constructor to prevent instantiation
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public init(env: Env): void {
    if (env.datadogRumToken) {
      datadogLogs.init({
        clientToken: env.datadogRumToken,
        site: Logger.DATADOG_SITE,
        service: Logger.DATADOG_SERVICE,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });

      this.isInitialized = true;
    }
  }

  public log(message: LoggerMessageInfo, messageContext?: object) {
    return datadogLogs.logger.info(message, messageContext);
  }

  public error(message: string, messageContext?: object, error?: Error) {
    return datadogLogs.logger.error(message, messageContext, error);
  }

  public warn(message: string, messageContext?: object) {
    return datadogLogs.logger.warn(message, messageContext);
  }
}

export function useLogger(): Logger {
  const env = useEnv();
  const { isPolicyAccepted } = useCookiePolicy();
  const logger = Logger.getInstance();

  useEffect(() => {
    if (!logger.isInitialized && env && env.enableDatadogRum && isPolicyAccepted(DATA_DOG)) {
      logger.init(env);
    }
  }, [env, isPolicyAccepted, logger]);

  return logger;
}
