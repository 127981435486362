import styled from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const PillSectionWrapper = styled.div(() => {
  const { spacing, color, font } = useThemeTokens();

  return {
    display: "inline-flex",
    alignItems: "center",
    color: color.text.secondary,
    lineHeight: font.lineHeight["04"],
    flexWrap: "wrap" as "wrap",
    rowGap: spacing.inner.base02,
    gap: spacing.inner.base02,
  };
});

const TitleWrapper = styled(Typography).attrs({ component: "span" })`
  display: "inline-flex";
  flexwrap: "nowrap";
`;

export { PillSectionWrapper, TitleWrapper };
