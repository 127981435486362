import React, { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import Card from "components/Card";
import { Citation } from "components/CitationContext/Citation";
import CitedMarkdown from "components/CitedMarkdown";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import CompetitorDynamicsTableHeaderItem, {
  GroupingType,
} from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/CompetitorDynamicsHeader";
import GrowthSymbol from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import useCompanyPrimerCompetitorCardStyles from "./CompanyPrimerCompetitorCard.styles";

interface GroupingProps {
  groupingType: CitableValue<string>;
  doesRelevantGroupDataExist: boolean;
}

interface CompanyPrimerCompetitorMobileCardContentProps {
  isSensitive: boolean;
  competitor: CitableCompetitor;
  companyNameIdToCDSCompanyNamesMap: Record<number, CDSCompanyNames>;
  grouping: GroupingProps;
}

const CompanyPrimerCompetitorMobileCardContent: FC<CompanyPrimerCompetitorMobileCardContentProps> = ({
  competitor,
  companyNameIdToCDSCompanyNamesMap,
  grouping,
  isSensitive,
}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const { groupingType, doesRelevantGroupDataExist } = grouping;
  const { companyNameId, companyName, rank, growth, competitorGroup, competitiveDynamicDescription } = competitor;

  return (
    <Typography component="div" color={text.strong._} variant="body-large">
      <x.div display="flex" flexDirection="column" spaceY={inner.base04}>
        <x.div display="flex" pt={inner.base06} gap="40%">
          <MetricColumnWrapper>
            <MetricWrapper>
              <CompetitorDynamicsTableHeaderItem label="RANK" />
              <Citation isEnabled={false} value={rank} />
            </MetricWrapper>
            {(!!companyNameId?.value || !!companyName?.value) && (
              <MetricWrapper isSensitive={isSensitive}>
                <CompetitorDynamicsTableHeaderItem label="COMPETITOR" />
                <Typography color={text.strong._} variant="body-large-em">
                  {!!companyName?.value && <Citation value={companyName} />}
                  {!!companyNameId?.value && !companyName?.value && (
                    <Citation
                      value={companyNameId}
                      renderer={({ value }) => companyNameIdToCDSCompanyNamesMap[value].displayName}
                    />
                  )}
                </Typography>
              </MetricWrapper>
            )}
          </MetricColumnWrapper>
          <MetricColumnWrapper>
            <MetricWrapper>
              <CompetitorDynamicsTableHeaderItem label="MARKET SHARE" />
              <Citation
                value={growth}
                isEnabled={false}
                renderer={({ value: growth }) => <GrowthSymbol {...{ growth }} />}
              />
            </MetricWrapper>
            {doesRelevantGroupDataExist && (
              <MetricWrapper isSensitive={isSensitive}>
                <CompetitorDynamicsTableHeaderItem
                  label={GroupingType[groupingType.value as keyof typeof GroupingType] || "GROUP"}
                />
                {(!!companyNameId?.value || !!companyName?.value) && <Citation value={competitorGroup} />}
              </MetricWrapper>
            )}
          </MetricColumnWrapper>
        </x.div>
        <MetricWrapper isSensitive={isSensitive}>
          <CitedMarkdown value={competitiveDynamicDescription as CitableValue<string>} />
        </MetricWrapper>
      </x.div>
    </Typography>
  );
};

interface MetricColumnWrapperProps {
  children: any;
}

const MetricColumnWrapper = ({ children }: MetricColumnWrapperProps) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div display="flex" flexDirection="column" spaceY={spacing.inner.base02}>
      {children}
    </x.div>
  );
};

interface MetricWrapperProps {
  isSensitive?: boolean;
  children: any;
}

const MetricWrapper = ({ isSensitive = false, children }: MetricWrapperProps) => {
  const { spacing } = useThemeTokens();

  const Container = () => (
    <x.div display="flex" flexDirection="column" minH={spacing.inner.base14}>
      {children}
    </x.div>
  );
  if (isSensitive) {
    return (
      <SensitiveDataContainer isSensitive={isSensitive} style={{ minHeight: spacing.inner.base14 }}>
        <Container />
      </SensitiveDataContainer>
    );
  }
  return <Container />;
};

interface CompanyPrimerCompetitorCardContentProps {
  competitor: CitableCompetitor;
  companyNameIdToCDSCompanyNamesMap: Record<number, CDSCompanyNames>;
  grouping?: GroupingProps | {};
  isSensitive: boolean;
  groupingType?: CitableValue<string>;
}

const CompanyPrimerCompetitorCardContent: FC<CompanyPrimerCompetitorCardContentProps> = ({
  competitor,
  companyNameIdToCDSCompanyNamesMap,
  isSensitive,
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const { companyNameId, companyName, rank, growth, competitorGroup, competitiveDynamicDescription } = competitor;

  return (
    <Typography color={text.strong._} variant="body">
      <x.div row mb={inner.base04} alignItems="center">
        <x.div col={1 / 10} maxWidth="72px">
          <Citation isEnabled={false} value={rank} />
        </x.div>
        <x.div style={{ width: 180 }}>
          <SensitiveDataContainer
            isSensitive={isSensitive}
            style={{
              col: 1 / 6,
              maxWidth: 180,
              pr: inner.base08,
            }}
          >
            <Typography color={text.strong._} variant="body-em">
              {!!companyName?.value && <Citation value={companyName} renderer={({ value }) => value ?? "-"} />}
              {!!companyNameId?.value && !companyName?.value && (
                <Citation
                  value={companyNameId}
                  renderer={({ value }) => companyNameIdToCDSCompanyNamesMap[value]?.displayName ?? "-"}
                />
              )}
            </Typography>
          </SensitiveDataContainer>
        </x.div>
        <x.div col={1 / 6} maxWidth={128}>
          <span>
            <Citation
              value={growth}
              isEnabled={false}
              renderer={({ value: growth }) => <GrowthSymbol {...{ growth }} />}
            />
          </span>
        </x.div>
        {!!competitorGroup.value && (
          <SensitiveDataContainer isSensitive={isSensitive} style={{ col: true }}>
            <Citation value={competitorGroup} />
          </SensitiveDataContainer>
        )}
      </x.div>
      <SensitiveDataContainer isSensitive={isSensitive}>
        <CitedMarkdown value={competitiveDynamicDescription as CitableValue<string>} />
      </SensitiveDataContainer>
    </Typography>
  );
};

interface CompanyPrimerCompetitorCardProps {
  competitor: CitableCompetitor;
  primerCompanyNameId: CitableValue<number>;
  companyName?: CitableValue<string>;
  companyNameIdToCDSCompanyNamesMap: Record<number, CDSCompanyNames>;
  grouping?: GroupingProps | {};
}

const CompanyPrimerCompetitorCard: FC<CompanyPrimerCompetitorCardProps> = ({
  competitor,
  primerCompanyNameId,
  companyName: primerCompanyName,
  companyNameIdToCDSCompanyNamesMap,
  grouping = {},
}) => {
  const { color } = useThemeTokens();
  const { companyPrimerCompetitorCard: companyPrimerCompetitorCardStyles } = useCompanyPrimerCompetitorCardStyles();
  const { isMobile } = useCheckScreen();

  const { companyNameId, companyName } = competitor;
  const isSensitive = hasSensitiveData(competitor);

  return (
    <Card
      {...companyPrimerCompetitorCardStyles}
      mt={0}
      borderColor={{
        xs: "inherit",
        sm:
          (companyNameId && primerCompanyNameId?.value && primerCompanyNameId?.value === companyNameId?.value) ||
          (companyName && primerCompanyName?.value && primerCompanyName?.value === companyName?.value)
            ? color.text.assistive
            : color.background.neutral.default,
      }}
    >
      {isMobile ? (
        // @ts-ignore
        <CompanyPrimerCompetitorMobileCardContent
          {...{
            grouping,
            competitor,
            companyNameIdToCDSCompanyNamesMap,
            isSensitive,
          }}
        />
      ) : (
        <CompanyPrimerCompetitorCardContent
          {...{
            competitor,
            companyNameIdToCDSCompanyNamesMap,
            isSensitive,
          }}
        />
      )}
    </Card>
  );
};

export default CompanyPrimerCompetitorCard;
