/**
 * Concepts:
 *
 * FLAGS: Enable/disable features in the application. Values are boolean.
 * FEATURES: Soft launch features in the application. Values are numbers between 0-1.
 */

export const Flags = {
  newLogin: "newLogin",
};

export const Features = {
  launchNewLogin: "launchNewLogin",
};

/**
 * The Flags values correspond to the path in the Env object from useEnv
 */
export const FLAGS: Record<keyof typeof Flags, string> = {
  newLogin: "login.enableNewLogin",
};

/**
 * The Features values correspond to the path in the Env object from useEnv
 */
export const FEATURES: Record<keyof typeof Features, string> = {
  launchNewLogin: "login.launchNewLogin",
};

export type Flag = keyof typeof FLAGS;
export type Feature = keyof typeof FEATURES;

/**
 * The map is important because in the SoftLaunchProvider,
 * the feature name in the `launchFlags` prop must be the same as the flag name in `initialFlags`.
 * Here is the full documentation: https://github.com/alphasights/ads-community/blob/main/packages/%40utils/src/utilities/soft-launch/soft-launch.mdx
 */
export const MAP_FROM_FEATURES_TO_FLAGS: Record<string, string> = {
  launchNewLogin: Flags.newLogin,
};
