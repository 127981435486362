import { ProjectCreationRequest } from "models/ProjectLauncher";
import { fetch } from "../hooks/useApi";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";

export const projectLauncherService = {
  async launchProject(project: ProjectCreationRequest): Promise<{ token: string }> {
    return fetch({
      url: "/api/launched-projects",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify(project),
    }).then((res) => res.json());
  },

  async generateTitle(companies: number[], mainCompany?: number): Promise<{ title: string }> {
    return fetch({
      url: `/api/launched-projects/generate-title?companies=${companies.join(",")}${
        mainCompany ? "&mainCompany=" + mainCompany : ""
      }`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async fetchRecommendedResearch(companies: ExternalAlphaCompany[]): Promise<ContentResults[]> {
    return fetch({
      url: "/api/content/recommendations/search",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify(companies),
    }).then((res) => res.json());
  },
};
