import React from "react";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import useWatchlist from "hooks/useWatchlist";
import {
  WATCHLIST_TITLE,
  MANAGE_WATCHLIST_BUTTON,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/constants";
import useModal from "hooks/useModal";
import WatchlistModal from "components/WatchlistModal";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { WatchlistHeaderContainer, WatchlistTitleContainer } from "./WatchlistHeader.styled";

const WatchlistHeader = ({ watchlistName, watchlistId }: { watchlistName: string; watchlistId?: string }) => {
  const { color } = useThemeTokens();
  const { isVisible, onOpen, onClose } = useModal();
  const { availableWatchlists, selectedWatchlistId, setSelectedWatchlistId } = useWatchlist();
  const { logHit } = useTrackUserAction();

  const handleSelectWatchlist = () => {
    onOpen();
    logHit({
      origin: HitOrigin.landingPage,
      action: HitAction.viewWatchlist,
      details: {
        watchlistId,
      },
    });
  };

  return (
    <WatchlistHeaderContainer>
      <WatchlistTitleContainer>
        <Typography variant="body-small" color={color.text.secondary}>
          {WATCHLIST_TITLE}
        </Typography>
        <Typography variant="body-em">{watchlistName}</Typography>
      </WatchlistTitleContainer>
      <Button variant="outline" size="small" onClick={handleSelectWatchlist}>
        {MANAGE_WATCHLIST_BUTTON}
      </Button>
      {isVisible && (
        <WatchlistModal
          selectedWatchlistId={watchlistId ?? selectedWatchlistId}
          setSelectedWatchlistId={setSelectedWatchlistId}
          availableWatchlists={availableWatchlists}
          onClose={onClose}
        />
      )}
    </WatchlistHeaderContainer>
  );
};

export default WatchlistHeader;
