const flagsArray = [];

try {
  const reqSvgs = require.context("../../assets/flags", true, /\.svg$/);

  reqSvgs.keys().forEach((path) => {
    const code = path.replaceAll(".svg", "").replaceAll("./", "");
    flagsArray[code] = reqSvgs(path);
  });
} catch (error) {}

export const FLAG_MAP = flagsArray;
