import React, { FC } from "react";
import { Avatar, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CardContainer, CompanyDetailsContainer, CompanyTitleContainer } from "./CompanyCard.styled";
import { Company } from "@alphasights/alphadesign-icons";

const DataTestIds = {
  companyPageCard: "company-page-card",
};
interface CompanyPageCardProps {
  companyName: string;
  onClick: () => void;

  companyLogo?: string;
  isSelected?: boolean;
}

export const companyPage = "Company Page";

const CompanyPageCard: FC<CompanyPageCardProps> = ({ companyName, companyLogo, isSelected = false, onClick }) => {
  const {
    color: { text },
  } = useThemeTokens();
  return (
    <CardContainer isSelected={isSelected} onClick={onClick} data-testid={DataTestIds.companyPageCard}>
      <CompanyDetailsContainer>
        {companyLogo ? (
          <Avatar src={companyLogo} size="medium" />
        ) : (
          <Avatar size="medium" color="base02">
            <Company />
          </Avatar>
        )}
        <CompanyTitleContainer>
          <Typography variant="body-em">{companyName}</Typography>
        </CompanyTitleContainer>
      </CompanyDetailsContainer>
      <Typography color={text.secondary} variant="body-small">
        {companyPage}
      </Typography>
    </CardContainer>
  );
};

export { CompanyPageCard as default, DataTestIds };
