import { Icon, Loading, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./AiAskQuestionInput.styles";
import React, { useEffect, useRef, useState } from "react";
import { Error, GenerativeAi } from "@alphasights/alphadesign-icons";
import { noop } from "lodash";

export const AiAskQuestionInput = ({
  text,
  onSubmit,
  onBlur,
  isNewQuestion,
  isLoading,
  onChange = noop,
  error,
  "data-testid": dataTestId,
}: {
  text: string;
  onSubmit: (newText: string) => Promise<any> | null;
  onBlur: () => void;
  isNewQuestion?: boolean;
  isLoading?: boolean;
  onChange?: (newText: string) => void;
  error?: string | boolean;
  "data-testid": string;
}) => {
  const { color } = useThemeTokens();
  const styles = useStyles();
  const ref = useRef<HTMLInputElement>();
  const [isError, setIsError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [submitError, setSubmitError] = useState<string>();

  const onKeyPress = (e: any) => {
    if (e.key === "Escape") onBlur();
    if (e.key !== "Enter") return;

    e.preventDefault();

    setSubmitting(true);

    const submission = onSubmit(e.currentTarget.value.trim())
      ?.catch((message) => {
        setIsError(true);
        setSubmitError(typeof message === "string" ? message : undefined);
        setTimeout(() => {
          ref.current?.focus();
        });
      })
      ?.finally(() => {
        setSubmitting(false);
      });

    if (!submission) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.value = text;
      recalculateHeight(ref.current);
    }
  }, [ref, text]);

  function recalculateHeight(element: any) {
    element.style.height = "5px";
    element.style.height = Math.max(element.scrollHeight + 2, 20) + "px";
  }

  useEffect(() => {
    if (!isLoading && ref.current && isNewQuestion) {
      ref.current.value = "";
      recalculateHeight(ref.current);
    }
  }, [isLoading, isNewQuestion]);

  useEffect(() => {
    setIsError(Boolean(error));
  }, [error]);

  return (
    <>
      <x.div
        {...styles.questionInputWrapper}
        data-testid="ai-question-wrapper"
        borderColor={
          isError
            ? color.border.danger
            : (!isFocused && isNewQuestion) || isLoading
            ? color.border.neutral.default
            : color.border.selected
        }
        onClick={() => ref.current?.focus()}
      >
        <Icon size="medium" {...(isError ? styles.error : styles.purple)}>
          {isError ? <Error /> : isLoading ? <Loading /> : <GenerativeAi />}
        </Icon>
        <x.textarea
          //@ts-ignore
          ref={ref}
          fontSize="0.875rem"
          p="0"
          mt="2px"
          data-testid={dataTestId}
          disabled={submitting || isLoading}
          onChange={(e) => {
            setIsError(false);
            recalculateHeight(e.currentTarget);
            onChange(e.currentTarget.value);
          }}
          onFocus={(e) => {
            setIsFocused(true);
            recalculateHeight(e.currentTarget);
          }}
          onKeyDown={onKeyPress}
          w="100%"
          resize={"none"}
          border="0px"
          outline="none"
          onBlur={() => {
            setIsFocused(false);
            if (onBlur) {
              onBlur();
            }
          }}
          placeholder={isNewQuestion ? "Ask a question..." : ""}
          color={isLoading && color.text.disabled}
          backgroundColor={color.background.input.default}
        />
      </x.div>
      {isError && typeof error !== "boolean" && (error ?? submitError ?? "").length > 0 && (
        <Typography color="danger">{error ?? submitError}</Typography>
      )}
    </>
  );
};
