import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CommissionPrimerLabels } from "models/primers/constants";
import { COMMISSION_MODAL_FIT } from "../constants";
const CommissionModalHeader = () => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const headerItems = [
    {
      label: CommissionPrimerLabels.COMPANY,
    },
    {
      label: CommissionPrimerLabels.FORMAT,
    },
    {
      label: CommissionPrimerLabels.TYPE,
    },
    {
      label: CommissionPrimerLabels.TIMELINE,
    },
    {
      label: CommissionPrimerLabels.CREDITS,
    },
  ];

  return (
    <x.div
      mx={inner.base06}
      mt={inner.base03}
      gridTemplateColumns={COMMISSION_MODAL_FIT}
      display="grid"
      columnGap="10px"
      mb={inner.base04}
    >
      {headerItems
        .filter(({ label }) => label.length > 0)
        .map((item) => {
          return (
            <Typography component="div" color={text.secondary} variant="body-small-em">
              {item.label}
            </Typography>
          );
        })}
    </x.div>
  );
};

export default CommissionModalHeader;
