import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { ContentSuggestion } from "views/DeliverablesView/types";
import PitchedDetails from "components/PitchedDetails";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const PitchedAtCell = ({ row: { original: content } }: AlphaTableTypes.CellContext<ContentSuggestion, any>) => {
  const { pitchedAt } = content;
  const { project } = useCurrentProjectContext();

  if (!pitchedAt) return <></>;

  return <PitchedDetails hasTooltip pitchedAt={pitchedAt} pitchedBy={project?.lead} />;
};

export default PitchedAtCell;
