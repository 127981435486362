import React, { FC } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Carousel } from "@alphasights/client-portal-shared";
import { useThemeTokens, Avatar, Typography, Pill, Icon } from "@alphasights/alphadesign-components";
import { Company, Transcript } from "@alphasights/alphadesign-icons";
import { CardDataProps, CardCarouselProps, CardProps } from "./types";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
const DataTestIds = {
  carouselCard: "carousel-card",
  placeHolderIcon: "placeholder-icon",
};

const Card: FC<CardProps> = ({ header, logoLink, title, subtitle, numberRelatedContent, handleOnClick }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const numContentDisplayValue = numberRelatedContent < 100 ? numberRelatedContent : "99+";

  return (
    <CardContainer onClick={() => handleOnClick()} data-testid={DataTestIds.carouselCard}>
      {logoLink ? (
        <Avatar src={logoLink} size="x-large" text={title} padding={inner.base} />
      ) : (
        <LogoContainer>
          <Icon size="large" color={text.secondary} data-testid={DataTestIds.placeHolderIcon}>
            <Company />
          </Icon>
        </LogoContainer>
      )}
      <x.div pl={inner.base04} display="flex" flexDirection="column" justifyContent="center" gap={inner.base}>
        <Typography variant="body-small-em" color={text.secondary}>
          {header}
        </Typography>
        <Typography variant="body-em">{title}</Typography>
        {subtitle && (
          <Typography variant="body-small" color={text.secondary}>
            {subtitle}
          </Typography>
        )}
      </x.div>
      <Pill size="small" leftAccessories={<Transcript />} margin={inner.base03} position="absolute" right={0} top={0}>
        {numContentDisplayValue}
      </Pill>
    </CardContainer>
  );
};

const CardCarousel: FC<CardCarouselProps> = ({ cardData, numberCardsDisplayed }) => {
  const { isUltraWide } = useCheckScreen();
  const numberOfCardsDisplayedUltraWide = 2 * numberCardsDisplayed;

  const numberOfItemsDisplayed = isUltraWide ? numberOfCardsDisplayedUltraWide : numberCardsDisplayed;

  return (
    <Carousel numberOfItemsDisplayed={numberOfItemsDisplayed}>
      {cardData.map((data: CardDataProps, key: number) => (
        <Card
          key={key}
          header={data.header}
          title={data.title}
          subtitle={data.subtitle}
          logoLink={data.logo}
          numberRelatedContent={data.numberRelatedContent}
          handleOnClick={data.handleOnClick}
          id={data.id}
        />
      ))}
    </Carousel>
  );
};

const CardContainer = styled.div(() => {
  const {
    color: { border: borderColor, background },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();
  return `
      padding: ${inner.base04};
      background-color: ${background.surface.page.default};
      position: relative;
      border-radius: ${inner.base04};
      border-width: ${border.width.sm};
      border-color: ${borderColor.neutral.default};
      display: flex;
      flex-direction: row;
      cursor: pointer;
    `;
});

const LogoContainer = styled.div(() => {
  const {
    color: { border: borderColor },
    spacing: { layout },
    shape: { border },
  } = useThemeTokens();
  return {
    height: layout.base08,
    width: layout.base08,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `${border.width.sm} solid ${borderColor.divider}`,
    borderRadius: border.radius.xlarge,
  };
});

export { CardCarousel as default, DataTestIds };
