import { clamp } from "lodash";

const useAvatarColors = (ids) => {
  const colors = ["base", "base02", "base03", "base04", "base05", "base06"];

  return ids.map((id, ix) => ({
    ...id,
    color: colors[clamp(ix, 0, colors.length - 1)],
  }));
};

export default useAvatarColors;
