import { Modal, Tabs, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const ModalWrapper = styled(Modal)`
  header:first-of-type {
    border-width: 0;
  }
  z-index: 1000;

  [data-testid="modal-slot"] {
    padding-top: 1px;
    padding-bottom: 0;
    overflow-y: hidden;
  }
`;

const TitleWrapper = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "center",
    gap: inner.base03,
  };
});

const TabWrapper = styled(Tabs)(() => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();
  return {
    marginTop: layout.base02,
    marginInline: `-${inner.base06}`,
    "button[role=tab]:first-of-type ": {
      marginLeft: inner.base06,
    },
  };
});

export { TitleWrapper, TabWrapper, ModalWrapper };
