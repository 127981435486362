import { useState, useCallback } from "react";
import { useQueries, useMutation } from "react-query";

import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { unsuccessfulRequest, unsuccessfulFetchRequest } from "content/AlphaNow";
import { contentService } from "services/content";
import useContentClientSettings from "hooks/useContentClientSettings";

interface UpdateClientExpertInquiryProps {
  clientExpertInquiryId: string;
  clientExpertInquiry: ClientExpertInquiry;
}

type ContentId = {
  id: string;
};

type UseExpertRequestProps = {
  contents: ContentId[] | Content[] | ContentResults[];

  onClose?: () => void;
  onExpertRequested?: (newExpertRequest: Partial<ClientExpertInquiryDto>) => void;
  origin?: HitOrigin;
};

export default ({ onClose, contents, onExpertRequested, origin }: UseExpertRequestProps) => {
  const [expertRequests, setExpertRequests] = useState<ClientExpertInquiryDto[]>([]);
  const [expertRequestExists, setExpertRequestExists] = useState<boolean>(false);
  const { clientSettings } = useContentClientSettings();
  const { logHit } = useTrackUserAction();
  const [error, setError] = useState("");

  const canRequestExperts = !!clientSettings?.requestExperts;

  const updateExpertRequest = (clientExpertInquiries: ClientExpertInquiryDto[], contentId: string) => {
    if (clientExpertInquiries?.length > 0) {
      setExpertRequests((prev) => [...prev, ...clientExpertInquiries]);
    } else {
      setExpertRequests((prev) => prev.filter((req) => req.contentId !== contentId));
    }
  };
  const queries = canRequestExperts
    ? contents
        .map((content: ContentId | Content | ContentResults) =>
          content
            ? {
                queryKey: ["expert-request", content.id],
                queryFn: () => contentService.fetchExpertRequests(content.id),
                onError: () => {
                  setError(unsuccessfulFetchRequest);
                  updateExpertRequest([], content.id);
                },
                onSuccess: (res: ClientExpertInquiryDto[]) => {
                  updateExpertRequest(res, content.id);
                  setExpertRequestExists(true);
                },
                enabled: canRequestExperts,
              }
            : null
        )
        .filter((query): query is NonNullable<typeof query> => query !== null) // Type guard to ensure typings are accurate
    : [];

  const queryResults = useQueries(queries);

  const isRequestExpertLoading = queryResults.some((result) => result.isLoading || result.isRefetching);

  const handleSuccess = useCallback(
    (expertRequest: Partial<ClientExpertInquiryDto>) => {
      setError("");
      setExpertRequests((prev) => [
        expertRequest as ClientExpertInquiryDto,
        ...prev.filter(
          (req: ClientExpertInquiryDto) => req.clientExpertInquiryId !== expertRequest.clientExpertInquiryId
        ),
      ]);
      onExpertRequested?.(expertRequest);
      onClose?.();
    },
    [onClose, onExpertRequested]
  );

  const baseMutationSettings = {
    onError: () => setError(unsuccessfulRequest),
    onSuccess: (expertRequestResponse: ClientExpertInquiryDto) => handleSuccess(expertRequestResponse),
  };

  const updateClientExpertInquiry = useMutation(
    ({ clientExpertInquiryId, clientExpertInquiry }: UpdateClientExpertInquiryProps) =>
      contentService.updateExpertRequest(clientExpertInquiryId, clientExpertInquiry as any),
    { ...baseMutationSettings }
  );

  const createClientExpertInquiry = useMutation(
    (clientExpertInquiry: ClientExpertInquiry) => contentService.postExpertRequest(clientExpertInquiry as any),
    { ...baseMutationSettings }
  );

  const deleteClientExpertInquiry = useMutation(
    (clientExpertInquiryId: string) => contentService.deleteExpertRequest(clientExpertInquiryId),
    {
      ...baseMutationSettings,
      onSuccess: () => handleSuccess({}),
    }
  );

  const handleCancelRequest = useCallback(() => {
    const expertRequestId = expertRequests.find((req) => req.clientExpertInquiryId)?.clientExpertInquiryId;
    if (expertRequestId) {
      deleteClientExpertInquiry.mutate(expertRequestId!);
    }
    onClose?.();
  }, [deleteClientExpertInquiry, expertRequests, onClose]);

  const handleSubmitRequest = useCallback(
    (speakerIds: number[], note: string = "") => {
      contents?.forEach((content: Partial<Content> | Partial<ContentResults>) => {
        const contentSpeakersIds = content.speakers?.map((speaker) => speaker.id);
        const clientExpertInquiry = {
          contentId: content.id,
          note,
          speakerIds: speakerIds.filter((id) => contentSpeakersIds?.includes(id)),
        };

        const clientExpertInquiryId = expertRequests.find((req) => req.clientExpertInquiryId)?.clientExpertInquiryId;

        if (clientExpertInquiryId) {
          updateClientExpertInquiry.mutate({
            clientExpertInquiryId,
            clientExpertInquiry,
          });
        } else {
          createClientExpertInquiry.mutate(clientExpertInquiry);
        }

        origin &&
          logHit({
            origin,
            action: HitAction.alphaNowRequestAnExpert,
            details: { contentId: content.id },
          });
      });
    },
    [contents, expertRequests, logHit, updateClientExpertInquiry, createClientExpertInquiry, origin]
  );

  return {
    expertRequests,
    isRequestExpertLoading,
    setExpertRequests,
    expertRequestExists,
    handleCancelRequest,
    handleSubmitRequest,
    error,
  };
};
