import { useHistory } from "react-router-dom";
import useQuery from "./useQuery";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

function isVisitingPage() {
  const { pathname, search } = window.location;
  if (pathname === "/" || pathname === "/login-success") return false;
  if ((pathname === "/sign-in" || pathname.startsWith("/invitations/")) && !search.match(/next/)) return false;
  return true;
}

function getRedirectRoute(user, allProjects, nextFromQuery, mobileRedirect, fallback = null) {
  const { accessOnly, alphaNowEnabled, contentEnabled } = user ?? {};

  switch (true) {
    case accessOnly && alphaNowEnabled:
      return "/alphanow";
    case !!nextFromQuery:
      return nextFromQuery;
    case isVisitingPage():
      return null;
    case mobileRedirect:
      return "/upcoming-calls?openSidebar=true";
    case allProjects.length > 0:
      return "/my-projects";
    case alphaNowEnabled:
      return "/alphanow";
    case contentEnabled:
      return "/content";
    default:
      return fallback ?? "/my-projects";
  }
}

export function useGetRedirectRoute({ fallback = null } = {}) {
  const { isMobile: mobileRedirect } = useCheckScreen();
  const allProjects = useMyProjects();
  const currentUser = useCurrentUser();
  const query = useQuery();
  const nextFromQuery = query.get("next");

  return getRedirectRoute(currentUser, allProjects, nextFromQuery, mobileRedirect, fallback);
}

function useRedirect({ onRedirect, onContinue, hardRefresh = true } = {}) {
  const { isMobile: mobileRedirect } = useCheckScreen();
  const allProjects = useMyProjects();
  const currentUser = useCurrentUser();
  const query = useQuery();
  const nextFromQuery = query.get("next");
  const history = useHistory();

  const redirectTo = (url) => {
    onRedirect && onRedirect();
    hardRefresh ? (window.location.href = url) : history.push(url);
  };

  const continueInPage = () => onContinue && onContinue();

  const performRedirect = async () => {
    const nextRoute = getRedirectRoute(currentUser, allProjects, nextFromQuery, mobileRedirect);

    if (!nextRoute) return continueInPage();
    return redirectTo(nextRoute);
  };

  return performRedirect;
}

export default useRedirect;
