const TARGET_COMPANY = "Target Company";
const FORMER = "Former";
const COMPETITORS = "Competitors";
const CUSTOMERS = "Customers";
const END_CUSTOMERS = "End Customers";
const INTERMEDIARIES = "Intermediaries";
const SUPPLIERS = "Suppliers";
const INDUSTRY_AUTHORITIES = "Industry Authorities";
const OTHER = "Other";

export const EXPERT_CATEGORIZATION_ORDER = [
  TARGET_COMPANY,
  COMPETITORS,
  END_CUSTOMERS,
  INTERMEDIARIES,
  SUPPLIERS,
  INDUSTRY_AUTHORITIES,
  OTHER,
];

export const ANGLE_TYPE_NAME_TO_FORMATTED_NAME = new Map([
  [TARGET_COMPANY, FORMER],
  [END_CUSTOMERS, CUSTOMERS],
]);
