import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useRecommendedContentCardStyles = () => {
  const {
    color: { background },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  return {
    wrapper: {
      border: "solid 1px",
      display: "flex",
      gap: inner.base02,
      borderRadius: inner.base02,
      padding: inner.base04,
      bg: background.surface.page.default,
      alignItems: "center",
    },
    logo: {
      p: "10px",
      bg: background.danger.subtle,
      borderRadius: shape.border.radius.small,
    },
    text: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base,
    },
  };
};
