import React, { FC, ReactElement, useRef } from "react";
import { Divider, Icon, Typography, TypographyVariation, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

import { BOOLEAN_OPTIONS_FOOTER_TEXT, STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { OptionsPopoverFooterProps } from "components/Search/types";
import { FooterContentWrapper } from "./Footer.styled";

const DataTestIds = {
  Footer: "popover-footer",
};

export type FooterProps = OptionsPopoverFooterProps & {
  size: SearchSizeVariant;
  allowBooleanExpressions: boolean;
};

const Footer: FC<FooterProps> = ({ size, allowBooleanExpressions = false, ...props }) => {
  const { color, spacing } = useThemeTokens();

  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const { footer: footerStyles } = styles;

  const footer = "footer" in props ? props.footer : undefined;
  const text = "text" in props ? props.text : "";
  const startAdornmentFromProps = "startAdornment" in props ? props.startAdornment : undefined;

  if (footer) {
    return React.cloneElement(footer as ReactElement, { "data-testid": DataTestIds.Footer });
  }

  const footerText = text ? text : allowBooleanExpressions ? BOOLEAN_OPTIONS_FOOTER_TEXT : "";
  const startAdornment =
    startAdornmentFromProps ??
    (allowBooleanExpressions ? (
      <Icon color={color.icon.secondary}>
        <Info />
      </Icon>
    ) : null);

  if (footerText) {
    return (
      <x.div px={spacing.inner.base04}>
        <Divider my={spacing.inner.base02} />
        <Typography
          data-testid={DataTestIds.Footer}
          component="div"
          variant={footerStyles.typographyVariant as TypographyVariation}
          {...footerStyles.xStyledProps}
        >
          <x.div display="flex" flexDirection="column">
            <FooterContentWrapper>
              {startAdornment}
              <x.span color={color.text.secondary}>{footerText}</x.span>
            </FooterContentWrapper>
          </x.div>
        </Typography>
      </x.div>
    );
  }

  return null;
};

export { Footer as default, DataTestIds };
