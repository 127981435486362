import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useKeyQuotesStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    quotesListWrapper: {
      pb: inner.base06,
    },
    quoteWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    divider: {
      my: inner.base05,
    },
    quote: {
      ml: inner.base06,
      mr: inner.base02,
    },
    viewTranscriptButtonStyles: {
      alignSelf: "end",
    },
  };
};
