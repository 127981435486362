import { Typography } from "@alphasights/alphadesign-components";
import { FormattedTimeRange } from "providers/TimezoneProvider";
import React from "react";

export const UpcomingCallWrapper = ({ event, children }) => {
  return children;
};

export const upcomingCallEventProps = ({ raw }) => {
  return {
    className: "event-grey aui-opacity-50 hover:aui-opacity-100",
  };
};

export const UpcomingCallEvent = ({ event: { start, end, source, raw } }) => {
  return (
    <div data-testid={`${source}-slot-${raw.id}`} className="aui-flex aui-flex-col aui-leading-tight aui-text-xs">
      <Typography variant="body-small-em" component="div" style={{ whiteSpace: "nowrap" }}>
        <div>{raw.projectTitle}</div>
        {raw.advisorName}
        <br />
        {raw.advisorCompany} - {raw.role}
        <br />
      </Typography>
      <Typography variant="body-small" component="div" data-testid={`event-period-slot-${raw.id}`}>
        <FormattedTimeRange start={start} end={end} />
      </Typography>
    </div>
  );
};
