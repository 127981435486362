import React, { useEffect, useState, useCallback } from "react";
import { addMinutes, isBefore, isSameMinute, isEqual } from "date-fns";
import { FormattedDateTime, useTimezone } from "../../providers/TimezoneProvider";
import { Option, Select, Typography } from "@alphasights/alphadesign-components";
import { GroupedAvailabilitySlots } from "pages/InteractionPage/sections/Availability/AvailabilitySelector";
import { useAvailabilitySelectorStyles } from "./AvailabilitySelector.styles";
import { x } from "@xstyled/styled-components";
import { OneHourMinimumAlert } from "components/OneHourMinimumAlert";

export const formatSlot = (timezone, { startsAt, endsAt }) => {
  const samePeriod =
    (timezone.parseDateZoned(startsAt).getHours() >= 12 && timezone.parseDateZoned(endsAt).getHours() >= 12) ||
    (timezone.parseDateZoned(startsAt).getHours() < 12 && timezone.parseDateZoned(endsAt).getHours() < 12);

  return (
    <>
      <FormattedDateTime date={startsAt} format={"h:mm" + (samePeriod ? "" : "aaa")} /> -{" "}
      <FormattedDateTime date={endsAt} format={"h:mmaaa"} />
    </>
  );
};

const splitSlots = (timespans, duration) =>
  timespans.flatMap((timespan) => {
    let smallerSlots = [];

    let startsAt = timespan.startsAt;
    let endsAt = addMinutes(startsAt, duration);

    while (isBefore(endsAt, addMinutes(timespan.endsAt, 1))) {
      smallerSlots.push({ startsAt, endsAt });

      startsAt = addMinutes(startsAt, 15);
      endsAt = addMinutes(startsAt, duration);
    }

    return smallerSlots;
  });

export const AvailabilitySelector = ({
  expectedDuration,
  mutualSlots,
  oneHourMinimum,
  onSelectSlot,
  selectedBulkAvailability,
  slots,
  isMobileView = false,
}) => {
  const tz = useTimezone();
  const { availabilitiesStyles } = useAvailabilitySelectorStyles();

  const shouldShowSelect = (groupedSlots) => {
    return (
      selectedBulkAvailability && groupedSlots.find((s) => isSameMinute(s.startsAt, selectedBulkAvailability.startsAt))
    );
  };

  return (
    <x.div {...availabilitiesStyles}>
      {mutualSlots.length > 0 && (
        <x.div {...availabilitiesStyles}>
          <GroupedAvailabilitySlots
            title="Mutual Availability"
            slots={mutualSlots}
            onClickTimespan={onSelectSlot}
            minExpectedDuration={expectedDuration * 60}
            showDay
          />
          {shouldShowSelect(mutualSlots) && (
            <SlotSelect
              isMobileView={isMobileView}
              expectedDuration={expectedDuration}
              onSelectSlot={onSelectSlot}
              selectedBulkAvailability={selectedBulkAvailability}
              oneHourMinimum={oneHourMinimum}
            />
          )}
        </x.div>
      )}
      {Object.entries(tz.timespansByDay(slots, "eeee d MMM")).map(([day, groupedSlots = []]) => (
        <x.div key={day} {...availabilitiesStyles}>
          <GroupedAvailabilitySlots
            title={day}
            slots={groupedSlots}
            onClickTimespan={onSelectSlot}
            minExpectedDuration={expectedDuration * 60}
          />
          {shouldShowSelect(groupedSlots) && (
            <SlotSelect
              isMobileView={isMobileView}
              expectedDuration={expectedDuration}
              onSelectSlot={onSelectSlot}
              selectedBulkAvailability={selectedBulkAvailability}
              oneHourMinimum={oneHourMinimum}
            />
          )}
        </x.div>
      ))}
    </x.div>
  );
};

const SlotSelect = ({
  expectedDuration,
  onSelectSlot,
  oneHourMinimum,
  selectedBulkAvailability,
  isMobileView = false,
}) => {
  const tz = useTimezone();
  const [value, setValue] = useState(null);
  const [slots, setSlots] = useState([]);
  const { selectLabelStyles, selectStyles } = useAvailabilitySelectorStyles();
  const { oneHourAlertStyles } = useAvailabilitySelectorStyles();

  useEffect(() => {
    const newSlots = splitSlots([selectedBulkAvailability], expectedDuration);
    setSlots(newSlots);
    setValue(newSlots[0].startsAt);
    onSelectSlot(newSlots[0], false);
  }, [onSelectSlot, selectedBulkAvailability, expectedDuration]);

  const findSlot = useCallback((startsAt) => slots.find((slot) => isEqual(slot.startsAt, startsAt)) || slots[0], [
    slots,
  ]);

  return (
    <>
      <Select
        label={<Typography {...selectLabelStyles}>Interaction start time</Typography>}
        size={isMobileView ? "small" : "medium"}
        renderSelectedValue={({ value }) => formatSlot(tz, findSlot(value))}
        onChange={(value) => {
          setValue(value);
          onSelectSlot(findSlot(value), false);
        }}
        value={value}
        {...selectStyles}
      >
        {slots.map((slot) => (
          <Option key={slot.startsAt} value={slot.startsAt}>
            {formatSlot(tz, slot)}
          </Option>
        ))}
      </Select>
      {expectedDuration < 60 && oneHourMinimum && <OneHourMinimumAlert {...oneHourAlertStyles} />}
    </>
  );
};
