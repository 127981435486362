import React, { useContext, useEffect, useState } from "react";
import { labelsService } from "services/labelsService";

export interface LabelsProviderState {
  getLabelledExpert: (expertId: string, angleId: string) => LabelledExpert | undefined;
  deleteLabel: (labelId: string) => Promise<void>;
  createLabel: (labelText: string) => Promise<Label>;
  editLabel: (labelId: string, labelText: string) => Promise<Label>;
  addLabel: (labelId: string, experts: { expertId: string; angleId: string }[]) => Promise<LabelledExpert[]>;
  removeLabel: (labelledExpertId: string, expertId: string, angleId: string) => void;
  labels: Label[];
  labelledExperts: LabelledExpert[];
  enableLabels: boolean;
  isLoading: (expertId: string, angleId: string) => boolean;
}

interface LabelsProviderProps {
  project: Project;
  service?: typeof labelsService;
}

export const LabelContext = React.createContext<LabelsProviderState | null>(null);

export const LabelsProvider = ({ project, service = labelsService, ...props }: LabelsProviderProps) => {
  const [labelledExperts, setLabelledExperts] = useState<LabelledExpert[]>([]);
  const [labels, setLabels] = useState<Label[]>([]);
  const [loading, setLoading] = useState<Set<string>>(new Set<string>());

  useEffect(() => {
    if (project && project.enableLabels) {
      service.fetchLabels(project.token).then((labels) => setLabels(labels));

      service.fetchLabelledExperts(project.token).then((labelledExperts) => setLabelledExperts(labelledExperts));
    }
  }, [project, service]);

  const getLabelledExpert = (expertId: string, angleId: string) =>
    labelledExperts.find((c: LabelledExpert) => c.angleId === angleId && c.expertId === expertId);

  const deleteLabel = (labelId: string) => {
    return service.deleteLabel(project.token, labelId).then(() => {
      setLabels((current) => current.filter((e) => e.id !== labelId));
      setLabelledExperts((current) => current.filter((e) => e.label.id !== labelId));
    });
  };

  const createLabel = (labelText: string): Promise<Label> => {
    return service.createLabel(project.token, labelText).then((label) => {
      setLabels((current) => [...current, label]);
      return label;
    });
  };

  const editLabel = (labelId: string, labelText: string): Promise<Label> => {
    const labelsToEdit = labelledExperts.filter((labelledExpert) => labelledExpert.label.id === labelId);
    const keys = labelsToEdit.map((e) => ({ expertId: e.expertId, angleId: e.angleId }));
    addToLoading(keys);
    return service.editLabel(project.token, labelId, labelText).then((editedLabel) => {
      setLabels((current) => current.map((label) => (label.id === labelId ? editedLabel : label)));
      setLabelledExperts((current) => {
        removeFromLoading(keys);
        return current.map((labelledExpert) =>
          labelledExpert.label.id === labelId ? { ...labelledExpert, label: editedLabel } : labelledExpert
        );
      });
      return editedLabel;
    });
  };

  const addLabel = (labelId: string, experts: { expertId: string; angleId: string }[]): Promise<LabelledExpert[]> => {
    const keys = experts.map((e) => ({ expertId: e.expertId, angleId: e.angleId }));
    addToLoading(keys);
    return service.addLabelledExpert(project.token, labelId, experts).then((labelledExperts) => {
      setLabelledExperts((current) => [
        ...current.filter((e) => !labelledExperts.find((l) => l.angleId === e.angleId && l.expertId === e.expertId)),
        ...labelledExperts,
      ]);
      removeFromLoading(keys);
      return labelledExperts;
    });
  };

  const removeLabel = (labelledExpertId: string, expertId: string, angleId: string) => {
    addToLoading([{ expertId, angleId }]);
    return service.removeLabelledExpert(project.token, labelledExpertId).then((_) =>
      setLabelledExperts((current) => {
        removeFromLoading([{ expertId, angleId }]);
        return current.filter((e) => e.id !== labelledExpertId);
      })
    );
  };

  const addToLoading = (keys: { expertId: string; angleId: string }[]) => {
    setLoading((current) => keys.reduce((acc, key) => acc.add(interactionKey(key)), new Set(current)));
  };

  const removeFromLoading = (keys: { expertId: string; angleId: string }[]) => {
    const keysToRemove = new Set(keys.map(interactionKey));
    setLoading((current) => new Set([...current].filter((key) => !keysToRemove.has(key))));
  };

  const isLoading = (expertId: string, angleId: string) => {
    return loading.has(interactionKey({ expertId, angleId }));
  };

  const interactionKey = ({ expertId, angleId }: { expertId: string; angleId: string }) => `${expertId}-${angleId}`;

  const context: LabelsProviderState = {
    labels,
    labelledExperts,
    getLabelledExpert,
    deleteLabel,
    createLabel,
    editLabel,
    addLabel,
    removeLabel,
    enableLabels: project.enableLabels,
    isLoading,
  };

  return <LabelContext.Provider value={context} {...props} />;
};

export const useLabelsContext = () => {
  const context = useContext(LabelContext);

  if (!context) throw new Error("LabelsContext should only be used within the LabelsProvider");

  return context;
};
