import React, { FC } from "react";
import { Button, Modal, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  backButton,
  confirmButton,
  deleteWatchlistLabel,
  deleteWatchlistConfirmation,
} from "components/WatchlistModal/consts";

interface DeleteWatchlistModal {
  onClose: () => void;
  onDeleteWatchlist: () => void;
}

const DeleteWatchlistModal: FC<DeleteWatchlistModal> = ({ onClose, onDeleteWatchlist }) => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  return (
    <Modal
      title={deleteWatchlistLabel}
      size="small"
      open
      onClose={onClose}
      variant="complex"
      slotHeight={layout.base10}
      slotWidth="497px"
      shouldShowFooter={true}
      zIndex={2000}
      primaryButton={
        <Button variant="secondary" size="small" onClick={onDeleteWatchlist}>
          {confirmButton}
        </Button>
      }
      secondaryButton={
        <Button marginRight={inner.base04} variant="ghost" size="small" onClick={onClose}>
          {backButton}
        </Button>
      }
    >
      <Typography variant="body-small" paddingTop={inner.base04}>
        {deleteWatchlistConfirmation}
      </Typography>
    </Modal>
  );
};

export { DeleteWatchlistModal as default };
